import styled from '@emotion/styled';
import { alpha } from '@mui/material';

export const DatePicker = styled.input`
  box-sizing: border-box;
  max-width: 180px;
  font-size: 14px;
  outline: 0;
  padding: 4px 10px;
  border: 1px solid ${({ theme }) => theme.palette.light.dark};
  border-radius: 5px;

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    box-shadow:
      inset 0 2px 1px 0 rgba(0, 0, 0, 0.1),
      0 0 10px 0 ${({ theme }) => alpha(theme.palette.primary.main, 0.5)};
  }
`;
