import { InputHTMLAttributes } from 'react';
import { FieldValues, RegisterOptions, UseFormRegister } from 'react-hook-form';
import styled from '@emotion/styled';

const StyledCheckbox = styled('input')<BulkCheckboxProps>`
  appearance: none;
  position: relative;
  width: 20px;
  height: 20px;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
  border: 1px solid ${({ theme }) => theme.palette.gray['200']};
  border-radius: 4px;
  background-color: #ffffff;
  flex-shrink: 0;

  & ~ label {
    cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
    font-size: 14px;
    color: ${({ theme }) => theme.palette.gray.dark};
    margin-left: 10px;
  }

  &:checked {
    background-color: ${({ theme }) => theme.palette.accent['500']};
    border-color: #07090e20;

    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);

      ${({ checkIcon }) =>
        checkIcon === 'dash'
          ? 'width: 12px; height: 3px; background-color: #fff; top: 50%'
          : `
        display: inline-block;
        transform: translate(-50%, -50%) rotate(45deg);
        height: 10px;
        width: 4px;
        border-bottom: 3px solid #fff;
        border-right: 3px solid #fff;
        top: 45%;
      `}
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

interface BulkCheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  pointer?: boolean;
  checkIcon?: 'check-mark' | 'dash';
  register?: UseFormRegister<FieldValues>;
  name?: string;
  parameters?: RegisterOptions;
}

export const Checkbox = ({ pointer = true, register, name, parameters, ...props }: BulkCheckboxProps) => (
  <StyledCheckbox
    {...props}
    pointer={pointer}
    type="checkbox"
    {...(register && name ? register(name, parameters) : {})}
  />
);
