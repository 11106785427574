import dayjs from 'dayjs';
import { sortBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { Link, TableCell, Tags, Tooltip, Typography } from 'src/components';
import { ModalTypes } from 'src/enums';
import { resolveActionDescription } from 'src/helpers';
import { openModal } from 'src/store/modal.slice';
import { IConvertedLead, ILeadColumnsPosition, TLeadsColumnsKey } from 'src/types';
import { LeadName } from '../_components';

interface ILeadListCellProps {
  lead: IConvertedLead;
  column: ILeadColumnsPosition;
}

export const LeadListCell = ({ lead, column }: ILeadListCellProps) => {
  const dispatch = useDispatch();
  const withNAColumns: TLeadsColumnsKey[] = ['company', 'title', 'campaign'];

  if (withNAColumns.includes(column.key)) {
    const value = lead[column.key] as string;

    return <TableCell tooltip={value || 'NA'}>{value || 'NA'}</TableCell>;
  }

  switch (column.key) {
    case 'name':
      return (
        <TableCell>
          <Link variant="lightBlue" to={`${location.pathname}?person=${lead?.entityUrn}&tab=personal_details`}>
            <LeadName crop fontSize="15px">
              {lead.name}
            </LeadName>
          </Link>
        </TableCell>
      );
    case 'connected':
      return <TableCell>{lead.connected ? 'Yes' : 'No'}</TableCell>;
    case 'action':
      return lead.action ? (
        <TableCell>
          <Tooltip placement="left" title={dayjs(lead?.actionTime).local().format('MMMM Do YYYY h:mm a')}>
            <Box display="flex" alignItems="center">
              <Typography crop fontSize="15px">
                {resolveActionDescription(lead.action)}
              </Typography>
            </Box>
          </Tooltip>
        </TableCell>
      ) : (
        <TableCell>NA</TableCell>
      );
    case 'tags': {
      const sortedTags = sortBy(lead.tags, 'tag');
      const isValues = Array.isArray(sortedTags);
      const tagIds = sortedTags.map(({ id }) => id);

      if (!isValues) {
        return null;
      }

      const onCellClick = () =>
        dispatch(
          openModal({
            headerText: 'Change tags',
            type: ModalTypes.ADD_LEADS_TAGS,
            params: { entityUrn: lead.entityUrn, tagIds },
          }),
        );

      return (
        <TableCell
          maxWidth="180px"
          onClick={(event) => {
            event.stopPropagation();
            onCellClick();
          }}
          contentStyle={{ minWidth: ' 100%' }}
        >
          <Tags withTooltip ids={tagIds} />
        </TableCell>
      );
    }
    default:
      return <TableCell tooltip={lead[column.key]}>{lead[column.key]}</TableCell>;
  }
};
