import { Box } from '@mui/material';

import LinkedInIcon from 'src/assets/icons/linkedin-icon.svg';
import { ReactComponent as Warning } from 'src/assets/icons/warning-bold.svg';
import { Button, MessageBlock, Typography } from 'src/components';
import { useCompanyTheme } from 'src/hooks';
import { Accordion, AccordionDetails, AccordionSummary, GridContainer, GridItem, Loader } from '../ui';
import { LinkedInAccount } from './LinkedInAccount';

interface TryAgainProps {
  isLoadingUpdateCredentials: boolean;
  isLoadingDeleteCredentials: boolean;
  tryAgain: () => void;
  tryDelete: () => void;
}

export const TryAgain = ({
  isLoadingUpdateCredentials,
  isLoadingDeleteCredentials,
  tryAgain,
  tryDelete,
}: TryAgainProps) => {
  const { shortCompanyName } = useCompanyTheme();

  const isLoading = isLoadingUpdateCredentials || isLoadingDeleteCredentials;

  return (
    <>
      <Accordion expanded>
        <AccordionSummary tabIndex={-1}>
          <Box display="flex" alignItems="center" gap="12px" mr="20px">
            <img width="20px" src={LinkedInIcon} alt="LinkedIn" />
            <Typography semibold fontSize="20px">
              Connect LinkedIn
            </Typography>
          </Box>

          <Box display="flex" alignItems="center" gap="7px">
            <Warning />
            <Typography color="error.700" fontSize="15px">
              Connection Error
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <GridContainer container rowSpacing="20px" mb="20px">
            <GridItem item xs={12} sm={6}>
              <LinkedInAccount tryDelete={tryDelete} isLoading={isLoading} />
            </GridItem>
          </GridContainer>

          <MessageBlock
            type="error"
            message={`Security process for account wasn't finished yet. If you have changed your LinkedIn credentials, please click "Remove Account" button and start process again.`}
          />
        </AccordionDetails>
        <AccordionSummary tabIndex={-1}>
          <Box display="flex" alignItems="center" gap="20px" width="100%">
            <Button type="submit" data-testid="button" disabled={isLoading} onClick={tryAgain}>
              Try to Reconnect
            </Button>

            {isLoading && (
              <Box flexGrow="1">
                <Typography fontSize="15px" color="gray.600">
                  {isLoadingUpdateCredentials &&
                    `Almost there! We’re connecting your account for a seamless experience. This may take a couple of minutes.`}
                  {isLoadingDeleteCredentials &&
                    `${shortCompanyName} is removing your LinkedIn account. May take a few minutes.`}
                </Typography>
                <Loader />
              </Box>
            )}
          </Box>
        </AccordionSummary>
      </Accordion>
    </>
  );
};
