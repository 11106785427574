import { PropsWithChildren, useRef } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Slider, Tooltip, Typography } from 'src/components';
import { IDailyLimits, IDailyLimitsFields } from 'src/types';

const LimitSlider = styled(Slider, {
  shouldForwardProp: (prop) => !['deadline'].includes(prop),
})<{ deadline?: boolean | number }>`
  & .MuiSlider-track {
    overflow: hidden;
    background-color: ${({ theme }) => theme.palette.warning.main};
  }
  & .MuiSlider-track::after {
    content: '';
    position: absolute;
    margin-left: -14px;
    width: 100%;
    height: 100%;
    max-width: ${({ deadline }) => (deadline ? `${deadline}px` : 'none')};
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const valueLabelComponent = ({ children }: PropsWithChildren) => {
  return (
    <Tooltip title="Exceeding 40 invites per day does not guarantee a specific number of daily invites sent and may result in LinkedIn imposing restrictions on your account.">
      {children as JSX.Element}
    </Tooltip>
  );
};

const INVITES_RED_ZONE = 40;

interface IDailySliderProps {
  field: ControllerRenderProps<IDailyLimits, keyof IDailyLimits>;
  item: IDailyLimitsFields;
}

export const DayLimitSlider = ({ field, item }: IDailySliderProps) => {
  const { value, onChange } = field;
  const { name, defaultValue, max = 0 } = item;
  const isExceeding = name === 'connects' && value > INVITES_RED_ZONE;

  const sliderRef = useRef<HTMLSpanElement>(null);

  const sliderPaddingWidth = 34;
  const sliderWidth = (sliderRef.current?.clientWidth || 0) + sliderPaddingWidth;
  const redPoint = (((INVITES_RED_ZONE * 100) / (max || 1)) * sliderWidth) / 100;

  return (
    <Box display="flex" alignItems="center">
      <LimitSlider
        ref={sliderRef}
        deadline={name === 'connects' && redPoint}
        data-testid={`limit-slider-${name}`}
        onChange={onChange}
        value={value ?? defaultValue}
        max={max}
        min={0}
        valueLabelDisplay={isExceeding ? 'auto' : 'off'}
        components={{
          ValueLabel: valueLabelComponent,
        }}
      />

      <Typography data-testid={`limit-value-${name}`} width="70px" textAlign="end" color="text.primary" fontSize="16px">
        {value ?? defaultValue}
      </Typography>
    </Box>
  );
};
