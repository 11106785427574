import { UseFormHandleSubmit } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { HeaderButton, HeaderContainer, HeaderTitle } from 'src/components';
import { Features, PostStatus } from 'src/enums';
import { checkPostValid } from 'src/helpers';
import { useLocationState, useTeamPlan } from 'src/hooks';
import { useArchivePost, useDeletePost } from 'src/reactQueries';
import { openModal, showToast } from 'src/store';
import { IGetPost, TPostTypes, TSchedulePost } from 'src/types';

interface PostFormLayoutProps {
  title: string;
  onPublish: (post: Omit<TSchedulePost, 'scheduledAt'> & { scheduledAt: string }) => void;
  isPublishDisabled: boolean;
  attachmentError: string;
  handleSubmit: UseFormHandleSubmit<TSchedulePost>;
  isEdit?: boolean;
  post?: IGetPost;
}

export const PostFormLayout = ({
  title,
  onPublish,
  isPublishDisabled,
  attachmentError,
  handleSubmit,
  isEdit = false,
  post,
}: PostFormLayoutProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const locationState = useLocationState<{ postType: TPostTypes }>();

  const { isTrialStarted, checkFeature } = useTeamPlan();

  const publishPost = (post: TSchedulePost) => {
    const validPost = checkPostValid({
      post,
      isTrialStarted,
      attachmentError,
      isAppropriatePlan: checkFeature(Features.schedulePosts),
      onInvalidError: (message) =>
        dispatch(
          showToast({
            message,
            type: 'error',
            autoCloseTime: 3000,
          }),
        ),
    });

    if (!validPost) {
      return null;
    }

    return onPublish({
      ...post,
      scheduledAt: post.scheduledAt.utc().format('YYYY-MM-DD HH:mm:ss'),
    });
  };

  const duplicatePost = () => {
    navigate('/posts/new', {
      state: {
        postType: post?.postType,
        defaultValues: {
          title: post ? `${post.title} (clone)` : '',
          postTypes: [],
          allowComments: post ? post.isCommentsAllowed : true,
          postAs: post ? post.postAs : 'You',
          audience: post ? post.audience : 'anyone',
          attachments: post ? post.attachments : [],
          content: post ? post.content : '',
        },
      },
    });
  };

  const { deletePost, isLoading: isDeletingPost } = useDeletePost(post?.postType || 'linkedin', {
    onSuccess: () => navigate(`/posts/${post?.postType || 'linkedin'}`),
  });
  const { archivePost, isLoading: isArchivingPost } = useArchivePost();

  const archivePostHandler = () => {
    dispatch(
      openModal({
        headerText: 'Archive Post',
        descriptionText: 'Are you sure you want to archive this post?',
        onConfirm: () => {
          if (post?.id) {
            archivePost(post.id);
          }
        },
      }),
    );
  };

  const deletePostHandler = () => {
    dispatch(
      openModal({
        headerText: 'Delete Post',
        descriptionText: 'Are you sure you want to delete this post?',
        onConfirm: () => {
          if (post?.id) {
            deletePost(post.id);
          }
        },
      }),
    );
  };

  return (
    <HeaderContainer display="flex" justifyContent="space-between" pb="20px">
      <HeaderTitle>{title}</HeaderTitle>
      <Box display="flex" alignItems="flex-end" gap="20px">
        <HeaderButton
          variant="gray"
          onClick={() => navigate(`/posts/${locationState?.postType || post?.postType || 'linkedin'}`)}
        >
          Cancel
        </HeaderButton>
        {isEdit && post && (
          <>
            <HeaderButton variant="warning" disabled={isDeletingPost} onClick={deletePostHandler}>
              Delete
            </HeaderButton>
            {post?.status !== PostStatus.ARCHIVED && (
              <HeaderButton variant="gray" disabled={isArchivingPost} onClick={archivePostHandler}>
                Archive
              </HeaderButton>
            )}
            <HeaderButton onClick={duplicatePost}>Duplicate</HeaderButton>
          </>
        )}
        {(!isEdit || [PostStatus.SKIPPED, PostStatus.PENDING].includes(post?.status as PostStatus)) && (
          <HeaderButton disabled={isPublishDisabled} onClick={handleSubmit(publishPost)}>
            {isEdit ? 'Save' : 'Schedule'}
          </HeaderButton>
        )}
      </Box>
    </HeaderContainer>
  );
};
