import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { Checkbox, Table, TableBody, TableHead, TableHeadCell, TablePagination, TableRow } from 'src/components';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from 'src/constants';
import { CampaignType, LeadStatus, ModalTypes } from 'src/enums';
import { resolveNoLeadsPlaceholder } from 'src/helpers';
import { useAppSelector, useCompanyTheme, useDebounce } from 'src/hooks';
import { useGetCampaign, useGetIgnoredCampaignLeads } from 'src/reactQueries';
import { openModal, updateIgnoredSelectedLeads } from 'src/store';
import {
  IGetIgnoredCampaignLeadRequest,
  IIgnoredCampaignLeadsSearchFields,
  ITablePagination,
  TIgnoredLeadsSortField,
  TSortOrder,
} from 'src/types';
import { DetailsLayout } from '../DetailsLayout';
import { IgnoredLeadsFilterBar } from './IgnoredLeadsFilterBar';
import { IgnoredLeadsTableRow } from './IgnoredLeadsTableRow';

export const IgnoredLeads = () => {
  const { campaignId } = useParams();
  const CAMPAIGN_ID = Number(campaignId);
  const { shortCompanyName } = useCompanyTheme();

  const [sortField, setSortField] = useState<TIgnoredLeadsSortField>('name');
  const [sortOrder, setSortOrder] = useState<TSortOrder>('ASC');
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [searchFields, setSearchFields] = useState<IIgnoredCampaignLeadsSearchFields>({
    name: '',
    statuses: [],
    campaignIds: [],
  });

  const nameDebounced = useDebounce(searchFields.name?.trim() || '');

  const getCampaignLeadData = {
    page,
    perPage,
    sortOrder,
    sortField,
    name: nameDebounced,
    statuses: searchFields.statuses,
    campaignIds: searchFields.campaignIds,
  } as IGetIgnoredCampaignLeadRequest;

  const { campaign } = useGetCampaign(CAMPAIGN_ID);

  const dispatch = useDispatch();

  const { data, isLoading, isFetching } = useGetIgnoredCampaignLeads(CAMPAIGN_ID, getCampaignLeadData, {
    refetchOnMount: true,
  });

  const { entityUrns } = useAppSelector((state) => state.campaign.selectedIgnoredLeads);
  const { leads = [], total = 0 } = data ?? {};
  const currPageLeads = leads.map(({ personKey }) => personKey);

  const setSelectLeads = (newEntityUrns: string[]) =>
    dispatch(updateIgnoredSelectedLeads({ campaignId: CAMPAIGN_ID, entityUrns: newEntityUrns }));

  const isSelectedAll =
    leads.length !== 0 && (entityUrns.length === total || currPageLeads.every((urm) => entityUrns.includes(urm)));

  const selectProps = {
    isSelectedAll,
    selectLeads: entityUrns,
    toggleLead: (entityUrn: string) =>
      entityUrns.includes(entityUrn)
        ? setSelectLeads(entityUrns.filter((urm) => urm !== entityUrn))
        : setSelectLeads([...entityUrns, entityUrn]),

    toggleAllLeads: () =>
      isSelectedAll
        ? setSelectLeads(entityUrns.filter((urm) => !currPageLeads.includes(urm)))
        : setSelectLeads([...entityUrns, ...currPageLeads.filter((urm) => !entityUrns.includes(urm))]),
  };

  useEffect(() => {
    if (!leads.length && page * perPage - total === perPage) {
      setPage((prev) => prev - 1 || DEFAULT_PAGE);
    }
  }, [leads.length]);

  const sortingProps = {
    sortOrder,
    sortField,
    onSortFieldChange: (field: TIgnoredLeadsSortField, order: TSortOrder) => {
      setSortField(field);
      setSortOrder(order);
    },
  };

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  const setSearchField = (field: string, value: string | number[] | LeadStatus[]) => {
    setPage(DEFAULT_PAGE);
    setSearchFields((prev) => ({ ...prev, [field]: value }));
  };

  //We will export here all leads. To apply the filter, we need to pass the searchFields to the export function
  const onExportClick = () =>
    dispatch(
      openModal({
        type: ModalTypes.EXPORT_LEAD_CSV,
        params: {
          entityUrns,
          type: 'ignored',
          name: searchFields.name || '',
          campaignId: CAMPAIGN_ID,
          ignoredFilters: {
            ...searchFields,
          },
        },
      }),
    );

  return (
    <DetailsLayout
      onExportClick={!!(leads?.length || entityUrns?.length) && onExportClick}
      showRemoveAll
      selectLeads={selectProps.selectLeads}
      getIgnoredLeadKeys={getCampaignLeadData}
      showAddMoreLeads
    >
      <IgnoredLeadsFilterBar
        campaignType={campaign?.campaignType}
        searchFields={searchFields}
        onSearchFieldChange={setSearchField}
      />
      <Box padding="15px">
        <Table
          placeholder={
            !leads.length && !isLoading && resolveNoLeadsPlaceholder(campaign?.campaignType, shortCompanyName)
          }
          processing={isLoading || (!leads.length && (isFetching || !!total))}
          disabled={isLoading}
        >
          <TableHead>
            <TableRow>
              <TableHeadCell width="40px">
                <Checkbox checked={selectProps.isSelectedAll} onChange={() => selectProps.toggleAllLeads()} />
              </TableHeadCell>
              <TableHeadCell name="name" sorting={sortingProps} width="35%">
                <Box ml="55px">{campaign?.campaignType === CampaignType.EMAIL_CSV_UPLOAD ? 'Email' : 'Name'}</Box>
              </TableHeadCell>
              <TableHeadCell name="reason" sorting={sortingProps} width="35%">
                Reason
              </TableHeadCell>
              <TableHeadCell name="campaign" sorting={sortingProps} width="30%">
                Campaign
              </TableHeadCell>
              <TableHeadCell width="100px">Step</TableHeadCell>
              <TableHeadCell name="tags" width="25%">
                Tags
              </TableHeadCell>
              <TableHeadCell width="150px" />
            </TableRow>
          </TableHead>
          <TableBody>
            {leads?.map((lead) => (
              <IgnoredLeadsTableRow
                key={lead.personKey}
                lead={lead}
                selectProps={selectProps}
                getIgnoredLeadKeys={getCampaignLeadData}
                campaignId={CAMPAIGN_ID}
                campaignType={campaign?.campaignType}
              />
            ))}
          </TableBody>
        </Table>

        <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={paginationChangeHandler} />
      </Box>
    </DetailsLayout>
  );
};
