import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';

import { Button, Toggle, Typography } from 'src/components';
import { ModalTypes } from 'src/enums/modal.enum';
import { openModal } from 'src/store/modal.slice';
import { ITwitterDetails, IUserCompany, IUserLinkedInGroup } from 'src/types';
import { AUDIENCE_LABELS } from '../../../constants';
import { useGetUserLinkedInGroups } from '../../../reactQueries';
import { IFormCreatePost } from './PostForm';

const SelectButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 30px;
  height: auto;
  border-radius: 5px;
  text-align: left;
  padding: 0 25px;
  font-family: 'ProximaSoft', sans-serif;
  font-weight: normal;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-left: 5px;
  color: ${({ theme }) => theme.palette.gray.main};
`;

const TextContainer = styled.span`
  font-size: 14px;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;
`;

export interface ILinkedinOptionsProps {
  watch: UseFormWatch<IFormCreatePost>;
  setValue: UseFormSetValue<IFormCreatePost>;
  postAs: string;
  twitterData?: ITwitterDetails;
  selectedCompany: IUserCompany | null;
  selectedGroup: IUserLinkedInGroup | null;
  audience: {
    label: string;
    value: string;
  };
  // eslint-disable-next-line
  onPostByConfirm: (params: any) => void;
  // eslint-disable-next-line
  onAudienceConfirm: (params: any) => void;
  disabled?: boolean;
}

export const LinkedinOptions = ({
  watch,
  setValue,
  postAs,
  twitterData,
  selectedGroup,
  selectedCompany,
  onPostByConfirm,
  onAudienceConfirm,
  disabled = false,
}: ILinkedinOptionsProps) => {
  const dispatch = useDispatch();

  const { groups } = useGetUserLinkedInGroups();

  return (
    <>
      <div>
        <Typography mb="15px" semibold>
          Linkedin Options
        </Typography>

        <Grid container spacing={4} display="flex" alignItems="flex-start">
          <Grid container item spacing={2} xs={6} alignItems="center">
            <Grid item xs={4}>
              <TextContainer>Post by</TextContainer>
            </Grid>
            <Grid item xs={8}>
              <SelectButton
                size={{ width: '100%' }}
                variant="gray"
                onClick={() =>
                  disabled ||
                  dispatch(
                    openModal({
                      type: ModalTypes.POST_BY,
                      closable: false,
                      onConfirm: onPostByConfirm,
                      params: {
                        postAs: selectedCompany?.entityUrn || 'You',
                      },
                    }),
                  )
                }
              >
                <span>{selectedCompany?.name || 'You'}</span>
                <Icon icon={faChevronRight} />
              </SelectButton>
            </Grid>
          </Grid>
          <Grid container item spacing={2} xs={6} alignItems="center">
            <Grid item xs={4}>
              <TextContainer>Audience</TextContainer>
            </Grid>
            <Grid item xs={8}>
              <SelectButton
                size={{ width: '100%' }}
                variant="gray"
                onClick={() =>
                  disabled ||
                  dispatch(
                    openModal({
                      type: ModalTypes.POST_AUDIENCE,
                      closable: false,
                      onConfirm: onAudienceConfirm,
                      params: {
                        postAs: postAs || 'You',
                        audience: (watch('audience').includes('group:') ? 'group' : watch('audience')) || 'anyone',
                        twitterScreenName: twitterData?.username,
                        selectedGroup,
                      },
                    }),
                  )
                }
              >
                <Typography lineHeight="14px" py="5px">
                  {AUDIENCE_LABELS[watch('audience')] ||
                    (watch('audience').includes('group') &&
                      groups?.find((group) => `group:${group.savedSearchId}` === watch('audience'))?.name) ||
                    'Anyone'}
                </Typography>
                <Icon icon={faChevronRight} />
              </SelectButton>
            </Grid>
          </Grid>
        </Grid>
        <ToggleContainer>
          <Toggle name="allowComments" defaultChecked setValue={setValue} disabled={disabled} />
          <Typography ml="15px">Allow comments on this post</Typography>
        </ToggleContainer>
      </div>
    </>
  );
};
