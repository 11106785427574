import { AppLayout } from 'src/layouts';
import { TagManagerLayout } from './TagManagerLayout';
import { TagTable } from './TagTable/TagTable';

export const TagManager = () => {
  return (
    <AppLayout>
      <TagManagerLayout />
      <TagTable />
    </AppLayout>
  );
};
