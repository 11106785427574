import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getCampaignLeadSources } from 'src/api';
import { ILeadSource } from 'src/types';

export const useGetCampaignLeadSources = <TData = ILeadSource[]>(
  campaignId: number,
  options?: UseQueryOptions<ILeadSource[], AxiosError, TData>,
) => {
  return useQuery<ILeadSource[], AxiosError, TData>(
    ['get-lead-sources', campaignId],
    () => getCampaignLeadSources(campaignId),
    options,
  );
};
