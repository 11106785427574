import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { BASE_WL_API_ULR } from 'src/constants';
import { useCompanyTheme, useWhiteLabel } from 'src/hooks';
import { Code, RequestFields, Response, Title } from '../_components';
import { GET_LEAD, UNAUTHORIZED } from '../_constants';

export const WebhookDocLeads = () => {
  const { shortCompanyName } = useCompanyTheme();
  const {
    whitelabel: { isAlfredDomain },
  } = useWhiteLabel();

  return (
    <Box>
      <Typography lineHeight="14px">
        This webhook can be used get the leads, you can get your {shortCompanyName} leads by using this webhook. It will
        return the data in actions array. It will include <b>campaign</b> object which includes all the information of
        the associated campaign and <b>person object</b> which includes all the lead information.
      </Typography>
      <Title>
        Request URL <b>GET</b>
      </Title>
      <Code>
        {isAlfredDomain ? 'https://meetalfred.com' : BASE_WL_API_ULR}
        /api/integrations/webhook/new-leads?webhook_key=:webhook_key&page=0&per_page=1
      </Code>
      <Typography>
        <b>Note:</b> here page and per_page are optional params, page starts with 0.
      </Typography>
      <Title>
        Request Payload <b>application/json</b>
      </Title>
      <RequestFields
        type="integer"
        field="campaign (optional)"
        description={`id of the ${shortCompanyName} campaign`}
      />
      <Title>Responses</Title>
      <Response type="success" title="200 OK" responses={GET_LEAD} />
      <Response mt="20px" type="error" title="401 Unauthorized" responses={UNAUTHORIZED} />
    </Box>
  );
};
