import { useQuery, UseQueryOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { getTrendPosts } from 'src/api';
import { setFetchingTrend } from 'src/store/trends.slice';
import { ICustomAxiosError, ITrendPost } from 'src/types';

export const useGetTrendPosts = (
  { trendId }: { trendId: number },
  options?: UseQueryOptions<ITrendPost[], ICustomAxiosError>,
) => {
  const dispatch = useDispatch();

  return useQuery<ITrendPost[], ICustomAxiosError>(['get-trend-posts', trendId], () => getTrendPosts(trendId), {
    ...options,
    onError: (err) => {
      options?.onError?.(err);
      if (err.statusCode === 425) {
        dispatch(setFetchingTrend({ [trendId]: true }));
      }
    },
  });
};
