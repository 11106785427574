import classNames from 'classnames';
import { MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { ActionButton, Tooltip } from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { CampaignCategory, CampaignStatus, CampaignType, Features, ModalTypes } from 'src/enums';
import { getAddMoreLeadsState, getCampaignTypes } from 'src/helpers';
import { useTeamPlan } from 'src/hooks';
import {
  useArchiveCampaign,
  useDeleteCampaign,
  useDuplicateCampaign,
  useRestartSearchCampaign,
} from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';
import { IGetCampaigns, TCampaignsStatus } from 'src/types';

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    overflow: visible;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
    padding: 10px;
    color: ${({ theme }) => theme.palette.primary.wlLight};
    width: 140px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  cursor: pointer;
  padding: 10px;
  color: ${({ theme }) => theme.palette.primary.wlLight};
  line-height: 1.42857143;
  font-size: 14px;
  font-family: 'ProximaSoft', sans-serif;
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: #fff;
  }
  &:focus {
    background-color: #fff;
  }
  &.disabled {
    cursor: default;
  }
`;

const TooltipTitle = styled.div`
  text-align: center;
  white-space: pre-wrap;
  max-width: 500px;
`;

interface ICampaignActionsProps {
  campaign: IGetCampaigns;
  campaignStatus: TCampaignsStatus;
}

export const CampaignActions = ({ campaign, campaignStatus }: ICampaignActionsProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { id, isSearchEnded, leadSourcesSearchEnded, leadSourcesCount, campaignType } = campaign;
  const { category, isConnectionsCampaign, isZapierCampaign } = getCampaignTypes(campaign);
  const isMenuOpen = Boolean(anchorEl);
  const isActive = campaignStatus === 'active';

  const { checkFeature } = useTeamPlan();

  const { deleteCampaign } = useDeleteCampaign({ campaignId: id, category });
  const { duplicateCampaign } = useDuplicateCampaign(id);
  const { restartSearch } = useRestartSearchCampaign({ campaignId: id, category });
  const { archiveCampaign } = useArchiveCampaign(id, {
    onSuccess: (data, variables) => {
      if (!variables.toArchive)
        navigate(`/campaign/stats/${campaign.category}/${data.status === CampaignStatus.DRAFT ? 'draft' : 'paused'}`);
    },
  });

  const addMoreLeads = () => {
    const { path, state } = getAddMoreLeadsState(campaign);

    navigate(path, { state });
  };

  const openRestartSearchModal = () => {
    if (!leadSourcesCount) {
      return restartSearch({ restartCampaign: true });
    }

    return dispatch(
      openModal({
        type: ModalTypes.RESTART_CAMPAIGN_SEARCH,
        closable: false,
        params: {
          campaignId: id,
        },
      }),
    );
  };

  const isTwitterCategory = category === CampaignCategory.TWITTER;

  const itemOptions = [
    {
      label: 'Details',
      onClick: () => navigate(`/campaign/details/overview/${id}`),
    },
    {
      label: 'Edit Sequence',
      isShow: isActive,
      onClick: () =>
        navigate(`/campaign/${id}/sequence`, {
          state: { isEdit: true, canPublish: false },
        }),
      disabled: isTwitterCategory,
      tooltip: isTwitterCategory ? 'Feature under Maintenance' : '',
    },
    {
      label: 'Duplicate',
      isShow: isActive,
      onClick: () => duplicateCampaign(),
      disabled: isTwitterCategory,
      tooltip: isTwitterCategory ? 'Feature under Maintenance' : '',
    },
    {
      label: isActive ? 'Archive' : 'Unarchive',
      onClick: () => archiveCampaign({ toArchive: isActive }),
      disabled: !isActive && isTwitterCategory,
      tooltip: !isActive && isTwitterCategory ? 'Feature under Maintenance' : '',
    },
    {
      label: 'Restart Search',
      isShow:
        isActive &&
        ((isSearchEnded && !isConnectionsCampaign) || !!leadSourcesSearchEnded) &&
        (leadSourcesCount || !isZapierCampaign),
      onClick: () => openRestartSearchModal(),
      disabled: isTwitterCategory,
      tooltip: isTwitterCategory
        ? 'Feature under Maintenance'
        : `Your campaign search is now finished, however, your, campaign will continue to run. If you were expecting more results, try creating a new campaign with less stringent search filters or you can restart the search clicking here.`,
    },
    {
      label: 'Add More Leads',
      isShow:
        isActive &&
        ![CampaignType.LINKEDIN_GROUP, CampaignType.LINKEDIN_POST, CampaignType.LINKEDIN_EVENT].includes(campaignType),
      onClick: () => addMoreLeads(),
      disabled: !checkFeature(Features.addMoreLeads) || isTwitterCategory,
      tooltip:
        (!checkFeature(Features.addMoreLeads) && PLAN_TOOLTIPS.professional) ||
        (isTwitterCategory && 'Feature under Maintenance') ||
        'You can add more leads to this campaign from different sources.',
    },
    {
      label: 'Delete',
      isShow: !isActive,
      onClick: () => deleteCampaign(),
    },
  ];

  const handleClose = () => setAnchorEl(null);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);

  return (
    <>
      <ActionButton
        ml="10px"
        tooltip="Click here to view options"
        onClick={handleClick}
        fontSize="14px"
        icon={faEllipsisH}
        aria-haspopup="true"
        {...(isMenuOpen && {
          'aria-controls': 'basic-menu',
          'aria-expanded': 'true',
        })}
      />
      <StyledMenu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {itemOptions.map(
          ({ disabled, label, onClick, tooltip, isShow = true }) =>
            isShow && (
              <Tooltip key={label} title={tooltip && <TooltipTitle>{tooltip}</TooltipTitle>} arrow placement="top">
                <span>
                  <StyledMenuItem
                    className={classNames({ disabled })}
                    disabled={disabled}
                    onClick={() => {
                      handleClose();
                      !disabled && onClick && onClick();
                    }}
                  >
                    {label}
                  </StyledMenuItem>
                </span>
              </Tooltip>
            ),
        )}
      </StyledMenu>
    </>
  );
};
