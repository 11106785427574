import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { deleteWhiteLabelSMTP } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IWhiteLabel } from 'src/types';

export const useDeleteWhiteLabelSMTP = (options?: UseMutationOptions<void, ICustomAxiosError>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const getWhiteLabelQueryKey = ['get-whitelabel'];
  const { mutate, isLoading, ...rest } = useMutation(['delete-whitelabel-smtp'], deleteWhiteLabelSMTP, {
    ...options,
    onMutate: async (variables) => {
      options?.onMutate?.(variables);
      await queryClient.cancelQueries(getWhiteLabelQueryKey);

      const prevData = queryClient.getQueryData(getWhiteLabelQueryKey);

      queryClient.setQueryData<IWhiteLabel | undefined>(
        getWhiteLabelQueryKey,
        (data) =>
          data && {
            ...data,
            smtpSettings: {},
          },
      );

      return { prevData };
    },
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(getWhiteLabelQueryKey);
      options?.onSuccess?.(data, variables, context);
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      queryClient.setQueryData(getWhiteLabelQueryKey, context?.prevData);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { deleteSMTP: mutate, isSMTPLoading: isLoading, ...rest };
};
