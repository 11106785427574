import { ACTIONS_SYSTEM_MESSAGES, LeadActivityEnum } from 'src/enums';

export const CONNECTIONS_SORTABLE_COLUMNS = [
  { key: 'name', fieldName: 'firstName' },
  { key: 'company', fieldName: 'companyName' },
  { key: 'title', fieldName: 'title' },
  { key: 'industry', fieldName: 'industryName' },
  { key: 'connectedAt', fieldName: 'connectedAt' },
  { key: 'address', fieldName: 'address' },
  { key: 'email', fieldName: 'email' },
  { key: 'phone', fieldName: 'phone' },
];
export const LEAD_ACTIVITY_ACTIONS: Record<string, string> = {
  [LeadActivityEnum.FOUND]: 'Lead Found',
  [LeadActivityEnum.ALREADY_CONNECTED]: 'Already Connected',
  [LeadActivityEnum.CONNECTED]: 'Connected',
  [LeadActivityEnum.COULD_NOT_FOLLOW]: 'Could not send Follow up',
  [LeadActivityEnum.FOLLOWED_AFTER_CONNECT]: 'Follow up message sent after Connecting',
  [LeadActivityEnum.INMAIL_MESSAGE_SENT]: 'InMail Message sent',
  [LeadActivityEnum.INMAIL_SKIPPED]: 'InMail skipped',
  [LeadActivityEnum.LINKEDIN_REPLY_DETECTED]: 'LinkedIn reply detected',
  [LeadActivityEnum.MESSAGE_SENT]: 'Message sent',
  [LeadActivityEnum.REQUESTED_CONNECT]: 'Invited',
  [LeadActivityEnum.VERIFIED_UPLOADED_LEAD]: 'Lead verified',
  [LeadActivityEnum.VIEWED]: 'Profile viewed',
  [LeadActivityEnum.EMAILED]: 'Email Sent',
  [LeadActivityEnum.EMAIL_REPLY_DETECTED]: 'Email reply detected',
  [LeadActivityEnum.EMAILED_POSTPONED]: 'Email postponed',
  [LeadActivityEnum.CONNECT_REQUEST_POSTPONED]: 'Connect request postponed',
  [LeadActivityEnum.ENDORSED_PROFILE]: 'Endorsed a profile',
  [LeadActivityEnum.USER_INTERACTION_DETECTED]: 'User interaction detected',
  [LeadActivityEnum.FOLLOWED_PROFILE]: 'Followed a profile',
  [LeadActivityEnum.INVITE_WITHDRAWN]: 'Invite withdrawn',
  [LeadActivityEnum.NO_EMAIL_FOUND]: 'No email found',
  [LeadActivityEnum.EMAIL_OPENED]: 'Email opened',
  [LeadActivityEnum.COULDNT_SEND_MAIL]: 'Could not send mail',
  [LeadActivityEnum.COULD_NOT_RUN_ACTION]: 'Could not run action',
  [LeadActivityEnum.EMAIL_BOUNCED]: 'Email bounced',
  [LeadActivityEnum.UNABLE_SEND_MESSAGE]: 'Unable to send message',
  [LeadActivityEnum.COULD_NOT_CONNECT_USER]: 'Could not connect user',
  [LeadActivityEnum.RETURNED_TO_CAMPAIGN]: 'Returned to campaign',
  [LeadActivityEnum.GROUP_MESSAGE_SENT]: 'Group message sent',
  [LeadActivityEnum.ENGAGED_PROFILE]: 'Engaged profile',
  [LeadActivityEnum.EMAIL_SKIPPED]: 'Email skipped',
  [LeadActivityEnum.TWITTER_MESSAGE_SENT]: 'X (Twitter) Message sent',
  [LeadActivityEnum.TWITTER_REPLY_DETECTED]: 'X (Twitter) reply detected',
  [LeadActivityEnum.UNABLE_TO_FOLLOW_ON_TWITTER]: 'Unable to follow on X (Twitter)',
  [LeadActivityEnum.UNABLE_TO_SEND_DIRECT_MESSAGE]: 'Unable to send Direct message',
  [LeadActivityEnum.INMAIL_REPLY_DETECTED]: 'InMail reply detected',
  [LeadActivityEnum.LEAD_FOLLOWED_IN_TWITTER]: 'Lead Followed in X (Twitter)',
  [LeadActivityEnum.EVENT_MESSAGE_SENT]: 'Event Message sent',
  [LeadActivityEnum.ALREADY_INVITED]: 'Invite already sent',
  [LeadActivityEnum.MOVED_TO_CAMPAIGN]: 'Moved to campaign',
  [LeadActivityEnum.MESSAGE_ALREADY_SENT]: 'Message already sent',
  [LeadActivityEnum.USER_UNSUBSCRIBED_FROM_EMAIL]: 'User unsubscribed from email',
  [LeadActivityEnum.LEAD_POSTPONED]: 'Unable to resend invitation',
  [LeadActivityEnum.MESSAGE_POSTPONED]: 'Message postponed',
  [LeadActivityEnum.LIKED_POST]: 'Liked a post',
  [LeadActivityEnum.TWITTER_HANDLE_SYNCED]: 'X (Twitter) handle synced',

  [LeadActivityEnum.WORK_ANNIVERSARY]: 'Work anniversary action',
  [LeadActivityEnum.BIRTHDAY]: 'Birthday action',
  [LeadActivityEnum.SKILL_ENDORSEMENT]: 'Skill Endorsement action',
  [LeadActivityEnum.JOB_CHANGE]: 'Job change action',
};

export const LEAD_ACTIONS_MESSAGES: Record<string, string> = {
  [ACTIONS_SYSTEM_MESSAGES.withoutMessage]:
    'We found a limit on connection request messages but bypassed the restriction and sent your request without a message.',
  [ACTIONS_SYSTEM_MESSAGES.postponed]:
    "Your connection request message is a bit too long. Please trim it down to 200 characters or less, and we'll try sending it again.",
  [ACTIONS_SYSTEM_MESSAGES.attachmentWithoutMessage]: 'Attachment was sent without message',
};
