import { useMemo } from 'react';
import { useTheme } from '@emotion/react';

import { ITypographyProps, Tag, Typography } from 'src/components';
import { TeamUserRole } from 'src/enums';

interface IRoleBadgedProps extends ITypographyProps {
  role: TeamUserRole;
}

export const RoleBadged = ({ role, ...rest }: IRoleBadgedProps) => {
  const { palette } = useTheme();

  const { bgColor, borderColor, color } = useMemo(() => {
    switch (role) {
      case 'owner':
        return {
          bgColor: `${palette.success[200]}26`,
          borderColor: `${palette.success[200]}e6`,
          color: palette.success[600],
        };
      case 'admin':
        return {
          bgColor: `${palette.warning.main}1a`,
          borderColor: `${palette.warning.main}80`,
          color: palette.warning.main,
        };
      default:
        return { bgColor: palette.blueLight[50], borderColor: palette.blueLight[200], color: palette.blueLight[700] };
    }
  }, [role]);

  return (
    <Tag borderColor={borderColor} bgColor={bgColor}>
      <Typography color={color} textTransform="capitalize" textAlign="center" {...rest}>
        {role}
      </Typography>
    </Tag>
  );
};
