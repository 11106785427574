import { useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { Box } from '@mui/material';

import { ToggleButtonGroup, Typography } from 'src/components';
import { ISelectOption } from 'src/types';
import { ICreateTemplateForm } from '../TemplateCreator/TemplateCreator';

export const AvailabilitySelect = ({
  name,
  setValue,
  options,
  label,
  defaultValue = null,
}: {
  name: keyof ICreateTemplateForm;
  setValue: UseFormSetValue<ICreateTemplateForm>;
  options: ISelectOption[];
  label: string;
  defaultValue: ISelectOption | null;
}) => {
  const [availability, setAvailability] = useState(defaultValue || options[0]);

  return (
    <Box display="flex" alignItems="center">
      <Typography mr="10px" ml="30px" color="text.primary">
        {label}
      </Typography>
      <ToggleButtonGroup
        value={availability}
        onChange={(opt) => {
          setValue(name, opt);
          setAvailability(opt);
        }}
        options={options}
      />
    </Box>
  );
};
