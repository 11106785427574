import { useDispatch } from 'react-redux';

import { Button, Typography } from 'src/components';
import { closeModal } from 'src/store/modal.slice';
import { IModalProps } from 'src/types';
import { ModalBody, ModalFooter } from '../_components';

export const ModalDowngradeRole = ({ onCancel, params }: IModalProps) => {
  const dispatch = useDispatch();
  const { roleFrom = '', roleTo = '', onConfirm, isUpdateLoading } = params ?? {};

  const closeHandler = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  const confirmHandler = () => {
    onConfirm && onConfirm();
    closeHandler();
  };

  return (
    <>
      <ModalBody>
        <Typography color="primary.wlLight">
          Are you sure you want to change your own role from {roleFrom} to {roleTo}?
        </Typography>
        <Typography color="violet.dark" fontSize="11px" mt="20px">
          This change is permanent and you will lose the ability to change back to the {roleFrom} role once you confirm
        </Typography>
      </ModalBody>
      <ModalFooter justifyContent="flex-end">
        <Button variant="gray" onClick={closeHandler}>
          Cancel
        </Button>
        <Button onClick={confirmHandler} processing={isUpdateLoading}>
          Confirm role change
        </Button>
      </ModalFooter>
    </>
  );
};
