import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { BASE_WL_API_ULR } from 'src/constants';
import { useWhiteLabel } from 'src/hooks';
import { useGetUserAccount } from 'src/reactQueries';
import { Code, Response, Title } from '../_components';
import { GET_TEAM_MEMBERS, UNAUTHORIZED } from '../_constants';

export const WebhookDocTeamMembers = () => {
  const { userMe } = useGetUserAccount();
  const {
    whitelabel: { isAlfredDomain },
  } = useWhiteLabel();

  return (
    <Box>
      <Typography lineHeight="14px">
        This webhook can be used get the team members, Team members details along with their webhook key is returned by
        this webhook. Array include the objects and object includes all the information of the team members including
        associated <b>webhook key</b> and <b>timezone</b> as well.
      </Typography>
      <Typography>
        <b>Note:</b> only team owner can access his team members information
      </Typography>
      <Title>
        Request URL <b>GET</b>
      </Title>
      <Code>
        {isAlfredDomain ? 'https://meetalfred.com' : BASE_WL_API_ULR}
        /api/integrations/webhook/get_team_members?webhook_key=:webhook_key&team_key=
        {userMe?.lastTeamId}
      </Code>
      <Typography>
        <b>Note: </b>only team owner can call this api
      </Typography>
      <Title>Responses</Title>
      <Response type="success" title="200 OK" responses={GET_TEAM_MEMBERS} />
      <Response mt="20px" type="error" title="401 Unauthorized" responses={UNAUTHORIZED} />
    </Box>
  );
};
