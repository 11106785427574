import { useEffect, useMemo } from 'react';
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { getLIConversationMessages } from 'src/api';
import { LIConversationFilterType } from 'src/enums';
import { useAppSelector } from 'src/hooks';
import { setSelectedConversation } from 'src/store';
import { ICustomAxiosError, ILIConversation, ILIMessages } from 'src/types';

export const useGetLIConversationMessages = (
  {
    conversationId,
    entityUrn,
  }: {
    entityUrn: string;
    conversationId: string;
  },
  options?: UseInfiniteQueryOptions<ILIMessages, ICustomAxiosError, ILIMessages>,
) => {
  const dispatch = useDispatch();
  const conversation = useAppSelector((state) => state.conversation);
  const { filterType } = conversation;

  const queryClient = useQueryClient();
  const taggedConversation = filterType === LIConversationFilterType.TAGGED && ['get-tagged-conversations'];
  const replyConversation = filterType === LIConversationFilterType.REPLIES && ['get-replies-conversations'];

  const queryKey = taggedConversation || replyConversation || ['get-li-conversations'];

  const { data, ...rest } = useInfiniteQuery<ILIMessages, ICustomAxiosError, ILIMessages>(
    ['get-li-conversation-messages', entityUrn, conversationId],
    ({ pageParam }) => getLIConversationMessages({ entityUrn, createdBefore: pageParam, conversationId }),
    {
      ...options,
      cacheTime: 0,
      getNextPageParam: (lastPage) => {
        return [...lastPage.messages].pop()?.createdAt;
      },
      enabled: !!conversationId,
      onSuccess: async (data) => {
        options?.onSuccess?.(data);

        const currentConversationId = data?.pages[0].conversationId;

        if (!conversation?.selectedConversation?.conversationId && currentConversationId) {
          dispatch(
            setSelectedConversation({
              ...conversation.selectedConversation,
              conversationId: currentConversationId,
            }),
          );
        }
      },
    },
  );

  useEffect(() => {
    const updateUnreadCount = async () => {
      await queryClient.cancelQueries(queryKey);

      queryClient.setQueriesData<InfiniteData<ILIConversation[]> | undefined>(queryKey, (conversations) => {
        if (conversations) {
          const newPages = conversations.pages.map((results) =>
            // once messages are loaded we have to make unread count to equal 0
            results.map((conv) => (conv.conversationId === conversationId ? { ...conv, unreadCount: 0 } : conv)),
          );

          return { ...conversations, pages: newPages };
        }

        return conversations;
      });

      // after reading one of the conversation we have to invalidate unread query
      if (filterType === LIConversationFilterType.ALL) {
        queryClient.invalidateQueries(['get-li-conversations', LIConversationFilterType.UNREAD]).catch(console.error);
      }
    };

    if (data) {
      updateUnreadCount().catch(console.error);
    }
  }, [data]);

  const messages = useMemo(() => {
    return data?.pages.map((page) => page.messages).flat() || [];
  }, [data, data?.pages[0]?.messages?.length]);

  return { messages, ...rest };
};
