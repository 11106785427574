import dayjs from 'dayjs';
import he from 'he';
import { Fragment, useState } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faClockFour } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { HtmlParser, Spinner, TablePagination, Typography } from 'src/components';
import { DEFAULT_PAGINATION_OPTIONS } from 'src/constants';
import { LeadActivityEnum } from 'src/enums';
import { resolveActionDescription, resolveActionMessage } from 'src/helpers';
import { useGetLeadActivities } from 'src/reactQueries';
import { ITablePagination } from 'src/types';
import CampaignTitle from './CampaignTitle';

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  list-style-type: none;
  width: 100%;
  font-family: 'ProximaSoft', sans-serif !important;
`;

const Divider = styled.hr`
  margin-top: 20px;
  border: 0;
  border-top: 1px solid #e6e8ed;
`;

const VerticalLine = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.gray[100]};
  width: 2px;
`;

const Dot = styled(Box)`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.gray[300]};
  margin: 5px;
`;

const DelimiterWrapper = styled(Box)`
  align-items: center;
  color: ${({ theme }) => theme.palette.violet.dark};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 12px;
  margin-right: 12px;
`;

const MessageWrapper = styled(Box)`
  max-width: 386px;
  overflow-wrap: break-word;
  color: ${({ theme }) => theme.palette.gray['800']};
  margin-top: 8px;
  border-radius: 6px;
  padding: 8px 12px;
  background-color: rgba(242, 243, 247, 0.5);
  border: 1px solid #f2f3f7;
`;

interface ILeadActivitiesProps {
  entityUrn: string;
}

export const LeadActivities = ({ entityUrn }: ILeadActivitiesProps) => {
  const { palette } = useTheme();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(5);

  const { leadActivitiesData, isLoading } = useGetLeadActivities(
    {
      entityUrn,
      page,
      perPage,
    },
    { refetchOnMount: true },
  );
  const { actions = [], total } = leadActivitiesData ?? {};

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (!total) {
    return (
      <Typography color={palette.gray[400]} fontSize="15px" py="48px" textAlign="center">
        No history of activities found for this person.
      </Typography>
    );
  }

  return (
    <Box data-testid="activities">
      <ul>
        {actions?.map((activity, index, array) => {
          const isLast = index === array.length - 1;
          const isFirst = index === 0;
          const { campaignId, campaignName, createdAt, description, id, message, isCampaignRemoved, isLeadRemoved } =
            activity ?? {};
          const sameCampaignAsPrevious = array[index - 1]?.campaignName === campaignName;
          const time = dayjs(createdAt).format('MMMM Do YYYY h:mm a');
          const label = resolveActionDescription(description);
          const parseHtmlTags = [LeadActivityEnum.EMAILED, LeadActivityEnum.EMAIL_OPENED].includes(description);
          const decodedMessage = !!message && he.decode(message);

          return (
            <Fragment key={id}>
              <ListItem>
                <DelimiterWrapper>{!isFirst && <VerticalLine />}</DelimiterWrapper>
                {!!campaignName && !sameCampaignAsPrevious && (
                  <CampaignTitle
                    campaignId={campaignId}
                    campaignName={campaignName}
                    isCampaignRemoved={isCampaignRemoved}
                    isLeadRemoved={isLeadRemoved}
                  />
                )}
              </ListItem>
              <ListItem>
                <DelimiterWrapper>
                  <Dot />
                  {!isLast && <VerticalLine />}
                </DelimiterWrapper>

                <Box flex="auto" paddingBottom="20px">
                  <Box>
                    <Box display="flex" justifyContent="space-between">
                      <Typography fontSize="16px" fontWeight="600">
                        {label}
                      </Typography>
                      <Box fontFamily="ProximaSoft" fontWeight="500" color={palette.violet.dark} fontSize="14px">
                        <FontAwesomeIcon icon={faClockFour} /> {time}
                      </Box>
                    </Box>

                    {decodedMessage && (
                      <MessageWrapper>
                        {parseHtmlTags ? (
                          <HtmlParser parseText={decodedMessage} />
                        ) : (
                          resolveActionMessage(description, decodedMessage)
                        )}
                      </MessageWrapper>
                    )}
                    {!isLast && <Divider />}
                  </Box>
                </Box>
              </ListItem>
            </Fragment>
          );
        })}
      </ul>

      <Box marginBottom="20px">
        <TablePagination
          rowsPerPageOptions={[5, ...DEFAULT_PAGINATION_OPTIONS]}
          page={page}
          perPage={perPage}
          total={total}
          onPaginationChange={paginationChangeHandler}
        />
      </Box>
    </Box>
  );
};
