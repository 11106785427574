import { CSVLink } from 'react-csv';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { CSVTableHeadCell } from './CSVTableHeadCell';

const StyledTable = styled.table`
  width: 100%;
  margin-bottom: 20px;
  background-color: ${({ theme }) => theme.palette.light.light};
  border-collapse: collapse;
  border: 1px solid ${({ theme }) => theme.palette.lightGray.light};

  & th {
    padding: 20px 10px;
    white-space: pre;
    color: ${({ theme }) => theme.palette.primary.wlLight};
    font-family: 'ProximaSoft', sans-serif;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    text-transform: uppercase;
  }
`;

interface ICSVTableProps {
  customSnippets: Record<string, string>;
}

export const CSVSnippetsTable = ({ customSnippets }: ICSVTableProps) => {
  const sortedSnippet = Object.keys(customSnippets).sort((key) => (key === 'linkedin_url' ? -1 : 0));

  return (
    <Box mt="20px">
      <Typography color="gray.dark" mb="20px">
        Please make sure to add below headers to sync with the already added headers{' '}
        <CSVLink filename={`template.csv`} headers={sortedSnippet.map((key) => customSnippets[key])} data={[]}>
          (Download template)
        </CSVLink>
      </Typography>
      <StyledTable>
        <thead>
          <tr>
            {sortedSnippet.map((key) => (
              <th key={`snippet-cell-${key}`}>
                <CSVTableHeadCell name={key} value={customSnippets[key]} disabled />
              </th>
            ))}
          </tr>
        </thead>
      </StyledTable>
    </Box>
  );
};
