import { useEffect, useState } from 'react';
import Select, { components, PropsValue, StylesConfig } from 'react-select';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faCaretDown, faCaretUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { ReactComponent as XMarkIcon } from 'src/assets/icons/x-mark.svg';
import { getMultiSelectStyles } from 'src/components';
import { ISelectOption } from 'src/types';

const RemoveAll = styled(Box)`
  pointer-events: all !important;
  color: #999;
  cursor: pointer;
  font-size: 18px;
  margin: 0 10px;
`;

export interface IMultiSelectProps<T, V> {
  initialValues?: V[];
  options: T[];
  placeholder?: string;
  onChange: (value: T[]) => void;
  stylesConfig?: StylesConfig<ISelectOption<unknown>>;
  cropOptions?: boolean;
}

export const MultiSelect = <V extends ISelectOption<unknown>['value'], T extends ISelectOption<V>>({
  onChange,
  options,
  placeholder = 'Select Category',
  initialValues = [],
  stylesConfig = {},
  cropOptions = false,
}: IMultiSelectProps<T, V>) => {
  const { palette } = useTheme();
  const [selectCampaign, setSelectCampaign] = useState<PropsValue<ISelectOption<unknown>>>(
    options.filter(({ value }) => initialValues.includes(value)),
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (initialValues.length) {
      setSelectCampaign(options.filter(({ value }) => initialValues.includes(value)));
    }
  }, [initialValues.length]);

  const onChangeHandler = (newValue: T[]) => {
    setSelectCampaign(newValue);
    onChange(newValue);
  };

  return (
    <Select
      isMulti
      options={options}
      value={selectCampaign}
      onChange={(data) => onChangeHandler(data as T[])}
      placeholder={placeholder}
      onMenuOpen={() => setIsOpen(true)}
      onMenuClose={() => setIsOpen(false)}
      styles={{
        ...getMultiSelectStyles(palette),
        option: (providedStyles) => ({
          ...providedStyles,
          padding: '10px 20px',
          fontSize: '14px',
          color: palette.text.primary,
          backgroundColor: 'white',
          cursor: 'pointer',
          '&:hover': {
            color: palette.primary.main,
          },
          ...(cropOptions && { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }),
        }),
        ...stylesConfig,
      }}
      components={{
        IndicatorSeparator: null,
        ClearIndicator: (props) => (
          <components.ClearIndicator {...props}>{props.hasValue && <RemoveAll>×</RemoveAll>}</components.ClearIndicator>
        ),
        DropdownIndicator: ({ selectProps, ...otherProps }) => (
          <components.DropdownIndicator selectProps={selectProps} {...otherProps}>
            <FontAwesomeIcon
              icon={isOpen ? faCaretUp : faCaretDown}
              color={isOpen ? '#7a7a7a' : palette.primary.main}
            />
          </components.DropdownIndicator>
        ),
        NoOptionsMessage: (props) => (
          <Box {...props} padding="10px 20px" fontSize="14px" color="#A0A0A0">
            No results found
          </Box>
        ),
        MultiValueRemove: (props) => (
          <components.MultiValueRemove {...props}>
            <XMarkIcon />
          </components.MultiValueRemove>
        ),
        Option: ({ children, ...props }) => (
          <components.Option {...props}>
            <>
              {children}
              {props.isSelected && <FontAwesomeIcon icon={faCheck} color={palette.primary.main} />}
            </>
          </components.Option>
        ),
      }}
    />
  );
};
