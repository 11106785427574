import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { refreshSNSavedSearches } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, ISNSavedSearch } from 'src/types';

export const useRefreshSNSavedSearch = (options?: UseMutationOptions<ISNSavedSearch[], ICustomAxiosError>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation('refresh-sn-saved-search', refreshSNSavedSearches, {
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      dispatch(
        showToast({
          type: 'info',
          message: 'SUCCESSFULLY REFRESHED SAVED SEARCHES',
        }),
      );

      await queryClient.cancelQueries(['get-sn-saved-search']);

      queryClient.setQueryData(['get-sn-saved-search'], () => data);
    },
  });

  return { refreshSNSavedSearch: mutate, ...rest };
};
