import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Toggle } from 'src/components/ui/Toggle';
import { ISequence } from 'src/types';
import { Tags } from '../Tags';

const ToggleBox = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: 20px;
`;

const Label = styled.p`
  color: #333;
  font-size: 14px;
  margin-left: 15px;
`;

const Selector = styled.select`
  margin-left: 10px;
  width: 65px;
  background-color: ${({ theme }) => theme.palette.light.light};
  padding: 7px 10px;
  border-radius: 4px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

interface IEngageWithProfileProps {
  index: number;
  setValue: UseFormSetValue<ISequence>;
  watch: UseFormWatch<ISequence>;
}

export const EngageWithProfile = ({ index, watch, setValue }: IEngageWithProfileProps) => {
  const options = Array.from({ length: 5 }).map((_v, i) => (
    <option key={i} value={i + 1}>
      {i + 1}
    </option>
  ));

  return (
    <>
      <ToggleBox>
        <Toggle checked={watch(`sequence.${index}.view`)} name={`sequence.${index}.view`} setValue={setValue} />
        <Label>View LinkedIn Profile</Label>
      </ToggleBox>

      <ToggleBox>
        <Toggle
          checked={watch(`sequence.${index}.auto_follow`)}
          name={`sequence.${index}.auto_follow`}
          setValue={setValue}
        />
        <Label>Auto Follow on LinkedIn</Label>
      </ToggleBox>

      <ToggleBox>
        <Toggle
          checked={watch(`sequence.${index}.auto_endorse`)}
          name={`sequence.${index}.auto_endorse`}
          setValue={setValue}
        />
        <Label>Endorse top skills (1st degree connections only)</Label>
        <Selector
          value={watch(`sequence.${index}.endorsements_count`)}
          onChange={(e) => setValue(`sequence.${index}.endorsements_count`, Number(e.target.value))}
        >
          {options}
        </Selector>
      </ToggleBox>

      <ToggleBox>
        <Toggle
          checked={watch(`sequence.${index}.auto_post_like`)}
          name={`sequence.${index}.auto_post_like`}
          setValue={setValue}
        />
        <Label>Like recent LinkedIn posts</Label>
        <Selector
          value={watch(`sequence.${index}.posts_count`)}
          onChange={(e) => setValue(`sequence.${index}.posts_count`, Number(e.target.value))}
        >
          {options}
        </Selector>
      </ToggleBox>

      <Tags index={index} watch={watch} setValue={setValue} />
    </>
  );
};
