import dayjs from 'dayjs';
import { useQuery } from 'react-query';

import { postUsersBots } from 'src/api';
import { useAppSelector } from 'src/hooks';

export const usePostUsersBots = () => {
  const { isAuth, profile } = useAppSelector((state) => state.user);

  useQuery(['post-users-bots'], () => postUsersBots(dayjs.tz.guess() || 'America/Los_Angeles'), {
    enabled: isAuth && !!profile.isEmailVerified,
    staleTime: Infinity,
    retry: false,
  });
};
