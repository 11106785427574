import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Spinner, Typography } from 'src/components';
import { TeamManage } from 'src/containers/Team';
import { BillingTypeEnum, CurrencyEnum, Plan } from 'src/enums';
import { useGetTeamCount, useTeamPlan } from 'src/hooks';
import { useGetBillingInfo } from 'src/reactQueries';
import { BillingCycleSelector } from './BillingCycleSelector';
import { CurrencySelector } from './CurrencySelector';
import { SubscriptionPlanCard } from './SubscriptionPlanCard';
import { SubscriptionPlansLayout } from './SubscriptionPlansLayout';
import { SupportBanner } from './SupportBanner';

const RegularWrapper = styled(Box)`
  margin-bottom: 24px;
  border-radius: 4px;
`;

const WhiteWrapper = styled(RegularWrapper)`
  padding: 20px;
  background-color: #ffffff;
`;

export const SubscriptionPlans = () => {
  const { hash } = useLocation();

  const [billingType, setBillingType] = useState<BillingTypeEnum>();
  const [currency, setCurrency] = useState<CurrencyEnum>();

  const { isTrial, isDeactivated } = useTeamPlan();
  const teamCount = useGetTeamCount();

  const { billingInfo, isLoading } = useGetBillingInfo();

  const plans = useMemo<Array<Plan>>(() => {
    const { plan } = billingInfo || {};

    return [
      plan === Plan.STARTER && Plan.STARTER,
      plan === Plan.PERSONAL && Plan.PERSONAL,
      Plan.INDIVIDUAL,
      Plan.BUSINESS,
      plan === Plan.ENTERPRISE && Plan.ENTERPRISE,
      Plan.AGENCY,
    ].filter(Boolean) as Array<Plan>;
  }, [billingInfo]);

  useEffect(() => {
    if (billingInfo) {
      if (!billingType) {
        setBillingType(
          isTrial || isDeactivated ? BillingTypeEnum.QUARTERLY : billingInfo.billingType || BillingTypeEnum.QUARTERLY,
        );
      }

      if (!currency) {
        setCurrency(isTrial || isDeactivated ? CurrencyEnum.USD : billingInfo.currency || CurrencyEnum.USD);
      }
    }
  }, [billingInfo]);

  useEffect(() => {
    const element = document.getElementById(hash.slice(1));

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  const Wrapper = isTrial || isDeactivated ? WhiteWrapper : RegularWrapper;

  const resolveWrapperDescription = () => {
    if (isTrial || isDeactivated) {
      return (
        <>
          <Typography fontSize="22px" semibold color="gray.800" lineHeight="22px" mb="8px">
            {isTrial && 'Upgrade today to keep your campaigns running and gain access to exclusive features'}
            {isDeactivated && 'To continue using Alfred, please subscribe.'}
          </Typography>
          <Typography fontSize="15px" color="gray.800" lineHeight="22px" mb="24px">
            We hope you've enjoyed your trial period with Meet Alfred! Upgrade now to unlock all the amazing benefits
            our plans have to offer.
          </Typography>
        </>
      );
    }

    return null;
  };

  return (
    <>
      <SubscriptionPlansLayout />

      <Box p="20px 16px">
        <Wrapper mb="24px">
          {resolveWrapperDescription()}

          <Box display="flex" justifyContent="space-between" mb="24px">
            <BillingCycleSelector billingType={billingType} setBillingType={setBillingType} />
            {(isTrial || isDeactivated) && <CurrencySelector currency={currency} setCurrency={setCurrency} />}
          </Box>

          <Box display="flex" gap="16px">
            {plans.map((plan) => (
              <SubscriptionPlanCard
                key={plan}
                plan={plan}
                billingType={billingType as BillingTypeEnum}
                currency={currency as CurrencyEnum}
              />
            ))}
          </Box>
        </Wrapper>

        {isDeactivated && teamCount > 1 && (
          <Box id="team" p="20px 5px" bgcolor="#fff" borderRadius="4px" mb="24px">
            <Typography fontSize="20px" semibold color="gray.800" px="15px" mb="24px">
              Your team members
            </Typography>

            <TeamManage tableOnly tablesWrapperProps={{ pb: '0' }} />
          </Box>
        )}

        <SupportBanner />
      </Box>
    </>
  );
};
