import { FunctionComponent, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { ReactComponent as AnalyticsIcon } from 'src/assets/sidebar-icons/analytics.svg';
import { ReactComponent as AutomateIcon } from 'src/assets/sidebar-icons/automate.svg';
import { ReactComponent as CommunicateIcon } from 'src/assets/sidebar-icons/communicate.svg';
import { ReactComponent as HelpIcon } from 'src/assets/sidebar-icons/help.svg';
import { ReactComponent as ProspectIcon } from 'src/assets/sidebar-icons/prospects.svg';
import { ReactComponent as SettingsIcon } from 'src/assets/sidebar-icons/settings.svg';
import { ReactComponent as TemplatesIcon } from 'src/assets/sidebar-icons/templates.svg';
import { CampaignCategory, FeatureFlagEnum, Features, TeamUserRole } from 'src/enums';
import { useAppSelector, useFeatureFlag, useWhiteLabel } from 'src/hooks';
import { useGetBillingInfo, useGetUserAccount } from 'src/reactQueries';
import { LANDING_URL } from '../constants';
import { useTeamPlan } from './useTeamPlan';

interface ISectionConfig {
  show?: boolean;
  icon: FunctionComponent;
  id?: string;
  title?: string;
  tabs: {
    title: string;
    id?: string;
    to: string;
    show?: boolean;
    external?: boolean;
    activeRoutes?: string[];
  }[];
}

export const useGetNavigationConfig = () => {
  const location = useLocation();

  const { userMe } = useGetUserAccount();
  const { whitelabel } = useWhiteLabel();

  const { teamUser } = useAppSelector((state) => state.team.data) ?? {};
  const userRole = teamUser?.role ?? TeamUserRole.MEMBER;
  const isGroup = !!teamUser?.groupId;
  const isOwner = userRole === 'owner';
  const isAdmin = userRole === 'admin';

  const { checkFeature, isTrial } = useTeamPlan();
  const { billingInfo } = useGetBillingInfo();

  const isTeamShown = checkFeature(Features.teamStats) && (isOwner || isAdmin);
  const isTeamGroupsShown = checkFeature(Features.teamGroups) && isOwner;
  const isManageGroupShown = checkFeature(Features.teamGroup) && isAdmin && isGroup;
  const isManageTeamShown = checkFeature(Features.manageTeam) && (isOwner || (isAdmin && !isGroup));
  const isTeamTemplatesShow = checkFeature(Features.templatesTeamAndLibrary);
  const isLibraryTemplatesShow = checkFeature(Features.templatesTeamAndLibrary);
  const isTeamSettingsShown = checkFeature(Features.teamSettings) && isOwner;
  const isBillingShown = isOwner && !whitelabel.isWhiteLabelUser;
  const isWhiteLabelShown = userMe?.isAlfredAdmin || (!whitelabel.isWhiteLabelUser && isOwner);
  const isReferFriendShown =
    whitelabel.isAlfredDomain || userMe?.isAlfredAdmin || (whitelabel.isWhiteLabelUser && isOwner);
  const isHelpShown = userMe?.isAlfredAdmin || isOwner || !whitelabel.isWhiteLabelUser;
  const isGreetingsShown = checkFeature(Features.greetings);
  const isSequenceTemplateShown = checkFeature(Features.sequenceTemplates);
  const isEditPlanShown = isOwner && billingInfo?.canUpdateSeats;

  const [isUpdatedSubscriptionFlowEnabled] = useFeatureFlag(FeatureFlagEnum.updatedSubscriptionFlow);

  const navigationConfig: ISectionConfig[] = [
    {
      icon: ProspectIcon,
      id: 'nav_crm',
      title: 'CRM',
      tabs: [
        { to: '/contacts/connections', title: 'Connections', id: 'sub_connections' },
        { to: '/contacts/leads', title: 'Leads', id: 'sub_leads' },
        { to: '/contacts/blacklist', title: 'Excluded', id: 'sub_blacklist' },
        { to: '/invitations', title: 'Invitations', id: 'sub_invitations' },
        { to: '/tags', title: 'Tag Manager', id: 'sub_tags' },
      ],
    },
    {
      icon: AutomateIcon,
      id: 'nav_campaigns',
      title: 'Campaigns',
      tabs: [
        { to: `/campaign/stats/${CampaignCategory.LINKEDIN}`, title: 'LinkedIn', id: 'sub_linkedin' },
        { to: `/campaign/stats/${CampaignCategory.EMAIL}`, title: 'Email', id: 'sub_email' },
        { to: `/campaign/stats/${CampaignCategory.TWITTER}`, title: 'X (Twitter)', id: 'sub_twitter' },
        { to: `/campaign/stats/${CampaignCategory.MULTICHANNEL}`, title: 'Multi-Channel', id: 'sub_multi' },
        { to: `/campaign/stats/${CampaignCategory.INMAIL}`, title: 'InMail', id: 'sub_inmail' },
        { to: `/campaign/stats/${CampaignCategory.GROUP}`, title: 'Groups', id: 'sub_groups' },
        { to: `/campaign/stats/${CampaignCategory.EVENT}`, title: 'Events', id: 'sub_events' },
        { to: `/campaign/stats/${CampaignCategory.POST}`, title: 'Posts', id: 'sub_posts' },
        { to: `/settings/greetings`, title: 'Greetings', show: checkFeature(Features.greetings), id: 'sub_greetings' },
      ],
    },
    {
      icon: CommunicateIcon,
      id: 'nav_conversations',
      title: 'Conversations',
      tabs: [
        { to: '/campaign/replies', title: 'Replies', id: 'sub_replies' },
        { to: '/inbox', title: 'Inbox', id: 'sub_inbox' },
        { to: '/posts', title: 'Schedule Posts', id: 'sub_posts' },
        { to: '/trends', title: 'Trending Topics', id: 'sub_trends' },
      ],
    },
    {
      icon: AnalyticsIcon,
      id: 'nav_analytics',
      title: 'Analytics',
      tabs: [
        { to: '/dashboard', title: 'Dashboard', id: 'sub_dashboard' },
        { to: '/campaign/activity', title: 'Activity', id: 'sub_activity' },
        { to: '/team/stats', title: 'Team Stats', show: isTeamShown, id: 'sub_team_stats' },
      ],
    },
    {
      icon: TemplatesIcon,
      id: 'nav_templates',
      title: 'Templates',
      tabs: [
        { to: '/templates', title: 'Messages', id: 'sub_templates' },
        { to: '/sequence-templates', title: 'Campaigns', id: 'sub_sequence_templates' },
      ],
    },
    {
      icon: SettingsIcon,
      id: 'nav_settings',
      tabs: [
        { to: '/settings/user-profile', title: 'User Profile', id: 'sub_user_profile' },
        {
          to:
            (!isUpdatedSubscriptionFlowEnabled && '/billing') ||
            (isTrial ? '/billing/subscription/plans' : '/billing/subscription'),
          title: 'Billing',
          show: isBillingShown,
          id: 'sub_billing',
          activeRoutes: ['/billing'],
        },
        { to: '/integrations', title: 'Integrations', id: 'sub_integrations' },
        { to: '/settings/work-hours', title: 'Work Hours', id: 'sub_work_hours' },
        { to: '/settings/daily-limits', title: 'Daily Limits', id: 'sub_daily_limit' },
        { to: '/settings/auto-withdrawals', title: 'Auto Withdrawal', id: 'sub_auto_withdrawal' },
        {
          to: '/team/manage-team',
          id: 'sub_team',
          title: 'Team',
          show: isTeamShown,
          activeRoutes: ['/team/manage-team', '/team/manage-group', '/team/groups', '/team/settings'],
        },
        {
          to: '/settings/white-label',
          id: 'sub_white_label',
          title: 'White Label',
          show: isWhiteLabelShown,
        },
        {
          to: '/settings/refer-friend',
          id: 'sub_refer',
          title: 'Refer & Save',
          show: isReferFriendShown,
        },
      ],
    },
    {
      icon: HelpIcon,
      id: 'nav_help',
      show: isHelpShown,
      tabs: [
        { to: `${LANDING_URL}/help/`, title: 'Articles', id: 'sub_articles', external: true },
        { to: `${LANDING_URL}/resources/videos/`, title: 'Videos', id: 'sub_articles', external: true },
        { to: `${LANDING_URL}/guides/`, title: 'Guides', id: 'sub_articles', external: true },
        { to: `${LANDING_URL}/resources/blog/`, title: 'Blog', id: 'sub_articles', external: true },
      ],
    },
  ];

  const defineActive = useCallback(() => {
    return navigationConfig.findIndex((section) =>
      section.tabs.find(
        ({ to, activeRoutes = [] }) =>
          new RegExp(to).test(location.pathname) ||
          activeRoutes.some((route) => new RegExp(route).test(location.pathname)),
      ),
    );
  }, [location.pathname]);

  return {
    navigationConfig,
    defineActive,

    isTrial,

    isTeamShown,
    isTeamGroupsShown,
    isManageGroupShown,
    isManageTeamShown,
    isTeamSettingsShown,
    isTeamTemplatesShow,
    isLibraryTemplatesShow,
    isBillingShown,
    isWhiteLabelShown,
    isReferFriendShown,
    isGreetingsShown,
    isSequenceTemplateShown,
    isEditPlanShown,
  };
};
