import { useMutation, UseMutationOptions } from 'react-query';

import { getMicrosoftCodeUrl } from 'src/api';
import { ICustomAxiosError } from 'src/types';

export const useGetMicrosoftCodeUrl = (redirectTo: string, options?: UseMutationOptions<string, ICustomAxiosError>) => {
  const { mutate, ...rest } = useMutation('get-microsoft-code-url', () => getMicrosoftCodeUrl(redirectTo), {
    ...options,
    onSuccess: (data, variables, context) => {
      document.location.href = data;

      options?.onSuccess?.(data, variables, context);
    },
  });

  return {
    getMicrosoftCodeUrl: mutate,
    ...rest,
  };
};
