import { PropsWithChildren, useEffect } from 'react';

import { useTeamPlan } from 'src/hooks';

const HOTJAR_SCRIPT_ID = 'hotjar-analytics-script';

export const HotjarProvider = ({ children }: PropsWithChildren) => {
  const { isTrial, isTrialStarted } = useTeamPlan();

  useEffect(() => {
    let script = document.getElementById(HOTJAR_SCRIPT_ID);

    if (isTrial && isTrialStarted) {
      if (!script) {
        script = document.createElement('script');

        script.id = HOTJAR_SCRIPT_ID;
        script.innerHTML = `
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:${process.env.REACT_APP_NODE_ENV === 'production' ? '4992682' : '4974845'},hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `;

        console.log(`[HOTJAR] Hotjar script added to the page.`);
        document.head.appendChild(script);
      }
    } else if (script) {
      console.log(`[HOTJAR] Hotjar script removed from the page.`);
      document.head.removeChild(script);
    }
  }, [isTrial, isTrialStarted]);

  return <>{children}</>;
};
