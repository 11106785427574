import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateWhiteLabelBranding } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IUpdateBrandingReq, IWhiteLabel } from 'src/types';

export const useUpdateWhiteLabelBranding = (
  options?: UseMutationOptions<IWhiteLabel, ICustomAxiosError, IUpdateBrandingReq>,
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const getWhiteLabelQueryKey = ['get-whitelabel'];
  const { mutate, ...rest } = useMutation(['update-whitelabel-branding'], updateWhiteLabelBranding, {
    ...options,
    onMutate: async (variables) => {
      options?.onMutate?.(variables);
      const { companyName, favicon, logo, logoIcon, ...colors } = variables;

      await queryClient.cancelQueries(getWhiteLabelQueryKey);

      const prevData = queryClient.getQueryData(getWhiteLabelQueryKey);

      queryClient.setQueryData<IWhiteLabel | undefined>(getWhiteLabelQueryKey, (data) => {
        if (data) {
          return {
            ...data,
            companyName,
            favicon: favicon ? URL.createObjectURL(favicon) : data.favicon,
            logo: logo ? URL.createObjectURL(logo) : data.logo,
            logoIcon: logoIcon ? URL.createObjectURL(logoIcon) : data.logoIcon,
            theme: colors,
          };
        }

        return data;
      });

      return { prevData };
    },
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      await queryClient.invalidateQueries(getWhiteLabelQueryKey);

      dispatch(
        showToast({
          type: 'success',
          message: 'Branding updated successfully',
          autoCloseTime: 3000,
        }),
      );
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      queryClient.setQueryData(getWhiteLabelQueryKey, context?.prevData);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { updateBranding: mutate, ...rest };
};
