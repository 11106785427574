import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { HeaderButton, HeaderContainer, HeaderDescription, HeaderTitle, HeaderVideo, Link } from 'src/components';
import { ModalTypes } from 'src/enums';
import { useAppSelector, useTeamPlan } from 'src/hooks';
import { useGetBillingInfo } from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';

interface IBillingLayoutProps {
  isEditPlan?: boolean;
  hideActions?: boolean;
}

export const BillingLayout = ({ isEditPlan = false, hideActions = false }: IBillingLayoutProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAppPaused } = useAppSelector((state) => state.user);

  const { billingInfo } = useGetBillingInfo();
  const { isTrial } = useTeamPlan();

  const openUpdateCreditCard = () => {
    dispatch(openModal({ closable: false, type: ModalTypes.UPDATE_CREDIT_CARD }));
  };

  const navigateToEditPlan = () => {
    navigate('/billing/edit');
  };

  return (
    <HeaderContainer>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <HeaderTitle>{isEditPlan ? 'Edit Plan' : 'Billing'}</HeaderTitle>
        <Box display="flex" gap="20px">
          {billingInfo &&
            (!!billingInfo.stripeTokenObject || billingInfo.hasLifetimeDeal) &&
            !isEditPlan &&
            !hideActions &&
            !isAppPaused && (
              <>
                <HeaderButton onClick={openUpdateCreditCard}>Update Credit Card</HeaderButton>
                {billingInfo?.canUpdateSeats && (
                  <HeaderButton
                    onClick={navigateToEditPlan}
                    disabled={!isTrial && !!billingInfo?.stripeSubscriptionObject?.cancel_at_period_end}
                  >
                    Edit plan
                  </HeaderButton>
                )}
              </>
            )}
        </Box>
      </Box>
      <HeaderDescription>
        Manage and modify your billing information, plan selection, and the number of seats used.
        <Link leftIcon underline external openNewTab to="https://help.meetalfred.com/en/collections/3130855-billing">
          Step by Step Guides
        </Link>
        <HeaderVideo src="https://player.vimeo.com/video/871911902" />
      </HeaderDescription>
    </HeaderContainer>
  );
};
