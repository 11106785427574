import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getAllCampaignLeads } from 'src/api';
import { IGetLeadsRequest, IGetLeadsResponse } from 'src/types';

export const useGetCampaignLeads = <TData = IGetLeadsResponse>(
  campaignId: number,
  params: IGetLeadsRequest,
  options?: UseQueryOptions<IGetLeadsResponse, AxiosError, TData>,
) => {
  return useQuery<IGetLeadsResponse, AxiosError, TData>(
    ['get-campaign-leads', campaignId, JSON.stringify(params)],
    () => getAllCampaignLeads(campaignId, params),
    options,
  );
};
