import { useQuery, UseQueryOptions } from 'react-query';

import { getLeadInfo } from 'src/api';
import { ICustomAxiosError, ILeadInfo } from 'src/types';

export const useGetLeadInfo = (
  entityUrn: string,
  objectUrn?: string,
  options?: UseQueryOptions<ILeadInfo, ICustomAxiosError>,
) => {
  const { data, isLoading, ...rest } = useQuery<ILeadInfo, ICustomAxiosError>(
    ['get-lead-info', entityUrn],
    () => getLeadInfo(entityUrn, objectUrn),
    {
      refetchOnMount: true,
      enabled: entityUrn !== 'UNKNOWN' && !!entityUrn,
      ...options,
    },
  );

  return { leadInfo: data, isLeadLoading: isLoading, ...rest };
};
