import { KeyboardEvent, MouseEvent, UIEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faAngleDoubleDown, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { ActionButton, Input, Menu, Spinner, Typography } from 'src/components';
import { MenuItem } from 'src/components/ui/Menu';
import { LIConversationFilterType } from 'src/enums';
import { useAppSelector, useDebounce } from 'src/hooks';
import {
  useGetLIConversations,
  useGetRealUser,
  useGetRepliesConnections,
  useGetTaggedConnections,
} from 'src/reactQueries';
import { setFilterType, setQuery, setSelectedConversation } from 'src/store/conversation.slice';
import { ILIConversation } from 'src/types';
import { Conversation } from './Conversation';
import { FilterByTag } from './FilterByTag';

const Wrapper = styled(Box)`
  padding: 10px 0 50px;
  width: 25%;
  border-right: 1px solid ${({ theme }) => theme.palette.light.light};
  min-height: 400px;
  flex-shrink: 0;
`;

const StyledInput = styled(Input)`
  margin-top: 0;
  height: 32px;
`;

const StyledMenuItem = styled(MenuItem)<{ chosen: number }>`
  transition: 0.2s;
  width: 140px;
  color: ${({ chosen, theme }) => (chosen ? theme.palette.primary.main : theme.palette.text.primary)};

  &.Mui-focusVisible {
    background-color: transparent;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => theme.palette.light.main};
  }

  &::after {
    content: '${(props) => (props.chosen ? '\\2713' : '')}';
    margin-left: auto;
    color: ${({ theme }) => theme.palette.primary.main};
    font-weight: bolder;
  }
`;

const AppliedTag = styled.span`
  right: -8px;
  bottom: -8px;
  position: absolute;
  background: #002f52;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  color: #fff;
  border: 1px solid #fff;
  box-shadow: 0 0 2px #002f52;
  line-height: 20px;
  text-align: center;
  font-size: 14px;
`;

const filterTypes = [
  { name: 'All messages', type: LIConversationFilterType.ALL },
  { name: 'Tagged', type: LIConversationFilterType.TAGGED },
  { name: 'Archived', type: LIConversationFilterType.ARCHIVED },
  { name: 'Unread', type: LIConversationFilterType.UNREAD },
  { name: 'Replies', type: LIConversationFilterType.REPLIES },
];

const SCROLL_LIST_LENGTH = 6;

export const ConversationsList = () => {
  const dispatch = useDispatch();
  const { realImpersonateUser } = useGetRealUser();

  const { isImpersonate } = useAppSelector((state) => state.user);
  const { selectedConversation, query, filterType, selectedTags, userConversationId } = useAppSelector(
    (state) => state.conversation,
  );

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();
  const [queryValue, setQueryValue] = useState<string>(query);

  const {
    conversations,
    isLoading,
    isFetchingNextPage,
    fetchNextPage: fetchNextConversations,
  } = useGetLIConversations({
    query,
    filterType,
  });

  const handleConversation = (conversation: ILIConversation | undefined) => {
    if (!conversation) {
      return;
    }
    dispatch(setSelectedConversation(conversation));
  };

  useEffect(() => {
    if (selectedConversation) {
      const userConversation = conversations.find(
        (conversation) => conversation.entityUrn === selectedConversation.conversationId,
      );
      handleConversation(userConversation);
    }
  }, [userConversationId, selectedConversation, conversations, isLoading]);

  const debouncedSearch = useDebounce(queryValue, 500);

  useEffect(() => {
    dispatch(setQuery(debouncedSearch));
  }, [debouncedSearch]);

  const {
    taggedConnections,
    isLoadingTagged,
    isFetchingNextPageTagged,
    fetchNextPage: fetchNextTagged,
  } = useGetTaggedConnections(
    {
      selectedTags,
    },
    { enabled: filterType === LIConversationFilterType.TAGGED },
  );

  const { repliesConnections, isLoadingReplies, isFetchingNextPageReplies, fetchNextReplies } =
    useGetRepliesConnections(
      {
        query,
      },
      { enabled: filterType === LIConversationFilterType.REPLIES },
    );

  const isInmail = filterType === LIConversationFilterType.INMAIL;
  const isRepliesType = filterType === LIConversationFilterType.REPLIES;
  const isTaggedType = filterType === LIConversationFilterType.TAGGED;
  const inmailSearchPlaceholder = isInmail ? 'inmail' : 'Linkedin';
  const searchPlaceholderText = isRepliesType ? 'replies' : inmailSearchPlaceholder;

  const repliedConversation = isRepliesType && repliesConnections;
  const taggedConversation = isTaggedType && taggedConnections;
  const list: ILIConversation[] = repliedConversation || taggedConversation || conversations;

  // preselecting first conversation
  useEffect(() => {
    if (selectedConversation) return;

    if (!isImpersonate || (isImpersonate && !realImpersonateUser?.isAdminImpersonate)) {
      if (list.length) {
        handleConversation(list[0]);
      }
    }
  }, [list.length, selectedConversation?.conversationId]);

  const handleOpenMenu = (e: MouseEvent<HTMLDivElement>) => setAnchorEl(e.currentTarget);
  const handleCloseMenu = () => setAnchorEl(null);

  const setFilter = (option: LIConversationFilterType) => {
    dispatch(setFilterType(option));
    dispatch(setSelectedConversation(null));
    handleCloseMenu();
  };

  const handler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      dispatch(setQuery(e.currentTarget.value));
    }
  };

  const clearQuery = () => {
    setQueryValue('');
    dispatch(setQuery(''));
  };

  const handleScrollPagination = async (e: UIEvent<HTMLDivElement>) => {
    if (
      e.currentTarget.scrollTop !== 0 &&
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.offsetHeight < 30
    ) {
      if (isTaggedType) {
        if (!isFetchingNextPageTagged) {
          fetchNextTagged().catch(console.error);
        }
      } else if (isRepliesType) {
        if (!isFetchingNextPageReplies) {
          fetchNextReplies().catch(console.error);
        }
      } else if (!isFetchingNextPage) {
        fetchNextConversations().catch(console.error);
      }
    }
  };

  return (
    <Wrapper>
      <Box display="flex" mb="10px" px="10px" gap="10px">
        {isTaggedType ? (
          <FilterByTag />
        ) : (
          <StyledInput
            type="text"
            placeholder={`Search messages in ${searchPlaceholderText}`}
            name="search"
            value={queryValue}
            onChange={(e) => setQueryValue(e.target.value)}
            onKeyUp={handler}
            removeAllAction={!!queryValue && clearQuery}
          />
        )}

        {!isInmail && (
          <Box position="relative">
            <ActionButton flexShrink="0" ml="10px" icon={faEllipsisH} onClick={handleOpenMenu} />
            {!!filterType && <AppliedTag>1</AppliedTag>}
            <Menu
              open={!!anchorEl}
              anchorEl={anchorEl}
              onClose={handleCloseMenu}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              {filterTypes.map((filter) => (
                <StyledMenuItem
                  key={filter.type}
                  chosen={filter.type === filterType ? 1 : 0}
                  onClick={() => setFilter(filter.type)}
                >
                  {filter.name}
                </StyledMenuItem>
              ))}
            </Menu>
          </Box>
        )}
      </Box>

      {!list.length ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%" px="20px">
          {isLoading || isLoadingTagged || isLoadingReplies ? (
            <Spinner />
          ) : (
            <Typography>We couldn’t find any conversations with this filter.</Typography>
          )}
        </Box>
      ) : (
        <Box height="calc(100% - 70px)" overflow="auto" px="10px" onScroll={handleScrollPagination}>
          {list.map((conversation) => (
            <Conversation
              key={`${conversation?.entityUrn}-${conversation?.conversationId}`}
              conversation={conversation}
              filterType={filterType}
              handleConversation={handleConversation}
              selectedConversation={selectedConversation}
            />
          ))}
        </Box>
      )}

      {list.length > SCROLL_LIST_LENGTH && (
        <Box display="flex" pt="10px" justifyContent="center" alignItems="center">
          <FontAwesomeIcon icon={faAngleDoubleDown} />
          <Typography ml="10px">Scroll down to see more</Typography>
        </Box>
      )}
    </Wrapper>
  );
};
