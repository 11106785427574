import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import notFoundImg from 'src/assets/img/not-found.png';
import { AppLayout } from 'src/layouts';

const Wrapper = styled(Box)`
  padding-top: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: radial-gradient(circle, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
`;

const Title = styled.h3`
  font-family: 'ProximaSoft', sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  margin-top: 50px;
  margin-bottom: 30px;
`;

const BackButton = styled.p`
  cursor: pointer;
  border-radius: 4px;
  color: #fff;
  border: 0;
  text-align: center;
  font-weight: 400;
  background: ${({ theme }) => theme.palette.primary.main};
  font-family: 'ProximaSoft', sans-serif;
  letter-spacing: 0.5px;
  height: 60px;
  width: 200px;
  font-size: 14px;
  line-height: 56px;
`;

export const NotFound = () => {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <AppLayout>
      <Wrapper>
        <img width="300px" src={notFoundImg} alt="not found img" />
        <Title>Oops! The page you were looking for doesn't exist!</Title>
        <BackButton onClick={goBack}>Go back</BackButton>
      </Wrapper>
    </AppLayout>
  );
};
