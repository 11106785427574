import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { deleteTag } from 'src/api';
import { closeModal } from 'src/store/modal.slice';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, ITag } from 'src/types';

export const useDeleteTag = (
  tagId: number,
  options?: UseMutationOptions<void, ICustomAxiosError, void, { tagsData: unknown; allTagsData: unknown }>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const getTagsKey = ['get-tags'];
  const getAllTagsKey = ['get-all-tags'];

  const { mutate } = useMutation<void, ICustomAxiosError, void, { tagsData: unknown; allTagsData: unknown }>(
    ['delete-tag', tagId],
    () => deleteTag(tagId),
    {
      ...options,
      onMutate: async (variables) => {
        options?.onMutate?.(variables);
        await queryClient.cancelQueries(getTagsKey);
        await queryClient.cancelQueries(getAllTagsKey);

        const tagsData = queryClient.getQueryData(getTagsKey);
        const allTagsData = queryClient.getQueryData(getAllTagsKey);

        queryClient.setQueryData<ITag[] | undefined>(getTagsKey, (items) => items?.filter((item) => item.id !== tagId));

        queryClient.setQueryData<ITag[] | undefined>(getAllTagsKey, (tags) => {
          if (tags) {
            return tags.filter((item) => item.id !== tagId);
          }

          return tags;
        });

        return { tagsData, allTagsData };
      },
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        queryClient.invalidateQueries(getAllTagsKey, { refetchInactive: true }, {}).catch(console.error);
        dispatch(closeModal());
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueryData(getTagsKey, context?.tagsData);
        queryClient.setQueryData(getAllTagsKey, context?.allTagsData);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(getTagsKey);
      },
    },
  );

  return { deleteTag: mutate };
};
