import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import {
  HeaderContainer,
  HeaderDescription,
  HeaderTabLink,
  HeaderTabsContainer,
  HeaderTitle,
  HeaderVideo,
  Link,
  Toggle,
  Tooltip,
  Typography,
} from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features, TeamUserRole } from 'src/enums';
import { useTeamPlan, useWhiteLabel } from 'src/hooks';
import {
  useGetTeamById,
  useGetUserAccount,
  useGetUserPreferences,
  useToggleIntegrationNotification,
  useToggleMemberInbox,
  useToggleMemberLeads,
} from 'src/reactQueries';
import { Title, Wrapper } from './_components';

export const TeamSettings = () => {
  const { userPreferences } = useGetUserPreferences();
  const { excludeMemberLeads = false, enableMembersInbox = false } = userPreferences ?? {};

  const { whitelabel } = useWhiteLabel();
  const { checkFeature, isTrial, isBusiness, isBusinessV2 } = useTeamPlan();

  const { userMe } = useGetUserAccount();
  const { teamData } = useGetTeamById({
    teamId: userMe?.lastTeamId ?? -1,
    enabled: !!userMe?.lastTeamId,
  });
  const { sendDisconnectedEmailsToOwners = false, sendDisconnectedEmailsToAdmins = false } = teamData ?? {};

  const { toggleIntegrationNotification } = useToggleIntegrationNotification(userMe?.lastTeamId ?? -1);
  const { toggleMemberLeads } = useToggleMemberLeads();
  const { toggleMemberInbox } = useToggleMemberInbox();

  const toggleShareLeads = () => toggleMemberLeads();
  const toggleViewInbox = () => toggleMemberInbox();
  const toggleEmailOwners = () => {
    toggleIntegrationNotification({
      sendToOwners: !sendDisconnectedEmailsToOwners,
      sendToAdmins: Boolean(sendDisconnectedEmailsToAdmins),
    });
  };
  const toggleEmailAdmins = () => {
    toggleIntegrationNotification({
      sendToOwners: Boolean(sendDisconnectedEmailsToOwners),
      sendToAdmins: !sendDisconnectedEmailsToAdmins,
    });
  };

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Team Settings</HeaderTitle>
        <HeaderDescription>
          Enable system email notifications and exclusive permissions to enhance team collaboration for your entire
          team.
          {whitelabel.isWhiteLabelUser || (
            <>
              <Link
                leftIcon
                underline
                external
                openNewTab
                to="https://help.meetalfred.com/en/articles/6556518-advanced-team-settings"
              >
                Learn how it works
              </Link>
              <HeaderVideo src="https://player.vimeo.com/video/871572207" />
            </>
          )}
        </HeaderDescription>
        {teamData?.teamUser?.role === TeamUserRole.OWNER && (
          <HeaderTabsContainer>
            {checkFeature(Features.teamMembers) && <HeaderTabLink to="/team/manage-team">Members</HeaderTabLink>}
            {checkFeature(Features.teamGroups) && (
              <HeaderTabLink
                to="/team/groups"
                disabled={isTrial || isBusiness}
                tooltip={(isTrial || isBusiness) && PLAN_TOOLTIPS.agency}
              >
                Groups
              </HeaderTabLink>
            )}
            {checkFeature(Features.teamSettings) && (
              <HeaderTabLink
                to="/team/settings"
                disabled={isTrial || isBusinessV2}
                tooltip={(isTrial || isBusinessV2) && PLAN_TOOLTIPS.agency}
              >
                Team Settings
              </HeaderTabLink>
            )}
          </HeaderTabsContainer>
        )}
      </HeaderContainer>
      <Wrapper maxWidth="46%">
        <Title>Team Settings</Title>
        <Typography>Adjust permissions across your team.</Typography>
        <Box mt="20px">
          <Box display="flex" alignItems="center">
            <Toggle data-testid="excludeMemberLeads" checked={!excludeMemberLeads} onChange={toggleShareLeads} />
            <Typography mr="3px" ml="10px">
              Share leads with team members.
            </Typography>
            <Tooltip
              placement="bottom"
              title="When this option is enabled, members of your team will be able to connect and send messages to the same lead. Disable it if you don't want to members of your team messaging the same lead"
            >
              <FontAwesomeIcon size="sm" icon={faQuestionCircle} />
            </Tooltip>
          </Box>
          <Box display="flex" mt="10px" alignItems="center">
            <Toggle data-testid="enableMembersInbox" checked={enableMembersInbox} onChange={toggleViewInbox} />
            <Typography mr="3px" ml="10px">
              Allow viewing inbox when impersonating.
            </Typography>
            <Tooltip
              placement="bottom"
              title="When this option is enabled, admins and owners will have access to other member's inbox while impersonating them. Disable it if you don't want admins and owners to see the inbox of other members"
            >
              <FontAwesomeIcon size="sm" icon={faQuestionCircle} />
            </Tooltip>
          </Box>
          <Box display="flex" mt="10px" alignItems="center">
            <Toggle
              data-testid="sendDisconnectedEmailsToOwners"
              checked={sendDisconnectedEmailsToOwners}
              onChange={toggleEmailOwners}
            />
            <Typography mr="3px" ml="10px">
              Owners should receive other members integration notifications.
            </Typography>
            <Tooltip
              placement="bottom"
              title="When this option is enabled, owners of your team will be receiving email if any of team members got email or linkedin integration disconnected (linkedin restriction as well)"
            >
              <FontAwesomeIcon size="sm" icon={faQuestionCircle} />
            </Tooltip>
          </Box>
          <Box display="flex" mt="10px" alignItems="center">
            <Toggle
              data-testid="sendDisconnectedEmailsToAdmins"
              checked={sendDisconnectedEmailsToAdmins}
              onChange={toggleEmailAdmins}
            />
            <Typography mr="3px" ml="10px">
              Admins should receive other members integration notifications.
            </Typography>
            <Tooltip
              placement="bottom"
              title="When this option is enabled, admins of your team will be receiving email if any of team members got email or linkedin integration disconnected (linkedin restriction as well)"
            >
              <FontAwesomeIcon size="sm" icon={faQuestionCircle} />
            </Tooltip>
          </Box>
        </Box>
      </Wrapper>
    </>
  );
};
