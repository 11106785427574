import { css } from '@emotion/css';
import styled from '@emotion/styled';

import { Input } from 'src/components';

export const StyledInput = styled(Input)`
  background: rgba(242, 243, 249, 0.6) !important;
  border-radius: 8px !important;
  height: 50px !important;
`;

export const labelStyle = css`
  color: var(--text-primary);
  font-family: 'ProximaSoft' !important;

  &::after {
    content: '*';
    position: absolute;
    color: var(--primary-main);
    margin-left: 5px;
    font-weight: 500;
  }
`;
