import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Box, CircularProgress, SvgIcon } from '@mui/material';

import { ReactComponent as TwitterIcon } from 'src/assets/icons/x-twitter-icon.svg';
import { Button, Input, Typography } from 'src/components';
import { PLAN_TOOLTIPS, REGEXP_ONLY_NUMBERS } from 'src/constants';
import { Features } from 'src/enums';
import { useCompanyTheme, useTeamPlan } from 'src/hooks';
import {
  useGetTwitterAuthUrl,
  useLogoutFromTwitter,
  useUserTwitterDetails,
  useVerifyTwitterAuth,
} from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';
import { ITwitterDetails } from 'src/types';
import { Text, Title } from './ui';

const Wrapper = styled(Box)`
  background-color: #ffffff;
  padding: 20px;
  min-width: 300px;
  box-shadow: 0 1px 1px 0 ${({ theme }) => theme.palette.light.dark};
  border-radius: 5px;
`;

const StyledIcon = styled(SvgIcon)`
  position: absolute;
  height: 20px;
  width: 20px;
  padding: 13px;
  font-size: 20px;
  text-align: center;
  border-right: 1px solid rgba(255, 255, 255);
  top: 0;
  left: 0;
`;

const TwitterButton = styled(Button)`
  position: relative;
  background: #000000;
  padding: 9px 12px 9px 54px;
  font-size: 20px;
  transition: 0.2s;

  &:hover {
    background: #000000cc;
  }

  &:disabled {
    background: #000000cc;
    border-color: transparent;
    cursor: default;
  }
`;

const StyledForm = styled.form`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const StyledInput = styled(Input)`
  margin: 0 10px 0 0;
  width: 100%;
  height: auto;
  padding: 8px 10px;
`;

const AuthorizedBlock = styled.div`
  background-color: ${({ theme }) => theme.palette.light.light};
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-top: 20px;

  span {
    font-size: 14px;
  }
`;

const StyledImage = styled.img`
  border-radius: 50%;
  margin-right: 10px;
`;

export const TwitterIntegration = ({ fullWidth = false }: { fullWidth?: boolean }) => {
  const dispatch = useDispatch();
  const { shortCompanyName } = useCompanyTheme();

  const [twitterData, setTwitterData] = useState<ITwitterDetails | null>(null);
  const [showPin, setShowPin] = useState(false);

  const { register, handleSubmit, setValue } = useForm<Record<string, string>>();

  const { isFetching: isFetchingTwitterData } = useUserTwitterDetails({
    onSuccess: (data) => setTwitterData(data),
  });
  const { checkFeature } = useTeamPlan();
  const isTwitterIntegrationAllowed = checkFeature(Features.integrations);

  const { getTwitterAuthUrl, isLoading } = useGetTwitterAuthUrl();

  const { verifyTwitterAuth, isLoading: isVerifyLoading } = useVerifyTwitterAuth();

  const { logoutFromTwitter, isLoading: isLogoutLoading } = useLogoutFromTwitter({
    onSuccess: () => setTwitterData(null),
  });

  const signInWithTwitter = () => {
    getTwitterAuthUrl(
      {},
      {
        onSuccess: () => {
          setValue('pin', '');
          setShowPin(true);
        },
      },
    );
  };

  const sendPin = (data: FieldValues) => {
    verifyTwitterAuth(data.pin);
    setShowPin(false);
  };

  const logout = () => {
    dispatch(
      openModal({
        headerText: 'Are you sure you want to remove X (Twitter) credentials ?',
        descriptionText: 'This means that your X (Twitter) Campaigns stop to working.',
        onConfirm: logoutFromTwitter,
      }),
    );
  };

  if (isFetchingTwitterData) {
    return (
      <Box textAlign="center" mt="20px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Wrapper width={fullWidth ? '100%' : '50%'}>
      <Title>{twitterData ? 'X (Twitter) authorized' : 'Authorize X (Twitter)'}</Title>

      {twitterData ? (
        <>
          <Text>You have authorized your X (Twitter) account for sending Direct messages from {shortCompanyName}</Text>
          <AuthorizedBlock>
            <StyledImage src={twitterData.imageUrl} />
            <Box display="flex" flexDirection="column" justifyContent="space-around" mr="auto">
              <Typography semibold>{twitterData.name}</Typography>
              <span>@{twitterData.username}</span>
            </Box>
            <Button variant="warning" disabled={isLogoutLoading} onClick={logout}>
              Logout from X (Twitter)
            </Button>
          </AuthorizedBlock>
        </>
      ) : (
        <>
          <Text>
            To follow connections and send Direct messages in X (Twitter), we need you to authenticate X (Twitter).
          </Text>

          <Text pt="16px">Note: When you click the below button, you'll be see a window to login to X (Twitter)</Text>

          <Text pt="16px">
            After successful login, you will see a 7 digit code on the screen which you need to enter in below text box.
          </Text>
          <Box display="inline-block">
            <TwitterButton
              size={{ height: '48px' }}
              onClick={signInWithTwitter}
              disabled={isLoading || !isTwitterIntegrationAllowed}
              tooltip={{
                title: !isTwitterIntegrationAllowed && PLAN_TOOLTIPS.professional,
              }}
            >
              <StyledIcon>
                <TwitterIcon width="24px" height="24px" />
              </StyledIcon>
              Sign in with X (Twitter)
            </TwitterButton>
          </Box>

          {showPin && (
            <StyledForm onSubmit={handleSubmit(sendPin)}>
              <StyledInput
                register={register}
                name="pin"
                parameters={{
                  required: 'Please enter a pin',
                  minLength: {
                    value: 7,
                    message: 'Pin must 7 characters long',
                  },
                  pattern: {
                    value: REGEXP_ONLY_NUMBERS,
                    message: 'Please enter only numbers',
                  },
                }}
                placeholder="Enter pin here"
              />
              <Button disabled={isVerifyLoading} type="submit">
                Validate
              </Button>
            </StyledForm>
          )}
        </>
      )}
    </Wrapper>
  );
};
