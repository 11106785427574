import dayjs from 'dayjs';
import { Fragment } from 'react';
import { Box } from '@mui/material';

import { ReactComponent as CreditCard } from 'src/assets/icons/credit-card.svg';
import { Spinner, Typography } from 'src/components';
import { BILLING_TYPE_NAMES } from 'src/constants';
import { BillingTypeEnum, CurrencyEnum, Plan } from 'src/enums';
import { useTeamPlan } from 'src/hooks';
import { useGetBillingInfo } from 'src/reactQueries';
import { IStripeInvoice } from 'src/types';
import { InvoiceDetails } from './InvoiceDetails';

interface PaymentDetailsProps {
  plan?: Plan;
  billingType?: BillingTypeEnum;
  currency?: CurrencyEnum;
  invoiceDetails?: IStripeInvoice;
  isLoadingInvoiceDetails: boolean;
  showInvoiceDetails?: boolean;
}

export const PaymentDetails = ({
  plan,
  billingType,
  currency,
  invoiceDetails,
  isLoadingInvoiceDetails = false,
  showInvoiceDetails = true,
}: PaymentDetailsProps) => {
  const { billingInfo, isSubscriptionCanceled } = useGetBillingInfo();

  const { isTrial, isDeactivated } = useTeamPlan();

  const details = [
    { label: 'Plan', value: plan },
    { label: 'Billed', value: billingType && BILLING_TYPE_NAMES[billingType] },
    { label: 'Currency', value: currency?.toUpperCase() },
    {
      label: 'Next Invoice',
      value: dayjs.unix(billingInfo?.stripeSubscriptionObject?.current_period_end || 0).format('MM/DD/YYYY hh:mm A'),
      isShown: !isTrial && !isDeactivated && !isSubscriptionCanceled,
    },
  ];

  return (
    <Box width="100%" p="20px" bgcolor="#fff" borderRadius="4px">
      <Typography color="gray.800" fontSize="22px" semibold mb="24px">
        Payment Details
      </Typography>

      {details.map(({ label, value, isShown = true }) => (
        <Fragment key={label}>
          {isShown && (
            <>
              <Typography color="gray.500" fontSize="15px" mb="2px">
                {label}
              </Typography>
              <Typography color="gray.800" fontSize="16px" mb="16px" medium capitalizeFirstLetter>
                {value}
              </Typography>
            </>
          )}
        </Fragment>
      ))}

      <Typography color="gray.500" fontSize="15px" mb="2px">
        Payment Method
      </Typography>
      <Box display="flex" alignItems="center" gap="12px" mb="24px">
        <CreditCard />
        <Typography component="span" color="gray.800" fontSize="16px" medium>
          {billingInfo?.stripeTokenObject?.card?.last4
            ? `•••• •••• •••• ${billingInfo?.stripeTokenObject?.card?.last4}`
            : 'Not added yet'}
        </Typography>
      </Box>

      {isLoadingInvoiceDetails && showInvoiceDetails && <Spinner />}

      {!isLoadingInvoiceDetails && invoiceDetails && currency && showInvoiceDetails && (
        <InvoiceDetails invoiceDetails={invoiceDetails} currency={currency} />
      )}
    </Box>
  );
};
