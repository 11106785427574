import { Fragment, useMemo } from 'react';

import { Typography } from 'src/components';
import { BillingTypeEnum } from 'src/enums';
import { useGetBillingInfo } from 'src/reactQueries';
import { RawSelector, RawSelectorItem } from '../_components';

interface BillingCycleSelectorProps {
  billingType?: BillingTypeEnum;
  setBillingType: (type: BillingTypeEnum) => void;
}

export const BillingCycleSelector = ({ billingType, setBillingType }: BillingCycleSelectorProps) => {
  const { billingInfo } = useGetBillingInfo();

  const billingCycles = useMemo(() => {
    return [
      { label: 'Monthly', value: BillingTypeEnum.MONTHLY },
      { label: 'Quarterly', value: BillingTypeEnum.QUARTERLY, percentage: 20 },
      {
        label: 'Half-yearly',
        value: BillingTypeEnum.SEMESTRIAL,
        isShown: billingInfo?.billingType === BillingTypeEnum.SEMESTRIAL,
      },
      {
        label: 'Annual',
        value: BillingTypeEnum.ANNUAL,
        percentage: 40,
      },
    ];
  }, [billingInfo]);

  return (
    <RawSelector>
      {billingCycles.map(({ label, value, percentage, isShown = true }) => (
        <Fragment key={value}>
          {isShown && (
            <RawSelectorItem chosen={(value === billingType).toString()} onClick={() => setBillingType(value)}>
              <Typography fontSize="15px" color="black.base" inline>
                {label}
              </Typography>

              {percentage && (
                <Typography fontSize="15px" color="success.500" inline ml="4px">
                  (Save up to {percentage}%)
                </Typography>
              )}
            </RawSelectorItem>
          )}
        </Fragment>
      ))}
    </RawSelector>
  );
};
