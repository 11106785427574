import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { faCreditCard, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid } from '@mui/material';
import { useStripe } from '@stripe/react-stripe-js';

import { Button, Checkbox, Link, Typography } from 'src/components';
import { BillingTypeEnum, CurrencyEnum, ModalTypes, Plan } from 'src/enums';
import { delay } from 'src/helpers';
import {
  redirectToPaymentSuccessPage,
  resolveDefaultPaidPlanForSelector,
  resolveDowngradePlanText,
} from 'src/helpers/billing/billing';
import { useAppSelector, useCompanyTheme, usePageListeners, useWhiteLabel } from 'src/hooks';
import {
  useGetBillingInfo,
  useGetInvoiceDetails,
  useGetTeamInvites,
  useGetTeamMembers,
  useUpdateSubscription,
  useUpdateTeamDataAfterFailedPayment,
  useUpdateTeamDataAfterPaymentConfirmation,
} from 'src/reactQueries';
import { showToast } from 'src/store';
import { openModal } from 'src/store/modal.slice';
import { setIsAppDeactivated } from 'src/store/user.slice';
import { IBillingRedirectUrlParams, ICreateSubscription, IEditPlan } from 'src/types';
import { InvoicePlanDetails, PlanDetails, Testimonials } from '../_components';

const Divider = styled.hr`
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #eee;
`;

interface IEditSubscriptionProps {
  seatsOnly?: boolean;
}

export const EditSubscription = ({ seatsOnly = false }: IEditSubscriptionProps) => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { whitelabel } = useWhiteLabel();
  const { shortCompanyName } = useCompanyTheme();
  const teamData = useAppSelector(({ team }) => team.data);

  const [termsChecked, setTermsChecked] = useState(false);
  const [refundChecked, setRefundChecked] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);

  const { billingInfo } = useGetBillingInfo();
  const { teamMembers } = useGetTeamMembers();
  const { teamInvites = [] } = useGetTeamInvites();

  const teamCount =
    (teamMembers && teamInvites?.filter((invite) => !invite?.isRejected).length + teamMembers?.total) || 1;

  const redirectToPaymentSuccess = (urlParams: IBillingRedirectUrlParams) => {
    dispatch(setIsAppDeactivated(false));

    redirectToPaymentSuccessPage(urlParams);
  };

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<ICreateSubscription>({
    mode: 'onChange',
    defaultValues: {
      currency: billingInfo?.currency || CurrencyEnum.USD,
      billingType: billingInfo?.billingType || BillingTypeEnum.QUARTERLY,
      plan: resolveDefaultPaidPlanForSelector(billingInfo?.plan || Plan.INDIVIDUAL, teamCount),
      seats: teamData?.seats || 1,
    },
  });

  const { updateTeamDataAfterPaymentConfirmation } = useUpdateTeamDataAfterPaymentConfirmation();
  const { updateTeamDataAfterFailedPayment } = useUpdateTeamDataAfterFailedPayment();

  const { addUnloadConfirmation, removeUnloadConfirmation } = usePageListeners();

  const { updateSubscription, isLoading } = useUpdateSubscription({
    onError: (err) => {
      setError('paymentMethod', { type: 'custom', message: err.message });

      setIsProcessingPayment(false);
    },
    onSuccess: (data) => {
      setIsProcessingPayment(false);
      if (!stripe) {
        return;
      }

      const { subscription, redirectUrlParams } = data;

      const paymentIntent = subscription?.latest_invoice?.payment_intent;

      if (paymentIntent) {
        if (paymentIntent?.status !== 'succeeded') {
          setIsProcessingPayment(true);
          addUnloadConfirmation();

          stripe
            .confirmCardPayment(paymentIntent.client_secret, {
              payment_method: paymentIntent.payment_method || paymentIntent.last_payment_error.payment_method.id,
            })
            .then((data) => {
              removeUnloadConfirmation();
              if (data.error) {
                dispatch(
                  showToast({
                    message:
                      data.error.message ||
                      'We are unable to authenticate your payment method. Please check payment details and try again',
                    type: 'error',
                    autoCloseTime: 3000,
                  }),
                );
                updateTeamDataAfterFailedPayment();
              } else {
                delay(2000, () => {
                  updateTeamDataAfterPaymentConfirmation({ process: 'update' });
                  redirectToPaymentSuccess(redirectUrlParams);
                });
              }
            })
            .finally(() => setIsProcessingPayment(false));
        } else {
          redirectToPaymentSuccess(redirectUrlParams);
        }
      } else {
        redirectToPaymentSuccess(redirectUrlParams);
      }
    },
  });

  const watchPlan = watch('plan') || Plan.INDIVIDUAL;
  const watchSeats = watch('seats') || 1;
  const watchBillingType = watch('billingType') || BillingTypeEnum.QUARTERLY;
  const watchCurrency = watch('currency') || CurrencyEnum.USD;

  const { isFetching } = useGetInvoiceDetails(
    {
      plan: watchPlan,
      billingType: watchBillingType,
      seats: watchSeats,
      currency: watchCurrency,
    },
    { enabled: true },
  );

  const isPlanEdited = useMemo(() => {
    const isPlanChanged = teamData?.plan === watchPlan;
    const isSeatsChanged = teamData?.seats === watchSeats;
    const isBillingTypeChanged = teamData?.billingType === watchBillingType;

    return !isPlanChanged || !isSeatsChanged || !isBillingTypeChanged;
  }, [watchPlan, watchSeats, watchBillingType]);

  useEffect(() => {
    if (billingInfo && teamData && teamCount) {
      reset({
        currency: billingInfo.currency,
        plan: resolveDefaultPaidPlanForSelector(billingInfo.plan, teamCount),
        billingType: billingInfo.billingType || BillingTypeEnum.QUARTERLY,
        seats: teamData.seats,
      });
    }
  }, [billingInfo, teamData, teamCount]);

  const submit = async (values: ICreateSubscription) => {
    setIsProcessingPayment(true);

    if (billingInfo) {
      const body: IEditPlan = seatsOnly
        ? {
            seats: values.seats,
            plan: billingInfo.plan,
            billingType: billingInfo.billingType,
            currency: billingInfo.currency,
          }
        : {
            seats: values.seats,
            plan: values.plan,
            billingType: values.billingType,
            currency: values.currency,
          };

      const confirmationText = resolveDowngradePlanText(values.plan, billingInfo.plan, whitelabel.status === 'active');

      if (confirmationText) {
        dispatch(
          openModal({
            headerText: 'Plan downgrading',
            descriptionText: confirmationText,
            onConfirm: () => updateSubscription(body),
            onCancel: () => setIsProcessingPayment(false),
          }),
        );
      } else {
        updateSubscription(body);
      }
    }
  };

  const openUpdateCreditCard = () => {
    dispatch(openModal({ closable: false, type: ModalTypes.UPDATE_CREDIT_CARD }));
  };

  const openBillingHistoryModalWindow = () => {
    dispatch(openModal({ type: ModalTypes.BILLING_HISTORY, params: { preOpenFirstOpenedInvoice: true } }));
  };

  return (
    <Grid container px="15px" pt="20px" pb="80px" columnSpacing="30px" fontSize="14px">
      <Grid item sm={7}>
        <Box p="20px" borderRadius="4px" bgcolor="#ffffff">
          <form onSubmit={handleSubmit(submit)}>
            <PlanDetails
              register={register}
              setValue={setValue}
              currency={watchCurrency}
              seats={watchSeats}
              billingType={watchBillingType}
              plan={watchPlan}
              minSeats={teamCount}
              seatsOnly={seatsOnly}
              noCurrency
              isDisabled={isFetching}
            />

            <Divider />

            <Typography color="primary.wlLight" semibold mb="5px">
              Payment Method:
            </Typography>

            <Box display="flex" alignItems="center" mb="20px">
              <FontAwesomeIcon icon={faCreditCard} />
              <Typography fontSize="14px" color="violet.main" semibold mx="5px">
                •••• •••• •••• {billingInfo?.stripeTokenObject?.card?.last4}
              </Typography>
              <FontAwesomeIcon icon={faPencil} cursor="pointer" onClick={openUpdateCreditCard} />
            </Box>

            <Typography color="error.light" my="10px">
              {errors.paymentMethod?.message === 'outstanding_invoice_requires_action' ? (
                <>
                  Subscription can not be updated due to{' '}
                  <Link onClick={openBillingHistoryModalWindow}>outstanding invoices requiring attention</Link>.
                </>
              ) : (
                errors.paymentMethod?.message
              )}
            </Typography>

            <Typography fontSize="12px" fontStyle="italic">
              *Your credit card will be charged immediately.
            </Typography>

            {billingInfo?.billingType !== watchBillingType && (
              <Typography fontSize="12px" fontStyle="italic">
                *Your billing cycle will be restarted.
              </Typography>
            )}

            <Typography fontSize="12px" fontStyle="italic" mb="20px">
              *Unused time from your current billing cycle will be credited for future invoices.
            </Typography>

            <Box display="flex" alignItems="center" mb="20px">
              <Checkbox type="checkbox" checked={termsChecked} onChange={(e) => setTermsChecked(e.target.checked)} />
              <Typography color="violet.dark" ml="10px">
                I accept {shortCompanyName}{' '}
                <Link external openNewTab to="https://meetalfred.com/terms">
                  Terms of Use
                </Link>
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" mb="20px">
              <Checkbox type="checkbox" checked={refundChecked} onChange={(e) => setRefundChecked(e.target.checked)} />
              <Typography color="violet.dark" ml="10px">
                I accept {shortCompanyName}{' '}
                <Link
                  external
                  openNewTab
                  to="https://meetalfred.com/help/en/articles/3338757-refund-policy"
                  tooltip={
                    <Box textAlign="left">
                      <h5>No Refund Policy</h5>
                      Once upgraded, we do not offer any refunds under any circumstance, but you can cancel your
                      subscription at any time.
                    </Box>
                  }
                >
                  No Refund Policy
                </Link>
              </Typography>
            </Box>

            <Box display="flex" justifyContent="space-between">
              <Button
                disabled={isProcessingPayment || isFetching || !(isPlanEdited && termsChecked && refundChecked)}
                type="submit"
                processing={isLoading}
              >
                Confirm plan change
              </Button>
              <Button type="button" onClick={() => navigate(seatsOnly ? '/team/manage-team' : '/billing')}>
                Cancel
              </Button>
            </Box>
          </form>
        </Box>
      </Grid>

      <Grid item sm={5}>
        {billingInfo && (
          <InvoicePlanDetails
            plan={watchPlan}
            currency={watchCurrency}
            billingType={watchBillingType}
            seats={watchSeats}
          />
        )}
        <Testimonials />
      </Grid>
    </Grid>
  );
};
