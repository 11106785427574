import { UseFormReturn } from 'react-hook-form';
import { Box } from '@mui/material';

import { ISequence } from 'src/types';
import { SequenceForm } from '../SequenceForm/SequenceForm';
import { Tags } from '../Tags';

interface ISendMessageProps extends UseFormReturn<ISequence> {
  index: number;
  customSnippets: Record<string, string>;
}

export const SendMessage = ({ index, customSnippets, ...sequenceForm }: ISendMessageProps) => {
  const snippetOptions = Object.keys(customSnippets).map((key) => ({ label: key, value: key }));
  const { watch, setValue } = sequenceForm;

  return (
    <>
      <Box mb="20px">
        <SequenceForm
          title="Message"
          watchField="message"
          description={`Messages will only be sent to 1st degree connections.\nIf the lead is not connected the message will be skipped.`}
          config={{
            textMaxLength: 8000,
            withUploadFile: true,
          }}
          index={index}
          snippetOptions={snippetOptions}
          registerOptions={{
            validate: (value, formValues) => !!value.trim() || !!formValues.sequence?.[index]?.attachments?.length,
          }}
          {...sequenceForm}
        />
      </Box>
      <Tags index={index} watch={watch} setValue={setValue} />
    </>
  );
};
