import classnames from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid } from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';

import { Button, Checkbox, Input, Link, StripeCardOld, Typography } from 'src/components';
import { BillingTypeEnum, CurrencyEnum, Plan, TrialStatus } from 'src/enums';
import { redirectToPaymentSuccessPage } from 'src/helpers/billing/billing';
import { useAppSelector, useCompanyTheme, usePageListeners } from 'src/hooks';
import {
  useCreateSubscription,
  useGetBillingInfo,
  useGetInvoiceDetails,
  useGetTeamInvites,
  useGetTeamMembers,
  useRemoveSubscription,
  useUpdateTeamDataAfterPaymentConfirmation,
} from 'src/reactQueries';
import { showToast } from 'src/store';
import { ICreateSubscription } from 'src/types';
import { InvoicePlanDetails, PlanDetails, Testimonials } from '../_components';

const Divider = styled.hr`
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #eee;
`;

const StyledInput = styled(Input)`
  height: 60px;
  margin-bottom: 20px;
`;

const StyledInputWithError = styled(Input)`
  height: 60px;
`;

export const CreateSubscription = () => {
  const dispatch = useDispatch();

  const { shortCompanyName } = useCompanyTheme();

  const { profile } = useAppSelector((state) => state.user);

  const [termsChecked, setTermsChecked] = useState(false);
  const [refundChecked, setRefundChecked] = useState(false);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [isPromoCodeInputShown, setIsPromoCodeInputShown] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const { billingInfo } = useGetBillingInfo();
  const { teamMembers } = useGetTeamMembers();
  const { teamInvites = [] } = useGetTeamInvites();

  const { addUnloadConfirmation, removeUnloadConfirmation } = usePageListeners();

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors, touchedFields, dirtyFields },
    setError,
    clearErrors,
    reset,
  } = useForm<ICreateSubscription>({
    mode: 'onChange',
    defaultValues: {
      phone: '',
      company: '',
      address: '',
      promoCode: '',
      currency: CurrencyEnum.USD,
      billingType: BillingTypeEnum.QUARTERLY,
      plan: Plan.INDIVIDUAL,
      seats: 1,
    },
  });

  const { removeSubscription, isLoading: isRemovingSubscription } = useRemoveSubscription();
  const { updateTeamDataAfterPaymentConfirmation } = useUpdateTeamDataAfterPaymentConfirmation();

  const { createSubscription, isLoading } = useCreateSubscription({
    onError: (err) => {
      if (['inactive-promo-code', 'invalid-promo-code'].includes(err.message)) {
        setError('promoCode', {
          type: 'custom',
          message:
            err.message === 'invalid-promo-code'
              ? 'Please enter valid Promo Code'
              : 'This Promo Code has expired or been deactivated',
        });
      } else {
        setError('paymentMethod', { type: 'custom', message: err.message });
      }

      setIsProcessingPayment(false);
    },
    onSuccess: (data) => {
      if (!stripe) {
        return;
      }

      const { subscription, redirectUrlParams } = data;

      if (subscription?.latest_invoice?.payment_intent) {
        if (subscription?.latest_invoice?.payment_intent?.status !== 'succeeded') {
          setIsProcessingPayment(true);
          addUnloadConfirmation();

          stripe
            .confirmCardPayment(subscription.latest_invoice.payment_intent.client_secret, {
              payment_method: subscription.latest_invoice.payment_intent.payment_method,
            })
            .then((data) => {
              removeUnloadConfirmation();
              if (data.error) {
                dispatch(
                  showToast({
                    message:
                      data.error.message ||
                      'We are unable to authenticate your payment method. Please check payment details and try again',
                    type: 'error',
                    autoCloseTime: 3000,
                  }),
                );
                setError('paymentMethod', {
                  type: 'custom',
                  message:
                    'We are unable to authenticate your payment method. Please check payment details and try again',
                });
                removeSubscription();
              } else {
                updateTeamDataAfterPaymentConfirmation({ process: 'create' });
                redirectToPaymentSuccessPage(redirectUrlParams);
              }
            })
            .finally(() => setIsProcessingPayment(false));
        } else {
          redirectToPaymentSuccessPage(redirectUrlParams);
        }
      } else {
        redirectToPaymentSuccessPage(redirectUrlParams);
      }
    },
  });

  const watchPlan = watch('plan') || Plan.INDIVIDUAL;
  const watchSeats = watch('seats') || 1;
  const watchBillingType = watch('billingType') || BillingTypeEnum.QUARTERLY;
  const watchCurrency = watch('currency') || CurrencyEnum.USD;

  const { isFetching } = useGetInvoiceDetails(
    {
      plan: watchPlan,
      billingType: watchBillingType,
      seats: watchSeats,
      currency: watchCurrency,
    },
    { enabled: true },
  );

  const teamCount = teamMembers && teamInvites?.filter((invite) => !invite?.isRejected).length + teamMembers?.total;

  const isTrial =
    billingInfo?.trial === TrialStatus.STARTED ||
    (billingInfo?.trial === TrialStatus.ENDED && !billingInfo?.hasPaidPlan) ||
    !billingInfo?.trial;

  const submit = async (values: ICreateSubscription) => {
    if (!stripe || !elements) {
      return;
    }

    setIsProcessingPayment(true);

    const cardElement = elements.getElement(CardElement) as StripeCardElement;

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: profile.name,
        email: profile.email,
        phone: values.phone,
        address: {
          line1: values.address,
        },
      },
    });

    if (error) {
      setError('paymentMethod', { type: 'custom', message: error.message });

      setIsProcessingPayment(false);
    } else {
      createSubscription({ ...values, paymentMethod });
    }
  };

  useEffect(() => {
    if (teamCount && teamCount > 1) {
      reset({
        plan: Plan.BUSINESS,
        seats: teamCount,
        currency: CurrencyEnum.USD,
        billingType: BillingTypeEnum.QUARTERLY,
      });
    }
  }, [teamCount]);

  const renderTrialEndsBlock = () => {
    if (
      !isTrial ||
      billingInfo?.trial === TrialStatus.ENDED ||
      !billingInfo?.stripeSubscriptionObject?.current_period_end
    ) {
      return null;
    }

    return (
      <Grid item xs={12}>
        <Box bgcolor="#ffffff" p="20px" display="flex" borderRadius="4px" mb="20px">
          <Typography mr="10px" color="primary.wlLight" semibold>
            Trial ends on:
          </Typography>
          <Typography color="violet.dark" semibold>
            {dayjs.unix(billingInfo?.stripeSubscriptionObject?.current_period_end || 0).format('MM/DD/YYYY')}
          </Typography>
        </Box>
      </Grid>
    );
  };

  return (
    <Grid container px="15px" pt="20px" pb="80px" columnSpacing="30px" fontSize="14px">
      {renderTrialEndsBlock()}
      <Grid item sm={7}>
        <Box p="20px" borderRadius="4px" bgcolor="#ffffff">
          <form onSubmit={handleSubmit(submit)}>
            <PlanDetails
              register={register}
              setValue={setValue}
              currency={watchCurrency}
              seats={watchSeats}
              billingType={watchBillingType}
              plan={watchPlan}
              minSeats={teamCount}
              isDisabled={isFetching}
            />
            <Divider />
            <Typography color="primary.wlLight" semibold>
              Payment:
            </Typography>
            <StyledInput
              name="phone"
              register={register}
              parameters={{ required: true }}
              placeholder="Phone"
              required
              className={classnames({ error: touchedFields.phone && !dirtyFields.phone })}
            />
            <StyledInput
              name="company"
              register={register}
              parameters={{ required: true }}
              placeholder="Company"
              required
              className={classnames({ error: touchedFields.company && !dirtyFields.company })}
            />
            <StyledInput name="address" register={register} placeholder="Address (optional)" />
            <StripeCardOld onChange={() => errors.paymentMethod && clearErrors('paymentMethod')} />
            <Typography color="error.light" my="10px">
              {errors.paymentMethod?.message}
            </Typography>
            <Typography fontSize="18px" color="violet.dark" mb="16px">
              Your card will be charged immediately. All features included in your plan will be enabled.
            </Typography>
            <Typography
              fontSize="14px"
              color="info.dark"
              mb="16px"
              pointer
              onClick={() => setIsPromoCodeInputShown(!isPromoCodeInputShown)}
            >
              Do you have a promo code?
            </Typography>
            {isPromoCodeInputShown && (
              <>
                <StyledInputWithError
                  name="promoCode"
                  register={register}
                  parameters={{ required: false }}
                  placeholder="Promo code"
                  required
                  className={classnames({
                    error: errors.promoCode?.message && dirtyFields.promoCode,
                  })}
                />
                <Typography color="error.light" my="10px">
                  {errors.promoCode?.message}
                </Typography>
              </>
            )}
            <Box display="flex" alignItems="center" mb="20px">
              <Checkbox type="checkbox" checked={termsChecked} onChange={(e) => setTermsChecked(e.target.checked)} />
              <Typography color="violet.dark" ml="10px">
                I accept {shortCompanyName}{' '}
                <Link underline to="https://meetalfred.com/terms">
                  Terms of Use
                </Link>
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mb="20px">
              <Checkbox type="checkbox" checked={refundChecked} onChange={(e) => setRefundChecked(e.target.checked)} />
              <Typography color="violet.dark" ml="10px">
                I accept {shortCompanyName}{' '}
                <Link
                  external
                  openNewTab
                  to="https://meetalfred.com/help/en/articles/3338757-refund-policy"
                  tooltip={
                    <Box textAlign="left">
                      <h5>No Refund Policy</h5>
                      Once upgraded, we do not offer any refunds under any circumstance, but you can cancel your
                      subscription at any time.
                    </Box>
                  }
                >
                  No Refund Policy
                </Link>
              </Typography>
            </Box>
            <Button
              size={{ width: '100%' }}
              disabled={!termsChecked || !refundChecked || isProcessingPayment || isFetching}
              type="submit"
              processing={isLoading || isProcessingPayment || isRemovingSubscription}
            >
              Start Using {shortCompanyName}
            </Button>
            <Typography fontSize="11px" color="violet.dark" mt="20px" textAlign="center">
              <FontAwesomeIcon icon={faLock} /> This is a secure 128-bit SSL encryption.
            </Typography>
          </form>
        </Box>
      </Grid>
      <Grid item sm={5}>
        <InvoicePlanDetails
          plan={watchPlan}
          currency={watchCurrency}
          billingType={watchBillingType}
          seats={watchSeats}
        />
        <Testimonials />
      </Grid>
    </Grid>
  );
};
