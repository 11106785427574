import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { inviteUserWithEmail } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IInviteUserWithEmailRequest, IInviteUserWithEmailResponse } from 'src/types';

export const useInviteUserWithEmail = (
  options?: UseMutationOptions<IInviteUserWithEmailResponse, ICustomAxiosError, IInviteUserWithEmailRequest>,
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(['invite-user-with-email'], inviteUserWithEmail, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      if (data.type === 'invitation') {
        await queryClient.invalidateQueries(['get-team-invites']);
        dispatch(
          showToast({
            message: 'Invite has been sent',
            type: 'info',
            autoCloseTime: 5000,
          }),
        );
      }

      if (data.type === 'member') {
        await queryClient.invalidateQueries(['get-team-members']);
        await queryClient.invalidateQueries(['get-team-by-id']);
        await queryClient.invalidateQueries(['get-all-team-members']);

        dispatch(
          showToast({
            message: 'User has been added to team',
            type: 'info',
            autoCloseTime: 5000,
          }),
        );
      }
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return {
    inviteUserWithEmail: mutate,
    ...rest,
  };
};
