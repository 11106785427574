import { CampaignCategory } from 'src/enums';
import {
  ICreateSequenceTemplate,
  IGetAvailableSequenceTemplates,
  IGetSequenceTemplates,
  IGetSequenceTemplatesCategories,
  IGetSequenceTemplatesResponse,
  ISequenceTemplate,
} from 'src/types';
import { appApi } from './axios';

export const createSequenceTemplate = (body: ICreateSequenceTemplate): Promise<ISequenceTemplate> =>
  appApi.post(`/sequence-templates`, body);

export const getSequenceTemplates = (params: IGetSequenceTemplates): Promise<IGetSequenceTemplatesResponse> =>
  appApi.get('/sequence-templates', { params });

export const getSequenceTemplatesCategories = (
  params: IGetSequenceTemplatesCategories,
): Promise<Record<CampaignCategory, number>> => appApi.get('/sequence-templates/categories', { params });

export const getAvailableSequenceTemplates = (
  params: IGetAvailableSequenceTemplates,
): Promise<Array<ISequenceTemplate>> => appApi.get('/sequence-templates/available', { params });

export const getSequenceTemplateById = (id: number): Promise<ISequenceTemplate> =>
  appApi.get(`/sequence-templates/${id}`);

export const updateSequenceTemplateById = (id: number, body: ICreateSequenceTemplate): Promise<ISequenceTemplate> =>
  appApi.patch(`/sequence-templates/${id}`, body);

export const toggleSequenceTemplateAvailability = (id: number): Promise<void> =>
  appApi.patch(`/sequence-templates/${id}/toggle-availability`);

export const removeSequenceTemplateById = (id: number): Promise<void> => appApi.delete(`/sequence-templates/${id}`);
