import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { editCampaignLeadColumns } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import {
  ICustomAxiosError,
  IGetLeadsRequest,
  IGetLeadsResponse,
  ILeadsUpdateFields,
  IUpdateCampaignLeadResponse,
} from 'src/types';

interface IUpdateCampaignLeadColumnsParams {
  entityUrn: string;
  campaignId: number;
  getLeadKeys: IGetLeadsRequest;
}

export const useUpdateCampaignLeadColumns = (
  { campaignId, entityUrn, getLeadKeys }: IUpdateCampaignLeadColumnsParams,
  options?: UseMutationOptions<IUpdateCampaignLeadResponse, ICustomAxiosError, ILeadsUpdateFields>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const campaignLeadQueryKey = ['get-campaign-leads', campaignId, JSON.stringify(getLeadKeys)];

  const { mutate, isLoading, ...rest } = useMutation(
    ['update-campaign-lead-columns', entityUrn],
    (data: ILeadsUpdateFields) => editCampaignLeadColumns(entityUrn, campaignId, data),
    {
      ...options,
      onMutate: async (variables) => {
        options?.onMutate?.(variables);
        await queryClient.cancelQueries({ queryKey: campaignLeadQueryKey });

        const prevData = queryClient.getQueryData(campaignLeadQueryKey);

        queryClient.setQueryData<IGetLeadsResponse | undefined>(campaignLeadQueryKey, (data) => {
          if (data) {
            return {
              ...data,
              leads: data?.leads?.map((lead) =>
                lead.entityUrn === entityUrn
                  ? {
                      ...lead,
                      customData: variables,
                    }
                  : lead,
              ),
            };
          }

          return data;
        });

        return { prevData };
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueryData(campaignLeadQueryKey, context?.prevData);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { updateLeadColumn: mutate, isUpdateLoading: isLoading, ...rest };
};
