import { useMutation, UseMutationOptions } from 'react-query';

import { createSubscription } from 'src/api';
import { ICreateSubscription, ICustomAxiosError, ISubscriptionResponse } from 'src/types';

export const useCreateSubscription = (
  options?: UseMutationOptions<ISubscriptionResponse, ICustomAxiosError, ICreateSubscription>,
) => {
  const { mutate, ...rest } = useMutation(['create-subscription'], createSubscription, options);

  return { createSubscription: mutate, ...rest };
};
