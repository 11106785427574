import { ReactNode } from 'react';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Box, Radio, RadioProps } from '@mui/material';

const wrapperCss = css`
  &:disabled {
    span,
    label {
      cursor: default !important;
      opacity: 0.7;
    }
  }
`;

const Circle = styled.span`
  width: 16px;
  height: 16px;
  border: 1px solid #d0d5dd;
  border-radius: 50%;
`;

const CheckedCircle = styled(Circle)`
  position: relative;
  border: 1px solid ${({ theme }) => theme.palette.accent[500]};
  background-color: ${({ theme }) => theme.palette.accent[100]}80;
`;

const CheckedDot = styled.span`
  display: block;
  width: 6px;
  height: 6px;
  background-color: ${({ theme }) => theme.palette.accent[500]};
  border-radius: 50%;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledRadioButton = styled(Radio)`
  padding: 0;

  & ~ label {
    cursor: pointer;
  }
`;

interface RadioButtonNewProps extends RadioProps {
  label: string | ReactNode;
}

export const RadioButton = ({ id, label, disabled, ...restProps }: RadioButtonNewProps) => {
  return (
    <Box display="flex" alignItems="center" gap="8px" component="fieldset" disabled={disabled} className={wrapperCss}>
      <StyledRadioButton
        icon={<Circle />}
        checkedIcon={
          <CheckedCircle>
            <CheckedDot />
          </CheckedCircle>
        }
        color="default"
        id={id}
        disableRipple
        {...restProps}
      />
      <label htmlFor={id}>{label}</label>
    </Box>
  );
};
