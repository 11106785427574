import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { postGmailSingIn } from 'src/api';
import { showToast } from 'src/store';
import { ICustomAxiosError } from 'src/types';

export const usePostGmailSignIn = (options?: UseMutationOptions<void, ICustomAxiosError, string>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, error, ...rest } = useMutation('post-gmail-sign-in', postGmailSingIn, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      queryClient.invalidateQueries('get-users-email');
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return {
    postGmailSingIn: mutate,
    googleError: error,
    ...rest,
  };
};
