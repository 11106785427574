import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { Button, Typography } from 'src/components';
import { ModalTypes } from 'src/enums/modal.enum';
import { openModal } from 'src/store/modal.slice';

interface ITemplateCreatorLayoutProps {
  onSave: () => void;
  isSaveDisabled?: boolean;
  onClone: () => void;
  templateId?: number;
  isEdit: boolean;
}

export const TemplateCreatorLayout = ({
  onSave,
  templateId,
  onClone,
  isEdit,
  isSaveDisabled = false,
}: ITemplateCreatorLayoutProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onCancelClick = () => navigate(-1);
  const onSaveClick = () => onSave();
  const onCloneClick = () => onClone();
  const onDeleteClick = () => {
    if (templateId) {
      dispatch(
        openModal({
          type: ModalTypes.DELETE_TEMPLATE,
          headerText: 'Delete Confirmation',
          params: { id: templateId, goBack: true },
        }),
      );
    }
  };

  return (
    <Box width="100%" sx={{ bgcolor: '#fff' }}>
      <Box display="flex" flexDirection="row" width="100%">
        <Box alignItems="center" boxSizing="border-box" display="flex" flexDirection="row" padding="0 15px" width="50%">
          <Typography color="primary.wlLight" fontSize="30px" lineHeight="30px" padding="20px 0" semibold>
            Create template
          </Typography>
        </Box>
        <Box
          alignItems="center"
          boxSizing="border-box"
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          padding="0 15px"
          width="50%"
        >
          <Box marginLeft="20px">
            <Button variant="gray" onClick={onCancelClick}>
              Cancel
            </Button>
          </Box>
          {isEdit && (
            <>
              <Box marginLeft="20px">
                <Button variant="warning" onClick={onDeleteClick}>
                  Delete
                </Button>
              </Box>
              <Box marginLeft="20px">
                <Button onClick={onCloneClick}>Clone</Button>
              </Box>
            </>
          )}
          <Box marginLeft="20px">
            <Button onClick={onSaveClick} disabled={isSaveDisabled}>
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
