import { useDispatch } from 'react-redux';

import { Button, Typography } from 'src/components';
import { closeModal } from 'src/store/modal.slice';
import { IModalProps } from 'src/types';
import { ModalBody, ModalFooter } from '../_components';

export const ModalTeamGroupDelete = ({ onCancel, onConfirm, params }: IModalProps) => {
  const dispatch = useDispatch();

  const confirmHandler = () => {
    onConfirm?.(params);
    dispatch(closeModal());
  };

  const closeHandler = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  return (
    <>
      <ModalBody>
        <Typography color="violet.dark">Are you sure you want to delete this group?</Typography>
        <Typography color="violet.dark" mt="10px">
          All the members of this group will remain in your team but without a group. You can assign a group later in
          the manage team page.
        </Typography>
      </ModalBody>

      <ModalFooter>
        <Button variant="gray" onClick={closeHandler}>
          Cancel
        </Button>
        <Button onClick={confirmHandler}>Delete</Button>
      </ModalFooter>
    </>
  );
};
