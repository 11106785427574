import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { verifyDns } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError } from 'src/types';

export const useVerifyDns = (options?: UseMutationOptions<void, ICustomAxiosError>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const getWhiteLabelQueryKey = ['get-whitelabel'];
  const { mutate, isLoading, ...rest } = useMutation(['verify-dns'], verifyDns, {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(getWhiteLabelQueryKey);
      options?.onSuccess?.(data, variables, context);
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { verifyDns: mutate, isVerifyLoading: isLoading, ...rest };
};
