import {
  EMAIL_COLUMNS,
  INMAIL_COLUMNS,
  LINKEDIN_COLUMNS,
  LINKEDIN_EVENT_COLUMNS,
  LINKEDIN_GROUP_COLUMNS,
  LINKEDIN_POST_COLUMNS,
  TWITTER_COLUMNS,
} from 'src/constants';
import { CampaignCategory } from 'src/enums';
import { generateLinkedinDataset } from 'src/helpers/campaign/getCampaignDataset';
import { useGetUserAccount } from 'src/reactQueries';
import { checkLinkedInIsConnectedSuccessfully } from '../helpers';

export const useCampaignsListProps = (category: CampaignCategory) => {
  const { userMe } = useGetUserAccount();

  switch (category) {
    case CampaignCategory.LINKEDIN:
      return {
        title: 'LinkedIn Campaigns',
        inactiveNoLeads: false,
        isInactive: !checkLinkedInIsConnectedSuccessfully(userMe),
        inactiveLabel: 'LinkedIn',
        columnConfig: LINKEDIN_COLUMNS,
        generateDataset: generateLinkedinDataset,
      };
    case CampaignCategory.POST:
      return {
        title: 'LinkedIn Post Campaigns',
        inactiveNoLeads: false,
        isInactive: !checkLinkedInIsConnectedSuccessfully(userMe),
        inactiveLabel: 'LinkedIn',
        columnConfig: LINKEDIN_POST_COLUMNS,
        generateDataset: generateLinkedinDataset,
      };
    case CampaignCategory.GROUP:
      return {
        title: 'LinkedIn Group Campaigns',
        inactiveNoLeads: false,
        isInactive: !checkLinkedInIsConnectedSuccessfully(userMe),
        inactiveLabel: 'LinkedIn',
        columnConfig: LINKEDIN_GROUP_COLUMNS,
        generateDataset: generateLinkedinDataset,
      };
    case CampaignCategory.EVENT:
      return {
        title: 'LinkedIn Event Campaigns',
        inactiveNoLeads: false,
        isInactive: !checkLinkedInIsConnectedSuccessfully(userMe),
        inactiveLabel: 'LinkedIn',
        columnConfig: LINKEDIN_EVENT_COLUMNS,
        generateDataset: generateLinkedinDataset,
      };
    case CampaignCategory.INMAIL:
      return {
        title: 'InMails Campaigns',
        inactiveNoLeads: false,
        isInactive: !checkLinkedInIsConnectedSuccessfully(userMe),
        inactiveLabel: 'LinkedIn',
        columnConfig: INMAIL_COLUMNS,
        generateDataset: generateLinkedinDataset,
      };
    case CampaignCategory.EMAIL:
      return {
        title: 'Email Campaigns',
        inactiveNoLeads: true,
        isInactive: !userMe?.isEmailActive,
        inactiveLabel: 'Email',
        columnConfig: EMAIL_COLUMNS,
      };
    case CampaignCategory.TWITTER:
      return {
        title: 'X (Twitter) Campaigns',
        inactiveNoLeads: true,
        isInactive: !userMe?.isTwitterActive,
        inactiveLabel: 'X (Twitter)',
        columnConfig: TWITTER_COLUMNS,
      };
    case CampaignCategory.MULTICHANNEL:
      return {
        title: 'Multichannel Campaigns',
        isInactive: !checkLinkedInIsConnectedSuccessfully(userMe),
        inactiveLabel: 'LinkedIn',
        columnConfig: [...LINKEDIN_COLUMNS, ...EMAIL_COLUMNS, ...TWITTER_COLUMNS],
        generateDataset: generateLinkedinDataset,
      };
  }
};
