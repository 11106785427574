import { sortBy } from 'lodash';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Link, TableCell, Tag, Tags, Typography } from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features, ModalTypes } from 'src/enums';
import { useTeamPlan } from 'src/hooks';
import { openModal } from 'src/store/modal.slice';
import { IConnectionColumnsPosition, IConvertedConnection, TConnectionColumnsKey } from 'src/types';
import { LeadName, TagWrapper } from '../_components';

const BlurText = styled(Box)`
  filter: blur(5px);
  height: 100%;
  width: 100%;
`;

interface IConnectionsListCellProps {
  connection: IConvertedConnection;
  column: IConnectionColumnsPosition;
}

export const ConnectionsListCell = ({ connection, column }: IConnectionsListCellProps) => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { checkFeature } = useTeamPlan();

  const paidColumns: TConnectionColumnsKey[] = ['email', 'phone', 'language', 'skill'];

  if (paidColumns.includes(column.key) && !checkFeature(Features.connection)) {
    return (
      <TableCell tooltip={PLAN_TOOLTIPS.professional} contentStyle={{ filter: 'blur(5px)' }}>
        <BlurText>{PLAN_TOOLTIPS.professional}</BlurText>
      </TableCell>
    );
  }

  switch (column.key) {
    case 'name':
      return (
        <TableCell>
          <Link variant="lightBlue" to={`${location.pathname}?person=${connection.entityUrn}`}>
            <LeadName crop fontSize="15px">
              {connection.name}
            </LeadName>
          </Link>
        </TableCell>
      );

    case 'connectedAt':
      return <TableCell>{connection[column.key] || 'NA'}</TableCell>;

    case 'language': {
      const languages = connection?.language?.split(',');
      const empty = languages?.every((language) => language);

      if (!empty) {
        return <TableCell>NA</TableCell>;
      }

      return (
        <TableCell>
          <TagWrapper>
            {languages?.map(
              (language) =>
                language && (
                  <Tag key={language} borderColor="#0000001a" bgColor={`${palette.gray[50]}80`}>
                    <Typography color="gray.600" crop>
                      {language}
                    </Typography>
                  </Tag>
                ),
            )}
          </TagWrapper>
        </TableCell>
      );
    }
    case 'skill': {
      const skills = connection?.skill?.split(',')?.map((x) => x?.split('||')[0]?.trim());
      const empty = skills?.every((skill) => skill);

      if (!empty) {
        return <TableCell>NA</TableCell>;
      }

      return (
        <TableCell>
          <TagWrapper>
            {skills?.map(
              (skill) =>
                skill && (
                  <Tag key={skill} borderColor={'#0000001a'} bgColor={`${palette.gray[50]}80`}>
                    <Typography color="gray.600" crop>
                      {skill}
                    </Typography>
                  </Tag>
                ),
            )}
          </TagWrapper>
        </TableCell>
      );
    }
    case 'tags': {
      const sortedTags = sortBy(connection.tags, 'tag');
      const isValues = Array.isArray(sortedTags);
      const tagIds = sortedTags.map(({ id }) => id);

      if (!isValues) {
        return null;
      }

      const onCellClick = () =>
        dispatch(
          openModal({
            headerText: 'Change tags',
            type: ModalTypes.ADD_LEADS_TAGS,
            params: { entityUrn: connection.entityUrn, tagIds },
          }),
        );

      return (
        <TableCell
          maxWidth="180px"
          onClick={(event) => {
            event.stopPropagation();
            onCellClick();
          }}
          contentStyle={{ minWidth: '100%' }}
        >
          <Tags withTooltip ids={tagIds} />
        </TableCell>
      );
    }
    default:
      return <TableCell tooltip={connection[column.key]}>{connection[column.key]}</TableCell>;
  }
};
