import { Box } from '@mui/material';

import { Spinner, Typography } from 'src/components';
import { BILLING_TYPE_NAMES } from 'src/constants';
import { BillingTypeEnum, CurrencyEnum, Plan } from 'src/enums';
import { useGetInvoiceDetails } from 'src/reactQueries';
import { InvoiceDetails } from './InvoiceDetails';

interface InvoicePlanDetailsProps {
  plan: Plan;
  billingType: BillingTypeEnum;
  seats: number;
  currency: CurrencyEnum;
}

export const InvoicePlanDetails = ({ plan, billingType, seats, currency }: InvoicePlanDetailsProps) => {
  const { invoiceDetails, isFetching } = useGetInvoiceDetails(
    { plan, billingType, seats, currency },
    { enabled: true },
  );

  if (isFetching) {
    return (
      <Box p="20px" bgcolor="#ffffff" borderRadius="4px" mb="20px">
        <Spinner />
      </Box>
    );
  }

  return (
    <Box p="20px" bgcolor="#ffffff" borderRadius="4px" mb="20px">
      <Box display="flex" textTransform="capitalize">
        <Typography color="primary.wlLight" semibold mr="10px">
          {plan} {BILLING_TYPE_NAMES[billingType]}
        </Typography>
        <Typography color="violet.main" semibold>
          {seats} Seat{seats > 1 && 's'}
        </Typography>
      </Box>

      {invoiceDetails && <InvoiceDetails invoiceDetails={invoiceDetails} currency={currency} />}
    </Box>
  );
};
