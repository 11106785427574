import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { faSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { MessageBlock, Toggle, Typography } from 'src/components';
import { Button } from 'src/components/Buttons';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features, ModalTypes, TrialStatus } from 'src/enums';
import { useTeamPlan, useWhiteLabel } from 'src/hooks';
import {
  useDeleteMailSettings,
  useGetBillingInfo,
  useGetMailSettings,
  useGetUserAccount,
  useSetMailSettings,
  useTestSMTPSettings,
  useToggleIncludeUnsubscribeEmailLink,
} from 'src/reactQueries';
import { openModal } from 'src/store';
import { IIMAPSettings, ISMTPSettings } from 'src/types';
import { Accordion, AccordionDetails, AccordionSummary } from '../../ui';
import { IMAPForm } from './IMAPForm';
import { SMTPForm } from './SMTPForm';

export type TEmailSMTPForm = Required<Omit<ISMTPSettings, 'port'> & { port: number | string }>;
export type TEmailIMAPForm = Required<Omit<IIMAPSettings, 'port'> & { port: number | string }>;

const SMTPFormDefaultValues: TEmailSMTPForm = {
  isTlsEnabled: false,
  password: '',
  port: '',
  replyToEmail: '',
  senderName: '',
  smtpServer: '',
  username: '',
};

const IMAPFormDefaultValues: TEmailIMAPForm = {
  imapServer: '',
  isTlsEnabled: false,
  password: '',
  port: '',
  username: '',
};

export const OtherProviderForm = () => {
  const dispatch = useDispatch();
  const { whitelabel } = useWhiteLabel();
  const { checkFeature, isTrial } = useTeamPlan();

  const [expanded, setExpanded] = useState(false);

  const smtpForm = useForm<TEmailSMTPForm>({ defaultValues: SMTPFormDefaultValues });
  const imapForm = useForm<TEmailIMAPForm>({ defaultValues: IMAPFormDefaultValues });

  const { userMe } = useGetUserAccount();
  const { mailSettings } = useGetMailSettings();
  const { billingInfo } = useGetBillingInfo();
  const { setMailSettings, isLoading } = useSetMailSettings();
  const { toggleIncludeUnsubscribeEmailLink } = useToggleIncludeUnsubscribeEmailLink();
  const { testSMTP, isTestLoading, isError, isSuccess } = useTestSMTPSettings({
    onError: (err) => {
      smtpForm.setError('root', { message: err.message });
    },
  });
  const { deleteMailSettings, isDeleteLoading } = useDeleteMailSettings({
    onMutate: () => setExpanded(false),
    onError: () => setExpanded(true),
    onSuccess: () => {
      smtpForm.reset(SMTPFormDefaultValues);
      imapForm.reset(IMAPFormDefaultValues);
    },
  });
  const isSMTPIntegrationAllowed =
    !checkFeature(Features.SMTPIntegration) || (isTrial && billingInfo?.trial === TrialStatus.ENDED);

  useEffect(() => {
    if (mailSettings.isConnected) {
      setExpanded(true);
      smtpForm.reset({ ...mailSettings?.smtpSettings, password: mailSettings?.smtpSettings?.password || '' });
      imapForm.reset(mailSettings?.imapSettings);
    }
  }, [mailSettings?.smtpSettings, mailSettings?.imapSettings]);

  const handleConnect = () => {
    if (smtpForm.getValues().username !== imapForm.getValues().username) {
      return imapForm.setError(
        'username',
        { message: 'IMAP username should match SMTP username' },
        { shouldFocus: true },
      );
    }

    setMailSettings({
      imapSettings: { ...imapForm.getValues(), port: Number(imapForm.getValues('port')) },
      smtpSettings: { ...smtpForm.getValues(), port: Number(smtpForm.getValues('port')) },
    });
  };

  const handleDelete = () => {
    dispatch(
      openModal({
        headerText: 'Remove SMTP + IMAP Settings',
        descriptionText: 'Are you sure you want to remove SMTP + IMAP Settings',
        onConfirm: deleteMailSettings,
      }),
    );
  };

  const onTestSMTPClick = async () => {
    const [isSMPTFormValid, isIMAPFormValid] = await Promise.all([smtpForm.trigger(), imapForm.trigger()]);

    if (!isSMPTFormValid || !isIMAPFormValid) {
      return;
    }

    dispatch(
      openModal({
        headerText: 'Test SMTP',
        type: ModalTypes.TEST_SMTP,
        params: {
          validateEmail: (email: string) => email !== smtpForm.getValues().username,
        },
        onConfirm: (receiverEmail) =>
          testSMTP({
            receiverEmail,
            imapSettings: { ...imapForm.getValues(), port: Number(imapForm.getValues('port')) },
            smtpSettings: { ...smtpForm.getValues(), port: Number(smtpForm.getValues('port')) },
          }),
      }),
    );
  };

  const onUpdateSignatureClick = () =>
    dispatch(
      openModal({
        type: ModalTypes.SIGNATURE,
        headerText: 'Setup your Email Signature',
      }),
    );

  const onExportUnsubscribedEmailsClick = () =>
    dispatch(
      openModal({
        type: ModalTypes.EXPORT_UNSUBSCRIBED_EMAILS_CSV,
      }),
    );

  return (
    <Accordion expanded={expanded}>
      <AccordionSummary>
        <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
          <Typography semibold fontSize="20px" color="#000">
            {mailSettings.isConnected
              ? 'Authorized via SMTP + IMAP'
              : `${whitelabel.isWhiteLabelUser ? '' : 'Other '}Email Provider`}
          </Typography>

          {mailSettings.isConnected ? (
            <Button variant="warning" processing={isDeleteLoading} onClick={handleDelete}>
              Disconnect SMTP + IMAP
            </Button>
          ) : (
            <Button
              disabled={isSMTPIntegrationAllowed}
              tooltip={{ title: isSMTPIntegrationAllowed && PLAN_TOOLTIPS.agency }}
              onClick={() => setExpanded((prev) => !prev)}
            >
              {expanded ? 'Cancel' : 'Connect via SMTP + IMAP'}
            </Button>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Typography bold fontSize="18px">
            SMTP for Sending
          </Typography>
          <SMTPForm form={smtpForm} />
        </Box>
        <Box mt="32px">
          <Typography bold fontSize="18px">
            IMAP for Receiving
          </Typography>
          <IMAPForm form={imapForm} />
        </Box>

        {mailSettings.isConnected && (
          <Box marginTop="30px" display="flex" gap="16px">
            <Button variant="gray" onClick={onUpdateSignatureClick}>
              <FontAwesomeIcon icon={faSignature} /> Update signature
            </Button>
            <Button onClick={onExportUnsubscribedEmailsClick}>Export unsubscribed emails</Button>
          </Box>
        )}
        <Box display="flex" mt="30px" mb="10px" alignItems="center">
          <Toggle checked={userMe?.includeUnsubscribeEmailLink} onChange={() => toggleIncludeUnsubscribeEmailLink()} />
          <Typography mr="3px" ml="10px">
            Include unsubscribe link to all email campaigns
          </Typography>
        </Box>

        {isError && !isTestLoading && (
          <MessageBlock type="error" message={smtpForm?.formState?.errors?.root?.message} />
        )}

        <Box mt="18px" mb="-20px" mx="-20px" p="20px" display="flex" alignItems="center" gap="15px" bgcolor="#fcfcfc">
          <Box mr="auto" display="flex" alignItems="center" gap="20px">
            <Button processing={isTestLoading} onClick={onTestSMTPClick}>
              Test SMTP and IMAP
            </Button>
            {isSuccess && !isTestLoading && <Typography>We sent test email to your inbox</Typography>}
          </Box>
          <Button processing={isLoading} onClick={smtpForm.handleSubmit(() => imapForm.handleSubmit(handleConnect)())}>
            Save Settings
          </Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
