import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from 'src/constants';
import { transformConnectionsData } from 'src/transformers';
import {
  IConnection,
  IConnectionColumnsPosition,
  IConnectionsPostParameters,
  IConnectionsResponse,
  IGetLeadActivitiesRequest,
  IGetLeadActivitiesResponse,
  ILIConversation,
} from 'src/types';
import { appApi } from './axios';

export interface IGetTaggedConnectionsRequest {
  selectedTags: number[];
  page: number;
}

export const getTaggedConnections = ({
  selectedTags,
  page,
}: IGetTaggedConnectionsRequest): Promise<ILIConversation[]> =>
  appApi
    .get<ILIConversation[], { connections: IConnection[]; total: number }>(`/connections/tagged`, {
      params: {
        page,
        perPage: 20,
        tagIds: selectedTags,
      },
    })
    .then((data) => transformConnectionsData(data));

export const getLIConnectionsReplies = ({ page, query }: { page: number; query: string }) =>
  appApi
    .get<unknown, { connections?: IConnection[]; leads?: IConnection[]; total: number }>(`/leads/replied`, {
      params: {
        page,
        perPage: 20,
        name: query,
      },
    })
    .then((data) => transformConnectionsData(data));

export const updateConnectionNotes = ({ entityUrn, notes }: { entityUrn: string; notes: string }): Promise<void> =>
  appApi.patch(`/connections/${entityUrn}/notes`, { notes });

export const updateConnection = ({
  entityUrn,
  body,
}: {
  entityUrn: string;
  body: { isExcluded: boolean };
}): Promise<void> => appApi.patch(`/connections/${entityUrn}`, body);

export const updateConnectionsColumnsPosition = (
  columnsPositions: IConnectionColumnsPosition[],
): Promise<IConnectionColumnsPosition[]> => appApi.put(`/connections/save-columns-position`, { columnsPositions });

export const postConnections = (requestData: IConnectionsPostParameters): Promise<IConnectionsResponse> =>
  appApi.post(`/connections`, requestData);

export const exportConnections = (
  receiverEmail: string,
  entityUrns: string[],
  request: IConnectionsPostParameters,
): Promise<void> => appApi.post(`/connections/csv`, { ...request, receiverEmail, entityUrns });

export const getLeadActivities = ({
  entityUrn,
  page = DEFAULT_PAGE,
  perPage = DEFAULT_PER_PAGE,
}: IGetLeadActivitiesRequest): Promise<IGetLeadActivitiesResponse> =>
  appApi.get(`/connections/${entityUrn}/actions`, {
    params: {
      page,
      perPage,
    },
  });
