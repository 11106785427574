import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { setMailSettings } from 'src/api';
import { showToast } from 'src/store';
import { ICustomAxiosError, IMailSettings } from 'src/types';

export const useSetMailSettings = (options?: UseMutationOptions<IMailSettings, ICustomAxiosError, IMailSettings>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const getMailSettingsQueryKey = ['get-mail-settings'];

  const { mutate, ...rest } = useMutation(['set-mail-settings'], setMailSettings, {
    ...options,
    onMutate: async (variables) => {
      options?.onMutate?.(variables);

      await queryClient.cancelQueries(getMailSettingsQueryKey);

      const prevData = queryClient.getQueryData(getMailSettingsQueryKey);

      queryClient.setQueryData<IMailSettings | undefined>(
        getMailSettingsQueryKey,
        (data) =>
          data && {
            ...data,
            ...variables,
          },
      );

      return { prevData };
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      dispatch(
        showToast({
          type: 'success',
          message: 'SMTP + IMAP setting updated successfully',
        }),
      );
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);

      queryClient.setQueryData(getMailSettingsQueryKey, context?.prevData);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return {
    setMailSettings: mutate,
    ...rest,
  };
};
