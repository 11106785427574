import {
  IGetPost,
  IGetPostsCount,
  IGetPostsRequest,
  IGetPostsResponse,
  IGetPostsSearchRequest,
  TSchedulePost,
} from 'src/types';
import { appApi } from './axios';

export const getPosts = (params: IGetPostsRequest): Promise<IGetPostsResponse> => appApi.get(`/posts`, { params });

export const getPostsCount = (params: IGetPostsSearchRequest): Promise<IGetPostsCount> =>
  appApi.get(`/posts/types`, { params });

export const archivePost = (postsId: number): Promise<void> => appApi.patch(`/posts/${postsId}/archive`);

export const deletePost = (postsId: number): Promise<void> => appApi.delete(`/posts/${postsId}`);

export const schedulePost = (body: Omit<TSchedulePost, 'scheduledAt'> & { scheduledAt: string }): Promise<void> =>
  appApi.post(`/posts`, body);

export const reschedulePost = (postId: number, body: { timestamp?: number }): Promise<void> =>
  appApi.patch(`/posts/${postId}/post-time`, body);

export const getPostById = (postId: number): Promise<IGetPost> => appApi.get(`/posts/${postId}`);

export const updatePost = (
  postId: number,
  body: Omit<TSchedulePost, 'scheduledAt' | 'postTypes'> & { scheduledAt: string },
): Promise<IGetPost> => appApi.patch(`/posts/${postId}`, body);
