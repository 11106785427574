import { CSSProperties, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { HtmlParser } from 'src/components';

const initialTextStyles: CSSProperties = {
  WebkitLineClamp: 5,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const ShowMoreButton = styled.span`
  display: block;
  margin-top: 15px;
  cursor: pointer;
  color: #0a66c2;
`;

const CONTENT_PREVIEW_HEIGHT = 80;

export const PostPreviewText = ({ content, type }: { content: string; type?: string }) => {
  const contentRef = useRef<HTMLParagraphElement>(null);
  const [textStyles, setTextStyles] = useState(initialTextStyles);
  const [showMore, setShowMore] = useState(false);
  const [isShowMoreShown, setIsShowMoreShown] = useState(false);
  const [previewText, setPreviewText] = useState(content);

  const onShowMoreCLick = () => {
    setTextStyles(showMore ? initialTextStyles : {});
    setShowMore(!showMore);
  };

  useEffect(() => {
    setPreviewText(content);
  }, [content]);

  useEffect(() => {
    setIsShowMoreShown(!!contentRef?.current && contentRef?.current?.scrollHeight > CONTENT_PREVIEW_HEIGHT);
  }, [contentRef, previewText]);

  return (
    <div>
      <p
        ref={contentRef}
        style={{
          display: '-webkit-box',
          wordBreak: 'break-word',
          lineHeight: '16px',
          ...(type === 'instagram' ? { marginLeft: '10px', lineHeight: '24px' } : {}),
          ...textStyles,
        }}
      >
        <HtmlParser parseText={previewText} options={{ allowedTags: ['br', 'span'], disallowedTagsMode: 'escape' }} />
      </p>
      {isShowMoreShown && (
        <ShowMoreButton
          onClick={onShowMoreCLick}
          style={type === 'instagram' ? { marginLeft: '10px', lineHeight: '24px' } : {}}
        >
          Show {showMore ? 'less' : 'more'}
        </ShowMoreButton>
      )}
    </div>
  );
};
