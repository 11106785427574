import { isEmpty } from 'lodash';
import { PropsWithChildren, useMemo, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import { APP_HOSTNAME, LANDING_URL } from 'src/constants';
import { DeactivatedWhiteLabel } from 'src/containers/DeactivatedApp';
import {
  useAppSelector,
  useCheckDeactivateApp,
  useIsTrialUserQueryParam,
  useLogoutUser,
  useSyncUserLanguage,
  useWhiteLabel,
} from 'src/hooks';
import {
  useGetAllTeamMembers,
  useGetFacebookData,
  useGetFeatureFlags,
  useGetLastInvitationToTeam,
  useGetRealUser,
  useGetUserAccount,
  useGetUserInvitesRestriction,
  useGetUserPreferences,
  usePostUsersBots,
} from 'src/reactQueries';
import { AccountBlocked } from './AccountBlocked';
import { AppLoading } from './AppLoading';
import { AppNavBar } from './AppNavBar';

const AppLayoutContainer = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.light.light};
`;

const AppLayoutContent = styled.div<{ offsetTop: number }>`
  height: calc(100vh - ${(props) => props.offsetTop}px);
`;

interface IAppLayoutProps extends PropsWithChildren {
  hideNavBar?: boolean;
}

export const AppLayout = ({ children, hideNavBar }: IAppLayoutProps) => {
  const location = useLocation();
  const { isAuth, isAppDeactivated, isAppPaused, profile } = useAppSelector((state) => state.user);
  const [navBarHeight, setNavBarHeight] = useState(58);

  const { isLoading: isWhiteLabelLoading, whitelabel: { isAlfredDomain, isWhiteLabelUser, isActive, domain } = {} } =
    useWhiteLabel();
  const { data: members = [], isLoading: isMembersLoading } = useGetAllTeamMembers();
  const { userMe, isLoading: isLoadingUserAccount } = useGetUserAccount();
  const { realImpersonateUser, isLoading: isLoadingRealUser } = useGetRealUser();
  const { isLoading } = useCheckDeactivateApp();
  const { isLoading: isLoadingFeatureFlags } = useGetFeatureFlags();

  const isTeamUser = useMemo(
    () => !!members.find(({ userId }) => userId === realImpersonateUser?.id),
    [members, realImpersonateUser],
  );

  useLogoutUser();
  useGetUserPreferences();
  useGetLastInvitationToTeam();
  useSyncUserLanguage();
  useIsTrialUserQueryParam();
  usePostUsersBots();
  useGetUserInvitesRestriction();
  useGetFacebookData({ refetchOnMount: false });

  if (!isAuth) {
    if (isAlfredDomain) {
      window.location.href = LANDING_URL;

      return null;
    }

    return <Navigate to="/auth/login" />;
  }

  if (location.pathname !== '/app/verifyEmail' && isAuth && !isEmpty(userMe) && !userMe?.isEmailVerified) {
    return <Navigate to="/app/verifyEmail" />;
  }

  if (
    isLoading ||
    isLoadingRealUser ||
    isLoadingUserAccount ||
    isEmpty(profile) ||
    isWhiteLabelLoading ||
    isMembersLoading ||
    isLoadingFeatureFlags
  ) {
    return <AppLoading />;
  }

  if (
    isWhiteLabelUser &&
    (!isActive || profile?.isBlocked || isAppDeactivated || isAppPaused || APP_HOSTNAME !== domain)
  ) {
    return <DeactivatedWhiteLabel />;
  }

  if (profile?.isBlocked) {
    return isTeamUser && !realImpersonateUser?.isAdminImpersonate ? (
      <AppLayoutContainer>
        <AppNavBar isEmpty />
        <AccountBlocked />
      </AppLayoutContainer>
    ) : (
      <AccountBlocked />
    );
  }

  if (
    isAlfredDomain &&
    location.pathname !== '/walkthrough' &&
    isAuth &&
    !isEmpty(userMe) &&
    userMe?.isEmailVerified &&
    !userMe?.isWalkthroughCompleted &&
    !isAppDeactivated &&
    !isAppPaused
  ) {
    return <Navigate to="/walkthrough" />;
  }

  return (
    <AppLayoutContainer>
      <AppNavBar setNavBarHeight={setNavBarHeight} isEmpty={hideNavBar} />
      <AppLayoutContent offsetTop={navBarHeight}>{children}</AppLayoutContent>
    </AppLayoutContainer>
  );
};
