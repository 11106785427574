import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { applyDiscount } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError } from 'src/types';
import { useCloseCancelModalTrigger } from './useCloseCancelModalTrigger';

export const useApplyDiscount = (options?: UseMutationOptions<void, ICustomAxiosError, number>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { closeCancelModalTrigger } = useCloseCancelModalTrigger();

  const { mutate, ...rest } = useMutation(['apply-discount'], applyDiscount, {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(['get-upcoming-invoice']);

      options?.onSuccess?.(data, variables, context);

      closeCancelModalTrigger(`${variables}% discount accepted`);

      dispatch(
        showToast({
          type: 'success',
          message: 'Discount applied successfully.',
          autoCloseTime: 3000,
        }),
      );
    },
    onError: (err: ICustomAxiosError) => {
      dispatch(
        showToast({
          type: 'error',
          message: err.message,
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return {
    applyDiscount: mutate,
    ...rest,
  };
};
