import { CampaignCategory, CampaignType } from 'src/enums';
import { ICampaignAudienceRequest, ICampaignSearchParams, ISequence } from 'src/types';

export const transformCampaignRequestBody = (data: {
  category: CampaignCategory;
  campaignType: CampaignType;
  maxSearchAmount?: number;
  excludeNoPhotos?: boolean;
  noFirstConnections?: boolean;
  retargetLeads?: boolean;
  openLinkOnly?: boolean;
  premiumOnly?: boolean;
  isLinkedinOnly?: boolean;
  includeMessageOnly?: boolean;
  searchParameters?: ICampaignSearchParams;
  searchUrl?: string;
  salesNavigatorUrl?: string;
  touchSequence?: ISequence;
  entityUrns?: string[];
  header?: Record<string, string>;
  leads?: Record<string, string>[];
  isLinkedinSearchUrl?: boolean;
  leadSourceId?: number;
}): ICampaignAudienceRequest => {
  const {
    category,
    campaignType,
    isLinkedinSearchUrl,
    searchParameters,
    searchUrl,
    salesNavigatorUrl,
    touchSequence,
    entityUrns,
    header,
    leads,
  } = data;
  const isShortSearchAmount = [CampaignType.LINKEDIN_SEARCH, CampaignType.LINKEDIN_EVENT].includes(campaignType);

  return {
    category,
    campaignType,
    maxSearchAmount: data.maxSearchAmount ?? (isShortSearchAmount ? 1000 : 2500),
    excludeNoPhotos: Boolean(data.excludeNoPhotos),
    noFirstConnections: Boolean(data.noFirstConnections),
    openLinkOnly: Boolean(data.openLinkOnly),
    premiumOnly: Boolean(data.premiumOnly),
    retargetLeads: Boolean(data.retargetLeads),
    includeMessageOnly: Boolean(data.includeMessageOnly),
    isLinkedinOnly: Boolean(data.isLinkedinOnly),
    searchUrl: searchUrl || salesNavigatorUrl || '',
    touchSequence: touchSequence?.sequence?.length ? touchSequence : { sequence: [] },
    leadSourceId: data.leadSourceId,
    searchParameters: isLinkedinSearchUrl
      ? ({} as ICampaignSearchParams)
      : {
          keywords: '',
          connections: {
            first: false,
            second: false,
            third: false,
          },
          profileLanguages: {
            english: false,
            french: false,
            polish: false,
            portuguese: false,
            russian: false,
            spanish: false,
          },
          specifyKeywords: {
            firstName: '',
            lastName: '',
            title: '',
            company: '',
            school: '',
          },
          industries: [],
          currentCompanies: [],
          pastCompanies: [],
          schools: [],
          locations: [],
          ...searchParameters,
        },
    ...(entityUrns ? { entityUrns } : {}),
    ...(header && leads ? { header, leads } : {}),
  };
};
