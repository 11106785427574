import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Input } from '../TextField';

export const FilterInput = styled(Input)`
  height: 34px;
`;

export const Filters = styled(Box)`
  margin: 20px 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
`;
