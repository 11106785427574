import { Box } from '@mui/material';

import { Spinner } from 'src/components';
import { CampaignCategory } from 'src/enums';
import { convertCampaignTypeToAudienceType } from 'src/helpers';
import { useLocationState, useSingleCampaignProps } from 'src/hooks';
import { ILocationStateSingleCampaign } from 'src/types';
import { EmailCsvUpload } from './AudienceForms/EmailCsvUpload';
import { LinkedinCsvUpload } from './AudienceForms/LinkedinCsvUpload';
import { LinkedinEvent } from './AudienceForms/LinkedinEvent';
import { LinkedinGroup } from './AudienceForms/LinkedinGroup';
import { LinkedinPost } from './AudienceForms/LinkedinPost';
import { LinkedinSalesNavigator } from './AudienceForms/LinkedinSalesNavigator';
import { LinkedinSearch } from './AudienceForms/LinkedinSearch';
import { LinkedinUrl } from './AudienceForms/LinkedinUrl';
import { LinkedinZapier } from './AudienceForms/LinkedinZapier';
import { TwitterCsvUpload } from './AudienceForms/TwitterCsvUpload';

export const Audience = () => {
  const { campaign, isCampaignLoading } = useSingleCampaignProps();
  const { campaignPageType, category } = useLocationState<ILocationStateSingleCampaign>();
  const isLinkedinOnly = category
    ? [
        CampaignCategory.LINKEDIN,
        CampaignCategory.INMAIL,
        CampaignCategory.GROUP,
        CampaignCategory.POST,
        CampaignCategory.EVENT,
      ].includes(category as CampaignCategory)
    : !!campaign?.isLinkedinOnly;

  const campaignAudienceType = campaignPageType || convertCampaignTypeToAudienceType(campaign);
  const isCsvCampaign = campaignAudienceType === 'csv-upload';

  switch (true) {
    case isCsvCampaign && category === CampaignCategory.EMAIL:
      return <EmailCsvUpload />;
    case isCsvCampaign && category === CampaignCategory.TWITTER:
      return <TwitterCsvUpload />;
    case isCsvCampaign &&
      [CampaignCategory.LINKEDIN, CampaignCategory.MULTICHANNEL, CampaignCategory.INMAIL].includes(
        category as CampaignCategory,
      ):
      return <LinkedinCsvUpload isLinkedinOnly={isLinkedinOnly} />;
    case campaignAudienceType === 'zapier':
      return <LinkedinZapier isLinkedinOnly={isLinkedinOnly} />;
    case campaignAudienceType === 'search':
      return <LinkedinSearch isLinkedinOnly={isLinkedinOnly} />;
    case campaignAudienceType === 'post':
      return <LinkedinPost />;
    case campaignAudienceType === 'event':
      return <LinkedinEvent />;
    case campaignAudienceType === 'sales-navigator':
      return <LinkedinSalesNavigator isLinkedinOnly={isLinkedinOnly} />;
    case campaignAudienceType === 'url':
      return <LinkedinUrl isLinkedinOnly={isLinkedinOnly} />;
    case campaignAudienceType === 'group':
      return <LinkedinGroup />;
    default:
      return isCampaignLoading ? (
        <Box py="80px" display="flex" justifyContent="center">
          <Spinner type="dots" size="18px" />
        </Box>
      ) : null;
  }
};
