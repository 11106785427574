import { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { css, Global, ThemeProvider } from '@emotion/react';

import { IS_SHOW_WHITE_LABEL } from 'src/constants';
import { DeactivatedWhiteLabel } from 'src/containers/DeactivatedApp';
import { useAppSelector, useBaseTheme, useCompanyTheme, useWhiteLabel } from 'src/hooks';
import { AppLoading } from 'src/layouts';

interface IStyleProvider {
  withLoader?: boolean;
}

export const StyleProvider = ({ children, withLoader = true }: PropsWithChildren<IStyleProvider>) => {
  const { isAuth } = useAppSelector((state) => state.user);
  const {
    isLoading,
    whitelabel: { isActive, isWhiteLabelUser },
  } = useWhiteLabel();

  const { companyName, favicon } = useCompanyTheme();
  const { baseTheme, cssVarsThemePalette } = useBaseTheme();

  const resolveBody = () => (!isActive && isWhiteLabelUser && !isAuth ? <DeactivatedWhiteLabel /> : <>{children}</>);

  if (IS_SHOW_WHITE_LABEL && withLoader && isLoading) {
    return <AppLoading />;
  }

  return (
    <ThemeProvider theme={baseTheme}>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href={favicon} type="image/x-icon" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="apple-touch-icon" href={favicon} />
        <title>{companyName}</title>
      </Helmet>
      <Global
        styles={css`
          :root {
            ${cssVarsThemePalette}
          }
        `}
      />
      {resolveBody()}
    </ThemeProvider>
  );
};
