import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createTrend } from 'src/api';
import { closeModal } from 'src/store/modal.slice';
import { ICreateTrendRequest, ICustomAxiosError, ITrend } from 'src/types';
import { useRefreshTrendPosts } from '../trends';

export const useCreateTrend = (options?: UseMutationOptions<ITrend, ICustomAxiosError, ICreateTrendRequest>) => {
  const queryClient = useQueryClient();
  const { refreshTrendPosts } = useRefreshTrendPosts();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getTrendsKey = ['get-trends'];

  const { mutate, ...rest } = useMutation<ITrend, ICustomAxiosError, ICreateTrendRequest, { prevData: ITrend[] }>(
    'create-trend',
    createTrend,
    {
      ...options,
      onMutate: async (trend) => {
        options?.onMutate?.(trend);
        await queryClient.cancelQueries(getTrendsKey);

        const prevData = queryClient.getQueryData(getTrendsKey);

        queryClient.setQueryData<(ITrend | ICreateTrendRequest)[] | undefined>(getTrendsKey, (trends) => [
          ...(trends || []),
          trend,
        ]);

        return { prevData: prevData as ITrend[] };
      },
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        refreshTrendPosts(data.id);
        navigate(`/trends?trendId=${data.id}`);
        dispatch(closeModal());
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueryData(getTrendsKey, context?.prevData);
      },
      onSettled: async () => {
        await queryClient.invalidateQueries(getTrendsKey);
      },
    },
  );

  return {
    createTrend: mutate,
    ...rest,
  };
};
