import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Box, CircularProgress } from '@mui/material';
import MuiTable, { TableProps } from '@mui/material/Table';

const ScrollableContainer = styled(Box)`
  width: 100%;
  max-width: 100vw;
  overflow-x: auto;
  border-radius: 5px;
  background-color: #ffffff;

  &::-webkit-scrollbar {
    height: 24px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0 0 5px 5px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100px;
    background-color: ${({ theme }) => theme.palette.gray.main};
    border: 7px solid #fff;
  }
`;

const StyledTable = styled(MuiTable, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ disabled?: boolean; highlightSticky?: boolean; hideHead?: boolean }>`
  table-layout: fixed;
  border-radius: 5px;
  height: fit-content;

  & tr:first-of-type th:first-of-type {
    border-top-left-radius: 5px;
  }

  & tr:first-of-type th:last-of-type {
    border-top-right-radius: 5px;
  }

  ${({ disabled }) => disabled && 'opacity: 0.5; pointer-events: none;'}

  ${({ hideHead }) => hideHead && '& > thead th { padding: 0; border-bottom: none }'}
  
  & .sticky {
    filter: ${({ highlightSticky }) => (highlightSticky ? 'drop-shadow(-2px 0px 2px #e8e8e8)' : 'none')};
  }
`;

const Placeholder = styled(Box)`
  padding: 30px 15px 20px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.gray.light};
  text-align: center;
`;

interface ITableProps extends Omit<TableProps, 'placeholder'> {
  disabled?: boolean;
  placeholder?: false | ReactNode;
  processing?: boolean;
  processingContent?: ReactNode;
  hideHead?: boolean;
}

export const Table = ({ children, processing, processingContent, placeholder, ...restProps }: ITableProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [highlightSticky, setHighlightSticky] = useState(false);

  const checkHighlightSticky = useCallback(() => {
    const current = wrapperRef.current;

    return (
      !!current &&
      current.clientWidth < current.scrollWidth &&
      current.scrollLeft + current.offsetWidth < current.scrollWidth
    );
  }, [wrapperRef]);

  useEffect(() => {
    setHighlightSticky(checkHighlightSticky());
  }, [wrapperRef, processing]);

  if (processing) {
    return (
      <Box textAlign="center" pt="30px">
        {processingContent || <CircularProgress />}
      </Box>
    );
  }

  if (placeholder) {
    return <Placeholder>{placeholder}</Placeholder>;
  }

  return (
    <ScrollableContainer ref={wrapperRef} onScroll={() => setHighlightSticky(checkHighlightSticky())}>
      <StyledTable highlightSticky={highlightSticky} {...restProps}>
        {children}
      </StyledTable>
    </ScrollableContainer>
  );
};
