import { CSSProperties, MouseEvent, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@mui/material';

import { Typography } from '../ui';

interface ShowMoreProps extends PropsWithChildren {
  maxLines?: number;
}

const Text = styled.p`
  display: -webkit-box;
  word-break: break-word;
  line-height: 16px;
`;

const ShowMoreText = styled(Typography)`
  cursor: pointer;
`;

const CONTENT_PREVIEW_HEIGHT = 60;

export const ShowMore = ({ maxLines = 3, children }: ShowMoreProps) => {
  const { palette } = useTheme();
  const contentRef = useRef<HTMLParagraphElement>(null);

  const [needShowMore, setNeedShowMore] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const onShowMoreCLick = (e: MouseEvent<HTMLParagraphElement>) => {
    e.stopPropagation();
    setShowMore((prev) => !prev);
  };

  const textStyles = useMemo<CSSProperties>(
    () => ({
      WebkitLineClamp: maxLines,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }),
    [maxLines],
  );

  useEffect(() => {
    setNeedShowMore(!!contentRef?.current && contentRef?.current?.scrollHeight > CONTENT_PREVIEW_HEIGHT);
  }, [contentRef]);

  return (
    <>
      <Text ref={contentRef} style={showMore ? {} : textStyles}>
        {children}
      </Text>
      {needShowMore && (
        <ShowMoreText
          component="span"
          onClick={onShowMoreCLick}
          color="brand.300"
          hoverColor={palette.brand[400]}
          mt="10px"
        >
          Show {showMore ? 'less' : 'more'}
        </ShowMoreText>
      )}
    </>
  );
};
