import { useQuery, UseQueryOptions } from 'react-query';

import { getIgnoredCampaignLeads } from 'src/api';
import { ICustomAxiosError, IGetIgnoredCampaignLeadRequest, IGetIgnoredCampaignLeadResponse } from 'src/types';

export const useGetIgnoredCampaignLeads = (
  campaignId: number,
  params: IGetIgnoredCampaignLeadRequest,
  options?: UseQueryOptions<IGetIgnoredCampaignLeadResponse, ICustomAxiosError>,
) => {
  return useQuery<IGetIgnoredCampaignLeadResponse, ICustomAxiosError>(
    ['get-campaign-ignored-leads', campaignId, JSON.stringify(params)],
    () => getIgnoredCampaignLeads(campaignId, params),
    options,
  );
};
