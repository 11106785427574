import { useQuery, UseQueryOptions } from 'react-query';

import { exchangeTemporaryToken } from 'src/api';
import { IAuthResponse, ICustomAxiosError } from 'src/types';

export const useExchangeTemporaryToken = (
  token: string,
  options?: UseQueryOptions<IAuthResponse, ICustomAxiosError>,
) => {
  return useQuery<IAuthResponse, ICustomAxiosError>(
    ['exchange-token', token],
    () => exchangeTemporaryToken(token),
    options,
  );
};
