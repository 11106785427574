import { Spinner } from 'src/components';
import { Plan, TeamUserRole } from 'src/enums';
import { useAppSelector } from 'src/hooks';
import { AppLayout } from 'src/layouts';
import { useGetBillingInfo } from 'src/reactQueries';
import { BillingInformation } from './BillingInformation';
import { BillingLayout } from './BillingLayout';
import { CreateSubscription, EditSubscription } from './Subscriptions';

export const Billing = ({ isEditPlan = false }: { isEditPlan?: boolean }) => {
  const team = useAppSelector((state) => state.team.data);

  const { billingInfo, isLoading } = useGetBillingInfo();

  const renderBody = () => {
    if (isLoading) {
      return <Spinner />;
    }

    if (isEditPlan) {
      return <EditSubscription seatsOnly={!!billingInfo?.hasCustomPlan} />;
    }

    if (
      (billingInfo?.stripeSubscriptionObject && team?.plan !== Plan.TRIAL) ||
      team?.teamUser.role !== TeamUserRole.OWNER ||
      billingInfo?.hasLifetimeDeal
    ) {
      return <BillingInformation />;
    }

    return <CreateSubscription />;
  };

  return (
    <AppLayout>
      <BillingLayout isEditPlan={isEditPlan} />
      {renderBody()}
    </AppLayout>
  );
};
