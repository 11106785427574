import styled from '@emotion/styled';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { HeaderButton } from 'src/components';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.primary.wlLight};
  cursor: pointer;
`;

interface IRefreshButton {
  variant: 'button' | 'icon';
  onClick: () => void;
  processing?: boolean;
  label?: string;
}

export const RefreshButton = ({ variant, onClick, processing, label }: IRefreshButton) => {
  switch (variant) {
    case 'button':
      return (
        <HeaderButton processing={processing} onClick={onClick}>
          {label}
        </HeaderButton>
      );
    case 'icon':
      return (
        <IconWrapper onClick={onClick}>
          <FontAwesomeIcon icon={faSync} spin={processing} />
        </IconWrapper>
      );
    default:
      return null;
  }
};
