import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';
import Creatable from 'react-select/creatable';
import { Box, styled } from '@mui/material';

import { ReactComponent as XMarkIcon } from 'src/assets/icons/x-mark.svg';
import { Button, selectTagStyles } from 'src/components';
import { getRandomHexColor } from 'src/helpers';
import { useAddLeadsTags, useCreateTag, useGetAllTags } from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';
import { IModalProps, ITag } from 'src/types';
import { ModalBody, ModalFooter } from '../_components';

const Label = styled(Box)`
  margin-bottom: 14px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: 'ProximaSoft', sans-serif;
  font-size: 14px;
  line-height: 16px;
`;

interface IModalAddConnectionsTagsProps {
  type: 'connections' | 'leads';
  entityUrns: string[];
  leadTags?: ITag[];
  withDeleteConfirm?: boolean;
}

export const ModalAddConnectionsTags = ({ params, onCancel }: IModalProps<IModalAddConnectionsTagsProps>) => {
  const dispatch = useDispatch();
  const { type, entityUrns } = params;
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);

  const { tags } = useGetAllTags();
  const options = useMemo(() => tags?.map((tag: ITag) => ({ label: tag.tag, ...tag, value: tag.tag })) || [], [tags]);

  const { addLeadsTags } = useAddLeadsTags(type);
  const { createTag } = useCreateTag({
    withNotCloseModal: true,
    onMutate: ({ color, tag }) => {
      setSelectedTags((prev) => [...prev, { color, tag, id: -1, snId: null, leadsCount: 0, connectionsCount: 0 }]);
    },
    onSuccess: (data) => {
      setSelectedTags((prev) => prev.map((tag) => (tag.tag === data.tag ? data : tag)));
    },
    onError: (_error, variables) => {
      setSelectedTags((prev) => prev.filter(({ tag }) => tag !== variables.tag));
    },
  });

  const onCreateTag = (tag: string) => createTag({ tag, color: getRandomHexColor() });

  const confirmHandler = () => {
    addLeadsTags({
      leadIds: entityUrns,
      tagIds: selectedTags.map(({ id }) => id),
    });
  };

  const closeHandler = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  return (
    <>
      <ModalBody>
        <Label>Select tags</Label>

        <Creatable
          isMulti
          isClearable={true}
          placeholder="Select Tags"
          value={selectedTags.map((tag) => ({ label: tag.tag, ...tag, value: tag.tag }))}
          options={options}
          styles={selectTagStyles}
          components={{
            MultiValueRemove: (props) => {
              return (
                <components.MultiValueRemove {...props}>
                  <XMarkIcon color={props.data.color} />
                </components.MultiValueRemove>
              );
            },
          }}
          onChange={(values) => setSelectedTags(values as ITag[])}
          onCreateOption={onCreateTag}
        />
      </ModalBody>

      <ModalFooter>
        <Button variant="gray" onClick={closeHandler}>
          Cancel
        </Button>
        <Button onClick={confirmHandler}>Add tags</Button>
      </ModalFooter>
    </>
  );
};
