import Select, {
  components as ReactSelectComponents,
  GroupBase,
  Props as ReactSelectProps,
  SelectComponentsConfig,
  StylesConfig,
} from 'react-select';
import { useTheme } from '@emotion/react';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Palette } from '@mui/material';

import { ReactComponent as XMarkIcon } from 'src/assets/icons/x-mark.svg';
import { getContrastYIQ } from 'src/helpers';
import { IColorSelectOption } from 'src/types';

export const defaultStyles: StylesConfig<IColorSelectOption, false, GroupBase<IColorSelectOption>> = {
  input: (provided) => ({
    ...provided,
    display: 'flex',
    flex: 1,
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: '#fff',
    border: '1px solid var(--gray-200)',
    borderRadius: '4px',
    boxShadow: 'none',
    display: 'flex',
    fontSize: '14px',
    minHeight: '30px',
    height: '100%',
    padding: '0 5px',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: state.data.color ?? 'var(--success-700)',
    borderRadius: '20px',
    display: 'flex',
    margin: '0 5px 0 0',
    padding: '0 5px 0 10px',
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: state.data?.color ? getContrastYIQ(state.data.color) : '#ffffff',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
  }),
  multiValueRemove: (provided) => {
    return {
      ...provided,
      color: '#ffffff !important',
      cursor: 'pointer',

      svg: {
        filter: 'drop-shadow(0 0 1px #000)',
      },

      ['&:hover']: {
        backgroundColor: 'transparent',
        color: '#ffffff',
      },
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
      alignItems: 'center',
      backgroundColor: 'transparent !important',
      cursor: 'pointer',
      display: 'flex',
      fontSize: '14px',
      padding: '10px 15px',
      transition: '0.2s',
      color: '#333',
      ['&:hover']: {
        color: 'var(--info-dark)',
      },
      ['&::before']: {
        backgroundColor: state.data.color,
        borderRadius: '50%',
        color: state.data.color,
        content: "''",
        display: state.data.color ? 'inline-block' : 'none',
        height: '10px',
        marginRight: '5px',
        width: '10px',
      },
    };
  },
  menu: (provided) => {
    return {
      ...provided,
      zIndex: 10,
    };
  },
};

export const defaultComponents = (
  palette: Palette,
): SelectComponentsConfig<IColorSelectOption, boolean, GroupBase<IColorSelectOption>> => ({
  DropdownIndicator: ({ selectProps, ...otherProps }) => (
    <ReactSelectComponents.DropdownIndicator selectProps={selectProps} {...otherProps}>
      <Box width="16px" display="flex" justifyContent="center">
        <FontAwesomeIcon icon={selectProps.menuIsOpen ? faCaretUp : faCaretDown} color={palette.primary.main} />
      </Box>
    </ReactSelectComponents.DropdownIndicator>
  ),
  MultiValueRemove: (props) => (
    <ReactSelectComponents.MultiValueRemove {...props}>
      <XMarkIcon />
    </ReactSelectComponents.MultiValueRemove>
  ),
});

export type TSelectGenericProps = ReactSelectProps<IColorSelectOption>;

export const SelectBasic = ({ styles, components, ...otherProps }: TSelectGenericProps) => {
  const { palette } = useTheme();

  return (
    <Select
      styles={
        { ...defaultStyles, ...styles } as StylesConfig<IColorSelectOption, boolean, GroupBase<IColorSelectOption>>
      }
      components={{ ...defaultComponents(palette), ...components }}
      {...otherProps}
    />
  );
};
