import classNames from 'classnames';
import styled from '@emotion/styled';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, BoxProps } from '@mui/material';

import multichannelIcon from 'src/assets/icons/multichannel-icon.svg';
import { Tooltip } from 'src/components';
import { CampaignCategory } from 'src/enums';

const StyledWrapper = styled(Box)`
  border-radius: 4px;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
  &.dragging {
    background: white;
    cursor: grabbing;
  }
`;

const StyledIcon = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  color: #ffffff;
`;

type TCampaignIconSizes = 'medium' | 'large' | 'small';

interface ICampaignIconProps extends BoxProps {
  type?: CampaignCategory;
  size?: TCampaignIconSizes;
  tooltip?: string;
  disabled?: boolean;
}

const getIconProps = (type?: CampaignCategory) => {
  switch (type) {
    case 'multi-channel':
      return { color: 'transparent', svgIcon: multichannelIcon };
    case 'post':
    case 'group':
    case 'event':
    case 'linkedin':
    case 'inmail':
      return { color: '#0077b5', faIcon: faLinkedinIn };
    case 'email':
      return { color: '#ffae33', faIcon: faEnvelope };
    case 'twitter':
      return { color: '#000000', faIcon: faXTwitter };
    default:
      return { color: 'transparent' };
  }
};

const getSizeProps = (size: TCampaignIconSizes): { width: string; height: string; iconSize: SizeProp } => {
  switch (size) {
    case 'large':
      return { width: '50px', height: '50px', iconSize: 'lg' };
    case 'medium':
      return { width: '40px', height: '40px', iconSize: 'lg' };
    case 'small':
      return { width: '25px', height: '25px', iconSize: 'sm' };
    default:
      return { width: '40px', height: '40px', iconSize: 'lg' };
  }
};

export const CampaignIcon = ({ type, tooltip, size, disabled, className, ...restProps }: ICampaignIconProps) => {
  const { color, faIcon, svgIcon } = getIconProps(type);
  const { iconSize, height, width } = getSizeProps(size ?? 'large');

  return (
    <Tooltip title={tooltip}>
      <StyledWrapper
        id={`${type}-step`}
        data-intercom-target={`${type}-step`}
        width={width}
        height={height}
        bgcolor={color}
        className={classNames(className, { disabled })}
        {...restProps}
      >
        {faIcon && (
          <StyledIcon>
            <FontAwesomeIcon size={iconSize} icon={faIcon} />
          </StyledIcon>
        )}
        {svgIcon && <img width="100%" height="100%" src={multichannelIcon} alt="" />}
      </StyledWrapper>
    </Tooltip>
  );
};
