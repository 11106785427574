import { ReactNode, useEffect } from 'react';
import styled from '@emotion/styled';
import { faLinkedin, faSkype, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faBuilding, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, useTheme } from '@mui/material';

import { ReactComponent as EducationIcon } from 'src/assets/icons/education.svg';
import { ReactComponent as EmployerIcon } from 'src/assets/icons/employer.svg';
import { ReactComponent as LocationIcon } from 'src/assets/icons/location.svg';
import { ReactComponent as EmailIcon } from 'src/assets/icons/mailing.svg';
import { Link, Typography } from 'src/components';
import { Features } from 'src/enums';
import { isLinkedinEntityUrn } from 'src/helpers';
import { useAppSelector, useTeamPlan } from 'src/hooks';
import { ILeadPerson } from 'src/types';

const Detail = styled(Box)`
  display: grid;
  grid-template-columns: 30px 1fr;
  gap: 12px;
  align-items: center;
  margin-bottom: 16px;

  & > div:first-of-type {
    flex-shrink: 0;
  }
`;

const IconWrapper = styled(Box)`
  display: flex;
  justify-content: center;
`;

const Title = styled(Typography)`
  line-height: 16px;
  font-size: 15px;
  color: ${({ theme }) => theme.palette.gray[500]};
`;

const Value = styled(Typography)`
  line-height: 20px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.9);
`;

const NOT_AVAILABLE = 'No available in your plan';

interface PersonalDetailsProps {
  person?: ILeadPerson;
  label?: ReactNode;
  onShowChange?: (isHidePersonalDetails: boolean) => void;
  hideLinkedin?: boolean;
}

export const PersonalDetails = ({ person, label, onShowChange, hideLinkedin }: PersonalDetailsProps) => {
  const { palette } = useTheme();

  const { checkFeature } = useTeamPlan();
  const isAdvancedDetailsAllowed = checkFeature(Features.connection);

  const { selectedConversation } = useAppSelector((state) => state.conversation);

  const {
    currentEmployer,
    location,
    email,
    twitterHandle,
    linkedInData,
    skype,
    phone,
    linkedInUrn = selectedConversation?.entityUrn,
    key,
    linkedInHandle,
  } = person || {};

  const linkedinUserKey = linkedInUrn || linkedInHandle || (isLinkedinEntityUrn(key) && key);

  const checkIfExistOrAvailable = (value: string | undefined) => {
    return !(!value || value === NOT_AVAILABLE);
  };

  const showList = {
    isShowLinkedin: !!linkedinUserKey,
    isShowCompany: checkIfExistOrAvailable(linkedInData?.company),
    isShowEmployer: checkIfExistOrAvailable(currentEmployer),
    isShowSchool: checkIfExistOrAvailable(linkedInData?.school),
    isShowEmail: checkIfExistOrAvailable(email) && isAdvancedDetailsAllowed,
    isShowLocation: checkIfExistOrAvailable(location),
    isShowPhone: checkIfExistOrAvailable(phone) && isAdvancedDetailsAllowed,
    isShowSkype: checkIfExistOrAvailable(skype) && isAdvancedDetailsAllowed,
    isShowTitter: checkIfExistOrAvailable(twitterHandle) && isAdvancedDetailsAllowed,
  };

  const isHidePersonalDetails = !Object.values(showList).some(Boolean);

  useEffect(() => {
    onShowChange?.(isHidePersonalDetails);
  }, [isHidePersonalDetails]);

  if (isHidePersonalDetails) {
    return null;
  }

  return (
    <Box data-testid="personal_details">
      <Typography fontSize="14px" color="gray.dark" my="10px" semibold>
        {label}
      </Typography>

      {showList.isShowLinkedin && !hideLinkedin && (
        <Detail>
          <IconWrapper>
            <FontAwesomeIcon icon={faLinkedin} color={palette.gray[400]} size="xl" />
          </IconWrapper>
          <Box>
            <Title>Linkedin</Title>
            <Value>
              <Link
                tooltip="View profile on Linkedin"
                to={`https://www.linkedin.com/in/${linkedinUserKey}`}
                openNewTab
                external
                rightIcon
                fontSize="16px"
                variant="lightBlue"
              >
                View LinkedIn Profile
              </Link>
            </Value>
          </Box>
        </Detail>
      )}
      {showList.isShowLocation && (
        <Detail>
          <IconWrapper>
            <LocationIcon color={palette.gray[400]} height="24px" width="24px" />
          </IconWrapper>

          <Box>
            <Title>Location</Title>
            <Value>{location}</Value>
          </Box>
        </Detail>
      )}
      {showList.isShowSchool && (
        <Detail>
          <IconWrapper>
            <EducationIcon color={palette.gray[400]} height="24px" width="24px" />
          </IconWrapper>

          <Box>
            <Title>Education</Title>
            <Value>{linkedInData?.school}</Value>
          </Box>
        </Detail>
      )}
      {showList.isShowCompany && !showList.isShowEmployer && (
        <Detail>
          <IconWrapper>
            <FontAwesomeIcon icon={faBuilding} color={palette.gray[400]} size="xl" />
          </IconWrapper>

          <Box>
            <Title>Company</Title>
            <Value>{linkedInData?.company}</Value>
          </Box>
        </Detail>
      )}
      {showList.isShowEmployer && (
        <Detail>
          <IconWrapper>
            <EmployerIcon color={palette.gray[400]} height="24px" width="24px" />
          </IconWrapper>

          <Box>
            <Title>Current Employer</Title>
            <Value>{currentEmployer}</Value>
          </Box>
        </Detail>
      )}
      {showList.isShowEmail && (
        <Detail>
          <IconWrapper>
            <EmailIcon color={palette.gray[400]} height="24px" width="24px" />
          </IconWrapper>

          <Box>
            <Title>Email</Title>
            <Value>
              <Link to={`mailto:${email}`} external openNewTab fontSize="16px" variant="lightBlue">
                {email}
              </Link>
            </Value>
          </Box>
        </Detail>
      )}
      {showList.isShowPhone && (
        <Detail>
          <IconWrapper>
            <FontAwesomeIcon icon={faPhone} color={palette.gray[400]} size="lg" />
          </IconWrapper>

          <Box>
            <Title>Phone</Title>
            <Value>
              <Link to={`tel:${phone}`} external openNewTab fontSize="16px" variant="lightBlue">
                {phone}
              </Link>
            </Value>
          </Box>
        </Detail>
      )}
      {showList.isShowSkype && (
        <Detail>
          <IconWrapper>
            <FontAwesomeIcon icon={faSkype} color={palette.gray[400]} size="xl" />
          </IconWrapper>

          <Box>
            <Title>Skype</Title>
            <Value>{skype}</Value>
          </Box>
        </Detail>
      )}
      {showList.isShowTitter && (
        <Detail>
          <IconWrapper>
            <FontAwesomeIcon icon={faXTwitter} color={palette.gray[400]} size="xl" />
          </IconWrapper>

          <Box>
            <Title>X (Twitter)</Title>
            <Value>
              <Link to={`https://twitter.com/${twitterHandle}`} external openNewTab fontSize="16px" variant="lightBlue">
                {twitterHandle}
              </Link>
            </Value>
          </Box>
        </Detail>
      )}
    </Box>
  );
};
