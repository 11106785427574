import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Button, Input, Link, Tooltip, Typography } from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features } from 'src/enums';
import { useCompanyTheme, useCopyToClipboard, useTeamPlan } from 'src/hooks';
import { useGetUserAccount } from 'src/reactQueries';
import { Title } from './ui';

const IntegrationContainer = styled(Box)`
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 1px 1px 0 ${({ theme }) => theme.palette.light.dark};
  border-radius: 5px;
`;

const WebhookInput = styled(Input)`
  height: 34px;
  caret-color: transparent;
  background-color: #eee;
`;

export const WebhookIntegration = ({ fullWidth = false }: { fullWidth?: boolean }) => {
  const { palette } = useTheme();
  const { shortCompanyName } = useCompanyTheme();
  const { userMe } = useGetUserAccount();

  const { copy, isCopied } = useCopyToClipboard();

  const { checkFeature } = useTeamPlan();
  const isWebhookAllowed = checkFeature(Features.zappierAndWebhook);

  return (
    <IntegrationContainer width={fullWidth ? '100%' : '50%'}>
      <Title>Webhook API key</Title>
      <Typography mt="20px" lineHeight="22px" maxWidth="450px">
        Your Webhook API key allows you to connect all {shortCompanyName} webhooks via API
      </Typography>
      {isWebhookAllowed && (
        <Typography lineHeight="22px">
          Please click{' '}
          <Link variant="darkBlue" semibold underline to="/documentation/webhook">
            here
          </Link>{' '}
          to review the <b>Documentation</b> of webhooks
        </Typography>
      )}
      <Box display="flex" gap="20px" alignItems="center" mt="25px">
        <Box width="300px">
          <WebhookInput
            readOnly
            value={isWebhookAllowed ? userMe?.webhookToken : ''}
            name="webhook"
            disabled={!isWebhookAllowed}
          />
        </Box>
        {isWebhookAllowed ? (
          <>
            <Button onClick={() => copy(userMe?.webhookToken)}>Copy</Button>
            {isCopied && (
              <Typography color="violet.dark" fontStyle="italic">
                Copied!
              </Typography>
            )}
          </>
        ) : (
          <Tooltip placement="bottom" title={PLAN_TOOLTIPS.professional}>
            <FontAwesomeIcon color={palette.warning.main} icon={faExclamationTriangle} />
          </Tooltip>
        )}
      </Box>
    </IntegrationContainer>
  );
};
