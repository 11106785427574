import Linkify from 'linkify-react';
import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faCheckSquare, faEye, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Attachment, HtmlParser, Tag, Typography } from 'src/components';
import { CampaignSequenceStepType } from 'src/enums';
import { configSequenceDetails, separateNumWithComma } from 'src/helpers';
import { useGetAllTags } from 'src/reactQueries';
import { ISequenceStep, ITag } from 'src/types';
import { CampaignIcon } from './CampaignIcon';

const Highlight = styled(Box)<{ borderColor: string }>`
  margin-left: 20px;
  padding-bottom: 40px;
  padding-top: 10px;
  box-sizing: border-box;
  border-left: 1px solid ${({ borderColor }) => borderColor};
`;

const Wrapper = styled(Box)`
  margin-left: 30px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Options = styled(Box)`
  font-size: 14px;
  line-height: 24px;
  font-family: 'ProximaSoft', sans-serif;
  display: grid;
  grid-template-columns: 20px 1fr;
  align-items: center;
`;

interface ISequenceStepProps {
  index?: number;
  highlight: boolean;
  sequence: ISequenceStep;
  showCompletedLeads?: boolean;
  completedLeads?: number;
}

export const SequenceStep = ({
  index = 0,
  highlight,
  sequence,
  showCompletedLeads,
  completedLeads,
}: ISequenceStepProps) => {
  const { palette } = useTheme();
  const {
    type,
    auto_endorse,
    auto_post_like,
    auto_follow,
    view,
    delay_time_unit,
    delay_number,
    tags,
    attachments,
    posts_count,
    endorsements_count,
  } = sequence;
  const { icon: stepIcon, title: stepTitle, messages } = configSequenceDetails(type, sequence) ?? {};
  const time = `${delay_number} ${delay_time_unit}`;
  const titles =
    messages &&
    messages?.map(({ key, title: messageTitle }) => ({
      title: messageTitle,
      description: sequence[key] as string,
    }));

  const list = [
    {
      label: 'View LinkedIn Profile',
      icon: faEye,
      isShow: view,
    },
    {
      label: `Endorse their first skill (${endorsements_count})`,
      icon: faCheckSquare,
      isShow: auto_endorse,
    },
    {
      label: `Like their last post (${posts_count})`,
      icon: faPlusSquare,
      isShow: auto_post_like,
    },
    {
      label: 'Follow their LinkedIn profile',
      icon: faPlusSquare,
      isShow: auto_follow,
    },
  ];

  const isSearchInfoShown = list?.some(({ isShow }) => isShow);
  const isParserNeeded = ![
    CampaignSequenceStepType.LINKED_IN_CONNECT,
    CampaignSequenceStepType.IN_MAIL_MESSAGE,
    CampaignSequenceStepType.LINKED_IN_MESSAGE,
    CampaignSequenceStepType.TWITTER_DM,
  ].includes(sequence.type);

  const { tags: allTags } = useGetAllTags();

  const previewTags = useMemo(() => {
    return allTags && tags
      ? tags?.map((tag) => allTags.find((allTag) => allTag.id === tag.key) as ITag).filter(Boolean)
      : [];
  }, [tags, allTags]);

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <CampaignIcon type={stepIcon} size="medium" mr="20px" />
        <Typography color="gray.dark" semibold>
          Step {index + 1} - {stepTitle}
        </Typography>

        {!!index && <Typography ml="15px">{time} from previous step</Typography>}
      </Box>

      <Highlight borderColor={highlight ? palette.lightGray.dark : 'transparent'}>
        {isSearchInfoShown || titles || !!previewTags.length ? (
          <Wrapper>
            {isSearchInfoShown && (
              <Box>
                {list?.map(
                  ({ icon, label, isShow }) =>
                    isShow && (
                      <Options key={label}>
                        <FontAwesomeIcon color="#0077b5" icon={icon} />
                        {label}
                      </Options>
                    ),
                )}
              </Box>
            )}
            {titles?.map(
              ({ title, description }) =>
                description &&
                title && (
                  <Box key={title + description} p="10px">
                    <Typography semibold>{title}</Typography>
                    {isParserNeeded ? (
                      <Typography component="div">
                        <HtmlParser parseText={description} />
                      </Typography>
                    ) : (
                      <Typography>
                        <Linkify options={{ nl2br: true }}>{description}</Linkify>
                      </Typography>
                    )}
                  </Box>
                ),
            )}
            {!!attachments?.length && (
              <Box px="10px">
                <Typography semibold mb="10px">
                  Attachments:
                </Typography>
                <Box display="flex" flexWrap="wrap" gap="10px 15px">
                  {attachments?.map(({ file_uri, file_name }) => (
                    <Attachment margin="0" key={file_uri} name={file_name} />
                  ))}
                </Box>
              </Box>
            )}
            {!!previewTags.length && (
              <Box p="10px">
                <Typography semibold mb="10px">
                  Tags:
                </Typography>
                <Box display="flex" flexWrap="wrap" gap="10px 0">
                  {previewTags?.map((tag) => <Tag key={tag.id} tag={tag.tag} color={tag.color} height="22px" />)}
                </Box>
              </Box>
            )}

            {type === CampaignSequenceStepType.TWITTER_DM && (
              <Typography color="red" pl="10px">
                Warning: X (Twitter) DM feature is under maintenance. The step won’t be completed as expected.
              </Typography>
            )}
          </Wrapper>
        ) : null}
        {showCompletedLeads && (
          <Box display="flex" justifyContent="space-between" alignItems="center" ml="30px" mt="10px">
            <Typography semibold color="gray.600">
              Leads that have passed this step:
            </Typography>
            <Typography medium color="gray.600">
              {separateNumWithComma(completedLeads)}
            </Typography>
          </Box>
        )}
      </Highlight>
    </Box>
  );
};
