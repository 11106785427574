import classNames from 'classnames';
import { ReactNode, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faCloudUpload, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { HiddenFileInput, Tooltip, Typography } from 'src/components';
import { createTempImageElement } from 'src/helpers';
import { showToast } from 'src/store/toast.slice';
import { IBrandingForm } from '../WhiteLabel/Branding';

const Label = styled.label`
  box-sizing: border-box;
  padding: 0 16px;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 15px;
  background: #ffffff;
  border: 1px solid #bfbfbf;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.016);
  border-radius: 4px;
  width: 180px;
  cursor: pointer;

  &.error {
    border: 1px solid rgb(246, 39, 19);
    box-shadow:
      inset 0 1px 1px rgb(0 0 0 / 8%),
      0 0 8px rgb(246 39 19 / 60%);
  }
`;

export interface IUploadInputProps {
  label?: string;
  accept?: string;
  tooltip?: ReactNode;
  description?: ReactNode;
  tooltipHint?: ReactNode;
  form: UseFormReturn<IBrandingForm>;
  name: 'favicon' | 'logo' | 'logoIcon';
  validation: {
    width: number;
    height: number;
  };
}

export const UploadInput = ({
  label,
  accept,
  description,
  tooltipHint = '',
  tooltip,
  validation,
  name,
  form,
}: IUploadInputProps) => {
  const { setValue, formState, watch, clearErrors } = form;
  const dispatch = useDispatch();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { isRequired, name: fileName } = watch(name);

  const onSelectClick = () => {
    const file = Array.from(fileInputRef?.current?.files ?? [])[0];

    createTempImageElement(file, async (element) => {
      const width = element.width;
      const height = element.height;

      if (width > validation.width) {
        return dispatch(
          showToast({
            type: 'error',
            message: 'Image has wrong width',
          }),
        );
      }

      if (height > validation.height) {
        return dispatch(
          showToast({
            type: 'error',
            message: 'Image has wrong height',
          }),
        );
      }

      clearErrors(name);

      return setValue(
        name,
        { isRequired, file: file, name: file.name, url: URL.createObjectURL(file) },
        { shouldValidate: true },
      );
    });
  };

  return (
    <Box>
      <Typography fontSize="14px" mb="6px">
        {label}{' '}
        <Typography mr="4px" inline color="error.light">
          *
        </Typography>
        {!!tooltipHint && (
          <Tooltip title={tooltipHint}>
            <FontAwesomeIcon size="sm" icon={faQuestionCircle} />
          </Tooltip>
        )}
      </Typography>
      <Tooltip title={tooltip}>
        <Label htmlFor={name} className={classNames({ error: formState.errors[name] })}>
          <FontAwesomeIcon icon={faCloudUpload} />{' '}
          <Typography crop fontSize="16px">
            {fileName || 'Upload'}
          </Typography>
        </Label>
      </Tooltip>
      <Typography color="#8C8C8C" fontSize="14px" mt="2px">
        {description}
      </Typography>
      <HiddenFileInput
        type="file"
        id={name}
        accept={accept || 'image/jpeg,image/jpg,image/png'}
        onChange={onSelectClick}
        ref={fileInputRef}
      />
    </Box>
  );
};
