import { useQuery, UseQueryOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { getBillingInfo } from 'src/api';
import { NotificationTypes, Plan, TeamUserRole, TrialStatus } from 'src/enums';
import { useAppSelector } from 'src/hooks';
import { addNotification, removeNotificationByType } from 'src/store/notification.slice';
import { IBillingInfo, ICustomAxiosError } from 'src/types';
import { useGetUserAccount } from '../users';

export const useGetBillingInfo = (options?: UseQueryOptions<IBillingInfo, ICustomAxiosError>) => {
  const dispatch = useDispatch();
  const { userMe } = useGetUserAccount();
  const { data: teamData } = useAppSelector((state) => state.team);

  const { data, ...rest } = useQuery<IBillingInfo, ICustomAxiosError>(['get-billing-info'], getBillingInfo, {
    enabled: !!userMe?.isEmailVerified && !!teamData && teamData.teamUser?.role === TeamUserRole.OWNER,
    ...options,
    onSuccess: (billingInfo) => {
      options?.onSuccess?.(billingInfo);
      if (billingInfo.plan === Plan.TRIAL && billingInfo.trial === TrialStatus.STARTED) {
        dispatch(addNotification({ type: NotificationTypes.TRIAL }));
      }

      if (
        billingInfo.plan &&
        billingInfo.plan !== Plan.TRIAL &&
        billingInfo.trial === TrialStatus.ENDED &&
        billingInfo.stripeSubscriptionObject?.cancel_at_period_end
      ) {
        dispatch(addNotification({ type: NotificationTypes.SUBSCRIPTION_CANCELLED }));
      } else {
        dispatch(removeNotificationByType({ type: NotificationTypes.SUBSCRIPTION_CANCELLED }));
      }
    },
  });

  return {
    billingInfo: data,
    isSubscriptionCanceled:
      data?.plan &&
      data?.plan !== Plan.TRIAL &&
      data?.trial === TrialStatus.ENDED &&
      !!data?.stripeSubscriptionObject?.cancel_at_period_end,
    ...rest,
  };
};
