import { BillingTypeEnum, CurrencyEnum, Plan } from 'src/enums';

export const VAT_NAMES: Record<string, string> = {
  ae_trn: 'United Arab Emirates TRN',
  au_abn: 'Australian Business Number (AU ABN)',
  au_arn: 'Australian Taxation Office Reference Number',
  br_cnpj: 'Brazilian CNPJ number',
  br_cpf: 'Brazilian CPF number',
  bg_uic: 'Bulgaria Unified Identification Code',
  ca_bn: 'Canadian BN',
  ca_gst_hst: 'Canadian GST/HST number',
  ca_pst_bc: 'Canadian PST number (British Columbia)',
  ca_pst_mb: 'Canadian PST number (Manitoba)',
  ca_pst_sk: 'Canadian PST number (Saskatchewan)',
  ca_qst: 'Canadian QST number',
  ch_vat: 'Switzerland VAT number',
  cl_tin: 'Chilean TIN',
  es_cif: 'Spanish CIF number',
  eu_vat: 'European VAT number',
  eu_oss_vat: 'European One Stop Shop VAT number for non-Union scheme',
  eg_tin: 'Egyptian Tax Identification Number',
  gb_vat: 'United Kingdom VAT number',
  ge_vat: 'Georgian VAT',
  hk_br: 'Hong Kong BR number',
  hu_tin: 'Hungary tax number',
  id_npwp: 'Indonesian NPWP number',
  in_gst: 'Indian GST number',
  jp_cn: 'Japanese Corporate Number',
  jp_rn: 'Japanese Registered Foreign Businesses’ Registration Number',
  jp_trn: 'Japanese Tax Registration Number',
  is_vat: 'Icelandic VAT',
  il_vat: 'Israel VAT',
  ke_pin: 'Kenya Revenue Authority Personal Identification Number',
  kr_brn: 'Korean BRN',
  li_uid: 'Liechtensteinian UID number',
  mx_rfc: 'Mexican RFC number',
  my_frp: 'Malaysian FRP number',
  my_itn: 'Malaysian ITN',
  my_sst: 'Malaysian SST number',
  no_vat: 'Norwegian VAT number',
  nz_gst: 'New Zealand GST number',
  ph_tin: 'Philippines Tax Identification Number',
  ru_inn: 'Russian INN',
  ru_kpp: 'Russian KPP',
  sa_vat: 'Saudi Arabia VAT',
  sg_gst: 'Singaporean GST',
  sg_uen: 'Singaporean UEN',
  si_tin: 'Slovenia tax number',
  th_vat: 'Thai VAT',
  tw_vat: 'Taiwanese VAT',
  tr_tin: 'Turkish Tax Identification Number',
  ua_vat: 'Ukrainian VAT',
  us_ein: 'United States EIN',
  za_vat: 'South African VAT number',
};

export const VAT_EXAMPLES: Record<string, string> = {
  ae_trn: '123456789012345',
  au_abn: '12345678912',
  au_arn: '123456789123',
  br_cnpj: '01.234.456/5432-10',
  br_cpf: '123.456.789-87',
  bg_uic: '123456789',
  ca_bn: '123456789',
  ca_gst_hst: '123456789RT0002',
  ca_pst_bc: 'PST-1234-5678',
  ca_pst_mb: '123456-7',
  ca_pst_sk: '1234567',
  ca_qst: '1234567890TQ1234',
  ch_vat: 'CHE-123.456.789 MWST',
  cl_tin: '12.345.678-K',
  es_cif: 'A12345678',
  eu_vat: 'ATU12345678',
  eu_oss_vat: 'EU123456789',
  eg_tin: '123456789',
  gb_vat: 'GB123456789',
  ge_vat: '123456789',
  hk_br: '12345678',
  hu_tin: '12345678-1-23',
  id_npwp: '12.345.678.9-012.345',
  in_gst: '12ABCDE3456FGZH',
  jp_cn: '1234567891234',
  jp_rn: '12345',
  jp_trn: 'T1234567891234',
  is_vat: '123456',
  il_vat: '000012345',
  ke_pin: 'P000111111A',
  kr_brn: '123-45-67890',
  li_uid: 'CHE 123456789',
  mx_rfc: 'ABC010203AB9',
  my_frp: '12345678',
  my_itn: 'C 1234567890',
  my_sst: 'A12-3456-78912345',
  no_vat: '123456789MVA',
  nz_gst: '123456789',
  ph_tin: '123456789012',
  ru_inn: '1234567891',
  ru_kpp: '123456789',
  sa_vat: '123456789012345',
  sg_gst: 'M12345678X',
  sg_uen: '123456789F',
  si_tin: '12345678',
  th_vat: '1234567891234',
  tw_vat: '12345678',
  tr_tin: '0123456789',
  ua_vat: '123456789',
  us_ein: '12-3456789',
  za_vat: '4123456789',
};

export const OTHER_PRODUCTS = [
  'Dux Soup',
  'Linked Helper',
  'We-Connect',
  'Zopto',
  'Growth Lead',
  'Elink Pro',
  'Octopus CRM',
  'Skylead',
  'Linked Prospect',
  'Prospectin',
  'Linkelead',
  'Linkedomata',
  'Expandi',
  'Phantombuster',
  'Kennected',
  'Linked Assist',
  'Other',
];

export const PLAN_SEATS: Record<Plan, { seats: number; minSeats: number }> = {
  [Plan.INDIVIDUAL]: {
    seats: 1,
    minSeats: 1,
  },
  [Plan.STARTER]: {
    seats: 1,
    minSeats: 1,
  },
  [Plan.PERSONAL]: {
    seats: 1,
    minSeats: 1,
  },
  [Plan.BUSINESS]: {
    seats: 250,
    minSeats: 1,
  },
  [Plan.ENTERPRISE]: {
    seats: 250,
    minSeats: 5,
  },
  [Plan.AGENCY]: {
    seats: 250,
    minSeats: 10,
  },
  [Plan.TRIAL]: {
    seats: 5,
    minSeats: 1,
  },
};

export const BILLING_TYPE_NAMES: Record<BillingTypeEnum, string> = {
  [BillingTypeEnum.MONTHLY]: 'Monthly',
  [BillingTypeEnum.QUARTERLY]: 'Quarterly',
  [BillingTypeEnum.SEMESTRIAL]: 'Half Yearly',
  [BillingTypeEnum.ANNUAL]: 'Annually',
};

export const PLAN_SUB_DESCRIPTIONS: { [key in Plan]?: string } = {
  [Plan.INDIVIDUAL]: 'Cloud-based solution ideal for automated outreach on LinkedIn.',
  [Plan.STARTER]: 'Cloud-based solution ideal for automated outreach on LinkedIn.',
  [Plan.PERSONAL]: 'Ideal for single users interested in automating campaigns on Linkedin, Email & Twitter.',
  [Plan.BUSINESS]: 'Best choice for teams seeking optimal performance.',
  [Plan.ENTERPRISE]: 'Exclusive features and dedicated support for larger teams.',
  [Plan.AGENCY]: 'Exclusive features and dedicated support for larger teams.',
};

export const PLAN_INFO: {
  [p in Plan]?: {
    description: string;
    priceDescription: string;
    features: Array<string>;
    price: { [c in CurrencyEnum]: { [b in BillingTypeEnum]?: number } };
  };
} = {
  [Plan.STARTER]: {
    description: 'Cloud-based solution ideal for automated outreach on LinkedIn.',
    priceDescription: 'Per user, per month',
    features: [
      '1 Active campaign',
      'LinkedIn Automation',
      'LinkedIn CSV Campaigns',
      'Personalized Messaging',
      'Basic LinkedIn CRM',
      'Campaign Analytics',
    ],
    price: {
      [CurrencyEnum.USD]: {
        [BillingTypeEnum.MONTHLY]: 39,
        [BillingTypeEnum.QUARTERLY]: 29,
        [BillingTypeEnum.SEMESTRIAL]: 29,
      },
      [CurrencyEnum.EUR]: {
        [BillingTypeEnum.MONTHLY]: 35,
        [BillingTypeEnum.QUARTERLY]: 25,
        [BillingTypeEnum.SEMESTRIAL]: 25,
      },
      [CurrencyEnum.GBP]: {
        [BillingTypeEnum.MONTHLY]: 30,
        [BillingTypeEnum.QUARTERLY]: 20,
        [BillingTypeEnum.SEMESTRIAL]: 20,
      },
    },
  },
  [Plan.PERSONAL]: {
    description: 'Ideal for single users interested in automating campaigns on Linkedin, Email & Twitter.',
    priceDescription: 'Per user, per month',
    features: [
      '3 Active campaigns',
      'LinkedIn Automation',
      'Email Automation (Gmail & Microsoft)',
      'Twitter Automation',
      'Multi-Channel Automation',
      'Basic LinkedIn CRM',
      'LinkedIn CSV Campaigns',
      'Tag Manager & Notes',
      'Smart Inbox',
      'Automated Greetings',
    ],
    price: {
      [CurrencyEnum.USD]: {
        [BillingTypeEnum.MONTHLY]: 59,
        [BillingTypeEnum.QUARTERLY]: 49,
        [BillingTypeEnum.SEMESTRIAL]: 49,
      },
      [CurrencyEnum.EUR]: {
        [BillingTypeEnum.MONTHLY]: 55,
        [BillingTypeEnum.QUARTERLY]: 45,
        [BillingTypeEnum.SEMESTRIAL]: 45,
      },
      [CurrencyEnum.GBP]: {
        [BillingTypeEnum.MONTHLY]: 45,
        [BillingTypeEnum.QUARTERLY]: 35,
        [BillingTypeEnum.SEMESTRIAL]: 35,
      },
    },
  },
  [Plan.INDIVIDUAL]: {
    description: 'Cloud-based solution ideal for automated outreach on LinkedIn.',
    priceDescription: 'Per user, per month',
    features: [
      '3 Active campaigns',
      'LinkedIn Automation',
      'Linkedin Search Campaigns',
      'LinkedIn Engagement',
      'Linkedin URL Campaigns',
      'Basic LinkedIn CRM',
      'Tag Manager & Notes',
      'LinkedIn Inbox',
      'Templates',
      'Automated Greetings',
    ],
    price: {
      [CurrencyEnum.USD]: {
        [BillingTypeEnum.MONTHLY]: 59,
        [BillingTypeEnum.QUARTERLY]: 49,
        [BillingTypeEnum.ANNUAL]: 39,
      },
      [CurrencyEnum.EUR]: {
        [BillingTypeEnum.MONTHLY]: 55,
        [BillingTypeEnum.QUARTERLY]: 45,
        [BillingTypeEnum.ANNUAL]: 35,
      },
      [CurrencyEnum.GBP]: {
        [BillingTypeEnum.MONTHLY]: 49,
        [BillingTypeEnum.QUARTERLY]: 39,
        [BillingTypeEnum.ANNUAL]: 29,
      },
    },
  },
  [Plan.BUSINESS]: {
    description: 'Best choice for teams seeking optimal performance.',
    priceDescription: 'Per user, per month',
    features: [
      'All Individual plan features',
      'Unlimited Campaigns',
      'Supports Sales Navigator',
      'Advanced LinkedIn Campaigns',
      'Multi-Channel Automation (LinkedIn, Twitter, Email)',
      'Advanced Linkedin CRM',
      'Team Management',
      '3rd Party Integrations (Zapier & Webhooks)',
      'Social Media Scheduler',
      'Attachments',
    ],
    price: {
      [CurrencyEnum.USD]: {
        [BillingTypeEnum.MONTHLY]: 99,
        [BillingTypeEnum.QUARTERLY]: 79,
        [BillingTypeEnum.ANNUAL]: 59,
      },
      [CurrencyEnum.EUR]: {
        [BillingTypeEnum.MONTHLY]: 85,
        [BillingTypeEnum.QUARTERLY]: 75,
        [BillingTypeEnum.ANNUAL]: 55,
      },
      [CurrencyEnum.GBP]: {
        [BillingTypeEnum.MONTHLY]: 75,
        [BillingTypeEnum.QUARTERLY]: 60,
        [BillingTypeEnum.ANNUAL]: 45,
      },
    },
  },
  [Plan.ENTERPRISE]: {
    description: 'Exclusive features and dedicated support for larger teams.',
    priceDescription: 'Includes 5 seats',
    features: [
      'All Business plan features',
      'Volume Discounts',
      'White Label',
      'Advanced Team Settings',
      'Dedicated Account Manager',
      'Groups',
      'Team Inbox Management',
      'Export Team Campaign Data',
      'Export Team CRM Data',
      'Priority Support',
    ],
    price: {
      [CurrencyEnum.USD]: {
        [BillingTypeEnum.MONTHLY]: 69,
        [BillingTypeEnum.QUARTERLY]: 49,
        [BillingTypeEnum.SEMESTRIAL]: 49,
        [BillingTypeEnum.ANNUAL]: 29.9,
      },
      [CurrencyEnum.EUR]: {
        [BillingTypeEnum.MONTHLY]: 60,
        [BillingTypeEnum.QUARTERLY]: 45,
        [BillingTypeEnum.SEMESTRIAL]: 45,
        [BillingTypeEnum.ANNUAL]: 30,
      },
      [CurrencyEnum.GBP]: {
        [BillingTypeEnum.MONTHLY]: 50,
        [BillingTypeEnum.QUARTERLY]: 35,
        [BillingTypeEnum.SEMESTRIAL]: 35,
        [BillingTypeEnum.ANNUAL]: 25,
      },
    },
  },
  [Plan.AGENCY]: {
    description: 'Exclusive features and dedicated support for larger teams.',
    priceDescription: 'Includes 10 seats',
    features: [
      'All Business plan features',
      'Volume Discounts',
      'White Label',
      'Advanced Team Settings',
      'Dedicated Account Manager',
      'Groups',
      'Team Inbox Management',
      'Export Team Campaign Data',
      'Export Team CRM Data',
      'Priority Support',
    ],
    price: {
      [CurrencyEnum.USD]: {
        [BillingTypeEnum.MONTHLY]: 79,
        [BillingTypeEnum.QUARTERLY]: 59,
        [BillingTypeEnum.ANNUAL]: 49,
      },
      [CurrencyEnum.EUR]: {
        [BillingTypeEnum.MONTHLY]: 70,
        [BillingTypeEnum.QUARTERLY]: 55,
        [BillingTypeEnum.ANNUAL]: 45,
      },
      [CurrencyEnum.GBP]: {
        [BillingTypeEnum.MONTHLY]: 60,
        [BillingTypeEnum.QUARTERLY]: 45,
        [BillingTypeEnum.ANNUAL]: 35,
      },
    },
  },
};
