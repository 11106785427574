import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { useStripe } from '@stripe/react-stripe-js';

import { Button, Checkbox, Divider, Link, MessageBlock, Typography } from 'src/components';
import { PLAN_SEATS } from 'src/constants';
import { ModalTypes } from 'src/enums';
import { delay, redirectToPaymentSuccessPage, resolveDowngradePlanText, resolveSeatsForPaidPlan } from 'src/helpers';
import { useCompanyTheme, useGetTeamCount, useLocationState, usePageListeners, useWhiteLabel } from 'src/hooks';
import {
  useGetBillingInfo,
  useGetInvoiceDetails,
  useUpdateSubscription,
  useUpdateTeamDataAfterFailedPayment,
  useUpdateTeamDataAfterPaymentConfirmation,
} from 'src/reactQueries';
import { openModal, showToast } from 'src/store';
import { ICreateSubscription, IEditPlan, ILocationStateEditSubscription } from 'src/types';
import { BlockTitle } from '../../_components';
import { PaymentDetails } from '../../PaymentDetails';
import { PaymentMethod } from '../../PaymentMethod';
import { PlanDetailsEditor } from '../PlanDetailsEditor';
import { EditSubscriptionLayout } from './EditSubscriptionLayout';

export const EditSubscription = () => {
  const { shortCompanyName } = useCompanyTheme();
  const { whitelabel } = useWhiteLabel();

  const locationState = useLocationState<ILocationStateEditSubscription>();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const stripe = useStripe();

  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [refundChecked, setRefundChecked] = useState(false);

  const { billingInfo, isSubscriptionCanceled } = useGetBillingInfo();

  const form = useForm<ICreateSubscription>();

  const { reset, watch, setError, handleSubmit, formState } = form;
  const { errors, isValid } = formState;

  const teamCount = useGetTeamCount();

  const plan = watch('plan');
  const billingType = watch('billingType');
  const seats = watch('seats');
  const currency = watch('currency');

  useEffect(() => {
    if (billingInfo && teamCount) {
      const resetPlan = plan || locationState.plan || billingInfo.plan;
      const resetBillingType = billingType || locationState.billingType || billingInfo.billingType;
      const resetCurrency = currency || billingInfo.currency;
      const resetSeats = seats || resolveSeatsForPaidPlan(resetPlan, billingInfo.seats, teamCount);

      reset({
        currency: resetCurrency,
        plan: resetPlan,
        billingType: resetBillingType,
        seats: resetSeats,
      });
    }
  }, [billingInfo, teamCount]);

  const { invoiceDetails, isFetching: isFetchingInvoiceDetails } = useGetInvoiceDetails(
    {
      plan,
      billingType,
      seats,
      currency,
    },
    { enabled: PLAN_SEATS[plan]?.seats >= teamCount },
  );

  const { updateTeamDataAfterPaymentConfirmation } = useUpdateTeamDataAfterPaymentConfirmation();
  const { updateTeamDataAfterFailedPayment } = useUpdateTeamDataAfterFailedPayment();

  const { addUnloadConfirmation, removeUnloadConfirmation } = usePageListeners();

  const { updateSubscription, isLoading: isUpdatingSubscription } = useUpdateSubscription({
    onError: (err) => {
      setError('paymentMethod', { type: 'custom', message: err.message });

      setIsProcessingPayment(false);
    },
    onSuccess: (data) => {
      setIsProcessingPayment(false);
      if (!stripe) {
        return;
      }

      const { subscription, redirectUrlParams } = data;

      const paymentIntent = subscription?.latest_invoice?.payment_intent;

      if (paymentIntent) {
        if (paymentIntent?.status !== 'succeeded') {
          setIsProcessingPayment(true);
          addUnloadConfirmation();

          stripe
            .confirmCardPayment(paymentIntent.client_secret, {
              payment_method: paymentIntent.payment_method || paymentIntent.last_payment_error.payment_method.id,
            })
            .then((data) => {
              removeUnloadConfirmation();
              if (data.error) {
                dispatch(
                  showToast({
                    message:
                      data.error.message ||
                      'We are unable to authenticate your payment method. Please check payment details and try again',
                    type: 'error',
                    autoCloseTime: 3000,
                  }),
                );
                updateTeamDataAfterFailedPayment();
              } else {
                delay(2000, () => {
                  updateTeamDataAfterPaymentConfirmation({ process: 'update' });
                  redirectToPaymentSuccessPage(redirectUrlParams);
                });
              }
            })
            .finally(() => setIsProcessingPayment(false));
        } else {
          redirectToPaymentSuccessPage(redirectUrlParams);
        }
      } else {
        redirectToPaymentSuccessPage(redirectUrlParams);
      }
    },
  });

  const isPlanEdited = useMemo(() => {
    const isPlanChanged = billingInfo?.plan === plan;
    const isSeatsChanged = billingInfo?.seats === seats;
    const isBillingTypeChanged = billingInfo?.billingType === billingType;

    return !isPlanChanged || !isSeatsChanged || !isBillingTypeChanged;
  }, [plan, seats, billingType]);

  const isConfirmDisabled = useMemo(() => {
    if (!termsChecked || !refundChecked) {
      return true;
    }

    return (
      !isPlanEdited ||
      isFetchingInvoiceDetails ||
      isProcessingPayment ||
      !isValid ||
      PLAN_SEATS[plan]?.seats < teamCount ||
      !isEmpty(errors)
    );
  }, [
    termsChecked,
    refundChecked,
    isPlanEdited,
    isFetchingInvoiceDetails,
    isProcessingPayment,
    isValid,
    plan,
    teamCount,
    formState,
  ]);

  const onCancelClick = () => navigate('/billing/subscription');

  const openBillingHistoryModalWindow = () => {
    dispatch(openModal({ type: ModalTypes.BILLING_HISTORY, params: { preOpenFirstOpenedInvoice: true } }));
  };

  const onSubmit = async (values: ICreateSubscription) => {
    setIsProcessingPayment(true);

    if (billingInfo) {
      const body: IEditPlan = locationState?.seatsOnly
        ? {
            seats: values.seats,
            plan: billingInfo.plan,
            billingType: billingInfo.billingType,
          }
        : { seats: values.seats, plan: values.plan, billingType: values.billingType };

      const confirmationText = resolveDowngradePlanText(values.plan, billingInfo.plan, whitelabel.status === 'active');

      if (confirmationText) {
        dispatch(
          openModal({
            headerText: 'Plan downgrading',
            descriptionText: confirmationText,
            onConfirm: () => updateSubscription(body),
            onCancel: () => setIsProcessingPayment(false),
          }),
        );
      } else {
        updateSubscription(body);
      }
    }
  };

  return (
    <>
      <EditSubscriptionLayout isPlanChange={!!billingInfo && billingInfo.plan !== plan} />

      <Box p="20px 16px" display="flex" gap="16px" alignItems="flex-start">
        <Box bgcolor="#fff" padding="20px" width="100%" borderRadius="4px">
          <BlockTitle mb="24px">Your plan details</BlockTitle>

          <PlanDetailsEditor
            plan={plan}
            billingType={billingType}
            currency={currency}
            seats={seats}
            disabled={isFetchingInvoiceDetails}
            seatsOnly={!!locationState?.seatsOnly}
            {...form}
          />

          <PaymentMethod fullWidth withBorder isTransparentButton />

          <Box my="24px">
            <Box display="flex" alignItems="center" gap="8px" mb="12px">
              <Checkbox type="checkbox" checked={termsChecked} onChange={(e) => setTermsChecked(e.target.checked)} />
              <Typography color="black.base">
                I accept {shortCompanyName}{' '}
                <Link external openNewTab to="https://meetalfred.com/terms" variant="lightBlue">
                  Terms of Use
                </Link>
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" gap="8px">
              <Checkbox type="checkbox" checked={refundChecked} onChange={(e) => setRefundChecked(e.target.checked)} />
              <Typography color="black.base">
                I accept {shortCompanyName}{' '}
                <Link
                  external
                  openNewTab
                  variant="lightBlue"
                  to="https://meetalfred.com/help/en/articles/3338757-refund-policy"
                  tooltip={
                    <Box textAlign="left">
                      <h5>No Refund Policy</h5>
                      Once upgraded, we do not offer any refunds under any circumstance, but you can cancel your
                      subscription at any time.
                    </Box>
                  }
                >
                  No Refund Policy
                </Link>
              </Typography>
            </Box>
          </Box>

          {errors?.paymentMethod?.message && (
            <MessageBlock
              type="error"
              wrapperProps={{ mb: '24px' }}
              message={
                <Typography color="gray.600">
                  {errors?.paymentMethod?.message === 'outstanding_invoice_requires_action' ? (
                    <>
                      Subscription can not be updated due to{' '}
                      <Link onClick={openBillingHistoryModalWindow}>outstanding invoices requiring attention</Link>.
                    </>
                  ) : (
                    errors?.paymentMethod?.message
                  )}
                </Typography>
              }
            />
          )}

          <Divider mb="24px" />

          <Box display="flex" justifyContent="space-between">
            <Button variant="gray" onClick={onCancelClick}>
              Cancel
            </Button>

            <Button
              disabled={isConfirmDisabled || isSubscriptionCanceled}
              tooltip={{
                title:
                  isSubscriptionCanceled &&
                  'Please resume your subscription on the Subscription Plan page if you want to change your plan',
              }}
              onClick={handleSubmit(onSubmit)}
              processing={isUpdatingSubscription}
            >
              Confirm Changes
            </Button>
          </Box>
        </Box>

        <PaymentDetails
          plan={plan}
          billingType={billingType}
          currency={currency}
          invoiceDetails={invoiceDetails}
          isLoadingInvoiceDetails={isFetchingInvoiceDetails}
          showInvoiceDetails={PLAN_SEATS[plan]?.seats >= teamCount}
        />
      </Box>
    </>
  );
};
