import dayjs from 'dayjs';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';

import { Button, Typography } from 'src/components';
import { useGetBillingInfo, useResumeSubscription } from 'src/reactQueries';
import { BlockTitle } from '../_components';

export const CanceledSubscription = () => {
  const { palette } = useTheme();

  const { billingInfo } = useGetBillingInfo();

  const { resumeSubscription, isLoading } = useResumeSubscription();

  return (
    <Box
      p="20px"
      border={`1px solid ${palette.gray[100]}`}
      bgcolor={`${palette.accent[100]}80`}
      borderRadius="6px"
      display="flex"
      gap="60px"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" flexDirection="column" gap="8px">
        <BlockTitle>Your Subscription is Canceled</BlockTitle>

        <Typography medium color="gray.700" lineHeight="22px">
          You will still be able to use the services included in your plan until
          {dayjs.unix(billingInfo?.stripeSubscriptionObject?.current_period_end || 0).format(' MMMM D, YYYY')}
        </Typography>

        <Typography medium color="gray.700" lineHeight="22px">
          You can choose to resume your subscription at any time before this date.
        </Typography>
      </Box>

      <Button onClick={() => resumeSubscription()} processing={isLoading}>
        Resume Subscription
      </Button>
    </Box>
  );
};
