import { PropsWithChildren } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Typography } from '../ui/Typography';

export const WithTagCircle = styled(Box)`
  color: ${({ theme }) => theme.palette.primary.main};
`;

const StyledTag = styled(Box)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 12px;
  font-family: ProximaSoft, sans-serif;
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 16px;
  border-width: 1px;
  border-style: solid;
  box-sizing: border-box;
`;

interface ITagProps {
  color?: string;
  bgColor?: string;
  borderColor?: string;
  tag?: string;
  height?: string;
  width?: string;
}

export const Tag = (props: PropsWithChildren<ITagProps>) => {
  const { palette } = useTheme();

  const { tag, color, height = '30px', width = 'auto', borderColor, bgColor, children } = props;

  return (
    <StyledTag
      height={height}
      width={width}
      borderColor={borderColor || (color && `${color}80`) || '#0000001a'}
      bgcolor={bgColor || (color && `${color}1a`) || `${palette.gray[50]}80`}
      mr="5px"
    >
      {tag ? (
        <Typography medium color={color || palette.gray[600]} crop>
          {tag}
        </Typography>
      ) : (
        children
      )}
    </StyledTag>
  );
};
