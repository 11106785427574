import { UseFormReturn } from 'react-hook-form';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Toggle } from 'src/components/ui/Toggle';
import { useGetUserAccount } from 'src/reactQueries';
import { ISequence } from 'src/types';
import { SequenceForm } from '../SequenceForm/SequenceForm';
import { Tags } from '../Tags';

const ToggleBox = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: 20px;
`;

const Label = styled.p`
  color: #333;
  font-size: 14px;
  margin-left: 15px;
`;

interface IConnectionRequestProps extends UseFormReturn<ISequence> {
  index: number;
  customSnippets: Record<string, string>;
}

const BASIC_INVITE_NOTES_LENGTH = 200;
const PREMIUM_INVITE_NOTES_LENGTH = 300;

export const ConnectionRequest = ({ index, customSnippets, ...sequenceForm }: IConnectionRequestProps) => {
  const { userMe } = useGetUserAccount();

  const { watch, setValue } = sequenceForm;

  const watchConnectFollowup = watch(`sequence.${index}.connect_followup`);
  const snippetOptions = Object.keys(customSnippets).map((key) => ({ label: key, value: key }));
  const textMaxLength =
    userMe?.hasLinkedInPremium || userMe?.hasLinkedInSalesNavigator
      ? PREMIUM_INVITE_NOTES_LENGTH
      : BASIC_INVITE_NOTES_LENGTH;

  return (
    <>
      <SequenceForm
        watchField="message"
        title="Connection Request Message"
        description={`Please note that if your message exceeds ${textMaxLength} characters, we will automatically trim it.\nIf we identify a limit on connection request messages, we'll automatically send the connection request without including a message.`}
        config={{
          textMaxLength,
        }}
        index={index}
        snippetOptions={snippetOptions}
        {...sequenceForm}
      />
      <Box mt="10px">
        <ToggleBox>
          <Toggle checked={watchConnectFollowup} name={`sequence.${index}.connect_followup`} setValue={setValue} />
          <Label>Auto follow-up with message once connected</Label>
        </ToggleBox>
      </Box>
      {watchConnectFollowup && (
        <Box mt="15px">
          <SequenceForm
            title="Follow Up Message"
            watchField="followup_message"
            index={index}
            snippetOptions={snippetOptions}
            registerOptions={{
              validate: (value) => !!value.trim(),
            }}
            config={{ textMaxLength: 8000 }}
            {...sequenceForm}
          />
        </Box>
      )}

      <Tags index={index} watch={watch} setValue={setValue} />
    </>
  );
};
