import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updatePost } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IGetPost, TSchedulePost } from 'src/types';

export const useUpdatePost = (
  postId: number,
  options?: UseMutationOptions<
    IGetPost,
    ICustomAxiosError,
    Omit<TSchedulePost, 'scheduledAt' | 'postTypes'> & {
      scheduledAt: string;
    }
  >,
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(
    ['update-post', postId],
    (
      body: Omit<TSchedulePost, 'scheduledAt' | 'postTypes'> & {
        scheduledAt: string;
      },
    ) => updatePost(postId, body),
    {
      ...options,
      onSuccess: async (data, _vars, context) => {
        options?.onSuccess?.(data, _vars, context);

        await queryClient.cancelQueries(['post', postId]);

        queryClient.setQueryData<IGetPost | undefined>(['post', postId], data);

        dispatch(
          showToast({
            type: 'success',
            message: 'Post updated successfully',
            autoCloseTime: 3000,
          }),
        );
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return {
    updatePost: mutate,
    ...rest,
  };
};
