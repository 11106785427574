import { useQuery, UseQueryOptions } from 'react-query';

import { getCampaignLeadActions } from 'src/api';
import { ICampaignActivity, ICustomAxiosError } from 'src/types';

export const useGetCampaignLeadActions = (
  campaignId: number,
  entityUrn: string,
  options?: UseQueryOptions<ICampaignActivity[], ICustomAxiosError>,
) => {
  const { data, ...rest } = useQuery<ICampaignActivity[], ICustomAxiosError>(
    ['get-campaign-lead-actions', campaignId, entityUrn],
    () => getCampaignLeadActions(campaignId, entityUrn),
    options,
  );

  return { leadActions: data, ...rest };
};
