import { useMutation, UseMutationOptions } from 'react-query';

import { resendVerificationEmail } from 'src/api';
import { ICustomAxiosError } from 'src/types';

export const useResendVerificationEmail = (options?: UseMutationOptions<void, ICustomAxiosError>) => {
  const { mutate, ...rest } = useMutation('send-verification-link', resendVerificationEmail, options);

  return {
    resendVerificationEmail: mutate,
    ...rest,
  };
};
