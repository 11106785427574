import styled from '@emotion/styled';
import MuiMenu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';

export const Menu = styled(MuiMenu)`
  & .MuiList-root {
    padding: 10px;
  }
`;

export const MenuItem = styled(MuiMenuItem)`
  padding: 10px;
  color: ${({ theme }) => theme.palette.primary.wlLight};
  font-size: 14px;

  &.Mui-focusVisible {
    background-color: transparent;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: #ffffff;
  }
`;
