import { mapValues } from 'lodash';
import { IntercomProps, useIntercom } from 'react-use-intercom';

import { Plan } from 'src/enums';
import { useAppSelector } from 'src/hooks';
import {
  useGetBillingInfo,
  useGetIntercomInitialData,
  useGetTeamInvites,
  useGetTeamMembers,
  useGetUserAccount,
  useGetUserReferral,
} from 'src/reactQueries';

export const useInitIntercom = () => {
  const { boot, ...rest } = useIntercom();
  const { team, user } = useAppSelector((state) => state);
  const teamData = team.data;

  const { data: intercomData, isLoading: isLoadingIntercom } = useGetIntercomInitialData({ enabled: user?.isAuth });
  const { userReferral, isLoading: isLoadingReferral } = useGetUserReferral();
  const { userMe, isLoading: isLoadingUser } = useGetUserAccount();
  const { billingInfo, isLoading: isLoadingBilling } = useGetBillingInfo();
  const { teamMembers, isLoading: isLoadingMembers } = useGetTeamMembers(
    {
      teamId: teamData?.id ?? 0,
    },
    { enabled: !!teamData?.id },
  );
  const { teamInvites, isLoading: isLoadingInvites } = useGetTeamInvites();

  const isLoading = [
    isLoadingUser,
    isLoadingIntercom,
    isLoadingReferral,
    isLoadingInvites,
    isLoadingBilling,
    isLoadingMembers,
  ].some((load) => load);

  const toYesNo = (val?: boolean | string | null) => (val ? 'Yes' : 'No');

  if (isLoading || user.isImpersonate) {
    return { ...rest };
  }

  const userPlan = userMe?.hasLinkedInPremium ? 'Premium' : 'Basic';

  const initData = {
    name: userMe?.name,
    email: userMe?.email,
    userHash: intercomData?.intercomHash,
    customAttributes: {
      user_id: userMe?.id,
      last_name: userMe?.lastName,
      first_name: userMe?.firstName,
      created_at: Math.floor(new Date(userMe?.createdAt ?? '').getTime() / 1000.0),
      Use: userMe?.whoWillBeUsing,
      Referral: userMe?.howYouHeard,
      team_role: teamData?.teamUser?.role,
      Experience: userMe?.haveYouUsedSocialSellingTools,
      'Email Verified': toYesNo(userMe?.isEmailVerified),

      Company: teamData?.name,
      connections: userMe?.linkedInConnections,
      'Pending Invites': userMe?.linkedInPendingInvites,
      Bot: toYesNo(userMe?.isCloudBotEnabled),
      bot_version: userMe?.isCloudBotEnabled ? 'Cloud' : '',
      Linkedin: userMe?.isLinkedInLoginValid ? 'Connected' : 'Not Connected',
      'Li Plan': userMe?.hasLinkedInSalesNavigator ? 'Sales Navigator' : userPlan,

      Trial: billingInfo?.trial,
      'Trial Extended': toYesNo(teamData?.isTrialExtended),
      Plan: `${billingInfo?.plan} ${billingInfo?.billingType}`,
      Subscribed: toYesNo(billingInfo?.hasPaidPlan),
      ltd: toYesNo(billingInfo?.hasLifetimeDeal),

      leads: intercomData?.leads,
      Campaigns: intercomData?.campaigns,
      Replies: intercomData?.totalReplies,
      Accepted: intercomData?.invitesAccepted,
      'Team Members': teamMembers?.total,
      'Invites Sent': teamInvites?.length,
      'Connections Rate': `${intercomData?.connectionRate}%`,

      'Referral Url': userReferral?.referralUrl,
      // TODO(Volodya): add route to admin panel
      'Alfred url': `${location.origin}/admin/${userMe?.id}`,
    },
  } as IntercomProps;

  if (teamData?.plan === Plan.BUSINESS) {
    initData.customAttributes = { ...(initData.customAttributes || {}), ['Plan Version']: teamData?.planVersion };
  }

  const initIntercom = {
    ...initData,
    customAttributes: mapValues(initData.customAttributes, (value) => (value === null ? 'None' : value)),
  };

  boot(user?.isAuth ? initIntercom : {});

  return { ...rest };
};
