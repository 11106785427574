import { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, Collapse } from '@mui/material';

import { Button, Typography } from 'src/components';
import { CampaignCategory } from 'src/enums';
import { useOutsideClick, useWhiteLabel } from 'src/hooks';

const StyledMenu = styled.ul`
  position: absolute;
  top: 39px;
  right: 0;
  width: 180px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.18);
  overflow: hidden;
`;

const StyledItem = styled(Link)<{ selected: boolean }>`
  display: flex;
  padding: 10px;
  font-size: 14px;
  transition:
    color 0.2s,
    background-color 0.2s;
  color: ${({ selected, theme }) => (selected ? theme.palette.secondary.main : theme.palette.primary.wlLight)};
  background-color: ${({ selected, theme }) => (selected ? theme.palette.light.main : '#ffffff')};

  &:hover {
    color: ${({ theme }) => theme.palette.secondary.main};
    background-color: ${({ theme }) => theme.palette.light.main};
  }
`;

export const CreateButton = () => {
  const location = useLocation();
  const { whitelabel } = useWhiteLabel();

  const [isCollapseOpened, setIsCollapseOpened] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const ref = useRef<HTMLDivElement>();

  const setCollapse = (isOpen = false) => {
    setIsClicked(isOpen);
    setIsCollapseOpened(isOpen);
  };

  useOutsideClick(ref, setCollapse);

  const list = [
    { name: 'LinkedIn Campaign', path: `/campaign/new/${CampaignCategory.LINKEDIN}`, state: {} },
    {
      name: 'Email Campaign',
      path: `/campaign/new/${CampaignCategory.EMAIL}`,
      state: {},
    },
    {
      name: 'X (Twitter) Campaign',
      path: `/campaign/new/${CampaignCategory.TWITTER}`,
      state: {},
      hidden: whitelabel.isWhiteLabelUser,
    },
    { name: 'Multi-channel Campaign', path: `/campaign/new/${CampaignCategory.MULTICHANNEL}`, state: {} },
    { name: 'InMail Campaign', path: `/campaign/new/${CampaignCategory.INMAIL}`, state: {} },
    { name: 'LinkedIn Group Campaign', path: `/campaign/new/${CampaignCategory.GROUP}/group`, state: {} },
    { name: 'LinkedIn Event Campaign', path: `/campaign/new/${CampaignCategory.EVENT}/event`, state: {} },
    { name: 'LinkedIn Post Campaign', path: `/campaign/new/${CampaignCategory.POST}/post`, state: {} },
  ];

  return (
    <Box ref={ref} onMouseEnter={() => setIsCollapseOpened(true)} onMouseLeave={() => setIsCollapseOpened(false)}>
      <Button variant="secondary" size={{ height: '32px' }} onClick={() => setCollapse(!isClicked)}>
        <Typography color="inherit" medium>
          Create
        </Typography>
      </Button>
      <Collapse in={isClicked || isCollapseOpened} timeout={{ enter: 200, exit: 200 }}>
        <StyledMenu onClick={() => setCollapse(false)}>
          {list.map(
            ({ name, path, state, hidden }) =>
              !hidden && (
                <StyledItem selected={location.pathname === path} key={name} to={path} state={state}>
                  {name}
                </StyledItem>
              ),
          )}
        </StyledMenu>
      </Collapse>
    </Box>
  );
};
