import { useMutation, UseMutationOptions } from 'react-query';

import { stopImpersonateUser } from 'src/api';
import { COOKIE_TOKEN_NAME } from 'src/constants';
import { navigateAfterImpersonating, removeCookie, setCookie } from 'src/helpers';
import { IAuthToken, ICustomAxiosError } from 'src/types';
import { useGetRealUser } from './useGetRealUser';

export const usePostStopImpersonateUser = (
  options?: UseMutationOptions<IAuthToken, ICustomAxiosError, { withAdminRedirect?: boolean } | void>,
) => {
  const { realImpersonateUser } = useGetRealUser();

  const { mutate, ...rest } = useMutation<IAuthToken, ICustomAxiosError, { withAdminRedirect?: boolean } | void>(
    ['stop-impersonate-user'],
    stopImpersonateUser,
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);

        const { withAdminRedirect = true } = variables || {};

        setCookie(COOKIE_TOKEN_NAME, data.authToken);
        removeCookie('realImpersonateUser');

        if (realImpersonateUser?.isAdminImpersonate && withAdminRedirect) {
          location.assign(
            `${realImpersonateUser?.redirectLink || process.env.REACT_APP_ADMIN_URL}?saveSearchParams=true`,
          );
        } else {
          navigateAfterImpersonating();
        }
      },
    },
  );

  return { stopImpersonateUser: mutate, ...rest };
};
