import { ReactNode } from 'react';
import { Box } from '@mui/material';

import { Input, InputProps, Typography } from 'src/components';

export interface IFormInput extends Omit<InputProps, 'label'> {
  description?: ReactNode;
  label?: ReactNode;
}

export const FormInput = ({ description, label, ...props }: IFormInput) => (
  <Box>
    {label && (
      <Typography fontSize="14px">
        {label}
        {props?.parameters?.required && (
          <Typography inline color="error.light">
            {' '}
            *
          </Typography>
        )}
      </Typography>
    )}
    <Input {...props} />
    {!!description && (
      <Typography color="#8C8C8C" fontSize="14px" mt="2px">
        {description}
      </Typography>
    )}
  </Box>
);
