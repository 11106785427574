import { useEffect, useState } from 'react';
import { cssTransition, toast, ToastContainer } from 'react-toastify';

import { useAppSelector } from 'src/hooks';
import { AlertTemplate, ToastTemplate } from './templates';

const bounce = cssTransition({
  enter: 'zoomIn',
  exit: 'zoomOut',
});

export const Toast = () => {
  const [currToastNum, setCurrToastQty] = useState(0);
  const { trace } = useAppSelector((state) => state.toast);

  useEffect(() => {
    const nextToastNum = trace.length;

    if (trace.length && nextToastNum > currToastNum) {
      setCurrToastQty(nextToastNum);

      toast(
        ({ closeToast }) => {
          switch (trace[0].model) {
            case 'toast':
              return <ToastTemplate message={trace[0].message} close={closeToast} type={trace[0].type} />;

            case 'alert':
              return <AlertTemplate message={trace[0].message} close={closeToast} type={trace[0].type} />;

            default:
              return null;
          }
        },
        {
          autoClose: trace[0].autoCloseTime,
          delay: trace[0].delay,
          transition: bounce,
          position: trace[0].model === 'toast' ? 'top-right' : 'top-center',
          style: {
            top: trace[0].model === 'toast' ? 'auto' : '80px',
            padding: 0,
            minHeight: 'fit-content',
            width: 'auto',
            backgroundColor: 'transparent',
          },
        },
      );
    }
  }, [currToastNum, trace]);

  return (
    <ToastContainer
      closeButton={false}
      closeOnClick={false}
      draggable={false}
      hideProgressBar
      newestOnTop={false}
      pauseOnFocusLoss={false}
      pauseOnHover={false}
      rtl={false}
      bodyStyle={{
        padding: 0,
      }}
    />
  );
};
