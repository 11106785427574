import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getSequenceTemplatesCategories } from 'src/api';
import { CampaignCategory } from 'src/enums';
import { IGetSequenceTemplatesCategories } from 'src/types';

export const useGetSequenceTemplatesCategories = <TData = Record<CampaignCategory, number>>(
  params: IGetSequenceTemplatesCategories,
  options?: UseQueryOptions<Record<CampaignCategory, number>, AxiosError, TData>,
) => {
  const { availability, search } = params;

  const { data, ...rest } = useQuery<Record<CampaignCategory, number>, AxiosError, TData>(
    ['get-sequence-templates', availability, search],
    () => getSequenceTemplatesCategories(params),
    {
      refetchOnMount: true,
      ...options,
    },
  );

  return { data, ...rest };
};
