export const UNAUTHORIZED = [
  {
    json: {
      id: 0,
      message: 'Authentication failed',
    },
  },
];

export const BAD_REQUEST = [
  {
    json: {
      id: 0,
      message: 'Bad request',
    },
  },
];
