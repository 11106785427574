import { Dispatch, SetStateAction, useState } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import { Box } from '@mui/material';

import { Input, SelectBasic, Typography } from 'src/components';
import { OTHER_PRODUCTS } from 'src/constants';
import { ISelectOption } from 'src/types';
import { Discount } from './Discount';

interface IOtherProductProps {
  product: string;
  setProduct: Dispatch<SetStateAction<string>>;
  showError: boolean;
}

const options = OTHER_PRODUCTS.map((product) => ({ value: product, label: product }));

export const OtherProduct = ({ showError, product, setProduct }: IOtherProductProps) => {
  const [selectedOption, setSelectedOption] = useState<string>();

  const onProductChange = (
    newValue: SingleValue<ISelectOption<string | number>> | MultiValue<ISelectOption<string | number>>,
  ) => {
    const value = newValue as SingleValue<ISelectOption>;

    if (value) {
      const option = value?.value;

      if (option === 'Other') {
        setSelectedOption(option);
        setProduct('');
      } else {
        setProduct(option);
        setSelectedOption(option);
      }
    }
  };

  return (
    <Box py="30px" px="40px" bgcolor="light.light">
      <Discount header="Can we change your mind?" subheader="We can offer you one of the following discounts:" />

      <Typography color="primary.wlLight">We are sad to see you go</Typography>
      <Typography mb="10px" color="violet.dark">
        Please tell us which product are you going to use.
      </Typography>

      <SelectBasic placeholder="Search by group" onChange={onProductChange} options={options} />

      {selectedOption === 'Other' && (
        <Input
          placeholder="Which one?"
          name="product"
          value={product}
          onChange={(e) => setProduct(e.target.value)}
          style={{ height: 'auto' }}
        />
      )}

      {showError && (
        <Typography mt="10px" mb="5px" color="error.main">
          {selectedOption === 'Other' ? 'Please tell us which product' : 'Please select an option'}
        </Typography>
      )}
    </Box>
  );
};
