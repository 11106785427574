import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { Button, Spinner, Typography } from 'src/components';
import { BillingHistory } from 'src/containers/Billing';
import { InvoiceStatus } from 'src/enums';
import { delay } from 'src/helpers';
import { useCompanyTheme } from 'src/hooks';
import { closeModal } from 'src/store/modal.slice';
import { IBillingInvoice, IModalProps } from 'src/types';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../_components';

interface IModalBillingHistory {
  preOpenFirstOpenedInvoice?: boolean;
}

export const ModalBillingHistory = ({
  params: { preOpenFirstOpenedInvoice = false },
}: IModalProps<IModalBillingHistory>) => {
  const dispatch = useDispatch();

  const { shortCompanyName } = useCompanyTheme();

  const [showLoader, setShowLoader] = useState<boolean>(preOpenFirstOpenedInvoice);

  const close = () => dispatch(closeModal());

  const onBillingHistoryLoad = (invoices: IBillingInvoice[], page: number) => {
    if (page === 1 && preOpenFirstOpenedInvoice) {
      const invoice = [...invoices].shift();

      if (invoice?.status === InvoiceStatus.OPEN) {
        delay(2000, () => {
          window.open(invoice.hosted_invoice_url, '_blank', 'noopener,noreferrer');
          setShowLoader(false);
        });
      }
    }
  };

  return (
    <ModalContainer>
      <ModalHeader onClose={close}>Billing history</ModalHeader>

      {showLoader && (
        <ModalBody display="flex" alignItems="center">
          <Spinner type="dots" mr="10px" />
          <Typography>{shortCompanyName} is opening the pending invoice in a new tab.</Typography>
        </ModalBody>
      )}

      <Box display={showLoader ? 'none' : 'block'} padding="0 20px">
        <BillingHistory onSuccess={onBillingHistoryLoad} />
      </Box>

      <ModalFooter>
        <Button onClick={close}>Close</Button>
      </ModalFooter>
    </ModalContainer>
  );
};
