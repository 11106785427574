import { useMutation, UseMutationOptions } from 'react-query';

import { getMicrosoftOutlookCodeUrl } from 'src/api';
import { ICustomAxiosError } from 'src/types';

export const useGetMicrosoftOutlookCodeUrl = (
  redirectTo: string,
  options?: UseMutationOptions<string, ICustomAxiosError>,
) => {
  const { mutate, ...rest } = useMutation(
    'get-microsoft-outlook-code-url',
    () => getMicrosoftOutlookCodeUrl(redirectTo),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        document.location.href = data;
      },
    },
  );

  return {
    getMicrosoftOutlookCodeUrl: mutate,
    ...rest,
  };
};
