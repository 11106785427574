export const decodeJWT = (token = ''): { id?: number; name?: string; realUserId?: number } => {
  const base64Url = token.split('.')[1] || '';
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload =
    decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join(''),
    ) || '{}';

  const { sub, name, realUserId } = JSON.parse(jsonPayload);

  return {
    id: sub,
    name,
    realUserId,
  };
};
