import {
  components as ReactSelectComponents,
  GroupBase,
  MultiValue,
  SelectComponentsConfig,
  SingleValue,
  StylesConfig,
} from 'react-select';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Palette } from '@mui/material';

import { SelectBasic } from 'src/components';
import { IColorSelectOption } from 'src/types';

const CropText = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 5px;
`;

const getStyles = (palette: Palette): StylesConfig<IColorSelectOption, boolean, GroupBase<IColorSelectOption>> => ({
  container: (styles) => ({
    ...styles,
    maxWidth: '200px',
  }),
  menuList: (styles) => ({ ...styles, overflowX: 'hidden' }),
  placeholder: (styles) => ({
    ...styles,
    color: 'var(--violet-main)',
  }),
  option: (provided, { isSelected }) => ({
    ...provided,
    backgroundColor: 'transparent !important',
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px',
    lineHeight: '14px',
    padding: '10px',
    transition: '0.2s',
    color: isSelected ? palette.primary.main : palette.text.primary,
    ['&:hover']: {
      color: palette.primary.main,
    },
  }),
});

const components: SelectComponentsConfig<IColorSelectOption, boolean, GroupBase<IColorSelectOption>> = {
  Option: ({ children, ...props }) => (
    <ReactSelectComponents.Option {...props}>
      <CropText>{children}</CropText>
      {props.isSelected && <FontAwesomeIcon size="xs" icon={faCheck} />}
    </ReactSelectComponents.Option>
  ),
};

interface IGroupSelectProps {
  onChangeGroup: (option: SingleValue<IColorSelectOption> | MultiValue<IColorSelectOption>) => void;
  groupOptions: IColorSelectOption[];
  groupSelected: IColorSelectOption | null;
}

export const GroupSelect = ({ groupOptions, groupSelected, onChangeGroup }: IGroupSelectProps) => {
  const { palette } = useTheme();

  return (
    <SelectBasic
      aria-label="group-select"
      placeholder="Assign group"
      onChange={onChangeGroup}
      options={groupOptions}
      value={groupSelected}
      styles={getStyles(palette)}
      components={components}
    />
  );
};
