import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppSelector } from 'src/hooks';
import { useGetAllTags, useGetTeamById, useGetUserAccount } from 'src/reactQueries';
import { clearBlacklistConnectionRequestParams, clearBlacklistLeadsRequestParams } from 'src/store';
import { clearConnectionRequestParams, setConnectionSelectedLeads } from 'src/store/connections.slice';
import { clearLeadRequestParams, setSelectedLeads } from 'src/store/lead.slice';
import { setTeam } from 'src/store/team.slice';
import { setUser } from 'src/store/user.slice';

export const useRoutesController = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isAuth, profile, isAppDeactivated, isAppPaused } = useAppSelector((state) => state.user);

  useGetAllTags();

  useGetUserAccount({
    onSuccess: (user) => dispatch(setUser(user)),
    enabled: isAuth,
  });

  const { teamData: teamDataFresh } = useGetTeamById({
    teamId: profile?.lastTeamId ?? -1,
    enabled: !!profile?.lastTeamId && isAuth,
  });

  useEffect(() => {
    if (!pathname.startsWith('/contacts')) {
      dispatch(clearConnectionRequestParams());
      dispatch(clearLeadRequestParams());
      dispatch(clearBlacklistConnectionRequestParams());
      dispatch(clearBlacklistLeadsRequestParams());

      dispatch(setConnectionSelectedLeads([]));
      dispatch(setSelectedLeads([]));
    }
  }, [pathname]);

  useEffect(() => {
    if (teamDataFresh) {
      dispatch(setTeam(teamDataFresh));
    }
  }, [teamDataFresh]);

  useEffect(() => {
    if (isAppPaused) {
      navigate('/billing');
    }
  }, [isAppPaused, pathname]);

  return { isAuth, isAppDeactivated };
};
