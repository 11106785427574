import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';

export const TabsContainer = styled('div', {
  shouldForwardProp: (prop) => isPropValid(prop),
})`
  width: auto;
  justify-content: space-around;
  display: inline-flex;
  align-items: center;

  &:last-child {
    box-shadow: none;
  }
`;
