import { useState } from 'react';
import styled from '@emotion/styled';
import { Accordion, Box, BoxProps } from '@mui/material';

import { Typography } from 'src/components';

type TAccordionStyle = {
  bgcolor: string;
  borderColor: string;
  color: string;
};
const BLACK_LIST_PROPS = ['borderColor'];

const StyledAccordion = styled(Accordion, {
  shouldForwardProp: (prop) => !BLACK_LIST_PROPS.includes(prop),
})<TAccordionStyle>`
  font-size: 14px;
  line-height: 14px;
  box-shadow: none;
  border-radius: 4px;
  overflow: hidden;
  background-color: ${({ bgcolor }) => bgcolor};
  color: ${({ color }) => color};
  border: 1px solid ${({ borderColor }) => borderColor};
`;

const OpenTrigger = styled(Typography)`
  color: ${({ theme }) => theme.palette.info.dark};
  display: inline;
  cursor: pointer;
  font-weight: 600;
  margin-left: 20px;

  &:hover {
    color: ${({ theme }) => theme.palette.info.main};
  }
`;

const Code = styled.pre`
  font-family: courier;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  overflow: auto;
  color: #333;
`;

type TResponseProps = BoxProps & {
  type: 'info' | 'success' | 'error';
  title: string;
  responses: { json: Record<string, unknown>; title?: string }[];
};

const statusResolver = (type: 'info' | 'success' | 'error') => {
  switch (type) {
    case 'info':
      return {
        bgcolor: '#d9edf7',
        borderColor: '#bce8f1',
        color: '#31708f',
      };
    case 'success':
      return {
        bgcolor: '#dff0d8',
        borderColor: '#d6e9c6',
        color: '#3c763d',
      };
    case 'error':
      return {
        bgcolor: '#f2dede',
        borderColor: '#eed3d7',
        color: '#b94a48',
      };
    default:
      return null;
  }
};

export const Response = ({ title, responses, type, ...boxProps }: TResponseProps) => {
  const [expanded, setExpanded] = useState(false);

  const styleType = statusResolver(type);

  const toggleAccordion = () => setExpanded((prev) => !prev);

  if (!styleType) {
    return null;
  }

  return (
    <Box {...boxProps}>
      <StyledAccordion expanded={expanded} {...styleType}>
        <Box p="12px">
          {title} <OpenTrigger onClick={toggleAccordion}>Expand</OpenTrigger>
        </Box>

        <Box p="0 20px">
          {responses.map((response, index) => (
            <Box key={index} mb="20px">
              {response.title && (
                <Typography semibold color="darkred">
                  {response.title}
                </Typography>
              )}
              <Code>{JSON.stringify(response.json, null, 4)}</Code>
            </Box>
          ))}
        </Box>
      </StyledAccordion>
    </Box>
  );
};
