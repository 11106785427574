import classNames from 'classnames';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';

import { HeaderContainer, Tooltip } from 'src/components';
import { CampaignCategory } from 'src/enums';
import { useSingleCampaignProps } from 'src/hooks';

const Circle = styled(Box)`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'ProximaSoft', sans-serif;
  font-weight: 700;
  margin-right: 10px;
  background-color: ${({ theme }) => theme.palette.light.light};
  color: ${({ theme }) => theme.palette.primary.wlLight};

  &.active {
    background-color: ${({ theme }) => theme.palette.primary.wlLight};
    color: #fff;
  }
`;

const Tab = styled(Box)<{ cursor: string }>`
  margin-right: 90px;
  cursor: ${(props) => props.cursor};
`;

interface ICampaignsStepsProps {
  children?: ReactNode;
}

export const CampaignsSteps = ({ children }: ICampaignsStepsProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAddMoreLeads, isCsvUploadCampaign, isConnectionsCampaign, campaignId, campaign } = useSingleCampaignProps();

  const addLeadsDisabled = isCsvUploadCampaign || campaign?.status !== 'draft' || isConnectionsCampaign;
  const isChooseGeneralType =
    location.pathname.startsWith('/campaign/new') || location.pathname.startsWith('/campaign/add-leads');

  const steps = [
    {
      name: 'Add Leads',
      activeRoutes: [
        `campaign/new/(${CampaignCategory.LINKEDIN}|${CampaignCategory.EMAIL}|${CampaignCategory.TWITTER}|${CampaignCategory.MULTICHANNEL}|${CampaignCategory.POST}|${CampaignCategory.GROUP}|${CampaignCategory.EVENT}|${CampaignCategory.INMAIL})`,
        `campaign/add-leads/(${CampaignCategory.LINKEDIN}|${CampaignCategory.EMAIL}|${CampaignCategory.TWITTER}|${CampaignCategory.MULTICHANNEL}|${CampaignCategory.POST}|${CampaignCategory.GROUP}|${CampaignCategory.EVENT}|${CampaignCategory.INMAIL})`,
        '/campaign/[\\d]+/audience',
        '/campaign/[\\d]+/sequence',
        '/campaign/[\\d]+/publish',
      ],
      path: 'audience',
      isDisabled: addLeadsDisabled || isChooseGeneralType,
      tooltip:
        isCsvUploadCampaign && !location.pathname.startsWith(`/campaign/${campaignId}/audience`)
          ? 'Leads are already uploaded'
          : '',
    },
    {
      name: 'Set Sequence',
      activeRoutes: ['/campaign/[\\d]+/sequence', '/campaign/[\\d]+/publish'],
      path: 'sequence',
      isShow: !isAddMoreLeads,
    },
    {
      name: 'Review And Publish',
      activeRoutes: ['/campaign/[\\d]+/publish'],
      path: 'publish',
      isShow: !isAddMoreLeads,
    },
  ];

  return (
    <HeaderContainer display="flex" alignItems="center" justifyContent="space-between" py="20px">
      {steps.map(({ name, activeRoutes, path, tooltip = '', isDisabled = false, isShow = true }, i) => {
        const isCurrentTab = location.pathname.split('/').pop() === path;
        const isActive = new RegExp(`(${activeRoutes.join('|')})`).test(location.pathname);

        if (!isShow) {
          return null;
        }

        const activeCursor = isActive ? 'pointer' : 'default';

        const onTabClick = () => {
          switch (true) {
            case isCurrentTab || !isActive:
              return;
            case isDisabled:
              return;
            case isConnectionsCampaign && path === 'audience':
              return navigate(`/contacts/connections`, location);
            default:
              return navigate(`/campaign/${campaignId}/${path}`, location);
          }
        };

        return (
          <Tab key={name} cursor={isDisabled ? 'not-allowed' : activeCursor} onClick={onTabClick}>
            <Tooltip title={tooltip}>
              <Box display="flex" alignItems="center">
                <Circle
                  className={classNames({
                    active: isActive,
                  })}
                >
                  {i + 1}
                </Circle>
                <Typography color="primary.wlLight" fontSize="14px">
                  {name}
                </Typography>
              </Box>
            </Tooltip>
          </Tab>
        );
      })}

      <Box ml="auto" display="flex" gap="20px">
        {children}
      </Box>
    </HeaderContainer>
  );
};
