import { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button, Input } from 'src/components';
import { closeModal } from 'src/store/modal.slice';
import { ICampaignKeywords } from 'src/types';

const ClearAllBlock = styled(Box)`
  margin-left: 20px;
  padding: 5px 15px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.wlLight};
  border: 1px solid ${({ theme }) => theme.palette.light.dark};
  font-size: 10px;
  border-radius: 4px;
  transition: 0.2s;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.wlLight};
    border: 1px solid ${({ theme }) => theme.palette.primary.wlLight};
    color: #fff;
  }
`;

const StyledInput = styled(Input)`
  padding: 5px 10px;
  margin: 20px 0;
  height: auto;
`;

interface IModalCampaignKeywordsProps {
  values: ICampaignKeywords;
  setValues: (newValues: ICampaignKeywords) => void;
}

export const ModalCampaignKeywords = ({
  values: propValues,
  setValues: setValuesProp,
}: IModalCampaignKeywordsProps) => {
  const [values, setValues] = useState(propValues);
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeModal());
  };

  const change = (e: ChangeEvent<HTMLInputElement>, name: keyof ICampaignKeywords) => {
    const newValues = { ...values, [name]: e.target.value };
    setValues(newValues);
    setValuesProp(newValues);
  };

  const showClearAll = !!Object.values(values).find((value) => value);

  const clearAll = () => {
    const cleared: ICampaignKeywords = {
      firstName: '',
      lastName: '',
      title: '',
      company: '',
      school: '',
    };
    setValues(cleared);
    setValuesProp(cleared);
  };

  return (
    <>
      <Box py="30px" px="40px" display="flex" alignItems="center" fontSize="14px" height="20px">
        Specify keywords
        {showClearAll && <ClearAllBlock onClick={clearAll}>X CLEAR ALL</ClearAllBlock>}
      </Box>
      <Box py="30px" px="40px" bgcolor="light.light">
        <StyledInput
          placeholder="First name"
          name="firstName"
          value={values.firstName || ''}
          onChange={(e) => change(e, 'firstName')}
        />
        <StyledInput
          placeholder="Last name"
          name="lastName"
          value={values.lastName || ''}
          onChange={(e) => change(e, 'lastName')}
        />
        <StyledInput placeholder="Title" name="title" value={values.title || ''} onChange={(e) => change(e, 'title')} />
        <StyledInput
          placeholder="Company"
          name="company"
          value={values.company || ''}
          onChange={(e) => change(e, 'company')}
        />
        <StyledInput
          placeholder="School"
          name="school"
          value={values.school || ''}
          onChange={(e) => change(e, 'school')}
        />
      </Box>
      <Box py="20px" px="40px" display="flex" justifyContent="flex-end">
        <Button onClick={close}>Save</Button>
      </Box>
    </>
  );
};
