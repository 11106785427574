import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';

import { Toggle } from 'src/components';
import { useAppSelector, useCompanyTheme } from 'src/hooks';
import { useUpdateConnection } from 'src/reactQueries';
import { setBlacklistLeadsSelectedLeads, setConnectionSelectedLeads } from 'src/store';

export const ToggleConnection = ({ entityUrn, isExcluded }: { entityUrn: string; isExcluded: boolean }) => {
  const dispatch = useDispatch();
  const {
    exportData: { selectedLeads: leads },
  } = useAppSelector((state) => state.connections);
  const {
    exportData: { selectedLeads: blacklistLeads },
  } = useAppSelector((state) => state.blacklistConnections);

  const { companyName } = useCompanyTheme();

  const { updateConnection } = useUpdateConnection(entityUrn, {
    onMutate: () => {
      if (leads.find((lead) => lead.entityUrn === entityUrn)) {
        dispatch(
          setConnectionSelectedLeads(
            leads.map((lead) => (lead.entityUrn === entityUrn ? { ...lead, isExcluded: !isExcluded } : lead)),
          ),
        );
      }
      if (blacklistLeads.find((lead) => lead.entityUrn === entityUrn)) {
        dispatch(
          setBlacklistLeadsSelectedLeads(
            blacklistLeads.map((lead) => (lead.entityUrn === entityUrn ? { ...lead, isExcluded: !isExcluded } : lead)),
          ),
        );
      }
    },
  });
  const { palette } = useTheme();

  return (
    <Toggle
      tooltip={{
        title: `Toggle to exclude this lead from all ${companyName} actions across your campaigns`,
        placement: 'left',
      }}
      checked={isExcluded}
      name={entityUrn}
      checkedBgColor={palette.error['300']}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        updateConnection({ isExcluded: e.currentTarget.checked });
      }}
    />
  );
};
