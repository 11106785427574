import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Link, ProgressBar, Typography } from 'src/components';
import { Features } from 'src/enums';
import { useGetTeamCount, useTeamPlan } from 'src/hooks';
import { useGetBillingInfo } from 'src/reactQueries';
import { BlockTitle } from '../_components';

const AvailableSeatsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 24px;

  background-color: ${({ theme }) => theme.palette.gray[50]}80;
  border: 1px solid ${({ theme }) => theme.palette.gray[100]};
  border-radius: 4px;
  padding: 20px;
`;

export const AvailableSeats = () => {
  const { palette } = useTheme();

  const { checkFeature } = useTeamPlan();

  const { billingInfo } = useGetBillingInfo();

  const teamCount = useGetTeamCount();

  const progress = (teamCount / (billingInfo?.seats || 1) || 0) * 100;
  const availableSeats = (billingInfo?.seats || 1) - teamCount;

  return (
    <AvailableSeatsWrapper>
      <Box display="flex" alignItems="center">
        <BlockTitle inline>Available seats</BlockTitle>

        <Typography medium fontSize="15px" color="gray.800" inline ml="auto">
          {availableSeats} of {billingInfo?.seats}
        </Typography>
      </Box>

      <ProgressBar progress={progress} bgColor={palette.gray[200]} height="12px" />

      {checkFeature(Features.manageTeam) && (
        <Link leftIcon to="/team/manage-team" underline underlineColor={palette.brand[200]} pb="2px">
          View team users
        </Link>
      )}
    </AvailableSeatsWrapper>
  );
};
