export enum ModalTypes {
  SIGNATURE = 'SIGNATURE',
  USE_EMAIL_ALIAS = 'USE_EMAIL_ALIAS',
  CREATE_TREND = 'CREATE_TREND',
  DELETE_TREND = 'DELETE_TREND',
  POST_BY = 'POST_BY',
  POST_AUDIENCE = 'POST_AUDIENCE',
  CREATE_TAG = 'CREATE_TAG',
  UPDATE_TAG = 'UPDATE_TAG',
  DELETE_TAG = 'DELETE_TAG',
  DELETE_TEMPLATE = 'DELETE_TEMPLATE',
  VIEW_TEMPLATE = 'VIEW_TEMPLATE',
  INTEGRATIONS = 'INTEGRATIONS',
  DELETE_CONVERSATION = 'DELETE_CONVERSATION',
  ADD_CONNECTIONS_TAGS = 'ADD_CONNECTIONS_TAGS',
  DELETE_LEAD_TAG = 'DELETE_LEAD_TAG',
  DELETE_TEAM_MEMBER = 'DELETE_TEAM_MEMBER',
  SELECT_CONNECTION_DEGREE = 'SELECT_CONNECTION_DEGREE',
  CAMPAIGN_KEYWORDS = 'CAMPAIGN_KEYWORDS',
  SELECT_PROFILE_LANGUAGE = 'SELECT_PROFILE_LANGUAGE',
  AUTOCOMPLETE_SEARCH = 'AUTOCOMPLETE_SEARCH',
  CHOOSE_TEMPLATE = 'CHOOSE_TEMPLATE',
  CONFIRM_INVITATION_TO_TEAM = 'CONFIRM_INVITATION_TO_TEAM',
  EXPORT_LEAD_CSV = 'EXPORT_LEAD_CSV',
  EXPORT_REPLIES = 'EXPORT_REPLIES',
  TEAM_GROUP_CREATE = 'TEAM_GROUP_CREATE',
  VIEW_LEAD = 'VIEW_LEAD',
  TEAM_GROUP_UPDATE = 'TEAM_GROUP_UPDATE',
  TEAM_GROUP_DELETE = 'TEAM_GROUP_DELETE',
  CHANGE_EMAIL = 'CHANGE_EMAIL',
  PAUSE_SUBSCRIPTION = 'PAUSE_SUBSCRIPTION',
  CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION',
  COMPANY_INFO = 'COMPANY_INFO',
  INVITE = 'INVITE',
  UPDATE_CREDIT_CARD = 'UPDATE_CREDIT_CARD',
  SAVE_BILLING_CYCLE = 'SAVE_BILLING_CYCLE',
  DOWNGRADE_ROLE = 'DOWNGRADE_ROLE',
  RESCHEDULE_POST = 'RESCHEDULE_POST',
  RESTART_CAMPAIGN_SEARCH = 'RESTART_CAMPAIGN_SEARCH',
  ADD_LEADS_TAGS = 'ADD_LEADS_TAGS',
  EXPORT_CONTACTS = 'EXPORT_CONTACTS',
  TEST_SMTP = 'TEST_SMTP',
  EXPORT_TEAM_STATS_CSV = 'EXPORT_TEAM_STATS_CSV',
  EXPORT_UNSUBSCRIBED_EMAILS_CSV = 'EXPORT_UNSUBSCRIBED_EMAILS_CSV',
  EXPORT_CAMPAIGN_ACTIVITY_CSV = 'EXPORT_CAMPAIGN_ACTIVITY_CSV',
  BILLING_HISTORY = 'BILLING_HISTORY',
  SET_PASSWORD = 'SET_PASSWORD',
  SEND_TEST_EMAIL = 'SEND_TEST_EMAIL',
  VIEW_SEQUENCE_TEMPLATE = 'VIEW_SEQUENCE_TEMPLATE',
  CHOOSE_SEQUENCE_TEMPLATE = 'CHOOSE_SEQUENCE_TEMPLATE',
  SAVE_AS_SEQUENCE_TEMPLATE = 'SAVE_AS_SEQUENCE_TEMPLATE',
}

export enum ModalCancelSubscriptionSteps {
  INFO = 'info',
  PAUSE = 'pause',
  CHOOSE_REASON = 'choose_reason',
  CONFIRM_CANCEL = 'confirm_cancel',
}
