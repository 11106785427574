export const getProperClassName = (className: string) => {
  return className
    .toLowerCase()
    .replace(/[^A-Za-z0-9]/g, '')
    .replace(/\s+/g, '_');
};

export const capitalizeFirstLetter = (string = '') => {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
};
