import { LANDING_URL, PLAN_SEATS } from 'src/constants';
import { CancelSubscriptionReason, CurrencyEnum, Plan } from 'src/enums';
import { getRandomNotSecuredIntNumber } from 'src/helpers';
import { IBillingRedirectUrlParams, IStripeInvoice } from 'src/types';

export const shuffleReasons = (): CancelSubscriptionReason[] => {
  const reasons = [
    CancelSubscriptionReason.COST,
    CancelSubscriptionReason.DIFFICULTY_OF_USE,
    CancelSubscriptionReason.MISSING_FUNCTIONALITY,
    CancelSubscriptionReason.USING_OTHER_PRODUCT,
    CancelSubscriptionReason.TECHNICAL_ISSUES,
  ];

  for (let i = reasons.length - 1; i > 0; i--) {
    const j = getRandomNotSecuredIntNumber(i + 1);
    [reasons[i], reasons[j]] = [reasons[j], reasons[i]];
  }

  reasons.push(CancelSubscriptionReason.SOMETHING_ELSE);

  return reasons;
};

export const getCurrencySymbol = (currency?: CurrencyEnum): string => {
  switch (currency) {
    case CurrencyEnum.EUR:
      return '€';
    case CurrencyEnum.GBP:
      return '£';
    case CurrencyEnum.USD:
      return '$';
    default:
      return '';
  }
};

export const getAmountString = (number = 0, options?: Intl.NumberFormatOptions): string => {
  return (number / 100 || 0).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...(options || {}),
  });
};

export const calculateUserInvoice = (invoiceDetails?: IStripeInvoice) => {
  return (
    invoiceDetails?.lines.data.reduce((gen, line) => {
      if (line.description === 'Referral program discount') {
        gen.referral = {
          total: (gen.referral?.total || 0) + line.amount,
        };

        return gen;
      }
      if (line.description.includes('Unused')) {
        if (!gen.prorata) {
          gen.prorata = 0;
        }
        gen.prorata += line.amount;

        return gen;
      }

      if (line.description.includes('Remaining')) {
        if (!gen.remaining) {
          gen.remaining = 0;
        }
        gen.remaining += line.amount;

        return gen;
      }

      if (line.plan.billing_scheme === 'tiered') {
        if (!gen.graduated) {
          gen.graduated = line.quantity;
        }
        if (line.plan.tiers_mode === 'graduated' && gen.graduated > 1) {
          if (!gen.first || !Number(line.unit_amount_excluding_tax)) {
            gen.first = {
              price: gen.first?.price || line.amount / line.quantity,
              quantity: gen.first?.quantity || line.quantity,
              total: gen.first?.total || line.amount,
            };

            return gen;
          }
          gen.extra = {
            price: line.amount / line.quantity,
            quantity: line.quantity,
            total: line.amount,
          };

          return gen;
        }
        gen.graduated = false;

        if (!gen.first) {
          gen.first = {
            price: line.amount / line.quantity,
            quantity: line.quantity,
            total: line.amount,
          };

          return gen;
        }
        gen.extra = {
          price: line.amount / line.quantity,
          quantity: line.quantity,
          total: line.amount,
        };

        return gen;
      }

      gen.users = {
        price: line.amount / line.quantity,
        quantity: line.quantity,
        total: line.amount,
      };

      return gen;
    }, {} as any) || {} //eslint-disable-line
  );
};

export const redirectToPaymentSuccessPage = (urlParams: IBillingRedirectUrlParams) => {
  const redirectUrl = new URL(`${LANDING_URL}/payment-success`);

  redirectUrl.searchParams.set('seatsUpgrade', urlParams.isSeatsUpgrade ? 'yes' : 'no');
  redirectUrl.searchParams.set('planUpgrade', urlParams.isPlanUpgrade ? 'yes' : 'no');
  redirectUrl.searchParams.set('planRenewal', urlParams.isPlanRenewal ? 'yes' : 'no');
  redirectUrl.searchParams.set('newCustomer', urlParams.isNewCustomer ? 'yes' : 'no');

  window.location.href = redirectUrl.toString();
};

export const resolveDowngradePlanText = (newPlan: Plan, oldPlan: Plan, isWhiteLabelActive: boolean): string => {
  if (newPlan === oldPlan) return '';

  if (newPlan === Plan.INDIVIDUAL) {
    switch (oldPlan) {
      case Plan.AGENCY:
      case Plan.ENTERPRISE:
        return isWhiteLabelActive
          ? 'By downgrading your account you will lose access to exclusive features like White Label, and all integrations (except for LinkedIn) will be disabled. Are you sure you want to downgrade?'
          : 'By downgrading your account, you will lose access to exclusive features and all integrations (except for LinkedIn) will be disabled. Are you sure you want to downgrade?';
      case Plan.BUSINESS:
        return 'By downgrading your account, you will lose access to exclusive features and all integrations (except for LinkedIn) will be disabled. Are you sure you want to downgrade?';
    }
  }

  if (
    isWhiteLabelActive &&
    [Plan.AGENCY, Plan.ENTERPRISE].includes(oldPlan) &&
    ![Plan.AGENCY, Plan.ENTERPRISE].includes(newPlan)
  ) {
    return 'If you downgrade your plan White Label app will be deactivated. Are you sure you would like to downgrade your plan?';
  }

  return '';
};

export const resolveDefaultPaidPlanForSelector = (plan: Plan, teamCount: number): Plan => {
  if (plan === Plan.TRIAL || !plan) {
    return teamCount < 11 ? Plan.BUSINESS : Plan.AGENCY;
  }

  return plan;
};

export const resolveSeatsForPaidPlan = (
  plan: Plan,
  seats: number,
  teamCount: number,
  defaultNumber: number = seats,
): number => {
  const { seats: maxSeats = 1, minSeats = 1 } = PLAN_SEATS[plan] || {};

  if (maxSeats < teamCount || maxSeats < seats) {
    return maxSeats;
  }

  if (minSeats > teamCount || minSeats > seats) {
    return Math.max(seats, minSeats);
  }

  return defaultNumber;
};

export const checkIfPlanIsDowngrade = (newPlan: Plan, oldPlan: Plan): boolean => {
  if (!oldPlan || oldPlan === Plan.TRIAL) return false;

  if (newPlan === oldPlan) return false;

  if (newPlan === Plan.INDIVIDUAL) {
    return [Plan.BUSINESS, Plan.AGENCY, Plan.ENTERPRISE].includes(oldPlan);
  }

  if (newPlan === Plan.BUSINESS) {
    return [Plan.AGENCY, Plan.ENTERPRISE].includes(oldPlan);
  }

  return false;
};
