import { useQuery, UseQueryOptions } from 'react-query';

import { getUserLinkedInProfile } from 'src/api';
import { ICustomAxiosError, ILinkedInProfileData } from 'src/types';

export const useGetLinkedInProfile = (options?: UseQueryOptions<ILinkedInProfileData, ICustomAxiosError>) => {
  const { data, ...rest } = useQuery<ILinkedInProfileData, ICustomAxiosError>(
    ['get-user-linked-in-profile'],
    getUserLinkedInProfile,
    {
      refetchOnMount: true,
      ...options,
    },
  );

  return { linkedinProfile: data, ...rest };
};
