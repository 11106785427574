import { useQuery, UseQueryOptions } from 'react-query';

import { getPlanPricing } from 'src/api';
import { ICustomAxiosError, IGetPlanPricing, IPlanPricing } from 'src/types';

export const useGetPlanPricing = (
  { plan, billingType, currency }: IGetPlanPricing,
  options?: UseQueryOptions<IGetPlanPricing, ICustomAxiosError, IPlanPricing>,
) => {
  return useQuery<IGetPlanPricing, ICustomAxiosError, IPlanPricing>(
    ['get-plan-pricing', plan, billingType, currency],
    () => getPlanPricing({ plan, billingType, currency }),
    { enabled: !!plan && !!billingType && !!currency, ...options },
  );
};
