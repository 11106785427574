import { FieldArrayWithId, UseFieldArrayRemove, UseFormReturn } from 'react-hook-form';

import { CampaignCategory, CampaignSequenceStepType } from 'src/enums';
import { getCampaignTypes } from 'src/helpers';
import { IGetCampaign, ISequence } from 'src/types';
import { ConnectionRequest } from './SequenceSteps/ConnectionRequest';
import { EngageWithProfile } from './SequenceSteps/EngageWithProfile';
import { SendEmail } from './SequenceSteps/SendEmail';
import { SendEventMessage } from './SequenceSteps/SendEventMessage';
import { SendGroupMessage } from './SequenceSteps/SendGroupMessage';
import { SendInMail } from './SequenceSteps/SendInMail';
import { SendMessage } from './SequenceSteps/SendMessage';
import { TwitterDirectMessage } from './SequenceSteps/TwitterDirectMessage';
import { TwitterFollow } from './SequenceSteps/TwitterFollow';
import { SequenceWrapper } from './SequenceWrapper/SequenceWrapper';

interface ISequenceStepProps {
  step: FieldArrayWithId<ISequence, 'sequence'>;
  index: number;
  campaign?: IGetCampaign;
  sequenceForm: UseFormReturn<ISequence>;
  remove: UseFieldArrayRemove;
  fields: FieldArrayWithId<ISequence, 'sequence'>[];
}

export const SequenceStep = ({ index, step, campaign, sequenceForm, fields, remove }: ISequenceStepProps) => {
  const { isMultichannelCampaign } = getCampaignTypes(campaign);
  const canEditSequence = campaign?.status === 'draft';
  const { setValue, watch, register } = sequenceForm;

  const { type, id: fieldId, is_editing } = step ?? {};
  const highlight = fields?.length !== 1 && index !== fields?.length - 1;

  switch (type) {
    case CampaignSequenceStepType.LINKED_IN_VIEW:
      return (
        <SequenceWrapper
          key={fieldId}
          title="Engage with profile"
          index={index}
          watch={watch}
          setValue={setValue}
          highlight={highlight}
          remove={remove}
          register={register}
          canEdit={canEditSequence || is_editing}
          icon={CampaignCategory.LINKEDIN}
        >
          <EngageWithProfile key={fieldId} index={index} watch={watch} setValue={setValue} />
        </SequenceWrapper>
      );
    case CampaignSequenceStepType.LINKED_IN_MESSAGE:
      return (
        <SequenceWrapper
          key={fieldId}
          title="Send a message"
          index={index}
          watch={watch}
          setValue={setValue}
          highlight={highlight}
          remove={remove}
          register={register}
          canEdit={canEditSequence || is_editing}
          icon={CampaignCategory.LINKEDIN}
        >
          <SendMessage key={fieldId} index={index} customSnippets={campaign?.customSnippets ?? {}} {...sequenceForm} />
        </SequenceWrapper>
      );
    case CampaignSequenceStepType.LINKED_IN_CONNECT:
      return (
        <SequenceWrapper
          key={fieldId}
          title="Send a connection request"
          index={index}
          watch={watch}
          setValue={setValue}
          highlight={highlight}
          remove={remove}
          register={register}
          canEdit={canEditSequence || is_editing}
          icon={CampaignCategory.LINKEDIN}
        >
          <ConnectionRequest
            key={fieldId}
            index={index}
            customSnippets={campaign?.customSnippets ?? {}}
            {...sequenceForm}
          />
        </SequenceWrapper>
      );
    case CampaignSequenceStepType.IN_MAIL_MESSAGE:
      return (
        <SequenceWrapper
          key={fieldId}
          title="Send a InMail message"
          index={index}
          watch={watch}
          setValue={setValue}
          highlight={highlight}
          remove={remove}
          register={register}
          canEdit={canEditSequence || is_editing}
          icon={CampaignCategory.LINKEDIN}
        >
          <SendInMail key={fieldId} index={index} customSnippets={campaign?.customSnippets ?? {}} {...sequenceForm} />
        </SequenceWrapper>
      );
    case CampaignSequenceStepType.EMAIL:
      return (
        <SequenceWrapper
          key={fieldId}
          title="Send email"
          index={index}
          watch={watch}
          setValue={setValue}
          highlight={highlight}
          remove={remove}
          register={register}
          canEdit={canEditSequence || is_editing}
          icon={CampaignCategory.EMAIL}
        >
          <SendEmail
            key={fieldId}
            index={index}
            customSnippets={campaign?.customSnippets ?? {}}
            isMultichannelCampaign={isMultichannelCampaign}
            {...sequenceForm}
          />
        </SequenceWrapper>
      );
    case CampaignSequenceStepType.TWITTER_FOLLOW:
      return (
        <SequenceWrapper
          key={fieldId}
          title="Follow Lead on X (Twitter)"
          index={index}
          watch={watch}
          setValue={setValue}
          highlight={highlight}
          remove={remove}
          register={register}
          canEdit={canEditSequence || is_editing}
          icon={CampaignCategory.TWITTER}
        >
          <TwitterFollow key={fieldId} index={index} watch={watch} setValue={setValue} />
        </SequenceWrapper>
      );
    case CampaignSequenceStepType.TWITTER_DM:
      return (
        <SequenceWrapper
          key={fieldId}
          title="Send X (Twitter) Direct Message"
          index={index}
          watch={watch}
          setValue={setValue}
          highlight={highlight}
          remove={remove}
          register={register}
          canEdit={canEditSequence || is_editing}
          icon={CampaignCategory.TWITTER}
        >
          <TwitterDirectMessage
            key={fieldId}
            index={index}
            customSnippets={campaign?.customSnippets ?? {}}
            {...sequenceForm}
          />
        </SequenceWrapper>
      );
    case CampaignSequenceStepType.GROUP_MESSAGE:
      return (
        <SequenceWrapper
          key={fieldId}
          title="Send a message to Group member"
          index={index}
          watch={watch}
          setValue={setValue}
          highlight={highlight}
          remove={remove}
          register={register}
          canEdit={canEditSequence || is_editing}
          icon={CampaignCategory.GROUP}
        >
          <SendGroupMessage key={fieldId} index={index} {...sequenceForm} />
        </SequenceWrapper>
      );
    case CampaignSequenceStepType.EVENT_MESSAGE:
      return (
        <SequenceWrapper
          key={fieldId}
          title="Send a message to Event members"
          index={index}
          watch={watch}
          setValue={setValue}
          highlight={highlight}
          remove={remove}
          register={register}
          canEdit={canEditSequence || is_editing}
          icon={CampaignCategory.EVENT}
        >
          <SendEventMessage key={fieldId} index={index} {...sequenceForm} />
        </SequenceWrapper>
      );
    default:
      return null;
  }
};
