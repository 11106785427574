import { useMemo } from 'react';

import { PLAN_TOOLTIPS } from 'src/constants';
import { CampaignCategory, CampaignSequenceStepType, CampaignType, Features } from 'src/enums';
import { IGetCampaign, ISequenceConfig, ISequenceStep } from 'src/types';
import { useTeamPlan } from './useTeamPlan';
import { useWhiteLabel } from './useWhiteLabel';

export const useAddSequenceStepConfig = (
  steps: ISequenceStep[],
  category?: CampaignCategory,
  campaign?: IGetCampaign,
): ISequenceConfig[] => {
  const { whitelabel } = useWhiteLabel();
  const { checkFeature } = useTeamPlan();

  const isLinkedinCampaign = [
    CampaignCategory.LINKEDIN,
    CampaignCategory.INMAIL,
    CampaignCategory.POST,
    CampaignCategory.EVENT,
    CampaignCategory.GROUP,
  ].includes(category as CampaignCategory);
  const isEmailCampaign = category === CampaignCategory.EMAIL;
  const isTwitterCampaign = category === CampaignCategory.TWITTER;
  const isMultichannelCampaign = category === CampaignCategory.MULTICHANNEL;
  const isLinkedInGroupCampaign = category === CampaignCategory.GROUP;
  const isLinkedInEventCampaign = category === CampaignCategory.EVENT;
  const isLinkedInConnectionsCampaign = campaign?.campaignType === CampaignType.LINKEDIN_CONNECTIONS;

  const isEventMessageStepAdded = useMemo(
    () => steps.some((step) => step.type === CampaignSequenceStepType.EVENT_MESSAGE),
    [steps],
  );
  const isLinkedInConnectedStepAdded = useMemo(
    () => steps.some((step) => step.type === CampaignSequenceStepType.LINKED_IN_CONNECT),
    [steps],
  );
  const isTwitterFollowedStepAdded = useMemo(
    () => steps.some((step) => step.type === CampaignSequenceStepType.TWITTER_FOLLOW),
    [steps],
  );

  const isSendGroupMessageAllowed = checkFeature(Features.groupMessageCampaignStep);
  const isEventMessageAllowed = checkFeature(Features.eventMessageCampaignStep);
  const isInMailAllowed = checkFeature(Features.inMailCampaignStep);

  return [
    {
      show: true,
      type: CampaignCategory.LINKEDIN,
      disabled: !isLinkedinCampaign && !isMultichannelCampaign,
      disabledTooltip: 'Available in Multichannel and Linkedin only campaigns.',
      steps: [
        { title: 'Engage with Profile', stepType: CampaignSequenceStepType.LINKED_IN_VIEW, id: 'engage-option-step' },
        {
          title: 'Send Connection Request',
          show: [CampaignCategory.LINKEDIN, CampaignCategory.MULTICHANNEL, CampaignCategory.POST].includes(
            category as CampaignCategory,
          ),
          stepType: CampaignSequenceStepType.LINKED_IN_CONNECT,
          disabled: isLinkedInConnectedStepAdded || isLinkedInConnectionsCampaign,
          id: 'connection-request-option-step',
        },
        {
          title: 'Send Linkedin Message',
          show: [CampaignCategory.LINKEDIN, CampaignCategory.MULTICHANNEL, CampaignCategory.POST].includes(
            category as CampaignCategory,
          ),
          stepType: CampaignSequenceStepType.LINKED_IN_MESSAGE,
          disabled: isLinkedInEventCampaign && !isEventMessageStepAdded,
          disabledTooltip:
            (isLinkedInEventCampaign &&
              !isEventMessageStepAdded &&
              'LinkedIn Message is only available after Event Message') ||
            '',
        },
        {
          title: 'Send InMail',
          show: [CampaignCategory.INMAIL].includes(category as CampaignCategory),
          stepType: CampaignSequenceStepType.IN_MAIL_MESSAGE,
          disabled: !isInMailAllowed,
          disabledTooltip: (!isInMailAllowed && PLAN_TOOLTIPS.professional) || '',
        },
        {
          title: 'Send Event Message',
          show: [CampaignCategory.EVENT].includes(category as CampaignCategory),
          stepType: CampaignSequenceStepType.EVENT_MESSAGE,
          disabled: !isLinkedInEventCampaign || isEventMessageStepAdded || !isEventMessageAllowed,
          disabledTooltip:
            (!isLinkedInEventCampaign && 'Available on Linkedin Event campaigns only') ||
            (isLinkedInEventCampaign && isEventMessageStepAdded && 'Event Message can only be used once') ||
            (!isEventMessageAllowed && PLAN_TOOLTIPS.professional) ||
            '',
        },
        {
          title: 'Send Group Message',
          show: [CampaignCategory.GROUP].includes(category as CampaignCategory),
          stepType: CampaignSequenceStepType.GROUP_MESSAGE,
          disabled: !isLinkedInGroupCampaign || !isSendGroupMessageAllowed,
          disabledTooltip:
            (!isLinkedInGroupCampaign && 'Available on Linkedin Group campaigns only') ||
            (!isSendGroupMessageAllowed && PLAN_TOOLTIPS.professional) ||
            '',
        },
      ],
    },
    {
      show: true,
      type: CampaignCategory.EMAIL,
      stepType: CampaignSequenceStepType.EMAIL,
      tooltip: 'Send Email',
      disabled: !isEmailCampaign && !isMultichannelCampaign,
      disabledTooltip: 'Available in Multichannel and Email only campaigns',
      steps: [],
    },
    {
      show: !whitelabel.isWhiteLabelUser,
      type: CampaignCategory.TWITTER,
      // TODO return to previous condition when Twitter issues are resolved
      disabled: !isMultichannelCampaign,
      disabledTooltip: isTwitterCampaign
        ? 'Feature under Maintenance'
        : 'Available in Multichannel and X (Twitter) only campaigns',
      steps: [
        {
          title: 'Send X (Twitter) Direct Message',
          stepType: CampaignSequenceStepType.TWITTER_DM,
          disabled: true,
          disabledTooltip: 'Feature under Maintenance',
        },
        {
          title: 'Follow Lead on X (Twitter)',
          stepType: CampaignSequenceStepType.TWITTER_FOLLOW,
          disabled: isTwitterFollowedStepAdded,
        },
      ],
    },
  ];
};
