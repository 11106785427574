import { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { Button, RadioButtonOld, Typography } from 'src/components';
import { BillingTypeEnum } from 'src/enums';
import { useCompanyTheme } from 'src/hooks';
import { useApplyDiscount, useGetBillingInfo } from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';

interface IDiscountProps {
  header: string;
  subheader: string;
  showForFree?: boolean;
}

export const Discount = ({ header, subheader, showForFree }: IDiscountProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shortCompanyName } = useCompanyTheme();

  const [discount, setDiscount] = useState<number>();
  const [showError, setShowError] = useState(false);

  const { billingInfo } = useGetBillingInfo();
  const { applyDiscount, isLoading } = useApplyDiscount({
    onSuccess: () => {
      dispatch(closeModal());
    },
  });

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setShowError(false);
    setDiscount(Number(e.target.value));
  };

  const navigateToReferFriend = () => {
    navigate('/settings/refer-friend');
    dispatch(closeModal());
  };

  const submitDiscount = () => {
    if (!discount) {
      setShowError(true);
    } else {
      applyDiscount(discount);
    }
  };

  return (
    <Box color="violet.dark">
      <Typography color="primary.wlLight">{header}</Typography>
      <Typography mb="10px" color="violet.dark">
        {subheader}
      </Typography>

      <RadioButtonOld
        id="discount50for1"
        label="50% discount for 1 month"
        labelSize="14px"
        value={50}
        checked={discount === 50}
        onChange={changeHandler}
        disabled={[BillingTypeEnum.SEMESTRIAL, BillingTypeEnum.QUARTERLY].includes(
          billingInfo?.billingType as BillingTypeEnum,
        )}
      />
      <RadioButtonOld
        id="discount25for3"
        label="25% discount for 3 months"
        labelSize="14px"
        value={25}
        checked={discount === 25}
        onChange={changeHandler}
        disabled={billingInfo?.billingType === BillingTypeEnum.SEMESTRIAL}
      />
      <RadioButtonOld
        id="discount15for6"
        label="15% discount for 6 months"
        labelSize="14px"
        value={15}
        checked={discount === 15}
        onChange={changeHandler}
      />

      {showError && (
        <Typography mt="10px" mb="5px" color="error.main">
          Please select a discount
        </Typography>
      )}

      <Box display="flex" justifyContent="space-around" mt="20px">
        {showForFree && <Button onClick={navigateToReferFriend}>Get {shortCompanyName} for FREE!</Button>}
        <Button processing={isLoading} onClick={submitDiscount}>
          I'll take the discount
        </Button>
      </Box>
    </Box>
  );
};
