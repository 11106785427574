import { useMutation, UseMutationOptions } from 'react-query';

import { exportUnsubscribedEmails } from 'src/api';
import { ICustomAxiosError, IExportUnsubscribedEmailsRequest } from 'src/types';

export const useExportUnsubscribedEmails = (
  options?: UseMutationOptions<void, ICustomAxiosError, IExportUnsubscribedEmailsRequest>,
) => {
  const { mutate, ...rest } = useMutation(
    'export-unsubscribed-emails',
    (requestData: IExportUnsubscribedEmailsRequest) => exportUnsubscribedEmails(requestData),
    options,
  );

  return { exportEmails: mutate, ...rest };
};
