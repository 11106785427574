import dayjs from 'dayjs';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button, Checkbox, Spinner, Typography } from 'src/components';
import { CampaignType } from 'src/enums';
import { useCompanyTheme } from 'src/hooks';
import { useGetCampaign, useGetCampaignLeadSources, useRestartSearchCampaign } from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';

const Block = styled(Box)`
  background: ${({ theme }) => theme.palette.light.main};
  padding: 15px 10px;
  margin: 5px 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
`;

interface IModalRestartSearchProps {
  campaignId: number;
}

export const ModalRestartSearch = ({ campaignId }: IModalRestartSearchProps) => {
  const dispatch = useDispatch();
  const { shortCompanyName } = useCompanyTheme();
  const [restartCampaign, setRestartCampaign] = useState(false);
  const [leadSourceIds, setLeadSourceIds] = useState<number[]>([]);

  const { campaign, isLoading: isCampaignLoading } = useGetCampaign(campaignId, { refetchOnMount: true });
  const { data: leadSources, isLoading: isLeadSourcesLoading } = useGetCampaignLeadSources(campaignId, {
    refetchOnMount: true,
  });

  const { restartSearch, isLoading } = useRestartSearchCampaign({ campaignId, category: campaign?.category });

  const isAvailableToRestart = ![
    CampaignType.LINKEDIN_CONNECTIONS,
    CampaignType.EMAIL_CONNECTIONS,
    CampaignType.TWITTER_CONNECTIONS,
    CampaignType.LINKEDIN_CSV_UPLOAD,
    CampaignType.TWITTER_CSV_UPLOAD,
    CampaignType.EMAIL_CSV_UPLOAD,
    CampaignType.ZAPIER_LEADS,
  ].includes(campaign?.campaignType as CampaignType);

  const LEAD_SOURCE_NAMES: { [key in CampaignType]?: string } = {
    [CampaignType.LINKEDIN_SEARCH]: 'Linkedin Search',
    [CampaignType.LINKEDIN_CSV_UPLOAD]: 'LinkedIn URLs uploaded via CSV file',
    [CampaignType.EMAIL_CSV_UPLOAD]: 'LinkedIn URLs uploaded via CSV file',
    [CampaignType.TWITTER_CSV_UPLOAD]: 'X (Twitter) URLs uploaded via CSV file',
    [CampaignType.LINKEDIN_CONNECTIONS]: '1st degree connections',
    [CampaignType.EMAIL_CONNECTIONS]: '1st degree connections',
    [CampaignType.LINKEDIN_SN_SEARCH]: 'Sales Navigator search via URL',
    [CampaignType.LINKEDIN_SN_SAVED_SEARCH]: 'Sales Navigator saved search via URL',
    [CampaignType.RECRUITER_SEARCH]: 'Recruiter search via URL',
    [CampaignType.LINKEDIN_GROUP]: 'LinkedIn Group',
    [CampaignType.LINKEDIN_POST]: 'LinkedIn Post',
    [CampaignType.TWITTER_CONNECTIONS]: 'X (Twitter) connections',
    [CampaignType.LINKEDIN_EVENT]: 'Linkedin Event',
    [CampaignType.ZAPIER]: `Send LinkedIn URLs to ${shortCompanyName} through Zapier`,
  };

  const toggleRestartLeadSource = (leadSourceId: number, toAdd: boolean) => {
    if (toAdd) {
      setLeadSourceIds((prevState) => [...prevState, leadSourceId]);
    } else {
      setLeadSourceIds((prevState) => prevState.filter((id) => id !== leadSourceId));
    }
  };

  const resolveTitleLink = (title = 'Lead Source', searchUrl?: string) => {
    if (searchUrl) {
      return (
        <a target="_blank" href={searchUrl} rel="noopener noreferrer">
          {title}
        </a>
      );
    }

    return <span>{title}</span>;
  };

  const close = () => {
    dispatch(closeModal());
  };

  const confirm = () => {
    restartSearch({ restartCampaign, leadSourceIds });
    close();
  };

  return (
    <>
      <Box px="20px" py="30px">
        <Typography mb="30px" color="primary.wlLight" semibold>
          Select what sources should restart
        </Typography>

        {(isCampaignLoading || isLeadSourcesLoading) && (
          <Box textAlign="center">
            <Spinner type="dots" />
          </Box>
        )}

        {!isCampaignLoading && !isLeadSourcesLoading && (
          <>
            <Block>
              <Box flexGrow={1}>
                <Typography fontSize="15px">
                  {resolveTitleLink('Initial Search', campaign?.searchUrl)}{' '}
                  {campaign && isAvailableToRestart && !campaign.isSearchEnded && (
                    <Typography component="span" fontWeight="bold" inline fontSize="15px">
                      (running)
                    </Typography>
                  )}
                </Typography>
                <Typography color="#8a8a8a" fontSize="13px">
                  {isAvailableToRestart
                    ? 'Restart initial search'
                    : 'Initial search of this campaign type cannot be restarted'}
                </Typography>
              </Box>
              <Checkbox
                type="checkbox"
                disabled={!campaign?.isSearchEnded || !isAvailableToRestart}
                checked={restartCampaign}
                onChange={(e) => setRestartCampaign(e.target.checked)}
              />
            </Block>

            {leadSources?.map((leadSource) => (
              <Block key={leadSource.id}>
                <Box flexGrow={1}>
                  <Typography fontSize="15px">
                    {resolveTitleLink(LEAD_SOURCE_NAMES[leadSource.searchType], leadSource.searchUrl)}{' '}
                    {!leadSource.isSearchEnded &&
                      ![
                        CampaignType.LINKEDIN_CONNECTIONS,
                        CampaignType.EMAIL_CONNECTIONS,
                        CampaignType.TWITTER_CONNECTIONS,
                      ].includes(leadSource.searchType) && (
                        <Typography component="span" fontWeight="bold" inline fontSize="15px">
                          (running)
                        </Typography>
                      )}
                  </Typography>
                  <Typography color="#8a8a8a" fontSize="13px">
                    Lead Source created on {dayjs(leadSource.createdAt).format('Do MMMM YYYY')}, at{' '}
                    {dayjs(leadSource.createdAt).format('HH:mm')}
                  </Typography>
                </Box>
                <Checkbox
                  type="checkbox"
                  disabled={
                    !leadSource?.isSearchEnded ||
                    [
                      CampaignType.LINKEDIN_CONNECTIONS,
                      CampaignType.EMAIL_CONNECTIONS,
                      CampaignType.TWITTER_CONNECTIONS,
                    ].includes(leadSource.searchType)
                  }
                  checked={leadSourceIds.includes(leadSource.id)}
                  onChange={(e) => toggleRestartLeadSource(leadSource.id, e.target.checked)}
                />
              </Block>
            ))}
          </>
        )}
      </Box>
      <Box display="flex" justifyContent="space-between" px="20px" py="15px" borderTop="1px solid #eee">
        <Button variant="gray" onClick={close}>
          Cancel
        </Button>
        <Button onClick={confirm} processing={isLoading} disabled={!restartCampaign && !leadSourceIds.length}>
          Confirm
        </Button>
      </Box>
    </>
  );
};
