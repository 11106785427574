import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import { ErrorMessage, Spinner, Typography } from 'src/components';
import { delay } from 'src/helpers';
import { useWhiteLabel } from 'src/hooks';
import {
  useGetMailSettings,
  useGetUsersEmail,
  usePostGmailSignIn,
  usePostMicrosoftOutlookSignIn,
} from 'src/reactQueries';
import { ICustomAxiosError } from 'src/types';
import { IntegrationContainer, Title } from '../ui';
import { GoogleForm } from './GoogleForm';
import { MicrosoftForm } from './MicrosoftForm';
import { OtherProviderForm } from './OtherProviderForm/OtherProviderForm';

export const WithWLEmailIntegration = ({
  redirectTo,
  isFullWidth = false,
}: {
  redirectTo: string;
  isFullWidth?: boolean;
}) => {
  const { whitelabel } = useWhiteLabel();
  const { state } = useLocation() as { state: { code?: string } };
  const [errorMessage, setErrorMessage] = useState('');

  const { userEmail, isLoading, isFetching } = useGetUsersEmail();
  const { googleError } = usePostGmailSignIn();
  const { mailSettings, isMailLoading } = useGetMailSettings();
  const { microsoftError } = usePostMicrosoftOutlookSignIn(state?.code || '');

  const activeProvider = useMemo(() => {
    switch (true) {
      case userEmail?.gmailEnabled:
        return 'google';
      case userEmail?.outlookEnabled:
        return 'microsoft';
      case !!mailSettings?.isConnected:
        return 'smtp';
      default:
        return null;
    }
  }, [userEmail, mailSettings]);

  useEffect(() => {
    const message = (googleError as ICustomAxiosError)?.message || (microsoftError as ICustomAxiosError)?.message;

    if (message) {
      setErrorMessage(message);
      const clearMessage = delay(5000, () => setErrorMessage(''));

      return () => {
        clearTimeout(clearMessage);
      };
    }
  }, [googleError, microsoftError]);

  if (isLoading || isFetching || isMailLoading) {
    return (
      <IntegrationContainer width={isFullWidth ? '100%' : 'auto'}>
        <Spinner size="24px" mb="40px" />
      </IntegrationContainer>
    );
  }

  return (
    <IntegrationContainer width={isFullWidth ? '100%' : 'auto'}>
      <Title>Authorize Email</Title>

      <Typography mt="12px">
        We need your permission to send emails in your name and to read your emails for checking for replies.
      </Typography>
      <Typography>The emails will be send from the email account you authorize next.</Typography>
      <Box display="grid" gap="8px" mt="16px">
        {!whitelabel.isWhiteLabelUser && (!activeProvider || activeProvider === 'google') && <GoogleForm />}
        {!whitelabel.isWhiteLabelUser && (!activeProvider || activeProvider === 'microsoft') && (
          <MicrosoftForm redirectTo={redirectTo} />
        )}
        {(!activeProvider || activeProvider === 'smtp') && <OtherProviderForm />}
      </Box>
      {!!errorMessage && (
        <Box mt="15px">
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Box>
      )}
    </IntegrationContainer>
  );
};
