export enum LeadStatus {
  APPROVED = 'approved',
  MOVED = 'moved',
  IN_OTHER_CAMPAIGN = 'in_other_campaign',
  IN_OTHER_MEMBER_CAMPAIGN = 'in_other_member_campaign',
  PENDING = 'pending',
  SKIPPED = 'skipped',
  NO_PICTURE = 'no_picture',
  NO_PREMIUM = 'no_premium',
  NO_OPEN_PROFILE = 'no_open_profile',
  FIRST_CONNECTION = 'first_connection',
  NO_LI_DATA = 'no_linkedin_data',
  NO_TWITTER_DATA = 'no_twitter_data',
  NO_MESSAGE_OPTION = 'no_message_option',
}
