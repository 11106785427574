import { LeadStatus } from 'src/enums';

export const LEADS_SORTABLE_COLUMNS = [
  { key: 'name', fieldName: 'name' },
  { key: 'company', fieldName: 'currentEmployer' },
  { key: 'title', fieldName: 'currentTitle' },
];

export const IGNORED_LEADS_REASON: { [k in LeadStatus]?: string } = {
  [LeadStatus.IN_OTHER_MEMBER_CAMPAIGN]: 'Active in other member campaign',
  [LeadStatus.NO_MESSAGE_OPTION]: 'Cannot send message',
  [LeadStatus.IN_OTHER_CAMPAIGN]: 'In other campaign',
  [LeadStatus.FIRST_CONNECTION]: 'Is first connection',
  [LeadStatus.NO_OPEN_PROFILE]: 'Is not open profile',
  [LeadStatus.NO_PREMIUM]: 'Is not premium profile',
  [LeadStatus.MOVED]: 'Moved to other campaign',
  [LeadStatus.NO_LI_DATA]: 'Non-existing profile',
  [LeadStatus.NO_TWITTER_DATA]: 'Non-existing X (Twitter) profile',
  [LeadStatus.SKIPPED]: 'Removed from the campaign',
  [LeadStatus.NO_PICTURE]: 'Without profile picture',
};
