import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { Button, Typography } from 'src/components';
import { useDeleteTrend } from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';
import { IModalProps, ITrend } from 'src/types';

interface IModalDeleteTrendProps {
  trend: ITrend;
}

export const ModalDeleteTrend = ({ params }: IModalProps<IModalDeleteTrendProps>) => {
  const dispatch = useDispatch();
  const { trend } = params ?? {};
  const { deleteTrend, isDeleteLoading } = useDeleteTrend(trend?.id, {
    onSuccess: () => {
      dispatch(closeModal());
    },
  });

  const closeHandler = () => dispatch(closeModal());

  const deleteHandler = () => deleteTrend();

  return (
    <Box data-testid="ModalDeleteTrend" pt="30px" pb="20px" px="40px">
      <Typography color="violet.dark">
        Are you sure you want to delete <strong>{trend?.term}</strong>?
      </Typography>
      <Box display="flex" justifyContent="space-around" mt="20px">
        <Button variant="gray" onClick={closeHandler}>
          Cancel
        </Button>
        <Button variant="warning" onClick={deleteHandler} processing={isDeleteLoading}>
          Delete
        </Button>
      </Box>
    </Box>
  );
};
