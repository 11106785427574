import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IModalProps } from 'src/types';

const initialState: IModalProps[] = [];

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<Partial<IModalProps>>) => {
      state.push({ ...action.payload, params: action.payload.params ?? {} });

      if (document?.activeElement instanceof HTMLElement) {
        document?.activeElement?.blur?.();
      }
    },
    closeModal: (state) => {
      state.pop();
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
