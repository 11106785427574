import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Button, Checkbox, GoogleButton, Input, Link, MicrosoftButton, Typography } from 'src/components';
import { REGEXP_VALID_EMAIL } from 'src/constants';
import { renderSignUpCodeError } from 'src/helpers';
import { useCompanyTheme, useLocationState, useWhiteLabel } from 'src/hooks';
import { AuthLayout } from 'src/layouts';
import { useSignUpViaEmail } from 'src/reactQueries';
import { ICustomAxiosError, TSignUpRequest } from 'src/types';
import { AuthError, AuthForm, FormErrorMessage, IconBox, InputColumn, MainHeading } from './_components';

const SignUpPage = styled(Box)`
  width: 100%;
  max-width: 400px;
  margin: auto;
  position: relative;
  padding: 60px 0;
`;

const StyledCheckbox = styled(Checkbox)`
  zoom: 1.3;
  margin-right: 5px;
`;

export const SignUp = ({ withPromoCode }: { withPromoCode?: boolean }) => {
  const { palette } = useTheme();
  const { whitelabel } = useWhiteLabel();
  const { shortCompanyName } = useCompanyTheme();
  const locationState = useLocationState<TSignUpRequest>();
  const { promoCode } = useParams<{ promoCode: string }>() ?? {};

  const [loginError, setError] = useState<number | null>(null);
  const [signUpError, setSignUpError] = useState('');
  // const [industry, setIndustry] = useState('');

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<Record<string, string>>({ defaultValues: { promoCode } });

  const { signUpViaEmail, error, isLoading } = useSignUpViaEmail();

  useEffect(() => {
    setSignUpError((error as ICustomAxiosError)?.message);
  }, [error]);

  const submit = ({ password }: FieldValues) => {
    // const finalIndustry = industry === 'other' ? getValues('customIndustry') : industry;
    //
    // if (!finalIndustry && !locationState.invitedEmail && !whitelabel.isWhiteLabelUser) {
    //   setSignUpError('Please select an industry');
    //
    //   return;
    // }

    signUpViaEmail({
      ...locationState,
      name: getValues('name'),
      email: getValues('email'),
      // industry: finalIndustry,
      // eslint-disable-next-line no-undefined
      promoCode: getValues('promoCode') || undefined,
      password,
    });
  };

  const omRedirectToSignUp = () => {
    setSignUpError('');
    reset();
  };

  const closeErrorAlert = () => setError(null);

  return (
    <AuthLayout isSignUp>
      <SignUpPage>
        {loginError && (
          <AuthError>
            {renderSignUpCodeError(loginError)}
            <IconBox>
              <FontAwesomeIcon icon={faXmark} onClick={closeErrorAlert} />
            </IconBox>
          </AuthError>
        )}
        <MainHeading>Create Account</MainHeading>
        <AuthForm
          onSubmit={(e) => {
            setValue('name', getValues('name').trim());
            setValue('email', getValues('email').trim());
            // if (getValues('customIndustry')) {
            //   setValue('customIndustry', getValues('customIndustry').trim());
            // }

            return handleSubmit(submit)(e);
          }}
        >
          <InputColumn mt="20px">
            <Input
              register={register}
              label="Name"
              name="name"
              parameters={{
                required: 'Please enter a name',
                onBlur: () => {
                  setValue('name', getValues('name').trim());
                },
              }}
              placeholder="Enter your name"
              className={classNames({ error: !!errors.name })}
              data-testid="name-field"
            />
            {errors.name && <FormErrorMessage data-testid="name-error-message">{errors.name.message}</FormErrorMessage>}
          </InputColumn>
          {/*Team industry selection removed*/}
          {/*{whitelabel.isWhiteLabelUser || locationState.invitedEmail || (*/}
          {/*  <>*/}
          {/*    <Typography fontSize="14px" lineHeight="1.3rem" fontWeight="600" marginBottom="5px">*/}
          {/*      What is the primary function of your company?*/}
          {/*    </Typography>*/}
          {/*    <SelectBasic*/}
          {/*      aria-label="industry-select"*/}
          {/*      styles={{*/}
          {/*        container: (styles) => ({*/}
          {/*          ...styles,*/}
          {/*          border: '1px solid #c1c7d3 !important',*/}
          {/*          borderRadius: '4px',*/}
          {/*          marginBottom: '10px',*/}
          {/*          boxShadow: '0 1px 1px 0 #dfdfe8',*/}
          {/*          color: '#ffffff',*/}
          {/*        }),*/}
          {/*        control: (styles) => ({*/}
          {/*          ...styles,*/}
          {/*          border: '0',*/}
          {/*          color: '#ffffff',*/}
          {/*        }),*/}
          {/*        singleValue: (styles) => ({*/}
          {/*          ...styles,*/}
          {/*          opacity: '1',*/}
          {/*          fontSize: '14px',*/}
          {/*        }),*/}
          {/*        placeholder: (styles) => ({*/}
          {/*          ...styles,*/}
          {/*          color: '#999',*/}
          {/*          opacity: '1',*/}
          {/*          fontSize: '14px',*/}
          {/*        }),*/}
          {/*      }}*/}
          {/*      placeholder="What is the primary function of your company?"*/}
          {/*      onChange={(data) => {*/}
          {/*        if (data && 'value' in data) {*/}
          {/*          setIndustry(data.value);*/}
          {/*        }*/}
          {/*      }}*/}
          {/*      options={Object.entries(SELECT_TEAM_INDUSTRY_OPTIONS).map(([value, label]) => ({*/}
          {/*        value,*/}
          {/*        label,*/}
          {/*      }))}*/}
          {/*    />*/}
          {/*  </>*/}
          {/*)}*/}
          {/*{industry === 'other' && (*/}
          {/*  <InputColumn mt="20px">*/}
          {/*    <Input*/}
          {/*      register={register}*/}
          {/*      name="customIndustry"*/}
          {/*      parameters={{*/}
          {/*        required: 'Please enter an industry',*/}
          {/*        onBlur: () => {*/}
          {/*          setValue('customIndustry', getValues('customIndustry').trim());*/}
          {/*        },*/}
          {/*      }}*/}
          {/*      placeholder="Enter your industry"*/}
          {/*      className={classNames({ error: !!errors.name })}*/}
          {/*      data-testid="customIndustry-field"*/}
          {/*    />*/}
          {/*    {errors.customIndustry && (*/}
          {/*      <FormErrorMessage data-testid="industry-error-message">*/}
          {/*        {errors.customIndustry.message}*/}
          {/*      </FormErrorMessage>*/}
          {/*    )}*/}
          {/*  </InputColumn>*/}
          {/*)}*/}
          <InputColumn>
            <Input
              register={register}
              label={`${locationState.invitedEmail ? '' : 'Work '}Email Address`}
              name="email"
              parameters={{
                required: 'Please enter an email',
                minLength: {
                  value: 6,
                  message: 'Please enter an email at least 6 characters long',
                },
                pattern: {
                  value: REGEXP_VALID_EMAIL,
                  message: 'Please enter a valid email',
                },
                onBlur: () => {
                  setValue('email', getValues('email').trim());
                },
              }}
              placeholder={`Enter your ${locationState.invitedEmail ? '' : 'work '}email`}
              className={classNames({ error: !!errors.email })}
              data-testid="email-field"
              defaultValue={locationState.invitedEmail}
            />
            {errors.email && (
              <FormErrorMessage data-testid="email-error-message">{errors.email.message}</FormErrorMessage>
            )}
          </InputColumn>
          <InputColumn>
            <Input
              register={register}
              label="Password"
              name="password"
              parameters={{
                required: 'Please enter a password',
                minLength: {
                  value: 6,
                  message: 'Please enter a password at least 6 characters long',
                },
              }}
              placeholder="Enter your password"
              type="password"
              className={classNames({ error: !!errors.password })}
              data-testid="password-field"
            />
            {errors.password && (
              <FormErrorMessage data-testid="password-error-message">{errors.password.message}</FormErrorMessage>
            )}
          </InputColumn>
          {withPromoCode && (
            <InputColumn>
              <Input
                register={register}
                label="Access Code"
                name="promoCode"
                placeholder="Access Code"
                parameters={{
                  required: true,
                }}
                className={classNames({ error: !!errors.promoCode })}
              />
              {errors.promoCode && (
                <FormErrorMessage data-testid="promoCode-error-message">
                  Please enter a valid access code. If you don't have one, please register{' '}
                  <Link underline to="/auth/signup" onClick={omRedirectToSignUp}>
                    here
                  </Link>
                </FormErrorMessage>
              )}
            </InputColumn>
          )}
          {whitelabel.isWhiteLabelUser || (
            <Box display="flex" justifyContent="center">
              <Box width="70%">
                <Box display="flex" justifyContent="center">
                  <strong>OR</strong>
                </Box>
                <Box mt="20px" textAlign="center">
                  <GoogleButton redirectTo="/registration-completed" isSignup setError={setError} />
                </Box>
                <Box textAlign="center" mt="20px">
                  <MicrosoftButton redirectTo="/auth/signup" isSignup setError={setError} />
                </Box>
              </Box>
            </Box>
          )}
          {whitelabel.isWhiteLabelUser || (
            <Box display="flex" alignItems="center" mt="20px">
              <StyledCheckbox
                register={register}
                name="accepted"
                parameters={{
                  required: `Please accept ${shortCompanyName}'s terms of use and privacy policy`,
                }}
                type="checkbox"
                defaultChecked
                data-testid="accepted-field"
              />
              <Typography color={palette.primary.dark} bold>
                I accept Alfred's{' '}
                <Link color={palette.primary.main} hoverColor={palette.primary.main} to="https://meetalfred.com/terms">
                  Terms of Use
                </Link>{' '}
                and{' '}
                <Link
                  color={palette.primary.main}
                  hoverColor={palette.primary.main}
                  to="https://meetalfred.com/privacy-policy"
                >
                  Privacy Policy
                </Link>
              </Typography>
            </Box>
          )}
          <Box mt="20px">
            <Button
              size={{ width: '100%', height: '42px' }}
              type="submit"
              variant="secondary"
              data-testid="signup-button"
              processing={isLoading}
              disabled={isLoading}
            >
              <Typography semibold color="#fff">
                Start My FREE Trial
              </Typography>
            </Button>
          </Box>
          {errors.accepted && (
            <FormErrorMessage data-testid="accepted-error-message">{errors.accepted.message}</FormErrorMessage>
          )}
          {signUpError && (
            <FormErrorMessage>
              {signUpError === 'Promo code is invalid' ? (
                <>
                  Invalid access code. If you don't have a access code{' '}
                  <Link variant="error" bold underline to="/auth/signup" onClick={omRedirectToSignUp}>
                    click here to signup without a code
                  </Link>
                </>
              ) : (
                signUpError
              )}
            </FormErrorMessage>
          )}
        </AuthForm>
      </SignUpPage>
    </AuthLayout>
  );
};
