import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { Modals } from 'src/components/Modal';
import { Toast } from './components/Toast';
import {
  GoogleProvider,
  HotjarProvider,
  IntercomProvider,
  SentryProvider,
  StripeProvider,
  StyleProvider,
} from './providers';
import { Routes } from './routes';
import { store } from './store';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  },
});

function App() {
  return (
    <SentryProvider>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <StyleProvider>
            <GoogleProvider>
              <Toast />
              <StripeProvider>
                <IntercomProvider>
                  <HotjarProvider>
                    <BrowserRouter>
                      <Modals />
                      <Routes />
                    </BrowserRouter>
                  </HotjarProvider>
                </IntercomProvider>
              </StripeProvider>
            </GoogleProvider>
          </StyleProvider>
        </QueryClientProvider>
      </Provider>
    </SentryProvider>
  );
}

export default App;
