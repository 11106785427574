import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { ModalTypes } from 'src/enums';
import { openModal } from 'src/store/modal.slice';
import {
  IAutocomplete,
  ICampaignAudienceRequest,
  ICampaignConnections,
  ICampaignKeywords,
  ICampaignProfileLanguages,
} from 'src/types';
import { ParametersPreview } from './ParametersPreview';

const MoreBox = styled(Box)`
  padding: 7px 10px;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  border-radius: 5px;
  transition: 0.2s;
  cursor: pointer;
  font-size: 13px;
  color: ${({ theme }) => theme.palette.text.primary};
  width: 195px;
  margin: 0 10px 20px 0;

  & > span:first-of-type {
    margin-right: auto;
  }

  & > span:last-of-type:not(:first-of-type) {
    line-height: 10px;
    font-size: 12px;
    padding: 1px 12px;
    display: inline-block;
    border-radius: 5px;
    background: #eaeff1;
    margin-right: 5px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.light.main};
  }
`;

const StylesFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.gray.main};
`;

interface ISearchParametersProps {
  setValue: UseFormSetValue<ICampaignAudienceRequest>;
  watch: UseFormWatch<ICampaignAudienceRequest>;
  control: Control<ICampaignAudienceRequest>;
}

export const SearchParameters = ({ setValue, watch, control }: ISearchParametersProps) => {
  const dispatch = useDispatch();

  const watchConnections = Object.values(watch('searchParameters.connections')).filter(Boolean);
  const watchIndustries = watch('searchParameters.industries');
  const watchKeywords = Object.values(watch('searchParameters.specifyKeywords')).filter(Boolean);
  const watchCurrentCompanies = watch('searchParameters.currentCompanies');
  const watchPastCompanies = watch('searchParameters.pastCompanies');
  const watchLanguages = Object.values(watch('searchParameters.profileLanguages')).filter(Boolean);
  const watchSchools = watch('searchParameters.schools');
  const watchLocations = watch('searchParameters.locations');

  return (
    <>
      <Typography color="text.primary" fontSize="14px" mb="20px">
        Want to be more precise?
      </Typography>

      <Box display="flex" flexWrap="wrap" mb="30px">
        <MoreBox
          onClick={() =>
            dispatch(
              openModal({
                type: ModalTypes.SELECT_CONNECTION_DEGREE,
                closable: false,
                params: {
                  values: { ...watch('searchParameters.connections') },
                  setValues: (values: ICampaignConnections) => {
                    setValue('searchParameters.connections', values);
                  },
                },
              }),
            )
          }
        >
          <span>Connections</span>
          {!!watchConnections.length && <span>{watchConnections.length}</span>}
          <StylesFontAwesomeIcon icon={faAngleRight} />
        </MoreBox>
        <MoreBox
          onClick={() =>
            dispatch(
              openModal({
                type: ModalTypes.AUTOCOMPLETE_SEARCH,
                closable: false,
                params: {
                  headerText: 'Industries',
                  route: 'industries',
                  values: [...watchIndustries],
                  setValues: (values: IAutocomplete[]) => {
                    setValue('searchParameters.industries', values);
                  },
                },
              }),
            )
          }
        >
          <span>Industries</span>
          {!!watchIndustries.length && <span>{watchIndustries.length}</span>}
          <StylesFontAwesomeIcon icon={faAngleRight} />
        </MoreBox>
        <MoreBox
          onClick={() =>
            dispatch(
              openModal({
                type: ModalTypes.CAMPAIGN_KEYWORDS,
                closable: false,
                params: {
                  values: { ...watch('searchParameters.specifyKeywords') },
                  setValues: (values: ICampaignKeywords) => {
                    setValue('searchParameters.specifyKeywords', values);
                  },
                },
              }),
            )
          }
        >
          <span>Specify keywords</span>
          {!!watchKeywords.length && <span>{watchKeywords.length}</span>}
          <StylesFontAwesomeIcon icon={faAngleRight} />
        </MoreBox>
        <MoreBox
          onClick={() =>
            dispatch(
              openModal({
                type: ModalTypes.AUTOCOMPLETE_SEARCH,
                closable: false,
                params: {
                  headerText: 'Current companies',
                  route: 'companies',
                  values: [...watchCurrentCompanies],
                  setValues: (values: IAutocomplete[]) => {
                    setValue('searchParameters.currentCompanies', values);
                  },
                },
              }),
            )
          }
        >
          <span>Current companies</span>
          {!!watchCurrentCompanies.length && <span>{watchCurrentCompanies.length}</span>}
          <StylesFontAwesomeIcon icon={faAngleRight} />
        </MoreBox>
        <MoreBox
          onClick={() =>
            dispatch(
              openModal({
                type: ModalTypes.AUTOCOMPLETE_SEARCH,
                closable: false,
                params: {
                  headerText: 'Past companies',
                  route: 'companies',
                  values: [...watchPastCompanies],
                  setValues: (values: IAutocomplete[]) => {
                    setValue('searchParameters.pastCompanies', values);
                  },
                },
              }),
            )
          }
        >
          <span>Past companies</span>
          {!!watchPastCompanies.length && <span>{watchPastCompanies.length}</span>}
          <StylesFontAwesomeIcon icon={faAngleRight} />
        </MoreBox>
        <MoreBox
          onClick={() =>
            dispatch(
              openModal({
                type: ModalTypes.SELECT_PROFILE_LANGUAGE,
                closable: false,
                params: {
                  values: { ...watch('searchParameters.profileLanguages') },
                  setValues: (values: ICampaignProfileLanguages) => {
                    setValue('searchParameters.profileLanguages', values);
                  },
                },
              }),
            )
          }
        >
          <span>Profile languages</span>
          {!!watchLanguages.length && <span>{watchLanguages.length}</span>}
          <StylesFontAwesomeIcon icon={faAngleRight} />
        </MoreBox>
        <MoreBox
          onClick={() =>
            dispatch(
              openModal({
                type: ModalTypes.AUTOCOMPLETE_SEARCH,
                closable: false,
                params: {
                  headerText: 'Schools',
                  route: 'schools',
                  values: [...watchSchools],
                  setValues: (values: (IAutocomplete & { location: string })[]) => {
                    setValue('searchParameters.schools', values);
                  },
                },
              }),
            )
          }
        >
          <span>Schools</span>
          {!!watchSchools.length && <span>{watchSchools.length}</span>}
          <StylesFontAwesomeIcon icon={faAngleRight} />
        </MoreBox>
        <MoreBox
          onClick={() =>
            dispatch(
              openModal({
                type: ModalTypes.AUTOCOMPLETE_SEARCH,
                closable: false,
                params: {
                  headerText: 'Locations',
                  route: 'locations',
                  values: [...watchLocations],
                  setValues: (values: IAutocomplete[]) => {
                    setValue('searchParameters.locations', values);
                  },
                },
              }),
            )
          }
        >
          <span>Locations</span>
          {!!watchLocations.length && <span>{watchLocations.length}</span>}
          <StylesFontAwesomeIcon icon={faAngleRight} />
        </MoreBox>
      </Box>

      <ParametersPreview control={control} watch={watch} setValue={setValue} />
    </>
  );
};
