import { Navigate, useSearchParams } from 'react-router-dom';

export const MicrosoftCallback = () => {
  const [searchParams] = useSearchParams();

  const code = searchParams.get('code');
  const navigateTo = searchParams.get('state');

  if (!code || !navigateTo) {
    return <Navigate to="/auth/login" />;
  }

  return <Navigate to={navigateTo} state={{ code }} />;
};
