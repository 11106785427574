import { sortBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { components, MultiValue, MultiValueRemoveProps, SingleValue } from 'react-select';
import Creatable from 'react-select/creatable';
import { Box, useTheme } from '@mui/material';

import { ReactComponent as XMarkIcon } from 'src/assets/icons/x-mark.svg';
import { selectTagStyles, Tooltip, Typography, WithTagCircle } from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features, ModalTypes } from 'src/enums';
import { getRandomHexColor } from 'src/helpers';
import { useTeamPlan } from 'src/hooks';
import { useCreateSetLeadTag, useGetAllTags, useSetLeadTags } from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';
import { ITag } from 'src/types';

const convertTagToOption = (tagItem: ITag) => ({
  label: tagItem.tag,
  value: tagItem.id,
  ...tagItem,
});

interface ILeadTagsProp {
  tags: ITag[];
  entityUrn: string;
}

export const LeadTags = ({ tags, entityUrn }: ILeadTagsProp) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const [tagsAttached, setTagsAttached] = useState<ITag[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const creatableRef = useRef<any>(null);

  const { tags: tagsAvailable } = useGetAllTags();
  const { setLeadTags } = useSetLeadTags({ entityUrn });
  const { createAddLeadTag } = useCreateSetLeadTag(entityUrn);

  const { checkFeature } = useTeamPlan();

  useEffect(() => {
    setTagsAttached(sortBy(tags, 'tag'));
  }, [tags]);

  const onChange = (nextTags: MultiValue<ITag> | SingleValue<ITag>) => {
    if (!Array.isArray(nextTags) || (!nextTags.length && !tagsAttached.length)) {
      return null;
    }

    if (nextTags.length > tagsAttached.length) {
      const tagCandidates = nextTags.reduce((accTags, currTagItem) => {
        let tagCandidate = currTagItem;

        if (currTagItem.id !== -1) {
          tagCandidate = tagsAvailable?.find((tagAvailable) => tagAvailable.id === currTagItem.id);
        }

        if (tagCandidate) {
          return [...accTags, tagCandidate];
        }

        return accTags;
      }, [] as ITag[]);

      return setLeadTags(tagCandidates);
    }

    creatableRef?.current?.blur();

    return dispatch(
      openModal({
        headerText: 'Delete Tag',
        type: ModalTypes.DELETE_LEAD_TAG,
        onConfirm: () => setLeadTags(nextTags),
      }),
    );
  };

  const onCreate = (tag: string) => {
    createAddLeadTag({ tag, color: getRandomHexColor() });
  };

  const MultiValueRemove = (props: MultiValueRemoveProps<ITag>) => {
    const onConfirm = () => {
      const tagCandidates = tagsAttached.filter((tagItem) => tagItem.id !== props.data.id);
      setLeadTags(tagCandidates);
    };

    props.innerProps.onClick = (event) => {
      event.preventDefault();
      if (tagsAttached.some(({ id }) => id === -1)) {
        return;
      }
      creatableRef?.current?.blur();

      dispatch(
        openModal({
          headerText: 'Delete Tag',
          type: ModalTypes.DELETE_LEAD_TAG,
          onConfirm,
        }),
      );
    };

    return (
      <components.MultiValueRemove data-testid="remove" {...props}>
        <XMarkIcon color={props.data.color} />
      </components.MultiValueRemove>
    );
  };

  return (
    <Box data-testid="tags">
      <Typography color={palette.gray[500]} fontSize="15px" lineHeight="16px" mb="12px">
        Account Tags
      </Typography>
      <Tooltip title={!checkFeature(Features.leadsTags) && PLAN_TOOLTIPS.any}>
        <span>
          <Creatable
            ref={creatableRef}
            placeholder={`Select Tags`}
            isMulti
            isClearable={false}
            noOptionsMessage={() => 'Not results found'}
            options={tagsAvailable?.map(convertTagToOption)}
            value={tagsAttached.map(convertTagToOption)}
            onChange={onChange}
            onCreateOption={onCreate}
            formatCreateLabel={(inputText) => {
              return <WithTagCircle>Create option "{inputText}"</WithTagCircle>;
            }}
            components={{
              MultiValueRemove,
            }}
            styles={selectTagStyles}
            isDisabled={!checkFeature(Features.leadsTags)}
          />
        </span>
      </Tooltip>
    </Box>
  );
};
