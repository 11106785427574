import { Link, Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import unavailableInbox from 'src/assets/img/unavailable-inbox.jpg';
import { Typography } from 'src/components';
import { Features } from 'src/enums';
import { useAppSelector, useTeamPlan } from 'src/hooks';
import { AppLayout } from 'src/layouts';
import { useGetRealUser, useGetUserAccount, useGetUserPreferences } from 'src/reactQueries';
import { NotSynced } from './_components';
import { ConversationLayout } from './ConversationLayout';

const InboxDisabledContainer = styled(Box)`
  font-size: 20px;
  color: ${({ theme }) => theme.palette.primary.wlLight};
  border-radius: 5px;
`;

const UnavailableContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${unavailableInbox});
  margin: 15px;
  background-repeat: no-repeat;
  background-size: cover;
  height: calc(100% - 135px);
`;

export const Conversations = () => {
  const { userMe } = useGetUserAccount();
  const { userPreferences } = useGetUserPreferences();

  const { isImpersonate } = useAppSelector((state) => state.user);
  const { realImpersonateUser } = useGetRealUser();

  const { checkFeature } = useTeamPlan();

  const showUserInbox =
    (isImpersonate &&
      (userPreferences?.enableMembersInbox || userMe?.isGhostUser || realImpersonateUser?.isAlfredAdmin)) ||
    !isImpersonate;

  const renderBody = () => {
    if (!checkFeature(Features.inbox)) {
      return (
        <UnavailableContainer>
          <InboxDisabledContainer boxShadow="0 5px 15px rgb(0 0 0 / 50%)" bgcolor="#fff" p="120px 100px">
            <Typography fontSize="18px">
              <Link to="/billing/edit">Upgrade</Link> your subscription plan in order to get access to this feature
            </Typography>
          </InboxDisabledContainer>
        </UnavailableContainer>
      );
    }

    if (showUserInbox) {
      return userMe?.isLinkedInLoginValid ? <Outlet /> : <NotSynced />;
    }

    return (
      <InboxDisabledContainer display="flex" justifyContent="center" p="200px 10px 10px">
        For privacy reasons you are only allowed to view your own messages inbox.
      </InboxDisabledContainer>
    );
  };

  return (
    <AppLayout>
      <ConversationLayout />
      {renderBody()}
    </AppLayout>
  );
};
