import { v4 as uuidv4 } from 'uuid';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NotificationTypes } from 'src/enums';
import { TAddNotification, TNotification } from 'src/types';

interface IInitialState {
  notifications: TNotification[];
}

const NOT_UNIQUE_NOTIFICATION: NotificationTypes[] = [NotificationTypes.ERROR, NotificationTypes.WARN];
// we don't need to save notifications to cookies anymore
// as we are defining if to show them every time app is loaded
const initialState: IInitialState = {
  notifications: [],
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<TAddNotification>) => {
      const newNotification = { ...action.payload, id: uuidv4() };

      if (!NOT_UNIQUE_NOTIFICATION.includes(newNotification.type)) {
        if (state.notifications.some((notification) => notification.type === newNotification.type)) {
          return;
        }
      }

      state.notifications.unshift(newNotification);
    },
    removeNotification: (state, action: PayloadAction<{ notificationId: string }>) => {
      const { notificationId } = action.payload;
      state.notifications = state.notifications.filter(({ id }) => id !== notificationId);
    },
    removeAllNotifications: (state) => {
      state.notifications = [];
    },
    removeNotificationByType: (state, action: PayloadAction<{ type: NotificationTypes }>) => {
      const { type } = action.payload;
      state.notifications = state.notifications.filter((notification) => notification.type !== type);
    },
  },
});

export const { addNotification, removeNotification, removeAllNotifications, removeNotificationByType } =
  notificationSlice.actions;

export default notificationSlice.reducer;
