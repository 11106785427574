import styled from '@emotion/styled';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { Tooltip } from 'src/components/ui/Tooltip';
import { IInstagramPage, IPostAttachment, TSchedulePost } from 'src/types';
import {
  AttachmentImage,
  AttachmentVideo,
  PostPreviewText,
  PreviewContainer,
  PreviewSocialIcon,
  SocialAvatar,
  SocialAvatarContainer,
  UserDataContainer,
  UserName,
} from '../../_components';

const InstagramIcon = styled(PreviewSocialIcon)`
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
  color: white;
  border-radius: 7px;
`;

const InstagramPostedAt = styled.div`
  margin-top: 10px;
  color: #8e8e8e;
  font-size: 10px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
`;

export const Instagram = ({
  postData,
  instagramProfile,
}: {
  postData: TSchedulePost;
  instagramProfile: IInstagramPage | null;
}) => {
  if (!postData?.attachments?.length) {
    return (
      <PreviewContainer>
        <InstagramIcon icon={faInstagram} />
        <Tooltip title="Please attach image/video for preview." placement="top" arrow>
          <Typography fontSize="16px">Preview unavailable</Typography>
        </Tooltip>
      </PreviewContainer>
    );
  }

  if (postData.attachments[0].wrong_aspect_ratio) {
    return (
      <PreviewContainer>
        <InstagramIcon icon={faInstagram} />
        <Tooltip title="Please upload another image/video for preview." placement="top" arrow>
          <Typography fontSize="16px">Incorrect aspect ratio, please upload another image/video</Typography>
        </Tooltip>
      </PreviewContainer>
    );
  }

  return (
    <PreviewContainer>
      <InstagramIcon icon={faInstagram} />
      <Box display="flex" alignItems="center" mb="20px">
        <SocialAvatarContainer>
          <SocialAvatar src={instagramProfile?.profile_pic} />
        </SocialAvatarContainer>
        <UserDataContainer ml="10px">
          <UserName>{instagramProfile?.username}</UserName>
        </UserDataContainer>
      </Box>
      <Box>
        {postData.attachments?.map((attachment: IPostAttachment) => {
          const fileType = attachment.file_type;

          if (fileType.includes('image')) {
            return (
              <AttachmentImage
                data-testid="image-attachment"
                src={attachment.file_uri}
                key={`attachment-${attachment.file_uri}`}
              />
            );
          }

          if (fileType.includes('video')) {
            return (
              <AttachmentVideo
                data-testid="video-attachment"
                key={`attachment-${attachment.file_uri}`}
                src={attachment.file_uri}
                controls
              ></AttachmentVideo>
            );
          }

          return null;
        })}

        <Box display="flex" justifyContent="flex-start" alignItems="baseline" mt="10px">
          <UserName>{instagramProfile?.username}</UserName>
          <PostPreviewText content={postData.content} type="instagram" />
        </Box>

        <InstagramPostedAt>Few seconds ago</InstagramPostedAt>
      </Box>
    </PreviewContainer>
  );
};
