import { useMutation, UseMutationOptions } from 'react-query';

import { exportLeads } from 'src/api';
import { convertLeadsFilterToRequest } from 'src/helpers';
import { useAppSelector } from 'src/hooks';
import { ICustomAxiosError } from 'src/types';

export const useExportLeadsCsv = (
  isBlackList: boolean,
  options?: UseMutationOptions<void, ICustomAxiosError, string>,
) => {
  const {
    request,
    exportData: { selectedLeads },
  } = useAppSelector((state) => (isBlackList ? state.blacklistLeads : state.lead));

  const entityUrns = selectedLeads.map(({ entityUrn }) => entityUrn);

  const { mutate, ...rest } = useMutation(
    'get-leads-for-csv',
    (email: string) => exportLeads(email, entityUrns, convertLeadsFilterToRequest({ ...request, selectedOnly: false })),
    options,
  );

  return { exportLeadsCsv: mutate, ...rest };
};
