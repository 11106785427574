import { Spinner, Typography } from 'src/components';
import { CurrencyEnum } from 'src/enums';
import { getAmountString, getCurrencySymbol } from 'src/helpers';

interface PlanPriceProps {
  price?: number;
  currency?: CurrencyEnum;
  size?: string;
  isLoading?: boolean;
}

export const PlanPrice = ({ price, currency, size = '18px', isLoading }: PlanPriceProps) => {
  if (isLoading) {
    return <Spinner type="dots" width="22px" height={size} />;
  }

  return (
    <>
      <Typography component="span" fontSize="15px" color="gray.400" inline mr="2px">
        {getCurrencySymbol(currency)}
      </Typography>
      <Typography component="span" fontSize={size} color="gray.800" semibold inline>
        {getAmountString(price, { minimumFractionDigits: 0 })}
      </Typography>
      <Typography component="span" fontSize="15px" color="gray.800" inline ml="2px">
        /mo
      </Typography>
    </>
  );
};
