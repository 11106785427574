import { CSSProperties } from 'react';
import { Box } from '@mui/material';

import { Conversation } from 'src/containers/Conversations/_components/Conversation';
import { LIConversationFilterType, LIConversationType } from 'src/enums';
import { ILIConversation } from 'src/types';

interface ILeadMessagesProp {
  conversationId: string;
  entityUrn: string;
  imageUrl: string;
  name: string;
}

const containerStyle: CSSProperties = {
  backgroundColor: '#ffffff',
  paddingBottom: 0,
  marginBottom: '80px',
  maxHeight: '500px',
};

const sendMessagesStyle: CSSProperties = {
  padding: '10px 0 5px 0',
};

export const LeadMessages = ({ entityUrn, conversationId, name, imageUrl }: ILeadMessagesProp) => {
  const conversationData: ILIConversation = {
    content: '',
    conversationId: conversationId || entityUrn,
    entityUrn,
    headline: '',
    imageUrl,
    isArchived: false,
    isConnected: true,
    lastActivityAt: 0,
    name,
    type: LIConversationType.MEMBER_TO_MEMBER,
    unreadCount: 0,
  };

  return (
    <Box data-testid="messages">
      <Conversation
        isLeadMessages
        conversationData={conversationData}
        filterType={LIConversationFilterType.ALL}
        containerStyle={containerStyle}
        sendMessagesStyle={sendMessagesStyle}
      />
    </Box>
  );
};
