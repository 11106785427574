import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import styled from '@emotion/styled';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Radio, RadioGroup } from '@mui/material';

import { Button, Tooltip } from 'src/components';
import { useGetUserLinkedInGroups, useRefreshUserLinkedinGroups } from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';
import { IModalProps, IUserLinkedInGroup } from 'src/types';
import { AUDIENCE_LABELS } from '../../../constants';

const Container = styled(Box)`
  padding: 20px;
  font-size: 14px;
`;

const ModalLayout = styled(Box)`
  margin-bottom: 20px;
`;

const Title = styled.h4`
  font-family: 'ProximaSoft', sans-serif;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primary.wlLight};
  line-height: 30px;
`;

const StyledRadioGroup = styled(RadioGroup)`
  display: block;
`;

const AudienceGroupBox = styled(Box)`
  background: ${({ theme }) => theme.palette.light.main};
  padding: 8px 10px;
  margin: 5px 0;
  border-radius: 5px;
  font-size: 18px;
`;

const AudienceGroupTitle = styled.div`
  font-size: 15px;
  margin-bottom: 5px;
`;

const AudienceGroupSubTitle = styled.div`
  font-size: 13px;
  color: #8a8a8a;
`;

const ComingSoonBadge = styled.span`
  font-weight: 600;
  line-height: 20px;
  vertical-align: middle;
  background: red;
  color: white;
  border-radius: 5px;
  margin-left: 5px;
  padding: 3px;
  font-size: 12px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.primary.wlLight};
  cursor: pointer;
  flex-shrink: 0;
`;

export const ModalPostAudience = ({ onConfirm, params }: IModalProps) => {
  const dispatch = useDispatch();

  const { groups } = useGetUserLinkedInGroups();
  const { refreshLIGroups, isLoading: isRefreshing } = useRefreshUserLinkedinGroups();

  const defaultAudience = (params?.audience as string) || 'anyone';
  const [audience, setAudience] = useState({
    value: defaultAudience,
    label: AUDIENCE_LABELS[defaultAudience],
  });
  const [selectedGroup, setSelectedGroup] = useState<IUserLinkedInGroup | null>(params?.selectedGroup || null);

  return (
    <Container>
      <ModalLayout>
        <Title>Audience</Title>
        <div>Who can see your post?</div>
      </ModalLayout>
      <Box>
        <StyledRadioGroup
          name="audience"
          defaultValue={params?.audience || 'anyone'}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;

            setAudience({
              label: AUDIENCE_LABELS[value],
              value,
            });
          }}
        >
          <AudienceGroupBox display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <AudienceGroupTitle>Anyone</AudienceGroupTitle>
              <AudienceGroupSubTitle>Anyone on or off LinkedIn</AudienceGroupSubTitle>
            </Box>
            <Radio value="anyone" />
          </AudienceGroupBox>
          {params?.postAs === 'You' ? (
            <>
              <AudienceGroupBox display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <AudienceGroupTitle>Connections only</AudienceGroupTitle>
                  <AudienceGroupSubTitle>Connection on LinkedIn</AudienceGroupSubTitle>
                </Box>
                <Radio value="connections_only" />
              </AudienceGroupBox>
              <AudienceGroupBox display="flex" flexDirection="column">
                <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                  <Box>
                    <AudienceGroupTitle>Group members</AudienceGroupTitle>
                    <AudienceGroupSubTitle>Only visible to members of selected group</AudienceGroupSubTitle>
                  </Box>
                  <Radio value="group" />
                </Box>
                {audience.value === 'group' && (
                  <Box display="flex" alignItems="center" mt="10px" data-testid="group-select">
                    <Select
                      styles={{ container: (base) => ({ ...base, flexGrow: 1 }) }}
                      defaultValue={{
                        value: params?.selectedGroup?.savedSearchId,
                        label: params?.selectedGroup?.name,
                      }}
                      options={groups?.map((group) => ({
                        label: group.name,
                        value: group.savedSearchId,
                      }))}
                      // eslint-disable-next-line
                      onChange={(selectedOption: any) => {
                        const group = groups?.find((userGroup) => userGroup.savedSearchId === selectedOption.value);

                        setSelectedGroup(group || null);
                      }}
                    />
                    <Tooltip title="Refresh Linkedin Groups">
                      <IconWrapper onClick={() => refreshLIGroups()}>
                        <FontAwesomeIcon icon={faSync} spin={isRefreshing} />
                      </IconWrapper>
                    </Tooltip>
                  </Box>
                )}
              </AudienceGroupBox>
            </>
          ) : (
            <>
              <AudienceGroupBox display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <AudienceGroupTitle>
                    Targeted Audience <ComingSoonBadge>Coming soon</ComingSoonBadge>
                  </AudienceGroupTitle>
                  <AudienceGroupSubTitle>
                    Choose target audience (using Industry / Location / Job function / Language / Seniority)
                  </AudienceGroupSubTitle>
                </Box>
                <Radio disabled />
              </AudienceGroupBox>
            </>
          )}
        </StyledRadioGroup>
      </Box>
      <Box py={2} px={4} display="flex" justifyContent="flex-end" id="here">
        <Button
          variant="gray"
          onClick={() => {
            if (typeof onConfirm === 'function') {
              onConfirm({ audience, selectedGroup });
            }

            dispatch(closeModal());
          }}
        >
          Close
        </Button>
      </Box>
    </Container>
  );
};
