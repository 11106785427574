export enum LeadActivityEnum {
  VIEWED = 'viewed',
  ALREADY_CONNECTED = 'already connected',
  CONNECTED = 'connected',
  COULD_NOT_FOLLOW = 'could not follow-up',
  FOLLOWED_AFTER_CONNECT = 'followed up after connect',
  FOUND = 'found',
  INMAIL_MESSAGE_SENT = 'InMail Message sent',
  INMAIL_SKIPPED = 'InMail skipped',
  LINKEDIN_REPLY_DETECTED = 'linkedin reply detected',
  MESSAGE_SENT = 'Message sent',
  REQUESTED_CONNECT = 'requested connect',
  VERIFIED_UPLOADED_LEAD = 'verified uploaded lead',
  EMAILED = 'emailed',
  EMAIL_REPLY_DETECTED = 'Email reply detected',
  EMAILED_POSTPONED = 'emailed postponed',
  CONNECT_REQUEST_POSTPONED = 'Connect request postponed',
  ENDORSED_PROFILE = 'Endorsed a profile',
  USER_INTERACTION_DETECTED = 'user interaction detected',
  FOLLOWED_PROFILE = 'Followed a profile',
  INVITE_WITHDRAWN = 'invite withdrawn',
  NO_EMAIL_FOUND = 'no email found',
  EMAIL_OPENED = 'Email opened',
  COULDNT_SEND_MAIL = `Couldn't send mail`,
  COULD_NOT_RUN_ACTION = 'Could not run action',
  JOB_CHANGE = 'JOB_CHANGE',
  EMAIL_BOUNCED = 'Email bounced',
  UNABLE_SEND_MESSAGE = 'Unable to send message',
  WORK_ANNIVERSARY = 'WORK_ANNIVERSARY',
  COULD_NOT_CONNECT_USER = 'Could not connect user',
  BIRTHDAY = 'BIRTHDAY',
  RETURNED_TO_CAMPAIGN = 'Returned to campaign',
  GROUP_MESSAGE_SENT = 'Group Message sent',
  EMAIL_SKIPPED = 'Email skipped',
  ENGAGED_PROFILE = 'engaged profile',
  TWITTER_MESSAGE_SENT = 'Twitter Message sent',
  TWITTER_REPLY_DETECTED = 'twitter reply detected',
  UNABLE_TO_FOLLOW_ON_TWITTER = 'Unable to follow on Twitter',
  UNABLE_TO_SEND_DIRECT_MESSAGE = 'Unable to send Direct message',
  INMAIL_REPLY_DETECTED = 'InMail reply detected',
  LEAD_FOLLOWED_IN_TWITTER = 'Lead Followed in Twitter',
  EVENT_MESSAGE_SENT = 'Event Message sent',
  ALREADY_INVITED = 'already invited',
  MOVED_TO_CAMPAIGN = 'moved to campaign',
  MESSAGE_ALREADY_SENT = 'Message already sent',
  USER_UNSUBSCRIBED_FROM_EMAIL = 'user unsubscribed from email',
  SKILL_ENDORSEMENT = 'SKILL_ENDORSEMENT',
  LEAD_POSTPONED = 'Lead postponed',
  MESSAGE_POSTPONED = 'Message postponed',
  LIKED_POST = 'Liked a post',
  TWITTER_HANDLE_SYNCED = 'twitter_handle synced',
  UNABLE_TO_SEND_INMAIL = 'Unable to send InMail message',
}
