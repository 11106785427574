import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useFieldArray, UseFieldArrayReturn, useForm, UseFormReset, UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { AvailabilitySelector, Input, SelectBasic, Typography } from 'src/components';
import { CAMPAIGN_CATEGORIES } from 'src/constants';
import { Sequence } from 'src/containers/SingleCampaign';
import { CampaignCategory, TemplateAvailability } from 'src/enums';
import { useLocationState, useSequenceForm } from 'src/hooks';
import { useCreateSequenceTemplate, useGetSequenceTemplateById, useUpdateSequenceTemplate } from 'src/reactQueries';
import { openModal } from 'src/store';
import { IColorSelectOption, ICreateSequenceTemplate, ISequence } from 'src/types';
import { SequenceTemplateFormLayout } from './SequenceTemplateFormLayout';

const CATEGORY_OPTIONS = CAMPAIGN_CATEGORIES.map(({ category, name }) => ({ value: category, label: name }));

interface SequenceTemplateFormProps {
  isEdit?: boolean;
}

const StyledInput = styled(Input)`
  color: #333;
  height: 30px;
  line-height: 19px;
  padding: 10px;
`;

export const SequenceTemplateForm = ({ isEdit = false }: SequenceTemplateFormProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const state = useLocationState<ICreateSequenceTemplate>() || {};

  const { createSequenceTemplate, isLoading: isLoadingCreate } = useCreateSequenceTemplate({
    onSuccess: (data, variables) => {
      navigate(`/sequence-templates/personal?category=${variables.category}`);
    },
  });
  const { updateSequenceTemplate, isLoading: isLoadingUpdate } = useUpdateSequenceTemplate(Number(id));

  const form = useForm<ICreateSequenceTemplate>({
    mode: 'all',
    defaultValues: {
      name: state.name || '',
      category: state.category || CampaignCategory.LINKEDIN,
      isAvailableForTeam: state.isAvailableForTeam || false,
      sequence: state.sequence || [],
    },
  });
  const { register, handleSubmit, setValue, watch, reset, control, formState } = form;

  const fieldArrayForm = useFieldArray({
    control,
    name: 'sequence',
  });

  const name = watch('name');
  const category = watch('category');
  const sequence = watch('sequence');
  const isAvailableForTeam = watch('isAvailableForTeam');

  useGetSequenceTemplateById(Number(id), {
    enabled: !isNaN(Number(id)) && !state.name,
    onSuccess: ({ name, sequence, category, isAvailableForTeam }) => {
      reset({ name, sequence, category, isAvailableForTeam });
    },
  });

  const onSubmit = (values: ICreateSequenceTemplate) => {
    if (isEdit) {
      return updateSequenceTemplate(values);
    }

    createSequenceTemplate(values);
  };

  const { submit, removeNotAvailableSteps } = useSequenceForm<ICreateSequenceTemplate>({
    category,
    onSubmit,
    sequence,
    reset: reset as unknown as UseFormReset<ISequence>,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onCategoryChange = (value: SingleValue<IColorSelectOption>) => {
    if (value) {
      const category = value.value as CampaignCategory;

      if (sequence.length) {
        return dispatch(
          openModal({
            headerText: 'Change Sequence template category',
            descriptionText: `Changing the category will remove all sequence steps that are not available for ${value.label}. Are you sure you want to proceed?`,
            onConfirm: () => {
              const savedIsAvailableForTeam = isAvailableForTeam;

              removeNotAvailableSteps(category);

              setValue('category', category);
              setValue('isAvailableForTeam', savedIsAvailableForTeam);
            },
            confirmButtonLabel: 'Yes, proceed',
          }),
        );
      }
      setValue('category', category);
    }
  };

  const onAvailabilityChange = (availability: TemplateAvailability) => {
    setValue('isAvailableForTeam', availability === TemplateAvailability.team);
  };

  const onClone = () => {
    navigate('/sequence-templates/new', { replace: true });
    setValue('name', `${name} (clone)`);
  };

  return (
    <>
      <SequenceTemplateFormLayout
        onSave={handleSubmit(submit)}
        onClone={onClone}
        isLoading={isLoadingCreate || isLoadingUpdate}
        isEdit={isEdit}
        sequenceTemplateId={Number(id)}
        isSaveDisabled={!sequence?.length || !isEmpty(formState.errors)}
        saveButtonTooltip={!sequence?.length ? 'Please add a step in the sequence to continue' : ''}
      />
      <Box padding="40px 15px" marginBottom="20px" width="900px">
        <StyledInput
          label="Template Name"
          register={register}
          parameters={{ required: 'Please input a name for this template', validate: (value) => !!value.trim() }}
          name="name"
          labelStyle={css`
            font-weight: 400 !important;
          `}
        />
        <Box display="flex" mt="25px">
          <Box display="flex" flexDirection="row" alignItems="center" width="50%">
            <Typography fontSize="14px" lineHeight="1.3rem">
              Category:
            </Typography>
            <SelectBasic
              styles={{ container: (styles) => ({ ...styles, flex: 1, marginLeft: '10px' }) }}
              value={CATEGORY_OPTIONS.find((option) => option.value === category)}
              placeholder="Select Category"
              options={CATEGORY_OPTIONS}
              onChange={(category) => onCategoryChange(category as SingleValue<IColorSelectOption>)}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <Typography fontSize="14px" lineHeight="1.3rem" m="0px 10px 0px 30px">
              Availability:
            </Typography>
            <AvailabilitySelector isShared={isAvailableForTeam} onChange={onAvailabilityChange} />
          </Box>
        </Box>
      </Box>
      <Sequence
        category={category}
        sequenceForm={form as unknown as UseFormReturn<ISequence>}
        fieldArrayForm={fieldArrayForm as unknown as UseFieldArrayReturn<ISequence, 'sequence'>}
      />
    </>
  );
};
