import { useEffect, useRef, useState } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Input, SelectSnippet, Typography } from 'src/components';
import { separateNumWithComma } from 'src/helpers';
import { ICreateTemplateForm } from './TemplateCreator';

const StyledInput = styled(Input)`
  color: #333;
  height: 33px;
  line-height: 19px;
  padding: 10px;
  margin-top: 0;
  margin-bottom: 5px;
`;

const AttachmentBox = styled(Box)`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.light.light};
  cursor: pointer;
  border-radius: 4px 4px 0 0;
  margin-left: 5px;
  font-size: 14px;

  &:hover {
    color: #fff;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

interface IInputWithSnippetProps {
  maxLength?: number;
  label: string;
  name: keyof ICreateTemplateForm;
  register: UseFormRegister<ICreateTemplateForm>;
  value: string;
  setValue: UseFormSetValue<ICreateTemplateForm>;
}

export const InputWithSnippet = ({ name, register, maxLength, label, value, setValue }: IInputWithSnippetProps) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [touched, setTouched] = useState(false);
  const [buffValue, setBuffValue] = useState(value);
  const caretPosition = useRef(value.length);

  const numLetterShown = !!maxLength;
  const numLettersLeft = (maxLength ?? 0) - (touched ? value.length : 0);

  const onOpenSelect = () => setIsSelectOpen(true);
  const onCloseSelect = () => setIsSelectOpen(false);

  const onSnippetClick = (selectValue: string) => {
    const snippet = `{{${selectValue}}}`;
    const position = caretPosition.current;
    const leftValueSide = value.slice(0, position) + snippet;
    const newValue = leftValueSide + value.slice(position);

    caretPosition.current = leftValueSide.length;
    setValue(name, newValue);
  };

  useEffect(() => {
    if (value !== buffValue) {
      setTouched(true);
    }
    setBuffValue(value);
  }, [value]);

  return (
    <>
      <Typography marginBottom="5px" semibold>
        {label}
      </Typography>

      <Box position="relative">
        <StyledInput
          maxLength={maxLength}
          name={name}
          register={register}
          parameters={{
            onBlur: (e) => {
              caretPosition.current = e.target.selectionStart;
            },
          }}
        />

        <Box position="absolute" top="-30px" right="0">
          <AttachmentBox onClick={onOpenSelect} padding="0 15px">
            Personalize
          </AttachmentBox>

          <SelectSnippet
            isSelectOpen={isSelectOpen}
            onCloseSelect={onCloseSelect}
            onOpenSelect={onOpenSelect}
            onSnippetClick={onSnippetClick}
          />
        </Box>
      </Box>

      {numLetterShown && (
        <Typography data-testid="char-count" textAlign="right">
          {separateNumWithComma(numLettersLeft)}
        </Typography>
      )}
    </>
  );
};
