import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { faPencilAlt, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ActionButton } from 'src/components';
import { Input } from 'src/components/TextField';
import { Tooltip } from 'src/components/ui/Tooltip';
import { useUpdateCampaignName } from 'src/reactQueries';

const StyledInput = styled(Input)`
  width: 100%;
`;

const SupText = styled.p`
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 16px;
`;

const CampaignTitle = styled.h2`
  margin-top: 20px;
  font-size: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

interface INameInput {
  name: string;
  campaignId: number;
  isArchived: boolean;
}

export const NameInput = ({ name, campaignId, isArchived }: INameInput) => {
  const [isEdit, setIsEdit] = useState(false);
  const [campaignName, setCampaignName] = useState(name ?? '');

  useEffect(() => {
    if (!campaignName) {
      setCampaignName(name);
    }
  }, [name]);

  const { updateCampaignName, isLoading } = useUpdateCampaignName(campaignId);

  const toggleEdit = () => setIsEdit((prev) => !prev);

  const cancelEditName = () => {
    setCampaignName(name);
    toggleEdit();
  };

  const editName = () => {
    updateCampaignName({ name: campaignName });
    toggleEdit();
  };

  if (isEdit) {
    return (
      <>
        <StyledInput value={campaignName} onChange={(e) => setCampaignName(e.target.value)} name="company-name" />
        <ActionButton mx="8px" processing={isLoading} onClick={editName}>
          <FontAwesomeIcon icon={faSave} fontSize="14px" />
        </ActionButton>
        <ActionButton onClick={cancelEditName}>
          <FontAwesomeIcon icon={faTimes} fontSize="14px" />
        </ActionButton>
      </>
    );
  }

  return (
    <>
      <Tooltip title={campaignName} placement="right">
        <CampaignTitle>{campaignName}</CampaignTitle>
      </Tooltip>
      {isArchived && <SupText>(Archived)</SupText>}
      {!isArchived && (
        <ActionButton ml="8px" mb="6px" onClick={toggleEdit}>
          <FontAwesomeIcon icon={faPencilAlt} fontSize="14px" />
        </ActionButton>
      )}
    </>
  );
};
