import classNames from 'classnames';
import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { TableCell, TablePagination, Tooltip, Typography } from 'src/components';
import { ITablePagination, TCSVFileTypes } from 'src/types';
import { CSVTableHeadCell } from './CSVTableHeadCell';

const StyledTableWrapper = styled.div`
  margin-bottom: 40px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    opacity: 1;
    display: block;
    height: 9px;
    background: transparent;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.palette.primary.main};
    border-radius: 4px;
    border: 4px solid transparent;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.light.light};
  border-collapse: separate;
  & th {
    padding: 10px 20px 10px 0;
    white-space: pre;
    color: ${({ theme }) => theme.palette.primary.wlLight};
    font-family: 'ProximaSoft', sans-serif;
    font-weight: 600;
    font-size: 14px;
    text-align: left;
    text-transform: uppercase;
    border-bottom: 1px solid ${({ theme }) => theme.palette.lightGray.light};
  }
  & td {
    min-width: 100px;
    max-width: 200px;
    overflow: hidden;
    padding: 20px 20px 20px 0;
    text-overflow: ellipsis;
    white-space: pre;
    font-size: 14px;
    color: #333;
    border-bottom: 1px solid ${({ theme }) => theme.palette.lightGray.light};
    line-height: 14px;
    &.shadowed {
      margin: 0;
      padding: 20px 20px 20px 0;
      box-shadow: 5px 0 6px -4px rgb(32 33 36 / 28%);
      min-width: 180px;
      max-width: 180px;
      position: sticky;
    }
  }
  & .sticky-cell {
    left: 0;
    min-width: 180px;
    max-width: 180px;
    z-index: 1;
    background: ${({ theme }) => theme.palette.light.light};
  }
`;

interface ICSVTableProps {
  header: Record<string, string>;
  leads: Record<string, string>[];
  stickyColumns: number;
  status?: string;
  focusedField?: string;
  type?: TCSVFileTypes;

  onUpdateHeader: (header: Record<string, string>) => void;
}

export const CSVTable = ({
  header,
  leads,
  type,
  stickyColumns,
  focusedField,
  status,
  onUpdateHeader,
}: ICSVTableProps) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [isScroll, setIsScroll] = useState(false);
  const changeHandler = (value: Record<string, string>) => {
    onUpdateHeader({ ...header, ...value });
  };

  const total = leads.length;
  const visibleLeads = useMemo(() => leads.slice(page * perPage - perPage, page * perPage), [perPage, page]);

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  const onScrollChange = (event?: React.UIEvent<HTMLDivElement>) =>
    setIsScroll(!!(event?.currentTarget?.scrollLeft ?? 0));

  return (
    <div>
      <StyledTableWrapper onScroll={onScrollChange}>
        <StyledTable>
          <thead>
            <tr>
              {Object.keys(header).map((key, index) =>
                index >= stickyColumns ? (
                  <th key={`head-cell-${key}`}>
                    <CSVTableHeadCell
                      name={key}
                      focusedField={focusedField}
                      value={header[key]}
                      onChange={changeHandler}
                    />
                  </th>
                ) : (
                  <th key={`head-cell-${key}`} className="sticky-cell" style={{ left: `${200 * index}px` }}>
                    {header[key]}
                  </th>
                ),
              )}
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {visibleLeads?.map((lead: Record<string, string>, rowIndex: number) => (
              <tr key={`row-${rowIndex}`}>
                {Object.keys(lead).map((key, colIndex) => {
                  if (colIndex < stickyColumns) {
                    return (
                      <TableCell
                        key={`cell-${colIndex}-${key}`}
                        className={classNames('sticky-cell', isScroll ? 'shadowed' : '')}
                        style={{ left: `${200 * colIndex}px` }}
                        tooltip={type === 'linkedin' && lead[key]}
                        tooltipPlacement="right"
                      >
                        {lead[key]}
                      </TableCell>
                    );
                  }

                  return (
                    <td key={`cell-${colIndex}-${key}`}>
                      <Tooltip title={lead[key]} placement="left">
                        <span>{lead[key]}</span>
                      </Tooltip>
                    </td>
                  );
                })}
                <td>Pending upload by user</td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </StyledTableWrapper>

      <Box display="flex" justifyContent="space-between">
        {status ? <Typography fontSize="14px">{status}</Typography> : <div />}
        <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={paginationChangeHandler} />
      </Box>
    </div>
  );
};
