export enum Features {
  referAndSave,
  whiteLabel,
  exportTeamStats,
  teamStats,
  impersonate,
  manageTeam,
  teamMembers,
  teamGroups,
  teamGroup,
  teamSettings,
  zappierAndWebhook,
  integrations,
  SMTPIntegration,
  schedulePosts,
  trendCreation,
  templateCreation,
  templatesTeamAndLibrary,
  templatesAttachments,
  templatesInSequenceStep,
  inbox,
  inmailInbox,
  salesNavigatorInbox,
  inboxAttachments,
  inboxTemplate,
  leadsTags,
  connectionNote,
  connection,
  connections,
  leads,
  exportCSV,
  addMoreLeads,
  exportReplies,
  directReply,
  returnToCampaign,
  greetings,
  repliesBulkAction,
  groupMessageCampaignStep,
  eventMessageCampaignStep,
  inMailCampaignStep,
  attachmentInSequenceStep,
  attachmentsUploadLimit,
  CSVSnippets,
  twitterAndEmailCSVCampaign,
  twitterAndEmail1stDegreeCampaign,
  linkedinSNUrlCampaign,
  sequenceTemplates,
}

export enum PlanVersions {
  v1 = 'v1',
  v2 = 'v2',
  v3 = 'v3',
}
