import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { NotificationTypes, TrialStatus } from 'src/enums';
import { useGetBillingInfo, useGetTeamById } from 'src/reactQueries';
import { addNotification, removeAllNotifications, removeNotificationByType } from 'src/store/notification.slice';
import { setIsAppDeactivated, setIsAppPaused } from 'src/store/user.slice';
import { useAppSelector } from './useReduxHooks';

export const useCheckDeactivateApp = () => {
  const dispatch = useDispatch();

  const lastTeamId = useAppSelector(({ user }) => user.profile?.lastTeamId);

  const { isLoading: isTeamLoading, teamData } = useGetTeamById({
    teamId: lastTeamId,
    enabled: !!lastTeamId,
  });

  const { isLoading: isBillingLoading } = useGetBillingInfo();

  useEffect(() => {
    dispatch(removeNotificationByType({ type: NotificationTypes.DEACTIVATED }));
    if (teamData) {
      if (!teamData.hasPaidPlan && teamData.trial === TrialStatus.ENDED) {
        dispatch(setIsAppDeactivated(true));
        dispatch(removeAllNotifications());
        dispatch(addNotification({ type: NotificationTypes.DEACTIVATED }));
      } else if (teamData.isSubscriptionPaused) {
        dispatch(setIsAppPaused(true));
      }
    }
  }, [teamData]);

  return { isLoading: isTeamLoading || isBillingLoading };
};
