import {
  IAcceptInvitationToTeamRequest,
  IAcceptInvitationToTeamResponse,
  IDeleteTeamInviteRequest,
  IDeleteTeamMemberRequest,
  IExportTeamStatsRequest,
  IGetInvitationToTeamRequest,
  IGetInvitationToTeamResponse,
  IGetMemberCampaignStatsRequest,
  IGetMemberCampaignStatsResponse,
  IGetTeamByIdRequest,
  IGetTeamInvitesRequest,
  IGetTeamMembersRequest,
  IGetTeamMembersResponse,
  IGetTeamStatsRequest,
  IGetTeamStatsResponse,
  IImpersonateTeamMember,
  IInviteUserWithEmailRequest,
  IInviteUserWithEmailResponse,
  IInviteUserWithoutEmailRequest,
  IInviteUserWithoutEmailResponse,
  IRejectInvitationToTeamRequest,
  IToggleIntegrationNotification,
  IUpdateTeamMemberRequest,
  IUpdateTeamRequest,
  TGetTeamByIdResponse,
  TGetTeamInvitesResponse,
  TUpdateTeamMemberResponse,
} from 'src/types';
import { appApi } from './axios';

export const getTeamById = ({ teamId }: IGetTeamByIdRequest): Promise<TGetTeamByIdResponse> =>
  appApi.get(`/teams/${teamId}`);

export const getAllTeamMembers = (): Promise<IImpersonateTeamMember[]> => appApi.get(`/teams/members`);

export const getTeamMembers = ({ teamId, ...params }: IGetTeamMembersRequest): Promise<IGetTeamMembersResponse> =>
  appApi.get(`/teams/${teamId}/members`, {
    params,
  });

export const getTeamInvites = ({ teamId }: IGetTeamInvitesRequest): Promise<TGetTeamInvitesResponse> =>
  appApi.get(`/teams/${teamId}/invites`);

export const inviteUserWithEmail = (params: IInviteUserWithEmailRequest): Promise<IInviteUserWithEmailResponse> =>
  appApi.post(`/teams/${params.teamId}/invites`, params);

export const inviteUserWithoutEmail = (
  params: IInviteUserWithoutEmailRequest,
): Promise<IInviteUserWithoutEmailResponse> => appApi.post(`/teams/${params.teamId}/invites/ghost`, params);

export const deleteTeamMember = ({ teamId, memberId }: IDeleteTeamMemberRequest): Promise<void> =>
  appApi.delete(`/teams/${teamId}/members/${memberId}`);

export const deleteTeamInvite = ({ inviteId }: IDeleteTeamInviteRequest): Promise<void> =>
  appApi.delete(`/teams/invites/${inviteId}/remove`);

export const updateTeam = ({ teamId, ...params }: IUpdateTeamRequest): Promise<void> =>
  appApi.patch(`/teams/${teamId}`, params);

export const updateTeamMember = ({
  teamId,
  memberId,
  ...params
}: IUpdateTeamMemberRequest): Promise<TUpdateTeamMemberResponse> =>
  appApi.patch(`/teams/${teamId}/members/${memberId}`, params);

export const getTeamInvitationByKey = ({
  inviteKey,
}: IGetInvitationToTeamRequest): Promise<IGetInvitationToTeamResponse> => appApi.get(`/teams/invites/${inviteKey}`);

export const rejectTeamInvitation = ({ inviteId }: IRejectInvitationToTeamRequest): Promise<void> =>
  appApi.post(`/teams/invites/${inviteId}/reject`);

export const acceptTeamInvitation = ({
  inviteId,
  moveCampaigns,
  moveMessagesTemplates,
  moveSequenceTemplates,
}: IAcceptInvitationToTeamRequest): Promise<IAcceptInvitationToTeamResponse> =>
  appApi.post(`/teams/invites/${inviteId}/accept`, { moveCampaigns, moveMessagesTemplates, moveSequenceTemplates });

export const toggleMemberInbox = (): Promise<void> => appApi.patch(`/teams/toggle-enable-member-inbox`);

export const toggleMemberLeads = (): Promise<void> => appApi.patch(`/teams/toggle-share-member-leads`);

export const toggleIntegrationNotification = (body: IToggleIntegrationNotification): Promise<void> =>
  appApi.patch(`/teams/send-disconnected-email`, body);

export const getTeamStats = (params: IGetTeamStatsRequest): Promise<IGetTeamStatsResponse> =>
  appApi.get(`/teams/stats`, { params });

export const getMemberCampaignsStats = (
  memberId: number,
  params: IGetMemberCampaignStatsRequest,
): Promise<IGetMemberCampaignStatsResponse> => appApi.get(`/teams/stats/${memberId}`, { params });

export const leaveTeam = (): Promise<void> => appApi.post(`/teams/leave`);

export const getLastInvitation = (): Promise<IGetInvitationToTeamResponse> => appApi.get(`/teams/invites/first`);

export const markInvitationAsClicked = (inviteKey: string): Promise<void> =>
  appApi.post(`/teams/invites/${inviteKey}/clicked`);

export const exportTeamStats = (params: IExportTeamStatsRequest): Promise<void> =>
  appApi.post(`/teams/stats/csv`, params);

export const removeTeamUserEmail = (memberId: number): Promise<void> =>
  appApi.post(`/teams/members/${memberId}/move-to-ghost`);
