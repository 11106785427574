import { UseFormRegister } from 'react-hook-form';
import { Box } from '@mui/material';

import LinkedInIcon from 'src/assets/icons/linkedin-icon.svg';
import { Button, Input, MessageBlock, Typography } from 'src/components';
import { useCompanyTheme } from 'src/hooks';
import { ILinkedInFormFieldValues } from 'src/types';
import { Accordion, AccordionDetails, AccordionSummary, GridContainer, GridItem, Loader } from '../ui';
import { LinkedInAccount } from './LinkedInAccount';

interface IVerifyCodeProps {
  register: UseFormRegister<ILinkedInFormFieldValues>;
  tryVerify: () => void;
  tryDelete: () => void;
  isLoadingVerifyCode: boolean;
  isLoadingDeleteCredentials: boolean;
  verificationCodeError?: string;
}

export const VerifyCode = ({
  isLoadingVerifyCode,
  isLoadingDeleteCredentials,
  register,
  tryVerify,
  tryDelete,
  verificationCodeError,
}: IVerifyCodeProps) => {
  const { shortCompanyName } = useCompanyTheme();

  const isLoading = isLoadingVerifyCode || isLoadingDeleteCredentials;

  return (
    <>
      <Accordion expanded>
        <AccordionSummary tabIndex={-1}>
          <Box display="flex" alignItems="center" gap="12px">
            <img width="20px" src={LinkedInIcon} alt="LinkedIn" />
            <Typography semibold fontSize="20px">
              Connect LinkedIn
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <GridContainer container rowSpacing="20px" mb="20px">
            <GridItem item xs={12} sm={6}>
              <LinkedInAccount tryDelete={tryDelete} isLoading={isLoading} />
            </GridItem>
            <GridItem item xs={-1} sm={6} />
            <GridItem item xs={8} sm={4}>
              <Typography semibold fontSize="18px">
                Security Code
              </Typography>
              <Typography color="gray.500" fontSize="15px" mt="5px" pr="40px">
                Please insert the 6 digit code verification code. LinkedIn sent to your registered email or phone number
                to complete the process.
              </Typography>
            </GridItem>
            <GridItem item xs={4} sm={2}>
              <Input
                label="Verification Code"
                type="text"
                placeholder="Verification Code"
                data-testid="verification-code-field"
                register={register}
                name="verificationCode"
                parameters={{
                  required: true,
                  minLength: { value: 6, message: 'Please make sure you enter a 6 digit code.' },
                  maxLength: { value: 6, message: 'Please make sure you enter a 6 digit code.' },
                }}
              />
            </GridItem>
            <GridItem item xs={-1} sm={6} />
          </GridContainer>

          {verificationCodeError ? (
            <MessageBlock type="error" message={verificationCodeError} />
          ) : (
            <MessageBlock message="Haven’t received verification code yet? Please wait a few more minutes and/or double check your spam folder." />
          )}
        </AccordionDetails>
        <AccordionSummary tabIndex={-1}>
          <Box display="flex" alignItems="center" gap="20px" width="100%">
            <Button type="submit" data-testid="button" disabled={isLoading} onClick={tryVerify}>
              Verify code
            </Button>

            {isLoading && (
              <Box flexGrow="1">
                <Typography fontSize="15px" color="gray.600">
                  {isLoadingVerifyCode &&
                    `Almost there! We’re connecting your account for a seamless experience. This may take a couple of minutes.`}
                  {isLoadingDeleteCredentials &&
                    `${shortCompanyName} is removing your LinkedIn account. May take a few minutes.`}
                </Typography>
                <Loader />
              </Box>
            )}
          </Box>
        </AccordionSummary>
      </Accordion>
    </>
  );
};
