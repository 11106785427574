import Cookies from 'universal-cookie';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { COOKIE_TOKEN_NAME } from 'src/constants';
import { removeCookie } from 'src/helpers';
import { decodeJWT } from 'src/helpers/common/tokens';
import { IRealUser, IUser } from 'src/types';
import { removeTeam } from './team.slice';

interface IInitialState {
  isAuth: boolean;
  isImpersonate: boolean;
  isAppDeactivated: boolean;
  isAppPaused: boolean;
  realImpersonateUser: IRealUser;
  profile: IUser;
}

const cookies = new Cookies();

const authToken = cookies.get(COOKIE_TOKEN_NAME);
const userStringFromLocalStorage = localStorage.getItem('user');

const { id, realUserId } = decodeJWT(authToken);

const initialState: IInitialState = {
  isAuth: !!authToken,
  isImpersonate: !!realUserId && realUserId !== id,
  isAppDeactivated: false,
  isAppPaused: false,
  realImpersonateUser: {} as IRealUser,
  profile: (userStringFromLocalStorage && JSON.parse(userStringFromLocalStorage)) || {},
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.profile = action.payload;
      state.isAuth = true;
      localStorage.setItem('user', JSON.stringify(action.payload));
    },
    setUserSelectedContract: (state, action) => {
      state.profile.selectedContractId = action.payload;
    },
    removeUser: (state) => {
      state.profile = {} as IUser;
      state.isAppDeactivated = false;
      state.isAppPaused = false;
      state.isAuth = false;
      removeCookie(COOKIE_TOKEN_NAME);
      localStorage.removeItem('user');
    },
    removeTeamId: (state) => {
      state.profile.lastTeamId = null;
      localStorage.setItem('user', JSON.stringify(state.profile));
    },
    setIsAppDeactivated: (state, action: PayloadAction<boolean>) => {
      state.isAppDeactivated = action.payload;
    },
    setIsAppPaused: (state, action: PayloadAction<boolean>) => {
      state.isAppPaused = action.payload;
    },
    setIsEmailVerified: (state, action: PayloadAction<boolean>) => {
      state.profile.isEmailVerified = action.payload;
    },
    setRealImpersonateUser: (state, action: PayloadAction<IRealUser>) => {
      state.realImpersonateUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(removeTeam, (state) => {
      if (state.profile.lastTeamId) {
        state.profile.lastTeamId = null;
        localStorage.setItem('user', JSON.stringify(state.profile));
      }
    });
  },
});

export const {
  setUser,
  setUserSelectedContract,
  setIsEmailVerified,
  removeUser,
  setIsAppDeactivated,
  setIsAppPaused,
  removeTeamId,
  setRealImpersonateUser,
} = userSlice.actions;

export default userSlice.reducer;
