import { isEmpty } from 'lodash';
import { useMutation, UseMutationOptions } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { duplicateCampaign, getAllLeadsOfCampaign } from 'src/api';
import { CampaignType } from 'src/enums';
import { setConnectionSelectedLeads } from 'src/store/connections.slice';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IGetCampaign } from 'src/types';

export const useDuplicateCampaign = (
  campaignId: number,
  options?: UseMutationOptions<IGetCampaign, ICustomAxiosError>,
) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation(['delete-campaign', campaignId], () => duplicateCampaign(campaignId), {
    ...options,
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      const { id, campaignType, searchParameters, isLinkedinOnly, category } = data;

      const redirectToAudience = (type: string) => {
        navigate(`/campaign/${id}/audience`, {
          state: {
            campaignPageType: type,
            initialCampaignId: campaignId,
            category,
            isAddMoreLeads: false,
          },
        });
      };

      const redirectToConnections = async () => {
        const resp = await getAllLeadsOfCampaign(campaignId);

        dispatch(setConnectionSelectedLeads(resp?.entityUrns?.map((entityUrn) => ({ entityUrn })) || []));

        navigate('/contacts/connections', {
          state: {
            category,
            campaignType,
            campaignIdToAddLeads: id,
            isLinkedinOnly,
            isAddMoreLeads: false,
          },
        });
      };

      switch (campaignType) {
        case CampaignType.LINKEDIN_SEARCH:
          return isEmpty(searchParameters) ? redirectToAudience('url') : redirectToAudience('search');
        case CampaignType.LINKEDIN_POST:
          return redirectToAudience('post');
        case CampaignType.LINKEDIN_EVENT:
          return redirectToAudience('event');
        case CampaignType.LINKEDIN_SN_SAVED_SEARCH:
          return redirectToAudience('sales-navigator');
        case CampaignType.LINKEDIN_CSV_UPLOAD:
        case CampaignType.EMAIL_CSV_UPLOAD:
        case CampaignType.TWITTER_CSV_UPLOAD:
          return redirectToAudience('csv-upload');
        case CampaignType.LINKEDIN_CONNECTIONS:
        case CampaignType.EMAIL_CONNECTIONS:
        case CampaignType.TWITTER_CONNECTIONS:
          return redirectToConnections();
        case CampaignType.ZAPIER_LEADS:
          return redirectToAudience('zapier');
        case CampaignType.LINKEDIN_SN_SEARCH:
          return redirectToAudience('url');
        case CampaignType.LINKEDIN_GROUP:
          return redirectToAudience('group');
        default:
          return null;
      }
    },
  });

  return { duplicateCampaign: mutate, ...rest };
};
