import styled from '@emotion/styled';

export const Message = styled.p`
  font-weight: 600;
  font-size: 0.9rem;
  margin-top: 20px;
  padding: 0;
  line-height: 1.3rem;
`;

export const ErrorMessage = styled(Message)`
  color: ${({ theme }) => theme.palette.error.light};
`;

export const SuccessMessage = styled(Message)`
  color: #00a650;
`;
