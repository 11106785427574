import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { AvailabilitySelector, Button, Input, Typography } from 'src/components';
import { CampaignCategory, TemplateAvailability } from 'src/enums';
import { useCreateSequenceTemplate } from 'src/reactQueries';
import { closeModal } from 'src/store';
import { ICreateSequenceTemplate, IModalProps, ISequenceStep } from 'src/types';
import { ModalBody, ModalFooter } from '../_components';

interface ModalSaveAsSequenceTemplateProps {
  sequence: ISequenceStep[];
  category: CampaignCategory;
}

export const ModalSaveAsSequenceTemplate = ({
  params: { sequence, category },
}: IModalProps<ModalSaveAsSequenceTemplateProps>) => {
  const dispatch = useDispatch();

  const { createSequenceTemplate, isLoading } = useCreateSequenceTemplate({
    onSuccess: () => {
      dispatch(closeModal());
    },
  });

  const { register, handleSubmit, setValue, watch } = useForm<ICreateSequenceTemplate>({
    defaultValues: {
      name: '',
      category,
      isAvailableForTeam: false,
      sequence,
    },
  });

  const isAvailableForTeam = watch('isAvailableForTeam');

  const onAvailabilityChange = (availability: TemplateAvailability) => {
    setValue('isAvailableForTeam', availability === TemplateAvailability.team);
  };

  const onCancel = () => {
    dispatch(closeModal());
  };

  const onSaveButtonClick = (values: ICreateSequenceTemplate) => {
    createSequenceTemplate(values);
  };

  return (
    <>
      <ModalBody>
        <Input
          label={
            <>
              Template Name{' '}
              <Typography inline color="error.light">
                *
              </Typography>
            </>
          }
          register={register}
          parameters={{ required: 'Please input a name for this template' }}
          name="name"
          width="320px"
        />
        <Typography fontSize="14px" semibold mb="5px" mt="16px" lineHeight="1.3rem">
          Availability{' '}
          <Typography inline color="error.light">
            *
          </Typography>
        </Typography>
        <AvailabilitySelector isShared={isAvailableForTeam} onChange={onAvailabilityChange} />
      </ModalBody>

      <ModalFooter>
        <Button variant="gray" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSaveButtonClick)} processing={isLoading}>
          Save
        </Button>
      </ModalFooter>
    </>
  );
};
