import { ISelectOption, TIntervalFilter } from 'src/types';

export const TIME_INTERVAL_OPTIONS: ISelectOption<TIntervalFilter>[] = [
  { value: 'all', label: 'All Time' },
  { value: 'last28days', label: 'Last 4 weeks' },
  { value: 'last7days', label: 'Last 7 days' },
  { value: 'today', label: 'Today' },
];

export const ACTIVITY_INTERVAL_OPTIONS: ISelectOption<TIntervalFilter>[] = [
  { value: 'all', label: 'All Time' },
  { value: 'last28days', label: 'Last 4 weeks' },
  { value: 'last7days', label: 'Last 7 days' },
  { value: 'yesterday', label: 'Yesterday' },
  { value: 'today', label: 'Today' },
  { value: 'custom', label: 'Custom' },
];
