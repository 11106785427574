import dayjs from 'dayjs';
import { useState } from 'react';
import { useTheme } from '@emotion/react';

import {
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
  Tag,
  Typography,
} from 'src/components';
import { InvoiceStatus } from 'src/enums';
import { getCurrencySymbol } from 'src/helpers';
import { useGetBillingInvoices } from 'src/reactQueries';
import { IBillingInvoice, IGetBillingInvoicesRequest, ITablePagination } from 'src/types';

const DEFAULT_PAGE = 1;

interface BillingHistoryProps {
  onSuccess?: (invoices: IBillingInvoice[], page: number) => void;
}

export const BillingHistory = ({ onSuccess }: BillingHistoryProps) => {
  const { palette } = useTheme();

  const [prevPage, setPrevPage] = useState(DEFAULT_PAGE);
  const [params, setParams] = useState<IGetBillingInvoicesRequest>({});

  const { data, isLoading } = useGetBillingInvoices(params, {
    onSuccess: ({ invoices = [] }) => {
      onSuccess?.(invoices, prevPage);
    },
    refetchOnMount: true,
  });

  const { invoices = [], hasMore = false } = data || {};

  const onPaginationChange = ({ page }: ITablePagination) => {
    const isNextPage = prevPage < page;

    const invoice = isNextPage ? [...invoices].pop() : [...invoices].shift();

    if (invoice) {
      setParams(isNextPage ? { startingAfter: invoice.id } : { endingBefore: invoice.id });
    }

    setPrevPage(page);
  };

  const renderStatus = (status: InvoiceStatus) => {
    switch (status) {
      case InvoiceStatus.PAID:
        return (
          <Tag width="110px" bgColor={`${palette.success[200]}26`} borderColor={`${palette.success[200]}e6`}>
            <Typography color={`${palette.success[600]}`} lineHeight="15px">
              PAID
            </Typography>
          </Tag>
        );
      case InvoiceStatus.OPEN:
        return (
          <Tag width="110px" bgColor={palette.blueLight[50]} borderColor={palette.blueLight[200]}>
            <Typography color={palette.blueLight[700]} lineHeight="15px">
              OPEN
            </Typography>
          </Tag>
        );
      case InvoiceStatus.UNCOLLECTIBLE:
        return (
          <Tag width="110px" bgColor={palette.error[100]} borderColor={palette.error[200]}>
            <Typography color={palette.error[600]} lineHeight="15px">
              FAILED
            </Typography>
          </Tag>
        );
      default:
        return (
          <Tag width="110px" bgColor={`${palette.gray[50]}80`} borderColor="#0000001a">
            <Typography color="gray.600" lineHeight="15px">
              {String(status).toUpperCase()}
            </Typography>
          </Tag>
        );
    }
  };

  return (
    <>
      <Table processing={isLoading}>
        <TableHead>
          <TableRow>
            <TableHeadCell>DATE</TableHeadCell>
            <TableHeadCell>AMOUNT</TableHeadCell>
            <TableHeadCell>STATUS</TableHeadCell>
            <TableHeadCell>INVOICE ID</TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody placeholder={!invoices && 'No billings yet'}>
          {invoices?.map(({ created, currency, hosted_invoice_url, amount_due, status, number }) => (
            <TableRow key={created}>
              <TableCell>{dayjs.unix(created).format('MMM DD, YYYY')}</TableCell>

              <TableCell>
                <>
                  {getCurrencySymbol(currency)}
                  {amount_due / 100}
                </>
              </TableCell>

              <TableCell>{renderStatus(status)}</TableCell>

              <TableCell>
                <Link variant="lightBlue" external openNewTab to={hosted_invoice_url}>
                  {number}
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        page={prevPage}
        total={-1}
        onPaginationChange={onPaginationChange}
        hideRowsPerPage
        hideTotal
        nextIconButtonProps={{ disabled: isLoading || !hasMore }}
        backIconButtonProps={{ disabled: isLoading || prevPage <= DEFAULT_PAGE }}
      />
    </>
  );
};
