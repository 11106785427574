import { isEqual } from 'lodash';
import { memo } from 'react';
import { Box } from '@mui/material';

import { Checkbox, Table, TableBody, TableHead, TableHeadCell, TableRow } from 'src/components';
import { resolveNoLeadsPlaceholder } from 'src/helpers';
import { useCompanyTheme } from 'src/hooks';
import { ICampaignLead, IGetCampaign, IGetLeadsRequest, ISelectProps, ITableSorting, TLeadsSortType } from 'src/types';
import { TwitterLeadsTableRow } from './TwitterLeadsTableRow';

interface IEmailLeadsTableProps {
  leads: ICampaignLead[];
  campaign: IGetCampaign;
  getLeadKeys: IGetLeadsRequest;
  isLoading: boolean;
  sortingProps: ITableSorting;
  selectProps: ISelectProps;
}

export const TwitterLeadsTable = memo(
  ({ campaign, selectProps, getLeadKeys, leads, isLoading, sortingProps }: IEmailLeadsTableProps) => {
    const { shortCompanyName } = useCompanyTheme();
    const includeLeadType = (types: TLeadsSortType[]) => types?.includes(getLeadKeys?.type);

    const showMessages = includeLeadType([
      'twitterMessageSent',
      'replies',
      'allReplies',
      'inmailReplies',
      'messaged',
      'eventMessages',
      'groupMessageSent',
      'inmailed',
      'emailed',
      'emailReplies',
      'emailsBounced',
    ]);
    const repliesFilter = includeLeadType([
      'replies',
      'allReplies',
      'inmailReplies',
      'userInteraction',
      'emailReplies',
    ]);
    const showDate = includeLeadType(['followedTwitter']);
    const isAllFilter = includeLeadType(['approved']);
    const showActions = isAllFilter || repliesFilter;

    return (
      <Table processing={isLoading} disabled={isLoading}>
        <TableHead>
          <TableRow>
            <TableHeadCell width="40px">
              <Checkbox checked={selectProps.isSelectedAll} onChange={() => selectProps.toggleAllLeads()} />
            </TableHeadCell>
            <TableHeadCell name="name" sorting={sortingProps} width="25%">
              <Box ml="50px">Lead</Box>
            </TableHeadCell>
            <TableHeadCell>X (Twitter) Handle</TableHeadCell>
            {showMessages && <TableHeadCell>Message</TableHeadCell>}
            {showDate && <TableHeadCell>Date</TableHeadCell>}
            <TableHeadCell name="touchIndex" sorting={sortingProps} width="150px">
              Steps
            </TableHeadCell>
            {showActions && <TableHeadCell width="150px" />}
          </TableRow>
        </TableHead>
        <TableBody
          placeholder={
            !leads?.length && !isLoading && resolveNoLeadsPlaceholder(campaign?.campaignType, shortCompanyName)
          }
        >
          {leads?.map((lead, index) => (
            <TwitterLeadsTableRow
              key={index}
              lead={lead}
              campaign={campaign}
              getLeadKeys={getLeadKeys}
              selectProps={selectProps}
              config={{ isAllFilter, repliesFilter, showActions, showDate, showMessages }}
            />
          ))}
        </TableBody>
      </Table>
    );
  },
  isEqual,
);
