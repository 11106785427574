import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Typography } from 'src/components';

export const MainHeading = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  color: ${({ theme }) => theme.palette.primary.dark};
  text-align: center;
  margin-bottom: 2rem;
`;

export const InputColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

export const FormErrorMessage = styled(Typography)`
  margin-top: 10px;
  font-weight: 700;
  line-height: 1.3rem;
  color: ${({ theme }) => theme.palette.error.light};
`;

export const AuthForm = styled.form`
  max-width: 400px;
  width: 100%;
  margin: auto;
`;

export const IconBox = styled(Box)`
  display: flex;
  align-items: center;
  float: right;
  cursor: pointer;
  opacity: 0.2;
  margin-left: 15px;

  &:hover {
    opacity: 0.5;
  }
`;

export const AuthError = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  display: flex;
  align-items: center;
  background-color: #f2dede;
  color: #a94442;
  padding: 15px;
  border: 1px solid #ebccd1;
  border-radius: 4px;

  & a {
    color: ${({ theme }) => theme.palette.info.dark};

    &:hover {
      color: ${({ theme }) => theme.palette.info.main};
    }
  }
`;
