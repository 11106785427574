import { useQuery, UseQueryOptions } from 'react-query';

import { getTeamMembers } from 'src/api';
import { useAppSelector } from 'src/hooks';
import { ICustomAxiosError, IGetTeamMembersRequest, IGetTeamMembersResponse } from 'src/types';

export const useGetTeamMembers = (
  params?: Omit<IGetTeamMembersRequest, 'teamId'> & { teamId?: number },
  options?: UseQueryOptions<IGetTeamMembersResponse, ICustomAxiosError>,
) => {
  const { id } = useAppSelector(({ team }) => team.data) || {};

  const TEAM_ID = params?.teamId || id || 0;

  const { data, ...rest } = useQuery<IGetTeamMembersResponse, ICustomAxiosError>(
    ['get-team-members', JSON.stringify({ ...params, teamId: TEAM_ID })],
    () => getTeamMembers({ ...params, teamId: TEAM_ID }),
    { enabled: !!TEAM_ID, ...options },
  );

  return { teamMembers: data, ...rest };
};
