import { ChangeEvent, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { Button, HeaderContainer, HeaderDescription, HeaderTitle, HeaderVideo, Link, Typography } from 'src/components';
import { useCompanyTheme, useWhiteLabel } from 'src/hooks';
import { useGetUserPreferences, useUpdateWithdrawals } from 'src/reactQueries';
import { SuccessMessage, Wrapper } from './_components/ui';

const Label = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 16px;
  margin-bottom: 2px;
`;

const RadioStyle = {
  color: '#767676',
  height: 28,
  width: 28,
  '&.Mui-checked': {
    color: '#0075FF',
  },
  '&.Mui-checked:hover': {
    color: '#005CC8',
  },
  '& .MuiSvgIcon-root': {
    fontSize: 15,
  },
};

const WITHDRAW_PERIODS = [
  { value: 7, label: '7 days' },
  { value: 14, label: '14 days' },
  { value: 21, label: '21 days' },
  { value: 30, label: '30 days' },
  { value: 60, label: '60 days' },
  { value: 90, label: '90 days' },
  { value: 0, label: 'Disable auto withdraw' },
];

export const AutoWithdrawals = () => {
  const { userPreferences } = useGetUserPreferences();
  const { shortCompanyName } = useCompanyTheme();
  const { whitelabel } = useWhiteLabel();
  const [autoWithdraw, setAutoWithdraw] = useState(0);

  const { updateWithdrawals, isSuccess, isLoading } = useUpdateWithdrawals();

  useEffect(() => {
    setAutoWithdraw(userPreferences?.autoWithdraw ?? 0);
  }, [userPreferences?.autoWithdraw]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAutoWithdraw(Number(event.target.value));
  };

  const onChangePeriod = () => {
    updateWithdrawals({ autoWithdraw });
  };

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Auto Withdrawals</HeaderTitle>
        <HeaderDescription>
          Select a time period for {shortCompanyName} to withdraw all pending sent invitations.
          {whitelabel.isWhiteLabelUser || (
            <>
              <Link
                leftIcon
                underline
                external
                openNewTab
                to="https://help.meetalfred.com/en/articles/3450169-automatically-withdraw-sent-linkedin-connection-requests"
              >
                Learn how it works
              </Link>
              <HeaderVideo src="https://player.vimeo.com/video/871561404" />
            </>
          )}
        </HeaderDescription>
      </HeaderContainer>
      <Wrapper maxWidth="46%">
        <Box m="10px">
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={autoWithdraw}
            onChange={handleChange}
          >
            {WITHDRAW_PERIODS.map(({ label, value }) => (
              <FormControlLabel
                key={value}
                value={value}
                label={<Label>{label}</Label>}
                control={<Radio sx={RadioStyle} />}
              />
            ))}
          </RadioGroup>
        </Box>
        {isSuccess && <SuccessMessage>Auto withdrawal interval updated.</SuccessMessage>}
        <Box display="flex" justifyContent="flex-end" mt="15px">
          <Button processing={isLoading} onClick={onChangePeriod}>
            Save
          </Button>
        </Box>
      </Wrapper>
    </>
  );
};
