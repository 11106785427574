import { createSlice } from '@reduxjs/toolkit';

import { LIConversationFilterType } from 'src/enums';
import { ILIConversation } from 'src/types';

interface IInitialState {
  selectedConversation: ILIConversation | null;
  filterType: LIConversationFilterType;
  query: string;
  selectedTags: number[];
  pictures: Record<string, string>;
  userConversationId: string | null;
}

const initialState: IInitialState = {
  selectedConversation: null,
  filterType: LIConversationFilterType.ALL,
  query: '',
  selectedTags: [],
  pictures: {},
  userConversationId: null,
};

export const conversationSlice = createSlice({
  name: 'conversation',
  initialState,
  reducers: {
    setSelectedConversation: (state, action) => {
      state.selectedConversation = action.payload;
    },
    setFilterType: (state, action) => {
      state.filterType = action.payload;
    },
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    setSelectedTags: (state, action) => {
      state.selectedTags = action.payload;
    },
    setConversationPictures: (state, action) => {
      state.pictures = { ...state.pictures, ...action.payload };
    },
    clearConversationState: (state) => {
      state.selectedConversation = null;
      state.filterType = LIConversationFilterType.ALL;
      state.selectedTags = [];
    },
    setUserConversationId: (state, action) => {
      state.userConversationId = action.payload;
    },
  },
});

export const {
  setSelectedConversation,
  setFilterType,
  setQuery,
  setSelectedTags,
  setConversationPictures,
  clearConversationState,
  setUserConversationId,
} = conversationSlice.actions;

export default conversationSlice.reducer;
