import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import {
  DatePicker,
  HtmlParser,
  SelectBasicGray,
  ShowMore,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
  Typography,
} from 'src/components';
import { ACTIVITY_INTERVAL_OPTIONS } from 'src/constants';
import { ModalTypes } from 'src/enums';
import {
  resolveActionDescription,
  resolveActionMessage,
  resolveActionStepNumber,
  resolvePersonName,
} from 'src/helpers';
import { useTimeIntervalOptions } from 'src/hooks';
import { useGetCampaign, useGetCampaignDetailsActivity } from 'src/reactQueries';
import { openModal } from 'src/store';
import { ITablePagination, TDetailsActivitySortField, TSortOrder } from 'src/types';
import { DetailsLayout } from './DetailsLayout';

dayjs.extend(relativeTime);

const SHOW_MESSAGE_WHITELIST = ['emailed', 'Email reply detected', 'Email opened'];

export const Activity = () => {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const CAMPAIGN_ID = Number(campaignId);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(50);
  const [sortField, setSortField] = useState<TDetailsActivitySortField>('createdAt');
  const [sortOrder, setSortOrder] = useState<TSortOrder>('DESC');

  const { today, dateFrom, dateTo, interval, setDateTo, setDateFrom, setInterval } = useTimeIntervalOptions({
    onIntervalChange: () => setPage(1),
  });

  const { campaign } = useGetCampaign(CAMPAIGN_ID);
  const { data, isLoading } = useGetCampaignDetailsActivity(
    CAMPAIGN_ID,
    { page, perPage, sortField, sortOrder, timeFilter: interval, customFromDate: dateFrom, customToDate: dateTo },
    { refetchOnMount: true },
  );
  const { actions = [], total } = data ?? {};

  const totalSteps = campaign?.touchSequence?.sequence?.length || 1;

  const sortingProps = {
    sortField,
    sortOrder,
    onSortFieldChange: (field: string, order: string) => {
      setSortField(field as TDetailsActivitySortField);
      setSortOrder(order as TSortOrder);
    },
  };

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  const renderSelector = () => {
    return (
      <>
        {interval === 'custom' && (
          <Box display="flex" alignItems="center" gap="10px" mr="10px">
            <DatePicker
              type="date"
              value={dateFrom}
              placeholder="from"
              max={dateTo}
              onChange={(e) => e.target.value && setDateFrom(e.target.value)}
            />
            <FontAwesomeIcon icon={faArrowRight} />
            <DatePicker
              type="date"
              value={dateTo}
              placeholder="to"
              max={today}
              min={dateFrom}
              onChange={(e) => e.target.value && setDateTo(e.target.value)}
            />
          </Box>
        )}
        <SelectBasicGray
          label="Filter by:"
          value={interval}
          onChange={({ value }) => setInterval(value)}
          options={ACTIVITY_INTERVAL_OPTIONS}
          minWidth="180px"
          valueContainerPosition="space-between"
        />
      </>
    );
  };

  return (
    <DetailsLayout renderSelector={renderSelector} showAddMoreLeads>
      <Box padding="15px">
        <Table processing={isLoading}>
          <TableHead>
            <TableRow>
              <TableHeadCell width="54px" />
              <TableHeadCell name="name" sorting={sortingProps}>
                Name
              </TableHeadCell>
              <TableHeadCell width="18%" name="action" sorting={sortingProps}>
                Last action
              </TableHeadCell>
              <TableHeadCell width="50%" name="message" sorting={sortingProps}>
                Message
              </TableHeadCell>
              <TableHeadCell width="150px" name="touchIndex" sorting={sortingProps}>
                Step
              </TableHeadCell>
              <TableHeadCell width="120px" name="createdAt" sorting={sortingProps}>
                Time
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody placeholder={!total && 'No activity yet.'}>
            {actions?.map(
              ({ person, id, msgCreatedAt, createdAt, objectUrn, description, message, touchIndex = 1 }) => {
                const { name, avatarContent } = resolvePersonName({
                  ...person,
                  campaignType: campaign?.campaignType,
                });

                const modalHandler = () => {
                  dispatch(
                    openModal({
                      type: ModalTypes.VIEW_LEAD,
                      closable: false,
                      params: {
                        campaignId: campaign?.id,
                        entityUrn: objectUrn || person?.key,
                      },
                    }),
                  );
                };

                return (
                  <TableRow key={id} onClick={modalHandler} hoverBgColor={({ light }) => light.main} cursor="pointer">
                    <TableCell>{avatarContent}</TableCell>
                    <TableCell>
                      <Typography crop fontSize="15px">
                        {name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography capitalizeFirstLetter fontSize="15px">
                        {resolveActionDescription(description, message)}
                      </Typography>
                    </TableCell>
                    <TableCell whiteSpace="normal">
                      {!!message &&
                        (SHOW_MESSAGE_WHITELIST.includes(description) ? (
                          <ShowMore>
                            <HtmlParser parseText={message.replace(/(<([^>]+)>)/g, '')} />
                          </ShowMore>
                        ) : (
                          resolveActionMessage(description, message)
                        ))}
                    </TableCell>
                    <TableCell>
                      {resolveActionStepNumber({
                        action: description,
                        stepNumber: touchIndex,
                        totalSteps,
                        incrementStepNumber: true,
                      })}
                    </TableCell>
                    <TableCell>{dayjs(msgCreatedAt || createdAt).fromNow()}</TableCell>
                  </TableRow>
                );
              },
            )}
          </TableBody>
        </Table>

        <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={paginationChangeHandler} />
      </Box>
    </DetailsLayout>
  );
};
