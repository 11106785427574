import { useDispatch } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Button, Typography } from 'src/components';
import { ModalTypes } from 'src/enums';
import { useCompanyTheme } from 'src/hooks';
import { useCloseCancelModalTrigger, useGetBillingInfo, useGetIntercomInitialData } from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';

export const ManageSubscription = () => {
  const dispatch = useDispatch();
  const { shortCompanyName } = useCompanyTheme();
  const { closeCancelModalTrigger } = useCloseCancelModalTrigger();

  const { update: updateIntercomData } = useIntercom();
  const { data: intercomData } = useGetIntercomInitialData();

  const { billingInfo } = useGetBillingInfo();

  const deleteAccount = () => {
    dispatch(
      openModal({
        isActionsShown: false,
        headerText: `Remove your account from ${shortCompanyName}`,
        descriptionText: 'To remove your account, please contact support on the chat',
      }),
    );
  };

  const cancelSubscription = () => {
    dispatch(
      openModal({
        type: ModalTypes.CANCEL_SUBSCRIPTION,
        closable: false,
        size: 'large',
        onCancel: () => {
          updateIntercomData({
            userHash: intercomData?.intercomHash,
            customAttributes: {
              closedCancellationView: true,
            },
          });
          closeCancelModalTrigger('close cancel view');
        },
      }),
    );
  };

  return (
    <Box bgcolor="#fff" borderRadius="4px" p="20px">
      <Typography color="primary.wlLight" lineHeight="40px">
        Subscription
      </Typography>
      <Box display="flex" justifyContent="flex-end" gap="20px">
        {billingInfo && !billingInfo?.stripeSubscriptionObject?.cancel_at_period_end && (
          <Button onClick={cancelSubscription}>Cancel Subscription</Button>
        )}

        <Button variant="warning" onClick={deleteAccount}>
          <FontAwesomeIcon icon={faExclamationTriangle} /> Delete account
        </Button>
      </Box>
    </Box>
  );
};
