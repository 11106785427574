import { useQuery, UseQueryOptions } from 'react-query';

import { getMailSettings } from 'src/api';
import { ICustomAxiosError, IMailSettings } from 'src/types';

export const useGetMailSettings = (options?: UseQueryOptions<IMailSettings, ICustomAxiosError>) => {
  const { data, isLoading, ...rest } = useQuery<IMailSettings, ICustomAxiosError>(
    ['get-mail-settings'],
    getMailSettings,
    options,
  );

  const isSMTPConnected = !!data?.smtpSettings.smtpServer;
  const isIMAPConnected = !!data?.imapSettings.imapServer;

  return {
    mailSettings: { ...data, isIMAPConnected, isSMTPConnected, isConnected: isSMTPConnected && isIMAPConnected },
    isMailLoading: isLoading,
    ...rest,
  };
};
