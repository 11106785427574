import dayjs from 'dayjs';
import { useEffect } from 'react';

import { useGetCampaignListItem } from 'src/reactQueries';
import { TIntervalFilter } from 'src/types';
import { useSocket } from './useSocket';

export const useUpdateCampaignDataBySocket = ({ interval }: { interval: TIntervalFilter }) => {
  const socket = useSocket();
  const { getCampaignListItem } = useGetCampaignListItem();

  useEffect(() => {
    socket.on('campaign-update', ({ campaignId }: { campaignId: number }) => {
      console.info(
        `[Campaign Update] Received a socket event for campaign [${campaignId}] at ${dayjs().format('HH:mm:ss')}`,
      );
      getCampaignListItem(
        { id: campaignId, filter: interval },
        {
          onSuccess: (data) => {
            console.info(
              `[Campaign Update] Updated campaign [${campaignId}] data. Approved Leads found - ${data.leadsApproved}`,
            );
          },
        },
      );
    });

    return () => {
      socket.off('campaign-update');
    };
  }, []);
};
