import { UseFormRegister } from 'react-hook-form';

import { Checkbox, Tooltip } from 'src/components';
import { CheckboxContainer } from '../../_components';

interface ILinkedinCheckboxProps {
  isLinkedInLoginValid: boolean;
  // eslint-disable-next-line
  register: UseFormRegister<any>;
  disabled?: boolean;
}

export const LinkedinCheckbox = ({ isLinkedInLoginValid, register, disabled = false }: ILinkedinCheckboxProps) => {
  if (isLinkedInLoginValid) {
    return (
      <CheckboxContainer>
        <Checkbox
          data-testid="linkedin-checkbox"
          name="postTypes"
          register={register}
          type="checkbox"
          value="linkedin"
          disabled={disabled}
        />
        <label>LinkedIn</label>
      </CheckboxContainer>
    );
  }

  return (
    <Tooltip
      title={
        <>
          Please authenticate LinkedIn in the Integrations page.
          <br />
          Click on Integrations tab on top navigation bar.
        </>
      }
      placement="top"
      arrow
    >
      <CheckboxContainer>
        <Checkbox
          data-testid="linkedin-checkbox"
          name="postTypes"
          register={register}
          type="checkbox"
          value="linkedin"
          disabled
        />
        <label>LinkedIn</label>
      </CheckboxContainer>
    </Tooltip>
  );
};
