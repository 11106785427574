import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { Button, Input, Typography } from 'src/components';
import { closeModal } from 'src/store/modal.slice';
import { IModalProps } from 'src/types';
import { ModalBody, ModalFooter } from '../_components';

const StyledInput = styled(Input)`
  background: rgba(242, 243, 249, 0.6) !important;
  border-radius: 8px !important;
  height: 50px !important;
  margin-top: 10px;
`;

interface IModalChangeEmail {
  value: string;
  onConfirm: () => void;
  setValue: (value: string) => void;
}

export const ModalChangeEmail = ({ onCancel, params }: IModalProps<IModalChangeEmail>) => {
  const dispatch = useDispatch();
  const { onConfirm, value, setValue } = params ?? {};
  const [email, setEmail] = useState(value);

  const confirmHandler = () => {
    onConfirm && onConfirm();
    dispatch(closeModal());
  };

  const closeHandler = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    setValue && setValue(event.target.value);
  };

  return (
    <>
      <ModalBody>
        <Typography color="violet.dark">Please confirm if this is your new email.</Typography>
        <StyledInput
          data-testid="change-modal-email-input"
          value={email}
          onChange={onChange}
          name="email"
          type="email"
          placeholder="Email"
        />
      </ModalBody>

      <ModalFooter>
        <Button variant="gray" onClick={closeHandler}>
          Cancel
        </Button>
        <Button onClick={confirmHandler}>Confirm</Button>
      </ModalFooter>
    </>
  );
};
