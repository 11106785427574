import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useQuery, UseQueryOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { getUserPreferences } from 'src/api';
import { NotificationTypes } from 'src/enums';
import { delay } from 'src/helpers';
import { useAppSelector } from 'src/hooks';
import { addNotification, removeNotificationByType } from 'src/store/notification.slice';
import { ICustomAxiosError, IUserPreferences } from 'src/types';

dayjs.extend(utc);
dayjs.extend(timezone);

let timeout: number;

const APPROXIMATE_LIMIT_OF_LI_CONNECTIONS = 29900;

export const useGetUserPreferences = (options?: UseQueryOptions<IUserPreferences, ICustomAxiosError>) => {
  const dispatch = useDispatch();
  const userTimezone = useAppSelector((state) => state.user.profile?.timezone?.name);

  const { data, ...rest } = useQuery<IUserPreferences, ICustomAxiosError>(
    ['get-user-preferences'],
    getUserPreferences,
    {
      ...options,
      onSuccess: (data) => {
        options?.onSuccess?.(data);
        const currentDay = dayjs().tz(userTimezone).get('day');
        const currentHour = dayjs().tz(userTimezone).get('hour');
        const currentMinute = dayjs().tz(userTimezone).get('minute');
        const currentSecond = dayjs().tz(userTimezone).get('second');

        const currentWorkingDays =
          data?.operatingHours?.scheduled?.filter(({ day }) => currentDay === Number(day)) || [];
        const startWorkingAt = Math.min(...currentWorkingDays.map(({ startHour }) => Number(startHour)));

        const inWorkingPeriod = currentWorkingDays?.some(
          ({ endHour, startHour }) => Number(endHour) > currentHour && currentHour >= Number(startHour),
        );

        if (currentWorkingDays?.length && !inWorkingPeriod && startWorkingAt > currentHour) {
          clearTimeout(timeout);
          const diffHours = startWorkingAt - currentHour;
          const minutes = 60 - currentMinute;
          const second = 60 - currentSecond;

          const timeoutDelayInMinutes = minutes + (diffHours - 1) * 60;
          const timeoutDelayInMs = (timeoutDelayInMinutes * 60 + second) * 1000;
          const botStartingDelayInMs = 120000; // 2 min

          timeout = delay(timeoutDelayInMs + botStartingDelayInMs, () => {
            dispatch(removeNotificationByType({ type: NotificationTypes.OUTSIDE_SCHEDULED }));
          });
        }

        if (!inWorkingPeriod) {
          dispatch(addNotification({ type: NotificationTypes.OUTSIDE_SCHEDULED }));
        } else {
          dispatch(removeNotificationByType({ type: NotificationTypes.OUTSIDE_SCHEDULED }));
        }

        if (
          data?.connectionRestrictionAt &&
          data?.connectionRestrictionType &&
          dayjs(data.connectionRestrictionAt)
            .add(data.connectionRestrictionType === 'days' ? 1 : 3, data.connectionRestrictionType)
            .diff(dayjs()) > 1
        ) {
          dispatch(addNotification({ type: NotificationTypes.LIMITED_CONNECTIONS_REQUESTS }));
        } else {
          dispatch(removeNotificationByType({ type: NotificationTypes.LIMITED_CONNECTIONS_REQUESTS }));
        }

        if (data?.linkedInConnections > APPROXIMATE_LIMIT_OF_LI_CONNECTIONS) {
          dispatch(addNotification({ type: NotificationTypes.LIMIT_OF_LI_CONNECTIONS }));
        } else {
          dispatch(removeNotificationByType({ type: NotificationTypes.LIMIT_OF_LI_CONNECTIONS }));
        }
      },
    },
  );

  return { userPreferences: data, ...rest };
};
