import { useQuery, UseQueryOptions } from 'react-query';

import { getAllLeadsStatuses } from 'src/api';
import { ICustomAxiosError, ILeadsSearchFields, ILeadsStatuses } from 'src/types';

export const useGetCampaignLeadsStatuses = (
  campaignId: number,
  params: ILeadsSearchFields,
  options?: UseQueryOptions<ILeadsStatuses, ICustomAxiosError>,
) => {
  return useQuery<ILeadsStatuses, ICustomAxiosError>(
    ['get-campaign-leads-statuses', campaignId, JSON.stringify(params)],
    () => getAllLeadsStatuses(campaignId, params),
    options,
  );
};
