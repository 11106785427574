import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import spinner from 'src/assets/img/spinner.gif';
import { Button } from 'src/components';
import { delay, removeCookie } from 'src/helpers';
import { useCompanyTheme, useLocationState } from 'src/hooks';
import { showToast } from 'src/store/toast.slice';
import { TToastType } from 'src/types';

const cookies = new Cookies();

const Wrapper = styled(Box)`
  position: relative;
  min-height: 307px;
  width: 100%;
  background-position: 50% 100%;
  background-size: cover;
  color: #fff;
  padding: 40px 20px 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 987px) {
    min-height: 293px;
  }

  @media screen and (max-width: 767px) {
    min-height: 269px;
    background-color: ${({ theme }) => theme.palette.primary.dark};
  }
`;

const Body = styled(Box)`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;

  @media screen and (max-width: 767px) {
    margin-top: 60px;
  }
`;

const Title = styled.h1`
  margin-top: 24px;
  font-weight: 400;
  font-family: proxima-nova, sans-serif;
  font-size: 60px;
  line-height: 70px;

  @media screen and (max-width: 900px) {
    font-size: 46px;
    line-height: 56px;
  }

  @media screen and (max-width: 770px) {
    font-size: 42px;
    line-height: 52px;
  }

  @media screen and (max-width: 480px) {
    font-size: 38px;
    line-height: 50px;
  }
`;

const Text = styled.p`
  font-family: proxima-nova, sans-serif;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.primary.dark};
  line-height: 30px;

  @media screen and (max-width: 770px) {
    font-size: 16px;
  }
`;

export const Welcome = () => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const { logoIconWhite, shortCompanyName } = useCompanyTheme();

  const navigate = useNavigate();
  const state = useLocationState<{ message?: string }>();

  const [isButtonShown, setIsButtonShown] = useState(false);

  const isChangeUserEmailProcess = Boolean(cookies.get('changeUserEmailProcess'));
  const welcomeBg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1366.5 730"> <path fill="${palette.primary.dark}" d="M1366.5,724.74s-37.5,15.73-426-7.23c-209-9.58-397-2.9-518,1.81C157.5,729.62,0,709,0,709V0H1366.5Z" /> </svg> `;

  const verifyMessage = useMemo(() => {
    switch (state.message) {
      case 'Email successfully verified':
        return {
          type: 'success',
          message: 'Your email has been successfully verified.',
        };
      case 'Email already verified':
        return {
          type: 'success',
          message: 'Email already verified.',
        };
      default:
        return {
          type: 'error',
          message: 'The confirmation link is invalid or has expired.',
        };
    }
  }, [state.message]);

  const redirect = () => {
    setIsButtonShown(true);
    removeCookie('changeUserEmailProcess');
    navigate(isChangeUserEmailProcess ? '/dashboard/today' : '/walkthrough');

    if (state.message) {
      dispatch(
        showToast({
          type: verifyMessage.type as TToastType,
          message: verifyMessage.message,
          model: 'alert',
        }),
      );
    }
  };

  useEffect(() => {
    const timeout = delay(5000, redirect);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Box>
      <Wrapper sx={{ backgroundImage: `url("data:image/svg+xml, ${encodeURIComponent(welcomeBg)}")` }}>
        <img src={logoIconWhite} height="90px" alt="logo" />
        <Title>Welcome to {shortCompanyName}</Title>
      </Wrapper>
      <Body>
        <Text>
          {isChangeUserEmailProcess || state?.message
            ? 'Thank you for verifying your email!'
            : 'Thank you for registering!'}
        </Text>
        <Text>You'll be redirected back to the app in a few seconds.</Text>
        <img src={spinner} width="50" height="50" alt="" />
        {isButtonShown && <Button onClick={redirect}>Take me to the {shortCompanyName}</Button>}
      </Body>
    </Box>
  );
};
