import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { postConnections } from 'src/api';
import { convertConnectionFilterToRequest } from 'src/helpers';
import { IConnectionsFilter, IConnectionsResponse } from 'src/types';

export const usePostConnections = <TData = IConnectionsResponse>(
  requestData: IConnectionsFilter,
  options?: UseQueryOptions<IConnectionsResponse, AxiosError, TData>,
) => {
  return useQuery<IConnectionsResponse, AxiosError, TData>(
    ['post-connections', JSON.stringify(requestData)],
    () => postConnections(convertConnectionFilterToRequest(requestData)),
    options,
  );
};
