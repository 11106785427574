import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateUserInfo } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { IChangeUserInfoRequest, ICustomAxiosError, IUser, IUserPreferences } from 'src/types';

export const useUpdateUserInfo = (options?: UseMutationOptions<void, ICustomAxiosError, IChangeUserInfoRequest>) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const getUserPreferencesKey = ['get-user-preferences'];
  const getUserProfileKey = ['get-user-profile'];

  const { mutate, ...rest } = useMutation(
    ['update-user-info'],
    (data: IChangeUserInfoRequest) => updateUserInfo(data),
    {
      ...options,
      onMutate: async (variables) => {
        options?.onMutate?.(variables);
        const { firstName, lastName } = variables;
        await queryClient.cancelQueries(getUserPreferencesKey);
        await queryClient.cancelQueries(getUserProfileKey);

        const prevDataPreferences = queryClient.getQueryData(getUserPreferencesKey);
        const prevDataProfile = queryClient.getQueryData(getUserProfileKey);

        queryClient.setQueryData<IUserPreferences | undefined>(getUserPreferencesKey, (data) => {
          if (data) {
            return {
              ...data,
              userProfile: {
                ...data.userProfile,
                firstName,
                lastName,
              },
            };
          }

          return data;
        });

        queryClient.setQueryData<IUser | undefined>(getUserProfileKey, (data) => {
          if (data) {
            return {
              ...data,
              firstName,
              lastName,
              name: `${firstName} ${lastName}`,
            };
          }

          return data;
        });

        return { prevData: { prevDataPreferences, prevDataProfile } };
      },
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        // need to load again if picture was updated
        if (variables.profilePicture) {
          await Promise.all([
            queryClient.invalidateQueries(getUserPreferencesKey),
            queryClient.invalidateQueries(getUserProfileKey),
          ]);
        }

        dispatch(
          showToast({
            type: 'info',
            message: 'BASIC DATA SAVED',
            autoCloseTime: 3000,
          }),
        );
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueryData(getUserPreferencesKey, context?.prevData.prevDataPreferences);
        queryClient.setQueryData(getUserProfileKey, context?.prevData.prevDataProfile);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { updateUserInfo: mutate, ...rest };
};
