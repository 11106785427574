import { useQuery, UseQueryOptions } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { authUsingGoogle } from 'src/api';
import { COOKIE_TOKEN_NAME } from 'src/constants';
import { removeReferralCodeFromCookies, setCookie } from 'src/helpers';
import { setUser } from 'src/store/user.slice';
import { IAuthResponse, ICustomAxiosError, IGoogleSignUpRequest } from 'src/types';

export const useGoogleAuth = (
  data: IGoogleSignUpRequest,
  isSignup: boolean,
  navigateTo?: string,
  options?: UseQueryOptions<IAuthResponse, ICustomAxiosError>,
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return useQuery<IAuthResponse, ICustomAxiosError>(
    ['google-auth', isSignup, data.authorizationCode],
    () => authUsingGoogle(data, isSignup),
    {
      enabled: !!data.authorizationCode,
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...options,
      onSuccess: (data) => {
        if (data.redirectTo) {
          return location.replace(`${data.redirectTo}/auth/login?exchange-token=${data.authToken}`);
        }

        if (options?.onSuccess) {
          options?.onSuccess(data);
        } else {
          setCookie(COOKIE_TOKEN_NAME, data.authToken);

          removeReferralCodeFromCookies();

          dispatch(setUser(data.user));
        }

        navigate(navigateTo || '/');
      },
    },
  );
};
