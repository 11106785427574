import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';

import { Toggle } from 'src/components';
import { useAppSelector, useCompanyTheme } from 'src/hooks';
import { useUpdateLead } from 'src/reactQueries';
import { setBlacklistLeadsSelectedLeads, setSelectedLeads } from 'src/store';

interface ToggleLeadProps {
  entityUrn: string;
  isExcluded: boolean;
  tooltipPlacement?: 'left' | 'right' | 'top' | 'bottom';
}

export const ToggleLead = ({ entityUrn, isExcluded, tooltipPlacement = 'left' }: ToggleLeadProps) => {
  const dispatch = useDispatch();

  const {
    exportData: { selectedLeads: leads },
  } = useAppSelector((state) => state.lead);
  const {
    exportData: { selectedLeads: blacklistLeads },
  } = useAppSelector((state) => state.blacklistLeads);

  const { companyName } = useCompanyTheme();

  const { updateLead } = useUpdateLead(entityUrn, {
    onMutate: () => {
      if (leads.find((lead) => lead.entityUrn === entityUrn)) {
        dispatch(
          setSelectedLeads(
            leads.map((lead) => (lead.entityUrn === entityUrn ? { ...lead, isExcluded: !isExcluded } : lead)),
          ),
        );
      }
      if (blacklistLeads.find((lead) => lead.entityUrn === entityUrn)) {
        dispatch(
          setBlacklistLeadsSelectedLeads(
            blacklistLeads.map((lead) => (lead.entityUrn === entityUrn ? { ...lead, isExcluded: !isExcluded } : lead)),
          ),
        );
      }
    },
  });

  const { palette } = useTheme();

  return (
    <Toggle
      tooltip={{
        title: `Toggle to exclude this lead from all ${companyName} actions across your campaigns`,
        placement: tooltipPlacement,
      }}
      checked={isExcluded}
      name={entityUrn}
      checkedBgColor={palette.error['300']}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        updateLead({ isExcluded: e.currentTarget.checked });
      }}
    />
  );
};
