import { useState } from 'react';
import { Box } from '@mui/material';

import { Table, TableBody, TableHead, TableHeadCell, TablePagination, TableRow } from 'src/components';
import { DEFAULT_PER_PAGE } from 'src/constants';
import { useGetTeamStats } from 'src/reactQueries';
import { ITablePagination, TIntervalFilter, TSortOrder, TTeamStatsSortFields } from 'src/types';
import { TeamStatsTableRow } from './TeamStatsTableRow';

const STATS_COLUMNS = [
  { name: 'campaigns', label: 'Campaigns' },
  { name: 'invites', label: 'Invites' },
  { name: 'connected', label: 'Connected' },
  { name: 'viewed', label: 'Viewed' },
  {
    name: 'messages',
    label: 'Messages',
    tooltip: 'This number includes Linkedin, InMail, Event, Group, Followed up messages, Emails and X (Twitter) DMs.',
  },
  { name: 'replies', label: 'Replies' },
  { name: 'reply rate', label: 'Reply rate', sortable: false },
];

export const TeamStatsTable = ({
  timeFilter,
  customFromDate,
  customToDate,
}: {
  timeFilter: TIntervalFilter;
  customFromDate: string;
  customToDate: string;
}) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [sortField, setSortField] = useState<TTeamStatsSortFields>('role');
  const [sortOrder, setSortOrder] = useState<TSortOrder>('ASC');

  const { teamStats, isLoading } = useGetTeamStats({
    sortField,
    sortOrder,
    perPage,
    page,
    timeFilter,
    customFromDate,
    customToDate,
  });
  const { data, total } = teamStats ?? {};

  const onSortByField = (field: string, order: string) => {
    setSortField(field as TTeamStatsSortFields);
    setSortOrder(order as TSortOrder);
  };

  const paginationChangeHandler = ({ page, perPage }: ITablePagination) => {
    setPage(page);
    setPerPage(perPage);
  };

  return (
    <Box>
      <Table disabled={isLoading} processing={isLoading}>
        <TableHead>
          <TableRow>
            <TableHeadCell
              width="136px"
              name="role"
              sorting={{
                sortOrder,
                sortField,
                onSortFieldChange: onSortByField,
              }}
            >
              Role
            </TableHeadCell>
            <TableHeadCell width="20%">Team member</TableHeadCell>
            {STATS_COLUMNS.map(({ name, label, tooltip = '', sortable = true }) => (
              <TableHeadCell
                key={name}
                name={name}
                tooltip={tooltip}
                width="10%"
                {...(sortable ? { sorting: { sortOrder, sortField, onSortFieldChange: onSortByField } } : {})}
              >
                {label}
              </TableHeadCell>
            ))}
            <TableHeadCell width="120px" />
          </TableRow>
        </TableHead>

        <TableBody placeholder={!total && 'No stats yet'}>
          {data?.map((stat) => (
            <TeamStatsTableRow
              key={stat.userId}
              statsData={stat}
              timeFilter={timeFilter}
              customToDate={customToDate}
              customFromDate={customFromDate}
            />
          ))}
        </TableBody>
      </Table>
      <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={paginationChangeHandler} />
    </Box>
  );
};
