import styled from '@emotion/styled';
import { Box, BoxProps } from '@mui/material';

const StyledBox = styled(Box)`
  width: auto;
  justify-content: space-around;
  display: inline-flex;
  align-items: center;

  &:last-child {
    box-shadow: none;
  }
`;

export const HeaderTabsContainer = ({ children, ...restProps }: BoxProps) => {
  return <StyledBox {...restProps}>{children}</StyledBox>;
};
