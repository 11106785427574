import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import {
  HeaderButton,
  HeaderContainer,
  HeaderTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
  Typography,
} from 'src/components';
import { DEFAULT_PER_PAGE } from 'src/constants';
import { LeadActivityEnum, ModalTypes } from 'src/enums';
import { resolvePersonName } from 'src/helpers';
import { useAppDispatch } from 'src/hooks';
import { useGetCampaign, useGetCampaignActivity } from 'src/reactQueries';
import { openModal } from 'src/store';
import { ITablePagination, TActivitySortField, TSortOrder } from 'src/types';

const StyledTable = styled(Table)`
  border-collapse: separate;
`;

const StyledTableCell = styled(TableCell)`
  padding: 20px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.lightGray.light};
`;

export const StatInfo = () => {
  const dispatch = useAppDispatch();

  const { palette } = useTheme();
  const { stat = '', campaignId } = useParams();
  const CAMPAIGN_ID = Number(campaignId);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [sortField, setSortField] = useState<TActivitySortField>('name');
  const [sortOrder, setSortOrder] = useState<TSortOrder>('ASC');

  const { title, filters = [] } = useMemo(() => {
    switch (stat) {
      case 'connected':
        return { title: 'Connected', filters: [LeadActivityEnum.CONNECTED] };
      case 'invites':
        return { title: 'Sent Requests', filters: [LeadActivityEnum.REQUESTED_CONNECT] };
      default:
        return { title: '', filters: [] };
    }
  }, [stat]);

  const { campaign } = useGetCampaign(Number(campaignId));
  const { data, isLoading } = useGetCampaignActivity({
    filters,
    campaigns: [String(campaignId)],
    page,
    perPage,
    sortOrder,
    sortField,
  });
  const { actions = [], total } = data ?? {};

  const onSortFieldChange = (field: string, order: string) => {
    setSortField(field as TActivitySortField);
    setSortOrder(order as 'DESC' | 'ASC');
  };

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  const modalHandler = (params: { campaignId: number; entityUrn?: string }) => {
    dispatch(
      openModal({
        type: ModalTypes.VIEW_LEAD,
        closable: false,
        params,
      }),
    );
  };

  const onExportCampaignActivity = () => {
    dispatch(
      openModal({
        type: ModalTypes.EXPORT_CAMPAIGN_ACTIVITY_CSV,
        closable: false,
        params: {
          campaignId: CAMPAIGN_ID,
          filters,
        },
      }),
    );
  };

  return (
    <Box>
      <HeaderContainer pb="10px">
        <HeaderTitle>{title}</HeaderTitle>
      </HeaderContainer>
      <Box padding="10px 50px 100px">
        <Box display="flex" justifyContent="space-between" mt="30px" mb="40px">
          <Box>
            <Box>
              <Typography color="primary.wlLight" semibold fontSize="22px" inline>
                {campaign?.name}{' '}
              </Typography>
              <Typography color="violet.dark" fontSize="12px" inline>
                ID: {campaignId}
              </Typography>
            </Box>
            <Typography color={palette.lightGray.main} mt="5px">
              Campaign details - <b>{campaign?.runState}</b>
            </Typography>
          </Box>
          <Box>
            <HeaderButton onClick={onExportCampaignActivity}>Export CSV</HeaderButton>
          </Box>
        </Box>
        <Box>
          <StyledTable processing={isLoading} placeholder={!total && `No ${title}`}>
            <TableHead>
              <TableRow>
                <TableHeadCell
                  name="name"
                  secondary
                  sorting={{
                    sortOrder,
                    sortField,
                    onSortFieldChange,
                  }}
                  width="350px"
                  contentColor={palette.lightGray.main}
                >
                  LEAD
                </TableHeadCell>
                <TableHeadCell
                  secondary
                  sorting={{
                    sortOrder,
                    sortField,
                    onSortFieldChange,
                  }}
                  width="35%"
                  contentColor={palette.lightGray.main}
                >
                  TITLE
                </TableHeadCell>
                <TableHeadCell
                  secondary
                  sorting={{
                    sortOrder,
                    sortField,
                    onSortFieldChange,
                  }}
                  width="35%"
                  contentColor={palette.lightGray.main}
                >
                  COMPANY
                </TableHeadCell>
                <TableHeadCell
                  name="date"
                  secondary
                  sorting={{
                    sortOrder,
                    sortField,
                    onSortFieldChange,
                  }}
                  width="140px"
                  contentColor={palette.lightGray.main}
                >
                  TIME
                </TableHeadCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {actions.map(({ person, createdAt, objectUrn }) => {
                const { name, nameContent, avatarContent } = resolvePersonName(person);

                return (
                  <TableRow
                    key={objectUrn}
                    hoverBgColor={({ light }) => light.main}
                    bgColor={palette.light.light}
                    onClick={() => modalHandler({ entityUrn: person?.key, campaignId: CAMPAIGN_ID })}
                  >
                    <StyledTableCell>
                      <Box display="flex" gap="20px" alignItems="center">
                        {avatarContent}
                        <Typography bold>{name || nameContent}</Typography>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography color="text.primary">{person?.currentTitle}</Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography color="text.primary">{person?.currentEmployer}</Typography>
                    </StyledTableCell>
                    <StyledTableCell>
                      <Typography color="text.primary">{dayjs(createdAt).fromNow()}</Typography>
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </StyledTable>

          <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={paginationChangeHandler} />
        </Box>
      </Box>
    </Box>
  );
};
