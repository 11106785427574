import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getCampaignsSearchList } from 'src/api';
import { ICampaignSearchItem, IGetCampaignsSearchListRequest } from 'src/types';

export const useGetCampaignsSearchList = <TData = ICampaignSearchItem[]>(
  data?: IGetCampaignsSearchListRequest,
  options?: UseQueryOptions<ICampaignSearchItem[], AxiosError, TData>,
) => {
  return useQuery<ICampaignSearchItem[], AxiosError, TData>(
    ['campaign-activity-search-list'],
    () => getCampaignsSearchList(data),
    options,
  );
};
