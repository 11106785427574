import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { deleteCampaignIgnoredLeads } from 'src/api';
import { removeIgnoredSelectedLead } from 'src/store';
import { showToast } from 'src/store/toast.slice';
import {
  ICustomAxiosError,
  IGetCampaign,
  IGetIgnoredCampaignLeadRequest,
  IGetIgnoredCampaignLeadResponse,
} from 'src/types';

export const useDeleteIgnoredCampaignLeads = (
  campaignId: number,
  getIgnoredLeadKeys: IGetIgnoredCampaignLeadRequest,
  options?: UseMutationOptions<void, ICustomAxiosError, string[]>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const campaignIgnoredLeadQueryKey = ['get-campaign-ignored-leads', campaignId, JSON.stringify(getIgnoredLeadKeys)];
  const campaignQueryKey = ['campaign', campaignId];

  const { mutate, isLoading, ...rest } = useMutation(
    ['delete-campaign-ignored-lead', campaignId],
    (entityUrns: string[]) => deleteCampaignIgnoredLeads(entityUrns, campaignId),
    {
      ...options,
      onMutate: async (entityUrns) => {
        options?.onMutate?.(entityUrns);
        await queryClient.cancelQueries(campaignIgnoredLeadQueryKey);
        await queryClient.cancelQueries(campaignQueryKey);
        dispatch(removeIgnoredSelectedLead(entityUrns));
        const prevLeadsData = queryClient.getQueryData<IGetIgnoredCampaignLeadResponse>(campaignIgnoredLeadQueryKey);
        const prevCampaignData = queryClient.getQueryData(campaignQueryKey);

        queryClient.setQueryData<IGetIgnoredCampaignLeadResponse | undefined>(campaignIgnoredLeadQueryKey, (data) => {
          if (data) {
            return {
              ...data,
              total: data.total - entityUrns.length,
              leads: data?.leads?.filter((lead) => !entityUrns.includes(lead.personKey)),
            };
          }

          return data;
        });

        queryClient.setQueryData<IGetCampaign | undefined>(campaignQueryKey, (data) => {
          if (data && data.ignoredLeadsCount === entityUrns.length) {
            return {
              ...data,
              ignoredLeadsCount: 0,
            };
          }

          return data;
        });

        if (prevLeadsData?.total === entityUrns.length) {
          navigate(`/campaign/details/leads/${campaignId}`, { state: { refetch: false } });
        }

        return { prevLeadsData, prevCampaignData };
      },
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        await queryClient.invalidateQueries(['get-campaign-ignored-leads', campaignId], {
          exact: false,
          refetchInactive: true,
        });
        await queryClient.invalidateQueries(['get-campaign-leads', campaignId], {
          exact: false,
          refetchInactive: true,
        });
        await queryClient.invalidateQueries(['campaign', campaignId], {
          exact: false,
          refetchInactive: true,
        });
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueryData(campaignIgnoredLeadQueryKey, context?.prevLeadsData);
        queryClient.setQueryData(campaignQueryKey, context?.prevCampaignData);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { deleteCampaignLead: mutate, isDeleteCampaignLoading: isLoading, ...rest };
};
