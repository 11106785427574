import classNames from 'classnames';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { HeaderButton, Input, Typography } from 'src/components';
import { CampaignType, Features } from 'src/enums';
import { validateLinkedinSalesNavigatorUrl, validateLinkedInUrl } from 'src/helpers';
import { useCompanyTheme, useLocationState, useSingleCampaignProps, useTeamPlan } from 'src/hooks';
import {
  useAddMoreLeadsCampaign,
  useCreateCampaignAudience,
  useGetCampaign,
  useUpdateCampaignAudience,
} from 'src/reactQueries';
import { transformCampaignRequestBody } from 'src/transformers';
import { ICampaignAudienceRequest, ILocationStateSingleCampaign } from 'src/types';
import { ControlledCheckbox, ControlledSlider } from '../../_components';
import { CampaignsSteps } from '../../CampaignSteps';

const Form = styled.form`
  width: 900px;
  padding: 30px 15px 80px;
`;

const StyledInput = styled(Input)`
  padding: 5px 10px;
  height: auto;
  border-width: 2px;
`;

const labelStyle = css`
  color: var(--text-primary);
  font-weight: 400 !important;
`;

const PreviewResult = styled.a`
  color: ${({ theme }) => theme.palette.lightGray.main};
  font-size: 15px;
  letter-spacing: 1px;
  padding-bottom: 3px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.lightGray.light};
  transition: 0.2s;
  margin-left: auto;

  &:hover {
    color: ${({ theme }) => theme.palette.info.main};
  }
`;

export const LinkedinUrl = ({ isLinkedinOnly }: { isLinkedinOnly: boolean }) => {
  const { initialCampaignId } = useLocationState<ILocationStateSingleCampaign>();
  const { isAddMoreLeads, isEdit, campaignId, campaign, category } = useSingleCampaignProps();
  const { checkFeature } = useTeamPlan();
  const isLinkedInSNUrlAllowed = checkFeature(Features.linkedinSNUrlCampaign);

  const { shortCompanyName } = useCompanyTheme();
  const { campaign: initialCampaign } = useGetCampaign(initialCampaignId, {
    enabled: !!campaignId && !!initialCampaignId && !isAddMoreLeads,
  });

  const {
    register,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<ICampaignAudienceRequest>({
    mode: 'onChange',
    defaultValues: transformCampaignRequestBody({
      category,
      campaignType: CampaignType.LINKEDIN_SEARCH,
    }),
  });
  const watchSearchUrl = watch('searchUrl');
  const watchCampaignType = watch('campaignType');
  const watchMaxSearchAmount = watch('maxSearchAmount');

  const withEventAttending = watchSearchUrl.includes('?eventAttending=');
  const isSNSearch = validateLinkedinSalesNavigatorUrl(watchSearchUrl) === true;
  const type = isSNSearch ? CampaignType.LINKEDIN_SN_SEARCH : CampaignType.LINKEDIN_SEARCH;

  useEffect(() => {
    if (type === CampaignType.LINKEDIN_SEARCH && watchMaxSearchAmount > 1000) {
      setValue('maxSearchAmount', 1000);
    }

    setValue('campaignType', type);
  }, [type]);

  useEffect(() => {
    if (initialCampaignId && initialCampaign) {
      reset(transformCampaignRequestBody(initialCampaign));
    } else if (!isAddMoreLeads && campaign) {
      reset(transformCampaignRequestBody(campaign));
    }
  }, [campaign, initialCampaign]);

  const { addMoreLeads, isAddMoreLeadsLoading } = useAddMoreLeadsCampaign(campaignId);
  const { createCampaignAudience, isCreateLoading } = useCreateCampaignAudience();
  const { updateCampaignAudience, isUpdateLoading } = useUpdateCampaignAudience(campaignId);

  const submit = ({ searchUrl = '', ...values }: ICampaignAudienceRequest) => {
    const isSNSearch = validateLinkedinSalesNavigatorUrl(searchUrl) === true;
    const type = isSNSearch ? CampaignType.LINKEDIN_SN_SEARCH : CampaignType.LINKEDIN_SEARCH;

    const body = transformCampaignRequestBody({
      ...values,
      campaignType: type,
      includeMessageOnly: true,
      isLinkedinOnly: isLinkedinOnly,
      openLinkOnly: !withEventAttending && values?.openLinkOnly,
      searchUrl,
      isLinkedinSearchUrl: !isSNSearch,
    });

    if (isAddMoreLeads) {
      return addMoreLeads({
        ...body,
        searchType: type,
        campaignId,
      });
    }

    if (isEdit) {
      return updateCampaignAudience(body);
    }

    return createCampaignAudience(body);
  };

  return (
    <>
      <CampaignsSteps>
        <HeaderButton
          id="next-step-btn"
          data-intercom-target="next-step-btn"
          size={{ width: '180px' }}
          processing={isAddMoreLeadsLoading || isCreateLoading || isUpdateLoading}
          disabled={isAddMoreLeadsLoading}
          onClick={handleSubmit(submit)}
        >
          Next
        </HeaderButton>
      </CampaignsSteps>
      <Box bgcolor="light.light">
        <Form onSubmit={handleSubmit(submit)}>
          <StyledInput
            className={classNames({ error: errors?.searchUrl })}
            data-intercom-target="search-input"
            id="search-input"
            register={register}
            parameters={{
              validate: (value) => validateLinkedInUrl(value, isLinkedInSNUrlAllowed),
              required: true,
            }}
            name="searchUrl"
            label="Paste here your Linkedin search URL."
            labelStyle={labelStyle}
            placeholder={`You can use basic linkedin search URL ${
              isLinkedInSNUrlAllowed ? 'or sales navigator URL' : ''
            }`}
          />

          <Box display="flex" justifyContent="space-between" mt="5px">
            <Box>
              {errors?.searchUrl && (
                <Typography color="error.light" fontSize="16px">
                  {errors?.searchUrl?.message}
                </Typography>
              )}
              {watchSearchUrl.includes('?recentSearchId=') && (
                <>
                  <Typography color="error.light" fontSize="16px">
                    Searches from recent history could not be used on {shortCompanyName}.
                  </Typography>
                  <Typography color="error.light" fontSize="16px">
                    Please save the search and use it from Saved Searches.
                  </Typography>
                </>
              )}
            </Box>

            <Box display="flex" flexDirection="column" gap="5px">
              <PreviewResult
                href="https://www.linkedin.com/search/results/people/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Go to Linkedin search <FontAwesomeIcon icon={faArrowRight} />
              </PreviewResult>
              {isLinkedInSNUrlAllowed && (
                <PreviewResult
                  href="https://www.linkedin.com/sales/search/people"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Go to sales navigator <FontAwesomeIcon icon={faArrowRight} />
                </PreviewResult>
              )}
            </Box>
          </Box>

          <ControlledSlider
            name="maxSearchAmount"
            label="Maximum amount of leads:"
            max={watchCampaignType === CampaignType.LINKEDIN_SEARCH ? 1000 : 2500}
            control={control}
          />

          <ControlledCheckbox name="excludeNoPhotos" label="Exclude profiles without photos." control={control} />
          <ControlledCheckbox name="noFirstConnections" label="Exclude 1st degree connections." control={control} />
          <ControlledCheckbox name="premiumOnly" label="Premium users only." control={control} />
          {!withEventAttending && isSNSearch && (
            <ControlledCheckbox name="openLinkOnly" label="Open Link Profiles only." control={control} />
          )}
          <ControlledCheckbox name="retargetLeads" label="Include leads from other campaigns." control={control} />
        </Form>
      </Box>
    </>
  );
};
