import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { TemplateAvailability } from 'src/enums';
import { getContrastYIQ } from 'src/helpers';
import { Typography } from '../ui';

interface AvailabilitySelectorProps {
  isShared: boolean;
  onChange: (value: TemplateAvailability) => void;
}

const Wrapper = styled(Box)`
  height: 28px;
  width: 220px;
  min-width: 220px;
  display: flex;
  border-radius: 5px;
  border: 1px solid rgba(210, 222, 224, 0.5);
  background-color: #ffffff;
  overflow: hidden;
`;

const Item = styled(Box)<{ chosen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 28px;
  background-color: ${({ theme, chosen }) => (chosen ? theme.palette.accent[500] : `${theme.palette.gray[50]}80`)};
  color: ${({ theme, chosen }) => (chosen ? getContrastYIQ(theme.palette.accent[500]) : '#000000')};
  transition: 0.2s;
  cursor: pointer;
  box-sizing: border-box;

  & > p {
    color: ${({ theme, chosen }) => (chosen ? getContrastYIQ(theme.palette.accent[500]) : '#000000')};
  }
`;

export const AvailabilitySelector = ({ isShared, onChange }: AvailabilitySelectorProps) => {
  return (
    <Wrapper>
      <Item chosen={!isShared} onClick={() => onChange(TemplateAvailability.personal)}>
        <Typography>Personal</Typography>
      </Item>
      <Item chosen={isShared} onClick={() => onChange(TemplateAvailability.team)}>
        <Typography>Team</Typography>
      </Item>
    </Wrapper>
  );
};
