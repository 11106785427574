import { Controller, useForm } from 'react-hook-form';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Select as SelectMui } from '@mui/material';

import { Button, MenuItem, Tooltip, Typography } from 'src/components';
import { DAYS, HOURS, MAX_RECOMMENDED_HOURS } from 'src/constants';
import { convertTime, resolveHours } from 'src/helpers';
import { useCompanyTheme } from 'src/hooks';
import { useGetUserPreferences, useUpdateWorkHours } from 'src/reactQueries';
import { Divider, Subtitle, Title } from '../_components';

const DateSelect = styled(SelectMui)`
  width: 25%;
  height: 33px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.primary};
  background-color: ${({ theme }) => theme.palette.light.light};
  border-color: ${({ theme }) => theme.palette.light.light} !important;
  font-family: 'ProximaSoft', sans-serif;
  border-radius: 4px;

  & fieldset {
    border: none;
  }
`;

const DateMenuItem = styled(MenuItem)`
  height: 25px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const DayTitle = styled(Subtitle)`
  color: ${({ theme }) => theme.palette.primary.light};
`;

const SCHEDULER_DEFAULT_VALUES = { day: 1, startHour: 9, endHour: 17 };

interface IAddDayForm {
  day: number;
  startHour: number;
  endHour: number;
}

export const Scheduler = () => {
  const { palette } = useTheme();
  const { shortCompanyName } = useCompanyTheme();

  const { userPreferences } = useGetUserPreferences();
  const { updateWorkHours } = useUpdateWorkHours();
  const { scheduled = [] } = userPreferences?.operatingHours ?? {};
  const { control, handleSubmit, watch, setValue } = useForm<IAddDayForm>({
    defaultValues: SCHEDULER_DEFAULT_VALUES,
  });
  const endHour = watch('endHour');
  const startHour = watch('startHour');

  const { warnDays } = resolveHours(scheduled);

  const showErrorMessage =
    Math.abs(startHour - endHour) > MAX_RECOMMENDED_HOURS || warnDays.some((hourInDay) => hourInDay);

  const onAddHours = (values: IAddDayForm) => {
    updateWorkHours({
      scheduled: [
        ...scheduled,
        { day: String(values.day), endHour: String(values.endHour), startHour: String(values.startHour) },
      ],
    });
  };

  const onRemoveDay = (dayIndex: number) => {
    updateWorkHours({
      scheduled: scheduled.filter((_value, index) => dayIndex !== index),
    });
  };

  return (
    <Box>
      <Title>Scheduler</Title>
      {showErrorMessage && (
        <Box p="10px 15px" mb="5px" bgcolor={palette.warning.main} borderRadius="5px">
          <Typography textAlign="center">We recommend you assign a maximum of 8 hours per day</Typography>
        </Box>
      )}
      <Typography>
        <strong color={palette.primary.wlLight}>Note:</strong> On unspecified days {shortCompanyName} won't run.
      </Typography>

      {scheduled.map((item, index) => {
        const startTime = Number(item.startHour);
        const endTime = Number(item.endHour);

        const showTooltip = warnDays[Number(item.day)];

        return (
          <Box key={index} display="flex" alignItems="center" mt="12px">
            <DayTitle width="12%">{DAYS[Number(item.day)]}</DayTitle>
            <Typography width="28%">
              {convertTime(startTime)} - {convertTime(endTime)}
            </Typography>
            <Button variant="gray" onClick={() => onRemoveDay(index)}>
              Remove
            </Button>
            {showTooltip && (
              <Box ml="15px">
                <Tooltip title="We recommend you assign a maximum of 8 hours per day" placement="bottom">
                  <FontAwesomeIcon icon={faExclamationTriangle} size="lg" color={palette.warning.main} />
                </Tooltip>
              </Box>
            )}
          </Box>
        );
      })}

      <Divider mt="30px" mb="20px" />

      <Subtitle>Add hours</Subtitle>
      <Box display="flex" gap="30px" mt="10px">
        <Controller
          name="day"
          control={control}
          render={({ field }) => {
            return (
              <DateSelect {...field}>
                {DAYS.map((day, i) => (
                  <DateMenuItem key={day} value={i}>
                    {day}
                  </DateMenuItem>
                ))}
              </DateSelect>
            );
          }}
        />
        <Controller
          name="startHour"
          control={control}
          render={({ field }) => (
            <DateSelect
              {...field}
              onChange={(event) => {
                field.onChange(event);
                const value = event.target?.value;
                if (value === 100) {
                  setValue('endHour', 100);
                }
              }}
            >
              <DateMenuItem value={100}>Don't run</DateMenuItem>
              <DateMenuItem value={0}>12 am (midnight)</DateMenuItem>
              {HOURS.map(
                (hour, index) =>
                  hour &&
                  index < (endHour || 24) && (
                    <DateMenuItem key={index} value={index}>
                      {hour}
                    </DateMenuItem>
                  ),
              )}
            </DateSelect>
          )}
        />
        <Controller
          name="endHour"
          control={control}
          render={({ field }) => (
            <DateSelect
              {...field}
              onChange={(event) => {
                field.onChange(event);
                const value = event.target?.value;
                if (value === 100) {
                  setValue('startHour', 100);
                }
              }}
            >
              <DateMenuItem value={100}>Don't run</DateMenuItem>
              {HOURS.map(
                (hour, index) =>
                  hour &&
                  index > startHour && (
                    <DateMenuItem key={index} value={index}>
                      {hour}
                    </DateMenuItem>
                  ),
              )}
              <DateMenuItem value={24}>11:59 pm (midnight)</DateMenuItem>
            </DateSelect>
          )}
        />

        <Button onClick={handleSubmit(onAddHours)}>Add Hours</Button>
      </Box>
    </Box>
  );
};
