import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import { Typography } from 'src/components';
import { CampaignActions } from 'src/enums';
import { updateActivityParams } from 'src/store';
import { Wrapper } from '.';

const CardWrapper = styled(Wrapper)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  transition: background 0.4s ease;

  &:hover {
    background-color: #eee;
  }
`;

interface ICountCardProps {
  count?: number;
  description: string;
  to: string;
  actions?: CampaignActions[];
}

export const CountCard = ({ description, count = 0, to, actions, ...rest }: ICountCardProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onCardClick = () => {
    dispatch(updateActivityParams({ filters: actions }));
    navigate(to);
  };

  return (
    <CardWrapper {...rest} onClick={onCardClick}>
      <Typography fontSize="50px" lineHeight="50px" semibold color="primary.wlLight">
        {count}
      </Typography>
      <Typography color="gray" mt="10px">
        {description}
      </Typography>
    </CardWrapper>
  );
};
