import { ChangeEvent, CSSProperties, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import styled from '@emotion/styled';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { Box, useTheme } from '@mui/material';

import { ActionButton, Attachment, HiddenFileInput, Tooltip } from 'src/components';
import { IModalChooseTemplateProps } from 'src/components/Modal';
import { DISALLOWED_INBOX_FILES, MAX_FILE_NUM_LIMIT, MAX_FILE_NUM_LIMIT_TRIAL, PLAN_TOOLTIPS } from 'src/constants';
import { Features, ModalTypes } from 'src/enums';
import { loadFileByUrl } from 'src/helpers';
import { useTeamPlan } from 'src/hooks';
import { useGetLeadInfo, useSendLIAttachmentMessage, useSendLIMessage } from 'src/reactQueries';
import { openModal, showToast } from 'src/store';
import { ILIConversation } from 'src/types';

const TextArea = styled.textarea`
  resize: none;
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.palette.light.light};
  outline: none;
  font-size: 14px;
  line-height: 14px;
  height: 70px;
  border-radius: 6px;

  &:disabled {
    background-color: #ffffff;
    cursor: not-allowed;
  }
`;

const ButtonWrapper = styled(Box)<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'default')};
`;

const Button = styled.button<{ backgroundColor?: string; hoverColor?: string }>`
  padding: 5px 20px;
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.palette.light.dark};
  background-color: ${({ disabled, theme, backgroundColor }) =>
    backgroundColor || (disabled ? theme.palette.gray[100] : '#2B5EDB')};
  border-radius: 4px;
  color: ${({ color, disabled, theme }) => color || (disabled ? theme.palette.gray[300] : '#FFFFFF')};
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: ${({ theme, hoverColor }) => hoverColor || theme.palette.light.light};
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const MAX_ATTACHMENT_SIZE = 10 * 1024 * 1024; // 10mb
const MAX_LI_MESSAGE_LENGTH = 8000;
const MAX_INMAIL_MESSAGE_LENGTH = 1900;

interface SendMessageProps {
  conversationData: ILIConversation;
  isInmailFilter: boolean;
  isSponsoredInMail: boolean;
  sendMessagesStyle?: CSSProperties;
}

export const SendMessage = ({
  conversationData,
  isInmailFilter,
  isSponsoredInMail,
  sendMessagesStyle,
}: SendMessageProps) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();

  const [msgValue, setMsgValue] = useState('');
  const [files, setFiles] = useState<Array<{ file: File; fileSrc: string; fileId: string }>>([]);

  const { isTrialStarted, isTrial, checkFeature } = useTeamPlan();

  const entityUrn = conversationData?.entityUrn || '';
  const objectUrn = conversationData?.objectUrn || '';
  const conversationId = conversationData?.conversationId || '';

  const MAX_ATTACHMENTS_NUM = isTrialStarted ? MAX_FILE_NUM_LIMIT_TRIAL : MAX_FILE_NUM_LIMIT;
  const MAX_ATTACHMENTS_ERROR = `Maximum ${MAX_ATTACHMENTS_NUM} file${
    MAX_ATTACHMENTS_NUM > 1 ? 's' : ''
  } can be attached`;

  const { leadInfo } = useGetLeadInfo(entityUrn, objectUrn);

  const { sendLIMessage, isSendLiLoading } = useSendLIMessage({ conversationId, entityUrn });
  const { sendLIAttachmentMessage, isSendLiAttachmentLoading } = useSendLIAttachmentMessage({
    conversationId,
    entityUrn,
  });

  useEffect(() => {
    setMsgValue('');
    setFiles([]);
  }, [conversationData?.conversationId]);

  const send = () => {
    if (files.length) {
      if (files.length > MAX_ATTACHMENTS_NUM) {
        return dispatch(
          showToast({
            message: MAX_ATTACHMENTS_ERROR,
            type: 'info',
            autoCloseTime: 3000,
          }),
        );
      }
      sendLIAttachmentMessage({ message: msgValue, files });
    } else {
      sendLIMessage({ message: msgValue, isGroup: !!conversationData?.isGroup });
    }
    setMsgValue('');
    setFiles([]);
  };

  const addAttachment = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget?.files) {
      if (isTrial && files.length === 1) {
        return dispatch(
          showToast({
            message: 'Upgrade your subscription to PROFESSIONAL plans to attach more files',
            type: 'info',
            autoCloseTime: 3000,
          }),
        );
      }

      if (files.length >= MAX_ATTACHMENTS_NUM) {
        return dispatch(
          showToast({
            message: MAX_ATTACHMENTS_ERROR,
            type: 'info',
            autoCloseTime: 3000,
          }),
        );
      }

      const file = e.currentTarget.files[0];
      if (file) {
        if (file.size > MAX_ATTACHMENT_SIZE) {
          return dispatch(
            showToast({
              message: 'You cannot upload file of size more than 10 Mb',
              type: 'error',
              autoCloseTime: 3000,
            }),
          );
        }

        if (DISALLOWED_INBOX_FILES.includes(file.type)) {
          return dispatch(
            showToast({
              message: 'Download error. Try again.',
              type: 'error',
              autoCloseTime: 3000,
            }),
          );
        }

        dispatch(
          showToast({
            type: 'info',
            message: 'Uploading file! Please wait!',
            autoCloseTime: 3000,
          }),
        );

        const reader = new FileReader();
        reader.onload = (ev) => {
          const fileSrc = ev.target?.result as string;
          setFiles((prevFiles) => [...prevFiles, { file, fileSrc, fileId: uuid() }]);

          toast.dismiss();

          dispatch(
            showToast({
              type: 'success',
              message: 'File uploaded!',
              autoCloseTime: 3000,
              delay: 500,
            }),
          );

          // clearing input to be able to load the same file again
          e.target.value = '';
        };

        reader.readAsDataURL(file);
      }
    }
  };

  const removeFile = (fileId: string) => {
    setFiles((files) => files.filter((file) => file.fileId !== fileId));
  };

  const openTemplateSelectorModal = () => {
    const snippetsData = {
      first_name: leadInfo?.person?.firstName || conversationData.firstName,
      last_name: leadInfo?.person?.lastName || conversationData.lastName,
      company: leadInfo?.person?.currentEmployer,
      position: leadInfo?.person?.currentTitle || conversationData.headline,
      industry: leadInfo?.person?.industryName,
    };

    dispatch(
      openModal({
        type: ModalTypes.CHOOSE_TEMPLATE,
        closable: false,
        params: {
          noTemplatesMessage: "You don't have Inbox templates created",
          preselectCategory: 'Inbox',
          onConfirm: async (message, _subject, attachments = []) => {
            setMsgValue(
              Object.entries(snippetsData).reduce((msg, [snippet, data]) => {
                return msg.replaceAll(`{{${snippet}}}`, data || '');
              }, message),
            );

            const files = [] as Array<{ file: File; fileSrc: string; fileId: string }>;

            if (attachments) {
              for await (const attachment of attachments) {
                const file = await loadFileByUrl(attachment.file_uri, attachment.file_name, attachment.file_type);
                files.push({ file, fileSrc: attachment.file_uri, fileId: uuid() });
              }
            }

            setFiles((prevFiles) => [...prevFiles, ...files]);
          },
        } as IModalChooseTemplateProps,
      }),
    );
  };

  return (
    <>
      <TextArea
        maxLength={isInmailFilter ? MAX_INMAIL_MESSAGE_LENGTH : MAX_LI_MESSAGE_LENGTH}
        disabled={leadInfo?.userNetwork.isExcluded || isSponsoredInMail}
        placeholder="Write a message..."
        value={msgValue}
        onChange={(e) => setMsgValue(e.target.value)}
      />
      <Box p="10px" sx={sendMessagesStyle} display="flex" gap="12px">
        {!leadInfo?.userNetwork.isExcluded && !isSponsoredInMail && (
          <>
            <HiddenFileInput
              id="attachment"
              type="file"
              accept="image/*,.ai,.psd,.pdf,.doc,.docx,.csv,.zip,.rar,.ppt,.pptx,.pps,.ppsx,.odt,.rtf,.xls,.xlsx,.txt,.pub,.html,.7z,.eml"
              onChange={addAttachment}
              disabled={!checkFeature(Features.inboxAttachments)}
            />
            <label htmlFor="attachment">
              <ActionButton
                icon={faPaperclip}
                color={palette.gray[800]}
                borderRadius="4px"
                disabled={!checkFeature(Features.inboxAttachments)}
                tooltip={!checkFeature(Features.inboxAttachments) && PLAN_TOOLTIPS.professional}
              />
            </label>

            <Tooltip title={!checkFeature(Features.inboxTemplate) && PLAN_TOOLTIPS.any}>
              <ButtonWrapper>
                <Button
                  disabled={!checkFeature(Features.inboxTemplate)}
                  onClick={openTemplateSelectorModal}
                  color={palette.gray[800]}
                  backgroundColor="#ffffff"
                >
                  Template
                </Button>
              </ButtonWrapper>
            </Tooltip>
          </>
        )}
        <Tooltip
          title={
            (leadInfo?.userNetwork.isExcluded && "You can't send a message to the excluded connection.") ||
            (isSponsoredInMail &&
              "Reply for sponsored message couldn't be sent, please check you LinkedIn conversation directly.")
          }
        >
          <ButtonWrapper
            disabled={(!msgValue && !files.length) || leadInfo?.userNetwork.isExcluded || isSponsoredInMail}
          >
            <Button
              hoverColor={palette.brand[300]}
              disabled={
                isSendLiLoading ||
                isSendLiAttachmentLoading ||
                (!msgValue && !files.length) ||
                leadInfo?.userNetwork.isExcluded ||
                isSponsoredInMail
              }
              onClick={send}
            >
              Send
            </Button>
          </ButtonWrapper>
        </Tooltip>
      </Box>
      <Box display="flex" flexWrap="wrap" px="10px" sx={sendMessagesStyle}>
        {!!files?.length &&
          files.map((file) => {
            return (
              <Attachment key={file.fileId} fileId={file.fileId} name={file.file.name} onDeleteClick={removeFile} />
            );
          })}
      </Box>
    </>
  );
};
