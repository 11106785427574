import { useQuery, UseQueryOptions } from 'react-query';
import { useTheme } from '@emotion/react';

import { getTags } from 'src/api';
import { ICustomAxiosError, IGetTagsRequest, IGetTagsResponse } from 'src/types';

export const useGetTags = (params: IGetTagsRequest, options?: UseQueryOptions<IGetTagsResponse, ICustomAxiosError>) => {
  const { sortField, sortOrder, page, perPage } = params;
  const { palette } = useTheme();

  const { data, isLoading } = useQuery<IGetTagsResponse, ICustomAxiosError>(
    ['get-tags', sortField, sortOrder, page, perPage],
    () => getTags(params),
    {
      refetchOnMount: true,
      ...options,
    },
  );

  const normalizeTags = data?.tags?.map((tag) => ({ ...tag, color: tag?.color || palette.gray[600] })) ?? [];

  return { data: { ...data, tags: normalizeTags }, isLoading };
};
