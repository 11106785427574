import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import campaignSummaryIcon from 'src/assets/icons/campaign-summary-icon.svg';
import { Attachment, Button } from 'src/components';
import { TEMPLATE_AVAILABILITY_OPTIONS } from 'src/constants';
import { closeModal } from 'src/store/modal.slice';
import { IEditTemplateState, IModalProps, ITemplate } from 'src/types';
import { ModalBody, ModalFooter } from '../_components';

const BodyInner = styled(Box)`
  background-color: white !important;
  border-radius: 5px;
  padding: 42px 40px;
`;

const Title = styled('h5')`
  margin-left: 10px;
  margin-bottom: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.wlLight};
`;

const Label = styled('b')`
  color: ${({ theme }) => theme.palette.violet.dark};
  font-size: 14px;
  line-height: 24px;
`;

const Text = styled('p')`
  color: ${({ theme }) => theme.palette.violet.dark};
  font-size: 14px;
  font-family: 'ProximaSoft', sans-serif !important;
  overflow-y: auto;
  line-height: 22px;
  min-width: 100%;
  max-width: 100%;
  max-height: 280px;
  white-space: pre-wrap;
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid #fff;
    background-color: rgba(0, 0, 0, 0.5);
  }
  &::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    -webkit-appearance: none;
  }
`;

interface IViewTemplateParams {
  template: ITemplate;
  categoryLabel: string;
  canClone?: boolean;
  canEdit?: boolean;
}

export const ModalViewTemplate = ({ onCancel, params }: IModalProps<IViewTemplateParams>) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { template, categoryLabel, canEdit = true, canClone = true } = params;
  const { content, isAvailableForTeam, name, categoryId, id } = template;
  const { message, subject = '', attachments } = content.sequence[0];

  const closeHandler = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  const editCloneHandler = (isEdit: boolean) => {
    const state: IEditTemplateState = {
      name: isEdit ? name : `${name} (clone)`,
      id,
      category: {
        label: categoryLabel,
        value: String(categoryId),
      },
      availability: isAvailableForTeam ? TEMPLATE_AVAILABILITY_OPTIONS[1] : TEMPLATE_AVAILABILITY_OPTIONS[0],
      message,
      subject: subject,
      isSubjectShown: !!subject,
      attachments: attachments,
      isEdit: isEdit,
    };

    dispatch(closeModal());
    navigate(`/templates/new`, {
      state,
    });
  };

  const shortAttachments = attachments && attachments.slice(0, 6);

  return (
    <>
      <ModalBody>
        <BodyInner>
          <Box display="flex" alignItems="center">
            <img width={20} height={20} src={campaignSummaryIcon} alt="campaign summary icon" />
            <Title>Template Message</Title>
          </Box>
          {subject && (
            <Box mt="22px">
              <Label>Subject:</Label>
              <Text>{subject}</Text>
            </Box>
          )}
          {message && (
            <Box mt="22px">
              <Label>Message:</Label>
              <Text>{message}</Text>
            </Box>
          )}
          {!!shortAttachments?.length && (
            <Box mt="22px">
              <Label>Attachments:</Label>
              <Box width="100%" display="flex" flexWrap="wrap">
                {shortAttachments.map(({ file_name, file_uri }) => (
                  <Attachment key={file_uri} name={file_name} />
                ))}
              </Box>
            </Box>
          )}
        </BodyInner>
      </ModalBody>

      <ModalFooter>
        <Button variant="gray" onClick={closeHandler}>
          Cancel
        </Button>
        <Box display="flex" gap="25px">
          {canClone && <Button onClick={() => editCloneHandler(false)}>Clone</Button>}
          {canEdit && <Button onClick={() => editCloneHandler(true)}>Edit</Button>}
        </Box>
      </ModalFooter>
    </>
  );
};
