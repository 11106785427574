import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { TeamUserRole } from 'src/enums';
import { useAppSelector } from 'src/hooks';
import { useGetBillingInfo } from 'src/reactQueries';
import { BillingHistory } from './BillingHistory';
import { ManageSubscription } from './ManageSubscription';
import { SubscriptionDetails } from './SubscriptionDetails';
import { UnpauseSubscription } from './UnpauseSubscription';

export const BillingInformation = () => {
  const team = useAppSelector((state) => state.team.data);

  const { billingInfo } = useGetBillingInfo();

  if (team?.teamUser.role !== TeamUserRole.OWNER) {
    return (
      <Typography color="#A0A0A0" textAlign="center" mt="50px">
        {team?.isSubscriptionPaused ? (
          <>
            Your team <strong>{team.name}</strong>, has no longer an active subscription. Please ask the team owner to
            renew your subscription.
          </>
        ) : (
          'Your user does not have access to billing information.'
        )}
      </Typography>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap="20px" px="15px" pt="20px" pb="80px">
      {billingInfo?.isSubscriptionPaused ? <UnpauseSubscription /> : <SubscriptionDetails />}

      <BillingHistory />

      {!billingInfo?.isSubscriptionPaused && <ManageSubscription />}
    </Box>
  );
};
