import dayjs from 'dayjs';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { Checkbox, Typography } from 'src/components';
import { useGetBillingInfo } from 'src/reactQueries';

interface IConfirmCancellingProps {
  setConfirmDisabled: Dispatch<SetStateAction<boolean>>;
}

export const ConfirmCancelling = ({ setConfirmDisabled }: IConfirmCancellingProps) => {
  const [firstChecked, setFirstChecked] = useState(false);
  const [secondChecked, setSecondChecked] = useState(false);
  const [thirdChecked, setThirdChecked] = useState(false);

  const { billingInfo } = useGetBillingInfo();

  useEffect(() => {
    if (firstChecked && secondChecked && thirdChecked) {
      setConfirmDisabled(false);
    } else {
      setConfirmDisabled(true);
    }
  }, [firstChecked, secondChecked, thirdChecked]);

  return (
    <Box py="30px" px="40px" bgcolor="light.light">
      <Typography color="violet.dark" mb="20px">
        All of your campaigns, connections, leads, history, notes, tags, etc will be deleted. It won’t be possible to
        recover any of your data after that period and your account will stop functioning.
      </Typography>

      <Box display="flex" alignItems="center" mb="25px">
        <Checkbox type="checkbox" checked={firstChecked} onChange={(e) => setFirstChecked(e.target.checked)} />
        <Typography color="violet.dark" ml="10px">
          I understand that my account will be deleted including and all the assets I've created including campaigns,
          CRM, tags and actions.
        </Typography>
      </Box>

      <Box display="flex" alignItems="center" mb="25px">
        <Checkbox type="checkbox" checked={secondChecked} onChange={(e) => setSecondChecked(e.target.checked)} />
        <Typography color="violet.dark" ml="10px">
          I understand that I can re-activate my account at any time after{' '}
          {billingInfo?.stripeSubscriptionObject?.current_period_end &&
            ` on ${dayjs.unix(billingInfo?.stripeSubscriptionObject?.current_period_end).format('MM/DD/YYYY')}`}
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        <Checkbox type="checkbox" checked={thirdChecked} onChange={(e) => setThirdChecked(e.target.checked)} />
        <Typography color="violet.dark" ml="10px">
          All of your campaigns, connections, leads, history, notes, tags, etc will be deleted. It won’t be possible to
          recover any of your data after that period and your account will stop functioning.
        </Typography>
      </Box>
    </Box>
  );
};
