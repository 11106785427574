import { Features, Plan, PlanVersions } from '../enums';

export const FEATURES_AVAILABILITY = {
  [Features.referAndSave]: [
    Plan.STARTER,
    Plan.INDIVIDUAL,
    Plan.PERSONAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.whiteLabel]: [Plan.ENTERPRISE, Plan.AGENCY],
  [Features.exportTeamStats]: [Plan.ENTERPRISE, Plan.AGENCY],
  [Features.teamStats]: [
    Plan.TRIAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.impersonate]: [
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.manageTeam]: [
    Plan.TRIAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.teamMembers]: [
    Plan.TRIAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.teamGroups]: [
    Plan.TRIAL,
    Plan.ENTERPRISE,
    Plan.AGENCY,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
  ],
  [Features.teamGroup]: [Plan.ENTERPRISE, Plan.AGENCY],
  [Features.teamSettings]: [
    Plan.TRIAL,
    Plan.ENTERPRISE,
    Plan.AGENCY,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
  ],
  [Features.zappierAndWebhook]: [
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.integrations]: [
    Plan.TRIAL,
    Plan.STARTER,
    Plan.PERSONAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.SMTPIntegration]: [`${Plan.BUSINESS}-${PlanVersions.v1}`, Plan.ENTERPRISE, Plan.AGENCY],
  [Features.schedulePosts]: [
    Plan.TRIAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.trendCreation]: [
    Plan.TRIAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.templateCreation]: [
    Plan.TRIAL,
    Plan.INDIVIDUAL,
    Plan.PERSONAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.templatesTeamAndLibrary]: [
    Plan.TRIAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.templatesAttachments]: [
    Plan.TRIAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.templatesInSequenceStep]: [
    Plan.TRIAL,
    Plan.INDIVIDUAL,
    Plan.PERSONAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.inbox]: [
    Plan.TRIAL,
    Plan.INDIVIDUAL,
    Plan.PERSONAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.inmailInbox]: [
    Plan.TRIAL,
    Plan.PERSONAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.salesNavigatorInbox]: [
    Plan.TRIAL,
    Plan.PERSONAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.inboxAttachments]: [
    Plan.TRIAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.inboxTemplate]: [
    Plan.TRIAL,
    Plan.INDIVIDUAL,
    Plan.PERSONAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.leadsTags]: [
    Plan.TRIAL,
    Plan.INDIVIDUAL,
    Plan.PERSONAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.connectionNote]: [
    Plan.TRIAL,
    Plan.INDIVIDUAL,
    Plan.PERSONAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.connection]: [
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.connections]: [
    Plan.TRIAL,
    Plan.INDIVIDUAL,
    Plan.PERSONAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.leads]: [
    Plan.TRIAL,
    Plan.INDIVIDUAL,
    Plan.PERSONAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.exportCSV]: [
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.addMoreLeads]: [
    Plan.TRIAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.exportReplies]: [
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.directReply]: [
    Plan.TRIAL,
    Plan.INDIVIDUAL,
    Plan.PERSONAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.returnToCampaign]: [
    Plan.TRIAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.greetings]: [
    Plan.TRIAL,
    Plan.INDIVIDUAL,
    Plan.PERSONAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.repliesBulkAction]: [
    Plan.TRIAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.groupMessageCampaignStep]: [
    Plan.TRIAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.eventMessageCampaignStep]: [
    Plan.TRIAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.inMailCampaignStep]: [
    Plan.TRIAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.attachmentInSequenceStep]: [
    Plan.TRIAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.attachmentsUploadLimit]: [
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.CSVSnippets]: [
    Plan.TRIAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.twitterAndEmailCSVCampaign]: [
    Plan.TRIAL,
    Plan.PERSONAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.twitterAndEmail1stDegreeCampaign]: [
    Plan.TRIAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.linkedinSNUrlCampaign]: [
    Plan.TRIAL,
    Plan.PERSONAL,
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
  [Features.sequenceTemplates]: [
    `${Plan.BUSINESS}-${PlanVersions.v1}`,
    `${Plan.BUSINESS}-${PlanVersions.v2}`,
    Plan.ENTERPRISE,
    Plan.AGENCY,
  ],
};
