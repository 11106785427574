import { useMemo } from 'react';

import { FeatureFlagEnum } from 'src/enums';
import { useGetFeatureFlags } from 'src/reactQueries';

type ReturnType<T> = undefined extends T ? [boolean] : [T, boolean];

export const useFeatureFlag = <T>(flag: FeatureFlagEnum, enabledValue?: T, disabledValue?: T): ReturnType<T> => {
  const { featureFlags = [] } = useGetFeatureFlags();

  const { isEnabled, value } = useMemo(() => {
    const flagData = featureFlags.find((f) => f.uniqueKey === flag);

    if (flagData?.enabled) {
      return { isEnabled: true, value: enabledValue };
    }

    return { isEnabled: false, value: disabledValue };
  }, [featureFlags]);

  if (value) {
    return [value, isEnabled] as ReturnType<T>;
  }

  return [isEnabled] as ReturnType<T>;
};
