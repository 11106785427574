import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';

import googleIcon from 'src/assets/icons/google-icon.svg';
import { Typography } from 'src/components';
import { Button } from 'src/components/Buttons';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features } from 'src/enums';
import { useCompanyTheme, useTeamPlan } from 'src/hooks';
import { useDeleteGmail, useGetUsersEmail, usePostGmailSignIn } from 'src/reactQueries';
import { openModal } from 'src/store';
import { Accordion, AccordionDetails, AccordionSummary } from '../ui';
import { ProfileInfo } from './ProfileInfo';

export const SignInButton = styled(Button)<{ padding?: string }>`
  display: flex;
  gap: 15px;
  padding: ${({ padding }) => padding};
  justify-content: space-between;
  background: white;
  border-radius: 0;
  border: 1px solid ${({ theme }) => theme.palette.gray.main};
  align-items: center;
  cursor: pointer;

  &:hover {
    background: white;
  }
`;

export const GoogleForm = () => {
  const dispatch = useDispatch();
  const { palette } = useTheme();

  const { shortCompanyName } = useCompanyTheme();
  const { postGmailSingIn } = usePostGmailSignIn();
  const { checkFeature } = useTeamPlan();
  const isIntegrationAllowed = checkFeature(Features.integrations);

  const { deleteGmail, isDeleteGmailLoading } = useDeleteGmail();
  const { userEmail } = useGetUsersEmail();
  const { gmailEnabled, alias, email, name, pic } = userEmail || {};

  const loginWithGoogle = useGoogleLogin({
    onSuccess: ({ code }) => {
      postGmailSingIn(code);
    },
    scope: 'profile email https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly',
    flow: 'auth-code',
  });

  const onDisconnectClick = () =>
    dispatch(
      openModal({
        headerText: 'Are you sure you want to remove Email credentials ?',
        descriptionText:
          'This means that your Email Campaigns stop to working. Once another Email is connected leads who already got email from previous email box won’t receive any emails.',
        onConfirm: deleteGmail,
      }),
    );

  return (
    <Accordion expanded={gmailEnabled}>
      <AccordionSummary>
        <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
          {gmailEnabled ? (
            <Box display="flex" alignItems="center" gap="12px">
              <img width="20px" src={googleIcon} />
              <Typography semibold fontSize="20px" color="#8A8A8A">
                Gmail Authorized
              </Typography>
            </Box>
          ) : (
            <Typography semibold fontSize="20px" color="#000">
              Google Mail
            </Typography>
          )}

          {gmailEnabled ? (
            <Button processing={isDeleteGmailLoading} onClick={onDisconnectClick} variant="warning">
              Disconnect this Google account
            </Button>
          ) : (
            <SignInButton
              disabled={!isIntegrationAllowed}
              tooltip={{ title: !isIntegrationAllowed && PLAN_TOOLTIPS.professional }}
              onClick={loginWithGoogle}
              padding="10px 32px 10px 20px"
            >
              <img width="20px" src={googleIcon} />
              <Typography fontWeight="500" fontSize="15px" color="gray.dark">
                Sign in with Google
              </Typography>
            </SignInButton>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" gap="12px">
          <FontAwesomeIcon icon={faCircleCheck} color={palette.success.main} />
          <Typography>You have authorized your Gmail account for sending emails from {shortCompanyName}.</Typography>
        </Box>
        <ProfileInfo
          type="google"
          isEmailAlias={alias?.sendAsEmail !== email}
          avatar={pic}
          name={alias?.displayName || name}
          email={alias?.sendAsEmail || email}
        />
      </AccordionDetails>
    </Accordion>
  );
};
