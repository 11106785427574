import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { alpha, Box } from '@mui/material';

import clickImg from 'src/assets/img/click.svg';
import moneyBagImg from 'src/assets/img/money-bag.svg';
import sendImg from 'src/assets/img/send.svg';
import { Button, HeaderContainer, HeaderDescription, HeaderTitle, Input, Link, Typography } from 'src/components';
import { FeatureFlagEnum, Features } from 'src/enums';
import { useAppSelector, useCompanyTheme, useCopyToClipboard, useFeatureFlag, useTeamPlan } from 'src/hooks';
import { useGetUserReferral } from 'src/reactQueries';
import { Title, Wrapper } from './_components/ui';

const ReferralInput = styled(Input)`
  height: 34px;
  caret-color: transparent;
  background-color: #eee;
`;

const StepBox = styled(Box)`
  box-sizing: border-box;
  height: 200px;
  width: 200px;
  background-color: #fff;
  border: 2px solid ${({ theme }) => theme.palette.light.light};
  border-radius: 4px;
  padding: 40px 20px;
  color: ${({ theme }) => theme.palette.text.primary};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0 15px 1px ${({ theme }) => alpha(theme.palette.text.primary, 0.1)};

  & h1 {
    font-size: 82px;
  }

  & img {
    width: 65px;
    height: 65px;
    margin-left: 10px;
    transform: translateY(-5px);
  }
`;

export const ReferFriend = () => {
  const navigate = useNavigate();
  const { shortCompanyName } = useCompanyTheme();
  const { checkFeature } = useTeamPlan();

  const team = useAppSelector((state) => state.team.data);

  const { copy, isCopied } = useCopyToClipboard();

  const { userReferral } = useGetUserReferral();

  const [navigateValue] = useFeatureFlag(
    FeatureFlagEnum.updatedSubscriptionFlow,
    '/billing/subscription/plans',
    '/billing',
  );

  const { referralUrl = '', referralPromo = 0, referredBy, referredSubscribersNumber = 0 } = userReferral ?? {};
  const { hasPaidPlan } = team ?? {};
  const saved = Math.abs(Math.round(referralPromo / 100));

  const referralText = useMemo(() => {
    if (referredBy) {
      return referredSubscribersNumber
        ? `You save $10 in your next invoice because you were referred by ${referredBy.name}
           You also save ${saved} because you have referred ${referredSubscribersNumber} other subscribers.`
        : `You save $10 in your next invoice because you were referred by ${referredBy.name}.`;
    }

    if (referredSubscribersNumber) {
      return `You save ${saved} because you have referred ${referredSubscribersNumber} other subscribers.`;
    }

    return null;
  }, [referredBy, referredSubscribersNumber, saved]);

  const steps = [
    {
      img: sendImg,
      text: 'Share your referral link with friends or colleagues',
    },
    {
      img: clickImg,
      text: `They subscribe to ${shortCompanyName}`,
    },
    {
      img: moneyBagImg,
      text: 'Both of you get $10 off your invoice!',
    },
  ];

  const redirectToBilling = () => navigate(navigateValue);

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Refer & Save</HeaderTitle>
        <HeaderDescription>
          Earn rewards by sharing {shortCompanyName} with friends, clients, or colleagues! For every paid customer you
          refer, you’ll save $10 off your bill.
          <Link
            leftIcon
            underline
            external
            openNewTab
            to="https://meetalfred.com/help/en/articles/3338766-refer-a-friend-program-with-meet-alfred"
          >
            Learn how it works
          </Link>
        </HeaderDescription>
      </HeaderContainer>
      <Wrapper maxWidth="100%">
        <Title lineHeight="22px">
          Get rewarded for sharing {shortCompanyName} with a friend, client, or colleague.
        </Title>
        <Typography>Save $10 off your bill for every paid customer you refer to {shortCompanyName}.</Typography>

        {hasPaidPlan && checkFeature(Features.referAndSave) ? (
          <Box>
            <Title mt="20px">Referral code:</Title>
            <Box display="flex" alignItems="center">
              <Box width="300px" mr="20px">
                <ReferralInput value={referralUrl} name="referral_url" readOnly />
              </Box>
              <Button onClick={() => copy(referralUrl)}>Copy</Button>
              {isCopied && (
                <Typography color="violet.dark" fontStyle="italic" ml="10px">
                  Copied!
                </Typography>
              )}
            </Box>
          </Box>
        ) : (
          <Box>
            <Typography mb="5px">Please subscribe to {shortCompanyName} to get your referral link</Typography>
            <Button onClick={redirectToBilling}>Subscribe</Button>
          </Box>
        )}
        <Box display="flex" gap="20px" mt="40px" mb="20px">
          {steps.map(({ img, text }, index) => (
            <StepBox key={text}>
              <Box display="flex">
                <h1>{index + 1}</h1>
                <img src={img} alt="" />
              </Box>
              <Typography color="text.primary" textAlign="center" fontSize="16px" lineHeight="16px" mt="15px">
                {text}
              </Typography>
            </StepBox>
          ))}
        </Box>

        <Typography lineHeight="22px">
          Refer a friend, save $10. Refer 2, save $20 and so on until {shortCompanyName} FREE! Don’t forget, they also
          get $10 off their bill.
        </Typography>
        <Typography lineHeight="22px">
          You read that right. The more people you introduce to {shortCompanyName}, the more you save. Do something good
          for your business, and then pass on the savings.
        </Typography>
        <Title mt="20px">Current referral savings: ${saved}</Title>
        <Typography>{referralText}</Typography>
        <Box mt="10px">
          <Link
            variant="darkBlue"
            fontSize="16px"
            underline
            semibold
            external
            openNewTab
            to="https://meetalfred.com/refer-a-friend-terms"
          >
            Refer a Friend Terms
          </Link>
        </Box>
      </Wrapper>
    </>
  );
};
