import { useEffect } from 'react';

import { getLanguage } from 'src/helpers';
import { useGetUserAccount, useSetUserLanguage } from 'src/reactQueries';

export const useSyncUserLanguage = () => {
  const { userMe } = useGetUserAccount();
  const { setUserLanguage } = useSetUserLanguage();

  useEffect(() => {
    if (userMe && !userMe.language) {
      const language = getLanguage();

      if (language) {
        setUserLanguage(language);
      }
    }
  }, [userMe]);
};
