import { useMutation, UseMutationOptions } from 'react-query';

import { postLinkedInVerifyCode } from 'src/api';
import { ICustomAxiosError } from 'src/types';

export const usePostLinkedInVerifyCode = (
  options?: UseMutationOptions<
    { status: string; data?: { error: string } },
    ICustomAxiosError,
    { code: string; browserId: string }
  >,
) => {
  const { mutate, ...rest } = useMutation('post-linkedin-verify-code', postLinkedInVerifyCode, options);

  return {
    postLinkedInVerifyCode: mutate,
    ...rest,
  };
};
