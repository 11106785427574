import {
  IAddLeadsTagsRequest,
  ICampaignActivity,
  ICampaignZapierLeads,
  ICreateTagRequest,
  IGetIgnoredCampaignLeadRequest,
  IGetIgnoredCampaignLeadResponse,
  IGetLeadsRequest,
  IGetLeadsResponse,
  ILeadColumnsPosition,
  ILeadInfo,
  ILeadsPostParameters,
  ILeadsResponse,
  ILeadsSearchFields,
  ILeadsStatuses,
  ILeadsUpdateFields,
  IReturnLeadsToCampaignResponse,
  ITag,
  IUpdateCampaignLeadResponse,
} from 'src/types';
import { appApi } from './axios';

export const postLeads = (requestData: ILeadsPostParameters): Promise<ILeadsResponse> =>
  appApi.post(`/leads`, requestData);

export const getLeadInfo = (entityUrn: string, objectUrn?: string): Promise<ILeadInfo> =>
  appApi.get(`/leads/${entityUrn}?objectUrn=${objectUrn || ''}`);

export const updateLead = ({ entityUrn, body }: { entityUrn: string; body: { isExcluded: boolean } }): Promise<void> =>
  appApi.patch(`/leads/${entityUrn}`, body);

export const addLeadsTags = (body: IAddLeadsTagsRequest): Promise<void> => appApi.post(`/leads/add-tags`, body);

export const setLeadTags = (entityUrn: string, tags: ITag[]): Promise<void> =>
  appApi.put(`/leads/${entityUrn}/set-tags`, {
    tagIds: tags.map((tag) => tag.id),
  });

export const createSetLeadTag = (entityUrn: string, tag: ICreateTagRequest): Promise<ITag> =>
  appApi.put(`/leads/${entityUrn}/create-set-tag`, tag);

export const updateLeadTags = (entityUrn: string, tags: ITag[]): Promise<void> =>
  appApi.patch(`/leads/${entityUrn}/tags`, {
    tagIds: tags.map((tag) => tag.id),
  });

export const deleteLeadTags = (entityUrn: string, tags: ITag[]): Promise<void> =>
  appApi.delete(`/leads/${entityUrn}/remove-tags`, { data: { tagIds: tags.map((tag) => tag.id) } });

export const getAllLeadsStatuses = (campaignId: number, data: ILeadsSearchFields): Promise<ILeadsStatuses> =>
  appApi.get(`/leads/campaign/${campaignId}/statuses`, { params: data });

export const getAllCampaignLeads = (campaignId: number, data: IGetLeadsRequest): Promise<IGetLeadsResponse> =>
  appApi.get(`/leads/campaign/${campaignId}`, { params: data });

export const getAllLeadsOfCampaign = (campaignId: number): Promise<{ entityUrns: string[] }> =>
  appApi.get(`/leads/campaign/${campaignId}/all`);

export const exportLeads = (
  receiverEmail: string,
  entityUrns: string[],
  request: ILeadsPostParameters,
): Promise<void> => appApi.post(`/leads/csv`, { ...request, receiverEmail, entityUrns });

export const updateLeadsColumnsPosition = (columnsPositions: ILeadColumnsPosition[]): Promise<ILeadColumnsPosition[]> =>
  appApi.put(`/leads/save-columns-position`, { columnsPositions });

export const editCampaignLeadColumns = (
  entityUrn: string,
  campaignId: number,
  data: ILeadsUpdateFields,
): Promise<IUpdateCampaignLeadResponse> => appApi.patch(`/leads/${entityUrn}/campaign/${campaignId}/custom-data`, data);

export const skipCampaignLead = (campaignId: number, data: { entityUrns: string[] }): Promise<void> =>
  appApi.patch(`/leads/campaign/${campaignId}/skip`, data);

export const resetCampaignLeadColumns = (entityUrn: string, campaignId: number): Promise<void> =>
  appApi.delete(`/leads/${entityUrn}/campaign/${campaignId}/custom-data`);

export const deleteCampaignIgnoredLeads = (entityUrns: string[], campaignId: number): Promise<void> =>
  appApi.delete(`/leads/campaign/${campaignId}/ignored`, { data: { entityUrns } });

export const deleteCampaignAllIgnoredLead = (campaignId: number): Promise<void> =>
  appApi.delete(`/leads/campaign/${campaignId}/ignored/all`);

export const returnCampaignIgnoredLead = (
  campaignId: number,
  body: { entityUrns: string[] },
): Promise<IReturnLeadsToCampaignResponse> => appApi.patch(`/leads/campaign/${campaignId}/return`, body);

export const returnCampaignReplies = (entityUrns: string[]): Promise<void> =>
  appApi.patch(`/leads/return-to-campaign`, { entityUrns });

export const getIgnoredCampaignLeads = (
  campaignId: number,
  data: IGetIgnoredCampaignLeadRequest,
): Promise<IGetIgnoredCampaignLeadResponse> => appApi.get(`/leads/campaign/${campaignId}/ignored`, { params: data });

export const getCampaignLeadActions = (campaignId: number, entityUrn: string): Promise<ICampaignActivity[]> =>
  appApi.get(`/leads/${entityUrn}/campaign/${campaignId}/actions`);

export const getCampaignZapierLeads = (campaignId: number): Promise<ICampaignZapierLeads> =>
  appApi.get(`/leads/campaign/${campaignId}/zapier`);

export const excludeLeads = (entityUrns: string[], exclude: boolean): Promise<void> =>
  appApi.post(`/leads/exclude`, { entityUrns, exclude });
