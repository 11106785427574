import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { updateCampaignFromConnections } from 'src/api';
import { ICampaign, ICampaignAudienceRequest, ICustomAxiosError } from 'src/types';

interface IUseUpdateCampaignFromConnectionsParams {
  generalCampaignType: string;
  campaignId: number;
  isAddMoreLeads: boolean;
  redirectTo?: string;
}

export const useUpdateCampaignFromConnections = (
  { generalCampaignType, campaignId, isAddMoreLeads, redirectTo }: IUseUpdateCampaignFromConnectionsParams,
  options?: UseMutationOptions<ICampaign, ICustomAxiosError, ICampaignAudienceRequest>,
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate, isLoading, ...rest } = useMutation<ICampaign, ICustomAxiosError, ICampaignAudienceRequest>(
    ['update-campaign-connections'],
    (data) => updateCampaignFromConnections(generalCampaignType, campaignId, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        await queryClient.cancelQueries(['campaign', data.id]);

        queryClient.setQueryData<ICampaign>(['campaign', data.id], () => data);

        if (isAddMoreLeads) {
          navigate(
            redirectTo || `/campaign/stats/${data?.category}/${data?.runState === 'paused' ? 'paused' : 'active'}`,
          );
        } else {
          navigate(`/campaign/${data.id}/sequence`);
        }
      },
    },
  );

  return { updateCampaignFromConnections: mutate, isUpdateLoading: isLoading, ...rest };
};
