import classNames from 'classnames';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Button, GoogleButton, Input, MicrosoftButton, Typography } from 'src/components';
import { renderLoginCodeError } from 'src/helpers';
import { useWhiteLabel } from 'src/hooks';
import { AuthLayout } from 'src/layouts';
import { useSignInViaEmail } from 'src/reactQueries';
import { ICustomAxiosError } from 'src/types';
import { AuthError, AuthForm, FormError, IconBox, InputColumn, MainHeading } from './_components';

const LoginPage = styled(Box)`
  width: 100%;
  max-width: 445px;
  margin: auto;
  position: relative;
  padding-top: 60px;
`;

const StyledLink = styled(Link)`
  border: none;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<Record<string, string>>();

  const { whitelabel } = useWhiteLabel();
  const [loginError, setError] = useState<number | null>(null);
  const [formError, setFormError] = useState<{
    message: string;
    type: string;
  } | null>(null);
  const [shouldRewriteError, setShouldRewriteError] = useState(false);

  const { signInViaEmail, error, isLoading } = useSignInViaEmail();
  const requestError = error as ICustomAxiosError;

  const checkAndSetErrors = () => {
    if (shouldRewriteError) {
      if (errors?.email?.message) {
        setFormError({ message: errors.email.message, type: 'email' });
        setShouldRewriteError(false);
      }
      if (!errors?.email?.message && errors?.password?.message) {
        setFormError({ message: errors.password.message, type: 'password' });
        setShouldRewriteError(false);
      }
      if (!errors?.email?.message && !errors?.password?.message && error) {
        setFormError({ message: requestError?.message, type: 'error' });
        setShouldRewriteError(false);
      }
    }

    if (formError?.message && formError?.type) {
      return (
        <FormError
          errorMessage={{
            message: formError.message,
            type: formError.type,
          }}
        />
      );
    }

    return null;
  };

  const submit = ({ email, password }: FieldValues) => {
    signInViaEmail({
      email,
      password,
    });
  };

  const closeErrorAlert = () => {
    setError(null);
  };

  return (
    <AuthLayout>
      <LoginPage>
        {loginError && (
          <AuthError>
            {renderLoginCodeError(loginError)}
            <IconBox>
              <FontAwesomeIcon data-testid="close-error" icon={faXmark} onClick={closeErrorAlert} />
            </IconBox>
          </AuthError>
        )}

        <MainHeading>Hey, welcome{!whitelabel.isWhiteLabelUser && ' back'}!</MainHeading>
        <Box maxWidth="400px" mx="auto">
          <AuthForm
            onSubmit={(e) => {
              setValue('email', getValues('email').trim());
              setShouldRewriteError(true);

              return handleSubmit(submit)(e);
            }}
          >
            <InputColumn mt="20px">
              <Input
                register={register}
                name="email"
                label="Email Address"
                parameters={{
                  required: 'Please enter an email',
                  onBlur: () => {
                    setValue('email', getValues('email').trim());
                  },
                }}
                data-testid="email-field"
                placeholder="Enter your email"
                className={classNames({ error: formError?.type === 'email' })}
              />
            </InputColumn>
            <InputColumn mt="10px">
              <Input
                register={register}
                name="password"
                type="password"
                label={
                  <Typography color="text.primary" semibold>
                    Password • <StyledLink to="/auth/forgotPassword">Forgot?</StyledLink>
                  </Typography>
                }
                parameters={{
                  required: 'Please enter a password',
                }}
                placeholder="Enter your password"
                data-testid="password-field"
                className={classNames({ error: formError?.type === 'password' })}
              />
            </InputColumn>
            <Box mt="20px">
              <Button
                size={{ width: '100%', height: '42px' }}
                type="submit"
                variant="secondary"
                data-testid="button"
                processing={isLoading}
                disabled={isLoading}
              >
                Login
              </Button>
            </Box>
            <Box mt="10px">{checkAndSetErrors()}</Box>
          </AuthForm>
          {whitelabel.isWhiteLabelUser || (
            <>
              <Box textAlign="center">
                <strong>OR</strong>
              </Box>
              <Box mt="20px">
                <GoogleButton redirectTo="/dashboard" setError={setError} />
              </Box>
              <Box mt="20px">
                <MicrosoftButton redirectTo="/auth/login" setError={setError} />
              </Box>
            </>
          )}
        </Box>
      </LoginPage>
    </AuthLayout>
  );
};
