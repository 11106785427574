import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateTag } from 'src/api';
import { closeModal } from 'src/store/modal.slice';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, ITag, IUpdateTagRequest } from 'src/types';

export const useUpdateTag = (params: IUpdateTagRequest, options?: UseMutationOptions<void, ICustomAxiosError>) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate } = useMutation(['update-tag', params], () => updateTag(params), {
    ...options,
    onMutate: async (variables) => {
      options?.onMutate?.(variables);
      await queryClient.cancelQueries(['get-tags']);

      const prevData = queryClient.getQueryData(['get-tags']);

      queryClient.setQueryData<ITag[] | undefined>(['get-tags'], (items) =>
        items?.map((item) => {
          if (item.id === params.id) {
            const itemUpdated = { ...item };

            if (params.color) {
              itemUpdated.color = params.color;
            }
            if (params.tag) {
              itemUpdated.tag = params.tag;
            }

            return itemUpdated;
          }

          return item;
        }),
      );

      return { prevData };
    },
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      queryClient.invalidateQueries(['get-all-tags'], { refetchInactive: true }, {}).catch(console.error);
      dispatch(closeModal());
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      queryClient.setQueryData(['get-tags'], context?.prevData);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
    onSettled: async (data, error, variables, context) => {
      options?.onSettled?.(data, error, variables, context);
      await queryClient.invalidateQueries(['get-tags']);
    },
  });

  return { updateTag: mutate };
};
