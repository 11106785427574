import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';

import gmailBtn from 'src/assets/img/google.svg';
import outlookBtn from 'src/assets/img/microsoft.svg';
import { Button, ErrorMessage, Spinner, Toggle, Typography } from 'src/components';
import { IS_SHOW_WHITE_LABEL } from 'src/constants';
import { ModalTypes } from 'src/enums';
import { delay } from 'src/helpers';
import { useCompanyTheme } from 'src/hooks';
import {
  useDeleteGmail,
  useDeleteMicrosoftOutlook,
  useGetMicrosoftOutlookCodeUrl,
  useGetUserAccount,
  useGetUsersEmail,
  usePostGmailSignIn,
  usePostMicrosoftOutlookSignIn,
  useToggleIncludeUnsubscribeEmailLink,
} from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';
import { ICustomAxiosError, IUserEmailResponse } from 'src/types';
import { Title } from '../ui';
import { WithWLEmailIntegration } from './WithWLEmailIntegration';

const EmailIntegrationContainer = styled(Box)`
  background-color: #ffffff;
  padding: 20px;
  min-width: 300px;
  box-shadow: 0 1px 1px 0 ${({ theme }) => theme.palette.light.dark};
  border-radius: 5px;
`;

const SignInButton = styled.img`
  height: 40px;
  cursor: pointer;
`;

const AuthorizedBlock = styled.div`
  display: flex;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  margin-top: 20px;
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: auto;
  height: 100% !important;
  padding: 8px 10px;
  margin: 0;
`;

const ProfileImageContainer = styled.div`
  position: relative;
`;

const AliasBadged = styled(Typography)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  box-shadow: 0 1px 1px 0 ${({ theme }) => theme.palette.lightGray.light};
  text-align: center;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
  line-height: 15px;
  margin: 0 5px;
  padding: 3px 5px;
  font-size: 10px;
`;

const ProfileImage = styled.img`
  border-radius: 50%;
  width: 48px;
  height: 48px;
`;

export const EmailIntegration = ({ redirectTo, fullWidth = false }: { redirectTo: string; fullWidth?: boolean }) => {
  const dispatch = useDispatch();
  const { shortCompanyName } = useCompanyTheme();
  const [errorMessage, setErrorMessage] = useState('');
  const { state } = useLocation() as { state: { code?: string } };

  const [userEmailInfo, setUserEmailInfo] = useState<IUserEmailResponse | null>(null);

  const { refetch, isLoading, isFetching } = useGetUsersEmail({
    onSuccess: (data) => setUserEmailInfo(data),
  });

  const { userMe } = useGetUserAccount();

  const { microsoftError } = usePostMicrosoftOutlookSignIn(state?.code || '');

  const { postGmailSingIn, googleError } = usePostGmailSignIn();

  const { deleteGmail, isDeleteGmailLoading } = useDeleteGmail();

  const { deleteMicrosoftOutlook, isLoading: isDeleteMicrosoftOutlookLoading } = useDeleteMicrosoftOutlook();

  const { getMicrosoftOutlookCodeUrl } = useGetMicrosoftOutlookCodeUrl(redirectTo);

  const { toggleIncludeUnsubscribeEmailLink } = useToggleIncludeUnsubscribeEmailLink();

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    const message = (googleError as ICustomAxiosError)?.message || (microsoftError as ICustomAxiosError)?.message;

    if (message) {
      setErrorMessage(message);
      delay(5000, () => {
        setErrorMessage('');
      });
    }
  }, [googleError, microsoftError]);

  const logout = (onConfirm: () => void) => {
    dispatch(
      openModal({
        headerText: 'Are you sure you want to remove Email credentials ?',
        descriptionText:
          'This means that your Email Campaigns stop to working. Once another Email is connected leads who already got email from previous email box won’t receive any emails.',
        onConfirm,
      }),
    );
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: ({ code }) => {
      postGmailSingIn(code);
    },
    scope: 'profile email https://www.googleapis.com/auth/gmail.send https://www.googleapis.com/auth/gmail.readonly',
    flow: 'auth-code',
  });

  const loginWithMicrosoft = () => {
    getMicrosoftOutlookCodeUrl();
  };

  const onUpdateSignatureClick = () => {
    dispatch(
      openModal({
        type: ModalTypes.SIGNATURE,
        headerText: 'Setup your Email Signature',
      }),
    );
  };

  const onSendingAliasClick = () => {
    if (userEmailInfo) {
      dispatch(
        openModal({
          type: ModalTypes.USE_EMAIL_ALIAS,
          headerText: 'Select the address you want to use to send emails from',
          params: {
            emails: [userEmailInfo.email],
          },
        }),
      );
    }
  };

  const onExportUnsubscribedEmailsClick = () =>
    dispatch(
      openModal({
        type: ModalTypes.EXPORT_UNSUBSCRIBED_EMAILS_CSV,
      }),
    );

  if (IS_SHOW_WHITE_LABEL) {
    return <WithWLEmailIntegration redirectTo={redirectTo} isFullWidth={fullWidth} />;
  }

  if (isLoading || isFetching) {
    return (
      <EmailIntegrationContainer width={fullWidth ? '100%' : '50%'}>
        <Spinner size="24px" mb="40px" />
      </EmailIntegrationContainer>
    );
  }

  return (
    <EmailIntegrationContainer width={fullWidth ? '100%' : '50%'}>
      {!userEmailInfo?.gmailEnabled && !userEmailInfo?.outlookEnabled && (
        <>
          <Title>Authorize Email</Title>
          <Typography>
            We need your permission to send emails in your name and to read your emails for checking for replies.
          </Typography>
          <Typography>The emails will be send from the email account you authorize next.</Typography>
          <Box marginTop="20px" marginLeft="-5px">
            <SignInButton src={gmailBtn} onClick={loginWithGoogle} />
          </Box>
          <Box marginTop="20px" marginLeft="-5px">
            <SignInButton src={outlookBtn} onClick={loginWithMicrosoft} />
          </Box>
          {!!errorMessage && (
            <Box mt="15px">
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </Box>
          )}
        </>
      )}
      {userEmailInfo?.gmailEnabled && (
        <>
          <Title>Gmail Authorized</Title>
          <Typography>You have authorized your Gmail account for sending emails from {shortCompanyName}.</Typography>
          <AuthorizedBlock>
            <ProfileImageContainer>
              {userEmailInfo?.pic && <ProfileImage src={userEmailInfo.pic} />}
            </ProfileImageContainer>
            <Box display="flex" flexDirection="column" justifyContent="space-around" ml="10px" mr="auto">
              <Typography fontSize="16px" bold>
                {userEmailInfo?.alias?.displayName || userEmailInfo.name}
              </Typography>
              <Typography>
                {userEmailInfo?.alias?.sendAsEmail || userEmailInfo.email}{' '}
                {userEmailInfo?.alias?.sendAsEmail !== userEmailInfo?.email && <AliasBadged inline>alias</AliasBadged>}
              </Typography>
            </Box>
            <StyledButton disabled={isDeleteGmailLoading} onClick={() => logout(deleteGmail)}>
              Remove this Gmail account.
            </StyledButton>
          </AuthorizedBlock>
        </>
      )}
      {userEmailInfo?.outlookEnabled && (
        <>
          <Title>Microsoft Account Authorized</Title>
          <Typography>You have authorized your Outlook account for sending emails from {shortCompanyName}.</Typography>
          <AuthorizedBlock>
            <ProfileImageContainer>
              {userEmailInfo?.pic && <ProfileImage src={userEmailInfo.pic} />}
            </ProfileImageContainer>
            <Box display="flex" flexDirection="column" justifyContent="space-around" mr="auto">
              <Typography fontSize="16px" bold>
                {userEmailInfo.name}
              </Typography>
              <Typography>{userEmailInfo.email}</Typography>
            </Box>
            <StyledButton disabled={isDeleteMicrosoftOutlookLoading} onClick={() => logout(deleteMicrosoftOutlook)}>
              Remove this Microsoft account.
            </StyledButton>
          </AuthorizedBlock>
        </>
      )}

      {userEmailInfo?.gmailEnabled && (
        <Box marginTop="20px">
          <StyledButton variant="gray" onClick={onSendingAliasClick}>
            Use alias for sending emails.
          </StyledButton>
        </Box>
      )}

      {(userEmailInfo?.outlookEnabled || userEmailInfo?.gmailEnabled) && (
        <>
          <Box marginTop="20px" display="flex" justifyContent="flex-start" gap="15px">
            <StyledButton variant="gray" onClick={onUpdateSignatureClick}>
              Update signature
            </StyledButton>
            <StyledButton onClick={onExportUnsubscribedEmailsClick}>Export unsubscribed emails</StyledButton>
          </Box>
          <Box display="flex" mt="30px" mb="10px" alignItems="center">
            <Toggle
              checked={userMe?.includeUnsubscribeEmailLink}
              onChange={() => toggleIncludeUnsubscribeEmailLink()}
            />
            <Typography mr="3px" ml="10px">
              Include unsubscribe link to all email campaigns
            </Typography>
          </Box>
        </>
      )}
    </EmailIntegrationContainer>
  );
};
