import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateWorkHours } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IScheduled, IUserPreferences } from 'src/types';

export const useUpdateWorkHours = (options?: UseMutationOptions<void, ICustomAxiosError, IScheduled>) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const getUserPreferencesQueryKey = ['get-user-preferences'];

  const { mutate, ...rest } = useMutation(['update-user-daily-limits'], (data: IScheduled) => updateWorkHours(data), {
    ...options,
    onMutate: async (variables) => {
      options?.onMutate?.(variables);
      await queryClient.cancelQueries(getUserPreferencesQueryKey);

      const prevData = queryClient.getQueryData(getUserPreferencesQueryKey);

      queryClient.setQueryData<IUserPreferences | undefined>(getUserPreferencesQueryKey, (data) => {
        if (data) {
          return {
            ...data,
            operatingHours: variables,
          };
        }

        return data;
      });

      return { prevData };
    },

    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      queryClient.setQueryData(getUserPreferencesQueryKey, context?.prevData);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { updateWorkHours: mutate, ...rest };
};
