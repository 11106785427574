import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createTemplate } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICreateTemplateRequest, ICustomAxiosError } from 'src/types';

export const useCreateTemplate = (options?: UseMutationOptions<void, ICustomAxiosError, ICreateTemplateRequest>) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation('create-template', createTemplate, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      navigate(`/templates/personal?category=${variables.categoryId}`);

      queryClient.invalidateQueries(['get-templates-personal'], { refetchInactive: true }, {}).catch(console.error);
      queryClient.invalidateQueries(['get-templates-by-category'], { refetchInactive: true }, {}).catch(console.error);
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return {
    createTemplate: mutate,
    ...rest,
  };
};
