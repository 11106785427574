import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const CheckboxContainer = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0 10px 5px;

  label {
    font-size: 14px;
    margin: 3px 10px 0;
  }
`;
