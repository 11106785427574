import { useMemo } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { TToastType } from 'src/types';

const Container = styled(Box)`
  padding: 15px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 16px;
  gap: 15px;
  cursor: default;
`;

const CloseButton = styled(Box)`
  position: relative;
  top: 2px;
  right: 0px;
  text-shadow: 0 1px 0 #fff;
  cursor: pointer;
  font-size: 18px;
  line-height: 5px;
  font-weight: 700;
  opacity: 0.2;
  text-align: center;

  &:hover {
    color: #000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.5;
  }
`;

export const AlertTemplate = ({ message, type, close }: { message: string; type: TToastType; close?: () => void }) => {
  const model = useMemo(() => {
    switch (type) {
      case 'error':
        return {
          bgcolor: '#f2dede',
          border: '1px solid #ebccd1',
          color: '#a94442',
        };
      case 'success':
        return {
          bgcolor: '#dff0d8',
          border: '1px solid #d6e9c6',
          color: '#3c763d',
        };

      default:
        return null;
    }
  }, []);

  return (
    <Container {...model}>
      {message}

      <CloseButton onClick={close}>×</CloseButton>
    </Container>
  );
};
