import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { getCampaignListItem } from 'src/api';
import { ICustomAxiosError, IGetCampaignListItemParams, IGetCampaignResponse, IGetCampaigns } from 'src/types';

export const useGetCampaignListItem = (
  options?: UseMutationOptions<IGetCampaigns, ICustomAxiosError, IGetCampaignListItemParams>,
) => {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation<IGetCampaigns, ICustomAxiosError, IGetCampaignListItemParams>(
    ['get-campaign-list-item'],
    getCampaignListItem,
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        queryClient.setQueriesData<IGetCampaignResponse | undefined>(
          ['get-campaigns', data.category, 'active', 'running', variables.filter],
          (prevData) => {
            if (prevData) {
              return {
                ...prevData,
                campaigns: prevData.campaigns.map((campaign) =>
                  campaign.id === variables.id ? { ...campaign, ...data } : campaign,
                ),
              };
            }

            return prevData;
          },
        );
      },
    },
  );

  return { getCampaignListItem: mutate, ...rest };
};
