import { useMemo } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Divider, MessageBlock, Typography } from 'src/components';
import { CurrencyEnum } from 'src/enums';
import { calculateUserInvoice, getAmountString, getCurrencySymbol } from 'src/helpers';
import { IStripeInvoice } from 'src/types';
import { BlockTitle } from '../_components';

interface InvoiceDetailsProps {
  invoiceDetails: IStripeInvoice;
  currency: CurrencyEnum;
}

const InvoiceDetailsWrapper = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.gray[100]};
  background-color: ${({ theme }) => theme.palette.gray[50]}80;
  padding: 20px;
  border-radius: 4px;
`;

const Line = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const Label = styled(Typography)<{ color?: string }>`
  color: ${({ theme, color }) => color || theme.palette.gray[500]};
  font-size: 15px;
`;

const Value = styled(Typography)<{ color?: string; size?: string }>`
  color: ${({ theme, color }) => color || theme.palette.gray[800]};
  font-size: ${({ size }) => size || '15px'};
  font-weight: 500;
`;

export const InvoiceDetails = ({ invoiceDetails, currency }: InvoiceDetailsProps) => {
  const currencySymbol = useMemo(() => getCurrencySymbol(currency), [currency]);

  const discount = useMemo(() => {
    if (invoiceDetails?.discount && invoiceDetails?.total_discount_amounts?.length) {
      const percentOff = invoiceDetails?.discount?.coupon?.percent_off;
      const amountOff = invoiceDetails?.discount?.coupon?.amount_off;
      const totalAmount = invoiceDetails.total_discount_amounts[0].amount;

      if (percentOff) {
        return {
          label: `${percentOff}% off`,
          total: totalAmount,
        };
      }

      if (amountOff) {
        return {
          label: `${currencySymbol} ${(amountOff / 100).toFixed(2)} off`,
          total: totalAmount,
        };
      }
    }

    return null;
  }, [invoiceDetails]);

  const { referral, first, extra, users, prorata, remaining, graduated } = useMemo(
    () => calculateUserInvoice(invoiceDetails),
    [invoiceDetails],
  );

  return (
    <InvoiceDetailsWrapper>
      <BlockTitle mb="16px">Invoice details</BlockTitle>

      {first && (
        <Line>
          <Label>{graduated ? `First ${graduated} seats` : 'First seat'}</Label>
          <Value>
            {currencySymbol}
            {getAmountString(first.total)}
          </Value>
        </Line>
      )}

      {extra?.quantity > 0 && (
        <Line>
          <Label>
            Extra seat{extra.quantity > 1 && 's'}{' '}
            {extra.quantity > 1 && (
              <span>
                {currencySymbol}
                {getAmountString(extra.price)} &times; {extra.quantity}
              </span>
            )}
          </Label>
          <Value>
            {currencySymbol}
            {getAmountString(extra.total)}
          </Value>
        </Line>
      )}

      {!!users && (
        <Line>
          <Label>
            User seat{users.quantity > 1 && 's'}{' '}
            {users.quantity > 1 && (
              <span>
                {currencySymbol}
                {getAmountString(users.price)} &times; {users.quantity}
              </span>
            )}
          </Label>
          <Value>
            {currencySymbol}
            {getAmountString(users.total)}
          </Value>
        </Line>
      )}

      {!!prorata && (
        <Line>
          <Label semibold color="gray.800">
            Unused time of current billing cycle
          </Label>
          <Value color="success.500">
            - {currencySymbol}
            {getAmountString(Math.abs(prorata))}
          </Value>
        </Line>
      )}

      {!!remaining && (
        <>
          <Line>
            <Label semibold color="gray.800">
              Remaining time of current billing cycle
            </Label>
            <Value>
              {currencySymbol}
              {getAmountString(Math.abs(remaining))}
            </Value>
          </Line>
          <Divider my="16px" />
        </>
      )}

      {!!referral && (
        <Line>
          <Label color="success.500">Referral Discount</Label>
          <Value color="success.500">
            - {currencySymbol}
            {getAmountString(Math.abs(referral.total))}
          </Value>
        </Line>
      )}

      <Divider my="16px" />

      <Line>
        <Label>Subtotal</Label>
        <Value>
          {Math.sign(invoiceDetails.subtotal) === -1 && '-'}
          {currencySymbol}
          {getAmountString(Math.abs(invoiceDetails.subtotal))}
        </Value>
      </Line>

      {!!discount?.total && (
        <Line>
          <Label color="success.500">Discount {discount.label}</Label>
          <Value color="success.500">
            - {currencySymbol}
            {getAmountString(discount.total)}
          </Value>
        </Line>
      )}

      {!!invoiceDetails && invoiceDetails?.amount_due < invoiceDetails?.total && (
        <Line>
          <Label color="success.500">Pro-rata balance</Label>
          <Value color="success.500">
            - {currencySymbol}
            {getAmountString(invoiceDetails?.total - invoiceDetails?.amount_due)}
          </Value>
        </Line>
      )}

      <Divider my="16px" />

      <Line mb="0 !important">
        <Label>Total</Label>
        <Value size="18px">
          {currencySymbol}
          {getAmountString(invoiceDetails.amount_due)}
        </Value>
      </Line>

      {discount && !discount.total && (
        <>
          <MessageBlock
            topContent={
              !!invoiceDetails?.subtotal &&
              invoiceDetails.subtotal < 0 && (
                <Line mb="0 !important">
                  <Label color="gray.800" medium>
                    Pro-rata Balance for next invoices
                  </Label>
                  <Value size="18px">
                    {currencySymbol}
                    {getAmountString(Math.abs(invoiceDetails.subtotal))}
                  </Value>
                </Line>
              )
            }
            wrapperProps={{ mt: '16px' }}
            messageProps={{ color: 'gray.500', fontSize: '14px', lineHeight: '16px' }}
            message={
              discount &&
              !discount.total &&
              'Please note that the discount has already been applied to the remaining time of current billing cycle'
            }
          />
          <Label fontStyle="italic"></Label>
        </>
      )}
    </InvoiceDetailsWrapper>
  );
};
