import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getTemplatesTeam } from 'src/api';
import { IGetTemplatesRequest, IGetTemplatesResponse } from 'src/types';

export const useGetTemplatesTeam = <TData = IGetTemplatesResponse>(
  params: IGetTemplatesRequest,
  options?: UseQueryOptions<IGetTemplatesResponse, AxiosError, TData>,
) => {
  const { page, perPage, categoryId, search, sortField, sortOrder } = params;

  const { data, ...rest } = useQuery<IGetTemplatesResponse, AxiosError, TData>(
    ['get-templates-team', categoryId, search, page, perPage, sortField, sortOrder],
    () => getTemplatesTeam(params),
    {
      refetchOnMount: true,
      ...options,
    },
  );

  return { data, ...rest };
};
