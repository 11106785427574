import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { returnCampaignReplies } from 'src/api';
import { updateRepliesParams } from 'src/store';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IGetCampaignReplies, IGetCampaignRepliesRequest } from 'src/types';

interface IReturnCampaignReplies {
  getRepliesKeys?: IGetCampaignRepliesRequest;
  campaignId?: number;
}

export const useReturnCampaignReplies = (
  params?: IReturnCampaignReplies,
  options?: UseMutationOptions<void, ICustomAxiosError, string[]>,
) => {
  const { campaignId } = params ?? {};
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const campaignRepliesQueryKey = ['get-campaign-replies'];

  const { mutate, isLoading, ...rest } = useMutation(
    ['return-campaign-replies'],
    (entityUrns: string[]) => returnCampaignReplies(entityUrns),
    {
      ...options,
      onMutate: async (entityUrns) => {
        options?.onMutate?.(entityUrns);
        await queryClient.cancelQueries(campaignRepliesQueryKey);

        const prevData = queryClient.getQueryData(campaignRepliesQueryKey);

        queryClient.setQueriesData<IGetCampaignReplies | undefined>(campaignRepliesQueryKey, (data) => {
          if (data) {
            return {
              ...data,
              replies: data.replies?.map((reply) =>
                entityUrns.includes(reply.person?.key) ? { ...reply, isReturnToCampaign: true } : reply,
              ),
            };
          }

          return data;
        });

        return { prevData };
      },
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        dispatch(updateRepliesParams({ entityUrns: [] }));
        dispatch(
          showToast({
            type: 'info',
            message: 'The lead was returned to sequence',
            autoCloseTime: 3000,
          }),
        );

        await queryClient.invalidateQueries(campaignRepliesQueryKey);
        await queryClient.invalidateQueries(['get-campaign-leads', campaignId]);
        await queryClient.invalidateQueries(['get-campaign-activity'], { refetchInactive: true, exact: false });
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueriesData(campaignRepliesQueryKey, context?.prevData);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { returnCampaignReplies: mutate, isReturnLoading: isLoading, ...rest };
};
