import { useEffect, useState } from 'react';

import { getFacebookDetails, getFacebookPages, getInstagramBusinessAccounts, getInstagramDetails } from 'src/api';
import { delay, initFacebookSdk } from 'src/helpers';
import {
  useGetFacebookAccessToken,
  useGetFacebookData,
  useGetFacebookPostOptions,
  usePostFacebookClearLogin,
  usePostFacebookData,
  usePostFacebookOptions,
  usePostFacebookShortLiveTokenToGetLongLiveToken,
} from 'src/reactQueries';
import { IFacebookData, IFacebookDetailsResponse, IFacebookPage, IFacebookResponse } from 'src/types';

const FACEBOOK_SCOPE =
  'public_profile,email,business_management,pages_read_engagement,pages_read_user_content,pages_manage_posts,pages_show_list,instagram_basic,instagram_manage_insights,instagram_content_publish';

export const useFacebookIntegration = () => {
  const [facebookLongLiveToken, setFacebookLongLiveToken] = useState<string | null>(null);
  const [fbAppToken, setFbAppToken] = useState<string | null>(null);
  const [facebookDetails, setFacebookDetails] = useState<IFacebookDetailsResponse | null>(null);
  const [pagesLoading, setPagesLoading] = useState(false);
  const [facebookPages, setFacebookPages] = useState<IFacebookPage[]>([]);
  const [checkedPages, setCheckedPages] = useState<string[]>([]);
  const [checkedInstagramPages, setCheckedInstagramPages] = useState<string[]>([]);
  const [optionsSavedStatus, setOptionsSavedStatus] = useState<string | false>(false);
  const [authResponse, setAuthResponse] = useState<fb.AuthResponse | null>(null); //eslint-disable-line

  const displaySavedStatus = (status: string | boolean) => {
    if (!status) {
      setOptionsSavedStatus('Saved');
      delay(3000, () => setOptionsSavedStatus(false));
    }
  };

  const {
    isLoading: isFetchingData,
    refetch: refetchData,
    data,
  } = useGetFacebookData({
    onSuccess: ({ facebookData }) => {
      const {
        longLivedAccessToken,
        facebookDetails: facebookDetailsFromBE,
        facebookPages: facebookPagesFromBE,
      } = facebookData || {};

      longLivedAccessToken && !facebookLongLiveToken && setFacebookLongLiveToken(longLivedAccessToken);
      facebookDetailsFromBE && !facebookDetails && setFacebookDetails(facebookDetailsFromBE);
      facebookPagesFromBE && !facebookPages.length && setFacebookPages(facebookPagesFromBE);
    },
  });

  const { isFetching: isFetchingOptions, refetch: refetchOptions } = useGetFacebookPostOptions({
    onSuccess: ({ facebookOptions }) => {
      setCheckedPages(facebookOptions?.pages_checked || []);
      setCheckedInstagramPages(facebookOptions?.ig_accounts_checked || []);
    },
  });

  const { postFacebookClearLogin, isLoading: isClearLoginLoading } = usePostFacebookClearLogin({
    onSuccess: () => {
      setFacebookDetails(null);
      setFacebookPages([]);
    },
  });

  const { postFacebookData } = usePostFacebookData();

  const { postFacebookOptions } = usePostFacebookOptions({});

  const { getFacebookAccessToken } = useGetFacebookAccessToken({
    onSuccess: ({ accessToken }) => {
      accessToken && setFbAppToken(accessToken);
    },
  });

  useEffect(() => {
    initFacebookSdk();
    getFacebookAccessToken();
    refetchData();
    refetchOptions();
  }, []);

  const sendFacebookData = (showSuccessMessage = false, initialData: Partial<IFacebookData> = {}) => {
    const fbData: IFacebookData = { facebookPages: [], ...initialData };

    fbAppToken && (fbData.facebookAppAccessToken = fbAppToken);
    facebookLongLiveToken && (fbData.longLivedAccessToken = facebookLongLiveToken);
    facebookPages?.length && (fbData.facebookPages = facebookPages);
    facebookDetails && (fbData.facebookDetails = facebookDetails);
    authResponse && (fbData.facebookAuth = authResponse);

    if (Object.keys(fbData).length > 0) {
      postFacebookData(fbData, {
        onSuccess: () => {
          showSuccessMessage && displaySavedStatus(optionsSavedStatus);
        },
      });
    }

    postFacebookOptions(
      {
        pages_checked: checkedPages,
        ig_accounts_checked: checkedInstagramPages,
      },
      {
        onSuccess: () => {
          showSuccessMessage && displaySavedStatus(optionsSavedStatus);
        },
      },
    );
  };

  const toggleCheckSelect = (pageId: string) => {
    const index = checkedPages.indexOf(pageId);
    if (index === -1) {
      return setCheckedPages([...checkedPages, pageId]);
    }

    return setCheckedPages(checkedPages.filter((id) => id !== pageId));
  };

  const toggleCheckInstagramSelect = (pageId: string | undefined) => {
    if (!pageId) {
      return null;
    }

    const index = checkedInstagramPages.indexOf(pageId);

    if (index === -1) {
      return setCheckedInstagramPages([...checkedInstagramPages, pageId]);
    }

    return setCheckedInstagramPages(checkedInstagramPages.filter((id) => id !== pageId));
  };

  const getNextPage = (url: string, groupIds: string[], callback: (totalIds: string[]) => void) => {
    window.FB.api(url, {}, function ({ data, paging }: IFacebookResponse<{ id: string }>) {
      if (data?.length > 0) {
        groupIds = groupIds.concat(data.map(({ id }) => id));
      }

      if (paging?.next) {
        return getNextPage(paging.next, groupIds, callback);
      }

      return callback(groupIds);
    });
  };

  const sessionLogout = () => {
    window.FB.logout();
    postFacebookClearLogin();
  };

  const onGetInstagramDetails = (page: IFacebookPage) => {
    if (!page?.ig?.id) {
      return;
    }

    getInstagramDetails(facebookLongLiveToken, page.ig.id, (response) => {
      if (response.error) {
        return;
      }

      const { id, followers_count, follows_count, media_count, name, profile_picture_url, username } = response;

      const facebookPagesTmp = facebookPages.map((fPage) => {
        if (fPage.id === page.id) {
          fPage.ig = {
            id,
            username,
            profile_pic: profile_picture_url,
            follows_count,
            followers_count,
            media_count,
            name,
          };
        }

        return fPage;
      });

      setFacebookPages(facebookPagesTmp);
      sendFacebookData();
    });
  };

  const getFBPages = (token?: string, facebookDetails?: IFacebookDetailsResponse) => {
    setPagesLoading(true);
    getFacebookPages(token || facebookLongLiveToken, (pages) => {
      if (pages.error) {
        return sessionLogout();
      }

      let facebookPagesTmp = pages.data || [];

      getInstagramBusinessAccounts(token || facebookLongLiveToken, ({ error, data }) => {
        if (error) {
          return sessionLogout();
        }

        data.forEach((fPage) => {
          facebookPagesTmp = facebookPagesTmp.map((fbPage) => {
            const instId = fPage?.instagram_business_account?.id;

            if (fbPage.id === fPage.id && instId) {
              fbPage.ig = {
                id: instId,
              };
            }

            return fbPage;
          });
        });

        setFacebookPages(facebookPagesTmp);
        setPagesLoading(false);
      });

      return sendFacebookData(false, { facebookPages: facebookPagesTmp, facebookDetails });
    });
  };

  const { postFacebookShortLiveTokenToGetLongLiveToken } = usePostFacebookShortLiveTokenToGetLongLiveToken({
    onSuccess: ({ accessToken }) => {
      if (accessToken) {
        setFacebookLongLiveToken(accessToken);
      }

      getFacebookDetails((response) => {
        if (response.error) {
          sessionLogout();
        } else {
          setFacebookDetails(response);
          getFBPages(accessToken, response);
        }
      });
    },
  });

  const signInWithFacebook = () =>
    window.FB.login(
      (loginResponse) => {
        setFacebookPages([]);
        if (loginResponse && (loginResponse as any).error) {
          return sessionLogout();
        }

        if (loginResponse.status !== 'connected') {
          signInWithFacebook();
        }

        if (loginResponse?.authResponse) {
          setAuthResponse(loginResponse.authResponse);
          postFacebookShortLiveTokenToGetLongLiveToken(loginResponse?.authResponse?.accessToken);
        }
      },
      {
        scope: FACEBOOK_SCOPE,
      },
    );

  return {
    signInWithFacebook,
    getFBPages,
    onGetInstagramDetails,
    sessionLogout,
    getNextPage,
    sendFacebookData: () => sendFacebookData(true),
    toggleCheckSelect,
    toggleCheckInstagramSelect,
    getFacebookAccessToken,
    postFacebookShortLiveTokenToGetLongLiveToken,
    postFacebookOptions,
    postFacebookData,
    refetchData,
    isFetchingData,
    refetchOptions,
    isFetchingOptions,
    postFacebookClearLogin,
    isClearLoginLoading,
    authResponse,
    optionsSavedStatus,
    checkedInstagramPages,
    checkedPages,
    facebookPages,
    pagesLoading,
    facebookDetails,
    fbAppToken,
    facebookLongLiveToken,
    isUnauthorized: data?.facebookData?.unauthorized,
  };
};
