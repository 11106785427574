import dayjs from 'dayjs';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import { faComments, faPaperPlane, faReply } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { ActionButton, Checkbox, Link, NotificationDot, TableCell, TableRow, Tooltip } from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { IConversationDrawerDataProps } from 'src/containers/Conversations/_components';
import { CampaignActions, Features, LeadActivityEnum, ModalTypes, RepliesTypes } from 'src/enums';
import {
  getIconByAction,
  getRemovedNotificationText,
  getReturnToCampaignTooltip,
  resolvePersonName,
} from 'src/helpers';
import { useTeamPlan } from 'src/hooks';
import { useReturnCampaignReplies } from 'src/reactQueries';
import { openModal } from 'src/store';
import { ICampaignReplies, IGetCampaignRepliesRequest } from 'src/types';

interface IRepliesTableRowProps {
  replies: ICampaignReplies;
  getRepliesKeys: IGetCampaignRepliesRequest;
  selectLeads: string[];
  toggleLead: (key: string) => void;
  setDrawerState: Dispatch<SetStateAction<IConversationDrawerDataProps>>;
}

export const RepliesTableRow = ({
  getRepliesKeys,
  replies,
  selectLeads,
  toggleLead,
  setDrawerState,
}: IRepliesTableRowProps) => {
  const {
    person,
    msgCreatedAt,
    conversationUrn,
    description,
    threadId,
    message,
    createdAt,
    campaignId,
    twitterConversationUrn,
    leadStatus,
    isCampaignRemoved,
    isLeadRemoved,
    campaignName,
    isReturnToCampaign,
    personKey,
  } = replies;
  const { linkedInHandle, key } = person ?? {};

  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { checkFeature } = useTeamPlan();
  const { returnCampaignReplies, isReturnLoading } = useReturnCampaignReplies({ getRepliesKeys });

  const isApproved = leadStatus === 'approved';
  const isReturnButtonDisabled =
    !checkFeature(Features.returnToCampaign) || isReturnToCampaign || !isApproved || isCampaignRemoved;
  const isBulkActionCheckboxDisabled = !checkFeature(Features.repliesBulkAction);
  const isSelected = selectLeads.includes(key) && !isReturnButtonDisabled;

  const returnButtonTooltip = getReturnToCampaignTooltip({ isReturnToCampaign, isApproved, isCampaignRemoved });

  const notificationText = useMemo(
    () => getRemovedNotificationText({ isCampaignRemoved, isLeadRemoved }),
    [isCampaignRemoved, isLeadRemoved],
  );

  const conversationAction = useMemo(() => {
    if (description === LeadActivityEnum.LINKEDIN_REPLY_DETECTED) {
      return {
        type: RepliesTypes.USER_CONVERSATION,
        tooltip: `View Conversation on Linkedin`,
        link: conversationUrn
          ? `https://www.linkedin.com/messaging/thread/${conversationUrn}`
          : `https://www.linkedin.com/messaging/compose/?recipient=${linkedInHandle}`,
        directReply: {
          isShown: true,
          isDisabled: false,
          tooltip: 'Direct reply',
        },
      };
    }

    if (description === LeadActivityEnum.TWITTER_REPLY_DETECTED && (twitterConversationUrn || conversationUrn)) {
      return {
        type: RepliesTypes.TWITTER_CONVERSATION,
        tooltip: 'View Conversation on X (Twitter)',
        link: twitterConversationUrn || conversationUrn,
        directReply: {
          isShown: false,
        },
      };
    }

    if (description === LeadActivityEnum.INMAIL_REPLY_DETECTED) {
      return {
        type: RepliesTypes.LINKEDIN_SALES,
        tooltip: 'View Conversation on Linkedin',
        link:
          (threadId && `https://www.linkedin.com/sales/inbox/${threadId}`) ||
          (conversationUrn && `https://www.linkedin.com/messaging/thread/${conversationUrn}`) ||
          `https://www.linkedin.com/messaging/compose/?recipient=${linkedInHandle}`,
        directReply: {
          isShown: true,
          isDisabled: true,
          tooltip: 'Direct reply is not available for Inmail reply',
        },
      };
    }

    return null;
  }, [description, twitterConversationUrn, conversationUrn, threadId, linkedInHandle]);

  const { color, faIcon } = getIconByAction(description);
  const { name: personName, avatarContent } = resolvePersonName({
    ...person,
    action: [CampaignActions.TWITTER_REPLY_DETECTED, CampaignActions.LINKEDIN_REPLY_DETECTED].includes(
      description as CampaignActions,
    )
      ? description
      : '',
  });

  const handleRedirectClick = () => {
    if (!conversationAction) {
      return null;
    }

    return window.open(conversationAction.link, '_blank', 'noopener,noreferrer');
  };

  const handleOpenDrawer = () => {
    setDrawerState({
      isOpen: true,
      entityUrn: personKey,
      conversationId: conversationUrn,
    });
  };

  const modalHandler = () => {
    dispatch(
      openModal({
        type: ModalTypes.VIEW_LEAD,
        closable: false,
        params: {
          campaignId: campaignId,
          entityUrn: personKey,
        },
      }),
    );
  };

  return (
    <TableRow
      checkedBgColor={palette.primary.main}
      hoverBgColor={({ light }) => light.main}
      checked={isSelected}
      cursor="pointer"
      onClick={modalHandler}
    >
      <TableCell onClick={(event) => event.stopPropagation()}>
        <Tooltip title={isBulkActionCheckboxDisabled && PLAN_TOOLTIPS.professional}>
          <span>
            <Checkbox
              type="checkbox"
              disabled={isBulkActionCheckboxDisabled || isReturnToCampaign || !isApproved}
              checked={isSelected}
              onChange={() => toggleLead(key)}
            />
          </span>
        </Tooltip>
      </TableCell>
      <TableCell justifyContent="center">{avatarContent}</TableCell>
      <TableCell>{personName}</TableCell>
      <TableCell justifyContent="center">
        <FontAwesomeIcon icon={faIcon} color={color} style={{ fontSize: '20px' }} />
      </TableCell>
      <TableCell>{message}</TableCell>
      <TableCell tooltip={campaignName}>
        <Link
          crop
          variant="lightBlue"
          fontSize="15px"
          to={`/campaign/details/overview/${campaignId}`}
          onClick={(e) => e.stopPropagation()}
          disabled={isCampaignRemoved}
          cursor={isCampaignRemoved ? 'default' : 'pointer'}
        >
          <Box display="flex" alignItems="center">
            <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
              {campaignName}
            </Box>

            {campaignId && notificationText && (
              <NotificationDot tooltip={{ title: notificationText, placement: 'right' }} />
            )}
          </Box>
        </Link>
      </TableCell>
      <TableCell>
        {dayjs
          .utc(msgCreatedAt || createdAt)
          .local()
          .format('MMMM Do YYYY')}
      </TableCell>
      <TableCell>
        {dayjs
          .utc(msgCreatedAt || createdAt)
          .local()
          .format('h:mm a')}
      </TableCell>
      <TableCell justifyContent="flex-end" onClick={(e) => e.stopPropagation()} sticky>
        {conversationAction && (
          <ActionButton
            onClick={handleRedirectClick}
            tooltip={conversationAction.tooltip}
            mr="15px"
            icon={faComments}
          />
        )}
        {conversationAction?.directReply?.isShown && (
          <ActionButton
            disabled={!checkFeature(Features.directReply) || conversationAction?.directReply?.isDisabled}
            onClick={handleOpenDrawer}
            tooltip={checkFeature(Features.directReply) ? conversationAction?.directReply?.tooltip : PLAN_TOOLTIPS.any}
            mr="15px"
            icon={faPaperPlane}
          />
        )}
        <ActionButton
          disabled={isReturnButtonDisabled}
          tooltip={checkFeature(Features.returnToCampaign) ? returnButtonTooltip : PLAN_TOOLTIPS.professional}
          onClick={() => returnCampaignReplies([key])}
          processing={isReturnLoading}
          icon={faReply}
        />
      </TableCell>
    </TableRow>
  );
};
