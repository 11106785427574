import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { archiveCampaign } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICampaign, IGetCampaign, TMutationsOptions } from 'src/types';

export const useArchiveCampaign = (
  campaignId: number,
  options?: TMutationsOptions<ICampaign, { toArchive: boolean }>,
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, isLoading, ...rest } = useMutation(
    ['archive-campaign'],
    ({ toArchive }: { toArchive: boolean }) =>
      archiveCampaign({
        campaignId: campaignId,
        body: { toArchive },
      }),
    {
      ...options,
      onMutate: async ({ toArchive }) => {
        options?.onMutate && options?.onMutate({ toArchive });
        await queryClient.cancelQueries(['campaign', campaignId]);

        const prevData = queryClient.getQueryData(['campaign', campaignId]);

        queryClient.setQueryData<IGetCampaign | undefined>(['campaign', campaignId], (data) => {
          if (data) {
            return {
              ...data,
              status: toArchive ? 'archived' : 'active',
            };
          }

          return data;
        });

        return { prevData };
      },
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(['get-campaigns', data.category]);
        await queryClient.invalidateQueries(['get-campaigns-count', data.category]);
        await queryClient.invalidateQueries(['campaigns-dashboard'], { refetchInactive: true });
        options?.onSuccess && options?.onSuccess(data, variables, context);
      },
      onError: (err, _vars, context) => {
        options?.onError && options?.onError(err, _vars, context);
        queryClient.setQueryData(['campaign', campaignId], context?.prevData);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { archiveCampaign: mutate, isArchiveLoading: isLoading, ...rest };
};
