import classNames from 'classnames';
import { debounce, isNull } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { ImpersonateBar, Notification } from 'src/components/Notification';
import { useAppSelector, useCompanyTheme, useGetNavigationConfig } from 'src/hooks';
import { useGetRealUser } from 'src/reactQueries';
import {
  Divider,
  Logo,
  LogoWrapper,
  NavBar,
  NavigationLabel,
  NavigationSection,
  NavigationWrapper,
  Tabs,
  TabsWrapper,
} from '../_components';
import { AdminRedirect } from './AdminRedirect';
import { CreateButton } from './CreateButton';
import { Logout } from './Logout';
import { SelectProfile } from './SelectProfile';

interface IAppNavBarProps {
  isEmpty?: boolean;
  setNavBarHeight?: Dispatch<SetStateAction<number>>;
}

export const AppNavBar = ({ setNavBarHeight, isEmpty }: IAppNavBarProps) => {
  const location = useLocation();

  const { realImpersonateUser } = useGetRealUser();

  const { logoIconWhite, shortCompanyName } = useCompanyTheme();
  const { navigationConfig, defineActive } = useGetNavigationConfig();
  const [clickedIndex, setClickedIndex] = useState<number | null>(null);

  const [activeSectionIndex, setActiveSectionIndex] = useState(defineActive());

  const tabs = useMemo(() => navigationConfig[activeSectionIndex]?.tabs, [activeSectionIndex]);

  const debouncedDefineActiveSection = useCallback(
    debounce(() => setActiveSectionIndex(isNull(clickedIndex) ? defineActive() : clickedIndex), 500),
    [defineActive, clickedIndex],
  );
  const debouncedSetActiveSection = useCallback(
    debounce((sectionIndex: number) => setActiveSectionIndex(sectionIndex), 300),
    [defineActive],
  );

  const {
    notification,
    user: { isImpersonate, profile, isAppDeactivated, isAppPaused },
  } = useAppSelector((state) => state);

  const hideNavBar = isAppDeactivated || isEmpty;
  const navRef = useRef<HTMLDivElement>(null);

  const setHeight = () => {
    setNavBarHeight?.(navRef.current?.offsetHeight || 48);
  };

  useEffect(() => {
    setHeight();
  }, [notification]);

  useEffect(() => {
    setActiveSectionIndex(defineActive());
  }, [location.pathname]);

  return (
    <NavBar ref={navRef} onMouseLeave={debouncedDefineActiveSection} onMouseEnter={debouncedDefineActiveSection.cancel}>
      {!!realImpersonateUser?.isAdminImpersonate && <ImpersonateBar />}
      {!isEmpty && <Notification />}
      <Box display="flex" alignItems="center" minHeight="48px">
        <LogoWrapper>
          <Link to="/dashboard">
            <Logo src={logoIconWhite} alt={shortCompanyName} />
          </Link>
        </LogoWrapper>

        {!hideNavBar && (
          <NavigationWrapper>
            {navigationConfig.map(({ icon, id, title, show = true }, index) => {
              if (!show) return null;

              const Icon = icon;

              return (
                <NavigationSection
                  key={title}
                  id={id}
                  onMouseLeave={debouncedSetActiveSection.cancel}
                  onMouseEnter={() => debouncedSetActiveSection(index)}
                  className={classNames({ active: activeSectionIndex === index })}
                  onClick={() => setClickedIndex(index)}
                >
                  <NavigationLabel>
                    <Icon />
                    {title && (
                      <Typography medium fontSize="16px" lineHeight="22px" color="inherit" ml="10px">
                        {title}
                      </Typography>
                    )}
                  </NavigationLabel>
                </NavigationSection>
              );
            })}
          </NavigationWrapper>
        )}

        <Box ml="auto" px="20px" display="flex" alignItems="center" gap="10px" position="relative">
          {((!hideNavBar && !isAppPaused) || isImpersonate) && <SelectProfile />}
          {!hideNavBar && !isAppPaused && <CreateButton />}
          {!isImpersonate && profile?.isAlfredAdmin && <AdminRedirect />}
          {!isImpersonate && <Logout />}
        </Box>
      </Box>
      {!hideNavBar && (
        <TabsWrapper>
          <Tabs bgcolor={!tabs?.length ? '#ffffff !important' : ''}>
            {tabs?.map(({ title, id, to, external = false, show = true, activeRoutes = [] }) => {
              if (!show) return null;

              const active =
                new RegExp(to).test(location.pathname) ||
                activeRoutes.some((route) => new RegExp(route).test(location.pathname));

              if (external) {
                return (
                  <>
                    <a key={title + to} href={to} target="_blank" rel="noopener noreferrer" id={id}>
                      <Typography component="span" fontSize="16px" lineHeight="22px" color="inherit">
                        {title}
                      </Typography>
                    </a>
                    <Divider />
                  </>
                );
              }

              return (
                <>
                  <Link key={title + to} to={to} className={classNames({ active })} id={id}>
                    <Typography component="span" fontSize="16px" lineHeight="22px" color="inherit">
                      {title}
                    </Typography>
                  </Link>
                  <Divider />
                </>
              );
            })}
          </Tabs>
        </TabsWrapper>
      )}
    </NavBar>
  );
};
