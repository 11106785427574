import { useDispatch } from 'react-redux';

import { Button, Typography } from 'src/components';
import { closeModal } from 'src/store/modal.slice';
import { IModalProps } from 'src/types';
import { ModalBody, ModalFooter } from '../_components';

export const ModalDeleteTeamMember = ({ onCancel, onConfirm, confirmButtonLoading, params }: IModalProps) => {
  const dispatch = useDispatch();

  const confirmHandler = () => {
    onConfirm?.(params);
  };

  const closeHandler = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  return (
    <>
      <ModalBody>
        <Typography color="primary.wlLight">{params?.description}</Typography>
        {!!params?.subDescription && (
          <Typography color="violet.dark" mt="10px">
            {params?.subDescription}
          </Typography>
        )}
      </ModalBody>

      <ModalFooter py="20px" alignToTheEnd noBorder>
        <Button variant="gray" onClick={closeHandler}>
          Cancel
        </Button>
        <Button onClick={confirmHandler} processing={confirmButtonLoading}>{`Remove ${params?.userName ?? ''}`}</Button>
      </ModalFooter>
    </>
  );
};
