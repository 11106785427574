import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getMemberCampaignsStats } from 'src/api';
import { IGetMemberCampaignStatsRequest, IGetMemberCampaignStatsResponse } from 'src/types';

export const useGetMemberCampaignsStats = <TData = IGetMemberCampaignStatsResponse>(
  userId: number,
  params: IGetMemberCampaignStatsRequest,
  options?: UseQueryOptions<IGetMemberCampaignStatsResponse, AxiosError, TData>,
) => {
  return useQuery<IGetMemberCampaignStatsResponse, AxiosError, TData>(
    ['get-team-stats-info', userId, JSON.stringify(params)],
    () => getMemberCampaignsStats(userId, params),
    {
      ...options,
    },
  );
};
