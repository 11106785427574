import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { reschedulePost } from 'src/api';
import { PostStatus } from 'src/enums';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IGetPostsRequest, IGetPostsResponse } from 'src/types';

export const useReschedulePost = (
  postId: number,
  getPostsQueryKeys: IGetPostsRequest,
  options?: UseMutationOptions<void, ICustomAxiosError, { timestamp?: number }>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const getPostsKey = ['get-posts', JSON.stringify(getPostsQueryKeys)];

  const { mutate, ...rest } = useMutation(
    'reschedule-post',
    (data: { timestamp?: number }) => reschedulePost(postId, data),
    {
      ...options,
      onMutate: async (variables) => {
        options?.onMutate?.(variables);
        await queryClient.cancelQueries(getPostsKey);

        const prevData = queryClient.getQueryData(getPostsKey);

        queryClient.setQueryData<IGetPostsResponse | undefined>(getPostsKey, (data) => {
          if (data) {
            return {
              ...data,
              posts: data.posts.map((post) =>
                post.id === postId
                  ? {
                      ...post,
                      status: PostStatus.PENDING,
                      scheduledAt: variables?.timestamp
                        ? new Date(variables.timestamp).toISOString()
                        : new Date().toISOString(),
                    }
                  : post,
              ),
            };
          }

          return data;
        });

        return { prevData };
      },
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        queryClient.invalidateQueries(getPostsKey);
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueryData(getPostsKey, context?.prevData);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return {
    reschedulePost: mutate,
    ...rest,
  };
};
