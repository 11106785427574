import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import {
  Button,
  Link,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
  Typography,
} from 'src/components';
import { useAppSelector, useCompanyTheme } from 'src/hooks';
import { useGetDashboardCampaign } from 'src/reactQueries';
import { updateCampaignsParams } from 'src/store';
import { IGetDashboardParams } from 'src/types';
import { Title, Wrapper } from '../_components';
import { CampaignTableRow } from './CampaignTableRow';

export const CampaignTable = (props: IGetDashboardParams) => {
  const dispatch = useDispatch();
  const { shortCompanyName } = useCompanyTheme();
  const { campaignsParams } = useAppSelector(({ dashboard }) => dashboard);
  const { page, perPage } = campaignsParams;

  const { data, isLoading } = useGetDashboardCampaign({ ...props, ...campaignsParams }, { refetchOnMount: true });
  const { campaigns = [], total } = data ?? {};

  return (
    <Wrapper minHeight="150px">
      <Box display="flex" justifyContent="space-between">
        <Title>Campaigns</Title>
        <Link to="/campaign/stats">
          <Button>Go to Campaigns</Button>
        </Link>
      </Box>
      <Box mt="30px">
        <Table processing={isLoading} disabled={isLoading}>
          <TableHead>
            <TableRow>
              <TableHeadCell width="55px" />
              <TableHeadCell justifyContent="flex-start" width="30%">
                Name
              </TableHeadCell>
              <TableHeadCell justifyContent="center">Contacts</TableHeadCell>
              <TableHeadCell justifyContent="center" tooltip="Total number of days in campaign sequence">
                Days
              </TableHeadCell>
              <TableHeadCell justifyContent="center" tooltip="Total number of steps in this campaign">
                Steps
              </TableHeadCell>
              <TableHeadCell
                justifyContent="center"
                tooltip={`Number of actions performed by ${shortCompanyName} in the selected time filter`}
              >
                Actions
              </TableHeadCell>
              <TableHeadCell
                justifyContent="center"
                tooltip={`Number of replies detected by ${shortCompanyName} in the selected time filter`}
              >
                Replies
              </TableHeadCell>
              <TableHeadCell justifyContent="center">Progress</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody placeholder={!total && <Typography fontSize="16px">No campaigns yet.</Typography>}>
            {campaigns?.map((campaign) => <CampaignTableRow key={campaign.id} {...campaign} />)}
          </TableBody>
        </Table>
        <Box mt="5px">
          <TablePagination
            page={page}
            perPage={perPage}
            total={total}
            hideRowsPerPage
            onPaginationChange={(pagination) => dispatch(updateCampaignsParams(pagination))}
          />
        </Box>
      </Box>
    </Wrapper>
  );
};
