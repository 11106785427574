import { useDispatch } from 'react-redux';

import { HeaderButton } from 'src/components';
import { useExcludeLeads } from 'src/reactQueries';
import {
  setBlacklistConnectionSelectedLeads,
  setBlacklistLeadsSelectedLeads,
  setConnectionSelectedLeads,
  setSelectedLeads,
} from 'src/store';
import { ISelectedLead } from 'src/types';

export const ButtonToggleExclude = ({
  selectedLeads,
  type,
  isBlacklist = false,
}: {
  selectedLeads: ISelectedLead[];
  type: 'connections' | 'leads';
  isBlacklist?: boolean;
}) => {
  const dispatch = useDispatch();
  const { toggleExcludeLeads, isLoading } = useExcludeLeads(type);
  const isExcludedAll = selectedLeads.every(({ isExcluded }) => isExcluded);
  const entityUrns = selectedLeads.map(({ entityUrn }) => entityUrn);

  const onToggleExclude = () => {
    toggleExcludeLeads(
      { entityUrns, isExclude: !isExcludedAll },
      {
        onSuccess: () => {
          const updatedLeads = selectedLeads.map((lead) => ({ ...lead, isExcluded: !isExcludedAll }));

          if (type === 'connections') {
            const updateMethod = isBlacklist ? setBlacklistConnectionSelectedLeads : setConnectionSelectedLeads;
            dispatch(updateMethod(updatedLeads));
          }
          if (type === 'leads') {
            const updateMethod = isBlacklist ? setBlacklistLeadsSelectedLeads : setSelectedLeads;
            dispatch(updateMethod(updatedLeads));
          }
        },
      },
    );
  };

  if (!selectedLeads.length) {
    return null;
  }

  return (
    <HeaderButton variant={isExcludedAll ? 'primary' : 'warning'} processing={isLoading} onClick={onToggleExclude}>
      {isExcludedAll ? 'Include' : 'Exclude'} {selectedLeads.length}
    </HeaderButton>
  );
};
