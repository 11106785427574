import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { updateCampaignSequence } from 'src/api';
import { ICampaign, ICustomAxiosError, IUpdateCampaignSequenceRequest } from 'src/types';

export const useUpdateCampaignSequence = (
  options?: UseMutationOptions<ICampaign, ICustomAxiosError, IUpdateCampaignSequenceRequest>,
) => {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation<ICampaign, ICustomAxiosError, IUpdateCampaignSequenceRequest>(
    ['update-campaign-sequence'],
    updateCampaignSequence,
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await queryClient.cancelQueries(['campaign', data.id]);

        queryClient.setQueryData<ICampaign>(['campaign', data.id], () => data);
        queryClient.invalidateQueries(['get-campaigns', data.category, 'draft']);
        options?.onSuccess?.(data, variables, context);
      },
    },
  );

  return { updateCampaignSequence: mutate, ...rest };
};
