import { isEqual } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Checkbox, Table, TableBody, TableHead, TableHeadCell, TablePagination, TableRow } from 'src/components';
import { DEFAULT_PAGE, LEADS_SORTABLE_COLUMNS } from 'src/constants';
import { checkLeadsFilter } from 'src/helpers';
import { useAppSelector } from 'src/hooks';
import { usePostLeads, useUpdateLeadsColumnsPosition } from 'src/reactQueries';
import {
  setBlacklistLeadsSelectedLeads,
  setSelectedLeads,
  updateBlacklistLeadsRequestParams,
  updateLeadsRequestParams,
} from 'src/store';
import { IConvertedLead, ILeadColumnsPosition, ISelectedLead, ITablePagination } from 'src/types';
import { RearrangeColumns } from '../_components';
import { LeadsFilters } from './LeadsFilters';
import { LeadsListItem } from './LeadsListItem';

const StyledTable = styled(Table)`
  border-collapse: separate;
`;

interface LeadsListProps {
  isExcluded?: boolean;
  openLeadInfo: (entityUrn?: string) => void;
}

export const LeadsList = memo(({ isExcluded = false, openLeadInfo }: LeadsListProps) => {
  const dispatch = useDispatch();

  const {
    request,
    exportData: { selectedLeads },
    columns,
  } = useAppSelector(
    (state) =>
      isExcluded
        ? {
            columns: state.lead.leadColumns,
            request: state.blacklistLeads.request,
            exportData: state.blacklistLeads.exportData,
          }
        : {
            columns: state.lead.leadColumns,
            request: state.lead.request,
            exportData: state.lead.exportData,
          },
    isEqual,
  );
  // const navigate = useNavigate();

  const { page, perPage, sortField, sortOrder, selectedOnly } = request;

  const viewedLeadColumns = useMemo(() => columns.slice(0, 5), [columns]);
  const isLeadsFiltered = useMemo(() => checkLeadsFilter(request, isExcluded), [request, isExcluded]);

  const updateParamsMethod = useMemo(
    () => (isExcluded ? updateBlacklistLeadsRequestParams : updateLeadsRequestParams),
    [isExcluded],
  );
  const updateSelectedMethod = useMemo(
    () => (isExcluded ? setBlacklistLeadsSelectedLeads : setSelectedLeads),
    [isExcluded],
  );

  const { updateLeadsColumnsPosition } = useUpdateLeadsColumnsPosition();

  const { data, isLoading } = usePostLeads(
    {
      ...request,
      search: request?.search?.trim(),
      entityUrns: selectedOnly ? selectedLeads.map((lead) => lead.entityUrn) : [],
    },
    {
      refetchOnMount: true,
      onSuccess: (data) => {
        if (isExcluded && !data.leads?.length && request.page && request?.page > DEFAULT_PAGE) {
          dispatch(updateParamsMethod({ page: DEFAULT_PAGE }));
        }
      },
    },
  );

  const totalLeads = data?.total;

  const leads = useMemo<IConvertedLead[]>(
    () =>
      data?.leads?.map((lead) => ({
        entityUrn: lead?.person?.key,
        picture: lead.person?.linkedInData?.pic,
        publicIdentifier: lead.person?.linkedInHandle,
        twitterHandle: lead.person?.twitterHandle,
        isExcluded: Boolean(lead.isExcluded),
        name: lead.person?.isEmailOnlyPerson ? lead.person?.workEmail : lead.person?.name,
        company: lead.person?.currentEmployer,
        title: lead.person?.currentTitle,
        campaign: lead.campaign?.name,
        campaignData: lead.campaign,
        conversationUrn: lead?.conversationUrn,
        isPremium: Boolean(lead?.isPremium),
        action: lead.lastAction?.description,
        actionTime: lead.lastAction?.createdAt,
        connected: Boolean(lead.userNetwork),
        tags: lead.tags,
        notes: lead.userNetwork?.notes || '',
      })) ?? [],
    [data?.leads],
  );

  const includeEntityUrn = useCallback(
    (entityUrn?: string) => !!entityUrn && selectedLeads.some((lead) => lead.entityUrn === entityUrn),
    [selectedLeads.length],
  );

  const selectedLeadsOnCurrentPage = useMemo(
    () => leads?.map(({ entityUrn, isExcluded }) => ({ entityUrn, isExcluded })) || [],
    [leads],
  );
  const isEverySelected = useMemo(
    () => selectedLeadsOnCurrentPage.every(({ entityUrn }) => includeEntityUrn(entityUrn)),
    [selectedLeadsOnCurrentPage],
  );
  const isSelectedAll = !!totalLeads && isEverySelected;

  const sortingProps = {
    sortField,
    sortOrder,
    onSortFieldChange: (field: string, order: string) => {
      dispatch(updateParamsMethod({ sortField: field, sortOrder: order }));
    },
  };

  const paginationChangeHandler = useCallback(
    (pagination: ITablePagination) => dispatch(updateParamsMethod(pagination)),
    [updateParamsMethod],
  );

  const onSelectAllCheckboxChange = () => {
    if (isSelectedAll) {
      return dispatch(
        updateSelectedMethod(
          selectedLeads.filter(
            ({ entityUrn }) => !selectedLeadsOnCurrentPage.some((lead) => lead.entityUrn === entityUrn),
          ),
        ),
      );
    }

    return dispatch(
      updateSelectedMethod([
        ...selectedLeads,
        ...selectedLeadsOnCurrentPage.filter(({ entityUrn }) => !includeEntityUrn(entityUrn)),
      ]),
    );
  };

  const onCheckboxChange = (selectLead: ISelectedLead) => {
    if (includeEntityUrn(selectLead.entityUrn)) {
      return dispatch(updateSelectedMethod(selectedLeads.filter((lead) => lead.entityUrn !== selectLead.entityUrn)));
    }

    return dispatch(updateSelectedMethod([...selectedLeads, selectLead]));
  };

  return (
    <>
      <LeadsFilters isBlacklist={isExcluded} />
      <Box p="20px 15px 80px" maxWidth="calc(100% - 310px)" overflow="scroll" width="100%">
        <StyledTable disabled={false} processing={isLoading}>
          <TableHead>
            <TableRow>
              <TableHeadCell width="40px">
                <Checkbox
                  checkIcon={selectedLeads.length > 0 && isSelectedAll ? 'check-mark' : 'dash'}
                  data-testid="main-checkbox"
                  checked={selectedLeads?.length > 0}
                  onChange={onSelectAllCheckboxChange}
                />
              </TableHeadCell>
              <TableHeadCell width="55px" />
              {viewedLeadColumns.map(({ key, label }) => {
                const sortingColumn = LEADS_SORTABLE_COLUMNS.find((column) => column.key === key);

                return (
                  <TableHeadCell
                    width="250px"
                    key={key}
                    {...(sortingColumn?.fieldName && {
                      name: sortingColumn.fieldName,
                      sorting: sortingProps,
                    })}
                  >
                    {label}
                  </TableHeadCell>
                );
              })}
              <TableHeadCell width="134px" sticky justifyContent="flex-end">
                <RearrangeColumns
                  columns={columns}
                  separator={4}
                  onChange={(result) => updateLeadsColumnsPosition(result as ILeadColumnsPosition[])}
                />
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody
            placeholder={!leads.length && (isLeadsFiltered ? 'No profiles meet your search criteria' : 'No leads yet.')}
          >
            {leads.map((lead) => (
              <LeadsListItem
                key={lead?.entityUrn}
                lead={lead}
                columns={viewedLeadColumns}
                isSelected={includeEntityUrn(lead?.entityUrn)}
                onCheckboxClick={onCheckboxChange}
                onItemClick={openLeadInfo}
              />
            ))}
          </TableBody>
        </StyledTable>
        <TablePagination
          page={page}
          perPage={perPage}
          total={totalLeads}
          onPaginationChange={paginationChangeHandler}
          SelectProps={{
            MenuProps: {
              anchorOrigin: { vertical: 'top', horizontal: 'center' },
              transformOrigin: { vertical: 'bottom', horizontal: 'center' },
            },
          }}
        />
      </Box>
    </>
  );
});
