import { Control, Controller } from 'react-hook-form';
import { Box } from '@mui/material';

import { Checkbox } from 'src/components';

interface ICampaignCheckboxProps {
  name: string;
  label?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
}

export const ControlledCheckbox = ({ name, label, control }: ICampaignCheckboxProps) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Box position="relative" width="100%" pt="15px" pb="10px">
          <Box display="flex" alignItems="center">
            <Checkbox id={name} checked={Boolean(value)} type="checkbox" onChange={onChange} />
            <label htmlFor={name}>{label}</label>
          </Box>
        </Box>
      )}
    />
  );
};
