import { PropsWithChildren } from 'react';
import styled from '@emotion/styled';
import { Avatar, AvatarProps, Box, BoxProps } from '@mui/material';

const StyedAvatar = styled(Avatar)<{ cursor: string }>`
  text-shadow: 1px 1px 0 #000;
  cursor: ${({ cursor }) => cursor};

  img {
    object-fit: contain;
  }
`;

export interface IProfileAvatarProps extends BoxProps {
  href?: string | false;
  avatarSrc?: string;
  avatarProps?: AvatarProps;
  firstLatter?: string;
  cursor?: 'pointer' | 'default';
}

export const ProfileAvatar = ({
  href,
  avatarProps,
  avatarSrc,
  children,
  firstLatter = '',
  cursor,
  width = '34px',
  height = '34px',
  ...props
}: PropsWithChildren<IProfileAvatarProps>) => {
  const avatar = (
    <StyedAvatar
      sx={{
        width,
        height,
      }}
      cursor={cursor || href ? 'pointer' : 'default'}
      {...avatarProps}
      src={avatarSrc || ''}
    >
      {firstLatter.toUpperCase() ?? children}
    </StyedAvatar>
  );

  return <Box {...props}> {href ? <a href={href}>{avatar}</a> : avatar}</Box>;
};
