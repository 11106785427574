import dayjs from 'dayjs';

import { LinkedInRestrictionType } from 'src/enums';
import { IUser } from 'src/types';

export const checkUserIsInVerificationStatus = (data?: IUser): boolean =>
  [
    'LOGGED_IN_VERIFICATION',
    'LOGGED_IN_VERIFICATION_PHONE',
    'LOGGED_IN_VERIFICATION_EMAIL',
    'LOGGED_IN_VERIFICATION_2FA',
  ].includes(data?.cloudBotStatus?.loginStatus || '');

export const checkLinkedInVerificationCodeIsActive = (data?: IUser): boolean =>
  !!data?.cloudBotStatus?.browserId &&
  !!data?.cloudBotStatus?.verificationCodeExpiresAt &&
  dayjs().isBefore(data?.cloudBotStatus?.verificationCodeExpiresAt);

export const checkUserIsLoggedIn = (data?: IUser): boolean =>
  !!data?.isLinkedInLoginValid && data?.cloudBotStatus?.loginStatus === 'LOGGED_IN';

export const checkLILoginIsInProgress = (data?: IUser): boolean => {
  const { isLinkedinActive, cloudBotStatus, isCloudBotInLoginProcess } = data || {};

  if (isCloudBotInLoginProcess) return true;

  // check if LI credentials exist
  if (!isLinkedinActive) return false;

  return !cloudBotStatus || !!cloudBotStatus?.trying_login;
};

export const checkShowLIIntegrationError = (data?: IUser): boolean => {
  const { isLinkedinActive, cloudBotStatus, isCloudBotInLoginProcess } = data || {};

  if (isCloudBotInLoginProcess) return false;

  // check if LI credentials exist
  if (!isLinkedinActive) return false;

  // check cloud bot status is not null
  if (!cloudBotStatus) return false;

  // check if LI login is not in process
  if (checkLILoginIsInProgress(data)) return false;

  // check if LI is successfully connected
  if (checkUserIsLoggedIn(data)) return false;

  // check if user is in verification status and verification code did not expire
  if (checkUserIsInVerificationStatus(data) && checkLinkedInVerificationCodeIsActive(data)) return false;

  return true;
};

export const checkLinkedInIsConnected = (data?: IUser): boolean => {
  const { isLinkedinActive, isCloudBotInLoginProcess } = data || {};

  if (isCloudBotInLoginProcess) return false;

  // check if LI credentials exist
  if (!isLinkedinActive) return false;

  // check if LI login is not in process
  if (checkLILoginIsInProgress(data)) return false;

  return !!data?.isLinkedinActive;
};

export const checkLinkedInIsConnectedSuccessfully = (data?: IUser): boolean => {
  const { isLinkedinActive, isCloudBotInLoginProcess } = data || {};

  if (isCloudBotInLoginProcess) return false;

  // check if LI credentials exist
  if (!isLinkedinActive) return false;

  // check if LI login is not in process
  if (checkLILoginIsInProgress(data)) return false;

  return checkUserIsLoggedIn(data);
};

export const checkLinkedInRestrictionWarningDetected = (data?: IUser): boolean => {
  const { isLinkedinActive, isCloudBotInLoginProcess, cloudBotStatus } = data || {};

  if (isCloudBotInLoginProcess) return false;

  // check if LI credentials exist
  if (!isLinkedinActive) return false;

  return (
    !!cloudBotStatus?.warningType &&
    [LinkedInRestrictionType.NOTICE, LinkedInRestrictionType.PERMANENT, LinkedInRestrictionType.TEMPORAL].includes(
      cloudBotStatus.warningType,
    )
  );
};
