import {
  IConnectionsFilter,
  IConnectionsPostParameters,
  ILeadsFilters,
  ILeadsPostParameters,
  IParsedValueOption,
} from 'src/types';

export const checkConnectionsFilter = (filters: IConnectionsFilter, skipExcluded = false) => {
  const { isPremium, isOpenLink, email, phone, skype, twitter, isExcluded, search, selectedOnly } = filters;
  const { firstName, lastName, companyName, title, geography, industryCode, languages, tags } = filters;

  const checkboxFilters = [isPremium, isOpenLink, email, phone, skype, twitter, selectedOnly];
  if (!skipExcluded) {
    checkboxFilters.push(isExcluded);
  }
  const checkboxesFiltered = checkboxFilters.includes(true);

  const fieldsFiltered = [firstName, lastName, companyName, title, geography, industryCode, languages, tags].some(
    (field) => field?.length,
  );

  return checkboxesFiltered || fieldsFiltered || !!search?.trim();
};
export const checkLeadsFilter = (filters: ILeadsFilters, skipExcluded = false) => {
  const {
    name,
    currentTitle,
    currentEmployer,
    location,
    campaignIds,
    tags,
    isExcluded,
    search,
    connectedOnly,
    selectedOnly,
  } = filters;

  const checkboxFilters = [connectedOnly, selectedOnly];
  if (!skipExcluded) {
    checkboxFilters.push(isExcluded);
  }
  const checkboxesFiltered = checkboxFilters.includes(true);

  const fieldsFiltered = [name, currentTitle, currentEmployer, location, campaignIds, tags].some(
    (field) => field?.length,
  );

  return fieldsFiltered || checkboxesFiltered || !!search?.trim();
};

export const parseContactsFilter = (filters: IParsedValueOption[] = [], toNumber = false) => {
  const include = filters.filter((item) => !item.exclude).map((item) => (toNumber ? Number(item.value) : item.value));
  const exclude = filters.filter((item) => item.exclude).map((item) => (toNumber ? Number(item.value) : item.value));

  return { include, exclude };
};

export const convertLeadsFilterToRequest = (filter: ILeadsFilters): ILeadsPostParameters => {
  return {
    ...filter,
    search: filter.search?.trim(),
    name: parseContactsFilter(filter.name),
    currentTitle: parseContactsFilter(filter.currentTitle),
    currentEmployer: parseContactsFilter(filter.currentEmployer),
    location: parseContactsFilter(filter.location),
    campaignIds: parseContactsFilter(filter.campaignIds, true),
    tags: parseContactsFilter(filter.tags, true),
  };
};

export const convertConnectionFilterToRequest = (filter: IConnectionsFilter): IConnectionsPostParameters => {
  return {
    ...filter,
    search: filter.search?.trim(),
    firstName: parseContactsFilter(filter.firstName),
    lastName: parseContactsFilter(filter.lastName),
    title: parseContactsFilter(filter.title),
    companyName: parseContactsFilter(filter.companyName),
    geography: parseContactsFilter(filter.geography),
    languages: parseContactsFilter(filter.languages),
    industryCode: parseContactsFilter(filter.industryCode, true),
    tags: parseContactsFilter(filter.tags, true),
    campaignIds: parseContactsFilter(filter.campaignIds, true),
  };
};
