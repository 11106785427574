import classNames from 'classnames';
import isValidDomain from 'is-valid-domain';
import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import styled from '@emotion/styled';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import {
  Button,
  FormInput,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from 'src/components';
import { useWhiteLabel } from 'src/hooks/useWhiteLabel';
import {
  useCreateWhiteLabelDomain,
  useDeleteWhiteLabelDomain,
  useDeleteWhiteLabelSMTP,
  useVerifyDns,
} from 'src/reactQueries';
import { openModal, showToast } from 'src/store';
import { Accordion, AccordionDetails, AccordionSummary } from '../_components';

const CopyIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  margin-left: 10px;
`;

const STEPS = [
  'Enter the Custom Domain name in the field below',
  'Wait for MeetAlfred to generate DNS recrods for you',
  'Set up the DNS records with your domain management system',
  'Wait for MeetAlfred to verify the records are added and working',
  'Access your MeetAlfred app via the Custom Domain',
];

interface IForm {
  domain: string;
}

const defaultValues: IForm = {
  domain: '',
};

export const CustomDomain = () => {
  const dispatch = useDispatch();
  const { showNewMessage } = useIntercom();
  const { whitelabel, isLoading } = useWhiteLabel();
  const { withBranding, withCustomDomain, isDomainValidated, domainSettings, isAdvancedSettingsSetupAllowed } =
    whitelabel;
  const [expanded, setExpanded] = useState(!!isAdvancedSettingsSetupAllowed && withBranding && !withCustomDomain);
  const { reset, register, setValue, getValues, handleSubmit, formState } = useForm<IForm>({
    defaultValues: whitelabel,
  });

  const { verifyDns, isVerifyLoading } = useVerifyDns();
  const { createDomain, isDomainLoading } = useCreateWhiteLabelDomain();
  const { deleteSMTP, isSMTPLoading } = useDeleteWhiteLabelSMTP();
  const { deleteDomain, isDomainDeleteLoading } = useDeleteWhiteLabelDomain({
    onSuccess: () => {
      reset(defaultValues);

      deleteSMTP();
    },
  });

  const showWaitValidationMessage = useMemo<boolean>(() => {
    return (
      !!whitelabel.domain &&
      !whitelabel.configurationMessage &&
      !isDomainValidated &&
      !!domainSettings?.length &&
      !!domainSettings?.every((record) => record.isValidated)
    );
  }, [whitelabel]);

  const onAddDomain = ({ domain }: IForm) => {
    dispatch(
      openModal({
        headerText: 'Apply Custom Domain',
        descriptionText:
          'Are you sure you want to continue?<br /><br />This action will replace the current domain with your chosen domain for accessing the platform and will result in the automatic logout of additional account users. Are you certain you want to proceed with this setup?',
        confirmButtonLabel: 'Yes, proceed',
        confirmButtonLoading: isDomainLoading,
        onConfirm: () => createDomain(domain),
      }),
    );
  };

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
    dispatch(showToast({ message: 'Copied', type: 'success', autoCloseTime: 1500 }));
  };

  const onDeleteDomainClick = () => {
    dispatch(
      openModal({
        headerText: 'Are you sure you want to remove Domain Settings?',
        descriptionText:
          'Your Email SMTP settings will be removed also. Your team members will no longer have access to the app from custom domain',
        onConfirm: deleteDomain,
      }),
    );
  };

  return (
    <Accordion expanded={expanded} onChange={() => isAdvancedSettingsSetupAllowed && setExpanded((prev) => !prev)}>
      <AccordionSummary
        withButtonWrapper={isAdvancedSettingsSetupAllowed}
        actionButton={{
          // eslint-disable-next-line no-undefined
          icon: isAdvancedSettingsSetupAllowed ? undefined : faLock,
          disabled: !isAdvancedSettingsSetupAllowed,
          tooltip: !isAdvancedSettingsSetupAllowed && 'Please contact support',
        }}
        expanded={expanded}
        title="Custom Domain"
        status={withCustomDomain && isDomainValidated ? 'applied' : 'pending'}
      >
        {isAdvancedSettingsSetupAllowed || (
          <Button variant="secondary" onClick={() => showNewMessage('I want help with White Label')}>
            Contact Support
          </Button>
        )}
        {withCustomDomain && (
          <Button
            variant="warning"
            processing={isDomainLoading || isDomainDeleteLoading || isSMTPLoading}
            onClick={onDeleteDomainClick}
          >
            Remove Domain
          </Button>
        )}
      </AccordionSummary>

      <AccordionDetails>
        <Box>
          <Typography>
            Make your customers access MeetAlfred app at your custom domain instead of app.meetalfred.com.
          </Typography>
          <Typography fontSize="16px" mt="16px" color="#000" semibold>
            Steps:
          </Typography>
          {STEPS.map((description, index) => (
            <Typography key={description} mt="12px">
              <b>{index + 1}.</b> {description}
            </Typography>
          ))}

          <Box display="flex" gap="16px" alignItems="flex-end" mt="24px">
            <FormInput
              name="domain"
              label="Custom Domain"
              placeholder="app.meetalfred.com"
              register={register}
              parameters={{
                required: 'Please enter a Custom Domain',
                validate: (domain) => isValidDomain(domain) || 'Please enter a valid domain',
                onBlur: () => setValue('domain', getValues('domain').trim()),
              }}
              className={classNames({ error: formState.errors.domain })}
            />
            <Button
              tooltip={{ title: withBranding ? '' : 'Please add Branding first' }}
              disabled={!withBranding}
              processing={isDomainLoading}
              onClick={handleSubmit(onAddDomain)}
            >
              Apply Custom Domain
            </Button>
          </Box>
          <Typography color="error.light" medium mt="10px" mb="5px">
            {whitelabel.isDomainRecordsValidationFailed ? (
              <>
                You are not allowed to set such domain here, as a fraud-protection measure. <br />
                If you are sure this is a domain of your ownership and you still would like to proceed, please contact
                our support.
              </>
            ) : (
              formState.errors.domain?.message
            )}
          </Typography>

          {whitelabel.configurationMessage && (
            <Typography color="error.light" medium mt="10px" mb="5px">
              {whitelabel.configurationMessage}
            </Typography>
          )}
          {showWaitValidationMessage && (
            <Typography color="error.light" medium mt="10px" mb="5px">
              Please wait up to 30 minutes for the while the domain is in the validation process
            </Typography>
          )}
        </Box>
        <Box maxWidth="80%">
          <Table processing={isLoading}>
            <TableHead>
              <TableRow>
                <TableHeadCell width="100px">TYPE</TableHeadCell>
                <TableHeadCell>HOST/NAME</TableHeadCell>
                <TableHeadCell>TARGET/VALUE</TableHeadCell>
                <TableHeadCell width="80px">TTL</TableHeadCell>
                <TableHeadCell width={isDomainValidated ? '100px' : '165px'}>
                  Status{' '}
                  {isDomainValidated ||
                    (!!domainSettings?.length && (
                      <Typography
                        underline
                        medium
                        pointer
                        color="#002F53"
                        textTransform="none"
                        onClick={() => isVerifyLoading || verifyDns()}
                      >
                        Check Now
                      </Typography>
                    ))}
                </TableHeadCell>
              </TableRow>
            </TableHead>

            <TableBody placeholder={!domainSettings?.length && 'No Custom Domain yet'}>
              {domainSettings?.map(({ host, isValidated, isValidationFailed, type, value }) => {
                const getStatus = () => {
                  switch (true) {
                    case isVerifyLoading:
                      return <Typography color="#8C8C8C">Checking...</Typography>;
                    case isValidated:
                      return <Typography color="success.main">Verified</Typography>;
                    case isValidationFailed:
                      return <Typography color="error.main">Failed</Typography>;

                    default:
                      return <Typography color="#E47D44">Pending</Typography>;
                  }
                };

                return (
                  <TableRow key={host}>
                    <TableCell>{type}</TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Typography crop> {host}</Typography> <CopyIcon icon={faCopy} onClick={() => copyText(host)} />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Typography crop> {value}</Typography>{' '}
                        <CopyIcon icon={faCopy} onClick={() => copyText(value)} />
                      </Box>
                    </TableCell>
                    <TableCell>600</TableCell>
                    <TableCell contentStyle={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                      {getStatus()}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
        <Box mt="15px">
          <Typography semibold>Note:</Typography>
          <Typography mt="10px">
            As MeetAlfred runs in secure (https) mode we generate an SSL certificate for your custom domain. If you have
            an SSL set up through Cloudflare we recommend to disable the orange cloud SSL for Cloudflare and just use
            the SSL on our side. Otherwise we likely won't be able to generate/renew the cert and your custom domain
            will not work or will stop working in the future.
          </Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
