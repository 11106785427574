import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { SummaryBoard } from 'src/containers/SingleCampaign/_components';
import { useSingleCampaignProps } from 'src/hooks';
import {
  useGetCampaign,
  useGetCampaignLeadSources,
  useGetCampaignSequenceProgress,
  useRestartSearchCampaign,
} from 'src/reactQueries';
import { CampaignIcon, CampaignSequenceProgress, SequenceStep } from '../_components';
import { DetailsLayout } from './DetailsLayout';

const Container = styled(Box)`
  box-shadow: 0 1px 1px 0 ${({ theme }) => theme.palette.light.dark};
  border-radius: 5px;
  background-color: white;
  padding: 20px;
`;

export const Overview = () => {
  const { campaignId } = useParams();
  const CAMPAIGN_ID = Number(campaignId);
  const { campaign } = useGetCampaign(CAMPAIGN_ID);
  const { data: leadSources = [] } = useGetCampaignLeadSources(CAMPAIGN_ID);
  const sequences = campaign?.touchSequence?.sequence;

  const { category, generalCampaignTypeLabel } = useSingleCampaignProps();

  const { data } = useGetCampaignSequenceProgress(CAMPAIGN_ID, { refetchOnMount: true });

  const { restartSearch } = useRestartSearchCampaign({ campaignId: CAMPAIGN_ID, category });

  const {
    searchUrl,
    campaignType,
    status,
    excludeNoPhotos,
    salesNavigatorUrl,
    noFirstConnections,
    openLinkOnly,
    premiumOnly,
    includeMessageOnly,
    maxSearchAmount = 1,
    isSearchEnded,
    retargetLeads,
  } = campaign ?? {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <DetailsLayout showAddMoreLeads>
      <Box display="grid" gridTemplateColumns="1fr 1fr" padding="20px 15px" gap="30px">
        <Box>
          <Typography color="primary.wlLight">Sequence</Typography>
          <Box mt="20px">
            {sequences?.map((sequence, index) => (
              <SequenceStep
                key={index}
                index={index}
                sequence={sequence}
                highlight={sequences.length !== 1 && index !== sequences.length - 1}
                showCompletedLeads
                completedLeads={data?.sequenceProgress?.[index + 1] || 0}
              />
            ))}
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" gap="20px">
          <Typography color="primary.wlLight">Audience</Typography>
          <Container display="flex" alignItems="center">
            <CampaignIcon type={category} size="medium" />
            <Box ml="20px">
              <Typography fontWeight="300">Campaign type</Typography>
              <Typography height="21px" semibold>
                {generalCampaignTypeLabel}
              </Typography>
            </Box>
          </Container>

          {campaign && campaign?.status !== 'draft' && (
            <>
              <Typography color="primary.wlLight">Campaign Sequence Progress</Typography>
              <CampaignSequenceProgress />
            </>
          )}

          <Box>
            <SummaryBoard
              campaignType={campaignType}
              campaignStatus={status}
              options={{
                excludeNoPhotos,
                noFirstConnections,
                premiumOnly,
                openLinkOnly,
                includeMessageOnly,
                retargetLeads,
              }}
              searchUrl={searchUrl || salesNavigatorUrl}
              maxSearchAmount={maxSearchAmount}
              isSearchEnded={isSearchEnded}
              onSearchRestart={() => restartSearch({ restartCampaign: true })}
            />
            {leadSources?.map((source) => (
              <Box key={source.id} mt="20px">
                <SummaryBoard
                  isOverviewSummary
                  campaignStatus={status}
                  campaignType={source.searchType}
                  options={{
                    excludeNoPhotos: source.excludeNoPhotos,
                    noFirstConnections: source.noFirstConnections,
                    premiumOnly: source.premiumOnly,
                    openLinkOnly: source.openLinkOnly,
                    retargetLeads: source.retargetLeads,
                  }}
                  searchUrl={source.searchUrl}
                  maxSearchAmount={source.maxSearchAmount}
                  isSearchEnded={source.isSearchEnded}
                  onSearchRestart={() => restartSearch({ leadSourceIds: [source.id] })}
                />
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </DetailsLayout>
  );
};
