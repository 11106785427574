import styled from '@emotion/styled';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Box } from '@mui/material';

import globeIcon from 'src/assets/icons/globe.svg';
import { getLinkedinProfile } from 'src/helpers';
import { useAppSelector } from 'src/hooks';
import { useGetLinkedInProfile } from 'src/reactQueries';
import { IPostAttachment, TSchedulePost } from 'src/types';
import {
  AttachmentImage,
  AttachmentVideo,
  PostPreviewText,
  PreviewContainer,
  PreviewSocialIcon,
  SocialAvatar,
  SocialAvatarContainer,
  UserDataContainer,
  UserName,
} from '../../_components';

const OnlineBadge = styled.div`
  background: #057642;
  width: 12px;
  height: 12px;
  position: absolute;
  bottom: 2px;
  right: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #fff;
`;

const AttachmentFrame = styled.iframe`
  max-height: 1350px;
  max-width: 100%;
  border-radius: 10px;
  display: block;
  box-shadow: 0 0 1px #2f3336;
`;

export const LinkedIn = ({ postData }: { postData: TSchedulePost }) => {
  const user = useAppSelector((state) => state.user.profile);
  const { linkedinProfile } = useGetLinkedInProfile();

  const { profileImage, userName, occupation } = getLinkedinProfile(postData.postAs, user.companies, linkedinProfile);

  return (
    <PreviewContainer>
      <PreviewSocialIcon color="#0a66c2" icon={faLinkedin} />
      <Box display="flex" alignItems="center" mb="20px">
        <SocialAvatarContainer>
          <SocialAvatar src={profileImage} />
          <OnlineBadge />
        </SocialAvatarContainer>
        <UserDataContainer ml="10px">
          <UserName data-testid="linked-username">{userName}</UserName>
          <Box mb="3px">{occupation}</Box>
          <Box display="flex">
            <span>now</span>
            <Box mx="5px">•</Box>
            <img src={globeIcon} />
          </Box>
        </UserDataContainer>
      </Box>
      <div>
        <PostPreviewText content={postData.content} />
        {postData.attachments?.map((attachment: IPostAttachment) => {
          const fileType = attachment.file_type;

          if (fileType.includes('image')) {
            return (
              <AttachmentImage
                data-testid="image-attachment"
                src={attachment.file_uri}
                key={`attachment-${attachment.file_uri}`}
              />
            );
          }

          if (fileType.includes('video')) {
            return (
              <AttachmentVideo
                data-testid="video-attachment"
                key={`attachment-${attachment.file_uri}`}
                src={attachment.file_uri}
                controls
              ></AttachmentVideo>
            );
          }

          if (fileType === 'application/pdf') {
            return (
              <AttachmentFrame
                data-testid="pdf-attachment"
                src={attachment.file_uri}
                key={`attachment-${attachment.file_uri}`}
              />
            );
          }

          return (
            <AttachmentFrame
              data-testid="document-attachment"
              key={`attachment-${attachment.file_uri}`}
              src={`https://docs.google.com/gview?url=${attachment.file_uri}&embedded=true`}
            />
          );
        })}
      </div>
    </PreviewContainer>
  );
};
