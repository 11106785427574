import { CampaignCategory } from 'src/enums';
import { addValueAndLabel } from 'src/transformers';
import {
  IArchiveCampaignRequest,
  IAutocomplete,
  ICampaign,
  ICampaignAudienceRequest,
  ICampaignProgress,
  ICampaignSearchItem,
  ICampaignSequenceProgress,
  ICreateLeadSourcesBody,
  IExportCampaignActivityCSVRequest,
  IExportCSVCampaignRequest,
  IExportRepliesCSVRequest,
  IGetCampaign,
  IGetCampaignActivity,
  IGetCampaignActivityRequest,
  IGetCampaignChart,
  IGetCampaignListItemParams,
  IGetCampaignReplies,
  IGetCampaignRepliesRequest,
  IGetCampaignResponse,
  IGetCampaigns,
  IGetCampaignsCount,
  IGetCampaignsRequest,
  IGetCampaignsSearchListRequest,
  IGetDashboardCampaign,
  IGetDashboardParams,
  IGetDashboardStatistic,
  IGetDetailsActivityRequest,
  IGetInvitesRequest,
  IGetInvitesResponse,
  IInvite,
  ILeadSource,
  IPagination,
  IPublishCampaignResponse,
  IRestartSearchCampaignRequest,
  ISNSavedSearch,
  IUpdateCampaignNameRequest,
  IUpdateCampaignSequenceRequest,
  IUpdateInviteStatus,
  TCampaignRunState,
} from 'src/types';
import { appApi } from './axios';

export const getCampaign = (id: number): Promise<IGetCampaign> =>
  appApi.get(`/campaigns/${id}`, { params: { countIgnoredLeads: true } });

export const getCampaignChart = (id: number): Promise<IGetCampaignChart> =>
  appApi.get(`/campaigns/${id}/activity/chart`);

export const getCampaigns = (params: IGetCampaignsRequest): Promise<IGetCampaignResponse> =>
  appApi.get(`/campaigns`, { params });

export const getCampaignsCount = (category?: CampaignCategory): Promise<IGetCampaignsCount> =>
  appApi.get(`/campaigns/counts`, { params: { category } });

export const createCampaignAudience = (body: Omit<ICampaign, 'id' | 'status' | 'touchSequence'>): Promise<ICampaign> =>
  appApi.post(`/campaigns/audience`, body);

export const createCampaignFromLinkedinCsv = (body: ICampaignAudienceRequest): Promise<ICampaign> =>
  appApi.post(`/campaigns/linked-in/audience/csv`, body);

export const createCampaignFromEmailCsv = (body: ICampaignAudienceRequest): Promise<ICampaign> =>
  appApi.post(`/campaigns/email/audience/csv`, body);

export const createCampaignFromTwitterCsv = (body: ICampaignAudienceRequest): Promise<ICampaign> =>
  appApi.post(`/campaigns/twitter/audience/csv`, body);

export const updateCampaignFromCsvUpload = (
  generalCampaignType: string,
  id: number,
  body: ICampaignAudienceRequest,
): Promise<ICampaign> => appApi.patch(`/campaigns/${generalCampaignType}/${id}/audience/csv`, body);

export const createCampaignFromConnections = (
  generalCampaignType: string,
  body: ICampaignAudienceRequest,
): Promise<ICampaign> => appApi.post(`/campaigns/${generalCampaignType}/audience/connections`, body);

export const updateCampaignFromConnections = (
  generalCampaignType: string,
  id: number,
  body: ICampaignAudienceRequest,
): Promise<ICampaign> => appApi.patch(`/campaigns/${generalCampaignType}/${id}/audience/connections`, body);

export const publishCampaign = (id: number, body: { name: string }): Promise<IPublishCampaignResponse> =>
  appApi.post(`/campaigns/${id}/publish`, body);

export const updateCampaignAudience = (
  id: number,
  body: Omit<ICampaign, 'id' | 'status' | 'touchSequence'>,
): Promise<ICampaign> => appApi.patch(`/campaigns/${id}/audience`, body);

export const updateCampaignRunningState = (id: number, state: TCampaignRunState): Promise<ICampaign> =>
  appApi.patch(`/campaigns/${id}/running-state`, {
    runState: state,
  });

export const updateCampaignSequence = ({ campaignId, body }: IUpdateCampaignSequenceRequest): Promise<ICampaign> =>
  appApi.patch(`/campaigns/${campaignId}/sequence`, {
    touchSequence: body,
  });

export const archiveCampaign = ({ campaignId, body }: IArchiveCampaignRequest): Promise<ICampaign> =>
  appApi.patch(`/campaigns/${campaignId}/archive`, body);

export const updateCampaignName = ({ campaignId, body }: IUpdateCampaignNameRequest): Promise<ICampaign> =>
  appApi.patch(`/campaigns/${campaignId}/name`, body);

export const deleteCampaign = (id: number): Promise<void> => appApi.delete(`/campaigns/${id}`);

export const restartSearchCampaign = (id: number, params: IRestartSearchCampaignRequest): Promise<void> =>
  appApi.patch(`/campaigns/${id}/restart-search`, params);

export const exportCSVCampaign = ({ campaignId, body }: IExportCSVCampaignRequest): Promise<ICampaign> =>
  appApi.post(`/leads/campaign/${campaignId}/csv`, body);

export const exportCSVReplies = (body: IExportRepliesCSVRequest): Promise<void> =>
  appApi.post(`/campaigns/replies/csv`, body);

export const duplicateCampaign = (id: number): Promise<IGetCampaign> => appApi.post(`/campaigns/${id}/clone`);

export const addMoreLeadsCampaign = (body: ICreateLeadSourcesBody): Promise<ILeadSource> =>
  appApi.post(`/lead-sources`, body);

export const getCampaignLeadSources = (campaignId: number): Promise<ILeadSource[]> =>
  appApi.get(`/lead-sources/campaign/${campaignId}`);

export const getCampaignInvites = (params: IGetInvitesRequest): Promise<IGetInvitesResponse> =>
  appApi.get(`/invites`, { params });

export const updateCampaignInviteStatus = (body: IUpdateInviteStatus): Promise<IInvite[]> =>
  appApi.post(`/invites/change-status`, body);

export const getCampaignActivity = (params: IGetCampaignActivityRequest): Promise<IGetCampaignActivity> =>
  appApi.get(`/campaigns/activity`, { params });

export const getCampaignReplies = (params: IGetCampaignRepliesRequest): Promise<IGetCampaignReplies> =>
  appApi.get(`/campaigns/replies`, { params });

export const getCampaignDetailsActivity = (
  campaignId: number,
  params: IGetDetailsActivityRequest,
): Promise<IGetCampaignActivity> => appApi.get(`/campaigns/${campaignId}/actions`, { params });

export const getSNSavedSearches = (): Promise<ISNSavedSearch[]> => appApi.get(`/users/saved-searches`);

export const refreshSNSavedSearches = (): Promise<ISNSavedSearch[]> => appApi.patch(`/users/refresh-saved-searches`);

export const getCampaignsSearchList = (params?: IGetCampaignsSearchListRequest): Promise<ICampaignSearchItem[]> =>
  appApi.get(`/campaigns/search-list`, { params });

export const getCampaignsSearchListConnections = (): Promise<ICampaignSearchItem[]> =>
  appApi.get(`/campaigns/search-list/connections`);

export const getCampaignsSearchListIgnored = (campaignId: number): Promise<ICampaignSearchItem[]> =>
  appApi.get(`/campaigns/${campaignId}/search-list/ignored`);

export const getCampaignListItem = ({ id, filter }: IGetCampaignListItemParams): Promise<IGetCampaigns> =>
  appApi.get(`/campaigns/${id}/list-item`, { params: { filter } });

export const getCampaignsDashboard = (params: IGetDashboardParams & IPagination): Promise<IGetDashboardCampaign> =>
  appApi.get(`/campaigns/dashboard`, { params });

export const getCampaignsStatistics = (params: IGetDashboardParams): Promise<IGetDashboardStatistic> =>
  appApi.get(`/campaigns/statistics`, { params });

export const getAutocomplete = ({ route, query }: { route: string; query: string }): Promise<IAutocomplete[]> =>
  appApi
    .get<unknown, IAutocomplete[]>(`/search/autocomplete`, { params: { autocompleteType: route, prompt: query } })
    .then((data) => addValueAndLabel(data));

export const exportCSVCampaignActivity = (
  campaignId: number,
  params: IExportCampaignActivityCSVRequest,
): Promise<void> => appApi.post(`/campaigns/${campaignId}/activity/csv`, params);

export const getCampaignProgressById = (id: number): Promise<ICampaignProgress> =>
  appApi.get(`/campaigns/${id}/progress`);

export const getCampaignSequenceProgressById = (id: number): Promise<ICampaignSequenceProgress> =>
  appApi.get(`/campaigns/${id}/sequence-progress`);
