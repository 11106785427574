import { useState } from 'react';
import { BoxProps } from '@mui/material';

import { useAppSelector, useDebounce } from 'src/hooks';
import { useGetGroupListFull, useGetTeamInvites, useGetTeamMembers } from 'src/reactQueries';
import { TeamsSearchBar } from '../_components';
import { InviteUserForm } from './InviteUserForm';
import { ManageTeamLayout } from './ManageTeamLayout';
import { TeamMembersTable } from './TeamMembersTable/TeamMembersTable';

export type TSortField = 'name' | 'email' | 'role';
export type TSearchField = 'name' | 'email' | 'group';

interface TeamManageProps {
  tableOnly?: boolean;
  tablesWrapperProps?: BoxProps;
}

export const TeamManage = ({ tableOnly = false, tablesWrapperProps }: TeamManageProps) => {
  const { seats, teamUser } = useAppSelector((state) => state.team.data) || {};

  const [searchByName, setSearchByName] = useState('');
  const [searchByEmail, setSearchByEmail] = useState('');
  const [searchByGroups, setSearchByGroups] = useState('');

  const searchByNameDebounced = useDebounce(searchByName);
  const searchByEmailDebounced = useDebounce(searchByEmail);

  const { groupList } = useGetGroupListFull();
  const { teamMembers } = useGetTeamMembers();
  const { teamInvites = [] } = useGetTeamInvites();

  const onChangeSearch = (key: 'group' | 'name' | 'email', value: string) => {
    switch (key) {
      case 'name':
        return setSearchByName(value);
      case 'email':
        return setSearchByEmail(value);
      case 'group':
        return setSearchByGroups(value);
    }
  };

  const seatNumUsed = (teamMembers?.total ?? 0) + teamInvites?.filter((invite) => !invite?.isRejected).length;
  const seatNumLeft = (seats ?? 0) - seatNumUsed;

  const isInviteUserFormShown = seatNumLeft > 0 && teamUser?.role === 'owner' && !tableOnly;

  return (
    <>
      {!tableOnly && <ManageTeamLayout />}

      {isInviteUserFormShown && <InviteUserForm seatNumLeft={seatNumLeft} />}
      <TeamsSearchBar
        emailValue={searchByEmail}
        nameValue={searchByName}
        onChangeValue={onChangeSearch}
        groupList={groupList ?? []}
      />

      <TeamMembersTable
        teamInvites={teamInvites}
        email={searchByEmailDebounced}
        group={searchByGroups}
        name={searchByNameDebounced}
        wrapperProps={tablesWrapperProps}
      />
    </>
  );
};
