import Cookies, { Cookie } from 'universal-cookie';

import { APP_DOMAIN, APP_HOSTNAME, IS_ALFRED_DOMAIN } from 'src/constants';

const cookies = new Cookies();

export const setCookie = (name: string, cookie: Cookie): void => {
  cookies.set(name, cookie, { path: '/', domain: IS_ALFRED_DOMAIN ? APP_DOMAIN : APP_HOSTNAME });
};

export const removeCookie = (name: string): void => {
  cookies.remove(name, { path: '/', domain: APP_HOSTNAME });
  cookies.remove(name, { path: '/', domain: APP_DOMAIN });
};
