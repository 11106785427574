import { ISelectOption } from 'src/types';

export const SELECT_USER_ROLE_OPTIONS: ISelectOption[] = [
  {
    value: 'owner',
    label: 'Owner',
  },
  {
    value: 'admin',
    label: 'Admin',
  },
  {
    value: 'member',
    label: 'Member',
  },
];

export const SELECT_TEAM_INDUSTRY_OPTIONS: Record<string, string> = {
  agency: 'Agency',
  sales_team: 'Sales Team',
  marketing_team: 'Marketing Team',
  recruiters: 'Recruiters',
  other: 'Other: Please specify',
};
