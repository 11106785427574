import { Link, ProgressBar, Spinner, TableCell, TableRow, Typography } from 'src/components';
import { useGetCampaignProgress } from 'src/reactQueries';
import { IDashboardCampaign } from 'src/types';
import { CampaignIcon } from '../../Campaigns/_components';

export const CampaignTableRow = ({
  id,
  category,
  name,
  contacts,
  days,
  touchSequence,
  actions,
  replies,
}: IDashboardCampaign) => {
  const { data, isLoading } = useGetCampaignProgress(id);

  return (
    <TableRow hoverBgColor={({ light }) => light.main}>
      <TableCell justifyContent="center">
        <CampaignIcon size="small" type={category} />
      </TableCell>
      <TableCell tooltip={name}>
        <Link variant="lightBlue" crop to={`/campaign/details/overview/${id}`} fontSize="15px">
          {name}
        </Link>
      </TableCell>
      <TableCell justifyContent="center" data-testid="contacts">
        {contacts || '-'}
      </TableCell>
      <TableCell justifyContent="center">{days}</TableCell>
      <TableCell justifyContent="center">{touchSequence?.sequence?.length}</TableCell>
      <TableCell justifyContent="center" data-testid="actions">
        {actions || '-'}
      </TableCell>
      <TableCell justifyContent="center">
        <Typography color="error.light" data-testid="replies">
          {replies || '-'}
        </Typography>
      </TableCell>
      <TableCell contentStyle={{ width: '100%' }}>
        {isLoading ? <Spinner type="dots" /> : <ProgressBar progress={data?.progress} showInside />}
      </TableCell>
    </TableRow>
  );
};
