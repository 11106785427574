import { ChangeEvent, useMemo } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Box, Grid } from '@mui/material';

import { Link, RadioButtonOld, Typography } from 'src/components';
import { PLAN_SEATS } from 'src/constants';
import { BillingTypeEnum, CurrencyEnum, ModalTypes, Plan } from 'src/enums';
import { useAppSelector, useCompanyTheme } from 'src/hooks';
import { useGetBillingInfo } from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';
import { ICreateSubscription } from 'src/types';

const Selector = styled.select`
  width: 100%;
  min-width: 70px;
  height: 42px;
  padding-left: 10px;
  border: 1px solid ${({ theme }) => theme.palette.light.dark};
  border-radius: 4px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

interface IPlanDetailsProps {
  register: UseFormRegister<ICreateSubscription>;
  setValue: UseFormSetValue<ICreateSubscription>;
  seats: number;
  minSeats?: number;
  currency: CurrencyEnum;
  billingType: BillingTypeEnum;
  plan: Plan;
  noCurrency?: boolean;
  seatsOnly?: boolean;
  isDisabled: boolean;
}

export const PlanDetails = ({
  register,
  setValue,
  seats,
  billingType,
  plan,
  minSeats = 1,
  noCurrency = false,
  seatsOnly = false,
  isDisabled,
}: IPlanDetailsProps) => {
  const dispatch = useDispatch();
  const { shortCompanyName } = useCompanyTheme();
  const { data: teamData } = useAppSelector((state) => state.team);
  const { billingInfo } = useGetBillingInfo();

  const handlePlanChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (isDisabled) {
      return null;
    }
    const newValue = e.target.value as Plan;

    switch (newValue) {
      case Plan.INDIVIDUAL:
      case Plan.STARTER:
      case Plan.PERSONAL:
        setValue('seats', 1);
        break;
      case Plan.BUSINESS:
        if (seats > 10) {
          setValue('seats', Math.max(10, minSeats));
        }
        break;
      case Plan.ENTERPRISE:
        setValue('seats', Math.max(5, minSeats));
        setValue('billingType', BillingTypeEnum.QUARTERLY);
        break;
      case Plan.AGENCY:
        setValue('seats', Math.max(10, minSeats));
        setValue('billingType', BillingTypeEnum.QUARTERLY);
    }

    // need to remove not available billing types if user changes plan
    if ([BillingTypeEnum.ANNUAL, BillingTypeEnum.SEMESTRIAL].includes(billingType)) {
      setValue('billingType', BillingTypeEnum.QUARTERLY);
    }

    setValue('plan', newValue);
  };

  const handleBillingTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (isDisabled) {
      return null;
    }
    const type = e.target.value as BillingTypeEnum;

    if (billingType !== type) {
      if (type === BillingTypeEnum.MONTHLY) {
        dispatch(
          openModal({
            type: ModalTypes.SAVE_BILLING_CYCLE,
            closable: false,
            params: {
              percentage: billingType === BillingTypeEnum.ANNUAL ? 40 : 20,
              billingType,
            },
            onConfirm: () => setValue('billingType', type),
          }),
        );
      } else if ([BillingTypeEnum.QUARTERLY, BillingTypeEnum.SEMESTRIAL, BillingTypeEnum.ANNUAL].includes(type)) {
        setValue('billingType', type);
      }
    }
  };

  const plans = useMemo(() => {
    return [
      { value: Plan.INDIVIDUAL, name: 'Individual', disabled: minSeats > 1, isShown: true },
      { value: Plan.STARTER, name: 'Starter', disabled: minSeats > 1, isShown: teamData?.plan === Plan.STARTER },
      { value: Plan.PERSONAL, name: 'Personal', disabled: minSeats > 1, isShown: teamData?.plan === Plan.PERSONAL },
      { value: Plan.BUSINESS, name: 'Business', disabled: false, isShown: true },
      { value: Plan.ENTERPRISE, name: 'Enterprise', disabled: false, isShown: teamData?.plan === Plan.ENTERPRISE },
      { value: Plan.AGENCY, name: 'Agency', disabled: false, isShown: true },
    ];
  }, [teamData?.plan]);

  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography color="primary.wlLight" semibold mb="20px">
          Plan details
        </Typography>
        {!noCurrency && (
          <Box ml="auto" display="flex" alignItems="center">
            <Typography mr="15px">Currency:</Typography>
            <fieldset disabled={seatsOnly || isDisabled}>
              <Selector {...register('currency')}>
                <option value={CurrencyEnum.USD}>USD</option>
                <option value={CurrencyEnum.GBP}>GBP</option>
                <option value={CurrencyEnum.EUR}>EUR</option>
              </Selector>
            </fieldset>
          </Box>
        )}
      </Box>
      <Grid container columnSpacing="30px" mb="15px">
        <Grid item xs={4}>
          <Typography fontSize="12px" mb="5px">
            {shortCompanyName} Plan
          </Typography>
          <fieldset disabled={seatsOnly || isDisabled}>
            <Selector {...register('plan', { onChange: handlePlanChange })}>
              {plans.map(
                ({ isShown, value, name, disabled }) =>
                  isShown && (
                    <option key={value} value={value} disabled={disabled}>
                      {name}
                    </option>
                  ),
              )}
            </Selector>
          </fieldset>
        </Grid>

        <Grid item xs={2}>
          <Typography fontSize="12px" mb="5px">
            Total user
          </Typography>
          <fieldset disabled={isDisabled}>
            <Selector {...register('seats', { valueAsNumber: true })}>
              {Array.from(Array(PLAN_SEATS[plan]?.seats || 1)).map((_v, i) => (
                <option
                  disabled={
                    (!billingInfo?.hasCustomPlan && (PLAN_SEATS[plan]?.minSeats || 1) > i + 1) || minSeats > i + 1
                  }
                  key={i}
                  value={i + 1}
                >
                  {i + 1}
                </option>
              ))}
            </Selector>
          </fieldset>
        </Grid>

        <Grid item xs={6}>
          <Typography fontSize="12px" mb="5px">
            Billing cycle
          </Typography>
          <Box
            display="flex"
            justifyContent="space-between"
            maxWidth="300px"
            component="fieldset"
            disabled={seatsOnly || isDisabled}
          >
            <RadioButtonOld
              label="Monthly"
              labelSize="14px"
              value={BillingTypeEnum.MONTHLY}
              checked={BillingTypeEnum.MONTHLY === billingType}
              onChange={handleBillingTypeChange}
            />
            <RadioButtonOld
              label="Quarterly"
              labelSize="14px"
              subLabel="Save up to 20%"
              value={BillingTypeEnum.QUARTERLY}
              checked={BillingTypeEnum.QUARTERLY === billingType}
              onChange={handleBillingTypeChange}
            />
            {teamData?.billingType === BillingTypeEnum.SEMESTRIAL && teamData?.plan === plan && (
              <RadioButtonOld
                label="Half Yearly"
                labelSize="14px"
                value={BillingTypeEnum.SEMESTRIAL}
                checked={BillingTypeEnum.SEMESTRIAL === billingType}
                onChange={handleBillingTypeChange}
              />
            )}
            {(teamData?.billingType === BillingTypeEnum.ANNUAL ||
              ![Plan.STARTER, Plan.PERSONAL, Plan.ENTERPRISE].includes(plan)) && (
              <RadioButtonOld
                label="Annual"
                labelSize="14px"
                subLabel="Save up to 40%"
                value={BillingTypeEnum.ANNUAL}
                checked={BillingTypeEnum.ANNUAL === billingType}
                onChange={handleBillingTypeChange}
              />
            )}
          </Box>
        </Grid>
      </Grid>
      <Link external to="https://meetalfred.com/pricing" openNewTab>
        Compare plans
      </Link>
    </>
  );
};
