import { useQuery } from 'react-query';

import { getPostsCount } from 'src/api';
import { IGetPostsSearchRequest } from 'src/types';

export const usePostsCount = (data: IGetPostsSearchRequest) => {
  return useQuery(['get-posts-count', JSON.stringify(data)], () => getPostsCount(data), {
    refetchOnMount: true,
  });
};
