import { PropsWithChildren } from 'react';
import { IntercomProvider as ReactIntercomProvider } from 'react-use-intercom';

import { INTERCOM_APP_ID } from 'src/constants';
import { useWhiteLabel } from 'src/hooks';

export const IntercomProvider = ({ children }: PropsWithChildren) => {
  const { whitelabel } = useWhiteLabel();

  return (
    <ReactIntercomProvider appId={whitelabel.isWhiteLabelUser ? '' : INTERCOM_APP_ID}>{children}</ReactIntercomProvider>
  );
};
