import { Box } from '@mui/material';

import {
  Breadcrumbs,
  HeaderContainer,
  HeaderDescription,
  HeaderTitle,
  HeaderVideo,
  Link,
  Typography,
} from 'src/components';
import { useTeamPlan } from 'src/hooks';

export const SubscriptionPlansLayout = () => {
  const { isTrial, isDeactivated } = useTeamPlan();

  if (isTrial || isDeactivated) {
    return (
      <HeaderContainer>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <HeaderTitle>Billing</HeaderTitle>
        </Box>
        <HeaderDescription>
          Manage and modify your billing information, plan selection, and the number of seats used.
          <Link leftIcon underline external openNewTab to="https://help.meetalfred.com/en/collections/3130855-billing">
            Step by Step Guides
          </Link>
          <HeaderVideo src="https://player.vimeo.com/video/1000455752" />
        </HeaderDescription>
      </HeaderContainer>
    );
  }

  return (
    <HeaderContainer pt="16px">
      <Breadcrumbs>
        <Link to="/billing/subscription">
          <Typography>Subscription Plan</Typography>
        </Link>
        <Typography>Change plan</Typography>
      </Breadcrumbs>
      <HeaderTitle pt="16px">Change plan</HeaderTitle>
      <HeaderDescription>Find the perfect plan to achieve your goals</HeaderDescription>
    </HeaderContainer>
  );
};
