import { useMutation, UseMutationOptions } from 'react-query';

import { setNewPassword } from 'src/api';
import { ICustomAxiosError, ISetNewPasswordRequest } from 'src/types';

export const useSetPassword = (options?: UseMutationOptions<void, ICustomAxiosError, ISetNewPasswordRequest>) => {
  const { mutate, ...rest } = useMutation('set-new-password', setNewPassword, options);

  return {
    setNewPassword: mutate,
    ...rest,
  };
};
