import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { PaymentMethod } from '@stripe/stripe-js';

import { updateCreditCard } from 'src/api';
import { ICustomAxiosError } from 'src/types';

export const useUpdateCreditCard = (options?: UseMutationOptions<void, ICustomAxiosError, PaymentMethod>) => {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(['update-credit-card'], updateCreditCard, {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(['get-billing-info']);

      options?.onSuccess?.(data, variables, context);
    },
  });

  return { updateCreditCard: mutate, ...rest };
};
