import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getIntercomInitialData } from 'src/api/intercom';
import { IIntercomInitData } from 'src/types';

export const useGetIntercomInitialData = <TData = IIntercomInitData>(
  options?: UseQueryOptions<IIntercomInitData, AxiosError, TData>,
) => {
  return useQuery<IIntercomInitData, AxiosError, TData>(['get-campaign-replies'], getIntercomInitialData, options);
};
