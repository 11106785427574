import { useMutation, UseMutationOptions } from 'react-query';

import { updateLinkedInCredentials } from 'src/api';
import { ICustomAxiosError, ILinkedInSetAccountRequest, IUpdateLinkedInCredentialsResponse } from 'src/types';

export const useUpdateLinkedInCredentials = (
  options?: UseMutationOptions<IUpdateLinkedInCredentialsResponse, ICustomAxiosError, ILinkedInSetAccountRequest>,
) => {
  const { mutate, ...rest } = useMutation('update-linkedin-credentials', updateLinkedInCredentials, options);

  return {
    updateLinkedInCredentials: mutate,
    ...rest,
  };
};
