import styled from '@emotion/styled';
import { Box, BoxProps } from '@mui/material';

export const Wrapper = styled(Box)`
  position: relative;
  background-color: #fff;
  color: ${({ theme }) => theme.palette.primary.wlLight};
  padding-left: 15px;
  padding-right: 15px;
`;

export const Container = styled(Box)`
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
`;

export const HeaderContainer = ({ children, ...restProps }: BoxProps) => {
  return (
    <Wrapper>
      <Container {...restProps}>{children}</Container>
    </Wrapper>
  );
};
