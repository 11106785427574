import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button, Input, Typography } from 'src/components';

export const StyledInput = styled(Input)`
  margin-top: 0;
  height: 34px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.palette.gray[50]};
`;

export const FiltersSidebar = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 280px;
  flex-shrink: 0;
  box-shadow: 2px 2px 7px 0 #0a090b0d;
  background-color: #ffffff;
  max-height: 100%;
  overflow-y: scroll;
  margin-top: 1px;
`;

export const FiltersHeader = styled(Box)`
  position: sticky;
  top: 0;
  padding: 14px 12px;
  box-shadow: 0 4px 6px 0 #0000001f;
  z-index: 5;
  background-color: #ffffff;
`;

export const FiltersFooter = styled(Box)`
  position: sticky;
  bottom: 0;
  padding: 8px;
  box-shadow: 0 -4px 6px 0 #0000001f;
  z-index: 5;
  background-color: #ffffff;
`;

export const FiltersBox = styled(Box)`
  flex: 1 1 auto;
  background-color: #ffffff;
`;

export const StyledButton = styled(Button)`
  color: white;
  border-radius: 5px;
  width: 82px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;

  & > svg {
    flex-shrink: 0;
  }
`;

export const TagWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  height: 30px;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
`;

export const LeadName = styled(Typography)`
  color: inherit;

  &:hover {
    color: inherit;
  }
`;

export const UnavailableContainer = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ url: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 90px);
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: cover;
`;
