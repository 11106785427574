import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';

import blacklistConnections from './blacklist-connections.slice';
import blacklistLeads from './blacklist-leads.slice';
import campaign from './campaign.slice';
import connections from './connections.slice';
import conversation from './conversation.slice';
import dashboard from './dashboard.slice';
import lead from './lead.slice';
import modal from './modal.slice';
import notification from './notification.slice';
import team from './team.slice';
import toast from './toast.slice';
import trends from './trends.slice';
import user from './user.slice';

export const rootReducer = combineReducers({
  team,
  user,
  conversation,
  connections,
  lead,
  modal,
  toast,
  notification,
  campaign,
  trends,
  dashboard,
  blacklistLeads,
  blacklistConnections,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch;

export * from './blacklist-connections.slice';
export * from './blacklist-leads.slice';
export * from './campaign.slice';
export * from './connections.slice';
export * from './conversation.slice';
export * from './dashboard.slice';
export * from './lead.slice';
export * from './modal.slice';
export * from './notification.slice';
export * from './team.slice';
export * from './toast.slice';
export * from './trends.slice';
export * from './user.slice';
