import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { CSSProperties, UIEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { PersonAvatar, Spinner, Typography } from 'src/components';
import { IModalChooseTemplateProps } from 'src/components/Modal';
import { MessagesLayout } from 'src/containers/Conversations/_components/MessagesLayout';
import { LIConversationFilterType, ModalTypes, SNMessageType } from 'src/enums';
import { useAppSelector } from 'src/hooks';
import { useGetLeadInfo, useGetSNConversationMessages, useSendSNMessage } from 'src/reactQueries';
import { openModal, showToast } from 'src/store';
import { IAttachment, ILIConversation } from 'src/types';

dayjs.extend(isToday);

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > * {
    flex-shrink: 0;
  }
`;

const MessagesBox = styled(Box)`
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  padding: 10px 5px 0 5px;
  margin-top: auto;
  flex-shrink: 1;
`;

const Cloud = styled(Box)<{ self: 1 | 0 }>`
  display: flex;
  flex-direction: column;
  white-space: pre-wrap;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  background-color: ${({ self, theme }) => (self ? theme.palette.light.main : theme.palette.light.light)};
`;

const Message = styled(Box)<{ self: 1 | 0 }>`
  display: flex;
  align-items: flex-end;
  margin: 10px 0;
  max-width: 70%;
  ${(props) => (props.self ? 'margin-left: auto;' : 'margin-right: auto;')};
  flex-direction: ${(props) => (props.self ? 'row-reverse' : 'row')};
`;

const Deleted = styled(Cloud)`
  border-radius: 0;
  background-color: #cccccc;
`;

const Reply = styled(Box)`
  padding: 5px 10px;
  border-radius: 20px;
  margin: 0 5px;
  font-size: 14px;
  color: #000000;
  border: 1px solid ${({ theme }) => theme.palette.light.main};
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.light.main};
  }
`;

const TextArea = styled.textarea`
  resize: none;
  padding: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.light.light};
  border-top: 1px solid ${({ theme }) => theme.palette.light.light};
  outline: none;
  font-size: 14px;
  line-height: 14px;
  height: 70px;

  &:disabled {
    background-color: #ffffff;
    cursor: not-allowed;
  }
`;

const Button = styled.button<{ backgroundColor?: string; hoverColor?: string }>`
  padding: 5px 20px;
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.palette.light.dark};
  background-color: ${({ disabled, theme, backgroundColor }) =>
    backgroundColor || (disabled ? theme.palette.gray[100] : '#2B5EDB')};
  border-radius: 4px;
  color: ${({ color, disabled, theme }) => color || (disabled ? theme.palette.gray[300] : '#FFFFFF')};
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: ${({ theme, hoverColor }) => hoverColor || theme.palette.light.light};
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;

const MAX_LI_MESSAGE_LENGTH = 8000;
const MAX_INMAIL_MESSAGE_LENGTH = 1900;

export const SNConversation = ({
  conversationData,
  filterType,
  containerStyle,
  isLeadMessages,
}: {
  isLeadMessages?: boolean;
  conversationData: ILIConversation;
  filterType: LIConversationFilterType;
  containerStyle?: CSSProperties;
}) => {
  const { palette } = useTheme();
  const dispatch = useDispatch();

  const pictures = useAppSelector((state) => state.conversation.pictures);
  const [msgValue, setMsgValue] = useState('');
  const maxMessageLength =
    filterType === LIConversationFilterType.INMAIL ? MAX_INMAIL_MESSAGE_LENGTH : MAX_LI_MESSAGE_LENGTH;

  const { leadInfo } = useGetLeadInfo(conversationData?.entityUrn || '');

  const { messages, isLoading, fetchNextPage, isFetchingNextPage } = useGetSNConversationMessages({
    entityUrn: conversationData?.entityUrn || '',
    conversationId: conversationData?.conversationId || '',
  });

  const { sendSNMessage, isSendSnLoading } = useSendSNMessage({
    entityUrn: conversationData?.entityUrn || '',
    conversationId: conversationData?.conversationId || '',
  });

  const handleScrollPagination = async (e: UIEvent<HTMLDivElement>) => {
    // the logic of defining scroll that left is a bit strange
    // because of using 'flex-direction: column-reverse' prop
    if (
      e.currentTarget.scrollHeight + e.currentTarget.scrollTop - e.currentTarget.offsetHeight < 30 &&
      messages?.length > 0 &&
      !isFetchingNextPage
    ) {
      fetchNextPage().catch(console.error);
    }
  };

  const send = (message: string) => {
    sendSNMessage({ message });
    setMsgValue('');
  };

  const openTemplateSelectorModal = () => {
    const snippetsData = {
      first_name: leadInfo?.person?.firstName || conversationData.firstName,
      last_name: leadInfo?.person?.lastName || conversationData.lastName,
      company: leadInfo?.person?.currentEmployer,
      position: leadInfo?.person?.currentTitle || conversationData.headline,
      industry: leadInfo?.person?.industryName,
    };

    dispatch(
      openModal({
        type: ModalTypes.CHOOSE_TEMPLATE,
        closable: false,
        params: {
          noTemplatesMessage: "You don't have Inbox templates created",
          preselectCategory: 'Inbox',
          onConfirm: async (message, _subject, attachments: IAttachment[] = []) => {
            if (attachments.length) {
              dispatch(
                showToast({
                  type: 'info',
                  message: 'Attachments were removed.',
                  autoCloseTime: 3000,
                }),
              );
            }

            setMsgValue(
              Object.entries(snippetsData).reduce((msg, [snippet, data]) => {
                return msg.replace(`{{${snippet}}}`, data || '');
              }, message),
            );
          },
        } as IModalChooseTemplateProps,
      }),
    );
  };

  useEffect(() => {
    setMsgValue('');
  }, [conversationData?.conversationId]);

  const replies = [...messages][0]?.replyRecommendations || [];

  return (
    <Wrapper style={containerStyle}>
      {isLeadMessages || (
        <MessagesLayout isLoadingMessages={isLoading} conversationData={conversationData} isSNConversation />
      )}

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <MessagesBox onScroll={handleScrollPagination}>
            {messages?.map((msg) => {
              const self = msg.entityUrn === conversationData?.entityUrn ? 0 : 1;
              const picture = pictures[msg.entityUrn];
              const content = msg.body;

              const renderContent = () => {
                if (msg.type === SNMessageType.INMAIL_DECLINE) {
                  return (
                    <Deleted self={self}>Lead has declined your InMail {!!content && `with note "${content}"`}</Deleted>
                  );
                }

                if (!content || !!msg.recalledAt) {
                  return <Deleted self={self}>This message has been deleted.</Deleted>;
                }

                return (
                  <Cloud self={self}>
                    {msg.subject && (
                      <>
                        <span>{msg.subject}</span>
                        <br />
                      </>
                    )}
                    {content}
                  </Cloud>
                );
              };

              return (
                <Message key={msg.createdAt} self={self}>
                  <Box mx="5px" pb="22px" height="30px">
                    <PersonAvatar src={picture} width="30px" height="30px" />
                  </Box>

                  <Box>
                    {renderContent()}
                    <Typography fontSize="11px" color="gray.light" mt="3px" textAlign={self ? 'right' : 'left'}>
                      {dayjs(msg.createdAt).isToday()
                        ? `TODAY ${dayjs(msg.createdAt).format('hh:mm A')}`
                        : dayjs(msg.createdAt).format('dddd hh:mm A')}
                    </Typography>
                  </Box>
                </Message>
              );
            })}
          </MessagesBox>
          <Box display="flex" p="10px" justifyContent="center">
            {replies.map((reply) => (
              <Reply key={reply.objectUrn} onClick={() => send(reply.content.text)}>
                {reply.content.text}
              </Reply>
            ))}
          </Box>
          <TextArea
            maxLength={maxMessageLength}
            placeholder="Write a message..."
            value={msgValue}
            onChange={(e) => setMsgValue(e.target.value)}
          />
          <Box py="10px" px="20px" display="flex" gap="12px">
            <Button onClick={openTemplateSelectorModal} color={palette.gray[800]} backgroundColor="#ffffff">
              Template
            </Button>

            <Button
              disabled={isSendSnLoading || !msgValue}
              hoverColor={palette.brand[300]}
              onClick={() => send(msgValue)}
            >
              Send
            </Button>
          </Box>
        </>
      )}
    </Wrapper>
  );
};
