import { useMutation, UseMutationOptions } from 'react-query';

import { testSMTPSettings } from 'src/api/smtp';
import { ICustomAxiosError, ITestSMTPSettingsReq, IWhiteLabel } from 'src/types';

export const useTestSMTPSettings = (
  options?: UseMutationOptions<IWhiteLabel, ICustomAxiosError, ITestSMTPSettingsReq>,
) => {
  const { mutate, isLoading, ...rest } = useMutation(['test-smtp-settings'], testSMTPSettings, options);

  return { testSMTP: mutate, isTestLoading: isLoading, ...rest };
};
