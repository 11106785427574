import { useQuery, UseQueryOptions } from 'react-query';

import { getFeatureFlags } from 'src/api';
import { useAppSelector } from 'src/hooks';
import { ICustomAxiosError, IFeatureFlag } from 'src/types';

export const useGetFeatureFlags = (options?: UseQueryOptions<IFeatureFlag[], ICustomAxiosError>) => {
  const { isAuth, profile } = useAppSelector((state) => state.user);

  const { data, ...rest } = useQuery<IFeatureFlag[], ICustomAxiosError>(['get-feature-flags'], getFeatureFlags, {
    enabled: isAuth && !!profile?.isEmailVerified,
    retry: false,
    ...options,
  });

  return {
    featureFlags: data,
    ...rest,
  };
};
