import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box } from '@mui/material';

import { Button, HeaderContainer, HeaderDescription, HeaderTitle, HeaderVideo, Link } from 'src/components';
import { delay } from 'src/helpers';
import { useWhiteLabel } from 'src/hooks';
import { useGetUserPreferences, useUpdateGreetings } from 'src/reactQueries';
import { IGreetingsRequest, IUserPreferences } from 'src/types';
import { SuccessMessage, Wrapper } from '../_components';
import { GreetingsForm } from './GreetingsForm';

export const generateGreetingsDefaultValues = (data?: IUserPreferences) => {
  const { greetingsSettings, birthday = '', jobChange = '', workAnniversary = '', skillEndorsement = '' } = data ?? {};
  const {
    BIRTHDAY = false,
    JOB_CHANGE = false,
    SKILL_ENDORSEMENT = false,
    WORK_ANNIVERSARY = false,
  } = greetingsSettings ?? {};

  return {
    BIRTHDAY: birthday,
    JOB_CHANGE: jobChange,
    WORK_ANNIVERSARY: workAnniversary,
    SKILL_ENDORSEMENT: skillEndorsement,
    greetings_settings: {
      BIRTHDAY,
      JOB_CHANGE,
      SKILL_ENDORSEMENT,
      WORK_ANNIVERSARY,
    },
  };
};

export const Greetings = () => {
  const [show, setShow] = useState(false);

  const { whitelabel } = useWhiteLabel();
  const { userPreferences } = useGetUserPreferences();

  const { register, watch, setValue, handleSubmit, reset } = useForm<IGreetingsRequest>({
    defaultValues: generateGreetingsDefaultValues(userPreferences),
  });

  const { updateGreetings, isSuccess, isLoading } = useUpdateGreetings();

  useEffect(() => {
    if (userPreferences) {
      reset(generateGreetingsDefaultValues(userPreferences));
    }
  }, [userPreferences]);

  useEffect(() => {
    if (isSuccess) {
      setShow(true);
    }
    const timeId = delay(3000, () => setShow(false));

    return () => {
      clearTimeout(timeId);
    };
  }, [isSuccess]);

  const onChangeGreetings = (values: IGreetingsRequest) => {
    updateGreetings(values);
  };

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Greetings</HeaderTitle>
        <HeaderDescription>
          Stay connected with your network by sending automatic birthday, job change, job anniversary, and endorsement
          messages to your connections.
          {whitelabel.isWhiteLabelUser || (
            <>
              <Link
                leftIcon
                underline
                external
                openNewTab
                to="https://meetalfred.com/help/en/articles/3378667-set-up-greetings-for-birthday-work-anniversaries-and-more"
              >
                Learn how it works
              </Link>
              <HeaderVideo src="https://player.vimeo.com/video/873029860" />
            </>
          )}
        </HeaderDescription>
      </HeaderContainer>
      <Wrapper maxWidth="100%">
        <Box mt="20px" display="grid" gap="15px 30px" gridTemplateColumns="1fr 1fr">
          <GreetingsForm
            title="Add auto-message to congratulate on job anniversary"
            watchField="WORK_ANNIVERSARY"
            watchToggle="WORK_ANNIVERSARY"
            watch={watch}
            setValue={setValue}
            register={register}
          />
          <GreetingsForm
            title="Add auto-message to wish happy birthday"
            watchField="BIRTHDAY"
            watchToggle="BIRTHDAY"
            watch={watch}
            setValue={setValue}
            register={register}
          />
          <GreetingsForm
            title="Add auto-message to thank them for endorsement/s received"
            watchField="SKILL_ENDORSEMENT"
            watchToggle="SKILL_ENDORSEMENT"
            watch={watch}
            setValue={setValue}
            register={register}
          />
          <GreetingsForm
            title="Add auto-message to congratulate on new role"
            watchField="JOB_CHANGE"
            watchToggle="JOB_CHANGE"
            watch={watch}
            setValue={setValue}
            register={register}
          />
        </Box>
        {show && <SuccessMessage data-testid="success-message">Greetings messages are saved</SuccessMessage>}
        <Box display="flex" justifyContent="flex-end" mt="15px">
          <Button processing={isLoading} onClick={handleSubmit(onChangeGreetings)}>
            Save
          </Button>
        </Box>
      </Wrapper>
    </>
  );
};
