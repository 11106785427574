import { isEqual, uniqWith } from 'lodash';

import { DAYS, MAX_RECOMMENDED_HOURS } from 'src/constants';
import { IScheduledDay } from 'src/types';

export const convertTime = (time: number) => {
  if (time === 0) {
    return '12 am';
  } else if (time === 100) {
    return "Don't run";
  } else if (time === 12) {
    return '12 pm';
  } else if (time === 24) {
    return '11:59 pm';
  } else if (time > 12) {
    return `${time - 12} pm`;
  }

  return `${time} am`;
};

export const resolveHours = (scheduled: IScheduledDay[]) => {
  const numberScheduled = uniqWith(scheduled, isEqual).map(({ day, endHour, startHour }) => ({
    day: Number(day),
    endHour: Number(endHour),
    startHour: Number(startHour),
  }));

  const hourInDays = DAYS.map((day, dayIndex) => {
    const dayRanges = numberScheduled.filter((workDay) => workDay.day === dayIndex);
    const startFrom = Math.min(...dayRanges.map((range) => range.startHour));
    const endTo = Math.max(...dayRanges.map((range) => range.endHour));

    const isDontRun = dayRanges.some((range) => range.startHour > 24 || range.endHour > 24);

    if (isDontRun) {
      return 0;
    }

    let hoursInDay = 0;

    for (let hour = startFrom; hour < endTo; hour++) {
      const isInRange = dayRanges.some((range) => range.startHour <= hour && hour < range.endHour);

      if (isInRange) {
        hoursInDay += 1;
      }
    }

    return hoursInDay;
  }, [] as number[]);

  const warnDays = hourInDays.map((allHours) => allHours > MAX_RECOMMENDED_HOURS);

  return { hourInDays, warnDays };
};
