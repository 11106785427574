import { useDispatch } from 'react-redux';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import {
  DatePicker,
  HeaderButton,
  HeaderContainer,
  HeaderDescription,
  HeaderTitle,
  HeaderVideo,
  Link,
  SelectBasicGray,
} from 'src/components';
import { ACTIVITY_INTERVAL_OPTIONS, PLAN_TOOLTIPS } from 'src/constants';
import { Features, ModalTypes } from 'src/enums';
import { useAppSelector, useGetNavigationConfig, useTeamPlan, useTimeIntervalOptions, useWhiteLabel } from 'src/hooks';
import { useGetTeamMembers } from 'src/reactQueries';
import { openModal } from 'src/store';
import { ISelectOption, TIntervalFilter } from 'src/types';
import { TeamStatsTable } from './TeamStatsTable/TeamStatsTable';

export const TeamStats = () => {
  const dispatch = useDispatch();
  const { whitelabel } = useWhiteLabel();
  const { checkFeature } = useTeamPlan();
  const isExportStatsAllowed = checkFeature(Features.exportTeamStats);
  const teamData = useAppSelector((state) => state.team.data);

  const { interval, dateTo, dateFrom, setDateFrom, setInterval, setDateTo, today } = useTimeIntervalOptions();

  const { teamMembers } = useGetTeamMembers();
  const { isManageGroupShown } = useGetNavigationConfig();

  const groupName = teamMembers?.members[0].group?.name ?? '';

  const onIntervalChange = (data: ISelectOption<TIntervalFilter>) => {
    setInterval(data.value);
  };

  const onExportClick = () =>
    dispatch(
      openModal({
        type: ModalTypes.EXPORT_TEAM_STATS_CSV,
        params: {
          timeFilter: interval,
          customFromDate: dateFrom,
          customToDate: dateTo,
        },
      }),
    );

  const renderIntervalSelector = () => (
    <>
      {interval === 'custom' && (
        <Box display="flex" alignItems="center" gap="10px" mr="10px">
          <DatePicker
            type="date"
            value={dateFrom}
            placeholder="from"
            max={dateTo}
            onChange={(e) => e.target.value && setDateFrom(e.target.value)}
          />
          <FontAwesomeIcon icon={faArrowRight} />
          <DatePicker
            type="date"
            value={dateTo}
            placeholder="to"
            max={today}
            min={dateFrom}
            onChange={(e) => e.target.value && setDateTo(e.target.value)}
          />
        </Box>
      )}
      <SelectBasicGray
        aria-label="interval-select"
        label="Filter by:"
        value={interval}
        onChange={onIntervalChange}
        options={ACTIVITY_INTERVAL_OPTIONS}
        minWidth="180px"
        valueContainerPosition="space-between"
      />
    </>
  );

  return (
    <>
      <HeaderContainer pb="20px">
        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <HeaderTitle>{isManageGroupShown ? `Group ${groupName} stats` : `${teamData?.name} Team stats`}</HeaderTitle>
          <Box display="flex" justifyContent="space-between" gap="15px">
            <HeaderButton
              onClick={onExportClick}
              disabled={!isExportStatsAllowed}
              tooltip={{
                title: !isExportStatsAllowed && PLAN_TOOLTIPS.agency,
              }}
            >
              Export stats
            </HeaderButton>
            <Link to={isManageGroupShown ? '/team/manage-group' : '/team/manage-team'}>
              <HeaderButton>Manage team</HeaderButton>
            </Link>
          </Box>
        </Box>
        <HeaderDescription>
          Get valuable insights on your team's campaign performance, all in one place.
          {whitelabel.isWhiteLabelUser || (
            <>
              <Link
                leftIcon
                underline
                external
                openNewTab
                to="https://help.meetalfred.com/en/articles/5615010-manage-your-teams-campaigns"
              >
                Learn how it works
              </Link>
              <HeaderVideo src="https://player.vimeo.com/video/622872008" />
            </>
          )}
        </HeaderDescription>

        <Box display="flex" width="100%" justifyContent="flex-end">
          {renderIntervalSelector()}
        </Box>
      </HeaderContainer>

      <Box pt="15px" px="15px" pb="80px">
        <TeamStatsTable timeFilter={interval} customFromDate={dateFrom} customToDate={dateTo} />
      </Box>
    </>
  );
};
