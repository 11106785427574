import { useQuery, UseQueryOptions } from 'react-query';

import { getSequenceTemplateById } from 'src/api';
import { ICustomAxiosError, ISequenceTemplate } from 'src/types';

export const useGetSequenceTemplateById = (
  sequenceTemplateId: number,
  options?: UseQueryOptions<ISequenceTemplate, ICustomAxiosError>,
) => {
  return useQuery<ISequenceTemplate, ICustomAxiosError>(
    ['get-sequence-template', sequenceTemplateId],
    () => getSequenceTemplateById(sequenceTemplateId),
    options,
  );
};
