import { Box } from '@mui/material';

import { Button, Typography } from 'src/components';
import { useCompanyTheme } from 'src/hooks';
import { useLeaveTeam } from 'src/reactQueries';

export const DeactivatedApp = () => {
  const { shortCompanyName } = useCompanyTheme();

  const { leaveTeam, isLoading } = useLeaveTeam();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt="50px">
      <Typography color="#A0A0A0" mb="40px">
        Your subscription period is over. Please contact your team owner in order to continue using {shortCompanyName}.
      </Typography>
      <Button processing={isLoading} onClick={() => leaveTeam()}>
        Leave team
      </Button>
    </Box>
  );
};
