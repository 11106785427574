import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { createTag } from 'src/api';
import { closeModal } from 'src/store/modal.slice';
import { showToast } from 'src/store/toast.slice';
import { ICreateTagRequest, ITag, TMutationsOptions } from 'src/types';

export const useCreateTag = (
  options?: TMutationsOptions<ITag, ICreateTagRequest> & { withNotCloseModal?: boolean },
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const getAllTags = ['get-all-tags'];

  const { mutate, error, isLoading, isSuccess } = useMutation('create-trend', createTag, {
    ...options,
    onMutate: async (tag) => {
      await queryClient.cancelQueries(getAllTags);

      const prevData = queryClient.getQueryData(getAllTags);

      queryClient.setQueryData<ITag[] | undefined>(getAllTags, (tags) => {
        return [
          ...(tags || []),
          { color: tag.color, tag: tag.tag, id: -1, snId: '-1', leadsCount: 0, connectionsCount: 0 },
        ];
      });
      options?.onMutate && options?.onMutate(tag);

      return { prevData };
    },
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData<ITag[] | undefined>(getAllTags, (tags) => {
        if (tags) {
          return tags.map((tag) => (tag.tag === data.tag ? data : tag));
        }

        return tags;
      });

      queryClient.invalidateQueries(['get-tags']).catch(console.error);
      queryClient.invalidateQueries(getAllTags, { refetchInactive: true }, {}).catch(console.error);
      options?.withNotCloseModal || dispatch(closeModal());
      options?.onSuccess && options?.onSuccess(data, variables, context);
    },
    onError: (err, variables, context) => {
      queryClient.setQueryData(getAllTags, context?.prevData);
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
      options?.onError && options?.onError(err, variables, context);
    },
  });

  return {
    createTag: mutate,
    error,
    isLoading,
    isSuccess,
  };
};
