import classNames from 'classnames';
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { ReactComponent as MinusIcon } from 'src/assets/icons/minus-bold.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus-bold.svg';
import { ReactComponent as WarningIcon } from 'src/assets/icons/warning-bold.svg';
import { Typography } from 'src/components';
import { PLAN_SEATS } from 'src/constants';
import { Plan } from 'src/enums';
import { useDebounce } from 'src/hooks';

const InputWrapper = styled(Box)`
  display: flex;
  width: 200px;
  height: 42px;

  border: 1px solid ${({ theme }) => theme.palette.gray[200]};
  border-radius: 4px;
  overflow: hidden;

  box-shadow: 0 1px 1px 0 #dfdfe8;

  &.error {
    border-color: ${({ theme }) => theme.palette.error[400]};
    box-shadow: 0 0 6px 0 #e3484866;
  }

  &.disabled {
    opacity: 0.6;

    &,
    & > * {
      cursor: not-allowed;
    }
  }
`;

const IconWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.palette.gray[50]};
  height: 42px;
  width: 40px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: 0.2s;

  &:hover,
  &.disabled {
    opacity: 0.6;
  }

  &.disabled {
    cursor: not-allowed;
  }
`;

const NumberInput = styled.input`
  width: 120px;
  height: 100%;
  text-align: center;

  &:active,
  &:focus {
    outline: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

interface SeatsInputProps {
  plan: Plan;
  isCustomPlan: boolean;
  value: number;
  error?: string;
  teamCount: number;
  disabled?: boolean;
  onChange: (value: number) => void;
  onError: (error?: string) => void;
}

export const SeatsInput = ({
  plan,
  value,
  disabled,
  onChange,
  onError,
  error,
  teamCount,
  isCustomPlan = false,
}: SeatsInputProps) => {
  const [inputSeats, setInputSeats] = useState(value);

  const debouncedSeats = useDebounce(inputSeats);

  // min seats for custom plans is always 1
  const { minSeats = 1, seats: maxSeats = 1 } = isCustomPlan
    ? { ...(PLAN_SEATS[plan] || {}), minSeats: 1 }
    : PLAN_SEATS[plan] || {};

  const onChangeHandler = (value: number) => {
    if (value < teamCount) {
      onError(
        'Minimum limit of seats is exceeded. Please change current number of users on your team or contact customer support for assistance.',
      );

      return onChange(teamCount);
    }

    if (value > maxSeats) {
      onError(`You've reached the maximum limit of ${maxSeats} seats. Please contact customer support for assistance.`);

      return onChange(maxSeats);
    }

    if (value < minSeats) {
      onError(`You need to have at least ${minSeats} seat${minSeats > 1 ? 's' : ''} for this plan.`);

      return onChange(minSeats);
    }

    onError();
    onChange(value);
  };

  const onMinusClick = () =>
    !disabled && inputSeats >= Math.max(minSeats, teamCount) && setInputSeats((prev) => Math.max(prev - 1, 1));

  const onPlusClick = () => !disabled && inputSeats <= maxSeats && setInputSeats((prev) => prev + 1);

  useEffect(() => {
    onChangeHandler(debouncedSeats);
  }, [debouncedSeats]);

  return (
    <>
      <Typography color="gray.700" medium mb="4px">
        Modify number of seats
      </Typography>

      <InputWrapper className={classNames({ error, disabled })}>
        <IconWrapper
          onClick={onMinusClick}
          className={classNames({ disabled: inputSeats === 1 || inputSeats < Math.max(minSeats, teamCount) })}
        >
          <MinusIcon />
        </IconWrapper>

        <fieldset disabled={disabled}>
          <NumberInput
            type="number"
            min={minSeats}
            max={maxSeats}
            value={inputSeats}
            onChange={(e) => setInputSeats(Number(e.target.value))}
            onWheel={(e) => e.currentTarget.blur()}
          />
        </fieldset>

        <IconWrapper onClick={onPlusClick} className={classNames({ disabled: inputSeats > maxSeats })}>
          <PlusIcon />
        </IconWrapper>
      </InputWrapper>

      {!!error && (
        <Box display="flex" alignItems="center" gap="8px" mt="8px" lineHeight="14px">
          <WarningIcon />
          <Typography color="gray.600">{error}</Typography>
        </Box>
      )}
    </>
  );
};
