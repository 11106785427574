import { inRange } from 'lodash';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import mux from 'mux.js';

import { POST_MAX_VIDEO_DURATION, POSTS_MAX_VIDEO_SIZE } from 'src/constants';
import { PostAttachmentError } from 'src/enums';
import { createTempImageElement, createTempVideoElement, getImageMimeType } from 'src/helpers';

interface IValidationCallbacks {
  onError: (errorType: PostAttachmentError) => void;
  onSuccess: (file: File) => void;
}

interface IValidationOptions {
  postsWithLinkedinTwitter?: boolean;
  postsWithInstagram?: boolean;
  postsWithTwitter?: boolean;
  postsWithLinkedin?: boolean;
  postsWithFacebook?: boolean;
}

export const checkVideoPostAttachment = (file: File, callbacks: IValidationCallbacks, options?: IValidationOptions) =>
  createTempVideoElement(file, async (element) => {
    const { onError, onSuccess } = callbacks;
    const { postsWithFacebook, postsWithInstagram, postsWithLinkedin, postsWithLinkedinTwitter, postsWithTwitter } =
      options || {};

    const fileSize = file.size;
    const width = element.videoWidth;
    const height = element.videoHeight;
    const aspectRatio = width / height;

    if (postsWithLinkedinTwitter) {
      switch (true) {
        case fileSize > POSTS_MAX_VIDEO_SIZE.linkedin:
          return onError(PostAttachmentError.LINKEDIN_VIDEO_SIZE_ERROR);
        case element.duration > POST_MAX_VIDEO_DURATION.twitter:
          return onError(PostAttachmentError.TWITTER_VIDEO_DURATION_ERROR);
      }
    }

    if (postsWithTwitter) {
      switch (true) {
        case aspectRatio < 1 / 3 || aspectRatio > 3:
          return onError(PostAttachmentError.INVALID_TWITTER_ASPECT_RATIO);
        case element.duration > POST_MAX_VIDEO_DURATION.twitter:
          return onError(PostAttachmentError.TWITTER_VIDEO_DURATION_ERROR);
        case fileSize > POSTS_MAX_VIDEO_SIZE.twitter:
          return onError(PostAttachmentError.TWITTER_VIDEO_SIZE_ERROR);
      }
    }

    if (postsWithFacebook) {
      const inRatio =
        (aspectRatio >= 4 / 5 && aspectRatio <= 5 / 4) ||
        (aspectRatio >= 9 / 16 && aspectRatio <= 16 / 9) ||
        (aspectRatio >= 1 / 1.91 && aspectRatio <= 1.91);

      switch (true) {
        case !inRatio:
          return onError(PostAttachmentError.INVALID_ASPECT_RATIO);
        case fileSize > POSTS_MAX_VIDEO_SIZE.facebook:
          return onError(PostAttachmentError.FACEBOOK_VIDEO_SIZE_ERROR);
        case element.duration > POST_MAX_VIDEO_DURATION.facebook:
          return onError(PostAttachmentError.FACEBOOK_VIDEO_DURATION_ERROR);
      }
    }

    if (postsWithLinkedin) {
      const inRatio = aspectRatio >= 1 / 2.4 && aspectRatio <= 2.4;

      switch (true) {
        case fileSize > POSTS_MAX_VIDEO_SIZE.linkedin:
          return onError(PostAttachmentError.LINKEDIN_VIDEO_SIZE_ERROR);

        case element.duration > POST_MAX_VIDEO_DURATION.linkedin:
          return onError(PostAttachmentError.LINKEDIN_VIDEO_DURATION_ERROR);

        case !inRatio:
          return onError(PostAttachmentError.INVALID_ASPECT_RATIO);

        case !inRange(width, 256, 4097):
        case !inRange(height, 144, 2305):
          return onError(PostAttachmentError.LINKEDIN_VIDEO_RESOLUTION_RANGE_ERROR);
      }
    }

    if (postsWithInstagram) {
      const inRatio =
        (aspectRatio >= 4 / 5 && aspectRatio <= 5 / 4) ||
        (aspectRatio >= 9 / 16 && aspectRatio <= 16 / 9) ||
        (aspectRatio >= 1 / 1.91 && aspectRatio <= 1.91);

      switch (true) {
        case !inRatio:
          return onError(PostAttachmentError.INVALID_ASPECT_RATIO);

        case width > 1920:
          return onError(PostAttachmentError.INSTAGRAM_VIDEO_WIDTH_ERROR);
        case fileSize > POSTS_MAX_VIDEO_SIZE.instagram:
          return onError(PostAttachmentError.INSTAGRAM_VIDEO_SIZE_ERROR);

        case !['video/mp4', 'video/quicktime'].includes(file.type):
          return onError(PostAttachmentError.INSTAGRAM_VIDEO_TYPE_ERROR);
        case element.duration < 3 || element.duration > POST_MAX_VIDEO_DURATION.instagram:
          return onError(PostAttachmentError.INSTAGRAM_VIDEO_DURATION_ERROR);
      }

      if (file) {
        const buffer = await file?.arrayBuffer?.();
        const tracks = mux.mp4.probe.tracks(new Uint8Array(buffer));
        const videoTrack = tracks?.find((track: { type: string; codec: string }) => track.type === 'video');

        if (videoTrack && !(videoTrack.codec.includes('avc1') || videoTrack.codec.includes('hev1'))) {
          return onError(PostAttachmentError.INSTAGRAM_INVALID_VIDEO_CODEC);
        }
      }
    }

    return onSuccess(file);
  });

export const checkImagePostAttachment = (file: File, callbacks: IValidationCallbacks, options: IValidationOptions) =>
  createTempImageElement(file, async (element) => {
    const { onError, onSuccess } = callbacks;
    const { postsWithFacebook, postsWithInstagram, postsWithLinkedin, postsWithLinkedinTwitter, postsWithTwitter } =
      options;

    const fileSize = file.size;
    const width = element.width;
    const height = element.height;
    const aspectRatio = width / height;
    const mimeType = (await getImageMimeType(file)) || '';

    if (postsWithLinkedinTwitter) {
      switch (true) {
        case fileSize > 15000000:
          return onError(PostAttachmentError.LINKEDIN_IMAGE_SIZE_ERROR);
      }
    }

    if (postsWithLinkedin) {
      switch (true) {
        case fileSize > 15000000:
          return onError(PostAttachmentError.LINKEDIN_IMAGE_SIZE_ERROR);
        case !['image/png', 'image/gif', 'image/jpeg'].includes(mimeType):
          return onError(PostAttachmentError.LINKEDIN_IMAGE_INVALID_FORMAT);
      }
    }

    if (postsWithFacebook) {
      switch (true) {
        case fileSize > 30000000:
          return onError(PostAttachmentError.FACEBOOK_IMAGE_SIZE_ERROR);
      }
    }

    if (postsWithTwitter) {
      switch (true) {
        case fileSize > 5000000:
          return onError(PostAttachmentError.TWITTER_IMAGE_SIZE_ERROR);
      }
    }

    if (postsWithInstagram) {
      switch (true) {
        case aspectRatio < 4 / 5 || aspectRatio > 16 / 9:
          return onError(PostAttachmentError.INVALID_ASPECT_RATIO);
        case fileSize > 8000000:
          return onError(PostAttachmentError.INSTAGRAM_IMAGE_SIZE_ERROR);
      }
    }

    return onSuccess(file);
  });

export const checkDocumentPostAttachment = (
  file: File,
  callbacks: IValidationCallbacks,
  options: IValidationOptions,
) => {
  const { onError, onSuccess } = callbacks;
  const { postsWithLinkedin, postsWithLinkedinTwitter } = options;

  const fileSize = file.size;

  if (postsWithLinkedin || postsWithLinkedinTwitter) {
    switch (true) {
      case fileSize > 100000000:
        return onError(PostAttachmentError.LINKEDIN_DOCUMENT_SIZE_ERROR);
    }
  }

  onSuccess(file);
};
