import { useMemo, useState } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Collapse } from '@mui/material';

import { ActionButton, Link, TableCell, TableRow, Toggle } from 'src/components';
import { CampaignCategory } from 'src/enums';
import { getCampaignTypes, resolveShowCampaignChart } from 'src/helpers';
import { useCampaignsListProps } from 'src/hooks';
import { useUpdateRunningState } from 'src/reactQueries';
import { IGetCampaigns, TCampaignsStatus } from 'src/types';
import { CampaignActions } from './CampaignActions';
import { CampaignsTableRowCell } from './CampaignsTableRowCell';
import { StatsChart } from './StatsChart';

const ChartTableCell = styled(TableCell)`
  padding-bottom: 0;
  padding-top: 0;
  border: none;

  & > div {
    padding: 0;
  }
`;

interface ICampaignsTableRowProps {
  campaign: IGetCampaigns;
  campaignStatus: TCampaignsStatus;
}

export const CampaignsTableRow = ({ campaign, campaignStatus }: ICampaignsTableRowProps) => {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);

  const { runState, name, id, category } = campaign;
  const { updateRunningState } = useUpdateRunningState(id, {
    campaignType: campaign.campaignType,
    isLinkedInOnly: campaign.isLinkedinOnly,
    category: campaign.category,
  });
  const { isLinkedinCampaign, isMultichannelCampaign } = getCampaignTypes(campaign);
  const { columnConfig, generateDataset } = useCampaignsListProps(category);

  const isRunning = useMemo(() => runState === 'running', [runState]);

  const isActive = campaignStatus === 'active';
  const isShowChart = (isLinkedinCampaign || isMultichannelCampaign) && isActive && resolveShowCampaignChart(campaign);
  const isTwitterCategory = campaign?.category === CampaignCategory.TWITTER;

  const toggleCampaign = () => {
    updateRunningState(isRunning ? 'paused' : 'running');
  };

  return (
    <>
      <TableRow hoverBgColor={({ light }) => light.main}>
        <TableCell>
          {isActive && (
            <Toggle
              name="toggle"
              onChange={toggleCampaign}
              checked={isRunning}
              checkedBgColor={palette.success.main}
              uncheckedBgColor={palette.warning.main}
              disabled={!isRunning && isTwitterCategory}
              tooltip={{
                title:
                  (!isRunning && isTwitterCategory && 'Feature under Maintenance') ||
                  (isRunning ? 'Click to pause campaign' : 'Click to resume campaign'),
                placement: 'top',
              }}
            />
          )}
        </TableCell>

        <TableCell tooltip={name}>
          <Link variant="lightBlue" crop to={`/campaign/details/overview/${id}`} fontSize="15px">
            {name || `Campaign draft (${id})`}
          </Link>
        </TableCell>

        <CampaignsTableRowCell campaign={campaign} campaignStatus={campaignStatus} />

        <TableCell justifyContent="flex-end" sticky>
          {isShowChart && (
            <ActionButton
              tooltip={open ? 'Click to see less details' : 'Click to see more details'}
              icon={open ? faChevronUp : faChevronDown}
              fontSize="14px"
              onClick={() => {
                setOpen((prev) => !prev);
              }}
            />
          )}

          <CampaignActions campaign={campaign} campaignStatus={campaignStatus} />
        </TableCell>
      </TableRow>
      <TableRow>
        <ChartTableCell colSpan={columnConfig?.length + 3} contentStyle={{ width: '100%' }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {!!generateDataset && <StatsChart campaignId={id} generateDataset={generateDataset} />}
          </Collapse>
        </ChartTableCell>
      </TableRow>
    </>
  );
};
