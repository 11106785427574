import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faBoxArchive, faEnvelopeOpen, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';

import { ActionButton, GroupedPersonAvatar, Link, PersonAvatar, Typography } from 'src/components';
import { ModalTypes, ModifyConversationActions } from 'src/enums';
import { useCompanyTheme } from 'src/hooks';
import { useGetLeadInfo, useModifyConversation } from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';
import { ILIConversation } from 'src/types';
import LeadDrawer from '../../Contacts/LeadDetails/LeadDrawer';

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  padding: 7px 20px;
  box-shadow: 0 2px 9px rgb(11, 22, 64, 5%);
  border-bottom: 1px solid ${({ theme }) => theme.palette.light.light};
`;

const StyledActionButton = styled(ActionButton)`
  & svg,
  path {
    color: #8c96ad !important;
  }

  &:hover svg path {
    color: ${({ theme, disabled }) => disabled || theme.palette.primary.main} !important;
  }
`;

interface IMessagesLayoutProps {
  conversationData: ILIConversation;
  isLoadingMessages: boolean;
  isSNConversation?: boolean;
  hideConversationActions?: boolean;
}

export const MessagesLayout = ({
  conversationData: {
    entityUrn,
    objectUrn,
    conversationId,
    name,
    groupName,
    isGroup,
    participants,
    imageUrl,
    isArchived,
    isConnected,
    headline,
  },
  isLoadingMessages,
  isSNConversation,
  hideConversationActions = false,
}: IMessagesLayoutProps) => {
  const dispatch = useDispatch();

  const { shortCompanyName } = useCompanyTheme();
  const { leadInfo } = useGetLeadInfo(entityUrn, objectUrn);

  const { modifyConversation } = useModifyConversation(conversationId, isSNConversation);

  const participantsHeadline = useMemo(() => {
    const exceptFirstTwo = participants?.slice(2).length || 0;
    if (participants) {
      if (participants.length > 4) {
        return `${participants
          .slice(0, 2)
          .map((participant) => participant.name)
          .join(', ')} and ${exceptFirstTwo} others`;
      } else if (exceptFirstTwo <= 4) {
        return `${participants
          .slice(0, -1)
          .map((participant) => participant.name)
          .join(', ')} and ${participants.slice(-1)[0].name}`;
      }
    }

    return '';
  }, [participants, conversationId, entityUrn]);

  const modifyHandler = (action: ModifyConversationActions) => {
    modifyConversation(action);
  };

  const deleteHandler = () => {
    dispatch(
      openModal({
        type: ModalTypes.DELETE_CONVERSATION,
        closable: false,
        params: {
          name,
          conversationId,
        },
      }),
    );
  };

  return (
    <Wrapper>
      {isGroup ? (
        <GroupedPersonAvatar>
          {participants?.map((participant) => (
            <PersonAvatar key={participant.entityUrn} src={participant.imageUrl} width="40px" height="40px" />
          ))}
        </GroupedPersonAvatar>
      ) : (
        <PersonAvatar src={imageUrl} width="40px" height="40px" />
      )}

      <Box ml="10px" display="flex" flexWrap="wrap" gap="10px">
        <Typography color="#0073b1" fontSize="15px" semibold>
          {isGroup ? (
            <a
              href={`https://www.linkedin.com/messaging/thread/${conversationId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {groupName}
            </a>
          ) : (
            <Link
              semibold
              title={`See profile on ${shortCompanyName}`}
              to={`${location.pathname}?person=${entityUrn}&tab=personal_details`}
            >
              {name}
            </Link>
          )}
        </Typography>

        {!isConnected && !isGroup && (
          <Typography fontSize="14px" color="gray.dark">
            You haven’t connected with this contact
          </Typography>
        )}

        <Typography color="gray.dark" fontSize="14px" width="100%">
          {isGroup ? participantsHeadline : headline || leadInfo?.person?.headline || leadInfo?.person?.currentTitle}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" ml="auto" gap="15px">
        {!hideConversationActions && (
          <>
            <StyledActionButton
              icon={faEnvelopeOpen}
              tooltip="Mark as unread"
              disabled={isLoadingMessages}
              onClick={() => modifyHandler(ModifyConversationActions.UNREAD)}
            />
            <StyledActionButton
              icon={faBoxArchive}
              tooltip={`${isArchived ? 'Unarchive' : 'Archive'} this conversation`}
              disabled={isLoadingMessages}
              onClick={() =>
                modifyHandler(isArchived ? ModifyConversationActions.UNARCHIVE : ModifyConversationActions.ARCHIVE)
              }
            />
            {!isSNConversation && (
              <StyledActionButton
                icon={faTrashCan}
                tooltip="Delete this conversation"
                disabled={isLoadingMessages}
                onClick={deleteHandler}
              />
            )}
          </>
        )}
      </Box>
      <LeadDrawer />
    </Wrapper>
  );
};
