import { useState } from 'react';
import { Control, Controller } from 'react-hook-form';
import Select, { components } from 'react-select';
import { useTheme } from '@emotion/react';
import { faCaretDown, faCaretUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { ITemplateCategory } from 'src/types';
import { ICreateTemplateForm } from '../TemplateCreator/TemplateCreator';

export const CategorySelect = ({
  name,
  control,
  label,
  required,
  data,
}: {
  name: keyof ICreateTemplateForm;
  // eslint-disable-next-line
  control: Control<any>;
  label: string;
  required?: string | boolean;
  data: ITemplateCategory[];
}) => {
  const { palette } = useTheme();
  const [isOpened, setIsOpened] = useState(false);

  return (
    <Box display="flex" flexDirection="row" alignItems="center" width="50%">
      <Typography color="text.primary">{label}</Typography>
      <Box display="flex" flex="1" position="relative" data-testid="category-select">
        <Controller
          name={name}
          rules={{ required }}
          control={control}
          render={({ field }) => {
            return (
              <Select
                onMenuOpen={() => setIsOpened(true)}
                onMenuClose={() => setIsOpened(false)}
                options={data?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                {...field}
                noOptionsMessage={() => (
                  <Typography align="left" color="gray">
                    No results found
                  </Typography>
                )}
                placeholder="Select Category"
                styles={{
                  container: (provided) => ({
                    ...provided,
                    display: 'flex',
                    flex: 1,
                    marginLeft: 10,
                  }),
                  control: (provided) => ({
                    ...provided,
                    border: `2px solid ${palette.light.dark} !important`,
                    borderRadius: 5,
                    boxShadow: 'none',
                    display: 'flex',
                    flex: 1,
                    height: 34,
                    minHeight: 34,
                    outline: 'none',
                  }),
                  input: (provided) => ({
                    ...provided,
                    margin: 0,
                    color: '#333',
                    fontFamily: 'ProximaSoft',
                    fontSize: 14,
                    fontWeight: 400,
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: 'var(--violet-main)',
                    fontFamily: 'ProximaSoft',
                    fontSize: 14,
                  }),
                  menu: (provided) => ({
                    ...provided,
                    marginTop: 0,
                  }),
                  menuList: (provided) => ({
                    ...provided,
                    paddingTop: '10px',
                    paddingBottom: '10px',
                  }),
                  option: (provided, { isSelected }) => ({
                    ...provided,
                    backgroundColor: '#fff',
                    color: isSelected ? palette.primary.main : palette.text.primary,
                    cursor: 'pointer',
                    display: 'flex',
                    flex: 1,
                    fontFamily: 'ProximaSoft',
                    fontSize: '14px',
                    justifyContent: 'space-between',
                    padding: '10px 20px',
                    '&:hover': {
                      color: palette.primary.main,
                    },
                    '&:active': {
                      backgroundColor: '#fff',
                    },
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: '#333',
                    fontFamily: 'ProximaSoft',
                    fontSize: 14,
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                    height: '30px',
                    padding: '0 6px',
                  }),
                  indicatorsContainer: (provided) => ({
                    ...provided,
                    height: '30px',
                  }),
                }}
                components={{
                  IndicatorSeparator: null,
                  DropdownIndicator: () => {
                    return (
                      <Box width="25px" marginRight="5px" display="flex" justifyContent="center">
                        <FontAwesomeIcon icon={isOpened ? faCaretUp : faCaretDown} color={palette.primary.main} />
                      </Box>
                    );
                  },
                  Option: ({ children, ...props }) => {
                    return (
                      <components.Option {...props}>
                        <>
                          {children}
                          {props.isSelected && <FontAwesomeIcon icon={faCheck} color={palette.primary.main} />}
                        </>
                      </components.Option>
                    );
                  },
                }}
              />
            );
          }}
        />
      </Box>
    </Box>
  );
};
