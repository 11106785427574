import { Link } from 'react-router-dom';

import { APP_DOMAIN } from '../constants';
import { removeCookie } from './common';

export const renderLoginCodeError = (code: number) => {
  switch (code) {
    case 400:
      return <span>User registered with a different authentication method. Please try again</span>;
    case 401:
      return (
        <span>
          Something went wrong. If you signed up with gmail or microsoft, please make sure to login with that method.
        </span>
      );
    case 404:
      return (
        <span>
          User doesn't exists. Please <Link to="/auth/signup">click here to create a new account</Link>
        </span>
      );
    default:
      return null;
  }
};

export const renderSignUpCodeError = (code: number) => {
  if (code === 400) {
    return (
      <span>
        User already exists. Please <Link to="/auth/login">click here to login</Link>
      </span>
    );
  }

  return null;
};

export const removeReferralCodeFromCookies = () => {
  const hostArray = APP_DOMAIN.split('.');
  const hostArrayLength = hostArray.length;

  let domain = '.';

  if (hostArray[hostArrayLength - 2]) {
    domain += `${hostArray[hostArrayLength - 2]}.`;
  }

  if (hostArray[hostArrayLength - 1]) {
    domain += hostArray[hostArrayLength - 1];
  }

  removeCookie('referralCode');
};
