import { Fragment, useMemo } from 'react';

import { Typography } from 'src/components';
import { CurrencyEnum } from 'src/enums';
import { RawSelector, RawSelectorItem } from '../_components';

interface BillingCycleSelectorProps {
  currency?: CurrencyEnum;
  setCurrency: (type: CurrencyEnum) => void;
}

export const CurrencySelector = ({ currency, setCurrency }: BillingCycleSelectorProps) => {
  const currencies = useMemo(() => {
    return [
      { label: 'USD', value: CurrencyEnum.USD },
      { label: 'EUR', value: CurrencyEnum.EUR },
      { label: 'GBP', value: CurrencyEnum.GBP },
    ];
  }, []);

  return (
    <RawSelector>
      {currencies.map(({ label, value }) => (
        <Fragment key={value}>
          <RawSelectorItem chosen={(value === currency).toString()} onClick={() => setCurrency(value)}>
            <Typography fontSize="15px" color="black.base" inline>
              {label}
            </Typography>
          </RawSelectorItem>
        </Fragment>
      ))}
    </RawSelector>
  );
};
