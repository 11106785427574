import { getAllCountries, getCountryForTimezone } from 'countries-and-timezones';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button, HeaderContainer, HeaderDescription, HeaderTitle, HeaderVideo, Link, Typography } from 'src/components';
import { delay } from 'src/helpers';
import { useCompanyTheme, useWhiteLabel } from 'src/hooks';
import { useGetUserAccount, useUpdateDailyLimitsTimezone } from 'src/reactQueries';
import { ISelectOption } from 'src/types';
import { SuccessMessage, Title, Wrapper } from '../_components';
import { Scheduler } from './Scheduler';

const AlfSelect = styled(Select)`
  margin-top: 20px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

const ContentWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
`;

export const defaultCounty = {
  label: 'Ukraine',
  value: 'UA',
  timezones: ['Europe/Kiev', 'Europe/Simferopol', 'Europe/Uzhgorod', 'Europe/Zaporozhye'],
};

interface ICountryOption extends ISelectOption {
  timezones: string[];
}

export const WorkHours = () => {
  const [show, setShow] = useState(false);
  const { shortCompanyName } = useCompanyTheme();
  const [selectedTimezone, setSelectedTimezone] = useState<SingleValue<ISelectOption>>();
  const [selectedCountry, setSelectedCountry] = useState<SingleValue<ICountryOption>>(defaultCounty);
  const countries = Object.entries(getAllCountries()).map(([key, value]) => ({
    value: key,
    label: value.name,
    timezones: value.timezones,
  }));

  const { whitelabel } = useWhiteLabel();

  const addTimezoneTime = (timezone: string) => {
    try {
      return `${timezone} (${dayjs(new Date()).tz(timezone).format('hh:mm a')})`;
    } catch (e) {
      console.error(e);

      return '';
    }
  };

  const timezones = selectedCountry?.timezones.map((value) => ({
    label: addTimezoneTime(value),
    value: value,
  }));

  const { userMe } = useGetUserAccount();
  const { name, country } = userMe?.timezone ?? {};
  const { updateDailyLimitsTimezone, isSuccess, isError, isLoading } = useUpdateDailyLimitsTimezone();

  useEffect(() => {
    if (name) {
      setSelectedTimezone({
        label: addTimezoneTime(name),
        value: name,
      });
    }
    if (country && name) {
      const {
        id: countryId = defaultCounty.value,
        timezones: countryTimezones = defaultCounty.timezones,
        name: countryName = defaultCounty.label,
      } = getCountryForTimezone(name) ?? {};
      setSelectedCountry({ label: countryName, value: countryId, timezones: countryTimezones });
    }
  }, [userMe]);

  useEffect(() => {
    if (isSuccess || isError) {
      setShow(true);
    }
    const timeId = delay(3000, () => setShow(false));

    return () => {
      clearTimeout(timeId);
    };
  }, [isSuccess, isError]);

  const saveTimezone = () => selectedTimezone?.value && updateDailyLimitsTimezone(selectedTimezone?.value);

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Working Hours</HeaderTitle>
        <HeaderDescription>
          Adjust {shortCompanyName}'s start and end times for each day of the week, and select your preferred timezone.
          {whitelabel.isWhiteLabelUser || (
            <>
              <Link
                leftIcon
                underline
                external
                openNewTab
                to="https://meetalfred.com/help/en/articles/3321094-set-meetalfred-s-working-hours"
              >
                Learn how it works
              </Link>
              <HeaderVideo src="https://player.vimeo.com/video/871556517" />
            </>
          )}
        </HeaderDescription>
      </HeaderContainer>
      <ContentWrapper>
        <Wrapper>
          <Scheduler />
        </Wrapper>

        <Wrapper data-testid="timezone">
          <Title>Timezone</Title>
          <Typography>
            Please select location and timezone. {shortCompanyName} will use the working hours according to this
            selected timezone.
          </Typography>
          <AlfSelect
            placeholder="Select your location"
            options={countries}
            onChange={(option) => {
              const nextCountry = option as ICountryOption;
              const timezone = nextCountry.timezones[0];
              setSelectedCountry(nextCountry);
              setSelectedTimezone({ label: addTimezoneTime(timezone), value: timezone });
            }}
            value={selectedCountry}
          />
          <AlfSelect
            placeholder="Select your location"
            options={timezones}
            onChange={(nextTimezone) => {
              setSelectedTimezone(nextTimezone as ISelectOption);
            }}
            value={selectedTimezone}
          />

          <Box mt="20px" display="flex" justifyContent="space-between">
            <Box>
              {show && isSuccess && <SuccessMessage>Timezone updated successfully</SuccessMessage>}
              {show && isError && <Typography color="error.main">No Active Bot Found</Typography>}
            </Box>
            <Button onClick={saveTimezone} processing={isLoading}>
              Save
            </Button>
          </Box>
        </Wrapper>
      </ContentWrapper>
    </>
  );
};
