import { useQuery, UseQueryOptions } from 'react-query';

import { getCampaignsDashboard } from 'src/api';
import { ICustomAxiosError, IGetDashboardCampaign, IGetDashboardParams, IPagination } from 'src/types';

export const useGetDashboardCampaign = <TData = IGetDashboardCampaign>(
  data: IGetDashboardParams & IPagination,
  options?: UseQueryOptions<IGetDashboardCampaign, ICustomAxiosError, TData>,
) => {
  return useQuery<IGetDashboardCampaign, ICustomAxiosError, TData>(
    ['campaigns-dashboard', JSON.stringify(data)],
    () => getCampaignsDashboard(data),
    options,
  );
};
