import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import styled from '@emotion/styled';
import { useGoogleLogin } from '@react-oauth/google';

import googleIcon from 'src/assets/icons/google-icon.svg';
import { useGoogleAuth } from 'src/reactQueries';
import { IAuthResponse, TSignUpRequest } from 'src/types';
import { Button } from './Button';

const StyledButton = styled(Button)`
  background: #4f73ff;
  font-size: 0.9em;
  font-weight: 600;
  position: relative;
  width: 100%;
  height: 42px;
  border: none;
`;

const StyledImage = styled.img`
  padding: 8px;
  border-radius: 0.3em 0 0 0.3em;
  background-color: #ffffff;
  position: absolute;
  left: 2px;
  top: 2px;
`;

interface IGoogleButton {
  redirectTo: string;
  isSignup?: boolean;
  onSuccess?: (data: IAuthResponse) => void;
  setError: Dispatch<SetStateAction<number | null>>;
}

const cookies = new Cookies();

export const GoogleButton = ({ setError, onSuccess, redirectTo, isSignup = false }: IGoogleButton) => {
  const { state = {} } = useLocation();
  const locationState = state as TSignUpRequest;
  const [googleAuthCode, setGoogleAuthCode] = useState('');

  const login = useGoogleLogin({
    onSuccess: ({ code }) => setGoogleAuthCode(code),
    flow: 'auth-code',
    scope: 'profile email',
  });

  const { isLoading, error } = useGoogleAuth(
    {
      ...locationState,
      authorizationCode: googleAuthCode || '',
      referralCode: cookies.get('referralCode'),
    },
    isSignup,
    redirectTo,
    {
      onSuccess,
    },
  );

  useEffect(() => {
    if (error?.statusCode) {
      setError(error.statusCode);
    }
  }, [error, setError]);

  return (
    <StyledButton disabled={isLoading} onClick={login}>
      <StyledImage src={googleIcon} alt="google icon" />
      {isSignup ? 'Sign up' : 'Login'} with Google
    </StyledButton>
  );
};
