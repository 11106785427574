import { useQuery, UseQueryOptions } from 'react-query';

import { getNextInvoiceDetails } from 'src/api';
import { ICustomAxiosError, IStripeInvoice } from 'src/types';

export const useGetUpcomingInvoiceDetails = (options?: UseQueryOptions<IStripeInvoice, ICustomAxiosError>) => {
  const { data, ...rest } = useQuery<IStripeInvoice, ICustomAxiosError>(
    ['get-upcoming-invoice'],
    getNextInvoiceDetails,
    {
      refetchOnMount: true,
      ...options,
    },
  );

  return { invoiceDetails: data, ...rest };
};
