import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { leaveTeam } from 'src/api';
import { removeTeam } from 'src/store/team.slice';
import { showToast } from 'src/store/toast.slice';
import { removeTeamId } from 'src/store/user.slice';
import { ICustomAxiosError } from 'src/types';

export const useLeaveTeam = (options?: UseMutationOptions<void, ICustomAxiosError>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation<void, ICustomAxiosError>(['leave-team'], leaveTeam, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      dispatch(removeTeamId());
      dispatch(removeTeam());

      queryClient.removeQueries(['get-team-by-id']);
      queryClient.removeQueries(['get-team-invites']);
      queryClient.removeQueries(['get-team-members']);

      await queryClient.resetQueries();
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);

      dispatch(
        showToast({
          type: 'error',
          message: err?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return {
    leaveTeam: mutate,
    ...rest,
  };
};
