import classNames from 'classnames';
import { InputHTMLAttributes, ReactNode, useState } from 'react';
import { RegisterOptions, UseFormRegister } from 'react-hook-form';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alpha, Box, TooltipProps } from '@mui/material';

import { Tooltip, Typography } from '../ui';

const Label = styled.label`
  display: block;
  font-family: 'ProximaSoft', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3rem;
  color: ${({ theme }) => theme.palette.text.primary};
  margin-bottom: 5px;
`;

const StyledInput = styled.input<{ preTab?: boolean; generalType?: string }>`
  display: block;
  width: 100%;
  padding: 6px ${({ generalType }) => (generalType === 'password' ? '38px' : '12px')} 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-width: 1px !important;
  border: 1px solid ${({ theme }) => theme.palette.gray['200']};
  border-radius: ${({ preTab }) => (preTab ? '0 4px 4px 0' : '4px')};
  transition:
    border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  height: 42px;
  box-shadow: 0 1px 1px 0 #dfdfe8;
  box-sizing: border-box;

  &:focus {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    box-shadow:
      inset 0 1px 1px rgb(0 0 0 / 8%),
      0 0 8px ${({ theme }) => alpha(theme.palette.primary.main, 0.6)};
    outline: 0;
  }

  &.error {
    border: 1px solid ${({ theme }) => theme.palette.error.light};
    &:focus {
      box-shadow:
        inset 0 1px 1px rgb(0 0 0 / 8%),
        0 0 8px rgb(246 39 19 / 60%);
    }
  }

  &::placeholder {
    color: ${({ theme }) => theme.palette.gray.main};
  }
`;

const StyledInputContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const StyledIconContainer = styled.div`
  display: flex;
  position: absolute;
  cursor: pointer;
  right: 8px;
  top: calc(50%);
  transform: translateY(-50%);
  color: #999;

  &.visible {
    right: 9px;
  }
`;

const PreInputTab = styled(Box)`
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 42px;
  background: #fafafa;
  border: 1px solid #bfbfbf;
  box-sizing: border-box;
  box-shadow: 0 1px 1px 0 #dfdfe8;
  border-radius: 4px 0 0 4px;
`;

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  register?: UseFormRegister<any>; // eslint-disable-line
  name?: string;
  parameters?: RegisterOptions;
  label?: string | JSX.Element;
  labelStyle?: string;
  removeAllAction?: (() => void) | false;
  tooltip?: Omit<TooltipProps, 'children'>;
  preTab?: ReactNode;
  showRequiredIcon?: boolean;
}

export const Input = ({
  register,
  name = 'input',
  parameters = {},
  label,
  type,
  labelStyle,
  removeAllAction,
  preTab,
  tooltip = {
    title: '',
  },
  showRequiredIcon = false,
  ...restProps
}: InputProps) => {
  const { palette } = useTheme();
  const [visible, setVisible] = useState(type !== 'password');

  return (
    <Box width="100%" color="primary.dark">
      {label && (
        <Label htmlFor={name} className={labelStyle}>
          {label}
          {showRequiredIcon && parameters?.required && (
            <Typography inline color="error.light" ml="4px">
              *
            </Typography>
          )}
        </Label>
      )}
      <StyledInputContainer>
        {preTab && <PreInputTab>{preTab}</PreInputTab>}
        <Tooltip {...tooltip}>
          <StyledInput
            {...(register ? register(name, parameters) : {})}
            {...restProps}
            preTab={!!preTab}
            generalType={type}
            type={visible ? 'input' : 'password'}
          />
        </Tooltip>
        {removeAllAction && (
          <StyledIconContainer onClick={removeAllAction}>
            <FontAwesomeIcon icon={faXmark} color={palette.gray.dark} size="sm" />
          </StyledIconContainer>
        )}
        {type === 'password' && (
          <StyledIconContainer
            onClick={() => setVisible((prevState) => !prevState)}
            className={classNames({ visible })}
          >
            <FontAwesomeIcon icon={visible ? faEyeSlash : faEye} />
          </StyledIconContainer>
        )}
      </StyledInputContainer>
    </Box>
  );
};
