import { isEmpty } from 'lodash';
import { MouseEvent, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Checkbox } from 'src/components';
import { Typography } from 'src/components/ui/Typography';
import { download, parseCsvBody } from 'src/helpers';
import { ICSVUploadValue, TCSVFileTypes } from 'src/types';
import { CSVSnippetsTable } from './CSVSnippetsTable';
import { CSVTable } from './CSVTable';

const StyledDropzoneBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 1076px;
  margin: 20px auto 0;
  min-height: 80px;
  padding: 80px 60px;
  text-align: center;
  border-radius: 5px;
  border: 2px dashed #c7c7c7;
  cursor: pointer;
`;

const StyledDropzoneIcon = styled.div`
  position: relative;
  width: 32px;
  height: 30px;
  margin-bottom: 50px;

  &:before {
    position: absolute;
    top: -7px;
    left: 0;
    width: 29px;
    height: 34px;
    content: '';
    border: 2px solid ${({ theme }) => theme.palette.primary.wlLight};
    border-radius: 2px;
  }
  &:after {
    font-size: 11px;
    line-height: 1.3;
    position: absolute;
    top: 9px;
    left: -4px;
    padding: 0 2px;
    content: '.csv';
    text-align: right;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
    background-color: ${({ theme }) => theme.palette.primary.wlLight};
  }
`;

const StyledRemoveButton = styled.div`
  margin-top: 14px;
  padding: 6px 12px;
  color: ${({ theme }) => theme.palette.primary.wlLight};
  font-size: 14px;
  font-weight: 500;
  transition: 0.1s;

  &:hover {
    color: ${({ theme }) => theme.palette.info.main};
    text-decoration: underline;
  }
`;

const CSVUploadWrapper = styled(Box)`
  width: 100%;
`;

const TypographyLink = styled(Typography)`
  color: ${({ theme }) => theme.palette.info.dark};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.lightGray.light}`};
  transition: color 0.2s;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.palette.info.main};
  }
`;

interface ICSVUploadProps {
  value: ICSVUploadValue;
  label?: string;
  status?: string;
  type: TCSVFileTypes;
  exampleUrl?: string;
  isRemoveButton?: boolean;
  exclude1stDegree?: boolean;
  exclude1stDegreeChange?: (data: boolean) => void;
  retargetLeads?: boolean;
  setRetargetLeads?: (data: boolean) => void;
  customSnippets?: Record<string, string>;
  onUploaded: (value: ICSVUploadValue) => void;
  isMultichannel?: boolean;
}

export const CSVUpload = ({
  value,
  label,
  status,
  type,
  exampleUrl,
  isRemoveButton = false,
  customSnippets,
  onUploaded,
  exclude1stDegree,
  exclude1stDegreeChange,
  retargetLeads,
  setRetargetLeads,
  isMultichannel = false,
}: ICSVUploadProps) => {
  const isTwitter = type === 'twitter';
  const isLinkedin = type === 'linkedin';

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length) {
      const reader = new FileReader();

      reader.readAsText(acceptedFiles[0]);

      reader.onload = () => {
        const parsedBody = parseCsvBody(reader.result, type);

        onUploaded(parsedBody);
      };

      reader.onerror = () => {
        console.error('FileReader error: ', reader.error);
        onUploaded({ error: 'Wrong format of file' });
      };
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'text/csv': ['.csv'] },
    multiple: false,
    onDrop,
  });

  const updateHeaderHandler = (header: Record<string, string>) => {
    if (value.request) {
      onUploaded({ ...value, request: { ...value.request, header } });
    }
  };

  const removeFileHandler = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    onUploaded({});
  };

  if (value?.error && !isRemoveButton) {
    return (
      <Typography color="error.main" fontSize="16px" marginTop="10px" semibold>
        {value.error}
      </Typography>
    );
  }

  if (value?.request) {
    return (
      <CSVUploadWrapper>
        {isLinkedin && (
          <Box display="flex" alignItems="center" my="25px" onClick={() => exclude1stDegreeChange?.(!exclude1stDegree)}>
            <Checkbox readOnly type="checkbox" checked={exclude1stDegree} />
            <Typography ml="10px">Exclude 1st degree connections.</Typography>
          </Box>
        )}
        <Box display="flex" alignItems="center" my="25px" onClick={() => setRetargetLeads?.(!retargetLeads)}>
          <Checkbox readOnly type="checkbox" checked={retargetLeads} />
          <Typography ml="10px">Include leads from other campaigns.</Typography>
        </Box>
        <CSVTable
          header={value.request.header}
          leads={value.request.leads}
          focusedField={value?.focusedField}
          status={status}
          type={type}
          stickyColumns={isTwitter ? 2 : 1}
          onUpdateHeader={updateHeaderHandler}
        />
      </CSVUploadWrapper>
    );
  }

  return (
    <CSVUploadWrapper>
      <Typography color="gray.dark" mb="5px">
        {label}
        {exampleUrl && (
          <span style={{ marginLeft: '5px' }}>
            (
            <TypographyLink component="span" pointer inline onClick={() => download(exampleUrl)}>
              Download example
            </TypographyLink>
            )
          </span>
        )}
      </Typography>
      {isMultichannel && (
        <Typography color="gray.dark" mt="20px" mb="5px" semibold>
          Email and email address columns will be used to sent email in the first place
        </Typography>
      )}
      <Typography color="gray.dark" mt="20px" mb="5px" semibold>
        CSV file size must be less than 5000 lines
      </Typography>
      {!isEmpty(customSnippets) && !value?.error && <CSVSnippetsTable customSnippets={customSnippets} />}
      <StyledDropzoneBox {...getRootProps()}>
        <input {...getInputProps()} />
        {value?.error && isRemoveButton ? (
          <>
            <Typography>Error: {value.error}</Typography>
            <StyledRemoveButton onClick={removeFileHandler}>Remove file</StyledRemoveButton>
          </>
        ) : (
          <>
            <StyledDropzoneIcon />
            <Typography fontSize="14px" color="gray.dark">
              Drop files here to upload
            </Typography>
          </>
        )}
      </StyledDropzoneBox>
    </CSVUploadWrapper>
  );
};
