import dayjs from 'dayjs';
import { Box } from '@mui/material';

import { Button, Typography } from 'src/components';
import { CancelSubscriptionReason } from 'src/enums';
import { shuffleReasons } from 'src/helpers';
import { useCompanyTheme } from 'src/hooks';
import { useGetBillingInfo } from 'src/reactQueries';

interface IChooseReasonProps {
  setReason: (reason: CancelSubscriptionReason) => void;
}

export const ChooseReason = ({ setReason }: IChooseReasonProps) => {
  const { billingInfo } = useGetBillingInfo();
  const { shortCompanyName } = useCompanyTheme();

  const reasons = shuffleReasons();

  return (
    <Box pt="30px" pb="10px" px="40px" bgcolor="light.light">
      <Typography color="violet.dark" mb="20px">
        <strong>
          Deleting your {shortCompanyName} account will make it completely inaccessible at the end of your billing cycle
          {billingInfo?.stripeSubscriptionObject?.current_period_end &&
            ` on ${dayjs.unix(billingInfo?.stripeSubscriptionObject?.current_period_end).format('MM/DD/YYYY')}`}
          .
        </strong>{' '}
        All of your campaigns, connections, leads, history, notes, tags, etc will be deleted. It won’t be possible to
        recover any of your data after that period and your account will stop functioning.
      </Typography>
      <Typography textAlign="center" color="primary.wlLight" mb="20px">
        Please share your reason for deleting your account
      </Typography>

      <Box display="flex" justifyContent="space-between" flexWrap="wrap" gap="20px">
        {reasons.map((reason) => (
          <Button key={reason} size={{ width: '250px' }} onClick={() => setReason(reason)}>
            {reason}
          </Button>
        ))}
      </Box>
    </Box>
  );
};
