import { faUserFriends } from '@fortawesome/free-solid-svg-icons';

import { ActionButton } from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features, TeamUserRole } from 'src/enums';
import { useAppSelector, useTeamPlan } from 'src/hooks';
import { useGetAllTeamMembers, usePostImpersonateUser } from 'src/reactQueries';
import { TUserStatus } from 'src/types';

interface IManageUserProps {
  userId: number;
  userStatus?: TUserStatus;
}

export const ManageUser = ({ userId, userStatus = 'active' }: IManageUserProps) => {
  const { isImpersonate, profile } = useAppSelector((state) => state.user);
  const { checkFeature } = useTeamPlan();
  const { teamUser, id: teamId } = useAppSelector((state) => state.team.data) ?? {};

  const { data: members = [] } = useGetAllTeamMembers();

  const userMember = members?.find((member) => member.userId === userId);

  const { impersonateUser, isLoading } = usePostImpersonateUser();

  const onImpersonateClick = () => impersonateUser({ teamIdToImpersonate: teamId ?? -1, userIdToImpersonate: userId });

  if (profile.id === userId || isImpersonate) {
    return null;
  }

  if (teamUser?.role === TeamUserRole.MEMBER || userStatus === 'rejected' || userStatus === 'pending') {
    return null;
  }

  if (teamUser?.role === TeamUserRole.ADMIN && userMember?.role === TeamUserRole.OWNER) {
    return null;
  }

  if (!profile.isAlfredAdmin && userMember?.isAlfredAdmin) {
    return null;
  }

  if (checkFeature(Features.impersonate)) {
    return (
      <ActionButton processing={isLoading} tooltip="Manage user" icon={faUserFriends} onClick={onImpersonateClick} />
    );
  }

  return <ActionButton disabled icon={faUserFriends} tooltip={PLAN_TOOLTIPS.professional} />;
};
