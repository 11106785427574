import classNames from 'classnames';
import { Control, Controller, FieldErrorsImpl, UseFormRegister } from 'react-hook-form';
import { Box } from '@mui/material';

import LinkedInIcon from 'src/assets/icons/linkedin-icon.svg';
import { Button, Input, MessageBlock, SelectBasic, Tooltip, Typography } from 'src/components';
import { REGEXP_VALID_EMAIL_EXTENDED } from 'src/constants';
import { useCompanyTheme } from 'src/hooks';
import { ILinkedInFormFieldValues, ISelectOption } from 'src/types';
import { Accordion, AccordionDetails, AccordionSummary, Divider, GridContainer, GridItem, Loader } from '../ui';

interface ILinkedInLoginProps {
  errors: FieldErrorsImpl<ILinkedInFormFieldValues>;
  register: UseFormRegister<ILinkedInFormFieldValues>;
  control: Control<ILinkedInFormFieldValues>;
  countries: ISelectOption[];
  isLinkedinActive: boolean;
  isLoading: boolean;
  trySubmit: () => void;
  errorMessage?: string;
  isConnectDisabled: boolean;
}

export const LinkedInLogin = ({
  register,
  countries,
  trySubmit,
  errors,
  control,
  isLoading,
  isLinkedinActive,
  errorMessage,
  isConnectDisabled,
}: ILinkedInLoginProps) => {
  const { shortCompanyName } = useCompanyTheme();

  return (
    <>
      <Accordion expanded>
        <AccordionSummary tabIndex={-1}>
          <Box display="flex" alignItems="center" gap="12px">
            <img width="20px" src={LinkedInIcon} alt="LinkedIn" />
            <Typography semibold fontSize="20px">
              Connect LinkedIn
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <GridContainer container columnSpacing="22px" rowSpacing="40px" py="20px">
            <GridItem item sm={4} xs={12}>
              <Typography semibold fontSize="18px">
                Country
              </Typography>
              <Typography color="gray.500" fontSize="15px" mt="5px">
                Select your country to connect from a nearby location for a secure and reliable experience.
              </Typography>
            </GridItem>
            <GridItem item sm={4} xs={6}>
              <Tooltip title={isLinkedinActive && 'In order to change the country remove the LinkedIn connection'}>
                <span>
                  <Controller
                    control={control}
                    name="countryCode"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <Typography fontSize="14px" semibold mb="5px">
                          Select your country:
                        </Typography>
                        <SelectBasic
                          isDisabled={isLinkedinActive}
                          value={value && { label: value.label, value: value.value }}
                          placeholder="Select your location"
                          options={countries}
                          onChange={(nextCountry) => {
                            onChange(nextCountry);
                          }}
                        />
                      </>
                    )}
                  />
                </span>
              </Tooltip>
            </GridItem>
            <GridItem item sm={4} xs={6} />

            <GridItem item xs={12}>
              <Divider />
            </GridItem>

            <GridItem item sm={4} xs={12}>
              <Typography semibold fontSize="18px">
                LinkedIn account
              </Typography>
              <Typography color="gray.500" fontSize="15px" mt="5px">
                Please enter your LinkedIn credentials so {shortCompanyName} can work its magic.
              </Typography>
            </GridItem>
            <GridItem item sm={4} xs={6}>
              <Input
                label="LinkedIn Account Email"
                type="email"
                placeholder="Email"
                data-testid="email-field"
                autoComplete="off"
                register={register}
                name="email"
                className={classNames({ error: errors.email })}
                parameters={{
                  required: true,
                  pattern: {
                    value: REGEXP_VALID_EMAIL_EXTENDED,
                    message: 'Please enter valid email address',
                  },
                }}
              />
              {errors.email && (
                <Typography color="error.light" fontSize="12px" mt="5px">
                  {errors.email.message}
                </Typography>
              )}
            </GridItem>
            <GridItem item sm={4} xs={6}>
              <Input
                label="LinkedIn Password"
                type="password"
                placeholder="Password"
                data-testid="password-field"
                autoComplete="off"
                register={register}
                name="password"
                className={classNames({ error: errors.password })}
                parameters={{ required: true }}
              />
              {errors.password && (
                <Typography color="error.light" fontSize="12px" mt="5px" data-testid="password-error-message">
                  {errors.password?.message}
                </Typography>
              )}
            </GridItem>
          </GridContainer>

          {errorMessage && <MessageBlock type="error" message={errorMessage} />}
        </AccordionDetails>
        <AccordionSummary tabIndex={-1} sx={{ position: 'unset' }}>
          <Box display="flex" alignItems="center" gap="20px" width="100%">
            <Button type="submit" data-testid="button" disabled={isConnectDisabled || isLoading} onClick={trySubmit}>
              Connect
            </Button>

            {isLoading && (
              <Box flexGrow="1">
                <Typography fontSize="15px" color="gray.600">
                  Almost there! We’re connecting your account for a seamless experience. This may take a couple of
                  minutes.
                </Typography>
                <Loader />
              </Box>
            )}
          </Box>
        </AccordionSummary>
      </Accordion>
    </>
  );
};
