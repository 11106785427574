import { UseFormReturn } from 'react-hook-form';
import { Box } from '@mui/material';

import { ISequence } from 'src/types';
import { SequenceForm } from '../SequenceForm/SequenceForm';
import { Tags } from '../Tags';

interface ISendInMailProps extends UseFormReturn<ISequence> {
  index: number;
  customSnippets: Record<string, string>;
}

export const SendInMail = ({ index, customSnippets, ...sequenceForm }: ISendInMailProps) => {
  const snippetOptions = Object.keys(customSnippets).map((key) => ({ label: key, value: key }));
  const { watch, setValue } = sequenceForm;

  return (
    <>
      <SequenceForm
        title="Subject"
        watchField="subject"
        config={{
          textMaxLength: 200,
          withTemplates: false,
          type: 'input',
        }}
        index={index}
        snippetOptions={snippetOptions}
        registerOptions={{ required: true, validate: (value: string) => !!value.trim() }}
        {...sequenceForm}
      />
      <Box my="20px">
        <SequenceForm
          title="Message"
          watchField="message"
          description={`InMails can only be sent to 1st degree connections and/or Open Link profiles.\nNo credits will be used using this feature and profiles will be skipped which don’t meet this criteria.`}
          config={{ textMaxLength: 1900, withUploadFile: true }}
          index={index}
          snippetOptions={snippetOptions}
          registerOptions={{ required: true, validate: (value: string) => !!value.trim() }}
          {...sequenceForm}
        />
      </Box>
      <Tags index={index} watch={watch} setValue={setValue} />
    </>
  );
};
