import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { components, MultiValue, SingleValue } from 'react-select';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Attachment, Button, SelectBasic, Spinner, TSelectGenericProps, Typography } from 'src/components';
import { CampaignSequenceStepType } from 'src/enums';
import { configChooseTemplateMessageResolver } from 'src/helpers';
import { useGetTemplatesByCategory, useGetTemplatesPersonal } from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';
import { IAttachment, IColorSelectOption, IModalProps, ISequenceStep, TTemplateCategory } from 'src/types';
import { ModalBody, ModalFooter } from '../_components';

const StyledModalBody = styled(ModalBody)`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Title = styled(Typography)`
  color: #333;
  font-family: 'ProximaSoft', sans-serif;
  font-size: 14px;
`;

const TemplateTitle = styled.h5`
  margin-left: 10px;
  margin-bottom: 0;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.wlLight};
`;

const Label = styled.b`
  color: ${({ theme }) => theme.palette.violet.dark};
  font-size: 14px;
  line-height: 24px;
`;

const LongMessage = styled.p`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 12px;
  line-height: 24px;
  margin-top: 15px;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.palette.violet.dark};
  font-size: 14px;
  font-family: 'ProximaSoft', sans-serif !important;
  overflow: auto;
  line-height: 22px;
  min-width: 100%;
  max-width: 100%;
  max-height: 280px;
  white-space: pre-wrap;
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid #fff;
    background-color: rgba(0, 0, 0, 0.5);
  }
  &::-webkit-scrollbar {
    width: 9px;
    height: 9px;
    -webkit-appearance: none;
  }
`;

const IconBox = styled(Box)`
  cursor: pointer;
`;

const RemoveAll = styled(Box)`
  pointer-events: all !important;
  color: #999;
  cursor: pointer;
  font-size: 18px;
  margin-top: -3px;
`;

const TemplateSelect = (props: TSelectGenericProps) => {
  const { palette } = useTheme();

  return (
    <SelectBasic
      {...props}
      isClearable
      styles={{
        option: (provided, { isSelected }) => ({
          ...provided,
          backgroundColor: 'transparent !important',
          cursor: 'pointer',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontSize: '14px',
          lineHeight: '14px',
          padding: '12px 10px',
          transition: '0.2s',
          color: isSelected ? palette.primary.main : palette.text.primary,
          ['&:hover']: {
            color: palette.primary.main,
          },
        }),
      }}
      components={{
        ClearIndicator: (props) => (
          <components.ClearIndicator {...props}>{props.hasValue && <RemoveAll>×</RemoveAll>}</components.ClearIndicator>
        ),
        Option: ({ children, ...props }) => (
          <components.Option {...props}>
            {children} {props.isSelected && <FontAwesomeIcon size="xs" icon={faCheck} />}
          </components.Option>
        ),
      }}
    />
  );
};

export interface IModalChooseTemplateProps {
  onConfirm: (message: string, subject?: string | false, attachments?: IAttachment[] | false) => void | Promise<void>;
  type: CampaignSequenceStepType;
  field: keyof ISequenceStep;
  preselectCategory?: TTemplateCategory;
  noTemplatesMessage?: string;
}

export const ModalChooseTemplate = ({ onCancel, params }: IModalProps<IModalChooseTemplateProps>) => {
  const dispatch = useDispatch();

  const { onConfirm, field, type, preselectCategory, noTemplatesMessage } = params;

  const [selectCategoryId, setSelectCategoryId] = useState<number>();
  const [selectTemplateId, setSelectTemplateId] = useState<number>();
  const [selectedCategoryOption, setSelectedCategoryOption] = useState<
    MultiValue<IColorSelectOption> | SingleValue<IColorSelectOption>
  >();
  const [selectedTemplateOption, setSelectedTemplateOption] = useState<
    MultiValue<IColorSelectOption> | SingleValue<IColorSelectOption>
  >();

  const { data, isLoading: isLoadingCategories } = useGetTemplatesPersonal(
    {},
    {
      onSuccess: ({ categories }) => {
        if (preselectCategory) {
          const category = categories.find(({ name }) => name === preselectCategory);
          setSelectCategoryId(category?.id as number);
        }
      },
    },
  );
  const { categories = [], total } = data ?? {};
  const { templates = [], isLoading: isLoadingTemplates } = useGetTemplatesByCategory(selectCategoryId);

  const {
    icon,
    messageTitle,
    modalTitle,
    withSubject,
    title,
    maxLength = 0,
    withAttachments,
  } = configChooseTemplateMessageResolver(type, field);

  const CategoryOptions = categories
    .filter(({ id }) => id)
    .map(({ name, totalTemplates, id }) => ({
      label: `${name} (${totalTemplates})`,
      value: id,
    }));

  const TemplateOptions = templates
    .filter(({ categoryId }) => !selectCategoryId || categoryId === selectCategoryId)
    .map(({ name, id }) => ({
      label: name,
      value: id,
    }));

  const template = templates.find(({ id }) => id === selectTemplateId);
  const { subject = '', message = '', attachments } = template?.content?.sequence[0] ?? {};

  const isTooLongMessage = maxLength === 0 ? false : message?.length >= maxLength;
  const templateMessage = isTooLongMessage ? message.slice(0, maxLength) : message;

  const confirmHandler = async () => {
    onConfirm && (await onConfirm(templateMessage, withSubject && subject, withAttachments && attachments));
    dispatch(closeModal());
  };

  const closeHandler = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  const getBody = () => {
    if (isLoadingCategories || isLoadingTemplates) {
      return (
        <ModalBody p="20px">
          <Spinner marginTop="0" />
        </ModalBody>
      );
    }

    if (!total) {
      return (
        <ModalBody p="20px">
          <Typography fontSize="16px">You don't have templates created</Typography>
        </ModalBody>
      );
    }

    if (!TemplateOptions.length) {
      return (
        <ModalBody p="20px">
          <Typography fontSize="16px">{noTemplatesMessage || 'No Category Templates'}</Typography>
        </ModalBody>
      );
    }

    return (
      <StyledModalBody>
        <Box width="81%">
          {/* hide category selector if it is preselected */}
          {!preselectCategory && (
            <Box pb="20px">
              <Typography fontSize="14px" semibold mb="5px" lineHeight="1.3rem">
                Category
              </Typography>
              <TemplateSelect
                value={selectedCategoryOption}
                options={CategoryOptions as IColorSelectOption[]}
                placeholder="Select Category"
                onChange={(newValue) => {
                  setSelectedCategoryOption(newValue);
                  const { value } = (newValue ?? {}) as { value: number };
                  setSelectCategoryId(value);
                  setSelectTemplateId(0);
                  setSelectedTemplateOption(null);
                }}
              />
            </Box>
          )}
          <Typography fontSize="14px" semibold mb="5px" lineHeight="1.3rem">
            Template{' '}
            <Typography inline color="error.light">
              *
            </Typography>
          </Typography>
          <TemplateSelect
            value={selectedTemplateOption}
            options={TemplateOptions as IColorSelectOption[]}
            placeholder="Search Template"
            onChange={(newValue) => {
              setSelectedTemplateOption(newValue);
              const { value } = (newValue ?? {}) as { value: number };
              setSelectTemplateId(value);
            }}
          />
        </Box>
        <Box>
          <Box bgcolor="white" p="42px 40px">
            <Box display="flex" alignItems="center">
              {icon ? <img width={20} height={20} src={icon} alt="campaign summary icon" /> : <Box width="20px" />}
              <TemplateTitle>{title}</TemplateTitle>
            </Box>
            {withSubject && !!subject && (
              <Box mt="22px">
                <Label>Subject:</Label>
                <Text>{subject}</Text>
              </Box>
            )}
            {!!message && (
              <Box mt="22px">
                <Label>{messageTitle}</Label>
                <Text>{templateMessage}</Text>
              </Box>
            )}
            {withAttachments && !!attachments?.length && (
              <Box mt="22px" display="flex" flexWrap="wrap" gap="10px">
                <Label>Attachments:</Label>
                {attachments?.map(({ file_name, file_uri }) => <Attachment key={file_uri} name={file_name} m="0" />)}
              </Box>
            )}
          </Box>
          {isTooLongMessage && <LongMessage>Template message is too long, shortened to 280 characters</LongMessage>}
        </Box>
      </StyledModalBody>
    );
  };

  return (
    <Box>
      <Box pt={3} pb="10px" px={5} display="flex">
        <Title mr="auto">{modalTitle}</Title>
        <IconBox>
          <FontAwesomeIcon icon={faXmark} onClick={closeHandler} />
        </IconBox>
      </Box>

      {getBody()}

      <ModalFooter>
        <Button variant="gray" onClick={closeHandler}>
          Cancel
        </Button>
        <Button onClick={confirmHandler} disabled={!total}>
          Add
        </Button>
      </ModalFooter>
    </Box>
  );
};
