import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import Select, { components, MultiValue, SingleValue } from 'react-select';

import { ReactComponent as XMarkIcon } from 'src/assets/icons/x-mark.svg';
import { selectTagStyles } from 'src/components';
import { useAppSelector } from 'src/hooks';
import { useGetAllTags, useUpdateLeadTags } from 'src/reactQueries';
import { ILeadInfo, ITag } from 'src/types';

interface ISelectLeadTagProps {
  leadInfo?: ILeadInfo;
}

export const SelectLeadTag = ({ leadInfo }: ISelectLeadTagProps) => {
  const queryClient = useQueryClient();

  const { selectedConversation } = useAppSelector((state) => state.conversation);

  const { tags } = useGetAllTags();

  const { updateLeadTags } = useUpdateLeadTags(
    leadInfo?.userNetwork?.entityUrn || leadInfo?.person?.key || selectedConversation?.entityUrn || '',
    {
      onMutate: (tags) =>
        queryClient.setQueryData<ILeadInfo | undefined>(['get-lead-info', selectedConversation?.entityUrn], (lead) =>
          lead ? { ...lead, tags } : lead,
        ),
    },
  );

  const options = useMemo(() => {
    return tags?.map((tag: ITag) => ({ label: tag.tag, ...tag, value: tag })) || [];
  }, [tags]);

  const selected = useMemo(() => {
    return options?.filter((tag) => leadInfo?.tags?.find((selectedTag) => selectedTag.id === tag.id));
  }, [options, leadInfo?.tags]);

  const changeHandler = (value: MultiValue<ITag> | SingleValue<ITag>) => {
    const newTags = value as MultiValue<ITag & { label: string; value: ITag }>;

    updateLeadTags(newTags.map((tag) => tag.value));
  };

  return (
    <Select
      isMulti
      isClearable={false}
      placeholder={`Select Tags`}
      value={selected}
      options={options}
      styles={selectTagStyles}
      onChange={changeHandler}
      components={{
        MultiValueRemove: (props) => {
          return (
            <components.MultiValueRemove {...props}>
              <XMarkIcon color={props.data.color} />
            </components.MultiValueRemove>
          );
        },
      }}
    />
  );
};
