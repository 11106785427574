import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Box, Grid } from '@mui/material';

import { Input, TextArea, Typography } from 'src/components';
import { POST_MAX_CONTENT } from 'src/constants';
import { useAppSelector, useWhiteLabel } from 'src/hooks';
import { useGetFacebookData, useGetFacebookPostOptions, useUserTwitterDetails } from 'src/reactQueries';
import {
  IFacebookDetailsResponse,
  IFacebookPage,
  IInstagramPage,
  IPostAttachment,
  IPostSelectedDate,
  IUserCompany,
  IUserLinkedInGroup,
  TPostTypes,
} from 'src/types';
import { PostPreview } from '../PostPreview';
import { Attachments } from './Attachments';
import { FacebookCheckbox, InstagramCheckbox, LinkedinCheckbox, TwitterCheckbox } from './Checkbox';
import { LinkedinOptions } from './LinkedinOptions';
import { SchedulePost } from './SchedulePost';

dayjs.extend(timezone);
dayjs.extend(customParseFormat);

interface PostFormProps {
  disablePostTypes?: boolean;
  disableForm?: boolean;
  form: UseFormReturn<IFormCreatePost>;
  setAttachmentError: Dispatch<SetStateAction<string>>;
  isAttachmentLoading: boolean;
  setIsAttachmentLoading: Dispatch<SetStateAction<boolean>>;
}

const FormContainer = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 ${({ theme }) => theme.palette.light.dark};
`;

const Divider = styled.hr`
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #eee;
`;

const StyledInput = styled(Input)`
  padding: 5px 10px;
  outline: 0;
  font-size: 14px;
  height: auto;

  &::placeholder {
    color: #535353;
  }
`;

const StyledTextArea = styled(TextArea)`
  min-height: 200px;
  max-height: 300px;
  min-width: 100%;
  max-width: 100%;
  padding-bottom: 55px;
`;

const AttachmentsContainer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 80%;
`;

export interface IFormCreatePost {
  postTypes: TPostTypes[];
  allowComments: boolean;
  postAs: string;
  audience: string;
  attachments: IPostAttachment[];
  content: string;
  title: string;
  scheduledAt: dayjs.Dayjs;
}

export const PostForm = ({
  form,
  setAttachmentError,
  isAttachmentLoading,
  setIsAttachmentLoading,
  disableForm = false,
  disablePostTypes = false,
}: PostFormProps) => {
  const { whitelabel } = useWhiteLabel();
  const user = useAppSelector((state) => state.user.profile);

  const { register, setValue, watch, getValues } = form;

  const scheduledAt = watch('scheduledAt');

  const [selectedCompany, setSelectedCompany] = useState<IUserCompany | null>(null);
  const [audience, setAudience] = useState({
    label: 'Anyone',
    value: 'anyone',
  });
  const [selectedGroup, setSelectedGroup] = useState<IUserLinkedInGroup | null>(null);
  const [selectedDate, setSelectedDate] = useState<IPostSelectedDate>();

  const postAs = watch('postAs');
  const postTypes = watch('postTypes') || [];
  const content = watch('content');
  const attachments = watch('attachments');

  const maxLengthForContent = useMemo(() => {
    if (audience.value === 'anyoneAndTwitter') {
      return POST_MAX_CONTENT.linkedin;
    }

    return Math.min(...(postTypes?.map((type: keyof typeof POST_MAX_CONTENT) => POST_MAX_CONTENT[type]) ?? []));
  }, [postTypes, audience]);
  const availableContent = maxLengthForContent - content?.length;

  const { data: facebook } = useGetFacebookData({});
  const { data: twitterData } = useUserTwitterDetails({});
  const { data: facebookOptions } = useGetFacebookPostOptions({});
  const facebookPostOptions = facebookOptions?.facebookOptions;
  const facebookData = facebook?.facebookData;

  const { enabledFacebookPages, enabledInstagramPages, facebookProfileDetails } = useMemo(() => {
    let enabledFacebookPages: IFacebookPage[] = [];
    let enabledInstagramPages: IInstagramPage[] = [];
    let facebookProfileDetails: IFacebookDetailsResponse | null = null;

    if (facebookData?.facebookDetails && facebookData?.facebookPages) {
      facebookProfileDetails = facebookData.facebookDetails;

      if (facebookPostOptions) {
        enabledFacebookPages = facebookData.facebookPages.filter((page: IFacebookPage) =>
          facebookPostOptions.pages_checked?.includes(page.id),
        );

        enabledInstagramPages = facebookData.facebookPages
          .filter((page: IFacebookPage) => page.ig && facebookPostOptions.ig_accounts_checked?.includes(page.ig.id))
          .map((page: IFacebookPage) => page.ig as IInstagramPage);
      }
    }

    return { enabledFacebookPages, enabledInstagramPages, facebookProfileDetails };
  }, [facebookData, facebookPostOptions]);

  useEffect(() => {
    const company = user?.companies?.find((userCompany: IUserCompany) => userCompany.entityUrn === postAs);

    setSelectedCompany(company || null);
  }, [postAs, user?.companies]);

  useEffect(() => {
    if (scheduledAt) {
      const hour = dayjs(scheduledAt).get('h') || 12;

      setSelectedDate({
        date: dayjs(scheduledAt).format('YYYY-MM-DD'),
        hour: hour > 12 ? hour - 12 : hour,
        minute: dayjs(scheduledAt).get('m'),
        place: dayjs(scheduledAt).format('A'),
      });
    }
  }, [setSelectedDate, scheduledAt]);

  // eslint-disable-next-line
  const onPostByConfirm = (params: any) => {
    setValue('postAs', params.postAs);
    setValue('audience', 'anyone');

    setAudience({
      value: 'anyone',
      label: 'Anyone',
    });
    setSelectedGroup(null);
  };

  // eslint-disable-next-line
  const onAudienceConfirm = (params: any) => {
    setValue(
      'audience',
      params.audience.value === 'group' ? `group:${params?.selectedGroup?.savedSearchId}` : params.audience.value,
    );

    if (params.audience.value === 'anyoneAndTwitter') {
      setValue(
        'postTypes',
        postTypes.filter((type) => type !== 'twitter'),
      );
    }

    setAudience(params.audience);
    setSelectedGroup(params.selectedGroup);
  };

  const onDateSelect = (date: IPostSelectedDate) => {
    const updatedDate = dayjs.tz(
      `${date.date} ${date.hour}:${date.minute} ${date.place}`,
      'YYYY-MM-DD h:mm A',
      user?.timezone?.name,
    );

    setValue('scheduledAt', updatedDate);
  };

  return (
    <>
      <Grid container spacing={6} padding="15px">
        <Grid item xs={6}>
          <FormContainer>
            <StyledInput
              id="title-input"
              label="Title"
              labelStyle={css`
                color: #333;
              `}
              name="title"
              register={register}
              parameters={{ disabled: disableForm }}
              placeholder="Enter a post title"
            />
            <Divider />
            <Box>
              <Typography mb="15px" semibold>
                Publish in
              </Typography>
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <LinkedinCheckbox
                  register={register}
                  isLinkedInLoginValid={user.isLinkedInLoginValid}
                  disabled={isAttachmentLoading || disablePostTypes || disableForm}
                />
                {whitelabel.isWhiteLabelUser || (
                  <>
                    <FacebookCheckbox
                      register={register}
                      pages={enabledFacebookPages.length}
                      profileDetails={facebookProfileDetails}
                      disabled={isAttachmentLoading || disablePostTypes || disableForm}
                    />
                    <TwitterCheckbox
                      audience={audience.value}
                      register={register}
                      twitterData={twitterData}
                      disabled={isAttachmentLoading || disablePostTypes || disableForm}
                    />
                    <InstagramCheckbox
                      instagramPages={enabledInstagramPages}
                      register={register}
                      disabled={isAttachmentLoading || disablePostTypes || disableForm}
                    />
                  </>
                )}
              </Box>
            </Box>
            <Divider />
            {!!postTypes?.includes('linkedin') && (
              <>
                <LinkedinOptions
                  watch={watch}
                  setValue={setValue}
                  postAs={postAs}
                  audience={audience}
                  twitterData={twitterData}
                  selectedGroup={selectedGroup}
                  selectedCompany={selectedCompany}
                  onAudienceConfirm={onAudienceConfirm}
                  onPostByConfirm={onPostByConfirm}
                  disabled={disableForm}
                />
                <Divider />
              </>
            )}
            <Box position="relative">
              <StyledTextArea
                id="content-input"
                name="content"
                register={register}
                readOnly={disableForm}
                label="Content"
                placeholder={'What do you want to talk about?\n\nUse Hashtags (#) for more reach'}
              />
              <AttachmentsContainer>
                <Attachments
                  audience={audience}
                  postTypes={postTypes || []}
                  onAttachmentsChange={(value: Array<IPostAttachment>) => setValue('attachments', value)}
                  setError={setAttachmentError}
                  isAttachmentLoading={isAttachmentLoading}
                  setIsAttachmentLoading={setIsAttachmentLoading}
                  disabled={disableForm}
                  attachments={attachments}
                />
              </AttachmentsContainer>
            </Box>
            {!!postTypes?.length && (
              <Box display="flex" justifyContent="flex-end" mt="5px">
                <Typography
                  data-testid="availableContent"
                  color={({ palette }) => (availableContent >= 0 ? palette.text.primary : 'red')}
                >
                  {availableContent}
                </Typography>
              </Box>
            )}
            <Divider />
            <SchedulePost
              onDateSelect={onDateSelect}
              selectedDate={selectedDate}
              watch={watch}
              disabled={disableForm}
            />
          </FormContainer>
        </Grid>
        <Grid item xs={6}>
          {!!postTypes?.length && (!!content || !!attachments.length) && (
            <PostPreview
              postData={getValues()}
              facebookProfileDetails={facebookProfileDetails}
              enabledFacebookPages={enabledFacebookPages}
              instagramProfile={enabledInstagramPages[0]}
              twitterData={twitterData || null}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
