import { createFormData } from 'src/helpers';
import {
  transformConversations,
  transformLIMessages,
  transformSNConversations,
  transformSNMessages,
} from 'src/transformers';
import {
  IGetLIConversationMessagesRequest,
  IGetLIConversationsRequest,
  IGetSNConversationMessagesRequest,
  IGetSNConversationsRequest,
  ILIConversation,
  ILIMessages,
  IModifyConversationRequest,
  ISendLIAttachmentMessageRequest,
  ISendLIConversationMessageRequest,
  ISendLIMessageResponse,
  ISNConversation,
  ISNMessage,
  IUpdateLeadReceivingMessagesRequest,
} from 'src/types';
import { appApi } from './axios';

export const modifyConversation = ({
  conversationId,
  action,
  isSales = false,
}: IModifyConversationRequest): Promise<void> =>
  appApi.post(`/conversations/${conversationId}/modify`, { action, isSales });

export const updateLeadReceivingMessages = ({
  entityUrn,
  isExcluded,
}: IUpdateLeadReceivingMessagesRequest): Promise<void> =>
  appApi.post(`/connections/${entityUrn}/set-exclude`, {
    isExcluded,
  });

export const sendSNConversationMessage = ({
  conversationId,
  message,
}: {
  conversationId: string;
  message: string;
}): Promise<ISendLIMessageResponse> =>
  appApi.post(`/conversations/${conversationId}/send-sn-message`, {
    message,
  });

export const getSNConversations = ({ query, createdBefore }: IGetSNConversationsRequest): Promise<ISNConversation[]> =>
  appApi
    .get(`/conversations/sn-conversations`, {
      params: {
        query: query || null,
        createdBefore: createdBefore || null,
      },
    })
    .then((data) => transformSNConversations(data));

export const getSNConversationMessages = ({
  conversationId,
  createdBefore,
}: IGetSNConversationMessagesRequest): Promise<ISNMessage[]> =>
  appApi
    .get(`/conversations/${conversationId}/sn-messages`, {
      params: {
        createdBefore: createdBefore || null,
      },
    })
    .then((data) => transformSNMessages(data));

export const getLIConversations = ({
  filterType,
  query,
  createdBefore,
}: IGetLIConversationsRequest): Promise<ILIConversation[]> =>
  appApi
    .get(`/conversations/linkedin`, {
      params: {
        filterType: filterType || null,
        query: query || null,
        createdBefore: createdBefore || null,
        inboxCount: 0,
      },
    })
    .then((data) => transformConversations(data));

export const getLIConversationMessages = ({
  conversationId,
  entityUrn,
  createdBefore,
}: IGetLIConversationMessagesRequest): Promise<ILIMessages> =>
  appApi
    .get<unknown, ILIMessages>(`/conversations/${conversationId}/messages`, {
      params: {
        entityUrn: entityUrn || null,
        createdBefore: createdBefore || null,
      },
    })
    .then((data) => ({
      messages: transformLIMessages(data),
      conversationId: data.conversationId,
    }));

export const sendLIConversationMessage = (params: ISendLIConversationMessageRequest): Promise<ISendLIMessageResponse> =>
  appApi.post(`/conversations/messages`, params);

export const sendLIAttachmentMessage = ({
  conversationId,
  files,
  message,
}: ISendLIAttachmentMessageRequest): Promise<ISendLIMessageResponse> => {
  const formData = createFormData({ files, message });

  return appApi.post(`/conversations/${conversationId}/message-with-attachment`, formData);
};
