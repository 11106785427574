import { useMutation, UseMutationOptions } from 'react-query';

import { logoutFromTwitter } from 'src/api';
import { ICustomAxiosError } from 'src/types';

export const useLogoutFromTwitter = (options?: UseMutationOptions<void, ICustomAxiosError>) => {
  const { mutate, ...rest } = useMutation('logout-from-twitter', logoutFromTwitter, options);

  return {
    logoutFromTwitter: mutate,
    ...rest,
  };
};
