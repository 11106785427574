import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Button, ProfileAvatar, Toggle, Typography } from 'src/components';
import { ModalTypes } from 'src/enums';
import { useGetUserAccount, useToggleIncludeUnsubscribeEmailLink } from 'src/reactQueries';
import { openModal } from 'src/store';

const AuthorizedBlock = styled(Box)`
  padding: 20px;
  max-width: 50%;
  background-color: #f3f4f5;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
`;

const AliasBadged = styled(Typography)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  box-shadow: 0 1px 1px 0 ${({ theme }) => theme.palette.lightGray.light};
  text-align: center;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
  line-height: 15px;
  margin: 0 5px;
  padding: 3px 5px;
  font-size: 10px;
`;

interface ProfileInfoProps {
  avatar?: string;
  name?: string;
  email?: string;
  isEmailAlias?: boolean;
  type: 'google' | 'microsoft';
}

export const ProfileInfo = ({ email, isEmailAlias, name, type, avatar }: ProfileInfoProps) => {
  const dispatch = useDispatch();

  const { userMe } = useGetUserAccount();
  const { toggleIncludeUnsubscribeEmailLink } = useToggleIncludeUnsubscribeEmailLink();

  const onSendingAliasClick = () =>
    dispatch(
      openModal({
        type: ModalTypes.USE_EMAIL_ALIAS,
        headerText: 'Select the address you want to use to send emails from',
        params: {
          emails: [email],
        },
      }),
    );

  const onUpdateSignatureClick = () =>
    dispatch(
      openModal({
        type: ModalTypes.SIGNATURE,
        headerText: 'Setup your Email Signature',
      }),
    );

  const onExportUnsubscribedEmailsClick = () =>
    dispatch(
      openModal({
        type: ModalTypes.EXPORT_UNSUBSCRIBED_EMAILS_CSV,
      }),
    );

  return (
    <Box>
      <AuthorizedBlock mt="20px">
        <ProfileAvatar width="48px" height="48px" avatarSrc={avatar} firstLatter={(name || '')[0]} />

        <Box display="flex" flexDirection="column" justifyContent="space-around" ml="10px" mr="auto">
          <Typography fontSize="18px" bold>
            {name}
          </Typography>
          <Typography>
            {email} {isEmailAlias && <AliasBadged inline>alias</AliasBadged>}
          </Typography>
        </Box>
      </AuthorizedBlock>

      {type === 'google' && (
        <Box marginTop="20px" display="flex" gap="16px">
          <Button variant="gray" onClick={onSendingAliasClick}>
            Use alias for sending emails.
          </Button>
        </Box>
      )}
      <Box marginTop="20px" display="flex" gap="16px">
        <Button variant="gray" onClick={onUpdateSignatureClick}>
          <FontAwesomeIcon icon={faSignature} /> Update signature
        </Button>
        <Button onClick={onExportUnsubscribedEmailsClick}>Export unsubscribed emails</Button>
      </Box>
      <Box display="flex" mt="30px" mb="10px" alignItems="center">
        <Toggle checked={userMe?.includeUnsubscribeEmailLink} onChange={() => toggleIncludeUnsubscribeEmailLink()} />
        <Typography mr="3px" ml="10px">
          Include unsubscribe link to all email campaigns
        </Typography>
      </Box>
    </Box>
  );
};
