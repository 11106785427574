import { useEffect, useMemo, useState } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { components, MultiValue, SingleValue } from 'react-select';
import Creatable from 'react-select/creatable';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { ReactComponent as XMarkIcon } from 'src/assets/icons/x-mark.svg';
import { selectTagStyles, Tooltip, WithTagCircle } from 'src/components';
import { Toggle } from 'src/components/ui/Toggle';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features } from 'src/enums';
import { getRandomHexColor } from 'src/helpers';
import { useTeamPlan } from 'src/hooks';
import { useCreateTag, useGetAllTags } from 'src/reactQueries';
import { showToast } from 'src/store/toast.slice';
import { ISequence, ITag } from 'src/types';

const ToggleBox = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  height: 20px;
`;

const Label = styled.div`
  color: #333;
  font-size: 14px;
  margin-left: 15px;
`;

export interface ITagsProps {
  index: number;
  setValue: UseFormSetValue<ISequence>;
  watch: UseFormWatch<ISequence>;
}

export const Tags = ({ index, watch, setValue }: ITagsProps) => {
  const dispatch = useDispatch();
  const watchTags = watch(`sequence.${index}.tags`);
  const [showTags, setShowTags] = useState(!!watchTags?.length);
  const [selectedTags, setSelectedTags] = useState<ITag[]>([]);

  const { checkFeature } = useTeamPlan();

  const changeTagsHandler = (values: MultiValue<ITag> | SingleValue<ITag>) => {
    const newValues = values as ITag[];

    setSelectedTags(() => newValues);
    setValue(
      `sequence.${index}.tags`,
      newValues.map((tag) => ({ key: tag.id, value: tag.tag })),
    );
  };

  const { tags } = useGetAllTags();
  const { createTag } = useCreateTag({
    onMutate: ({ color, tag }) => {
      changeTagsHandler([...selectedTags, { color, tag, id: -1, snId: null, leadsCount: 0, connectionsCount: 0 }]);
    },
    onSuccess: (data) => {
      changeTagsHandler([...selectedTags, data]);

      dispatch(
        showToast({
          type: 'info',
          message: 'tag created',
          autoCloseTime: 3000,
        }),
      );
    },
    onError: (_error, variables) => {
      const newTags = selectedTags.filter(({ tag }) => tag !== variables.tag);

      changeTagsHandler(newTags);
    },
  });

  const options = useMemo(() => tags?.map((tag: ITag) => ({ label: tag.tag, ...tag, value: tag.tag })) || [], [tags]);

  useEffect(() => {
    if (tags && watchTags) {
      const tagKeys = watchTags.map(({ key }) => key);
      setSelectedTags(
        tags.filter(({ id }) => tagKeys.includes(id)).map((tag) => ({ label: tag.tag, ...tag, value: tag.tag })),
      );
    }
  }, [JSON.stringify(tags ?? [])]);

  const toggleTags = () => {
    showTags
      ? setValue(`sequence.${index}.tags`, [])
      : setValue(
          `sequence.${index}.tags`,
          selectedTags.map((tag) => ({ key: tag.id, value: tag.tag })),
        );

    setShowTags((prevState) => !prevState);
  };

  const onCreateTag = (tag: string) => {
    createTag({ tag, color: getRandomHexColor() });
  };

  return (
    <>
      <ToggleBox>
        <Toggle checked={showTags || false} name="Show tags" onChange={toggleTags} />
        <Label>Add tags</Label>
      </ToggleBox>
      {showTags && (
        <Tooltip title={!checkFeature(Features.leadsTags) && PLAN_TOOLTIPS.any}>
          <span>
            <Creatable
              isMulti
              isClearable={false}
              placeholder="Select Tags"
              value={selectedTags}
              options={options}
              styles={selectTagStyles}
              components={{
                MultiValueRemove: (props) => {
                  return (
                    <components.MultiValueRemove {...props}>
                      <XMarkIcon color={props.data.color} />
                    </components.MultiValueRemove>
                  );
                },
              }}
              formatCreateLabel={(inputText) => {
                return <WithTagCircle>Create option "{inputText}"</WithTagCircle>;
              }}
              onChange={changeTagsHandler}
              onCreateOption={onCreateTag}
              isDisabled={!checkFeature(Features.leadsTags)}
            />
          </span>
        </Tooltip>
      )}
    </>
  );
};
