import { useMutation, UseMutationOptions } from 'react-query';

import { markInvitationAsClicked } from 'src/api';
import { ICustomAxiosError } from 'src/types';

export const useMarkInvitationAsClicked = (
  inviteKey: string,
  options?: UseMutationOptions<void, ICustomAxiosError>,
) => {
  const { mutate, ...rest } = useMutation(
    ['mark-invite-as-clicked', inviteKey],
    () => markInvitationAsClicked(inviteKey),
    options,
  );

  return { markInvitationAsClicked: mutate, ...rest };
};
