import { Box } from '@mui/material';

import { ReactComponent as Check } from 'src/assets/icons/check-circle-bold.svg';
import LinkedInIcon from 'src/assets/icons/linkedin-icon.svg';
import { ReactComponent as Warning } from 'src/assets/icons/warning-bold.svg';
import { Button, MessageBlock, Typography } from 'src/components';
import { useCompanyTheme } from 'src/hooks';
import { Accordion, AccordionDetails, AccordionSummary, ExternalLink, GridContainer, GridItem, Loader } from '../ui';
import { LinkedInAccount } from './LinkedInAccount';

interface LinkedInConnectedProps {
  is2FAActive: boolean;
  isConnectedSuccessfully: boolean;
  isLinkedInRestrictionWarningDetected: boolean;
  isLoadingUpdateCredentials: boolean;
  isLoadingDeleteCredentials: boolean;
  errorMessage?: string;
  tryDelete: () => void;
  tryAgain: () => void;
}

export const LinkedInConnected = ({
  is2FAActive,
  isConnectedSuccessfully,
  isLoadingUpdateCredentials,
  isLoadingDeleteCredentials,
  isLinkedInRestrictionWarningDetected,
  tryDelete,
  tryAgain,
  errorMessage,
}: LinkedInConnectedProps) => {
  const { shortCompanyName } = useCompanyTheme();

  const isLoading = isLoadingUpdateCredentials || isLoadingDeleteCredentials;

  return (
    <>
      <Accordion expanded>
        <AccordionSummary tabIndex={-1}>
          <Box display="flex" alignItems="center" gap="12px" mr="20px">
            <img width="20px" src={LinkedInIcon} alt="LinkedIn" />
            {isConnectedSuccessfully ? (
              <Typography semibold fontSize="20px" color="gray.400">
                LinkedIn Connected
              </Typography>
            ) : (
              <Typography semibold fontSize="20px">
                Connect LinkedIn
              </Typography>
            )}
          </Box>

          <Box display="flex" alignItems="center" gap="7px">
            {isConnectedSuccessfully ? (
              <>
                <Check />
                <Typography color="success.700" fontSize="15px">
                  Connected
                </Typography>
              </>
            ) : (
              <>
                <Warning />
                <Typography color="error.700" fontSize="15px">
                  Connection Error
                </Typography>
              </>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <GridContainer container rowSpacing="20px" mb="20px">
            <GridItem item xs={12} sm={4}>
              <LinkedInAccount tryDelete={tryDelete} isConnected={isConnectedSuccessfully} isLoading={isLoading} />
            </GridItem>
          </GridContainer>

          {isConnectedSuccessfully ? (
            <MessageBlock
              type="success"
              message={
                <>
                  LinkedIn account connected.{' '}
                  {is2FAActive && (
                    <>
                      Automation works best if you disable 2FA on LinkedIn.{' '}
                      <ExternalLink
                        href="https://www.linkedin.com/psettings/two-step-verification"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here
                      </ExternalLink>{' '}
                      to disable it.
                    </>
                  )}
                </>
              }
            />
          ) : (
            <MessageBlock
              type="error"
              message={
                errorMessage ||
                (isLinkedInRestrictionWarningDetected
                  ? "We can't seem to connect with your LinkedIn Profile. To confirm it's active and reachable, please open your LinkedIn profile in a new tab to check its status."
                  : `${shortCompanyName} is no longer connected to your LinkedIn account. Please click "Try to Reconnect" button to try again.`)
              }
            />
          )}
        </AccordionDetails>
        {(isLoading || !isConnectedSuccessfully) && (
          <AccordionSummary tabIndex={-1}>
            <Box display="flex" alignItems="center" gap="20px" width="100%">
              {!isConnectedSuccessfully && (
                <Button type="submit" data-testid="button" disabled={isLoading} onClick={tryAgain}>
                  Try to Reconnect
                </Button>
              )}
              {isLoading && (
                <Box flexGrow="1">
                  <Typography fontSize="15px" color="gray.600">
                    {isLoadingUpdateCredentials &&
                      `Almost there! We’re connecting your account for a seamless experience. This may take a couple of minutes.`}
                    {isLoadingDeleteCredentials &&
                      `${shortCompanyName} is removing your LinkedIn account. May take a few minutes.`}
                  </Typography>
                  <Loader />
                </Box>
              )}
            </Box>
          </AccordionSummary>
        )}
      </Accordion>
    </>
  );
};
