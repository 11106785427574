import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { sendSNConversationMessage } from 'src/api';
import { showToast } from 'src/store';
import { ICustomAxiosError, ISendLIMessageResponse } from 'src/types';

export const useSendSNMessage = (
  { conversationId, entityUrn }: { entityUrn: string; conversationId: string },
  options?: UseMutationOptions<ISendLIMessageResponse, ICustomAxiosError, { message: string }>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const getSNMessagesKey = ['get-sn-conversation-messages', entityUrn, conversationId];

  const { mutate, isLoading, ...rest } = useMutation(
    ['send-sn-conversation-message', conversationId],
    ({ message }: { message: string }) => sendSNConversationMessage({ message, conversationId }),
    {
      ...options,
      onMutate: async (msg) => {
        options?.onMutate?.(msg);
        await queryClient.cancelQueries(getSNMessagesKey);

        const prevData = queryClient.getQueryData(getSNMessagesKey);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        queryClient.setQueryData<Record<any, any>>(getSNMessagesKey, (resp) => {
          if (resp) {
            const pages = [...resp.pages];

            pages[0].unshift({
              body: msg.message,
              createdAt: Date.now(),
              entityUrn: '',
              imageUrl: '',
            });

            return { ...resp, pages };
          }

          return resp;
        });

        return { prevData };
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueryData(getSNMessagesKey, context?.prevData);
      },
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);

        if (data?.originalHttpCode === 422) {
          dispatch(showToast({ type: 'error', message: 'Unable to send message' }));
        }

        await queryClient.invalidateQueries(['get-sn-conversations'], { exact: false, refetchInactive: true });
      },
      onSettled: async (data, error, variables, context) => {
        options?.onSettled?.(data, error, variables, context);
        if (!conversationId) {
          return;
        }

        await queryClient.invalidateQueries(getSNMessagesKey);
      },
    },
  );

  return { sendSNMessage: mutate, isSendSnLoading: isLoading, ...rest };
};
