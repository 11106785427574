import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 70px);
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  font-size: 23px;
  text-align: center;
`;

const DetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const Details = styled.p`
  max-width: 400px;
  text-align: center;
`;

export const Unsubscribe = () => {
  return (
    <Wrapper>
      <Container>
        <Title>Goodbye for now!</Title>
        <DetailsContainer>
          <Details>You've successfully unsubscribed from this mailing list.</Details>
        </DetailsContainer>
      </Container>
    </Wrapper>
  );
};
