import { InfiniteData, useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { sendLIConversationMessage } from 'src/api';
import { LIMessageType } from 'src/enums';
import { useAppSelector } from 'src/hooks';
import { showToast } from 'src/store';
import { ICustomAxiosError, ILIMessages, ISendLIMessageResponse } from 'src/types';

interface IUseSendLIMessageParams {
  message: string;
  objectUrn?: string;
  isGroup: boolean;
}

export const useSendLIMessage = (
  { conversationId, entityUrn }: { entityUrn: string; conversationId?: string },
  options?: UseMutationOptions<
    ISendLIMessageResponse,
    ICustomAxiosError,
    IUseSendLIMessageParams,
    { prevMessages: unknown }
  >,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { linkedInEntityUrn } = useAppSelector((state) => state.user.profile);

  const getLiMessagesKey = ['get-li-conversation-messages', entityUrn, conversationId];
  const getConversationsKey = ['get-li-conversations'];

  const { mutate, isLoading, ...rest } = useMutation<
    ISendLIMessageResponse,
    ICustomAxiosError,
    IUseSendLIMessageParams,
    { prevMessages: unknown }
  >(
    ['send-conversation-message', conversationId],
    ({ message, objectUrn, isGroup }: IUseSendLIMessageParams) =>
      //do not need to send entityUrn if it is group chat message
      sendLIConversationMessage({ message, objectUrn, entityUrn: isGroup ? '' : entityUrn, conversationId }),
    {
      onMutate: async (msg) => {
        options?.onMutate?.(msg);
        await queryClient.cancelQueries(getLiMessagesKey);

        const prevMessages = queryClient.getQueryData(getLiMessagesKey);

        queryClient.setQueryData<InfiniteData<ILIMessages> | undefined>(getLiMessagesKey, (messages) => {
          if (messages) {
            const pages = [...messages.pages];

            pages[0].messages.unshift({
              subtype: LIMessageType.memberToMember,
              content: {
                attributedBody: { text: msg.message },
                body: '',
              },
              createdAt: Date.now(),
              entityUrn: linkedInEntityUrn,
              imageUrl: '',
            });

            const newMessages: InfiniteData<ILIMessages> = {
              ...messages,
              pages,
            };

            return newMessages;
          }

          return messages;
        });

        return { prevMessages };
      },
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);

        if (data?.originalHttpCode === 422) {
          dispatch(showToast({ type: 'error', message: 'Unable to send message' }));
        }

        await queryClient.invalidateQueries(getConversationsKey, { exact: false, refetchInactive: true });

        if (!conversationId) {
          queryClient.invalidateQueries(['get-lead-info', entityUrn]);
        }
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueryData(getLiMessagesKey, context?.prevMessages);
      },
      onSettled: async (data, error, variables, context) => {
        options?.onSettled?.(data, error, variables, context);
        if (!conversationId) {
          return;
        }

        await queryClient.invalidateQueries(getLiMessagesKey);
      },
    },
  );

  return { sendLIMessage: mutate, isSendLiLoading: isLoading, ...rest };
};
