import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { toggleIntegrationNotification } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, ITeam, IToggleIntegrationNotification } from 'src/types';

export const useToggleIntegrationNotification = (
  teamId: number,
  options?: UseMutationOptions<void, ICustomAxiosError, IToggleIntegrationNotification>,
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const getTeamQueryKey = ['get-team-by-id', teamId];

  const { mutate, ...rest } = useMutation(
    ['toggle-integration-notification'],
    (data: IToggleIntegrationNotification) => toggleIntegrationNotification(data),
    {
      ...options,
      onMutate: async ({ sendToAdmins, sendToOwners }) => {
        options?.onMutate?.({ sendToAdmins, sendToOwners });

        await queryClient.cancelQueries(getTeamQueryKey);

        const prevData = queryClient.getQueryData(getTeamQueryKey);

        queryClient.setQueryData<ITeam | undefined>(getTeamQueryKey, (data) => {
          if (data) {
            return {
              ...data,
              sendDisconnectedEmailsToAdmins: sendToAdmins,
              sendDisconnectedEmailsToOwners: sendToOwners,
            };
          }

          return data;
        });

        return { prevData };
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueryData(getTeamQueryKey, context?.prevData);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { toggleIntegrationNotification: mutate, ...rest };
};
