import { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button, Checkbox } from 'src/components';
import { closeModal } from 'src/store/modal.slice';
import { ICampaignConnections } from 'src/types';

const ClearAllBlock = styled(Box)`
  margin-left: 20px;
  padding: 5px 15px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.wlLight};
  border: 1px solid ${({ theme }) => theme.palette.light.dark};
  font-size: 10px;
  border-radius: 4px;
  transition: 0.2s;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.wlLight};
    border: 1px solid ${({ theme }) => theme.palette.primary.wlLight};
    color: #fff;
  }
`;

interface IModalSelectConnectionDegreeProps {
  values: ICampaignConnections;
  setValues: (newValues: ICampaignConnections) => void;
}

export const ModalSelectConnectionDegree = ({
  values: propValues,
  setValues: setValuesProp,
}: IModalSelectConnectionDegreeProps) => {
  const [values, setValues] = useState(propValues);
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeModal());
  };

  const change = (e: ChangeEvent<HTMLInputElement>, name: keyof ICampaignConnections) => {
    const newValues = { ...values, [name]: e.target.checked };
    setValues(newValues);
    setValuesProp(newValues);
  };

  const showClearAll = !!Object.values(values).find((value) => value);

  const clearAll = () => {
    const cleared: ICampaignConnections = {
      first: false,
      second: false,
      third: false,
    };
    setValues(cleared);
    setValuesProp(cleared);
  };

  return (
    <>
      <Box py="30px" px="40px" display="flex" alignItems="center" fontSize="14px" height="20px">
        Connections
        {showClearAll && <ClearAllBlock onClick={clearAll}>X CLEAR ALL</ClearAllBlock>}
      </Box>
      <Box py="30px" px="40px" display="flex" justifyContent="space-between" bgcolor="light.light">
        <Box display="flex" alignItems="center">
          <Checkbox id="first" type="checkbox" checked={values.first} onChange={(e) => change(e, 'first')} />
          <label htmlFor="first">1st degree</label>
        </Box>
        <Box display="flex" alignItems="center">
          <Checkbox id="second" type="checkbox" checked={values.second} onChange={(e) => change(e, 'second')} />
          <label htmlFor="second">2nd degree</label>
        </Box>
        <Box display="flex" alignItems="center">
          <Checkbox id="third" type="checkbox" checked={values.third} onChange={(e) => change(e, 'third')} />
          <label htmlFor="third">3rd+ degree</label>
        </Box>
      </Box>
      <Box py="20px" px="40px" display="flex" justifyContent="flex-end">
        <Button onClick={close}>Save</Button>
      </Box>
    </>
  );
};
