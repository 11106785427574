import { useMutation, UseMutationOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { sendTestEmail } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, ISendTestEmailSequenceStep } from 'src/types';

export const useSendTestEmail = (options?: UseMutationOptions<void, ICustomAxiosError, ISendTestEmailSequenceStep>) => {
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation<void, ICustomAxiosError, ISendTestEmailSequenceStep>(
    ['send-test-email'],
    (data) => sendTestEmail(data),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        dispatch(
          showToast({
            type: 'success',
            message: 'Test email was sent successfully',
            autoCloseTime: 3000,
          }),
        );
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { sendTestEmail: mutate, ...rest };
};
