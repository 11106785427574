import axios, { AxiosError } from 'axios';
import Cookies from 'universal-cookie';

import { API_URL, COOKIE_TOKEN_NAME } from 'src/constants';

const cookies = new Cookies();

const appApi = axios.create({ baseURL: API_URL });

appApi.interceptors.request.use((config) => {
  const accessToken = cookies.get(COOKIE_TOKEN_NAME);

  config.headers.setAuthorization(`Bearer ${accessToken}`);
  config.headers.setAccept('application/json');

  return config;
});

appApi.interceptors.response.use(
  (response) => response.data,
  (error: AxiosError<{ message: Array<string>; statusCode: number }>) => {
    if (['ERR_NETWORK', 'ETIMEDOUT'].includes(error?.code ?? '')) {
      // eslint-disable-next-line
      return Promise.reject({
        name: error.name,
        message: 'Failed to connect to server.',
        statusCode: 511,
      });
    }

    // eslint-disable-next-line
    return Promise.reject({
      name: error.name,
      message: Array.isArray(error?.response?.data?.message)
        ? error?.response?.data?.message[0]
        : error?.response?.data?.message,
      statusCode: error?.response?.data?.statusCode,
    });
  },
);

export { appApi };
