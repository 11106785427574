import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faEarthAmericas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { HtmlParser, Link, PersonAvatar, ShowMore } from 'src/components';
import { ILIMessage } from 'src/types';

const PostPreviewImage = styled.img`
  margin: 10px -15px -10px;
  max-width: 500px;
`;

export const MessagePostPreview = (message: ILIMessage) => {
  const { palette } = useTheme();

  const { avatar, content, date, authorUrl, image, name, headline, degree } = message.content?.postPreview || {};

  return (
    <>
      <Box display="flex" alignItems="flex-start" gap="10px" mb="15px">
        <Link external to={authorUrl} openNewTab>
          <PersonAvatar src={avatar} width="30px" height="30px" />
        </Link>
        <Box>
          <Link external to={authorUrl} openNewTab color="text.primary" hoverColor="info.main">
            {name}
          </Link>
          <Link external to={authorUrl} openNewTab color="gray.light" hoverColor={palette.gray.light} ml="4px">
            {degree}
          </Link>
          <Link
            display="block"
            external
            to={authorUrl}
            openNewTab
            variant="primary"
            fontSize="12px"
            color="gray.light"
            hoverColor={palette.gray.light}
            maxWidth="430px"
            crop
          >
            {headline}
          </Link>
          <Box>
            <Link
              external
              to={authorUrl}
              openNewTab
              variant="primary"
              fontSize="12px"
              color="gray.light"
              hoverColor={palette.gray.light}
            >
              {date?.trim()} <FontAwesomeIcon icon={faEarthAmericas} />
            </Link>
          </Box>
        </Box>
      </Box>

      <ShowMore>
        <Box>
          <HtmlParser parseText={content} />
        </Box>
      </ShowMore>

      {!!image && <PostPreviewImage src={image} />}
    </>
  );
};
