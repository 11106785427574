import { Box } from '@mui/material';

import { ReactComponent as EmptyImg } from 'src/assets/img/empty-inbox.svg';
import { Link, Typography } from 'src/components';
import { useCompanyTheme } from 'src/hooks';

export const NotSynced = () => {
  const { shortCompanyName } = useCompanyTheme();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Typography fontSize="34px" mt="75px">
        Your LinkedIn inbox is not synced
      </Typography>
      <Typography fontSize="18px" mt="35px" textAlign="center">
        {shortCompanyName} gives you access all your LinkedIn inbox data.
        <br />
        Please connect your linkedin credentials to sync your data.
      </Typography>
      <Box mt="80px">
        <EmptyImg width="250px" height="163px" />
      </Box>
      <Typography mt="20px">
        <Link variant="title" semibold underline to="/integrations/linkedin">
          Click here
        </Link>{' '}
        to connect your LinkedIn account.
      </Typography>
    </Box>
  );
};
