import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faLinkedin, faSkype, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { ReactComponent as ExcludedUserIcon } from 'src/assets/icons/excluded-user.svg';
import { ReactComponent as FiltersApplied } from 'src/assets/icons/filter-applied.svg';
import { ReactComponent as FilterClearIcon } from 'src/assets/icons/filter-clear.svg';
import { ReactComponent as FiltersBlock } from 'src/assets/icons/filters-block.svg';
import { ReactComponent as MailingIcon } from 'src/assets/icons/mailing.svg';
import { ReactComponent as OpenlinkIcon } from 'src/assets/icons/openlink.svg';
import { ReactComponent as PhoneIcon } from 'src/assets/icons/phone.svg';
import { ReactComponent as SelectedIcon } from 'src/assets/icons/selected.svg';
import { Button, Tooltip, Typography } from 'src/components';
import { INDUSTRY_CODES, LANGUAGE_CODES } from 'src/constants';
import { CampaignType } from 'src/enums';
import { checkConnectionsFilter } from 'src/helpers';
import { useAppSelector, useLocationState } from 'src/hooks';
import { useGetAllTags, useGetCampaignsSearchListConnections } from 'src/reactQueries';
import {
  DEFAULT_CONNECTIONS_REQUEST,
  updateBlacklistConnectionsRequestParams,
  updateConnectionsRequestParams,
} from 'src/store';
import { IConnectionsFilter, IConnectionsParsedValue, ILocationStateContacts, IParsedValueOption } from 'src/types';
import { FilterInput, FiltersBox, FiltersFooter, FiltersHeader, FiltersSidebar, StyledInput } from '../_components';

export const LabelsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 7px 12px 16px;
  gap: 8px;
`;
export const LabelBox = styled(Box)<{
  checked?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  border-radius: 16px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: 1px solid ${({ checked, theme }) => (checked ? theme.palette.success[600] : theme.palette.gray[100])};
  background-color: ${({ checked, theme }) => (checked ? theme.palette.success[100] : theme.palette.gray[50])};
  align-items: center;
  padding: 6px 10px 6px 8px;
  width: fit-content;
`;

export const ConnectionsFilters = ({ isBlacklist = false }: { isBlacklist?: boolean }) => {
  const dispatch = useDispatch();
  const { campaignType } = useLocationState<{ tags?: number[] } & ILocationStateContacts>();

  const {
    request,
    exportData: { selectedLeads },
  } = useAppSelector((state) => (isBlacklist ? state.blacklistConnections : state.connections));
  const { tags: tagsAvailable } = useGetAllTags();
  const { data: campaignsOptions } = useGetCampaignsSearchListConnections({
    refetchOnMount: true,
    select: (data) =>
      data?.map((campaign) => ({
        value: String(campaign.id),
        label: campaign.name,
      })),
  });

  const clearLocationState = () => window.history.replaceState({}, document.title);

  const {
    search,
    isPremium,
    isOpenLink,
    email,
    phone,
    skype,
    twitter,
    isExcluded,
    selectedOnly,
    firstName,
    lastName,
    companyName,
    title,
    geography,
    industryCode,
    languages,
    tags,
    campaignIds,
  } = request;

  const {
    register,
    getValues,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<IConnectionsFilter>({
    defaultValues: {
      search: search || '',
      firstName: firstName || [],
      lastName: lastName || [],
      companyName: companyName || [],
      title: title || [],
      geography: geography || [],
      industryCode: industryCode || [],
      languages: languages || [],
      tags: tags || [],
      campaignIds: campaignIds || [],
      isPremium,
      isOpenLink,
      isExcluded,
      skype,
      twitter,
      phone,
      email,
      selectedOnly,
    },
  });
  const updateParamsMethod = isBlacklist ? updateBlacklistConnectionsRequestParams : updateConnectionsRequestParams;

  useEffect(() => {
    window.addEventListener('beforeunload', clearLocationState);

    if (campaignType === CampaignType.EMAIL_CONNECTIONS) {
      dispatch(updateParamsMethod({ email: true }));
      setValue('email', true);
    }
    if (campaignType === CampaignType.TWITTER_CONNECTIONS) {
      dispatch(updateParamsMethod({ twitter: true }));
      setValue('twitter', true);
    }

    return () => window.removeEventListener('beforeunload', clearLocationState);
  }, [campaignType]);

  const changeFilter = useCallback((value: IConnectionsFilter) => {
    dispatch(updateParamsMethod({ ...value, page: 1 }));
  }, []);

  const onFilterChange = useCallback(
    (valueName: keyof IConnectionsParsedValue, value: IParsedValueOption[] | boolean) => {
      setValue(valueName, value, { shouldDirty: true });
    },
    [],
  );

  const onFilterApply = useCallback((values: IConnectionsFilter) => {
    reset(getValues(), {
      keepDirtyValues: false,
    });
    changeFilter(values);
  }, []);

  const isFilterApplied = checkConnectionsFilter(request, isBlacklist);

  return (
    <FiltersSidebar>
      <FiltersHeader>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb="12px">
          <Box display="flex" alignItems="center">
            {isFilterApplied ? <FiltersApplied /> : <FiltersBlock />}
            <Typography semibold color="gray.700" ml="8px" fontSize="16px" letterSpacing="1px">
              FILTERS
            </Typography>
          </Box>
          {(isDirty || isFilterApplied) && (
            <Box
              display="flex"
              gap="4px"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                reset({ ...DEFAULT_CONNECTIONS_REQUEST, isExcluded: isBlacklist });
                onFilterApply({ ...DEFAULT_CONNECTIONS_REQUEST, isExcluded: isBlacklist });
                clearLocationState();
              }}
            >
              <FilterClearIcon />
              <Typography color="#2B5DDB" fontSize="16px">
                Clear All
              </Typography>
            </Box>
          )}
        </Box>
        <StyledInput
          type="text"
          placeholder="Search connections"
          name="search"
          register={register}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onFilterApply(getValues());
            }
          }}
        />
      </FiltersHeader>
      <FiltersBox>
        <FilterInput
          valueName="firstName"
          label="First Name"
          placeholder="Search for First Name"
          parsedValue={watch('firstName') || []}
          onParsedValueChange={onFilterChange}
        />
        <FilterInput
          valueName="lastName"
          label="Last Name"
          placeholder="Search for Last Name"
          parsedValue={watch('lastName') || []}
          onParsedValueChange={onFilterChange}
        />
        <FilterInput
          valueName="companyName"
          label="Company"
          placeholder="Search for company"
          parsedValue={watch('companyName') || []}
          onParsedValueChange={onFilterChange}
        />
        <FilterInput
          valueName="title"
          label="Position"
          placeholder="Search for position"
          parsedValue={watch('title') || []}
          onParsedValueChange={onFilterChange}
        />
        <FilterInput
          valueName="geography"
          label="Location"
          placeholder="Search for location"
          parsedValue={watch('geography') || []}
          onParsedValueChange={onFilterChange}
        />
        <FilterInput
          valueName="industryCode"
          label="Industry"
          placeholder="Select industry"
          options={Object.keys(INDUSTRY_CODES).map((key) => ({
            value: key,
            label: INDUSTRY_CODES[key],
          }))}
          parsedValue={watch('industryCode') || []}
          onParsedValueChange={onFilterChange}
        />
        <FilterInput
          valueName="languages"
          label="Language"
          placeholder="Select language"
          options={LANGUAGE_CODES.map((value) => ({ value, label: value }))}
          parsedValue={watch('languages') || []}
          onParsedValueChange={onFilterChange}
        />
        <FilterInput
          valueName="tags"
          label="Tags"
          placeholder="Select tags"
          options={tagsAvailable?.map((tag) => ({
            value: String(tag.id),
            label: tag.tag,
          }))}
          parsedValue={watch('tags') || []}
          onParsedValueChange={onFilterChange}
        />
        <FilterInput
          valueName="campaignIds"
          label="Campaign"
          placeholder="Select campaign"
          options={campaignsOptions}
          parsedValue={watch('campaignIds') || []}
          onParsedValueChange={onFilterChange}
        />
        <LabelsWrapper>
          <LabelBox
            data-testid="isPremium-checkbox"
            checked={watch('isPremium')}
            onClick={() => onFilterChange('isPremium', !watch('isPremium'))}
          >
            <Tooltip title="Select to filter connections with LinkedIn premium">
              <Box display="flex" alignItems="center" gap="5px">
                <FontAwesomeIcon icon={faLinkedin} color="#af9b62" fontSize="16px" />
                <Typography>LinkedIn Premium</Typography>
              </Box>
            </Tooltip>
          </LabelBox>
          <LabelBox
            data-testid="isOpenLink-checkbox"
            checked={watch('isOpenLink')}
            onClick={() => onFilterChange('isOpenLink', !watch('isOpenLink'))}
          >
            <Tooltip title="Select to filter connections with Open Link">
              <Box display="flex" alignItems="center" gap="5px">
                <OpenlinkIcon />
                <Typography>with Openlink</Typography>
              </Box>
            </Tooltip>
          </LabelBox>
          <LabelBox
            data-testid="email-checkbox"
            checked={watch('email')}
            onClick={() => campaignType !== CampaignType.EMAIL_CONNECTIONS && onFilterChange('email', !watch('email'))}
          >
            <Tooltip title="Select to filter connections with email">
              <Box display="flex" alignItems="center" gap="5px">
                <MailingIcon />
                <Typography>with Email</Typography>
              </Box>
            </Tooltip>
          </LabelBox>
          {!isBlacklist && (
            <LabelBox
              data-testid="isExcluded-checkbox"
              checked={watch('isExcluded')}
              onClick={() => onFilterChange('isExcluded', !watch('isExcluded'))}
            >
              <Tooltip title="Select to filter connections which are excluded">
                <Box display="flex" alignItems="center" gap="5px">
                  <ExcludedUserIcon />
                  <Typography>User Excluded</Typography>
                </Box>
              </Tooltip>
            </LabelBox>
          )}
          <LabelBox
            data-testid="twitter-checkbox"
            checked={watch('twitter')}
            onClick={() =>
              campaignType !== CampaignType.TWITTER_CONNECTIONS && onFilterChange('twitter', !watch('twitter'))
            }
          >
            <Tooltip title="Select to filter connections with X (Twitter)">
              <Box display="flex" alignItems="center" gap="5px">
                <FontAwesomeIcon icon={faXTwitter} fontSize="16px" />
                <Typography>with X (Twitter)</Typography>
              </Box>
            </Tooltip>
          </LabelBox>
          <LabelBox
            data-testid="skype-checkbox"
            checked={watch('skype')}
            onClick={() => onFilterChange('skype', !watch('skype'))}
          >
            <Tooltip title="Select to filter connections with Skype">
              <Box display="flex" alignItems="center" gap="5px">
                <FontAwesomeIcon icon={faSkype} color="#00acee" fontSize="16px" />
                <Typography>with Skype</Typography>
              </Box>
            </Tooltip>
          </LabelBox>
          <LabelBox
            data-testid="phone-checkbox"
            checked={watch('phone')}
            onClick={() => onFilterChange('phone', !watch('phone'))}
          >
            <Tooltip title="Select to filter connections with phone number">
              <Box display="flex" alignItems="center" gap="5px">
                <PhoneIcon />
                <Typography>Phone Number</Typography>
              </Box>
            </Tooltip>
          </LabelBox>

          {!!selectedLeads?.length && (
            <LabelBox
              checked={watch('selectedOnly')}
              disabled={!selectedLeads?.length}
              onClick={() => selectedLeads?.length && onFilterChange('selectedOnly', !watch('selectedOnly'))}
            >
              <Tooltip title={selectedLeads?.length ? 'Select to filter connections which are selected' : ''}>
                <Box display="flex" alignItems="center" gap="5px">
                  <SelectedIcon />
                  <Typography>Selected only</Typography>
                </Box>
              </Tooltip>
            </LabelBox>
          )}
        </LabelsWrapper>
      </FiltersBox>
      <FiltersFooter>
        <Button size={{ width: '100%' }} disabled={!isDirty} onClick={handleSubmit(onFilterApply)}>
          Apply & Search
        </Button>
      </FiltersFooter>
    </FiltersSidebar>
  );
};
