import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  CreatableSearch,
  Spinner,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
} from 'src/components';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from 'src/constants';
import { CampaignCategory, TemplateAvailability } from 'src/enums';
import { useGetSequenceTemplates } from 'src/reactQueries';
import { ISelectOption, ITablePagination, TSortOrder, TTemplatesSortField } from 'src/types';
import { SequenceTemplatesLayout } from './SequenceTemplatesLayout';
import { SequenceTemplatesTableRow } from './SequenceTemplatesTableRow';

interface SequenceTemplatesProps {
  availability: TemplateAvailability;
}

export const SequenceTemplates = ({ availability }: SequenceTemplatesProps) => {
  const [searchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState<string[]>([]);
  const [sortField, setSortField] = useState<TTemplatesSortField>('name');
  const [sortOrder, setSortOrder] = useState<TSortOrder>('ASC');
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [page, setPage] = useState(DEFAULT_PAGE);

  const category = searchParams.get('category') as CampaignCategory;

  const { data, isLoading } = useGetSequenceTemplates({
    availability,
    search: searchValue,
    sortField,
    sortOrder,
    page,
    perPage,
    category,
  });

  const changeSearchHandler = (options: ISelectOption[]) => {
    setSearchValue(options.map(({ label }) => label));
    setPage(1);
  };

  const onSortByField = (field: string, order: string) => {
    setSortField(field as TTemplatesSortField);
    setSortOrder(order as 'DESC' | 'ASC');
  };

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  const { route, title, description, guideLink, videoLink } = useMemo(() => {
    return availability === TemplateAvailability.personal
      ? {
          route: 'personal',
          title: 'Personal Templates',
          description: 'Boost your campaigns with customizable sequence templates.',
          guideLink: 'https://help.meetalfred.com/en/articles/9118624-create-campaign-sequence-templates',
          videoLink: 'https://player.vimeo.com/video/932611160',
        }
      : {
          route: 'team',
          title: 'Team Templates',
          description: 'Save time by sharing sequence templates across your team.',
          guideLink: 'https://help.meetalfred.com/en/articles/9118624-create-campaign-sequence-templates',
          videoLink: 'https://player.vimeo.com/video/932611160',
        };
  }, [availability]);

  useEffect(() => {
    if (data && !data.sequenceTemplates.length && page * perPage - data.total === perPage) {
      setPage((prev) => prev - 1 || DEFAULT_PAGE);
    }
  }, [data?.sequenceTemplates?.length]);

  return (
    <SequenceTemplatesLayout
      route={route}
      title={title}
      description={description}
      availability={availability}
      search={searchValue}
      guideLink={guideLink}
      videoLink={videoLink}
    >
      <Box px="15px" pt="20px" pb="80px">
        <Box mb="20px">
          <CreatableSearch placeholder="Search Templates" onChange={changeSearchHandler} />
        </Box>
        <Table processing={isLoading} processingContent={<Spinner type="dots" />}>
          <TableHead>
            <TableRow>
              {availability === TemplateAvailability.team && <TableHeadCell width="60px" />}
              <TableHeadCell
                name="name"
                sorting={{
                  sortOrder,
                  sortField,
                  onSortFieldChange: onSortByField,
                }}
                width="220px"
                justifyContent="flex-start"
              >
                Name
              </TableHeadCell>
              <TableHeadCell>Sequence</TableHeadCell>
              <TableHeadCell width="150px">Type</TableHeadCell>
              {availability === TemplateAvailability.personal && (
                <TableHeadCell
                  name="isAvailableForTeam"
                  sorting={{
                    sortOrder,
                    sortField,
                    onSortFieldChange: onSortByField,
                  }}
                  width="100px"
                  justifyContent="center"
                >
                  Status
                </TableHeadCell>
              )}
              <TableHeadCell width="180px" />
            </TableRow>
          </TableHead>

          <TableBody
            placeholder={
              !data?.total && `No ${availability === TemplateAvailability.personal ? 'Personal' : 'Team'} Templates`
            }
          >
            {data?.sequenceTemplates.map((template) => {
              return (
                <SequenceTemplatesTableRow key={template.id} sequenceTemplate={template} availability={availability} />
              );
            })}
          </TableBody>
        </Table>

        <TablePagination
          page={page}
          perPage={perPage}
          total={data?.total}
          onPaginationChange={paginationChangeHandler}
        />
      </Box>
    </SequenceTemplatesLayout>
  );
};
