import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Avatar, Box } from '@mui/material';

import { Button, Spinner, Tooltip, Typography } from 'src/components';
import { PersonalDetails, ToggleLead } from 'src/containers/Contacts/_components';
import { resolveActionDescription } from 'src/helpers';
import { useCompanyTheme } from 'src/hooks';
import { useGetCampaignLeadActions, useGetLeadActivities, useGetLeadInfo } from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';
import { IModalProps } from 'src/types';
import { ModalBody, ModalFooter } from '../_components';

dayjs.extend(relativeTime);

const ModalHead = styled(Box)`
  display: flex;
  padding: 30px 40px;
  gap: 40px;
`;

const StyledAvatar = styled(Avatar)`
  height: 117px;
  width: 117px;
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.palette.lightGray.main};
  vertical-align: middle;
  border: 4px solid #fff;
  border-radius: 125px;
  box-shadow:
    inset 0 1.5px 3px 0 rgb(0 0 0 / 15%),
    0 1.5px 3px 0 rgb(0 0 0 / 15%);
  overflow: hidden;
`;

const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.lightGray.main};
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 20px;
`;

const BodyText = styled(Typography)`
  text-align: center;
  color: ${({ theme }) => theme.palette.violet.dark};
  font-size: 14px;
  line-height: 16px;
  word-break: break-word;
`;

const BreakWordTitle = styled(Typography)`
  word-break: break-word;
`;

export interface IModalViewLeadParams {
  campaignId: number;
  entityUrn: string;
}

export const ModalViewLead = ({ onCancel, params }: IModalProps) => {
  const dispatch = useDispatch();
  const { shortCompanyName } = useCompanyTheme();
  const { campaignId, entityUrn } = (params ?? {}) as IModalViewLeadParams;
  const [hidePersonalDetails, setHidePersonalDetails] = useState(true);

  const { leadActions = [], isLoading } = useGetCampaignLeadActions(campaignId, entityUrn, {
    enabled: !!campaignId && !!entityUrn,
  });
  const { leadActivitiesData } = useGetLeadActivities(
    { entityUrn, perPage: 10 },
    { enabled: !campaignId && !!entityUrn },
  );

  const { leadInfo } = useGetLeadInfo(entityUrn);
  const { person } = leadInfo || {};

  const leadName = person?.name || person?.workEmail || `${person?.firstName || ''} ${person?.lastName || ''}`;
  const isLeadExcluded = !!leadInfo?.lead?.isExcluded || !!leadInfo?.userNetwork?.isExcluded;

  const actions = (campaignId ? leadActions : leadActivitiesData?.actions) || [];

  const closeHandler = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  return (
    <>
      <ModalHead>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          pt="15px"
          width={hidePersonalDetails ? '100%' : '40%'}
        >
          <StyledAvatar src={person?.linkedInData?.pic || ''} />

          <Tooltip title={`See profile on ${shortCompanyName}`}>
            <Link to={`/contacts/leads?person=${entityUrn}&tab=personal_details`} onClick={closeHandler}>
              {leadName}
            </Link>
          </Tooltip>
          <BreakWordTitle mt="10px">{leadInfo?.person?.currentTitle}</BreakWordTitle>
          {hidePersonalDetails && (
            <BreakWordTitle fontSize="16px" mt="6px">
              {leadInfo?.person?.location}
            </BreakWordTitle>
          )}
        </Box>

        <PersonalDetails onShowChange={setHidePersonalDetails} person={person} label="Personal details" />
      </ModalHead>

      <ModalBody>
        <Box>
          <Box display="grid" gridTemplateColumns="1fr 1fr">
            <Title>ACTIVITY</Title>
            <Title>time</Title>
          </Box>
          {isLoading ? (
            <Spinner mt="15px" />
          ) : (
            actions.map(({ description, message, createdAt, id }) => (
              <Box key={id} width="100%" display="grid" gridTemplateColumns="1fr 1fr">
                <BodyText>{resolveActionDescription(description, message)}</BodyText>
                <BodyText>{dayjs(createdAt).fromNow()}</BodyText>
              </Box>
            ))
          )}
        </Box>

        {!actions.length && !isLoading && (
          <Box display="flex" justifyContent="center">
            <BodyText>{campaignId ? 'No activity in this campaign.' : 'No activity yet.'}</BodyText>
          </Box>
        )}
      </ModalBody>

      <ModalFooter>
        <Box display="flex" alignItems="center" gap="10px">
          <ToggleLead entityUrn={entityUrn} isExcluded={isLeadExcluded} tooltipPlacement="top" />
          <Typography>Exclude lead</Typography>
        </Box>

        <Button variant="gray" onClick={closeHandler}>
          Close
        </Button>
      </ModalFooter>
    </>
  );
};
