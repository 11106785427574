import { isEqual } from 'lodash';
import { memo } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Checkbox, Table, TableBody, TableHead, TableHeadCell, TableRow } from 'src/components';
import { resolveNoLeadsPlaceholder } from 'src/helpers';
import { useCompanyTheme } from 'src/hooks';
import { ICampaignLead, IGetCampaign, IGetLeadsRequest, ISelectProps, ITableSorting, TLeadsSortType } from 'src/types';
import { EmailLeadsTableRow } from './EmailLeadsTableRow';

const StyledTable = styled(Table)`
  table-layout: auto !important;
`;

interface IEmailLeadsTableProps {
  leads: ICampaignLead[];
  campaign: IGetCampaign;
  getLeadKeys: IGetLeadsRequest;
  isLoading: boolean;
  selectProps: ISelectProps;
  sortingProps: ITableSorting;
}

export const EmailLeadsTable = memo(
  ({ campaign, getLeadKeys, selectProps, leads, isLoading, sortingProps }: IEmailLeadsTableProps) => {
    const { shortCompanyName } = useCompanyTheme();
    const { customSnippets } = campaign ?? {};

    const includeLeadType = (types: TLeadsSortType[]) => types?.includes(getLeadKeys?.type);

    const columnKeys = Object.keys(customSnippets)?.filter(
      (key) => key && customSnippets[key]?.toLowerCase() !== 'email',
    );
    const isAllFilter = includeLeadType(['approved']);
    const repliesFilter = includeLeadType([
      'replies',
      'allReplies',
      'inmailReplies',
      'userInteraction',
      'emailReplies',
    ]);
    const showMessages = includeLeadType(['emailOpened', 'emailReplies', 'emailed']);
    const showDate = includeLeadType(['emailOpened']);
    const showActions = isAllFilter || repliesFilter;

    return (
      <Box overflow="auto">
        <StyledTable processing={isLoading} disabled={isLoading}>
          <TableHead>
            <TableRow>
              <TableHeadCell width="40px">
                <Checkbox checked={selectProps.isSelectedAll} onChange={() => selectProps.toggleAllLeads()} />
              </TableHeadCell>
              <TableHeadCell>
                <Box ml="50px">Lead</Box>
              </TableHeadCell>
              {showMessages && <TableHeadCell>Message</TableHeadCell>}
              {showDate && <TableHeadCell>Date</TableHeadCell>}

              {columnKeys?.map((key) => isAllFilter && <TableHeadCell key={key}>{customSnippets[key]}</TableHeadCell>)}

              <TableHeadCell name="touchIndex" sorting={sortingProps} width="150px">
                Steps
              </TableHeadCell>

              {showActions && <TableHeadCell width="80px" />}
            </TableRow>
          </TableHead>
          <TableBody
            placeholder={
              !leads?.length && !isLoading && resolveNoLeadsPlaceholder(campaign?.campaignType, shortCompanyName)
            }
          >
            {leads?.map((lead, index) => (
              <EmailLeadsTableRow
                key={index}
                lead={lead}
                campaign={campaign}
                columnKeys={columnKeys}
                getLeadKeys={getLeadKeys}
                config={{ isAllFilter, repliesFilter, showActions, showDate, showMessages }}
                selectProps={selectProps}
              />
            ))}
          </TableBody>
        </StyledTable>
      </Box>
    );
  },
  isEqual,
);
