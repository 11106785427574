import { ChangeEventHandler, MouseEvent } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import MuiTablePagination, { TablePaginationProps } from '@mui/material/TablePagination';

import { DEFAULT_PAGINATION_OPTIONS, DEFAULT_PER_PAGE } from 'src/constants';
import { separateNumWithComma } from 'src/helpers';
import { ITablePagination } from 'src/types';

const StyledTablePagination = styled(MuiTablePagination)<{ component: string }>`
  background-color: transparent;
  border-bottom: none;

  & .MuiInputBase-root {
    order: 3;
  }

  & .MuiInputBase-root {
    margin-right: 0;
    & > div {
      padding: 5px 16px;
      border-radius: 5px;
      border: 1px solid rgba(224, 224, 224, 1);
      background-color: #ffffff;
    }

    & .MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.gray['800']};
    }
  }

  & .MuiTablePagination-actions {
    margin-left: 12px;
    & .MuiButtonBase-root {
      border-radius: 5px;
      border: 1px solid rgba(224, 224, 224, 1);
      padding: 3px;
      color: ${({ theme }) => theme.palette.primary.main};
      background-color: #ffffff;

      &.Mui-disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      &:first-of-type {
        margin-right: 4px;
      }
    }
  }
`;

type TOmitPaginationProps = 'count' | 'rowsPerPage' | 'page' | 'onPageChange';

interface ITablePaginationAdditionalProps extends Omit<TablePaginationProps, TOmitPaginationProps> {
  page?: number;
  perPage?: number;
  total?: number;
  hideForSinglePageResults?: boolean;
  onPageChange?: (event: MouseEvent<HTMLButtonElement> | null, page: number) => void;
  onPaginationChange?: ({ page, perPage }: ITablePagination) => void;
  hideRowsPerPage?: boolean;
  hideTotal?: boolean;
}

export const TablePagination = ({
  page = 1,
  perPage = DEFAULT_PER_PAGE,
  total = 0,
  hideForSinglePageResults,
  onPaginationChange,
  onPageChange,
  onRowsPerPageChange,
  hideRowsPerPage = false,
  hideTotal = false,
  ...props
}: ITablePaginationAdditionalProps) => {
  const onPageChangeHandler = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    typeof onPageChange === 'function' && onPageChange(event, newPage);

    typeof onPaginationChange === 'function' && onPaginationChange({ page: newPage + 1, perPage });
  };

  const onRowsPerPageChangeHandler: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> = (event) => {
    typeof onRowsPerPageChange === 'function' && onRowsPerPageChange(event);

    typeof onPaginationChange === 'function' && onPaginationChange({ page: 1, perPage: Number(event.target.value) });
  };

  if ((hideForSinglePageResults && perPage > total) || !total) {
    return null;
  }

  return (
    <Box width="100%" maxWidth="100vw">
      <StyledTablePagination
        component="div"
        SelectProps={{
          MenuProps: {
            sx: {
              '&& .Mui-selected': {
                accentColor: 'success.main',
                background: 'success.main',
              },
            },
          },
        }}
        data-testid="table-pagination"
        labelRowsPerPage={false}
        page={page - 1}
        rowsPerPage={perPage}
        count={total}
        rowsPerPageOptions={hideRowsPerPage ? [] : DEFAULT_PAGINATION_OPTIONS}
        labelDisplayedRows={({ count, from, to }) => !hideTotal && `${from}–${to} of ${separateNumWithComma(count)}`}
        onPageChange={onPageChangeHandler}
        onRowsPerPageChange={onRowsPerPageChangeHandler}
        {...props}
      />
    </Box>
  );
};
