import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { HtmlParser, Link, PersonAvatar, Typography } from 'src/components';
import { parseDate } from 'src/helpers';
import { ILIMessage } from 'src/types';
import { MessageAttachment } from './MessageAttachment';
import { MessagePostPreview } from './MessagePostPreview';

const Cloud = styled(Box)<{ self: 1 | 0 }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: pre-wrap;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  background-color: ${({ self, theme }) => (self ? theme.palette.light.main : theme.palette.light.light)};
  overflow: hidden;

  b {
    color: ${({ theme }) => theme.palette.gray.dark};
  }

  strong {
    color: ${({ theme }) => theme.palette.primary.wlLight};
  }

  ul {
    list-style: none;
  }

  a {
    color: ${({ theme }) => theme.palette.primary.wlLight};
    border-bottom: 1px solid ${({ theme }) => theme.palette.lightGray.light};

    &:hover {
      color: ${({ theme }) => theme.palette.info.main};
    }
  }
`;

const Message = styled(Box)<{ self: 1 | 0 }>`
  display: flex;
  align-items: flex-end;
  margin: 10px 0;
  max-width: 84%;
  ${(props) => (props.self ? 'margin-left: auto;' : 'margin-right: auto;')};
  flex-direction: ${(props) => (props.self ? 'row-reverse' : 'row')};
`;

const Deleted = styled(Cloud)`
  border-radius: 0;
  background-color: #cccccc;
`;

interface IUserMessageProps {
  msg: ILIMessage;
  content?: string;
  self: 0 | 1;
}

export const UserMessage = ({ msg, self, content }: IUserMessageProps) => {
  const customContent = msg.content.customContent;

  const resolveContent = () => {
    if (msg.content?.postPreview?.authorUrl) {
      return (
        <>
          {!!content && (
            <Cloud self={self} mb="10px">
              <HtmlParser parseText={content} />
            </Cloud>
          )}
          <Cloud self={self}>
            <MessagePostPreview {...msg} />
          </Cloud>
        </>
      );
    }
    if (content || !msg.recalledAt) {
      return (
        <Cloud self={self}>
          {msg.content.subject && (
            <>
              <b>{msg.content.subject}</b>
              <br />
            </>
          )}

          <HtmlParser parseText={content} />

          {customContent?.action && customContent?.actionText && (
            <Link external openNewTab to={customContent.action}>
              {customContent.actionText}
            </Link>
          )}
          <MessageAttachment {...msg} />
        </Cloud>
      );
    }

    return <Deleted self={self}>This message has been deleted.</Deleted>;
  };

  return (
    <Message self={self}>
      <Box mx="5px" pb="22px" height="30px" alignSelf="flex-start">
        <PersonAvatar src={msg.imageUrl} width="30px" height="30px" />
      </Box>

      <Box>
        {resolveContent()}

        <Typography fontSize="11px" color="gray.light" mt="3px" textAlign={self ? 'right' : 'left'}>
          {parseDate(msg.createdAt)}
        </Typography>
      </Box>
    </Message>
  );
};
