import { useQuery, UseQueryOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { getFacebookData } from 'src/api';
import { NotificationTypes } from 'src/enums';
import { addNotification, removeNotificationByType } from 'src/store';
import { ICustomAxiosError, IGetIFacebookDataResponse } from 'src/types';

export const useGetFacebookData = (options?: UseQueryOptions<IGetIFacebookDataResponse, ICustomAxiosError>) => {
  const dispatch = useDispatch();

  return useQuery<IGetIFacebookDataResponse, ICustomAxiosError>(['get-facebook-data'], getFacebookData, {
    retry: false,
    refetchOnMount: true,
    ...options,
    onSuccess: (data) => {
      options?.onSuccess?.(data);

      if (data?.facebookData?.unauthorized) {
        dispatch(addNotification({ type: NotificationTypes.FACEBOOK_INTEGRATION_ERROR }));
      } else {
        dispatch(removeNotificationByType({ type: NotificationTypes.FACEBOOK_INTEGRATION_ERROR }));
      }
    },
  });
};
