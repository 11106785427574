import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { CSSProperties, UIEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Spinner, Tooltip, Typography } from 'src/components';
import { LIConversationFilterType, LIConversationType, LIMessageType } from 'src/enums';
import { useAppSelector } from 'src/hooks';
import { useGetLeadInfo, useGetLIConversationMessages, useSendLIMessage } from 'src/reactQueries';
import { setSelectedConversation } from 'src/store';
import { ILIConversation } from 'src/types';
import { ConnectLinkedinPlaceholder } from './ConnectLinkedinPlaceholder';
import { MessagesLayout } from './MessagesLayout';
import { SendMessage } from './SendMessage';
import { SystemMessage } from './SystemMessage';
import { UserMessage } from './UserMessage';

dayjs.extend(isToday);

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > * {
    flex-shrink: 0;
  }
`;

const MessagesBox = styled(Box)`
  overflow-y: auto;
  display: flex;
  flex-direction: column-reverse;
  padding: 10px 5px 0 5px;
  margin-top: auto;
  flex-shrink: 1;
  min-height: 145px;
`;

const Reply = styled(Box)`
  padding: 5px 10px;
  border-radius: 20px;
  margin: 0 5px;
  font-size: 14px;
  color: #000000;
  border: 1px solid ${({ theme }) => theme.palette.light.main};
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.light.main};
  }
`;

interface IConversationProps {
  conversationData: ILIConversation;
  filterType: LIConversationFilterType;
  containerStyle?: CSSProperties;
  sendMessagesStyle?: CSSProperties;
  isLeadMessages?: boolean;
  hideConversationActions?: boolean;
  showRefetchMessages?: boolean;
}

export const Conversation = ({
  conversationData,
  filterType,
  containerStyle,
  sendMessagesStyle,
  isLeadMessages,
  hideConversationActions = false,
  showRefetchMessages = false,
}: IConversationProps) => {
  const dispatch = useDispatch();
  const { linkedInEntityUrn, isLinkedInLoginValid } = useAppSelector((state) => state.user.profile);

  const [showRefetching, setShowRefetching] = useState(false);

  const entityUrn = conversationData?.entityUrn || '';
  const objectUrn = conversationData?.objectUrn || '';
  const conversationId = conversationData?.conversationId || '';
  const isInmailFilter = filterType === LIConversationFilterType.INMAIL;

  const { leadInfo, isLeadLoading } = useGetLeadInfo(entityUrn, objectUrn);
  const { sendLIMessage } = useSendLIMessage({ conversationId, entityUrn });
  const { messages, isLoading, fetchNextPage, isFetchingNextPage, refetch } = useGetLIConversationMessages(
    {
      conversationId,
      entityUrn,
    },
    { onSuccess: () => setShowRefetching(false) },
  );

  const replies = messages[0]?.replyRecommendations || [];
  const isSponsoredInMail =
    conversationData.type === LIConversationType.SPONSORED_INMAIL ||
    messages?.some((msg) => msg?.subtype === LIMessageType.sponsoredMessage);

  useEffect(() => {
    return () => {
      dispatch(setSelectedConversation(null));
    };
  }, []);

  const handleScrollPagination = async (e: UIEvent<HTMLDivElement>) => {
    // the logic of defining scroll that left is a bit strange
    // because of using 'flex-direction: column-reverse' prop
    const scrollPosition = e.currentTarget.scrollHeight + e.currentTarget.scrollTop - e.currentTarget.offsetHeight < 30;

    if (!isFetchingNextPage && scrollPosition && messages?.length > 0) {
      fetchNextPage().catch(console.error);
    }
  };

  const send = (message: string, objectUrn?: string) => {
    sendLIMessage({ message, objectUrn, isGroup: !!conversationData?.isGroup });
  };

  const updateConversation = () => {
    setShowRefetching(true);
    refetch();
  };

  if (!isLinkedInLoginValid) {
    return <ConnectLinkedinPlaceholder />;
  }

  if (isLeadMessages && leadInfo?.userNetwork?.degree !== 1 && !isLoading && !messages.length) {
    return (
      <Wrapper style={containerStyle}>
        <Box display="flex" justifyContent="center" pt="55px" pb="120px">
          <Typography color="gray.400" fontSize="16px">
            You can't send a message to a person who is not in your LinkedIn network.
          </Typography>
        </Box>
      </Wrapper>
    );
  }

  return (
    <Wrapper style={containerStyle}>
      {!!messages.length && !isLeadMessages && (
        <MessagesLayout
          isLoadingMessages={isLoading}
          conversationData={conversationData}
          hideConversationActions={hideConversationActions}
        />
      )}

      {isLoading || isLeadLoading || showRefetching ? (
        <Spinner />
      ) : (
        <>
          <MessagesBox onScroll={handleScrollPagination}>
            {!messages.length && (
              <Box display="flex" alignItems="center" flexDirection="column" mb="10px" py="48px" gap="5px">
                <Typography color="gray.400" fontSize="15px">
                  No history of messages found with this person.
                </Typography>
                <Typography color="gray.400" fontSize="15px">
                  You can initiate the conversation.
                </Typography>
              </Box>
            )}
            {messages?.map((msg) => {
              const self = msg.entityUrn === linkedInEntityUrn ? 1 : 0;

              const content = msg.content?.attributedBody?.text || msg.content.customContent?.body;

              if ([LIMessageType.systemMessage, LIMessageType.participantChange].includes(msg.subtype)) {
                return (
                  <SystemMessage
                    key={msg.createdAt}
                    name={self ? 'You' : msg.name}
                    content={content}
                    participantChanges={msg.participantsChanges}
                  />
                );
              }

              return <UserMessage key={msg.createdAt} msg={msg} self={self} content={content} />;
            })}
            {isFetchingNextPage && <Spinner size="12px" marginTop="24px" />}
          </MessagesBox>
          {!!replies.length && !leadInfo?.userNetwork?.isExcluded && (
            <Box display="flex" p="10px" justifyContent="center">
              {replies.map((reply) => (
                <Reply key={reply.objectUrn} onClick={() => send(reply.content.text, reply.objectUrn)}>
                  {reply.content.text}
                </Reply>
              ))}
            </Box>
          )}
          {showRefetchMessages && !!messages.length && (
            <Box mb="14px" mx="auto">
              <Tooltip title="Update Conversation">
                <FontAwesomeIcon icon={faArrowsRotate} cursor="pointer" color="#545753" onClick={updateConversation} />
              </Tooltip>
            </Box>
          )}

          <SendMessage
            conversationData={conversationData}
            isInmailFilter={isInmailFilter}
            isSponsoredInMail={isSponsoredInMail}
            sendMessagesStyle={sendMessagesStyle}
          />
        </>
      )}
    </Wrapper>
  );
};
