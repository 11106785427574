import { useTheme } from '@emotion/react';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, BoxProps } from '@mui/material';

import { Typography } from './Typography';

interface IAttachmentProps extends BoxProps {
  name: string;
  fileId?: string;
  onDeleteClick?: (name: string) => void;
}

export const Attachment = ({ name, fileId, onDeleteClick, ...props }: IAttachmentProps) => {
  const { palette } = useTheme();

  return (
    <Box
      data-testid={`attachments-${name}`}
      display="flex"
      alignItems="center"
      border={`1px solid ${palette.light.dark} !important`}
      borderRadius="5px"
      height="31px"
      key={name}
      marginBottom="20px"
      marginRight="10px"
      px="10px"
      width="180px"
      {...props}
    >
      <FontAwesomeIcon icon={faFileLines} color="#333" fontSize="10px" />

      <Typography display="inline-block" ml="10px" width="100%" lineHeight="14px" crop>
        {name}
      </Typography>
      {!!onDeleteClick && (
        <Box
          width="10px"
          display="flex"
          alignItems="center"
          ml="10px"
          marginTop="2px"
          sx={{ cursor: 'pointer' }}
          data-testid={`attachments-delete-${name}`}
          onClick={() => onDeleteClick(fileId || name)}
        >
          <FontAwesomeIcon icon={faXmark} color={palette.primary.main} fontSize="14px" />
        </Box>
      )}
    </Box>
  );
};
