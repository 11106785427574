import { useMemo } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { CurrencyEnum } from 'src/enums';
import { calculateUserInvoice, getAmountString, getCurrencySymbol } from 'src/helpers';
import { IStripeInvoice } from 'src/types';

interface InvoiceDetailsProps {
  invoiceDetails: IStripeInvoice;
  currency: CurrencyEnum;
}

const Dots = styled.div`
  border-bottom: 2px dotted #dbdbdb;
  margin: 4px 8px;
  flex-grow: 1;
`;

export const InvoiceDetails = ({ invoiceDetails, currency }: InvoiceDetailsProps) => {
  const currencySymbol = useMemo(() => getCurrencySymbol(currency), [currency]);

  const discount = useMemo(() => {
    if (invoiceDetails?.discount && invoiceDetails?.total_discount_amounts?.length) {
      const percentOff = invoiceDetails?.discount?.coupon?.percent_off;
      const amountOff = invoiceDetails?.discount?.coupon?.amount_off;
      const totalAmount = invoiceDetails.total_discount_amounts[0].amount;

      if (percentOff) {
        return {
          label: `${percentOff}% off`,
          total: totalAmount,
        };
      }

      if (amountOff) {
        return {
          label: `${currencySymbol} ${(amountOff / 100).toFixed(2)} off`,
          total: totalAmount,
        };
      }
    }

    return null;
  }, [invoiceDetails]);

  const { referral, first, extra, users, prorata, remaining, graduated } = useMemo(
    () => calculateUserInvoice(invoiceDetails),
    [invoiceDetails],
  );

  return (
    <>
      <Typography color="primary.wlLight" semibold lineHeight="40px">
        Invoice details:
      </Typography>

      {first && (
        <Box display="flex" alignItems="flex-end">
          <Typography>{graduated ? `First ${graduated} seats` : 'First seat'}</Typography>
          <Dots />
          <Typography>
            {currencySymbol}
            {getAmountString(first.total)}
          </Typography>
        </Box>
      )}

      {extra?.quantity > 0 && (
        <Box display="flex" alignItems="flex-end">
          <Typography>
            Extra seat{extra.quantity > 1 && 's'}{' '}
            {extra.quantity > 1 && (
              <span>
                {currencySymbol}
                {getAmountString(extra.price)} &times; {extra.quantity}
              </span>
            )}
          </Typography>
          <Dots />
          <Typography>
            {currencySymbol}
            {getAmountString(extra.total)}
          </Typography>
        </Box>
      )}

      {!!users && (
        <Box display="flex" alignItems="flex-end">
          <Typography>
            User seat{users.quantity > 1 && 's'}{' '}
            {users.quantity > 1 && (
              <span>
                {currencySymbol}
                {getAmountString(users.price)} &times; {users.quantity}
              </span>
            )}
          </Typography>
          <Dots />
          <Typography>
            {currencySymbol}
            {getAmountString(users.total)}
          </Typography>
        </Box>
      )}

      {!!prorata && (
        <Box display="flex" alignItems="flex-end" mt="8px">
          <Typography>Unused time of current billing cycle</Typography>
          <Dots />
          <Typography fontSize="12px">
            - {currencySymbol}
            {getAmountString(Math.abs(prorata))}
          </Typography>
        </Box>
      )}

      {!!remaining && (
        <Box display="flex" alignItems="flex-end" mt="8px">
          <Typography>Remaining time of current billing cycle</Typography>
          <Dots />
          <Typography fontSize="12px">
            {currencySymbol}
            {getAmountString(Math.abs(remaining))}
          </Typography>
        </Box>
      )}

      {!!referral && (
        <Box display="flex" alignItems="flex-end" mt="8px">
          <Typography>Referral Discount</Typography>
          <Dots />
          <Typography fontSize="12px">
            - {currencySymbol}
            {getAmountString(Math.abs(referral.total))}
          </Typography>
        </Box>
      )}

      <br />
      <Box display="flex" alignItems="flex-end">
        <Typography>Subtotal</Typography>
        <Dots />
        <Typography>
          {Math.sign(invoiceDetails.subtotal) === -1 && '-'}
          {currencySymbol}
          {getAmountString(Math.abs(invoiceDetails.subtotal))}
        </Typography>
      </Box>

      {!!discount?.total && (
        <Box display="flex" alignItems="flex-end">
          <Typography>Discount {discount.label}</Typography>
          <Dots />
          <Typography fontSize="12px">
            - {currencySymbol}
            {getAmountString(discount.total)}
          </Typography>
        </Box>
      )}

      {!!invoiceDetails && invoiceDetails?.amount_due < invoiceDetails?.total && (
        <Box display="flex" alignItems="flex-end">
          <Typography>Pro-rata balance</Typography>
          <Dots />
          <Typography fontSize="12px">
            - {currencySymbol}
            {getAmountString(invoiceDetails?.total - invoiceDetails?.amount_due)}
          </Typography>
        </Box>
      )}

      <br />
      <Box display="flex" alignItems="flex-end">
        <Typography>Total</Typography>
        <Dots />
        <Typography>
          {currencySymbol}
          {getAmountString(invoiceDetails.amount_due)}
        </Typography>
      </Box>

      {!!invoiceDetails?.subtotal && invoiceDetails.subtotal < 0 && (
        <>
          <br />
          <br />
          <Box display="flex" alignItems="flex-end" fontStyle="italic">
            <Typography>*Pro-rata Balance for next invoices</Typography>
            <Dots />
            <Typography fontSize="12px">
              {currencySymbol}
              {getAmountString(Math.abs(invoiceDetails.subtotal))}
            </Typography>
          </Box>
        </>
      )}

      {discount && !discount.total && (
        <>
          <br />
          <Typography fontStyle="italic">
            Please note that the discount has already been applied to the remaining time of current billing cycle
          </Typography>
        </>
      )}
    </>
  );
};
