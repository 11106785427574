import { Control, useFieldArray, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Typography } from 'src/components';
import {
  ICampaignAudienceRequest,
  ICampaignConnections,
  ICampaignKeywords,
  ICampaignProfileLanguages,
} from 'src/types';

const PreviewBlock = styled(Box)`
  padding: 5px 7px 5px 10px;
  cursor: pointer;
  transition: 0.2s;
  background-color: #eaeff1;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.gray.dark};
  border-radius: 5px;
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 10px;
  overflow-wrap: anywhere;

  &:hover {
    background-color: ${({ theme }) => theme.palette.lightGray.light};
  }

  &::after {
    display: flex;
    align-items: center;
    justify-content: center;
    content: 'X';
    min-width: 14px;
    width: 14px;
    font-size: 9px;
    height: 14px;
    background-color: #ffffff;
    color: ${({ theme }) => theme.palette.lightGray.main};
    border-radius: 50%;
    margin-left: 8px;
  }
`;

interface IParametersPreviewProps {
  watch: UseFormWatch<ICampaignAudienceRequest>;
  setValue: UseFormSetValue<ICampaignAudienceRequest>;
  control: Control<ICampaignAudienceRequest>;
}

interface IPreviewBlock<T> {
  name: string;
  field: keyof T;
}

const connectionsPreview: Array<IPreviewBlock<ICampaignConnections>> = [
  { name: 'First', field: 'first' },
  { name: 'Second', field: 'second' },
  { name: 'Third', field: 'third' },
];

const keywordsPreview: Array<IPreviewBlock<ICampaignKeywords>> = [
  { name: 'First name', field: 'firstName' },
  { name: 'Last name', field: 'lastName' },
  { name: 'Title', field: 'title' },
  { name: 'Company', field: 'company' },
  { name: 'School', field: 'school' },
];

const languagesPreview: Array<IPreviewBlock<ICampaignProfileLanguages>> = [
  { name: 'English', field: 'english' },
  { name: 'French', field: 'french' },
  { name: 'Spanish', field: 'spanish' },
  { name: 'Portuguese', field: 'portuguese' },
  { name: 'Russian', field: 'russian' },
  { name: 'Polish', field: 'polish' },
];

export const ParametersPreview = ({ watch, setValue, control }: IParametersPreviewProps) => {
  const watchShowConnections = !!Object.values(watch('searchParameters.connections')).filter(Boolean).length;

  const watchShowSpecifyKeywords = !!Object.values(watch('searchParameters.specifyKeywords')).filter(Boolean).length;

  const watchShowLanguages = !!Object.values(watch('searchParameters.profileLanguages')).filter(Boolean).length;

  const { fields: industries, remove: removeIndustry } = useFieldArray({
    name: 'searchParameters.industries',
    control,
  });

  const { fields: currentCompanies, remove: removeCurrentCompany } = useFieldArray({
    name: 'searchParameters.currentCompanies',
    control,
  });

  const { fields: pastCompanies, remove: removePastCompany } = useFieldArray({
    name: 'searchParameters.pastCompanies',
    control,
  });

  const { fields: schools, remove: removeSchool } = useFieldArray({
    name: 'searchParameters.schools',
    control,
  });

  const { fields: locations, remove: removeLocation } = useFieldArray({
    name: 'searchParameters.locations',
    control,
  });

  const preview = [
    {
      title: 'Connections',
      isShow: watchShowConnections,
      content: connectionsPreview.map(
        ({ field, name }) =>
          watch(`searchParameters.connections.${field}`) && (
            <PreviewBlock key={field} onClick={() => setValue(`searchParameters.connections.${field}`, false)}>
              {name}
            </PreviewBlock>
          ),
      ),
    },
    {
      title: 'Industries',
      isShow: !!industries.length,
      content: industries.map(({ name }, id) => (
        <PreviewBlock key={name} onClick={() => removeIndustry(id)}>
          {name}
        </PreviewBlock>
      )),
    },
    {
      title: 'Specify keywords',
      isShow: watchShowSpecifyKeywords,
      content: keywordsPreview.map(
        ({ field, name }) =>
          watch(`searchParameters.specifyKeywords.${field}`) && (
            <PreviewBlock key={field} onClick={() => setValue(`searchParameters.specifyKeywords.${field}`, '')}>
              {name}: {watch(`searchParameters.specifyKeywords.${field}`)}
            </PreviewBlock>
          ),
      ),
    },
    {
      title: 'Current companies',
      isShow: !!currentCompanies.length,
      content: currentCompanies.map(({ name }, id) => (
        <PreviewBlock key={name} onClick={() => removeCurrentCompany(id)}>
          {name}
        </PreviewBlock>
      )),
    },
    {
      title: 'Past companies',
      isShow: !!pastCompanies.length,
      content: pastCompanies.map(({ name }, id) => (
        <PreviewBlock key={name} onClick={() => removePastCompany(id)}>
          {name}
        </PreviewBlock>
      )),
    },
    {
      title: 'Profile languages',
      isShow: watchShowLanguages,
      content: languagesPreview.map(
        ({ field, name }) =>
          watch(`searchParameters.profileLanguages.${field}`) && (
            <PreviewBlock key={field} onClick={() => setValue(`searchParameters.profileLanguages.${field}`, false)}>
              {name}
            </PreviewBlock>
          ),
      ),
    },
    {
      title: 'Schools',
      isShow: !!schools.length,
      content: schools.map(({ name }, id) => (
        <PreviewBlock key={name} onClick={() => removeSchool(id)}>
          {name}
        </PreviewBlock>
      )),
    },
    {
      title: 'Locations',
      isShow: !!locations.length,
      content: locations.map(({ name }, id) => (
        <PreviewBlock key={name} onClick={() => removeLocation(id)}>
          {name}
        </PreviewBlock>
      )),
    },
  ];

  return (
    <Box display="grid" gap="10px">
      {preview.map(
        ({ content, isShow, title }) =>
          isShow && (
            <Box key={title} display="flex" width="100%">
              <Box width="180px">
                <Typography width="180px" display="block" fontSize="12px" color="gray.dark">
                  {title}
                </Typography>
              </Box>
              <Box width="80" display="flex" flexWrap="wrap" gap="10px">
                {content}
              </Box>
            </Box>
          ),
      )}
    </Box>
  );
};
