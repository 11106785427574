import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getUserLinkedInGroups } from 'src/api';
import { IUserLinkedInGroup } from 'src/types';

export const useGetUserLinkedInGroups = <TData = IUserLinkedInGroup[]>(
  options?: UseQueryOptions<IUserLinkedInGroup[], AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IUserLinkedInGroup[], AxiosError, TData>(
    'user-linked-in-groups',
    getUserLinkedInGroups,
    { refetchOnMount: true, ...options },
  );

  return {
    groups: data,
    ...rest,
  };
};
