import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { addMoreLeadsCampaign, updateCampaignFromCsvUpload } from 'src/api';
import { ICampaign, ICampaignAudienceRequest, ICustomAxiosError } from 'src/types';
import { useSingleCampaignProps } from '../../hooks';

interface IUseUpdateCampaignFromCsvUploadParams {
  generalCampaignType: string;
  campaignId: number;
  isAddMoreLeads: boolean;
}

export const useUpdateCampaignFromCsvUpload = (
  { generalCampaignType, campaignId, isAddMoreLeads }: IUseUpdateCampaignFromCsvUploadParams,
  options?: UseMutationOptions<ICampaign, ICustomAxiosError, ICampaignAudienceRequest>,
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { category, campaign, redirectTo } = useSingleCampaignProps();

  const { mutate, ...rest } = useMutation<ICampaign, ICustomAxiosError, ICampaignAudienceRequest>(
    ['update-campaign-csv'],
    (data) => updateCampaignFromCsvUpload(generalCampaignType, campaignId, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        await queryClient.cancelQueries(['campaign', data.id]);

        queryClient.setQueryData<ICampaign>(['campaign', data.id], () => data);

        if (isAddMoreLeads) {
          await addMoreLeadsCampaign({
            campaignId: data.id,
            searchType: variables.campaignType,
            excludeNoPhotos: data.excludeNoPhotos,
            noFirstConnections: data.noFirstConnections,
            retargetLeads: data.retargetLeads,
            premiumOnly: data.premiumOnly,
            openLinkOnly: data.openLinkOnly,
            maxSearchAmount: variables.leads?.length,
            searchUrl: '',
          });

          await queryClient.invalidateQueries(['get-lead-sources', data.id], { refetchInactive: true });

          navigate(
            redirectTo || `/campaign/stats/${category}/${campaign?.runState === 'paused' ? 'paused' : 'active'}`,
          );
        } else {
          navigate(`/campaign/${data.id}/sequence`);
        }
      },
    },
  );

  return { updateCampaignFromCsv: mutate, ...rest };
};
