export enum CampaignCategory {
  LINKEDIN = 'linkedin',
  EMAIL = 'email',
  TWITTER = 'twitter',
  MULTICHANNEL = 'multi-channel',
  INMAIL = 'inmail',
  GROUP = 'group',
  EVENT = 'event',
  POST = 'post',
}

export enum CampaignType {
  LINKEDIN_SEARCH = 'linkedin search',
  LINKEDIN_CSV_UPLOAD = 'csv upload',
  LINKEDIN_SN_SEARCH = 'sales navigator search',
  LINKEDIN_SN_SAVED_SEARCH = 'sales navigator saved search',
  LINKEDIN_CONNECTIONS = 'crm connections',
  LINKEDIN_GROUP = 'linkedin group',
  LINKEDIN_POST = 'linkedin post',
  LINKEDIN_EVENT = 'linkedin event',
  EMAIL_CSV_UPLOAD = 'email_only',
  EMAIL_CONNECTIONS = 'email_connections',
  TWITTER_CSV_UPLOAD = 'twitter_csv',
  TWITTER_CONNECTIONS = 'twitter_connections',
  ZAPIER = 'zapier',
  ZAPIER_LEADS = 'zapier leads',
  RECRUITER_SEARCH = 'recruiter search',
}

export enum CampaignActions {
  FOUND = 'found',
  VIEWED = 'viewed',
  REQUESTED_CONNECT = 'requested connect',
  CONNECTED = 'connected',
  FOLLOWED_UP_AFTER_CONNECT = 'followed up after connect',
  MESSAGE_SENT = 'Message sent',
  GROUP_MESSAGE_SENT = 'Group Message sent',
  INMAIL_MESSAGE_SENT = 'InMail Message sent',
  INMAIL_SKIPPED = 'InMail skipped',
  LINKEDIN_REPLY_DETECTED = 'linkedin reply detected',
  INMAIL_REPLY_DETECTED = 'InMail reply detected',
  EVENT_MESSAGE_SENT = 'Event Message sent',
  EMAIL_BOUNCED = 'Email bounced',
  EMAILED = 'emailed',
  GREETINGS = 'greetings',
  EMAIL_REPLY_DETECTED = 'Email reply detected',
  LEAD_FOLLOWED_IN_TWITTER = 'Lead Followed in Twitter',
  TWITTER_MESSAGE_SENT = 'Twitter Message sent',
  TWITTER_REPLY_DETECTED = 'twitter reply detected',
  ALFRED_DESKTOP_APP_ACTION = 'alfred desktop app action',
  INVITE_WITHDRAWN = 'invite withdrawn',
  EMAIL_OPENED = 'Email opened',
  RETURNED_TO_CAMPAIGN = 'Returned to campaign',
  ALREADY_CONNECTED = 'already connected',
  ALREADY_INVITED = 'already invited',
  FOLLOWED_A_PROFILE = 'Followed a profile',
  NO_EMAIL_FOUND = 'no email found',
  LEAD_POSTPONED = 'Lead postponed',
  MESSAGE_POSTPONED = 'Message postponed',
  LEAD_EXCLUDED = 'Lead Excluded',
  UNABLE_TO_SEND_INMAIL = 'Unable to send InMail message',
}

export enum CampaignStatus {
  ACTIVE = 'active',
  DRAFT = 'draft',
  ARCHIVED = 'archived',
}

export enum CampaignSearchListType {
  ACCOUNT_LIST = 'account_list',
  LEAD_LIST = 'lead_list',
  SAVED_ACCOUNTS_SEARCH = 'saved_accounts_search',
  SAVED_LEADS_SEARCH = 'saved_leads_search',
}

export enum CampaignSequenceStepType {
  LINKED_IN_MESSAGE = 'LI Message',
  LINKED_IN_CONNECT = 'LI Connect',
  LINKED_IN_VIEW = 'LI View',
  IN_MAIL_MESSAGE = 'InMail Message',
  EMAIL = 'Email',
  TWITTER_DM = 'Twitter DM',
  TWITTER_FOLLOW = 'Twitter Follow',
  GROUP_MESSAGE = 'Group Message',
  EVENT_MESSAGE = 'Event Message',
}

export enum CampaignSequenceDelayUnit {
  DAYS = 'day(s)',
  HOURS = 'hour(s)',
}
