import { Grid } from '@mui/material';

import { ImpersonateBar } from 'src/components';

export const AccountBlocked = () => {
  return (
    <>
      <ImpersonateBar />
      <Grid container width="100%" display="flex" justifyContent="center" textAlign="center" mt="150px">
        <Grid item xs={12} md={4}>
          <img
            src="https://uploads-ssl.webflow.com/5c80baac065a7b8335eba635/5c80bf770119be49d3bcb3ed_icon-128.png"
            width="64"
            alt=""
          />
          <h4>Something is wrong with your account, please email us at help@meetalfred.com for further assistance</h4>
        </Grid>
      </Grid>
    </>
  );
};
