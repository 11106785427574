import { useState } from 'react';
import Select, { components, Props as ReactSelectProps } from 'react-select';
import { useTheme } from '@emotion/react';
import { faCaretDown, faCaretUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { IColorSelectOption } from 'src/types';

interface IOptionsSelectProps extends Omit<ReactSelectProps<IColorSelectOption>, 'onChange'> {
  noResultsText?: string;
  defaultValue?: IColorSelectOption | IColorSelectOption[];
  options: IColorSelectOption[];
  placeholder?: string;
  onChange?: (value: IColorSelectOption | IColorSelectOption[]) => void;
}

export const OptionsSelect = ({
  noResultsText = 'No results found',
  placeholder,
  onChange,
  options,
  defaultValue,
  ...props
}: IOptionsSelectProps) => {
  const { palette } = useTheme();
  const [isOpened, setIsOpened] = useState(false);
  const [value, setValue] = useState(defaultValue);

  const onSelectChange = (option: IColorSelectOption | IColorSelectOption[]) => {
    onChange && onChange(option);
    setValue(option);
  };

  return (
    <>
      <Select
        onMenuOpen={() => setIsOpened(true)}
        onMenuClose={() => setIsOpened(false)}
        value={value}
        options={options}
        placeholder={placeholder}
        onChange={(newValue) => onSelectChange(newValue as IColorSelectOption | IColorSelectOption[])}
        noOptionsMessage={() => (
          <Typography align="left" margin="10px" semibold>
            {noResultsText}
          </Typography>
        )}
        {...props}
        styles={{
          container: (provided) => ({
            ...provided,
            display: 'flex',
            flex: 1,
          }),
          control: (provided) => ({
            ...provided,
            border: `1px solid ${palette.lightGray.light} !important`,
            borderRadius: 5,
            boxShadow: 'none',
            display: 'flex',
            flex: 1,
            minHeight: 32,
            height: 32,
            outline: 'none',
            '&:hover': {
              boxShadow: '0 1px 0 rgb(0 0 0 / 6%)',
            },
          }),
          input: (provided) => ({
            ...provided,
            color: '#333',
            fontFamily: 'ProximaSoft',
            fontSize: 14,
            fontWeight: 400,
          }),
          placeholder: (provided) => ({
            ...provided,
            color: 'var(--violet-main)',
            fontFamily: 'ProximaSoft',
            fontSize: 14,
          }),
          menu: (provided) => ({
            ...provided,
            marginTop: -1,
            boxShadow: 'none',
            border: `1px solid ${palette.lightGray.light}`,
          }),
          menuList: (provided) => ({
            ...provided,
            paddingTop: '10px',
            paddingBottom: '10px',
            zIndex: 3,
          }),
          option: (provided, { isSelected }) => ({
            ...provided,
            backgroundColor: '#fff',
            color: isSelected ? palette.primary.main : palette.text.primary,
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            fontFamily: 'ProximaSoft',
            fontSize: '14px',
            justifyContent: 'space-between',
            padding: '10px 20px',
            '&:hover': {
              color: palette.primary.main,
            },
            '&:active': {
              backgroundColor: '#fff',
            },
          }),
          singleValue: (provided) => ({
            ...provided,
            color: '#333',
            fontFamily: 'ProximaSoft',
            fontSize: 14,
          }),
          ...(props?.styles ?? {}),
        }}
        components={{
          IndicatorsContainer: () => (
            <>
              <Box width="25px" marginRight="5px" display="flex" justifyContent="center">
                <FontAwesomeIcon icon={isOpened ? faCaretUp : faCaretDown} color={palette.primary.main} />
              </Box>
            </>
          ),
          Option: ({ children, ...props }) => {
            return (
              <components.Option {...props}>
                <>
                  {children}
                  {props.isSelected && <FontAwesomeIcon icon={faCheck} color={palette.primary.main} />}
                </>
              </components.Option>
            );
          },
          ...(props?.components ?? {}),
        }}
      />
    </>
  );
};
