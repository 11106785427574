export const TESTIMONIALS = [
  {
    logo: '/images/testimonials/streak_crm.png',
    quote:
      'By using Alfred, our team can stay focused on the most important part of their job, without having to waste time manually prospecting for new customers.',
    name: 'Peter Ahn',
    position: 'Head of Sales and Business Development, Streak CRM',
    picture: '/images/testimonials/peter_ahn.png',
  },
  {
    logo: '/images/testimonials/qordoba.png',
    quote:
      "To have access to my and my team's Linkedin connection information is priceless. It's the easiest way to quickly find their email, phone number and even things I didn't think I needed like their Skype ID so I can start talking to them faster.",
    name: 'Elsa MacDonald',
    position: 'Director of Digital Marketing, Qordoba',
    picture: '/images/testimonials/elsa_macdonald.png',
  },
  {
    logo: '/images/testimonials/convertfox.png',
    quote:
      "With a sales and marketing team the size of ours, being able to access everyone's campaigns from my dashboard is beyond helpful. Now I don't have to follow up with them to see how they are going, I can see it with my own eyes.",
    name: "Anna O'Leary",
    position: 'Head of Sales and Business Development, ConvertFox',
    picture: '/images/testimonials/annaoleary.png',
  },
  {
    logo: '/images/testimonials/factoryfour.png',
    quote:
      "We pride ourselves in getting every dollar of value from any investment we make and Alfred's analytics and data keeps us up to date with how are campaigns are going and performing. Super valuable!",
    name: 'Josh Nash',
    position: 'Director of Business Development and Marketing, Factory Four',
    picture: '/images/testimonials/joshnash.png',
  },
  {
    logo: '/images/testimonials/mindsdb.png',
    quote:
      'Alfred has made reaching our potential customers and users a breeze. We have seen a significant improvement in response rates and engagement from using Alfred, from our tests no other method comes close to the effectiveness of Linkedin with Alfred.',
    name: 'Adam Carrigan ',
    position: 'Co-founder and CEO, Minds DB',
    picture: '/images/testimonials/adamcarrigan.png',
  },
];
