import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { completeUserWalkthrough } from 'src/api';
import { ICustomAxiosError } from 'src/types';

export const useCompleteUserWalkthrough = (options?: UseMutationOptions<void, ICustomAxiosError>) => {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(['complete-user-walkthrough'], completeUserWalkthrough, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      await queryClient.invalidateQueries(['get-user-profile']);
    },
  });

  return {
    completeUserWalkthrough: mutate,
    ...rest,
  };
};
