import { partition } from 'lodash';
import { useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, AvatarGroup, Box, Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

import { Tooltip } from 'src/components';
import { Typography } from 'src/components/ui/Typography';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features, TeamUserRole } from 'src/enums';
import { useAppSelector, useTeamPlan } from 'src/hooks';
import {
  useGetAllTeamMembers,
  useGetRealUser,
  usePostImpersonateUser,
  usePostStopImpersonateUser,
} from 'src/reactQueries';

const SelectWrapper = styled.div`
  height: 32px;
  width: 240px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  background-color: #fff;
  padding-left: 10px;
  padding-right: 30px;
  border: 1px solid ${({ theme }) => theme.palette.light.light};
  border-radius: 4px;
  position: relative;

  &:after {
    content: '\\25BE';
    position: absolute;
    color: ${({ theme }) => theme.palette.primary.main};
    right: 10px;
  }
`;

const Text = styled(Typography)`
  font-size: 14px;
  color: #333;
  margin-left: 10px;
`;

const UserItem = styled(MenuItem)`
  padding: 5px 10px;

  &:hover > div > p {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

const Item = styled(Box)<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 90%;
  ${({ disabled }) => disabled && 'filter: grayscale(1); opacity: .8;'}
`;

const Spinner = styled(FontAwesomeIcon)`
  animation: fa-spin 2s linear infinite;
`;

export const SelectProfile = () => {
  const { profile: currentUser, isImpersonate } = useAppSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  const { realImpersonateUser } = useGetRealUser();

  const { impersonateUser, isLoading: impersonateLoading } = usePostImpersonateUser();
  const { stopImpersonateUser, isLoading: stopImpersonateLoading } = usePostStopImpersonateUser();
  const loading = impersonateLoading || stopImpersonateLoading;

  const { checkFeature } = useTeamPlan();
  const isImpersonateAvailable = checkFeature(Features.impersonate);

  const { data: members = [] } = useGetAllTeamMembers();
  const realUser = isImpersonate ? realImpersonateUser : currentUser;
  const realUserMember = (members || [])?.find(({ userId }) => userId === realUser?.id);
  const realUserRole = realUserMember?.role;

  const allPotentialMembers = (members || []).filter(
    // hide real and impersonated user
    (member) => member.userId !== currentUser.id && member.userId !== realImpersonateUser?.id,
  );
  const [available, unavailable] = useMemo(
    () =>
      partition(allPotentialMembers, (member) => {
        // not available all if impersonate is not available
        if (!isImpersonateAvailable) {
          return false;
        }

        // hide owners if user admin
        if (realUserRole === TeamUserRole.ADMIN && member?.role === TeamUserRole.OWNER) {
          return false;
        }

        // hide alfred admins if user not alfred admin
        if (!realUser?.isAlfredAdmin && member?.isAlfredAdmin) {
          return false;
        }

        return true;
      }),
    [allPotentialMembers, realImpersonateUser],
  );

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) =>
    (isImpersonate || allPotentialMembers.length) && setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const impersonateSelect = useMemo(() => {
    const previewTeamMembers = [...available, ...unavailable].slice(0, 3);

    if (realUserRole === TeamUserRole.MEMBER || (!isImpersonate && !allPotentialMembers.length)) {
      return null;
    }

    if (loading) {
      return (
        <SelectWrapper>
          <Spinner icon={faSpinner} />
          <Text>Loading ...</Text>
        </SelectWrapper>
      );
    }

    if (isImpersonate) {
      return (
        <SelectWrapper onClick={handleClick}>
          <Item>
            <Avatar src={currentUser.profilePictureUrl} sx={{ width: 26, height: 26 }} />
            <Text>{currentUser.name}</Text>
          </Item>
        </SelectWrapper>
      );
    }

    return (
      <SelectWrapper onClick={handleClick}>
        <AvatarGroup max={4}>
          {previewTeamMembers.map(({ userId, profilePictureUrl }) => (
            <Avatar key={userId} src={profilePictureUrl} sx={{ width: 26, height: 26 }} />
          ))}
        </AvatarGroup>
        <Text>All accounts ({allPotentialMembers.length})</Text>
      </SelectWrapper>
    );
  }, [loading, isImpersonate, realUserRole, currentUser, allPotentialMembers]);

  if (realUserRole === TeamUserRole.MEMBER && isImpersonate) {
    stopImpersonateUser();

    return null;
  }

  return (
    <Box>
      {impersonateSelect}
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          disablePadding: true,
        }}
        PaperProps={{
          sx: {
            width: 280,
            maxHeight: 450,
            marginTop: '15px',
          },
        }}
        disableScrollLock={true}
      >
        {isImpersonate && currentUser.id !== realImpersonateUser?.id && (
          <UserItem onClick={() => stopImpersonateUser({ withAdminRedirect: false })}>
            <Item>
              <Avatar src={realImpersonateUser?.profilePictureUrl} sx={{ width: 26, height: 26 }} />
              <Typography crop>{realImpersonateUser?.name}</Typography> <Typography>(me)</Typography>
            </Item>
          </UserItem>
        )}
        {available.map(({ teamId, profilePictureUrl, userId, name }) => {
          return (
            <UserItem
              key={userId}
              onClick={() => {
                impersonateUser({ teamIdToImpersonate: teamId, userIdToImpersonate: userId });
              }}
            >
              <Item>
                <Avatar src={profilePictureUrl} sx={{ width: 26, height: 26 }} />
                <Typography crop>{name}</Typography>
              </Item>
            </UserItem>
          );
        })}
        <Box height="1px" bgcolor="#e8e8e8" />
        {unavailable.map(({ profilePictureUrl, userId, name }) => {
          return (
            <UserItem key={userId}>
              <Tooltip
                title={isImpersonateAvailable ? 'This account cannot be impersonated' : PLAN_TOOLTIPS.professional}
              >
                <Item disabled>
                  <Avatar src={profilePictureUrl} sx={{ width: 26, height: 26 }} />
                  <Typography crop>{name}</Typography>
                </Item>
              </Tooltip>
            </UserItem>
          );
        })}
      </Menu>
    </Box>
  );
};
