import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { assignMemberToGroup } from 'src/api/groups';
import { showToast } from 'src/store/toast.slice';
import { IAssignMemberToGroupRequest, ICustomAxiosError } from 'src/types';

export const useAssignMemberToGroup = (
  options?: UseMutationOptions<void, ICustomAxiosError, IAssignMemberToGroupRequest>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation(['assign-member-to-group'], assignMemberToGroup, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      await queryClient.invalidateQueries(['get-group-list-full']);
      await queryClient.invalidateQueries(['get-team-members']);
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { assignMemberToGroup: mutate, ...rest };
};
