import { useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Tooltip, Typography } from 'src/components';
import { MenuItem } from 'src/components/ui/Menu';
import { CampaignIcon } from 'src/containers/Campaigns/_components';
import { CampaignCategory, CampaignSequenceStepType } from 'src/enums';
import { useAddSequenceStepConfig, useOutsideClick } from 'src/hooks';
import { IGetCampaign, ISequenceStep } from 'src/types';

const StepIcon = styled(Box)`
  position: relative;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
  &:hover {
    div:nth-of-type(2) {
      display: block !important;
    }
  }
`;

const StepMenu = styled(Box)`
  //display: none;
  position: absolute;
  z-index: 1000;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  background-clip: padding-box;
  top: 48px;
  left: auto;
`;

const StyledItem = styled(MenuItem)`
  transition: 0.2s;
  padding: 7px 20px;
  font-size: 14px;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.wlLight};
    background-color: ${({ theme }) => theme.palette.light.main};
  }

  &:disabled {
    color: gray;
    background-color: transparent;
  }
`;

interface IAddSequenceStep {
  steps: ISequenceStep[];
  category?: CampaignCategory;
  campaign?: IGetCampaign;
  onAddSequenceStep: (type: CampaignSequenceStepType) => void;
}

export const AddSequenceStep = ({ steps = [], onAddSequenceStep, category, campaign }: IAddSequenceStep) => {
  const config = useAddSequenceStepConfig(steps, category, campaign);

  const ref = useRef();
  const [clickedBlock, setClickedBlock] = useState<CampaignCategory | null>(null);

  useOutsideClick(ref, () => {
    setClickedBlock(null);
  });

  const onSequenceStepClick = (type: CampaignSequenceStepType) => {
    onAddSequenceStep(type);
  };

  return (
    <Box mt="20px" p="20px">
      <Typography mb="20px" color="#333" semibold>
        Add campaign step:
      </Typography>
      <Box display="flex" ref={ref}>
        {config.map(
          ({ show, type, disabled, tooltip, disabledTooltip, steps, stepType }) =>
            show &&
            (disabled ? (
              <CampaignIcon key={type} type={type} tooltip={disabledTooltip} disabled mr="40px" />
            ) : (
              <StepIcon mr="40px">
                <CampaignIcon
                  key={type}
                  type={type}
                  tooltip={tooltip}
                  onClick={() => {
                    setClickedBlock(type);
                    stepType && onSequenceStepClick(stepType);
                  }}
                />
                {!!steps.length && (
                  <StepMenu display={clickedBlock === type ? 'block' : 'none'}>
                    {steps.map(
                      ({ title, show = true, stepType, disabled, disabledTooltip, id }) =>
                        show && (
                          <Tooltip key={stepType} title={disabled && disabledTooltip}>
                            <span>
                              <StyledItem
                                disabled={disabled}
                                onClick={() => onSequenceStepClick(stepType)}
                                id={id}
                                data-intercom-target={id}
                              >
                                {title}
                              </StyledItem>
                            </span>
                          </Tooltip>
                        ),
                    )}
                  </StepMenu>
                )}
              </StepIcon>
            )),
        )}
      </Box>
    </Box>
  );
};
