import { IS_ALFRED_DOMAIN, IS_SHOW_WHITE_LABEL } from 'src/constants';
import { useGetWhiteLabel, useGetWhiteLabelBranding } from 'src/reactQueries';
import { useAppSelector } from './useReduxHooks';

export const useWhiteLabel = () => {
  const { isAuth } = useAppSelector(({ user }) => user);

  const isAlfredDomain = IS_ALFRED_DOMAIN;

  const authWhitelabel = useGetWhiteLabel({
    enabled: isAuth,
    retry: 0,
  });
  const domainWhitelabel = useGetWhiteLabelBranding({
    enabled: !isAuth && !isAlfredDomain,
    retry: 0,
  });

  const { data, ...rest } = isAuth ? authWhitelabel : domainWhitelabel;

  const isActive = IS_SHOW_WHITE_LABEL && data?.status === 'active';
  const isWhiteLabelUser = !isAlfredDomain;
  const withBranding = !!data?.companyName;
  const withCustomDomain = !!data?.domain;
  const withCustomEmail = !!data?.smtpSettings?.smtpServer;

  return {
    whitelabel: {
      ...data,
      isActive,
      isWhiteLabelUser,
      isAlfredDomain,
      withCustomEmail,
      withBranding,
      withCustomDomain,
    },
    ...rest,
  };
};
