import { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button, Checkbox } from 'src/components';
import { closeModal } from 'src/store/modal.slice';
import { ICampaignProfileLanguages } from 'src/types';

const ClearAllBlock = styled(Box)`
  margin-left: 20px;
  padding: 5px 15px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.wlLight};
  border: 1px solid ${({ theme }) => theme.palette.light.dark};
  font-size: 10px;
  border-radius: 4px;
  transition: 0.2s;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.wlLight};
    border: 1px solid ${({ theme }) => theme.palette.primary.wlLight};
    color: #fff;
  }
`;

const LANGUAGES: Array<keyof ICampaignProfileLanguages> = [
  'english',
  'french',
  'spanish',
  'portuguese',
  'russian',
  'polish',
];

interface IModalSelectProfileLanguagesProps {
  values: ICampaignProfileLanguages;
  setValues: (newValues: ICampaignProfileLanguages) => void;
}

export const ModalSelectProfileLanguages = ({
  values: propValues,
  setValues: setValuesProp,
}: IModalSelectProfileLanguagesProps) => {
  const [values, setValues] = useState(propValues);
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeModal());
  };

  const change = (e: ChangeEvent<HTMLInputElement>, name: keyof ICampaignProfileLanguages) => {
    const newValues = { ...values, [name]: e.target.checked };
    setValues(newValues);
    setValuesProp(newValues);
  };

  const showClearAll = !!Object.values(values).find((value) => value);

  const clearAll = () => {
    const cleared: ICampaignProfileLanguages = {
      english: false,
      french: false,
      spanish: false,
      portuguese: false,
      russian: false,
      polish: false,
    };
    setValues(cleared);
    setValuesProp(cleared);
  };

  return (
    <>
      <Box py="30px" px="40px" display="flex" alignItems="center" fontSize="14px" height="20px">
        Profile languages
        {showClearAll && <ClearAllBlock onClick={clearAll}>X CLEAR ALL</ClearAllBlock>}
      </Box>
      <Box py="30px" px="40px" display="flex" justifyContent="space-between" flexWrap="wrap" bgcolor="light.light">
        {LANGUAGES.map((language) => (
          <Box key={language} display="flex" alignItems="center" width="50%" my="14px">
            <Checkbox id={language} type="checkbox" checked={values[language]} onChange={(e) => change(e, language)} />
            <label htmlFor={language}>{language}</label>
          </Box>
        ))}
      </Box>
      <Box py="20px" px="40px" display="flex" justifyContent="flex-end">
        <Button onClick={close}>Save</Button>
      </Box>
    </>
  );
};
