import classNames from 'classnames';
import { CSSProperties, ReactNode } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import MuiTableCell, { TableCellProps } from '@mui/material/TableCell';

import { Tooltip } from 'src/components/ui/Tooltip';
import { ITableSorting, TJustifyContent } from 'src/types';

const StyledTableHeadCell = styled(MuiTableCell)`
  padding: 10px;
  background-clip: padding-box;
  ${({ width }) => (width ? `width: ${width} !important; box-sizing: border-box;` : '')}

  &::first-letter {
    text-transform: capitalize;
  }

  &.sortable {
    cursor: pointer;
  }

  &.sticky {
    position: sticky;
    right: 0;
    background-color: #ffffff;
    padding: 0;

    & > div {
      padding: 10px;
    }
  }
`;

const BLACK_LIST_PROPS = ['secondary'];

const StyledInnerContent = styled(Box, {
  shouldForwardProp: (prop) => !BLACK_LIST_PROPS.includes(prop),
})<{ secondary?: boolean }>`
  display: flex;
  align-items: center;
  font-family: 'ProximaSoft', sans-serif;
  font-weight: 500;
  font-size: ${(props) => (props.secondary ? '10' : '14')}px;
  white-space: nowrap;
  height: 100%;
  box-sizing: border-box;
`;

interface ITableHeadCellProps extends TableCellProps {
  children?: ReactNode;
  name?: string;
  contentColor?: string;
  sorting?: ITableSorting;
  secondary?: boolean;
  justifyContent?: TJustifyContent;
  contentStyle?: CSSProperties;
  tooltip?: ReactNode;
  tooltipPlacement?: 'top' | 'left';
  sticky?: boolean;
  empty?: boolean;
}

export const TableHeadCell = ({
  children,
  width,
  name,
  sorting,
  tooltip,
  secondary,
  tooltipPlacement = 'top',
  justifyContent = secondary ? 'flex-start' : 'space-between',
  contentStyle,
  contentColor,
  sticky = false,
  empty = false,
}: ITableHeadCellProps) => {
  const { palette } = useTheme();
  const isSorting = !!(name && sorting?.onSortFieldChange);
  const isActive = name === sorting?.sortField;
  const activeIcon = sorting?.sortOrder === 'DESC' ? faSortDown : faSortUp;

  const clickHandler = () => {
    if (name && sorting?.onSortFieldChange) {
      sorting.onSortFieldChange(name, isActive && sorting?.sortOrder === 'ASC' ? 'DESC' : 'ASC');
    }
  };

  if (empty) {
    return <StyledTableHeadCell variant="head" width="0" sx={{ padding: '0 !important' }} />;
  }

  return (
    <StyledTableHeadCell
      data-testid="table-head-cell"
      variant="head"
      width={width}
      className={classNames({ sortable: isSorting, sticky })}
      {...(isSorting && { onClick: clickHandler })}
    >
      <Tooltip title={tooltip} placement={tooltipPlacement}>
        <StyledInnerContent
          justifyContent={justifyContent}
          secondary={secondary}
          color={contentColor || palette.gray['500']}
          fontWeight={600}
          textTransform="uppercase"
          style={contentStyle}
        >
          {children}
          {isSorting && (
            <FontAwesomeIcon
              data-testid={`table-sorting-${name}`}
              icon={isActive ? activeIcon : faSort}
              style={{
                margin: '0 5px',
                color: contentColor,
                opacity: isActive ? 1 : 0.5,
              }}
            />
          )}
        </StyledInnerContent>
      </Tooltip>
    </StyledTableHeadCell>
  );
};
