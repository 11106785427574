import styled from '@emotion/styled';
import { faHeadset } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getContrastYIQ } from 'src/helpers';

const AdminRedirectButton = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => getContrastYIQ(theme.palette.secondary.main)};
  }
`;

export const AdminRedirect = () => {
  const onAdminRedirectClick = () => {
    window.location.href = process.env.REACT_APP_ADMIN_URL || '';
  };

  return (
    <AdminRedirectButton onClick={onAdminRedirectClick}>
      <FontAwesomeIcon icon={faHeadset} />
    </AdminRedirectButton>
  );
};
