import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { refreshUserLinkedInGroups } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IUserLinkedInGroup } from 'src/types';

export const useRefreshUserLinkedinGroups = (options?: UseMutationOptions<IUserLinkedInGroup[], ICustomAxiosError>) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation(['refresh-user-li-groups'], refreshUserLinkedInGroups, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      dispatch(
        showToast({
          type: 'info',
          message: 'successfully refreshed linkedin groups',
        }),
      );
      await queryClient.cancelQueries(['user-linked-in-groups']);

      queryClient.setQueryData('user-linked-in-groups', () => data);
    },
  });

  return { refreshLIGroups: mutate, ...rest };
};
