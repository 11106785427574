import { createSlice } from '@reduxjs/toolkit';

type TInitialState = {
  fetchingTrends: Record<number, boolean>;
};

const initialState: TInitialState = {
  fetchingTrends: {},
};

export const trendsSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    setFetchingTrend: (state, action) => {
      state.fetchingTrends = { ...state.fetchingTrends, ...action.payload };
    },
  },
});

export const { setFetchingTrend } = trendsSlice.actions;
export default trendsSlice.reducer;
