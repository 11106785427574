import { useDispatch } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Box, Grid } from '@mui/material';

import { ReactComponent as ConnectionsFromContactsIcon } from 'src/assets/img/campaign/icons_set_1st_Degree_Connections.svg';
import { ReactComponent as ThirdPartyIcon } from 'src/assets/img/campaign/icons_set_3rd_Party_Integrations.svg';
import { ReactComponent as csvUploadIcon } from 'src/assets/img/campaign/icons_set_CSV_Upload.svg';
import { ReactComponent as liSearchIcon } from 'src/assets/img/campaign/icons_set_Linkedin_Search.svg';
import { ReactComponent as liUrlIcon } from 'src/assets/img/campaign/icons_set_Linkedin_URL.svg';
import { ReactComponent as snSavedSearchIcon } from 'src/assets/img/campaign/icons_set_Sales_Navigator_Searches.svg';
import { Typography } from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { CampaignCategory, CampaignType, Features } from 'src/enums';
import { useLocationState, useTeamPlan, useWhiteLabel } from 'src/hooks';
import { useGetSNSavedSearch } from 'src/reactQueries';
import { setConnectionSelectedLeads } from 'src/store/connections.slice';
import { ILocationStateChooseCampaignType } from 'src/types';
import { CampaignsSteps } from '../CampaignSteps';
import { CampaignCard } from './CampaignCard';

const PRO_ONLY_TOOLTIP = PLAN_TOOLTIPS.professional;
const PERSONAL_AND_PRO_ONLY_TOOLTIP = PLAN_TOOLTIPS.any;

export const ChooseCampaignType = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { whitelabel } = useWhiteLabel();
  const { category } = useParams();
  const { campaignIdToAddLeads, redirectTo } = useLocationState<ILocationStateChooseCampaignType>();

  const { checkFeature, isIndividual, isStarter } = useTeamPlan();

  useGetSNSavedSearch();

  const redirectToAudience = (type: string) => {
    const path = campaignIdToAddLeads
      ? `/campaign/${campaignIdToAddLeads}/audience`
      : `/campaign/new/${category}/${type}`;
    const state = campaignIdToAddLeads ? { campaignPageType: type, category, isAddMoreLeads: true, redirectTo } : {};

    navigate(path, { state });
  };

  const redirectToConnections = (campaignType: CampaignType) => {
    dispatch(setConnectionSelectedLeads([]));

    navigate('/contacts/connections', {
      state: {
        category,
        campaignType,
        campaignIdToAddLeads,
        isLinkedinOnly: category === CampaignCategory.LINKEDIN,
        isAddMoreLeads: true,
        redirectTo,
      },
    });
  };

  const trialTryTooltip = !checkFeature(Features.twitterAndEmail1stDegreeCampaign) ? PRO_ONLY_TOOLTIP : '';

  const getCampaignCards = () => {
    switch (category) {
      case CampaignCategory.LINKEDIN:
      case CampaignCategory.MULTICHANNEL:
      case CampaignCategory.INMAIL:
        return [
          {
            name: 'LinkedIn Search',
            description: 'Use our built-in search to find your ideal target audience',
            image: liSearchIcon,
            visible: category !== CampaignCategory.INMAIL,
            onClick: () => redirectToAudience('search'),
          },
          {
            name: 'LinkedIn URL',
            id: 'linkedin-url-card',
            description: 'Cut and paste your LinkedIn search URL to add up 2,500 leads at a time',
            image: liUrlIcon,
            visible: true,
            onClick: () => redirectToAudience('url'),
          },
          {
            name: 'CSV Upload',
            description: 'Import file with just LinkedIn profiles to add leads',
            image: csvUploadIcon,
            visible: true,
            onClick: () => redirectToAudience('csv-upload'),
            tryTooltip: isIndividual ? PLAN_TOOLTIPS.any : '',
          },
          {
            name: 'Sales Navigator Searches',
            description: 'Import leads from SN Lead Lists and Saved Searches',
            image: snSavedSearchIcon,
            visible: true,
            onClick: () => redirectToAudience('sales-navigator'),
            tryTooltip: isStarter || isIndividual ? PLAN_TOOLTIPS.any : '',
          },
          {
            name: '1st Degree Connections',
            description: 'Choose profiles from your Contacts list',
            image: ConnectionsFromContactsIcon,
            visible: true,
            onClick: () => redirectToConnections(CampaignType.LINKEDIN_CONNECTIONS),
            tryTooltip: isStarter ? PLAN_TOOLTIPS.any : '',
          },
          {
            name: '3rd Party Integrations',
            description: 'Add leads from thousands of tools using Zapier',
            image: ThirdPartyIcon,
            visible: !whitelabel.isWhiteLabelUser && !campaignIdToAddLeads,
            onClick: () => redirectToAudience('zapier'),
            tryTooltip: trialTryTooltip,
          },
        ];
      case CampaignCategory.EMAIL:
        return [
          {
            name: 'CSV Upload',
            description: 'Import file with emails to add leads',
            image: csvUploadIcon,
            height: '240px',
            visible: true,
            onClick: () => redirectToAudience('csv-upload'),
            tryTooltip: !checkFeature(Features.twitterAndEmailCSVCampaign) ? PERSONAL_AND_PRO_ONLY_TOOLTIP : '',
          },
          {
            name: '1st Degree Connections',
            description: 'Choose profiles from your Contacts list',
            image: ConnectionsFromContactsIcon,
            height: '240px',
            visible: true,
            onClick: () => redirectToConnections(CampaignType.EMAIL_CONNECTIONS),
            tryTooltip: trialTryTooltip,
          },
        ];
      case CampaignCategory.TWITTER:
        return [
          {
            name: 'CSV Upload',
            description: 'Import file with just X (Twitter) profiles to add leads',
            image: csvUploadIcon,
            height: '240px',
            visible: true,
            onClick: () => redirectToAudience('csv-upload'),
            tryTooltip: !checkFeature(Features.twitterAndEmailCSVCampaign) ? PERSONAL_AND_PRO_ONLY_TOOLTIP : '',
            disabled: true,
            disabledTooltip: 'Feature under Maintenance',
          },
          {
            name: '1st Degree Connections',
            description: 'Choose profiles from your Contacts list',
            image: ConnectionsFromContactsIcon,
            height: '240px',
            visible: true,
            onClick: () => redirectToConnections(CampaignType.TWITTER_CONNECTIONS),
            tryTooltip: trialTryTooltip,
            disabled: true,
            disabledTooltip: 'Feature under Maintenance',
          },
        ];
      default:
        return [];
    }
  };

  const list = getCampaignCards();

  // these categories do not have more than 1 type that why redirect to audience
  if (category === CampaignCategory.GROUP) {
    return <Navigate to={`/campaign/new/${CampaignCategory.GROUP}/group`} />;
  }

  if (category === CampaignCategory.POST) {
    return <Navigate to={`/campaign/new/${CampaignCategory.POST}/post`} />;
  }

  if (category === CampaignCategory.EVENT) {
    return <Navigate to={`/campaign/new/${CampaignCategory.EVENT}/event`} />;
  }

  return (
    <>
      <CampaignsSteps />

      <Box pt="10px" pb="40px" display="flex" flexDirection="column" alignItems="center" bgcolor="light.light">
        <Typography
          component="h2"
          fontSize="32px"
          lineHeight="44px"
          color="primary.wlLight"
          textAlign="center"
          mb="10px"
          semibold
        >
          Where do you want your leads to come from?
        </Typography>

        <Grid container width="80%" columnSpacing="60px" rowSpacing="30px" justifyContent="center">
          {list.map((card) =>
            card.visible ? (
              <Grid key={card.name} item lg={4} md={6} sm={12}>
                <CampaignCard {...card} />
              </Grid>
            ) : null,
          )}
        </Grid>
      </Box>
    </>
  );
};
