import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { deleteTemplate } from 'src/api';
import { closeModal } from 'src/store/modal.slice';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IGetTemplatesResponse } from 'src/types';

export const useDeleteTemplate = (
  templateId: number,
  goBack: boolean,
  options?: UseMutationOptions<void, ICustomAxiosError>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getPersonalTemplateKey = ['get-templates-personal'];

  const { mutate, ...rest } = useMutation(['delete-template', templateId], () => deleteTemplate(templateId), {
    ...options,
    onMutate: async (variables) => {
      options?.onMutate?.(variables);
      await queryClient.cancelQueries(getPersonalTemplateKey);

      const prevData = queryClient.getQueryData(getPersonalTemplateKey);

      queryClient.setQueriesData<IGetTemplatesResponse | undefined>(getPersonalTemplateKey, (items) => {
        if (items) {
          return {
            ...items,
            total: items?.total - 1,
            templates: items?.templates?.filter((item) => item.id !== templateId),
          };
        }

        return items;
      });

      return { prevData };
    },
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      await queryClient.invalidateQueries(getPersonalTemplateKey, { exact: false });
      dispatch(closeModal());
      goBack && navigate(-1);
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      queryClient.setQueryData(getPersonalTemplateKey, context?.prevData);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { deleteTemplate: mutate, ...rest };
};
