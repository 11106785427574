import { PropsWithChildren, ReactNode, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  HeaderButton,
  HeaderContainer,
  HeaderDescription,
  HeaderTabLink,
  HeaderTitle,
  HeaderVideo,
  Link,
  SelectBasicGray,
  TabsContainer,
} from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features } from 'src/enums';
import { useTeamPlan, useWhiteLabel } from 'src/hooks';
import { IGetTemplatesResponse, ISelectOption } from 'src/types';

interface IHeaderProps extends PropsWithChildren {
  title: string;
  route: string;
  description: ReactNode;
  guideLink?: string;
  videoLink?: string;
  categories: IGetTemplatesResponse['categories'];
}

export const TemplateLayout = ({
  categories,
  title,
  route,
  description,
  children,
  guideLink,
  videoLink,
}: IHeaderProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { whitelabel } = useWhiteLabel();
  const { checkFeature } = useTeamPlan();
  const isTemplatesTeamAndLibraryAllowed = checkFeature(Features.templatesTeamAndLibrary);

  const TABS = useMemo(
    () => [
      { name: 'Personal', route: 'personal' },
      {
        name: 'Team',
        route: 'team',
        disabled: !isTemplatesTeamAndLibraryAllowed,
        disabledTooltip: PLAN_TOOLTIPS.professional,
      },
      {
        name: 'Library',
        route: 'library',
        disabled: !isTemplatesTeamAndLibraryAllowed,
        disabledTooltip: PLAN_TOOLTIPS.professional,
      },
    ],
    [isTemplatesTeamAndLibraryAllowed],
  );

  const onCreateTemplateClick = () => {
    navigate('/templates/new');
  };

  const onCategoryChange = ({ value }: ISelectOption) => {
    navigate(`/templates/${route}${Number(value) ? `?category=${value}` : ''}`);
  };

  return (
    <>
      <HeaderContainer>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <HeaderTitle>{title}</HeaderTitle>
          <Box alignItems="center" boxSizing="border-box" display="flex" padding="0 15px">
            <HeaderButton
              onClick={onCreateTemplateClick}
              disabled={!checkFeature(Features.templateCreation)}
              tooltip={{
                title: !checkFeature(Features.templateCreation) && PLAN_TOOLTIPS.professional,
              }}
            >
              Create Template
            </HeaderButton>
          </Box>
        </Box>
        <HeaderDescription>
          {description}
          {whitelabel.isWhiteLabelUser || (
            <>
              {guideLink && (
                <Link leftIcon underline external openNewTab to={guideLink}>
                  Learn how it works
                </Link>
              )}

              {videoLink && <HeaderVideo src={videoLink} />}
            </>
          )}
        </HeaderDescription>
        <Box display="flex" width="100%">
          <TabsContainer>
            {TABS.map(({ name, route, disabled, disabledTooltip }) => {
              const to = `/templates/${route}`;

              return (
                <HeaderTabLink key={name} to={to} disabled={disabled} tooltip={disabled && disabledTooltip}>
                  {name}
                </HeaderTabLink>
              );
            })}
          </TabsContainer>
          <Box display="flex" margin="0 0 0 auto">
            {!!categories.length && (
              <SelectBasicGray
                label="Category:"
                value={String(searchParams.get('category') || 0)}
                onChange={onCategoryChange}
                options={categories.map((category) => ({
                  value: String(category.id || 0),
                  label: `${category.name} (${category.totalTemplates})`,
                }))}
                minWidth="200px"
                valueContainerPosition="space-between"
              />
            )}
          </Box>
        </Box>
      </HeaderContainer>
      {children}
    </>
  );
};
