import { map, uniqBy } from 'lodash';
import Papa from 'papaparse';

import { REGEXP_VALID_EMAIL_EXTENDED } from 'src/constants';
import { ICSVUploadValue, TCSVFileTypes } from 'src/types';
import { convertSalesNavigatorUrl } from './searchUrl';
import { getProperClassName } from './strings';
import { validateLinkedinCsvUrl } from './validate';

const WRONG_FORMAT = 'Wrong format of file.';
const NO_LEADS_FOUND = 'No leads found. Please verify your file.';

const MAX_CSV_ROWS = 5000;
const MAX_CSV_ROWS_ERROR = 'CSV file size must be less than 5000 lines';

export const convertCSVToMatrix = (file: string) => {
  const data = file?.replaceAll('\r', '');

  return Papa.parse<string[]>(data, {
    delimiter: '',
    skipEmptyLines: true,
    delimitersToGuess: [',', ';', '\t'],
    transform: (value, field) => (field === 0 ? value?.trim() : value),
  })?.data;
};

export const serializeCsvToRequest = (firstRow: string[], matrix: string[][], headers: string[]): ICSVUploadValue => {
  return {
    request: {
      header: firstRow.reduce((prev, cur, index) => {
        return {
          ...prev,
          [cur]: headers[index] || matrix[0]?.[index],
        };
      }, {}),
      leads: matrix.slice(1).map((row) => {
        return firstRow.reduce((prev, cur, index) => {
          return { ...prev, [cur]: row?.[index] };
        }, {});
      }),
    },
  };
};

export const parseLinkedInCsv = (firstRow: string[], matrix: string[][]): ICSVUploadValue => {
  if (matrix[0]?.[0]?.includes('linkedin.com')) {
    return { error: 'The First row should be headers.' };
  }

  if (!matrix.slice(1).some((row) => row[0]?.includes('linkedin.com'))) {
    return { error: NO_LEADS_FOUND };
  }

  const normalizedFirstRow = firstRow.map((item, index) => (index === 0 ? 'linkedin_url' : `csv_${item}`));

  const filteredMatrix = matrix.filter((row, index) => index === 0 || (row[0] && validateLinkedinCsvUrl(row[0])));

  const uniqLinkedinUrlMatrix = uniqBy(filteredMatrix, (array) => (array ?? [])[0]);

  const linkedinProfileMatrix = uniqLinkedinUrlMatrix.map(([linkedinUrl, ...row]) => [
    convertSalesNavigatorUrl(linkedinUrl),
    ...row,
  ]);

  return serializeCsvToRequest(normalizedFirstRow, linkedinProfileMatrix, ['Linkedin profile url']);
};

export const parseEmailCsv = (firstRow: string[], matrix: string[][]): ICSVUploadValue => {
  const normalizedFirstRow = firstRow.map((item, index) => (index === 0 ? 'email' : `csv_${item}`));
  const normalizedMatrix = matrix.filter(
    (row, index) => index === 0 || REGEXP_VALID_EMAIL_EXTENDED.test(String(row?.[0])),
  );

  if (normalizedMatrix.length <= 1) {
    return { error: `${NO_LEADS_FOUND} Email should be in the first Column` };
  }

  const uniqEmailMatrix = uniqBy(normalizedMatrix, (array) => (array ?? [])[0]);

  return serializeCsvToRequest(normalizedFirstRow, uniqEmailMatrix, ['Email']);
};

export const parseTwitterCsv = (firstRow: string[], matrix: string[][]): ICSVUploadValue => {
  if (firstRow[0]?.toLowerCase() !== 'name' || firstRow[1]?.toLowerCase() !== 'twitter') {
    return { error: WRONG_FORMAT };
  }

  const normalizedFirstRow = firstRow.map((item, index) => {
    if (index === 0) {
      return 'name';
    } else if (index === 1) {
      return 'twitter';
    }

    return `csv_${item}`;
  });

  const strippedTwitterHandleMatrix = map(matrix, (array) => {
    array[1] = String((array ?? [])[1] ?? '')?.replace('https://twitter.com/', '');

    return array;
  });
  const uniqTwitterHandleMatrix = uniqBy(strippedTwitterHandleMatrix, (array) =>
    String((array ?? [])[1] ?? '')?.toLowerCase(),
  );
  const removedEmptyNameAndTwitterHandle = uniqTwitterHandleMatrix.filter((row) => (row ?? [])[0] && (row ?? [])[1]);

  return serializeCsvToRequest(normalizedFirstRow, removedEmptyNameAndTwitterHandle, ['Name', 'Twitter name']);
};

export const parseCsvBody = (body: string | ArrayBuffer | null, type: TCSVFileTypes): ICSVUploadValue => {
  // reject if file body isn't string
  if (typeof body !== 'string' || !body?.length) {
    return { error: WRONG_FORMAT };
  }

  // convert file body to a two-dimensional matrix (1 column and 2 rows minimum)
  const matrix = convertCSVToMatrix(body);

  if (!(matrix?.length > 1 && Array.isArray(matrix[0]) && matrix[0]?.length)) {
    return { error: NO_LEADS_FOUND };
  }

  // normalize the matrix headers and check for an empty or duplicate value
  const firstRow = matrix[0].map((item) => getProperClassName(item));
  if (firstRow.some((item) => !item)) {
    return { error: `${WRONG_FORMAT} All columns must have headers.` };
  }
  if (new Set(firstRow).size !== firstRow.length) {
    return {
      error: `${WRONG_FORMAT} Please use different names for each column.`,
    };
  }

  if (matrix.length > MAX_CSV_ROWS) {
    return {
      error: MAX_CSV_ROWS_ERROR,
    };
  }

  switch (type) {
    case 'linkedin':
      return parseLinkedInCsv(firstRow, matrix);
    case 'email':
      return parseEmailCsv(firstRow, matrix);
    case 'twitter':
      return parseTwitterCsv(firstRow, matrix);
    default:
      return { error: WRONG_FORMAT };
  }
};

export const compareCsvSnippets = (oldSnippet?: Record<string, string>, newSnippet?: Record<string, string>) => {
  if (!oldSnippet) {
    return true;
  }

  const oldSnippets = Object.keys(oldSnippet ?? {})
    .map((v) => v.trim().toLowerCase())
    .sort();
  const newSnippets = Object.keys(newSnippet ?? {})
    .map((v) => v.trim().toLowerCase())
    .sort();

  if (!oldSnippets?.length || !newSnippets.length) {
    return true;
  }

  return JSON.stringify(oldSnippets) === JSON.stringify(newSnippets);
};
