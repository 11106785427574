import { useMemo } from 'react';
import { Box } from '@mui/material';

import { Button, Typography } from 'src/components';
import { BillingLayout, CreateSubscription, EditSubscription } from 'src/containers/Billing/_outdated';
import { TeamManage } from 'src/containers/Team';
import { TeamUserRole } from 'src/enums';
import { useAppSelector, useCompanyTheme } from 'src/hooks';
import { useGetBillingInfo, useGetTeamInvites, useGetTeamMembers, useLeaveTeam } from 'src/reactQueries';

export const DeactivatedApp = () => {
  const { shortCompanyName } = useCompanyTheme();
  const { data: teamData } = useAppSelector((state) => state.team);

  const { billingInfo } = useGetBillingInfo();
  const { leaveTeam, isLoading } = useLeaveTeam();

  const { teamMembers } = useGetTeamMembers();
  const { teamInvites = [] } = useGetTeamInvites();

  const showManageTeam = useMemo(() => {
    return (teamMembers?.total || 1) + (teamInvites?.length || 0) > 1;
  }, [teamMembers, teamInvites]);

  const subscriptionType = billingInfo?.stripeTokenObject ? <EditSubscription /> : <CreateSubscription />;

  return (
    <>
      <BillingLayout hideActions />

      {teamData?.teamUser.role === TeamUserRole.OWNER ? (
        <>
          <Box pb="80px">
            {subscriptionType}

            {showManageTeam && (
              <Box bgcolor="#fff" mx="15px" borderRadius="4px" overflow="hidden" p="20px 90px 100px" mt="20px">
                <TeamManage />
              </Box>
            )}
          </Box>
        </>
      ) : (
        <Box display="flex" flexDirection="column" alignItems="center" mt="50px">
          <Typography color="#A0A0A0" mb="40px">
            Your subscription period is over. Please contact your team owner in order to continue using{' '}
            {shortCompanyName}.
          </Typography>
          <Button processing={isLoading} onClick={() => leaveTeam()}>
            Leave team
          </Button>
        </Box>
      )}
    </>
  );
};
