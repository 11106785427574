import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { Spinner, Table, TableBody, TableHead, TableHeadCell, TablePagination, TableRow } from 'src/components';
import { CreatableSearch } from 'src/components/TextField/CreatableSearch';
import { DEFAULT_PER_PAGE } from 'src/constants';
import { useGetTemplatesTeam } from 'src/reactQueries';
import { ISelectOption, ITablePagination, TSortOrder, TTemplatesSortField } from 'src/types';
import { TemplateLayout } from '../TemplateLayout';
import { TemplatesTeamRow } from './TemplatesTeamRow';

export const TemplatesTeam = () => {
  const [searchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState<string[]>([]);
  const [sortField, setSortField] = useState<TTemplatesSortField>('name');
  const [sortOrder, setSortOrder] = useState<TSortOrder>('ASC');
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [page, setPage] = useState(1);

  const categoryId = searchParams.get('category');

  useEffect(() => {
    setPage(1);
    setPerPage(DEFAULT_PER_PAGE);
  }, [categoryId]);

  const { data, isLoading } = useGetTemplatesTeam({
    categoryId: categoryId,
    search: searchValue,
    page,
    perPage,
    sortField,
    sortOrder,
  });
  const { templates = [], total = 0, categories = [] } = data ?? {};

  const changeSearchHandler = (options: ISelectOption[]) => {
    setSearchValue(options.map(({ label }) => label));
    setPage(1);
  };

  const onSortByField = (field: string, order: string) => {
    setSortField(field as TTemplatesSortField);
    setSortOrder(order as 'DESC' | 'ASC');
  };

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  return (
    <TemplateLayout
      route="team"
      title="Team Templates"
      description="Save time by sharing your best messaging templates with your team."
      guideLink="https://meetalfred.com/help/en/articles/5276865-create-and-manage-templates-in-alfred"
      videoLink="https://player.vimeo.com/video/871148185"
      categories={categories}
    >
      <Box px="15px" pt="20px" pb="80px">
        <Box mb="20px">
          <CreatableSearch placeholder="Search Templates" onChange={changeSearchHandler} />
        </Box>
        <Table processing={isLoading} processingContent={<Spinner type="dots" />}>
          <TableHead>
            <TableRow>
              <TableHeadCell
                name="name"
                sorting={{
                  sortOrder,
                  sortField,
                  onSortFieldChange: onSortByField,
                }}
                width="22%"
                justifyContent="flex-start"
              >
                Name
              </TableHeadCell>
              <TableHeadCell width="70%">Message</TableHeadCell>
              <TableHeadCell sticky width="100px" />
            </TableRow>
          </TableHead>

          <TableBody placeholder={!total && 'No Team Templates'}>
            {templates.map((template) => {
              const categoryLabel = categories.find(({ id }) => id === template.categoryId)?.name ?? '';

              return <TemplatesTeamRow categoryLabel={categoryLabel} key={template.id} {...template} />;
            })}
          </TableBody>
        </Table>

        <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={paginationChangeHandler} />
      </Box>{' '}
    </TemplateLayout>
  );
};
