import { Box } from '@mui/material';

import { Button, Typography } from 'src/components';
import { useAppSelector, useAuth } from 'src/hooks';

export const DeactivatedWhiteLabel = () => {
  const { logout } = useAuth();
  const { isAuth } = useAppSelector(({ user }) => user);

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography color="gray.main" mt="40px" textAlign="center">
        Your app is deactivated. Please contact your team owner in order to continue using app.
      </Typography>
      {isAuth && (
        <Box mt="20px">
          <Button onClick={logout}>Back to login page</Button>
        </Box>
      )}
    </Box>
  );
};
