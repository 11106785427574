import { MouseEvent, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { ActionButton, Link, Spinner, TableCell, TableRow, Tooltip, Typography } from 'src/components';
import { CampaignCategory } from 'src/enums';
import { useDeleteCampaign } from 'src/reactQueries';
import { IGetCampaigns } from 'src/types';

const StyledMenuItem = styled(MenuItem)`
  cursor: pointer;
  padding: 10px;
  color: ${({ theme }) => theme.palette.primary.wlLight};
  line-height: 1.42857143;
  font-size: 14px;
  font-family: 'ProximaSoft', sans-serif;
  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: #fff;
  }
  &:focus {
    background-color: #fff;
  }
  &.disabled {
    cursor: default;
  }
`;

interface ICampaignsDraftListItemProps {
  campaign: IGetCampaigns;
  category: CampaignCategory;
  isInactive: boolean;
  inactiveLabel: string;
}

export const CampaignsDraftListItem = ({
  campaign,
  category,
  isInactive,
  inactiveLabel,
}: ICampaignsDraftListItemProps) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const { id, name } = campaign;

  const isTwitterCategory = category === CampaignCategory.TWITTER;

  const type = useMemo(() => {
    switch (category) {
      case CampaignCategory.EMAIL:
        return 'email';
      case CampaignCategory.TWITTER:
        return 'twitter';
      default:
        return 'linkedin';
    }
  }, [category]);

  const { deleteCampaign } = useDeleteCampaign({ campaignId: id, category });

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditCampaign = () => {
    handleClose();
    navigate(`/campaign/${id}/sequence`, { state: { isEdit: true } });
  };

  const handleDeleteCampaign = () => {
    deleteCampaign();
  };

  return (
    <>
      <TableRow hoverBgColor={({ light }) => light.main}>
        <TableCell>
          <Link variant="darkBlue" fontSize="15px" crop pointer to={`/campaign/details/overview/${id}`} maxWidth="90%">
            {name || `Campaign draft (${id})`}
          </Link>
        </TableCell>

        <TableCell contentStyle={{ display: 'flex', width: '100%' }}>
          {isInactive && type !== 'linkedin' && (
            <Typography color="error.main" mx="auto" fontSize="15px">
              <Spinner type="dots" size="14px" color="text.primary" inline />
              &nbsp;&nbsp;
              <Link variant="error" underline to={`/integrations/${type}`}>
                Click here
              </Link>
              &nbsp;to connect your {inactiveLabel} account to run this campaign
            </Typography>
          )}
        </TableCell>

        <TableCell>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <ActionButton
              tooltip="Click here to view options"
              aria-haspopup="true"
              {...(isMenuOpen && {
                'aria-controls': 'basic-menu',
                'aria-expanded': 'true',
              })}
              onClick={handleClick}
              icon={faEllipsisH}
            />

            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              PaperProps={{
                sx: {
                  overflow: 'visible',
                  fontSize: '14px',
                  borderRadius: '4px',
                  boxShadow: '0 6px 12px rgba(0,0,0,0.175)',
                  backgroundClip: 'padding-box',
                  padding: '10px',
                  color: palette.primary.wlLight,
                  width: '140px',
                },
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Tooltip title={isTwitterCategory ? 'Feature under Maintenance' : ''}>
                <span>
                  <StyledMenuItem onClick={handleEditCampaign} disabled={isTwitterCategory}>
                    Edit Sequence
                  </StyledMenuItem>
                </span>
              </Tooltip>
              <StyledMenuItem onClick={handleDeleteCampaign}>Delete</StyledMenuItem>
            </Menu>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
