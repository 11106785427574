import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';

import { useCompanyTheme } from 'src/hooks';
import { useGetRealUser, useGetUserAccount, usePostStopImpersonateUser } from 'src/reactQueries';

const Wrapper = styled(Box)`
  max-width: 100vw;
  height: 30px;
  line-height: 20px;
  padding: 15px;
  background-color: #ffe8a6;
  color: #b48e23;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled.button`
  display: inline-flex;
  align-items: baseline;
  justify-content: center;
  width: 100px;
  height: 30px;
  padding: 5px;
  cursor: pointer;
  background-color: #b48e23;
  color: #ffe8a6;
  border-radius: 4px;
  margin-left: 10px;
`;

export const ImpersonateBar = () => {
  const { shortCompanyName } = useCompanyTheme();
  const { realImpersonateUser } = useGetRealUser();
  const { userMe } = useGetUserAccount();
  const { stopImpersonateUser } = usePostStopImpersonateUser();

  if (!realImpersonateUser?.id) {
    return null;
  }

  return (
    <Wrapper>
      You are managing {shortCompanyName} for {userMe?.name}. To exit:{' '}
      <StyledButton onClick={() => stopImpersonateUser()}>
        <Typography fontSize="15px">click here</Typography>
      </StyledButton>
    </Wrapper>
  );
};
