import { isEmpty } from 'lodash';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateLead } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { IConnectionsResponse, ICustomAxiosError, IGetLeadsResponse, ILeadInfo, ILeadsResponse } from 'src/types';

export const useUpdateLead = (
  entityUrn: string,
  options?: UseMutationOptions<void, ICustomAxiosError, { isExcluded: boolean }>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const queryKey = ['post-leads'];

  const { mutate, ...rest } = useMutation(
    ['update-lead', entityUrn],
    (body: { isExcluded: boolean }) => updateLead({ entityUrn, body }),
    {
      ...options,
      onMutate: async (variables) => {
        options?.onMutate?.(variables);
        await queryClient.cancelQueries(queryKey);
        await queryClient.cancelQueries(['post-connections']);
        await queryClient.cancelQueries(['get-campaign-leads']);
        await queryClient.cancelQueries(['get-lead-info', entityUrn]);

        const prevData = queryClient.getQueriesData(queryKey);
        const prevConnectionsData = queryClient.getQueriesData(['post-connections']);
        const prevCampaignLeadsData = queryClient.getQueriesData(['get-campaign-leads']);
        const prevLeadData = queryClient.getQueryData(['get-lead-info', entityUrn]);

        if (prevData) {
          queryClient.setQueriesData<ILeadsResponse | undefined>(queryKey, (data) => {
            if (data) {
              return {
                ...data,
                leads: data?.leads?.map((lead) =>
                  lead.person?.key === entityUrn
                    ? {
                        ...lead,
                        isExcluded: variables.isExcluded,
                      }
                    : lead,
                ),
              };
            }

            return data;
          });
        }

        if (prevConnectionsData) {
          queryClient.setQueriesData<IConnectionsResponse | undefined>(['post-connections'], (data) => {
            return (
              data && {
                ...data,
                connections: data.connections.map((connection) =>
                  connection.entityUrn === entityUrn
                    ? {
                        ...connection,
                        isExcluded: variables.isExcluded,
                        userNetwork: isEmpty(connection.userNetwork)
                          ? connection.userNetwork
                          : {
                              ...connection.userNetwork,
                              isExcluded: variables.isExcluded,
                            },
                      }
                    : connection,
                ),
              }
            );
          });
        }

        if (prevCampaignLeadsData) {
          queryClient.setQueriesData<IGetLeadsResponse | undefined>(['get-campaign-leads'], (data) => {
            return (
              data && {
                ...data,
                leads: data?.leads?.map((lead) =>
                  lead.entityUrn === entityUrn
                    ? {
                        ...lead,
                        isExcluded: variables.isExcluded,
                      }
                    : lead,
                ),
              }
            );
          });
        }

        if (prevLeadData) {
          queryClient.setQueryData<ILeadInfo | undefined>(['get-lead-info', entityUrn], (data) => {
            return (
              data && {
                ...data,
                lead: { ...data.lead, isExcluded: variables.isExcluded },
                userNetwork: isEmpty(data.userNetwork)
                  ? data.userNetwork
                  : { ...data.userNetwork, isExcluded: variables.isExcluded },
              }
            );
          });
        }

        return { prevData, prevLeadData, prevConnectionsData, prevCampaignLeadsData };
      },
      onSuccess: (data, vars, context) => {
        options?.onSuccess?.(data, vars, context);

        queryClient.invalidateQueries(['get-lead-info', entityUrn]);
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);

        if (context?.prevData) {
          queryClient.setQueriesData([queryKey], context?.prevData);
        }

        if (context?.prevConnectionsData) {
          queryClient.setQueriesData(['post-connections'], context?.prevConnectionsData);
        }

        if (context?.prevCampaignLeadsData) {
          queryClient.setQueriesData(['get-campaign-leads'], context?.prevCampaignLeadsData);
        }

        if (context?.prevLeadData) {
          queryClient.setQueriesData(['get-lead-info', entityUrn], context?.prevLeadData);
        }

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { updateLead: mutate, ...rest };
};
