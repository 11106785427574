import { Dispatch, SetStateAction } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { PersonAvatar, Typography } from 'src/components';
import { getPostedTime } from 'src/helpers';
import { ITrendPost } from 'src/types';

const Post = styled(Box)<{ selected?: boolean }>`
  padding: 10px;
  transition: 0.2s;
  background-color: ${(props) => (props.selected ? '#dbdbde' : '#ffffff')};
  cursor: pointer;

  &:not(:last-of-type) {
    border-bottom: 1px solid #f0f0f0;
  }

  &:hover {
    background-color: #dbdbde;
  }
`;

interface IPostListProps {
  posts: ITrendPost[] | undefined;
  selectedPost: number | undefined;
  setSelectedPost: Dispatch<SetStateAction<number | undefined>>;
}

export const PostsList = ({ posts, selectedPost, setSelectedPost }: IPostListProps) => {
  const { palette } = useTheme();

  if (!posts?.length) {
    return <Box data-testid="post-placeholder" width="300px" />;
  }

  return (
    <Box
      data-testid="posts-list"
      maxHeight="690px"
      width="450px"
      overflow="auto"
      boxShadow={`0 0 5px 0 ${palette.light.dark}`}
    >
      {posts.map((post) => (
        <Post
          data-testid={`post-${post.id}`}
          key={post.id}
          display="flex"
          selected={post.id === selectedPost}
          onClick={() => setSelectedPost(post.id)}
        >
          <PersonAvatar src={post.profilePic} width="48px" height="48px" />

          <Box width="370px" ml="10px" display="flex" flexDirection="column" justifyContent="space-around">
            <Box display="flex" alignItems="center">
              <Typography
                color="gray.dark"
                width="100px"
                whiteSpace="pre"
                textOverflow="ellipsis"
                overflow="hidden"
                semibold
              >
                {post.title}
              </Typography>
              <Typography color="gray.dark" ml="3px">
                {post.badgeText}
              </Typography>
              <Typography fontSize="12px" color="#828282" ml="auto">
                {getPostedTime(post.secondarySubtitle)}
              </Typography>
            </Box>

            <Box>
              <Typography fontSize="14px" color="gray.dark" whiteSpace="pre" textOverflow="ellipsis" overflow="hidden">
                {post.primarySubtitle}
              </Typography>
            </Box>
          </Box>
        </Post>
      ))}
    </Box>
  );
};
