import { PropsWithChildren, ReactNode, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  HeaderButton,
  HeaderContainer,
  HeaderDescription,
  HeaderTabLink,
  HeaderTitle,
  HeaderVideo,
  Link,
  SelectBasicGray,
  TabsContainer,
} from 'src/components';
import { CAMPAIGN_CATEGORIES, PLAN_TOOLTIPS } from 'src/constants';
import { CampaignCategory, Features, TemplateAvailability } from 'src/enums';
import { useTeamPlan, useWhiteLabel } from 'src/hooks';
import { useGetSequenceTemplatesCategories } from 'src/reactQueries';
import { ISelectOption } from 'src/types';

interface SequenceTemplatesLayoutProps extends PropsWithChildren {
  title: string;
  route: string;
  description: ReactNode;
  guideLink?: string;
  videoLink?: string;
  availability: TemplateAvailability;
  search?: Array<string>;
}

const CAMPAIGN_CATEGORIES_OPTIONS = [{ name: 'All', category: '' }, ...CAMPAIGN_CATEGORIES] as Array<{
  name: string;
  category: string;
}>;

export const SequenceTemplatesLayout = ({
  title,
  route,
  description,
  children,
  guideLink,
  videoLink,
  availability,
  search,
}: SequenceTemplatesLayoutProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { whitelabel } = useWhiteLabel();
  const { checkFeature } = useTeamPlan();

  const { data, isLoading } = useGetSequenceTemplatesCategories(
    { availability, search },
    {
      select: (data) => {
        return { ...data, ['']: Object.values(data).reduce((a, b) => a + b, 0) };
      },
    },
  );

  const TABS = useMemo(
    () => [
      { name: 'Personal', route: 'personal' },
      {
        name: 'Team',
        route: 'team',
        disabled: !checkFeature(Features.sequenceTemplates),
        disabledTooltip: PLAN_TOOLTIPS.professional,
      },
    ],
    [],
  );

  const onCreateTemplateClick = () => {
    navigate('/sequence-templates/new');
  };

  const onCategoryChange = ({ value }: ISelectOption) => {
    navigate(`/sequence-templates/${route}${value ? `?category=${value}` : ''}`);
  };

  return (
    <>
      <HeaderContainer>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <HeaderTitle>{title}</HeaderTitle>
          <Box alignItems="center" boxSizing="border-box" display="flex" padding="0 15px">
            <HeaderButton onClick={onCreateTemplateClick}>Create Template</HeaderButton>
          </Box>
        </Box>
        <HeaderDescription>
          {description}
          {whitelabel.isWhiteLabelUser || (
            <>
              {guideLink && (
                <Link leftIcon underline external openNewTab to={guideLink}>
                  Learn how it works
                </Link>
              )}

              {videoLink && <HeaderVideo src={videoLink} />}
            </>
          )}
        </HeaderDescription>
        <Box display="flex" width="100%">
          <TabsContainer>
            {TABS.map(({ name, route, disabled, disabledTooltip }) => {
              const to = `/sequence-templates/${route}`;

              return (
                <HeaderTabLink key={name} to={to} disabled={disabled} tooltip={disabled && disabledTooltip}>
                  {name}
                </HeaderTabLink>
              );
            })}
          </TabsContainer>
          <Box display="flex" margin="0 0 0 auto">
            <SelectBasicGray
              label="Category:"
              value={String(searchParams.get('category') || '')}
              onChange={onCategoryChange}
              options={CAMPAIGN_CATEGORIES_OPTIONS.map((category) => ({
                value: String(category.category),
                label: `${category.name} ${
                  !isLoading && data?.[category.category as CampaignCategory]
                    ? `(${data?.[category.category as CampaignCategory]})`
                    : '(0)'
                }`,
              }))}
              minWidth="230px"
              valueContainerPosition="space-between"
            />
          </Box>
        </Box>
      </HeaderContainer>
      {children}
    </>
  );
};
