import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getCampaignActivity } from 'src/api';
import { IGetCampaignActivity, IGetCampaignActivityRequest } from 'src/types';

export const useGetCampaignActivity = <TData = IGetCampaignActivity>(
  data: IGetCampaignActivityRequest,
  options?: UseQueryOptions<IGetCampaignActivity, AxiosError, TData>,
) => {
  return useQuery<IGetCampaignActivity, AxiosError, TData>(
    ['get-campaign-activity', JSON.stringify(data)],
    () => getCampaignActivity(data),
    options,
  );
};
