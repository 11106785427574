import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { CreatableSearch, MultiSelect, Typography } from 'src/components';
import { CampaignActions } from 'src/enums';
import { useAppSelector, useCompanyTheme } from 'src/hooks';
import { useGetCampaignsSearchList } from 'src/reactQueries';
import { updateActivityParams } from 'src/store/campaign.slice';
import { IGetCampaignActivityRequest } from 'src/types';

export const CampaignsSearchBar = () => {
  const dispatch = useDispatch();
  const { shortCompanyName } = useCompanyTheme();
  const { activityParams } = useAppSelector(({ campaign }) => campaign);
  const { campaigns, filters, names } = activityParams;

  const { data = [] } = useGetCampaignsSearchList({}, { refetchOnMount: true });

  const CAMPAIGN_ACTIONS_LIST: Record<CampaignActions, string> = {
    [CampaignActions.ALFRED_DESKTOP_APP_ACTION]: `${shortCompanyName} action`,
    [CampaignActions.ALREADY_CONNECTED]: 'Already connected',
    [CampaignActions.ALREADY_INVITED]: 'Already invited',
    [CampaignActions.CONNECTED]: 'Connected',
    [CampaignActions.EMAIL_BOUNCED]: 'Email bounced',
    [CampaignActions.EMAIL_OPENED]: 'Email opened',
    [CampaignActions.EMAIL_REPLY_DETECTED]: 'Email replies',
    [CampaignActions.EMAILED]: 'Emailed',
    [CampaignActions.EVENT_MESSAGE_SENT]: 'Event Messages',
    [CampaignActions.FOUND]: 'Found',
    [CampaignActions.GREETINGS]: 'Greetings',
    [CampaignActions.GROUP_MESSAGE_SENT]: 'Group Messages',
    [CampaignActions.INMAIL_REPLY_DETECTED]: 'InMail replies',
    [CampaignActions.INMAIL_SKIPPED]: 'InMail Skipped',
    [CampaignActions.INMAIL_MESSAGE_SENT]: 'InMails',
    [CampaignActions.INVITE_WITHDRAWN]: 'Invites withdrawn',
    [CampaignActions.REQUESTED_CONNECT]: 'Invites',
    [CampaignActions.FOLLOWED_UP_AFTER_CONNECT]: 'Invites followed up',
    [CampaignActions.LEAD_EXCLUDED]: 'Lead Excluded/Included',
    [CampaignActions.FOLLOWED_A_PROFILE]: 'Linkedin profiles followed',
    [CampaignActions.MESSAGE_POSTPONED]: 'Message postponed',
    [CampaignActions.MESSAGE_SENT]: 'Messages',
    [CampaignActions.NO_EMAIL_FOUND]: 'No Email Found',
    [CampaignActions.LINKEDIN_REPLY_DETECTED]: 'Replies',
    [CampaignActions.RETURNED_TO_CAMPAIGN]: 'Returned to campaign',
    [CampaignActions.LEAD_POSTPONED]: 'Unable to resend invitation',
    [CampaignActions.UNABLE_TO_SEND_INMAIL]: 'Unable to send InMail message',
    [CampaignActions.VIEWED]: 'Viewed',
    [CampaignActions.TWITTER_MESSAGE_SENT]: 'X (Twitter) DMs',
    [CampaignActions.LEAD_FOLLOWED_IN_TWITTER]: 'X (Twitter) Follows',
    [CampaignActions.TWITTER_REPLY_DETECTED]: 'X (Twitter) replies',
  };

  const campaignOptions = data?.map(({ id, name }) => ({ label: name, value: String(id) }));
  const defaultNames = names?.map((value) => ({ value, label: value }));
  const actionOptions = Object.entries(CAMPAIGN_ACTIONS_LIST).map(([value, label]) => ({
    label,
    value,
  }));

  const updateParams = (params: Partial<IGetCampaignActivityRequest>) => dispatch(updateActivityParams(params));

  return (
    <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="30px" m="10px 15px 20px">
      <Box>
        <Typography color="text.primary" lineHeight="40px">
          Search By Lead Name
        </Typography>
        <CreatableSearch
          placeholder="Search for Leads"
          defaultValue={defaultNames}
          noResultPlaceholder={<Typography color="#88898E">Type and hit Return</Typography>}
          onChange={(options) => updateParams({ names: options.map(({ value }) => value) })}
        />
      </Box>
      <Box>
        <Typography color="text.primary" lineHeight="40px">
          Select Actions
        </Typography>
        <MultiSelect
          placeholder="Select actions"
          options={actionOptions}
          initialValues={filters}
          onChange={(options) => updateParams({ filters: options.map(({ value }) => value) })}
        />
      </Box>
      <Box>
        <Typography color="text.primary" lineHeight="40px">
          Select Campaigns
        </Typography>
        <MultiSelect
          placeholder="Select campaigns"
          options={campaignOptions}
          initialValues={campaigns}
          onChange={(options) => updateParams({ campaigns: options.map(({ value }) => value) })}
        />
      </Box>
    </Box>
  );
};
