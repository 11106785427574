import { darken, getContrastRatio, lighten } from '@mui/material';

import { getRandomNotSecuredIntNumber } from 'src/helpers';
import { IWhiteLabelTheme } from 'src/types';

const componentToHex = (c: number) => {
  const hex = c.toString(16).trim();

  return hex.length === 1 ? `0${hex}` : hex;
};

function rgbToHex(rgbString: string) {
  const [r, g, b] = rgbString
    .replace('rgb(', '')
    .replace(')', '')
    .split(',')
    .map((n) => Number(n));

  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
}

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 50, g: 50, b: 50 };
}

export const getRandomHexColor = () => {
  const randomHex = `000000${getRandomNotSecuredIntNumber(16777215).toString(16)}`.slice(-6);
  let { r, g, b } = hexToRgb(randomHex);
  r = r >= 245 ? r - 5 : r;
  g = g >= 245 ? g - 5 : g;
  b = b >= 245 ? b - 5 : b;

  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
};

export const getContrastYIQ = (hex = '#eaebef') => {
  hex = hex.replace('#', '');

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  /* Conversion from RGB to YIQ. In YIQ the Y component represents the luma information, I and Q represent the chrominance information. */
  const y = 0.299 * r + 0.587 * g + 0.114 * b;

  return y >= 186 ? '#000000' : '#ffffff';
};

export const getContrastTextColor = (bgColor: string) => {
  return getContrastRatio(bgColor, darken(bgColor, 0.6)) > 3 ? darken(bgColor, 0.6) : lighten(bgColor, 0.6);
};

export const transformRGBChannel = (
  hex: string,
  { red, green, blue }: { red: number; green: number; blue: number },
  preventIfLight = false,
) => {
  hex = hex?.replace('#', '');

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  if (preventIfLight) {
    if (r + red > 255 || g + green > 255 || b + blue > 255) {
      return '';
    }
  }

  return `rgb(${r + red}, ${g + green}, ${b + blue})`;
};

export const getBaseThemePalette = (theme?: IWhiteLabelTheme) => {
  return {
    primary: {
      light: '#102e50',
      wlLight: theme?.titleColor || '#102e50',
      main: theme?.secondaryColor || '#2361fc',
      dark: theme?.menuFontColor || '#002f53',
    },

    secondary: {
      main: theme?.accentColor || '#53a25d',
      dark: rgbToHex(darken(theme?.accentColor || '#53a25d', 0.1)),
    },

    success: {
      main: '#00b67a',

      100: '#d9f4e1',
      200: '#b3e8c3',
      500: '#31a554',
      600: '#216e38',
      700: '#18532a',
      800: '#10371c',
    },

    info: {
      main: '#00b2e4',
      dark: '#337ab7',
    },

    warning: {
      main: '#ffc727',

      50: '#fffaeb',
      200: '#fedf89',
    },

    error: {
      light: '#f62713',
      main: '#ff0000',

      100: '#fae0e0',
      200: '#f1a3a3',
      300: '#e76666',
      400: '#e34848',
      500: '#de2929',
      600: '#c71f1f',
      700: '#8e1616',
    },

    text: {
      primary: '#333333',
    },

    light: {
      light: '#f3f4f5',
      main: '#ebecf2',
      dark: '#dfdfe8',
    },

    lightGray: {
      light: '#d2dee0',
      main: '#c4c6d5',
      dark: '#8da1b5',
    },

    gray: {
      light: '#808080',
      main: '#98a9ac',
      dark: '#313c40',

      50: '#f2f3f7',
      100: '#e6e8ed',
      200: '#c1c7d3',
      300: '#a3aab8',
      400: '#868FA3',
      500: '#667085',
      600: '#555d6f',
      700: '#444B59',
      800: '#22252c',
    },

    violet: {
      main: '#8c8eb8',
      dark: '#8283a2',
    },

    accent: {
      100: '#dce4f9',
      300: '#809ee9',
      500: theme?.secondaryColor || '#2b5ddb',
      600: theme?.secondaryColor ? rgbToHex(darken(theme?.secondaryColor, 0.1)) : '#1e49b3',
      700: '#173786',
    },

    brand: {
      100: '#9cc1eb',
      200: '#6ba3e1',
      300: '#2467b3',
      400: '#1a4a81',
      500: theme?.menuFontColor || '#102e50',
    },

    orange: {
      50: '#fef6ee',
      200: '#f9dbaf',
      700: '#b93815',
    },

    purple: {
      50: '#f4f3ff',
      200: '#d9d6fe',
      700: '#5925dc',
    },

    blueLight: {
      50: '#f0f9ff',
      200: '#b9e6fe',
      700: '#026aa2',
    },

    black: {
      base: '#07090E',
    },

    notification: {
      errorBg: theme?.notificationErrorColor || '#ffbdad',
      errorText:
        (theme?.notificationErrorColor &&
          theme.notificationErrorColor !== '#ffbdad' &&
          getContrastTextColor(theme.notificationErrorColor)) ||
        '#673238',

      warningBg: theme?.notificationWarningColor || '#fff1b4',
      warningText:
        (theme?.notificationWarningColor &&
          theme.notificationWarningColor !== '#fff1b4' &&
          getContrastTextColor(theme.notificationWarningColor)) ||
        '#474432',
    },
  };
};
