import { isUndefined } from 'lodash';
import { Box } from '@mui/material';

import { CampaignActions } from 'src/enums';
import { useGetCampaignsStatistics } from 'src/reactQueries';
import { TIntervalFilter } from 'src/types';
import { CountCard } from './_components/CountCard';

interface ActionsStatsProps {
  customToDate: string;
  customFromDate: string;
  timeFilter?: TIntervalFilter;
}

export const ActionsStats = ({ customToDate, customFromDate, timeFilter }: ActionsStatsProps) => {
  const { data } = useGetCampaignsStatistics({ customToDate, customFromDate, timeFilter }, { refetchOnMount: true });

  const repliesCount = isUndefined(data?.repliesPercent) ? '' : `${data?.repliesPercent ?? 0}%`;
  const acceptedCount = isUndefined(data?.invitesAcceptedPercent) ? '' : `${data?.invitesAcceptedPercent ?? 0}%`;

  return (
    <Box display="flex" gap="20px">
      <CountCard
        to="/campaign/activity"
        actions={[CampaignActions.REQUESTED_CONNECT]}
        count={data?.invitesSent}
        description="Invites Sent"
      />
      <CountCard
        to="/campaign/activity"
        actions={[CampaignActions.CONNECTED]}
        count={data?.invitesAccepted}
        description={`${acceptedCount} Accepted`}
      />
      <CountCard
        to="/campaign/activity"
        actions={[
          CampaignActions.MESSAGE_SENT,
          CampaignActions.FOLLOWED_UP_AFTER_CONNECT,
          CampaignActions.INMAIL_MESSAGE_SENT,
          CampaignActions.EMAILED,
          CampaignActions.TWITTER_MESSAGE_SENT,
          CampaignActions.EVENT_MESSAGE_SENT,
          CampaignActions.GROUP_MESSAGE_SENT,
        ]}
        count={data?.messages}
        description="Messages"
      />
      <CountCard
        to="/campaign/activity"
        actions={[
          CampaignActions.EMAIL_REPLY_DETECTED,
          CampaignActions.LINKEDIN_REPLY_DETECTED,
          CampaignActions.INMAIL_REPLY_DETECTED,
          CampaignActions.TWITTER_REPLY_DETECTED,
        ]}
        count={data?.replies}
        data-testid="replies"
        description={`${repliesCount} Replies`}
      />
      <CountCard
        to="/campaign/activity"
        actions={[CampaignActions.VIEWED]}
        count={data?.profileViews}
        data-testid="profileViews"
        description="Profile Views"
      />
      <CountCard
        to="/campaign/activity"
        actions={[CampaignActions.GREETINGS]}
        count={data?.greetings}
        description="Greetings"
      />
    </Box>
  );
};
