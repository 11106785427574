import { KeyboardEvent, UIEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Input, PersonAvatar, Spinner, Typography } from 'src/components';
import { useAppSelector, useDebounce } from 'src/hooks';
import { useGetRealUser, useGetSNConversations } from 'src/reactQueries';
import { setQuery, setSelectedConversation } from 'src/store/conversation.slice';

const Wrapper = styled(Box)`
  padding: 10px 0 50px;
  width: 25%;
  border-right: 1px solid ${({ theme }) => theme.palette.light.light};
  min-height: 400px;
  flex-shrink: 0;
`;

const StyledInput = styled(Input)`
  margin-top: 0;
  height: 32px;
`;

const Conversation = styled(Box)<{ selected?: boolean }>`
  cursor: pointer;
  border-radius: 5px;
  ${({ selected, theme }) => selected && `background-color: ${theme.palette.light.light} !important`};

  transition: 0.2s;

  &:hover {
    background-color: #f7f9ff;
  }
`;

const UnreadBlock = styled(Box)`
  width: 18px;
  height: 18px;
  background-color: red;
  border-radius: 50px;
  color: #ffffff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

const SCROLL_LIST_LENGTH = 6;

export const SNConversationsList = () => {
  const dispatch = useDispatch();
  const { realImpersonateUser } = useGetRealUser();

  const { isImpersonate } = useAppSelector((state) => state.user);
  const { selectedConversation, query } = useAppSelector((state) => state.conversation);

  const [queryValue, setQueryValue] = useState<string>(query);
  const debouncedSearch = useDebounce(queryValue, 500);

  const { conversations, isLoading, isFetchingNextPage, fetchNextPage } = useGetSNConversations({
    query,
  });

  useEffect(() => {
    if (selectedConversation) return;

    if (!isImpersonate || (isImpersonate && !realImpersonateUser?.isAdminImpersonate)) {
      if (conversations.length) {
        dispatch(setSelectedConversation(conversations[0]));
      }
    }
  }, [conversations.length, selectedConversation?.conversationId]);

  useEffect(() => {
    dispatch(setQuery(debouncedSearch));
  }, [debouncedSearch]);

  const handler = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      dispatch(setQuery(e.currentTarget.value));
    }
  };

  const clearQuery = () => {
    setQueryValue('');
    dispatch(setQuery(''));
  };

  const handleScrollPagination = async (e: UIEvent<HTMLDivElement>) => {
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.offsetHeight < 30) {
      if (!isFetchingNextPage) {
        fetchNextPage().catch(console.error);
      }
    }
  };

  return (
    <Wrapper>
      <Box display="flex" mb="10px" px="10px">
        <StyledInput
          type="text"
          placeholder="Search Sales Navigator messages"
          name="search"
          value={queryValue}
          onChange={(e) => setQueryValue(e.target.value)}
          onKeyUp={handler}
          removeAllAction={!!queryValue && clearQuery}
        />
      </Box>

      {!conversations.length ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%" px="20px">
          {isLoading ? <Spinner /> : <Typography>We couldn’t find any conversations with this filter.</Typography>}
        </Box>
      ) : (
        <Box height="calc(100% - 70px)" overflow="auto" px="10px" onScroll={handleScrollPagination}>
          {conversations.map((conversation) => {
            const { imageUrl, name, content, unreadCount, isConnected, conversationId } = conversation;

            return (
              <Conversation
                key={conversationId}
                display="flex"
                alignItems="center"
                py="6px"
                px="10px"
                selected={conversationId === selectedConversation?.conversationId}
                onClick={() => dispatch(setSelectedConversation(conversation))}
              >
                <PersonAvatar src={imageUrl} width="40px" height="40px" />

                <Box py="4px" ml="10px" mr="auto" flexGrow="1" width="calc(100% - 70px)">
                  <Typography color="rgba(0,0,0,.9)">
                    {name}&nbsp;
                    {!isConnected && (
                      <Typography component="span" color="gray.light">
                        (Haven't connected)
                      </Typography>
                    )}
                  </Typography>
                  <Typography color="gray.light" crop>
                    {content}
                  </Typography>
                </Box>

                {!!unreadCount && <UnreadBlock>{unreadCount}</UnreadBlock>}
              </Conversation>
            );
          })}
        </Box>
      )}

      {conversations.length > SCROLL_LIST_LENGTH && (
        <Box display="flex" pt="10px" justifyContent="center" alignItems="center">
          <FontAwesomeIcon icon={faAngleDoubleDown} />
          <Typography ml="10px">Scroll down to see more</Typography>
        </Box>
      )}
    </Wrapper>
  );
};
