import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { toggleWhiteLabelStatus } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IWhiteLabel } from 'src/types';

export const useToggleWhiteLabelStatus = (options?: UseMutationOptions<IWhiteLabel, ICustomAxiosError>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const getWhiteLabelQueryKey = ['get-whitelabel'];

  const { mutate, ...rest } = useMutation(['toggle-whitelabel'], toggleWhiteLabelStatus, {
    ...options,
    onMutate: async (variables) => {
      options?.onMutate?.(variables);
      await queryClient.cancelQueries(getWhiteLabelQueryKey);

      const prevData = queryClient.getQueryData(getWhiteLabelQueryKey);

      queryClient.setQueryData<IWhiteLabel | undefined>(getWhiteLabelQueryKey, (data) => {
        return {
          ...((data || {}) as IWhiteLabel),
          status: data?.status === 'active' ? 'inactive' : 'active',
        };
      });

      return { prevData };
    },
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      await queryClient.refetchQueries(getWhiteLabelQueryKey);
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      queryClient.setQueryData(getWhiteLabelQueryKey, context?.prevData);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { toggleStatus: mutate, ...rest };
};
