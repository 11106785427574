import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { removeSequenceTemplateById } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError } from 'src/types';

export const useDeleteSequenceTemplate = (
  sequenceTemplateId: number,
  options?: UseMutationOptions<void, ICustomAxiosError>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const getSequenceTemplatesKey = ['get-sequence-templates'];

  const { mutate, ...rest } = useMutation(
    ['delete-sequence-template', sequenceTemplateId],
    () => removeSequenceTemplateById(sequenceTemplateId),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);

        await queryClient.invalidateQueries(getSequenceTemplatesKey, { exact: false });
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { deleteSequenceTemplate: mutate, ...rest };
};
