import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import { Box } from '@mui/material';

import { Button, Typography } from 'src/components';
import { CancelSubscriptionReason, ModalCancelSubscriptionSteps } from 'src/enums';
import { useCompanyTheme } from 'src/hooks';
import {
  useCancelSubscription,
  useCloseCancelModalTrigger,
  useGetBillingInfo,
  useGetIntercomInitialData,
} from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';
import {
  ChooseReason,
  ConfirmCancelling,
  DescribeIssue,
  DifficultyOfUse,
  Discount,
  OtherProduct,
  PauseSubscription,
} from './_components';

export const ModalCancelSubscription = () => {
  const dispatch = useDispatch();
  const { shortCompanyName } = useCompanyTheme();
  const [step, setStep] = useState<ModalCancelSubscriptionSteps | CancelSubscriptionReason>(
    ModalCancelSubscriptionSteps.INFO,
  );

  const { update: updateIntercomData } = useIntercom();
  const { data: intercomData } = useGetIntercomInitialData();

  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [showError, setShowError] = useState(false);

  const [selectedReason, setSelectedReason] = useState<CancelSubscriptionReason>();
  const [reasonValue, setReasonValue] = useState<string>('');

  const { billingInfo } = useGetBillingInfo();
  const { cancelSubscription, isCancelLoading } = useCancelSubscription();
  const { closeCancelModalTrigger, isCloseLoading } = useCloseCancelModalTrigger();

  const close = (includeTrigger = false) => {
    if (includeTrigger) {
      closeCancelModalTrigger('close cancel view');
    }

    dispatch(closeModal());
  };

  const onNeverMindClick = () => {
    updateIntercomData({
      userHash: intercomData?.intercomHash,
      customAttributes: {
        clickedNeverMind: true,
      },
    });
    close(true);
  };

  const defineNextStep = (): void => {
    if (showError) {
      return;
    }

    if (
      ([
        CancelSubscriptionReason.MISSING_FUNCTIONALITY,
        CancelSubscriptionReason.TECHNICAL_ISSUES,
        CancelSubscriptionReason.SOMETHING_ELSE,
      ].includes(step as CancelSubscriptionReason) &&
        reasonValue.trim().length < 30) ||
      (step === CancelSubscriptionReason.USING_OTHER_PRODUCT && !reasonValue.trim())
    ) {
      setShowError(true);

      return;
    }

    switch (step) {
      case ModalCancelSubscriptionSteps.INFO:
        setStep(ModalCancelSubscriptionSteps.CHOOSE_REASON);
        break;

      case CancelSubscriptionReason.COST:
      case CancelSubscriptionReason.MISSING_FUNCTIONALITY:
      case CancelSubscriptionReason.DIFFICULTY_OF_USE:
      case CancelSubscriptionReason.TECHNICAL_ISSUES:
      case CancelSubscriptionReason.USING_OTHER_PRODUCT:
      case CancelSubscriptionReason.SOMETHING_ELSE:
        setStep(ModalCancelSubscriptionSteps.CONFIRM_CANCEL);
        break;

      case ModalCancelSubscriptionSteps.CONFIRM_CANCEL:
        if (selectedReason) {
          cancelSubscription({ reason: selectedReason, value: reasonValue });
          close();
        } else {
          setStep(ModalCancelSubscriptionSteps.CHOOSE_REASON);
        }
        break;

      default:
        setStep(ModalCancelSubscriptionSteps.INFO);
    }
  };

  const renderBody = () => {
    switch (step) {
      case ModalCancelSubscriptionSteps.PAUSE:
        return <PauseSubscription type="cancel" />;

      case ModalCancelSubscriptionSteps.CHOOSE_REASON:
        return (
          <ChooseReason
            setReason={(reason: CancelSubscriptionReason) => {
              setSelectedReason(reason);
              setStep(reason);
            }}
          />
        );

      case CancelSubscriptionReason.COST:
        return (
          <Box py="30px" px="40px" bgcolor="light.light">
            <Discount
              showForFree
              header="Wait! We may have a better deal for you"
              subheader={`Did you know you can get ${shortCompanyName} for free? Or you can choose one of the following discounts starting on your billing cycle:`}
            />
          </Box>
        );

      case CancelSubscriptionReason.DIFFICULTY_OF_USE:
        return <DifficultyOfUse />;

      case CancelSubscriptionReason.USING_OTHER_PRODUCT:
        return <OtherProduct showError={showError} product={reasonValue} setProduct={setReasonValue} />;

      case CancelSubscriptionReason.MISSING_FUNCTIONALITY:
        return (
          <DescribeIssue
            reason={reasonValue}
            setReason={setReasonValue}
            showError={showError}
            header={`Wait! It’s very possible that ${shortCompanyName} already supports the feature you want`}
            subheader="Tell us what you are looking for and we’ll let you know if we have it or if it’s in our development roadmap."
            errorMessage={`Please let us know what functionality you would like to have on ${shortCompanyName} (At least 30 characters)`}
            placeholder="Please let us know the missing feature."
          />
        );

      case CancelSubscriptionReason.TECHNICAL_ISSUES:
        return (
          <DescribeIssue
            reason={reasonValue}
            setReason={setReasonValue}
            showError={showError}
            header="Apologies for the inconvenience. Could you tell us what went wrong?"
            errorMessage="Please tell us about the issue you are having (At least 30 characters)"
            placeholder="Please let us know your issue."
          />
        );

      case CancelSubscriptionReason.SOMETHING_ELSE:
        return (
          <DescribeIssue
            reason={reasonValue}
            setReason={setReasonValue}
            showError={showError}
            header="Please tell us about your reason for canceling."
            subheader="We will get in touch if we feel there's something we can do about it."
            errorMessage="Please tell us about your reason for canceling (At least 30 characters)"
          />
        );

      case ModalCancelSubscriptionSteps.CONFIRM_CANCEL:
        return <ConfirmCancelling setConfirmDisabled={setConfirmDisabled} />;

      default:
        return (
          <Box py="30px" px="40px" bgcolor="light.light">
            <Typography color="violet.dark">
              <strong>
                Deleting your {shortCompanyName} account will make it completely inaccessible at the end of your billing
                cycle
                {billingInfo?.stripeSubscriptionObject?.current_period_end &&
                  ` on ${dayjs.unix(billingInfo?.stripeSubscriptionObject?.current_period_end).format('MM/DD/YYYY')}`}
                .
              </strong>{' '}
              All of your campaigns, connections, leads, history, notes, tags, etc will be deleted. It won’t be possible
              to recover any of your data after that period and your account will stop functioning.
            </Typography>
          </Box>
        );
    }
  };

  useEffect(() => {
    if (showError) {
      if (
        ([
          CancelSubscriptionReason.MISSING_FUNCTIONALITY,
          CancelSubscriptionReason.TECHNICAL_ISSUES,
          CancelSubscriptionReason.SOMETHING_ELSE,
        ].includes(step as CancelSubscriptionReason) &&
          reasonValue.trim().length >= 30) ||
        (step === CancelSubscriptionReason.USING_OTHER_PRODUCT && reasonValue.trim())
      ) {
        setShowError(false);
      }
    }
  }, [reasonValue]);

  return (
    <>
      <Box py="30px" px="40px">
        <Typography textAlign="center" fontSize="22px" color="primary.wlLight" bold>
          {step === 'pause' ? `Pause ${shortCompanyName} Subscription` : `Delete ${shortCompanyName} account`}
        </Typography>
      </Box>

      {renderBody()}

      {step !== ModalCancelSubscriptionSteps.PAUSE && (
        <Box py="20px" px="40px" display="flex" justifyContent="center" gap="20px">
          <Button processing={isCloseLoading} onClick={onNeverMindClick}>
            Nevermind, keep using {shortCompanyName}
          </Button>

          {step !== ModalCancelSubscriptionSteps.CHOOSE_REASON && (
            <Button
              onClick={defineNextStep}
              processing={isCancelLoading}
              disabled={step === ModalCancelSubscriptionSteps.CONFIRM_CANCEL && confirmDisabled}
            >
              {step === ModalCancelSubscriptionSteps.INFO ? 'Cancel my subscription' : 'Cancel and delete'}
            </Button>
          )}
        </Box>
      )}
    </>
  );
};
