import { AxiosError } from 'axios';
import { UseQueryOptions } from 'react-query';
import { useParams } from 'react-router-dom';

import { CampaignCategory } from 'src/enums';
import { getCampaignTypes } from 'src/helpers';
import { useLocationState } from 'src/hooks';
import { useGetCampaign } from 'src/reactQueries';
import { IGetCampaign, ILocationStateSingleCampaign } from 'src/types';

export const useSingleCampaignProps = <TData = IGetCampaign>(
  options?: UseQueryOptions<IGetCampaign, AxiosError, TData>,
) => {
  const params = useParams();

  const campaignId = Number(params.campaignId);
  const isEdit = !!campaignId;
  const { isAddMoreLeads = false, category, redirectTo } = useLocationState<ILocationStateSingleCampaign>();

  const { campaign, isLoading } = useGetCampaign(campaignId, {
    ...options,
    enabled: isEdit,
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const campaignTypes = getCampaignTypes(campaign);

  return {
    isAddMoreLeads,
    redirectTo,
    isEdit,
    isCampaignLoading: isLoading,
    campaignId,
    campaign,
    ...campaignTypes,
    category: (params.category || campaignTypes.category || category) as CampaignCategory,
  };
};
