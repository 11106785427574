import { useMutation, UseMutationOptions } from 'react-query';

import { exportTeamStats } from 'src/api';
import { ICustomAxiosError, IExportTeamStatsRequest } from 'src/types';

export const useExportTeamStats = (options?: UseMutationOptions<void, ICustomAxiosError, IExportTeamStatsRequest>) => {
  const { mutate, ...rest } = useMutation('export-team-stats', exportTeamStats, options);

  return { exportTeamStats: mutate, ...rest };
};
