import { useMutation, UseMutationOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { schedulePost } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, TSchedulePost } from 'src/types';

export const useSchedulePost = (
  options?: UseMutationOptions<
    void,
    ICustomAxiosError,
    Omit<TSchedulePost, 'scheduledAt'> & {
      scheduledAt: string;
    }
  >,
) => {
  const dispatch = useDispatch();
  const { mutate, ...rest } = useMutation('schedule-post', schedulePost, {
    ...options,
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return {
    schedulePost: mutate,
    ...rest,
  };
};
