import { useQuery, UseQueryOptions } from 'react-query';

import { getCampaignsStatistics } from 'src/api';
import { ICustomAxiosError, IGetDashboardParams, IGetDashboardStatistic } from 'src/types';

export const useGetCampaignsStatistics = <TData = IGetDashboardStatistic>(
  data: IGetDashboardParams,
  options?: UseQueryOptions<IGetDashboardStatistic, ICustomAxiosError, TData>,
) => {
  return useQuery<IGetDashboardStatistic, ICustomAxiosError, TData>(
    ['dashboard-statistics ', JSON.stringify(data)],
    () => getCampaignsStatistics(data),
    options,
  );
};
