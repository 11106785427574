import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { unpauseSubscription } from 'src/api';
import { setIsAppPaused } from 'src/store/user.slice';
import { IBillingInfo, ICustomAxiosError } from 'src/types';

export const useUnpauseSubscription = (options?: UseMutationOptions<IBillingInfo, ICustomAxiosError>) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation(['unpause-subscription'], unpauseSubscription, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      await queryClient.invalidateQueries(['get-billing-info']);
      await queryClient.invalidateQueries(['get-team-by-id']);

      dispatch(setIsAppPaused(false));
    },
  });

  return { unpauseSubscription: mutate, ...rest };
};
