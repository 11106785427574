import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';

import { postMicrosoftOutlookSignIn } from 'src/api';
import { ICustomAxiosError, IEmailOutlookResponse } from 'src/types';

export const usePostMicrosoftOutlookSignIn = (
  code: string,
  options?: UseQueryOptions<IEmailOutlookResponse, ICustomAxiosError>,
) => {
  const queryClient = useQueryClient();

  const { error, ...rest } = useQuery<IEmailOutlookResponse, ICustomAxiosError>(
    ['microsoft-outlook-auth', code],
    () => postMicrosoftOutlookSignIn(code),
    {
      enabled: !!code,
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...options,
      onSuccess: async (data) => {
        options?.onSuccess?.(data);
        queryClient.invalidateQueries('get-users-email');
      },
      onSettled: async (data, error) => {
        options?.onSettled?.(data, error);
        window.history.replaceState({}, document.title);
      },
    },
  );

  return { microsoftError: error, ...rest };
};
