import { CSSProperties, Dispatch, SetStateAction } from 'react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Drawer, Typography } from 'src/components';
import { Conversation } from 'src/containers/Conversations/_components/Conversation';
import { LIConversationFilterType, LIConversationType } from 'src/enums';
import { useGetLeadInfo } from 'src/reactQueries';

const conversationContainerStyles: CSSProperties = {
  display: 'flex',
  height: 'calc(100% - 57px)',
  maxWidth: '500px',
  paddingBottom: '70px',
  boxSizing: 'border-box',
};

export interface IConversationDrawerDataProps {
  isOpen?: boolean;
  entityUrn: string;
  conversationId: string;
  type?: LIConversationType;
}

interface IConversationDrawerProps extends IConversationDrawerDataProps {
  setDrawerState: Dispatch<SetStateAction<IConversationDrawerDataProps>>;
}

export const ConversationDrawer = ({
  isOpen,
  type = LIConversationType.MEMBER_TO_MEMBER,
  setDrawerState,
  ...conversationData
}: IConversationDrawerProps) => {
  const { leadInfo } = useGetLeadInfo(conversationData?.entityUrn);

  const closeHandler = () => {
    setDrawerState((prev) => ({ ...prev, isOpen: false }));
  };

  return (
    <Drawer isOpen={!!isOpen} onClose={closeHandler}>
      <Box
        p="16px"
        display="flex"
        alignItems="center"
        width="500px"
        borderBottom="1px solid #e8e8e8"
        boxSizing="border-box"
      >
        <Typography semibold fontSize="16px" lineHeight="24px">
          Direct reply
        </Typography>
        <Box ml="auto">
          <FontAwesomeIcon cursor="pointer" icon={faXmark} onClick={closeHandler} />
        </Box>
      </Box>
      <Conversation
        conversationData={{
          ...conversationData,
          imageUrl: leadInfo?.person?.linkedInData.pic || '',
          headline: leadInfo?.person?.headline || '',
          name: leadInfo?.person?.name || '',
          type,
          isConnected: true,
        }}
        filterType={LIConversationFilterType.ALL}
        hideConversationActions
        containerStyle={conversationContainerStyles}
        showRefetchMessages
      />
    </Drawer>
  );
};
