import { HeaderButton } from 'src/components';
import { CampaignCategory, CampaignType } from 'src/enums';
import { getGeneralCampaignType } from 'src/helpers';
import { useLocationState } from 'src/hooks';
import {
  useAddMoreLeadsCampaign,
  useCreateCampaignFromConnections,
  useUpdateCampaignFromConnections,
} from 'src/reactQueries';
import { transformCampaignRequestBody } from 'src/transformers';
import { ILocationStateContacts } from 'src/types';

export const ButtonCreateCampaign = ({ entityUrns }: { entityUrns: string[] }) => {
  const {
    campaignType,
    isLinkedinOnly,
    campaignIdToAddLeads,
    isAddMoreLeads,
    category = CampaignCategory.LINKEDIN,
    redirectTo,
  } = useLocationState<ILocationStateContacts>();

  const generalCampaignType = getGeneralCampaignType(campaignType);
  const { createCampaignFromConnections, isCreateLoading } = useCreateCampaignFromConnections(generalCampaignType.type);
  const { updateCampaignFromConnections, isUpdateLoading } = useUpdateCampaignFromConnections({
    generalCampaignType: generalCampaignType.type,
    campaignId: campaignIdToAddLeads,
    isAddMoreLeads,
    redirectTo,
  });

  const { addMoreLeads, isAddMoreLeadsLoading } = useAddMoreLeadsCampaign(campaignIdToAddLeads, {
    onSuccess: ({ id: leadSourceId }) => {
      updateCampaignFromConnections(
        transformCampaignRequestBody({
          category,
          campaignType,
          isLinkedinOnly: false,
          entityUrns,
          leadSourceId,
        }),
      );
    },
  });

  const clickCreateButtonHandler = () => {
    createCampaignFromConnections(
      transformCampaignRequestBody({
        category,
        campaignType,
        isLinkedinOnly: isLinkedinOnly && campaignType === CampaignType.LINKEDIN_CONNECTIONS,
        entityUrns,
      }),
    );
  };

  const clickUpdateButtonHandler = () => {
    if (isAddMoreLeads) {
      addMoreLeads({
        campaignId: campaignIdToAddLeads,
        searchType: campaignType,
        excludeNoPhotos: false,
        noFirstConnections: false,
        premiumOnly: false,
        openLinkOnly: false,
        retargetLeads: false,
        maxSearchAmount: entityUrns.length,
        searchUrl: '',
      });
    } else {
      updateCampaignFromConnections(
        transformCampaignRequestBody({
          category,
          campaignType,
          isLinkedinOnly: false,
          entityUrns,
        }),
      );
    }
  };

  if (campaignIdToAddLeads) {
    return entityUrns.length >= 1 ? (
      <HeaderButton
        onClick={clickUpdateButtonHandler}
        processing={isAddMoreLeads ? isAddMoreLeadsLoading : isUpdateLoading}
      >
        {isAddMoreLeads ? `Add to Campaign ${entityUrns.length}` : `Create campaign ${entityUrns.length}`}
      </HeaderButton>
    ) : null;
  }

  if (!entityUrns.length) {
    return null;
  }

  return (
    <HeaderButton onClick={clickCreateButtonHandler} processing={isCreateLoading}>
      Create {generalCampaignType.label} Campaign {entityUrns.length}
    </HeaderButton>
  );
};
