import { useEffect } from 'react';

import { useGetRealUser, useGetUserAccount } from 'src/reactQueries';
import { useAuth } from './useAuth';
import { useSocket } from './useSocket';

export const useLogoutUser = () => {
  const socket = useSocket();

  const { userMe } = useGetUserAccount();
  const { realImpersonateUser } = useGetRealUser();

  const { logout } = useAuth();

  useEffect(() => {
    socket.off('logout');
    socket.on('logout', ({ userId }: { userId: number }) => {
      if ([userMe?.id, realImpersonateUser?.id].includes(userId)) {
        logout();
      }
    });

    return () => {
      socket.off('logout');
    };
  }, [userMe?.id, realImpersonateUser?.id]);
};
