export enum PostTypes {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin',
  INSTAGRAM = 'instagram',
}

export enum PostStatus {
  PUBLISHED = 'published',
  PENDING = 'pending',
  ARCHIVED = 'archived',
  QUEUED = 'queued',
  SKIPPED = 'skipped',
  DELETED = 'platform_deleted',
  INVALID = 'invalid',
  EXECUTING = 'executing',
}

export enum PostStatusMessage {
  PAYLOAD_TOO_LONG = 'payload_too_long',
  INSTAGRAM_UPLOAD_ERROR = 'instagram_upload_error',
  INSTAGRAM_SPAM = 'instagram_spam',
  INSTAGRAM_DAILY_LIMIT = 'instagram_daily_limit',
  INSTAGRAM_RESTRICTED = 'instagram_restricted',
  INVALID_VIDEO_FORMAT = 'invalid_video_format',
  INSTAGRAM_INVALID_PHOTO_FORMAT = 'instagram_invalid_photo_format',
  INSTAGRAM_INVALID_ASPECT_RATIO = 'instagram_invalid_aspect_ratio',
  INSTAGRAM_INVALID_CAPTION = 'instagram_invalid_caption',
}

export enum PostAttachmentError {
  INVALID_ASPECT_RATIO = 'invalid_aspect_ratio',

  TWITTER_IMAGE_SIZE_ERROR = 'twitter_image_size_error',
  TWITTER_VIDEO_SIZE_ERROR = 'twitter_video_size_error',
  TWITTER_VIDEO_DURATION_ERROR = 'twitter_video_duration_error',
  INVALID_TWITTER_ASPECT_RATIO = 'invalid_twitter_aspect_ratio',

  INSTAGRAM_IMAGE_SIZE_ERROR = 'instagram_image_size_error',
  INSTAGRAM_VIDEO_SIZE_ERROR = 'instagram_video_size_error',
  INSTAGRAM_VIDEO_WIDTH_ERROR = 'instagram_video_width_error',
  INSTAGRAM_VIDEO_TYPE_ERROR = 'instagram_video_type_error',
  INSTAGRAM_VIDEO_DURATION_ERROR = 'instagram_video_duration_error',
  INSTAGRAM_INVALID_VIDEO_CODEC = 'instagram_invalid_video_codec',

  LINKEDIN_IMAGE_SIZE_ERROR = 'LINKEDIN_IMAGE_SIZE_ERROR',
  LINKEDIN_IMAGE_INVALID_FORMAT = 'linkedin_image_invalid_format',
  LINKEDIN_VIDEO_SIZE_ERROR = 'linkedin_video_size_error',
  LINKEDIN_VIDEO_DURATION_ERROR = 'linkedin_video_duration_error',
  LINKEDIN_DOCUMENT_SIZE_ERROR = 'LINKEDIN_DOCUMENT_SIZE_ERROR',
  LINKEDIN_VIDEO_RESOLUTION_RANGE_ERROR = 'LINKEDIN_VIDEO_RESOLUTION_RANGE_ERROR',

  FACEBOOK_IMAGE_SIZE_ERROR = 'FACEBOOK_IMAGE_SIZE_ERROR',
  FACEBOOK_VIDEO_SIZE_ERROR = 'facebook_video_size_error',
  FACEBOOK_VIDEO_DURATION_ERROR = 'facebook_video_duration_error',
}
