import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { FieldValues, useForm, UseFormRegister } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faLock, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Button, Checkbox, ErrorMessage, FormInput, MessageBlock, Tooltip, Typography } from 'src/components';
import { REGEXP_VALID_EMAIL } from 'src/constants';
import { ModalTypes } from 'src/enums';
import { useWhiteLabel } from 'src/hooks/useWhiteLabel';
import { useDeleteWhiteLabelSMTP, useTestSMTPSettings, useUpdateWhiteLabelSMTPSettings } from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';
import { IUpdateSMTPSettingsReq } from 'src/types';
import { Accordion, AccordionDetails, AccordionSummary } from '../_components';

const FormColumns = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  max-width: 80%;
`;

interface IForm extends Omit<IUpdateSMTPSettingsReq, 'port'> {
  port: number | string;
}

const defaultValues: IForm = {
  isTlsEnabled: false,
  password: '',
  port: '',
  replyToEmail: '',
  senderName: '',
  smtpServer: '',
  username: '',
};

export const CustomEmail = () => {
  const dispatch = useDispatch();
  const { whitelabel } = useWhiteLabel();
  const { isAdvancedSettingsSetupAllowed, smtpSettings, withCustomDomain, withBranding, withCustomEmail } = whitelabel;
  const [expanded, setExpanded] = useState(
    !!isAdvancedSettingsSetupAllowed && withBranding && withCustomDomain && !withCustomEmail,
  );

  const {
    reset,
    register,
    setValue,
    setError,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<IForm>({ defaultValues: smtpSettings || defaultValues });
  const formError = Object.entries(errors).find(([key, value]) => key !== 'root' && value?.message)?.[1];

  const { testSMTP, isTestLoading, isError } = useTestSMTPSettings({
    onError: (err) => {
      setError('root', { message: err.message });
    },
  });
  const { updateSMTP, isLoading } = useUpdateWhiteLabelSMTPSettings();
  const { deleteSMTP, isSMTPLoading } = useDeleteWhiteLabelSMTP({
    onSuccess: () => reset(defaultValues),
  });

  useEffect(() => {
    if (!Object.keys(whitelabel.smtpSettings || {}).length) {
      reset(defaultValues);
    }
  }, [whitelabel.smtpSettings]);

  const submit = () => {
    dispatch(
      openModal({
        headerText: 'Apply Email Settings',
        descriptionText:
          'Are you sure you want to continue?<br /><br />This action will modify the email configuration settings, affecting email communication.',
        confirmButtonLabel: 'Yes, proceed',
        confirmButtonLoading: isLoading,
        onConfirm: () => updateSMTP({ ...getValues(), port: Number(getValues('port')) }),
      }),
    );
  };

  const onTestSMTPClick = () => {
    dispatch(
      openModal({
        headerText: 'Test SMTP',
        type: ModalTypes.TEST_SMTP,
        params: {
          validateEmail: (email: string) => email !== getValues().username,
        },
        onConfirm: (receiverEmail) =>
          testSMTP({ smtpSettings: { ...getValues(), port: Number(getValues('port')) }, receiverEmail }),
      }),
    );
  };

  const onDeleteSMTPClick = () => {
    dispatch(
      openModal({
        headerText: 'Remove Email Settings',
        descriptionText: 'Are you sure you want to remove Email Settings',
        onConfirm: deleteSMTP,
      }),
    );
  };

  return (
    <Accordion expanded={expanded} onChange={() => isAdvancedSettingsSetupAllowed && setExpanded((prev) => !prev)}>
      <AccordionSummary
        expanded={expanded}
        actionButton={{
          // eslint-disable-next-line no-undefined
          icon: isAdvancedSettingsSetupAllowed ? undefined : faLock,
          disabled: !isAdvancedSettingsSetupAllowed,
          tooltip: !isAdvancedSettingsSetupAllowed && 'Please contact support',
        }}
        title="Custom Email Address"
        status={withCustomEmail ? 'applied' : 'pending'}
      >
        {withCustomEmail && (
          <Button variant="warning" processing={isSMTPLoading} onClick={onDeleteSMTPClick}>
            Remove Email Settings
          </Button>
        )}
        <Button
          tooltip={{ title: !withCustomDomain && 'Set up Custom Domain first' }}
          disabled={!withCustomDomain}
          processing={isTestLoading}
          onClick={handleSubmit(onTestSMTPClick)}
        >
          Send Test Email
        </Button>
        <Button
          tooltip={{ title: !withCustomDomain && 'Set up Custom Domain first' }}
          disabled={!withCustomDomain}
          processing={isLoading}
          onClick={handleSubmit(submit)}
        >
          Apply Email Settings
        </Button>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          Set up a personalized email address [e.g. <u>noreply@yourdomain.com</u>] to send notification emails to your
          clients through MeetAlfred. This will set the From address of the email instead of default
          noreply@meetalfred.com. To add your custom email, please follow the steps below.
        </Typography>

        <FormColumns mt="24px">
          <FormInput
            label="Reply to Email"
            name="replyToEmail"
            type="email"
            placeholder="support@yourdomain.com"
            parameters={{
              required: 'Please enter an Email',
              minLength: {
                value: 6,
                message: 'Please enter an Email at least 6 characters long',
              },
              pattern: {
                value: REGEXP_VALID_EMAIL,
                message: 'Please enter a valid Email',
              },
              onBlur: () => setValue('replyToEmail', getValues('replyToEmail').trim()),
            }}
            register={register}
            className={classNames({ error: errors.replyToEmail })}
          />
          <FormInput
            label="From (sender) Name"
            name="senderName"
            placeholder="Your App Name Team"
            parameters={{
              required: 'Please enter a Sender Name',
              onBlur: () => setValue('senderName', getValues('senderName').trim()),
            }}
            register={register}
            className={classNames({ error: errors.senderName })}
          />
        </FormColumns>
        <FormColumns mt="20px">
          <FormInput
            label="SMTP Server"
            name="smtpServer"
            placeholder="smtp.gmail.com"
            parameters={{
              required: 'Please enter a SMTP server',
              onBlur: () => setValue('smtpServer', getValues('smtpServer').trim()),
            }}
            register={register}
            className={classNames({ error: errors.smtpServer })}
          />
          <FormInput
            label="SMTP Username"
            name="username"
            placeholder="e.g. John Doe"
            parameters={{
              required: 'Please enter a User Name',
              onBlur: () => setValue('username', getValues('username').trim()),
            }}
            register={register}
            className={classNames({ error: errors.username })}
          />

          <FormInput
            label="SMTP Password"
            name="password"
            type="password"
            autoComplete="on"
            placeholder="Set Your Password"
            parameters={{
              required: 'Please enter a Password',
              onBlur: () => setValue('password', getValues('password').trim()),
            }}
            register={register}
            className={classNames({ error: errors.password })}
          />
        </FormColumns>
        <FormColumns mt="34px">
          <Box>
            <FormInput
              name="port"
              placeholder="587"
              maxLength={5}
              parameters={{
                required: 'Please enter a Port',
                pattern: {
                  value: /[1-9]*/,
                  message: 'Please enter a valid number Port',
                },
                onBlur: () => {
                  const value = getValues('port');
                  setValue('port', value && (Number(String(value)?.trim()) || 0));
                },
                validate: (port) => (Number(port) >= 0 && Number(port) <= 65535) || 'Please enter a valid port',
              }}
              register={register}
              className={classNames({ error: errors.port })}
              preTab={
                <>
                  <Typography inline color="#BFBFBF">
                    Port{' '}
                  </Typography>
                  <Typography inline color="error.light">
                    *
                  </Typography>
                </>
              }
            />
          </Box>
          <Box display="flex" alignItems="center" pb="5px">
            <Checkbox
              type="checkbox"
              register={register as unknown as UseFormRegister<FieldValues>}
              name="isTlsEnabled"
            />
            <Typography mx="8px">Enable start TLS/SSL</Typography>
            <Tooltip title="To test sending an email, mark the checkbox for this option. If you don’t receive a test email, unmark the checkbox and try again.">
              <FontAwesomeIcon size="sm" icon={faQuestionCircle} />
            </Tooltip>
          </Box>
        </FormColumns>

        <ErrorMessage>{formError?.message}</ErrorMessage>

        {isError && !isTestLoading && <MessageBlock type="error" message={errors?.root?.message} />}
      </AccordionDetails>
    </Accordion>
  );
};
