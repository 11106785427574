import { ICampaignChartData, IDataset } from 'src/types';

export const generateLinkedinDataset = (campaignData: ICampaignChartData[]): IDataset[] => {
  return [
    {
      label: 'Connected',
      backgroundColor: '#5F4690',
      hoverBackgroundColor: '#5F469080',
      data: campaignData.map(({ connected }) => connected),
      hidden: false,
    },
    {
      label: 'Invites',
      backgroundColor: '#1D6996',
      hoverBackgroundColor: '#1D699680',
      data: campaignData.map(({ connectRequests }) => connectRequests),
      hidden: false,
    },
    {
      label: 'Replies',
      backgroundColor: '#38A6A5',
      hoverBackgroundColor: '#38A6A580',
      data: campaignData.map(({ responded }) => responded),
      hidden: false,
    },
    {
      label: 'Messages',
      backgroundColor: '#0F8554',
      hoverBackgroundColor: '#0F855480',
      data: campaignData.map(({ messages }) => messages),
      hidden: false,
    },
    {
      label: 'InMails',
      backgroundColor: '#73AF48',
      hoverBackgroundColor: '#73AF4880',
      data: campaignData.map(({ inmails }) => inmails),
      hidden: false,
    },
    {
      label: 'InMail Replies',
      backgroundColor: 'rgb(85,107,47)',
      hoverBackgroundColor: 'rgb(85,107,47)',
      data: campaignData.map(({ respondedInMails }) => respondedInMails),
      hidden: false,
    },
    {
      label: 'Event Messages',
      backgroundColor: 'rgb(184,134,11)',
      hoverBackgroundColor: 'rgb(184,134,11)',
      data: campaignData.map(({ eventMessages }) => eventMessages),
      hidden: false,
    },
    {
      label: 'Emails Bounced',
      backgroundColor: 'rgb(255,0,0)',
      hoverBackgroundColor: 'rgb(255,0,0)',
      data: campaignData.map(({ emailsBounced }) => emailsBounced),
      hidden: false,
    },
    {
      label: 'Group Messages',
      backgroundColor: '#EDAD08',
      hoverBackgroundColor: '#EDAD0880',
      data: campaignData.map(({ groupMessages }) => groupMessages),
      hidden: false,
    },
    {
      label: 'Views',
      backgroundColor: '#E17C05',
      hoverBackgroundColor: '#E17C0580',
      data: campaignData.map(({ viewed }) => viewed),
      hidden: false,
    },
    {
      label: 'Follow up messages',
      backgroundColor: '#94346E',
      hoverBackgroundColor: '#94346E80',
      data: campaignData.map(({ followUp }) => followUp),
      hidden: false,
    },
    {
      label: 'Emails sent',
      backgroundColor: '#6F4070',
      hoverBackgroundColor: '#6F407080',
      data: campaignData.map(({ emails }) => emails),
      hidden: false,
    },
    {
      label: 'Email replies',
      backgroundColor: '#994E95',
      hoverBackgroundColor: '#994E9580',
      data: campaignData.map(({ emailsReplies }) => emailsReplies),
      hidden: false,
    },
  ];
};
