import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MultiValue, SingleValue, StylesConfig } from 'react-select';
import AsyncSelect from 'react-select/async';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { getAutocomplete } from 'src/api';
import { Button, Checkbox, Typography } from 'src/components';
import { closeModal } from 'src/store/modal.slice';
import { IAutocomplete } from 'src/types';

const ClearAllBlock = styled(Box)`
  margin-left: 20px;
  padding: 5px 15px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.wlLight};
  border: 1px solid ${({ theme }) => theme.palette.light.dark};
  font-size: 10px;
  border-radius: 4px;
  transition: 0.2s;

  &:hover {
    background: ${({ theme }) => theme.palette.primary.wlLight};
    border: 1px solid ${({ theme }) => theme.palette.primary.wlLight};
    color: #fff;
  }
`;

const styles: StylesConfig<IAutocomplete> = {
  control: () => {
    return {
      fontSize: '14px',
      borderRadius: '4px',
      border: '2px solid #eee',
      position: 'relative',
      display: 'flex',
      backgroundColor: '#fff',
    };
  },
  indicatorSeparator: () => {
    return {
      display: 'none',
    };
  },
  multiValue: () => {
    return {
      display: 'flex',
      lineHeight: '22px',
      margin: '3px 5px 3px 0',
      backgroundColor: 'rgba(0,126,255,.08)',
      border: '1px solid #ccc',
      borderRadius: '2px',
      padding: '2px 0',
      color: '#0071e6',
    };
  },
  multiValueLabel: () => {
    return {
      order: 2,
      padding: '0 5px',
    };
  },
  multiValueRemove: (provided) => {
    return {
      ...provided,
      borderRight: '1px solid gray',
      borderRadius: 0,
      cursor: 'pointer',

      ['&:hover']: {
        backgroundColor: 'rgba(0,113,230,.08)',
        color: 'var(--gray-main)',
      },
    };
  },
  option: (provided) => {
    return {
      ...provided,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      fontSize: '14px',
      padding: '15px',
      backgroundColor: 'transparent !important',
      transition: '0.2s',
      ['&:hover']: {
        color: '#007eff',
      },
    };
  },
};

const loadAutocomplete = (
  {
    route,
    query,
  }: {
    route: string;
    query: string;
  },
  callback: (array: IAutocomplete[]) => void,
) => {
  getAutocomplete({ query, route }).then((resp) => callback(resp));
};

const debouncedAutocomplete = debounce(loadAutocomplete, 500);

interface IModalAutocompleteSearchProps {
  headerText: string;
  route: string;
  values: IAutocomplete[];
  setValues: (newValues: IAutocomplete[]) => void;
}

export const ModalAutocompleteSearch = ({
  headerText,
  route,
  values: propValues,
  setValues: setValuesProp,
}: IModalAutocompleteSearchProps) => {
  const [values, setValues] = useState(propValues);
  const dispatch = useDispatch();

  const close = () => {
    dispatch(closeModal());
  };

  const showClearAll = !!values.length;

  const changeHandler = (newValues: MultiValue<IAutocomplete> | SingleValue<IAutocomplete>) => {
    setValues(newValues as IAutocomplete[]);
  };

  const remove = (id: string) => {
    setValues((prevState) => prevState.filter((value) => value.id !== id));
  };

  const clearAll = () => setValues([]);

  useEffect(() => {
    setValuesProp(values);
  }, [values]);

  return (
    <>
      <Box py="30px" px="40px" display="flex" alignItems="center" fontSize="14px" height="20px">
        {headerText}
        {showClearAll && <ClearAllBlock onClick={clearAll}>X CLEAR ALL</ClearAllBlock>}
      </Box>
      <Box py="30px" px="40px" display="flex" justifyContent="space-between" flexWrap="wrap" bgcolor="light.light">
        {values.map((value) => (
          <Box key={value.id} display="flex" alignItems="center" width="50%" my="14px">
            <Checkbox id={value.id} type="checkbox" checked onChange={() => remove(value.id)} />
            <label htmlFor={value.id}>{value.name}</label>
          </Box>
        ))}

        <Box width="100%">
          <AsyncSelect
            placeholder={headerText}
            isMulti
            cacheOptions
            value={values}
            loadOptions={(query, cb) => debouncedAutocomplete({ route, query }, cb)}
            onChange={changeHandler}
            styles={styles}
            noOptionsMessage={() => (
              <Typography margin="5px" fontSize="16px" color="#999">
                Type to search
              </Typography>
            )}
          />
        </Box>
      </Box>
      <Box py="20px" px="40px" display="flex" justifyContent="flex-end">
        <Button onClick={close}>Save</Button>
      </Box>
    </>
  );
};
