import { ICreateTrendRequest, ITrend, ITrendPost } from 'src/types';
import { appApi } from './axios';

export const createTrend = (params: ICreateTrendRequest): Promise<ITrend> => appApi.post(`/trends`, params);

export const getTrends = (): Promise<ITrend[]> => appApi.get(`/trends`);

export const getTrendPosts = (trendId: number): Promise<ITrendPost[]> => {
  const apiRoute = trendId === -1 ? 'trend-posts' : `trends/${trendId}/posts`;

  return appApi.get(`/${apiRoute}`);
};

export const refreshTrendPosts = (trendId: number): Promise<ITrend> => {
  const apiRoute = trendId === -1 ? 'trends/refresh-all' : `trends/${trendId}/refresh`;

  return appApi.post(`/${apiRoute}`);
};

export const updateTrend = ({ id, sortBy, interval, term }: ITrend): Promise<ITrend> =>
  appApi.patch(`/trends/${id}`, {
    sortBy,
    interval,
    term,
  });

export const deleteTrend = (trendId: number): Promise<void> => appApi.delete(`/trends/${trendId}`);

export const likeTrendPost = (postId: number): Promise<void> => appApi.post(`/trend-posts/${postId}/like`);
