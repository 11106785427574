import { useMemo, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { HiddenFileInput, Tooltip } from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features } from 'src/enums';
import { uploadAttachmentToBucket } from 'src/helpers';
import { useTeamPlan } from 'src/hooks';
import { showToast } from 'src/store/toast.slice';
import { ISequence } from 'src/types';

const AttachmentBox = styled(Box)`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.light.light};
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px 4px 0 0;
  margin-left: 5px;

  &:hover {
    color: #fff;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const TooltipText = styled.p`
  text-align: center;
`;

export interface IAttachmentsProps extends UseFormReturn<ISequence> {
  index: number;
}

export const Attachments = ({ index, setValue, setError, watch, clearErrors }: IAttachmentsProps) => {
  const dispatch = useDispatch();
  const pendingFiles = useRef(0);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { isTrial, checkFeature } = useTeamPlan();

  const watchAttachments = watch(`sequence.${index}.attachments`) ?? [];
  const watchType = watch(`sequence.${index}.type`) ?? '';
  const filesLength = watchAttachments.length;

  const isTwitterStep = ['Twitter DM', 'Twitter Follow'].includes(watchType);
  const isAttachmentAllowed = checkFeature(Features.attachmentInSequenceStep);
  const isMaxUploadAllowed = checkFeature(Features.attachmentsUploadLimit);

  const maxSizeLimit = useMemo(() => {
    if (isTrial || isTwitterStep) {
      return 1;
    }

    if (isAttachmentAllowed) {
      return 5;
    }

    return 0;
  }, [isAttachmentAllowed]);

  const maxUploadLimit = useMemo(() => {
    if (isMaxUploadAllowed) {
      return 25000000;
    }

    return Number.MAX_VALUE;
  }, [isMaxUploadAllowed]);

  const tooltipTitle = useMemo(() => {
    if (!isAttachmentAllowed) {
      return (
        <>
          <TooltipText>{PLAN_TOOLTIPS.professional}</TooltipText>
        </>
      );
    }

    return filesLength ? (
      <>
        <TooltipText>{filesLength} file(s) are added.</TooltipText>
        <TooltipText>Click here to choose again</TooltipText>
      </>
    ) : (
      <>
        <TooltipText>Select file(s) as attachment(s) to this message.</TooltipText>
        <TooltipText>Max file size limit is 10MB</TooltipText>
      </>
    );
  }, [filesLength, isAttachmentAllowed]);

  const onAttachmentClick = () => isAttachmentAllowed && fileInputRef.current?.click();

  const showToastWrapper = (type: 'info' | 'error' | 'success', message: string) =>
    dispatch(
      showToast({
        type,
        message,
        autoCloseTime: 3000,
      }),
    );

  const onAttachmentChange = async () => {
    const candidateFiles = Array.from(fileInputRef?.current?.files ?? []);
    const allAttachments = [...candidateFiles, ...watchAttachments]?.length + pendingFiles.current;

    if (!maxSizeLimit) {
      return showToastWrapper('info', PLAN_TOOLTIPS.professional);
    }

    if (allAttachments > maxSizeLimit) {
      return showToastWrapper('info', `Maximum ${maxSizeLimit} file${maxSizeLimit > 1 ? 's' : ''} can be attached`);
    }

    const isSizeOutLimit = candidateFiles.some((file) => file.size > maxUploadLimit);
    if (isSizeOutLimit) {
      return showToastWrapper('info', 'File upload failed, exceeds file limit!');
    }

    setError(`sequence.${index}.attachments`, { type: 'custom', message: 'Uploading files' });
    showToastWrapper('info', 'UPLOADING FILES! PLEASE WAIT!');

    pendingFiles.current += candidateFiles.length;

    await Promise.all(
      candidateFiles.map((file) =>
        uploadAttachmentToBucket({
          file: file,
          isLinkedIn: true,
          onUrlError: () => {
            pendingFiles.current -= 1;
            showToastWrapper('error', 'Uploading failed! Please contact support!');
          },
          onUploadError: () => {
            pendingFiles.current -= 1;
            showToastWrapper('error', "Couldn't upload the file!");
          },
          onSuccess(resp) {
            pendingFiles.current -= 1;
            const attachments = watch(`sequence.${index}.attachments`) ?? [];

            clearErrors(`sequence.${index}.message`);
            setValue(`sequence.${index}.attachments`, [...attachments, resp]);
            showToastWrapper('success', 'FILE UPLOADED!');
          },
        }),
      ),
    );

    clearErrors(`sequence.${index}.attachments`);
  };

  return (
    <>
      <HiddenFileInput
        ref={fileInputRef}
        type="file"
        accept={
          watchType === 'Twitter DM'
            ? 'image/*'
            : 'image/*,.ai,.psd,.pdf,.doc,.docx,.csv,.zip,.rar,.ppt,.pptx,.pps,.ppsx,.odt,.rtf,.xls,.xlsx,.txt,.pub,.html,.7z,.eml'
        }
        onChange={onAttachmentChange}
        multiple
      />
      <Tooltip title={tooltipTitle} placement="top">
        <AttachmentBox width="30px" onClick={onAttachmentClick}>
          <FontAwesomeIcon icon={faPaperclip} />
        </AttachmentBox>
      </Tooltip>
    </>
  );
};
