import { useDispatch } from 'react-redux';

import { Button, Typography } from 'src/components';
import { BillingTypeEnum } from 'src/enums';
import { capitalizeFirstLetter } from 'src/helpers';
import { closeModal } from 'src/store/modal.slice';
import { IModalProps } from 'src/types';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../_components';

interface IModalSaveBillingCycle {
  percentage: number;
  billingType: BillingTypeEnum;
}

export const ModalSaveBillingCycle = ({
  onConfirm,
  params: { percentage, billingType },
}: IModalProps<IModalSaveBillingCycle>) => {
  const dispatch = useDispatch();

  const close = () => dispatch(closeModal());

  const confirm = () => {
    onConfirm && onConfirm({});
    close();
  };

  return (
    <ModalContainer>
      <ModalHeader onClose={close}>Billing cycle update</ModalHeader>
      <ModalBody>
        <Typography>
          Save up to {percentage}% by keeping the {capitalizeFirstLetter(billingType)} plan.
        </Typography>
      </ModalBody>
      <ModalFooter>
        <Button onClick={confirm}>No, I'd rather pay more</Button>
        <Button onClick={close}>Yes, Save me up to {percentage}%</Button>
      </ModalFooter>
    </ModalContainer>
  );
};
