import { useQuery, UseQueryOptions } from 'react-query';

import { getTemplatesByCategory } from 'src/api';
import { ICustomAxiosError, ITemplate } from 'src/types';

export const useGetTemplatesByCategory = (
  categoryId?: number,
  options?: UseQueryOptions<ITemplate[], ICustomAxiosError>,
) => {
  const { data, ...rest } = useQuery<ITemplate[], ICustomAxiosError>(
    ['get-templates-by-category', categoryId],
    () => getTemplatesByCategory({ categoryId }),
    options,
  );

  return { templates: data, ...rest };
};
