import { useMutation, UseMutationOptions } from 'react-query';

import { deleteLinkedInCredentials } from 'src/api';
import { ICustomAxiosError } from 'src/types';

export const useDeleteLinkedInCredentials = (options?: UseMutationOptions<void, ICustomAxiosError>) => {
  const { mutate, ...rest } = useMutation('delete-linkedin-credentials', deleteLinkedInCredentials, options);

  return {
    deleteLinkedInCredentials: mutate,
    ...rest,
  };
};
