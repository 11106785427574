import { Navigate, useParams } from 'react-router-dom';

import { Received } from './Received';
import { Sent } from './Sent';
import { Withdrawn } from './Withdrawn';

export const Invitations = () => {
  const { tab } = useParams();

  const generateContent = () => {
    switch (tab) {
      case 'sent':
        return <Sent />;
      case 'received':
        return <Received />;
      case 'withdrawn':
        return <Withdrawn />;
      default:
        return <Navigate to="/invitations/sent" />;
    }
  };

  return <>{generateContent()}</>;
};
