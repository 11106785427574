import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateTrend } from 'src/api';
import { closeModal } from 'src/store/modal.slice';
import { ICustomAxiosError, ITrend } from 'src/types';

export const useUpdateTrend = (options?: UseMutationOptions<ITrend, ICustomAxiosError, ITrend>) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const getTrendsKey = ['get-trends'];

  const { mutate, ...rest } = useMutation<ITrend, ICustomAxiosError, ITrend, { prevData: ITrend[] }>(
    ['update-trend'],
    updateTrend,
    {
      ...options,
      onMutate: async (updatedTrend) => {
        options?.onMutate?.(updatedTrend);
        await queryClient.cancelQueries(getTrendsKey);

        const prevData = queryClient.getQueryData(getTrendsKey);

        queryClient.setQueryData<ITrend[] | undefined>(getTrendsKey, (trends) =>
          trends?.map((trend: ITrend) => (trend.id === updatedTrend.id ? updatedTrend : trend)),
        );

        return { prevData: prevData as ITrend[] };
      },
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);

        dispatch(closeModal());
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueryData(getTrendsKey, context?.prevData);
      },
      onSettled: async (data, error, variables, context) => {
        options?.onSettled?.(data, error, variables, context);
        await queryClient.invalidateQueries(getTrendsKey);
      },
    },
  );

  return { updateTrend: mutate, ...rest };
};
