import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updatePassword } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { IChangePassword, ICustomAxiosError } from 'src/types';

export const useUpdatePassword = (options?: UseMutationOptions<void, ICustomAxiosError, IChangePassword>) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const getUserPreferencesQueryKey = ['get-user-preferences'];

  const { mutate, ...rest } = useMutation(['update-user-password'], (data: IChangePassword) => updatePassword(data), {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      queryClient.invalidateQueries(getUserPreferencesQueryKey);
      dispatch(
        showToast({
          type: 'info',
          message: 'Password updated successfully',
          autoCloseTime: 3000,
        }),
      );
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      const message = (err as Error)?.message;

      dispatch(
        showToast({
          type: 'error',
          message:
            message === 'Current password invalid'
              ? 'The current password is wrong'
              : message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { changePassword: mutate, ...rest };
};
