import { FieldValues, useForm, UseFormRegister } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button, Checkbox, Link, Tooltip, Typography } from 'src/components';
import { ModalTypes } from 'src/enums';
import { useAcceptInvitationToTeam, useGetUserPreferences, useRejectInvitationToTeam } from 'src/reactQueries';
import { closeModal, openModal } from 'src/store/modal.slice';
import { IModalConfirmInvitationToTeamProps } from 'src/types';
import { ModalBody, ModalFooter } from '../_components';

const TextBold = styled.span`
  font-weight: 700;
`;

const CheckboxLabel = styled.label`
  color: #333;
  font-size: 14px;
  cursor: pointer;
  margin-right: 6px;
`;

export const ModalConfirmInvitationToTeam = ({
  teamName,
  userRole,
  inviteId,
  isInviteToCustomDomain,
}: IModalConfirmInvitationToTeamProps) => {
  const dispatch = useDispatch();

  const { handleSubmit, register } = useForm<{
    moveCampaigns: boolean;
    moveMessagesTemplates: boolean;
    moveSequenceTemplates: boolean;
  }>({
    defaultValues: { moveCampaigns: false, moveMessagesTemplates: false, moveSequenceTemplates: false },
  });

  const { userPreferences } = useGetUserPreferences();
  const isAcceptDisabled = !userPreferences?.userProfile.havePassword && isInviteToCustomDomain;

  const { acceptInvitation } = useAcceptInvitationToTeam();
  const { rejectInvitation } = useRejectInvitationToTeam();

  const onAccept = () => {
    dispatch(closeModal());
    handleSubmit((data) => {
      acceptInvitation({
        inviteId,
        moveCampaigns: data.moveCampaigns,
        moveMessagesTemplates: data.moveMessagesTemplates,
        moveSequenceTemplates: data.moveSequenceTemplates,
      });
    })();
  };

  const onReject = () => {
    dispatch(closeModal());
    rejectInvitation({ inviteId });
  };

  const openSetPassword = () => {
    dispatch(openModal({ type: ModalTypes.SET_PASSWORD, headerText: 'Set password' }));
  };

  return (
    <>
      <ModalBody>
        <Typography mt="10px" color="violet.dark">
          You've have been invited to join <TextBold>{teamName}</TextBold>{' '}
          {`as ${userRole === 'member' ? 'a' : 'an'} ${userRole}`}.
        </Typography>
        <Typography color="violet.dark">
          You can only be part of one team in the app. Once you move to this new team, you will lose access to your
          current campaigns and leads unless you move them to your new team.
        </Typography>
        {isAcceptDisabled && (
          <>
            <Typography color="violet.dark" inline>
              In order to accept this invitation, please{' '}
              <Link underline onClick={openSetPassword} inline>
                set password
              </Link>
              .
            </Typography>
          </>
        )}

        <Box display="flex" alignItems="center" mt="16px">
          <Checkbox
            register={register as unknown as UseFormRegister<FieldValues>}
            name="moveCampaigns"
            type="checkbox"
            id="moveCampaigns"
          />
          <CheckboxLabel htmlFor="moveCampaigns">Move campaigns and leads to my new team</CheckboxLabel>
        </Box>
        <Box display="flex" alignItems="center" mt="5px">
          <Checkbox
            register={register as unknown as UseFormRegister<FieldValues>}
            name="moveMessagesTemplates"
            type="checkbox"
            id="moveMessagesTemplates"
          />
          <CheckboxLabel htmlFor="moveMessagesTemplates">Move messages templates to my new team</CheckboxLabel>
        </Box>
        <Box display="flex" alignItems="center" mt="5px">
          <Checkbox
            register={register as unknown as UseFormRegister<FieldValues>}
            name="moveSequenceTemplates"
            type="checkbox"
            id="moveSequenceTemplates"
          />
          <CheckboxLabel htmlFor="moveSequenceTemplates">Move sequence templates to my new team</CheckboxLabel>
        </Box>
      </ModalBody>

      <ModalFooter>
        <Button onClick={onReject}>Reject</Button>
        <Tooltip title={isAcceptDisabled && 'In order to accept this invitation, please set password'}>
          <span>
            <Button onClick={onAccept} disabled={isAcceptDisabled}>
              Accept
            </Button>
          </span>
        </Tooltip>
      </ModalFooter>
    </>
  );
};
