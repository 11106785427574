import styled from '@emotion/styled';
import { CardElement, CardElementProps } from '@stripe/react-stripe-js';

const StyledCard = styled(CardElement)`
  padding: 20px 29px;
  margin: 10px 0;
  width: 100%;
  height: 60px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #ffffff;
  font-size: 16px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-sizing: border-box;

  &::placeholder {
    color: ${({ theme }) => theme.palette.gray.main};
  }

  &:focus {
    outline: none;
    border: 2px solid #52d9ff;
  }
`;

export const StripeCardOld = (props: CardElementProps) => {
  return (
    <StyledCard
      {...props}
      options={{
        style: {
          base: {
            fontSmoothing: 'antialiased',
            fontSize: '14px',
            '::placeholder': {
              color: 'var(--gray-main)',
            },
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a',
          },
        },
      }}
    />
  );
};
