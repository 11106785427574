import { Breadcrumbs, HeaderContainer, HeaderDescription, HeaderTitle, Link, Typography } from 'src/components';
import { useTeamPlan } from 'src/hooks';

export const CreateSubscriptionLayout = () => {
  const { isDeactivated } = useTeamPlan();

  return (
    <HeaderContainer pt="16px">
      <Breadcrumbs>
        <Link to={isDeactivated ? '/deactivated' : '/billing/subscription/plans'}>
          <Typography>Subscription Plan</Typography>
        </Link>
        <Typography>Your plan</Typography>
      </Breadcrumbs>
      <HeaderTitle pt="16px">Your plan</HeaderTitle>
      <HeaderDescription>Find the perfect plan to achieve your goals.</HeaderDescription>
    </HeaderContainer>
  );
};
