import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { deleteGroup } from 'src/api/groups';
import { closeModal } from 'src/store/modal.slice';
import { showToast } from 'src/store/toast.slice';
import {
  ICustomAxiosError,
  IDeleteGroupRequest,
  IGetGroupListPageResponse,
  TGetGroupListFullResponse,
} from 'src/types';

export const useDeleteGroup = (options?: UseMutationOptions<void, ICustomAxiosError, IDeleteGroupRequest>) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const getGroupListKey = ['get-group-list-full'];
  const getGroupListPageKey = ['get-group-list-page'];

  const { mutate, isLoading, ...rest } = useMutation(['delete-group'], deleteGroup, {
    ...options,
    onMutate: async (request) => {
      options?.onMutate?.(request);
      await queryClient.cancelQueries(getGroupListKey);
      await queryClient.cancelQueries(getGroupListPageKey);

      const prevDataListFull = queryClient.getQueryData(getGroupListKey);

      const prevDataListPage = queryClient.getQueryData(getGroupListPageKey);

      queryClient.setQueryData<TGetGroupListFullResponse | undefined>(getGroupListKey, (items) =>
        items?.filter((item) => item.id !== request.groupId),
      );

      queryClient.setQueryData<IGetGroupListPageResponse | undefined>(getGroupListPageKey, (data) => ({
        total: (data?.total ?? 1) - 1,
        groups: data?.groups.filter((item) => item.id !== request.groupId) ?? [],
      }));

      return {
        prevData: {
          prevDataListFull,
          prevDataListPage,
        },
      };
    },
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      dispatch(showToast({ type: 'info', message: 'The group has been deleted' }));
      dispatch(closeModal());
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      queryClient.setQueryData(getGroupListKey, context?.prevData.prevDataListFull);
      queryClient.setQueryData(getGroupListPageKey, context?.prevData.prevDataListPage);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
    onSettled: async (data, error, variables, context) => {
      options?.onSettled?.(data, error, variables, context);
      await queryClient.invalidateQueries(getGroupListPageKey);
      await queryClient.invalidateQueries(getGroupListKey);
    },
  });

  return { deleteGroup: mutate, isDeleteLoading: isLoading, ...rest };
};
