import { useMutation, UseMutationOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateConnectionsColumnsPosition } from 'src/api';
import { useAppSelector } from 'src/hooks';
import { setConnectionColumns } from 'src/store/connections.slice';
import { showToast } from 'src/store/toast.slice';
import { IConnectionColumnsPosition, ICustomAxiosError } from 'src/types';

export const useUpdateConnectionsColumnsPosition = (
  options?: UseMutationOptions<IConnectionColumnsPosition[], ICustomAxiosError, IConnectionColumnsPosition[]>,
) => {
  const dispatch = useDispatch();
  const connectionColumns = useAppSelector((state) => state.connections.connectionColumns);
  const user = useAppSelector((state) => state.user.profile);

  const { mutate, ...rest } = useMutation('update-connections-columns-position', updateConnectionsColumnsPosition, {
    ...options,
    onMutate: async (variables) => {
      options?.onMutate?.(variables);
      const prevData = [...connectionColumns];

      dispatch(setConnectionColumns(variables));

      return { prevData };
    },
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      localStorage.setItem('user', JSON.stringify({ ...user, connectionColumns: data }));
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      if (context?.prevData) {
        dispatch(setConnectionColumns(context.prevData));
      }

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { updateConnectionsColumnsPosition: mutate, ...rest };
};
