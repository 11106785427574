import classNames from 'classnames';
import { useState } from 'react';
import './styles.scss';
import styled from '@emotion/styled';
import { Box, MenuItem, Select } from '@mui/material';

import { ISelectCreateTrendProp } from 'src/types';

const MainContainer = styled(Box)`
  background-color: ${({ theme }) => theme.palette.light.light};
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 34px;
  justify-content: space-between;
  margin-top: 4px;
  max-width: 180px;
  padding: 0px 0px;
`;

const TopContainer = styled(Box)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const Label = styled.p`
  color: #333;
  font-family: 'ProximaSoft', sans-serif;
  font-size: 14px;
  height: 34px;
  line-height: 34px;
  padding-left: 15px;
  vertical-align: middle;
`;

export const SelectCreateTrend = ({
  onChange,
  value,
  options,
  label,
  paperClassName,
  rootClassName,
}: ISelectCreateTrendProp) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  return (
    <MainContainer>
      <TopContainer>
        <Label onClick={onOpen}>{label}</Label>
        <Select
          MenuProps={{
            classes: { paper: paperClassName },
          }}
          className={rootClassName ? `alf-select-create-trend ${rootClassName}` : 'alf-select-create-trend'}
          defaultValue={options[0]?.value}
          onChange={onChange}
          onClose={onClose}
          onOpen={onOpen}
          open={isOpen}
          value={options?.length ? value : ''}
        >
          {options.map((item) => {
            const isSelected = item.value === value;

            return (
              <MenuItem
                key={item.value}
                value={item.value}
                className={classNames({
                  'alf-select-create-trend_menu-item__selected': isSelected,
                })}
              >
                {item.label}
              </MenuItem>
            );
          })}
        </Select>
      </TopContainer>
    </MainContainer>
  );
};
