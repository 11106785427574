import { ReactNode, useRef, useState } from 'react';
import { SketchPicker as ColorPicker } from 'react-color';
import styled from '@emotion/styled';
import { Box, BoxProps } from '@mui/material';

import { Typography } from 'src/components';
import { useOutsideClick } from 'src/hooks';

const ColorBox = styled(Box)`
  width: 24px;
  height: 24px;

  border: 0.857143px solid #bfbfbf;
  border-radius: 3.42857px;
`;

const Label = styled(Box)`
  box-sizing: border-box;
  padding: 0 16px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #ffffff;
  border: 1px solid #bfbfbf;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 4px;
`;

const ColorPickerContainer = styled(Box)`
  position: absolute;
  z-index: 1;
`;

export interface IColorInputProps extends Omit<BoxProps, 'title'> {
  label: ReactNode;
  color: string;
  onColorChange: (color: string) => void;
}

export const ColorInput = ({ label, onColorChange, color, ...boxProps }: IColorInputProps) => {
  const colorPickerContainerRef = useRef<HTMLDivElement>();
  const [colorPickerShown, setColorPickerShown] = useState(false);

  const showColorPicker = () => {
    if (!colorPickerShown) {
      setColorPickerShown(true);
    }
  };

  const hideColorPicker = () => {
    setColorPickerShown(false);
  };

  useOutsideClick(colorPickerContainerRef, hideColorPicker);

  return (
    <Box {...boxProps} position="relative">
      <Typography fontSize="14px" mb="6px">
        {label}
      </Typography>
      <Label>
        {color} <ColorBox bgcolor={color} onClick={showColorPicker} />
      </Label>
      {colorPickerShown && (
        <ColorPickerContainer ref={colorPickerContainerRef}>
          <ColorPicker onChange={(color) => onColorChange(color.hex)} color={color} />
        </ColorPickerContainer>
      )}
    </Box>
  );
};
