import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { useAppSelector } from 'src/hooks';
import { clearConversationState } from 'src/store/conversation.slice';
import { SNConversation } from '../_components/SNConversation';
import { SNConversationsList } from './SNConversationsList';

const Wrapper = styled(Box)`
  background-color: #fff;
  margin: 15px;
  display: flex;
  height: calc(100% - 171px);
  min-height: 420px;
`;

export const SNConversations = () => {
  const dispatch = useDispatch();

  const { selectedConversation, filterType } = useAppSelector((state) => state.conversation);

  useEffect(() => {
    return () => {
      dispatch(clearConversationState());
    };
  }, []);

  return (
    <Wrapper>
      <SNConversationsList />

      {selectedConversation ? (
        <SNConversation conversationData={selectedConversation} filterType={filterType} />
      ) : (
        <Box flexGrow="1" height="100%" display="flex" alignItems="center" justifyContent="center">
          <Typography fontSize="14px">Please select the conversation.</Typography>
        </Box>
      )}
    </Wrapper>
  );
};
