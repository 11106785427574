import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { deleteCampaignAllIgnoredLead } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IGetCampaign } from 'src/types';

export const useDeleteAllIgnoredCampaignLeads = (
  campaignId: number,
  options?: UseMutationOptions<void, ICustomAxiosError>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const campaignQueryKey = ['campaign', campaignId];

  const { mutate, isLoading, ...rest } = useMutation(
    ['delete-campaign-all-ignored-leads', campaignId],
    () => deleteCampaignAllIgnoredLead(campaignId),
    {
      ...options,
      onMutate: async (variables) => {
        options?.onMutate?.(variables);
        await queryClient.cancelQueries(campaignQueryKey);

        const prevData = queryClient.getQueryData(campaignQueryKey);

        queryClient.setQueryData<IGetCampaign | undefined>(campaignQueryKey, (data) => {
          if (data) {
            return {
              ...data,
              ignoredLeadsCount: 0,
            };
          }

          return data;
        });
        navigate(`/campaign/details/leads/${campaignId}`);

        return { prevData };
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueryData(campaignQueryKey, context?.prevData);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { deleteAllIgnoredLeads: mutate, isDeleteLeadsLoading: isLoading, ...rest };
};
