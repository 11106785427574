import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { Navigate, Outlet, Route, Routes as Switch } from 'react-router-dom';

import { IS_SHOW_WHITE_LABEL } from 'src/constants';
import {
  Activity,
  AutoWithdrawals,
  BlacklistLeadsPage,
  CampaignsActivity,
  CampaignSequence,
  CampaignsReplies,
  CampaignsStats,
  ChooseCampaignType,
  ChooseGeneralCampaignType,
  ConnectionsPage,
  DailyLimits,
  Dashboard,
  Greetings,
  GroupManage,
  IgnoredLeads,
  Invitations,
  Leads,
  LeadsPage,
  ManageTeamLayout,
  NewCampaign,
  NotFound,
  Overview,
  ReferFriend,
  SettingsLayout,
  SingleCampaign,
  StatInfo,
  TeamGroups,
  TeamInvite,
  TeamManage,
  TeamSettings,
  TeamStats,
  TemplatesLibrary,
  TemplatesTeam,
  Unsubscribe,
  UserProfile,
  WebhookDocCampaigns,
  WebhookDocConnections,
  WebhookDocLastActions,
  WebhookDocLeads,
  WebhookDocMemberConnections,
  WebhookDocNewLead,
  WebhookDocReplies,
  WebhookDocTeamMembers,
  WebhookLayout,
  WhiteLabel,
  WorkHours,
} from 'src/containers';
import { ConfirmEmail, ForgotPassword, Login, SetPassword, SignUp, VerifyEmail } from 'src/containers/Auth';
import {
  Billing,
  BillingHistory,
  BillingInformation,
  CreateSubscription,
  EditSubscription,
  PaymentMethod,
  SubscriptionPlan,
  SubscriptionPlans,
} from 'src/containers/Billing';
import { Billing as BillingOld, EditSubscription as EditSubscriptionOld } from 'src/containers/Billing/_outdated';
import { BlacklistConnectionsPage } from 'src/containers/Contacts/BlacklistConnectionsPage';
import { Conversations, LinkedinConversations, SNConversations } from 'src/containers/Conversations';
import { DeactivatedApp } from 'src/containers/DeactivatedApp';
import { DeactivatedApp as DeactivatedAppOld } from 'src/containers/DeactivatedApp/_outdated';
import { FirstIntegrations, Integrations, MicrosoftCallback } from 'src/containers/Integrations';
import {
  EmailIntegration,
  FacebookIntegration,
  LinkedInIntegration,
  TwitterIntegration,
  WebhookIntegration,
  ZapierIntegration,
} from 'src/containers/Integrations/_components';
import { CreatePost, EditPost, Posts } from 'src/containers/Posts';
import { SequenceTemplateForm, SequenceTemplates } from 'src/containers/SequenceTemplates';
import { Audience, Summary } from 'src/containers/SingleCampaign';
import { TagManager } from 'src/containers/TagManager';
import { TemplateCreator, TemplatesPersonal } from 'src/containers/Templates';
import { Trends } from 'src/containers/Trends';
import { Welcome } from 'src/containers/Welcome';
import { FeatureFlagEnum, LIConversationFilterType, TemplateAvailability } from 'src/enums';
import { useFeatureFlag, useGetNavigationConfig, useInitIntercom, useWhiteLabel } from 'src/hooks';
import { AppLayout } from 'src/layouts';
import { useRoutesController } from './useRoutesController';

dayjs.extend(timezone);

export const Routes = () => {
  useInitIntercom();
  const { whitelabel } = useWhiteLabel();
  const { isAuth, isAppDeactivated } = useRoutesController();
  const {
    isTrial,
    isManageGroupShown,
    isManageTeamShown,
    isTeamTemplatesShow,
    isTeamGroupsShown,
    isTeamShown,
    isTeamSettingsShown,
    isLibraryTemplatesShow,
    isBillingShown,
    isWhiteLabelShown,
    isReferFriendShown,
    isGreetingsShown,
    isSequenceTemplateShown,
    isEditPlanShown,
  } = useGetNavigationConfig();

  const [isUpdatedSubscriptionFlowEnabled] = useFeatureFlag(FeatureFlagEnum.updatedSubscriptionFlow);

  if (isAppDeactivated) {
    return (
      <Switch>
        <Route
          path="/deactivated"
          element={
            <AppLayout>
              <Outlet />
            </AppLayout>
          }
        >
          {isUpdatedSubscriptionFlowEnabled ? (
            <>
              {isBillingShown ? (
                <>
                  <Route index element={<SubscriptionPlans />} />
                  <Route path="subscription" element={<CreateSubscription />} />
                </>
              ) : (
                <Route index element={<DeactivatedApp />} />
              )}
            </>
          ) : (
            <Route index element={<DeactivatedAppOld />} />
          )}
        </Route>

        <Route path="/" element={<Navigate to="/deactivated" replace />} />
        <Route path="*" element={<Navigate to="/deactivated" replace />} />
      </Switch>
    );
  }

  return (
    <Switch>
      {whitelabel.isWhiteLabelUser || (
        <>
          <Route path="/auth/signup" element={<SignUp />} />
          <Route path="/auth/signupx" element={<SignUp withPromoCode />} />
          <Route path="/auth/signupx/:promoCode" element={<SignUp withPromoCode />} />
        </>
      )}
      <Route path="/auth/login" element={<Login />} />
      <Route path="/auth/forgotPassword" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<SetPassword />} />
      <Route path="/microsoft/callback" element={<MicrosoftCallback />} />
      <Route
        path="/app/verifyEmail"
        element={
          <AppLayout hideNavBar>
            <VerifyEmail />
          </AppLayout>
        }
      />
      <Route path="/verify-email/:verificationCode" element={<ConfirmEmail />} />
      <Route path="/email-verified" element={<Welcome />} />
      <Route
        path="/walkthrough"
        element={
          <AppLayout hideNavBar>
            <FirstIntegrations />
          </AppLayout>
        }
      />

      <Route path="/email-unsubscribed" element={<Unsubscribe />} />

      <Route
        path="/dashboard"
        element={
          <AppLayout>
            <Outlet />
          </AppLayout>
        }
      >
        <Route path=":tab" element={<Dashboard />} />
        <Route index path="*" element={<Navigate to="/dashboard/today" replace />} />
      </Route>

      <Route path="/campaign" element={<SingleCampaign />}>
        <Route path="choose-type" element={<ChooseGeneralCampaignType />} />
        <Route path="add-leads/:category" element={<ChooseCampaignType />} />
        <Route path="new/:category" element={<ChooseCampaignType />} />
        <Route path="new/:category/:campaignPageType" element={<NewCampaign />} />
      </Route>

      <Route path="/campaign" element={<SingleCampaign />}>
        <Route path=":campaignId/audience" element={<Audience />} />
        <Route path=":campaignId/sequence" element={<CampaignSequence />} />
        <Route path=":campaignId/publish" element={<Summary />} />
        <Route path="/campaign" element={<Navigate to="stats" replace />} />
      </Route>

      <Route
        path="/campaign"
        element={
          <AppLayout>
            <Outlet />
          </AppLayout>
        }
      >
        <Route path="stats/:category" element={<Outlet />}>
          <Route index element={<Navigate to="active" replace />} />
          <Route path=":tab" element={<CampaignsStats />} />
        </Route>
        <Route path="stats/info/:stat/:campaignId" element={<StatInfo />} />
        <Route path="activity" element={<CampaignsActivity />} />
        <Route path="replies" element={<CampaignsReplies />} />

        <Route path="details/activity/:campaignId" element={<Activity />} />
        <Route path="details/leads/:campaignId" element={<Leads />} />
        <Route path="details/overview/:campaignId" element={<Overview />} />
        <Route path="details/ignored-leads/:campaignId" element={<IgnoredLeads />} />
        <Route path="*" element={<Navigate to="/campaign/stats/linkedin/active" replace />} />
      </Route>

      <Route
        path="/invitations"
        element={
          <AppLayout>
            <Outlet />
          </AppLayout>
        }
      >
        <Route index element={<Navigate to="sent" replace />} />
        <Route path=":tab" element={<Invitations />} />
      </Route>

      {!whitelabel.isWhiteLabelUser && !isUpdatedSubscriptionFlowEnabled && (
        <Route path="/billing" element={isBillingShown ? <Outlet /> : <Navigate to="/dashboard" replace />}>
          <Route index element={<BillingOld />} />
          <Route path="edit" element={isEditPlanShown ? <BillingOld isEditPlan /> : <Navigate to="/billing" />} />

          <Route path="*" element={<Navigate to="/billing" replace />} />
        </Route>
      )}

      {!whitelabel.isWhiteLabelUser && isUpdatedSubscriptionFlowEnabled && (
        <Route
          path="/billing"
          element={
            <AppLayout>
              <Outlet />
            </AppLayout>
          }
        >
          {isBillingShown && (
            <>
              <Route path="subscription/plans" element={<SubscriptionPlans />} />

              {isTrial && <Route path="subscription/new" element={<CreateSubscription />} />}
              {!isTrial && (
                <>
                  <Route path="subscription/edit" element={<EditSubscription />} />

                  <Route path="" element={<Billing />}>
                    <Route path="history" element={<BillingHistory />} />
                    <Route path="payment-method" element={<PaymentMethod isProfileText />} />
                    <Route path="information" element={<BillingInformation />} />
                    <Route path="subscription" element={<SubscriptionPlan />} />

                    <Route path="/billing/" element={<Navigate to="/billing/subscription" replace />} />
                    <Route path="*" element={<Navigate to="/billing/subscription" replace />} />
                  </Route>
                </>
              )}

              <Route path="/billing" element={<Navigate to="/billing/subscription/plans" replace />} />
              <Route path="*" element={<Navigate to="/billing/subscription/plans" replace />} />
            </>
          )}
          <Route path="/billing" element={<Navigate to="/dashboard" replace />} />
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Route>
      )}

      <Route path="/inbox" element={<Conversations />}>
        <Route path="linkedin" element={<LinkedinConversations type={LIConversationFilterType.ALL} />} />
        <Route path="in-mail" element={<LinkedinConversations type={LIConversationFilterType.INMAIL} />} />
        <Route path="sales-navigator" element={<SNConversations />} />
        <Route path="/inbox" element={<Navigate to="/inbox/linkedin" replace />} />
        <Route path="*" element={<Navigate to="/inbox/linkedin" replace />} />
      </Route>

      <Route
        path="/posts"
        element={
          <AppLayout>
            <Outlet />
          </AppLayout>
        }
      >
        <Route index element={<Navigate to="linkedin" replace />} />
        <Route path="new" element={<CreatePost />} />
        <Route path=":postId/details" element={<EditPost />} />
        <Route path=":postType" element={<Posts />} />
      </Route>

      <Route
        path="trends"
        element={
          <AppLayout>
            <Trends />
          </AppLayout>
        }
      />

      <Route path="/integrations" element={<Integrations />}>
        <Route path="linkedin" element={<LinkedInIntegration />} />
        <Route path="email" element={<EmailIntegration redirectTo="/integrations/email" />} />
        <Route path="facebook" element={<FacebookIntegration />} />
        <Route path="twitter" element={<TwitterIntegration />} />
        <Route path="zapier" element={<ZapierIntegration />} />
        <Route path="webhook" element={<WebhookIntegration />} />
        <Route index path="*" element={<Navigate to="/integrations/linkedin" replace />} />
      </Route>

      <Route path="/settings" element={<SettingsLayout />}>
        <Route index element={<Navigate to="/settings/user-profile" replace />} />
        <Route path="user-profile" element={<UserProfile />} />
        <Route path="work-hours" element={<WorkHours />} />
        <Route path="daily-limits" element={<DailyLimits />} />
        <Route path="auto-withdrawals" element={<AutoWithdrawals />} />
        <Route
          path="greetings"
          element={isGreetingsShown ? <Greetings /> : <Navigate to="/settings/user-profile" replace />}
        />
        <Route
          path="refer-friend"
          element={isReferFriendShown ? <ReferFriend /> : <Navigate to="/campaign/stats/linkedin/active" />}
        />
        {IS_SHOW_WHITE_LABEL && (
          <Route
            path="white-label"
            element={isWhiteLabelShown ? <WhiteLabel /> : <Navigate to="/campaign/stats/linkedin/active" replace />}
          />
        )}
      </Route>

      <Route path="/documentation/webhook" element={<WebhookLayout />}>
        <Route path="add-new-lead" element={<WebhookDocNewLead />} />
        <Route path="get-campaigns" element={<WebhookDocCampaigns />} />
        <Route path="get-leads" element={<WebhookDocLeads />} />
        <Route path="get-connections" element={<WebhookDocConnections />} />
        <Route path="get-replies" element={<WebhookDocReplies />} />
        <Route path="get-team-members" element={<WebhookDocTeamMembers />} />
        <Route path="get-member-connections" element={<WebhookDocMemberConnections />} />
        <Route path="get-last-actions" element={<WebhookDocLastActions />} />
        <Route index path="*" element={<Navigate to="/documentation/webhook/add-new-lead" replace />} />
      </Route>

      <Route path="/tags" element={<TagManager />} />

      <Route
        path="/templates"
        element={
          <AppLayout>
            <Outlet />
          </AppLayout>
        }
      >
        <Route index element={<Navigate to="/templates/personal" replace />} />
        <Route path="personal" element={<TemplatesPersonal />} />
        <Route path="personal/:categoryId" element={<TemplatesPersonal />} />
        <Route path="team" element={isTeamTemplatesShow ? <TemplatesTeam /> : <Navigate to="/dashboard" replace />} />
        <Route
          path="team/:categoryId"
          element={isTeamTemplatesShow ? <TemplatesTeam /> : <Navigate to="/dashboard" replace />}
        />
        <Route
          path="library"
          element={isLibraryTemplatesShow ? <TemplatesLibrary /> : <Navigate to="/dashboard" replace />}
        />
        <Route
          path="library/:categoryId"
          element={isLibraryTemplatesShow ? <TemplatesLibrary /> : <Navigate to="/dashboard" replace />}
        />
        <Route path="new" element={<TemplateCreator />} />
      </Route>

      <Route
        path="/sequence-templates"
        element={
          <AppLayout>
            <Outlet />
          </AppLayout>
        }
      >
        <Route index element={<Navigate to="/sequence-templates/personal" replace />} />
        <Route path="personal" element={<SequenceTemplates availability={TemplateAvailability.personal} />} />
        <Route
          path="team"
          element={
            isSequenceTemplateShown ? (
              <SequenceTemplates availability={TemplateAvailability.team} />
            ) : (
              <Navigate to="/sequence-templates/personal" replace />
            )
          }
        />

        <Route path="new" element={<SequenceTemplateForm />} />
        <Route path=":id" element={<SequenceTemplateForm isEdit />} />
      </Route>

      <Route
        path="/contacts"
        element={
          <AppLayout>
            <Outlet />
          </AppLayout>
        }
      >
        <Route path="connections" element={<ConnectionsPage />} />
        <Route path="leads" element={<LeadsPage />} />
        <Route path="blacklist">
          <Route path="connections" element={<BlacklistConnectionsPage />} />
          <Route path="leads" element={<BlacklistLeadsPage />} />
          <Route index element={<Navigate to="connections" replace />} />
        </Route>
        <Route index element={<Navigate to="connections" replace />} />
      </Route>

      <Route
        path="/contacts"
        element={
          <AppLayout>
            <Outlet />
          </AppLayout>
        }
      />

      <Route
        path="/team"
        element={<AppLayout>{isTeamShown ? <Outlet /> : <Navigate to="/dashboard" replace />}</AppLayout>}
      >
        <Route path="stats" element={<TeamStats />} />
        <Route
          path="manage-team"
          element={
            isManageTeamShown ? (
              <TeamManage />
            ) : (
              <Navigate to={isManageGroupShown ? '/team/manage-group' : '/team/stats'} replace />
            )
          }
        />
        <Route
          path="manage-group"
          element={isManageGroupShown ? <GroupManage /> : <Navigate to="/team/stats" replace />}
        />

        <Route path="groups" element={isTeamGroupsShown ? <TeamGroups /> : <Navigate to="/team/stats" replace />} />

        <Route
          path="manage-team/seats"
          element={
            isBillingShown && isEditPlanShown && !isUpdatedSubscriptionFlowEnabled ? (
              <>
                <ManageTeamLayout hideChangeUsersSeats />
                <EditSubscriptionOld seatsOnly />
              </>
            ) : (
              <Navigate to="/team/stats" replace />
            )
          }
        />
        <Route
          path="settings"
          element={isTeamSettingsShown ? <TeamSettings /> : <Navigate to="/settings/user-profile" replace />}
        />
      </Route>
      <Route path="/team/invite/:inviteKey" element={<TeamInvite />} />

      <Route path="/deactivated" element={<Navigate to="/dashboard" replace />} />
      <Route path="/" element={<Navigate to="/dashboard" replace />} />
      <Route path="*" element={isAuth ? <NotFound /> : <Navigate to="/auth/login" replace />} />
    </Switch>
  );
};
