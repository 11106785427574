import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { faEdit, faEye, faLock, faShareAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';

import { ActionButton, TableCell, TableRow, Tag, Typography } from 'src/components';
import { TEMPLATE_AVAILABILITY_OPTIONS } from 'src/constants';
import { Features, ModalTypes } from 'src/enums';
import { useTeamPlan } from 'src/hooks';
import { useToggleShareTemplate } from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';
import { IEditTemplateState, ITemplate } from 'src/types';

export const TemplatesPersonalRow = (template: ITemplate & { categoryLabel: string }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { checkFeature } = useTeamPlan();
  const { categoryLabel, content, isAvailableForTeam, name, categoryId, id } = template;
  const { toggleShareTemplate, isShareLoading } = useToggleShareTemplate(id);
  const { message = '', subject = '', attachments = [] } = content.sequence[0];

  const onEditButtonClick = () => {
    const state: IEditTemplateState = {
      name,
      id,
      category: {
        label: categoryLabel,
        value: String(categoryId),
      },
      availability: isAvailableForTeam ? TEMPLATE_AVAILABILITY_OPTIONS[1] : TEMPLATE_AVAILABILITY_OPTIONS[0],
      message,
      subject: subject,
      isSubjectShown: !!subject,
      attachments: attachments,
      isEdit: true,
    };

    navigate(`/templates/new`, {
      state,
    });
  };

  const onDeleteButtonClick = () => {
    dispatch(
      openModal({
        type: ModalTypes.DELETE_TEMPLATE,
        headerText: 'Delete Confirmation',
        params: { id },
      }),
    );
  };

  const onEyeButtonClick = () => {
    dispatch(
      openModal({
        type: ModalTypes.VIEW_TEMPLATE,
        headerText: template?.name.slice(0, 40),
        params: { template, categoryLabel },
      }),
    );
  };

  const actions = [
    {
      isShown: true,
      title: 'View Template',
      onClick: onEyeButtonClick,
      icon: faEye,
    },
    {
      isShown: true,
      title: 'Edit Template',
      onClick: onEditButtonClick,
      icon: faEdit,
    },
    {
      isShown: checkFeature(Features.templatesTeamAndLibrary),
      title: isAvailableForTeam ? 'Unshare template' : 'Share to Team',
      onClick: () => toggleShareTemplate(),
      isLoading: isShareLoading,
      icon: isAvailableForTeam ? faLock : faShareAlt,
    },
    {
      isShown: true,
      title: 'Delete Template',
      onClick: onDeleteButtonClick,
      icon: faTrashAlt,
    },
  ];

  return (
    <TableRow>
      <TableCell maxWidth="200px" tooltip={name}>
        <Typography inline fontSize="15px">
          {name}
        </Typography>
      </TableCell>
      <TableCell>
        {subject && (
          <>
            <Typography color="primary.wlLight" maxWidth="250px" inline crop fontSize="15px">
              {subject}
            </Typography>
            {' - '}
          </>
        )}
        <Typography maxWidth="500px" inline crop fontSize="15px">
          {message}
        </Typography>
      </TableCell>
      <TableCell justifyContent="center">
        {isAvailableForTeam ? (
          <Tag color="#3fc0ff">
            <Typography color="#3fc0ff">Shared</Typography>
          </Tag>
        ) : (
          ''
        )}
      </TableCell>
      <TableCell contentStyle={{ width: '100%' }}>
        <Box display="flex" gap="8px" justifyContent="flex-end" width="100%">
          {actions.map(
            ({ icon, isShown, onClick, isLoading, title }) =>
              isShown && (
                <ActionButton
                  data-testid={title}
                  key={title}
                  tooltip={title}
                  processing={isLoading}
                  onClick={onClick}
                  icon={icon}
                />
              ),
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};
