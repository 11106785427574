import { ErrorMessage, Link } from 'src/components';
import { useWhiteLabel } from 'src/hooks';

interface IFormErrorProps {
  errorMessage: { message: string; type: string } | null;
}

export const FormError = ({ errorMessage }: IFormErrorProps) => {
  const { whitelabel } = useWhiteLabel();
  const errorMessageType = errorMessage?.type ? `${errorMessage.type}-` : '';

  switch (errorMessage?.message) {
    case 'User already exists':
      return (
        <ErrorMessage data-testid={`${errorMessageType}error-message`}>
          User already exists. Please{' '}
          <Link variant="error" to="/auth/login">
            click here to login
          </Link>
        </ErrorMessage>
      );
    case 'Unauthorized':
      return (
        <ErrorMessage data-testid={`${errorMessageType}error-message`}>
          Something went wrong, please make sure your have entered the correct email and/or password.{' '}
          {whitelabel.isWhiteLabelUser ||
            'If you signed up with gmail or microsoft, please make sure to login with that method.'}
        </ErrorMessage>
      );
    default:
      return (
        <ErrorMessage data-testid={`${errorMessageType}error-message`}>
          {errorMessage?.message ||
            `Something went wrong, please make sure your have entered the correct email and/or password.${
              whitelabel.isWhiteLabelUser
                ? ''
                : ' If you signed up with gmail or microsoft, please make sure to login with that method.'
            }`}
        </ErrorMessage>
      );
  }
};
