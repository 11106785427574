import { Box } from '@mui/material';

import { ReactComponent as EmailOnly } from 'src/assets/img/campaign/email_only.svg';
import { ReactComponent as LiGroupIcon } from 'src/assets/img/campaign/icons_set_Linkedin_Group.svg';
import { ReactComponent as LiPostIcon } from 'src/assets/img/campaign/icons_set_Linkedin_Post.svg';
import { ReactComponent as InMail } from 'src/assets/img/campaign/inmail.svg';
import { ReactComponent as LinkedinOnly } from 'src/assets/img/campaign/linkedin_only.svg';
import { ReactComponent as LiEventIcon } from 'src/assets/img/campaign/linkedin_search.svg';
import { ReactComponent as MultiChannel } from 'src/assets/img/campaign/multi_channel.svg';
import { ReactComponent as TwitterOnly } from 'src/assets/img/campaign/twitter_only.svg';
import { Typography } from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { CampaignCategory, Features } from 'src/enums';
import { useTeamPlan } from 'src/hooks';
import { CampaignCard } from './CampaignCard';

export const ChooseGeneralCampaignType = () => {
  const { checkFeature } = useTeamPlan();
  const isCampaignAllowed = checkFeature(Features.twitterAndEmailCSVCampaign);

  return (
    <Box mt="120px">
      <Typography fontSize="32px" color="primary.wlLight" textAlign="center" mb="10px" semibold>
        What type of campaign do you want to create?
      </Typography>
      <Box display="flex" justifyContent="center" flexWrap="wrap" gap="40px" px="20px" mt="30px">
        <CampaignCard
          name="Linkedin only"
          description="Create a sequence with linkedin touches"
          image={LinkedinOnly}
          navigation={{ to: `/campaign/new/${CampaignCategory.LINKEDIN}` }}
          height="240px"
        />
        <CampaignCard
          name="Email only"
          description="Import file with emails to start a campaign"
          image={EmailOnly}
          navigation={{ to: `/campaign/new/${CampaignCategory.EMAIL}` }}
          tryTooltip={PLAN_TOOLTIPS.any}
          height="240px"
        />
        <CampaignCard
          name="X (Twitter) only"
          description="Create a sequence with X (Twitter) only touches"
          image={TwitterOnly}
          navigation={{ to: `/campaign/new/${CampaignCategory.TWITTER}` }}
          tryTooltip="This feature is available in Professional plan"
          height="240px"
        />
        <CampaignCard
          name="Multi Channel"
          description="Create a sequence with Linkedin, Email and X (Twitter) touches"
          image={MultiChannel}
          navigation={{ to: `/campaign/new/${CampaignCategory.MULTICHANNEL}` }}
          tryTooltip={PLAN_TOOLTIPS.any}
          height="240px"
          disabledTooltip={!isCampaignAllowed ? PLAN_TOOLTIPS.any : ''}
        />
        <CampaignCard
          name="LinkedIn InMail"
          description="Reach out to Open Profiles through personalized InMails"
          image={InMail}
          navigation={{ to: `/campaign/new/${CampaignCategory.INMAIL}` }}
          tryTooltip={PLAN_TOOLTIPS.any}
          height="240px"
          disabledTooltip={!isCampaignAllowed ? PLAN_TOOLTIPS.any : ''}
        />
        <CampaignCard
          name="LinkedIn Group"
          description="Contact members of any LinkedIn group you are a member of"
          image={LiGroupIcon}
          navigation={{ to: `/campaign/new/${CampaignCategory.GROUP}/group` }}
          tryTooltip={PLAN_TOOLTIPS.any}
          height="240px"
          disabledTooltip={!isCampaignAllowed ? PLAN_TOOLTIPS.any : ''}
        />
        <CampaignCard
          name="LinkedIn Post"
          description="Engage with people who commented or reacted on any LinkedIn post"
          image={LiPostIcon}
          navigation={{ to: `/campaign/new/${CampaignCategory.POST}/post` }}
          tryTooltip={PLAN_TOOLTIPS.any}
          height="240px"
          disabledTooltip={!isCampaignAllowed ? PLAN_TOOLTIPS.any : ''}
        />
        <CampaignCard
          name="LinkedIn Event"
          description="Engage with people who are attending LinkedIn Events"
          image={LiEventIcon}
          navigation={{ to: `/campaign/new/${CampaignCategory.POST}/post` }}
          tryTooltip={PLAN_TOOLTIPS.any}
          height="240px"
          disabledTooltip={!isCampaignAllowed ? PLAN_TOOLTIPS.any : ''}
        />
        <Box width="100%" minWidth="290px" maxWidth="400px" />
      </Box>
    </Box>
  );
};
