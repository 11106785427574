import styled from '@emotion/styled';
import { Pagination as MuiPagination } from '@mui/material';

interface IPaginationProp {
  count: number;
  onChange: (event: React.ChangeEvent<unknown>, page: number) => void;
  page: number;
}

const StyledPagination = styled(MuiPagination)`
  & .MuiPaginationItem-root {
    border-radius: 10px;
    border: 1px solid rgba(224, 224, 224, 1);
    padding: 3px;
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: #ffffff;
  }

  & .Mui-selected {
    pointer-events: none;
    opacity: 0.5;
  }

  & .Mui-disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`;

export const Pagination = (props: IPaginationProp) => {
  return <StyledPagination {...props} />;
};
