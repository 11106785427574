import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Typography } from 'src/components';

export const BlockTitle = styled(Typography)`
  font-size: 22px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.gray[800]};
`;

export const RawSelector = styled(Box)`
  display: inline-flex;
  align-items: center;

  border: 1px solid ${({ theme }) => theme.palette.gray[100]};
  border-radius: 24px;

  background-color: #ffffff;
`;

export const RawSelectorItem = styled(Box)<{ chosen: string }>`
  padding: 5px 16px;
  border-radius: 24px;
  box-sizing: border-box;

  cursor: pointer;

  background-color: ${({ theme, chosen }) => (chosen === 'true' ? `${theme.palette.brand[100]}40` : 'transparent')};
  border: 1px solid ${({ theme, chosen }) => (chosen === 'true' ? `${theme.palette.brand[100]}80` : 'transparent')};
  transition: 0.2s;
`;
