import isPropValid from '@emotion/is-prop-valid';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Palette } from '@mui/material';
import MuiTableRow, { TableRowProps } from '@mui/material/TableRow';

const StyledTableRow = styled(MuiTableRow, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ checked?: boolean; hoverBgColor?: string; bgColor?: string; checkedColor?: string; cursor?: string }>`
  height: 100%;
  box-sizing: border-box;
  cursor: ${({ cursor }) => cursor};

  ${({ hoverBgColor }) => `
  &:hover, 
  &:hover > .sticky > div {
   background-color: ${hoverBgColor} 
   }`}

  ${({ bgColor }) => `
  background-color: ${bgColor || 'inherit'};
  `}
  
  ${({ checked, theme }) =>
    checked
      ? `
      &,
      & > .sticky > div {
       background-color: ${theme.palette.accent['100']}50; 
       a { 
        color: ${theme.palette.brand['400']}; 
       } 
      }`
      : ''}
`;

interface ITableRowProps extends TableRowProps {
  checked?: boolean;
  checkedBgColor?: string;
  cursor?: string;
  hoverBgColor?: string | ((palette: Palette) => string);
  checkedColor?: string;
  bgColor?: string;
}

export const TableRow = ({ children, hoverBgColor, cursor, ...restProps }: ITableRowProps) => {
  const { palette } = useTheme();

  const resolvedHoverBgColor = typeof hoverBgColor === 'function' ? hoverBgColor?.(palette) : hoverBgColor;

  return (
    <StyledTableRow {...restProps} hoverBgColor={resolvedHoverBgColor} cursor={cursor}>
      {children}
    </StyledTableRow>
  );
};
