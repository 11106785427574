import { useMutation, UseMutationOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { exportCSVCampaign } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICampaign, ICustomAxiosError, IExportCampaignCSVRequest } from 'src/types';

export const useExportCSVCampaign = (
  campaignId: number,
  options?: UseMutationOptions<ICampaign, ICustomAxiosError, IExportCampaignCSVRequest>,
) => {
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation(
    ['campaign-export-CSV'],
    (body: IExportCampaignCSVRequest) => exportCSVCampaign({ campaignId, body }),
    {
      ...options,
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { exportCSV: mutate, ...rest };
};
