import dayjs from 'dayjs';
import { MouseEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faFacebookSquare, faInstagram, faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEllipsisH, faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Menu } from '@mui/material';

import { ActionButton, MenuItem, TableCell, TableRow, Tag, Tooltip, Typography } from 'src/components';
import { ModalTypes, PostStatus, PostTypes } from 'src/enums';
import { resolveStatus } from 'src/helpers';
import { useArchivePost, useDeletePost, useGetFacebookData } from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';
import { IGetPostsRequest, IPost } from 'src/types';

const StyledMenu = styled(Menu)`
  & .MuiList-root {
    padding: 0 !important;
  }

  & .MuiPaper-root {
    overflow: visible;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
    padding: 10px;
    color: ${({ theme }) => theme.palette.primary.wlLight};
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 20px;
`;

const InstagramIcon = styled(StyledIcon)`
  color: #fff;
  background: radial-gradient(circle at 30% 107%, #fdf497 0, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
  border-radius: 6px;
  padding: 0 1px;
`;

const renderIcon = (type: PostTypes) => {
  switch (type) {
    case PostTypes.FACEBOOK: {
      return <StyledIcon data-testid="facebook-icon" color="#1876f2" icon={faFacebookSquare} />;
    }
    case PostTypes.TWITTER: {
      return <StyledIcon data-testid="twitter-icon" icon={faXTwitter} />;
    }
    case PostTypes.LINKEDIN: {
      return <StyledIcon data-testid="linkedin-icon" color="#0d66c2" icon={faLinkedin} />;
    }
    case PostTypes.INSTAGRAM: {
      return <InstagramIcon data-testid="instagram-icon" icon={faInstagram} />;
    }
    default:
      return null;
  }
};

interface IPostsTableRowProps {
  row: IPost;
  posts: IPost[];
  getPostsQueryKeys: IGetPostsRequest;
}

export const PostsTableRow = ({ row, posts, getPostsQueryKeys }: IPostsTableRowProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { palette } = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();
  const [selectedPostId, setSelectedPostId] = useState<number | null>();
  const { archivePost } = useArchivePost();
  const { deletePost } = useDeletePost(getPostsQueryKeys.type);
  const { data: facebookResponse } = useGetFacebookData({});
  const { facebookData } = facebookResponse ?? {};

  const isMenuOpen = Boolean(anchorEl);
  const showAudience = !!row.facebookObjectType;
  const { label, tooltip, color, borderColor, bgColor } = resolveStatus(palette, row.status, row.statusMessage);

  const handleOpenMenu = (e: MouseEvent<HTMLDivElement>, postId: number) => {
    setSelectedPostId(postId);
    setAnchorEl(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedPostId(null);
  };

  const archive = () => {
    dispatch(
      openModal({
        headerText: 'Archive Post',
        descriptionText: 'Are you sure you want to archive this post?',
        onConfirm: () => {
          if (selectedPostId) {
            archivePost(selectedPostId);
          }
        },
      }),
    );
    handleCloseMenu();
  };

  const remove = () => {
    dispatch(
      openModal({
        headerText: 'Delete Post',
        descriptionText: 'Are you sure you want to delete this post?',
        onConfirm: () => {
          if (selectedPostId) {
            deletePost(selectedPostId);
          }
        },
      }),
    );

    handleCloseMenu();
  };

  const onRescheduleClick = () => {
    handleCloseMenu();
    dispatch(
      openModal({
        type: ModalTypes.RESCHEDULE_POST,
        params: { posts, postId: row.id, getPostsQueryKeys },
        closable: false,
      }),
    );
  };

  const getFacebookPostAudience = (type?: string, id?: string) => {
    if (!facebookData) {
      return '';
    }
    const { facebookPages, facebookGroups } = facebookData;

    const getFacebookName = (facebookPages: { id: string; name: string }[]) => {
      const matchedData = facebookPages.filter((x) => x.id === id);

      if (matchedData?.length > 0) {
        return matchedData[0].name;
      }

      return '';
    };

    switch (type) {
      case 'page':
        if (facebookPages) {
          return getFacebookName(facebookPages);
        }

        return 'Facebook page';
      case 'group':
        if (facebookGroups) {
          return getFacebookName(facebookGroups);
        }

        return 'Facebook group';
    }

    return '';
  };

  return (
    <>
      <TableRow key={row.id}>
        <TableCell>{renderIcon(row.postType)}</TableCell>
        <TableCell tooltip={row.title}>{row.title}</TableCell>
        {showAudience && (
          <TableCell data-testid="audience">
            {getFacebookPostAudience(row.facebookObjectType, row.facebookObjectId) || 'N/A'}
          </TableCell>
        )}
        <TableCell>{row.likes || 0}</TableCell>
        <TableCell>{row.comments || 0}</TableCell>
        <TableCell>{row.shares || 0}</TableCell>
        <TableCell>{row.views || 0}</TableCell>
        <TableCell>{dayjs(row.scheduledAt).format('MMMM Do YYYY')}</TableCell>
        <TableCell>{dayjs(row.scheduledAt).format('h:mm a')}</TableCell>
        <TableCell>
          <Tooltip title={tooltip}>
            <Tag borderColor={borderColor} bgColor={bgColor}>
              <Typography color={color}>{label}</Typography>
            </Tag>
          </Tooltip>
        </TableCell>
        <TableCell justifyContent="flex-end" sticky>
          <Box display="flex" justifyContent="flex-end" gap="10px">
            {row.postUrl && row.status !== PostStatus.DELETED && (
              <a href={row.postUrl} target="_blank" rel="noopener noreferrer">
                <ActionButton icon={faLink} />
              </a>
            )}

            <ActionButton
              data-testid="menu-button"
              onClick={(e) => handleOpenMenu(e, row.id)}
              icon={faEllipsisH}
              aria-haspopup="true"
              {...(isMenuOpen && {
                'aria-controls': 'basic-menu',
                'aria-expanded': 'true',
              })}
            />
            <StyledMenu
              data-testid="menu"
              open={isMenuOpen}
              anchorEl={anchorEl}
              onClose={handleCloseMenu}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={() => navigate(`/posts/${row.id}/details`)}>Details</MenuItem>
              {row.status === PostStatus.SKIPPED && <MenuItem onClick={onRescheduleClick}>Reschedule</MenuItem>}
              {row.status !== PostStatus.ARCHIVED && <MenuItem onClick={archive}>Archive</MenuItem>}
              <MenuItem onClick={remove}>Delete</MenuItem>
            </StyledMenu>
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};
