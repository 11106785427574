import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import styled from '@emotion/styled';
import { faFile, faFileImage, faFileVideo, faPaperclip, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip as MuiTooltip, TooltipProps } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { ActionButton, HiddenFileInput } from 'src/components';
import { POST_ATTACHMENTS_ERROR_MESSAGE } from 'src/constants';
import { PostAttachmentError } from 'src/enums';
import {
  checkDocumentPostAttachment,
  checkImagePostAttachment,
  checkVideoPostAttachment,
  uploadAttachmentToBucket,
} from 'src/helpers';
import { showToast } from 'src/store/toast.slice';
import { IPostAttachment, ISelectOption } from 'src/types';

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledMenu = styled(Menu)`
  .MuiList-root {
    padding: 10px;
    width: 160px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.wlLight};
  font-family: 'ProximaSoft', sans-serif;
  line-height: 16px;
  padding: 0;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
    background: initial;
  }
`;

const Label = styled.label`
  display: block;
  width: 100%;
  cursor: pointer;
  padding: 10px;
`;

const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip classes={{ tooltip: className }} {...props}>
    {props.children}
  </MuiTooltip>
))`
  background-color: #000;
  font-size: 12px;
  font-family: 'ProximaSoft', sans-serif;

  .MuiTooltip-arrow {
    &:before {
      background-color: #000;
    }
  }
`;

const AttachmentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 30px;
  width: 200px;
  border: 1px solid ${({ theme }) => theme.palette.light.dark};
  padding: 0 10px;
  border-radius: 5px;
  background-color: #fff;
`;

const FileName = styled.span`
  font-size: 14px;
  width: 150px;
  white-space: pre;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  font-family: 'ProximaSoft', sans-serif;
`;

const DeleteAttachmentIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;

export interface IAttachmentsProps {
  postTypes: Array<string>;
  audience: ISelectOption;
  onAttachmentsChange: (attachments: Array<IPostAttachment>) => void;
  setError: Dispatch<SetStateAction<string>>;
  isAttachmentLoading: boolean;
  setIsAttachmentLoading: Dispatch<SetStateAction<boolean>>;
  disabled?: boolean;
  attachments: Array<IPostAttachment>;
}

export const Attachments = ({
  postTypes,
  audience,
  setError,
  isAttachmentLoading,
  setIsAttachmentLoading,
  disabled = false,
  attachments = [],
  onAttachmentsChange,
}: IAttachmentsProps) => {
  const dispatch = useDispatch();
  const imageFileInputRef = useRef<HTMLInputElement | null>(null);
  const videoFileInputRef = useRef<HTMLInputElement | null>(null);
  const documentFileInputRef = useRef<HTMLInputElement | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = !!anchorEl;

  const postsWithInstagram = postTypes.includes('instagram');
  const postsWithTwitter = postTypes.includes('twitter');
  const postsWithLinkedin = postTypes.includes('linkedin');
  const postsWithFacebook = postTypes.includes('facebook');
  const postsWithLinkedinTwitter = audience.value === 'anyoneAndTwitter';

  useEffect(() => {
    if (
      postsWithInstagram &&
      attachments?.length &&
      !attachments[0].file_type.includes('image') &&
      !attachments[0].file_type.includes('video')
    ) {
      onAttachmentsChange([]);

      dispatch(
        showToast({
          type: 'error',
          message: 'Attachment removed from post. Unsupported file attached to post for Instagram',
        }),
      );
    }
  }, [attachments, dispatch, postTypes]);

  const openMenu = (event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const deleteAttachment = (url: string) => {
    const removeAttachment = attachments.find((attachment) => attachment.file_uri === url);

    if (removeAttachment) {
      onAttachmentsChange(attachments.filter((attachment) => attachment.file_uri !== removeAttachment.file_uri));

      switch (true) {
        case removeAttachment.file_type.includes('image'):
          if (imageFileInputRef.current) imageFileInputRef.current.value = '';
          break;
        case removeAttachment.file_type.includes('video'):
          if (videoFileInputRef.current) videoFileInputRef.current.value = '';
          break;
        case removeAttachment.file_type.includes('document'):
          if (documentFileInputRef.current) documentFileInputRef.current.value = '';
          break;
      }
    }
  };

  const uploadFiles = async (file?: File) => {
    // all checks are passed so attachment error can be removed
    setError('');

    if (!file || attachments.length) {
      return null;
    }

    setIsAttachmentLoading(true);

    await uploadAttachmentToBucket({
      file,
      onUrlError: () =>
        dispatch(
          showToast({
            type: 'error',
            message: 'Uploading failed! Please contact support!',
            autoCloseTime: 3000,
          }),
        ),
      onUploadError: () =>
        dispatch(
          showToast({
            type: 'error',
            message: "Couldn't upload the file!",
            autoCloseTime: 3000,
          }),
        ),
      onSuccess: (uploadFile) => {
        onAttachmentsChange([...attachments, uploadFile]);
        toast.dismiss();
        dispatch(
          showToast({
            type: 'success',
            message: 'File uploaded!',
            autoCloseTime: 3000,
            delay: 500,
          }),
        );
      },
      onUploadStart: () =>
        dispatch(
          showToast({
            type: 'info',
            message: 'Uploading file! Please wait!',
            autoCloseTime: 30000,
          }),
        ),
    });

    setIsAttachmentLoading(false);

    return closeMenu();
  };

  const showUploadAttachmentError = (reason: PostAttachmentError) => {
    const message = POST_ATTACHMENTS_ERROR_MESSAGE[reason];

    if (message) {
      setError(message);
      dispatch(
        showToast({
          type: 'error',
          message,
          autoCloseTime: 3000,
        }),
      );
    }
  };

  const handleFileUpload = async (type: string) => {
    const file = (() => {
      switch (type) {
        case 'video':
          return videoFileInputRef?.current?.files?.[0];
        case 'document':
          return documentFileInputRef?.current?.files?.[0];
        default:
          return imageFileInputRef?.current?.files?.[0];
      }
    })();

    if (!file) {
      return;
    }

    let checkAttachment;

    if (file?.type?.includes('image')) {
      checkAttachment = checkImagePostAttachment;
    } else if (file?.type?.includes('video')) {
      checkAttachment = checkVideoPostAttachment;
    } else if (file?.type?.includes('application')) {
      checkAttachment = checkDocumentPostAttachment;
    }

    if (checkAttachment) {
      return checkAttachment(
        file,
        { onError: showUploadAttachmentError, onSuccess: uploadFiles },
        {
          postsWithFacebook,
          postsWithInstagram,
          postsWithLinkedin,
          postsWithLinkedinTwitter,
          postsWithTwitter,
        },
      );
    }
  };

  useEffect(() => {
    if (videoFileInputRef.current?.files?.[0]) {
      handleFileUpload('video');
    }

    if (imageFileInputRef.current?.files?.[0]) {
      handleFileUpload('image');
    }

    if (documentFileInputRef.current?.files?.[0]) {
      handleFileUpload('document');
    }
  }, [postTypes.length]);

  return (
    <>
      <ButtonContainer>
        <ActionButton
          id="dropdown-button"
          data-testid="dropdown-button"
          aria-haspopup="true"
          {...(open && {
            'aria-controls': 'dropdown-menu',
            'aria-expanded': 'true',
          })}
          disabled={!!attachments.length || !postTypes.length || isAttachmentLoading || disabled}
          onClick={openMenu}
          tooltip={!postTypes.length ? 'Please select one of the platform first' : 'Attachments'}
          icon={faPaperclip}
        />
        <div>
          {attachments.map((attachment) => (
            <AttachmentContainer key={attachment.file_uri}>
              <Tooltip title={attachment.file_name} placement="top" arrow>
                <FileName>{attachment.file_name}</FileName>
              </Tooltip>
              {disabled || (
                <DeleteAttachmentIcon
                  data-testid={`delete-${attachment.file_name}`}
                  icon={faTimes}
                  onClick={() => deleteAttachment(attachment.file_uri)}
                />
              )}
            </AttachmentContainer>
          ))}
        </div>
      </ButtonContainer>
      <StyledMenu
        id="dropdown-menu"
        data-testid="dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={closeMenu}
        keepMounted
        MenuListProps={{
          'aria-labelledby': 'dropdown-button',
        }}
      >
        <StyledMenuItem disabled={isAttachmentLoading}>
          <HiddenFileInput
            type="file"
            data-testid="image-item"
            accept="image/gif,image/jpeg,image/jpg,image/png"
            name="image-input"
            id="image-input"
            ref={imageFileInputRef}
            onChange={() => handleFileUpload('image')}
          />
          <Label htmlFor="image-input">
            <Icon icon={faFileImage} />
            Image
          </Label>
        </StyledMenuItem>
        <StyledMenuItem disabled={isAttachmentLoading}>
          <HiddenFileInput
            type="file"
            data-testid="video-item"
            accept="video/*,video/mp4,video/avi,video/x-ms-wmv,video/x-flv,video/mpeg,video/quicktime,video/x-m4v"
            name="video-input"
            id="video-input"
            ref={videoFileInputRef}
            onChange={() => handleFileUpload('video')}
          />
          <Label htmlFor="video-input">
            <Icon icon={faFileVideo} />
            Video
          </Label>
        </StyledMenuItem>
        {!postsWithFacebook && !postsWithTwitter && !postsWithInstagram && (
          <StyledMenuItem disabled={isAttachmentLoading}>
            <HiddenFileInput
              type="file"
              data-testid="document-item"
              accept=".doc,.docx,.pdf,.ppt,.pptx"
              name="document-input"
              id="document-input"
              ref={documentFileInputRef}
              onChange={() => handleFileUpload('document')}
            />
            <Label htmlFor="document-input">
              <Icon icon={faFile} />
              Document
            </Label>
          </StyledMenuItem>
        )}
      </StyledMenu>
    </>
  );
};
