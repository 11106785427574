import { ChangeEvent, useEffect } from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { Grid } from '@mui/material';

import { Button, Input, Typography } from 'src/components';
import { VAT_EXAMPLES, VAT_NAMES } from 'src/constants';
import { useGetBillingInfo, useUpdateCompanyInfo } from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';
import { ICompany } from 'src/types';
import { ModalBody, ModalFooter, ModalHeader } from '../_components';

const selectorStyle = css`
  margin-top: 5px;
  width: 100%;
  height: 42px;
  padding-left: 10px;
  border: 1px solid var(--light-dark);
  border-radius: 4px;
  font-size: 14px;
  color: var(--primary-light);
`;

const labelStyle = css`
  color: var(--violet-dark);
  font-size: 14px;
  font-weight: 600;
`;

export const ModalCompanyInfo = () => {
  const { palette } = useTheme();
  const dispatch = useDispatch();

  const { billingInfo } = useGetBillingInfo();

  const {
    register,
    reset,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
    resetField,
    setError,
  } = useForm<ICompany>({
    defaultValues: {
      company: '',
      vat: '',
      vatType: '',
      address: {
        country: '',
        region: '',
        city: '',
        line1: '',
        line2: '',
        postal_code: '',
      },
    },
  });

  const close = () => dispatch(closeModal());

  const { updateCompany, isLoading } = useUpdateCompanyInfo({
    onSuccess: () => {
      close();
    },
    onError: (err) => {
      if (err?.message.includes('Invalid value for')) {
        setError('vat', { type: 'custom', message: 'Please add a valid Tax ID Number' });
      }
    },
  });

  const submit = (values: ICompany) => {
    updateCompany(values);
  };

  const watchVatType = watch('vatType');
  const watchCountry = watch('address.country');

  useEffect(() => {
    if (billingInfo) {
      const { company, vat, vatType, address } = billingInfo;

      reset({
        company,
        vat,
        vatType,
        address: {
          country: address?.country,
          region: address?.region || address?.state,
          city: address?.city,
          line1: address?.line1,
          line2: address?.line2,
          postal_code: address?.postal_code,
        },
      });
    }
  }, [billingInfo]);

  return (
    <form onSubmit={handleSubmit(submit)}>
      <ModalHeader py="11px">Company Info</ModalHeader>
      <ModalBody color={palette.violet.dark}>
        <Grid container columnSpacing="30px" rowSpacing="20px">
          <Grid item xs={12}>
            <Input
              name="company"
              register={register}
              parameters={{
                required: 'Please add a company name',
                maxLength: { value: 350, message: 'Company name must be at most 350 characters' },
              }}
              label="Company Name:"
              placeholder="Company Name"
              labelStyle={labelStyle}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <label className={labelStyle}>Country:</label>
            <CountryDropdown
              value={watchCountry}
              classes={selectorStyle}
              onChange={(country) => setValue('address.country', country)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <label className={labelStyle}>Region:</label>
            <RegionDropdown
              value={watch('address.region')}
              country={watchCountry}
              classes={selectorStyle}
              onChange={(region) => setValue('address.region', region)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Input
              name="address.city"
              register={register}
              label="City:"
              placeholder="City, district, suburb, town, or village."
              labelStyle={labelStyle}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Input
              name="address.line1"
              register={register}
              label="Address Line 1:"
              placeholder="Address line 1 (e.g., street, PO Box, or company name)."
              labelStyle={labelStyle}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Input
              name="address.line2"
              register={register}
              label="Address Line 2:"
              placeholder="Address line 2 (e.g., apartment, suite, unit, or building)."
              labelStyle={labelStyle}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Input
              name="address.postal_code"
              register={register}
              label="Postal Code:"
              placeholder="ZIP or postal code."
              labelStyle={labelStyle}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <label className={labelStyle}>Tax Type:</label>
            <select
              className={selectorStyle}
              {...register('vatType', {
                required: { message: 'Please select a tax type', value: !!watch('vat') },
                onChange: (e: ChangeEvent<HTMLSelectElement>) => {
                  if (!e.target.value) {
                    resetField('vat');
                  }
                },
              })}
            >
              <option value="">Select</option>
              {Object.entries(VAT_NAMES).map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Input
              name="vat"
              register={register}
              parameters={{ required: { value: !!watchVatType, message: 'Please add a tax ID number' } }}
              label="Tax Number:"
              placeholder={watchVatType ? VAT_EXAMPLES[watchVatType] : 'Tax ID number'}
              disabled={!watchVatType}
              labelStyle={labelStyle}
            />
          </Grid>
        </Grid>

        <Typography color="error.light" fontSize="16px" mt="20px">
          {errors.company?.message || errors.vatType?.message || errors.vat?.message}
        </Typography>

        <Typography fontSize="11px" color="violet.dark" mt="20px">
          *This changes will take effect in your upcoming invoices
        </Typography>
      </ModalBody>
      <ModalFooter py="20px" alignToTheEnd noBorder>
        <Button variant="gray" onClick={close}>
          Cancel
        </Button>
        <Button type="submit" processing={isLoading}>
          Save details
        </Button>
      </ModalFooter>
    </form>
  );
};
