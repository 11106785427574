import classNames from 'classnames';
import React, { useState } from 'react';
import Select, { components, ContainerProps, Options } from 'react-select';
import Creatable from 'react-select/creatable';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Collapse } from '@mui/material';

import { ReactComponent as ArrowDown } from 'src/assets/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'src/assets/icons/arrow-up.svg';
import { ReactComponent as CheckCircle } from 'src/assets/icons/check-circle.svg';
import { ReactComponent as DeniedCircle } from 'src/assets/icons/denied-circle.svg';
import { ReactComponent as XMarkIcon } from 'src/assets/icons/x-mark.svg';
import { Tooltip, Typography } from 'src/components';
import { IConnectionsParsedValue, ILeadsParsedValue, IParsedValueOption } from 'src/types';

const FilterContainer = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray[100]};
  padding: 14px 16px;
`;

const StyledSelectCreatable = styled(Creatable)`
  margin-top: 12px;
`;

const StyledSelect = styled(Select)`
  margin-top: 12px;
`;

const OptionContainer = styled(Box)`
  flex-wrap: nowrap;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: min-content;
  max-width: 100%;
  border-radius: 16px;
  padding: 0 4px;
  color: #ffffff;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.success[600]};
  border: 1px solid ${({ theme }) => theme.palette.success[600]};
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.success[700]};
    border-color: ${({ theme }) => theme.palette.success[800]};
  }

  &.exclude {
    background-color: ${({ theme }) => theme.palette.error[500]};
    border-color: ${({ theme }) => theme.palette.error[500]};

    &:hover {
      background-color: ${({ theme }) => theme.palette.error[600]};
      border-color: ${({ theme }) => theme.palette.error[700]};
    }
  }

  & svg {
    flex-shrink: 0;
  }
`;

const OptionLabel = styled.span`
  margin: 0 5px;
  font-size: 14px;
  line-height: 26px;
  height: 25px;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
`;

export interface IFilterInputProps<T> {
  label: string;
  cropOption?: boolean;
  placeholder: string;
  parsedValue: IParsedValueOption[];
  valueName: keyof T;
  options?: { value: string; label: string }[];
  onParsedValueChange: (valueName: keyof T, value: IParsedValueOption[]) => void;
}

export const FilterInput = <T extends IConnectionsParsedValue | ILeadsParsedValue>({
  valueName,
  label,
  cropOption,
  placeholder,
  parsedValue,
  options,
  onParsedValueChange,
  ...restProps
}: IFilterInputProps<T>) => {
  const { palette } = useTheme();

  const [isExpanded, setIsExpanded] = useState(false);

  const Component = options ? StyledSelect : StyledSelectCreatable;

  const onValueChange = (values: IParsedValueOption[]) => {
    onParsedValueChange(valueName, values);
  };

  const SelectContainer = ({ children, ...props }: ContainerProps) => {
    const { getValue, options } = props as ContainerProps<IParsedValueOption>;

    const renderMultiValue = ({ value, exclude }: IParsedValueOption, index: number) => {
      const Exclude = exclude ? DeniedCircle : CheckCircle;

      const label =
        (options as Options<IParsedValueOption>)?.find((option) => option && option?.value === value)?.label || value;

      return (
        <OptionContainer
          className={classNames({ exclude })}
          onClick={(e) => {
            e.stopPropagation();

            const newValue = [...parsedValue].map((value, indx) =>
              indx === index ? { ...value, exclude: !value?.exclude } : value,
            );

            onValueChange(newValue);
          }}
        >
          <Tooltip title="Click to include / exclude">
            <Box display="flex" alignItems="center" overflow="hidden">
              <Exclude cursor="pointer" />
              <OptionLabel>{label}</OptionLabel>
            </Box>
          </Tooltip>

          <XMarkIcon
            height="100%"
            cursor="pointer"
            color="#ffffff"
            onClick={(e) => {
              e.stopPropagation();

              const newValue = (getValue() as { value: string; exclude: boolean }[]).filter((_v, i) => i !== index);

              onValueChange(newValue);
            }}
          />
        </OptionContainer>
      );
    };

    const values = getValue();

    return (
      <components.SelectContainer {...props}>
        {children}
        <Box display="flex" flexWrap="wrap" gap="2px" mt={values.length && '10px'}>
          {values.map(renderMultiValue)}
        </Box>
      </components.SelectContainer>
    );
  };

  return (
    <FilterContainer>
      <Box
        display="flex"
        justifyContent="space-between"
        onClick={() => setIsExpanded((prev) => !prev)}
        sx={{ cursor: 'pointer' }}
      >
        <Typography semibold lineHeight="22px" fontSize="16px" color="gray.700">
          {label}
        </Typography>
        <Box display="flex" alignItems="center" gap="10px">
          {parsedValue?.length > 0 && (
            <Typography
              color="#2B5DDB"
              fontSize="14px"
              semibold
              onClick={(e) => {
                e.stopPropagation();
                onValueChange([]);
              }}
            >
              Clear ({parsedValue.length})
            </Typography>
          )}
          {isExpanded ? <ArrowUp /> : <ArrowDown />}
        </Box>
      </Box>
      <Collapse in={isExpanded} timeout="auto">
        <Component
          isMulti
          placeholder={placeholder}
          controlShouldRenderValue={false}
          components={{
            SelectContainer: SelectContainer,
            DropdownIndicator: options ? components.DropdownIndicator : null,
            MultiValue: () => null,
            ClearIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          {...(!options && {
            noOptionsMessage: () => 'Type and hit Return',
          })}
          styles={{
            control: (provided, state) => ({
              ...provided,
              width: '99%',
              minHeight: '32px',
              border: `2px solid ${palette.light.dark} !important`,
              boxShadow: state.isFocused ? 'box-shadow: 0 1px 0 rgb(0 0 0 / 6%) !important' : 'none',
            }),
            input: (provided) => ({
              ...provided,
              padding: 0,
              color: '#333',
            }),
            placeholder: (provided) => ({
              ...provided,
              fontSize: 14,
              color: 'var(--violet-main)',
            }),
            option: (provided, state) => ({
              ...provided,
              color: state.isFocused ? palette.primary.main : palette.text.primary,
              backgroundColor: '#ffffff',
              cursor: 'pointer',
              ...(cropOption && { whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }),
            }),
            ...(!options && {
              noOptionsMessage: (provided) => ({
                ...provided,
                padding: '2px 8px',
                fontSize: '16px',
                textAlign: 'left',
                height: '36px',
              }),
            }),
            menu: (provided) => ({
              ...provided,
              fontSize: '14px',
              position: 'relative',
            }),
            menuList: (provided) => ({
              ...provided,
              maxHeight: '250px',
            }),
            valueContainer: (provided) => ({ ...provided, gap: '2px', maxHeight: '150px' }),
          }}
          value={parsedValue}
          {...(options && { options })}
          {...restProps}
          onChange={(_e, actionDetails) => {
            const { value } = (actionDetails.option || {}) as IParsedValueOption;
            if (value) {
              onValueChange([...parsedValue, { value, exclude: false }]);
            }
          }}
        />
      </Collapse>
    </FilterContainer>
  );
};
