import dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import {
  Checkbox,
  Spinner,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
} from 'src/components';
import { CONNECTIONS_SORTABLE_COLUMNS, DEFAULT_PAGE, DEFAULT_PER_PAGE } from 'src/constants';
import { checkConnectionsFilter } from 'src/helpers';
import { useAppSelector } from 'src/hooks';
import {
  useGetUserAccount,
  useGetUserCloudBotInfo,
  usePostConnections,
  useUpdateConnectionsColumnsPosition,
} from 'src/reactQueries';
import { setBlacklistConnectionSelectedLeads, updateBlacklistConnectionsRequestParams } from 'src/store';
import { setConnectionSelectedLeads, updateConnectionsRequestParams } from 'src/store/connections.slice';
import {
  IConnectionColumnsPosition,
  IConvertedConnection,
  ISelectedLead,
  ITablePagination,
  TSortOrder,
} from 'src/types';
import { NotSynced, RearrangeColumns } from '../_components';
import { ConnectionsFilters } from './ConnectionsFilters';
import { ConnectionsListItem } from './ConnectionsListItem';

const StyledTable = styled(Table)`
  border-collapse: separate;
`;

const StyledSpinner = styled(Spinner)`
  margin-top: 30px;
  flex: auto;
  align-items: baseline;
`;

interface ConnectionsListProps {
  isExcluded?: boolean;
  openLeadInfo: (entityUrn?: string) => void;
}

export const ConnectionsList = memo(({ isExcluded = false, openLeadInfo }: ConnectionsListProps) => {
  const dispatch = useDispatch();

  const {
    request,
    exportData: { selectedLeads },
    columns,
  } = useAppSelector(
    (state) =>
      isExcluded
        ? {
            request: state.blacklistConnections.request,
            exportData: state.blacklistConnections.exportData,
            columns: state.connections.connectionColumns,
          }
        : {
            request: state.connections.request,
            exportData: state.connections.exportData,
            columns: state.connections.connectionColumns,
          },
    isEqual,
  );

  const { page = DEFAULT_PAGE, perPage = DEFAULT_PER_PAGE, sortField, sortOrder, selectedOnly } = request;

  const viewedConnectionColumns = useMemo(() => columns.slice(0, 7), [columns]);
  const isConnectionsFiltered = useMemo(() => checkConnectionsFilter(request, isExcluded), [request, isExcluded]);

  const updateParamsMethod = isExcluded ? updateBlacklistConnectionsRequestParams : updateConnectionsRequestParams;
  const updateSelectedMethod = isExcluded ? setBlacklistConnectionSelectedLeads : setConnectionSelectedLeads;

  const currentUser = useGetUserAccount();
  const { userCloudBotInfo, isLoading: isLoadingCloutBotInfo } = useGetUserCloudBotInfo({ refetchOnMount: true });

  const { updateConnectionsColumnsPosition } = useUpdateConnectionsColumnsPosition();
  const { data, isLoading } = usePostConnections(
    {
      ...request,
      search: request?.search?.trim(),
      entityUrns: selectedOnly ? selectedLeads.map((lead) => lead.entityUrn) : [],
    },
    {
      refetchOnMount: true,
      onSuccess: (data) => {
        if (isExcluded && !data.connections?.length && request.page && request?.page > DEFAULT_PAGE) {
          dispatch(updateParamsMethod({ page: DEFAULT_PAGE }));
        }
      },
    },
  );

  const totalConnections = data?.total;

  const connections = useMemo<IConvertedConnection[]>(
    () =>
      data?.connections?.map((connection) => ({
        entityUrn: connection.entityUrn || '',
        picture: connection.picture,
        publicIdentifier: connection.publicIdentifier,
        isExcluded: Boolean(connection.userNetwork?.isExcluded || connection?.isExcluded),
        isPremium: Boolean(connection?.isPremium),
        name: connection.name,
        company: connection.companyName || '',
        title: connection.title || '',
        industry: connection.industryName,
        geography: connection.locationName || '',
        phone: connection.phone,
        connectedAt: connection.userNetwork?.connectedAt
          ? dayjs.utc(connection.userNetwork.connectedAt).local().format('ddd, D MMM YYYY')
          : '',
        language: connection.languages || '',
        skill: connection.skills || '',
        school: connection.schoolName || '',
        field_of_study: connection.fieldOfStudy || '',
        tags: connection.tags,
        notes: connection.userNetwork?.notes || '',
        email: connection.email || '',
        campaignName: connection.campaignName || '',
      })) ?? [],
    [data?.connections],
  );

  const includeEntityUrn = useCallback(
    (entityUrn?: string) => !!entityUrn && selectedLeads.some((lead) => lead.entityUrn === entityUrn),
    [selectedLeads],
  );

  const selectedLeadsOnCurrentPage = connections?.map(({ entityUrn, isExcluded }) => ({ entityUrn, isExcluded })) || [];
  const isEverySelected = selectedLeadsOnCurrentPage.every(({ entityUrn }) => includeEntityUrn(entityUrn));

  const isSelectedAll = !!totalConnections && isEverySelected;

  const sortingProps = {
    sortField,
    sortOrder,
    onSortFieldChange: (field: string, order: string) => {
      dispatch(updateParamsMethod({ sortField: field, sortOrder: order as TSortOrder }));
    },
  };

  const onSelectAllCheckboxChange = () => {
    if (isSelectedAll) {
      return dispatch(
        updateSelectedMethod(
          selectedLeads.filter(
            ({ entityUrn }) => !selectedLeadsOnCurrentPage.some((lead) => lead.entityUrn === entityUrn),
          ),
        ),
      );
    }

    return dispatch(
      updateSelectedMethod([
        ...selectedLeads,
        ...selectedLeadsOnCurrentPage.filter(({ entityUrn }) => !includeEntityUrn(entityUrn)),
      ]),
    );
  };

  const onCheckboxChange = (selectedLead: ISelectedLead) => {
    if (includeEntityUrn(selectedLead.entityUrn)) {
      return dispatch(updateSelectedMethod(selectedLeads.filter((item) => item.entityUrn !== selectedLead.entityUrn)));
    }

    return dispatch(updateSelectedMethod([...selectedLeads, selectedLead]));
  };

  const paginationChangeHandler = (pagination: ITablePagination) => {
    dispatch(updateParamsMethod(pagination));
  };

  if (currentUser?.isLoading || isLoadingCloutBotInfo) {
    return (
      <Box display="flex" width="100%">
        <ConnectionsFilters />
        <StyledSpinner size="40px" />
      </Box>
    );
  }

  if (!currentUser?.userMe?.isLinkedInLoginValid || !userCloudBotInfo?.lastUpdateConnections) {
    return (
      <Box display="flex" width="100%">
        <ConnectionsFilters />
        <Box flex="auto">
          <NotSynced
            isSyncInProgress={!!currentUser?.userMe?.isLinkedInLoginValid && !userCloudBotInfo?.lastUpdateConnections}
          />
        </Box>
      </Box>
    );
  }

  return (
    <>
      <ConnectionsFilters isBlacklist={isExcluded} />
      <Box p="20px 15px 80px" maxWidth="calc(100% - 310px)" overflow="scroll" width="100%">
        <StyledTable disabled={false} processing={isLoading}>
          <TableHead>
            <TableRow>
              <TableHeadCell width="40px">
                <Checkbox
                  checkIcon={selectedLeads.length > 0 && isSelectedAll ? 'check-mark' : 'dash'}
                  data-testid="main-checkbox"
                  checked={selectedLeads.length > 0}
                  onChange={onSelectAllCheckboxChange}
                />
              </TableHeadCell>
              <TableHeadCell width="55px" />
              {viewedConnectionColumns.map(({ key, label }) => {
                const sortingColumn = CONNECTIONS_SORTABLE_COLUMNS.find((column) => column.key === key);

                return (
                  <TableHeadCell
                    key={key}
                    width="250px"
                    {...(sortingColumn?.fieldName && {
                      name: sortingColumn.fieldName,
                      sorting: sortingProps,
                    })}
                  >
                    {label}
                  </TableHeadCell>
                );
              })}
              <TableHeadCell width="134px" sticky justifyContent="flex-end">
                <RearrangeColumns
                  columns={columns}
                  separator={6}
                  onChange={(result) => updateConnectionsColumnsPosition(result as IConnectionColumnsPosition[])}
                />
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody
            placeholder={
              !connections.length &&
              (isConnectionsFiltered ? 'No profiles meet your search criteria' : 'No connections yet.')
            }
          >
            {connections?.map((connection) => (
              <ConnectionsListItem
                key={connection.entityUrn}
                connection={connection}
                onItemClick={openLeadInfo}
                onCheckboxClick={onCheckboxChange}
                columns={viewedConnectionColumns}
                isSelected={includeEntityUrn(connection?.entityUrn)}
              />
            ))}
          </TableBody>
        </StyledTable>
        <TablePagination
          page={page}
          perPage={perPage}
          total={totalConnections}
          onPaginationChange={paginationChangeHandler}
        />
      </Box>
    </>
  );
});
