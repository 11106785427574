import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { getUserAccount } from 'src/api';
import { NotificationTypes } from 'src/enums';
import { checkShowLIIntegrationError } from 'src/helpers';
import { useAppSelector, useAuth } from 'src/hooks';
import { setConnectionColumns } from 'src/store/connections.slice';
import { setLeadColumns } from 'src/store/lead.slice';
import { addNotification, removeAllNotifications, removeNotificationByType } from 'src/store/notification.slice';
import { removeUser, setUser } from 'src/store/user.slice';
import { ICustomAxiosError, IUser } from 'src/types';

export const useGetUserAccount = <TData extends IUser>(options?: UseQueryOptions<IUser, ICustomAxiosError, TData>) => {
  const dispatch = useDispatch();

  const { logout } = useAuth();

  const queryClient = useQueryClient();

  const { isAuth, isImpersonate } = useAppSelector((state) => state.user);

  const { data, ...rest } = useQuery<IUser, ICustomAxiosError, TData>(['get-user-profile'], getUserAccount, {
    ...options,
    enabled: isAuth,
    onSuccess: (userData) => {
      if ((userData.needToLogout && !isImpersonate) || !userData.lastTeamId) {
        return logout();
      }

      dispatch(setUser(userData));
      dispatch(setLeadColumns(userData?.leadColumns));
      dispatch(setConnectionColumns(userData?.connectionColumns));

      options?.onSuccess && options.onSuccess(userData);

      if (userData?.linkedInPendingInvites >= 2500) {
        dispatch(addNotification({ type: NotificationTypes.LINKEDIN_MAX_PENDING_INVITES }));
      }

      if (checkShowLIIntegrationError(userData)) {
        dispatch(addNotification({ type: NotificationTypes.LINKEDIN_ERROR }));
      } else {
        dispatch(removeNotificationByType({ type: NotificationTypes.LINKEDIN_ERROR }));
      }

      if (userData?.cloudBotStatus?.data?.error === 'RESET_PASSWORD_VIEW') {
        dispatch(addNotification({ type: NotificationTypes.LINKEDIN_PASSWORD_RESET }));
      } else {
        dispatch(removeNotificationByType({ type: NotificationTypes.LINKEDIN_PASSWORD_RESET }));
      }

      if (userData?.outlookError) {
        dispatch(addNotification({ type: NotificationTypes.OUTLOOK_INTEGRATION_ERROR }));
      } else {
        dispatch(removeNotificationByType({ type: NotificationTypes.OUTLOOK_INTEGRATION_ERROR }));
      }
    },
    onError: (error) => {
      if (options?.onError) {
        options.onError(error);
      }

      if (error.statusCode === 401) {
        dispatch(removeAllNotifications());
        dispatch(removeUser());
        queryClient.removeQueries();
      }
    },
  });

  return { userMe: data, ...rest };
};
