import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { HeaderContainer, HeaderDescription, HeaderTitle, HeaderVideo, Link, Toggle, Typography } from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features } from 'src/enums';
import { useCompanyTheme, useTeamPlan } from 'src/hooks';
import { useWhiteLabel } from 'src/hooks/useWhiteLabel';
import { useToggleWhiteLabelStatus } from 'src/reactQueries';
import { openModal } from 'src/store';
import { Branding } from './Branding';
import { CustomDomain } from './CustomDomain';
import { CustomEmail } from './CustomEmail';

const Wrapper = styled(Box)`
  border-radius: 8px;
  background: #fff;
  border: 1px solid #e8e8e8;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 24px;
`;

export const WhiteLabel = () => {
  const dispatch = useDispatch();

  const { checkFeature } = useTeamPlan();
  const isWhiteLabelAllowed = checkFeature(Features.whiteLabel);

  const { shortCompanyName } = useCompanyTheme();
  const { whitelabel } = useWhiteLabel();
  const { toggleStatus } = useToggleWhiteLabelStatus();

  const isWhiteLabelConfigured = whitelabel.withBranding || whitelabel.withCustomDomain || whitelabel.withCustomEmail;

  const onToggleWhiteLabel = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      toggleStatus();
    } else {
      dispatch(
        openModal({
          headerText: isWhiteLabelConfigured
            ? 'Are you sure you want to deactivate White Label?'
            : 'Discard White Label Changes?',
          descriptionText: isWhiteLabelConfigured
            ? 'Deactivating the White Label feature will remove branding, custom domain, and custom email address settings across all your accounts. Are you sure you want to proceed?'
            : 'No specific changes have been configured yet. Are you sure you want to dismiss this set up?',
          confirmButtonLabel: 'Yes, proceed',
          confirmButtonStyles: {
            width: '150px',
          },
          onConfirm: toggleStatus,
        }),
      );
    }
  };

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>White Label</HeaderTitle>
        <HeaderDescription>
          Customize {shortCompanyName}'s appearance with your Brand's Colors, Logo, and URL.
          <Link
            leftIcon
            underline
            external
            openNewTab
            to="https://meetalfred.com/help/en/collections/5773532-white-label"
          >
            Learn how it works
          </Link>
          <HeaderVideo src="https://player.vimeo.com/video/855096337" />
        </HeaderDescription>
      </HeaderContainer>
      <Box display="flex" flexDirection="column" gap="16px" m="15px" pb="80px">
        <Wrapper>
          <Box>
            <Typography fontSize="16px" lineHeight="22px" semibold>
              White Label
            </Typography>
          </Box>
          <Toggle
            disabled={!isWhiteLabelAllowed}
            checked={isWhiteLabelAllowed && whitelabel.isActive}
            onChange={onToggleWhiteLabel}
            tooltip={{ title: isWhiteLabelAllowed ? '' : PLAN_TOOLTIPS.agency }}
          />
        </Wrapper>
        {whitelabel.isActive && (
          <>
            <Branding />
            <CustomDomain />
            <CustomEmail />
          </>
        )}
      </Box>
    </>
  );
};
