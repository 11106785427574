import { useState } from 'react';
import { Box } from '@mui/material';

import { Table, TableBody, TableHead, TableHeadCell, TablePagination, TableRow } from 'src/components';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from 'src/constants';
import { useGetTags } from 'src/reactQueries';
import { IGetTagsRequest, ITablePagination } from 'src/types';
import { TagTableRow } from './TagTableRow';

export const TagTable = () => {
  const [pageNum, setPageNum] = useState<number>(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState<number>(DEFAULT_PER_PAGE);
  const [sortField, setSortField] = useState<IGetTagsRequest['sortField']>('tag');
  const [sortOrder, setSortOrder] = useState<IGetTagsRequest['sortOrder']>('ASC');

  const { data, isLoading } = useGetTags(
    {
      sortField,
      sortOrder,
      page: pageNum,
      perPage: rowsPerPage,
    },
    {
      enabled: true,
      onSuccess: (data) => {
        if (!data.tags?.length && pageNum > DEFAULT_PAGE) {
          setPageNum(DEFAULT_PAGE);
        }
      },
    },
  );

  const onSortFieldChange = (field: string, order: string) => {
    setSortField(field as IGetTagsRequest['sortField']);
    setSortOrder(order as 'DESC' | 'ASC');
  };

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPageNum(pagination.page);
    setRowsPerPage(pagination.perPage);
  };

  return (
    <Box padding="15px 15px 80px">
      <Table processing={isLoading}>
        <TableHead>
          <TableRow>
            <TableHeadCell name="tag" sorting={{ sortOrder, sortField, onSortFieldChange }}>
              Tag
            </TableHeadCell>
            <TableHeadCell
              name="leadsCount"
              justifyContent="space-between"
              sorting={{ sortOrder, sortField, onSortFieldChange }}
            >
              Tagged Leads
            </TableHeadCell>
            <TableHeadCell
              name="connectionsCount"
              justifyContent="space-between"
              sorting={{ sortOrder, sortField, onSortFieldChange }}
            >
              Tagged Connections
            </TableHeadCell>
            <TableHeadCell width="80px" justifyContent="space-between">
              Color
            </TableHeadCell>
            <TableHeadCell width="100px" />
          </TableRow>
        </TableHead>

        <TableBody placeholder={!data?.total && 'No tags yet'}>
          {data?.tags.map((tagItem) => {
            return <TagTableRow key={tagItem.id} {...tagItem} />;
          })}
        </TableBody>
      </Table>

      <TablePagination
        page={pageNum}
        perPage={rowsPerPage}
        total={data?.total}
        onPaginationChange={paginationChangeHandler}
      />
    </Box>
  );
};
