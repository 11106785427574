import { UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button, Toggle, Typography } from 'src/components';
import { ModalTypes } from 'src/enums';
import { useCompanyTheme } from 'src/hooks';
import { useGetMailSettings, useGetUsersEmail, useSendTestEmail } from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';
import { ISequence } from 'src/types';
import { SequenceForm } from '../SequenceForm/SequenceForm';
import { Tags } from '../Tags';

const ToggleBox = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  height: 20px;
`;

const Label = styled.div`
  color: #333;
  font-size: 14px;
  margin: 0 15px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.palette.light.dark};
  margin: 20px -20px;
`;

interface ISendEmailProps extends UseFormReturn<ISequence> {
  index: number;
  customSnippets: Record<string, string>;
  isMultichannelCampaign: boolean;
}

export const SendEmail = ({ index, customSnippets, isMultichannelCampaign, ...sequenceForm }: ISendEmailProps) => {
  const dispatch = useDispatch();
  const { shortCompanyName } = useCompanyTheme();

  const { userEmail, isLoading: isLoadingUsersEmail } = useGetUsersEmail();
  const { mailSettings, isMailLoading } = useGetMailSettings();

  const { sendTestEmail } = useSendTestEmail();

  const { watch, setValue, getValues } = sequenceForm;

  const watchGmailSignature = !!watch(`sequence.${index}.gmail_signature`);

  const snippetOptions = Object.keys(customSnippets).map((key) => ({ label: key, value: key }));

  const isLoadingEmailIntegrations = isLoadingUsersEmail || isMailLoading;
  const isTestEmailDisabled =
    isLoadingEmailIntegrations ||
    (!userEmail?.gmailEnabled && !userEmail?.outlookEnabled && !mailSettings?.isConnected);

  const clickHandler = () => {
    dispatch(
      openModal({
        type: ModalTypes.SIGNATURE,
        headerText: 'Setup your Email Signature',
      }),
    );
  };

  const testEmailClickHandler = () => {
    dispatch(
      openModal({
        type: ModalTypes.SEND_TEST_EMAIL,
        headerText: 'Send test email',
        onConfirm: (email: string) => {
          const { attachments, message, subject, gmail_signature } = getValues(`sequence.${index}`);

          sendTestEmail({ sendTo: email, attachments, message, subject, includeSignature: gmail_signature });
        },
      }),
    );
  };

  return (
    <>
      <SequenceForm
        title="Subject"
        watchField="subject"
        config={{
          withTemplates: false,
          type: 'input',
        }}
        index={index}
        snippetOptions={snippetOptions}
        {...sequenceForm}
      />
      <Box my="20px">
        <SequenceForm
          title="Message"
          watchField="message"
          description={
            <>
              {isMultichannelCampaign && (
                <Typography fontSize={12}>
                  *{shortCompanyName} will attempt to find an email for the lead, otherwise it will skip the lead from
                  the sequence.
                </Typography>
              )}
              <Typography fontSize={12}>
                {isMultichannelCampaign ? '**' : '*'}Sequence stops if a reply is detected from the lead.
              </Typography>
            </>
          }
          config={{ withUploadFile: true, type: 'editField' }}
          index={index}
          snippetOptions={snippetOptions}
          {...sequenceForm}
        />
      </Box>
      <ToggleBox>
        <Toggle checked={watchGmailSignature} name={`sequence.${index}.gmail_signature`} setValue={setValue} />
        <Label>Include Email Signature</Label>
        {watchGmailSignature && (
          <Typography fontSize="14px" pointer color="primary.main" onClick={clickHandler}>
            Setup your signature
          </Typography>
        )}
      </ToggleBox>
      <Tags index={index} watch={watch} setValue={setValue} />

      <Divider />

      <Box display="flex" alignItems="center" gap="16px">
        <Button
          variant="white"
          onClick={testEmailClickHandler}
          disabled={isTestEmailDisabled}
          tooltip={{
            title:
              !isLoadingEmailIntegrations && isTestEmailDisabled && 'Connect your Email account to test this email',
          }}
        >
          Test this email
        </Button>
        <Typography color="gray.500">See the preview of the set email</Typography>
      </Box>
    </>
  );
};
