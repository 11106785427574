import { Box } from '@mui/material';

import { Spinner } from 'src/components';
import { getBaseThemePalette } from 'src/helpers';

export const AppLoading = () => {
  return (
    <Box
      position="absolute"
      bgcolor={getBaseThemePalette().light.light}
      width="100vw"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Spinner type="dots" mr="5px" /> Loading app...
    </Box>
  );
};
