import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { addLeadsTags } from 'src/api';
import { closeModal } from 'src/store/modal.slice';
import { showToast } from 'src/store/toast.slice';
import { IAddLeadsTagsRequest, ICustomAxiosError } from 'src/types';

export const useAddLeadsTags = (
  type: 'connections' | 'leads',
  options?: UseMutationOptions<void, ICustomAxiosError, IAddLeadsTagsRequest>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, error, isLoading, isSuccess } = useMutation('add-leads-tags', addLeadsTags, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      await queryClient.invalidateQueries(type === 'connections' ? ['post-connections'] : ['post-leads']);

      await Promise.all(variables.leadIds.map((id) => queryClient.invalidateQueries(['get-lead-info', id])));

      dispatch(closeModal());
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return {
    addLeadsTags: mutate,
    error,
    isLoading,
    isSuccess,
  };
};
