import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { Link, LinkProps } from 'react-router-dom';
import styled from '@emotion/styled';
import { faCreditCard, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Button, Typography } from 'src/components';
import { BILLING_TYPE_NAMES, VAT_NAMES } from 'src/constants';
import { ModalTypes } from 'src/enums';
import { useAppSelector, useCompanyTheme, useTeamPlan } from 'src/hooks';
import {
  useGetBillingInfo,
  useGetTeamInvites,
  useGetTeamMembers,
  useGetUpcomingInvoiceDetails,
  useResumeSubscription,
} from 'src/reactQueries';
import { openModal } from 'src/store';
import { InvoiceDetails } from '../_components';

const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};

  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

const TitleText = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.wlLight};
  line-height: 40px;
  text-transform: capitalize;
`;

const Divider = styled.div`
  height: 0;
  border-top: 1px solid #eee;
  margin-bottom: 20px;
`;

const PencilLink = styled((props: LinkProps) => (
  <Link {...props}>
    <FontAwesomeIcon icon={faPencil} />
  </Link>
))`
  &:hover {
    color: ${({ theme }) => theme.palette.info.main};
  }
`;

export const SubscriptionDetails = () => {
  const dispatch = useDispatch();

  const { shortCompanyName } = useCompanyTheme();
  const teamData = useAppSelector((state) => state.team.data);

  const { teamMembers } = useGetTeamMembers();
  const { teamInvites = [] } = useGetTeamInvites();

  const { billingInfo } = useGetBillingInfo();
  const { invoiceDetails } = useGetUpcomingInvoiceDetails();
  const { resumeSubscription, isLoading } = useResumeSubscription();

  const { isTrial } = useTeamPlan();

  const getCurrentPlan = () => {
    const billingType = billingInfo?.billingType ? BILLING_TYPE_NAMES[billingInfo.billingType] : '';

    return `${shortCompanyName} ${billingInfo?.plan} ${billingType || ''}`;
  };

  const openCompanyInfo = () => {
    dispatch(openModal({ closable: false, type: ModalTypes.COMPANY_INFO }));
  };

  const availableSeats =
    teamData &&
    teamMembers &&
    teamData?.seats - (teamInvites.filter(({ isRejected }) => !isRejected).length + (teamMembers?.total || 0));

  return (
    <Box display="flex">
      <Box p="20px" bgcolor="#fff" width="33%" mr="30px" borderRadius="4px">
        <Box display="flex">
          <TitleText mr="auto">Company Information</TitleText>
          <Button variant="gray" onClick={openCompanyInfo}>
            Edit Info
          </Button>
        </Box>

        <Text>Company name: {billingInfo?.company}</Text>

        <Text>
          {billingInfo?.vatType ? `${VAT_NAMES[billingInfo.vatType]}: ${billingInfo?.vat}` : 'Tax information'}
        </Text>

        <Divider />

        <TitleText>Address</TitleText>
        <Text>Country: {billingInfo?.address?.country}</Text>
        <Text>Region: {billingInfo?.address?.state}</Text>
        <Text>City: {billingInfo?.address?.city}</Text>
        <Text>Address Line 1: {billingInfo?.address?.line1}</Text>
        <Text>Address Line 2: {billingInfo?.address?.line2}</Text>
        <Text>Zip: {billingInfo?.address?.postal_code}</Text>
      </Box>

      <Box p="20px" bgcolor="#fff" width="66%" borderRadius="4px">
        <Box display="flex" alignItems="center">
          <TitleText mr="15px">{getCurrentPlan()}</TitleText>

          <Typography color="violet.main" semibold>
            {teamData?.seats} Seat{teamData && teamData.seats > 1 && 's'}
          </Typography>

          {!!availableSeats && (
            <Typography color="#88898e" fontSize="11px" ml="10px">
              ({availableSeats} Unused Seat{availableSeats > 1 && 's'}){' '}
              {billingInfo?.canUpdateSeats && <PencilLink to="/team/manage-team" />}
            </Typography>
          )}
        </Box>

        <Box display="flex" alignItems="center">
          <TitleText mr="5px">
            {billingInfo?.stripeSubscriptionObject?.cancel_at_period_end ? 'Subscription ending at:' : 'Next Invoice:'}
          </TitleText>

          <Typography color="violet.main" semibold>
            {dayjs.unix(billingInfo?.stripeSubscriptionObject?.current_period_end || 0).format('MM/DD/YYYY hh:mma')}
          </Typography>
        </Box>

        {!!billingInfo?.stripeTokenObject && (
          <Box display="flex" alignItems="center">
            <TitleText mr="20px">Payment Method:</TitleText>
            <FontAwesomeIcon icon={faCreditCard} />
            <Typography fontSize="14px" color="violet.main" semibold ml="5px">
              •••• •••• •••• {billingInfo?.stripeTokenObject?.card?.last4}
            </Typography>
          </Box>
        )}

        {billingInfo?.stripeSubscriptionObject?.cancel_at_period_end && !isTrial && (
          <Button onClick={() => resumeSubscription()} processing={isLoading}>
            Resume subscription
          </Button>
        )}

        {billingInfo && !billingInfo?.stripeSubscriptionObject?.cancel_at_period_end && invoiceDetails && (
          <Box p="20px" bgcolor="#f0f0f057" border="1px dashed #a4a4a4" width="65%" mx="auto" mt="20px">
            <InvoiceDetails invoiceDetails={invoiceDetails} currency={billingInfo.currency} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
