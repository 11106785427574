import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faQuestionCircle, faSave, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CircularProgress, SvgIcon } from '@mui/material';

import { Button, Link, MessageBlock, Typography } from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features } from 'src/enums';
import { useFacebookIntegration, useTeamPlan } from 'src/hooks';
import { openModal } from 'src/store';
import { IconButton, StyledButton, Text, Title } from '../ui';
import { FacebookPage } from './FacebookPage';

const Wrapper = styled(Box)`
  background-color: #ffffff;
  padding: 20px;
  min-width: 300px;
  box-shadow: 0 1px 1px 0 ${({ theme }) => theme.palette.light.dark};
  border-radius: 5px;
`;

const StyledIcon = styled(SvgIcon)`
  position: absolute;
  height: 28px;
  width: 28px;
  padding: 9px;
  font-size: 20px;
  text-align: center;
  top: 0;
  left: 0;
`;

const FacebookButton = styled(Button)`
  position: relative;
  background-color: #1877f2;
  width: auto;
  height: auto;
  padding: 9px 12px 9px 54px;
  font-size: 20px;
  border-radius: 4px;
  line-height: 1.42857143;
  font-weight: 600;

  &:disabled {
    background-color: #1877f2;
    border-color: transparent;
    cursor: default;
  }
`;

const AuthorizedBlock = styled.div`
  background-color: ${({ theme }) => theme.palette.light.light};
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;

  span {
    font-size: 14px;
  }
`;

const AutorizedFlex = styled(Box)`
  display: flex;
  align-items: center;

  span {
    font-size: 14px;
  }
`;

const StyledImage = styled.img`
  border-radius: 50%;
  margin-right: 10px;
`;

const OptionsSaveStatusMessage = styled.span`
  color: green !important;
  margin-right: 15px;
  display: block;
  float: left;
  font-size: 18px !important;
`;

export const FacebookIntegration = ({ fullWidth = false }: { fullWidth?: boolean }) => {
  const dispatch = useDispatch();
  const {
    isFetchingData,
    isFetchingOptions,
    facebookLongLiveToken,
    facebookDetails,
    facebookPages,
    signInWithFacebook,
    isClearLoginLoading,
    sessionLogout,
    getFBPages,
    pagesLoading,
    checkedPages,
    checkedInstagramPages,
    toggleCheckSelect,
    onGetInstagramDetails,
    toggleCheckInstagramSelect,
    optionsSavedStatus,
    sendFacebookData,
    isUnauthorized,
  } = useFacebookIntegration();
  const { checkFeature } = useTeamPlan();
  const isFacebookIntegrationAllowed = checkFeature(Features.integrations);

  const onLogout = () => {
    dispatch(
      openModal({
        headerText: 'Are you sure you want to remove Facebook credentials?',
        descriptionText:
          'This means that your scheduled posts for Facebook Pages, and Instagram will not be published.',
        onConfirm: sessionLogout,
      }),
    );
  };

  if (isFetchingData || isFetchingOptions) {
    return (
      <Box textAlign="center" mt="20px">
        <CircularProgress />
      </Box>
    );
  }

  if (!facebookLongLiveToken || !facebookDetails || !facebookPages) {
    return (
      <Wrapper width={fullWidth ? '100%' : '50%'}>
        <Title>{facebookLongLiveToken ? 'Facebook authorized' : 'Authorize Facebook'}</Title>

        <Text>Authorizing Facebook allows Alfred to post on your Facebook Pages.</Text>

        <Box display="inline-block">
          <FacebookButton
            disabled={!isFacebookIntegrationAllowed}
            tooltip={{
              title: !isFacebookIntegrationAllowed && PLAN_TOOLTIPS.professional,
            }}
            onClick={signInWithFacebook}
          >
            <StyledIcon>
              <FontAwesomeIcon icon={faFacebook} />
            </StyledIcon>
            Login with Facebook
          </FacebookButton>
        </Box>
      </Wrapper>
    );
  }

  return (
    <Wrapper width={fullWidth ? '100%' : '50%'}>
      <Title>{facebookLongLiveToken ? 'Facebook authorized' : 'Authorize Facebook'}</Title>

      <>
        <Text>You have authorized your Facebook account to allow Alfred to post on selected Facebook Pages.</Text>
        <AuthorizedBlock>
          <AutorizedFlex>
            <StyledImage src={(facebookDetails && facebookDetails.picture && facebookDetails.picture.data.url) || ''} />
            <Box display="flex" flexDirection="column" justifyContent="space-around" mr="auto">
              <b>{facebookDetails?.name}</b>
              <span>{facebookDetails?.email}</span>
            </Box>
            <StyledButton variant="warning" disabled={isClearLoginLoading} onClick={onLogout}>
              Logout from Facebook
            </StyledButton>
          </AutorizedFlex>
        </AuthorizedBlock>
        <AuthorizedBlock>
          <AutorizedFlex justifyContent="space-between">
            <Box display="flex" gap="10px" alignItems="center">
              <Typography>Facebook pages you manage will be displayed here.</Typography>

              <Link
                external
                openNewTab
                to="https://www.facebook.com/help/1148909221857370"
                tooltip={
                  <p>
                    To post content on your Instagram account, you need to connect Facebook page with Instagram account.
                    <br />
                    Click here to know more.
                  </p>
                }
              >
                <FontAwesomeIcon color="#000" size="sm" icon={faQuestionCircle} />
              </Link>
            </Box>
            <IconButton onClick={() => getFBPages()}>
              <FontAwesomeIcon icon={faSync} spin={pagesLoading} />
            </IconButton>
          </AutorizedFlex>
          {facebookPages.map((page, ix) => (
            <FacebookPage
              key={ix}
              page={page}
              checkedPages={checkedPages}
              checkedInstagramPages={checkedInstagramPages}
              onCheckPage={() => toggleCheckSelect(page.id)}
              onGetInstagramDetails={() => onGetInstagramDetails(page)}
              toggleInstagramSelect={() => toggleCheckInstagramSelect(page?.ig?.id)}
            />
          ))}
        </AuthorizedBlock>
        <Text>To publish your content on Facebook pages and Instagram, please connect them to Alfred.</Text>
        <Box display="flex" flex-direction="column" justifyContent="space-between" alignItems="center" mr="auto">
          <Box>{optionsSavedStatus && <OptionsSaveStatusMessage>{optionsSavedStatus}</OptionsSaveStatusMessage>}</Box>
          <StyledButton onClick={sendFacebookData}>
            <FontAwesomeIcon icon={faSave} /> Save
          </StyledButton>
        </Box>

        {isUnauthorized && (
          <Box mt="15px">
            <MessageBlock
              type="error"
              message="We've encountered an error. Please disconnect and reconnect your Facebook account"
            />
          </Box>
        )}
      </>
    </Wrapper>
  );
};
