import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import {
  Button,
  Link,
  NotificationDot,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from 'src/components';
import { ModalTypes } from 'src/enums';
import {
  getRemovedNotificationText,
  resolveActionDescription,
  resolvePersonName,
  resolvePersonNameForActivity,
} from 'src/helpers';
import { useCompanyTheme } from 'src/hooks';
import { useGetCampaignActivity } from 'src/reactQueries';
import { openModal } from 'src/store';
import { IGetDashboardParams } from 'src/types';
import { Title, Wrapper } from './_components';

export const ActivityTable = (props: IGetDashboardParams) => {
  const dispatch = useDispatch();

  const { shortCompanyName, logoIconBlack } = useCompanyTheme();
  const { data, isLoading } = useGetCampaignActivity(
    {
      ...props,
      perPage: 5,
      sortField: 'date',
      sortOrder: 'DESC',
    },
    { refetchOnMount: true },
  );
  const { actions = [] } = data ?? {};

  const modalHandler = (entityUrn: string, campaignId: number) => {
    dispatch(
      openModal({
        type: ModalTypes.VIEW_LEAD,
        closable: false,
        params: {
          campaignId,
          entityUrn,
        },
      }),
    );
  };

  return (
    <Wrapper minHeight="150px">
      <Box display="flex" justifyContent="space-between">
        <Title>Activity</Title>
        <Link to="/campaign/activity">
          <Button>Go to Activity</Button>
        </Link>
      </Box>
      <Box mt="30px" mb="20px">
        <Table processing={isLoading} disabled={isLoading}>
          <TableHead>
            <TableRow>
              <TableHeadCell width="55px" />
              <TableHeadCell>Name</TableHeadCell>
              <TableHeadCell>Action</TableHeadCell>
              <TableHeadCell>Campaign</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody placeholder={!actions.length && <Typography fontSize="16px">No activity yet.</Typography>}>
            {actions.map((action) => {
              const {
                person,
                id,
                description,
                campaignName,
                campaignId,
                objectUrn,
                message,
                isCampaignRemoved,
                isLeadRemoved,
              } = action;

              const { name, avatarContent } = resolvePersonName(
                {
                  ...person,
                  name: resolvePersonNameForActivity(action),
                  companyName: shortCompanyName,
                  companyLogo: logoIconBlack,
                },
                {
                  withAction: true,
                },
              );
              const actionDescription = resolveActionDescription(description, message);
              const notificationText = getRemovedNotificationText({ isCampaignRemoved, isLeadRemoved });

              return (
                <TableRow
                  key={id}
                  hoverBgColor={({ light }) => light.main}
                  cursor="pointer"
                  onClick={() => person?.key && modalHandler(objectUrn, campaignId)}
                >
                  <TableCell justifyContent="center">{avatarContent}</TableCell>
                  <TableCell>{name}</TableCell>
                  <TableCell tooltip={actionDescription}>{actionDescription}</TableCell>
                  <TableCell>
                    <Link
                      variant="lightBlue"
                      fontSize="15px"
                      crop
                      to={`/campaign/details/overview/${campaignId}`}
                      onClick={(e) => e.stopPropagation()}
                      tooltip={campaignName}
                      disabled={isCampaignRemoved}
                      cursor={isCampaignRemoved ? 'default' : 'pointer'}
                    >
                      <Box display="flex" alignItems="center">
                        <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                          {campaignName}
                        </Box>

                        {campaignId && notificationText && (
                          <NotificationDot tooltip={{ title: notificationText, placement: 'right' }} />
                        )}
                      </Box>
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Wrapper>
  );
};
