import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { useCompanyTheme } from 'src/hooks';
import { PauseSubscription } from './_components';

export const ModalPauseSubscription = () => {
  const { shortCompanyName } = useCompanyTheme();

  return (
    <>
      <Box py="30px" px="40px">
        <Typography>Pause your {shortCompanyName} subscription</Typography>
      </Box>

      <PauseSubscription type="pause" />
    </>
  );
};
