import SunEditor from 'suneditor-react';
import { SunEditorReactProps } from 'suneditor-react/dist/types/SunEditorReactProps';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { alpha, Box } from '@mui/material';

const EditorWrapper = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ error?: boolean }>`
  .sun-editor {
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.palette.light.dark};

    &:focus-within {
      border-color: ${({ theme }) => theme.palette.primary.main};
      box-shadow:
        inset 0 2px 1px 0 rgb(0 0 0 / 10%),
        0 0 10px 0 ${({ theme }) => alpha(theme.palette.primary.main, 0.5)};
    }
    ${({ error }) =>
      error &&
      `border: 1px solid #fc2323 !important;
      box-shadow: inset 0 2px 1px 0 rgba(0, 0, 0, 0.1), 0 0 10px 0 rgba(252, 35, 35, 0.5) !important; `}
  }

  .sun-editor-editable,
  .se-wrapper-inner {
    font-size: 14px;
    font-family: 'ProximaSoft', sans-serif !important;
  }

  .se-wrapper-inner {
    border-radius: 5px;
  }

  .se-container {
    display: flex;
    flex-direction: column;
  }

  .se-toolbar {
    order: 2;
    border-radius: 5px;
    background-color: #fff;
    border-top: 1px solid #dadada;
    outline: none;
  }

  .se-resizing-bar {
    display: none;
  }

  .se-btn {
    width: 30px;
    height: 30px;
    border: 1px solid ${({ theme }) => theme.palette.light.dark};
    border-radius: 0;
    margin: 0;

    svg {
      width: 12px;
      height: 12px;
    }
  }

  .se-btn-module-border {
    border: none;
  }

  .se-btn-module {
    margin: 0 2.5px;
  }

  .se-btn-tray {
    padding: 10px 10px 5px;
  }

  .se-toolbar-shadow {
    display: none !important;
  }

  .se-toolbar-sticky-dummy {
    display: none !important;
  }
  .sun-editor .se-toolbar.se-toolbar-sticky {
    position: relative !important;
    top: 0 !important;
  }
`;

interface ITextEditorProps extends SunEditorReactProps {
  isError?: boolean;
}

export const TextEditor = ({ isError, ...props }: ITextEditorProps) => {
  return (
    <EditorWrapper error={isError}>
      <SunEditor height="100%" {...props} />
    </EditorWrapper>
  );
};
