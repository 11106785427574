import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { postFacebookOptions } from 'src/api';
import { ICustomAxiosError, IFacebookOptions } from 'src/types';

export const usePostFacebookOptions = (options?: UseMutationOptions<void, ICustomAxiosError, IFacebookOptions>) => {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation('post-facebook-options', postFacebookOptions, {
    ...options,
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      queryClient.invalidateQueries(['get-facebook-data']);
    },
  });

  return {
    postFacebookOptions: mutate,
    ...rest,
  };
};
