import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getSNSavedSearches } from 'src/api';
import { ISNSavedSearch } from 'src/types';

export const useGetSNSavedSearch = <TData = ISNSavedSearch[]>(
  options?: UseQueryOptions<ISNSavedSearch[], AxiosError, TData>,
) => {
  return useQuery<ISNSavedSearch[], AxiosError, TData>('get-sn-saved-search', getSNSavedSearches, options);
};
