import Select, {
  components as ReactSelectComponents,
  GroupBase,
  Props as ReactSelectProps,
  SelectComponentsConfig,
  StylesConfig,
} from 'react-select';
import { useTheme } from '@emotion/react';
import { faCaretDown, faCaretUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Palette } from '@mui/material';

import { ISelectOption, TJustifyContent } from 'src/types';
import { Typography } from '../ui';

interface IDefaultStyles {
  minWidth?: string;
  valueContainerPosition?: TJustifyContent;
  palette: Palette;
}

const defaultStyles = ({
  minWidth,
  valueContainerPosition,
  palette,
}: IDefaultStyles): StylesConfig<ISelectOption<unknown>, boolean, GroupBase<ISelectOption<unknown>>> => ({
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    minWidth: minWidth || '160px',
    backgroundColor: '#ffffff',
    boxShadow: '#0000002c 0px 6px 12px',
    backgroundClip: 'padding-box',
    border: 'none',
    borderRadius: '4px',
    padding: '10px',
    color: palette.primary.wlLight,
  }),
  container: (provided) => ({
    ...provided,
    height: '32px',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '0px',
  }),
  control: (provided) => ({
    ...provided,
    minWidth: minWidth || '160px',
    backgroundColor: 'var(--light-light)',
    border: 'none',
    borderRadius: '4px',
    boxShadow: 'none',
    display: 'flex',
    fontSize: '14px',
    minHeight: '30px',
    padding: '0 5px',
    ['&:hover']: {
      outline: 'none',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: '32px',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#ffffff !important',
    cursor: 'pointer',

    svg: {
      filter: 'drop-shadow(0 0 1px #000)',
    },

    ['&:hover']: {
      backgroundColor: 'transparent',
      color: '#ffffff',
    },
  }),
  option: (provided, { isSelected }) => ({
    ...provided,
    backgroundColor: 'transparent !important',
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '14px',
    lineHeight: '14px',
    padding: '10px',
    transition: '0.2s',
    color: isSelected ? palette.primary.main : palette.text.primary,

    ['&:hover']: {
      color: palette.primary.main,
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    justifyContent: valueContainerPosition || 'flex-start',
    paddingRight: 0,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 5,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: palette.primary.main,
  }),
});

interface IDefaultComponents {
  label?: string;
  palette: Palette;
}

const defaultComponents = ({
  label,
  palette,
}: IDefaultComponents): SelectComponentsConfig<ISelectOption<unknown>, boolean, GroupBase<ISelectOption<unknown>>> => ({
  DropdownIndicator: (props) => (
    <ReactSelectComponents.DropdownIndicator {...props}>
      <Box width="16px" display="flex" justifyContent="center">
        <FontAwesomeIcon
          size="sm"
          icon={props.selectProps.menuIsOpen ? faCaretUp : faCaretDown}
          color={palette.primary.main}
        />
      </Box>
    </ReactSelectComponents.DropdownIndicator>
  ),
  Option: ({ children, ...props }) => (
    <ReactSelectComponents.Option {...props}>
      {children} {props.isSelected && <FontAwesomeIcon size="xs" icon={faCheck} />}
    </ReactSelectComponents.Option>
  ),
  ValueContainer: ({ children, ...props }) => (
    <ReactSelectComponents.ValueContainer {...props}>
      <Typography color="text.primary" mr="10px">
        {label}
      </Typography>{' '}
      <Box display="inherit">{children}</Box>
    </ReactSelectComponents.ValueContainer>
  ),
});

interface TSelectBasicGrayProps<T, V>
  extends Omit<ReactSelectProps<ISelectOption<unknown>>, 'options' | 'onChange' | 'value'> {
  label?: string;
  minWidth?: string;
  valueContainerPosition?: TJustifyContent;
  value: V;
  options: T[];
  onChange: (data: T) => void;
}

export const SelectBasicGray = <T extends ISelectOption<unknown>, V extends ISelectOption<unknown>['value']>({
  styles,
  minWidth,
  options,
  onChange,
  label,
  value,
  components,
  valueContainerPosition,
  ...otherProps
}: TSelectBasicGrayProps<T, V>) => {
  const { palette } = useTheme();

  return (
    <Select
      isMulti={false}
      isSearchable={false}
      options={options}
      value={options.find((data) => data.value === value)}
      onChange={(data) => onChange(data as T)}
      styles={{
        ...defaultStyles({ minWidth, valueContainerPosition, palette }),
        ...styles,
      }}
      components={{
        ...defaultComponents({ label, palette }),
        ...components,
      }}
      {...otherProps}
    />
  );
};
