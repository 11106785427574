import { isUndefined } from 'lodash';
import { ElementType } from 'react';
import styled from '@emotion/styled';
import MuiTypography, { TypographyProps } from '@mui/material/Typography';

const StyledMuiTypography = styled(MuiTypography)<{
  cursor?: string;
  component: React.ElementType;
  underline?: boolean;
  capitalizeFirstLetter?: boolean;
  hoverColor?: string | boolean;
}>`
  cursor: ${({ cursor }) => cursor};
  text-decoration: ${({ underline }) => (underline ? 'underline' : 'none')};

  ${({ capitalizeFirstLetter }) => capitalizeFirstLetter && ` &:first-letter { text-transform: uppercase; }`};

  &:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;

export interface ITypographyProps extends TypographyProps {
  component?: ElementType;
  semibold?: boolean;
  bold?: boolean;
  inline?: boolean;
  crop?: boolean;
  pointer?: boolean;
  medium?: boolean;
  underline?: boolean;
  capitalizeFirstLetter?: boolean;
  hoverColor?: string;
}

export const Typography = ({
  component = 'p',
  semibold,
  bold,
  inline,
  crop = false,
  pointer,
  medium,
  ...restProps
}: ITypographyProps) => {
  const cursor = pointer ? 'pointer' : 'default';

  return (
    <StyledMuiTypography
      cursor={isUndefined(pointer) ? 'inherit' : cursor}
      display={inline ? 'inline' : 'block'}
      component={component}
      color="text.primary"
      fontFamily="'ProximaSoft', sans-serif"
      fontSize="14px"
      lineHeight={1.145}
      fontWeight={400}
      letterSpacing="0"
      {...(crop && {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      })}
      {...(medium && { fontWeight: 500 })}
      {...(semibold && { fontWeight: 600 })}
      {...(bold && { fontWeight: 700 })}
      {...restProps}
    />
  );
};
