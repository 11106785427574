import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { MultiValue, SingleValue } from 'react-select';
import styled from '@emotion/styled';
import { faClone, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, SelectChangeEvent } from '@mui/material';

import { ActionButton, TableCell, TableRow, Tooltip, Typography } from 'src/components';
import { SelectTeamMemberRole } from 'src/components/Select/SelectTeamMemberRole';
import { REGEXP_VALID_EMAIL_EXTENDED, SELECT_USER_ROLE_OPTIONS } from 'src/constants';
import { Features, ModalTypes, TeamUserRole } from 'src/enums';
import { useAppSelector, useTeamPlan, useWhiteLabel } from 'src/hooks';
import {
  useAssignMemberToGroup,
  useDeleteTeamInvite,
  useDeleteTeamMember,
  useGetGroupListFull,
  useRemoveMemberFromGroup,
  useRemoveTeamUserEmail,
  useUpdateTeamMember,
} from 'src/reactQueries';
import { showToast } from 'src/store';
import { closeModal, openModal } from 'src/store/modal.slice';
import { IColorSelectOption, ITeamMember, TUserStatus } from 'src/types';
import { ManageUser, StatusBadged } from '../../_components';
import { GroupSelect } from './GroupSelect';

const StyledIcon = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
`;

const StyledTableCell = styled(TableCell)`
  border-bottom: none;
  color: ${({ theme }) => theme.palette.gray.dark};
`;

export interface IMemberListItemProps extends Pick<ITeamMember, 'role'> {
  userStatus: TUserStatus;
  id: number;
  name: string;
  email: string;
  isRoleEditable: boolean;
  inviteKey?: string;
  group?: {
    name: string;
    id: number;
  } | null;
}

export const TeamMembersRow = ({
  email,
  id, // member or invite id
  name,
  role: roleInitial,
  userStatus,
  isRoleEditable,
  group,
  inviteKey,
}: IMemberListItemProps) => {
  const dispatch = useDispatch();
  const teamData = useAppSelector((state) => state?.team?.data);
  const myId = useAppSelector((state) => state?.user?.profile?.id);

  const [role, setRole] = useState(roleInitial);
  const [groupSelected, setGroupSelected] = useState<IColorSelectOption | null>(
    group ? { value: group.id, label: group.name } : null,
  );

  const {
    whitelabel: { isActive, domain, isDomainValidated },
  } = useWhiteLabel();

  const { checkFeature } = useTeamPlan();
  const { groupList } = useGetGroupListFull();
  const { assignMemberToGroup } = useAssignMemberToGroup();
  const { removeMemberFromGroup } = useRemoveMemberFromGroup();
  const { deleteTeamMember, isDeleteMemberLoading } = useDeleteTeamMember(
    {
      memberName: name,
    },
    {
      onSettled: () => {
        dispatch(closeModal());
      },
    },
  );
  const { updateTeamMember, isUpdateLoading } = useUpdateTeamMember({
    memberName: name,
  });
  const { deleteTeamInvite, isDeleteInviteLoading } = useDeleteTeamInvite(
    {
      memberName: name,
    },
    {
      onSettled: () => {
        dispatch(closeModal());
      },
    },
  );

  const { removeTeamUserEmail } = useRemoveTeamUserEmail(id);

  const isGroupSelectorShown = role !== 'owner';
  const myTeamRole = teamData?.teamUser.role ?? 'member';
  const isRealEmailUser = REGEXP_VALID_EMAIL_EXTENDED.test(email);
  const isRemoveUserButtonShown = ![myId === id, myTeamRole === 'member', myTeamRole === 'admin'].includes(true);
  const isCopyInviteLinkButtonShown = ['pending', 'rejected'].includes(userStatus);
  const isGroupSelectorEditable = ![
    myId === id,
    myTeamRole === 'member',
    myTeamRole === 'admin',
    userStatus === 'rejected',
    userStatus === 'pending',
  ].includes(true);
  const isRemoveEmailShown =
    userStatus === 'active' && myTeamRole === TeamUserRole.OWNER && role === TeamUserRole.MEMBER;

  const groupOptions: IColorSelectOption[] =
    groupList?.map((groupItem) => ({
      label: groupItem.name,
      value: groupItem.id,
    })) ?? [];

  if (groupSelected) {
    groupOptions.unshift({ value: -1, label: 'Remove from group' });
  }

  const onDeleteClick = () => {
    const isActive = userStatus === 'active';

    if (isActive) {
      return dispatch(
        openModal({
          headerText: `Remove ${name} from ${teamData?.name}`,
          type: ModalTypes.DELETE_TEAM_MEMBER,
          params: {
            userName: name,
            description: `Are you sure you want to remove ${name} from the ${teamData?.name} team?`,
            subDescription: isRealEmailUser
              ? 'They will no longer have access to the campaigns they created under your team.'
              : `You will no longer have access to this user's campaigns, inbox, actions or CRM.`,
          },
          onConfirm: () => deleteTeamMember({ teamId: teamData?.id ?? -1, memberId: id }),
          confirmButtonLoading: isDeleteMemberLoading,
        }),
      );
    }

    return dispatch(
      openModal({
        headerText: `Remove ${name} from ${teamData?.name}`,
        type: ModalTypes.DELETE_TEAM_MEMBER,
        params: {
          userName: name,
          description: `Are you sure you want to remove ${name}'s invitation to the team?`,
        },
        onConfirm: () => deleteTeamInvite({ inviteId: id }),
        confirmButtonLoading: isDeleteInviteLoading,
      }),
    );
  };

  const onChangeRole = (event: SelectChangeEvent) => {
    const nextRole = event.target.value as TeamUserRole;
    const USER_ROLES_PRIORITY = [TeamUserRole.MEMBER, TeamUserRole.ADMIN, TeamUserRole.OWNER];
    const isDowngrade = USER_ROLES_PRIORITY.indexOf(role) > USER_ROLES_PRIORITY.indexOf(nextRole);

    const changeRole = () => {
      setRole(nextRole);
      updateTeamMember({
        role: nextRole,
        teamId: teamData?.id ?? -1,
        memberId: id,
      });
    };

    if (isDowngrade && myId === id) {
      dispatch(
        openModal({
          type: ModalTypes.DOWNGRADE_ROLE,
          headerText: `Changing your own role on ${teamData?.name}`,
          closable: false,
          params: {
            roleFrom: role,
            roleTo: nextRole,
            onConfirm: changeRole,
            isUpdateLoading,
          },
        }),
      );
    } else {
      changeRole();
    }
  };

  const onChangeGroup = (option: SingleValue<IColorSelectOption> | MultiValue<IColorSelectOption>) => {
    const selectedOption = option as IColorSelectOption;

    if (selectedOption.value === -1) {
      setGroupSelected(null);
      removeMemberFromGroup({ groupId: +selectedOption.value, memberId: id });
    } else {
      setGroupSelected(selectedOption);
      assignMemberToGroup({ groupId: +selectedOption.value, memberId: id });
    }
  };

  const copyToClipboard = async () => {
    const origin = isActive && domain && isDomainValidated ? `https://${domain}` : location.origin;
    await navigator.clipboard.writeText(`${origin}/team/invite/${inviteKey}`);
    dispatch(
      showToast({
        type: 'info',
        message: 'Copied to clipboard',
        autoCloseTime: 3000,
      }),
    );
  };

  const onRemoveUserEmail = () => {
    dispatch(
      openModal({
        headerText: 'Remove User Email',
        descriptionText: `Are you sure you want to remove ${email} from ${name}?`,
        onConfirm: () => removeTeamUserEmail(),
      }),
    );
  };

  return (
    <TableRow sx={{ minWidth: 960 }}>
      <StyledTableCell>{name}</StyledTableCell>
      <StyledTableCell>
        {isRealEmailUser ? (
          <Box display="flex" alignItems="center" gap="5px">
            <Typography fontSize="15px" crop>
              {email}
            </Typography>
            {isRemoveEmailShown && (
              <Tooltip title="Remove user email">
                <span>
                  <StyledIcon icon={faTimes} cursor="pointer" onClick={onRemoveUserEmail} />
                </span>
              </Tooltip>
            )}
          </Box>
        ) : (
          <em>User without email</em>
        )}
      </StyledTableCell>

      <StyledTableCell align="center" sx={{ textTransform: 'capitalize' }}>
        {isRoleEditable ? (
          <SelectTeamMemberRole
            options={SELECT_USER_ROLE_OPTIONS.map((option) => ({
              ...option,
              disabled: option.value !== 'member' && !isRealEmailUser,
            }))}
            defaultValue={role}
            value={role}
            onChange={onChangeRole}
          />
        ) : (
          role
        )}
      </StyledTableCell>

      {checkFeature(Features.teamGroup) && myTeamRole === TeamUserRole.OWNER && (
        <StyledTableCell contentStyle={{ overflow: 'visible' }}>
          {isGroupSelectorShown && isGroupSelectorEditable && (
            <GroupSelect onChangeGroup={onChangeGroup} groupOptions={groupOptions} groupSelected={groupSelected} />
          )}

          {isGroupSelectorShown && !isGroupSelectorEditable && group?.name}
        </StyledTableCell>
      )}
      <StyledTableCell>
        <StatusBadged width="84px" status={userStatus} />
      </StyledTableCell>

      <StyledTableCell justifyContent="flex-end">
        <Box display="flex" justifyContent="flex-end" gap="15px" width="100%">
          <ManageUser userId={id} userStatus={userStatus} />
          {isCopyInviteLinkButtonShown && (
            <ActionButton data-testid="copy-link" tooltip="Copy invite URL" onClick={copyToClipboard}>
              <StyledIcon icon={faClone} />
            </ActionButton>
          )}
          {isRemoveUserButtonShown && (
            <ActionButton data-testid="delete-user" tooltip="Remove user" onClick={onDeleteClick}>
              <StyledIcon icon={faTimes} />
            </ActionButton>
          )}
        </Box>
      </StyledTableCell>
    </TableRow>
  );
};
