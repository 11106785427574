import classNames from 'classnames';
import { FieldValues, UseFormRegister, UseFormReturn } from 'react-hook-form';
import styled from '@emotion/styled';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Checkbox, ErrorMessage, FormInput, Tooltip, Typography } from 'src/components';
import { REGEXP_VALID_EMAIL } from 'src/constants';
import { TEmailSMTPForm } from './OtherProviderForm';

const FormColumns = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
`;

interface ISMTPFormProps {
  form: UseFormReturn<TEmailSMTPForm>;
}

export const SMTPForm = ({ form }: ISMTPFormProps) => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = form;
  const formError = Object.entries(errors).find(([key, value]) => key !== 'root' && value?.message)?.[1];

  return (
    <Box>
      <FormColumns mt="24px">
        <FormInput
          label="Reply to Email"
          name="replyToEmail"
          type="email"
          placeholder="support@yourdomain.com"
          parameters={{
            required: 'Please enter an Email',
            minLength: {
              value: 6,
              message: 'Please enter an Email at least 6 characters long',
            },
            pattern: {
              value: REGEXP_VALID_EMAIL,
              message: 'Please enter a valid Email',
            },
            onBlur: () => setValue('replyToEmail', getValues('replyToEmail').trim()),
          }}
          register={register}
          className={classNames({ error: errors.replyToEmail })}
        />
        <FormInput
          label="From (sender) Name"
          name="senderName"
          placeholder="Your App Name Team"
          parameters={{
            required: 'Please enter a Sender Name',
            onBlur: () => setValue('senderName', getValues('senderName').trim()),
          }}
          register={register}
          className={classNames({ error: errors.senderName })}
        />
      </FormColumns>
      <FormColumns mt="20px">
        <FormInput
          label="SMTP Server"
          name="smtpServer"
          placeholder="smtp.gmail.com"
          parameters={{
            required: 'Please enter a SMTP server',
            onBlur: () => setValue('smtpServer', getValues('smtpServer').trim()),
          }}
          register={register}
          className={classNames({ error: errors.smtpServer })}
        />
        <FormInput
          label="SMTP Username"
          name="username"
          placeholder="e.g. John Doe"
          parameters={{
            required: 'Please enter a User Name',
            onBlur: () => setValue('username', getValues('username').trim()),
          }}
          register={register}
          className={classNames({ error: errors.username })}
        />

        <FormInput
          label="SMTP Password"
          name="password"
          type="password"
          autoComplete="on"
          placeholder="Set Your Password"
          parameters={{
            required: 'Please enter a Password',
            onBlur: () => setValue('password', getValues('password').trim()),
          }}
          register={register}
          className={classNames({ error: errors.password })}
        />
      </FormColumns>
      <FormColumns mt="34px">
        <Box>
          <FormInput
            name="port"
            placeholder="587"
            maxLength={5}
            parameters={{
              required: 'Please enter a Port',
              pattern: {
                value: /[1-9]*/,
                message: 'Please enter a valid number Port',
              },
              onBlur: () => {
                const value = getValues('port');
                setValue('port', value && (Number(String(value)?.trim()) || 0));
              },
              validate: (port) => (Number(port) >= 0 && Number(port) <= 65535) || 'Please enter a valid port',
            }}
            register={register}
            className={classNames({ error: errors.port })}
            preTab={
              <>
                <Typography inline color="#BFBFBF">
                  Port{' '}
                </Typography>
                <Typography inline color="error.light">
                  *
                </Typography>
              </>
            }
          />
        </Box>
        <Box display="flex" alignItems="center" pb="5px">
          <Checkbox
            type="checkbox"
            register={register as unknown as UseFormRegister<FieldValues>}
            name="isTlsEnabled"
          />
          <Typography mx="8px">Enable start TLS/SSL</Typography>
          <Tooltip title="To test sending an email, mark the checkbox for this option. If you don’t receive a test email, unmark the checkbox and try again.">
            <FontAwesomeIcon size="sm" icon={faQuestionCircle} />
          </Tooltip>
        </Box>
      </FormColumns>
      {!!formError?.message && (
        <Box mt="15px">
          <ErrorMessage>{formError?.message}</ErrorMessage>
        </Box>
      )}
    </Box>
  );
};
