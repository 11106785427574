import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import microsoftIcon from 'src/assets/icons/microsoft-icon.svg';
import { MessageBlock, Typography } from 'src/components';
import { Button } from 'src/components/Buttons';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features } from 'src/enums';
import { useCompanyTheme, useTeamPlan } from 'src/hooks';
import {
  useDeleteMicrosoftOutlook,
  useGetMicrosoftOutlookCodeUrl,
  useGetUserAccount,
  useGetUsersEmail,
} from 'src/reactQueries';
import { openModal } from 'src/store';
import { Accordion, AccordionDetails, AccordionSummary } from '../ui';
import { SignInButton } from './GoogleForm';
import { ProfileInfo } from './ProfileInfo';

interface MicrosoftFormProps {
  redirectTo: string;
}

export const MicrosoftForm = ({ redirectTo }: MicrosoftFormProps) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const { shortCompanyName } = useCompanyTheme();

  const { userMe } = useGetUserAccount();

  const { getMicrosoftOutlookCodeUrl } = useGetMicrosoftOutlookCodeUrl(redirectTo);
  const { deleteMicrosoftOutlook, isLoading: isDeleteMicrosoftOutlookLoading } = useDeleteMicrosoftOutlook();
  const { checkFeature } = useTeamPlan();
  const isIntegrationAllowed = checkFeature(Features.integrations);

  const { userEmail } = useGetUsersEmail();
  const { outlookEnabled, email, name, pic } = userEmail || {};

  const loginWithMicrosoft = () => getMicrosoftOutlookCodeUrl();

  const onDisconnectClick = () =>
    dispatch(
      openModal({
        headerText: 'Are you sure you want to remove Email credentials ?',
        descriptionText:
          'This means that your Email Campaigns stop to working. Once another Email is connected leads who already got email from previous email box won’t receive any emails.',
        onConfirm: deleteMicrosoftOutlook,
      }),
    );

  return (
    <Accordion expanded={outlookEnabled}>
      <AccordionSummary>
        <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
          {outlookEnabled ? (
            <Box display="flex" alignItems="center" gap="12px">
              <img width="20px" src={microsoftIcon} alt="Microsoft" />
              <Typography semibold fontSize="20px" color="#8A8A8A">
                Microsoft Authorized
              </Typography>
            </Box>
          ) : (
            <Typography semibold fontSize="20px" color="#000">
              Microsoft Outlook
            </Typography>
          )}

          {outlookEnabled ? (
            <Button processing={isDeleteMicrosoftOutlookLoading} onClick={onDisconnectClick} variant="warning">
              Disconnect this Microsoft account
            </Button>
          ) : (
            <SignInButton
              disabled={!isIntegrationAllowed}
              tooltip={{ title: !isIntegrationAllowed && PLAN_TOOLTIPS.professional }}
              onClick={loginWithMicrosoft}
              padding="10px 20px"
            >
              <img width="20px" src={microsoftIcon} alt="Microsoft" />
              <Typography fontWeight="500" fontSize="15px" color="gray.dark">
                Sign in with Microsoft
              </Typography>
            </SignInButton>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" gap="12px">
          <FontAwesomeIcon icon={faCircleCheck} color={palette.success.main} />
          <Typography>You have authorized your Outlook account for sending emails from {shortCompanyName}.</Typography>
        </Box>
        <ProfileInfo type="microsoft" avatar={pic} name={name} email={email} />
        {userMe?.outlookError && (
          <MessageBlock
            type="error"
            message="We've encountered an error. Please disconnect and reconnect your Outlook account."
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
};
