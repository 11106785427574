import { useMutation, UseMutationOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { getTwitterAuthUrl } from 'src/api';
import { showToast } from 'src/store';
import { ICustomAxiosError } from 'src/types';

export const useGetTwitterAuthUrl = (
  options?: UseMutationOptions<string, ICustomAxiosError, Record<string, never>>,
) => {
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation('get-twitter-auth-url', getTwitterAuthUrl, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      window.open(
        data,
        'targetWindow',
        'toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=500,height=250',
      );
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return {
    getTwitterAuthUrl: mutate,
    ...rest,
  };
};
