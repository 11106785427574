import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { useGetBillingInfo } from 'src/reactQueries';

dayjs.extend(utc);

const Wrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.success.main};
  padding: 5px;
  color: #ffffff;
  max-height: 40px;
  box-sizing: border-box;
`;

const ButtonLink = styled(Link)`
  color: ${({ theme }) => theme.palette.success.main};
  background-color: #ffffff;
  width: 180px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  padding: 8px;
  box-sizing: border-box;
`;

export const TrialBar = () => {
  const { billingInfo } = useGetBillingInfo();

  const days = useMemo(() => {
    if (billingInfo?.stripeSubscriptionObject) {
      return dayjs
        .utc(
          (billingInfo.stripeSubscriptionObject?.trial_end ||
            billingInfo.stripeSubscriptionObject?.current_period_end) * 1000,
        )
        .local()
        .format('MM/DD/YY');
    }

    return null;
  }, [billingInfo]);

  const navigateUrl = useMemo(() => {
    return billingInfo?.stripeTokenObject ? '/billing/subscription/edit' : '/billing/subscription/plans';
  }, [billingInfo]);

  return (
    <Wrapper>
      <Typography mr="20px" lineHeight="30px" color="#fff">
        {days && <>Trial ends on: {days}</>}
      </Typography>
      <ButtonLink to={navigateUrl}>Choose a plan</ButtonLink>
    </Wrapper>
  );
};
