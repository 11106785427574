import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { FilterInput, Filters, MultiSelect, Typography } from 'src/components';
import { IGNORED_LEADS_REASON } from 'src/constants';
import { CampaignType, LeadStatus } from 'src/enums';
import { useGetCampaignsSearchListIgnored } from 'src/reactQueries';
import { IIgnoredCampaignLeadsSearchFields, ISelectOption } from 'src/types';

interface IgnoredLeadsFilterBarProps {
  campaignType?: CampaignType;
  searchFields: IIgnoredCampaignLeadsSearchFields;
  onSearchFieldChange: (field: string, value: string | number[] | LeadStatus[]) => void;
}

const reasonsOptions = Object.entries(IGNORED_LEADS_REASON).map(([key, value]) => ({
  label: value,
  value: key as LeadStatus,
}));

export const IgnoredLeadsFilterBar = ({
  searchFields,
  onSearchFieldChange,
  campaignType,
}: IgnoredLeadsFilterBarProps) => {
  const { campaignId } = useParams();
  const CAMPAIGN_ID = Number(campaignId);

  const [showCampaignFilter, setShowCampaignFilter] = useState(false);

  const isCsvEmailCampaignType = campaignType === CampaignType.EMAIL_CSV_UPLOAD;

  const { data: campaignsOptions = [] } = useGetCampaignsSearchListIgnored(CAMPAIGN_ID, {
    refetchOnMount: true,
    select: (data) => data?.map(({ id, name }) => ({ label: name, value: id })),
  });

  const campaignChangeHandler = (options: ISelectOption<number>[]) =>
    onSearchFieldChange(
      'campaignIds',
      options.map(({ value }) => value),
    );

  const statusChangeHandler = (options: ISelectOption<LeadStatus>[]) => {
    const values = options.map(({ value }) => value);
    const isShowCampaignFilter = values.some((value) =>
      [LeadStatus.IN_OTHER_MEMBER_CAMPAIGN, LeadStatus.IN_OTHER_CAMPAIGN].includes(value),
    );

    setShowCampaignFilter(isShowCampaignFilter);

    // clearing campaigns select if it is not shown
    if (!isShowCampaignFilter) {
      onSearchFieldChange('campaignIds', []);
    }

    onSearchFieldChange('statuses', values);
  };

  return (
    <Filters>
      <FilterInput
        label={isCsvEmailCampaignType ? 'Email' : 'Name'}
        name="name"
        placeholder={isCsvEmailCampaignType ? 'Email' : 'Name'}
        value={searchFields.name}
        onChange={(e) => onSearchFieldChange('name', e.target.value)}
      />
      <Box>
        <Typography color="text.primary" lineHeight="1.3rem" semibold mb="5px">
          Reason
        </Typography>
        <MultiSelect
          onChange={statusChangeHandler}
          options={reasonsOptions}
          placeholder="Select reasons"
          stylesConfig={{
            control: (providedStyles) => ({
              ...providedStyles,
              border: '1px solid #ccc',
              boxShadow: 'inset 0 1px 1px rgb(0, 0, 0, 0.08)',
              minHeight: 'auto',
            }),
          }}
        />
      </Box>
      {showCampaignFilter && (
        <Box>
          <Typography color={({ palette }) => palette.text.primary} lineHeight="1.3rem" semibold mb="5px">
            Campaigns
          </Typography>
          <MultiSelect
            onChange={campaignChangeHandler}
            options={campaignsOptions}
            placeholder="Select campaigns"
            cropOptions
            stylesConfig={{
              control: (providedStyles) => ({
                ...providedStyles,
                border: '1px solid #ccc',
                boxShadow: 'inset 0 1px 1px rgb(0, 0, 0, 0.08)',
                minHeight: 'auto',
              }),
            }}
          />
        </Box>
      )}
    </Filters>
  );
};
