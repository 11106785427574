import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { createCampaignFromConnections } from 'src/api';
import { ICampaign, ICampaignAudienceRequest, ICustomAxiosError } from 'src/types';

export const useCreateCampaignFromConnections = (
  generalCampaignType: string,
  options?: UseMutationOptions<ICampaign, ICustomAxiosError, ICampaignAudienceRequest>,
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate, isLoading, ...rest } = useMutation<ICampaign, ICustomAxiosError, ICampaignAudienceRequest>(
    ['create-campaign-audience'],
    (data) => createCampaignFromConnections(generalCampaignType, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        await queryClient.cancelQueries(['campaign', data.id]);

        queryClient.setQueryData<ICampaign>(['campaign', data.id], () => data);

        navigate(`/campaign/${data.id}/sequence`, { state: { category: data.category } });
      },
    },
  );

  return { createCampaignFromConnections: mutate, isCreateLoading: isLoading, ...rest };
};
