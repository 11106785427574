import { useMutation, UseMutationOptions } from 'react-query';

import { postFacebookClearLogin } from 'src/api';
import { ICustomAxiosError } from 'src/types';

export const usePostFacebookClearLogin = (options?: UseMutationOptions<void, ICustomAxiosError>) => {
  const { mutate, ...rest } = useMutation('post-facebook-data', postFacebookClearLogin, options);

  return {
    postFacebookClearLogin: mutate,
    ...rest,
  };
};
