import { useQuery, UseQueryOptions } from 'react-query';

import { getBillingInvoices } from 'src/api';
import { ICustomAxiosError, IGetBillingInvoicesRequest, IGetBillingInvoicesResponse } from 'src/types';

export const useGetBillingInvoices = (
  { startingAfter, endingBefore }: IGetBillingInvoicesRequest,
  options?: UseQueryOptions<IGetBillingInvoicesResponse, ICustomAxiosError>,
) => {
  return useQuery<IGetBillingInvoicesResponse, ICustomAxiosError>(
    ['get-billing-invoices', startingAfter, endingBefore],
    () => getBillingInvoices({ startingAfter, endingBefore }),
    options,
  );
};
