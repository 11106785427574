import { isUndefined } from 'lodash';
import { ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, TooltipProps } from '@mui/material';

import { Tooltip } from '../ui/Tooltip';

interface StyledLinkProps {
  active?: boolean;
  disabled?: boolean;
}

const StyledLink = styled(Link)<StyledLinkProps>(({ theme, active, disabled }) => ({
  fontSize: '14px',
  lineHeight: '30px',
  fontWeight: active ? '500' : '400',
  padding: '8px 8px',
  borderRadius: '4px',
  color: active ? theme.palette.primary.wlLight : theme.palette.gray[600],
  backgroundColor: active ? '#9cc1eb40' : 'transparent',
  ...(disabled ? { color: '#d3d3d3', cursor: 'not-allowed !important' } : {}),

  ['& ~ span']: {
    backgroundColor: active ? theme.palette.primary.wlLight : 'transparent',
  },

  ['& > span']: {
    backgroundColor: active ? '#9cc1eb80' : theme.palette.gray[50],
    padding: '4px',
    borderRadius: '4px',
  },
}));

interface IHeaderTabLink extends LinkProps {
  pathname?: string;
  isActive?: boolean;
  disabled?: boolean;
  tooltip?: ReactNode;
  tooltipProps?: TooltipProps;
}

export const HeaderTabLink = ({
  children,
  pathname = location.pathname,
  to,
  isActive,
  disabled,
  tooltip,
  tooltipProps,
  ...restProps
}: IHeaderTabLink) => {
  return (
    <Tooltip title={tooltip} {...tooltipProps}>
      <Box mr="8px">
        <StyledLink
          to={to}
          disabled={disabled}
          active={isUndefined(isActive) ? pathname === to : isActive}
          {...restProps}
          onClick={(e) => disabled && e.preventDefault()}
        >
          {children}
        </StyledLink>

        <Box height="3px" mt="8px" display="block" component="span" />
      </Box>
    </Tooltip>
  );
};
