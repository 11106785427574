import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateWhiteLabelSMTPSettings } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IUpdateSMTPSettingsReq, IWhiteLabel } from 'src/types';

export const useUpdateWhiteLabelSMTPSettings = (
  options?: UseMutationOptions<IWhiteLabel, ICustomAxiosError, IUpdateSMTPSettingsReq>,
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(['update-whitelabel-smtp-settings'], updateWhiteLabelSMTPSettings, {
    ...options,
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      queryClient.invalidateQueries(['get-whitelabel']);
      dispatch(
        showToast({
          type: 'success',
          message: 'SMTP Settings updated successfully',
          autoCloseTime: 3000,
        }),
      );
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { updateSMTP: mutate, ...rest };
};
