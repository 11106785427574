import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getGmailAliases } from 'src/api';
import { IGmailAlias } from 'src/types';

export const useGetGmailAliases = <TData = IGmailAlias[]>(
  options?: UseQueryOptions<IGmailAlias[], AxiosError, TData>,
) => {
  return useQuery<IGmailAlias[], AxiosError, TData>(['get-gmail-aliases'], getGmailAliases, options);
};
