import { useState } from 'react';
import { Control, FormState, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { Toggle, Typography } from 'src/components';
import {
  MAX_FILE_NUM_LIMIT,
  MAX_FILE_NUM_LIMIT_TRIAL,
  PLAN_TOOLTIPS,
  TEMPLATE_AVAILABILITY_OPTIONS,
} from 'src/constants';
import { Features } from 'src/enums';
import { delay } from 'src/helpers';
import { useLocationState, useTeamPlan } from 'src/hooks';
import { useCreateTemplate, useGetTemplateCategories, useUpdateTemplate } from 'src/reactQueries';
import { showToast } from 'src/store/toast.slice';
import {
  IAttachment,
  ICreateTemplateRequest,
  IEditTemplateState,
  ISelectOption,
  IUpdateTemplateRequest,
  TTemplateAvailability,
} from 'src/types';
import { AvailabilitySelect, CategorySelect, NameInput } from '../_components';
import { InputWithSnippet } from './InputWithSnippet';
import { TemplateCreatorLayout } from './TemplateCreatorLayout';
import { TextAreaWithSnippet } from './TextAreaWithSnippet';

export interface ICreateTemplateForm {
  name: string;
  category: ISelectOption | null;
  availability: ISelectOption;
  subject: string;
  message: string;
  isSubjectShown: boolean;
}

const MESSAGE_MAX_LENGTH = 8000;
const SUBJECT_MAX_LENGTH = 200;

const TOAST_AUTOCLOSE_TIME = 3000;
const FILE_ADDED_MSG_TIMEOUT = 500;

/**
 * file size limit for non-pro users: 10000000
 * file size limit for professional users: 25000000
 */
const MAX_NON_PRO_FILE_SIZE_LIMIT = 10000000;
const MAX_PRO_FILE_SIZE_LIMIT = 25000000;
const MAX_FILE_SIZE_ERR_MSG = 'File upload failed, exceeds file limit!';

export const TemplateCreator = () => {
  const dispatch = useDispatch();
  const state = useLocationState<IEditTemplateState>() || {};

  const [files, setFiles] = useState<File[]>([]);
  const [isEdit, setIsEdit] = useState(state.isEdit ?? false);
  const [attachments, setAttachments] = useState<IAttachment[]>(state?.attachments ?? []);

  const { createTemplate, isLoading: isLoadingCreate } = useCreateTemplate();
  const { updateTemplate, isLoading: isLoadingUpdate } = useUpdateTemplate();
  const { isTrialStarted, isTrial, checkFeature } = useTeamPlan();
  const { categories = [], error: categoriesError, isError: isCategoriesError } = useGetTemplateCategories();

  const getDefaultValues = () => ({
    name: state.name || '',
    category: state.category || null,
    availability: state.availability || TEMPLATE_AVAILABILITY_OPTIONS[0],
    subject: state.subject || 'Hi there!',
    message: state.message || 'How are you?',
    isSubjectShown: Boolean(state.isSubjectShown),
  });

  const { register, handleSubmit, setValue, control, getValues, watch } = useForm<ICreateTemplateForm>({
    defaultValues: getDefaultValues(),
  });

  const category = watch('category');
  const message = watch('message');
  const subject = watch('subject');
  const availability = watch('availability');
  const isSubjectShown = watch('isSubjectShown');
  const availabilityOptions = TEMPLATE_AVAILABILITY_OPTIONS.map((option) =>
    option.value === 'team' ? { ...option, disabled: !checkFeature(Features.templatesTeamAndLibrary) } : option,
  );
  const totalAttachments = files.length + attachments.length;
  const isSubjectAvailable = category?.label !== 'Inbox';

  const MAX_FILE_NUM = isTrialStarted ? MAX_FILE_NUM_LIMIT_TRIAL : MAX_FILE_NUM_LIMIT;
  const MAX_FILE_NUM_ERR_MSG = `Maximum ${MAX_FILE_NUM} file${MAX_FILE_NUM > 1 ? 's' : ''} can be attached`;

  const onSubmit: SubmitHandler<ICreateTemplateForm> = (data) => {
    if (totalAttachments > MAX_FILE_NUM) {
      return dispatch(
        showToast({
          message: MAX_FILE_NUM_ERR_MSG,
          type: 'info',
          autoCloseTime: 3000,
        }),
      );
    }

    if (isEdit) {
      const requestParams: IUpdateTemplateRequest = {
        id: state.id,
        type: 'text',
        name: data.name,
        message: data.message,
        categoryId: +(data.category?.value ?? -1),
        availability: data.availability.value as TTemplateAvailability,
        attachments: attachments.map((attachment) => attachment.file_name),
        files,
      };

      if (data.isSubjectShown && isSubjectAvailable) {
        requestParams.subject = data.subject;
      }

      updateTemplate(requestParams);
    } else {
      const requestParams: ICreateTemplateRequest = {
        name: data.name,
        type: 'text',
        message: data.message,
        categoryId: +(data.category?.value ?? -1),
        availability: data.availability.value as TTemplateAvailability,
        attachments: attachments.map((attachment) => attachment.file_name),
        originalTemplateId: state?.id,
        files,
      };

      if (data.isSubjectShown && isSubjectAvailable) {
        requestParams.subject = data.subject;
      }

      createTemplate(requestParams);
    }
  };

  const onSubmitError = (submittingErrors: FormState<ICreateTemplateForm>['errors']) => {
    dispatch(
      showToast({
        message: Object.values(submittingErrors)[0].message ?? '',
        type: 'info',
        autoCloseTime: TOAST_AUTOCLOSE_TIME,
      }),
    );
  };

  const onCloneClick = () => {
    const { name } = getValues();
    setValue('name', `${name} (clone)`);
    setIsEdit(false);
  };

  const onFileAttach = (fileList: FileList | null | undefined) => {
    if (!checkFeature(Features.templatesAttachments)) {
      dispatch(
        showToast({
          type: 'error',
          message: PLAN_TOOLTIPS.professional,
          autoCloseTime: 3000,
        }),
      );
    }

    if (isTrial && files.length === 1) {
      return dispatch(
        showToast({
          message: 'Upgrade your subscription to PROFESSIONAL plans to attach more files',
          type: 'info',
          autoCloseTime: 3000,
        }),
      );
    }

    const candidateFiles = Array.from(fileList ?? []);

    if (candidateFiles.length + totalAttachments > MAX_FILE_NUM) {
      return dispatch(
        showToast({
          message: MAX_FILE_NUM_ERR_MSG,
          type: 'info',
          autoCloseTime: 3000,
        }),
      );
    }

    const maxFileSize = checkFeature(Features.attachmentsUploadLimit)
      ? MAX_PRO_FILE_SIZE_LIMIT
      : MAX_NON_PRO_FILE_SIZE_LIMIT;
    const isSizeOutLimit = candidateFiles.some((file) => file.size > maxFileSize);

    if (isSizeOutLimit) {
      return dispatch(
        showToast({
          type: 'info',
          message: MAX_FILE_SIZE_ERR_MSG,
          autoCloseTime: TOAST_AUTOCLOSE_TIME,
        }),
      );
    }

    candidateFiles?.forEach((_, index) => {
      delay(index * FILE_ADDED_MSG_TIMEOUT, () =>
        dispatch(
          showToast({
            type: 'success',
            message: 'File has been added',
            autoCloseTime: TOAST_AUTOCLOSE_TIME,
          }),
        ),
      );
    });

    return setFiles((file) => [...file, ...candidateFiles]);
  };

  const onFileDelete = (name: string) => setFiles((prevFiles) => prevFiles.filter((file) => file.name !== name));

  const onAttachmentDelete = (name: string) =>
    setAttachments((prevAttachments) => prevAttachments.filter((file) => file.file_name !== name));

  if (isCategoriesError) {
    dispatch(
      showToast({
        message: (categoriesError as Error)?.message ?? 'Oops, something has gone wrong!',
        type: 'error',
        autoCloseTime: 3000,
      }),
    );
  }

  return (
    <>
      <TemplateCreatorLayout
        isEdit={isEdit}
        templateId={state?.id}
        onSave={handleSubmit(onSubmit, onSubmitError)}
        isSaveDisabled={isLoadingCreate || isLoadingUpdate}
        onClone={onCloneClick}
      />
      <Box padding="10px 15px" marginBottom="80px" width="900px">
        <NameInput
          label="Template Name"
          name="name"
          data-testid="name-input"
          register={register}
          required="Please add a name to your template"
        />
        <Box display="flex">
          <CategorySelect
            // eslint-disable-next-line
            control={control as Control<any>}
            label="Category:"
            name="category"
            required="Please choose a category for your template"
            data={categories}
          />
          <AvailabilitySelect
            label="Availability:"
            name="availability"
            options={availabilityOptions}
            setValue={setValue}
            defaultValue={availability}
          />
        </Box>
        <Box borderRadius="4px" padding="20px" marginBottom="20px" marginTop="50px" sx={{ bgcolor: '#fff' }}>
          {isSubjectShown && isSubjectAvailable && (
            <InputWithSnippet
              name="subject"
              register={register}
              maxLength={SUBJECT_MAX_LENGTH}
              label="Subject (Optional)"
              setValue={setValue}
              value={subject}
            />
          )}
          <TextAreaWithSnippet
            attachedFiles={files}
            attachments={attachments}
            label="Message"
            maxLength={MESSAGE_MAX_LENGTH}
            name="message"
            onFileAttach={onFileAttach}
            onFileDelete={onFileDelete}
            onAttachmentDelete={onAttachmentDelete}
            register={register}
            required="Please add a message to your template"
            setValue={setValue}
            value={message}
          />
          {isSubjectAvailable && (
            <Box display="flex" alignItems="center">
              <Toggle name="isSubjectShown" defaultChecked={state?.isSubjectShown ?? false} setValue={setValue} />
              <Typography ml="15px">{isSubjectShown ? 'Remove' : 'Add'} Subject</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
