import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { sendVerificationCode } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { setIsEmailVerified } from 'src/store/user.slice';
import { ICustomAxiosError } from 'src/types';

export const useSendVerificationCode = (options?: UseMutationOptions<unknown, ICustomAxiosError, string>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate, error, isLoading, isSuccess } = useMutation<unknown, ICustomAxiosError, string>(
    'verify-email',
    sendVerificationCode,
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        dispatch(setIsEmailVerified(true));
        await queryClient.refetchQueries({ queryKey: ['get-user-profile'] });

        navigate('/email-verified', {
          state: { message: 'Email successfully verified' },
        });
      },
      onError: (error, _vars, context) => {
        options?.onError?.(error, _vars, context);

        if (error.message === 'Email already verified') {
          navigate('/email-verified', {
            state: { message: 'Email already verified' },
          });
        } else {
          dispatch(
            showToast({
              type: 'error',
              message: 'The confirmation link is invalid or has expired.',
              model: 'alert',
            }),
          );
          navigate('/app/verifyEmail');
        }
      },
    },
  );

  return {
    sendVerificationCode: mutate,
    error,
    isLoading,
    isSuccess,
  };
};
