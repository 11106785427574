import { isEqual } from 'lodash';
import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import unavailableConnections from 'src/assets/img/unavailable-connections.jpg';
import { HeaderContainer, HeaderDescription, HeaderTitle, HeaderVideo, Link, Typography } from 'src/components';
import { Features } from 'src/enums';
import { useAppSelector, useLocationState, useTeamPlan, useWhiteLabel } from 'src/hooks';
import { useGetUserAccount, usePostConnections } from 'src/reactQueries';
import { ButtonAddTags, ButtonCreateCampaign, ButtonExportCsv, ButtonToggleExclude } from './_components';
import { ConnectionsList } from './ConnectionsList/ConnectionsList';
import LeadDrawer from './LeadDetails/LeadDrawer';

const UnavailableContainer = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ url: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 90px);
  background-image: url(${(props) => props.url});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const ConnectionsPage = memo(() => {
  const { whitelabel } = useWhiteLabel();
  const { checkFeature } = useTeamPlan();
  const currentUser = useGetUserAccount();
  const navigate = useNavigate();
  const locationState = useLocationState();

  const { connectionsRequest, selectedLeads } = useAppSelector(
    (state) => ({
      selectedLeads: state.connections.exportData.selectedLeads,
      connectionsRequest: state.connections.request,
    }),
    isEqual,
  );

  const isConnectionsAllowed = checkFeature(Features.connections);
  const entityUrns = selectedLeads.map(({ entityUrn }) => entityUrn);

  const totalConnections = usePostConnections<number>(
    {
      ...connectionsRequest,
      search: connectionsRequest?.search?.trim(),
      entityUrns: connectionsRequest?.selectedOnly ? selectedLeads.map((lead) => lead.entityUrn) : [],
    },
    {
      enabled: isConnectionsAllowed,
      select: (resp) => resp.total,
      refetchOnMount: true,
    },
  );

  const isCsvButtonDisabled =
    !totalConnections.data || totalConnections.isLoading || !currentUser?.userMe?.isLinkedInLoginValid;

  const openLeadInfo = useCallback(
    (entityUrn?: string) =>
      navigate(`${location.pathname}?person=${entityUrn}&tab=personal_details`, { state: locationState }),
    [],
  );

  return (
    <>
      <HeaderContainer>
        <Box display="flex" justifyContent="space-between">
          <HeaderTitle>Connections</HeaderTitle>
          <Box display="flex" pt="20px" height="30px" gap="20px">
            {entityUrns.length > 0 && <ButtonAddTags type="connections" entityUrns={entityUrns} />}
            <ButtonToggleExclude type="connections" selectedLeads={selectedLeads} />
            {!!totalConnections.data && <ButtonCreateCampaign entityUrns={entityUrns} />}
            <ButtonExportCsv entityUrns={entityUrns} isExportDisabled={isCsvButtonDisabled} />
          </Box>
        </Box>
        <HeaderDescription>
          View, organize, and manage all your LinkedIn connections' information.
          {whitelabel.isWhiteLabelUser || (
            <>
              <Link
                leftIcon
                underline
                external
                openNewTab
                to="https://help.meetalfred.com/en/articles/5615042-manage-your-linkedin-connections-contact-information"
              >
                Learn how it works
              </Link>
              <HeaderVideo src="https://player.vimeo.com/video/622842715" />
            </>
          )}
        </HeaderDescription>
      </HeaderContainer>

      {isConnectionsAllowed ? (
        <Box display="flex" maxWidth="100vw" height="calc(100% - 90px)">
          <ConnectionsList openLeadInfo={openLeadInfo} />
        </Box>
      ) : (
        <UnavailableContainer url={unavailableConnections}>
          <Box boxShadow="0 5px 15px rgb(0 0 0 / 50%)" bgcolor="#fff" p="120px 100px" borderRadius="5px">
            <Typography fontSize="18px">
              <Link to="/billing/edit">Upgrade</Link> your subscription plan in order to get access to this feature
            </Typography>
          </Box>
        </UnavailableContainer>
      )}

      <LeadDrawer />
    </>
  );
});
