import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  HeaderContainer,
  HeaderDescription,
  HeaderTabLink,
  HeaderTabLinkCounter,
  HeaderTabsContainer,
  HeaderTitle,
  HeaderVideo,
  Link,
} from 'src/components';
import { SelectBasicGray } from 'src/components/Select';
import { TIME_INTERVAL_OPTIONS } from 'src/constants';
import { CampaignCategory } from 'src/enums';
import { useWhiteLabel } from 'src/hooks';
import { useGetCampaignsCount } from 'src/reactQueries';
import { ISelectOption, TIntervalFilter } from 'src/types';

interface ICampaignStatsLayoutProps {
  interval?: false | TIntervalFilter;
  onChangeInterval?: (interval: TIntervalFilter) => void;
}

export const CampaignsStatsLayout = ({ interval, onChangeInterval }: ICampaignStatsLayoutProps) => {
  const { category } = useParams<{ category: CampaignCategory }>();
  const { whitelabel } = useWhiteLabel();
  const { data } = useGetCampaignsCount(category);
  const { active = 0, paused = 0, archived = 0, draft = 0, total = 0 } = data ?? {};
  const tabs = [
    { label: `Active`, counter: active, to: `/campaign/stats/${category}/active` },
    { label: `Paused`, counter: paused, to: `/campaign/stats/${category}/paused` },
    { label: `Drafts`, counter: draft, to: `/campaign/stats/${category}/drafts` },
    { label: `Archived`, counter: archived, to: `/campaign/stats/${category}/archived` },
  ];

  const onIntervalChange = (newInterval: ISelectOption<TIntervalFilter>) => {
    onChangeInterval && onChangeInterval(newInterval.value);
  };

  return (
    <HeaderContainer>
      <HeaderTitle>Campaigns</HeaderTitle>
      <HeaderDescription>
        Build automated sequences and optimize campaigns using real-time statistics.
        {whitelabel.isWhiteLabelUser || (
          <>
            <Link
              leftIcon
              underline
              external
              openNewTab
              to="https://help.meetalfred.com/en/collections/1925814-campaign-builder"
            >
              Learn how it works
            </Link>
            <HeaderVideo src="https://player.vimeo.com/video/663459686" />
          </>
        )}
      </HeaderDescription>
      {!!total && (
        <Box display="flex" width="100%">
          <HeaderTabsContainer>
            {tabs.map(({ label, counter, to }) => (
              <HeaderTabLink key={to} to={to}>
                {label}
                <HeaderTabLinkCounter>{counter}</HeaderTabLinkCounter>
              </HeaderTabLink>
            ))}
          </HeaderTabsContainer>
          {interval && (
            <Box display="flex" margin="0 0 0 auto">
              <SelectBasicGray
                label="Filter statistics by:"
                value={interval}
                onChange={onIntervalChange}
                options={TIME_INTERVAL_OPTIONS}
                minWidth="200px"
                valueContainerPosition="space-between"
              />
            </Box>
          )}
        </Box>
      )}
    </HeaderContainer>
  );
};
