import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Spinner } from 'src/components';
import { useGetCampaignChart } from 'src/reactQueries';
import { ICampaignChartData, IDataset } from 'src/types';
import { DatasetSelect } from './DatasetSelect';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ActivityLabel = styled.p`
  font-family: 'ProximaSoft', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.wlLight};
  margin: 0 0 5px 0;
`;

interface IStatsChartProps {
  campaignId: number;
  generateDataset: (campaignData: ICampaignChartData[]) => IDataset[];
}

export const StatsChart = ({ campaignId, generateDataset }: IStatsChartProps) => {
  const [selectDataset, setSelectDataset] = useState<string>('All');
  const { data: chartData, isLoading } = useGetCampaignChart(campaignId);
  const campaignChartData = chartData?.data ?? {};
  const labels = Object.keys(campaignChartData)
    .map((label) => dayjs(label).format('MM/DD'))
    .reverse();

  const DATASET = useMemo(() => generateDataset(Object.values(campaignChartData).reverse()), [campaignChartData]);
  const isEmptyData = DATASET.every(({ data }) => !data.some((value) => value));

  if (isLoading) {
    return <Spinner />;
  }

  if (isEmptyData) {
    return (
      <Box textAlign="center" py="30px">
        No actions for the last 30 days
      </Box>
    );
  }

  const datasets = DATASET.map((dataset) => ({
    ...dataset,
    barPercentage: 0.4,
    hidden: selectDataset !== 'All' && dataset.label !== selectDataset,
  }));
  const isEmptyChart = datasets.every(({ data }) => !data.some((value) => value));
  const selectOptions = [{ value: 'All', label: 'All' }, ...DATASET.map(({ label }) => ({ value: label, label }))];

  return (
    <Box py="20px" px="40px" width="100%" boxSizing="border-box">
      <Box ml="20px" mb="28px" display="flex" alignItems="flex-end">
        <ActivityLabel>Recent Activity</ActivityLabel>
        <Box width="230px" ml="20px">
          <DatasetSelect
            value={selectDataset}
            options={selectOptions}
            onChange={(value) => setSelectDataset(value?.label ?? 'All')}
          />
        </Box>
      </Box>
      {isEmptyChart ? (
        <Box textAlign="center" py="30px">
          No actions yet
        </Box>
      ) : (
        <Box height="400px">
          <Bar
            options={{
              font: { family: 'ProximaSoft' },
              maintainAspectRatio: false,
              responsive: true,
              plugins: {
                legend: {
                  display: true,
                  position: 'bottom',
                  labels: {
                    font: {
                      family: 'ProximaSoft',
                    },
                  },
                },
              },
              scales: {
                y: {
                  beginAtZero: true,
                  stacked: true,
                  grace: Math.ceil((5 * (datasets.length ?? 0)) / 100),
                  ticks: {
                    color: 'gray',
                    stepSize: 1,
                  },
                },
                x: {
                  beginAtZero: true,
                  stacked: true,
                  grid: {
                    display: false,
                  },
                  ticks: {
                    color: 'gray',
                  },
                },
              },
            }}
            data={{ labels, datasets }}
          />
        </Box>
      )}
    </Box>
  );
};
