import { PropsWithChildren } from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { UseFieldArrayRemove, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import isPropValid from '@emotion/is-prop-valid';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { CampaignIcon } from 'src/containers/Campaigns/_components';
import { CampaignCategory, CampaignSequenceDelayUnit } from 'src/enums';
import { ISequence } from 'src/types';

const Highlight = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ borderColor: string }>`
  margin-left: 20px;
  padding-bottom: 40px;
  padding-top: 10px;
  box-sizing: border-box;
  border-left: 1px solid ${({ borderColor }) => borderColor};
`;

const Wrapper = styled(Box)`
  margin-left: 30px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 4px;
`;

const RemoveBox = styled(Box)`
  font-size: 20px;
  padding-right: 5px;
  margin-left: auto;
  cursor: pointer;
`;

const DelayInput = styled.input`
  height: 33px;
  width: 50px;
  margin: 0 10px 0 30px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.palette.light.dark};
  border-radius: 4px;
  padding-left: 7px;
  font-size: 14px;
  color: gray;
  box-sizing: border-box;
`;

const DelaySelector = styled.select`
  height: 33px;
  width: 88px;
  margin: 0 10px;
  outline: none;
  border: 1px solid ${({ theme }) => theme.palette.light.dark};
  border-radius: 4px;
  padding-left: 7px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

interface ISequenceWrapperProps {
  index: number;
  title: string;
  canEdit?: boolean;
  highlight: boolean;
  icon: CampaignCategory;
  setValue: UseFormSetValue<ISequence>;
  register: UseFormRegister<ISequence>;
  watch: UseFormWatch<ISequence>;
  remove: UseFieldArrayRemove;
  provided?: DraggableProvided;
}

const MAX_HOURS_DELAY = 24;
const MAX_DAYS_DELAY = 365;

export const SequenceStep = ({
  index,
  title,
  highlight,
  watch,
  remove,
  register,
  setValue,
  children,
  icon,
  canEdit,
  provided,
}: PropsWithChildren<ISequenceWrapperProps>) => {
  const { palette } = useTheme();
  const watchDelayTimeUnit = watch(`sequence.${index}.delay_time_unit`);
  const watchDelayTimeNumber = watch(`sequence.${index}.delay_number`);

  const maxDelay = watchDelayTimeUnit === CampaignSequenceDelayUnit.HOURS ? MAX_HOURS_DELAY : MAX_DAYS_DELAY;

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <Box marginRight="20px" {...provided?.dragHandleProps}>
          <CampaignIcon type={icon} size="medium" />
        </Box>
        <Typography color="gray.dark" fontSize="14px" semibold>
          Step {index + 1} - {title}
        </Typography>

        {index !== 0 && (
          <>
            <DelayInput
              {...register(`sequence.${index}.delay_number`, {
                valueAsNumber: true,
                onBlur: () => {
                  if (!/\d/.test(String(watchDelayTimeNumber)) || watchDelayTimeNumber < 1) {
                    setValue(`sequence.${index}.delay_number`, 1);
                  }
                },
              })}
              min="1"
              max={maxDelay}
              step="1"
              type="number"
            />
            <DelaySelector {...register(`sequence.${index}.delay_time_unit`)}>
              <option value={CampaignSequenceDelayUnit.HOURS}>Hour{watchDelayTimeNumber > 1 && 's'}</option>
              <option value={CampaignSequenceDelayUnit.DAYS}>Day{watchDelayTimeNumber > 1 && 's'}</option>
            </DelaySelector>
            <Typography color="gray"> from previous step</Typography>
          </>
        )}

        {canEdit && <RemoveBox onClick={() => remove(index)}>&times;</RemoveBox>}
      </Box>

      <Highlight borderColor={highlight ? palette.lightGray.main : 'transparent'}>
        <Wrapper>{children}</Wrapper>
      </Highlight>
    </Box>
  );
};

export const SequenceWrapper = (props: PropsWithChildren<ISequenceWrapperProps>) => {
  const { canEdit, index } = props;

  if (!canEdit) {
    return <SequenceStep {...props} />;
  }

  return (
    <Draggable key={index} draggableId={`${index}`} index={index}>
      {(provided, snapshot) => (
        <Box ref={provided.innerRef} bgcolor={snapshot.isDragging ? 'lightblue' : 'none'} {...provided.draggableProps}>
          <SequenceStep {...props} provided={provided} />
        </Box>
      )}
    </Draggable>
  );
};
