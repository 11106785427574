import { ModalTypes } from 'src/enums/modal.enum';
import {
  ModalBillingHistory,
  ModalCancelSubscription,
  ModalCompanyInfo,
  ModalPauseSubscription,
  ModalSaveBillingCycle,
  ModalUpdateCreditCard,
} from './billing';
import {
  ModalAutocompleteSearch,
  ModalCampaignKeywords,
  ModalInvite,
  ModalRestartSearch,
  ModalSelectConnectionDegree,
  ModalSelectProfileLanguages,
  ModalViewLead,
} from './campaigns';
import { ModalSendTestEmail } from './campaigns/ModalSendTestEmail';
import { ModalDeleteLeadTag } from './contacts';
import { ModalDeleteConversation } from './conversations';
import { ModalExportCampaignActivity, ModalExportContacts, ModalExportCSV, ModalExportReplies } from './exports';
import { ModalExportTeamStats } from './exports/ModalExportTeamStats';
import { ModalExportUnsubscribedEmails } from './exports/ModalExportUnsubscribedEmails';
import { ModalEmailAlias, ModalIntegrations, ModalSignature } from './integrations';
import { ModalPostAs, ModalPostAudience, ModalReschedulePost } from './posts';
import {
  ModalChooseSequenceTemplate,
  ModalSaveAsSequenceTemplate,
  ModalViewSequenceTemplate,
} from './sequence-templates';
import { ModalChangeEmail, ModalSetPassword } from './settings';
import { ModalAddConnectionsTags, ModalAddLeadsTags, ModalCreateUpdateTag, ModalDeleteTag } from './tags';
import {
  ModalConfirmInvitationToTeam,
  ModalDeleteTeamMember,
  ModalDowngradeRole,
  ModalTeamGroupCreate,
  ModalTeamGroupDelete,
  ModalTeamGroupUpdate,
} from './teams';
import { ModalChooseTemplate, ModalDeleteTemplate, ModalViewTemplate } from './templates';
import { ModalCreateTrend, ModalDeleteTrend } from './trends';
import { ModalTestSMTP } from './whiteLabel';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderBody = (type: ModalTypes, params: any) => {
  const modals = {
    [ModalTypes.SIGNATURE]: ModalSignature,
    [ModalTypes.USE_EMAIL_ALIAS]: ModalEmailAlias,
    [ModalTypes.CREATE_TREND]: ModalCreateTrend,
    [ModalTypes.DELETE_TREND]: ModalDeleteTrend,
    [ModalTypes.POST_BY]: ModalPostAs,
    [ModalTypes.POST_AUDIENCE]: ModalPostAudience,
    [ModalTypes.CREATE_TAG]: ModalCreateUpdateTag,
    [ModalTypes.UPDATE_TAG]: ModalCreateUpdateTag,
    [ModalTypes.DELETE_TAG]: ModalDeleteTag,
    [ModalTypes.DELETE_TEMPLATE]: ModalDeleteTemplate,
    [ModalTypes.VIEW_TEMPLATE]: ModalViewTemplate,
    [ModalTypes.INTEGRATIONS]: ModalIntegrations,
    [ModalTypes.DELETE_CONVERSATION]: ModalDeleteConversation,
    [ModalTypes.ADD_CONNECTIONS_TAGS]: ModalAddConnectionsTags,
    [ModalTypes.ADD_LEADS_TAGS]: ModalAddLeadsTags,
    [ModalTypes.DELETE_LEAD_TAG]: ModalDeleteLeadTag,
    [ModalTypes.DELETE_TEAM_MEMBER]: ModalDeleteTeamMember,
    [ModalTypes.SELECT_CONNECTION_DEGREE]: ModalSelectConnectionDegree,
    [ModalTypes.CAMPAIGN_KEYWORDS]: ModalCampaignKeywords,
    [ModalTypes.SELECT_PROFILE_LANGUAGE]: ModalSelectProfileLanguages,
    [ModalTypes.AUTOCOMPLETE_SEARCH]: ModalAutocompleteSearch,
    [ModalTypes.CHOOSE_TEMPLATE]: ModalChooseTemplate,
    [ModalTypes.CONFIRM_INVITATION_TO_TEAM]: ModalConfirmInvitationToTeam,
    [ModalTypes.EXPORT_LEAD_CSV]: ModalExportCSV,
    [ModalTypes.VIEW_LEAD]: ModalViewLead,
    [ModalTypes.TEAM_GROUP_CREATE]: ModalTeamGroupCreate,
    [ModalTypes.TEAM_GROUP_UPDATE]: ModalTeamGroupUpdate,
    [ModalTypes.TEAM_GROUP_DELETE]: ModalTeamGroupDelete,
    [ModalTypes.CHANGE_EMAIL]: ModalChangeEmail,
    [ModalTypes.PAUSE_SUBSCRIPTION]: ModalPauseSubscription,
    [ModalTypes.CANCEL_SUBSCRIPTION]: ModalCancelSubscription,
    [ModalTypes.COMPANY_INFO]: ModalCompanyInfo,
    [ModalTypes.INVITE]: ModalInvite,
    [ModalTypes.UPDATE_CREDIT_CARD]: ModalUpdateCreditCard,
    [ModalTypes.SAVE_BILLING_CYCLE]: ModalSaveBillingCycle,
    [ModalTypes.EXPORT_REPLIES]: ModalExportReplies,
    [ModalTypes.DOWNGRADE_ROLE]: ModalDowngradeRole,
    [ModalTypes.RESCHEDULE_POST]: ModalReschedulePost,
    [ModalTypes.RESTART_CAMPAIGN_SEARCH]: ModalRestartSearch,
    [ModalTypes.EXPORT_CONTACTS]: ModalExportContacts,
    [ModalTypes.TEST_SMTP]: ModalTestSMTP,
    [ModalTypes.EXPORT_TEAM_STATS_CSV]: ModalExportTeamStats,
    [ModalTypes.EXPORT_UNSUBSCRIBED_EMAILS_CSV]: ModalExportUnsubscribedEmails,
    [ModalTypes.EXPORT_CAMPAIGN_ACTIVITY_CSV]: ModalExportCampaignActivity,
    [ModalTypes.BILLING_HISTORY]: ModalBillingHistory,
    [ModalTypes.SET_PASSWORD]: ModalSetPassword,
    [ModalTypes.SEND_TEST_EMAIL]: ModalSendTestEmail,
    [ModalTypes.VIEW_SEQUENCE_TEMPLATE]: ModalViewSequenceTemplate,
    [ModalTypes.CHOOSE_SEQUENCE_TEMPLATE]: ModalChooseSequenceTemplate,
    [ModalTypes.SAVE_AS_SEQUENCE_TEMPLATE]: ModalSaveAsSequenceTemplate,
  };

  const ResolvedModal = modals[type] ?? null;

  return <ResolvedModal {...params} />;
};
