import { useMutation, UseMutationOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { exportCSVReplies } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IExportRepliesCSVRequest } from 'src/types';

export const useExportCSVReplies = (
  options?: UseMutationOptions<void, ICustomAxiosError, IExportRepliesCSVRequest>,
) => {
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation(
    ['campaign-export-CSV-replies'],
    (body: IExportRepliesCSVRequest) => exportCSVReplies(body),
    {
      ...options,
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { exportReplies: mutate, ...rest };
};
