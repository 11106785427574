import { LIConversationType } from 'src/enums';
import { IConnection, ILIConversation, ILIMessage, IReplyRecommendation, ISNConversation } from 'src/types';

const messagingMemberKey = 'com.linkedin.voyager.messaging.MessagingMember';
const vectorImageKey = 'com.linkedin.common.VectorImage';
const miniProfileKey = 'urn:li:fs_miniProfile:';
const messagingCompanyKey = 'com.linkedin.voyager.messaging.MessagingCompany';
const miniCompanyKey = 'urn:li:fs_miniCompany:';

// eslint-disable-next-line
const convertParticipants = (participants: any[]) =>
  participants.map((member) => {
    const profile = member[messagingMemberKey]?.miniProfile;

    if (!profile) {
      const companyProfile = member[messagingCompanyKey].miniCompany;
      const companyPicture = companyProfile.alternateImage && companyProfile.alternateImage[vectorImageKey];

      return {
        entityUrn: companyProfile.entityUrn.replace(miniCompanyKey, ''),
        imageUrl: companyPicture?.rootUrl + companyPicture?.artifacts?.pop()?.fileIdentifyingUrlPathSegment,
        name: companyProfile.name,
        headline: '',
      };
    }

    const picture = profile.picture && profile.picture[vectorImageKey];

    return {
      entityUrn: profile.entityUrn.replace(miniProfileKey, ''),
      imageUrl: picture?.rootUrl + picture?.artifacts?.pop()?.fileIdentifyingUrlPathSegment,
      name: `${profile.firstName || ''} ${profile.lastName || ''}`,
      headline: profile.occupation,
    };
  });

export const transformConversations = (
  data: Record<string, any>, // eslint-disable-line
): ILIConversation[] => {
  return (
    // eslint-disable-next-line
    data.elements?.map((element: any) => {
      const type = element.events[0].subtype;

      const isSponsoredInmail = [LIConversationType.SPONSORED_INMAIL, LIConversationType.SPONSORED_MESSAGE].includes(
        type,
      );

      const participant =
        (isSponsoredInmail && element.participants[0]['com.linkedin.voyager.messaging.MessagingCompany']) ||
        element.participants[0][messagingMemberKey];

      const miniProfile = isSponsoredInmail
        ? participant.miniCompany || participant.miniProfile
        : participant.miniProfile;

      const pic = isSponsoredInmail
        ? participant?.alternateImage?.[vectorImageKey]
        : miniProfile?.picture?.[vectorImageKey];

      const content = element.events[0].eventContent['com.linkedin.voyager.messaging.event.MessageEvent'];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const participants = convertParticipants(element.participants);

      const isPostResent =
        !!element.events[0].eventContent['com.linkedin.voyager.messaging.event.MessageEvent']?.feedUpdate?.entityUrn;

      return {
        conversationId: element.entityUrn.replace('urn:li:fs_conversation:', ''),
        entityUrn: miniProfile.entityUrn.replace('urn:li:fs_miniCompany:', '').replace(miniProfileKey, ''),
        objectUrn: miniProfile.objectUrn?.replace('urn:li:member:', ''),
        name: (isSponsoredInmail && miniProfile.name) || `${miniProfile.firstName || ''} ${miniProfile.lastName || ''}`,
        firstName:
          (isSponsoredInmail && miniProfile.name && [...miniProfile.name.split(' ')].shift()) ||
          miniProfile.firstName ||
          '',
        lastName:
          (isSponsoredInmail && miniProfile.name && [...miniProfile.name.split(' ')].pop()) ||
          miniProfile.lastName ||
          '',
        headline: miniProfile.occupation,
        imageUrl: pic?.rootUrl + pic?.artifacts?.pop()?.fileIdentifyingUrlPathSegment,
        unreadCount: element.unreadCount,
        content:
          ((type === LIConversationType.INMAIL || isSponsoredInmail) && content?.subject) ||
          content?.attributedBody?.text ||
          (content?.attachments?.length && 'Attachment') ||
          '',
        isConnected: !element.withNonConnection,
        lastActivityAt: element.lastActivityAt,
        isArchived: element.archived,
        type,
        isGroup: element.groupChat,
        groupName: element.name,
        participants,
        isPostResent,
        from: element.events[0]?.from?.[
          'com.linkedin.voyager.messaging.MessagingMember'
        ]?.miniProfile?.entityUrn?.replace('urn:li:fs_miniProfile:', ''),
      };
    }) || []
  );
};

export const transformConnectionsData = (data: {
  connections?: IConnection[];
  leads?: IConnection[];
  total: number;
}): ILIConversation[] => {
  const sourceData = data.connections ?? data.leads;
  if (!sourceData) {
    return [];
  }

  return sourceData.map((connection) => ({
    conversationId: connection?.userNetwork?.conversationId || connection?.entityUrn,
    entityUrn: connection?.entityUrn,
    name: `${connection?.firstName} ${connection?.lastName}`,
    headline: '',
    imageUrl: connection?.picture,
    unreadCount: 0,
    content: connection?.replyMessage || '',
    isConnected: true,
    lastActivityAt: 0,
    isArchived: false,
    type: LIConversationType.MEMBER_TO_MEMBER,
  }));
};

// eslint-disable-next-line
export const transformLIMessages = (data: Record<string, any>): ILIMessage[] => {
  return (
    // eslint-disable-next-line
    data.elements?.map((element: any) => {
      const isSponsoredCompanyMessage = !!element.from[messagingCompanyKey];

      const miniProfile = element.from[messagingMemberKey]?.miniProfile;
      const miniCompany = element.from[messagingCompanyKey]?.miniCompany;

      let pic = miniProfile?.picture && miniProfile.picture[vectorImageKey];

      if (isSponsoredCompanyMessage) {
        pic = miniCompany?.alternateImage && miniCompany.alternateImage[vectorImageKey];
      }

      const content = element.eventContent['com.linkedin.voyager.messaging.event.MessageEvent'];

      const participantsChanges = element.eventContent['com.linkedin.voyager.messaging.event.ParticipantChangeEvent'];

      const replyRecommendations =
        element.quickReplyRecommendations?.filter(
          (recommendation: IReplyRecommendation) => recommendation.replyType === 'TEXT',
        ) || [];

      return {
        entityUrn: isSponsoredCompanyMessage
          ? miniCompany.entityUrn.replace(miniCompanyKey, '')
          : miniProfile.entityUrn.replace(miniProfileKey, ''),
        imageUrl: pic?.rootUrl + pic?.artifacts?.pop()?.fileIdentifyingUrlPathSegment,
        createdAt: element.createdAt,
        replyRecommendations,
        recalledAt: content?.recalledAt,
        name: isSponsoredCompanyMessage ? miniCompany?.name : `${miniProfile.firstName} ${miniProfile.lastName}`,
        subtype: element.subtype,
        content: {
          ...content,
          customContent: content?.customContent && {
            ...content.customContent['com.linkedin.voyager.messaging.shared.ThirdPartyMedia'],
            ...content.customContent['com.linkedin.voyager.messaging.event.message.spinmail.SpInmailContent'],
            ...content.customContent['com.linkedin.voyager.messaging.event.message.spinmail.SpInmailContent']
              ?.subContent['com.linkedin.voyager.messaging.event.message.spinmail.SpInmailStandardSubContent'],
          },
          postPreview: {
            avatar:
              content?.feedUpdate?.actor?.image?.attributes?.[0]?.miniProfile?.picture?.[
                'com.linkedin.common.VectorImage'
              ]?.rootUrl +
              content?.feedUpdate?.actor?.image?.attributes?.[0]?.miniProfile?.picture?.[
                'com.linkedin.common.VectorImage'
              ]?.artifacts[0].fileIdentifyingUrlPathSegment,
            name: content?.feedUpdate?.actor?.name?.text,
            degree: content?.feedUpdate?.actor?.supplementaryActorInfo?.text,
            headline: content?.feedUpdate?.actor?.description?.text,
            date: content?.feedUpdate?.actor?.subDescription?.text,
            authorUrl: content?.feedUpdate?.actor?.navigationContext?.actionTarget,
            content: content?.feedUpdate?.commentary?.text?.text,
            image:
              content?.feedUpdate?.content?.['com.linkedin.voyager.feed.render.ImageComponent']?.images?.[0]
                ?.attributes?.[0]?.vectorImage?.rootUrl +
              content?.feedUpdate?.content?.['com.linkedin.voyager.feed.render.ImageComponent']?.images?.[0]
                ?.attributes?.[0]?.vectorImage?.artifacts?.[0]?.fileIdentifyingUrlPathSegment,
          },
        },
        participantsChanges: {
          added:
            // eslint-disable-next-line
            participantsChanges?.addedParticipants?.map((member: any) => {
              const profile = member[messagingMemberKey].miniProfile;

              return `${profile.firstName} ${profile.lastName}`;
            }) || [],
          removed:
            // eslint-disable-next-line
            participantsChanges?.removedParticipants?.map((member: any) => {
              const profile = member[messagingMemberKey].miniProfile;

              return `${profile.firstName} ${profile.lastName}`;
            }) || [],
        },
      } as ILIMessage;
    }) || []
  );
};

export const transformSNConversations = (
  data: Record<string, any>, // eslint-disable-line
): ISNConversation[] => {
  return (
    // eslint-disable-next-line
    data.elements?.map((element: any) => {
      const participantKey = element.participants[0] || '';
      const participantIndex = participantKey.indexOf(',NAME_SEARCH');
      const participant = element.participantsResolutionResults[participantKey];

      const message = element.messages[0];

      return {
        conversationId: element.id,
        entityUrn: participantIndex === -1 ? participantKey.slice(24) : participantKey.slice(24, participantIndex),
        name: participant?.fullName || '',
        firstName: participant?.firstName,
        lastName: participant?.lastName,
        headline: participant?.headline || '',
        imageUrl: participant?.profilePictureDisplayImage?.artifacts?.length
          ? participant.profilePictureDisplayImage.artifacts[0].fileIdentifyingUrlPathSegment
          : participant?.profilePictureDisplayImage?.rootUrl || '',
        unreadCount: element.unreadMessageCount,
        content: message?.attachments?.length ? 'Attachment' : message?.body || '',
        isConnected: true,
        lastActivityAt: element.lastActivityAt,
        nextPageStartsAt: element?.nextPageStartsAt,
        isArchived: element.archived,
        type: message?.type || '',
      };
    }) || []
  );
};

// eslint-disable-next-line
export const transformSNMessages = (data: Record<string, any>): ILIMessage[] => {
  return (
    // eslint-disable-next-line
    data.elements?.map((element: any) => {
      const participantIndex = element.author?.indexOf(',NAME_SEARCH');

      return {
        ...element,
        entityUrn: participantIndex > 24 ? element.author.slice(24, participantIndex) : element.author,
        createdAt: element.deliveredAt,
      };
    }) || []
  );
};
