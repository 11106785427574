import { useMemo } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { PersonAvatar, Typography } from 'src/components';
import { TESTIMONIALS } from 'src/constants';
import { getRandomNotSecuredIntNumber } from 'src/helpers';

const Image = styled.img`
  height: 50px;
`;

export const Testimonials = () => {
  const { logo, picture, quote, position, name } = useMemo(
    () => TESTIMONIALS[getRandomNotSecuredIntNumber(TESTIMONIALS.length)],
    [],
  );

  return (
    <Box p="20px" bgcolor="#ffffff" borderRadius="4px" textAlign="center">
      <Image src={logo} alt="company logo" />

      <Typography fontStyle="italic" my="20px">
        {quote}
      </Typography>

      <Box display="flex" alignItems="center" justifyContent="center">
        <PersonAvatar src={picture} width="50px" height="50%" />
        <Typography ml="10px" semibold>
          {name}
        </Typography>
        <Typography ml="10px">{position}</Typography>
      </Box>
    </Box>
  );
};
