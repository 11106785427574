import defaultProfileIcon from 'src/assets/icons/default-profile.svg';
import { ILinkedInProfileData, IUserCompany } from 'src/types';

export const getLinkedinProfile = (
  postAs: string,
  companies?: null | IUserCompany[],
  linkedInProfile?: ILinkedInProfileData,
) => {
  let profileImage = defaultProfileIcon;
  let userName = postAs;
  let occupation = '-';

  if (postAs === 'You' && linkedInProfile?.miniProfile) {
    occupation = linkedInProfile?.miniProfile?.occupation || '-';

    if (linkedInProfile?.miniProfile.firstName && linkedInProfile?.miniProfile.lastName) {
      userName = `${linkedInProfile?.miniProfile.firstName} ${linkedInProfile?.miniProfile.lastName}`;
    }

    const vectorImage =
      linkedInProfile?.miniProfile.picture && linkedInProfile?.miniProfile.picture['com.linkedin.common.VectorImage'];

    if (vectorImage && vectorImage.rootUrl && vectorImage?.artifacts?.length) {
      profileImage = vectorImage.rootUrl + vectorImage.artifacts.slice(-1)[0].fileIdentifyingUrlPathSegment;
    }
  } else if (companies) {
    const selectedCompany = companies.find((company) => company.entityUrn === postAs);

    if (selectedCompany?.logo) {
      profileImage = selectedCompany.logo;
    }

    if (selectedCompany?.name) {
      userName = selectedCompany.name;
    }
  }

  return { profileImage, userName, occupation };
};
