import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { updateTemplate } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IUpdateTemplateRequest } from 'src/types';

export const useUpdateTemplate = (options?: UseMutationOptions<void, ICustomAxiosError, IUpdateTemplateRequest>) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation('update-template', updateTemplate, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      navigate(-1);

      queryClient.invalidateQueries(['get-templates-personal'], { refetchInactive: true }, {}).catch(console.error);
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return {
    updateTemplate: mutate,
    ...rest,
  };
};
