import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateGroup } from 'src/api/groups';
import { closeModal } from 'src/store/modal.slice';
import { showToast } from 'src/store/toast.slice';
import {
  ICustomAxiosError,
  IGetGroupListPageResponse,
  IUpdateGroupRequest,
  TGetGroupListFullResponse,
} from 'src/types';

export const useUpdateGroup = (
  options?: UseMutationOptions<IGetGroupListPageResponse, ICustomAxiosError, IUpdateGroupRequest>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const getGroupListKey = ['get-group-list-full'];
  const getGroupListPageKey = ['get-group-list-page'];

  const { mutate, ...rest } = useMutation(['update-group'], updateGroup, {
    ...options,
    onMutate: async (request) => {
      options?.onMutate?.(request);
      await queryClient.cancelQueries(getGroupListKey);
      await queryClient.cancelQueries(getGroupListPageKey);

      const prevDataListFull = queryClient.getQueryData(getGroupListKey);

      const prevDataListPage = queryClient.getQueryData(getGroupListPageKey);

      queryClient.setQueryData<TGetGroupListFullResponse | undefined>(getGroupListKey, (items) =>
        items?.map((item) => {
          if (item.id === request.groupId) {
            const itemUpdated = { ...item };
            item.name = request.name;

            return itemUpdated;
          }

          return item;
        }),
      );

      queryClient.setQueryData<IGetGroupListPageResponse | undefined>(getGroupListPageKey, (data) => ({
        total: data?.total ?? 0,
        groups:
          data?.groups.map((groupItem) => {
            if (groupItem.id === request.groupId) {
              const itemUpdated = { ...groupItem };
              groupItem.name = request.name;

              return itemUpdated;
            }

            return groupItem;
          }) ?? [],
      }));

      return {
        prevData: {
          prevDataListFull,
          prevDataListPage,
        },
      };
    },
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      await queryClient.invalidateQueries(['get-team-members'], { exact: false, refetchInactive: true });
      dispatch(closeModal());
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      queryClient.setQueryData(getGroupListKey, context?.prevData.prevDataListFull);
      queryClient.setQueryData(getGroupListPageKey, context?.prevData.prevDataListPage);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
    onSettled: async (data, error, variables, context) => {
      options?.onSettled?.(data, error, variables, context);
      await queryClient.invalidateQueries(getGroupListPageKey);
    },
  });

  return { updateGroup: mutate, ...rest };
};
