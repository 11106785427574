import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';

import { HeaderButton, HeaderContainer, HeaderDescription, HeaderTitle, HeaderVideo, Link } from 'src/components';
import { ModalTypes } from 'src/enums/modal.enum';
import { useWhiteLabel } from 'src/hooks';
import { openModal } from 'src/store/modal.slice';

export const TagManagerLayout = () => {
  const dispatch = useDispatch();
  const { whitelabel } = useWhiteLabel();
  const { state } = useLocation() as { state: { openCreate?: boolean } };

  const onCreateTagClick = () => {
    dispatch(
      openModal({
        headerText: 'Create Tag',
        type: ModalTypes.CREATE_TAG,
      }),
    );
  };

  useEffect(() => {
    if (state?.openCreate) {
      onCreateTagClick();
      // clearing window state
      window.history.replaceState({}, document.title);
    }
  }, []);

  return (
    <HeaderContainer>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <HeaderTitle>Tag Manager</HeaderTitle>
        <HeaderButton onClick={onCreateTagClick}>Create Tag</HeaderButton>
      </Box>
      <HeaderDescription>
        Organize your contacts by creating and using tags that reflect their needs, interests, and other essential
        information.
        {whitelabel.isWhiteLabelUser || (
          <>
            <Link
              leftIcon
              underline
              external
              openNewTab
              to="https://meetalfred.com/help/en/articles/6196586-tag-manager-feature"
            >
              Learn how it works
            </Link>
            <HeaderVideo src="https://player.vimeo.com/video/708363392" />
          </>
        )}
      </HeaderDescription>
    </HeaderContainer>
  );
};
