import './styles.scss';
import styled from '@emotion/styled';
import { MenuItem, Select as SelectMui } from '@mui/material';

import { Typography } from 'src/components';
import { TEMPLATE_SNIPPET_OPTIONS } from 'src/constants';
import { ISelectOption } from 'src/types';

const Select = styled(SelectMui)`
  position: absolute;
  visibility: hidden;
`;

const SelectMenuItem = styled(MenuItem)`
  background-color: #fff !important;
  color: ${({ theme }) => theme.palette.gray.dark};
  font-size: 14px;
  font-family: 'ProximaSoft';
  line-height: 30px;
  padding: 3px 0 !important;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const SelectSnippet = ({
  onCloseSelect,
  onOpenSelect,
  isSelectOpen,
  onSnippetClick,
  snippetOptions,
}: {
  onOpenSelect: () => void;
  onCloseSelect: () => void;
  isSelectOpen: boolean;
  onSnippetClick: (value: string) => void;
  snippetOptions?: ISelectOption[];
}) => {
  const onOptionClick = (value: string) => {
    onCloseSelect();
    onSnippetClick(value);
  };

  return (
    <Select
      open={isSelectOpen}
      onClose={onCloseSelect}
      onOpen={onOpenSelect}
      MenuProps={{
        classes: {
          paper: 'select-snippet_paper',
        },
      }}
    >
      <Typography
        data-testid="snippets"
        color="#869a9d"
        textTransform="uppercase"
        fontSize="10px"
        lineHeight="12px"
        marginBottom="16px"
      >
        Snippets
      </Typography>
      {(snippetOptions?.length ? snippetOptions : TEMPLATE_SNIPPET_OPTIONS).map((snippet) => {
        return (
          <SelectMenuItem onClick={() => onOptionClick(snippet.value)} key={snippet.value} value={snippet.value}>
            {snippet.label}
          </SelectMenuItem>
        );
      })}
    </Select>
  );
};
