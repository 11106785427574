import { useState } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Collapse } from '@mui/material';

import { ActionButton, TableCell, TableRow, Typography } from 'src/components';
import { REGEXP_VALID_EMAIL_EXTENDED } from 'src/constants';
import { ITeamStat, TIntervalFilter } from 'src/types';
import { ManageUser, RoleBadged } from '../../_components';
import { CampaignInfoTable } from './CampaignInfoTable';

const ChartTableCell = styled(TableCell)`
  padding-bottom: 0;
  padding-top: 0;
  border: none;
  background-color: ${({ theme }) => theme.palette.light.light};
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: 16px;
  height: 16px;
`;

interface ITeamStatsTableRowProps {
  statsData: ITeamStat;
  timeFilter: TIntervalFilter;
  customFromDate: string;
  customToDate: string;
}

export const TeamStatsTableRow = ({ statsData, timeFilter, customFromDate, customToDate }: ITeamStatsTableRowProps) => {
  const { palette } = useTheme();
  const [open, setOpen] = useState(false);
  const [isOpened, setIsOpened] = useState(false);

  const { userName, userRole, connected, replies, messages, invites, userEmail, userId, campaigns, viewed } = statsData;
  const statsFields = [
    campaigns,
    invites,
    connected,
    viewed,
    messages,
    replies,
    `${messages + invites && replies ? ((replies / (messages + invites)) * 100).toFixed(1) : 0}%`,
  ];
  const isShowDetails = !statsFields.every((field) => Number(field) === 0);

  return (
    <>
      <TableRow>
        <TableCell>
          <RoleBadged width="54px" role={userRole} />
        </TableCell>
        <TableCell>
          <Typography lineHeight="24px" color={palette.primary.light}>
            {userName}
          </Typography>
          {REGEXP_VALID_EMAIL_EXTENDED.test(userEmail) && (
            <Typography fontSize="10px" color={palette.violet.dark}>
              {userEmail}
            </Typography>
          )}
        </TableCell>
        {statsFields.map((statCol, index) => (
          <TableCell key={index}>
            <Typography color={palette.violet.dark}>{statCol}</Typography>
          </TableCell>
        ))}

        <TableCell preventClick contentStyle={{ width: '100%' }}>
          <Box display="flex" justifyContent="flex-end" gap="10px" width="100%">
            <ManageUser userId={userId} userStatus="active" />

            <ActionButton
              data-testid="details"
              tooltip={isShowDetails && (open ? 'Hide details' : 'Show details')}
              disabled={!isShowDetails}
              onClick={() => {
                setIsOpened(true);
                setOpen((prev) => !prev);
              }}
            >
              <StyledFontAwesomeIcon
                color={isShowDetails ? palette.primary.main : '#88898E'}
                icon={open ? faChevronUp : faChevronDown}
              />
            </ActionButton>
          </Box>
        </TableCell>
      </TableRow>
      {isShowDetails && (
        <TableRow>
          <ChartTableCell colSpan={10} contentStyle={{ width: '100%' }}>
            <Collapse in={open} timeout="auto">
              {(open || isOpened) && (
                <CampaignInfoTable
                  userId={userId}
                  timeFilter={timeFilter}
                  customFromDate={customFromDate}
                  customToDate={customToDate}
                />
              )}
            </Collapse>
          </ChartTableCell>
        </TableRow>
      )}
    </>
  );
};
