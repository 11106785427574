import { PaymentMethod } from '@stripe/stripe-js';

import {
  IBillingInfo,
  ICancelSubscriptionRequest,
  ICompany,
  ICreateSubscription,
  IEditPlan,
  IGetBillingInvoicesRequest,
  IGetBillingInvoicesResponse,
  IGetInvoiceDetailsRequest,
  IGetPlanPricing,
  IPlanPricing,
  IStripeInvoice,
  ISubscriptionResponse,
  IUpdateTeamAfterPaymentConfirmation,
} from 'src/types';
import { appApi } from './axios';

export const getBillingInfo = (): Promise<IBillingInfo> => appApi.get(`/billing`);

export const getBillingInvoices = (params: IGetBillingInvoicesRequest): Promise<IGetBillingInvoicesResponse> =>
  appApi.get(`/billing/invoices`, { params });

export const pauseSubscription = (period: number): Promise<IBillingInfo> =>
  appApi.patch(`/billing/subscriptions/pause`, { period });

export const unpauseSubscription = (): Promise<IBillingInfo> => appApi.patch(`/billing/subscriptions/unpause`);

export const resumeSubscription = (): Promise<IBillingInfo> => appApi.patch(`/billing/subscriptions/resume`);

export const cancelSubscription = ({ reason, value }: ICancelSubscriptionRequest): Promise<IBillingInfo> =>
  appApi.patch(`/billing/subscriptions/cancel`, { reasons: [reason], tellUsMore: value });

export const updateCompanyInfo = (data: ICompany): Promise<IBillingInfo> =>
  appApi.patch(`/billing/company`, {
    ...data,
    name: data.company,
    address: { ...data.address, zip: data.address?.postal_code },
  });

export const updateCreditCard = (paymentMethod: PaymentMethod): Promise<void> =>
  appApi.patch(`/billing/update-card-details`, { paymentMethod });

export const createSubscription = (data: ICreateSubscription): Promise<ISubscriptionResponse> =>
  appApi.post(`/billing/subscriptions`, data);

export const updateSubscription = (data: IEditPlan): Promise<ISubscriptionResponse> =>
  appApi.patch(`/billing/subscriptions`, data);

export const applyDiscount = (discount: number): Promise<void> =>
  appApi.patch(`/billing/subscriptions/apply-discount`, { discount });

export const getInvoiceDetails = (params: IGetInvoiceDetailsRequest): Promise<IStripeInvoice> =>
  appApi.get(`/billing/plan-update-invoice`, { params });

export const getNextInvoiceDetails = (): Promise<IStripeInvoice> => appApi.get(`/billing/upcoming-invoice`);

export const closeCancelModalTrigger = (trigger: string): Promise<void> =>
  appApi.post(`/billing/subscriptions/close-cancel`, { trigger });

export const removeSubscription = (): Promise<void> => appApi.delete(`/billing/subscriptions`);

export const updateTeamDataAfterPaymentConfirmation = (body: IUpdateTeamAfterPaymentConfirmation): Promise<void> =>
  appApi.post(`/billing/subscriptions/payment-confirmed`, body);

export const updateTeamDataAfterFailedPayment = (): Promise<void> =>
  appApi.post(`/billing/subscriptions/payment-failed`);

export const getPlanPricing = (params: IGetPlanPricing): Promise<IPlanPricing> =>
  appApi.get(`/billing/plan-price`, { params });
