import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { Code } from './ui';

type TRequestFieldsProps = {
  type?: string;
  field: string;
  description?: string;
};

export const RequestFields = ({ description, field, type }: TRequestFieldsProps) => {
  return (
    <Box display="grid" gridTemplateColumns={type ? '1fr 1fr' : '1fr'} mt="1em" columnGap="15px">
      <Box>
        <Code>{field}</Code>
        <Typography mt="5px">{description}</Typography>
      </Box>
      {type && (
        <Box>
          <Typography semibold>{type}</Typography>
        </Box>
      )}
    </Box>
  );
};
