import {
  IAssignMemberToGroupRequest,
  ICreateGroupRequest,
  ICreateGroupResponse,
  IDeleteGroupRequest,
  IGetGroupListPageResponse,
  IGetGroups,
  IRemoveMemberFromGroupRequest,
  IUpdateGroupRequest,
  TGetGroupListFullResponse,
} from 'src/types';
import { appApi } from './axios';

export const createGroup = (params: ICreateGroupRequest): Promise<ICreateGroupResponse> =>
  appApi.post(`/groups`, params);

export const getGroupListFull = (): Promise<TGetGroupListFullResponse> => appApi.get(`/groups/all`);

export const getGroupListPage = (params: IGetGroups): Promise<IGetGroupListPageResponse> =>
  appApi.get(`/groups`, { params });

export const updateGroup = ({ groupId, ...params }: IUpdateGroupRequest): Promise<IGetGroupListPageResponse> =>
  appApi.patch(`/groups/${groupId}`, params);

export const deleteGroup = ({ groupId }: IDeleteGroupRequest): Promise<void> => appApi.delete(`/groups/${groupId}`);

export const assignMemberToGroup = ({ groupId, memberId }: IAssignMemberToGroupRequest): Promise<void> =>
  appApi.post(`/groups/${groupId}/members/${memberId}`);

export const removeMemberFromGroup = ({ groupId, memberId }: IRemoveMemberFromGroupRequest): Promise<void> =>
  appApi.delete(`/groups/${groupId}/members/${memberId}`);
