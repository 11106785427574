import { useMemo } from 'react';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';

import { getLIConnectionsReplies } from 'src/api';
import { ICustomAxiosError, ILIConversation } from '../../types';

export const useGetRepliesConnections = (
  { query = '' }: { query: string },
  options?: UseInfiniteQueryOptions<ILIConversation[], ICustomAxiosError, ILIConversation[]>,
) => {
  const {
    isLoading: isLoadingReplies,
    data,
    fetchNextPage: fetchNextReplies,
    isFetchingNextPage: isFetchingNextPageReplies,
    ...rest
  } = useInfiniteQuery<ILIConversation[], ICustomAxiosError, ILIConversation[]>(
    ['get-replies-conversations', query],
    ({ pageParam = 1 }) => getLIConnectionsReplies({ page: pageParam, query }),
    {
      ...options,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length === 20) {
          return allPages.length + 1;
        }
      },
    },
  );

  const repliesConnections = useMemo(() => {
    return data?.pages.flat() || [];
  }, [data]);

  return {
    repliesConnections,
    isLoadingReplies,
    fetchNextReplies,
    isFetchingNextPageReplies,
    ...rest,
  };
};
