import styled from '@emotion/styled';

import { Typography } from 'src/components';

export const Title = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.6em;
  color: ${({ theme }) => theme.palette.primary.wlLight};
  margin-top: 1em;
  margin-bottom: 1em;
`;

export const Code = styled(Typography)`
  font-family: courier;
  line-height: 14px;
`;
