import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateConnection } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { IConnectionsResponse, ICustomAxiosError } from 'src/types';

export const useUpdateConnection = (
  entityUrn: string,
  options?: UseMutationOptions<void, ICustomAxiosError, { isExcluded: boolean }>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const queryKey = ['post-connections'];

  const { mutate, ...rest } = useMutation(
    ['update-connection', entityUrn],
    (body: { isExcluded: boolean }) => updateConnection({ entityUrn, body }),
    {
      ...options,
      onMutate: async ({ isExcluded }) => {
        options?.onMutate?.({ isExcluded });
        await queryClient.cancelQueries(queryKey);

        const prevData = queryClient.getQueryData(queryKey);

        queryClient.setQueriesData<IConnectionsResponse | undefined>(queryKey, (data) => {
          if (data) {
            return {
              ...data,
              connections: data?.connections?.map((connection) =>
                connection.entityUrn === entityUrn
                  ? {
                      ...connection,
                      userNetwork: {
                        ...connection?.userNetwork,
                        isExcluded,
                      },
                      isExcluded,
                    }
                  : connection,
              ),
            };
          }

          return data;
        });

        return { prevData };
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueriesData(queryKey, context?.prevData);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { updateConnection: mutate, ...rest };
};
