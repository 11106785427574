import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  HeaderContainer,
  HeaderDescription,
  HeaderTabLink,
  HeaderTabsContainer,
  HeaderTitle,
  HeaderVideo,
  Link,
} from 'src/components';

const TABS = [
  { label: 'Subscription Plan', to: '/billing/subscription' },
  { label: 'Billing Information', to: '/billing/information' },
  { label: 'Payment Method', to: '/billing/payment-method' },
  { label: 'Billing History', to: '/billing/history' },
];

export const Billing = () => {
  return (
    <>
      <HeaderContainer>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <HeaderTitle>Billing</HeaderTitle>
        </Box>
        <HeaderDescription>
          Manage and modify your billing information, plan selection, and the number of seats used.
          <Link leftIcon underline external openNewTab to="https://help.meetalfred.com/en/collections/3130855-billing">
            Step by Step Guides
          </Link>
          <HeaderVideo src="https://player.vimeo.com/video/1000455752" />
        </HeaderDescription>
        <Box display="flex" width="100%">
          <HeaderTabsContainer>
            {TABS.map(({ label, to }) => (
              <HeaderTabLink key={to} to={to}>
                {label}
              </HeaderTabLink>
            ))}
          </HeaderTabsContainer>
        </Box>
      </HeaderContainer>

      <Box p="20px 16px">
        <Outlet />
      </Box>
    </>
  );
};
