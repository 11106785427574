import {
  ICheckLinkedinConnectionResponse,
  IEmailOutlookResponse,
  IFacebookData,
  IFacebookOptions,
  IGetFacebookAccessTokenResponse,
  IGetFacebookOptionsResponse,
  IGetIFacebookDataResponse,
  IGmailAlias,
  ILinkedInSetAccountRequest,
  ITwitterDetails,
  IUpdateLinkedInCredentialsResponse,
  IUserLinkedInGroup,
} from 'src/types';
import { appApi } from './axios';

export const getTwitterAuthUrl = (): Promise<string> => appApi.get(`/twitter/auth-url`);

export const verifyTwitterAuth = (pin: string): Promise<ITwitterDetails> =>
  appApi.post(`/twitter/verify-login`, { pin });

export const getUserTwitterDetails = (): Promise<ITwitterDetails> => appApi.get(`/twitter/details/user`);

export const logoutFromTwitter = (): Promise<void> => appApi.delete(`/twitter`);

export const getUserLinkedInGroups = (): Promise<Array<IUserLinkedInGroup>> => appApi.get(`/linked-in/groups`);

export const refreshUserLinkedInGroups = (): Promise<Array<IUserLinkedInGroup>> =>
  appApi.post(`/linked-in/groups/refresh`);

export const updateLinkedInCredentials = (
  body: ILinkedInSetAccountRequest,
): Promise<IUpdateLinkedInCredentialsResponse> =>
  appApi.patch(`/linked-in/update-credentials`, body, { timeout: 5 * 60 * 1000 }); // 5min timeout

export const postLinkedInVerifyCode = (body: { code: string; browserId: string }): Promise<{ status: string }> =>
  appApi.post(`/linked-in/verify-code`, body);

export const deleteLinkedInCredentials = (): Promise<void> => appApi.delete(`/linked-in/remove-credentials`);

export const getFacebookData = (): Promise<IGetIFacebookDataResponse> => appApi.get(`/facebook`);

export const postFacebookData = (faceBookData: IFacebookData): Promise<void> =>
  appApi.post(`/facebook/save-data`, faceBookData);

export const getFacebookOptions = (): Promise<IGetFacebookOptionsResponse> => appApi.get(`/facebook/options`);

export const postFacebookOptions = (options: IFacebookOptions): Promise<void> =>
  appApi.post(`/facebook/options`, options);

export const getFacebookAccessToken = (): Promise<IGetFacebookAccessTokenResponse> =>
  appApi.get(`/facebook/access-token`);

export const postFacebookClearLogin = (): Promise<void> => appApi.post(`/facebook/clear-login`);

export const postFacebookShortLiveTokenToGetLongLiveToken = (accessToken: string): Promise<{ accessToken: string }> =>
  appApi.post(`/facebook/exchange-access-token`, { accessToken });

export const postGmailSingIn = (authorizationCode: string): Promise<void> =>
  appApi.post(`/gmail/sign-in`, { authorizationCode });

export const deleteGmail = (): Promise<void> => appApi.delete(`/gmail`);

export const getGmailAliases = (): Promise<IGmailAlias[]> => appApi.get(`/gmail/aliases`);

export const changeGmailAlias = (email: string): Promise<void> => appApi.patch(`/gmail/aliases`, { email });

export const getMicrosoftOutlookCodeUrl = (state: string): Promise<string> =>
  appApi.get(`/microsoft/outlook/code-url`, { params: { state } });

export const postMicrosoftOutlookSignIn = (code: string): Promise<IEmailOutlookResponse> =>
  appApi.post(`/microsoft/outlook/sign-in`, { code });

export const deleteMicrosoftOutlook = (): Promise<void> => appApi.delete(`/microsoft/outlook`);

export const checkLinkedinConnection = (): Promise<ICheckLinkedinConnectionResponse> =>
  appApi.get(`/linked-in/check-connection`);
