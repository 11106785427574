import { useEffect, useState } from 'react';
import { Box, BoxProps } from '@mui/material';

import { Table, TableBody, TableHead, TableHeadCell, TablePagination, TableRow } from 'src/components';
import { DEFAULT_PER_PAGE } from 'src/constants';
import { Features, TeamUserRole } from 'src/enums';
import { useAppSelector, useTeamPlan } from 'src/hooks';
import { useGetTeamMembers } from 'src/reactQueries';
import { ITablePagination, ITableSorting, TGetTeamInvitesResponse, TSortOrder } from 'src/types';
import { TSortField } from '../TeamManage';
import { TeamMembersRow } from './TeamMembersRow';

interface ITeamMembersTableProps {
  group: string;
  name: string;
  email: string;
  teamInvites: TGetTeamInvitesResponse;
  wrapperProps?: BoxProps;
}

export const TeamMembersTable = ({ email, group, name, teamInvites, wrapperProps = {} }: ITeamMembersTableProps) => {
  const { teamUser } = useAppSelector((state) => state.team.data) || {};

  const [sortField, setSortField] = useState<TSortField>('role');
  const [sortOrder, setSortOrder] = useState<TSortOrder>('ASC');
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(DEFAULT_PER_PAGE);

  const { checkFeature } = useTeamPlan();
  const { teamMembers, isLoading } = useGetTeamMembers({
    page: page,
    perPage: perPage,
    searchName: name?.trim(),
    searchEmail: email?.trim(),
    sortField,
    sortOrder,
    // eslint-disable-next-line no-undefined
    groupIds: group || undefined,
  });

  useEffect(() => {
    // setting 1st page cuz we are not sure if current page exists with selected filters
    setPage(1);
  }, [perPage, email, group, name]);

  const sortingProps: ITableSorting = {
    sortField,
    sortOrder,
    onSortFieldChange: (field: TSortField, order: TSortOrder) => {
      setSortField(field);
      setSortOrder(order);
    },
  };

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  return (
    <Box padding="20px 15px 80px" minWidth={960} {...wrapperProps}>
      <Table disabled={false} sx={{ marginBottom: '0px !important' }} processing={isLoading}>
        <TableHead>
          <TableRow>
            <TableHeadCell name="name" sx={{ textAlign: 'left !important' }} sorting={sortingProps}>
              Name
            </TableHeadCell>
            <TableHeadCell name="email" sorting={sortingProps}>
              E-mail
            </TableHeadCell>
            <TableHeadCell name="role" width="15%" sorting={sortingProps}>
              Role
            </TableHeadCell>
            {checkFeature(Features.teamGroup) && teamUser?.role === TeamUserRole.OWNER && (
              <TableHeadCell width="220px">Group</TableHeadCell>
            )}
            <TableHeadCell width="140px">Status</TableHeadCell>
            <TableHeadCell width="100px" />
          </TableRow>
        </TableHead>

        <TableBody>
          {teamMembers?.members?.map((memberItem) => {
            return (
              <TeamMembersRow
                email={memberItem.user?.email}
                id={memberItem.userId}
                key={memberItem.userId}
                name={memberItem.user?.name}
                role={memberItem.role}
                userStatus="active"
                isRoleEditable={teamUser?.role === TeamUserRole.OWNER}
                group={memberItem.group}
              />
            );
          })}
        </TableBody>
      </Table>

      <TablePagination
        total={teamMembers?.total}
        onPaginationChange={paginationChangeHandler}
        page={page}
        perPage={perPage}
      />

      {teamInvites?.length > 0 && (
        <Table style={{ backgroundColor: 'transparent' }} hideHead>
          <TableHead style={{ opacity: '0' }}>
            <TableRow>
              <TableHeadCell />
              <TableHeadCell />
              <TableHeadCell width="15%" />
              {/* group column */}
              {checkFeature(Features.teamGroup) && teamUser?.role === TeamUserRole.OWNER && (
                <TableHeadCell width="220px" />
              )}
              <TableHeadCell width="140px" />
              <TableHeadCell width="100px" />
            </TableRow>
          </TableHead>
          <TableBody style={{ backgroundColor: '#ffffff' }}>
            {teamInvites.map((inviteItem) => {
              return (
                <TeamMembersRow
                  email={inviteItem.email}
                  id={inviteItem.id}
                  isRoleEditable={false}
                  key={inviteItem.id}
                  name={inviteItem.name}
                  role={inviteItem.role}
                  userStatus={inviteItem.isRejected ? 'rejected' : 'pending'}
                  inviteKey={inviteItem.inviteKey}
                />
              );
            })}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};
