import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { Table, TableBody, TableHead, TableHeadCell, TablePagination, TableRow, Typography } from 'src/components';
import { DEFAULT_PER_PAGE } from 'src/constants';
import { usePosts } from 'src/reactQueries';
import { ITablePagination, TPostFilterOptions, TPostTypes, TSortOrder } from 'src/types';
import { PostsTableRow } from './PostsTableRow';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

interface IPostsTableProps {
  search: string;
  status: TPostFilterOptions;
}

export const PostsTable = ({ search, status }: IPostsTableProps) => {
  const { postType } = useParams<{ postType: TPostTypes }>();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [sortOrder, setSortOrder] = useState<TSortOrder>('DESC');
  const [sortField, setSortField] = useState('scheduledAt');

  const isFacebookTab = postType === 'facebook';

  const getPostsRequest = {
    page,
    search,
    status,
    perPage,
    sortField,
    sortOrder,
    type: postType ?? 'linkedin',
  };
  const { data, isLoading } = usePosts(getPostsRequest);

  useEffect(() => {
    setPage(1);
    setPerPage(DEFAULT_PER_PAGE);
  }, [postType]);

  useEffect(() => {
    setPage(1);
  }, [search]);

  useEffect(() => {
    if (data?.posts.length === 0 && page > 1) {
      setPage(1);
    }
  }, [data]);

  const onSortByField = (field: string, order: TSortOrder) => {
    setSortField(field);
    setSortOrder(order);
  };

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  return (
    <>
      <Table processing={isLoading}>
        <TableHead>
          <TableRow>
            <TableHeadCell width="40px" />
            <TableHeadCell
              name="title"
              width="200px"
              sorting={{
                sortOrder,
                sortField,
                onSortFieldChange: onSortByField,
              }}
            >
              Title
            </TableHeadCell>
            {isFacebookTab && <TableHeadCell width="120px">audience</TableHeadCell>}

            <TableHeadCell
              name="likes"
              width="120px"
              sorting={{
                sortOrder,
                sortField,
                onSortFieldChange: onSortByField,
              }}
            >
              Likes
            </TableHeadCell>
            <TableHeadCell
              name="comments"
              width="120px"
              sorting={{
                sortOrder,
                sortField,
                onSortFieldChange: onSortByField,
              }}
            >
              Comments
            </TableHeadCell>
            <TableHeadCell
              name="shares"
              width="120px"
              sorting={{
                sortOrder,
                sortField,
                onSortFieldChange: onSortByField,
              }}
            >
              Shares
            </TableHeadCell>
            <TableHeadCell
              name="views"
              width="120px"
              sorting={{
                sortOrder,
                sortField,
                onSortFieldChange: onSortByField,
              }}
            >
              Views
            </TableHeadCell>
            <TableHeadCell
              name="scheduledAt"
              width="150px"
              sorting={{
                sortOrder,
                sortField,
                onSortFieldChange: onSortByField,
              }}
            >
              Date
            </TableHeadCell>
            <TableHeadCell width="80px">Time</TableHeadCell>
            <TableHeadCell width="132px">Status</TableHeadCell>
            <TableHeadCell width="96px" sticky />
          </TableRow>
        </TableHead>

        <TableBody placeholder={!data?.posts?.length && 'No posts yet'}>
          {data?.posts.map((row) => (
            <PostsTableRow key={row.id} row={row} posts={data.posts} getPostsQueryKeys={getPostsRequest} />
          ))}
        </TableBody>
      </Table>

      <TablePagination page={page} perPage={perPage} total={data?.total} onPaginationChange={paginationChangeHandler} />

      {!!data?.posts.length && (
        <Box mt="20px">
          <Typography>
            <b>* Note:</b> Post time is based in {dayjs.tz.guess()} time ({dayjs().format('HH:mm')}).
          </Typography>
        </Box>
      )}
    </>
  );
};
