const resolveNewLocationAfterImpersonating = (route: string): string => {
  if (new RegExp('/campaign/(new|add-leads|details|\\d+)/').test(route)) {
    return '/campaign/stats';
  } else if (new RegExp('/contacts/leads/.*/details').test(route)) {
    return '/contacts/connections';
  } else if (new RegExp('/templates/new').test(route)) {
    return '/templates/personal';
  } else if (new RegExp('/posts/(new|\\d+/details)').test(route)) {
    return '/posts/linkedin';
  } else if (new RegExp('/trends\\?trendId=\\d+').test(route)) {
    return '/trends';
  }

  return '';
};

export const navigateAfterImpersonating = (): void => {
  const route = location.pathname + location.search;

  // clearing window state
  window.history.replaceState({}, document.title);

  const newRoute = resolveNewLocationAfterImpersonating(route);

  location.replace(newRoute ? `${location.origin}${newRoute}` : location.href);
};
