import { useEffect, useMemo, useState } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { NotificationTypes } from 'src/enums';
import {
  checkLILoginIsInProgress,
  checkLinkedInIsConnected,
  checkLinkedInIsConnectedSuccessfully,
  checkLinkedInRestrictionWarningDetected,
  checkLinkedInVerificationCodeIsActive,
  checkUserIsInVerificationStatus,
  delay,
  interval,
} from 'src/helpers';
import {
  useCheckLinkedinConnection,
  useDeleteLinkedInCredentials,
  useGetUserAccount,
  usePostLinkedInVerifyCode,
  useUpdateLinkedInCredentials,
} from 'src/reactQueries';
import { addNotification, openModal, removeNotificationByType, showToast } from 'src/store';
import { ILinkedInFormFieldValues } from 'src/types';
import { useCompanyTheme } from './useCompanyTheme';

const USER_ACCOUNT_QUERY_KEY = ['get-user-profile'];

interface UseLinkedInIntegrationProps {
  setValue: UseFormSetValue<ILinkedInFormFieldValues>;
  getValues: UseFormGetValues<ILinkedInFormFieldValues>;
}

export const useLinkedInIntegration = ({ setValue, getValues }: UseLinkedInIntegrationProps) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { shortCompanyName } = useCompanyTheme();
  const RESET_PASSWORD_ERROR = `Please reset your LinkedIn password and then proceed to reconnect ${shortCompanyName}.`;

  const [isPollingCloudBotStatus, setIsPollingCloudBotStatus] = useState(false);
  const [pollIntervals, setPollIntervals] = useState<Array<number>>([]);

  const [errorMessage, setErrorMessage] = useState('');

  const { userMe, isFetching: isFetchingUserAccount } = useGetUserAccount({
    onSuccess: (data) => {
      if (checkLILoginIsInProgress(data)) return;

      if (pollIntervals?.length) {
        pollIntervals.forEach(clearInterval);
      }
      setIsPollingCloudBotStatus(false);
    },
    refetchOnMount: true,
  });

  const { isLinkedInLoginValid, cloudBotStatus, isLinkedInLoginBeingVerified, isLi2FAActive } = userMe || {};

  const isContractShown = useMemo(() => {
    return (
      !isLinkedInLoginBeingVerified &&
      isLinkedInLoginValid &&
      cloudBotStatus?.loginStatus === 'LOGGED_IN' &&
      cloudBotStatus?.salesStatus === 'SALES_NAVIGATOR_ACTIVE' &&
      cloudBotStatus?.contracts?.length > 1
    );
  }, [isLinkedInLoginValid, isLinkedInLoginBeingVerified]);

  const isShowVerificationBlock = useMemo<boolean>(() => {
    return checkUserIsInVerificationStatus(userMe) && checkLinkedInVerificationCodeIsActive(userMe);
  }, [userMe]);

  const isShowTryAgainBlock = useMemo<boolean>(() => {
    return checkUserIsInVerificationStatus(userMe) && !checkLinkedInVerificationCodeIsActive(userMe);
  }, [userMe]);

  const isLinkedInConnected = useMemo(() => {
    return checkLinkedInIsConnected(userMe);
  }, [userMe]);

  const isLinkedInConnectedSuccessfully = useMemo(() => {
    return checkLinkedInIsConnectedSuccessfully(userMe);
  }, [userMe]);

  const isLinkedInRestrictionWarningDetected = useMemo(() => {
    return checkLinkedInRestrictionWarningDetected(userMe);
  }, [userMe]);

  // polls cloud bot status if API request timeouts or login is already in progress
  const pollCloudBotStatus = () => {
    setIsPollingCloudBotStatus(true);
    setPollIntervals((prev) => [...prev, interval(5000, () => queryClient.invalidateQueries(USER_ACCOUNT_QUERY_KEY))]);
  };

  useEffect(() => {
    if (!isPollingCloudBotStatus && checkLILoginIsInProgress(userMe)) {
      pollCloudBotStatus();
    }
  }, [userMe]);

  useEffect(() => {
    return () => {
      if (pollIntervals?.length) {
        pollIntervals.forEach(clearInterval);
      }
    };
  }, []);

  const { updateLinkedInCredentials, isLoading: isLoadingUpdateCredentials } = useUpdateLinkedInCredentials({
    onMutate: async () => {
      dispatch(removeNotificationByType({ type: NotificationTypes.LINKEDIN_ERROR }));
      setErrorMessage('');
    },
    onSuccess: async () => {
      setValue('password', '');
      await queryClient.invalidateQueries(USER_ACCOUNT_QUERY_KEY);
    },
    onError: async (err) => {
      if ([408, 524].includes(Number(err.statusCode))) {
        pollCloudBotStatus();
      }
      if (Number(err.statusCode === 425)) {
        setErrorMessage(err.message.replace('{appName}', shortCompanyName));
        delay(5000, () => {
          setErrorMessage('');
          pollCloudBotStatus();
        });
      } else {
        setErrorMessage(err.message);
        queryClient.invalidateQueries(USER_ACCOUNT_QUERY_KEY);
      }
    },
  });

  const { postLinkedInVerifyCode, isLoading: isLoadingVerifyCode } = usePostLinkedInVerifyCode({
    onMutate: () => {
      setErrorMessage('');
      setValue('verificationCode', '');
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(USER_ACCOUNT_QUERY_KEY);

      if (data?.data?.error === 'RESET_PASSWORD_VIEW') {
        dispatch(addNotification({ type: NotificationTypes.LINKEDIN_PASSWORD_RESET }));
        setErrorMessage(RESET_PASSWORD_ERROR);
      } else {
        setErrorMessage('');
      }
    },
    onError: async (err) => {
      setErrorMessage(err?.message);
      queryClient.invalidateQueries(USER_ACCOUNT_QUERY_KEY);
    },
  });

  const { deleteLinkedInCredentials, isLoading: isLoadingDeleteCredentials } = useDeleteLinkedInCredentials({
    onMutate: () => {
      setErrorMessage('');
    },
    onSuccess: async () => {
      setValue('password', '');
      await queryClient.invalidateQueries(USER_ACCOUNT_QUERY_KEY);
    },
  });

  const { checkConnection, isLoading: isCheckLoading } = useCheckLinkedinConnection({
    onError: async () => {
      await queryClient.invalidateQueries(USER_ACCOUNT_QUERY_KEY);
      dispatch(
        showToast({
          type: 'error',
          model: 'alert',
          message:
            "NOT Connected. Please try again, if don't work, please say user to connect linkedin credentials again!",
        }),
      );
    },
    onSuccess: ({ name }) => {
      dispatch(
        showToast({
          type: 'success',
          model: 'alert',
          message: `CONNECTED FINE: Linkedin name is --> ${name}`,
        }),
      );
    },
  });

  const trySubmit = () => {
    const { email, password, countryCode } = getValues();

    if (email && password && countryCode?.value) {
      updateLinkedInCredentials({
        email: email,
        password: password,
        countryCode: countryCode?.value,
      });
    } else {
      dispatch(
        showToast({
          message: 'Please enter both an email and password and select country',
          type: 'info',
          autoCloseTime: 3000,
        }),
      );
    }
  };

  const tryVerify = (values: ILinkedInFormFieldValues) => {
    postLinkedInVerifyCode({
      code: values.verificationCode,
      browserId: String(userMe?.cloudBotStatus?.browserId),
    });
  };

  const tryAgain = () => {
    updateLinkedInCredentials({});
  };

  const tryLIManualAgain = () => {
    updateLinkedInCredentials({ tryLILoginAsAdmin: true });
  };

  const tryDelete = () => {
    dispatch(
      openModal({
        headerText: 'Are you sure you want to remove LinkedIn credentials ?',
        descriptionText: 'This means that your LinkedIn Campaigns, Connections and Inbox feature stop to working',
        onConfirm: () => {
          deleteLinkedInCredentials();
        },
      }),
    );
  };

  return {
    isShowTryAgainBlock,
    isShowVerificationBlock,
    isContractShown,
    isLinkedInConnected,
    isLinkedInConnectedSuccessfully,
    isLi2FAActive,
    isLinkedInRestrictionWarningDetected,

    tryAgain,
    tryDelete,
    trySubmit,
    tryVerify,
    tryLIManualAgain,
    checkConnection,

    errorMessage,

    isCheckLoading,
    isLoadingUpdateCredentials,
    isLoadingDeleteCredentials,
    isLoadingVerifyCode,
    isFetchingUserAccount,
    isPollingCloudBotStatus,

    isShowProgressBar:
      isLoadingUpdateCredentials || isLoadingVerifyCode || isLoadingDeleteCredentials || isPollingCloudBotStatus,
  };
};
