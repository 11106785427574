import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getTeamStats } from 'src/api';
import { IGetTeamStatsRequest, IGetTeamStatsResponse } from 'src/types';

export const useGetTeamStats = <TData = IGetTeamStatsResponse>(
  requestData: IGetTeamStatsRequest,
  options?: UseQueryOptions<IGetTeamStatsResponse, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetTeamStatsResponse, AxiosError, TData>(
    ['get-team-stats', JSON.stringify(requestData)],
    () => getTeamStats(requestData),
    { refetchOnMount: true, ...options },
  );

  return { teamStats: data, ...rest };
};
