import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Badge, Box } from '@mui/material';

import userAvatarImg from 'src/assets/img/user-avatar.jpg';
import { Button, HiddenFileInput } from 'src/components';
import { useGetUserPreferences, useUpdateUserInfo } from 'src/reactQueries';
import { ErrorMessage } from '../_components';
import { labelStyle, StyledInput } from './ui';

const ChangeAvatar = styled(Box)`
  font-family: 'ProximaSoft', sans-serif;
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: #ffffff;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const StyledAvatar = styled(Avatar)`
  width: 100px;
  height: 100px;
  background-color: #c4ccdf;
`;

interface INameForm {
  firstName: string;
  lastName: string;
}

export const ChangeUserInfo = () => {
  const [userAvatar, setUserAvatar] = useState('');
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { updateUserInfo, isLoading } = useUpdateUserInfo();
  const { userPreferences } = useGetUserPreferences();
  const { firstName, lastName, profilePictureUrl } = userPreferences?.userProfile ?? {};

  const {
    reset,
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<INameForm>();
  const formError = Object.values(errors).find((value) => value?.message);

  useEffect(() => {
    reset({ firstName, lastName: lastName ?? '' });
  }, [firstName, lastName]);

  const onSaveChanges = (values: INameForm) => {
    const files = fileInputRef?.current?.files;
    let profilePicture = files ? files[0] : null;
    if (files && files.length) {
      const userUploadedPhotoFile = new File([files[0]], encodeURIComponent(files[0].name), {
        type: files[0].type,
        lastModified: files[0].lastModified,
      });
      profilePicture = userUploadedPhotoFile;
    }
    files && profilePicture ? updateUserInfo({ ...values, profilePicture }) : updateUserInfo(values);
  };

  const onAttachmentClick = () => fileInputRef.current?.click();
  const onChangeUserAvatar = () => {
    if (fileInputRef?.current?.files) {
      const avatarFile = fileInputRef?.current?.files[0];
      setUserAvatar(URL.createObjectURL(avatarFile));
    }
  };

  return (
    <Box>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <ChangeAvatar data-testid="change-avatar" onClick={onAttachmentClick}>
            <FontAwesomeIcon icon={faPlus} color="white" size="lg" />
          </ChangeAvatar>
        }
      >
        <StyledAvatar data-testid="avatar" src={userAvatar || profilePictureUrl || userAvatarImg} />
      </Badge>
      <Box display="flex" gap="20px" mt="10px" mb="20px">
        <StyledInput
          register={register}
          parameters={{
            required: 'Please enter a first name',
          }}
          name="firstName"
          label="First Name"
          labelStyle={labelStyle}
          placeholder="First Name"
        />
        <StyledInput
          register={register}
          parameters={{
            required: 'Please enter a last name',
          }}
          name="lastName"
          label="Last Name"
          labelStyle={labelStyle}
          placeholder="Last Name"
        />
      </Box>
      {formError && <ErrorMessage data-testid="error-message">{formError.message}</ErrorMessage>}
      <Box display="flex" justifyContent="flex-end">
        <Button processing={isLoading} onClick={handleSubmit(onSaveChanges)}>
          Save
        </Button>
      </Box>
      <HiddenFileInput
        ref={fileInputRef}
        data-testid="file-input"
        type="file"
        accept="image/*"
        onChange={onChangeUserAvatar}
      />
    </Box>
  );
};
