import { useQuery, UseQueryOptions } from 'react-query';

import { getTeamInvites } from 'src/api';
import { useAppSelector } from 'src/hooks';
import { ICustomAxiosError, TGetTeamInvitesResponse } from 'src/types';

export const useGetTeamInvites = (
  teamId?: number,
  options?: UseQueryOptions<TGetTeamInvitesResponse, ICustomAxiosError>,
) => {
  const { id } = useAppSelector(({ team }) => team.data) || {};

  const TEAM_ID = teamId || id || 0;

  const { data, ...rest } = useQuery<TGetTeamInvitesResponse, ICustomAxiosError>(
    ['get-team-invites', TEAM_ID],
    () => getTeamInvites({ teamId: TEAM_ID }),
    { enabled: !!TEAM_ID, ...options },
  );

  return { teamInvites: data, ...rest };
};
