import faviconIco from 'src/assets/logo/favicon.ico';
import logoBlack from 'src/assets/logo/logo.svg';
import logoBlue from 'src/assets/logo/logo_blue.svg';
import logoWhite from 'src/assets/logo/logo_white.svg';
import logo192 from 'src/assets/logo/logo192.png';
import { useWhiteLabel } from './useWhiteLabel';

export const useCompanyTheme = () => {
  const { whitelabel, isLoading } = useWhiteLabel();
  const { isActive, isWhiteLabelUser, companyName, favicon, logoIcon, logo } = whitelabel || {};

  const resolveParam = (defaultValue: string, value?: string) =>
    (isActive || isWhiteLabelUser) && value ? value : defaultValue;

  return {
    isLoading,
    logo: resolveParam(logo192, logo),
    favicon: resolveParam(faviconIco, favicon),
    logoIconWhite: resolveParam(logoWhite, logoIcon),
    logoIconBlack: resolveParam(logoBlack, logoIcon),
    logoIconBlue: resolveParam(logoBlue, logoIcon),
    companyName: resolveParam('Meet Alfred', companyName),
    shortCompanyName: resolveParam('Alfred', companyName),
  };
};
