import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { createSetLeadTag } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICreateTagRequest, ICustomAxiosError, ILeadInfo, ITag } from 'src/types';

export const useCreateSetLeadTag = (
  entityUrn: string,
  options?: UseMutationOptions<ITag, ICustomAxiosError, ICreateTagRequest>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const getLeadInfoKey = ['get-lead-info', entityUrn];

  const { mutate, ...rest } = useMutation<ITag, ICustomAxiosError, ICreateTagRequest, { prevData: unknown }>(
    ['create-add-lead-tag', entityUrn],
    (tag: ICreateTagRequest) => createSetLeadTag(entityUrn, tag),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);

        queryClient.setQueryData<ILeadInfo | undefined>(getLeadInfoKey, (lead) => {
          if (lead) {
            const newTagIndex = lead.tags.findIndex(({ id }) => id === -1);

            return {
              ...lead,
              tags: lead.tags.map((tag, index) => (newTagIndex === index ? data : tag)),
            };
          }

          return lead;
        });

        await queryClient.invalidateQueries(['get-all-tags']);
      },
      onMutate: async (tag) => {
        options?.onMutate?.(tag);
        await queryClient.cancelQueries(getLeadInfoKey);

        const prevData = queryClient.getQueryData(getLeadInfoKey);

        queryClient.setQueryData<ILeadInfo | undefined>(getLeadInfoKey, (lead) => {
          if (lead) {
            return {
              ...lead,
              tags: [...lead.tags, { ...tag, id: -1, snId: null, leadsCount: 0, connectionsCount: 0 }],
            };
          }

          return lead;
        });

        dispatch(
          showToast({
            type: 'info',
            message: 'tag created',
            autoCloseTime: 3000,
          }),
        );

        return { prevData };
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);

        queryClient.setQueryData(getLeadInfoKey, context?.prevData);
      },
    },
  );

  return { createAddLeadTag: mutate, ...rest };
};
