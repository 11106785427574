import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button } from 'src/components';
import {
  EmailIntegration,
  FacebookIntegration,
  LinkedInIntegration,
  TwitterIntegration,
} from 'src/containers/Integrations/_components';
import { closeModal } from 'src/store/modal.slice';
import { IModalProps, TIntegrationTypes } from 'src/types';

const ModalContainer = styled(Box)`
  background-color: #ffffff;
  border-radius: 6px !important;
  box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 798px;
`;

const ModalBody = styled(Box)`
  background-color: ${({ theme }) => theme.palette.light.light};

  & > div {
    width: auto !important;
    border-radius: 5px;
  }
`;

const Title = styled.h3`
  font-size: 24px;
  line-height: 40px;
  color: #333;
  &:first-letter {
    text-transform: uppercase;
  }
`;

const ModalFooter = styled(Box)`
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: flex-end;
  padding: 15px 15px;
`;

export const ModalIntegrations = ({ onCancel, params = {} }: IModalProps) => {
  const { palette } = useTheme();
  const dispatch = useDispatch();

  const integrationType = (params?.integrationType ?? 'email') as TIntegrationTypes;

  const resolveIntegration = () => {
    switch (integrationType) {
      case 'email':
        return <EmailIntegration redirectTo="/walkthrough" fullWidth />;
      case 'facebook':
        return <FacebookIntegration fullWidth />;
      case 'twitter':
        return <TwitterIntegration fullWidth />;
      case 'linkedIn':
        return <LinkedInIntegration removeContainer />;

      default:
        return <></>;
    }
  };

  const closeHandler = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  return (
    <ModalContainer>
      <Box py={3} px={5} display="flex" borderBottom={`1px solid ${palette.gray[100]}`}>
        <Title>{integrationType} Connection</Title>
      </Box>

      <ModalBody p={integrationType === 'linkedIn' ? '0px' : '55px 50px'}>{resolveIntegration()}</ModalBody>

      <ModalFooter>
        <Button variant="gray" onClick={closeHandler}>
          Close
        </Button>
      </ModalFooter>
    </ModalContainer>
  );
};
