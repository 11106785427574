import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { removeTeamUserEmail } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IGetTeamMembersResponse } from 'src/types';

const teamMembersKey = ['get-team-members'];

export const useRemoveTeamUserEmail = (userId: number, options?: UseMutationOptions<void, ICustomAxiosError>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(['remove-team-user-email', userId], () => removeTeamUserEmail(userId), {
    ...options,
    onMutate: async (data) => {
      options?.onMutate?.(data);

      await queryClient.cancelQueries(teamMembersKey);

      const prevData = queryClient.getQueriesData<IGetTeamMembersResponse>(teamMembersKey);

      queryClient.setQueriesData<IGetTeamMembersResponse | undefined>(teamMembersKey, (data) => {
        return (
          data && {
            ...data,
            members: data.members.map((member) =>
              member.userId === userId ? { ...member, user: { ...member?.user, email: '' } } : member,
            ),
          }
        );
      });

      return { prevData };
    },
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      await queryClient.invalidateQueries(teamMembersKey);

      dispatch(
        showToast({
          message: 'User email was successfully removed',
          type: 'info',
          autoCloseTime: 5000,
        }),
      );
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);

      context?.prevData.forEach(([key, data]) => queryClient.setQueryData(key, data));

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return {
    removeTeamUserEmail: mutate,
    ...rest,
  };
};
