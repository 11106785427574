import { useMemo } from 'react';
import styled from '@emotion/styled';
import { faSquareFacebook } from '@fortawesome/free-brands-svg-icons';
import { Box } from '@mui/material';

import globeIcon from 'src/assets/icons/globe.svg';
import userAvatarImg from 'src/assets/img/empty-avatar.svg';
import { IFacebookDetailsResponse, IFacebookPage, IPostAttachment, TSchedulePost } from 'src/types';
import {
  AttachmentImage,
  AttachmentVideo,
  PostPreviewText,
  PreviewContainer,
  PreviewSocialIcon,
  SocialAvatar,
  SocialAvatarContainer,
  UserDataContainer,
  UserName,
} from '../../_components';

const OnlineBadge = styled.div`
  background: #057642;
  width: 12px;
  height: 12px;
  position: absolute;
  bottom: 2px;
  right: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #fff;
`;

export const Facebook = ({
  postData,
  facebookProfileDetails,
  enabledFacebookPages,
}: {
  postData: TSchedulePost;
  facebookProfileDetails: IFacebookDetailsResponse | null;
  enabledFacebookPages: IFacebookPage[];
}) => {
  const { name, avatar } = useMemo(() => {
    if (enabledFacebookPages.length) {
      return { name: enabledFacebookPages[0]?.name };
    }

    return { name: facebookProfileDetails?.name, avatar: facebookProfileDetails?.picture?.data.url };
  }, [facebookProfileDetails, enabledFacebookPages]);

  return (
    <PreviewContainer>
      <PreviewSocialIcon color="#1876f2" icon={faSquareFacebook} />
      <Box display="flex" alignItems="center" mb="12px">
        <SocialAvatarContainer>
          <SocialAvatar src={avatar || userAvatarImg} />
          <OnlineBadge />
        </SocialAvatarContainer>
        <UserDataContainer ml="10px">
          <UserName>{name}</UserName>
          <Box display="flex">
            <span>Just now</span>
            <Box mx="5px">•</Box>
            <img src={globeIcon} />
          </Box>
        </UserDataContainer>
      </Box>
      <div>
        <PostPreviewText content={postData.content} type="facebook" />
        {postData.attachments?.map((attachment: IPostAttachment) => {
          const fileType = attachment.file_type;

          if (fileType.includes('image')) {
            return (
              <AttachmentImage
                data-testid="image-attachment"
                src={attachment.file_uri}
                key={`attachment-${attachment.file_uri}`}
              />
            );
          }

          if (fileType.includes('video')) {
            return (
              <AttachmentVideo
                data-testid="video-attachment"
                key={`attachment-${attachment.file_uri}`}
                src={attachment.file_uri}
                controls
              ></AttachmentVideo>
            );
          }

          return null;
        })}
      </div>
    </PreviewContainer>
  );
};
