import { useMemo, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';

import { Typography } from 'src/components';
import { useAppSelector, useTrendsFetchingBySocket } from 'src/hooks';
import { useGetTrendPosts, useGetTrends, useGetUserAccount } from 'src/reactQueries';
import { FetchingPosts } from './FetchingPosts';
import { Post } from './Post';
import { PostsList } from './PostsList';
import { TrendLayout } from './TrendLayout';

export const Trends = () => {
  const [searchParams] = useSearchParams();
  const { userMe } = useGetUserAccount();
  const [selectedPost, setSelectedPost] = useState<number>();
  const { fetchingTrends } = useAppSelector((state) => state.trends);

  const selectedTrend = useMemo(() => {
    return Number(searchParams.get('trendId') || -1);
  }, [searchParams]);

  useTrendsFetchingBySocket();
  const { data: trends } = useGetTrends();
  const { data, isLoading } = useGetTrendPosts(
    {
      trendId: selectedTrend,
    },
    { enabled: !fetchingTrends[selectedTrend] },
  );

  const currentPost = useMemo(() => {
    return data?.find((post) => post.id === selectedPost);
  }, [selectedPost, data]);

  const renderPosts = () => {
    if (!userMe?.isLinkedInLoginValid) {
      return (
        <Box display="flex" justifyContent="center" py="40px">
          <Typography color="gray.dark">
            Please connect your Linkedin account. <Link to="/integrations/linkedin">Click here</Link> to go to the
            Integration page.
          </Typography>
        </Box>
      );
    }

    if (!trends?.length) {
      return (
        <Box display="flex" justifyContent="center" py="40px">
          <Typography color="gray.dark">You don't have any trend yet</Typography>
        </Box>
      );
    }

    if (fetchingTrends[selectedTrend]) {
      return <FetchingPosts />;
    }

    return (
      <Box data-testid="posts" pt="15px" px="25px" pb="95px" display="flex">
        <PostsList posts={data} selectedPost={selectedPost} setSelectedPost={setSelectedPost} />
        <Post post={currentPost} />
      </Box>
    );
  };

  return (
    <>
      <TrendLayout trends={trends} selectedTrendId={selectedTrend} />
      {isLoading ? (
        <Box display="flex" justifyContent="center" py="40px">
          <CircularProgress data-testid="trends-loading" />
        </Box>
      ) : (
        renderPosts()
      )}
    </>
  );
};
