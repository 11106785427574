import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getWhiteLabelBranding } from 'src/api';
import { APP_HOSTNAME } from 'src/constants';
import { IWhiteLabel } from 'src/types';

export const useGetWhiteLabelBranding = <TData = IWhiteLabel>(
  options?: UseQueryOptions<IWhiteLabel, AxiosError, TData>,
) => {
  return useQuery<IWhiteLabel, AxiosError, TData>(
    ['get-whitelabel-branding'],
    () =>
      new Promise((resolve) =>
        getWhiteLabelBranding({ domain: APP_HOSTNAME })
          .then(resolve)
          .catch(() => resolve({} as IWhiteLabel)),
      ),
    options,
  );
};
