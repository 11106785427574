import { useQuery, UseQueryOptions } from 'react-query';

import { getTemplateCategories } from 'src/api';
import { ICustomAxiosError, ITemplateCategory } from 'src/types';

export const useGetTemplateCategories = (options?: UseQueryOptions<ITemplateCategory[], ICustomAxiosError>) => {
  const { data, ...rest } = useQuery<ITemplateCategory[], ICustomAxiosError>(
    ['get-template-categories'],
    getTemplateCategories,
    options,
  );

  return { categories: data, ...rest };
};
