import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { LIConversationFilterType } from 'src/enums';
import { useAppSelector } from 'src/hooks';
import { clearConversationState, setFilterType } from 'src/store/conversation.slice';
import { Conversation } from '../_components';
import { ConversationsList } from './ConversationsList';
import { GroupMembers } from './GroupMembers';
import { PersonInformation } from './PersonInformation';

const Wrapper = styled(Box)`
  background-color: #fff;
  margin: 15px;
  display: flex;
  height: calc(100% - 171px);
  min-height: 420px;
`;

interface ILinkedinConversationsProps {
  type?: LIConversationFilterType;
}

export const LinkedinConversations = ({ type }: ILinkedinConversationsProps) => {
  const dispatch = useDispatch();

  const { selectedConversation, filterType } = useAppSelector((state) => state.conversation);

  useEffect(() => {
    if (type === filterType) return;

    dispatch(clearConversationState());

    if (type) {
      dispatch(setFilterType(type));
    } else {
      dispatch(setFilterType(LIConversationFilterType.ALL));
    }
  }, [type]);

  useEffect(() => {
    return () => {
      dispatch(clearConversationState());
    };
  }, []);

  const conversationAside =
    selectedConversation &&
    (selectedConversation.isGroup ? (
      <GroupMembers participants={selectedConversation?.participants || []} />
    ) : (
      type !== LIConversationFilterType.INMAIL && <PersonInformation />
    ));

  return (
    <Wrapper>
      <ConversationsList />

      {selectedConversation ? (
        <>
          <Conversation conversationData={selectedConversation} filterType={filterType} />
          {conversationAside}
        </>
      ) : (
        <Box flexGrow="1" height="100%" display="flex" alignItems="center" justifyContent="center">
          <Typography fontSize="14px">Please select the conversation.</Typography>
        </Box>
      )}
    </Wrapper>
  );
};
