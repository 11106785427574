import { useQuery, UseQueryOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { getLastInvitation } from 'src/api';
import { ModalTypes } from 'src/enums';
import { delay } from 'src/helpers';
import { openModal } from 'src/store/modal.slice';
import { ICustomAxiosError, IGetInvitationToTeamResponse, IModalConfirmInvitationToTeamProps } from 'src/types';

export const useGetLastInvitationToTeam = (
  options?: UseQueryOptions<IGetInvitationToTeamResponse, ICustomAxiosError>,
) => {
  const dispatch = useDispatch();

  return useQuery<IGetInvitationToTeamResponse, ICustomAxiosError>(['ge-last-invitation'], getLastInvitation, {
    ...options,
    onSuccess: (invitationData) => {
      options?.onSuccess?.(invitationData);
      if (invitationData?.id) {
        delay(2000, () => {
          dispatch(
            openModal({
              type: ModalTypes.CONFIRM_INVITATION_TO_TEAM,
              headerText: 'Team invitation received',
              params: {
                teamName: invitationData.team?.name,
                userRole: invitationData.role,
                inviteId: invitationData.id,
                isInviteToCustomDomain: !!invitationData.team?.isWhiteLabelCustomDomain,
              } as IModalConfirmInvitationToTeamProps,
            }),
          );
        });
      }
    },
    // },
  });
};
