import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { removeSubscription } from 'src/api';
import { ICustomAxiosError } from 'src/types';

export const useRemoveSubscription = (options?: UseMutationOptions<void, ICustomAxiosError>) => {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(['remove-subscription'], removeSubscription, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      await queryClient.invalidateQueries(['get-billing-info']);
      await queryClient.invalidateQueries(['get-team-by-id']);
    },
  });

  return { removeSubscription: mutate, ...rest };
};
