import { useCallback } from 'react';

import { FEATURES_AVAILABILITY } from 'src/constants';
import { Features, Plan, PlanVersions, TrialStatus } from 'src/enums';
import { useAppSelector } from 'src/hooks';

export const useTeamPlan = () => {
  const { data: team } = useAppSelector((state) => state.team);

  const checkFeature = useCallback(
    (feature: Features): boolean => {
      if (!team?.plan) {
        return false;
      }

      const plan = team.plan === Plan.BUSINESS ? `${team.plan}-v${team.planVersion}` : team.plan;

      return FEATURES_AVAILABILITY[feature].includes(plan as Plan);
    },
    [team?.plan],
  );

  return {
    isTrial: team?.plan === Plan.TRIAL,
    isIndividual: team?.plan === Plan.INDIVIDUAL,
    isStarter: team?.plan === Plan.STARTER,
    isPersonal: team?.plan === Plan.PERSONAL,
    isBusiness: team?.plan === Plan.BUSINESS,
    isBusinessV1: `${team?.plan}-v${team?.planVersion}` === `${Plan.BUSINESS}-${PlanVersions.v1}`,
    isBusinessV2: `${team?.plan}-v${team?.planVersion}` === `${Plan.BUSINESS}-${PlanVersions.v2}`,
    isEnterprise: team?.plan === Plan.ENTERPRISE,
    isAgency: team?.plan === Plan.AGENCY,
    isTrialStarted: team?.trial === TrialStatus.STARTED,

    isDeactivated: !team?.hasPaidPlan && team?.trial === TrialStatus.ENDED,

    checkFeature,
  };
};
