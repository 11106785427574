import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { To, useLocation, useNavigate, useParams } from 'react-router-dom';

import { HeaderButton } from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { CampaignCategory, Features, ModalTypes } from 'src/enums';
import { useSequenceForm, useTeamPlan } from 'src/hooks';
import { useGetCampaign, useUpdateCampaignSequence } from 'src/reactQueries';
import { openModal } from 'src/store';
import { ILocationStateSequence, ISequence, ISequenceStep } from 'src/types';
import { CampaignsSteps } from './CampaignSteps';
import { Sequence } from './Sequence';

export const CampaignSequence = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { checkFeature } = useTeamPlan();

  const location = useLocation();
  const locationState = (location.state ?? {}) as ILocationStateSequence;
  const { campaignId } = useParams<{ campaignId: string }>();
  const { canPublish = true } = locationState;

  const { campaign } = useGetCampaign(Number(campaignId), {
    select: (data) => ({
      ...data,
      touchSequence: {
        ...data?.touchSequence,
        sequence: data?.touchSequence?.sequence?.map((step) => ({
          ...step,
          is_editing: data?.status === 'draft',
        })),
      },
    }),
  });

  const { updateCampaignSequence, isLoading } = useUpdateCampaignSequence({
    onSuccess: () => {
      navigate(canPublish ? `/campaign/${campaignId}/publish` : (-1 as To), location);
    },
  });

  const sequenceForm = useForm<ISequence>({
    mode: 'all',
    defaultValues: {
      sequence: campaign?.touchSequence?.sequence ?? [],
    },
  });
  const { handleSubmit, formState, reset, watch, control } = sequenceForm;

  const fieldArrayForm = useFieldArray({
    control,
    name: 'sequence',
  });

  const sequence = watch('sequence');

  const onSubmit = (values: ISequence) => {
    if (campaignId) {
      updateCampaignSequence({ campaignId: Number(campaignId), body: values });
    }
  };

  const onChooseSequenceTemplate = () => {
    dispatch(
      openModal({
        type: ModalTypes.CHOOSE_SEQUENCE_TEMPLATE,
        headerText: 'Choose Sequence Template',
        params: {
          category: campaign?.category,
          onAddSequenceTemplate: (steps: Array<ISequenceStep>) => fieldArrayForm.append(steps),
        },
        size: 'large',
      }),
    );
  };

  const onSaveAsSequenceTemplate = () => {
    dispatch(
      openModal({
        type: ModalTypes.SAVE_AS_SEQUENCE_TEMPLATE,
        headerText: 'Save Sequence Template',
        params: { category: campaign?.category, sequence },
      }),
    );
  };

  const { submit } = useSequenceForm({ category: campaign?.category, campaign, onSubmit, sequence });

  useEffect(() => {
    reset(campaign?.touchSequence);
  }, [campaign]);

  const isTwitterCategory = campaign?.category === CampaignCategory.TWITTER;

  return (
    <>
      <CampaignsSteps>
        <HeaderButton onClick={onChooseSequenceTemplate}>Choose a Template</HeaderButton>
        <HeaderButton
          variant="gray"
          disabled={!sequence?.length || !isEmpty(formState.errors) || !checkFeature(Features.sequenceTemplates)}
          tooltip={{ title: !checkFeature(Features.sequenceTemplates) && PLAN_TOOLTIPS.professional }}
          onClick={onSaveAsSequenceTemplate}
        >
          Save as Template
        </HeaderButton>
        <HeaderButton
          id={canPublish ? 'next-step-btn' : ''}
          data-intercom-target={canPublish ? 'next-step-btn' : ''}
          size={{ width: '180px' }}
          processing={isLoading}
          disabled={!sequence?.length || !isEmpty(formState.errors) || isTwitterCategory}
          onClick={handleSubmit(submit)}
          tooltip={{
            title:
              (isTwitterCategory && 'Feature under Maintenance') ||
              (!sequence?.length && 'Please add a step in the sequence to continue'),
          }}
        >
          {canPublish ? 'Next' : 'Save'}
        </HeaderButton>
      </CampaignsSteps>
      <Sequence
        campaign={campaign}
        sequenceForm={sequenceForm}
        fieldArrayForm={fieldArrayForm}
        category={campaign?.category}
      />
    </>
  );
};
