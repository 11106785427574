import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { ReactComponent as EditPencilIcon } from 'src/assets/icons/edit-pencil-icon.svg';
import { Button, MessageBlock, Spinner, Typography } from 'src/components';
import { BILLING_TYPE_NAMES, PLAN_SUB_DESCRIPTIONS } from 'src/constants';
import { Plan } from 'src/enums';
import { getAmountString, getCurrencySymbol } from 'src/helpers';
import { useGetBillingInfo, useGetPlanPricing, useGetUpcomingInvoiceDetails } from 'src/reactQueries';
import { BlockTitle, PlanPrice } from '../_components';
import { PaymentDetails } from '../PaymentDetails';
import { AvailableSeats } from './AvailableSeats';
import { CanceledSubscription } from './CanceledSubscription';
import { CancelSubscription } from './CancelSubscription';

const PlanDetailsWrapper = styled(Box)`
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  overflow: hidden;
`;

const PlanDetails = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.gray[100]};
  border-radius: 6px;
  padding: 16px;
  width: 100%;
`;

export const SubscriptionPlan = () => {
  const navigate = useNavigate();

  const { palette } = useTheme();

  const { billingInfo, isLoading: isLoadingBillingInfo, isSubscriptionCanceled } = useGetBillingInfo();

  const { invoiceDetails, isLoading: isLoadingInvoiceDetails } = useGetUpcomingInvoiceDetails({
    enabled: !isSubscriptionCanceled,
  });

  const { plan, billingType, currency, stripeSubscriptionObject, stripeTokenObject, seats = 1 } = billingInfo || {};

  const { data: price, isLoading: isLoadingPlanPricing } = useGetPlanPricing({
    plan,
    billingType,
    currency,
  });

  const { minSeats = 1, initialSeatsPrice = 0, extraSeatPrice = 0 } = price || {};

  const extraSeats = Math.max(seats - minSeats, 0); // prevent extra seats from being negative
  const extraSeatsPrice = extraSeats * extraSeatPrice;

  const isAvailableSeatsShown = [Plan.BUSINESS, Plan.AGENCY, Plan.ENTERPRISE].includes(plan as Plan);

  const onChangePlanClick = () => navigate('/billing/subscription/plans');
  const onEditPlanClick = () => navigate('/billing/subscription/edit');

  if (isLoadingBillingInfo || isLoadingPlanPricing) {
    return <Spinner />;
  }

  return (
    <Box display="flex" alignItems="flex-start" gap="16px">
      <Box p="20px" bgcolor="#fff" borderRadius="4px" width="100%" display="flex" flexDirection="column" gap="24px">
        {isSubscriptionCanceled ? (
          <CanceledSubscription />
        ) : (
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <BlockTitle>Your Current plan</BlockTitle>

            <Button variant="gray" onClick={onChangePlanClick}>
              Change plan
            </Button>
          </Box>
        )}

        <PlanDetailsWrapper borderColor={isSubscriptionCanceled ? palette.gray[100] : palette.accent[500]}>
          <Box p="20px" bgcolor={`${palette.gray[50]}80`}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <BlockTitle capitalizeFirstLetter>{plan}</BlockTitle>

              {!isSubscriptionCanceled && (
                <Button variant="transparent" onClick={onEditPlanClick}>
                  <Box display="flex" alignItems="flex-end" gap="4px">
                    <EditPencilIcon />
                    <Box component="span" lineHeight="14px">
                      Edit Plan
                    </Box>
                  </Box>
                </Button>
              )}
            </Box>

            <Typography color="gray.800" fontSize="15px" mt="12px">
              {plan && PLAN_SUB_DESCRIPTIONS[plan]}
            </Typography>
          </Box>

          <Box p="20px">
            <Box display="flex" gap="12px" mb="12px">
              <PlanDetails>
                <Typography component="span" fontSize="15px" color="gray.500" mb="12px">
                  {minSeats} Included seat{minSeats > 1 ? 's' : ''}
                </Typography>

                <PlanPrice price={initialSeatsPrice} currency={currency} />
              </PlanDetails>

              {!!extraSeats && (
                <PlanDetails>
                  <Typography component="span" fontSize="15px" color="gray.500" mb="12px">
                    {extraSeats} additional seat{extraSeats > 1 ? 's' : ''}
                  </Typography>
                  <PlanPrice price={extraSeatsPrice} currency={currency} />
                </PlanDetails>
              )}

              <PlanDetails>
                <Typography component="span" fontSize="15px" color="gray.500" mb="14px">
                  Billed
                </Typography>
                <Typography component="span" fontSize="15px" color="gray.500">
                  {billingType && BILLING_TYPE_NAMES[billingType]}
                </Typography>
              </PlanDetails>
            </Box>

            <MessageBlock
              noBorder
              message={
                isSubscriptionCanceled ? (
                  <Typography color="gray.600">
                    You can only modify your plan once your subscription is active again.
                  </Typography>
                ) : (
                  <>
                    Your next bill is {getCurrencySymbol(currency)}
                    {getAmountString(invoiceDetails?.amount_due)} on
                    {dayjs.unix(stripeSubscriptionObject?.current_period_end || 0).format(' MMM D, YYYY')}.<br />
                    Automatically paid with card ending in •••• {stripeTokenObject?.card?.last4}.
                  </>
                )
              }
            />
          </Box>
        </PlanDetailsWrapper>

        {isAvailableSeatsShown && <AvailableSeats />}

        <CancelSubscription />
      </Box>

      <PaymentDetails
        plan={plan}
        billingType={billingType}
        currency={currency}
        invoiceDetails={invoiceDetails}
        isLoadingInvoiceDetails={isLoadingInvoiceDetails}
        showInvoiceDetails={!isSubscriptionCanceled}
      />
    </Box>
  );
};
