import { useState } from 'react';
import styled from '@emotion/styled';
import { faTimes, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MuiModal from '@mui/material/Modal';

const StyledLink = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.palette.info.dark};
  font-size: 14px;
  line-height: 14px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.lightGray.light};
  transition: color 0.2s;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.info.main};
  }
`;

const StyledMuiModal = styled(MuiModal)`
  overflow-y: overlay;
`;

const CloseIcon = styled.div`
  position: absolute;
  right: 25px;
  top: 25px;
  color: #ffffff;
  font-size: 30px;
  line-height: 1;
  font-weight: 900;
  cursor: pointer;
`;

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 888px;
  height: 500px;
  background-color: #ffffff;
  border: 4px solid #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 8px 1px #000;
`;

interface IHeaderVideoProps {
  src?: string;
}

export const HeaderVideo = ({ src }: IHeaderVideoProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const openHandler = () => {
    setIsOpen(true);
  };

  const closeHandler = () => {
    setIsOpen(false);
  };

  return (
    <>
      <StyledLink onClick={openHandler}>
        <FontAwesomeIcon icon={faVideo} /> Watch a video
      </StyledLink>
      <StyledMuiModal open={isOpen} closeAfterTransition onClose={closeHandler}>
        <>
          <Wrapper>
            <iframe
              className="vimeo-frame"
              src={`${src}?h=453bfddb4e&color=53A25d&title=0&byline=0&portrait=0`}
              width="888"
              height="500"
              frameBorder="0"
              allowFullScreen
            />
          </Wrapper>
          <CloseIcon onClick={closeHandler}>
            <FontAwesomeIcon icon={faTimes} />
          </CloseIcon>
        </>
      </StyledMuiModal>
    </>
  );
};
