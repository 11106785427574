import classNames from 'classnames';
import { UseFormReturn } from 'react-hook-form';
import { Box } from '@mui/material';

import { Button, Typography } from 'src/components';
import { useGetUserAccount, useGetUserPreferences, useUpdatePassword } from 'src/reactQueries';
import { IUpdateCredentials } from 'src/types';
import { ErrorMessage, Title } from '../_components';
import { labelStyle, StyledInput } from './ui';

export const ChangePassword = ({
  register,
  getValues,
  trigger,
  formState: { errors },
}: UseFormReturn<IUpdateCredentials>) => {
  const formError = errors.password?.message || errors.newPassword?.message;

  const { changePassword, error, isLoading } = useUpdatePassword();
  const { message } = (error ?? {}) as { message: string };

  const { userMe } = useGetUserAccount();
  const { isGhostUser } = userMe ?? {};

  const { userPreferences } = useGetUserPreferences();
  const { havePassword } = userPreferences?.userProfile ?? {};

  const onChangePassword = async () => {
    const [isPasswordValid, isNewPasswordValid] = await Promise.all([trigger('password'), trigger('newPassword')]);

    if (!isPasswordValid || !isNewPasswordValid) {
      return;
    }

    const { password, newPassword } = getValues();

    changePassword({ password, newPassword });
  };

  return (
    <Box>
      {!isGhostUser && <Title>{havePassword ? 'Change password' : 'Set password'}</Title>}
      <Box>
        {!isGhostUser && !havePassword && (
          <Typography bold>If you set a password you will no longer be able to login using google/microsoft</Typography>
        )}
        <Box display="flex" gap="20px" mt="10px" mb="20px">
          {!isGhostUser && havePassword && (
            <StyledInput
              register={register}
              parameters={{
                required: 'Please enter a password',
                minLength: {
                  value: 6,
                  message: 'The password must be a minimum of 6 characters',
                },
              }}
              className={classNames({
                error: errors?.password,
              })}
              type="password"
              name="password"
              label="Current password"
              labelStyle={labelStyle}
              placeholder="Current password"
            />
          )}
          <Box width="100%">
            <StyledInput
              register={register}
              parameters={{
                required: 'Please enter a new password',
                minLength: {
                  value: 6,
                  message: 'The password must be a minimum of 6 characters',
                },
              }}
              className={classNames({
                error: errors?.newPassword,
              })}
              type="password"
              name="newPassword"
              label="New password"
              labelStyle={labelStyle}
              placeholder="New password"
            />
          </Box>
        </Box>
        {(formError || message) && (
          <ErrorMessage data-testid="error-message">
            {formError || (message === 'Current password invalid' ? 'The current password is wrong' : message)}
          </ErrorMessage>
        )}
        {/* ghost users have separate button for setting email and password at once */}
        {!isGhostUser && (
          <Box display="flex" justifyContent="flex-end">
            <Button processing={isLoading} onClick={onChangePassword}>
              Save
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
