import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateTeam } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IUpdateTeamRequest } from 'src/types';

export const useUpdateTeam = (options?: UseMutationOptions<void, ICustomAxiosError, IUpdateTeamRequest>) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation(['update-team-data'], updateTeam, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      await queryClient.invalidateQueries(['get-team-by-id']);
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { updateTeam: mutate, ...rest };
};
