import { useMemo } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

interface SystemMessageProps {
  name?: string;
  content?: string;
  participantChanges?: {
    added: string[];
    removed: string[];
  };
}

const Message = styled(Box)`
  width: 70%;
  margin: 10px auto;
  text-align: center;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.gray.light};
`;

export const SystemMessage = ({
  name,
  participantChanges = { added: [], removed: [] },
  content,
}: SystemMessageProps) => {
  const addedText = useMemo(() => {
    const addedMembers = [...participantChanges.added];
    const lastMember = addedMembers.pop();

    return `${name} added ${addedMembers.join(', ')}${
      addedMembers.length ? ' and ' : ' '
    }${lastMember} to this conversation`;
  }, [participantChanges.added]);

  const removedText = useMemo(() => {
    const removedMembers = [...participantChanges.removed];
    const lastMember = removedMembers.pop();

    return `${name} removed ${removedMembers.join(', ')}${
      removedMembers.length ? ' and ' : ''
    }${lastMember} from this conversation`;
  }, [participantChanges.added]);

  return (
    <>
      {content && <Message>{content}</Message>}
      {!!participantChanges.added.length && <Message>{addedText}</Message>}
      {!!participantChanges.removed.length && <Message>{removedText}</Message>}
    </>
  );
};
