import { isNull, sortBy } from 'lodash';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faTimes, faUndo } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';

import { ActionButton, Checkbox, NotificationDot, TableCell, TableRow, Tags } from 'src/components';
import { CampaignType, LeadStatus, ModalTypes } from 'src/enums';
import { resolvePersonName } from 'src/helpers';
import { useDeleteIgnoredCampaignLeads, useReturnIgnoredCampaignLead } from 'src/reactQueries';
import { openModal } from 'src/store';
import { IGetIgnoredCampaignLeadRequest, IIgnoredLead, ISelectProps } from 'src/types';

const Text = styled(Box)<{
  checked?: boolean;
}>`
  max-width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline;
  color: ${({ checked, theme }) => (checked ? 'inherit' : theme.palette.text.primary)};
`;

const StyledLink = styled(Link)`
  width: 100%;
`;

interface ILeadsTableRow {
  campaignId: number;
  campaignType?: CampaignType;
  lead: IIgnoredLead;
  getIgnoredLeadKeys: IGetIgnoredCampaignLeadRequest;
  selectProps: ISelectProps;
}

export const IgnoredLeadsTableRow = ({
  lead,
  campaignId,
  campaignType,
  getIgnoredLeadKeys,
  selectProps,
}: ILeadsTableRow) => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const {
    name,
    step,
    campaign,
    personKey,
    linkedinData,
    customData,
    ownerName,
    workEmail,
    twitterHandle,
    reason,
    tags = [],
    status,
  } = lead;
  const { handle } = linkedinData ?? {};
  const { firstName, lastName } = customData ?? {};
  const { numOfTouches } = campaign ?? {};
  const isSelected = selectProps.selectLeads.includes(personKey);
  const sortedTags = useMemo(() => sortBy(tags, 'tag'), [tags]);
  const tagIds = useMemo(() => sortedTags.map(({ id }) => id), [sortedTags]);

  const leadStep = numOfTouches && !isNull(step) ? `${step}/${numOfTouches}` : '';

  const { deleteCampaignLead, isDeleteCampaignLoading } = useDeleteIgnoredCampaignLeads(campaignId, getIgnoredLeadKeys);
  const { returnCampaignLead, isReturnLoading } = useReturnIgnoredCampaignLead(campaignId, getIgnoredLeadKeys);

  const onCellClick = () => {
    dispatch(
      openModal({
        headerText: 'Change tags',
        type: ModalTypes.ADD_LEADS_TAGS,
        params: { entityUrn: personKey, tagIds, campaignId, campaignKeys: getIgnoredLeadKeys },
      }),
    );
  };

  const actions = [
    {
      title: 'Move to this campaign',
      onClick: () => returnCampaignLead([personKey]),
      isLoading: isReturnLoading,
      icon: faUndo,
    },
    {
      title: 'Remove ignored lead',
      onClick: () => deleteCampaignLead([personKey]),
      isLoading: isDeleteCampaignLoading,
      icon: faTimes,
    },
  ];

  const { name: personName, avatarContent } = resolvePersonName({
    linkedInData: linkedinData,
    linkedInHandle: handle || personKey,
    name: firstName ? `${firstName} ${lastName}` : name,
    isSelected,
    twitterHandle,
    workEmail,
    campaignType,
  });

  const modalHandler = () => {
    dispatch(
      openModal({
        type: ModalTypes.VIEW_LEAD,
        closable: false,
        params: {
          campaignId,
          entityUrn: personKey,
        },
      }),
    );
  };

  const resolveCampaign = () => {
    const { name: campaignName, status: campaignStatus, isRemoved } = campaign || {};

    if (!campaign && [LeadStatus.IN_OTHER_CAMPAIGN, LeadStatus.IN_OTHER_MEMBER_CAMPAIGN].includes(status)) {
      return <NotificationDot tooltip={{ title: `Lead was removed from campaign`, placement: 'right' }} />;
    }

    if (!campaign) return;

    if (ownerName) {
      return (
        <>
          <Text checked={isSelected}>{campaignName}</Text>
          <NotificationDot tooltip={{ title: `Owner: ${ownerName}`, placement: 'right' }} />
        </>
      );
    }

    if (isRemoved) {
      return (
        <>
          <Text checked={isSelected}>{campaignName}</Text>
          <NotificationDot tooltip={{ title: `Campaign deleted`, placement: 'right' }} />
        </>
      );
    }

    return (
      <StyledLink to={`/campaign/details/overview/${campaign.id}`} onClick={(e) => e.stopPropagation()}>
        <Text checked={isSelected}>
          {campaignName} <Text color={palette.error.main}>{campaignStatus === 'archived' && '(archived)'}</Text>
        </Text>
      </StyledLink>
    );
  };

  return (
    <TableRow
      checkedBgColor={palette.primary.main}
      hoverBgColor={({ light }) => light.main}
      checkedColor="#fff"
      checked={isSelected}
      cursor="pointer"
      onClick={() => modalHandler()}
    >
      <TableCell onClick={(event) => event.stopPropagation()}>
        <Checkbox checked={isSelected} onChange={() => selectProps.toggleLead(personKey)} />
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          {avatarContent}
          <Text checked={isSelected} ml="20px">
            {campaign?.campaignType === CampaignType.EMAIL_CSV_UPLOAD ? workEmail : personName}
          </Text>
        </Box>
      </TableCell>
      <TableCell maxWidth="200px" tooltip={reason?.length >= 30 && reason}>
        <Text checked={isSelected}>{reason}</Text>
      </TableCell>
      <TableCell contentStyle={{ width: '100%' }}>{resolveCampaign()}</TableCell>
      <TableCell contentStyle={{ width: '100%' }}>
        <Text checked={isSelected}>{(reason === 'In other campaign' || ownerName) && leadStep}</Text>
      </TableCell>
      <TableCell maxWidth="180px" preventClick contentStyle={{ minWidth: '100%' }}>
        <Tags onClick={onCellClick} withTooltip ids={tagIds} />
      </TableCell>
      <TableCell preventClick justifyContent="flex-end">
        <Box display="flex" gap="8px">
          {actions?.map(({ icon, onClick, isLoading, title }) => (
            <ActionButton key={title} tooltip={title} processing={isLoading} onClick={onClick} icon={icon} />
          ))}
        </Box>
      </TableCell>
    </TableRow>
  );
};
