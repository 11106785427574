import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Grid } from '@mui/material';

import { Button, ErrorMessage, Input, Link, Typography } from 'src/components';
import { AuthLayout } from 'src/layouts';
import { useSetPassword } from 'src/reactQueries';
import { ISetNewPassword } from 'src/types';
import { AuthForm, MainHeading } from './_components';

const Container = styled.div`
  width: 90%;
  max-width: 541px;
  margin: auto;
  padding: 3rem;
`;

export const SetPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ISetNewPassword>();

  const { token } = useParams<{ token: string }>();

  const { setNewPassword, error, isSuccess, isLoading } = useSetPassword();

  const submit = (data: ISetNewPassword) => {
    setNewPassword({ body: data, token: token || '' });
  };

  return (
    <AuthLayout>
      <Container>
        <MainHeading>Password recovery</MainHeading>

        {isSuccess ? (
          <Typography color="primary.wlLight" mt="20px" bold textAlign="center">
            Password successfully changed.{' '}
            <Link underline to="/auth/login">
              Click here to login
            </Link>
          </Typography>
        ) : (
          <AuthForm data-testid="form" onSubmit={handleSubmit(submit)}>
            <Typography color="primary.dark" mb="10px" bold>
              Please type your new password
            </Typography>
            <Grid container justifyContent="space-between">
              <Grid item sm={5.5} xs={12} mb="10px">
                <Input
                  register={register}
                  label="Password"
                  name="password"
                  parameters={{
                    required: 'Please enter a password',
                    minLength: {
                      value: 6,
                      message: 'Please enter a password at least 6 characters long',
                    },
                  }}
                  data-testid="password"
                  placeholder="Password"
                  className={classNames({ error: errors.password })}
                  type="password"
                />
              </Grid>
              <Grid item sm={5.5} xs={12}>
                <Input
                  register={register}
                  label="Confirm Password"
                  name="confirmPassword"
                  parameters={{
                    validate: (value) => value === watch('password') || 'The passwords do not match',
                  }}
                  data-testid="confirmPassword"
                  placeholder="Confirm Password"
                  className={classNames({ error: errors.confirmPassword })}
                  type="password"
                />
              </Grid>
            </Grid>
            <Button data-testid="button" type="submit" disabled={isLoading} className="margin-top-20">
              Reset password
            </Button>

            {(errors.password || errors.confirmPassword || !!error) && (
              <ErrorMessage data-testid="error-message">
                {errors.password?.message || errors.confirmPassword?.message || 'Something went wrong.'}
              </ErrorMessage>
            )}
          </AuthForm>
        )}
      </Container>
    </AuthLayout>
  );
};
