import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { deleteMicrosoftOutlook } from 'src/api';
import { ICustomAxiosError } from 'src/types';

export const useDeleteMicrosoftOutlook = (options?: UseMutationOptions<void, ICustomAxiosError>) => {
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation('delete-gmail', deleteMicrosoftOutlook, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      queryClient.invalidateQueries('get-users-email');
    },
  });

  return {
    deleteMicrosoftOutlook: mutate,
    ...rest,
  };
};
