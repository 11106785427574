import styled from '@emotion/styled';
import { faDownload, faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { ILIMessage } from 'src/types';

const Download = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
  left: 0;
  transition: 0.2s;
  opacity: 0;
`;

const File = styled(Box)`
  display: flex;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 0 0 1px rgb(0 0 0 / 15%);
  position: relative;
  cursor: pointer;

  & > div {
    transition: 0.2s;
  }

  &:hover {
    div {
      opacity: 0.3;
    }

    a {
      opacity: 1;
    }
  }
`;

export const MessageAttachment = ({ content }: ILIMessage) => {
  const gif = content.customContent;

  return (
    <>
      {gif?.media && <img src={gif.media.previewgif.url} alt={gif.title} width={gif.media.previewgif.originalWidth} />}
      {!!content?.attachments?.length &&
        content.attachments.map((attachment) => {
          const fileSrc = attachment.reference.string;
          const fileType = attachment.mediaType;
          const fileSize = attachment.byteSize;
          const fileName = attachment.name;

          if (fileType.indexOf('image') > -1) {
            return fileSize && <img key={fileSrc} src={fileSrc} alt={fileName} width="90%" />;
          }

          return (
            <File key={fileSrc}>
              <Box py="15px" pl="15px">
                <FontAwesomeIcon size="2x" icon={faFile} />
              </Box>
              <Box width="220px" ml="25px" py="5px">
                <Typography color="gray.dark" mb="8px">
                  {fileName}
                </Typography>
                <Typography color="gray.dark">{`${Math.ceil(fileSize / 1024)} KB`}</Typography>
              </Box>
              <Download target="_blank" rel="noopener noreferrer" download={fileName} href={fileSrc}>
                <FontAwesomeIcon size="lg" color="#000" icon={faDownload} />
              </Download>
            </File>
          );
        })}
    </>
  );
};
