import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { rejectTeamInvitation } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IRejectInvitationToTeamRequest } from 'src/types';

export const useRejectInvitationToTeam = (
  options?: UseMutationOptions<void, ICustomAxiosError, IRejectInvitationToTeamRequest>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation(['reject-invitation-to-team'], rejectTeamInvitation, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      await queryClient.invalidateQueries(['get-team-invites']);
      queryClient.removeQueries(['get-invitation-to-team']);

      localStorage.removeItem('lastInviteKey');
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { rejectInvitation: mutate, ...rest };
};
