import { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { Button, Input, Typography } from 'src/components';
import { useUpdateGroup } from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';
import { showToast } from 'src/store/toast.slice';
import { IModalProps, IModalTeamGroupUpdateProps } from 'src/types';
import { ModalBody, ModalFooter } from '../_components';

const TextInput = styled(Input)`
  color: #333;
  font-family: 'ProximaSoft', sans-serif;
  height: 33px;
  line-height: 19px;
  padding: 5px;
  width: 50%;
`;

export const ModalTeamGroupUpdate = ({ onCancel, params }: IModalProps<IModalTeamGroupUpdateProps>) => {
  const dispatch = useDispatch();
  const { groupId, name } = params;
  const [groupName, setGroupName] = useState(name);

  const { updateGroup } = useUpdateGroup();

  const confirmHandler = () => {
    if (!groupName?.trim()) {
      dispatch(showToast({ type: 'error', message: 'Please enter a name' }));

      return;
    }

    dispatch(closeModal());
    updateGroup({ groupId, name: groupName });
  };

  const closeHandler = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  const onChangeGroupName = (event: ChangeEvent<HTMLInputElement>) => {
    setGroupName(event.target.value);
  };

  return (
    <>
      <ModalBody>
        <Typography color="text.primary">Name:</Typography>
        <TextInput onChange={onChangeGroupName} value={groupName} type="text" placeholder="Group Name" />
      </ModalBody>

      <ModalFooter>
        <Button variant="gray" onClick={closeHandler}>
          Cancel
        </Button>
        <Button onClick={confirmHandler}>Save</Button>
      </ModalFooter>
    </>
  );
};
