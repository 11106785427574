// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createFormData = (params: Record<string, any>) => {
  const formData = new FormData();
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      value.forEach((item: File) => {
        formData.append(`${key}[]`, item);
      });
    } else if (value) {
      formData.append(key, value);
    }
  }

  return formData;
};
