import classNames from 'classnames';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { HeaderButton, Input, Typography } from 'src/components';
import { CampaignType } from 'src/enums';
import { getSearchUrl, validateLinkedinSearch } from 'src/helpers';
import { useLocationState, useSingleCampaignProps } from 'src/hooks';
import {
  useAddMoreLeadsCampaign,
  useCreateCampaignAudience,
  useGetCampaign,
  useUpdateCampaignAudience,
} from 'src/reactQueries';
import { transformCampaignRequestBody } from 'src/transformers';
import { ICampaignAudienceRequest, ILocationStateSingleCampaign } from 'src/types';
import { ControlledCheckbox, ControlledSlider } from '../../_components';
import { CampaignsSteps } from '../../CampaignSteps';
import { SearchParameters } from '../SearchParameters';

const Form = styled.form`
  width: 900px;
  padding: 30px 15px 80px;
`;

const StyledInput = styled(Input)`
  padding: 5px 10px;
  height: auto;
  border-width: 2px;
`;

const labelStyle = css`
  color: var(--text-primary);
  font-weight: 400 !important;
`;

const PreviewResult = styled.a`
  color: ${({ theme }) => theme.palette.lightGray.main};
  font-size: 15px;
  letter-spacing: 1px;
  padding-bottom: 3px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.lightGray.light};
  transition: 0.2s;
  margin-left: auto;

  &:hover {
    color: ${({ theme }) => theme.palette.info.main};
  }
`;

export const LinkedinSearch = ({ isLinkedinOnly }: { isLinkedinOnly: boolean }) => {
  const { initialCampaignId } = useLocationState<ILocationStateSingleCampaign>();
  const { isAddMoreLeads, isEdit, campaignId, campaign, category } = useSingleCampaignProps();

  const { campaign: initialCampaign } = useGetCampaign(initialCampaignId, {
    enabled: !!campaignId && !!initialCampaignId && !isAddMoreLeads,
  });

  const {
    register,
    control,
    reset,
    formState: { errors },
    getValues,
    setValue,
    handleSubmit,
    watch,
  } = useForm<ICampaignAudienceRequest>({
    defaultValues: transformCampaignRequestBody({ category, campaignType: CampaignType.LINKEDIN_SEARCH }),
  });

  useEffect(() => {
    if (initialCampaignId && initialCampaign) {
      reset(transformCampaignRequestBody(initialCampaign));
    } else if (!isAddMoreLeads && campaign) {
      reset(transformCampaignRequestBody(campaign));
    }
  }, [campaign, initialCampaign]);

  const { addMoreLeads, isAddMoreLeadsLoading } = useAddMoreLeadsCampaign(campaignId);
  const { createCampaignAudience, isCreateLoading } = useCreateCampaignAudience();
  const { updateCampaignAudience, isUpdateLoading } = useUpdateCampaignAudience(campaignId);

  const previewResults = () => {
    const url = getSearchUrl(getValues().searchParameters);
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const submit = (values: ICampaignAudienceRequest) => {
    const body = transformCampaignRequestBody({
      ...values,
      campaignType: CampaignType.LINKEDIN_SEARCH,
      searchUrl: getSearchUrl(values.searchParameters),
      includeMessageOnly: true,
      isLinkedinOnly: isLinkedinOnly,
    });

    if (isAddMoreLeads) {
      return addMoreLeads({
        ...body,
        searchType: CampaignType.LINKEDIN_SEARCH,
        campaignId,
      });
    }

    if (isEdit) {
      return updateCampaignAudience(body);
    }

    return createCampaignAudience(body);
  };

  return (
    <>
      <CampaignsSteps>
        <HeaderButton
          size={{ width: '180px' }}
          processing={isAddMoreLeadsLoading || isCreateLoading || isUpdateLoading}
          disabled={isAddMoreLeadsLoading}
          onClick={handleSubmit(submit)}
        >
          Next
        </HeaderButton>
      </CampaignsSteps>
      <Box bgcolor="light.light">
        <Form onSubmit={handleSubmit(submit)}>
          <StyledInput
            className={classNames({
              error: errors?.searchParameters?.keywords,
            })}
            register={register}
            parameters={{
              validate: (value) => validateLinkedinSearch(value),
              required: true,
            }}
            name="searchParameters.keywords"
            label="Who would you like to find? (eg. Job title, company name, etc.)"
            labelStyle={labelStyle}
            placeholder="eg. project manager OR manager OR senior project manager"
            tooltip={{
              placement: 'bottom',
              title: (
                <Box textAlign="left">
                  Examples of Boolean Searches:
                  <br />
                  * Quotes: “senior product manager”
                  <br />
                  * Parentheses: VP NOT (assistant OR svp OR “senior vp”)
                  <br />
                  * NOT: “sales development” NOT manager
                  <br />
                  * AND: manager AND director
                  <br />* OR: ceo OR “chief executive officer”
                </Box>
              ),
            }}
          />

          <Box display="flex" my="8px">
            {errors?.searchParameters?.keywords && (
              <Typography color="error.light">{errors?.searchParameters?.keywords?.message}</Typography>
            )}

            <PreviewResult onClick={previewResults}>
              Preview sample results <FontAwesomeIcon icon={faArrowRight} />
            </PreviewResult>
          </Box>

          <SearchParameters watch={watch} setValue={setValue} control={control} />

          <ControlledSlider name="maxSearchAmount" label="Maximum amount of leads:" max={1000} control={control} />

          <ControlledCheckbox name="excludeNoPhotos" label="Exclude profiles without photos." control={control} />
          <ControlledCheckbox name="noFirstConnections" label="Exclude 1st degree connections." control={control} />
          <ControlledCheckbox name="premiumOnly" label="Premium users only." control={control} />
          <ControlledCheckbox name="retargetLeads" label="Include leads from other campaigns." control={control} />
        </Form>
      </Box>
    </>
  );
};
