import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { Box, BoxProps, TooltipProps } from '@mui/material';

import { useGetAllTags } from 'src/reactQueries';
import { Tooltip } from '../ui';
import { Tag } from './Tag';

interface ITagsProps extends BoxProps {
  ids: number[];
  cursor?: string;
  withTooltip?: boolean;
  tooltip?: TooltipProps;
}

export const Tags = ({ ids, withTooltip, cursor = 'pointer', tooltip, ...boxProps }: ITagsProps) => {
  const { tags } = useGetAllTags();

  const objectTags = useMemo(() => {
    return keyBy(tags, 'id');
  }, [tags]);
  const selectedTags = ids.map((id) => objectTags[id]).filter(Boolean);

  const tagsTooltip = selectedTags.map(({ tag }) => tag).join(', ');

  return (
    <Tooltip hidden={!withTooltip} title={tagsTooltip} {...tooltip}>
      <Box height="30px" width="100%" display="flex" flexWrap="wrap" overflow="hidden" sx={{ cursor }} {...boxProps}>
        {selectedTags.map((tag) => (
          <Tag key={tag.id} {...tag} />
        ))}
      </Box>
    </Tooltip>
  );
};
