import { useEffect, useMemo } from 'react';
import { InfiniteData, useInfiniteQuery, UseInfiniteQueryOptions, useQueryClient } from 'react-query';

import { getSNConversationMessages } from 'src/api';
import { ICustomAxiosError, ILIConversation, ISNMessage } from 'src/types';

export const useGetSNConversationMessages = (
  {
    conversationId,
    entityUrn,
  }: {
    entityUrn: string;
    conversationId: string;
  },
  options?: UseInfiniteQueryOptions<ISNMessage[], ICustomAxiosError, ISNMessage[]>,
) => {
  const queryClient = useQueryClient();

  const queryKey = ['get-sn-conversations'];

  const { data, ...rest } = useInfiniteQuery<ISNMessage[], ICustomAxiosError, ISNMessage[]>(
    ['get-sn-conversation-messages', entityUrn, conversationId],
    ({ pageParam }) => getSNConversationMessages({ entityUrn, createdBefore: pageParam, conversationId }),
    {
      ...options,
      cacheTime: 0,
      getNextPageParam: (lastPage = []) => {
        return [...lastPage].pop()?.createdAt;
      },
      enabled: !!conversationId,
    },
  );

  useEffect(() => {
    const updateUnreadCount = async () => {
      await queryClient.cancelQueries(queryKey);

      queryClient.setQueriesData<InfiniteData<ILIConversation[]> | undefined>(queryKey, (conversations) => {
        if (conversations) {
          const newPages = conversations.pages.map((results) => {
            return results.map((conv) => {
              // once messages are loaded we have to make unread count to equal 0
              if (conv.conversationId === conversationId) {
                return { ...conv, unreadCount: 0 };
              }

              return conv;
            });
          });

          return { ...conversations, pages: newPages };
        }

        return conversations;
      });
    };

    if (data) {
      updateUnreadCount().catch(console.error);
    }
  }, [data]);

  const messages = useMemo(() => {
    return data?.pages.flat() || [];
  }, [data, data?.pages[0]?.length]);

  return { messages, ...rest };
};
