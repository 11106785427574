import { useMutation, UseMutationOptions } from 'react-query';

import { exportConnections } from 'src/api';
import { convertConnectionFilterToRequest } from 'src/helpers';
import { useAppSelector } from 'src/hooks';
import { ICustomAxiosError } from 'src/types';

export const useExportConnectionsCsv = (
  isBlacklist: boolean,
  options?: UseMutationOptions<void, ICustomAxiosError, string>,
) => {
  const {
    request,
    exportData: { selectedLeads },
  } = useAppSelector((state) => (isBlacklist ? state.blacklistConnections : state.connections));

  const entityUrns = selectedLeads.map(({ entityUrn }) => entityUrn);

  const { mutate, ...rest } = useMutation(
    'post-get-connections-for-csv',
    (email: string) =>
      exportConnections(email, entityUrns, convertConnectionFilterToRequest({ ...request, selectedOnly: false })),
    options,
  );

  return { exportConnectionsCsv: mutate, ...rest };
};
