import classNames from 'classnames';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { HeaderButton, Input, Typography } from 'src/components';
import { CampaignType } from 'src/enums';
import { validateLinkedinPostUrl } from 'src/helpers';
import { useLocationState, useSingleCampaignProps } from 'src/hooks';
import {
  useAddMoreLeadsCampaign,
  useCreateCampaignAudience,
  useGetCampaign,
  useUpdateCampaignAudience,
} from 'src/reactQueries';
import { transformCampaignRequestBody } from 'src/transformers';
import { ICampaignAudienceRequest, ILocationStateSingleCampaign } from 'src/types';
import { ControlledCheckbox, ControlledSlider } from '../../_components';
import { CampaignsSteps } from '../../CampaignSteps';

const StyledInput = styled(Input)`
  padding: 5px 10px;
  height: auto;
  border-width: 2px;
`;

const labelStyle = css`
  color: var(--text-primary);
  font-weight: 400 !important;
`;

export const LinkedinPost = () => {
  const { initialCampaignId } = useLocationState<ILocationStateSingleCampaign>();
  const { isAddMoreLeads, isEdit, campaignId, campaign, category } = useSingleCampaignProps();

  const { campaign: initialCampaign } = useGetCampaign(initialCampaignId, {
    enabled: !!campaignId && !!initialCampaignId && !isAddMoreLeads,
  });

  const {
    register,
    control,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<ICampaignAudienceRequest>({
    mode: 'onChange',
    defaultValues: transformCampaignRequestBody({ category, campaignType: CampaignType.LINKEDIN_POST }),
  });

  useEffect(() => {
    if (initialCampaignId && initialCampaign) {
      reset(transformCampaignRequestBody(initialCampaign));
    } else if (!isAddMoreLeads && campaign) {
      reset(transformCampaignRequestBody(campaign));
    }
  }, [campaign, initialCampaign]);

  const { addMoreLeads, isAddMoreLeadsLoading } = useAddMoreLeadsCampaign(campaignId);
  const { createCampaignAudience, isCreateLoading } = useCreateCampaignAudience();
  const { updateCampaignAudience, isUpdateLoading } = useUpdateCampaignAudience(campaignId);

  const submit = (values: ICampaignAudienceRequest) => {
    const body = transformCampaignRequestBody({
      ...values,
      campaignType: CampaignType.LINKEDIN_POST,
      includeMessageOnly: true,
    });

    if (isAddMoreLeads) {
      return addMoreLeads({
        ...body,
        searchType: CampaignType.LINKEDIN_POST,
        campaignId,
      });
    }

    if (isEdit) {
      return updateCampaignAudience(body);
    }

    return createCampaignAudience(body);
  };

  return (
    <>
      <CampaignsSteps>
        <HeaderButton
          size={{ width: '180px' }}
          processing={isAddMoreLeadsLoading || isCreateLoading || isUpdateLoading}
          disabled={isAddMoreLeadsLoading}
          onClick={handleSubmit(submit)}
        >
          Next
        </HeaderButton>
      </CampaignsSteps>
      <Box padding="30px 15px" width="100%" maxWidth="900px">
        <form>
          <StyledInput
            className={classNames({
              error: errors?.searchUrl,
            })}
            register={register}
            parameters={{
              required: 'Please enter a LinkedIn post URL',
              validate: (value) => validateLinkedinPostUrl(value),
            }}
            name="searchUrl"
            label="Paste here LinkedIn post URL."
            labelStyle={labelStyle}
            placeholder="You can use any LinkedIn post URL"
          />

          <Box display="flex" my="8px">
            {errors?.searchUrl && <Typography color="error.light">{errors?.searchUrl?.message}</Typography>}
          </Box>

          <ControlledSlider name="maxSearchAmount" label="Maximum amount of leads:" control={control} />

          <ControlledCheckbox name="excludeNoPhotos" label="Exclude profiles without photos." control={control} />

          <ControlledCheckbox name="noFirstConnections" label="Exclude 1st degree connections." control={control} />

          <ControlledCheckbox name="retargetLeads" label="Include leads from other campaigns." control={control} />
        </form>
      </Box>
    </>
  );
};
