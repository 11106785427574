import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import {
  HeaderButton,
  HeaderContainer,
  HeaderDescription,
  HeaderTabLink,
  HeaderTabsContainer,
  HeaderTitle,
  HeaderVideo,
  Link,
} from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features, ModalTypes, TeamUserRole } from 'src/enums';
import { useAppSelector, useLocationState, useTeamPlan, useWhiteLabel } from 'src/hooks';
import { openModal } from 'src/store/modal.slice';
import { ILocationStateTeams } from 'src/types';

export const TeamGroupsLayout = () => {
  const dispatch = useDispatch();
  const { whitelabel } = useWhiteLabel();
  const { openCreate } = useLocationState<ILocationStateTeams>();
  const { checkFeature, isTrial, isBusiness, isBusinessV2 } = useTeamPlan();

  const teamData = useAppSelector((state) => state.team.data);

  const onCreateClick = () => {
    dispatch(
      openModal({
        type: ModalTypes.TEAM_GROUP_CREATE,
        headerText: 'Create Group',
      }),
    );
  };

  useEffect(() => {
    if (openCreate) {
      onCreateClick();
      // clearing window state
      window.history.replaceState({}, document.title);
    }
  }, []);

  return (
    <HeaderContainer>
      <Box display="flex" justifyContent="space-between" alignItems="flex-end">
        <HeaderTitle>Groups</HeaderTitle>
        <HeaderButton onClick={onCreateClick}>Create Group</HeaderButton>
      </Box>
      <HeaderDescription>
        Organize your accounts into groups and assign team members specific user access over them.
        {whitelabel.isWhiteLabelUser || (
          <>
            <Link
              leftIcon
              underline
              external
              openNewTab
              to="https://help.meetalfred.com/en/articles/6498609-create-and-assign-groups-within-your-team"
            >
              Learn how it works
            </Link>
            <HeaderVideo src="https://player.vimeo.com/video/871569898" />
          </>
        )}
      </HeaderDescription>
      {teamData?.teamUser?.role === TeamUserRole.OWNER && (
        <HeaderTabsContainer>
          {checkFeature(Features.teamMembers) && <HeaderTabLink to="/team/manage-team">Members</HeaderTabLink>}
          {checkFeature(Features.teamGroups) && (
            <HeaderTabLink
              to="/team/groups"
              disabled={isTrial || isBusiness}
              tooltip={(isTrial || isBusiness) && PLAN_TOOLTIPS.agency}
            >
              Groups
            </HeaderTabLink>
          )}
          {checkFeature(Features.teamSettings) && (
            <HeaderTabLink
              to="/team/settings"
              disabled={isTrial || isBusinessV2}
              tooltip={(isTrial || isBusinessV2) && PLAN_TOOLTIPS.agency}
            >
              Team Settings
            </HeaderTabLink>
          )}
        </HeaderTabsContainer>
      )}
    </HeaderContainer>
  );
};
