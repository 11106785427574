import { Outlet } from 'react-router-dom';

import { AppLayout } from 'src/layouts';

export const SettingsLayout = () => {
  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};
