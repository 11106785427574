import { useQuery, UseQueryOptions } from 'react-query';

import { getCampaignsCount } from 'src/api';
import { CampaignCategory } from 'src/enums';
import { ICustomAxiosError, IGetCampaignsCount } from 'src/types';

export const useGetCampaignsCount = (
  category?: CampaignCategory,
  options?: UseQueryOptions<IGetCampaignsCount, ICustomAxiosError>,
) => {
  const { data, ...rest } = useQuery<IGetCampaignsCount, ICustomAxiosError>(
    ['get-campaigns-count', category],
    () => getCampaignsCount(category),
    {
      refetchOnMount: true,
      enabled: !!category,
      ...options,
    },
  );

  return { data, ...rest };
};
