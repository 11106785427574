import { useQuery, UseQueryOptions } from 'react-query';

import { getGroupListFull } from 'src/api/groups';
import { ICustomAxiosError, TGetGroupListFullResponse } from 'src/types';

export const useGetGroupListFull = (options?: UseQueryOptions<TGetGroupListFullResponse, ICustomAxiosError>) => {
  const { data, ...rest } = useQuery<TGetGroupListFullResponse, ICustomAxiosError>(
    ['get-group-list-full'],
    getGroupListFull,
    {
      refetchOnMount: true,
      ...options,
    },
  );

  return { groupList: data, ...rest };
};
