import { useIntercom } from 'react-use-intercom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button, Typography } from 'src/components';

const Banner = styled(Box)`
  height: 170px;
  padding: 24px 124px;
  text-align: center;
  background-color: #102e50;
  border-radius: 4px;
  box-sizing: border-box;
`;

export const SupportBanner = () => {
  const { showNewMessage } = useIntercom();

  return (
    <Banner>
      <Typography color="success.500" fontSize="26px" lineHeight="32px" semibold inline>
        Need help?{' '}
      </Typography>
      <Typography color="#ffffff" fontSize="26px" lineHeight="32px" semibold inline>
        Contact our 24/7 customer support team,
      </Typography>
      <Typography color="#ffffff" fontSize="26px" lineHeight="32px" semibold mb="24px">
        and we'll assist you in choosing the perfect plan to suit your needs.
      </Typography>
      <Box display="inline-flex">
        <Button variant="secondary" onClick={() => showNewMessage('')}>
          Contact support
        </Button>
      </Box>
    </Banner>
  );
};
