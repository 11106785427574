import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { ReactComponent as ConnectedUserIcon } from 'src/assets/icons/connected-user.svg';
import { ReactComponent as ExcludedUserIcon } from 'src/assets/icons/excluded-user.svg';
import { ReactComponent as FiltersApplied } from 'src/assets/icons/filter-applied.svg';
import { ReactComponent as FilterClearIcon } from 'src/assets/icons/filter-clear.svg';
import { ReactComponent as FiltersBlock } from 'src/assets/icons/filters-block.svg';
import { ReactComponent as SelectedIcon } from 'src/assets/icons/selected.svg';
import { Button, Tooltip, Typography } from 'src/components';
import { CampaignStatus } from 'src/enums';
import { useAppSelector } from 'src/hooks';
import { useGetAllTags, useGetCampaignsSearchList } from 'src/reactQueries';
import { DEFAULT_LEADS_REQUEST, updateBlacklistLeadsRequestParams, updateLeadsRequestParams } from 'src/store';
import { ILeadsFilters, ILeadsParsedValue, IParsedValueOption } from 'src/types';
import { checkLeadsFilter } from '../../../helpers';
import { FilterInput, FiltersBox, FiltersFooter, FiltersHeader, FiltersSidebar, StyledInput } from '../_components';
import { LabelBox, LabelsWrapper } from '../ConnectionsList/ConnectionsFilters';

export const LeadsFilters = ({ isBlacklist = false }: { isBlacklist?: boolean }) => {
  const dispatch = useDispatch();
  const {
    request,
    exportData: { selectedLeads },
  } = useAppSelector((state) => (isBlacklist ? state.blacklistLeads : state.lead));
  const { data } = useGetCampaignsSearchList({ status: CampaignStatus.ACTIVE }, { refetchOnMount: true });
  const { tags: tagsAvailable } = useGetAllTags();

  const {
    search,
    name,
    currentTitle,
    currentEmployer,
    location,
    campaignIds,
    tags,
    selectedOnly,
    connectedOnly,
    isExcluded,
  } = request;

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm<ILeadsFilters>({
    defaultValues: {
      search: search || '',
      name: name || [],
      currentTitle: currentTitle || [],
      currentEmployer: currentEmployer || [],
      location: location || [],
      campaignIds: campaignIds || [],
      tags: tags || [],
      selectedOnly,
      connectedOnly,
      isExcluded,
    },
  });

  const changeFilter = (value: ILeadsFilters) => {
    const updateParamsMethod = isBlacklist ? updateBlacklistLeadsRequestParams : updateLeadsRequestParams;
    dispatch(updateParamsMethod({ ...value, page: 1 }));
  };

  const onFilterChange = (valueName: keyof ILeadsParsedValue, value: IParsedValueOption[] | boolean) => {
    setValue(valueName, value, { shouldDirty: true });
  };

  const onFilterApply = useCallback((values: ILeadsFilters) => {
    reset(getValues(), {
      keepDirtyValues: false,
    });
    changeFilter(values);
  }, []);

  const isFilterApplied = checkLeadsFilter(request, isBlacklist);

  return (
    <FiltersSidebar>
      <FiltersHeader>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb="12px">
          <Box display="flex" alignItems="center">
            {isFilterApplied ? <FiltersApplied /> : <FiltersBlock />}
            <Typography semibold color="gray.700" ml="8px" fontSize="16px" letterSpacing="1px">
              FILTERS
            </Typography>
          </Box>
          {(isDirty || isFilterApplied) && (
            <Box
              display="flex"
              gap="4px"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                reset({ ...DEFAULT_LEADS_REQUEST, isExcluded: isBlacklist });
                onFilterApply({ ...DEFAULT_LEADS_REQUEST, isExcluded: isBlacklist });
              }}
            >
              <FilterClearIcon />
              <Typography color="#2B5DDB" fontSize="16px">
                Clear All
              </Typography>
            </Box>
          )}
        </Box>
        <StyledInput
          type="text"
          placeholder="Search leads"
          name="search"
          register={register}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onFilterApply(getValues());
            }
          }}
        />
      </FiltersHeader>
      <FiltersBox>
        <FilterInput
          valueName="name"
          label="Name"
          placeholder="Search for name"
          parsedValue={watch('name') || []}
          onParsedValueChange={onFilterChange}
        />
        <FilterInput
          valueName="currentEmployer"
          label="Company"
          placeholder="Search for company"
          parsedValue={watch('currentEmployer') || []}
          onParsedValueChange={onFilterChange}
        />
        <FilterInput
          valueName="currentTitle"
          label="Position"
          placeholder="Search for title"
          parsedValue={watch('currentTitle') || []}
          onParsedValueChange={onFilterChange}
        />
        <FilterInput
          valueName="location"
          label="Location"
          placeholder="Search for location"
          parsedValue={watch('location') || []}
          onParsedValueChange={onFilterChange}
        />
        <FilterInput
          valueName="tags"
          label="Tags"
          placeholder={`Select tags`}
          cropOption
          options={tagsAvailable?.map((tag) => ({
            value: String(tag.id),
            label: tag.tag,
          }))}
          parsedValue={watch('tags') || []}
          onParsedValueChange={onFilterChange}
        />
        <FilterInput
          valueName="campaignIds"
          label="Campaign"
          placeholder="Select campaign"
          cropOption
          options={data?.map((campaign) => ({
            value: String(campaign.id),
            label: campaign.name,
          }))}
          parsedValue={watch('campaignIds') || []}
          onParsedValueChange={onFilterChange}
        />
        <LabelsWrapper>
          {!isBlacklist && (
            <LabelBox
              data-testid="isExcluded-checkbox"
              checked={watch('isExcluded')}
              onClick={() => onFilterChange('isExcluded', !watch('isExcluded'))}
            >
              <Tooltip title="Select to filter excluded only">
                <Box display="flex" alignItems="center" gap="5px">
                  <ExcludedUserIcon />
                  <Typography>Excluded only</Typography>
                </Box>
              </Tooltip>
            </LabelBox>
          )}
          <LabelBox
            data-testid="connectedOnly-checkbox"
            checked={watch('connectedOnly')}
            onClick={() => onFilterChange('connectedOnly', !watch('connectedOnly'))}
          >
            <Tooltip title="Select to filter connected only">
              <Box display="flex" alignItems="center" gap="5px">
                <ConnectedUserIcon />
                <Typography>Connected only</Typography>
              </Box>
            </Tooltip>
          </LabelBox>
          {!!selectedLeads?.length && (
            <LabelBox
              checked={watch('selectedOnly')}
              disabled={!selectedLeads?.length}
              onClick={() => selectedLeads?.length && onFilterChange('selectedOnly', !watch('selectedOnly'))}
            >
              <Tooltip title={selectedLeads?.length ? 'Select to filter selected only' : ''}>
                <Box display="flex" alignItems="center" gap="5px">
                  <SelectedIcon />
                  <Typography>Selected only</Typography>
                </Box>
              </Tooltip>
            </LabelBox>
          )}
        </LabelsWrapper>
      </FiltersBox>
      <FiltersFooter>
        <Button size={{ width: '100%' }} disabled={!isDirty} onClick={handleSubmit(onFilterApply)}>
          Apply & Search
        </Button>
      </FiltersFooter>
    </FiltersSidebar>
  );
};
