import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button, Divider, Typography } from 'src/components';
import { VAT_NAMES } from 'src/constants';
import { ModalTypes } from 'src/enums';
import { useGetBillingInfo } from 'src/reactQueries';
import { openModal } from 'src/store';

const Label = styled(Typography)`
  color: ${({ theme }) => theme.palette.gray[500]};
  font-size: 15px;
`;

const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.gray[800]};
  font-size: 16px;
`;

export const BillingInformation = () => {
  const dispatch = useDispatch();
  const { billingInfo } = useGetBillingInfo();

  const openCompanyInfo = () => {
    dispatch(openModal({ closable: false, type: ModalTypes.COMPANY_INFO }));
  };

  return (
    <Box p="20px" bgcolor="#fff" width="50%" borderRadius="4px" boxSizing="border-box">
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="20px">
        <Typography fontSize="22px" semibold color="gray.800">
          Company Information
        </Typography>

        <Button onClick={openCompanyInfo}>Edit Info</Button>
      </Box>

      <Label>Company name</Label>
      <Text mb="16px">{billingInfo?.company || '-'}</Text>

      {billingInfo?.vatType ? (
        <>
          <Label>{VAT_NAMES[billingInfo.vatType]}</Label>
          <Text>{billingInfo?.vat}</Text>
        </>
      ) : (
        <>
          <Label>Tax information</Label>
          <Text>-</Text>
        </>
      )}

      <Divider my="24px" />

      <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="24px" rowGap="16px">
        <span>
          <Label>Country</Label>
          <Text>{billingInfo?.address?.country || '-'}</Text>
        </span>

        <span>
          <Label>Region</Label>
          <Text>{billingInfo?.address?.state || '-'}</Text>
        </span>

        <span>
          <Label>City</Label>
          <Text>{billingInfo?.address?.city || '-'}</Text>
        </span>

        <span>
          <Label>Address Line 1</Label>
          <Text>{billingInfo?.address?.line1 || '-'}</Text>
        </span>

        <span>
          <Label>Address Line 2</Label>
          <Text>{billingInfo?.address?.line2 || '-'}</Text>
        </span>

        <span>
          <Label>Zip</Label>
          <Text>{billingInfo?.address?.postal_code || '-'}</Text>
        </span>
      </Box>
    </Box>
  );
};
