import { useQuery, UseQueryOptions } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getTeamInvitationByKey } from 'src/api';
import { useAppSelector } from 'src/hooks';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IGetInvitationToTeamResponse } from 'src/types';
import { useMarkInvitationAsClicked } from './useMarkInvitationAsClicked';

export const useGetInvitationToTeam = (
  { inviteKey }: { inviteKey: string },
  options?: UseQueryOptions<IGetInvitationToTeamResponse, ICustomAxiosError>,
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    isAuth,
    profile: { email },
  } = useAppSelector((state) => state.user);

  const { markInvitationAsClicked } = useMarkInvitationAsClicked(inviteKey);

  const { data, ...rest } = useQuery<IGetInvitationToTeamResponse, ICustomAxiosError>(
    ['get-invitation-to-team', inviteKey],
    () => getTeamInvitationByKey({ inviteKey }),
    {
      ...options,
      onSuccess: async (invitationData) => {
        options?.onSuccess?.(invitationData);

        if (isAuth) {
          if (invitationData?.email !== email) {
            dispatch(
              showToast({
                type: 'error',
                message: 'Please make sure you are logged in with the same email you were invited to the team.',
                model: 'alert',
              }),
            );
          }
          navigate('/dashboard');
        } else {
          markInvitationAsClicked();
        }
      },
      onError: (err) => {
        options?.onError?.(err);

        navigate('/dashboard');
        dispatch(
          showToast({
            type: 'error',
            message: err?.message ?? 'Oops, something has gone wrong!',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { data, ...rest };
};
