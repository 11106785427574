import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { DateRange, DayPicker } from 'react-day-picker';
import { UseFormWatch } from 'react-hook-form';
import styled from '@emotion/styled';
import { Box, Grid, MenuItem, Select } from '@mui/material';

import { useAppSelector } from 'src/hooks';

dayjs.extend(timezone);

import { Typography } from 'src/components';
import { IPostSelectedDate } from 'src/types';
import { IFormCreatePost } from './PostForm';

const StyledDayPicker = styled(DayPicker)`
  margin: 0 0 0 -5px;

  .rdp-day_selected {
    background-color: ${({ theme }) => theme.palette.primary.main};

    &:focus,
    &:hover {
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  .rdp-button:not([disabled]) {
    outline: none;

    .rdp-day_selected {
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`;

const StyledSelect = styled(Select)`
  height: 40px;
`;

const TimeDivides = styled.div`
  margin: 0 10px;
`;

const SelectedDateContainer = styled.div`
  font-size: 15px;
  margin: 20px 0;
  font-family: 'ProximaSoft', sans-serif;
`;

const SelectedDate = styled.div`
  font-family: 'ProximaSoft', sans-serif;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primary.main};
`;

const TimeWarning = styled.div`
  margin-top: 20px;
  font-size: 15px;
  color: red;
  font-family: 'ProximaSoft', sans-serif;
`;

interface ISchedulePostProps {
  selectedDate?: IPostSelectedDate;
  watch: UseFormWatch<IFormCreatePost>;
  onDateSelect: (date: IPostSelectedDate) => void;
  disabled?: boolean;
}

export const SchedulePost = ({ selectedDate, onDateSelect, watch, disabled = false }: ISchedulePostProps) => {
  const { profile } = useAppSelector((state) => state.user);

  if (!selectedDate) return null;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography mb="15px" semibold>
          Publish on
        </Typography>
      </Grid>
      <Grid item xs={6} data-testid="date-select">
        <StyledDayPicker
          mode="single"
          disabled={disabled}
          fromDate={disabled ? dayjs(selectedDate.date).toDate() : dayjs().toDate()}
          selected={dayjs(selectedDate.date).toDate()}
          defaultMonth={dayjs(selectedDate.date).toDate()}
          disableNavigation={disabled}
          onSelect={(day: Date | DateRange | undefined) => {
            onDateSelect({
              ...selectedDate,
              date: dayjs(day as Date).format('YYYY-MM-DD'),
            });
          }}
        />
        {watch('scheduledAt') && (
          <SelectedDateContainer>
            <div>Scheduling this post at:</div>
            <SelectedDate>
              {dayjs.tz(watch('scheduledAt'), profile?.timezone?.name).format('dddd, MMMM D, YYYY h:mm A')}
            </SelectedDate>
            <div>{profile?.timezone?.name || dayjs.tz.guess()} time.</div>
          </SelectedDateContainer>
        )}
      </Grid>
      <Grid container item xs={6}>
        <Grid item xs={12}>
          <Typography mb="15px" semibold>
            Select a time
          </Typography>
          <Box display="flex" alignItems="center">
            <StyledSelect
              data-testid="hour-select"
              value={selectedDate.hour}
              disabled={disabled}
              onChange={(e) =>
                onDateSelect({
                  ...selectedDate,
                  hour: e.target.value as number,
                })
              }
            >
              <MenuItem value={1}>01</MenuItem>
              <MenuItem data-testid="hour-02" value={2}>
                02
              </MenuItem>
              <MenuItem value={3}>03</MenuItem>
              <MenuItem value={4}>04</MenuItem>
              <MenuItem value={5}>05</MenuItem>
              <MenuItem value={6}>06</MenuItem>
              <MenuItem value={7}>07</MenuItem>
              <MenuItem value={8}>08</MenuItem>
              <MenuItem value={9}>09</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={11}>11</MenuItem>
              <MenuItem value={12}>12</MenuItem>
            </StyledSelect>
            <TimeDivides>:</TimeDivides>
            <StyledSelect
              data-testid="minute-select"
              value={selectedDate.minute}
              disabled={disabled}
              onChange={(e) =>
                onDateSelect({
                  ...selectedDate,
                  minute: e.target.value as number,
                })
              }
            >
              <MenuItem value={0}>00</MenuItem>
              <MenuItem value={15}>15</MenuItem>
              <MenuItem data-testid="minute-30" value={30}>
                30
              </MenuItem>
              <MenuItem value={45}>45</MenuItem>
            </StyledSelect>
            <TimeDivides />
            <StyledSelect
              data-testid="place-select"
              value={selectedDate.place}
              disabled={disabled}
              onChange={(e) =>
                onDateSelect({
                  ...selectedDate,
                  place: e.target.value as string,
                })
              }
            >
              <MenuItem value="AM">AM</MenuItem>
              <MenuItem data-testid="place-PM" value="PM">
                PM
              </MenuItem>
            </StyledSelect>
          </Box>
          {dayjs().tz(profile?.timezone?.name).isAfter(watch('scheduledAt')) && !disabled && (
            <TimeWarning>
              Post time should be after the current time ({dayjs().tz(profile?.timezone?.name).format('hh:mm A')})
            </TimeWarning>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
