import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { CSVUpload, HeaderButton } from 'src/components';
import { CampaignType } from 'src/enums';
import { compareCsvSnippets } from 'src/helpers';
import { useSingleCampaignProps } from 'src/hooks';
import { useCreateCampaignFromTwitterCsv, useUpdateCampaignFromCsvUpload } from 'src/reactQueries';
import { showToast } from 'src/store/toast.slice';
import { transformCampaignRequestBody } from 'src/transformers';
import { ICSVUploadValue } from 'src/types';
import { CampaignsSteps } from '../../CampaignSteps';

export const TwitterCsvUpload = () => {
  const dispatch = useDispatch();
  const { isAddMoreLeads, campaignId, campaign, category } = useSingleCampaignProps();
  const [parsedCsv, setParsedCsv] = useState<ICSVUploadValue>({});

  const [retargetLeads, setRetargetLeads] = useState(false);

  const { createCampaignFromTwitterCsv, isCreateLoading } = useCreateCampaignFromTwitterCsv();
  const { updateCampaignFromCsv, isLoading } = useUpdateCampaignFromCsvUpload({
    generalCampaignType: 'twitter',
    campaignId,
    isAddMoreLeads,
  });
  const isButtonsBlocked = (isAddMoreLeads && isLoading) || isCreateLoading;

  const cancelHandler = () => {
    setParsedCsv({});
  };

  const submitHandler = () => {
    const body = transformCampaignRequestBody({
      category,
      campaignType: CampaignType.TWITTER_CSV_UPLOAD,
      ...parsedCsv.request,
      retargetLeads,
    });

    if (campaign?.id && !compareCsvSnippets(campaign?.customSnippets, parsedCsv.request?.header)) {
      return dispatch(
        showToast({
          type: 'info',
          message: "Please use same snippets that you've used before",
        }),
      );
    }

    if (campaign?.id) {
      updateCampaignFromCsv(body);
    } else {
      createCampaignFromTwitterCsv(body);
    }
  };

  return (
    <>
      <CampaignsSteps>
        {!!parsedCsv.request && (
          <>
            <HeaderButton processing={isButtonsBlocked} disabled={isButtonsBlocked} onClick={submitHandler}>
              Upload
            </HeaderButton>
            <HeaderButton disabled={isButtonsBlocked} onClick={cancelHandler}>
              Cancel
            </HeaderButton>
          </>
        )}
      </CampaignsSteps>
      <Box padding="15px" pb="80px">
        <CSVUpload
          value={parsedCsv}
          label="The first column should have the lead's name and second column should be X (Twitter) profile name."
          type="twitter"
          exampleUrl="https://daekjd9c602b4.cloudfront.net/example_twitter_new.csv"
          isRemoveButton
          customSnippets={campaign?.customSnippets}
          onUploaded={setParsedCsv}
          retargetLeads={retargetLeads}
          setRetargetLeads={setRetargetLeads}
        />
      </Box>
    </>
  );
};
