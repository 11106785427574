import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Button, Input, Typography } from 'src/components';
import { REGEXP_VALID_EMAIL } from 'src/constants';
import { useAppSelector } from 'src/hooks';
import { useExportCSVReplies } from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';
import { IModalProps } from 'src/types';
import { ModalBody, ModalContainer } from '../_components';

const StyledInput = styled(Input)`
  height: 34px;
  margin-top: 20px;
`;

const ModalFooter = styled(Box)`
  border-top: 1px solid #e5e5e5;
  display: flex;
  gap: 10px;
  padding: 15px 40px;
`;

const IconBox = styled(Box)`
  cursor: pointer;
`;

interface IEmailForm {
  email: string;
}

export const ModalExportReplies = ({ onCancel }: IModalProps) => {
  const dispatch = useDispatch();
  const { profile } = useAppSelector((state) => state.user);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IEmailForm>({ defaultValues: { email: profile.email ?? '' } });

  const { exportReplies, isLoading, isSuccess = false } = useExportCSVReplies();

  const confirmHandler = ({ email }: IEmailForm) =>
    exportReplies({
      email,
      offset: new Date().getTimezoneOffset(),
      filename: `Replies Export ${dayjs().format('MMMM Do YYYY, h:mm a')}`,
    });

  const closeHandler = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  return (
    <ModalContainer>
      <Box pt="24px" pb="10px" px="40px" display="flex">
        <Typography component="h4" mr="auto">
          {isSuccess ? 'Export confirmation' : 'Email confirmation'}
        </Typography>
        <IconBox>
          <FontAwesomeIcon icon={faXmark} onClick={closeHandler} />
        </IconBox>
      </Box>

      <ModalBody>
        {isSuccess ? (
          <Typography color="violet.dark">
            We are processing your request. You will receive the exported file on an email shortly.
          </Typography>
        ) : (
          <>
            <Typography color="violet.dark">Please type the email you want to use for receiving export.</Typography>
            <StyledInput
              register={register}
              name="email"
              parameters={{
                required: 'Please enter email',
                pattern: {
                  value: REGEXP_VALID_EMAIL,
                  message: 'Please enter a valid email',
                },
              }}
              placeholder="email"
            />
            {errors.email && (
              <Typography color="error.light" mt="5px">
                {errors.email?.message}
              </Typography>
            )}
          </>
        )}
      </ModalBody>

      <ModalFooter justifyContent={isSuccess ? 'flex-end' : 'space-between'}>
        {isSuccess ? (
          <>
            <Button variant="gray" onClick={closeHandler}>
              Close
            </Button>
          </>
        ) : (
          <>
            <Button variant="gray" onClick={closeHandler}>
              Cancel
            </Button>
            <Button disabled={isLoading} processing={isLoading} onClick={handleSubmit(confirmHandler)}>
              Confirm
            </Button>
          </>
        )}
      </ModalFooter>
    </ModalContainer>
  );
};
