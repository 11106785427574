import { Dispatch, SetStateAction, useMemo } from 'react';
import { UseFormHandleSubmit } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  faComments,
  faPaperPlane,
  faPencilAlt,
  faReply,
  faSave,
  faTimes,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';

import { ActionButton, TableCell } from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { IConversationDrawerDataProps } from 'src/containers/Conversations/_components';
import { CampaignActions, Features } from 'src/enums';
import { getReturnToCampaignTooltip } from 'src/helpers';
import { useTeamPlan } from 'src/hooks';
import {
  useResetCampaignLeadColumns,
  useReturnCampaignReplies,
  useSkipCampaignLead,
  useUpdateCampaignLeadColumns,
} from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';
import { ICampaignLead, IGetCampaign, IGetLeadsRequest, ILeadsUpdateFields, TLeadsSortType } from 'src/types';

interface ILinkedinTableRowActions {
  campaign: IGetCampaign;
  lead: ICampaignLead;
  getLeadKeys: IGetLeadsRequest;
  handleSubmit: UseFormHandleSubmit<ILeadsUpdateFields>;
  isEdit: boolean;
  setIsEdit: (action: boolean) => void;
  setDrawerState: Dispatch<SetStateAction<IConversationDrawerDataProps>>;
}

export const LinkedinTableRowActions = ({
  lead,
  isEdit,
  setIsEdit,
  getLeadKeys,
  handleSubmit,
  campaign,
  setDrawerState,
}: ILinkedinTableRowActions) => {
  const dispatch = useDispatch();

  const { checkFeature } = useTeamPlan();
  const isDirectReplyAllowed = checkFeature(Features.directReply);
  const isReturnToCampaignAllowed = checkFeature(Features.returnToCampaign);

  const campaignId = campaign?.id;

  const {
    entityUrn,
    status,
    linkedinData,
    customData,
    conversationUrn,
    isReturnToCampaign,
    threadId,
    twitterConversationUrn,
    actionDescription,
  } = lead;
  const includeLeadType = (types: TLeadsSortType[]) => types.includes(getLeadKeys?.type);

  const { resetLeadColumn } = useResetCampaignLeadColumns({ entityUrn, campaignId, getLeadKeys });
  const { returnCampaignReplies } = useReturnCampaignReplies({ campaignId });
  const { updateLeadColumn } = useUpdateCampaignLeadColumns({ entityUrn, campaignId, getLeadKeys });
  const { skipCampaignLead } = useSkipCampaignLead(campaignId, getLeadKeys);

  const isRepliesFilter = includeLeadType(['replies', 'allReplies', 'emailReplies', 'inmailReplies']);
  const isReturnButtonShow = isRepliesFilter || includeLeadType(['userInteraction']);
  const isConversationShow = includeLeadType([
    'replies',
    'allReplies',
    'inmailReplies',
    'messaged',
    'eventMessages',
    'groupMessageSent',
    'inmailed',
    'userInteraction',
  ]);
  const approvedFilter = includeLeadType(['approved']);
  const isApproved = status === 'approved';
  const isEdited = !!customData;

  const conversationUrl = useMemo(() => {
    if (threadId && includeLeadType(['inmailReplies'])) {
      return `https://www.linkedin.com/sales/inbox/${threadId}`;
    }

    if (includeLeadType(['eventMessages', 'eventMessages']) && conversationUrn) {
      return `https://www.linkedin.com/messaging/thread/${conversationUrn}`;
    }

    if (linkedinData?.handle || conversationUrn) {
      return conversationUrn
        ? `https://www.linkedin.com/messaging/thread/${conversationUrn}`
        : `https://www.linkedin.com/messaging/compose/?recipient=${linkedinData?.handle}`;
    }

    return '';
  }, [lead]);

  const replyTooltip = useMemo(() => {
    if (isRepliesFilter) {
      switch (true) {
        case CampaignActions.EMAIL_REPLY_DETECTED === actionDescription:
          return 'Direct reply is not available for Email reply';
        case CampaignActions.INMAIL_REPLY_DETECTED === actionDescription:
          return 'Direct reply is not available for Inmail reply';
        case CampaignActions.TWITTER_REPLY_DETECTED === actionDescription:
          return 'Direct reply is not available for Inmail reply';
        default:
          return 'Direct reply';
      }
    }
  }, [getLeadKeys?.type]);

  const actions = [
    {
      isShow: isEdit && approvedFilter,
      icon: faSave,
      title: 'Save',
      onClick: handleSubmit(({ company, firstName, lastName, title }) => {
        const leadValues = {
          company: company || lead?.company || '',
          firstName: (!lastName && !firstName ? linkedinData?.firstName : firstName) || '',
          lastName: (!firstName && !lastName ? linkedinData?.lastName : lastName) || '',
          title: title || lead?.title || '',
        };

        updateLeadColumn(leadValues);
        setIsEdit(false);
      }),
    },
    {
      isShow: !isEdit && isEdited && approvedFilter,
      icon: faUndo,
      title: 'Restore Lead Information',
      onClick: () => resetLeadColumn(),
    },
    {
      isShow: !isEdit && approvedFilter,
      icon: faPencilAlt,
      title: 'Edit Lead',
      onClick: () => setIsEdit(true),
    },
    {
      isShow: !isEdit && approvedFilter,
      icon: faTimes,
      title: 'Remove Lead',
      onClick: () =>
        dispatch(
          openModal({
            headerText: 'Remove Lead',
            descriptionText: 'Are you sure you want to remove this lead?',
            onConfirm: () => skipCampaignLead([entityUrn]),
          }),
        ),
    },
    {
      isShow: !isEdit && conversationUrl && isConversationShow,
      icon: faComments,
      title: 'View Conversation on Linkedin',
      onClick: () => window.open(conversationUrl, '_blank', 'noopener,noreferrer'),
    },
    {
      isShow: !isEdit && twitterConversationUrn && isConversationShow,
      icon: faComments,
      title: 'View Conversation on X (Twitter)',
      onClick: () => window.open(twitterConversationUrn, '_blank', 'noopener,noreferrer'),
    },
    {
      icon: faPaperPlane,
      title: isDirectReplyAllowed ? replyTooltip : PLAN_TOOLTIPS.any,
      isShow: !isEdit && isRepliesFilter,
      disabled: !isDirectReplyAllowed || CampaignActions.LINKEDIN_REPLY_DETECTED !== actionDescription,
      onClick: () =>
        setDrawerState({
          isOpen: true,
          entityUrn,
          conversationId: conversationUrn,
        }),
    },
    {
      isShow: !isEdit && isReturnButtonShow,
      icon: faReply,
      title: isReturnToCampaignAllowed
        ? getReturnToCampaignTooltip({ isApproved, isReturnToCampaign, isCampaignRemoved: campaign?.isRemoved })
        : PLAN_TOOLTIPS.professional,

      disabled: !isApproved || isReturnToCampaign || !isReturnToCampaignAllowed || campaign?.isRemoved,
      onClick: () => returnCampaignReplies([entityUrn]),
    },
  ];

  return (
    <TableCell preventClick justifyContent="flex-end">
      <Box display="flex" gap="8px">
        {actions?.map(
          ({ icon, onClick, title: actionTitle, disabled, isShow }) =>
            isShow && (
              <ActionButton key={actionTitle} tooltip={actionTitle} disabled={disabled} onClick={onClick} icon={icon} />
            ),
        )}
      </Box>
    </TableCell>
  );
};
