import { validate as validateEmail } from 'email-validator';
import { useEffect, useState } from 'react';
import { FieldValues, useForm, UseFormRegister } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, CircularProgress } from '@mui/material';

import { Button, Checkbox, Tooltip, Typography } from 'src/components';
import { SelectTeamMemberRole } from 'src/components/Select/SelectTeamMemberRole';
import { REGEXP_VALID_EMAIL, SELECT_USER_ROLE_OPTIONS } from 'src/constants';
import { TeamUserRole } from 'src/enums';
import { useAppSelector } from 'src/hooks';
import { useInviteUserWithEmail, useInviteUserWithoutEmail } from 'src/reactQueries';
import { showToast } from 'src/store/toast.slice';
import { TextInput } from '../_components';

const TextInputStyled = styled(TextInput)`
  width: 90%;
`;

const CheckboxLabel = styled.label`
  color: #333;
  font-family: 'ProximaSoft', sans-serif;
  font-size: 14px;
  cursor: pointer;
  margin: 0 6px 0 20px;
`;

interface IInviteUserForm {
  name: string;
  email: string;
  withoutEmail: boolean;
}

interface IInviterUserProps {
  seatNumLeft: number;
}

const TOAST_AUTOCLOSE_TIME = 3000;
const defaultFormValues: IInviteUserForm = {
  name: '',
  email: '',
  withoutEmail: false,
};

export const InviteUserForm = ({ seatNumLeft }: IInviterUserProps) => {
  const dispatch = useDispatch();
  const teamId = useAppSelector((state) => state.team.data?.id ?? 0);
  const { isAppDeactivated } = useAppSelector((state) => state.user);
  const [role, setRole] = useState<TeamUserRole>(TeamUserRole.OWNER);

  const {
    clearErrors,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    watch,
  } = useForm<IInviteUserForm>({
    defaultValues: defaultFormValues,
  });

  const withoutEmail = Boolean(watch('withoutEmail'));
  const onSuccess = () => reset();

  const { inviteUserWithEmail, isLoading: isInvitingWithEmail } = useInviteUserWithEmail({
    onSuccess,
  });

  const { inviteUserWithoutEmail, isInviteLoading } = useInviteUserWithoutEmail({
    onSuccess,
  });

  useEffect(() => {
    if (Object.entries(errors).length) {
      dispatch(
        showToast({
          message: Object.values(errors)[0].message ?? '',
          type: 'info',
          autoCloseTime: TOAST_AUTOCLOSE_TIME,
        }),
      );

      clearErrors();
    }
  }, [Object.values(errors).length]);

  useEffect(() => {
    if (withoutEmail) {
      setRole(TeamUserRole.MEMBER);
    }
  }, [withoutEmail]);

  const onInviteUser = (data: IInviteUserForm) => {
    const isEmailValid = validateEmail(data.email);
    if (!withoutEmail && !isEmailValid) {
      return dispatch(
        showToast({
          message: 'Please enter a valid email',
          type: 'info',
          autoCloseTime: TOAST_AUTOCLOSE_TIME,
        }),
      );
    }

    if (!data.name.trim()) {
      return dispatch(
        showToast({
          message: 'Please enter a valid name',
          type: 'info',
          autoCloseTime: TOAST_AUTOCLOSE_TIME,
        }),
      );
    }

    if (withoutEmail) {
      return inviteUserWithoutEmail({
        teamId,
        name: data.name,
      });
    }

    return inviteUserWithEmail({
      role,
      teamId,
      name: data.name,
      email: data.email,
    });
  };

  return (
    <Box
      borderRadius="5px 5px 0 0"
      marginLeft="15px"
      marginRight="15px"
      marginTop="20px"
      minWidth={960}
      sx={{ bgcolor: '#fff' }}
    >
      <Typography
        color="gray.dark"
        fontFamily="ProximaSoft"
        fontWeight="600"
        fontSize="14px"
        lineHeight="14px"
        padding="20px 15px 0 20px"
        textTransform="uppercase"
      >
        Invite new user - {seatNumLeft} {seatNumLeft === 1 ? 'seat' : 'seats'} available
      </Typography>

      {!isAppDeactivated && (
        <>
          <Box width="100%" display="flex" height="56px">
            <Box width="30%" paddingLeft="20px" display="flex" alignItems="center">
              <TextInputStyled
                placeholder="Name"
                name="name"
                register={register}
                parameters={{ required: 'Please add a name' }}
              />
            </Box>

            <Box width="30%" display="flex" alignItems="center">
              {!withoutEmail && (
                <TextInputStyled
                  placeholder="E-mail address"
                  name="email"
                  register={register}
                  parameters={{
                    required: withoutEmail ? false : 'Please add an email',
                    pattern: {
                      value: REGEXP_VALID_EMAIL,
                      message: 'Please enter a valid email',
                    },
                  }}
                />
              )}
            </Box>

            <Box width="20%" display="flex" alignItems="center" marginTop="5px">
              <SelectTeamMemberRole
                options={SELECT_USER_ROLE_OPTIONS}
                defaultValue="owner"
                disabled={withoutEmail}
                onChange={(nextRole) => setRole(nextRole.target.value as TeamUserRole)}
                value={role}
              />
            </Box>

            <Box
              alignItems="center"
              display="flex"
              justifyContent="flex-end"
              marginRight="20px"
              marginTop="5px"
              width="20%"
            >
              <Button
                data-testid="invite"
                disabled={isInvitingWithEmail || isInviteLoading}
                onClick={handleSubmit(onInviteUser)}
              >
                {isInvitingWithEmail || isInviteLoading ? <CircularProgress size={12} /> : 'Invite'}
              </Button>
            </Box>
          </Box>

          <Box display="flex" height="56px" marginLeft="20px" alignItems="center">
            <Checkbox
              type="checkbox"
              name="withoutEmail"
              id="withoutEmail"
              register={register as unknown as UseFormRegister<FieldValues>}
            />
            <CheckboxLabel htmlFor="withoutEmail">Create user without email</CheckboxLabel>
            <Tooltip
              placement="top"
              title="You can create a user that you can impersonate and manage his account without the need of assigning login credentials."
            >
              <FontAwesomeIcon icon={faInfoCircle} size="sm" fontSize="14px" />
            </Tooltip>
          </Box>
        </>
      )}
    </Box>
  );
};
