import { PropsWithChildren, ReactNode } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, BoxProps, TooltipProps } from '@mui/material';

import { Tooltip } from '../ui';

const Spinner = styled(FontAwesomeIcon)`
  animation: fa-spin 2s linear infinite;
`;

const StyleActionButton = styled(Box)<{ disabled?: boolean; color: string }>`
  box-sizing: border-box;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: #ffffff;
  border: 1px solid ${({ theme }) => theme.palette.light.dark} !important;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  min-width: 32px;
  ${({ disabled }) => disabled && 'opacity: 0.5; cursor: not-allowed;'}

  &:hover {
    background-color: ${({ theme }) => theme.palette.light.light};
  }

  & svg,
  path {
    color: ${({ color }) => color};
  }
`;

export interface IActionButtonProps extends BoxProps {
  tooltip?: ReactNode;
  tooltipProps?: TooltipProps;
  icon?: IconProp;
  color?: string;
  disabled?: boolean;
  processing?: boolean;
}

export const ActionButton = ({
  tooltip,
  tooltipProps,
  icon,
  disabled,
  processing,
  children,
  onClick,
  color,
  ...boxProps
}: PropsWithChildren<IActionButtonProps>) => {
  const { palette } = useTheme();
  const isDisabled = disabled || processing;

  const content = children ? children : icon && <FontAwesomeIcon icon={icon} fontSize="14px" />;

  const onClickHandler = (event: React.MouseEvent<HTMLDivElement>) => !isDisabled && onClick && onClick(event);

  return (
    <Tooltip {...tooltipProps} title={tooltip}>
      <StyleActionButton
        {...boxProps}
        color={color || palette.primary.main}
        onClick={onClickHandler}
        disabled={isDisabled}
      >
        {processing ? <Spinner size="lg" icon={faSpinner} /> : content}
      </StyleActionButton>
    </Tooltip>
  );
};
