import { ISelectOption, ITemplateAvailabilityOption } from 'src/types';

export const TEMPLATE_AVAILABILITY_OPTIONS: ITemplateAvailabilityOption[] = [
  { value: 'personal', label: 'Personal' },
  { value: 'team', label: 'Team' },
];

export const TEMPLATE_SNIPPET_OPTIONS: ISelectOption[] = [
  { value: 'first_name', label: 'First Name' },
  { value: 'last_name', label: 'Last Name' },
  { value: 'company', label: 'Company' },
  { value: 'position', label: 'Position' },
  { value: 'industry', label: 'Industry' },
];

export const TEMPLATE_EMAIL_SNIPPET_OPTIONS: ISelectOption[] = [
  { value: 'email', label: 'Email' },
  { value: 'csv_lastname', label: 'Last Name' },
  { value: 'csv_name', label: 'name' },
  { value: 'csv_specvideo', label: 'Specvideo' },
  { value: 'csv_htmltag', label: 'HTML tag' },
];
