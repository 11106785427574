import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { excludeLeads } from 'src/api';
import { showToast } from 'src/store';
import { IConnectionsResponse, ICustomAxiosError, ILeadsResponse } from 'src/types';

export const useExcludeLeads = (
  type: 'connections' | 'leads',
  options?: UseMutationOptions<void, ICustomAxiosError, { entityUrns: string[]; isExclude: boolean }>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const connectionsQueryKey = ['post-connections'];
  const leadsQueryKey = ['post-leads'];

  const changedQueryKey = type === 'connections' ? connectionsQueryKey : leadsQueryKey;

  const { mutate, ...rest } = useMutation(
    ['update-lead-tags'],
    ({ entityUrns, isExclude }: { entityUrns: string[]; isExclude: boolean }) => excludeLeads(entityUrns, isExclude),
    {
      ...options,
      onMutate: async ({ entityUrns, isExclude }) => {
        options?.onMutate?.({ entityUrns, isExclude });

        await queryClient.cancelQueries(changedQueryKey);

        const prevData = queryClient.getQueryData(changedQueryKey);

        if (type === 'leads') {
          queryClient.setQueriesData<ILeadsResponse | undefined>(
            leadsQueryKey,
            (data) =>
              data && {
                ...data,
                leads: data?.leads?.map((lead) =>
                  entityUrns.includes(lead.person?.key as string)
                    ? {
                        ...lead,
                        isExcluded: isExclude,
                      }
                    : lead,
                ),
              },
          );
        }

        if (type === 'connections') {
          queryClient.setQueriesData<IConnectionsResponse | undefined>(
            connectionsQueryKey,
            (data) =>
              data && {
                ...data,
                connections: data?.connections?.map((connection) =>
                  entityUrns.includes(connection?.entityUrn)
                    ? {
                        ...connection,
                        isExcluded: isExclude,
                        userNetwork: { ...connection.userNetwork, isExcluded: isExclude },
                      }
                    : connection,
                ),
              },
          );
        }

        return { prevData };
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueriesData(changedQueryKey, context?.prevData);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { toggleExcludeLeads: mutate, ...rest };
};
