export enum CurrencyEnum {
  USD = 'usd',
  GBP = 'gbp',
  EUR = 'eur',
}

export enum BillingTypeEnum {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  ANNUAL = 'annual',
  SEMESTRIAL = 'semestrial',
}

export enum Plan {
  TRIAL = 'trial',
  INDIVIDUAL = 'individual',
  STARTER = 'starter',
  PERSONAL = 'personal',
  BUSINESS = 'business',
  ENTERPRISE = 'enterprise',
  AGENCY = 'agency',
}

export enum TrialStatus {
  STARTED = 'started',
  ENDED = 'ended',
}

export enum InvoiceStatus {
  PAID = 'paid',
  OPEN = 'open',
  UNCOLLECTIBLE = 'uncollectible',
}

export enum CancelSubscriptionReason {
  COST = 'Cost',
  DIFFICULTY_OF_USE = 'Difficulty of use',
  MISSING_FUNCTIONALITY = 'Missing Functionality',
  USING_OTHER_PRODUCT = 'Using Other Product',
  TECHNICAL_ISSUES = 'Technical Issues',
  SOMETHING_ELSE = 'Something else',
}
