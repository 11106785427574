import classNames from 'classnames';
import { Fragment, MouseEvent, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import Popover from '@mui/material/Popover';

import { ActionButton } from 'src/components';
import { TConnectionColumnsKey, TLeadsColumnsKey } from 'src/types';

const List = styled(Box)`
  width: 180px;
`;

const ListItem = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 21px;
  padding: 8px 20px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primary.wlLight};
  border: 1px solid transparent;
  background-color: #ffffff;

  span {
    color: ${({ theme }) => theme.palette.gray.dark};
    transition: 0.2s;
  }
  &.dragging {
    font-size: 14px;
    border-color: #d2ded0;

    span {
      font-size: 16px;
    }
  }
`;

const HorizontalRule = styled(Box)`
  width: 250px;
  height: 1px;
  background-color: #d3d3d3;
`;

export interface IColumnsPosition {
  key: TLeadsColumnsKey | TConnectionColumnsKey;
  label: string;
  readOnly?: boolean;
}

interface IRearrangeColumnsProps {
  columns: IColumnsPosition[];
  separator: number;
  onChange: (columns: IColumnsPosition[], result: DropResult) => void;
}

export const RearrangeColumns = ({ columns, separator, onChange }: IRearrangeColumnsProps) => {
  const { palette } = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleOpen = (event: MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDragEnd = (result: DropResult) => {
    const from = result.source?.index;
    const to = result.destination?.index;

    if (typeof from === 'number' && typeof to === 'number' && from !== to) {
      const array = columns.slice(0, from).concat(columns.slice(from + 1));
      onChange(array.slice(0, to).concat(columns[from]).concat(array.slice(to)), result);
    }

    handleClose();
  };

  return (
    <>
      <ActionButton
        tooltip="Click to update columns"
        aria-haspopup="true"
        {...(isOpen && {
          'aria-controls': 'basic-menu',
          'aria-expanded': 'true',
        })}
        onClick={handleOpen}
        icon={faBars}
        color={palette.gray[500]}
      />

      <Popover
        id="sort-table-columns"
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ height: '332px' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {({ droppableProps, innerRef, placeholder }) => (
              <List {...droppableProps} ref={innerRef}>
                {columns.map(({ key, label }, index) => (
                  <Fragment key={key}>
                    <Draggable key={key} draggableId={key} index={index}>
                      {(provided, snapshot) => (
                        <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={classNames({
                            dragging: snapshot.isDragging,
                          })}
                        >
                          <span>{label}</span>
                          <FontAwesomeIcon color={palette.gray[500]} icon={faBars} />
                        </ListItem>
                      )}
                    </Draggable>
                    {index === separator && <HorizontalRule key="hr" />}
                  </Fragment>
                ))}
                {placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Popover>
    </>
  );
};
