import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import unavailableLeads from 'src/assets/img/unavailable-leads.jpg';
import {
  HeaderContainer,
  HeaderDescription,
  HeaderTabLink,
  HeaderTitle,
  HeaderVideo,
  Link,
  TabsContainer,
  Typography,
} from 'src/components';
import { Features } from 'src/enums';
import { useAppSelector, useLocationState, useTeamPlan, useWhiteLabel } from 'src/hooks';
import { usePostConnections } from 'src/reactQueries';
import { setBlacklistConnectionSelectedLeads } from 'src/store';
import { ButtonAddTags, ButtonExportCsv, ButtonToggleExclude, UnavailableContainer } from './_components';
import { ConnectionsList } from './ConnectionsList/ConnectionsList';
import LeadDrawer from './LeadDetails/LeadDrawer';

export const BlacklistConnectionsPage = () => {
  const dispatch = useDispatch();
  const { checkFeature } = useTeamPlan();
  const { whitelabel } = useWhiteLabel();
  const navigate = useNavigate();
  const locationState = useLocationState();
  const isConnectionsAllowed = checkFeature(Features.connections);

  const { blacklistRequest, selectedLeads } = useAppSelector((state) => ({
    selectedLeads: state.blacklistConnections.exportData.selectedLeads,
    blacklistRequest: state.blacklistConnections.request,
  }));

  const entityUrns = selectedLeads.map(({ entityUrn }) => entityUrn);

  const totalConnections = usePostConnections<number>(
    {
      ...blacklistRequest,
      search: blacklistRequest?.search?.trim(),
      entityUrns: blacklistRequest?.selectedOnly ? selectedLeads.map((lead) => lead.entityUrn) : [],
    },
    {
      enabled: isConnectionsAllowed,
      select: (resp) => resp.total,
      refetchOnMount: true,
    },
  );

  const isCsvButtonDisabled = !totalConnections.data || totalConnections.isLoading;

  // remove included connections from selected list
  useEffect(() => {
    if (selectedLeads.length) {
      dispatch(setBlacklistConnectionSelectedLeads(selectedLeads.filter((lead) => lead.isExcluded)));
    }
  }, [blacklistRequest]);

  const openLeadInfo = useCallback(
    (entityUrn?: string) =>
      navigate(`${location.pathname}?person=${entityUrn}&tab=personal_details`, { state: locationState }),
    [],
  );

  return (
    <>
      <HeaderContainer>
        <Box display="flex" justifyContent="space-between">
          <HeaderTitle>Excluded</HeaderTitle>
          <Box display="flex" pt="20px" height="30px" gap="20px">
            {entityUrns.length > 0 && <ButtonAddTags type="connections" entityUrns={entityUrns} />}
            <ButtonToggleExclude type="connections" selectedLeads={selectedLeads} isBlacklist />
            <ButtonExportCsv entityUrns={entityUrns} isExportDisabled={isCsvButtonDisabled} />
          </Box>
        </Box>
        <HeaderDescription>
          View and manage excluded leads to avoid unwanted interactions.
          {whitelabel.isWhiteLabelUser || (
            <>
              <Link
                leftIcon
                underline
                external
                openNewTab
                to="https://help.meetalfred.com/en/articles/8409795-blacklist-prospects-from-campaigns"
              >
                Learn how it works
              </Link>
              <HeaderVideo src="https://player.vimeo.com/video/871942061" />
            </>
          )}
        </HeaderDescription>
        <TabsContainer>
          <HeaderTabLink to="/contacts/blacklist/connections">Connections</HeaderTabLink>
          <HeaderTabLink to="/contacts/blacklist/leads">Leads</HeaderTabLink>
        </TabsContainer>
      </HeaderContainer>

      {isConnectionsAllowed ? (
        <Box display="flex" maxWidth="100vw" height="calc(100% - 130px)">
          <ConnectionsList isExcluded openLeadInfo={openLeadInfo} />
        </Box>
      ) : (
        <UnavailableContainer url={unavailableLeads}>
          <Box boxShadow="0 5px 15px rgb(0 0 0 / 50%)" bgcolor="#fff" p="120px 100px" borderRadius="5px">
            <Typography fontSize="18px">
              <Link to="/billing/edit">Upgrade</Link> your subscription plan in order to get access to this feature
            </Typography>
          </Box>
        </UnavailableContainer>
      )}

      <LeadDrawer />
    </>
  );
};
