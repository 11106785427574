import { ReactNode, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { components } from 'react-select';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { OptionsSelect, Typography } from 'src/components';
import { useGetAllTags } from 'src/reactQueries';
import { setSelectedTags } from 'src/store/conversation.slice';
import { IColorSelectOption } from 'src/types';

const ColorCircle = styled(Box)`
  width: 10px;
  min-width: 10px;
  height: 10px;
  border-radius: 15px;
`;

const CropText = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 5px;
`;

export const FilterByTag = () => {
  const { palette } = useTheme();
  const dispatch = useDispatch();

  const { tags = [] } = useGetAllTags();

  const options = useMemo(() => tags?.map(({ tag, id, color }) => ({ label: tag, value: id, color })), [tags]);

  const onChange = (newGroups: IColorSelectOption | IColorSelectOption[]) => {
    if (Array.isArray(newGroups)) {
      dispatch(setSelectedTags(newGroups.map(({ value }) => value)));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setSelectedTags([]));
    };
  }, []);

  return (
    <Box width="100%">
      <OptionsSelect
        isMulti={true}
        hideSelectedOptions={false}
        placeholder="Type to filter..."
        onChange={onChange}
        options={options}
        styles={{ menuList: (styles) => ({ ...styles, overflowX: 'hidden' }) }}
        components={{
          Option: ({ children, ...props }) => {
            return (
              <components.Option {...props}>
                <Box display="flex" alignItems="center" gap="5px" overflow="hidden">
                  <ColorCircle bgcolor={props?.data?.color} />
                  <CropText>{children}</CropText>
                </Box>
                {props.isSelected && <FontAwesomeIcon icon={faCheck} color={palette.primary.main} />}
              </components.Option>
            );
          },
          ValueContainer: ({ children, ...props }) => {
            const [values, input] = children as ReactNode[];

            return (
              <components.ValueContainer {...props}>
                {Array.isArray(values) ? (
                  <Typography>
                    Selected {values.length} tag{!!values.length && 's'}
                  </Typography>
                ) : (
                  values
                )}
                {input}
              </components.ValueContainer>
            );
          },
        }}
      />
    </Box>
  );
};
