import dayjs from 'dayjs';
import { useState } from 'react';

import { TIntervalFilter } from 'src/types';

interface UseTimeIntervalOptionsProps {
  onIntervalChange?: (params: { interval: TIntervalFilter; dateFrom: string; dateTo: string }) => void;
}

export const useTimeIntervalOptions = (props?: UseTimeIntervalOptionsProps) => {
  const { onIntervalChange } = props || {};

  const today = dayjs().format('YYYY-MM-DD');

  const [dateFrom, setDateFromAction] = useState(today);
  const [dateTo, setDateToAction] = useState(today);
  const [interval, setIntervalAction] = useState<TIntervalFilter>('all');

  const setInterval = (value: TIntervalFilter) => {
    onIntervalChange?.({ interval: value, dateFrom, dateTo });
    setIntervalAction(value);
  };

  const setDateFrom = (value: string) => {
    onIntervalChange?.({ interval, dateFrom: value, dateTo });
    setDateFromAction(value);
  };

  const setDateTo = (value: string) => {
    onIntervalChange?.({ interval, dateFrom, dateTo: value });
    setDateToAction(value);
  };

  return { today, dateTo, dateFrom, interval, setInterval, setDateFrom, setDateTo };
};
