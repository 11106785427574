import { NavigateOptions, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Tooltip, Typography } from 'src/components';

const Card = styled(Box)<{ height: string }>`
  position: relative;
  width: 100%;
  min-width: 290px;
  max-width: 400px;
  height: ${(props) => props.height};
  margin-bottom: 15px;
  padding: ${(props) => (props.height ? '15px 25px' : '25px')};
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 15px 30px 60px 0 rgb(220 220 230 / 50%);
  color: ${({ theme }) => theme.palette.primary.wlLight};
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:hover:not(.disabled) {
    background-color: #fcfcfc;
  }
  &.disabled {
    cursor: not-allowed;
    filter: grayscale(1);
  }
`;

const TryBadged = styled(Box)`
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 12px;
  z-index: 100;
  color: #fff;
  background: red;
  border-radius: 5px;
  line-height: 22px;
  padding: 3px;
`;

const PRO_ONLY_TOOLTIP = 'Available in Business and Enterprise plans';

interface IChooseCampaignCardProps {
  id?: string;
  name: string;
  description: string;
  image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  navigation?: {
    to: string;
    options?: NavigateOptions;
  };
  height?: string;
  tryTooltip?: string;
  disabledTooltip?: string;
  disabled?: boolean;
  onClick?: () => void;
  isAvailable?: boolean;
}

export const CampaignCard = ({
  id,
  name,
  description,
  image: ImageSvg,
  navigation,
  height = '200px',
  tryTooltip,
  disabledTooltip,
  disabled,
  onClick,
  isAvailable = true,
}: IChooseCampaignCardProps) => {
  const navigate = useNavigate();

  const redirectToPage = () => {
    !!navigation && navigate(navigation.to, navigation.options);
  };

  return (
    <Tooltip
      title={
        (disabled && (disabledTooltip || 'Please connect your LinkedIn account first')) ||
        (!isAvailable && PRO_ONLY_TOOLTIP)
      }
    >
      <Card
        key={name}
        id={id}
        data-intercom-target={id}
        height={height}
        {...(disabled || !isAvailable
          ? { className: 'disabled' }
          : { onClick: typeof onClick === 'function' ? onClick : redirectToPage })}
      >
        <Box mb="15px">
          <ImageSvg height="80px" />
        </Box>
        <Typography mb="12px" fontSize="16px" color="primary.wlLight" semibold>
          {name}
        </Typography>
        <Typography textAlign="center" height="45px" color="gray.light">
          {description}
        </Typography>
        {!!tryTooltip && (
          <Tooltip title={tryTooltip}>
            <TryBadged>Try me</TryBadged>
          </Tooltip>
        )}
      </Card>
    </Tooltip>
  );
};
