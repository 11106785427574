import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getCampaignChart } from 'src/api';
import { IGetCampaignChart } from 'src/types';

export const useGetCampaignChart = <TData = IGetCampaignChart>(
  id: number,
  options?: UseQueryOptions<IGetCampaignChart, AxiosError, TData>,
) => {
  return useQuery<IGetCampaignChart, AxiosError, TData>(['campaign-chart', id], () => getCampaignChart(id), options);
};
