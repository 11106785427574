import { useState } from 'react';

export const useCopyToClipboard = () => {
  const [isCopied, setIsCopied] = useState(false);

  const copy = (value = '') => {
    navigator.clipboard.writeText(value);
    setIsCopied(true);
  };

  return { copy, isCopied };
};
