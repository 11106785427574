import styled from '@emotion/styled';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, TypographyProps } from '@mui/material';

import { Typography } from 'src/components';

const StyledBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 40px;
`;

const StyledIcon = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  cursor: pointer;
`;

interface ModalHeaderProps extends TypographyProps {
  onClose?: () => void;
}

export const ModalHeader = ({ children, onClose, ...restProps }: ModalHeaderProps) => (
  <StyledBox>
    <Typography component="h4" {...restProps}>
      {children}
    </Typography>
    {typeof onClose === 'function' && (
      <StyledIcon onClick={onClose}>
        <FontAwesomeIcon icon={faXmark} />
      </StyledIcon>
    )}
  </StyledBox>
);
