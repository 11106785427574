import { createSlice } from '@reduxjs/toolkit';

import { DEFAULT_PER_PAGE } from 'src/constants';
import { ILeadsFilters, ISelectedLead } from 'src/types';

interface IInitialState {
  request: ILeadsFilters;
  exportData: {
    selectedLeads: ISelectedLead[];
  };
}

const DEFAULT_LEADS_REQUEST: ILeadsFilters = {
  page: 1,
  perPage: DEFAULT_PER_PAGE,
  search: '',
  sortField: 'updatedAt',
  sortOrder: 'DESC',
  name: [],
  currentTitle: [],
  currentEmployer: [],
  location: [],
  campaignIds: [],
  tags: [],
  isExcluded: true,
  connectedOnly: false,
  selectedOnly: false,
};

export const blacklistInitialState: IInitialState = {
  request: DEFAULT_LEADS_REQUEST,
  exportData: {
    selectedLeads: [],
  },
};

export const blacklistLeadsSlice = createSlice({
  name: 'blacklist',
  initialState: blacklistInitialState,
  reducers: {
    updateBlacklistLeadsRequestParams: (state, action) => {
      state.request = { ...state.request, ...(action.payload ?? {}) };
    },
    setBlacklistLeadsSelectedLeads: (state, action) => {
      state.exportData.selectedLeads = action.payload;
    },
    clearBlacklistLeadsRequestParams: (state) => {
      state.request = DEFAULT_LEADS_REQUEST;
    },
  },
});

export const { updateBlacklistLeadsRequestParams, setBlacklistLeadsSelectedLeads, clearBlacklistLeadsRequestParams } =
  blacklistLeadsSlice.actions;

export default blacklistLeadsSlice.reducer;
