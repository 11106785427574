import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { deleteTeamInvite } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IDeleteTeamInviteRequest } from 'src/types';

interface IUseDeleteTeamInviteParams {
  memberName: string;
}

export const useDeleteTeamInvite = (
  { memberName }: IUseDeleteTeamInviteParams,
  options?: UseMutationOptions<void, ICustomAxiosError, IDeleteTeamInviteRequest>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, isLoading, ...rest } = useMutation(['delete-user-invite'], deleteTeamInvite, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      await queryClient.invalidateQueries(['get-team-invites']);
      dispatch(
        showToast({
          message: `Removed ${memberName} from the org`,
          type: 'info',
          autoCloseTime: 5000,
        }),
      );
    },

    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { deleteTeamInvite: mutate, isDeleteInviteLoading: isLoading, ...rest };
};
