import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { clearToasts, removeAllNotifications, removeUser } from 'src/store';

export const useAuth = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const logout = () => {
    queryClient.removeQueries();
    dispatch(removeAllNotifications());
    dispatch(clearToasts());
    dispatch(removeUser());
  };

  return { logout };
};
