import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { Link, NotificationDot, TableCell, TableRow, Typography } from 'src/components';
import { ModalTypes } from 'src/enums';
import {
  getRemovedNotificationText,
  resolveActionDescription,
  resolvePersonName,
  resolvePersonNameForActivity,
} from 'src/helpers';
import { useCompanyTheme } from 'src/hooks';
import { openModal } from 'src/store';
import { ICampaignActivity } from 'src/types';

dayjs.extend(utc);

export const ActivityTableRow = (props: ICampaignActivity) => {
  const {
    person,
    description,
    campaignStatus,
    isCampaignRemoved,
    isLeadRemoved,
    createdAt,
    msgCreatedAt,
    campaignName,
    campaignId,
    message,
  } = props;

  const dispatch = useDispatch();
  const ref = useRef<HTMLElement>(null);
  const [showToolTip, setShowToolTip] = useState<boolean>(true);
  const { shortCompanyName, logoIconBlack } = useCompanyTheme();
  const { name, nameContent, avatarContent } = resolvePersonName(
    { ...person, name: resolvePersonNameForActivity(props), companyName: shortCompanyName, companyLogo: logoIconBlack },
    { withAction: true },
  );

  const notificationText = useMemo(
    () => campaignId && getRemovedNotificationText({ isCampaignRemoved, isLeadRemoved }),
    [isLeadRemoved, isCampaignRemoved],
  );

  const isArchive = campaignStatus === 'archived';

  useEffect(() => {
    const element = ref.current;

    if (element) {
      setShowToolTip(element.scrollWidth > element.clientWidth);
    }
  }, []);

  const modalHandler = () => {
    dispatch(
      openModal({
        type: ModalTypes.VIEW_LEAD,
        closable: false,
        params: {
          campaignId: campaignId,
          entityUrn: person?.key,
        },
      }),
    );
  };

  return (
    <TableRow hoverBgColor={({ light }) => light.main} onClick={person && modalHandler} cursor="pointer">
      <TableCell justifyContent="center">{avatarContent}</TableCell>
      <TableCell>{name || nameContent}</TableCell>
      <TableCell>
        <Typography capitalizeFirstLetter fontSize="15px">
          {resolveActionDescription(description, message)}
        </Typography>
      </TableCell>
      <TableCell>
        <Link
          variant="lightBlue"
          crop
          tooltip={showToolTip && campaignName}
          to={`/campaign/details/overview/${campaignId}`}
          onClick={(e) => e.stopPropagation()}
          disabled={isCampaignRemoved}
          cursor="pointer"
        >
          <Box display="flex" alignItems="center">
            <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" ref={ref} fontSize="15px">
              {campaignName}
            </Box>

            {isArchive && (
              <Typography display="block" color="error.main">
                &nbsp;(archived)
              </Typography>
            )}

            {notificationText && <NotificationDot tooltip={{ title: notificationText, placement: 'right' }} />}
          </Box>
        </Link>
      </TableCell>
      <TableCell>
        {dayjs
          .utc(msgCreatedAt || createdAt)
          .local()
          .format('MMMM Do YYYY')}
      </TableCell>
      <TableCell>
        {dayjs
          .utc(msgCreatedAt || createdAt)
          .local()
          .format('h:mm a')}
      </TableCell>
    </TableRow>
  );
};
