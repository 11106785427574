import styled from '@emotion/styled';
import { Box, Typography as TypographyMui } from '@mui/material';

import { Typography } from 'src/components';

export const Title = styled(TypographyMui)`
  font-family: 'ProximaSoft', sans-serif;
  font-weight: 600;
  font-size: 22px;
  line-height: 40px;
  color: ${({ theme }) => theme.palette.primary.wlLight};
`;

export const Subtitle = styled(Title)`
  font-size: 18px;
  line-height: 30px;
`;

export const SuccessMessage = styled(Typography)`
  color: #91d438;
  font-size: 12px;
`;

export const ErrorMessage = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
`;

export const Wrapper = styled(Box)`
  background: #ffffff;
  height: min-content;
  padding: 20px 20px;
  box-shadow: 0 1px 1px 0 ${({ theme }) => theme.palette.light.dark};
  border-radius: 5px;
  margin: 15px;
`;

export const Divider = styled(Box)`
  height: 3px;
  background-color: rgba(124, 125, 157, 0.2);
`;
