import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Checkbox, FilterInput, Filters, Tooltip } from 'src/components';
import { CampaignCategory, CampaignType } from 'src/enums';
import { getCampaignTypes } from 'src/helpers';
import { IGetCampaign } from 'src/types';
import { TLeadSearchFilters } from './Leads';

interface ILeadsFiltersProps {
  searchFields: TLeadSearchFilters;
  onSearchFieldsChange: (values: Partial<TLeadSearchFilters>) => void;
  campaign?: IGetCampaign;
}

const CheckboxLabel = styled.label`
  color: #333;
  font-size: 14px;
  cursor: pointer;
`;

export const LeadsFilters = ({ searchFields, campaign, onSearchFieldsChange }: ILeadsFiltersProps) => {
  const { category } = getCampaignTypes(campaign);

  const filters = [
    {
      label: 'Name',
      onInput: (name: string) => onSearchFieldsChange({ name }),
      value: searchFields.name,
      show: campaign?.campaignType !== CampaignType.EMAIL_CSV_UPLOAD,
    },
    {
      label: 'Position',
      onInput: (title: string) => onSearchFieldsChange({ title }),
      value: searchFields.title,
      show: [
        CampaignCategory.LINKEDIN,
        CampaignCategory.EVENT,
        CampaignCategory.POST,
        CampaignCategory.GROUP,
        CampaignCategory.MULTICHANNEL,
        CampaignCategory.INMAIL,
      ].includes(category as CampaignCategory),
    },
    {
      label: 'Company',
      onInput: (company: string) => onSearchFieldsChange({ company }),
      value: searchFields.company,
      show: [
        CampaignCategory.LINKEDIN,
        CampaignCategory.EVENT,
        CampaignCategory.POST,
        CampaignCategory.GROUP,
        CampaignCategory.MULTICHANNEL,
        CampaignCategory.INMAIL,
      ].includes(category as CampaignCategory),
    },
    {
      label: 'Location',
      onInput: (location: string) => onSearchFieldsChange({ location }),
      value: searchFields.location,
      show: [
        CampaignCategory.LINKEDIN,
        CampaignCategory.EVENT,
        CampaignCategory.POST,
        CampaignCategory.GROUP,
        CampaignCategory.MULTICHANNEL,
        CampaignCategory.INMAIL,
      ].includes(category as CampaignCategory),
    },
    {
      label: 'Email',
      onInput: (email: string) => onSearchFieldsChange({ email }),
      value: searchFields.email,
      show: category === CampaignCategory.EMAIL,
    },
    {
      label: 'X (Twitter) handle',
      onInput: (twitter: string) => onSearchFieldsChange({ twitter }),
      value: searchFields.twitter,
      show: category === CampaignCategory.TWITTER,
    },
  ];

  return (
    <Filters gridTemplateColumns="2fr 2fr 2fr 2fr 1fr !important">
      {filters?.map(
        ({ label, onInput, value, show }) =>
          show && (
            <FilterInput
              key={label}
              name={label}
              label={label}
              value={value}
              onChange={(event) => onInput(event.target.value)}
            />
          ),
      )}

      <Box display="flex" alignItems="center" pt="24px">
        <Tooltip title="Show excluded leads">
          <Box display="flex" alignItems="center">
            <Checkbox
              id="excluded-filter"
              checked={searchFields.excludedOnly}
              onChange={(e) => onSearchFieldsChange({ excludedOnly: e.target.checked })}
            />
            <CheckboxLabel htmlFor="excluded-filter">Excluded only</CheckboxLabel>
          </Box>
        </Tooltip>
      </Box>
    </Filters>
  );
};
