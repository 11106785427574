import { useMemo } from 'react';
import { useTheme } from '@emotion/react';

import { ITypographyProps, Tag, Typography } from 'src/components';
import { TUserStatus } from 'src/types';

interface IStatusBadgedProps extends ITypographyProps {
  status: TUserStatus;
}

export const StatusBadged = ({ status, ...rest }: IStatusBadgedProps) => {
  const { palette } = useTheme();

  const { bgColor, borderColor, color } = useMemo(() => {
    switch (status) {
      case 'pending':
        return { bgColor: palette.orange[50], borderColor: `${palette.orange[200]}`, color: palette.orange[700] };
      case 'rejected':
        return { bgColor: palette.error[100], borderColor: palette.error[200], color: palette.error[600] };
      default:
        return {
          bgColor: `${palette.success[200]}26`,
          borderColor: `${palette.success[200]}e6`,
          color: palette.success[600],
        };
    }
  }, [status]);

  return (
    <Tag borderColor={borderColor} bgColor={bgColor}>
      <Typography color={color} textTransform="capitalize" textAlign="center" {...rest}>
        {status}
      </Typography>
    </Tag>
  );
};
