import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { HeaderButton, HeaderContainer, HeaderTitle } from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features } from 'src/enums';
import { useTeamPlan } from 'src/hooks';
import { useDeleteSequenceTemplate } from 'src/reactQueries';
import { openModal } from 'src/store';

interface SequenceTemplateFormLayoutProps {
  onSave: () => void;
  onClone: () => void;
  sequenceTemplateId?: number;
  isEdit?: boolean;
  isLoading: boolean;
  isSaveDisabled: boolean;
  saveButtonTooltip: string;
}

export const SequenceTemplateFormLayout = ({
  onSave,
  sequenceTemplateId,
  onClone,
  isEdit = false,
  isLoading,
  isSaveDisabled = false,
  saveButtonTooltip,
}: SequenceTemplateFormLayoutProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { checkFeature } = useTeamPlan();

  const { deleteSequenceTemplate, isLoading: isLoadingDelete } = useDeleteSequenceTemplate(Number(sequenceTemplateId), {
    onSuccess: () => {
      navigate('/sequence-templates/personal', { replace: true });
    },
  });

  const onDeleteClick = () => {
    dispatch(
      openModal({
        headerText: 'Delete Confirmation',
        descriptionText: 'Are you sure to delete this template?',
        onConfirm: () => {
          deleteSequenceTemplate();
        },
      }),
    );
  };

  return (
    <>
      <HeaderContainer pb="10px">
        <Box display="flex" justifyContent="space-between">
          <HeaderTitle>Create Campaign Template</HeaderTitle>
          <Box display="flex" justifyContent="space-between" gap="15px" mt="20px">
            <HeaderButton variant="gray" onClick={() => navigate(-1)}>
              Cancel
            </HeaderButton>
            {isEdit && (
              <>
                <HeaderButton variant="warning" onClick={onDeleteClick} processing={isLoadingDelete}>
                  Delete
                </HeaderButton>
                <HeaderButton onClick={onClone}>Clone</HeaderButton>
              </>
            )}
            <HeaderButton
              disabled={!checkFeature(Features.sequenceTemplates) || isSaveDisabled}
              tooltip={{
                title:
                  (!checkFeature(Features.sequenceTemplates) && PLAN_TOOLTIPS.professional) ||
                  (isSaveDisabled && saveButtonTooltip),
              }}
              onClick={onSave}
              processing={isLoading}
            >
              Save
            </HeaderButton>
          </Box>
        </Box>
      </HeaderContainer>
    </>
  );
};
