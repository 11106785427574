import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './styles.scss';
import styled from '@emotion/styled';
import { Box, SelectChangeEvent } from '@mui/material';

import { Button, Input, SelectCreateTrend, Typography } from 'src/components';
import { TrendsSortBy } from 'src/enums';
import { useCreateTrend, useUpdateTrend } from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IModalProps, ISelectOption, ITrend } from 'src/types';

const ModalBody = styled(Box)`
  padding: 10px 40px 0 40px;
`;

const StyledInput = styled(Input)`
  height: 33px;
  line-height: 19px;
  margin-bottom: 18px;
  margin-top: 5px;
`;

const SelectHorizontalContainer = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: row;
  min-height: 53px;
`;

const SelectIntervalContainer = styled(Box)`
  width: 245px;
  margin-right: 15px;
`;

const SelectSortByContainer = styled(Box)`
  margin-left: 15px;
`;

const CustomIntervalInput = styled.input`
  margin-left: 20px;
  width: 40px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.light.dark} !important;
  padding: 5px;
  height: 33px;
  font-family: 'ProximaSoft', sans-serif;
  box-sizing: border-box;

  &:focus {
    outline: 1px solid ${({ theme }) => theme.palette.primary.main} !important;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

const INTERVAL_LIST: ISelectOption[] = [
  { value: '3', label: '3 hours' },
  { value: '6', label: '6 hours' },
  { value: '12', label: '12 hours' },
  { value: '24', label: '24 hours' },
  { value: 'custom_value', label: 'Custom' },
];

const SORT_BY_OPTION_LIST: ISelectOption[] = [
  { value: TrendsSortBy.TOP_MATCH, label: 'Top match' },
  { value: TrendsSortBy.LATEST, label: 'Latests' },
];

interface IModalCreateTrendProps {
  trend?: ITrend;
}

export const ModalCreateTrend = ({ params }: IModalProps<IModalCreateTrendProps>) => {
  const dispatch = useDispatch();
  const { trend } = params ?? {};
  const defaultInterval =
    INTERVAL_LIST.find((interval) => interval.value === String(trend?.interval))?.value || 'custom_value';

  const [keyword, setKeyword] = useState(trend?.term || '');
  const [interval, setInterval] = useState(trend ? defaultInterval : '3');
  const [sortBy, setSortBy] = useState<string>(trend?.sortBy || TrendsSortBy.TOP_MATCH);
  const [customIntervalValue, setCustomIntervalValue] = useState(trend?.interval || '3');

  const onError = (error: ICustomAxiosError) => {
    dispatch(
      showToast({
        type: 'error',
        message: error.message,
        autoCloseTime: 3000,
      }),
    );
  };

  const { createTrend, isLoading: isCreating } = useCreateTrend({ onError });
  const { updateTrend, isLoading: isUpdating } = useUpdateTrend({ onError });

  const customIntervalInputShown = interval === 'custom_value';

  const confirmHandler = () => {
    if (keyword && (!customIntervalInputShown || (+customIntervalValue >= 3 && +customIntervalValue <= 360))) {
      const body = {
        term: keyword,
        sortBy: sortBy as TrendsSortBy,
        interval: customIntervalInputShown ? +customIntervalValue : +interval,
      };

      if (trend) {
        updateTrend({ ...trend, ...body });
      } else {
        createTrend(body);
      }
    } else {
      dispatch(
        showToast({
          type: 'error',
          message: keyword ? 'Interval range should be with in 3 hours and 360 hours/15 days.' : 'Please add keyword.',
          autoCloseTime: 3000,
        }),
      );
    }
  };

  const closeHandler = () => {
    dispatch(closeModal());
  };

  const onChangeText = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  };

  const onChangeInterval = (event: SelectChangeEvent) => {
    setInterval(event.target.value);
  };

  const onChangeCustomInterval = (event: SelectChangeEvent) => {
    const newInterval = parseInt(event.target.value, 10);
    setCustomIntervalValue(event.target.value);

    if (newInterval < 3 || newInterval > 360) {
      dispatch(
        showToast({
          type: 'error',
          autoCloseTime: 3000,
          message: 'Interval range should be with in 3 hours and 360 hours/15 days.',
        }),
      );
    }
  };

  const onChangeSortBy = (event: SelectChangeEvent) => {
    setSortBy(event.target.value);
  };

  return (
    <>
      <ModalBody>
        <Typography color="text.primary">Keyword</Typography>
        <StyledInput name="keyword" onChange={onChangeText} placeholder="Keyword" type="text" value={keyword} />

        <SelectHorizontalContainer>
          <SelectIntervalContainer>
            <SelectCreateTrend
              label="Interval:"
              onChange={onChangeInterval}
              options={INTERVAL_LIST}
              value={interval}
              paperClassName="alf-modal-create-trend_paper alf-modal-create-trend_paper-interval"
            />
            {customIntervalInputShown && (
              <CustomIntervalInput type="number" value={customIntervalValue} onChange={onChangeCustomInterval} />
            )}
          </SelectIntervalContainer>

          <SelectSortByContainer>
            <SelectCreateTrend
              label="Sort by:"
              onChange={onChangeSortBy}
              options={SORT_BY_OPTION_LIST}
              value={sortBy}
              paperClassName="alf-modal-create-trend_paper alf-modal-create-trend_paper-sort_by"
            />
          </SelectSortByContainer>
        </SelectHorizontalContainer>
      </ModalBody>

      <Box py={2} px={5} display="flex" justifyContent="space-evenly">
        <Button variant="gray" onClick={closeHandler}>
          Cancel
        </Button>
        <Button onClick={confirmHandler} processing={isCreating || isUpdating} disabled={isCreating || isUpdating}>
          Save
        </Button>
      </Box>
    </>
  );
};
