import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { SelectSnippet, TextArea, Toggle, Typography } from 'src/components';
import { separateNumWithComma } from 'src/helpers';
import { IGreetingsRequest, IGreetingsSettings } from 'src/types';

const StyledTextArea = styled(TextArea)`
  max-height: 500px;
  min-height: 70px;
  padding: 5px 10px;
  line-height: 19px;
  resize: vertical;

  &:disabled {
    color: #d0d0d0;
    background-color: #fff;
  }
`;

const AttachmentBox = styled(Box)`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.light.light};
  cursor: pointer;
  border-radius: 4px 4px 0 0;
  margin-left: 5px;
  padding: 0 20px;
  font-size: 14px;

  &:hover {
    color: #fff;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const MAX_TEXT_LENGTH = 300;

export interface IGreetingsFormProps {
  title: string;
  defaultValue?: string;

  watchToggle: keyof IGreetingsSettings;
  watchField: keyof IGreetingsRequest;
  watch: UseFormWatch<IGreetingsRequest>;
  setValue: UseFormSetValue<IGreetingsRequest>;
  register: UseFormRegister<IGreetingsRequest>;
}

export const GreetingsForm = ({
  title,
  defaultValue = '',
  watchField,
  watchToggle,
  watch,
  setValue,
  register,
}: IGreetingsFormProps) => {
  const caretPosition = useRef(defaultValue.length);
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const watchText = (watch(watchField) ?? '') as string;
  const watchToggleField = watch(`greetings_settings.${watchToggle}`);

  const numLettersLeft = (MAX_TEXT_LENGTH ?? 0) - watchText.length;

  useEffect(() => {
    if (!watchText && defaultValue) {
      setValue(watchField, defaultValue);
    }
  }, []);

  useEffect(() => {
    if (watchText.length > MAX_TEXT_LENGTH) {
      setValue(watchField, watchText.slice(0, MAX_TEXT_LENGTH));
    }
  }, [watch(watchField)]);

  const onOpenSelect = () => setIsSelectOpen(true);
  const onCloseSelect = () => setIsSelectOpen(false);

  const onSnippetClick = (value: string) => {
    const snippet = `{{${value}}}`;
    const position = caretPosition.current;
    const leftValueSide = watchText.slice(0, position) + snippet;
    const newValue = leftValueSide + watchText.slice(position);

    setValue(watchField, newValue);
    caretPosition.current = leftValueSide.length;
  };

  const onToggleField = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(`greetings_settings.${watchToggle}`, e.currentTarget.checked);
  };

  return (
    <Box>
      <Box display="flex" marginBottom="10px">
        <Toggle onChange={onToggleField} checked={watchToggleField} name={watchToggle} />
        <Typography ml="10px">{title}</Typography>
      </Box>

      <Box position="relative">
        <StyledTextArea
          data-testid="textarea"
          disabled={!watchToggleField}
          maxLength={MAX_TEXT_LENGTH}
          name={watchField}
          register={register}
          parameters={{
            onBlur: (e) => {
              caretPosition.current = e.target.selectionStart;
            },
          }}
        />

        <Box display="flex" position="absolute" top="-30px" right="0">
          <Box position="relative">
            <AttachmentBox data-testid="213" onClick={onOpenSelect}>
              Insert
            </AttachmentBox>

            <SelectSnippet
              isSelectOpen={isSelectOpen}
              onCloseSelect={onCloseSelect}
              onOpenSelect={onOpenSelect}
              onSnippetClick={onSnippetClick}
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end">
        <Typography data-testid="char-counter" textAlign="right">
          {separateNumWithComma(numLettersLeft)}
        </Typography>
      </Box>
    </Box>
  );
};
