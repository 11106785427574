import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { Button, Input, Typography } from 'src/components';
import { REGEXP_VALID_EMAIL } from 'src/constants';
import { useAppSelector } from 'src/hooks';
import { closeModal } from 'src/store/modal.slice';
import { IModalProps } from 'src/types';
import { ModalBody, ModalFooter } from '../_components';

const StyledInput = styled(Input)`
  height: 34px;
`;

interface IEmailForm {
  email: string;
}

export const ModalSendTestEmail = ({ onCancel, onConfirm }: IModalProps) => {
  const dispatch = useDispatch();
  const { profile } = useAppSelector((state) => state.user);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IEmailForm>({ defaultValues: { email: profile.email ?? '' } });

  const confirmHandler = ({ email }: IEmailForm) => {
    onConfirm && onConfirm(email);
    dispatch(closeModal());
  };

  const closeHandler = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  return (
    <>
      <ModalBody>
        <StyledInput
          register={register}
          name="email"
          label="Please type the email you want to use for receiving the preview email"
          placeholder="Email"
          parameters={{
            required: 'Please enter email',
            pattern: {
              value: REGEXP_VALID_EMAIL,
              message: 'Please enter a valid email',
            },
          }}
        />
        {errors.email && (
          <Typography color="#f62713" mt="5px">
            {errors.email?.message}
          </Typography>
        )}
      </ModalBody>
      <ModalFooter>
        <Button variant="gray" onClick={closeHandler}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(confirmHandler)}>Submit</Button>
      </ModalFooter>
    </>
  );
};
