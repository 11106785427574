import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faReply, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';

import {
  ActionButton,
  Checkbox,
  HtmlParser,
  NotificationDot,
  ProfileAvatar,
  TableCell,
  TableRow,
  Typography,
} from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { ModalTypes, Plan } from 'src/enums';
import { getReturnToCampaignTooltip, resolveActionStepNumber } from 'src/helpers';
import { useAppSelector } from 'src/hooks';
import { useReturnCampaignReplies, useSkipCampaignLead } from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';
import { ICampaignLead, IGetCampaign, IGetLeadsRequest, ISelectProps, TLeadsSortType } from 'src/types';

dayjs.extend(utc);

const Text = styled.p<{
  checked?: boolean;
}>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ color, checked, theme }) => (checked ? 'inherit' : color || theme.palette.text.primary)};
`;

const SHOW_MESSAGE_WHITELIST: TLeadsSortType[] = ['emailOpened', 'emailed'];

interface IEmailLeadsTableRowProps {
  lead: ICampaignLead;
  campaign: IGetCampaign;
  getLeadKeys: IGetLeadsRequest;
  selectProps: ISelectProps;
  columnKeys: string[];
  config: {
    showMessages?: boolean;
    repliesFilter?: boolean;
    showDate?: boolean;
    isAllFilter?: boolean;
    showActions?: boolean;
  };
}

export const EmailLeadsTableRow = ({
  campaign,
  config,
  selectProps,
  columnKeys,
  getLeadKeys,
  lead,
}: IEmailLeadsTableRowProps) => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { plan } = useAppSelector((state) => state?.team?.data) ?? {};

  const {
    entityUrn,
    status,
    workEmail,
    csvColumns = {},
    isReturnToCampaign,
    createdAt,
    message,
    isExcluded,
    nextTouchIndex,
  } = lead;
  const { isAllFilter, repliesFilter, showActions, showDate, showMessages } = config;
  const leadEmail = csvColumns?.email || workEmail || '';

  const isApproved = status === 'approved';
  const isSelected = selectProps.selectLeads.includes(entityUrn);
  const totalSteps = campaign?.touchSequence?.sequence?.length || 1;

  const { skipCampaignLead, isSkipLoading } = useSkipCampaignLead(campaign?.id, getLeadKeys);
  const { returnCampaignReplies, isReturnLoading } = useReturnCampaignReplies({ campaignId: campaign?.id });
  const returnedLead =
    ['replies', 'allReplies', 'inmailReplies', 'userInteraction', 'emailReplies'].includes(getLeadKeys?.type) &&
    lead?.isReturnToCampaign;

  const messageWithSnippets = Object.entries(lead?.csvColumns ?? {}).reduce(
    (acc, value) => acc?.replaceAll(`{{${value[0]}}}`, value[1]),
    message || '',
  );

  const actions = [
    {
      isShow: isAllFilter,
      icon: faTimes,
      title: 'Remove Lead',
      isLoading: isSkipLoading,
      onClick: () => skipCampaignLead([entityUrn]),
    },
    {
      isShow: repliesFilter,
      icon: faReply,
      title:
        plan === Plan.PERSONAL
          ? PLAN_TOOLTIPS.professional
          : getReturnToCampaignTooltip({ isApproved, isReturnToCampaign, isCampaignRemoved: campaign?.isRemoved }),
      disabled: !isApproved || isReturnToCampaign || plan === Plan.PERSONAL || campaign?.isRemoved,
      isLoading: isReturnLoading,
      onClick: () => returnCampaignReplies([entityUrn]),
    },
  ];

  const modalHandler = () => {
    dispatch(
      openModal({
        type: ModalTypes.VIEW_LEAD,
        closable: false,
        params: {
          campaignId: campaign?.id,
          entityUrn,
        },
      }),
    );
  };

  return (
    <TableRow
      checkedBgColor={palette.primary.main}
      key={entityUrn}
      checked={isSelected}
      sx={{ cursor: 'pointer' }}
      onClick={modalHandler}
    >
      <TableCell onClick={(event) => event.stopPropagation()}>
        <Checkbox checked={isSelected} onChange={() => selectProps.toggleLead(entityUrn)} />
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          <ProfileAvatar margin="0 17px 0 1px" cursor="pointer" firstLatter={(leadEmail ?? '')[0]} />
          <Box display="inline">
            <Typography component="div" semibold>
              <Text color={isSelected ? '#fff' : palette.primary.wlLight}>{leadEmail}</Text>
            </Typography>
            {returnedLead && (
              <Typography color="violet.dark" fontSize="12px">
                (Returned to campaign)
              </Typography>
            )}
          </Box>
          {isExcluded && (
            <NotificationDot
              tooltip={{
                title: (
                  <>
                    <b>Excluded lead:</b> This lead was excluded from the campaign. Update their status in the CRM to
                    make changes.
                  </>
                ),
                placement: 'right',
              }}
            />
          )}
        </Box>
      </TableCell>
      {showMessages && (
        <TableCell>
          <Text checked={isSelected}>
            {SHOW_MESSAGE_WHITELIST.includes(getLeadKeys?.type) ? (
              <HtmlParser parseText={(messageWithSnippets || '')?.replace(/<img[^>]*>/g, '')} />
            ) : (
              messageWithSnippets
            )}
          </Text>
        </TableCell>
      )}
      {showDate && (
        <TableCell>
          <Text checked={isSelected}>{dayjs.utc(createdAt).local().format('MMMM Do YYYY h:mm a')}</Text>
        </TableCell>
      )}
      {columnKeys?.map((key) => {
        const columnNumber = [...key.split('')].pop();
        const customValue = lead[`customcolumn${columnNumber}` as keyof ICampaignLead];

        if (!isAllFilter) {
          return null;
        }

        return (
          <TableCell key={key}>
            <Text checked={isSelected}>{(csvColumns || {})[key] || customValue}</Text>
          </TableCell>
        );
      })}

      <TableCell>{resolveActionStepNumber({ stepNumber: nextTouchIndex, totalSteps })}</TableCell>

      {showActions && (
        <TableCell preventClick justifyContent="flex-end">
          <Box display="flex" gap="8px">
            {actions?.map(
              ({ icon, onClick, title: actionTitle, isLoading, disabled, isShow }) =>
                isShow && (
                  <ActionButton
                    key={actionTitle}
                    tooltip={actionTitle}
                    disabled={disabled}
                    onClick={onClick}
                    processing={isLoading}
                    icon={icon}
                  />
                ),
            )}
          </Box>
        </TableCell>
      )}
    </TableRow>
  );
};
