import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faPencilAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';

import {
  ActionButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
} from 'src/components';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from 'src/constants';
import { ModalTypes } from 'src/enums';
import { useDeleteGroup, useGetGroupListPage } from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';
import { IModalTeamGroupUpdateProps, ITablePagination, TGetGroupsSortField, TSortOrder } from 'src/types';

const AdminList = styled.ul`
  display: flex;
  flex-direction: row;
`;

const AdminListItem = styled.li`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin-right: 10px;
  overflow: hidden;
`;

export const TeamGroupsTable = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(DEFAULT_PAGE);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [sortOrder, setSortOrder] = useState<TSortOrder>('ASC');
  const [sortField, setSortField] = useState<TGetGroupsSortField>('name');

  const { data, isLoading } = useGetGroupListPage({ page, perPage, sortField, sortOrder });
  const { deleteGroup, isDeleteLoading } = useDeleteGroup();

  const onEditButtonPress = ({ groupId, name }: { groupId: number; name: string }) => {
    dispatch(
      openModal({
        type: ModalTypes.TEAM_GROUP_UPDATE,
        headerText: 'Edit Group',
        params: { groupId, name } as IModalTeamGroupUpdateProps,
      }),
    );
  };

  const onDeleteButtonPress = ({ groupId }: { groupId: number }) => {
    dispatch(
      openModal({
        type: ModalTypes.TEAM_GROUP_DELETE,
        headerText: 'Delete Group',
        onConfirm: () => deleteGroup({ groupId }),
        confirmButtonLoading: isDeleteLoading,
      }),
    );
  };

  const sortingProps = {
    sortOrder,
    sortField,
    onSortFieldChange: (field: TGetGroupsSortField, order: TSortOrder) => {
      setSortField(field);
      setSortOrder(order);
    },
  };

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  useEffect(() => {
    if (data?.groups?.length === 0 && page > DEFAULT_PAGE) {
      setPage(DEFAULT_PAGE);
    }
  }, [data?.groups]);

  return (
    <Box padding="15px 15px 80px">
      <Table disabled={false} processing={isLoading}>
        <TableHead>
          <TableRow>
            <TableHeadCell name="name" sorting={sortingProps} width="30%">
              Group
            </TableHeadCell>
            <TableHeadCell name="members" sorting={sortingProps} width="15%" justifyContent="center">
              Members
            </TableHeadCell>
            <TableHeadCell justifyContent="center">Admin</TableHeadCell>
            <TableHeadCell width="100px" />
          </TableRow>
        </TableHead>

        <TableBody placeholder={!data?.total && `No groups yet.`}>
          {data?.groups.map((groupItem) => {
            return (
              <TableRow key={groupItem.id}>
                <TableCell>{groupItem.name}</TableCell>
                <TableCell justifyContent="center">{groupItem.membersCount.toString()}</TableCell>
                <TableCell>
                  <AdminList>
                    {groupItem.admins.map((adminItem) => (
                      <AdminListItem key={adminItem.id}>
                        <Box>{adminItem.firstName}</Box>
                        <Box>{adminItem.email}</Box>
                      </AdminListItem>
                    ))}
                  </AdminList>
                </TableCell>
                <TableCell>
                  <Box display="flex" justifyContent="flex-end" gap="15px" width="100%">
                    <ActionButton
                      data-testid="edit"
                      tooltip="Edit Group"
                      icon={faPencilAlt}
                      onClick={() =>
                        onEditButtonPress({
                          groupId: groupItem.id,
                          name: groupItem.name,
                        })
                      }
                    />
                    <ActionButton
                      data-testid="delete"
                      tooltip="Delete Group"
                      icon={faTimes}
                      onClick={() => onDeleteButtonPress({ groupId: groupItem.id })}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <TablePagination page={page} perPage={perPage} total={data?.total} onPaginationChange={paginationChangeHandler} />
    </Box>
  );
};
