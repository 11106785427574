import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import { Palette } from '@mui/material';

import { PLAN_TOOLTIPS, POST_MAX_CONTENT, POST_STATUS_ERROR_MESSAGE } from 'src/constants';
import { PostStatus, PostStatusMessage } from 'src/enums';
import { TSchedulePost } from 'src/types';

dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export const resolveStatus = (palette: Palette, status: PostStatus, statusMessage?: PostStatusMessage) => {
  switch (status) {
    case PostStatus.PUBLISHED: {
      return {
        tooltip: 'This post is published',
        bgColor: `${palette.success[200]}26`,
        borderColor: `${palette.success[200]}e6`,
        color: `${palette.success[600]}`,
        label: 'PUBLISHED',
      };
    }
    case PostStatus.PENDING: {
      return {
        tooltip: 'This post is scheduled',
        bgColor: `${palette.warning.main}1a`,
        borderColor: `${palette.warning.main}80`,
        color: palette.warning.main,
        label: 'SCHEDULED',
      };
    }
    case PostStatus.ARCHIVED: {
      return {
        tooltip: 'This post is archived',
        bgColor: `${palette.gray[100]}26`,
        borderColor: `${palette.gray[100]}e6`,
        color: palette.gray[600],
        label: 'ARCHIVED',
      };
    }
    case PostStatus.EXECUTING:
    case PostStatus.QUEUED: {
      return {
        tooltip: 'This post is queued and will be published soon.',
        bgColor: palette.blueLight[50],
        borderColor: palette.blueLight[200],
        color: palette.blueLight[700],
        label: 'QUEUED',
      };
    }
    case PostStatus.SKIPPED: {
      return {
        tooltip:
          statusMessage === PostStatusMessage.PAYLOAD_TOO_LONG
            ? POST_STATUS_ERROR_MESSAGE[PostStatusMessage.PAYLOAD_TOO_LONG]
            : 'This post is skipped, either bot was not running when the post was scheduled or there is some problem at platform.',
        bgColor: `${palette.orange[50]}`,
        borderColor: `${palette.orange[200]}`,
        color: palette.orange[700],
        label: 'SKIPPED',
      };
    }
    case PostStatus.DELETED: {
      return {
        tooltip: 'The post is removed from the platform.',
        bgColor: `${palette.purple[50]}`,
        borderColor: `${palette.purple[200]}`,
        color: palette.purple[700],
        label: 'NOT FOUND',
      };
    }
    case PostStatus.INVALID: {
      return {
        tooltip: statusMessage
          ? POST_STATUS_ERROR_MESSAGE[statusMessage]
          : 'This post file has invalid parameters, please try another file',
        bgColor: `${palette.error[100]}`,
        borderColor: `${palette.error[200]}`,
        color: palette.error[600],
        label: 'INVALID',
      };
    }

    default:
      return {};
  }
};

interface ICheckPostValidParams {
  post: TSchedulePost;
  isTrialStarted: boolean;
  attachmentError: string;
  isAppropriatePlan: boolean;
  onInvalidError: (message: string) => void;
  timezoneName?: string;
}

export const checkPostValid = ({
  isAppropriatePlan,
  attachmentError,
  isTrialStarted,
  post,
  onInvalidError,
  timezoneName,
}: ICheckPostValidParams) => {
  const contentLength = post?.content?.length;

  const validations = [
    {
      condition: !post.title,
      message: 'Add title to the post',
      invalidCallback: () => document.getElementById('title-input')?.focus(),
    },
    {
      condition: !post.content,
      message: 'Add some text to post',
      invalidCallback: () => document.getElementById('content-input')?.focus(),
    },
    {
      condition: attachmentError,
      message: attachmentError,
    },
    {
      condition: !post.postTypes?.length,
      message: 'Please select at least one platform to post your content!',
    },
    {
      condition: post?.postTypes?.includes('instagram') && !post?.attachments?.length,
      message: 'PLEASE ADD IMAGE/VIDEO TO PUBLISH IN INSTAGRAM',
    },
    {
      condition: post?.postTypes?.includes('twitter') && contentLength > POST_MAX_CONTENT.twitter,
      message: `X (Twitter) posts cannot be longer than ${POST_MAX_CONTENT.twitter} characters.`,
    },
    {
      condition: post?.postTypes?.includes('instagram') && contentLength > POST_MAX_CONTENT.instagram,
      message: `Instagram post caption cannot be longer than ${POST_MAX_CONTENT.instagram} characters.`,
    },
    {
      condition: post?.postTypes?.includes('facebook') && contentLength > POST_MAX_CONTENT.facebook,
      message: `Facebook posts cannot be longer than ${POST_MAX_CONTENT.facebook} characters.`,
    },
    {
      condition: post?.postTypes?.includes('linkedin') && contentLength > POST_MAX_CONTENT.linkedin,
      message: `Linkedin post cannot be longer than ${POST_MAX_CONTENT.linkedin} characters.`,
    },
    {
      condition: dayjs().tz(timezoneName).isAfter(post.scheduledAt),
      message: `Post time should be after the current time (${dayjs().tz(timezoneName).format('hh:mm A')})`,
    },
    {
      condition: !isAppropriatePlan && !isTrialStarted,
      message: PLAN_TOOLTIPS.professional,
    },
  ];

  const failedValidationIndex = validations.findIndex(({ condition }) => condition);
  const { condition, message, invalidCallback } = validations[failedValidationIndex] ?? {};

  if (condition) {
    invalidCallback?.();
    onInvalidError(message);

    return false;
  }

  return true;
};
