import { PropsWithChildren, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { STRIPE_PUBLIC_KEY, STRIPE_PUBLIC_KEY_OLD } from 'src/constants';
import { useAppSelector } from 'src/hooks';
import { useGetBillingInfo } from 'src/reactQueries';

const stripePromise = STRIPE_PUBLIC_KEY ? loadStripe(STRIPE_PUBLIC_KEY) : null;
const stripePromiseOld = STRIPE_PUBLIC_KEY_OLD ? loadStripe(STRIPE_PUBLIC_KEY_OLD) : null;

export const StripeProvider = ({ children }: PropsWithChildren) => {
  const { profile } = useAppSelector((state) => state.user);
  const { billingInfo, isLoading } = useGetBillingInfo();

  useEffect(() => {
    const script = document.createElement('script');

    script.id = 'profitwell-js';
    script.setAttribute(
      'data-pw-auth',
      profile.isNewStripe ? '3c299e046d8d93d312d461e1b7a9edd5' : '4b64e6a7422c580eeda5bf0713486025',
    );

    script.innerHTML = `
      (function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);})(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
        profitwell('start', { 'user_email': '${profile.isGhostUser ? '' : profile.email}' });
    `;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [profile]);

  if (isLoading) {
    return null;
  }

  return billingInfo && STRIPE_PUBLIC_KEY && STRIPE_PUBLIC_KEY_OLD ? (
    <Elements stripe={billingInfo?.isNewStripe ? stripePromise : stripePromiseOld}>{children}</Elements>
  ) : (
    <>{children}</>
  );
};
