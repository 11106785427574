import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateTeamMember } from 'src/api';
import { useAppSelector } from 'src/hooks';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IUpdateTeamMemberRequest, TUpdateTeamMemberResponse } from 'src/types';

interface IUseUpdateTeamMemberParams {
  memberName: string;
}

export const useUpdateTeamMember = (
  { memberName }: IUseUpdateTeamMemberParams,
  options?: UseMutationOptions<TUpdateTeamMemberResponse, ICustomAxiosError, IUpdateTeamMemberRequest>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const userId = useAppSelector(({ user }) => user.profile.id);

  const { mutate, isLoading, ...rest } = useMutation(['update-team-member'], updateTeamMember, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      await queryClient.invalidateQueries(['get-team-members']);
      await queryClient.invalidateQueries(['get-group-list-page']);
      if (userId === variables.memberId) {
        await queryClient.invalidateQueries(['get-team-by-id']);
      }

      dispatch(
        showToast({
          message: `Changed ${memberName}'s role to ${data.role}`,
          type: 'info',
          autoCloseTime: 5000,
        }),
      );
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { updateTeamMember: mutate, isUpdateLoading: isLoading, ...rest };
};
