import classNames from 'classnames';
import { useState } from 'react';
import './styles.scss';
import styled from '@emotion/styled';
import { MenuItem, Select } from '@mui/material';

import { ISelectRegularProp } from 'src/types';

const StyledMenuItem = styled(MenuItem)<{ disabled?: boolean }>`
  ${({ disabled }) => (disabled ? `opacity: .7; cursor: not-allowed;` : '')}
`;

export const SelectTeamMemberRole = ({
  onChange,
  defaultValue,
  options,
  paperClassName,
  rootClassName,
  disabled,
  value,
}: ISelectRegularProp) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  return (
    <Select
      data-testid="role-select"
      MenuProps={{
        classes: {
          paper: paperClassName ? `alf-select_paper ${paperClassName}` : 'alf-select_paper',
        },
      }}
      className={rootClassName ? `alf-select ${rootClassName}` : 'alf-select'}
      defaultValue={defaultValue}
      onChange={onChange}
      onClose={onClose}
      onOpen={onOpen}
      open={isOpen}
      value={value}
      disabled={disabled}
    >
      {options.map((item) => {
        const isSelected = item.value === value;

        return (
          <StyledMenuItem
            data-testid={item.value}
            disabled={item.disabled}
            key={item.value}
            value={item.value}
            className={classNames({
              'alf-select_menu-item__selected': isSelected,
            })}
          >
            {item.label}
          </StyledMenuItem>
        );
      })}
    </Select>
  );
};
