import { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';

import { Button, StripeCardOld, Typography } from 'src/components';
import { useUpdateCreditCard } from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 420px;
`;

export const ModalUpdateCreditCard = () => {
  const dispatch = useDispatch();

  const stripe = useStripe();
  const elements = useElements();

  const [stripeError, setStripeError] = useState('');

  const close = () => dispatch(closeModal());

  const { updateCreditCard, isLoading } = useUpdateCreditCard({
    onSuccess: () => {
      close();
    },
    onError: (err) => setStripeError(err.message),
  });

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement) as StripeCardElement;

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      setStripeError(error.message || '');
    } else {
      updateCreditCard(paymentMethod);
    }
  };

  return (
    <>
      <Box py="30px" px="40px">
        <Typography color="primary.wlLight" fontSize="22px" bold>
          Update credit card
        </Typography>
      </Box>
      <Box py="30px" px="40px" bgcolor="light.light" display="flex" flexDirection="column" alignItems="center">
        <StyledForm onSubmit={submit}>
          <StripeCardOld onChange={() => setStripeError('')} />
          <Typography color="error.light" my="10px">
            {stripeError}
          </Typography>
          <Button type="submit" disabled={!stripe} processing={isLoading}>
            Update Credit Card
          </Button>
        </StyledForm>
        <Typography fontSize="11px" color="violet.dark" mt="20px">
          <FontAwesomeIcon icon={faLock} /> This is a secure 128-bit SSL encryption.
        </Typography>
      </Box>
      <Box py="20px" px="40px" display="flex" justifyContent="flex-end">
        <Button variant="gray" onClick={close}>
          Cancel
        </Button>
      </Box>
    </>
  );
};
