import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { BASE_WL_API_ULR } from 'src/constants';
import { useCompanyTheme, useWhiteLabel } from 'src/hooks';
import { Code, RequestFields, Response, Title } from '../_components';
import { BAD_REQUEST, GET_LAST_ACTIONS, UNAUTHORIZED } from '../_constants';

export const WebhookDocLastActions = () => {
  const { shortCompanyName } = useCompanyTheme();
  const {
    whitelabel: { isAlfredDomain },
  } = useWhiteLabel();

  return (
    <Box>
      <Typography lineHeight="14px">
        This webhook can be used to get the latest actions performed by {shortCompanyName}. It will return the data in
        actions array
      </Typography>
      <Title>
        Request URL <b>GET</b>
      </Title>
      <Code>
        {isAlfredDomain ? 'https://meetalfred.com' : BASE_WL_API_ULR}
        /api/integrations/webhook/get-last-actions?webhook_key=:webhook_key&action=:action&page=0&per_page=10
      </Code>
      <Typography>
        <b>Note: </b>here page and per_page are optional params, page starts with 0.
      </Typography>

      <Title>Action (required)</Title>
      <Typography>
        You can get different type of actions by sending one of the next keywords as parameter in the GET request
      </Typography>
      <RequestFields field="invites" description="Gets the latest linkedin invites sent" />
      <RequestFields
        field="already_connected"
        description={`Gets the leads that were already connected when ${shortCompanyName} attempted the connection request`}
      />
      <RequestFields
        field="already_invited"
        description={`Gets the leads that were already invited when ${shortCompanyName} attempted the connection request`}
      />
      <RequestFields field="accepted" description="Gets the latest linkedin accepted connect request" />
      <RequestFields field="messages" description="Gets the latest linkedin messages and inmails sent" />
      <RequestFields field="replies" description="Gets the latest linkedin replies received" />
      <RequestFields field="emails" description="Gets the latest emails sent" />
      <RequestFields field="email_replies" description="Gets the latest emails replies received" />
      <RequestFields field="twitter" description="Gets the latest X (Twitter) messages sent" />
      <RequestFields field="twitter_replies" description="Gets the latest X (Twitter) replies received" />
      <RequestFields
        field="all_replies"
        description="Gets the latest replies received, including LinkedIn, Email and X (Twitter)"
      />
      <RequestFields
        field="greetings"
        description="Gets the latest linkedin greetings messages sent (birthday, work_anniversary, skill_endorsement, job_change)"
      />
      <Title>Responses</Title>
      <Response type="success" title="200 OK" responses={GET_LAST_ACTIONS} />
      <Response mt="20px" type="error" title="401 Unauthorized" responses={UNAUTHORIZED} />
      <Response mt="20px" type="error" title="400 Bad Request" responses={BAD_REQUEST} />
    </Box>
  );
};
