import styled from '@emotion/styled';
import { Avatar, Box } from '@mui/material';

import { Typography } from 'src/components';

export const Title = styled(Typography)`
  font-weight: 600;
  font-size: 26px;
  line-height: 40px;
  color: ${({ theme }) => theme.palette.primary.wlLight};
`;

export const Subtitle = styled(Title)`
  font-size: 18px;
  line-height: 30px;
`;

export const Wrapper = styled(Box)`
  background: #ffffff;
  height: min-content;
  padding: 20px 20px;
  box-shadow: 0 1px 1px 0 ${({ theme }) => theme.palette.light.dark};
  border-radius: 5px;
`;

export const UserAvatar = styled(Avatar)`
  width: 32px;
  height: 32px;
`;
