import { createFormData } from 'src/helpers';
import {
  IAuthToken,
  IChangePassword,
  IChangeUserInfoRequest,
  IDailyLimits,
  IExportUnsubscribedEmailsRequest,
  IGetReferral,
  IGreetingsRequest,
  IImpersonateUser,
  ILinkedInProfileData,
  IMailSettings,
  IRealUser,
  IScheduled,
  ISendTestEmailSequenceStep,
  IUpdateCredentials,
  IUser,
  IUserCloudBotInfo,
  IUserEmailResponse,
  IUserInvitesRestriction,
  IUserLinkedInResponse,
  IUserPreferences,
  IUserSignature,
} from 'src/types';
import { appApi } from './axios';

export const getUserAccount = (): Promise<IUser> => appApi.get(`/users/me`);

export const getRealUser = (): Promise<IRealUser> => appApi.get(`/users/me/real`);

export const postUsersBots = (timezone: string): Promise<void> => appApi.post(`/users/me/bots`, { timezone });

export const getUserLinkedIn = (): Promise<IUserLinkedInResponse> => appApi.get(`/users/me/linked-in`);

export const getUsersEmail = (): Promise<IUserEmailResponse> => appApi.get(`/users/email`);

export const getUserSignature = (): Promise<IUserSignature> => appApi.get(`/users/signature`);

export const updateUserSignature = (data: IUserSignature): Promise<IUserSignature> =>
  appApi.patch(`/users/signature`, data);

export const getUserLinkedInProfile = (): Promise<ILinkedInProfileData> => appApi.get(`/users/me/linked-in`);

export const getUserPreferences = (): Promise<IUserPreferences> => appApi.get(`/users/preferences`);

export const getUserReferral = (): Promise<IGetReferral> => appApi.get(`/users/me/referral`);

export const updatePassword = (data: IChangePassword): Promise<void> => appApi.post(`/users/change-password`, data);

export const updateEmail = (data: { email: string }): Promise<void> => appApi.patch(`/users/change-email`, data);

export const updateWithdrawals = (data: { autoWithdraw: number }): Promise<void> =>
  appApi.patch(`/users/edit-auto-withdraw`, data);

export const updateWDailyLimits = (data: IDailyLimits): Promise<void> => appApi.patch(`/users/edit-daily-limits`, data);

export const updateGreetings = (data: IGreetingsRequest): Promise<void> =>
  appApi.patch(`/users/edit-greetings`, { greetingMessages: data });

export const updateWorkHours = (data: IScheduled): Promise<void> =>
  appApi.patch(`/users/edit-operating-hours`, { operatingHours: data });

export const updateWorkHoursTimezone = (timezone: string): Promise<void> =>
  appApi.patch(`/users/set-cloud-timezone`, { timezone });

export const impersonateUser = (data: IImpersonateUser): Promise<IAuthToken> => appApi.post(`/users/impersonate`, data);

export const stopImpersonateUser = (): Promise<IAuthToken> => appApi.post(`/users/stop-impersonating`);

export const updateUserInfo = (data: IChangeUserInfoRequest): Promise<void> =>
  appApi.patch(`/users/update`, createFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const setUserContractId = (selectedContractId: string): Promise<void> =>
  appApi.post(`/users/set-contract-id`, { selectedContractId });

export const setUserLanguage = (language: string): Promise<void> => appApi.patch(`/users/language`, { language });

export const completeUserWalkthrough = (): Promise<void> => appApi.patch(`/users/me/complete-walkthrough`);

export const setCredentials = (data: IUpdateCredentials): Promise<void> => appApi.patch(`users/set-credentials`, data);

export const exportUnsubscribedEmails = (params: IExportUnsubscribedEmailsRequest): Promise<void> =>
  appApi.post(`/users/me/unsubscribed-emails/csv`, params);

export const toggleIncludeUnsubscribeEmailLink = (): Promise<void> => appApi.patch(`/users/me/toggle-unsubscribe-link`);

export const deleteMailSettings = (): Promise<void> => appApi.delete(`/users/me/mail-settings`);

export const getMailSettings = (): Promise<IMailSettings> => appApi.get(`/users/me/mail-settings`);

export const setMailSettings = (data: IMailSettings): Promise<IMailSettings> =>
  appApi.put(`/users/me/mail-settings`, data);

export const getUserInvitesRestriction = (): Promise<IUserInvitesRestriction> =>
  appApi.get('/users/me/invites-restriction');

export const getUserCloudBotInfo = (): Promise<IUserCloudBotInfo> => appApi.get(`/users/me/cloud-bot-info`);

export const sendTestEmail = (body: ISendTestEmailSequenceStep): Promise<void> =>
  appApi.post('/users/integrations/test-email', body);
