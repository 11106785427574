import { Box, BoxProps } from '@mui/material';

interface ModalFooterProps extends BoxProps {
  alignToTheEnd?: boolean;
  noBorder?: boolean;
}

export const ModalFooter = ({ children, alignToTheEnd, noBorder = false, ...restProps }: ModalFooterProps) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent={alignToTheEnd ? 'flex-end' : 'space-between'}
    gap="10px"
    padding="15px 40px"
    borderTop="1px solid #e5e5e5"
    {...(noBorder && { border: 'none' })}
    {...restProps}
  >
    {children}
  </Box>
);
