import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { verifyTwitterAuth } from 'src/api';
import { showToast } from 'src/store';
import { ICustomAxiosError, ITwitterDetails } from 'src/types';

export const useVerifyTwitterAuth = (options?: UseMutationOptions<ITwitterDetails, ICustomAxiosError, string>) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation('verify-twitter-auth', (pin: string) => verifyTwitterAuth(pin), {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      queryClient.setQueryData(['get-user-twitter-details'], data);
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return {
    verifyTwitterAuth: mutate,
    ...rest,
  };
};
