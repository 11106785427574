import { useQuery, UseQueryOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { authUsingMicrosoft } from 'src/api';
import { COOKIE_TOKEN_NAME } from 'src/constants';
import { removeReferralCodeFromCookies, setCookie } from 'src/helpers';
import { setUser } from 'src/store/user.slice';
import { IAuthResponse, ICustomAxiosError, IMicrosoftSignUpRequest } from 'src/types';

export const useMicrosoftAuth = (
  data: IMicrosoftSignUpRequest,
  isSignup: boolean,
  options?: UseQueryOptions<IAuthResponse, ICustomAxiosError>,
) => {
  const dispatch = useDispatch();

  return useQuery<IAuthResponse, ICustomAxiosError>(
    ['microsoft-auth', isSignup, data.code],
    () => authUsingMicrosoft(data, isSignup),
    {
      enabled: !!data.code,
      retry: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      ...options,
      onSuccess: (data) => {
        if (data.redirectTo) {
          return location.replace(`${data.redirectTo}/auth/login?exchange-token=${data.authToken}`);
        }

        if (options?.onSuccess) {
          options.onSuccess(data);
        } else {
          setCookie(COOKIE_TOKEN_NAME, data.authToken);

          removeReferralCodeFromCookies();

          dispatch(setUser(data.user));
        }
      },
      onSettled: (data, error) => {
        options?.onSettled?.(data, error);
        window.history.replaceState({}, document.title);
      },
    },
  );
};
