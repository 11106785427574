import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { setUserLanguage } from 'src/api';
import { ICustomAxiosError, IUser } from 'src/types';

export const useSetUserLanguage = (options?: UseMutationOptions<void, ICustomAxiosError, string>) => {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(['set-user-language'], setUserLanguage, {
    ...options,
    onSuccess: async (data, language, context) => {
      options?.onSuccess?.(data, language, context);
      await queryClient.setQueryData<IUser | undefined>(['get-user-profile'], (data) => data && { ...data, language });
    },
  });

  return {
    setUserLanguage: mutate,
    ...rest,
  };
};
