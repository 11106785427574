import { UseFormRegister } from 'react-hook-form';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Input, Typography } from 'src/components';
import { ICreateTemplateForm } from '../TemplateCreator/TemplateCreator';

const StyledInput = styled(Input)`
  color: #333;
  height: 30px;
  line-height: 19px;
  padding: 10px;
`;

export const NameInput = ({
  label,
  register,
  name,
  required,
  ...props
}: {
  label: string;
  name: keyof ICreateTemplateForm;
  register: UseFormRegister<ICreateTemplateForm>;
  required?: boolean | string;
}) => {
  return (
    <Box marginTop="30px" marginBottom="25px" alignSelf="center">
      <Typography color="text.primary">{label}</Typography>
      <StyledInput type="text" name={name} register={register} parameters={{ required }} {...props} />
    </Box>
  );
};
