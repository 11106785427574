import { CampaignCategory, CampaignType } from 'src/enums';
import { IGetCampaign } from 'src/types';

export const getAddMoreLeadsState = (campaign: IGetCampaign, stateOptions: Record<string, string> = {}) => {
  if (
    [CampaignType.LINKEDIN_CSV_UPLOAD, CampaignType.EMAIL_CSV_UPLOAD, CampaignType.TWITTER_CSV_UPLOAD].includes(
      campaign.campaignType,
    )
  ) {
    return {
      path: `/campaign/${campaign.id}/audience`,
      state: { ...stateOptions, campaignPageType: 'csv-upload', category: campaign.category, isAddMoreLeads: true },
    };
  }

  if (campaign.category === CampaignCategory.EMAIL && campaign.campaignType === CampaignType.EMAIL_CONNECTIONS) {
    return {
      path: '/contacts/connections',
      state: {
        ...stateOptions,
        category: campaign.category,
        campaignType: campaign.campaignType,
        campaignIdToAddLeads: campaign.id,
        isLinkedinOnly: false,
        isAddMoreLeads: true,
      },
    };
  }

  if (campaign.category === CampaignCategory.TWITTER && campaign.campaignType === CampaignType.TWITTER_CONNECTIONS) {
    return {
      path: '/contacts/connections',
      state: {
        ...stateOptions,
        category: campaign.category,
        campaignType: campaign.campaignType,
        campaignIdToAddLeads: campaign.id,
        isLinkedinOnly: false,
        isAddMoreLeads: true,
      },
    };
  }

  return {
    path: `/campaign/add-leads/${campaign.category}`,
    state: { ...stateOptions, campaignIdToAddLeads: campaign.id, isAddMoreLeads: true },
  };
};
