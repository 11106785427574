import dayjs from 'dayjs';
import { Box } from '@mui/material';

import { Button, Typography } from 'src/components';
import { useGetBillingInfo, useUnpauseSubscription } from 'src/reactQueries';

export const UnpauseSubscription = () => {
  const { billingInfo } = useGetBillingInfo();
  const { unpauseSubscription, isLoading } = useUnpauseSubscription();

  return (
    <Box p="20px" display="flex" alignItems="center" bgcolor="#fff" borderRadius="4px">
      <Typography color="primary.wlLight" semibold mr="20px">
        Subscription will resume on:
      </Typography>

      <Typography color="violet.dark" mr="auto">
        {billingInfo?.stripeSubscriptionObject?.pause_collection?.resumes_at &&
          dayjs.unix(billingInfo?.stripeSubscriptionObject.pause_collection.resumes_at).format('MM/DD/YYYY')}
      </Typography>

      <Button onClick={() => unpauseSubscription()} processing={isLoading}>
        Resume Subscription Now
      </Button>
    </Box>
  );
};
