import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { HeaderContainer, HeaderTabLink, HeaderTitle, TabsContainer } from 'src/components';
import { useCompanyTheme } from 'src/hooks';
import { AppLayout } from 'src/layouts';

const ContentWrapper = styled(Box)`
  box-sizing: border-box;
  min-width: 760px;
  width: 66%;
  margin: 20px 15px;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
`;

const TABS = [
  { name: 'Add New Lead', route: 'add-new-lead' },
  { name: 'Get Campaigns', route: 'get-campaigns' },
  { name: 'Get Leads', route: 'get-leads' },
  { name: 'Get Connections', route: 'get-connections' },
  { name: 'Get Replies', route: 'get-replies' },
  { name: 'Get Team Members', route: 'get-team-members' },
  { name: 'Get Member Connections', route: 'get-member-connections' },
  { name: 'Get Last Actions', route: 'get-last-actions' },
];

export const WebhookLayout = () => {
  const { shortCompanyName } = useCompanyTheme();

  return (
    <AppLayout>
      <Box minWidth="1170px" bgcolor="light.light">
        <HeaderContainer>
          <HeaderTitle>{shortCompanyName} Webhooks</HeaderTitle>

          <TabsContainer>
            {TABS.map(({ name, route }) => {
              const to = `/documentation/webhook/${route}`;

              return (
                <HeaderTabLink key={to} to={to}>
                  {name}
                </HeaderTabLink>
              );
            })}
          </TabsContainer>
        </HeaderContainer>

        <ContentWrapper>
          <Outlet />
        </ContentWrapper>
      </Box>
    </AppLayout>
  );
};
