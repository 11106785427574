import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setFetchingTrend } from 'src/store/trends.slice';
import { useSocket } from './useSocket';

export const useTrendsFetchingBySocket = () => {
  const socket = useSocket();
  const dispatch = useDispatch();

  useEffect(() => {
    socket.on('trend', ({ trendId }: { trendId: number }) => {
      // setting All trends tab to be available
      dispatch(setFetchingTrend({ [trendId]: false, [-1]: false }));
    });

    return () => {
      socket.off('trend');
    };
  }, []);
};
