import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import {
  Spinner,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
  Typography,
} from 'src/components';
import { Alert } from 'src/containers/Campaigns/_components';
import { CampaignCategory } from 'src/enums';
import { useAppSelector, useCampaignsListProps } from 'src/hooks';
import { useGetCampaigns } from 'src/reactQueries';
import { TStatTableParams, updateStatTableParams } from 'src/store/campaign.slice';
import { TCampaignRunState, TCampaignsSortField, TIntervalFilter, TSortOrder } from 'src/types';
import { CampaignsTableRow } from './CampaignsTableRow';

interface ICampaignsTableProps {
  interval?: TIntervalFilter;
  category: CampaignCategory;
  runState?: TCampaignRunState;
  campaignStatus: 'active' | 'archived';
  campaignTab: 'active' | 'paused' | 'archived';
}

export const CampaignsTable = ({ interval, category, runState, campaignStatus, campaignTab }: ICampaignsTableProps) => {
  const dispatch = useDispatch();
  const { page, perPage, sortField, sortOrder } = useAppSelector(
    ({ campaign }) => campaign.statsParams[category][campaignTab],
  );

  const onChangeParams = (params: Partial<TStatTableParams>) =>
    dispatch(updateStatTableParams({ status: campaignTab, category, params }));

  const { title, columnConfig } = useCampaignsListProps(category);
  const { data, isLoading, isFetching, error } = useGetCampaigns({
    status: campaignStatus,
    category,
    runState,
    page,
    perPage,
    sortOrder,
    sortField,
    filter: interval,
  });
  const isShowAlert = [511, 401].includes(error?.statusCode ?? 0);
  const { campaigns = [], total = 0 } = data || {};

  const isActive = campaignStatus === 'active';

  useEffect(() => {
    if (!campaigns?.length && page * perPage - total === perPage) {
      onChangeParams({ page: page - 1 || 1 });
    }
  }, [campaigns?.length]);

  const onSortByField = (sortField: TCampaignsSortField, sortOrder: TSortOrder) =>
    onChangeParams({ sortField, sortOrder });

  if (isLoading || (!campaigns?.length && (isFetching || !!total))) {
    return <Spinner />;
  }

  if (!campaigns?.length) {
    return null;
  }

  return (
    <Box pb="20px">
      {isShowAlert && <Alert p="15px">{error?.message}</Alert>}
      <Typography py="15px" px="20px" fontSize="16px" semibold>
        {title}
      </Typography>

      <Table processing={isLoading}>
        <TableHead>
          <TableRow>
            <TableHeadCell width="70px" empty={!isActive}>
              Status
            </TableHeadCell>

            <TableHeadCell
              width="250px"
              name="name"
              sorting={{
                sortOrder,
                sortField,
                onSortFieldChange: onSortByField,
              }}
            >
              Name
            </TableHeadCell>

            <TableHeadCell width="100px" justifyContent="flex-end">
              Leads
            </TableHeadCell>

            {columnConfig?.map(({ label, columnTooltip }, index) => (
              <TableHeadCell key={index} tooltip={columnTooltip} width="125px" justifyContent="flex-end">
                {label}
              </TableHeadCell>
            ))}
            <TableHeadCell width="100px" sticky />
          </TableRow>
        </TableHead>

        <TableBody placeholder={!campaigns.length && `No ${category} campaign yet.`}>
          {campaigns?.map((campaign) => {
            return <CampaignsTableRow key={campaign.id} campaign={campaign} campaignStatus={campaignStatus} />;
          })}
        </TableBody>
      </Table>

      <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={onChangeParams} />
    </Box>
  );
};
