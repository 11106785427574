import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getCampaignZapierLeads } from 'src/api';
import { ICampaignZapierLeads } from 'src/types';

export const useGetCampaignZapierLeads = <TData = ICampaignZapierLeads>(
  campaignId: number,
  options?: UseQueryOptions<ICampaignZapierLeads, AxiosError, TData>,
) => {
  return useQuery<ICampaignZapierLeads, AxiosError, TData>(
    ['get-campaign-zapier-leads', campaignId],
    () => getCampaignZapierLeads(campaignId),
    options,
  );
};
