import { flatMap } from 'lodash';
import { useMemo } from 'react';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { getLIConversations } from 'src/api';
import { LIConversationFilterType } from 'src/enums';
import { setConversationPictures } from 'src/store/conversation.slice';
import { ICustomAxiosError, ILIConversation } from 'src/types';

export const useGetLIConversations = (
  {
    filterType,
    query,
  }: {
    filterType: LIConversationFilterType;
    query: string;
  },
  options?: UseInfiniteQueryOptions<ILIConversation[], ICustomAxiosError, ILIConversation[]>,
) => {
  const dispatch = useDispatch();
  const unEnableTags = [LIConversationFilterType.TAGGED, LIConversationFilterType.REPLIES];
  const { data, ...rest } = useInfiniteQuery<ILIConversation[], ICustomAxiosError, ILIConversation[]>(
    ['get-li-conversations', filterType, query],
    ({ pageParam }) => getLIConversations({ filterType, query, createdBefore: pageParam }),
    {
      ...options,
      enabled: !unEnableTags.includes(filterType),
      getNextPageParam: (lastPage) => {
        return [...lastPage].pop()?.lastActivityAt;
      },
      onSuccess: (resp) => {
        options?.onSuccess?.(resp);
        const array = resp?.pages?.length ? flatMap(resp.pages) : [];

        dispatch(
          setConversationPictures(
            array.reduce(
              (acc, { entityUrn, imageUrl }) => ({
                ...acc,
                [entityUrn]: imageUrl,
              }),
              {},
            ),
          ),
        );
      },
    },
  );

  const conversations = useMemo(() => {
    return data?.pages.flat() || [];
  }, [data]);

  return { conversations, ...rest };
};
