const ALF_DOMAINS = (process.env.REACT_APP_ALF_DOMAIN_NAMES || '').split(',');
export const APP_DOMAIN = window.location.hostname.split('.').slice(1).join('.') || '';
export const APP_HOSTNAME = window.location.hostname || '';

// !APP_DOMAIN stands for localhost
export const IS_ALFRED_DOMAIN = ALF_DOMAINS.includes(APP_DOMAIN) || !APP_DOMAIN;

export const BASE_API_URL = process.env.REACT_APP_API_URL || '';
export const BASE_WL_API_ULR = process.env.REACT_APP_WL_API_URL || '';
export const API_URL = `${IS_ALFRED_DOMAIN ? BASE_API_URL : BASE_WL_API_ULR}/api/v1`;
export const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';
export const STRIPE_PUBLIC_KEY_OLD = process.env.REACT_APP_STRIPE_PUBLIC_KEY_OLD || '';
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || '';
export const GOOGLE_AUTH_CLIENT_ID = process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID || '';
export const FACEBOOK_CLIENT_ID = process.env.REACT_APP_FACEBOOK_CLIENT_ID || '';
export const LANDING_URL = process.env.REACT_APP_LANDING_URL || '';

export const DEFAULT_PAGE = 1;
export const DEFAULT_PER_PAGE = 25;
export const DEFAULT_PAGINATION_OPTIONS = [10, 25, 50, 100, 250, 500];

// TODO: have to be removed anyway, left here because of lack of time
export const IS_SHOW_WHITE_LABEL = true;
