import { io, Socket } from 'socket.io-client';
import Cookies from 'universal-cookie';

import { BASE_API_URL, COOKIE_TOKEN_NAME } from 'src/constants';

let socket: Socket;
const cookies = new Cookies();

export const useSocket = () => {
  if (!socket) {
    socket = io(BASE_API_URL, {
      secure: true,
      transports: ['websocket'],
      auth: {
        token: cookies.get(COOKIE_TOKEN_NAME),
      },
    });
  }

  return socket;
};
