import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { BASE_WL_API_ULR } from 'src/constants';
import { useWhiteLabel } from 'src/hooks';
import { Code, Response, Title } from '../_components';
import { GET_MEMBER_CONNECTIONS, UNAUTHORIZED } from '../_constants';

export const WebhookDocMemberConnections = () => {
  const {
    whitelabel: { isAlfredDomain },
  } = useWhiteLabel();

  return (
    <Box>
      <Typography lineHeight="14px">
        This webhook can be used get the team member connections, Connection details will be returned by this webhook.
        Array include the objects and object includes all the information of the connections of the team member.
      </Typography>
      <Typography>
        <b>Note:</b> This webhook optionally accepts custom date range, pagination and custom timezone (which team owner
        can get from previous api)
      </Typography>
      <Title>
        Request URL <b>GET</b>
      </Title>
      <Code>
        {isAlfredDomain ? 'https://meetalfred.com' : BASE_WL_API_ULR}
        /api/integrations/webhook/get_member_connections?webhook_key=:webhook_key&date_from=:date_from(yyyy-mm-dd)&date_to=:date_to(yyyy-mm-dd)&timezone_name=:timezone_name&timezone_offset=:timezone_offset&per_page=10&page=0
      </Code>
      <Typography>
        <b>Note: </b>All params are optional except webhook key and by default it will return today's connections, page
        starts with 0.
      </Typography>
      <Title>Responses</Title>
      <Response type="success" title="200 OK" responses={GET_MEMBER_CONNECTIONS} />
      <Response mt="20px" type="error" title="401 Unauthorized" responses={UNAUTHORIZED} />
    </Box>
  );
};
