import { getAllCountries } from 'countries-and-timezones';
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { ReactComponent as LinkedIn } from 'src/assets/icons/linkedIn.svg';
import { ReactComponent as Location } from 'src/assets/icons/location-icon.svg';
import { Button, Typography } from 'src/components';
import { useGetUserAccount } from 'src/reactQueries';

interface LinkedInAccountProps {
  isConnected?: boolean;
  tryDelete: () => void;
  isLoading: boolean;
}

const Wrapper = styled(Box)<{ visible?: boolean }>`
  display: flex;
  align-items: center;
  opacity: ${({ visible }) => (visible ? 1 : 0.5)}};
`;

const LinkedInIconBlock = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 10px;
`;

const Divider = styled(Box)`
  height: 22px;
  width: 1px;
  background-color: #03363d33;
`;

export const LinkedInAccount = ({ tryDelete, isLoading, isConnected = false }: LinkedInAccountProps) => {
  const { userMe } = useGetUserAccount();

  const country = useMemo(() => {
    return userMe ? Object.entries(getAllCountries()).find(([code]) => code === userMe?.country)?.[1]?.name : '';
  }, [userMe?.country]);

  return (
    <Box
      display="flex"
      alignItems="center"
      p="17px 12px"
      bgcolor="gray.50"
      borderRadius="6px"
      gap="24px"
      justifyContent="space-between"
      minWidth="max-content"
    >
      <Wrapper visible={isConnected}>
        <LinkedInIconBlock>
          <LinkedIn />
        </LinkedInIconBlock>
        <Box>
          <Typography color="gray.800" mb="5px" crop>
            {userMe?.linkedInEmail}
          </Typography>
          <Typography display="flex" alignItems="center" color="gray.800" gap="5px" crop>
            <>
              <Location />
              {country}
            </>
          </Typography>
        </Box>
      </Wrapper>
      <Divider />
      <Button variant={isConnected ? 'white' : 'warning'} disabled={isLoading} onClick={tryDelete}>
        Remove Account
      </Button>
    </Box>
  );
};
