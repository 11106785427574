import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { Button, Typography } from 'src/components';
import { closeModal } from 'src/store/modal.slice';
import { IModalProps } from 'src/types';
import { ModalBody } from '../_components';

export const ModalInvite = ({ params }: IModalProps) => {
  const dispatch = useDispatch();
  const { description = '', buttonText = '', onConfirm } = params ?? {};

  const confirmHandler = () => {
    onConfirm && onConfirm();
    dispatch(closeModal());
  };

  return (
    <>
      <ModalBody pb="24px !important">
        <Typography color="violet.dark">{description}</Typography>
        <Box display="flex" justifyContent="flex-end" mt="20px">
          <Button onClick={confirmHandler}>{buttonText}</Button>
        </Box>
      </ModalBody>
    </>
  );
};
