import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { toggleIncludeUnsubscribeEmailLink } from 'src/api';
import { showToast } from 'src/store';
import { IUser } from 'src/types';

export const useToggleIncludeUnsubscribeEmailLink = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const getUserProfileQueryKey = ['get-user-profile'];

  const { mutate, ...rest } = useMutation(
    ['toggle-include-unsubscribe-email-link'],
    toggleIncludeUnsubscribeEmailLink,
    {
      onMutate: async () => {
        await queryClient.cancelQueries(getUserProfileQueryKey);

        const prevData = queryClient.getQueryData(getUserProfileQueryKey);

        queryClient.setQueryData<IUser | undefined>(getUserProfileQueryKey, (data) => {
          if (data) {
            return {
              ...data,
              includeUnsubscribeEmailLink: !data.includeUnsubscribeEmailLink,
            };
          }

          return data;
        });

        return { prevData };
      },
      onError: (err, _vars, context) => {
        queryClient.setQueryData(getUserProfileQueryKey, context?.prevData);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return {
    toggleIncludeUnsubscribeEmailLink: mutate,
    ...rest,
  };
};
