import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Typography } from 'src/components';

export const PreviewContainer = styled(Box)`
  position: relative;
  margin: 0 12px 20px;
  box-shadow:
    0px -1px 1px rgb(0 0 0 / 8%),
    1px 0px 1px rgb(0 0 0 / 8%),
    -1px 0px 1px rgb(0 0 0 / 8%),
    0px 1px 1px rgb(0 0 0 / 8%);
  background: white;
  border-radius: 5px;
  padding: 12px;
  font-size: 16px;
`;

export const PreviewSocialIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 5px;
  top: 5px;
  height: 30px;
  width: 30px;
`;

export const SocialAvatarContainer = styled(Box)`
  position: relative;
`;

export const SocialAvatar = styled.img`
  border-radius: 50%;
  width: 48px;
  height: 48px;
`;

export const UserDataContainer = styled(Box)`
  font-size: 16px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.6);
`;

export const UserName = styled(Typography)`
  font-weight: 600;
  color: black;
  margin-bottom: 3px;
`;

export const AttachmentImage = styled.img`
  max-height: 1350px;
  max-width: 100%;
  border-radius: 10px;
  display: block;
  box-shadow: 0 0 1px #2f3336;
  margin-top: 10px;
`;

export const AttachmentVideo = styled.video`
  max-height: 1350px;
  max-width: 100%;
  border-radius: 10px;
  display: block;
  box-shadow: 0 0 1px #2f3336;
  margin-top: 10px;
`;
