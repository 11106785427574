import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { cancelSubscription } from 'src/api';
import { IBillingInfo, ICancelSubscriptionRequest, ICustomAxiosError } from 'src/types';

export const useCancelSubscription = (
  options?: UseMutationOptions<IBillingInfo, ICustomAxiosError, ICancelSubscriptionRequest>,
) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading, ...rest } = useMutation(['cancel-subscription'], cancelSubscription, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      await queryClient.invalidateQueries(['get-billing-info']);
      await queryClient.invalidateQueries(['get-team-by-id']);
    },
  });

  return {
    cancelSubscription: mutate,
    isCancelLoading: isLoading,
    ...rest,
  };
};
