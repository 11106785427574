import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { BASE_WL_API_ULR } from 'src/constants';
import { useCompanyTheme, useWhiteLabel } from 'src/hooks';
import { Code, Response, Title } from '../_components';
import { GET_REPLIES, UNAUTHORIZED } from '../_constants';

export const WebhookDocReplies = () => {
  const { shortCompanyName } = useCompanyTheme();
  const {
    whitelabel: { isAlfredDomain },
  } = useWhiteLabel();

  return (
    <Box>
      <Typography lineHeight="14px">
        This webhook can be used get the replies, you can get your {shortCompanyName} replies by using this webhook. It
        will return the data in actions array. Array include the objects and object includes all the information of the
        reply including associated <b>campaign</b> and <b>reply_detected_on</b> as well.
      </Typography>
      <Title>
        Request URL <b>GET</b>
      </Title>
      <Code>
        {isAlfredDomain ? 'https://meetalfred.com' : BASE_WL_API_ULR}
        /api/integrations/webhook/new-reply-detected?webhook_key=:webhook_key&page=0&per_page=1
      </Code>
      <Typography>
        <b>Note: </b>here page and per_page are optional params, page starts with 0.
      </Typography>

      <Title>Responses</Title>
      <Response type="success" title="200 OK" responses={GET_REPLIES} />
      <Response mt="20px" type="error" title="401 Unauthorized" responses={UNAUTHORIZED} />
    </Box>
  );
};
