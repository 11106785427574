import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { HeaderButton } from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features, ModalTypes } from 'src/enums';
import { useTeamPlan } from 'src/hooks';
import { openModal } from 'src/store';

export const ButtonExportCsv = ({
  entityUrns,
  isExportDisabled,
}: {
  entityUrns: string[];
  isExportDisabled: boolean;
}) => {
  const dispatch = useDispatch();

  const location = useLocation();

  const { checkFeature } = useTeamPlan();
  const isExportCsvAllowed = checkFeature(Features.exportCSV);

  const openExportContactsModal = (params: {
    isLeadsExport?: boolean;
    isConnectionsExport?: boolean;
    isBlacklist?: boolean;
  }) => {
    dispatch(
      openModal({
        type: ModalTypes.EXPORT_CONTACTS,
        params: {
          isBlacklist: params.isBlacklist,
          isLeadsExport: params.isLeadsExport,
          isConnectionsExport: params.isConnectionsExport,
        },
      }),
    );
  };

  if (!isExportCsvAllowed) {
    return (
      <HeaderButton disabled tooltip={{ title: PLAN_TOOLTIPS.professional }}>
        Export {entityUrns.length || 'All'}
      </HeaderButton>
    );
  }

  return (
    <>
      <HeaderButton
        disabled={isExportDisabled}
        onClick={() => {
          const path = location.pathname;

          if (path.includes('/contacts/leads')) {
            openExportContactsModal({ isLeadsExport: true });
          } else if (path.includes('/contacts/blacklist/leads')) {
            openExportContactsModal({ isLeadsExport: true, isBlacklist: true });
          } else if (path.includes('/contacts/connections')) {
            openExportContactsModal({ isConnectionsExport: true });
          } else if (path.includes('/contacts/blacklist/connections')) {
            openExportContactsModal({ isConnectionsExport: true, isBlacklist: true });
          }
        }}
      >
        Export {entityUrns.length || 'All'}
      </HeaderButton>
    </>
  );
};
