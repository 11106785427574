import { Navigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { LANDING_URL } from 'src/constants';
import { useAppSelector, useExchangeToken } from 'src/hooks';
import { AppLoading } from '../AppLayout';
import { AuthHeader } from './_components';

interface IAuthLayoutProps {
  isSignUp?: boolean;
  children: JSX.Element | JSX.Element[];
}

export const AuthLayout = ({ isSignUp, children }: IAuthLayoutProps) => {
  const { isAuth } = useAppSelector((state) => state.user);

  const { isLoading } = useExchangeToken();

  if (isLoading) {
    return <AppLoading />;
  }

  if (isAuth) {
    if (isSignUp) {
      window.location.href = `${LANDING_URL}/registration-completed`;

      return null;
    }

    return <Navigate to="/dashboard" />;
  }

  return (
    <Box width="100%" minHeight="100vh" bgcolor="#fff" className="form-side">
      <AuthHeader isSignUp={isSignUp} />
      {children}
    </Box>
  );
};
