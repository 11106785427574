import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateUserSignature } from 'src/api';
import { showToast } from 'src/store';
import { ICustomAxiosError, IUserSignature } from 'src/types';

export const useUpdateUserSignature = (
  options?: UseMutationOptions<IUserSignature, ICustomAxiosError, IUserSignature>,
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const getUserSignatureQueryKey = ['user-signature'];

  const { mutate, ...rest } = useMutation(['update-user-signature'], updateUserSignature, {
    ...options,
    onMutate: async (variables) => {
      options?.onMutate?.(variables);
      await queryClient.cancelQueries(getUserSignatureQueryKey);

      const prevData = queryClient.getQueryData(getUserSignatureQueryKey);

      queryClient.setQueryData<IUserSignature | undefined>(
        getUserSignatureQueryKey,
        (data) => data && { ...data, ...variables },
      );

      return { prevData };
    },
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      await queryClient.refetchQueries(getUserSignatureQueryKey);
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);

      queryClient.setQueryData(getUserSignatureQueryKey, context?.prevData);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return {
    updateUserSignature: mutate,
    ...rest,
  };
};
