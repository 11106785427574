import styled from '@emotion/styled';

import { TableCell, TableRow } from 'src/components';
import { REGEXP_VALID_EMAIL_EXTENDED } from 'src/constants';
import { ITeamMember, TUserStatus } from 'src/types';
import { ManageUser, StatusBadged } from '../../_components';

interface IMembersTableRowProps extends Pick<ITeamMember, 'role'> {
  userStatus: TUserStatus;
  userId?: number;
  name: string;
  email: string;
}

const StyledTableCell = styled(TableCell)`
  border-bottom: none;
  color: ${({ theme }) => theme.palette.gray.dark};
`;

export const MembersTableRow = ({ userId, email, name, role, userStatus }: IMembersTableRowProps) => {
  return (
    <TableRow sx={{ minWidth: 960 }}>
      <StyledTableCell>{name}</StyledTableCell>
      <StyledTableCell>{REGEXP_VALID_EMAIL_EXTENDED.test(email) ? email : 'User without email'}</StyledTableCell>

      <StyledTableCell>{role}</StyledTableCell>

      <StyledTableCell>
        <StatusBadged width="110px" status={userStatus} />
      </StyledTableCell>
      <StyledTableCell>
        <ManageUser userId={userId ?? -1} userStatus={userStatus} />
      </StyledTableCell>
    </TableRow>
  );
};
