import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  Button,
  HeaderContainer,
  HeaderDescription,
  HeaderTabLink,
  HeaderTitle,
  HeaderVideo,
  Link,
  TabsContainer,
  Tooltip,
} from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features, ModalTypes } from 'src/enums';
import { useTeamPlan, useWhiteLabel } from 'src/hooks';
import { useGetUserAccount, useRefreshTrendPosts } from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';
import { ITrend } from 'src/types';

interface ITrendLayoutProps {
  trends: ITrend[] | undefined;
  selectedTrendId: number;
}

const tabs = [{ name: 'All', path: 'trends' }];

export const TrendLayout = ({ trends, selectedTrendId }: ITrendLayoutProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { whitelabel } = useWhiteLabel();
  const { userMe } = useGetUserAccount();
  const { isTrialStarted, checkFeature } = useTeamPlan();
  const { refreshTrendPosts, isLoading } = useRefreshTrendPosts();

  const isLinkedInConnected = userMe?.isLinkedInLoginValid;
  const areButtonsDisabled = !checkFeature(Features.trendCreation) && !isTrialStarted;

  const currentTrend = useMemo(() => trends?.find((trend) => trend.id === selectedTrendId), [selectedTrendId, trends]);
  const createTrendTooltip = useMemo(() => {
    if (!isLinkedInConnected) {
      return 'Please connect your Linkedin account.';
    }
    if (areButtonsDisabled) {
      return PLAN_TOOLTIPS.professional;
    }

    return 'You can only create 10 trends';
  }, [isLinkedInConnected, areButtonsDisabled]);

  const onCreateTrendClick = () => {
    dispatch(
      openModal({
        headerText: 'Create trend',
        type: ModalTypes.CREATE_TREND,
        closable: false,
      }),
    );
  };

  const onEditTrendClick = () => {
    if (currentTrend) {
      dispatch(
        openModal({
          type: ModalTypes.CREATE_TREND,
          headerText: 'Edit trend',
          closable: false,
          params: {
            trend: currentTrend,
          },
        }),
      );
    }
  };

  const onDeleteTrendClick = () => {
    if (currentTrend) {
      dispatch(
        openModal({
          closable: false,
          type: ModalTypes.DELETE_TREND,
          params: {
            trend: currentTrend,
          },
        }),
      );
    }
  };

  const refreshTrend = () => {
    refreshTrendPosts(selectedTrendId);
  };

  return (
    <HeaderContainer>
      <Box display="flex">
        <HeaderTitle>Trending Topics</HeaderTitle>
        <Box ml="auto" mt="20px" display="flex" gap="20px">
          {selectedTrendId !== -1 && (
            <>
              <Box>
                <Button variant="warning" onClick={onDeleteTrendClick}>
                  Delete
                </Button>
              </Box>
              <Box>
                <Button variant="gray" onClick={onEditTrendClick}>
                  Edit
                </Button>
              </Box>
            </>
          )}

          {isLinkedInConnected && !!trends?.length && (
            <Tooltip title={areButtonsDisabled ? PLAN_TOOLTIPS.professional : 'Get Latest Posts.'}>
              <Box>
                <Button variant="gray" processing={isLoading} disabled={areButtonsDisabled} onClick={refreshTrend}>
                  Refresh
                </Button>
              </Box>
            </Tooltip>
          )}

          <Tooltip data-testid="create-button-tooltip" title={createTrendTooltip}>
            <Box>
              <Button onClick={onCreateTrendClick} disabled={!isLinkedInConnected || areButtonsDisabled}>
                Create Trend
              </Button>
            </Box>
          </Tooltip>
        </Box>
      </Box>
      <HeaderDescription>
        Discover and engage with the latest LinkedIn articles on your favorite subjects to leverage valuable insights.
        {whitelabel.isWhiteLabelUser || (
          <>
            <Link
              leftIcon
              underline
              external
              openNewTab
              to="https://meetalfred.com/help/en/articles/5396514-create-trends-interact-with-posts"
            >
              Learn how it works
            </Link>
            <HeaderVideo src="https://player.vimeo.com/video/622842287" />
          </>
        )}
      </HeaderDescription>
      {!!trends?.length && (
        <TabsContainer>
          {tabs.map((tab) => {
            const to = `/${tab.path}`;
            const isActive = !searchParams.has('trendId');

            return (
              <HeaderTabLink key={tab.path} isActive={isActive} pathname={location.pathname + location.search} to={to}>
                {tab.name}
              </HeaderTabLink>
            );
          })}
          {trends.map((trend) => {
            const to = `/trends?trendId=${trend.id}`;
            const isActive = String(searchParams.get('trendId')) === String(trend.id);

            return (
              <HeaderTabLink key={to} isActive={isActive} pathname={location.pathname + location.search} to={to}>
                {trend.term}
              </HeaderTabLink>
            );
          })}
        </TabsContainer>
      )}
    </HeaderContainer>
  );
};
