import { useTheme } from '@emotion/react';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ReactComponent as ChatBubble } from 'src/assets/icons/chat-bubble.svg';
import { Checkbox, Link, ProfileAvatar, TableCell, TableRow } from 'src/components';
import { IConnectionColumnsPosition, IConvertedConnection } from 'src/types';
import { ToggleConnection } from '../_components';
import { ConnectionsListCell } from './ConnectionsListCell';

interface IConnectionsListItemProps {
  connection: IConvertedConnection;
  onItemClick?: (entityUrn: string) => void;
  onCheckboxClick?: (connection: IConvertedConnection) => void;
  columns?: IConnectionColumnsPosition[];
  isSelected?: boolean;
}

export const ConnectionsListItem = ({
  connection,
  onItemClick,
  onCheckboxClick,
  columns = [],
  isSelected,
}: IConnectionsListItemProps) => {
  const { palette } = useTheme();

  const connectionEntityUrn = String(connection.entityUrn);

  return (
    <TableRow
      data-testid="row"
      checkedBgColor={palette.primary.main}
      hoverBgColor={({ light }) => light.main}
      checked={isSelected}
      onClick={() => onItemClick?.(connectionEntityUrn)}
      cursor="pointer"
    >
      <TableCell preventClick>
        <Checkbox data-testid="checkbox" checked={isSelected} onChange={() => onCheckboxClick?.(connection)} />
      </TableCell>
      <TableCell>
        <ProfileAvatar avatarSrc={connection.picture} firstLatter={(connection.name ?? '')[0]} />
      </TableCell>
      {columns?.slice(0, 7).map((column) => {
        return (
          <ConnectionsListCell key={`${connectionEntityUrn}-${column.key}`} column={column} connection={connection} />
        );
      })}
      <TableCell
        contentStyle={{ display: 'flex', gap: '16px', marginLeft: 'auto', alignItems: 'center' }}
        preventClick
        sticky
      >
        <Link external openNewTab to={`https://www.linkedin.com/in/${connection.publicIdentifier}`} fontSize="20px">
          <FontAwesomeIcon icon={faLinkedin} color={connection.isPremium ? '#af9b62' : '#0072b1'} />
        </Link>
        <Link to={`${location.pathname}?person=${connection.entityUrn}&tab=messages`}>
          <ChatBubble />
        </Link>
        <ToggleConnection isExcluded={connection.isExcluded} entityUrn={connection.entityUrn} />
      </TableCell>
    </TableRow>
  );
};
