import { useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import styled from '@emotion/styled';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { NotificationTypes } from 'src/enums';
import { useAppSelector, useCompanyTheme, useTeamPlan, useWhiteLabel } from 'src/hooks';
import { useGetUserAccount } from 'src/reactQueries';
import { removeNotification } from 'src/store/notification.slice';
import { TNotification } from 'src/types';
import { TrialBar } from './TrialBar';

const StyledSlider = styled(Slider)`
  height: 40px;
  min-height: 40px;
  max-width: 100vw;
  position: relative;
`;

const Wrapper = styled(Box)`
  max-height: 40px;
`;

const Arrow = styled(Box)`
  display: inline-flex;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  color: white;
  height: 16px;
  z-index: 1;
  cursor: pointer;
`;

const CloseText = styled.span`
  padding: 0 12px;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    font-weight: 600;
  }
`;

const NotificationBlock = styled(Box)`
  height: 40px;
  padding: 5px 15px;
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  font-family: 'ProximaSoft', sans-serif;
  font-weight: 300;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  box-sizing: border-box;

  & a {
    font-family: inherit;
    text-decoration: underline;
  }
`;

const NotificationWarn = styled(NotificationBlock)(({ theme: { palette } }) => ({
  backgroundColor: palette.notification.warningBg,
  color: palette.notification.warningText,

  '& a': {
    color: palette.notification.warningText,
  },
}));

const NotificationError = styled(NotificationBlock)(({ theme: { palette } }) => ({
  backgroundColor: palette.notification.errorBg,
  color: palette.notification.errorText,

  '& a': {
    color: palette.notification.errorText,
  },
}));

export const Notification = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const sliderRef = useRef<Slider>(null);

  const { userMe } = useGetUserAccount();
  const { isStarter, isTrialStarted } = useTeamPlan();
  const {
    whitelabel: { isWhiteLabelUser },
  } = useWhiteLabel();
  const { shortCompanyName, companyName } = useCompanyTheme();

  const { notifications } = useAppSelector((state) => state.notification);
  const { profile, isAppDeactivated } = useAppSelector((state) => state.user);

  const renderNotification = ({ content, type, id }: TNotification) => {
    const onCloseNotification = () => dispatch(removeNotification({ notificationId: id }));

    const Close = () => <CloseText onClick={onCloseNotification}>(Close)</CloseText>;

    if (!isAppDeactivated) {
      switch (type) {
        case NotificationTypes.OUTSIDE_SCHEDULED:
          return (
            <NotificationWarn>
              Campaigns Paused. Outside of scheduled <Link to="/settings/work-hours">work hours.</Link> <Close />
            </NotificationWarn>
          );
        case NotificationTypes.LINKEDIN_MAX_PENDING_INVITES:
          return (
            <NotificationWarn>
              Detected more than 2,500 pending connection requests. To avoid getting your account restricted,{' '}
              <a
                href="https://www.linkedin.com/mynetwork/invitation-manager/sent/"
                target="_blank"
                rel="noopener noreferrer"
              >
                please withdraw to less than 500
              </a>
              . Invitations are disabled until this is resolved.
            </NotificationWarn>
          );
        case NotificationTypes.LINKEDIN_ERROR:
          return (
            <NotificationError>
              There's something wrong connecting your Linkedin account. Please go to{' '}
              <Link to="/integrations/linkedin">integrations settings</Link> to check the issue.
              <Close />
            </NotificationError>
          );
        case NotificationTypes.LIMITED_CONNECTIONS_REQUESTS:
          return (
            <NotificationWarn>
              {isStarter ? (
                <>
                  Your LinkedIn Connection Requests Are Limited. Consider{' '}
                  <Link to="/billing/edit">Upgrading Your Plan</Link> to Unlock Alternatives.
                </>
              ) : (
                <>
                  Limited LinkedIn Connection Requests Detected. While {companyName} works on bypassing limits, consider
                  exploring Multichannel Alternatives to amplify your impact.
                </>
              )}
              <Close />
            </NotificationWarn>
          );
        case NotificationTypes.LINKEDIN_PASSWORD_RESET:
          return (
            <NotificationError>
              Action Required:{' '}
              <a
                href="https://www.linkedin.com/mypreferences/d/change-password"
                target="_blank"
                rel="noopener noreferrer"
              >
                Reset Your LinkedIn Password.
              </a>{' '}
              Please reset your LinkedIn password and then proceed to reconnect {shortCompanyName}.
            </NotificationError>
          );
        case NotificationTypes.LIMIT_OF_LI_CONNECTIONS:
          return (
            <NotificationWarn>
              You are about to reach LinkedIn’s limit on number of connections. This can affect your ability to send new
              invitations.
              <Close />
            </NotificationWarn>
          );
        case NotificationTypes.BOT_RECONNECT_STATUS:
          return (
            userMe?.cloudBotStatus?.reconnectStatus && (
              <NotificationWarn>
                {userMe?.cloudBotStatus?.reconnectStatus.replace('{appName}', shortCompanyName)}
                <Close />
              </NotificationWarn>
            )
          );
        case NotificationTypes.OUTLOOK_INTEGRATION_ERROR:
          return (
            <NotificationWarn>
              Oops! It looks like there’s a problem with your{' '}
              <Link to="/integrations/email">Outlook email integration</Link>. Just disconnect and then hit the connect
              button to set things right again.
              <Close />
            </NotificationWarn>
          );
        case NotificationTypes.LI_INVITES_RESTRICTION:
          return (
            <NotificationWarn>
              Hey There! We've noticed a limit with connection request messages.{' '}
              {isWhiteLabelUser ? (
                'Check your campaign activity to learn how to bypass your messaging limits.'
              ) : (
                <>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to="https://help.meetalfred.com/en/articles/8688481-master-linkedin-s-connection-request-messages-changes"
                  >
                    Click here
                  </Link>{' '}
                  to learn how to bypass your messaging limits with {shortCompanyName}.
                </>
              )}
            </NotificationWarn>
          );
        case NotificationTypes.FACEBOOK_INTEGRATION_ERROR:
          return (
            <NotificationWarn>
              We've encountered an error. Please disconnect and reconnect your{' '}
              <Link to="/integrations/facebook">Facebook account</Link>.
              <Close />
            </NotificationWarn>
          );
        case NotificationTypes.SUBSCRIPTION_CANCELLED:
          return (
            <NotificationError>
              Your subscription is canceled. Your account will be inaccessible at the end of your billing cycle.
              <Close />
            </NotificationError>
          );
      }
    }

    switch (type) {
      case NotificationTypes.ERROR:
        return (
          <NotificationError>
            {content}
            <Close />
          </NotificationError>
        );
      case NotificationTypes.WARN:
        return (
          <NotificationWarn>
            {content}
            <Close />
          </NotificationWarn>
        );

      case NotificationTypes.DEACTIVATED:
        return (
          <NotificationError>
            Account deactivated. All campaigns are currently paused. To continue using {shortCompanyName}, please
            subscribe.
          </NotificationError>
        );

      case NotificationTypes.TRIAL:
        return <TrialBar />;
      default:
        return null;
    }
  };

  const filteredNotifications = useMemo(
    () =>
      notifications.filter((notification) => {
        if (
          isAppDeactivated &&
          [
            NotificationTypes.OUTSIDE_SCHEDULED,
            NotificationTypes.LINKEDIN_MAX_PENDING_INVITES,
            NotificationTypes.LINKEDIN_ERROR,
          ].includes(notification.type)
        ) {
          return false;
        }

        if (isTrialStarted && notification.type === NotificationTypes.LIMITED_CONNECTIONS_REQUESTS) {
          return false;
        }

        // do not show trial bar on walkthrough page and if user is not verified
        if (
          notification.type === NotificationTypes.TRIAL &&
          (pathname.includes('walkthrough') || !profile?.isEmailVerified)
        ) {
          return false;
        }

        return true;
      }),
    [profile?.isEmailVerified, pathname, notifications],
  );

  if (!filteredNotifications.length) {
    return null;
  }

  const onNextClick = () => sliderRef.current?.slickNext();
  const onPrevClick = () => sliderRef.current?.slickPrev();

  const NextArrow = () => (
    <Arrow right={25}>
      <FontAwesomeIcon onClick={onNextClick} icon={faChevronRight} />
    </Arrow>
  );

  const PrevArrow = () => (
    <Arrow left={25}>
      <FontAwesomeIcon onClick={onPrevClick} icon={faChevronLeft} />
    </Arrow>
  );

  return (
    <Box>
      <StyledSlider
        nextArrow={<NextArrow />}
        prevArrow={<PrevArrow />}
        ref={sliderRef}
        dots={false}
        infinite={true}
        speed={500}
      >
        {filteredNotifications.map((notification, index) => (
          <Box key={index}>
            <Wrapper>{renderNotification(notification)}</Wrapper>
          </Box>
        ))}
      </StyledSlider>
    </Box>
  );
};
