import { ICampaignSearchParams } from 'src/types';

class Param {
  private readonly key: string;

  private readonly paramName: string;

  private readonly codes: Array<Array<string>>;

  private readonly valueWrapper?: (value: string) => string;

  constructor(key: string, paramName?: string, codes?: Array<Array<string>>, valueWrapper?: (value: string) => string) {
    this.key = key;
    this.paramName = paramName || key;
    this.codes = codes || [];
    this.valueWrapper = valueWrapper;
  }

  // eslint-disable-next-line
  extractUrlString(params: any) {
    const value = params[this.key];
    if (!value) {
      return '';
    }

    const wrapValue = (value: string) => {
      const encodedValue = encodeURIComponent(value);

      if (this.valueWrapper) {
        return this.valueWrapper(encodedValue);
      }

      return encodedValue;
    };

    if (Array.isArray(value)) {
      const results = value?.map((item) => item?.value);

      if (results && results.length) {
        return `${this.paramName}=${wrapValue(JSON.stringify(results))}&`;
      }
    } else if (typeof value === 'object' && this.codes) {
      // value is a dictionary mapping strings to booleans, which indicate what to include in our array
      const results = this.codes?.filter((pair) => value[pair[0]]).map((pair) => pair[1]);

      if (results && results.length) {
        return `${this.paramName}=${wrapValue(JSON.stringify(results))}&`;
      }
    } else if (value.length) {
      return `${this.paramName}=${wrapValue(value)}&`;
    }

    return '';
  }
}

const SEARCH_PARAMS = [
  new Param('currentCompanies', 'currentCompany'),
  new Param('locations', 'geoUrn'),
  new Param('industries', 'industry'),
  new Param('connections', 'network', [
    ['first', 'F'],
    ['second', 'S'],
    ['third', 'O'],
  ]),
  new Param('nonprofit', 'facetNonprofitInterest', [
    ['skilledVounteering', 'volunteer'],
    ['boardService', 'nonprofitboard'],
  ]),
  new Param('pastCompanies', 'pastCompany'),
  new Param('profileLanguages', 'profileLanguage', [
    ['english', 'en'],
    ['french', 'fr'],
    ['spanish', 'es'],
    ['portuguese', 'pt'],
    ['russian', 'ru'],
    ['polish', 'pl'],
  ]),
  new Param('schools', 'schoolFilter'),
  new Param('company'),
  new Param('firstName'),
  new Param('lastName'),
  new Param('school', 'schoolFreetext', [], (value) => `"${value}"`),
  new Param('title', 'titleFreeText'),
  new Param('keywords'),
];

export const getSearchUrl = (params?: ICampaignSearchParams) => {
  if (!params) {
    return '';
  }

  params = Object.assign({}, params, params.specifyKeywords);

  let url = 'https://www.linkedin.com/search/results/people/?';
  for (const param of SEARCH_PARAMS) {
    url += param.extractUrlString(params);
  }
  url += 'origin=FACETED_SEARCH';

  return url;
};

export const convertSalesNavigatorUrl = (url: string) => {
  if (url.includes('linkedin.com/sales/people/')) {
    return `https://www.linkedin.com/in/${url.split('/sales/people/')[1].slice(0, 39)}`;
  }
  if (url.includes('linkedin.com/sales/lead/')) {
    return `https://www.linkedin.com/in/${url.split('/sales/lead/')[1].slice(0, 39)}`;
  }

  return url;
};
