import { useMutation, UseMutationOptions } from 'react-query';

import { checkLinkedinConnection } from 'src/api';
import { ICheckLinkedinConnectionResponse, ICustomAxiosError } from 'src/types';

export const useCheckLinkedinConnection = (
  options?: UseMutationOptions<ICheckLinkedinConnectionResponse, ICustomAxiosError>,
) => {
  const { mutate, ...rest } = useMutation(['check-linkedin-connection'], checkLinkedinConnection, options);

  return { checkConnection: mutate, ...rest };
};
