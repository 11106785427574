import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { COOKIE_TOKEN_NAME } from 'src/constants';
import { setCookie } from 'src/helpers';
import { useExchangeTemporaryToken } from 'src/reactQueries';
import { setUser } from 'src/store';
import { useAuth } from './useAuth';

export const useExchangeToken = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { logout } = useAuth();

  const [searchParams, setSearchParams] = useSearchParams();

  const exchangeToken = searchParams.get('exchange-token') || '';

  const { isLoading } = useExchangeTemporaryToken(exchangeToken, {
    enabled: !!exchangeToken,
    onSuccess: (data) => {
      logout();

      setCookie(COOKIE_TOKEN_NAME, data?.authToken);

      dispatch(setUser(data?.user));

      setSearchParams('exchange-token');

      navigate('/campaign/stats');
    },
  });

  return { isLoading };
};
