import { ReactNode, useState } from 'react';
import { components, StylesConfig } from 'react-select';
import Creatable from 'react-select/creatable';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, BoxProps, Palette } from '@mui/material';

import { ReactComponent as XMarkIcon } from 'src/assets/icons/x-mark.svg';
import { ISelectOption } from 'src/types';

const RemoveAll = styled(Box)`
  cursor: pointer;
  font-size: 18px;
  margin: 0 10px;
`;

export const getMultiSelectStyles = (palette: Palette): StylesConfig<ISelectOption<unknown>> => ({
  placeholder: (providedStyles) => ({
    ...providedStyles,
    color: 'var(--violet-main)',
    lineHeight: '24px',
    fontSize: '14px',
  }),
  control: (providedStyles) => ({
    ...providedStyles,
    minHeight: 30,
    boxShadow: 'none',
    border: '2px solid var(--light-dark)',
    '&:hover': {
      border: '2px solid var(--light-dark)',
    },
  }),
  multiValue: (providedStyles) => ({
    ...providedStyles,
    borderRadius: '20px',
    lineHeight: '22px',
    backgroundColor: palette.success[700],
    margin: '0 5px 0 0',
    padding: '0 5px 0 10px',
  }),
  multiValueLabel: (providedStyles) => ({
    ...providedStyles,
    color: '#ffffff',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
  }),
  multiValueRemove: (providedStyles) => ({
    ...providedStyles,
    cursor: 'pointer',
    backgroundColor: 'transparent',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#ffffff',
    },
  }),
  option: (providedStyles) => ({
    ...providedStyles,
    padding: '10px 20px',
    fontSize: '14px',
    color: '#2E6EFD',
    backgroundColor: 'white',
    cursor: 'pointer',
  }),
  menu: (providedStyles) => ({
    ...providedStyles,
    margin: '0px',
  }),
  dropdownIndicator: (providedStyles) => ({ ...providedStyles, marginRight: '5px' }),
  clearIndicator: (providedStyles) => ({ ...providedStyles, cursor: 'pointer', padding: 0 }),
});

interface ISearchProps {
  onChange?: (opt: ISelectOption[]) => void;
  defaultValue?: ISelectOption[];
  placeholder?: ReactNode;
  noResultPlaceholder?: ReactNode;
}

export const CreatableSearch = ({
  onChange,
  placeholder,
  defaultValue = [],
  noResultPlaceholder,
  ...rest
}: ISearchProps) => {
  const { palette } = useTheme();

  const [search, setSearch] = useState<ISelectOption[]>(defaultValue);

  const onSelectChange = (otp: ISelectOption[]) => {
    onChange && onChange(otp);
    setSearch(otp);
  };

  return (
    <Creatable
      {...rest}
      placeholder={placeholder}
      isMulti
      value={search}
      onChange={(otp) => onSelectChange(otp as ISelectOption[])}
      styles={getMultiSelectStyles(palette)}
      components={{
        DropdownIndicator: null,
        IndicatorSeparator: null,
        IndicatorsContainer: (props) => (
          <>
            {props.hasValue && (
              <RemoveAll {...(props as BoxProps)} onClick={props.clearValue}>
                <XMarkIcon color="#000000" />
              </RemoveAll>
            )}
          </>
        ),
        NoOptionsMessage: (props) => (
          <Box {...props} padding="10px 20px" fontSize="16px" color="#A0A0A0">
            {noResultPlaceholder}
          </Box>
        ),
        MultiValueRemove: (props) => (
          <components.MultiValueRemove {...props}>
            <XMarkIcon />
          </components.MultiValueRemove>
        ),
      }}
    />
  );
};
