import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { removeMemberFromGroup } from 'src/api/groups';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IGetTeamMembersResponse, IRemoveMemberFromGroupRequest } from 'src/types';

export const useRemoveMemberFromGroup = (
  options?: UseMutationOptions<void, ICustomAxiosError, IRemoveMemberFromGroupRequest>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const getTeamMembersKey = ['get-team-members'];

  const { mutate, ...rest } = useMutation(['remove-member-from-group'], removeMemberFromGroup, {
    ...options,
    onMutate: async (variables) => {
      options?.onMutate?.(variables);
      await queryClient.cancelQueries(getTeamMembersKey);

      const prevData = queryClient.getQueriesData(getTeamMembersKey);

      queryClient.setQueriesData<IGetTeamMembersResponse | undefined>(
        getTeamMembersKey,
        (data) =>
          data && {
            ...data,
            members: data.members.map((member) =>
              member.userId === variables?.memberId ? { ...member, group: null } : member,
            ),
          },
      );

      return { prevData };
    },
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      await queryClient.invalidateQueries(['get-group-list-full']);
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      queryClient.setQueriesData(getTeamMembersKey, context?.prevData);
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { removeMemberFromGroup: mutate, ...rest };
};
