import { PropsWithChildren } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { css, Global } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

const Background = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ isOpen: boolean }>`
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${({ isOpen }) => (isOpen ? 'rgba(0, 0, 0, 0.5)' : 'transparent')};
  z-index: ${({ isOpen }) => (isOpen ? 1000 : -1)};
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
`;

const Blade = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ isOpen: boolean }>`
  background-color: #ffffff;
  height: 100%;
  z-index: 1200;
  position: fixed;
  top: 0;
  outline: 0;
  right: 0;
  transform: ${({ isOpen }) => (isOpen ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
`;

const globalOpenDrawerStyles = css`
  body {
    overflow: hidden;
  }
`;

interface DrawerProps extends PropsWithChildren {
  isOpen: boolean;
  onClose: () => void;
}

export const Drawer = ({ isOpen, onClose, children }: DrawerProps) => {
  return (
    <Background isOpen={isOpen} onClick={onClose}>
      <Blade isOpen={isOpen} onClick={(e) => e.stopPropagation()}>
        {isOpen && (
          <>
            <Global styles={globalOpenDrawerStyles} />
            {children}
          </>
        )}
      </Blade>
    </Background>
  );
};
