import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { Button, GoogleButton, MicrosoftButton, Typography } from 'src/components';
import { REGEXP_VALID_EMAIL_EXTENDED } from 'src/constants';
import { ModalTypes } from 'src/enums';
import { delay, renderLoginCodeError } from 'src/helpers';
import { useGetUserAccount, useGetUserPreferences, useUpdateEmail } from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';
import { IUpdateCredentials } from 'src/types';
import { ErrorMessage, Title } from '../_components';
import { labelStyle, StyledInput } from './ui';

export const ChangeEmail = ({
  reset,
  setValue,
  register,
  getValues,
  formState: { errors },
  trigger,
}: UseFormReturn<IUpdateCredentials>) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginEmailError, setLoginEmailError] = useState<number | null>(null);

  const formError = errors.email?.message;

  const { changeEmail, isSuccess, isLoading } = useUpdateEmail();

  const { userPreferences } = useGetUserPreferences();
  const { email = '', havePassword } = userPreferences?.userProfile ?? {};

  const { userMe } = useGetUserAccount();
  const { isGhostUser } = userMe ?? {};
  const isSocialChange = !(havePassword || isGhostUser);

  useEffect(() => {
    if (REGEXP_VALID_EMAIL_EXTENDED.test(email)) {
      reset({ email });
    }
  }, [email]);

  if (isSuccess && !isSocialChange) {
    delay(2000, () => navigate('/app/verifyEmail'));
  }

  const onChangeEmail = async (emailParam?: string) => {
    if (!emailParam) {
      const isValid = await trigger('email');

      if (!isValid) {
        return;
      }
    }

    const onConfirm = async () => {
      const isValid = await trigger('email');

      if (isValid) {
        changeEmail(getValues('email'));
      }
    };

    if (isSocialChange) {
      return changeEmail(emailParam || getValues().email);
    }

    return dispatch(
      openModal({
        type: ModalTypes.CHANGE_EMAIL,
        headerText: 'Email confirmation',
        params: {
          onConfirm,
          value: getValues('email'),
          setValue: (value: string) => setValue('email', value, { shouldValidate: true }),
        },
      }),
    );
  };

  return (
    <Box>
      <Title data-testid="title">{isGhostUser ? 'Add email' : 'Change email'}</Title>
      {isSocialChange ? (
        <Box>
          <Typography>
            <b>Current mail: </b>
            {email}
          </Typography>
          <Box width="400px" display="grid" gap="20px" my="10px">
            <GoogleButton
              redirectTo="/settings/user-profile"
              onSuccess={({ user }) => onChangeEmail(user.email)}
              setError={setLoginEmailError}
            />

            <MicrosoftButton
              redirectTo="/settings/user-profile"
              onSuccess={({ user }) => onChangeEmail(user.email)}
              setError={setLoginEmailError}
            />
          </Box>
          {loginEmailError && <ErrorMessage>{renderLoginCodeError(loginEmailError)}</ErrorMessage>}
        </Box>
      ) : (
        <Box>
          <Box display="flex" gap="20px" mt="10px" mb="20px">
            <StyledInput
              register={register}
              parameters={{
                required: 'Please enter an email',
                pattern: {
                  value: REGEXP_VALID_EMAIL_EXTENDED,
                  message: 'Please enter a valid email',
                },
              }}
              className={classNames({
                error: errors?.email,
              })}
              name="email"
              type="email"
              label="Email"
              labelStyle={labelStyle}
              placeholder="Email"
            />
          </Box>
          {!!formError && <ErrorMessage data-testid="validation-error">{formError}</ErrorMessage>}
          {/* ghost users have separate button for setting email and password at once */}
          {!isGhostUser && (
            <Box display="flex" justifyContent="flex-end">
              <Button processing={isLoading} onClick={() => onChangeEmail()}>
                Save
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
