import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { HeaderButton, Spinner, Tooltip, Typography } from 'src/components';
import { CampaignType } from 'src/enums';
import { useCompanyTheme, useLocationState, useSingleCampaignProps } from 'src/hooks';
import {
  useAddMoreLeadsCampaign,
  useCreateCampaignAudience,
  useGetCampaign,
  useGetUserLinkedInGroups,
  useRefreshUserLinkedinGroups,
  useUpdateCampaignAudience,
} from 'src/reactQueries';
import { transformCampaignRequestBody } from 'src/transformers';
import { ICampaignAudienceFormFields, ILocationStateSingleCampaign } from 'src/types';
import { ControlledCheckbox, ControlledSingleSelect, ControlledSlider, RefreshButton } from '../../_components';
import { CampaignsSteps } from '../../CampaignSteps';

export const LinkedinGroup = () => {
  const { control, reset, watch, handleSubmit } = useForm<ICampaignAudienceFormFields>();

  const { shortCompanyName } = useCompanyTheme();
  const { initialCampaignId } = useLocationState<ILocationStateSingleCampaign>();
  const { isAddMoreLeads, isEdit, isCampaignLoading, campaignId, campaign, category } = useSingleCampaignProps();
  const { campaign: initialCampaign } = useGetCampaign(initialCampaignId, {
    enabled: !!campaignId && !!initialCampaignId && !isAddMoreLeads,
  });

  const { groups, isFetching } = useGetUserLinkedInGroups({
    select: (resp) => resp.map((item) => ({ value: item.savedSearchId, label: item.name, type: item.listType })),
  });

  const { addMoreLeads, isAddMoreLeadsLoading } = useAddMoreLeadsCampaign(campaignId);
  const { createCampaignAudience, isCreateLoading } = useCreateCampaignAudience();
  const { updateCampaignAudience, isUpdateLoading } = useUpdateCampaignAudience(campaignId);

  const { refreshLIGroups, isLoading: isRefreshing } = useRefreshUserLinkedinGroups();

  useEffect(() => {
    // eslint-disable-next-line no-undefined
    const nonAddLeadsCampaign = isAddMoreLeads ? undefined : campaign;
    const tempCampaign = initialCampaignId ? initialCampaign : nonAddLeadsCampaign;

    if (tempCampaign) {
      const index = tempCampaign.searchUrl?.indexOf('groups');

      reset({
        excludeNoPhotos: tempCampaign.excludeNoPhotos,
        maxSearchAmount: tempCampaign.maxSearchAmount,
        ...(index > 0 && { searchUrl: tempCampaign.searchUrl.split('/').pop() }),
      });
    }
  }, [initialCampaignId, campaign, initialCampaign]);

  const submit = (values: ICampaignAudienceFormFields) => {
    const body = transformCampaignRequestBody({
      ...values,
      category,
      campaignType: CampaignType.LINKEDIN_GROUP,
      searchUrl: `https://www.linkedin.com/groups/${values.searchUrl}`,
    });

    if (isAddMoreLeads) {
      return addMoreLeads({
        ...body,
        searchType: CampaignType.LINKEDIN_GROUP,
        campaignId,
      });
    }

    if (isEdit) {
      return updateCampaignAudience(body);
    }

    return createCampaignAudience(body);
  };

  const refreshHandler = () => {
    refreshLIGroups();
  };

  return (
    <>
      <CampaignsSteps>
        <HeaderButton
          size={{ width: '180px' }}
          processing={isAddMoreLeadsLoading || isCreateLoading || isUpdateLoading}
          disabled={!watch('searchUrl')}
          onClick={handleSubmit(submit)}
        >
          Next
        </HeaderButton>
      </CampaignsSteps>

      {(isFetching || isCampaignLoading) && <Spinner type="dots" />}

      {!!groups?.length && !isCampaignLoading && (
        <Box padding="30px 15px" width="100%" maxWidth="900px">
          <form>
            <Box display="flex" alignItems="flex-end">
              <ControlledSingleSelect
                name="searchUrl"
                options={groups}
                label="Select a LinkedIn group."
                placeholder="Select LinkedIn Group"
                control={control}
              />
              <Tooltip title="Refresh Linkedin Groups">
                <Box mb="12px">
                  <RefreshButton variant="icon" onClick={refreshHandler} processing={isRefreshing} />
                </Box>
              </Tooltip>
            </Box>

            <ControlledSlider name="maxSearchAmount" label="Maximum amount of leads:" control={control} />

            <ControlledCheckbox name="excludeNoPhotos" label="Exclude profiles without photos." control={control} />

            <ControlledCheckbox name="noFirstConnections" label="Exclude 1st degree connections." control={control} />

            <ControlledCheckbox name="retargetLeads" label="Include leads from other campaigns." control={control} />
          </form>
        </Box>
      )}

      {!isFetching && !groups?.length && (
        <Box mt="40px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Box display="flex" alignItems="center" mb="20px">
            <Typography mr="5px" color="#262A70">
              No LinkedIn Groups detected.
            </Typography>
            <Tooltip
              title={
                <Box textAlign="left">
                  This is because
                  <br />
                  <br />
                  1. You are not a member of any LinkedIn Group.
                  <br />
                  2. {shortCompanyName} bot has not synced your LinkedIn Groups yet.
                </Box>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} size="sm" color="red" />
            </Tooltip>
          </Box>
          <RefreshButton
            variant="button"
            onClick={refreshHandler}
            processing={isRefreshing}
            label="Refresh Linkedin Groups"
          />
        </Box>
      )}
    </>
  );
};
