import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getWhiteLabel } from 'src/api';
import { IWhiteLabel } from 'src/types';

export const useGetWhiteLabel = <TData = IWhiteLabel>(options?: UseQueryOptions<IWhiteLabel, AxiosError, TData>) => {
  return useQuery<IWhiteLabel, AxiosError, TData>(
    ['get-whitelabel'],
    () =>
      new Promise((resolve) =>
        getWhiteLabel()
          .then(resolve)
          .catch(() => resolve({} as IWhiteLabel)),
      ),
    options,
  );
};
