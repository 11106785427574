import { useMutation, UseMutationOptions } from 'react-query';

import { updateSubscription } from 'src/api';
import { ICustomAxiosError, IEditPlan, ISubscriptionResponse } from 'src/types';

export const useUpdateSubscription = (
  options?: UseMutationOptions<ISubscriptionResponse, ICustomAxiosError, IEditPlan>,
) => {
  const { mutate, ...rest } = useMutation(['update-subscription'], updateSubscription, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
    },
  });

  return { updateSubscription: mutate, ...rest };
};
