import {
  IAuthResponse,
  IEmailAuthRequest,
  IEmailSignUpRequest,
  IGoogleSignUpRequest,
  IMicrosoftSignUpRequest,
  ISetNewPasswordRequest,
} from 'src/types';
import { appApi } from './axios';

export const sendRecoveryLink = (email: string): Promise<void> =>
  appApi.post(`/auth/forgot-password`, {
    email,
  });

export const setNewPassword = ({ body, token }: ISetNewPasswordRequest): Promise<void> => {
  return appApi.patch(`/auth/set-new-password/${token}`, {
    password: body.password,
    passwordConfirmation: body.confirmPassword,
  });
};

export const getMicrosoftCodeUrl = (state: string): Promise<string> =>
  appApi.get(`/auth/microsoft/code-url`, {
    params: { state },
  });

export const authUsingMicrosoft = (data: IMicrosoftSignUpRequest, isSignup: boolean): Promise<IAuthResponse> =>
  appApi.post(`/auth/${isSignup ? 'sign-up' : 'sign-in'}/microsoft`, data);

export const authUsingGoogle = (data: IGoogleSignUpRequest, isSignup: boolean): Promise<IAuthResponse> =>
  appApi.post(`/auth/${isSignup ? 'sign-up' : 'sign-in'}/google`, data);

export const signInViaEmail = (body: IEmailAuthRequest): Promise<IAuthResponse> => appApi.post(`/auth/sign-in`, body);

export const signUpViaEmail = (body: IEmailSignUpRequest): Promise<IAuthResponse> => appApi.post(`/auth/sign-up`, body);

export const resendVerificationEmail = (): Promise<void> => appApi.post(`/auth/send-verification-link`);

export const sendVerificationCode = (verificationCode: string) =>
  appApi.patch(`/auth/verify-email/${verificationCode}`);

export const exchangeTemporaryToken = (token: string): Promise<IAuthResponse> => appApi.post(`/auth/exchange/${token}`);
