import { isUndefined } from 'lodash';
import { MultiValue, SingleValue } from 'react-select';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Input, Typography } from 'src/components';
import { SelectBasic } from 'src/components/Select';
import { Features, TeamUserRole } from 'src/enums';
import { useAppSelector, useTeamPlan } from 'src/hooks';
import { IColorSelectOption, IShortGroupData } from 'src/types';

export const TextInput = styled(Input)`
  color: #333;
  height: 33px;
  line-height: 19px;
  padding: 10px;
`;

const SearchWrapper = styled(Box)`
  min-width: 960px;
  display: grid;
  gap: 70px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 10px;
  padding: 0 15px;
`;

interface ISearchBarProps {
  onChangeValue: (key: 'group' | 'name' | 'email', value: string) => void;
  nameValue?: string;
  emailValue?: string;
  groupList?: IShortGroupData[];
}

export const TeamsSearchBar = ({ emailValue, nameValue, onChangeValue, groupList = [] }: ISearchBarProps) => {
  const myTeamRole = useAppSelector((state) => state.team.data?.teamUser?.role);

  const { checkFeature } = useTeamPlan();

  const selectOptions: IColorSelectOption[] = groupList.map((groupItem) => ({
    label: groupItem.name,
    value: groupItem.id,
  }));

  const onChangeGroup = (newValue: SingleValue<IColorSelectOption> | MultiValue<IColorSelectOption>) => {
    onChangeValue('group', (newValue as MultiValue<IColorSelectOption>).map((item) => item.value.toString()).join(','));
  };

  return (
    <SearchWrapper>
      {!isUndefined(nameValue) && (
        <Box>
          <Typography color="text.primary" lineHeight="36px" semibold>
            Search By Name
          </Typography>
          <TextInput
            data-testid="name-filter"
            type="text"
            name="name"
            value={nameValue}
            placeholder="Name"
            onChange={(event) => onChangeValue('name', event.target.value)}
          />
        </Box>
      )}
      {!isUndefined(emailValue) && (
        <Box>
          <Typography color="text.primary" lineHeight="36px" semibold>
            Search By Email
          </Typography>
          <TextInput
            type="text"
            name="email"
            value={emailValue}
            placeholder="Email"
            onChange={(event) => onChangeValue('email', event.target.value)}
          />
        </Box>
      )}
      {checkFeature(Features.teamGroup) && myTeamRole === TeamUserRole.OWNER && (
        <Box>
          <Typography mb="5px" color="text.primary" lineHeight="36px" semibold>
            Search By Group
          </Typography>
          <SelectBasic
            aria-label="search-group-select"
            placeholder="Search by group"
            onChange={onChangeGroup}
            options={selectOptions}
            isMulti
          />
        </Box>
      )}
    </SearchWrapper>
  );
};
