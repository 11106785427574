import { isEqual } from 'lodash';
import { memo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import unavailableLeads from 'src/assets/img/unavailable-leads.jpg';
import { HeaderContainer, HeaderDescription, HeaderTitle, HeaderVideo, Link, Typography } from 'src/components';
import { Features } from 'src/enums';
import { useAppSelector, useLocationState, useTeamPlan, useWhiteLabel } from 'src/hooks';
import { usePostLeads } from 'src/reactQueries';
import { ButtonAddTags, ButtonExportCsv, ButtonToggleExclude, UnavailableContainer } from './_components';
import LeadDrawer from './LeadDetails/LeadDrawer';
import { LeadsList } from './LeadsList/LeadsList';

export const LeadsPage = memo(() => {
  const { checkFeature } = useTeamPlan();
  const { whitelabel } = useWhiteLabel();
  const navigate = useNavigate();
  const locationState = useLocationState();

  const { leadsRequest, selectedLeads } = useAppSelector(
    (state) => ({
      selectedLeads: state.lead.exportData.selectedLeads,
      leadsRequest: state.lead.request,
    }),
    isEqual,
  );

  const isLeadsAllowed = checkFeature(Features.leads);
  const entityUrns = selectedLeads.map(({ entityUrn }) => entityUrn);

  const totalLeads = usePostLeads<number>(
    {
      ...leadsRequest,
      search: leadsRequest?.search?.trim(),
      entityUrns: leadsRequest?.selectedOnly ? selectedLeads.map((lead) => lead.entityUrn) : [],
    },
    {
      enabled: isLeadsAllowed,
      select: (resp) => resp.total,
      refetchOnMount: true,
    },
  );

  const isCsvButtonDisabled = !totalLeads.data || totalLeads.isLoading;

  const openLeadInfo = useCallback(
    (entityUrn?: string) =>
      navigate(`${location.pathname}?person=${entityUrn}&tab=personal_details`, { state: locationState }),
    [],
  );

  return (
    <>
      <HeaderContainer>
        <Box display="flex" justifyContent="space-between">
          <HeaderTitle>Leads</HeaderTitle>
          <Box display="flex" pt="20px" height="30px" gap="20px">
            {entityUrns.length > 0 && <ButtonAddTags type="leads" entityUrns={entityUrns} />}
            <ButtonToggleExclude type="leads" selectedLeads={selectedLeads} />
            <ButtonExportCsv entityUrns={entityUrns} isExportDisabled={isCsvButtonDisabled} />
          </Box>
        </Box>
        <HeaderDescription>
          Handle and filter your campaign prospect data for maximum efficiency.
          {whitelabel.isWhiteLabelUser || (
            <>
              <Link
                leftIcon
                underline
                external
                openNewTab
                to="https://help.meetalfred.com/en/articles/8392603-view-and-manage-your-leads"
              >
                Learn how it works
              </Link>
              <HeaderVideo src="https://player.vimeo.com/video/871923919" />
            </>
          )}
        </HeaderDescription>
      </HeaderContainer>

      {isLeadsAllowed ? (
        <Box display="flex" maxWidth="100vw" height="calc(100% - 90px)">
          <LeadsList openLeadInfo={openLeadInfo} />
        </Box>
      ) : (
        <UnavailableContainer url={unavailableLeads}>
          <Box boxShadow="0 5px 15px rgb(0 0 0 / 50%)" bgcolor="#fff" p="120px 100px" borderRadius="5px">
            <Typography fontSize="18px">
              <Link to="/billing/edit">Upgrade</Link> your subscription plan in order to get access to this feature
            </Typography>
          </Box>
        </UnavailableContainer>
      )}

      <LeadDrawer />
    </>
  );
});
