import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getCampaignSequenceProgressById } from 'src/api';
import { ICampaignSequenceProgress } from 'src/types';

export const useGetCampaignSequenceProgress = <TData = ICampaignSequenceProgress>(
  campaignId: number,
  options?: UseQueryOptions<ICampaignSequenceProgress, AxiosError, TData>,
) => {
  return useQuery<ICampaignSequenceProgress, AxiosError, TData>(
    ['get-campaign-sequence-progress', campaignId],
    () => getCampaignSequenceProgressById(campaignId),
    options,
  );
};
