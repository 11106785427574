import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { deleteTrend } from 'src/api';
import { ICustomAxiosError, ITrend } from 'src/types';

export const useDeleteTrend = (trendId: number, options?: UseMutationOptions<void, ICustomAxiosError>) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const getTrendsKey = ['get-trends'];

  const { mutate, isLoading, ...rest } = useMutation(['delete-trend', 'trendId'], () => deleteTrend(trendId), {
    ...options,
    onMutate: async (variables) => {
      options?.onMutate?.(variables);
      await queryClient.cancelQueries(getTrendsKey);

      const prevData = queryClient.getQueryData(getTrendsKey);

      queryClient.setQueryData<ITrend[] | undefined>(getTrendsKey, (trends) =>
        trends?.filter((trend) => trend.id !== trendId),
      );

      return { prevData };
    },
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      navigate('/trends');
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      queryClient.setQueryData(getTrendsKey, context?.prevData);
    },
    onSettled: async (data, error, variables, context) => {
      options?.onSettled?.(data, error, variables, context);
      await queryClient.invalidateQueries(getTrendsKey);
    },
  });

  return { deleteTrend: mutate, isDeleteLoading: isLoading, ...rest };
};
