import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  HeaderContainer,
  HeaderDescription,
  HeaderTabLink,
  HeaderTabsContainer,
  HeaderTitle,
  HeaderVideo,
  Link,
} from 'src/components';
import { useWhiteLabel } from 'src/hooks';
import { AppLayout } from 'src/layouts';

export const Integrations = () => {
  const { whitelabel } = useWhiteLabel();

  const INTEGRATIONS_TABS = [
    { label: 'LinkedIn', to: '/integrations/linkedin' },
    { label: 'Email', to: '/integrations/email' },
    { label: 'Facebook', to: '/integrations/facebook', hidden: whitelabel.isWhiteLabelUser },
    { label: 'X (Twitter)', to: '/integrations/twitter', hidden: whitelabel.isWhiteLabelUser },
    { label: 'Zapier', to: '/integrations/zapier', hidden: whitelabel.isWhiteLabelUser },
    { label: 'Webhook', to: '/integrations/webhook' },
  ];

  return (
    <AppLayout>
      <HeaderContainer>
        <HeaderTitle>Integrations</HeaderTitle>
        <HeaderDescription>
          Automate your workflows by connecting with your favorite outreach and sales channels.
          {whitelabel.isWhiteLabelUser || (
            <>
              <Link
                leftIcon
                underline
                external
                openNewTab
                to="https://help.meetalfred.com/en/collections/5916769-integrations"
              >
                Learn how it works
              </Link>
              <HeaderVideo src="https://player.vimeo.com/video/622842575" />
            </>
          )}
        </HeaderDescription>
        <HeaderTabsContainer>
          {INTEGRATIONS_TABS.map(
            ({ label, to, hidden }) =>
              hidden || (
                <HeaderTabLink key={to} to={to}>
                  {label}
                </HeaderTabLink>
              ),
          )}
        </HeaderTabsContainer>
      </HeaderContainer>
      <Box padding="20px 15px 80px">
        <Outlet />
      </Box>
    </AppLayout>
  );
};
