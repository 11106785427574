import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { postLeads } from 'src/api';
import { convertLeadsFilterToRequest } from 'src/helpers';
import { ILeadsFilters, ILeadsResponse } from 'src/types';

export const usePostLeads = <TData = ILeadsResponse>(
  requestData: ILeadsFilters,
  options?: UseQueryOptions<ILeadsResponse, AxiosError, TData>,
) => {
  return useQuery<ILeadsResponse, AxiosError, TData>(
    ['post-leads', JSON.stringify(requestData)],
    () => postLeads(convertLeadsFilterToRequest(requestData)),
    options,
  );
};
