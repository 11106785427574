import { useMutation, UseMutationOptions } from 'react-query';

import { sendRecoveryLink } from 'src/api';
import { ICustomAxiosError } from 'src/types';

export const useSendRecoveryLink = (options?: UseMutationOptions<void, ICustomAxiosError, string>) => {
  const { mutate, ...rest } = useMutation('recovery-link', sendRecoveryLink, options);

  return {
    sendRecoveryLink: mutate,
    ...rest,
  };
};
