import { useMutation } from 'react-query';

import { postFacebookShortLiveTokenToGetLongLiveToken } from 'src/api';

export const usePostFacebookShortLiveTokenToGetLongLiveToken = ({
  onSuccess,
}: {
  onSuccess: (data: { accessToken: string }) => void;
}) => {
  const { mutate, ...rest } = useMutation(
    'post-facebook-short-live-token-to-get-long-live-token',
    postFacebookShortLiveTokenToGetLongLiveToken,
    { onSuccess },
  );

  return {
    postFacebookShortLiveTokenToGetLongLiveToken: mutate,
    ...rest,
  };
};
