import classNames from 'classnames';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { HeaderButton, Input, Typography } from 'src/components';
import { CampaignCategory } from 'src/enums';
import { useSingleCampaignProps } from 'src/hooks';
import { useGetCampaign, usePostPublishCampaign } from 'src/reactQueries';
import { CampaignIcon, SequenceStep } from '../Campaigns/_components';
import { SummaryBoard } from './_components';
import { CampaignsSteps } from './CampaignSteps';

const StyledInput = styled(Input)`
  padding: 3px 10px;
  height: auto;
  border: 1px solid ${({ theme }) => theme.palette.light.dark};

  &.error {
    border-color: ${({ theme }) => theme.palette.error.light};

    &:focus {
      box-shadow:
        inset 0 2px 1px 0 rgb(0 0 0 / 10%),
        0 0 10px 0 rgb(246 39 19 / 50%);
    }
  }
`;

const Container = styled(Box)`
  box-shadow: 0 1px 1px 0 ${({ theme }) => theme.palette.light.dark};
  border-radius: 5px;
  background-color: white;
  padding: 20px;
`;

interface IFormState {
  name: string;
}

export const Summary = () => {
  const { campaignId } = useParams();

  const { campaign } = useGetCampaign(Number(campaignId));
  const { category, generalCampaignTypeLabel } = useSingleCampaignProps();
  const { publishCampaign, isLoading: publishLoading } = usePostPublishCampaign(Number(campaignId));
  const {
    name = '',
    campaignType,
    searchUrl,
    status,
    salesNavigatorUrl,
    excludeNoPhotos,
    noFirstConnections,
    openLinkOnly,
    premiumOnly,
    includeMessageOnly,
    maxSearchAmount = 1,
    retargetLeads,
  } = campaign ?? {};
  const sequences = campaign?.touchSequence?.sequence;

  const isTwitterCategory = category === CampaignCategory.TWITTER;

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<IFormState>();

  useEffect(() => {
    setValue('name', campaign?.name ?? '');
  }, [name]);

  const submit = (values: IFormState) => {
    publishCampaign({ name: values.name });
  };

  return (
    <Box bgcolor="light.light">
      <CampaignsSteps>
        <HeaderButton
          id="publish-btn"
          data-intercom-target="publish-btn"
          size={{ width: '180px' }}
          processing={publishLoading}
          tooltip={{ title: isTwitterCategory ? 'Feature under Maintenance' : '' }}
          disabled={publishLoading || isTwitterCategory}
          onClick={handleSubmit(submit)}
        >
          Publish
        </HeaderButton>
      </CampaignsSteps>

      <Box display="grid" gridTemplateColumns="1fr 1fr" padding="20px 15px" gap="30px">
        <Box>
          <Typography color="primary.wlLight" mb="15px">
            Name your campaign
          </Typography>
          <StyledInput
            register={register}
            className={classNames({
              error: errors?.name?.message,
            })}
            parameters={{
              required: 'Please enter a name',
            }}
            placeholder={errors?.name?.message}
            name="name"
            id="name-campaign-input"
            data-intercom-target="name-campaign-input"
          />
          <Typography color="primary.wlLight" mt="30px">
            Sequence
          </Typography>
          <Box mt="20px">
            {sequences?.map((sequence, index) => (
              <SequenceStep
                key={index}
                index={index}
                sequence={sequence}
                highlight={sequences.length !== 1 && index !== sequences.length - 1}
              />
            ))}
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" gap="20px">
          <Typography color="primary.wlLight">Audience</Typography>
          <Container display="flex" alignItems="center">
            <CampaignIcon type={category} size="medium" />
            <Box ml="20px">
              <Typography fontWeight="300">Campaign type</Typography>
              <Typography height="21px" semibold>
                {generalCampaignTypeLabel}
              </Typography>
            </Box>
          </Container>
          <Box>
            <SummaryBoard
              campaignType={campaignType}
              campaignStatus={status}
              options={{
                excludeNoPhotos,
                noFirstConnections,
                premiumOnly,
                openLinkOnly,
                includeMessageOnly,
                retargetLeads,
              }}
              searchUrl={searchUrl || salesNavigatorUrl}
              maxSearchAmount={maxSearchAmount}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
