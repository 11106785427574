export const separateNumWithComma = (num?: number) => {
  return typeof num === 'number' ? num.toLocaleString('en-US') : '';
};

export const getRandomNotSecuredIntNumber = (max = 1, min = 0) => {
  min = Math.ceil(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
};
