import { Link, ProfileAvatar, Typography } from 'src/components';
import { CampaignActions, CampaignCategory, CampaignType } from 'src/enums';

interface IResolvePersonName {
  name?: string | null;
  twitterHandle?: string | null;
  linkedInHandle?: string | null;
  linkedInData?: { pic?: string };
  workEmail?: string | null;
  action?: string;
  key?: string;
  companyLogo?: string;
  companyName?: string;
  isSelected?: boolean;
  campaignType?: CampaignType;
  campaignCategory?: CampaignCategory;
}

interface IParams {
  withAction?: boolean;
}

export const resolvePersonName = (props?: IResolvePersonName, params?: IParams) => {
  const {
    twitterHandle,
    name,
    linkedInHandle,
    linkedInData,
    workEmail,
    action,
    key,
    isSelected,
    campaignType,
    campaignCategory,
  } = props ?? {};
  const { withAction } = params ?? {};

  if (
    (linkedInHandle || key) &&
    name &&
    (!action || action === CampaignActions.LINKEDIN_REPLY_DETECTED) &&
    (!campaignType || campaignType !== CampaignType.TWITTER_CSV_UPLOAD)
  ) {
    const profileLink = `https://www.linkedin.com/in/${linkedInHandle || key}`;

    return {
      name,
      profileLink,
      avatar: linkedInData?.pic,
      avatarContent: <ProfileAvatar firstLatter={name[0]} avatarSrc={linkedInData?.pic} />,
      nameContent: (
        <Link variant="darkBlue" openNewTab external to={profileLink}>
          {name}
        </Link>
      ),
    };
  }

  if (
    name &&
    (twitterHandle || campaignCategory === CampaignCategory.TWITTER) &&
    (!action || action === CampaignActions.TWITTER_REPLY_DETECTED)
  ) {
    const profileLink = `https://twitter.com/${twitterHandle}`;

    return {
      name,
      profileLink,
      avatar: linkedInData?.pic,
      avatarContent: <ProfileAvatar firstLatter={name[0]} avatarSrc={linkedInData?.pic} />,
      nameContent: (
        <Link variant="darkBlue" openNewTab external to={profileLink}>
          {name}
        </Link>
      ),
    };
  }

  if (workEmail) {
    return {
      name: workEmail,
      avatarContent: <ProfileAvatar firstLatter={workEmail[0]} />,
      nameContent: (
        <Typography color={isSelected ? '#fff' : 'inherit'} crop>
          {workEmail}
        </Typography>
      ),
    };
  }

  if (name && !linkedInHandle && !twitterHandle) {
    return {
      name,
      avatarContent: <ProfileAvatar firstLatter={name[0]} />,
      nameContent: (
        <Typography fontSize="15px" crop>
          {name}
        </Typography>
      ),
    };
  }

  return {
    avatarContent: <ProfileAvatar avatarSrc={params?.withAction ? props?.companyLogo : ''} />,
    nameContent: (
      <Typography color={isSelected ? '#fff' : 'inherit'} crop>
        {withAction ? `${props?.companyName} action` : name || workEmail || twitterHandle}
      </Typography>
    ),
  };
};
