import { useMemo, useState } from 'react';
import { MultiValue, SingleValue } from 'react-select';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button, SelectBasic, Typography } from 'src/components';
import { useAppSelector, useCompanyTheme } from 'src/hooks';
import { useSetUserContractId } from 'src/reactQueries';

const Wrapper = styled.div`
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  min-width: 300px;
  flex: 1;
  margin-top: 20px;
`;

export const SalesNavigatorContracts = () => {
  const { shortCompanyName } = useCompanyTheme();
  const { cloudBotStatus, selectedContractId } = useAppSelector((state) => state.user.profile);

  const [contractId, setContractId] = useState<string | null>(selectedContractId || '');
  const [errorMessage, setErrorMessage] = useState('');

  const { setUserContractId, isLoading, isError, isSuccess, error } = useSetUserContractId();

  const changeHandler = (value: SingleValue<{ value: string | number }> | MultiValue<{ value: string | number }>) => {
    const selectedContract = value as { value: string };

    setErrorMessage('');
    setContractId(selectedContract?.value || null);
  };

  const selectedContract = useMemo(() => {
    return cloudBotStatus?.contracts?.find((contract) => contract.contractId === contractId);
  }, [contractId]);

  const save = () => {
    if (!contractId || selectedContractId === contractId) {
      setErrorMessage('Please change contract before save');

      return;
    }

    if (contractId) {
      setUserContractId(contractId);
    }
  };

  return (
    <Wrapper>
      <Typography color="primary.wlLight" fontSize="22px" lineHeight="40px" semibold>
        Choose sales navigator contract
      </Typography>
      <Typography mb="15px">
        {shortCompanyName} will use this selected contract to perform sales navigator searches, fetch lead lists and
        fetch saved searches.
      </Typography>
      <strong>Note: {shortCompanyName} will take some time to update lists according to new contract</strong>

      <Box my="20px">
        <SelectBasic
          value={selectedContract && { value: selectedContract.contractId, label: selectedContract.name }}
          onChange={changeHandler}
          options={cloudBotStatus?.contracts?.map((contract) => ({ value: contract.contractId, label: contract.name }))}
        />
      </Box>

      <Button onClick={save} processing={isLoading}>
        Save contract
      </Button>

      {isSuccess && !errorMessage && (
        <Typography fontSize="14px" color="#91d438" mt="20px">
          Contract updated successfully
        </Typography>
      )}

      {(errorMessage || isError) && (
        <Typography fontSize="14px" color="error.main" mt="20px">
          {errorMessage || error?.message || 'Something went wrong. Please try later.'}
        </Typography>
      )}
    </Wrapper>
  );
};
