import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ReactComponent as ChatBubble } from 'src/assets/icons/chat-bubble.svg';
import { ReactComponent as ChatBubbleDisabled } from 'src/assets/icons/chat-bubble-disabled.svg';
import { Checkbox, Link, ProfileAvatar, TableCell, TableRow } from 'src/components';
import { CampaignCategory } from 'src/enums';
import { getCampaignTypes } from 'src/helpers';
import { IConvertedLead, ILeadColumnsPosition } from 'src/types';
import { ToggleLead } from '../_components';
import { LeadListCell } from './LeadListCell';

interface ILeadsListItemProps {
  lead: IConvertedLead;
  onItemClick?: (entityUrn: string) => void;
  onCheckboxClick?: (params: { entityUrn: string; isExcluded: boolean }) => void;
  columns?: ILeadColumnsPosition[];
  isSelected?: boolean;
}

export const LeadsListItem = ({
  lead,
  onCheckboxClick,
  onItemClick,
  columns = [],
  isSelected,
}: ILeadsListItemProps) => {
  const { palette } = useTheme();

  const { category } = useMemo(() => getCampaignTypes(lead?.campaignData), [lead?.campaignData]);

  const campaignIcon = useMemo(() => {
    switch (category) {
      case CampaignCategory.LINKEDIN:
      case CampaignCategory.INMAIL:
      case CampaignCategory.POST:
      case CampaignCategory.GROUP:
      case CampaignCategory.EVENT:
        return {
          icon: faLinkedin,
          link: `https://www.linkedin.com/in/${lead.publicIdentifier}`,
          color: lead?.isPremium ? '#af9b62' : '#0072b1',
        };
      case CampaignCategory.TWITTER:
        return { icon: faXTwitter, link: `https://twitter.com/${lead.twitterHandle}`, color: '#000000' };

      default:
        return null;
    }
  }, [category, lead]);

  return (
    <TableRow
      data-testid="row"
      hoverBgColor={({ light }) => light.main}
      checkedBgColor={palette.primary.main}
      checked={isSelected}
      onClick={() => onItemClick?.(String(lead.entityUrn))}
      cursor="pointer"
    >
      <TableCell preventClick>
        <Checkbox
          data-testid="checkbox"
          checked={isSelected}
          onChange={() => onCheckboxClick?.({ entityUrn: lead.entityUrn || '', isExcluded: lead.isExcluded })}
        />
      </TableCell>
      <TableCell>
        <ProfileAvatar firstLatter={(lead?.name || '')[0]} avatarSrc={lead.picture} />
      </TableCell>
      {columns
        ?.slice(0, 5)
        .map((column) => <LeadListCell key={`${lead.entityUrn}-${column.key}`} column={column} lead={lead} />)}
      <TableCell
        preventClick
        contentStyle={{ display: 'flex', gap: '16px', marginLeft: 'auto', alignItems: 'center' }}
        sticky
      >
        {campaignIcon && (
          <Link external openNewTab data-testid={`campaignIcon-${category}`} to={campaignIcon.link} fontSize="20px">
            <FontAwesomeIcon icon={campaignIcon.icon} color={campaignIcon.color} />
          </Link>
        )}
        <Link to={`${location.pathname}?person=${lead.entityUrn}&tab=messages`}>
          {lead?.conversationUrn ? <ChatBubble /> : <ChatBubbleDisabled />}
        </Link>
        <ToggleLead isExcluded={lead.isExcluded} entityUrn={String(lead.entityUrn)} />
      </TableCell>
    </TableRow>
  );
};
