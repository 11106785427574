import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createCampaignFromLinkedinCsv } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICampaign, ICampaignAudienceRequest, ICustomAxiosError } from 'src/types';

export const useCreateCampaignFromLinkedinCsv = (
  options?: UseMutationOptions<ICampaign, ICustomAxiosError, ICampaignAudienceRequest>,
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { mutate, isLoading, ...rest } = useMutation<ICampaign, ICustomAxiosError, ICampaignAudienceRequest>(
    ['create-campaign-linkedin-csv'],
    createCampaignFromLinkedinCsv,
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);

        await queryClient.cancelQueries(['campaign', data.id]);

        queryClient.setQueryData<ICampaign>(['campaign', data.id], () => data);
        queryClient.invalidateQueries(['get-campaigns', data.category, 'draft']);

        navigate(`/campaign/${data.id}/sequence`, { state: { category: data.category } });
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { createCampaignFromLinkedinCsv: mutate, isCreateLoading: isLoading, ...rest };
};
