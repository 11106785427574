import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button, TextArea, Typography } from 'src/components';
import { delay } from 'src/helpers';
import { useAppSelector } from 'src/hooks';
import { useUpdateConnectionNotes } from 'src/reactQueries';
import { ILeadInfo } from 'src/types';

const StyledTextArea = styled(TextArea)`
  border-width: 1px;
  max-width: 100%;
  min-width: 100%;
  min-height: 150px;
  max-height: 300px;

  &::placeholder {
    color: ${({ theme }) => theme.palette.gray.light};
  }
`;

interface INoteProps {
  leadInfo?: ILeadInfo;
}

export const NoteForm = ({ leadInfo }: INoteProps) => {
  const { selectedConversation } = useAppSelector((state) => state.conversation);

  const [value, setValue] = useState('');
  const [showSaved, setShowSaved] = useState(false);

  const { updateNotes } = useUpdateConnectionNotes(
    leadInfo?.userNetwork?.entityUrn || leadInfo?.person?.key || selectedConversation?.entityUrn || '',
    {
      onSuccess: () => setShowSaved(true),
    },
  );

  useEffect(() => {
    if (showSaved) {
      delay(3000, () => setShowSaved(false));
    }
  }, [showSaved]);

  useEffect(() => {
    setValue(leadInfo?.userNetwork?.notes || '');
  }, [leadInfo?.userNetwork?.notes]);

  const save = () => {
    if (!showSaved) {
      updateNotes(value);
    }
  };

  return (
    <Box>
      <StyledTextArea value={value} onChange={(e) => setValue(e.target.value)} name="notes" placeholder="Add notes" />
      <Box display="flex" justifyContent="flex-end" mt="10px" mr="10px">
        <Button onClick={save}>{showSaved ? 'Saved' : 'Save'}</Button>
      </Box>
      {showSaved && (
        <Typography fontSize="13px" color="#3f51b5">
          Notes added to this lead.
        </Typography>
      )}
    </Box>
  );
};
