import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { ChangeEvent, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import {
  HeaderButton,
  HeaderContainer,
  HeaderDescription,
  HeaderTabLink,
  HeaderTabLinkCounter,
  HeaderTitle,
  HeaderVideo,
  Input,
  Link,
  SelectBasicGray,
  TabsContainer,
} from 'src/components';
import { PostStatus } from 'src/enums';
import { useDebounce, useWhiteLabel } from 'src/hooks';
import { usePostsCount } from 'src/reactQueries';
import { ISelectOption, TPostFilterOptions, TPostTypes } from 'src/types';
import { PostsTable } from './PostsTable';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

const StyledInput = styled(Input)`
  padding: 5px 10px;
  height: auto;
`;

const POST_TYPE_OPTIONS: ISelectOption<TPostFilterOptions>[] = [
  // eslint-disable-next-line no-undefined
  { value: undefined, label: 'All' },
  { value: PostStatus.PUBLISHED, label: 'Published' },
  { value: PostStatus.PENDING, label: 'Scheduled' },
  { value: PostStatus.QUEUED, label: 'Queued' },
  { value: PostStatus.SKIPPED, label: 'Skipped' },
  { value: PostStatus.ARCHIVED, label: 'Archived' },
];

export const Posts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { whitelabel } = useWhiteLabel();
  const { postType } = useParams<{ postType: TPostTypes }>();
  const [searchValue, setSearchValue] = useState<string>('');
  const [filter, setFilter] = useState<TPostFilterOptions>();

  const searchDebounced = useDebounce(searchValue?.trim());

  const { data } = usePostsCount({ search: searchDebounced, status: filter });
  const { facebook = 0, instagram = 0, linkedIn = 0, twitter = 0 } = data ?? {};

  const POSTS_TABS = [
    { name: `LinkedIn`, count: linkedIn, path: 'linkedin' },
    { name: `X (Twitter)`, count: twitter, path: 'twitter', hidden: whitelabel.isWhiteLabelUser },
    { name: `Facebook`, count: facebook, path: 'facebook', hidden: whitelabel.isWhiteLabelUser },
    { name: `Instagram`, count: instagram, path: 'instagram', hidden: whitelabel.isWhiteLabelUser },
  ];
  const isPathExist = POSTS_TABS.find(({ path }) => path === postType);

  const changeSearchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(() => e.target.value);
  };

  if (!isPathExist) {
    return <Navigate to="/posts/linkedin" />;
  }

  return (
    <>
      <HeaderContainer>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <HeaderTitle>Posts</HeaderTitle>
            <HeaderDescription>
              Create, schedule, and automate social media content to boost your audience reach and engagement.
              {whitelabel.isWhiteLabelUser || (
                <>
                  <Link
                    leftIcon
                    underline
                    external
                    openNewTab
                    to="https://meetalfred.com/help/en/collections/1925817-schedule-publish-social-media-content"
                  >
                    Step by step guides
                  </Link>
                  <HeaderVideo src="https://player.vimeo.com/video/622841932" />
                </>
              )}
            </HeaderDescription>
          </Box>
          <Box pt="20px">
            <HeaderButton onClick={() => navigate('/posts/new', { state: { postType } })}>Create New Post</HeaderButton>
          </Box>
        </Box>
        <Box display="flex" width="100%">
          <TabsContainer>
            {POSTS_TABS.map(
              (tab) =>
                tab.hidden || (
                  <HeaderTabLink
                    key={tab.path}
                    pathname={location.pathname + location.search}
                    to={`/posts/${tab.path}`}
                  >
                    {tab.name}
                    <HeaderTabLinkCounter>{tab.count}</HeaderTabLinkCounter>
                  </HeaderTabLink>
                ),
            )}
          </TabsContainer>
          <Box display="flex" margin="0 0 0 auto" data-testid="status-select">
            <SelectBasicGray
              aria-label="status-select"
              label="Status:"
              value={filter}
              onChange={(newFilter) => setFilter(newFilter.value)}
              options={POST_TYPE_OPTIONS}
              minWidth="180px"
              valueContainerPosition="space-between"
            />
          </Box>
        </Box>
      </HeaderContainer>
      <Box px="15px" pt="15px" pb="80px">
        <Box mb="20px">
          <StyledInput name="search" placeholder="Search Posts" onChange={changeSearchHandler} />
        </Box>

        <PostsTable search={searchDebounced} status={filter} />
      </Box>
    </>
  );
};
