import { UseFormReturn } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { ReactComponent as ExternalIcon } from 'src/assets/icons/external.svg';
import { Button, Divider, MessageBlock, Typography } from 'src/components';
import { BILLING_TYPE_NAMES, PLAN_SEATS, PLAN_SUB_DESCRIPTIONS } from 'src/constants';
import { BillingTypeEnum, CurrencyEnum, Plan } from 'src/enums';
import { capitalizeFirstLetter } from 'src/helpers';
import { useGetTeamCount, useTeamPlan } from 'src/hooks';
import { useGetBillingInfo, useGetPlanPricing } from 'src/reactQueries';
import { ICreateSubscription } from 'src/types';
import { BlockTitle, PlanPrice } from '../../_components';
import { BillingCycleSelect } from './BillingCycleSelect';
import { SeatsInput } from './SeatsInput';

interface PlanDetailsEditorProps extends UseFormReturn<ICreateSubscription> {
  plan: Plan;
  billingType: BillingTypeEnum;
  currency: CurrencyEnum;
  seats: number;
  disabled?: boolean;
  seatsOnly?: boolean;
}

const PlanDetailsWrapper = styled(Box)`
  border: 1px solid ${({ theme }) => theme.palette.accent[500]};
  border-radius: 4px;
`;

export const PlanDetailsEditor = ({
  plan,
  billingType,
  currency,
  seats,
  disabled = false,
  seatsOnly = false,
  setValue,
  setError,
  clearErrors,
  formState: { errors },
  trigger,
}: PlanDetailsEditorProps) => {
  const navigate = useNavigate();

  const { palette } = useTheme();

  const { isDeactivated } = useTeamPlan();

  const teamCount = useGetTeamCount();

  const { billingInfo } = useGetBillingInfo();

  const { data: price, isLoading: isLoadingPlanPricing } = useGetPlanPricing({
    plan,
    billingType,
    currency,
  });

  const { initialSeatsPrice = 1, extraSeatPrice = 1, minSeats = 1 } = price || {};

  const extraSeats = Math.max(seats - minSeats, 0); // prevent extra seats from being negative
  const extraSeatsPrice = extraSeats * extraSeatPrice;

  const isSeatsInputShown = [Plan.BUSINESS, Plan.AGENCY, Plan.ENTERPRISE].includes(plan);

  const onSeatsNumberChange = (value: number) => setValue('seats', value);
  const onSeatsErrorChange = (error?: string) => {
    if (!error) {
      clearErrors('seats');

      return trigger('seats');
    }

    setError('seats', { type: 'custom', message: error });
  };

  const onBillingTypeChange = (value: BillingTypeEnum) => setValue('billingType', value);

  const onViewTeamUserClick = () => navigate(isDeactivated ? '/deactivated#team' : '/team/manage-team');

  return (
    <>
      <PlanDetailsWrapper mb="24px">
        <Box p="20px" bgcolor={`${palette.gray[50]}80`}>
          <BlockTitle capitalizeFirstLetter>{plan}</BlockTitle>

          <Typography color="gray.800" fontSize="15px" mt="12px">
            {plan && PLAN_SUB_DESCRIPTIONS[plan]}
          </Typography>
        </Box>

        <Box p="20px">
          <PlanPrice price={initialSeatsPrice} currency={currency} size="30px" isLoading={isLoadingPlanPricing} />
          <Typography color="gray.500" fontSize="15px" mt="12px">
            Per {minSeats} seat{minSeats > 1 ? 's' : ''}, per month, billed{' '}
            {BILLING_TYPE_NAMES[billingType]?.toLowerCase()}
          </Typography>
        </Box>
      </PlanDetailsWrapper>

      {plan && PLAN_SEATS[plan]?.seats < teamCount && (
        <MessageBlock
          type="error"
          wrapperProps={{ mb: '24px' }}
          message="To downgrade to an Individual plan, please remove other users from your team"
          messageProps={{ color: 'gray.800', medium: true }}
          bottomContent={
            <Box display="flex" alignItems="center" gap="40px">
              <Typography color="gray.600" mr="auto">
                The Individual plan is meant for individual use and does not support multiple users or team features.
              </Typography>
              <Button variant="gray" onClick={onViewTeamUserClick}>
                <ExternalIcon style={{ marginRight: '4px', verticalAlign: 'sub' }} />
                View team users
              </Button>
            </Box>
          }
        />
      )}

      {isSeatsInputShown && (
        <Box p="20px" borderRadius="4px" border={`1px solid ${palette.gray[100]}`} mb="24px">
          <BlockTitle mb="24px">Customize your {capitalizeFirstLetter(plan)} plan</BlockTitle>

          {!!seats && (
            <SeatsInput
              plan={plan}
              value={seats}
              teamCount={teamCount}
              disabled={disabled}
              onChange={onSeatsNumberChange}
              onError={onSeatsErrorChange}
              error={errors.seats?.message}
              isCustomPlan={!!billingInfo?.hasCustomPlan}
            />
          )}

          <Divider my="24px" />

          <PlanPrice price={extraSeatsPrice} currency={currency} size="30px" isLoading={isLoadingPlanPricing} />
          <Typography color="gray.500" fontSize="15px" mt="12px">
            Per {extraSeats} seat{extraSeats > 1 ? 's' : ''}, per month, billed{' '}
            {BILLING_TYPE_NAMES[billingType]?.toLowerCase()}
          </Typography>
        </Box>
      )}

      <Box p="20px" borderRadius="4px" border={`1px solid ${palette.gray[100]}`} mb="24px">
        <BlockTitle mb="24px">Billing cycle</BlockTitle>

        <BillingCycleSelect
          plan={plan}
          billingType={billingType}
          onBillingTypeChange={onBillingTypeChange}
          disabled={disabled || seatsOnly}
        />
      </Box>
    </>
  );
};
