import { CSSProperties, useState } from 'react';
import Select, { components, SingleValue, StylesConfig } from 'react-select';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faCaretDown, faCaretUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Palette } from '@mui/material';

import { Typography } from 'src/components';

interface ISelectValue {
  label: string;
  value: string;
}

const getStyles = (palette: Palette): StylesConfig<ISelectValue> => ({
  container: (provided) => ({
    ...provided,
    display: 'flex',
    flex: 1,
    marginLeft: 10,
  }),
  control: (provided) => ({
    ...provided,
    border: '2px solid var(--light-dark) !important',
    borderRadius: 5,
    boxShadow: 'none',
    display: 'flex',
    flex: 1,
    height: 33,
    outline: 'none',
  }),
  input: (provided) => ({
    ...provided,
    color: '#333',
    fontFamily: 'ProximaSoft',
    fontSize: 14,
    fontWeight: 400,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'var(--violet-main)',
    fontFamily: 'ProximaSoft',
    fontSize: 14,
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: '10px',
    paddingBottom: '10px',
  }),
  option: (provided, { isSelected }) => ({
    ...provided,
    backgroundColor: '#fff',
    color: isSelected ? palette.primary.main : palette.text.primary,
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
    fontFamily: 'ProximaSoft',
    fontSize: '14px',
    justifyContent: 'space-between',
    padding: '6px 20px',
    '&:hover': {
      color: palette.primary.main,
    },
    '&:active': {
      backgroundColor: '#fff',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#333',
    fontFamily: 'ProximaSoft',
    fontSize: 14,
  }),
});

const ClearIndicator = styled.div`
  cursor: pointer;
  color: #999;
`;

interface IDatasetSelect {
  options: ISelectValue[];
  value: string;
  onChange: (newValue: SingleValue<ISelectValue>) => void;
}

export const DatasetSelect = ({ onChange, options, value }: IDatasetSelect) => {
  const { palette } = useTheme();
  const [isOpened, setIsOpened] = useState(false);

  return (
    <Select
      isClearable
      placeholder="Select Action"
      value={options.find((option) => option.value === value)}
      onMenuOpen={() => setIsOpened(true)}
      onMenuClose={() => setIsOpened(false)}
      onChange={(newValue) => onChange(newValue as SingleValue<ISelectValue>)}
      styles={getStyles(palette)}
      options={options}
      noOptionsMessage={() => (
        <Typography align="left" margin="10px" semibold>
          No results found
        </Typography>
      )}
      components={{
        DropdownIndicator: () => {
          return (
            <Box width="25px" marginRight="5px" display="flex" justifyContent="center">
              <FontAwesomeIcon
                cursor="pointer"
                icon={isOpened ? faCaretUp : faCaretDown}
                color={palette.primary.main}
              />
            </Box>
          );
        },
        Option: ({ children, ...props }) => {
          return (
            <components.Option {...props}>
              <>
                {children}
                {props.isSelected && <FontAwesomeIcon icon={faCheck} color={palette.primary.main} />}
              </>
            </components.Option>
          );
        },
        ClearIndicator: (props) => {
          const { getStyles, innerProps } = props;
          const { ref, ...restInnerProps } = innerProps;

          return (
            <ClearIndicator {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props) as CSSProperties}>
              <div style={{ padding: '0px 5px' }}>×</div>
            </ClearIndicator>
          );
        },
      }}
    />
  );
};
