import classNames from 'classnames';
import { FieldValues, UseFormRegister, UseFormReturn } from 'react-hook-form';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Checkbox, ErrorMessage, FormInput, Typography } from 'src/components';
import { TEmailIMAPForm } from './OtherProviderForm';

const FormColumns = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
`;

interface IMAPFormProps {
  form: UseFormReturn<TEmailIMAPForm>;
}

export const IMAPForm = ({ form }: IMAPFormProps) => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = form;
  const formError = Object.values(errors).find((value) => value?.message);

  return (
    <Box>
      <FormColumns mt="24px">
        <FormInput
          label="IMAP Server"
          name="imapServer"
          type="email"
          placeholder="imap.gmail.com"
          parameters={{
            required: 'Please enter an IMAP Server',
            onBlur: () => setValue('imapServer', getValues('imapServer').trim()),
          }}
          register={register}
          className={classNames({ error: errors.imapServer })}
        />
        <FormInput
          label="IMAP Username"
          name="username"
          placeholder="imap-username"
          parameters={{
            required: 'Please enter an IMAP Username',
            onBlur: () => setValue('username', getValues('username').trim()),
          }}
          register={register}
          className={classNames({ error: errors.username })}
        />
        <FormInput
          label="IMAP Password "
          name="password"
          type="password"
          autoComplete="on"
          placeholder="Set Your Password"
          parameters={{
            required: 'Please enter a Password',
            onBlur: () => setValue('password', getValues('password').trim()),
          }}
          register={register}
          className={classNames({ error: errors.password })}
        />
      </FormColumns>
      <FormColumns mt="34px">
        <Box>
          <FormInput
            name="port"
            placeholder="993"
            maxLength={5}
            parameters={{
              required: 'Please enter a Port',
              pattern: {
                value: /[1-9]*/,
                message: 'Please enter a valid number Port',
              },
              onBlur: () => {
                const value = getValues('port');
                setValue('port', value && (Number(String(value)?.trim()) || 0));
              },
              validate: (port) => (Number(port) >= 0 && Number(port) <= 65535) || 'Please enter a valid port',
            }}
            register={register}
            className={classNames({ error: errors.port })}
            preTab={
              <>
                <Typography inline color="#BFBFBF">
                  Port{' '}
                </Typography>
                <Typography inline color="error.light">
                  *
                </Typography>
              </>
            }
          />
        </Box>
        <Box display="flex" alignItems="center" pb="5px">
          <Checkbox
            type="checkbox"
            register={register as unknown as UseFormRegister<FieldValues>}
            name="isTlsEnabled"
          />
          <Typography ml="8px">Enable start TLS/SSL</Typography>
        </Box>
      </FormColumns>
      {!!formError?.message && (
        <Box mt="15px">
          <ErrorMessage>{formError?.message}</ErrorMessage>
        </Box>
      )}
    </Box>
  );
};
