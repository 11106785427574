import { useForm } from 'react-hook-form';
import { Box } from '@mui/material';

import { Button } from 'src/components';
import { useGetUserAccount, useSetCredentials } from 'src/reactQueries';
import { IUpdateCredentials } from 'src/types';
import { Wrapper } from '../_components';
import { ChangeEmail } from './ChangeEmail';
import { ChangePassword } from './ChangePassword';

export const UpdateCredentials = () => {
  const { userMe } = useGetUserAccount();
  const { setCredentials, isLoading } = useSetCredentials();

  const form = useForm<IUpdateCredentials>();

  const onUpdateCredentials = (values: IUpdateCredentials) => {
    setCredentials(values);
  };

  return (
    <Wrapper>
      <ChangeEmail {...form} />
      <ChangePassword {...form} />
      {userMe?.isGhostUser && (
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={form.handleSubmit(onUpdateCredentials)} processing={isLoading}>
            Save
          </Button>
        </Box>
      )}
    </Wrapper>
  );
};
