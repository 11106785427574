import { useQuery, UseQueryOptions } from 'react-query';

import { getLeadActivities } from 'src/api';
import { ICustomAxiosError, IGetLeadActivitiesResponse, TUseGetLeadActivities } from 'src/types';

export const useGetLeadActivities = (
  { entityUrn, page, perPage }: TUseGetLeadActivities,
  options?: UseQueryOptions<IGetLeadActivitiesResponse, ICustomAxiosError>,
) => {
  const { data, isLoading, ...rest } = useQuery<IGetLeadActivitiesResponse, ICustomAxiosError>(
    ['get-lead-activities', entityUrn, page, perPage],
    () => getLeadActivities({ entityUrn, page, perPage }),
    options,
  );

  return {
    leadActivitiesData: data,
    isLoading,
    ...rest,
  };
};
