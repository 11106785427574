import { useMemo } from 'react';
import styled from '@emotion/styled';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { faCaretDown, faCaretUp, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary';

import { ActionButton, IActionButtonProps, Typography } from 'src/components';

export const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} {...props} />)`
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  background: #fff;

  &::before {
    display: none;
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  padding: 16px;
  border-top: 1px solid #e8e8e8;
`;

const StyledAccordionSummary = styled(MuiAccordionSummary)`
  background: #fff;
  padding: 28px 24px;
  border-radius: 8px;
  min-height: 88px;
  max-height: 88px;

  &.Mui-expanded {
    min-height: 88px;
    max-height: 88px;
  }
`;

const ButtonsContainer = styled(Box)<{ expanded: boolean }>`
  display: flex;
  gap: 16px;
  transition: 0.2s;

  visibility: ${({ expanded }) => (expanded ? 'visible' : 'hidden')};
  opacity: ${({ expanded }) => (expanded ? 1 : 0)};
`;

interface SummaryProps extends AccordionSummaryProps {
  status: 'applied' | 'pending';
  expanded: boolean;
  actionButton?: IActionButtonProps;
  withButtonWrapper?: boolean;
}

export const AccordionSummary = ({
  title,
  status,
  expanded,
  withButtonWrapper = true,
  actionButton,
  children,
  ...props
}: SummaryProps) => {
  const { icon, label } = useMemo(() => {
    switch (status) {
      case 'applied':
        return { icon: <FontAwesomeIcon size="sm" icon={faCircleCheck} color="#272A74" />, label: 'Applied' };
      case 'pending':
        return {
          icon: <FontAwesomeIcon size="sm" icon={faTriangleExclamation} color="#F5222D" />,
          label: 'Setup Pending',
        };
    }
  }, [status]);

  return (
    <StyledAccordionSummary {...props}>
      <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
        <Box display="flex" gap="36px" alignItems="baseline">
          <Typography fontSize="20px" lineHeight="32px" medium>
            {title}
          </Typography>
          <Box display="flex" gap="8px" alignItems="center">
            {icon}
            <Typography fontSize="16px" color="#8C8C8C" lineHeight="24px">
              {label}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" gap="24px" alignItems="center">
          {!withButtonWrapper ? (
            children
          ) : (
            <ButtonsContainer expanded={expanded} onClick={(event) => expanded && event.stopPropagation()}>
              {children}
            </ButtonsContainer>
          )}
          <ActionButton {...actionButton} icon={actionButton?.icon || (expanded ? faCaretUp : faCaretDown)} />
        </Box>
      </Box>
    </StyledAccordionSummary>
  );
};
