import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { BASE_WL_API_ULR } from 'src/constants';
import { useCompanyTheme, useWhiteLabel } from 'src/hooks';
import { Code, RequestFields, Response, Title } from '../_components';
import { GET_CAMPAIGN, UNAUTHORIZED } from '../_constants';

export const WebhookDocCampaigns = () => {
  const { shortCompanyName } = useCompanyTheme();
  const {
    whitelabel: { isAlfredDomain },
  } = useWhiteLabel();

  return (
    <Box>
      <Typography lineHeight="14px">
        This webhook can be used get all user campaigns, you can get all your {shortCompanyName} campaigns by using this
        webhook
      </Typography>
      <Title>
        Request URL <b>GET</b>
      </Title>
      <Code>
        {isAlfredDomain ? 'https://meetalfred.com' : BASE_WL_API_ULR}
        /api/integrations/webhook/campaigns?webhook_key=:webhook_key
      </Code>
      <Title>Type (optional)</Title>
      <Typography>
        Default value <b>active</b>
      </Typography>
      <Typography>
        You can get different type of campaigns by sending one of the next keywords as parameter in the GET request
      </Typography>
      <RequestFields field="active" description="Get active campaigns" />
      <RequestFields field="draft" description="Get draft campaigns" />
      <RequestFields field="archived" description="Get archived campaigns" />
      <RequestFields field="all" description="Get all campaigns" />
      <Title>Responses</Title>
      <Response type="success" title="200 OK" responses={GET_CAMPAIGN} />
      <Response mt="20px" type="error" title="401 Unauthorized" responses={UNAUTHORIZED} />
    </Box>
  );
};
