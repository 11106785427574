import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button, Typography } from 'src/components';
import { useAppSelector, useCompanyTheme } from 'src/hooks';
import { useGetInvitationToTeam } from 'src/reactQueries';

const LogoImage = styled.img`
  min-width: 50px;
`;

export const TeamInvite = () => {
  const navigate = useNavigate();
  const { logoIconBlue, shortCompanyName } = useCompanyTheme();
  const { inviteKey = '' } = useParams<{ inviteKey: string }>();
  const { isAuth } = useAppSelector((state) => state.user);

  const { data, isLoading: isProcessing } = useGetInvitationToTeam({
    inviteKey,
  });

  const onButtonPress = () => {
    navigate('/auth/signup', { state: { invitedEmail: data?.email } });
  };

  if (isAuth || !data || isProcessing) {
    return null;
  }

  return (
    <Box maxWidth="960px">
      <Box padding="15px" height="80px">
        <LogoImage alt={`${shortCompanyName} logo`} src={logoIconBlue} />
      </Box>

      <Box marginTop="60px" padding="30px 15px">
        <Typography marginTop="30px" fontSize="45px" lineHeight="50px" fontFamily="NeurialGrotesk" bold>
          Welcome to {shortCompanyName}
        </Typography>

        <Typography fontSize="16px" lineHeight="30px" fontWeight="500" fontFamily="NeurialGrotesk">
          {`Accept ${data.user.name}’s invite to join the ${data.team.name} team by signing
          in with your `}{' '}
          <strong>
            <u>{data.email}</u>
          </strong>{' '}
          email.
        </Typography>

        <Box marginTop="60px">
          <Button onClick={onButtonPress}>Sign up to accept your invite</Button>
        </Box>
      </Box>
    </Box>
  );
};
