import { useIntercom } from 'react-use-intercom';
import { Box } from '@mui/material';

import { Button, Typography } from 'src/components';
import { useCompanyTheme, useWhiteLabel } from 'src/hooks';

export const DifficultyOfUse = () => {
  const { showNewMessage } = useIntercom();
  const { whitelabel } = useWhiteLabel();
  const { shortCompanyName } = useCompanyTheme();

  return (
    <Box py="30px" px="40px" bgcolor="light.light" display="flex" justifyContent="space-between">
      <Box width="30%" display="flex" flexDirection="column" justifyContent="space-between">
        <Typography color="violet.dark" mb="20px">
          Have you checked out our ‘Get to know {shortCompanyName}’ videos. They explain the many ways you can use{' '}
          {shortCompanyName} to grow your business
        </Typography>
        {whitelabel.isWhiteLabelUser || (
          <Button
            size={{ width: '100%' }}
            onClick={() =>
              window.open('https://meetalfred.com/resources/videos', '_blank', 'noopener,noreferrer')?.focus()
            }
          >
            Watch the videos
          </Button>
        )}
      </Box>

      <Box width="30%" display="flex" flexDirection="column" justifyContent="space-between">
        <Typography color="violet.dark" mb="20px">
          Book a private demo with one of our specialist, who can help you with any issue you are experiencing with{' '}
          {shortCompanyName}.
        </Typography>
        <Button
          size={{ width: '100%' }}
          onClick={() =>
            window.open('https://calendly.com/meetalfred-jessika/privatedemo', '_blank', 'noopener,noreferrer')?.focus()
          }
        >
          Book a Demo
        </Button>
      </Box>

      <Box width="30%" display="flex" flexDirection="column" justifyContent="space-between">
        <Typography color="violet.dark" mb="20px">
          Chat with one of our customer support specialists, who will gladly guide you through any difficulties you are
          experiencing.
        </Typography>
        <Button size={{ width: '100%' }} onClick={() => showNewMessage('I want help from a specialist')}>
          Contact Support
        </Button>
      </Box>
    </Box>
  );
};
