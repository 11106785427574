import { useQuery, UseQueryOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { getRealUser } from 'src/api';
import { useAppSelector, useAuth } from 'src/hooks';
import { setRealImpersonateUser } from 'src/store';
import { ICustomAxiosError, IRealUser } from 'src/types';

export const useGetRealUser = (options?: UseQueryOptions<IRealUser, ICustomAxiosError>) => {
  const dispatch = useDispatch();

  const { logout } = useAuth();

  const { isAuth, isImpersonate } = useAppSelector((state) => state.user);

  const { data, ...rest } = useQuery<IRealUser, ICustomAxiosError>(['get-real-user'], getRealUser, {
    enabled: isAuth && isImpersonate,
    ...options,
    onSuccess: (data) => {
      if (data.needToLogout) {
        return logout();
      }

      options?.onSuccess?.(data);
      dispatch(setRealImpersonateUser(data));
    },
  });

  return { realImpersonateUser: data, ...rest };
};
