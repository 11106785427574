import { getAllCountries } from 'countries-and-timezones';
import { useForm } from 'react-hook-form';
import { Box } from '@mui/material';

import { HeaderButton, Typography } from 'src/components';
import { useAppSelector, useLinkedInIntegration } from 'src/hooks';
import { useGetRealUser } from 'src/reactQueries';
import { ILinkedInFormFieldValues } from 'src/types';
import { Divider, IntegrationContainer, Title } from '../ui';
import { LinkedInConnected } from './LinkedInConnected';
import { LinkedInLogin } from './LinkedInLogin';
import { SalesNavigatorContracts } from './SalesNavigatorContracts';
import { TryAgain } from './TryAgain';
import { VerifyCode } from './VerifyCode';

const COUNTRIES = Object.entries(getAllCountries()).map(([key, value]) => ({
  value: key,
  label: value?.name,
}));

interface LinkedInIntegrationProps {
  removeContainer?: boolean;
}

export const LinkedInIntegration = ({ removeContainer = false }: LinkedInIntegrationProps) => {
  const { country, linkedInEmail, isLi2FAActive, isLinkedinActive, isLinkedInLoginBeingVerified } = useAppSelector(
    (state) => state?.user?.profile,
  );

  const {
    register,
    control,
    formState: { errors, isValid },
    setValue,
    handleSubmit,
    getValues,
  } = useForm<ILinkedInFormFieldValues>({
    defaultValues: {
      email: linkedInEmail || '',
      password: '',
      countryCode: COUNTRIES.find(({ value }) => value === country),
      verificationCode: '',
    },
  });

  const {
    isShowTryAgainBlock,
    isShowVerificationBlock,
    isContractShown,
    isLinkedInConnected,
    isLinkedInConnectedSuccessfully,
    isLinkedInRestrictionWarningDetected,
    errorMessage,
    trySubmit,
    tryVerify,
    tryDelete,
    tryAgain,
    tryLIManualAgain,
    checkConnection,
    isCheckLoading,
    isLoadingUpdateCredentials,
    isLoadingVerifyCode,
    isLoadingDeleteCredentials,
    isPollingCloudBotStatus,
  } = useLinkedInIntegration({ setValue, getValues });

  const { realImpersonateUser } = useGetRealUser();

  const renderForm = () => {
    // verification status and verification code has not expired yet
    if (isShowVerificationBlock) {
      return (
        <VerifyCode
          tryDelete={tryDelete}
          isLoadingVerifyCode={isLoadingVerifyCode}
          isLoadingDeleteCredentials={isLoadingDeleteCredentials}
          register={register}
          tryVerify={handleSubmit(tryVerify)}
          verificationCodeError={errorMessage || errors.verificationCode?.message}
        />
      );
    }

    // verification status and verification code expired
    if (isShowTryAgainBlock) {
      return (
        <TryAgain
          tryAgain={tryAgain}
          tryDelete={tryDelete}
          isLoadingUpdateCredentials={isLoadingUpdateCredentials}
          isLoadingDeleteCredentials={isLoadingDeleteCredentials}
        />
      );
    }

    // LinkedIn account connected
    if (isLinkedInConnected) {
      return (
        <LinkedInConnected
          is2FAActive={!!isLi2FAActive}
          isLoadingUpdateCredentials={isLoadingUpdateCredentials}
          isLoadingDeleteCredentials={isLoadingDeleteCredentials}
          errorMessage={errorMessage}
          isConnectedSuccessfully={isLinkedInConnectedSuccessfully}
          isLinkedInRestrictionWarningDetected={isLinkedInRestrictionWarningDetected}
          tryDelete={tryDelete}
          tryAgain={tryAgain}
        />
      );
    }

    // LinkedIn login form in all other cases
    return (
      <LinkedInLogin
        control={control}
        countries={COUNTRIES}
        errors={errors}
        isLinkedinActive={isLinkedinActive}
        isLoading={isLoadingUpdateCredentials || isPollingCloudBotStatus}
        register={register}
        trySubmit={handleSubmit(trySubmit)}
        errorMessage={errorMessage}
        isConnectDisabled={!isValid}
      />
    );
  };

  return (
    <>
      <IntegrationContainer>
        {!removeContainer && (
          <>
            <Title>LinkedIn connection</Title>
            <Typography mt="12px" mb="30px">
              We need your Linkedin credentials to perform actions on your behalf. Your information is safe and secured.
            </Typography>
            <Divider mx="-20px" mb="20px" />
          </>
        )}

        {renderForm()}

        {realImpersonateUser?.isAdminImpersonate && !isLinkedInLoginBeingVerified && (
          <Box mt="30px">
            {errorMessage || !isLinkedInConnectedSuccessfully ? (
              <HeaderButton
                processing={isLoadingUpdateCredentials || isPollingCloudBotStatus}
                onClick={tryLIManualAgain}
              >
                Try LI Manual Login
              </HeaderButton>
            ) : (
              <HeaderButton onClick={() => checkConnection()} processing={isCheckLoading}>
                Check LI Connection
              </HeaderButton>
            )}
          </Box>
        )}
      </IntegrationContainer>

      {isContractShown && <SalesNavigatorContracts />}
    </>
  );
};
