import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { BASE_WL_API_ULR } from 'src/constants';
import { useCompanyTheme, useWhiteLabel } from 'src/hooks';
import { Code, RequestFields, Response, Title } from '../_components';
import { UNAUTHORIZED } from '../_constants';

const infoResponse = [
  {
    json: {
      campaign: 1000,
      linkedin_profile_url: 'https://www.linkedin.com/in/test-public-identifier/',
      email: 'test@testmail.com',
      any_custom_field: 'John Doe',
      other_custom_field: 'Test',
    },
  },
];

export const WebhookDocNewLead = () => {
  const { shortCompanyName } = useCompanyTheme();
  const {
    whitelabel: { isAlfredDomain },
  } = useWhiteLabel();

  const ADD_NEW_LEAD = [
    {
      json: {
        id: 1,
        message: `Well done! Your lead was added to your campaign in ${shortCompanyName}.`,
        success: true,
      },
    },
    {
      title: 'Linkedin profile url is invalid',
      json: {
        id: 0,
        message: 'Whoops, that is not a valid LinkedIn profile URL',
        success: false,
      },
    },
  ];

  return (
    <Box>
      <Typography lineHeight="14px">
        This webhook can be used to add lead to your {shortCompanyName} campaigns, you can add any lead to your
        respective {shortCompanyName}
        campaign by using this webhook
      </Typography>
      <Title>
        Request URL <b>POST</b>
      </Title>
      <Code>
        {isAlfredDomain ? 'https://meetalfred.com' : BASE_WL_API_ULR}
        /api/integrations/webhook/add_lead_to_campaign?webhook_key=:webhook_key
      </Code>
      <Title>
        Request Payload <b>application/json</b>
      </Title>
      <RequestFields
        type="integer"
        field="campaign (required)"
        description={`id of the ${shortCompanyName} campaign`}
      />
      <RequestFields
        type="string"
        field="linkedin_profile_url (required)"
        description="Linkedin profile url of the lead"
      />
      <RequestFields
        type="string"
        field="email (optional)"
        description="Email of the lead. Required only for Email campaigns created via CSV file."
      />
      <RequestFields
        type="string"
        field="csv_{custom_field_name} (optional)"
        description="Custom field added to the CSV file on the initial upload. Required only for campaigns created via CSV file."
      />
      <Title>Example payload</Title>
      <Response type="info" title="Add new lead example payload" responses={infoResponse} />
      <Title>Responses</Title>
      <Response type="success" title="200 OK" responses={ADD_NEW_LEAD} />
      <Response mt="20px" type="error" title="401 Unauthorized" responses={UNAUTHORIZED} />
    </Box>
  );
};
