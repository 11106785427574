import { ReactNode, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, BoxProps } from '@mui/material';

import { ReactComponent as CheckCircle } from 'src/assets/icons/check-circle.svg';
import { ReactComponent as Info } from 'src/assets/icons/info.svg';
import { ReactComponent as Warning } from 'src/assets/icons/warning-bold.svg';
import { Divider } from './Divider';
import { ITypographyProps, Typography } from './Typography';

interface MessageBlockProps {
  type?: 'success' | 'info' | 'error';
  message?: string | ReactNode;
  messageProps?: Partial<ITypographyProps>;
  wrapperProps?: Partial<BoxProps>;
  topContent?: ReactNode;
  bottomContent?: ReactNode;
  noBorder?: boolean;
}

const Wrapper = styled(Box)`
  padding: 15px 20px;
  border-radius: 6px;
`;

export const MessageBlock = ({
  type = 'info',
  message,
  noBorder = false,
  topContent,
  bottomContent,
  messageProps = {},
  wrapperProps = {},
}: MessageBlockProps) => {
  const { palette } = useTheme();

  const { color, Icon, background, border, iconColor } = useMemo(() => {
    switch (type) {
      case 'success': {
        return {
          color: palette.success[700],
          background: `${palette.success[200]}50`,
          border: palette.success[200],
          Icon: CheckCircle,
          iconColor: palette.success[500],
        };
      }
      case 'error': {
        return {
          color: palette.error[700],
          background: `${palette.error[200]}50`,
          border: palette.error[200],
          Icon: Warning,
          iconColor: 'none',
        };
      }

      default: {
        return {
          color: palette.accent[700],
          background: `${palette.accent[300]}20`,
          border: palette.accent[300],
          Icon: Info,
          iconColor: 'none',
        };
      }
    }
  }, [type]);

  return (
    <Wrapper bgcolor={background} border={`1px solid ${noBorder ? 'transparent' : border}`} {...wrapperProps}>
      {topContent}

      {topContent && message && <Divider my="16px" bgcolor={`${palette.gray[200]} !important`} />}

      <Box display="flex" alignItems="center" gap="10px">
        <Icon color={iconColor} style={{ flexShrink: 0 }} />

        <Typography fontSize="16px" lineHeight="20px" color={color} inline {...messageProps}>
          {message}
        </Typography>
      </Box>

      {bottomContent && message && <Divider my="16px" bgcolor={`${palette.gray[200]} !important`} />}

      {bottomContent}
    </Wrapper>
  );
};
