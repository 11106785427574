import { Table, TableBody, TableHead, TableHeadCell, TableRow } from 'src/components';
import { useGetTeamInvites } from 'src/reactQueries';
import { MembersTableRow } from './MembersTableRow';

export const InvitesTable = () => {
  const { teamInvites = [] } = useGetTeamInvites();

  return (
    <Table style={{ backgroundColor: 'transparent' }}>
      <TableHead>
        <TableRow>
          <TableHeadCell />
          <TableHeadCell />
          <TableHeadCell width="180px" />
          <TableHeadCell width="220px" />
          <TableHeadCell width="60px" />
        </TableRow>
      </TableHead>
      <TableBody style={{ backgroundColor: '#ffffff' }}>
        {teamInvites.map((inviteItem) => {
          return (
            <MembersTableRow
              key={inviteItem.id}
              email={inviteItem.email}
              name={inviteItem.name}
              role={inviteItem.role}
              userStatus={inviteItem.isRejected ? 'rejected' : 'pending'}
            />
          );
        })}
      </TableBody>
    </Table>
  );
};
