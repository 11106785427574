import { useState } from 'react';
import { Navigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { faEnvelope as regularFaEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faEnvelope as solidFaEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { Button } from 'src/components';
import { useCompanyTheme } from 'src/hooks';
import { useGetUserAccount, useResendVerificationEmail } from 'src/reactQueries';

const Card = styled.div`
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  max-width: 400px;
  background-color: #fff;

  @media (max-width: 420px) {
    left: 60%;
    min-width: 200px;
  }
`;

const CardHeader = styled.div`
  height: 80px;
  text-align: center;
  padding: 20px;
  padding-top: 0;
  font-size: 20px;
  font-weight: 700;
  border-bottom: 1px solid #ccc;
  color: #333;
`;

const CardHeaderIcon = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: 50px;
  height: 50px;
  color: #fff;
  border-radius: 50%;
  margin: 0 auto;
  top: -20px;
  line-height: 50px;
  text-align: center;
  font-size: 25px;
`;

const CardContentColumn = styled(Box)`
  text-align: center;
  color: #333;
  line-height: 16px;
`;

export const VerifyEmail = () => {
  const { shortCompanyName } = useCompanyTheme();
  const { userMe } = useGetUserAccount({ refetchOnMount: true });
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const { resendVerificationEmail, isLoading } = useResendVerificationEmail();

  const submit = () => {
    resendVerificationEmail();
    setIsButtonClicked((prevState) => !prevState);
  };

  if (userMe?.isEmailVerified) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <Card>
      <CardHeader>
        <CardHeaderIcon>
          <FontAwesomeIcon icon={solidFaEnvelope} />
        </CardHeaderIcon>
        Verify your Email
      </CardHeader>
      <Box padding="20px">
        <CardContentColumn>
          To ensure you'll get notifications from {shortCompanyName}, please verify your account by clicking the link we
          sent to your email.
          <br />
          <br />
        </CardContentColumn>
        <CardContentColumn>
          If you did not receive the email please check your Inbox and your Spam filters or click the following button
          to get a new email.
        </CardContentColumn>
        <Box mt="20px">
          {!isButtonClicked ? (
            <Button size={{ width: '100%' }} className="blue-background" onClick={submit} disabled={isLoading}>
              <FontAwesomeIcon icon={regularFaEnvelope} /> Click here to send the email again.
            </Button>
          ) : (
            <CardContentColumn>Email sent! please check your inbox or spam folder.</CardContentColumn>
          )}
        </Box>
      </Box>
    </Card>
  );
};
