import { useQuery, UseQueryOptions } from 'react-query';

import { getUserTwitterDetails } from 'src/api';
import { ICustomAxiosError, ITwitterDetails } from 'src/types';

export const useUserTwitterDetails = (options?: UseQueryOptions<ITwitterDetails, ICustomAxiosError>) => {
  return useQuery<ITwitterDetails, ICustomAxiosError>(['get-user-twitter-details'], getUserTwitterDetails, {
    retry: false,
    refetchOnMount: true,
    ...options,
  });
};
