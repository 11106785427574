import { useMutation, UseMutationOptions } from 'react-query';

import { getFacebookAccessToken } from 'src/api';
import { ICustomAxiosError, IGetFacebookAccessTokenResponse } from 'src/types';

export const useGetFacebookAccessToken = (
  options?: UseMutationOptions<IGetFacebookAccessTokenResponse, ICustomAxiosError>,
) => {
  const { mutate, ...rest } = useMutation('get-facebook-access-token', getFacebookAccessToken, options);

  return {
    getFacebookAccessToken: mutate,
    ...rest,
  };
};
