import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar, Box, useTheme } from '@mui/material';

import { ReactComponent as XMarkIcon } from 'src/assets/icons/x-mark.svg';
import { Drawer, HeaderTabLink, Link, Tooltip, Typography } from 'src/components';
import { isLinkedinEntityUrn } from 'src/helpers';
import { useAppSelector, useLocationState } from 'src/hooks';
import {
  useGetLeadActivities,
  useGetLeadInfo,
  useGetRealUser,
  useGetUserAccount,
  useGetUserPreferences,
} from 'src/reactQueries';
import { PersonalDetails, ToggleLead } from '../_components';
import { LeadActivities } from './LeadActivities';
import { LeadMessages } from './LeadMessages';
import { LeadNotes } from './LeadNotes';
import { LeadTags } from './LeadTags';

const ProfileImage = styled(Avatar)`
  width: 100px;
  height: 100px;
  border-radius: 1000px;
`;

const TabsWrapper = styled(Box)`
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray[100]};
  padding: 10px 24px 0;
`;

const HorizontalDivider = styled.hr`
  border-bottom: 1px solid ${({ theme }) => theme.palette.gray[100]};
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${({ theme }) => theme.palette.gray[100]};
`;

const LeadDrawer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const locationState = useLocationState();

  const entityUrn = searchParams.get('person') || '';

  const theme = useTheme();
  const { leadActivitiesData } = useGetLeadActivities(
    {
      entityUrn,
    },
    { enabled: !!entityUrn },
  );

  const { leadInfo } = useGetLeadInfo(entityUrn);
  const { userPreferences } = useGetUserPreferences();
  const { userMe } = useGetUserAccount();
  const { realImpersonateUser } = useGetRealUser();

  const conversation = useAppSelector((state) => state.conversation.selectedConversation);
  const { isImpersonate } = useAppSelector((state) => state.user);

  const { currentTitle, linkedInData, name = '', workEmail, twitterHandle } = leadInfo?.person ?? {};
  const leadName = name || workEmail || twitterHandle;
  const currTab = searchParams.get('tab');

  const { key, linkedInUrn, linkedInHandle } = leadInfo?.person || {};

  const linkedinUserKey = linkedInUrn || linkedInHandle || (isLinkedinEntityUrn(key) && key);
  const isInbox = window.location.href.indexOf('inbox') !== -1;

  const isLeadExcluded = !!leadInfo?.lead?.isExcluded || !!leadInfo?.userNetwork?.isExcluded;

  const tabs = [
    { label: 'Personal details', name: 'personal_details' },
    {
      label: 'Activities',
      name: 'activities',
      total: leadActivitiesData?.total,
    },
    {
      label: 'Messages',
      name: 'messages',
      isHide: isInbox,
      isDisabled:
        isImpersonate &&
        !userPreferences?.enableMembersInbox &&
        !userMe?.isGhostUser &&
        !realImpersonateUser?.isAlfredAdmin,
      tooltip: 'You are only allowed to view your own messages inbox.',
    },
  ];

  const tabContent = useMemo(() => {
    switch (currTab) {
      case 'personal_details':
        return (
          <>
            <Box p="16px 24px 0">
              <PersonalDetails person={leadInfo?.person} hideLinkedin />
            </Box>
            <HorizontalDivider />
            <Box p="24px 24px 16px">
              <LeadTags tags={leadInfo?.tags ?? []} entityUrn={entityUrn} />
            </Box>
            <HorizontalDivider />
            <Box p="24px 24px 80px 24px">
              <LeadNotes
                data-testid="notes"
                notes={leadInfo?.userNetwork.notes}
                entityUrn={entityUrn}
                userNetwork={leadInfo?.userNetwork}
              />
            </Box>
          </>
        );
      case 'activities':
        return (
          <Box p="20px 24px 80px 24px">
            <LeadActivities entityUrn={entityUrn} />
          </Box>
        );
      case 'messages':
        return (
          <Box p="24px">
            <LeadMessages
              conversationId={leadInfo?.userNetwork.conversationId ?? conversation?.conversationId ?? ''}
              entityUrn={entityUrn}
              imageUrl={leadInfo?.person?.linkedInData.pic ?? ''}
              name={leadInfo?.person?.name ?? ''}
            />
          </Box>
        );
      default:
        return null;
    }
  }, [currTab, leadInfo, entityUrn, conversation]);

  const onClose = () => {
    setSearchParams({}, { replace: true, state: locationState });
  };

  if (!entityUrn) {
    return null;
  }

  return (
    <Drawer isOpen={!!entityUrn} onClose={onClose}>
      <Box overflow="scroll" height="100%" width="480px">
        <Box p="24px 24px 16px">
          <Box display="flex" gap="16px" alignItems="center" height="100px">
            <ProfileImage src={linkedInData?.pic} sx={{ backgroundColor: theme.palette.gray[100] }}>
              {' '}
            </ProfileImage>
            <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between" maxWidth="220px">
              {!!leadName && (
                <Typography crop semibold color="gray.dark" fontSize="20px" lineHeight="22px" mb="8px">
                  {leadName}
                </Typography>
              )}
              {!!currentTitle && (
                <Typography
                  medium
                  inline
                  fontSize="16px"
                  color={theme.palette.gray[600]}
                  lineHeight="16px"
                  fontWeight="400"
                >
                  {currentTitle}
                </Typography>
              )}
              {!isEmpty(linkedInData) && (
                <Box display="flex" gap="9px" alignItems="center" mt="12px">
                  <FontAwesomeIcon icon={faLinkedin} color="#0072B1" size="lg" />
                  <Box>
                    <Typography fontSize="14px" lineHeight="14px">
                      <Link
                        tooltip="View profile on Linkedin"
                        to={`https://www.linkedin.com/in/${linkedinUserKey}`}
                        openNewTab
                        external
                        rightIcon
                        variant="lightBlue"
                      >
                        View LinkedIn Profile
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
            <Box display="flex" flex="1" justifyContent="flex-end" alignSelf="flex-start" gap="10px">
              <ToggleLead entityUrn={entityUrn} isExcluded={isLeadExcluded} />

              <VerticalDivider />

              <XMarkIcon
                cursor="pointer"
                onClick={onClose}
                color={theme.palette.gray[600]}
                width="20px"
                height="20px"
              />
            </Box>
          </Box>
        </Box>

        <HorizontalDivider />

        <TabsWrapper>
          {tabs.map(({ label, name, isDisabled, tooltip, isHide: isShow, total }) => {
            if ((isDisabled || isShow) && currTab === name) {
              return (
                <Navigate
                  key={name}
                  to={`${location.pathname}?person=${entityUrn}&tab=personal_details`}
                  replace
                  state={locationState}
                />
              );
            }

            if (isShow) {
              return null;
            }

            return (
              <Tooltip title={isDisabled && tooltip} key={name}>
                <HeaderTabLink
                  data-testid={`tab-${name}`}
                  disabled={isDisabled}
                  to={`${location.pathname}?person=${entityUrn}&tab=${isDisabled ? currTab : name}`}
                  isActive={name === currTab}
                  state={locationState}
                >
                  {label} {total && <span>{total}</span>}
                </HeaderTabLink>
              </Tooltip>
            );
          })}
        </TabsWrapper>
        {tabContent}
      </Box>
    </Drawer>
  );
};

export default LeadDrawer;
