import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { HeaderContainer, HeaderDescription, HeaderTitle, HeaderVideo, Link } from 'src/components';
import { useCompanyTheme, useWhiteLabel } from 'src/hooks';
import { Title, Wrapper } from '../_components';
import { ChangeUserInfo } from './ChangeUserInfo';
import { UpdateCredentials } from './UpdateCredentials';

const ContentWrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
`;

export const UserProfile = () => {
  const { whitelabel } = useWhiteLabel();
  const { shortCompanyName } = useCompanyTheme();

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>User Profile</HeaderTitle>
        <HeaderDescription>
          Easily edit your {shortCompanyName} account information and manage your login details.
          {whitelabel.isWhiteLabelUser || (
            <>
              <Link
                leftIcon
                underline
                external
                openNewTab
                to="https://help.meetalfred.com/en/articles/5694731-manage-your-user-profile"
              >
                Learn how it works
              </Link>
              <HeaderVideo src="https://player.vimeo.com/video/651272120" />
            </>
          )}
        </HeaderDescription>
      </HeaderContainer>
      <ContentWrapper>
        <Wrapper>
          <Title>Change basic information</Title>
          <ChangeUserInfo />
        </Wrapper>
        <UpdateCredentials />
      </ContentWrapper>
    </>
  );
};
