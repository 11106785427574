import { isEmpty } from 'lodash';

import { CampaignCategory, CampaignType } from 'src/enums';
import { IGetCampaign, TCampaignPageType } from 'src/types';

interface IGetCampaignTypes {
  category?: CampaignCategory;
  campaignType?: CampaignType;
  isLinkedinOnly?: boolean;
}

interface IUseCampaignType {
  category?: CampaignCategory;
  campaignType?: CampaignType;
  generalCampaignTypeLabel: string;
  isLinkedinOnly: boolean;
  isLinkedinCampaign: boolean;
  isEmailCampaign: boolean;
  isTwitterCampaign: boolean;
  isMultichannelCampaign: boolean;
  isCsvUploadCampaign: boolean;
  isConnectionsCampaign: boolean;

  isSalesNavigatorCampaign: boolean;
  isLinkedInGroupCampaign: boolean;
  isLinkedInEventCampaign: boolean;
  isLinkedInConnectionsCampaign: boolean;
  isZapierCampaign: boolean;
}

const DEFAULT_VALUES: IUseCampaignType = {
  generalCampaignTypeLabel: '',
  isLinkedinOnly: false,
  isLinkedinCampaign: false,
  isEmailCampaign: false,
  isTwitterCampaign: false,
  isMultichannelCampaign: false,
  isCsvUploadCampaign: false,
  isConnectionsCampaign: false,

  isSalesNavigatorCampaign: false,
  isLinkedInGroupCampaign: false,
  isLinkedInEventCampaign: false,
  isLinkedInConnectionsCampaign: false,
  isZapierCampaign: false,
};

export const getCampaignTypes = (campaign?: IGetCampaignTypes): IUseCampaignType => {
  if (!campaign?.campaignType) {
    return DEFAULT_VALUES;
  }

  const { campaignType, category, isLinkedinOnly = false } = campaign;
  let generalCampaignTypeLabel = '';

  const isMultichannelCampaign = category === CampaignCategory.MULTICHANNEL;
  const isLinkedinCampaign = [
    CampaignCategory.LINKEDIN,
    CampaignCategory.INMAIL,
    CampaignCategory.POST,
    CampaignCategory.EVENT,
    CampaignCategory.GROUP,
  ].includes(category as CampaignCategory);
  const isEmailCampaign = [CampaignType.EMAIL_CSV_UPLOAD, CampaignType.EMAIL_CONNECTIONS].includes(campaignType);
  const isTwitterCampaign = [CampaignType.TWITTER_CSV_UPLOAD, CampaignType.TWITTER_CONNECTIONS].includes(campaignType);

  switch (true) {
    case category === CampaignCategory.POST:
      generalCampaignTypeLabel = 'Linkedin Post';
      break;
    case category === CampaignCategory.GROUP:
      generalCampaignTypeLabel = 'Linkedin Group';
      break;
    case category === CampaignCategory.EVENT:
      generalCampaignTypeLabel = 'Linkedin Event';
      break;
    case isLinkedinCampaign:
      generalCampaignTypeLabel = 'Linkedin Only';
      break;
    case isEmailCampaign:
      generalCampaignTypeLabel = 'Email Only';
      break;
    case isTwitterCampaign:
      generalCampaignTypeLabel = 'X (Twitter) Only';
      break;
    case isMultichannelCampaign:
      generalCampaignTypeLabel = 'Multichannel';
      break;
  }

  return {
    category,
    campaignType,
    generalCampaignTypeLabel,
    isLinkedinOnly,
    isLinkedinCampaign,
    isEmailCampaign,
    isTwitterCampaign,
    isMultichannelCampaign,
    isCsvUploadCampaign: [
      CampaignType.LINKEDIN_CSV_UPLOAD,
      CampaignType.EMAIL_CSV_UPLOAD,
      CampaignType.TWITTER_CSV_UPLOAD,
    ].includes(campaignType),
    isConnectionsCampaign: [
      CampaignType.LINKEDIN_CONNECTIONS,
      CampaignType.EMAIL_CONNECTIONS,
      CampaignType.TWITTER_CONNECTIONS,
    ].includes(campaignType),

    isSalesNavigatorCampaign: [CampaignType.LINKEDIN_SN_SEARCH, CampaignType.LINKEDIN_SN_SAVED_SEARCH].includes(
      campaignType,
    ),
    isLinkedInGroupCampaign: campaignType === CampaignType.LINKEDIN_GROUP,
    isLinkedInEventCampaign: campaignType === CampaignType.LINKEDIN_EVENT,
    isLinkedInConnectionsCampaign: campaignType === CampaignType.LINKEDIN_CONNECTIONS,
    isZapierCampaign: [CampaignType.ZAPIER_LEADS].includes(campaignType),
  };
};

export const getGeneralCampaignType = (campaignType: CampaignType) => {
  switch (campaignType) {
    case CampaignType.EMAIL_CONNECTIONS:
      return { type: 'email', label: 'Email' };
    case CampaignType.TWITTER_CONNECTIONS:
      return { type: 'twitter', label: 'X (Twitter)' };
    default:
      return { type: 'linked-in', label: '' };
  }
};

export const convertCampaignTypeToAudienceType = (campaign?: IGetCampaign): TCampaignPageType | '' => {
  const { campaignType, searchParameters } = campaign ?? {};

  switch (true) {
    case campaignType === CampaignType.LINKEDIN_SN_SEARCH:
    case campaignType === CampaignType.LINKEDIN_SEARCH && isEmpty(searchParameters):
      return 'url';
    case campaignType === CampaignType.LINKEDIN_SEARCH:
      return 'search';
    case campaignType === CampaignType.ZAPIER_LEADS:
      return 'zapier';
    case campaignType === CampaignType.LINKEDIN_POST:
      return 'post';
    case campaignType === CampaignType.LINKEDIN_EVENT:
      return 'event';
    case campaignType === CampaignType.LINKEDIN_SN_SAVED_SEARCH:
      return 'sales-navigator';
    case campaignType === CampaignType.LINKEDIN_GROUP:
      return 'group';
    case campaignType === CampaignType.EMAIL_CSV_UPLOAD:
    case campaignType === CampaignType.TWITTER_CSV_UPLOAD:
    case campaignType === CampaignType.LINKEDIN_CSV_UPLOAD:
      return 'csv-upload';
    default:
      return '';
  }
};
