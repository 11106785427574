import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';

import { Button } from 'src/components';
import { SequenceStep } from 'src/containers/Campaigns/_components';
import { TemplateAvailability } from 'src/enums';
import { closeModal } from 'src/store';
import { IModalProps, ISequenceTemplate } from 'src/types';
import { ModalBody, ModalFooter } from '../_components';

interface ModalViewSequenceTemplateProps {
  sequenceTemplate: ISequenceTemplate;
  availability: TemplateAvailability;
}

export const ModalViewSequenceTemplate = ({
  params: { sequenceTemplate, availability },
}: IModalProps<ModalViewSequenceTemplateProps>) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id, name, category, isAvailableForTeam, sequence } = sequenceTemplate;

  const onCancel = () => {
    dispatch(closeModal());
  };

  const onEditButtonClick = () => {
    navigate(`/sequence-templates/${id}`, { state: { name, category, sequence, isAvailableForTeam } });
    onCancel();
  };

  const onCloneButtonClick = () => {
    navigate(`/sequence-templates/new`, { state: { name: `${name} (clone)`, category, sequence, isAvailableForTeam } });
    onCancel();
  };

  return (
    <>
      <ModalBody>
        <Box maxHeight="500px" sx={{ overflowY: 'scroll' }}>
          {sequence?.map((step, index) => (
            <SequenceStep
              key={index}
              index={index}
              sequence={step}
              highlight={sequence.length !== 1 && index !== sequence.length - 1}
            />
          ))}
        </Box>
      </ModalBody>

      <ModalFooter>
        <Button variant="gray" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onCloneButtonClick} margin="0 15px 0 auto">
          Clone
        </Button>
        {availability === TemplateAvailability.personal && <Button onClick={onEditButtonClick}>Edit</Button>}
      </ModalFooter>
    </>
  );
};
