import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';

import { ReactComponent as TagIcon } from 'src/assets/icons/tag.svg';
import { Button, Checkbox, Divider, Input, Link, MessageBlock, Typography } from 'src/components';
import { PLAN_SEATS } from 'src/constants';
import { BillingTypeEnum, CurrencyEnum } from 'src/enums';
import { redirectToPaymentSuccessPage, resolveDefaultPaidPlanForSelector, resolveSeatsForPaidPlan } from 'src/helpers';
import { useCompanyTheme, useGetTeamCount, useLocationState, usePageListeners } from 'src/hooks';
import {
  useCreateSubscription,
  useGetBillingInfo,
  useGetInvoiceDetails,
  useGetUserAccount,
  useRemoveSubscription,
  useUpdateTeamDataAfterPaymentConfirmation,
} from 'src/reactQueries';
import { showToast } from 'src/store';
import { ICreateSubscription, ILocationStateCreateSubscription } from 'src/types';
import { BlockTitle } from '../../_components';
import { PaymentDetails } from '../../PaymentDetails';
import { PaymentDetailsForm } from '../PaymentDetailsForm';
import { PlanDetailsEditor } from '../PlanDetailsEditor';
import { CreateSubscriptionLayout } from './CreateSubscriptionLayout';

export const CreateSubscription = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { palette } = useTheme();
  const { shortCompanyName } = useCompanyTheme();

  const { userMe } = useGetUserAccount();
  const { billingInfo } = useGetBillingInfo();

  const stripe = useStripe();
  const elements = useElements();

  const locationState = useLocationState<ILocationStateCreateSubscription>();

  const teamCount = useGetTeamCount();

  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const [refundChecked, setRefundChecked] = useState(false);
  const [isPromoCodeInputShown, setIsPromoCodeInputShown] = useState(false);

  const form = useForm<ICreateSubscription>();

  const { watch, reset, setError, handleSubmit, formState, register } = form;
  const { errors, isValid, dirtyFields } = formState;

  useEffect(() => {
    if (billingInfo) {
      const resetCurrency = locationState?.currency || CurrencyEnum.USD;
      const resetBillingType = locationState?.billingType || BillingTypeEnum.MONTHLY;
      const resetPlan = resolveDefaultPaidPlanForSelector(locationState?.plan, teamCount);
      const resetSeats = resolveSeatsForPaidPlan(resetPlan, billingInfo.seats, teamCount, teamCount);

      reset({
        currency: resetCurrency,
        plan: resetPlan,
        billingType: resetBillingType,
        seats: resetSeats,
      });
    }
  }, [teamCount, billingInfo]);

  const plan = watch('plan');
  const billingType = watch('billingType');
  const seats = watch('seats');
  const currency = watch('currency');

  const { invoiceDetails, isFetching: isFetchingInvoiceDetails } = useGetInvoiceDetails(
    {
      plan,
      billingType,
      seats,
      currency,
    },
    { enabled: PLAN_SEATS[plan]?.seats >= teamCount },
  );

  const { removeSubscription, isLoading: isRemovingSubscription } = useRemoveSubscription();
  const { updateTeamDataAfterPaymentConfirmation } = useUpdateTeamDataAfterPaymentConfirmation();

  const { addUnloadConfirmation, removeUnloadConfirmation } = usePageListeners();

  const { createSubscription, isLoading: isCreatingSubscription } = useCreateSubscription({
    onError: (err) => {
      if (['inactive-promo-code', 'invalid-promo-code'].includes(err.message)) {
        setError('promoCode', {
          type: 'custom',
          message:
            err.message === 'invalid-promo-code'
              ? 'Please enter valid Promo Code'
              : 'This Promo Code has expired or been deactivated',
        });
      } else {
        setError('paymentMethod', { type: 'custom', message: err.message });
      }

      setIsProcessingPayment(false);
    },
    onSuccess: (data) => {
      if (!stripe) {
        return;
      }

      const { subscription, redirectUrlParams } = data;

      if (subscription?.latest_invoice?.payment_intent) {
        if (subscription?.latest_invoice?.payment_intent?.status !== 'succeeded') {
          setIsProcessingPayment(true);
          addUnloadConfirmation();

          stripe
            .confirmCardPayment(subscription.latest_invoice.payment_intent.client_secret, {
              payment_method: subscription.latest_invoice.payment_intent.payment_method,
            })
            .then((data) => {
              removeUnloadConfirmation();
              if (data.error) {
                dispatch(
                  showToast({
                    message:
                      data.error.message ||
                      'We are unable to authenticate your payment method. Please check payment details and try again',
                    type: 'error',
                    autoCloseTime: 3000,
                  }),
                );
                setError('paymentMethod', {
                  type: 'custom',
                  message:
                    'We are unable to authenticate your payment method. Please check payment details and try again',
                });
                removeSubscription();
              } else {
                updateTeamDataAfterPaymentConfirmation({ process: 'create' });
                redirectToPaymentSuccessPage(redirectUrlParams);
              }
            })
            .finally(() => setIsProcessingPayment(false));
        } else {
          redirectToPaymentSuccessPage(redirectUrlParams);
        }
      } else {
        redirectToPaymentSuccessPage(redirectUrlParams);
      }
    },
  });

  const onCancelClick = () => navigate('/billing/subscription');

  const isConfirmDisabled = useMemo(() => {
    if (!termsChecked || !refundChecked) {
      return true;
    }

    return (
      isFetchingInvoiceDetails ||
      isProcessingPayment ||
      !isValid ||
      PLAN_SEATS[plan]?.seats < teamCount ||
      !isEmpty(errors)
    );
  }, [termsChecked, refundChecked, isFetchingInvoiceDetails, isProcessingPayment, isValid, plan, teamCount, formState]);

  const onSubmit = async (values: ICreateSubscription) => {
    if (!stripe || !elements) {
      return;
    }

    setIsProcessingPayment(true);

    const cardElement = elements.getElement(CardElement) as StripeCardElement;

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: userMe?.name,
        email: userMe?.email,
        phone: values.phone,
        address: {
          line1: values.address,
        },
      },
    });

    if (error) {
      setError('paymentMethod', { type: 'custom', message: error.message });

      setIsProcessingPayment(false);
    } else {
      createSubscription({ ...values, paymentMethod });
    }
  };

  return (
    <>
      <CreateSubscriptionLayout />

      <Box p="20px 16px" display="flex" gap="16px" alignItems="flex-start">
        <Box bgcolor="#fff" padding="20px" width="100%" borderRadius="4px">
          <BlockTitle mb="24px">Your plan details</BlockTitle>

          <PlanDetailsEditor
            plan={plan}
            billingType={billingType}
            currency={currency}
            seats={seats}
            disabled={isFetchingInvoiceDetails}
            {...form}
          />

          <PaymentDetailsForm currency={currency} {...form} disabled={isFetchingInvoiceDetails} />

          <Box mt="24px" display="flex" flexDirection="column" gap="8px">
            <Link
              variant="lightBlue"
              onClick={() => setIsPromoCodeInputShown(true)}
              underline
              underlineColor={palette.brand[200]}
            >
              <TagIcon style={{ marginRight: '4px', verticalAlign: 'sub' }} />
              Do you have a promo code?
            </Link>
            {isPromoCodeInputShown && (
              <>
                <Input
                  name="promoCode"
                  register={register}
                  parameters={{ required: false }}
                  placeholder="Promo code"
                  required
                  className={classnames({
                    error: errors.promoCode?.message && dirtyFields.promoCode,
                  })}
                />
                {errors.promoCode?.message && (
                  <MessageBlock
                    type="error"
                    message={<Typography color="gray.600">{errors.promoCode.message}</Typography>}
                  />
                )}
              </>
            )}
          </Box>

          <Box my="24px">
            <Box display="flex" alignItems="center" gap="8px" mb="12px">
              <Checkbox type="checkbox" checked={termsChecked} onChange={(e) => setTermsChecked(e.target.checked)} />
              <Typography color="black.base">
                I accept {shortCompanyName}{' '}
                <Link external openNewTab to="https://meetalfred.com/terms" variant="lightBlue">
                  Terms of Use
                </Link>
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" gap="8px">
              <Checkbox type="checkbox" checked={refundChecked} onChange={(e) => setRefundChecked(e.target.checked)} />
              <Typography color="black.base">
                I accept {shortCompanyName}{' '}
                <Link
                  external
                  openNewTab
                  variant="lightBlue"
                  to="https://meetalfred.com/help/en/articles/3338757-refund-policy"
                  tooltip={
                    <Box textAlign="left">
                      <h5>No Refund Policy</h5>
                      Once upgraded, we do not offer any refunds under any circumstance, but you can cancel your
                      subscription at any time.
                    </Box>
                  }
                >
                  No Refund Policy
                </Link>
              </Typography>
            </Box>
          </Box>

          <Divider mb="24px" />

          <Box display="flex" justifyContent="space-between">
            <Button variant="gray" onClick={onCancelClick}>
              Cancel
            </Button>

            <Button
              disabled={isConfirmDisabled}
              onClick={handleSubmit(onSubmit)}
              processing={isCreatingSubscription || isRemovingSubscription}
            >
              Start Using {shortCompanyName}
            </Button>
          </Box>
        </Box>

        <PaymentDetails
          plan={plan}
          billingType={billingType}
          currency={currency}
          invoiceDetails={invoiceDetails}
          isLoadingInvoiceDetails={isFetchingInvoiceDetails}
          showInvoiceDetails={PLAN_SEATS[plan]?.seats >= teamCount}
        />
      </Box>
    </>
  );
};
