import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateCampaignName } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICampaign, ICustomAxiosError } from 'src/types';

export const useUpdateCampaignName = (
  campaignId: number,
  options?: UseMutationOptions<ICampaign, ICustomAxiosError, { name: string }>,
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(
    'update-campaign-name',
    ({ name }: { name: string }) => updateCampaignName({ campaignId, body: { name } }),
    {
      ...options,
      onMutate: async (variables) => {
        options?.onMutate?.(variables);
        await queryClient.cancelQueries(['campaign', campaignId]);

        const prevData = queryClient.getQueryData(['campaign', campaignId]);

        queryClient.setQueryData<ICampaign | undefined>(
          ['campaign', campaignId],
          (campaign) => ({ ...campaign, name: variables.name }) as ICampaign,
        );

        return { prevData };
      },
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        await queryClient.invalidateQueries(['campaign-activity-search-list'], { refetchInactive: true });
        await queryClient.invalidateQueries(['campaigns-dashboard'], { refetchInactive: true });
        await queryClient.invalidateQueries(['get-campaign-replies'], { refetchInactive: true, exact: false });
        await queryClient.invalidateQueries(['get-campaign-activity'], { refetchInactive: true, exact: false });
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueryData(['campaign', campaignId], context?.prevData);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return {
    updateCampaignName: mutate,
    ...rest,
  };
};
