import classNames from 'classnames';
import { CSSProperties, MouseEvent, PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import MuiTableCell, { TableCellProps } from '@mui/material/TableCell';

import { TJustifyContent, TWhiteSpace } from 'src/types';
import { Tooltip } from '../ui';

const StyledTableCell = styled(MuiTableCell)`
  padding: 10px;
  background-clip: padding-box;

  &.sticky {
    position: sticky;
    right: 0;
    background-color: #ffffff;
    padding: 0;

    & > div {
      padding: 10px;
    }
  }
`;

const InnerContent = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ whiteSpace: string }>`
  display: inline-block;
  font-family: 'ProximaSoft', sans-serif;
  font-size: 15px;
  letter-spacing: 0;
  white-space: ${({ whiteSpace }) => whiteSpace};
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ theme }) => theme.palette.gray.dark};
`;

interface ITableCellProps extends TableCellProps {
  tooltip?: ReactNode;
  tooltipPlacement?: 'top' | 'left' | 'right';
  preventClick?: boolean;
  contentStyle?: CSSProperties;
  justifyContent?: TJustifyContent;
  maxWidth?: string;
  whiteSpace?: TWhiteSpace;
  sticky?: boolean;
}

export const TableCell = ({
  children,
  tooltip,
  tooltipPlacement = 'top',
  preventClick,
  contentStyle,
  justifyContent,
  maxWidth,
  whiteSpace = 'nowrap',
  sticky = false,
  ...restProps
}: PropsWithChildren<ITableCellProps>) => {
  const ref = useRef<HTMLElement>(null);
  const [showToolTip, setShowToolTip] = useState<boolean>(true);

  useEffect(() => {
    const element = ref.current;
    if (element) {
      setShowToolTip(element.scrollWidth > element.offsetWidth);
    }
  }, []);

  return (
    <StyledTableCell variant="body" className={classNames({ sticky })} {...restProps}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={justifyContent}
        maxWidth={maxWidth}
        height="100%"
        boxSizing="border-box"
        {...(preventClick && { onClick: (event: MouseEvent<HTMLDivElement>) => event.stopPropagation() })}
      >
        <Tooltip title={showToolTip && tooltip} placement={tooltipPlacement}>
          <InnerContent maxWidth={maxWidth} whiteSpace={whiteSpace} style={contentStyle} ref={ref}>
            {children}
          </InnerContent>
        </Tooltip>
      </Box>
    </StyledTableCell>
  );
};
