import { Box } from '@mui/material';

import { Link } from 'src/components';
import { ReactComponent as EmptyImg } from '../../../assets/img/empty-inbox.svg';
import { Typography } from '../../../components/ui/Typography';

export const ConnectLinkedinPlaceholder = () => {
  return (
    <Box m="auto" textAlign="center" bgcolor="#fff" py="50px">
      <EmptyImg width="250px" height="163px" />
      <Typography fontSize="16px" mt="35px" textAlign="center">
        Please connect your linkedin credentials to sync your data.
      </Typography>
      <Typography mt="20px" fontSize="16px">
        <Link variant="title" semibold underline to="/integrations/linkedin">
          Click here
        </Link>{' '}
        to connect your LinkedIn account.
      </Typography>
    </Box>
  );
};
