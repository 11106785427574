import { useState } from 'react';
import { Box } from '@mui/material';

import { Table, TableBody, TableHead, TableHeadCell, TablePagination, TableRow } from 'src/components';
import { DEFAULT_PAGE } from 'src/constants';
import { useGetMemberCampaignsStats } from 'src/reactQueries';
import { TIntervalFilter } from 'src/types';
import { CampaignInfoTableRow } from './CampaignInfoTableRow';

const TABLE_COLUMNS = [
  { name: 'invites', label: 'Invites' },
  { name: 'connected', label: 'Connected' },
  { name: 'viewed', label: 'Viewed' },
  {
    name: 'messages',
    label: 'Messages',
    tooltip: 'This number includes Linkedin, InMail, Event, Group, Followed up messages, Emails and X (Twitter) DMs.',
  },
  { name: 'replies', label: 'Replies' },
  { name: 'reply rate', label: 'Reply rate' },
  { name: 'progress', label: 'Progress' },
];

interface ICampaignInfoTableProps {
  userId: number;
  timeFilter: TIntervalFilter;
  customFromDate: string;
  customToDate: string;
}

const DEFAULT_PER_PAGE = 10;

export const CampaignInfoTable = ({ userId, timeFilter, customFromDate, customToDate }: ICampaignInfoTableProps) => {
  const [page, setPage] = useState(DEFAULT_PAGE);

  const { data, isLoading } = useGetMemberCampaignsStats(userId, {
    timeFilter,
    customFromDate,
    customToDate,
    page,
    perPage: DEFAULT_PER_PAGE,
  });

  const paginationChangeHandler = ({ page }: { page: number }) => setPage(page);

  return (
    <Box display="flex" justifyContent="center" my="20px" data-testid="CampaignInfoTable">
      <Box maxWidth="83%">
        <Table
          processing={isLoading}
          hideHead={!data?.total}
          placeholder={!data?.total && "This team member doesn't have any active campaigns yet."}
        >
          <TableHead>
            <TableRow>
              <TableHeadCell width="20%">Campaign</TableHeadCell>
              {TABLE_COLUMNS.map(({ name, label, tooltip = '' }) => (
                <TableHeadCell key={name} tooltip={tooltip} width="10%">
                  {label}
                </TableHeadCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {data?.data?.map((campaign) => <CampaignInfoTableRow key={campaign.id} {...campaign} />)}
          </TableBody>
        </Table>
        <TablePagination
          page={page}
          perPage={DEFAULT_PER_PAGE}
          total={data?.total || 0}
          onPaginationChange={paginationChangeHandler}
          hideRowsPerPage
        />
      </Box>
    </Box>
  );
};
