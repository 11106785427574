import { UseFormSetValue } from 'react-hook-form';
import ReactToggle, { ToggleProps as ReactToggleProps } from 'react-toggle';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { TooltipProps } from '@mui/material';

import { Tooltip } from './Tooltip';

interface IToggleProps extends ReactToggleProps {
  defaultChecked?: boolean;
  name?: string;
  setValue?: UseFormSetValue<any>; // eslint-disable-line
  checkedBgColor?: string;
  uncheckedBgColor?: string;
  tooltip?: Omit<TooltipProps, 'children'>;
}

const Wrapper = styled.span`
  height: 20px;
`;

const StyledToggle = styled(
  ({
    checkedBgColor,
    uncheckedBgColor,
    ...props
  }: ReactToggleProps & {
    checkedBgColor: string;
    uncheckedBgColor: string;
  }) => <ReactToggle data-bgcolor-checked={checkedBgColor} data-bgcolor-unchecked={uncheckedBgColor} {...props} />,
)`
  &:not(.react-toggle--disabled) {
    &:hover {
      .react-toggle-track {
        background-color: ${(props) => props.uncheckedBgColor};
      }
    }
  }

  .react-toggle-track {
    width: 36px;
    height: 20px;
    border-radius: 16px;
    box-shadow: 0 0 2px 0 #00000040 inset;
    background-color: ${(props) => props.uncheckedBgColor};
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .react-toggle-thumb {
    height: 14px;
    width: 14px;
    border: none;
    top: 3px;
    left: 3px;
    box-shadow: 0 1px 0.5px 0 #00000033;
  }

  &.react-toggle--checked {
    &:hover {
      .react-toggle-track {
        background-color: ${(props) => props.checkedBgColor};
      }
    }

    .react-toggle-track {
      background-color: ${(props) => props.checkedBgColor};
    }

    .react-toggle-thumb {
      left: 19px;
      border-color: ${(props) => props.checkedBgColor};
    }
  }
`;

export const Toggle = ({
  name,
  setValue,
  checkedBgColor,
  uncheckedBgColor,
  tooltip = { title: '' },
  ...restProps
}: IToggleProps) => {
  const { palette } = useTheme();

  return (
    <Tooltip {...tooltip}>
      <Wrapper>
        <StyledToggle
          name={name}
          checkedBgColor={checkedBgColor || palette.primary.main}
          uncheckedBgColor={uncheckedBgColor || palette.lightGray.main}
          icons={false}
          onChange={(event) => name && setValue && setValue(name, event.target.checked)}
          {...restProps}
        />
      </Wrapper>
    </Tooltip>
  );
};
