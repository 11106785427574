import styled from '@emotion/styled';
import { Box } from '@mui/material';

import zapierStep1 from 'src/assets/img/campaign/zapier-step1.png';
import zapierStep2 from 'src/assets/img/campaign/zapier-step2.png';
import zapierStep3 from 'src/assets/img/campaign/zapier-step3.png';
import { HeaderButton, Typography } from 'src/components';
import { CampaignType } from 'src/enums';
import { useCompanyTheme, useSingleCampaignProps } from 'src/hooks';
import { useCreateCampaignAudience, useUpdateCampaignAudience } from 'src/reactQueries';
import { transformCampaignRequestBody } from 'src/transformers';
import { CampaignsSteps } from '../../CampaignSteps';

const Image = styled.img`
  width: 750px;
  margin-top: 20px;
`;

export const LinkedinZapier = ({ isLinkedinOnly }: { isLinkedinOnly: boolean }) => {
  const { shortCompanyName } = useCompanyTheme();
  const { campaignId, campaign, category } = useSingleCampaignProps();

  const { createCampaignAudience, isCreateLoading } = useCreateCampaignAudience();
  const { updateCampaignAudience, isUpdateLoading } = useUpdateCampaignAudience(campaignId);

  const submitHandler = () => {
    const body = transformCampaignRequestBody({
      category,
      campaignType: CampaignType.ZAPIER_LEADS,
      isLinkedinOnly: isLinkedinOnly,
    });

    if (campaign?.id) {
      updateCampaignAudience(body);
    } else {
      createCampaignAudience(body);
    }
  };

  return (
    <>
      <CampaignsSteps>
        <HeaderButton size={{ width: '180px' }} processing={isCreateLoading || isUpdateLoading} onClick={submitHandler}>
          Next
        </HeaderButton>
      </CampaignsSteps>
      <Box px="15px" py="50px">
        <Typography color="primary.wlLight" semibold>
          How to import Leads to {shortCompanyName} campaigns using Zapier integrations:
        </Typography>
        <Typography color="primary.wlLight" mt="30px">
          Select the Trigger app:
        </Typography>
        <Box mt="20px">
          <Typography fontSize="16px">- Go to Zapier and click on "MAKE A ZAP" button.</Typography>
          <Typography fontSize="16px">- Choose the app to generate the event.</Typography>
          <Typography fontSize="16px">- Select the Trigger event.</Typography>
          <Typography fontSize="16px">- Choose or login to your account.</Typography>
          <Typography fontSize="16px">
            - Specify the list of properties to retrieve, in this case Linkedin Profile Url and Email.
          </Typography>
          <Typography fontSize="16px">
            - Test the Trigger (Important: you have to activate the event to test the Trigger)
          </Typography>
        </Box>
        <Image src={zapierStep1} />
        <Typography color="primary.wlLight" mt="30px">
          Configure the action:
        </Typography>
        <Box mt="20px">
          <Typography fontSize="16px">- Select {shortCompanyName} in the list of Apps.</Typography>
          <Typography fontSize="16px">- Choose "Add Lead to Campaign" event.</Typography>
          <Typography fontSize="16px">
            - Choose or connect your {shortCompanyName} account using the Zapier api key
          </Typography>
          <Typography fontSize="16px">- Set up the action:</Typography>

          <Typography fontSize="16px">&nbsp;&nbsp;&nbsp;+ Select the Campaign where you want to add leads.</Typography>
          <Typography fontSize="16px">
            &nbsp;&nbsp;&nbsp;+ Select the property to be assigned as Linkedin Profile Url.
          </Typography>
          <Typography fontSize="16px">&nbsp;&nbsp;&nbsp;+ Select the property to be assigned as Email.</Typography>
          <Typography fontSize="16px">(See below image)</Typography>
          <Typography fontSize="16px">
            <b>Important note:</b> The properties must have these names: "Linkedin Profile Url" and "Email" from the
            Trigger app to work correctly
          </Typography>
        </Box>
        <Image src={zapierStep2} />
        <Typography color="primary.wlLight" mt="30px">
          Test the action:
        </Typography>
        <Typography mt="20px" fontSize="16px">
          After configuring fields, you can test the action which shows the status of test.
        </Typography>
        <Image src={zapierStep3} />
      </Box>
    </>
  );
};
