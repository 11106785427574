import dayjs from 'dayjs';
import { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import {
  DatePicker,
  HeaderContainer,
  HeaderDescription,
  HeaderTabLink,
  HeaderTabsContainer,
  HeaderTitle,
  HeaderVideo,
  Link,
} from 'src/components';
import { useWhiteLabel } from 'src/hooks';
import { TIntervalFilter } from 'src/types';
import { ActionsStats } from './ActionsStats';
import { ActivityTable } from './ActivityTable';
import { CampaignTable } from './CampaignTable';
import { RepliesTable } from './RepliesTable';

const DASHBOARD_TABS: { label: string; to: string; value: TIntervalFilter }[] = [
  { label: 'Today', to: 'today', value: 'today' },
  { label: 'Yesterday', to: 'yesterday', value: 'yesterday' },
  { label: 'This Week', to: 'this-week', value: 'thisWeek' },
  { label: 'This Month', to: 'this-month', value: 'thisMonth' },
  { label: 'This Year', to: 'this-year', value: 'thisYear' },
  { label: 'Custom', to: 'custom', value: 'custom' },
];

export const Dashboard = () => {
  const { tab } = useParams();
  const { whitelabel } = useWhiteLabel();
  const today = dayjs().format('YYYY-MM-DD');
  const [dateFrom, setDateFrom] = useState(today);
  const [dateTo, setDateTo] = useState(today);

  const timeFilter = DASHBOARD_TABS.find(({ to }) => tab === to)?.value;

  if (!DASHBOARD_TABS.some(({ to }) => to === tab)) {
    return <Navigate to="/dashboard/today" />;
  }

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Dashboard</HeaderTitle>
        <HeaderDescription>
          Analyze your campaign's performance and gain valuable insights for smarter decision-making.
          {whitelabel.isWhiteLabelUser || (
            <>
              <Link
                leftIcon
                underline
                external
                openNewTab
                to="https://help.meetalfred.com/en/articles/3321041-overview-of-the-meet-alfred-dashboard"
              >
                Learn how it works
              </Link>
              <HeaderVideo src="https://player.vimeo.com/video/641281561" />
            </>
          )}
        </HeaderDescription>
        <HeaderTabsContainer>
          {DASHBOARD_TABS.map(({ label, to }) => (
            <HeaderTabLink key={to} to={`/dashboard/${to}`}>
              {label}
            </HeaderTabLink>
          ))}
          {tab === 'custom' && (
            <Box display="flex" alignItems="center" gap="20px">
              <DatePicker
                type="date"
                value={dateFrom}
                placeholder="from"
                max={dateTo}
                onChange={(e) => e.target.value && setDateFrom(e.target.value)}
              />
              <FontAwesomeIcon icon={faArrowRight} />
              <DatePicker
                type="date"
                value={dateTo}
                placeholder="to"
                max={today}
                min={dateFrom}
                onChange={(e) => e.target.value && setDateTo(e.target.value)}
              />
            </Box>
          )}
        </HeaderTabsContainer>
      </HeaderContainer>

      <Box p="20px">
        <Box display="grid" gridTemplateColumns="3fr 2fr" gap="30px">
          <RepliesTable timeFilter={timeFilter} customFromDate={dateFrom} customToDate={dateTo} />
          <ActivityTable timeFilter={timeFilter} customFromDate={dateFrom} customToDate={dateTo} />
        </Box>

        <Box my="20px">
          <CampaignTable timeFilter={timeFilter} customFromDate={dateFrom} customToDate={dateTo} />
        </Box>

        <ActionsStats customFromDate={dateFrom} customToDate={dateTo} timeFilter={timeFilter} />
      </Box>
    </>
  );
};
