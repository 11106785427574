import { UseFormRegister } from 'react-hook-form';

import { Checkbox, Tooltip } from 'src/components';
import { useCompanyTheme } from 'src/hooks';
import { IInstagramPage } from 'src/types';
import { CheckboxContainer } from '../../_components';

interface IInstagramCheckboxProps {
  // eslint-disable-next-line
  register: UseFormRegister<any>;
  disabled?: boolean;
  instagramPages: IInstagramPage[];
}

export const InstagramCheckbox = ({ instagramPages, register, disabled = false }: IInstagramCheckboxProps) => {
  const { shortCompanyName } = useCompanyTheme();

  if (instagramPages?.length) {
    return (
      <CheckboxContainer>
        <Checkbox name="postTypes" register={register} type="checkbox" value="instagram" disabled={disabled} />
        <label>Instagram</label>
      </CheckboxContainer>
    );
  }

  return (
    <Tooltip
      title={
        <>
          Please connect at least one Instagram account to {shortCompanyName}.
          <br />
          Click on Integrations tab on top navigation bar.
        </>
      }
      placement="top"
      arrow
    >
      <CheckboxContainer>
        <Checkbox name="postTypes" register={register} type="checkbox" value="instagram" disabled />
        <label>Instagram</label>
      </CheckboxContainer>
    </Tooltip>
  );
};
