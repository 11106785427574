import { useDispatch } from 'react-redux';
import { faReply } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import {
  ActionButton,
  Button,
  Link,
  NotificationDot,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Typography,
} from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { CampaignActions, Features, ModalTypes } from 'src/enums';
import {
  getIconByAction,
  getRemovedNotificationText,
  getReturnToCampaignTooltip,
  resolvePersonName,
} from 'src/helpers';
import { useTeamPlan } from 'src/hooks';
import { useGetCampaignReplies, useReturnCampaignReplies } from 'src/reactQueries';
import { openModal } from 'src/store';
import { IGetCampaignRepliesRequest, IGetDashboardParams } from 'src/types';
import { Title, Wrapper } from './_components';

export const RepliesTable = (props: IGetDashboardParams) => {
  const dispatch = useDispatch();

  const { checkFeature } = useTeamPlan();
  const getRepliesKeys: IGetCampaignRepliesRequest = {
    ...props,
    perPage: 5,
    sortField: 'date',
    sortOrder: 'DESC',
  };
  const { returnCampaignReplies, isReturnLoading } = useReturnCampaignReplies({ getRepliesKeys });
  const { data, isLoading } = useGetCampaignReplies(getRepliesKeys, { refetchOnMount: true });
  const { replies = [] } = data ?? {};

  const modalHandler = (entityUrn: string, campaignId: number) => {
    dispatch(
      openModal({
        type: ModalTypes.VIEW_LEAD,
        closable: false,
        params: {
          campaignId,
          entityUrn,
        },
      }),
    );
  };

  return (
    <Wrapper minHeight="150px">
      <Box display="flex" justifyContent="space-between">
        <Title>Replies</Title>
        <Link to="/campaign/replies">
          <Button>Go to Replies</Button>
        </Link>
      </Box>
      <Box mt="30px" mb="20px">
        <Table processing={isLoading} disabled={isLoading}>
          <TableHead>
            <TableRow>
              <TableHeadCell width="55px" />
              <TableHeadCell>Name</TableHeadCell>
              <TableHeadCell width="40px" />
              <TableHeadCell>Message</TableHeadCell>
              <TableHeadCell>Campaign</TableHeadCell>
              <TableHeadCell width="60px" />
            </TableRow>
          </TableHead>
          <TableBody placeholder={!replies.length && <Typography fontSize="16px">No replies yet.</Typography>}>
            {replies.map(
              ({
                createdAt,
                description,
                campaignId,
                campaignName,
                isCampaignRemoved,
                isLeadRemoved,
                leadStatus,
                message,
                person,
                isReturnToCampaign,
                personKey,
              }) => {
                const { color, faIcon } = getIconByAction(description);
                const { name, avatarContent } = resolvePersonName({
                  ...person,
                  action: [CampaignActions.TWITTER_REPLY_DETECTED, CampaignActions.LINKEDIN_REPLY_DETECTED].includes(
                    description as CampaignActions,
                  )
                    ? description
                    : '',
                });

                const returnCampaignTooltip = checkFeature(Features.returnToCampaign)
                  ? getReturnToCampaignTooltip({
                      isReturnToCampaign,
                      isApproved: leadStatus === 'approved',
                      isCampaignRemoved,
                    })
                  : PLAN_TOOLTIPS.professional;
                const notificationText = getRemovedNotificationText({ isCampaignRemoved, isLeadRemoved });

                return (
                  <TableRow
                    key={createdAt}
                    hoverBgColor={({ light }) => light.main}
                    cursor="pointer"
                    onClick={() => modalHandler(personKey, campaignId)}
                  >
                    <TableCell justifyContent="center">{avatarContent}</TableCell>
                    <TableCell>{name}</TableCell>
                    <TableCell>
                      <FontAwesomeIcon icon={faIcon} color={color} style={{ fontSize: '20px' }} />
                    </TableCell>
                    <TableCell tooltip={message}>{message}</TableCell>
                    <TableCell tooltip={campaignName}>
                      <Link
                        variant="lightBlue"
                        fontSize="15px"
                        crop
                        to={`/campaign/details/overview/${campaignId}`}
                        onClick={(e) => e.stopPropagation()}
                        disabled={isCampaignRemoved}
                        cursor={isCampaignRemoved ? 'default' : 'pointer'}
                      >
                        <Box display="flex" alignItems="center">
                          <Box overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                            {campaignName}
                          </Box>

                          {campaignId && notificationText && (
                            <NotificationDot
                              data-testid="removed-dot"
                              tooltip={{ title: notificationText, placement: 'right' }}
                            />
                          )}
                        </Box>
                      </Link>
                    </TableCell>

                    <TableCell justifyContent="center">
                      <ActionButton
                        data-testid="return"
                        disabled={
                          !checkFeature(Features.returnToCampaign) ||
                          isReturnToCampaign ||
                          leadStatus !== 'approved' ||
                          isCampaignRemoved
                        }
                        icon={faReply}
                        processing={isReturnLoading}
                        onClick={(e) => {
                          e.stopPropagation();
                          returnCampaignReplies([person?.key]);
                        }}
                        tooltip={returnCampaignTooltip}
                      />
                    </TableCell>
                  </TableRow>
                );
              },
            )}
          </TableBody>
        </Table>
      </Box>
    </Wrapper>
  );
};
