import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { deletePost } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IGetPostsCount, IGetPostsResponse, TPostTypes } from 'src/types';

export const useDeletePost = (postType: TPostTypes, options?: UseMutationOptions<void, ICustomAxiosError, number>) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const getPostsKey = ['get-posts'];
  const getPostsCountKey = ['get-posts-count'];

  const { mutate, ...rest } = useMutation(['delete-post'], (postId: number) => deletePost(postId), {
    ...options,
    onMutate: async (postId) => {
      options?.onMutate?.(postId);
      await queryClient.cancelQueries(getPostsKey);
      await queryClient.cancelQueries(getPostsCountKey);

      const postsData = queryClient.getQueriesData(getPostsKey);
      const postCountData = queryClient.getQueriesData(getPostsCountKey);

      queryClient.setQueriesData<IGetPostsCount | undefined>(getPostsCountKey, (data) => {
        if (data) {
          return {
            ...data,
            [postType]: data[postType === 'linkedin' ? 'linkedIn' : postType] - 1,
          };
        }

        return data;
      });

      queryClient.setQueriesData<IGetPostsResponse | undefined>(getPostsKey, (data) => {
        if (data) {
          return {
            ...data,
            posts: data.posts.filter((post) => post.id !== postId),
            total: data.total - 1,
          };
        }

        return data;
      });

      return { postsData, postCountData };
    },
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      queryClient.invalidateQueries(getPostsKey);
      queryClient.invalidateQueries(getPostsCountKey);

      dispatch(
        showToast({
          type: 'success',
          message: 'Post deleted successfully',
          autoCloseTime: 3000,
        }),
      );
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      context?.postsData?.forEach(([key, data]) => queryClient.setQueryData(key, data));
      context?.postCountData?.forEach(([key, data]) => queryClient.setQueryData(key, data));

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return {
    deletePost: mutate,
    ...rest,
  };
};
