import { useDispatch } from 'react-redux';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import {
  DatePicker,
  HeaderContainer,
  HeaderDescription,
  HeaderTitle,
  HeaderVideo,
  Link,
  SelectBasicGray,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
} from 'src/components';
import { ACTIVITY_INTERVAL_OPTIONS } from 'src/constants';
import { useAppSelector, useCompanyTheme, useTimeIntervalOptions, useWhiteLabel } from 'src/hooks';
import { useGetCampaignActivity } from 'src/reactQueries';
import { updateActivityParams } from 'src/store/campaign.slice';
import { IGetCampaignActivityRequest, TActivitySortField, TSortOrder } from 'src/types';
import { ActivityTableRow } from './ActivityTableRow';
import { CampaignsSearchBar } from './CampaignsSearchBar';

export const CampaignsActivity = () => {
  const dispatch = useDispatch();
  const { whitelabel } = useWhiteLabel();
  const { shortCompanyName } = useCompanyTheme();

  const { activityParams } = useAppSelector(({ campaign }) => campaign);

  const updateParams = (params: Partial<IGetCampaignActivityRequest>) => dispatch(updateActivityParams(params));

  const { interval, dateTo, dateFrom, setDateFrom, setInterval, setDateTo, today } = useTimeIntervalOptions({
    onIntervalChange: () => updateParams({ page: 1 }),
  });

  const { page, perPage, sortField, sortOrder } = activityParams;

  const { data, isLoading } = useGetCampaignActivity(
    { ...activityParams, timeFilter: interval, customFromDate: dateFrom, customToDate: dateTo },
    { refetchOnMount: true },
  );
  const { actions = [], total = 0 } = data ?? {};

  const sortingProps = {
    sortField,
    sortOrder,
    onSortFieldChange: (sortField: TActivitySortField, sortOrder: TSortOrder) => updateParams({ sortField, sortOrder }),
  };

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Activity</HeaderTitle>
        <Box display="flex" pb="5px">
          <HeaderDescription>
            Monitor {shortCompanyName}'s activities, organized by leads, campaigns, or actions.
            {whitelabel.isWhiteLabelUser || (
              <>
                <Link
                  leftIcon
                  underline
                  external
                  openNewTab
                  to="https://help.meetalfred.com/en/articles/5615162-review-campaign-activities"
                >
                  Learn how it works
                </Link>
                <HeaderVideo src="https://player.vimeo.com/video/617467697" />
              </>
            )}
          </HeaderDescription>
          <Box display="flex" alignItems="center" justifyContent="flex-end" ml="auto">
            {interval === 'custom' && (
              <Box display="flex" alignItems="center" gap="10px" mr="10px">
                <DatePicker
                  type="date"
                  value={dateFrom}
                  placeholder="from"
                  max={dateTo}
                  onChange={(e) => e.target.value && setDateFrom(e.target.value)}
                />
                <FontAwesomeIcon icon={faArrowRight} />
                <DatePicker
                  type="date"
                  value={dateTo}
                  placeholder="to"
                  max={today}
                  min={dateFrom}
                  onChange={(e) => e.target.value && setDateTo(e.target.value)}
                />
              </Box>
            )}
            <SelectBasicGray
              label="Filter by:"
              value={interval}
              onChange={({ value }) => setInterval(value)}
              options={ACTIVITY_INTERVAL_OPTIONS}
              minWidth="180px"
              valueContainerPosition="space-between"
            />
          </Box>
        </Box>
      </HeaderContainer>

      <CampaignsSearchBar />
      <Box px="15px" pb="80px">
        <Table processing={isLoading} disabled={isLoading}>
          <TableHead>
            <TableRow>
              <TableHeadCell width="60px" />
              <TableHeadCell width="260px" name="name" sorting={sortingProps}>
                Name
              </TableHeadCell>
              <TableHeadCell width="35%" name="action" sorting={sortingProps}>
                Action
              </TableHeadCell>
              <TableHeadCell width="20%" name="campaign" sorting={sortingProps}>
                Campaign
              </TableHeadCell>
              <TableHeadCell width="200px" name="date" sorting={sortingProps}>
                Date
              </TableHeadCell>
              <TableHeadCell width="100px" sorting={sortingProps}>
                Time
              </TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody placeholder={!actions.length && 'No activity yet.'}>
            {actions.map((activity, index) => (
              <ActivityTableRow key={`${activity.createdAt}-${index}`} {...activity} />
            ))}
          </TableBody>
        </Table>
        <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={updateParams} />
      </Box>
    </>
  );
};
