import { isEmpty } from 'lodash';
import { Control, Controller } from 'react-hook-form';
import Select from 'react-select';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Typography } from 'src/components';

const ErrorMessage = styled(Typography)`
  position: absolute;
  color: ${({ theme }) => theme.palette.error.light};
`;

export type TControlledSingleSelectOption = {
  label: string;
  value?: string | number;
  color?: string;
  options?: { value: string; label: string; type: string }[];
};

export type TGroupOption = {
  label: string;
  options: { value: string; label: string; type: string }[];
};

interface IControlledSingleSelect {
  name: string;
  options?: TControlledSingleSelectOption[];
  label: string;
  placeholder: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
}

export const ControlledSingleSelect = ({
  name,
  options = [],
  label,
  placeholder,
  control,
}: IControlledSingleSelect) => {
  const { palette } = useTheme();

  const formatGroupLabel = (data: TGroupOption) => <Box>{data.label}</Box>;

  return (
    <Controller
      control={control}
      name={name}
      rules={{ required: true }}
      render={({ field: { onChange, value }, formState }) => {
        const isGrouped = 'options' in options[0];
        const selectedOption = options?.find((item) => (isGrouped ? item?.options?.[value] : item.value === value));
        const isErrors = !isEmpty(formState.errors);

        return (
          <Box position="relative" width="100%" pt="15px" pb="10px">
            <Typography color={palette.primary.wlLight} mb="5px">
              {label}
            </Typography>
            <Select
              isMulti={false}
              options={options}
              value={selectedOption}
              placeholder={placeholder}
              isSearchable={false}
              isClearable={true}
              formatGroupLabel={formatGroupLabel}
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderColor: `${isErrors ? palette.error.light : palette.light.dark} !important`,
                  boxShadow: 'none',
                }),
              }}
              onChange={(option) => {
                onChange(option?.value);
              }}
            />
            {isErrors && <ErrorMessage>Please select a Saved search.</ErrorMessage>}
          </Box>
        );
      }}
    />
  );
};
