import { useMutation, UseMutationOptions } from 'react-query';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'universal-cookie';

import { signUpViaEmail } from 'src/api';
import { COOKIE_TOKEN_NAME } from 'src/constants';
import { removeReferralCodeFromCookies, setCookie } from 'src/helpers';
import { setUser } from 'src/store/user.slice';
import { IAuthResponse, ICustomAxiosError, IEmailSignUpRequest } from 'src/types';

const cookies = new Cookies();

export const useSignUpViaEmail = (
  options?: UseMutationOptions<IAuthResponse, ICustomAxiosError, IEmailSignUpRequest>,
) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const { mutate, ...rest } = useMutation(
    'sign-up',
    (data: IEmailSignUpRequest) =>
      signUpViaEmail({
        ...data,
        landingUrl: cookies.get('alfred_landing_url'),
        utmSource: (cookies.get('alfred_utm_source') || []).join(','),
        lastPage: cookies.get('alfred_last_page'),
        pageCounter: cookies.get('alfred_page_counter'),
        referralCode: cookies.get('referralCode'),
        trialOrigin: searchParams.get('trial_btn') || '',
      }),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        setCookie(COOKIE_TOKEN_NAME, data?.authToken);

        removeReferralCodeFromCookies();

        dispatch(setUser(data?.user));
      },
    },
  );

  return {
    signUpViaEmail: mutate,
    ...rest,
  };
};
