import { useQuery, UseQueryOptions } from 'react-query';

import { getUserSignature } from 'src/api';
import { ICustomAxiosError, IUserSignature } from 'src/types';

export const useGetUserSignature = (options?: UseQueryOptions<IUserSignature, ICustomAxiosError>) => {
  const { data, ...rest } = useQuery<IUserSignature, ICustomAxiosError>(['user-signature'], getUserSignature, {
    refetchOnMount: true,
    ...options,
  });

  return {
    signature: data,
    ...rest,
  };
};
