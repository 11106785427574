import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { updateConnectionNotes } from 'src/api';
import { ICustomAxiosError, ILeadInfo } from 'src/types';

export const useUpdateConnectionNotes = (
  entityUrn: string,
  options?: UseMutationOptions<void, ICustomAxiosError, string>,
) => {
  const queryClient = useQueryClient();
  const getLeadInfoKey = ['get-lead-info', entityUrn];

  const { mutate, ...rest } = useMutation<void, ICustomAxiosError, string, { prevData: unknown }>(
    ['update-connection-notes', entityUrn],
    (notes: string) => updateConnectionNotes({ entityUrn, notes }),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        await queryClient.invalidateQueries(['post-leads'], { exact: false, refetchInactive: true });
      },
      onMutate: async (notes) => {
        options?.onMutate?.(notes);
        await queryClient.cancelQueries(getLeadInfoKey);

        const prevData = queryClient.getQueryData(getLeadInfoKey);

        queryClient.setQueryData<ILeadInfo | undefined>(getLeadInfoKey, (lead) => {
          if (lead) {
            return {
              ...lead,
              userNetwork: {
                ...lead.userNetwork,
                notes,
              },
            };
          }

          return lead;
        });

        return { prevData };
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueryData(getLeadInfoKey, context?.prevData);
      },
    },
  );

  return { updateNotes: mutate, ...rest };
};
