import { Box } from '@mui/material';

import { ReactComponent as EmptyImg } from 'src/assets/img/empty-connections.svg';
import { Link, Typography } from 'src/components';
import { useCompanyTheme } from 'src/hooks';

export const NotSynced = ({ isSyncInProgress }: { isSyncInProgress: boolean }) => {
  const { shortCompanyName } = useCompanyTheme();

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Typography fontSize="34px" mt="75px">
        Your LinkedIn contacts are not synced
      </Typography>
      <Typography fontSize="18px" mt="35px" textAlign="center">
        {shortCompanyName} gives you access all your LinkedIn contact data.
        <br />
        {isSyncInProgress
          ? 'Please wait until we sync your data.'
          : 'Please connect your linkedin credentials to sync your data.'}
      </Typography>
      <Box mt="80px">
        <EmptyImg width="250px" height="163px" />
      </Box>
      {!isSyncInProgress && (
        <Typography mt="20px">
          <Link variant="title" underline semibold to="/integrations/linkedin">
            Click here
          </Link>{' '}
          to connect your LinkedIn account.
        </Typography>
      )}
    </Box>
  );
};
