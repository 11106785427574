import ReactHtmlParser from 'html-react-parser';
import sanitize from 'sanitize-html';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

const HtmlParserWrapper = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(prop),
})<{ crop?: boolean | undefined }>`
  word-break: normal;
  overflow-wrap: anywhere;
  height: ${({ crop }) => crop && '1rem'};
  ${({ crop }) =>
    crop &&
    `white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;`}

  p {
    margin-top: 10px;
    ${({ crop }) =>
      crop &&
      `white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;`}
  }

  & > p:first-of-type {
    margin-top: 0;
  }

  ul,
  ol {
    margin-left: 20px;

    p {
      margin-top: 5px;
    }
  }
`;

interface HtmlParserProps {
  parseText?: string;
  crop?: boolean;
  options?: sanitize.IOptions;
}

export const HtmlParser = ({ parseText, crop, options = {} }: HtmlParserProps) => {
  if (!parseText || typeof parseText !== 'string') {
    return null;
  }

  return (
    <HtmlParserWrapper data-testid="html-parser" crop={crop}>
      {ReactHtmlParser(sanitize(parseText, options))}
    </HtmlParserWrapper>
  );
};
