import React from 'react';
import { RegisterOptions, UseFormRegister } from 'react-hook-form';
import styled from '@emotion/styled';
import { alpha, Box } from '@mui/material';

const Label = styled.label`
  font-family: 'ProximaSoft', sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3rem;
  color: #333;
  margin-bottom: 5px;
`;

const StyledTextArea = styled.textarea`
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  font-family: 'ProximaSoft', sans-serif;
  border: 1px solid ${({ theme }) => theme.palette.light.dark};
  border-radius: 5px;
  box-shadow: none;
  overflow: auto;
  box-sizing: border-box;
  line-height: 14px;

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    border-radius: 5px;
    box-shadow:
      inset 0 2px 1px 0 rgba(0, 0, 0, 0.1),
      0 0 10px 0 ${({ theme }) => alpha(theme.palette.primary.main, 0.5)};
  }

  &.error {
    border-color: ${({ theme }) => theme.palette.error.light};
    box-shadow:
      inset 0 2px 1px 0 rgba(0, 0, 0, 0.1),
      0 0 10px 0 ${({ theme }) => alpha(theme.palette.error.light, 0.5)};
  }

  &::placeholder {
    color: #535353;
  }
`;

interface ITextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  register?: UseFormRegister<any>; // eslint-disable-line
  parameters?: RegisterOptions;
  label?: string | JSX.Element;
  labelStyle?: any; // eslint-disable-line
}

export const TextArea = ({ register, name, parameters = {}, label, labelStyle = {}, ...restProps }: ITextAreaProps) => {
  return (
    <Box width="100%">
      {!!label && (
        <Label htmlFor={name} {...labelStyle}>
          {label}
        </Label>
      )}
      <StyledTextArea {...(register && name ? register(name, parameters) : {})} {...restProps} />
    </Box>
  );
};
