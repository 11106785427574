import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { faCopy, faEdit, faEye, faLock, faShareAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Avatar, Box } from '@mui/material';

import EmptyAvatar from 'src/assets/img/empty-avatar.svg';
import { ActionButton, TableCell, TableRow, Tag, Typography } from 'src/components';
import { CAMPAIGN_CATEGORIES } from 'src/constants';
import { CampaignCategory, ModalTypes, TemplateAvailability } from 'src/enums';
import { configSequenceDetails } from 'src/helpers';
import { useDeleteSequenceTemplate, useToggleSequenceTemplateAvailability } from 'src/reactQueries';
import { openModal } from 'src/store';
import { ISequenceTemplate } from 'src/types';

interface SequenceTemplatesTableRowProps {
  sequenceTemplate: ISequenceTemplate;
  availability: TemplateAvailability;
}

export const CAMPAIGN_CATEGORIES_GROUPED = CAMPAIGN_CATEGORIES.reduce(
  (gen, curr) => {
    gen[curr.category] = curr.name;

    return gen;
  },
  {} as Record<CampaignCategory, string>,
);

export const SequenceTemplatesTableRow = ({ sequenceTemplate, availability }: SequenceTemplatesTableRowProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id, sequence, name, category, owner, isAvailableForTeam } = sequenceTemplate;

  const { deleteSequenceTemplate, isLoading: isLoadingDelete } = useDeleteSequenceTemplate(id);
  const { toggleSequenceTemplateAvailability, isLoading: isLoadingToggle } = useToggleSequenceTemplateAvailability(id);

  const onViewButtonClick = () => {
    dispatch(
      openModal({
        type: ModalTypes.VIEW_SEQUENCE_TEMPLATE,
        headerText: name?.slice(0, 40),
        params: { sequenceTemplate: { ...sequenceTemplate }, availability },
      }),
    );
  };

  const onEditButtonClick = () => {
    navigate(`/sequence-templates/${id}`, { state: { name, category, sequence, isAvailableForTeam } });
  };

  const onCloneButtonClick = () => {
    navigate(`/sequence-templates/new`, { state: { name: `${name} (clone)`, category, sequence, isAvailableForTeam } });
  };

  const onToggleButtonClick = () => {
    toggleSequenceTemplateAvailability();
  };

  const onDeleteButtonClick = () => {
    dispatch(
      openModal({
        headerText: 'Delete Confirmation',
        descriptionText: 'Are you sure to delete this template?',
        onConfirm: deleteSequenceTemplate,
      }),
    );
  };

  const actions = [
    {
      isShown: true,
      title: 'View Template',
      onClick: onViewButtonClick,
      icon: faEye,
    },
    {
      isShown: availability === TemplateAvailability.personal,
      title: 'Edit Template',
      onClick: onEditButtonClick,
      icon: faEdit,
    },
    {
      isShown: availability === TemplateAvailability.personal,
      title: isAvailableForTeam ? 'Unshare template' : 'Share to Team',
      onClick: onToggleButtonClick,
      isLoading: isLoadingToggle,
      icon: isAvailableForTeam ? faLock : faShareAlt,
    },
    {
      isShown: availability === TemplateAvailability.personal,
      title: 'Delete Template',
      onClick: onDeleteButtonClick,
      isLoading: isLoadingDelete,
      icon: faTrashAlt,
    },
    {
      isShown: availability === TemplateAvailability.team,
      title: 'Clone Template',
      onClick: onCloneButtonClick,
      icon: faCopy,
    },
  ];

  return (
    <TableRow>
      {availability === TemplateAvailability.team && (
        <TableCell>
          <Avatar sx={{ width: 35, height: 35 }} src={owner?.picture || EmptyAvatar} />
        </TableCell>
      )}
      <TableCell maxWidth="200px" tooltip={name}>
        <Typography inline fontSize="15px">
          {name}
        </Typography>
      </TableCell>
      <TableCell>
        {sequence?.map((step, index) => {
          const { title: stepTitle } = configSequenceDetails(step.type, step) ?? {};

          return (
            <Typography key={index} color="primary.wlLight" maxWidth="250px" inline crop fontSize="15px">
              {index ? ', ' : ''}
              Step - {index + 1} - {stepTitle}
            </Typography>
          );
        })}
      </TableCell>
      <TableCell tooltip={CAMPAIGN_CATEGORIES_GROUPED[category]}>{CAMPAIGN_CATEGORIES_GROUPED[category]}</TableCell>
      {availability === TemplateAvailability.personal && (
        <TableCell justifyContent="center">
          {isAvailableForTeam ? (
            <Tag color="#3fc0ff">
              <Typography color="#3fc0ff">Shared</Typography>
            </Tag>
          ) : (
            ''
          )}
        </TableCell>
      )}
      <TableCell contentStyle={{ width: '100%' }}>
        <Box display="flex" gap="8px" justifyContent="flex-end" width="100%">
          {actions.map(
            ({ icon, isShown, onClick, isLoading, title }) =>
              isShown && (
                <ActionButton
                  data-testid={title}
                  key={title}
                  tooltip={title}
                  processing={isLoading}
                  onClick={onClick}
                  icon={icon}
                />
              ),
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};
