import React, { useRef, useState } from 'react';
import { ColorChangeHandler, SketchPicker as ColorPicker } from 'react-color';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button, Input, Typography } from 'src/components';
import { ModalTypes } from 'src/enums/modal.enum';
import { getRandomHexColor } from 'src/helpers';
import { useOutsideClick } from 'src/hooks';
import { useCreateTag, useUpdateTag } from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';
import { showToast } from 'src/store/toast.slice';
import { IModalProps } from 'src/types';
import { ModalBody, ModalFooter } from '../_components';

const ColorPreview = styled(Box)<{ bgcolor?: string }>`
  border-radius: 4px;
  width: 34px;
  height: 34px;
  border: 1px solid rgb(0 0 0 / 20%);
  cursor: pointer;
  background-color: ${(props) => props.bgcolor};
`;

const ColorPickerContainer = styled(Box)`
  left: calc(50% + 15px);
  position: absolute;
  z-index: 1;
`;

const StyledInput = styled(Input)`
  padding: 7.5px 24px 7.5px 12px;
  height: 100%;
`;

interface IModalCreateUpdateTagProps {
  id: number;
  tag?: string;
  color?: string;
}

export const ModalCreateUpdateTag = ({ params, onCancel, type }: IModalProps<IModalCreateUpdateTagProps>) => {
  const dispatch = useDispatch();
  const colorPickerContainerRef = useRef<HTMLDivElement>();
  const [tagName, setTagName] = useState(params?.tag ?? '');
  const [tagColor, setTagColor] = useState(params?.color ?? getRandomHexColor());
  const [colorPickerShown, setColorPickerShown] = useState(false);
  const isUpdateTag = type === ModalTypes.UPDATE_TAG;

  const { createTag } = useCreateTag();
  const { updateTag } = useUpdateTag({
    id: params?.id,
    color: tagColor,
    tag: tagName,
  });

  const confirmHandler = () => {
    if (tagName && isUpdateTag) {
      updateTag();
    } else if (tagName) {
      createTag({ tag: tagName, color: tagColor });
    } else {
      dispatch(
        showToast({
          type: 'error',
          message: 'Please enter a name',
          autoCloseTime: 3000,
        }),
      );
    }
  };

  const closeHandler = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  const onChangeTagName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTagName(event.target.value);
  };

  const onColorChange: ColorChangeHandler = (nextColor) => {
    setTagColor(nextColor.hex);
  };

  const showColorPicker = () => {
    if (!colorPickerShown) {
      setColorPickerShown(true);
    }
  };

  const hideColorPicker = () => {
    setColorPickerShown(false);
  };

  useOutsideClick(colorPickerContainerRef, hideColorPicker);

  return (
    <>
      <ModalBody position="relative" padding="24px 32px">
        {isUpdateTag && (
          <Typography color="black.base" mb="16px" fontSize="16px" lineHeight="22px">
            Apply changes to the selected tag? The tag name will be changed across all of your contacts such as leads
            and connections.
          </Typography>
        )}
        <Box display="flex" gap="30px">
          <Box minWidth="calc(50% - 15px)" maxWidth="50%">
            <Typography fontWeight="600" color="gray.700">
              Tag Name:
            </Typography>
            <StyledInput onChange={onChangeTagName} value={tagName} placeholder="Tag Name" />
          </Box>
          <Box minWidth="calc(50% - 15px)" maxWidth="50%">
            <Typography fontWeight="600" color="gray.700">
              Color:
            </Typography>
            <Box display="flex" alignItems="center" gap="12px" mt="5px">
              <ColorPreview bgcolor={tagColor} onClick={showColorPicker} />
              <Typography color="gray.500" fontSize="16px" fontWeight="300">
                Click to select color
              </Typography>
            </Box>
            {colorPickerShown && (
              <ColorPickerContainer ref={colorPickerContainerRef}>
                <ColorPicker onChange={onColorChange} color={tagColor} />
              </ColorPickerContainer>
            )}
          </Box>
        </Box>
      </ModalBody>

      <ModalFooter alignToTheEnd padding="15px 24px">
        <Button variant="gray" onClick={closeHandler}>
          Cancel
        </Button>
        <Button onClick={confirmHandler}>{isUpdateTag ? 'Save' : 'Create'}</Button>
      </ModalFooter>
    </>
  );
};
