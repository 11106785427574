import { isEmpty } from 'lodash';
import { CSSProperties, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, useTheme } from '@mui/material';

import { Button, TextArea, Tooltip, Typography } from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features } from 'src/enums';
import { delay } from 'src/helpers';
import { useTeamPlan } from 'src/hooks';
import { useUpdateConnectionNotes } from 'src/reactQueries';
import { IUserNetwork } from 'src/types';

interface ILeadNotesProp {
  notes?: string | null;
  userNetwork?: IUserNetwork;
  entityUrn: string;
}

const StyledTextArea = styled(TextArea)`
  height: 84px;
  line-height: 19px;
  resize: vertical;
  cursor: ${({ disabled }) => disabled && 'not-allowed'};
`;

const saveButtonStyles: CSSProperties = {
  float: 'right',
  marginTop: 12,
  marginBottom: 16,
};

const SAVED_RECENTLY_TIMEOUT = 3000; // ms
export const SAVED_RECENTLY_MESSAGE = 'Notes added to this lead.';

export const LeadNotes = ({ notes, entityUrn, userNetwork }: ILeadNotesProp) => {
  const { palette } = useTheme();
  const [value, setValue] = useState(notes || '');
  const [savedRecently, setSavedRecently] = useState(false);

  const { checkFeature } = useTeamPlan();
  const isConnectionNoteAllowed = checkFeature(Features.connectionNote);

  const buttonTitle = savedRecently ? 'Saved' : 'Save';
  const savedRecentlyMsg = savedRecently ? SAVED_RECENTLY_MESSAGE : '';

  const { updateNotes, isLoading } = useUpdateConnectionNotes(entityUrn, {
    onSuccess: () => {
      setSavedRecently(true);

      delay(SAVED_RECENTLY_TIMEOUT, () => setSavedRecently(false));
    },
  });

  useEffect(() => {
    setValue(notes || '');
  }, [notes]);

  const onChangeText: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    setValue(event.target.value);
  };

  const onSave = () => updateNotes(value);

  const toolTipMessage = !isConnectionNoteAllowed
    ? PLAN_TOOLTIPS.any
    : isEmpty(userNetwork)
      ? 'Notes feature is available just for your connections.'
      : '';

  return (
    <Box data-testid="notes">
      <Typography color={palette.gray[500]} fontSize="15px" lineHeight="16px" mb="12px">
        Connection Note
      </Typography>
      <Tooltip title={!isConnectionNoteAllowed && PLAN_TOOLTIPS.any}>
        <StyledTextArea
          placeholder="Add notes"
          value={value}
          onChange={onChangeText}
          disabled={!isConnectionNoteAllowed || isEmpty(userNetwork)}
        />
      </Tooltip>

      <Box display="flex" justifyContent="space-between">
        <Typography fontSize="14px" lineHeight="22px">
          {savedRecentlyMsg}
        </Typography>

        <Button
          style={saveButtonStyles}
          processing={isLoading}
          onClick={onSave}
          disabled={!isConnectionNoteAllowed || isEmpty(userNetwork)}
          tooltip={{
            title: toolTipMessage,
          }}
        >
          {buttonTitle}
        </Button>
      </Box>
    </Box>
  );
};
