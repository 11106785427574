import dayjs from 'dayjs';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import {
  Button,
  Checkbox,
  FilterInput,
  Filters,
  PersonAvatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
  Typography,
} from 'src/components';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from 'src/constants';
import { ModalTypes } from 'src/enums';
import { useCompanyTheme, useDebounce } from 'src/hooks';
import { useGetCampaignInvites, useUpdateCampaignInviteStatus } from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';
import {
  IGetInvitesRequest,
  IInvitesSearchFields,
  ITablePagination,
  TInvitesSortField,
  TInvitesStatus,
  TSortOrder,
} from 'src/types';
import { InvitationsLayout } from './InvitationsLayout';

export const Received = () => {
  const dispatch = useDispatch();
  const { shortCompanyName } = useCompanyTheme();

  const [page, setPage] = useState(DEFAULT_PAGE);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [sortField, setSortField] = useState<TInvitesSortField>('sentTime');
  const [sortOrder, setSortOrder] = useState<TSortOrder>('DESC');
  const [select, setSelect] = useState<number[]>([]);
  const [searchFields, setSearchFields] = useState<IInvitesSearchFields>({
    name: '',
  });

  const nameDebounced = useDebounce(searchFields.name?.trim() || '');

  const getInvitesParams: IGetInvitesRequest = {
    page,
    perPage,
    status: 'RECEIVED',
    sortField,
    sortOrder,
    name: nameDebounced,
  };

  const { data, isLoading } = useGetCampaignInvites(getInvitesParams);

  const { invites = [], total = 0 } = data ?? {};
  const includeId = (id?: number) => id && select.some((leadUrn) => leadUrn === id);
  const selectedOnCurrentPage = invites?.map(({ id }) => id) || [];
  const isEverySelected = selectedOnCurrentPage.every((id) => includeId(id));
  const isSelectedAll = !!total && isEverySelected;
  const noInvitesText = searchFields.name ? 'No profiles meet your search criteria' : 'No invitations yet.';

  const { updateStatus } = useUpdateCampaignInviteStatus(getInvitesParams);
  const updateInvite = (status: TInvitesStatus, inviteId: number) => {
    const statusAction = status === 'ACCEPT' ? 'accept' : 'reject';

    return dispatch(
      openModal({
        type: ModalTypes.INVITE,
        headerText: 'Success',
        params: {
          description: `The selected profile(s) to ${statusAction} will be added to ${shortCompanyName}’s next batch for processing.`,
          buttonText: 'Proceed',
          onConfirm: () => updateStatus({ status, inviteIds: [inviteId] }),
        },
      }),
    );
  };

  const onSelectAll = () =>
    isSelectedAll
      ? setSelect(select.filter((id) => !selectedOnCurrentPage.some((leadId) => leadId === id)))
      : setSelect([...select, ...selectedOnCurrentPage.filter((id) => !includeId(id))]);

  const onSelectOne = (inviteId: number) =>
    select.includes(inviteId)
      ? setSelect(select.filter((id) => id !== inviteId))
      : setSelect((prev) => [...prev, inviteId]);

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  const sortingProps = {
    sortOrder,
    sortField,
    onSortFieldChange: (field: TInvitesSortField, order: TSortOrder) => {
      setSortField(field);
      setSortOrder(order);
    },
  };

  const handleSearchFieldChange = (fields: Partial<IInvitesSearchFields>) => {
    setPage(DEFAULT_PAGE);
    setSearchFields((prev) => ({ ...prev, ...fields }));
  };

  return (
    <InvitationsLayout selected={select} onSuccess={() => setSelect([])} getInvitesParams={getInvitesParams}>
      <Filters>
        <FilterInput
          label="Name"
          name="name"
          value={searchFields.name}
          onChange={(e) => handleSearchFieldChange({ name: e.target.value })}
        />
      </Filters>
      <Box padding="20px 20px 80px">
        <Table processing={isLoading}>
          <TableHead>
            <TableRow>
              <TableHeadCell width="40px">
                <Checkbox
                  type="checkbox"
                  checkIcon={select.length > 0 && isSelectedAll ? 'check-mark' : 'dash'}
                  checked={select.length > 0}
                  onChange={onSelectAll}
                />
              </TableHeadCell>
              <TableHeadCell width="55px" />

              <TableHeadCell name="name" sorting={sortingProps}>
                Name
              </TableHeadCell>
              <TableHeadCell name="sentTime" sorting={sortingProps} width="160px">
                Date
              </TableHeadCell>
              <TableHeadCell width="80px">Time</TableHeadCell>

              <TableHeadCell width="120px" />
              <TableHeadCell width="120px" />
            </TableRow>
          </TableHead>
          <TableBody placeholder={!total && noInvitesText}>
            {invites.map(({ id, picture, name, sentTime }) => {
              const isSelected = select.includes(id);

              return (
                <TableRow key={id} checked={isSelected}>
                  <TableCell preventClick>
                    <Checkbox type="checkbox" checked={isSelected} onChange={() => onSelectOne(id)} />
                  </TableCell>
                  <TableCell>
                    <PersonAvatar src={picture} width="34px" height="34px" />
                  </TableCell>
                  <TableCell>
                    <Typography fontSize="15px">{name}</Typography>
                  </TableCell>
                  <TableCell>{dayjs.utc(sentTime).local().format('MMMM Do YYYY')} </TableCell>
                  <TableCell>{dayjs.utc(sentTime).local().format('h:mm a')}</TableCell>
                  <TableCell preventClick>
                    <Button variant="gray" onClick={() => updateInvite('ACCEPT', id)}>
                      Accept
                    </Button>
                  </TableCell>
                  <TableCell preventClick>
                    <Button variant="gray" onClick={() => updateInvite('REJECT', id)}>
                      Reject
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={paginationChangeHandler} />
      </Box>
    </InvitationsLayout>
  );
};
