import { useQuery, UseQueryOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { getUserInvitesRestriction } from 'src/api';
import { NotificationTypes } from 'src/enums';
import { addNotification, removeNotificationByType } from 'src/store';
import { ICustomAxiosError, IUserInvitesRestriction } from 'src/types';

export const useGetUserInvitesRestriction = (options?: UseQueryOptions<IUserInvitesRestriction, ICustomAxiosError>) => {
  const dispatch = useDispatch();

  useQuery<IUserInvitesRestriction, ICustomAxiosError>(['user-invites-restriction'], getUserInvitesRestriction, {
    ...options,
    onSuccess: (data) => {
      if (data.hasInvitesRestriction) {
        dispatch(addNotification({ type: NotificationTypes.LI_INVITES_RESTRICTION }));
      } else {
        dispatch(removeNotificationByType({ type: NotificationTypes.LI_INVITES_RESTRICTION }));
      }
    },
  });
};
