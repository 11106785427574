import { createSlice } from '@reduxjs/toolkit';

import { ITeam } from 'src/types';
import { removeUser } from './user.slice';

type TInitialState = {
  data: ITeam | null;
};

const teamStringFromLocalStorage = localStorage.getItem('team');

const initialState: TInitialState = {
  data: teamStringFromLocalStorage ? JSON.parse(teamStringFromLocalStorage) : null,
};

export const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    setTeam: (state, action) => {
      state.data = action.payload;
      localStorage.setItem('team', JSON.stringify(action.payload));
    },
    removeTeam: (state) => {
      state.data = null;
      localStorage.removeItem('team');
    },
  },
  extraReducers: (builder) => {
    builder.addCase(removeUser, (state) => {
      state.data = null;
      localStorage.removeItem('team');
    });
  },
});

export const { setTeam, removeTeam } = teamSlice.actions;
export default teamSlice.reducer;
