import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Box, Radio, RadioGroup } from '@mui/material';

import { Button, Typography } from 'src/components';
import { useAppSelector } from 'src/hooks';
import { closeModal } from 'src/store/modal.slice';
import { IModalProps, IUserCompany } from 'src/types';

const Container = styled(Box)`
  padding: 20px;
  font-size: 14px;
`;

const Title = styled.h4`
  font-family: 'ProximaSoft', sans-serif;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primary.wlLight};
  line-height: 30px;
`;

const CompanyBox = styled(Box)`
  background: ${({ theme }) => theme.palette.light.main};
  padding: 8px 10px;
  margin: 5px 0;
  border-radius: 5px;
  font-size: 18px;
`;

const CompanyTitle = styled.div`
  font-size: 15px;
`;

const CompanySubTitle = styled.div`
  font-size: 13px;
  color: #8a8a8a;
`;

const ListItem = styled.li`
  list-style-position: inside;
`;

export const ModalPostAs = ({ onConfirm, params }: IModalProps) => {
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.user.profile);
  const [postAs, setPostAs] = useState('You');

  return (
    <Container>
      <Box mb="20px">
        <Title>Post by</Title>
        <div>With which account you want to post by</div>
      </Box>
      <Box>
        <RadioGroup
          name="postAs"
          defaultValue={params?.postAs || 'You'}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setPostAs((event.target as HTMLInputElement).value);
          }}
        >
          <CompanyBox display="flex" justifyContent="space-between" alignItems="center">
            <Box fontSize="15px">You</Box>
            <Radio value="You" />
          </CompanyBox>
          {user.companies &&
            !!user.companies.length &&
            user.companies.map((company: IUserCompany) => (
              <CompanyBox key={company.entityUrn} display="flex" justifyContent="space-between" alignItems="center">
                <Box>
                  <CompanyTitle>{company.name}</CompanyTitle>
                  <CompanySubTitle>{company.universalName}</CompanySubTitle>
                </Box>
                <Radio value={company.entityUrn} />
              </CompanyBox>
            ))}
        </RadioGroup>
        {(!user.companies || (user.companies && !user.companies.length)) && (
          <ListItem>
            <Typography display="inline">No Company found.</Typography>
          </ListItem>
        )}
      </Box>
      <Box py={2} px={4} display="flex" justifyContent="flex-end" id="here">
        <Button
          variant="gray"
          onClick={() => {
            if (typeof onConfirm === 'function') {
              onConfirm({ postAs });
            }

            dispatch(closeModal());
          }}
        >
          Close
        </Button>
      </Box>
    </Container>
  );
};
