import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { IDisabledSelectOption } from 'src/types';

const Button = styled.button`
  min-width: 110px;
  height: 28px;
  font-size: 14px;
  transition: all 0.1s linear;
`;

interface IButtonGroupProp {
  value: IDisabledSelectOption;
  options: IDisabledSelectOption[];
  onChange: (opt: IDisabledSelectOption) => void;
}

export const ToggleButtonGroup = ({ value, options, onChange }: IButtonGroupProp) => {
  const { palette } = useTheme();

  return (
    <Box
      display="flex"
      alignSelf="center"
      border="1px solid rgba(210,222,224,0.5)"
      borderRadius="5px"
      overflow="hidden"
    >
      {options.map((item) => {
        const fontColor = value.value === item.value ? '#fff' : '#333';
        const bgColor = value.value === item.value ? palette.primary.main : '#fff';

        return (
          <Button
            key={item.value}
            style={{
              backgroundColor: bgColor,
              color: fontColor,
              cursor: item.disabled ? 'not-allowed' : 'pointer',
            }}
            onClick={() => item.disabled || onChange(item)}
          >
            {item.label}
          </Button>
        );
      })}
    </Box>
  );
};
