import { UseFormReset } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { CampaignCategory, CampaignSequenceStepType, CampaignType } from 'src/enums';
import { showToast } from 'src/store';
import { IGetCampaign, ISequence, ISequenceStep } from 'src/types';

interface UseSequenceFormProps<T extends ISequence> {
  category?: CampaignCategory;
  campaign?: IGetCampaign;
  onSubmit: (values: T) => void;
  sequence: ISequenceStep[];
  reset?: UseFormReset<ISequence>;
}

export const useSequenceForm = <T extends ISequence>({
  category,
  campaign,
  onSubmit,
  sequence,
  reset,
}: UseSequenceFormProps<T>) => {
  const dispatch = useDispatch();

  const submit = (values: T) => {
    const sequence = values?.sequence ?? [];

    if (!sequence?.length) {
      return dispatch(showToast({ type: 'info', message: 'Please add a touch to your sequence.' }));
    }

    const linkedinConnectionRequest = sequence?.find(({ type }) => type === CampaignSequenceStepType.LINKED_IN_CONNECT);
    if (linkedinConnectionRequest?.connect_followup && !linkedinConnectionRequest?.followup_message.trim()) {
      return dispatch(showToast({ type: 'info', message: 'Please add a followup message.' }));
    }

    if (campaign?.campaignType === CampaignType.LINKEDIN_CONNECTIONS && linkedinConnectionRequest) {
      return dispatch(
        showToast({
          type: 'info',
          message: 'Send connection request is not available for 1st Degree Connections campaign.',
        }),
      );
    }

    if (category === CampaignCategory.EVENT) {
      const eventMessagePosition = sequence?.findIndex(({ type }) => type === CampaignSequenceStepType.EVENT_MESSAGE);
      const linkedinMessagePosition = sequence?.findIndex(
        ({ type }) => type === CampaignSequenceStepType.LINKED_IN_MESSAGE,
      );

      if (
        (linkedinMessagePosition !== -1 && eventMessagePosition > linkedinMessagePosition) ||
        (linkedinMessagePosition !== -1 && eventMessagePosition === -1)
      ) {
        return dispatch(
          showToast({ type: 'info', message: 'Event message step must be before LinkedIn message step' }),
        );
      }
    }

    onSubmit(values);

    return null;
  };

  const removeNotAvailableSteps = (category: CampaignCategory) => {
    const newSequence = sequence.filter((step) => {
      switch (category) {
        case CampaignCategory.MULTICHANNEL:
          return [
            CampaignSequenceStepType.LINKED_IN_VIEW,
            CampaignSequenceStepType.LINKED_IN_CONNECT,
            CampaignSequenceStepType.LINKED_IN_MESSAGE,
            CampaignSequenceStepType.EMAIL,
            CampaignSequenceStepType.TWITTER_FOLLOW,
            CampaignSequenceStepType.TWITTER_DM,
          ].includes(step.type);
        case CampaignCategory.LINKEDIN:
          return [
            CampaignSequenceStepType.LINKED_IN_VIEW,
            CampaignSequenceStepType.LINKED_IN_CONNECT,
            CampaignSequenceStepType.LINKED_IN_MESSAGE,
          ].includes(step.type);
        case CampaignCategory.EMAIL:
          return [CampaignSequenceStepType.EMAIL].includes(step.type);
        case CampaignCategory.TWITTER:
          return [CampaignSequenceStepType.TWITTER_FOLLOW, CampaignSequenceStepType.TWITTER_DM].includes(step.type);
        case CampaignCategory.INMAIL:
          return [CampaignSequenceStepType.LINKED_IN_VIEW, CampaignSequenceStepType.IN_MAIL_MESSAGE].includes(
            step.type,
          );
        case CampaignCategory.GROUP:
          return [CampaignSequenceStepType.LINKED_IN_VIEW, CampaignSequenceStepType.GROUP_MESSAGE].includes(step.type);
        case CampaignCategory.EVENT:
          return [CampaignSequenceStepType.LINKED_IN_VIEW, CampaignSequenceStepType.EVENT_MESSAGE].includes(step.type);
        case CampaignCategory.POST:
          return [
            CampaignSequenceStepType.LINKED_IN_VIEW,
            CampaignSequenceStepType.LINKED_IN_CONNECT,
            CampaignSequenceStepType.LINKED_IN_MESSAGE,
          ].includes(step.type);
      }

      return false;
    });

    reset?.({ sequence: newSequence });
  };

  return { submit, removeNotAvailableSteps };
};
