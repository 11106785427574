import { useMutation, UseMutationOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateLeadsColumnsPosition } from 'src/api';
import { useAppSelector } from 'src/hooks';
import { setLeadColumns } from 'src/store/lead.slice';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, ILeadColumnsPosition } from 'src/types';

export const useUpdateLeadsColumnsPosition = (
  options?: UseMutationOptions<ILeadColumnsPosition[], ICustomAxiosError, ILeadColumnsPosition[]>,
) => {
  const dispatch = useDispatch();
  const leadColumns = useAppSelector((state) => state.lead.leadColumns);
  const user = useAppSelector((state) => state.user.profile);

  const { mutate, ...rest } = useMutation('update-leads-columns-position', updateLeadsColumnsPosition, {
    ...options,
    onMutate: async (request) => {
      options?.onMutate?.(request);
      const prevData = [...leadColumns];

      dispatch(setLeadColumns(request));

      return { prevData };
    },
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      localStorage.setItem('user', JSON.stringify({ ...user, leadColumns: data }));
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      if (context?.prevData) {
        dispatch(setLeadColumns(context.prevData));
      }

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { updateLeadsColumnsPosition: mutate, ...rest };
};
