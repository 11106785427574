import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { RadioButton, Typography } from 'src/components';
import { BillingTypeEnum, ModalTypes, Plan } from 'src/enums';
import { useGetBillingInfo } from 'src/reactQueries';
import { openModal } from 'src/store';

interface BillingCycleSelectProps {
  plan: Plan;
  billingType: BillingTypeEnum;
  disabled?: boolean;
  onBillingTypeChange: (type: BillingTypeEnum) => void;
}

export const BillingCycleSelect = ({ plan, billingType, disabled, onBillingTypeChange }: BillingCycleSelectProps) => {
  const dispatch = useDispatch();

  const { billingInfo } = useGetBillingInfo();

  const handleBillingTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (disabled) {
      return null;
    }
    const type = e.target.value as BillingTypeEnum;

    if (billingType !== type) {
      if (type === BillingTypeEnum.MONTHLY) {
        dispatch(
          openModal({
            type: ModalTypes.SAVE_BILLING_CYCLE,
            closable: false,
            params: {
              percentage: billingType === BillingTypeEnum.ANNUAL ? 40 : 20,
              billingType,
            },
            onConfirm: () => onBillingTypeChange(type),
          }),
        );
      } else if ([BillingTypeEnum.QUARTERLY, BillingTypeEnum.SEMESTRIAL, BillingTypeEnum.ANNUAL].includes(type)) {
        onBillingTypeChange(type);
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column" gap="12px">
      <RadioButton
        id="monthly"
        label={
          <Typography color="gray.800" fontSize="16px" inline component="span">
            Monthly
          </Typography>
        }
        value={BillingTypeEnum.MONTHLY}
        checked={BillingTypeEnum.MONTHLY === billingType}
        onChange={handleBillingTypeChange}
        disabled={disabled}
      />
      <RadioButton
        id="quarterly"
        label={
          <>
            <Typography color="gray.800" fontSize="16px" inline component="span">
              Quarterly{' '}
            </Typography>
            <Typography color="success.500" fontSize="16px" inline component="span">
              (Save up to 20%)
            </Typography>
          </>
        }
        value={BillingTypeEnum.QUARTERLY}
        checked={BillingTypeEnum.QUARTERLY === billingType}
        onChange={handleBillingTypeChange}
        disabled={disabled}
      />
      {billingInfo?.billingType === BillingTypeEnum.SEMESTRIAL && billingInfo?.plan === plan && (
        <RadioButton
          id="semestrial"
          label={
            <Typography color="gray.800" fontSize="16px" inline component="span">
              Half Yearly
            </Typography>
          }
          value={BillingTypeEnum.SEMESTRIAL}
          checked={BillingTypeEnum.SEMESTRIAL === billingType}
          onChange={handleBillingTypeChange}
          disabled={disabled}
        />
      )}
      {(billingInfo?.billingType === BillingTypeEnum.ANNUAL ||
        ![Plan.STARTER, Plan.PERSONAL, Plan.ENTERPRISE].includes(plan)) && (
        <RadioButton
          id="annual"
          label={
            <>
              <Typography color="gray.800" fontSize="16px" inline component="span">
                Annual{' '}
              </Typography>
              <Typography color="success.500" fontSize="16px" inline component="span">
                (Save up to 40%)
              </Typography>
            </>
          }
          value={BillingTypeEnum.ANNUAL}
          checked={BillingTypeEnum.ANNUAL === billingType}
          onChange={handleBillingTypeChange}
          disabled={disabled}
        />
      )}
    </Box>
  );
};
