import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import {
  Spinner,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
  Typography,
} from 'src/components';
import { Alert } from 'src/containers/Campaigns/_components';
import { CampaignCategory } from 'src/enums';
import { useAppSelector, useCampaignsListProps } from 'src/hooks';
import { useGetCampaigns } from 'src/reactQueries';
import { TStatTableParams, updateStatTableParams } from 'src/store/campaign.slice';
import { CampaignsDraftListItem } from './CampaignsDraftListItem';

interface ICampaignsDraftListProps {
  category: CampaignCategory;
}

export const CampaignsDraftList = ({ category }: ICampaignsDraftListProps) => {
  const dispatch = useDispatch();
  const { page, perPage } = useAppSelector(({ campaign }) => campaign.statsParams[category].draft);

  const onChangeParams = (params: Partial<TStatTableParams>) =>
    dispatch(updateStatTableParams({ status: 'draft', category, params }));

  const { title, isInactive = false, inactiveLabel = '' } = useCampaignsListProps(category);
  const { data, isLoading, isFetching, error } = useGetCampaigns({
    status: 'draft',
    category,
    page,
    perPage,
    sortField: 'createdAt',
    sortOrder: 'DESC',
    filter: 'all',
  });
  const { campaigns = [], total = 0 } = data || {};

  useEffect(() => {
    if (!campaigns?.length && page * perPage - total === perPage) {
      onChangeParams({ page: page - 1 || 1 });
    }
  }, [campaigns?.length]);

  if (isLoading || (!campaigns.length && (isFetching || !!total))) {
    return <Spinner />;
  }

  if (!campaigns?.length) {
    return null;
  }

  return (
    <Box>
      {error?.statusCode === 511 && <Alert>{error?.message}</Alert>}
      <Typography padding="15px" fontSize="16px" semibold>
        {title}
      </Typography>

      <Table disabled={isLoading}>
        <TableHead>
          <TableRow>
            <TableHeadCell width="200px">Name</TableHeadCell>
            <TableHeadCell />
            <TableHeadCell width="60px" />
          </TableRow>
        </TableHead>
        <TableBody>
          {campaigns.map((campaign) => {
            return (
              <CampaignsDraftListItem
                key={campaign.id}
                campaign={campaign}
                category={category}
                isInactive={isInactive}
                inactiveLabel={inactiveLabel}
              />
            );
          })}
        </TableBody>
      </Table>

      <TablePagination page={page} perPage={perPage} total={data?.total} onPaginationChange={onChangeParams} />
    </Box>
  );
};
