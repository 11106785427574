import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { setCredentials } from 'src/api';
import { setCookie } from 'src/helpers';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IUpdateCredentials } from 'src/types';

export const useSetCredentials = (options?: UseMutationOptions<void, ICustomAxiosError, IUpdateCredentials>) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation(['update-user-credentials'], setCredentials, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      setCookie('changeUserEmailProcess', true);
      queryClient.invalidateQueries(['get-user-profile']);
      dispatch(
        showToast({
          type: 'info',
          message: 'Credentials updated successfully',
          autoCloseTime: 3000,
        }),
      );
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);

      const message = (err as Error)?.message;

      dispatch(
        showToast({
          type: 'error',
          message:
            message === 'Current password invalid'
              ? 'The current password is wrong'
              : message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { setCredentials: mutate, ...rest };
};
