import { useEffect, useState } from 'react';

import { delay } from 'src/helpers';

export const useDebounce = <T = string>(value: T, delayMs = 500): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = delay(delayMs, () => setDebouncedValue(value));

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
