import { PropsWithChildren, ReactNode, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from '@emotion/styled';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { lighten, useTheme } from '@mui/material';

import { transformRGBChannel } from 'src/helpers';
import { Tooltip } from './Tooltip';
import { ITypographyProps, Typography } from './Typography';

interface IBoxLinkWrapper {
  cursor?: string;
  hoverColor?: string;
  borderUnderline?: boolean;
  underlineColor?: string;
}

const BoxLinkWrapper = styled(Typography)<IBoxLinkWrapper>`
  cursor: ${({ cursor }) => cursor};
  transition: color 0.2s;
  border-bottom: ${({ borderUnderline, underlineColor, theme }) =>
    borderUnderline ? `1px solid ${underlineColor || theme.palette.lightGray.light}` : 'none'};

  & a {
    color: inherit;
    border-bottom: none !important;
    transition: color 0.2s;
  }

  &:hover,
  & a:hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`;

export interface ILinkProps extends Omit<ITypographyProps, 'variant'> {
  disabled?: boolean;
  tooltip?: ReactNode;
  external?: boolean;
  openNewTab?: boolean;
  to?: string;
  cursor?: string;
  hoverColor?: string;
  variant?: 'default' | 'title' | 'error' | 'primary' | 'darkBlue' | 'lightBlue';
  leftIcon?: true | IconDefinition;
  rightIcon?: true | IconDefinition;
  underlineColor?: string;
}

export const Link = ({
  tooltip,
  to = '',
  disabled,
  openNewTab,
  external,
  children,
  onClick,
  hoverColor,
  color,
  variant = 'default',
  leftIcon,
  rightIcon,
  underline,
  ...linkWrapper
}: PropsWithChildren<ILinkProps>) => {
  const { palette } = useTheme();

  const externalLink = (
    <a href={to} target={openNewTab ? '_blank' : '_self'} rel={openNewTab ? 'noopener noreferrer' : ''}>
      {children}
    </a>
  );
  const localLink = <RouterLink to={to}>{children}</RouterLink>;
  const linkContent = external ? externalLink : localLink;

  const variantColor = useMemo(() => {
    switch (variant) {
      case 'default':
        return { color: palette.info.dark, hoverColor: palette.info.main };
      case 'darkBlue':
        return {
          color: palette.primary.light,
          hoverColor: transformRGBChannel(palette.primary.light, { red: -16, green: 132, blue: 148 }),
        };
      case 'lightBlue':
        return {
          color: palette.brand[300],
          hoverColor: palette.brand[400],
        };
      case 'title':
        return {
          color: palette.primary.wlLight,
          hoverColor: transformRGBChannel(palette.primary.wlLight, { red: -16, green: 132, blue: 148 }),
        };
      case 'error':
        return {
          color: palette.error.main,
          hoverColor: lighten(palette.error.light, 0.2),
        };
      case 'primary':
        return {
          color: palette.primary.main,
          hoverColor: lighten(palette.primary.main, 0.2),
        };
    }
  }, [variant]);

  return (
    <Tooltip title={tooltip}>
      <span>
        <BoxLinkWrapper
          inline
          color={disabled ? '#717171' : color ?? variantColor.color}
          hoverColor={disabled ? '#717171' : hoverColor ?? variantColor.hoverColor}
          cursor={disabled ? 'not-allowed' : 'pointer'}
          onClick={(event) => disabled || onClick?.(event)}
          borderUnderline={underline}
          {...linkWrapper}
        >
          {!!leftIcon && (
            <FontAwesomeIcon
              icon={typeof leftIcon === 'boolean' ? faExternalLinkAlt : leftIcon}
              style={{ marginRight: leftIcon ? '5px' : '0px' }}
            />
          )}
          {disabled || !to ? children : linkContent}
          {!!rightIcon && (
            <FontAwesomeIcon
              icon={typeof rightIcon === 'boolean' ? faExternalLinkAlt : rightIcon}
              style={{ marginLeft: children ? '5px' : '0px' }}
            />
          )}
        </BoxLinkWrapper>
      </span>
    </Tooltip>
  );
};
