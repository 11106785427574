import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createCampaignAudience } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICampaign, ICustomAxiosError } from 'src/types';

export const useCreateCampaignAudience = (
  options?: UseMutationOptions<ICampaign, ICustomAxiosError, Omit<ICampaign, 'id' | 'status' | 'touchSequence'>>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mutate, isLoading, ...rest } = useMutation<
    ICampaign,
    ICustomAxiosError,
    Omit<ICampaign, 'id' | 'status' | 'touchSequence'>
  >(['create-campaign-audience'], createCampaignAudience, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      await queryClient.cancelQueries(['campaign', data.id]);

      queryClient.setQueryData<ICampaign>(['campaign', data.id], () => data);
      queryClient.invalidateQueries(['get-campaigns', data.category, 'draft']);

      navigate(`/campaign/${data.id}/sequence`, { state: { category: data.category } });
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { createCampaignAudience: mutate, isCreateLoading: isLoading, ...rest };
};
