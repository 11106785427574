import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { Button, Input, Typography } from 'src/components';
import { REGEXP_VALID_EMAIL } from 'src/constants';
import { useAppSelector } from 'src/hooks';
import { useExportConnectionsCsv, useExportLeadsCsv } from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';
import { IModalProps } from 'src/types';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../_components';

const StyledInput = styled(Input)`
  height: 34px;
  margin-top: 20px;
`;

interface IEmailForm {
  email: string;
}

interface IModalExportContactsParams {
  isBlacklist?: boolean;
  isLeadsExport?: boolean;
  isConnectionsExport?: boolean;
}

export const ModalExportContacts = ({ onCancel, params }: IModalProps<IModalExportContactsParams>) => {
  const dispatch = useDispatch();
  const { profile } = useAppSelector((state) => state.user);
  const { isConnectionsExport, isLeadsExport, isBlacklist = false } = params ?? {};

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IEmailForm>({ defaultValues: { email: profile.email ?? '' } });

  const { exportLeadsCsv, isLoading: isLoadingLeads, isSuccess: isSuccessLeads } = useExportLeadsCsv(isBlacklist);
  const {
    exportConnectionsCsv,
    isLoading: isLoadingConnections,
    isSuccess: isSuccessConnections,
  } = useExportConnectionsCsv(isBlacklist);

  const isSuccess = isSuccessLeads || isSuccessConnections;
  const isLoading = isLoadingLeads || isLoadingConnections;

  const confirmHandler = ({ email }: IEmailForm) => {
    if (isLeadsExport) {
      exportLeadsCsv(email);
    }

    if (isConnectionsExport) {
      exportConnectionsCsv(email);
    }
  };

  const closeHandler = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  return (
    <ModalContainer>
      <ModalHeader onClose={closeHandler}>{isSuccess ? 'Export confirmation' : 'Email confirmation'}</ModalHeader>

      <ModalBody>
        {isSuccess ? (
          <Typography color="violet.dark">
            We are processing your request. You will receive the exported file on an email shortly.
          </Typography>
        ) : (
          <>
            <Typography color="violet.dark">Please type the email you want to use for receiving export.</Typography>
            <StyledInput
              register={register}
              name="email"
              parameters={{
                required: 'Please enter email',
                pattern: {
                  value: REGEXP_VALID_EMAIL,
                  message: 'Please enter a valid email',
                },
              }}
              placeholder="email"
            />
            {errors.email && (
              <Typography color="error.light" mt="5px">
                {errors.email?.message}
              </Typography>
            )}
          </>
        )}
      </ModalBody>

      <ModalFooter alignToTheEnd={isSuccess}>
        <Button variant="gray" onClick={closeHandler}>
          {isSuccess ? 'Close' : 'Cancel'}
        </Button>
        {!isSuccess && (
          <Button disabled={isLoading} processing={isLoading} onClick={handleSubmit(confirmHandler)}>
            Confirm
          </Button>
        )}
      </ModalFooter>
    </ModalContainer>
  );
};
