import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateCampaignRunningState } from 'src/api';
import {
  MAX_INDIVIDUAL_RUNNING_CAMPAIGNS,
  MAX_PERSONAL_RUNNING_CAMPAIGNS,
  MAX_STARTER_RUNNING_CAMPAIGNS,
  MAX_TRIAL_RUNNING_CAMPAIGNS,
} from 'src/constants';
import { CampaignCategory, CampaignType } from 'src/enums';
import { useTeamPlan } from 'src/hooks';
import { showToast } from 'src/store/toast.slice';
import { ICampaign, ICustomAxiosError, IGetCampaign, IGetCampaignResponse, TCampaignRunState } from 'src/types';

const PRO_ONLY_ERROR = 'Available only in Pro Plan';

export const useUpdateRunningState = (
  id: number,
  { category }: { campaignType?: CampaignType; isLinkedInOnly?: boolean; category?: CampaignCategory },
  options?: UseMutationOptions<ICampaign, ICustomAxiosError, TCampaignRunState>,
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const getCampaignsKey = ['get-campaigns', category];

  const { isTrialStarted, isStarter, isPersonal, isIndividual } = useTeamPlan();

  const { mutate, isLoading, ...rest } = useMutation<
    ICampaign,
    ICustomAxiosError,
    TCampaignRunState,
    { prevData: unknown }
  >('update-campaign-running-state', (state: TCampaignRunState) => updateCampaignRunningState(id, state), {
    onMutate: async (state) => {
      await queryClient.cancelQueries(['campaign', id]);
      await queryClient.cancelQueries(getCampaignsKey);

      const prevData = queryClient.getQueryData(['campaign', id]);

      if (prevData) {
        queryClient.setQueryData<IGetCampaign | undefined>(
          ['campaign', id],
          (campaign) => ({ ...campaign, runState: state }) as IGetCampaign,
        );
      }

      queryClient.setQueriesData<IGetCampaignResponse | undefined>(getCampaignsKey, (campaigns) => {
        if (campaigns) {
          return {
            ...campaigns,
            campaigns: campaigns.campaigns.map((campaign) =>
              campaign.id === id ? { ...campaign, runState: state } : campaign,
            ),
          };
        }

        return campaigns;
      });

      return { prevData };
    },
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      await queryClient.invalidateQueries(['campaigns-dashboard'], { refetchInactive: true });
      await queryClient.invalidateQueries(['get-campaigns-count', data.category]);
      await queryClient.invalidateQueries(['get-campaigns', data.category, 'active']);
      await queryClient.invalidateQueries(['get-campaigns', data.category, 'active', 'paused']);
    },
    onError: async (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      await queryClient.invalidateQueries(getCampaignsKey);

      if (context?.prevData) {
        queryClient.setQueryData(['campaign', id], context.prevData);
      }

      if (err.statusCode === 403) {
        const limitMessage =
          (isTrialStarted &&
            `You have reached the maximum limit of ${MAX_TRIAL_RUNNING_CAMPAIGNS} active campaigns for your current subscription plan.`) ||
          (isStarter &&
            `You have reached the maximum limit of ${MAX_STARTER_RUNNING_CAMPAIGNS} active campaigns for your current subscription plan.`) ||
          (isPersonal &&
            `You have reached the maximum limit of ${MAX_PERSONAL_RUNNING_CAMPAIGNS} active campaigns for your current subscription plan.`) ||
          (isIndividual &&
            `You have reached the maximum limit of ${MAX_INDIVIDUAL_RUNNING_CAMPAIGNS} active campaigns for your current subscription plan.`) ||
          '';

        dispatch(
          showToast({
            type: 'info',
            message:
              err.message === PRO_ONLY_ERROR
                ? 'This type of campaign is not available on your current subscription plan.'
                : limitMessage,
            autoCloseTime: 3000,
          }),
        );
      } else {
        dispatch(
          showToast({
            type: 'error',
            message: err.message ?? 'Oops, something has gone wrong!',
            autoCloseTime: 3000,
          }),
        );
      }
    },
  });

  return {
    updateRunningState: mutate,
    isUpdateLoading: isLoading,
    ...rest,
  };
};
