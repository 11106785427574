export enum LIConversationFilterType {
  ALL = '',
  UNREAD = 'UNREAD',
  ARCHIVED = 'ARCHIVED',
  TAGGED = 'TAGGED',
  INMAIL = 'INMAIL',
  SN = 'SALES_NAVIGATOR',
  REPLIES = 'REPLIES',
}

export enum ModifyConversationActions {
  READ = 'read',
  UNREAD = 'unread',
  ARCHIVE = 'archive',
  UNARCHIVE = 'unarchive',
  DELETE = 'delete',
}

export enum LIConversationType {
  MEMBER_TO_MEMBER = 'MEMBER_TO_MEMBER',
  INMAIL = 'INMAIL',
  SPONSORED_INMAIL = 'SPONSORED_INMAIL',
  SPONSORED_MESSAGE = 'SPONSORED_MESSAGE',
}

export enum LIMessageType {
  memberToMember = 'MEMBER_TO_MEMBER',
  participantChange = 'PARTICIPANT_CHANGE',
  systemMessage = 'SYSTEM_MESSAGE',
  sponsoredMessage = 'SPONSORED_MESSAGE',
}

export enum SNMessageType {
  INMAIL_DECLINE = 'INMAIL_DECLINE',
  INMAIL = 'INMAIL',
}
