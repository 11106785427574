import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';

import { ReactComponent as EditIcon } from 'src/assets/icons/edit.svg';
import { ActionButton, Link, TableCell, TableRow } from 'src/components';
import { ModalTypes } from 'src/enums/modal.enum';
import { updateConnectionsRequestParams } from 'src/store/connections.slice';
import { updateLeadsRequestParams } from 'src/store/lead.slice';
import { openModal } from 'src/store/modal.slice';
import { ITag } from 'src/types';

const Color = styled(Box)<{ bgcolor: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 4px;
  overflow: hidden;
  background-color: ${(props) => props.bgcolor};
  border: 1px solid rgb(0 0 0 / 20%);
`;

export const TagTableRow = ({ tag, connectionsCount, leadsCount, color, id }: ITag) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();

  const onEditButtonClick = () => {
    dispatch(
      openModal({
        type: ModalTypes.UPDATE_TAG,
        headerText: `Edit Tag: ${tag}`,
        params: {
          id,
          tag,
          color,
        },
        headerStyles: { fontWeight: '600', fontSize: '20px', color: 'black.base' },
        containerHeaderStyles: { paddingLeft: '32px', borderBottom: '1px solid #e5e5e5' },
        closeButtonStyles: { size: 'xl', color: palette.gray[600] },
      }),
    );
  };

  const onDeleteButtonClick = () => {
    dispatch(
      openModal({
        type: ModalTypes.DELETE_TAG,
        headerText: `Deleting Tag: ${tag}`,
        params: { id },
        headerStyles: { fontWeight: '600', fontSize: '20px', color: 'black.base' },
        containerHeaderStyles: { paddingLeft: '32px', borderBottom: '1px solid #e5e5e5' },
        closeButtonStyles: { size: 'xl', color: palette.gray[600] },
      }),
    );
  };

  return (
    <TableRow>
      <TableCell>{tag}</TableCell>
      <TableCell justifyContent="flex-start">
        {leadsCount && (
          <Link
            variant="lightBlue"
            fontSize="15px"
            data-testid="lead-tags"
            to="/contacts/leads"
            onClick={() => dispatch(updateLeadsRequestParams({ tags: [{ value: id.toString(), exclude: false }] }))}
          >
            {leadsCount}
          </Link>
        )}
      </TableCell>
      <TableCell justifyContent="flex-start">
        {connectionsCount && (
          <Link
            variant="lightBlue"
            fontSize="15px"
            data-testid="connection-tags"
            to="/contacts/connections"
            onClick={() =>
              dispatch(updateConnectionsRequestParams({ tags: [{ value: id.toString(), exclude: false }] }))
            }
          >
            {connectionsCount}
          </Link>
        )}
      </TableCell>
      <TableCell justifyContent="flex-start">
        <Color bgcolor={color} />
      </TableCell>
      <TableCell justifyContent="flex-end">
        <Box display="flex" flexWrap="nowrap" gap="8px">
          <ActionButton data-testid="edit-tag" tooltip="Edit Tag" onClick={onEditButtonClick}>
            <EditIcon />
          </ActionButton>
          <ActionButton data-testid="delete-tag" tooltip="Delete Tag" onClick={onDeleteButtonClick} icon={faXmark} />
        </Box>
      </TableCell>
    </TableRow>
  );
};
