import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getCampaignsSearchListConnections } from 'src/api';
import { ICampaignSearchItem } from 'src/types';

export const useGetCampaignsSearchListConnections = <TData = ICampaignSearchItem[]>(
  options?: UseQueryOptions<ICampaignSearchItem[], AxiosError, TData>,
) => {
  return useQuery<ICampaignSearchItem[], AxiosError, TData>(
    ['campaign-search-list-connections'],
    () => getCampaignsSearchListConnections(),
    options,
  );
};
