import { UseFormReturn } from 'react-hook-form';
import { faLinkedin, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faCommentDots, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import campaignHandsIcon from 'src/assets/icons/campaign-hands-icon.svg';
import campaignSummaryIcon from 'src/assets/icons/campaign-summary-icon.svg';
import emailIcon from 'src/assets/icons/email.svg';
import twitterIcon from 'src/assets/icons/x-twitter-icon.svg';
import {
  CampaignActions,
  CampaignCategory,
  CampaignSearchListType,
  CampaignSequenceDelayUnit,
  CampaignSequenceStepType,
  CampaignType,
} from 'src/enums';
import { ICampaign, IGetCampaigns, ISequence, ISequenceStep } from 'src/types';

type TConfigSequenceDetailsResponse = null | {
  icon: CampaignCategory;
  title: string;
  messages?: {
    title: string;
    key: keyof ISequenceStep;
  }[];
};

export const getIconByAction = (action: string) => {
  if (action === CampaignActions.TWITTER_REPLY_DETECTED) {
    return { color: '#000000', faIcon: faXTwitter };
  }
  if (action === CampaignActions.EMAIL_REPLY_DETECTED) {
    return { color: 'var(--primary-main)', faIcon: faEnvelope };
  }

  if (action === CampaignActions.INMAIL_REPLY_DETECTED) {
    return { color: '#0072b1', faIcon: faCommentDots };
  }

  return { color: '#0072b1', faIcon: faLinkedin };
};

export const resolveShowCampaignChart = ({
  leadsFound,
  requestedConnect,
  connected,
  messages,
  emails,
  views,
  withdrawals,
  responded,
}: IGetCampaigns) => {
  return !!(leadsFound && (requestedConnect || connected || messages || emails || views || withdrawals || responded));
};

export const configChooseTemplateMessageResolver = (type: CampaignSequenceStepType, field: keyof ISequenceStep) => {
  switch (type) {
    case CampaignSequenceStepType.IN_MAIL_MESSAGE:
      return {
        withSubject: true,
        withAttachments: true,
        icon: campaignSummaryIcon,
        title: 'Send InMail message',
        messageTitle: 'Message:',
        modalTitle: 'Choose InMail Template',
      };
    case CampaignSequenceStepType.LINKED_IN_CONNECT:
      return {
        maxLength: field === 'message' ? 280 : 0,
        icon: campaignHandsIcon,
        title: 'LinkedIn connect:',
        messageTitle: field === 'message' ? 'Message' : 'Follow-up Message',
        modalTitle: field === 'message' ? 'Choose Linkedin Connection Message' : 'Choose Follow Up Message',
      };
    case CampaignSequenceStepType.LINKED_IN_MESSAGE:
      return {
        withAttachments: true,
        icon: campaignSummaryIcon,
        title: 'Send LinkedIn message',
        messageTitle: 'Message:',
        modalTitle: 'Choose Message Template',
      };
    case CampaignSequenceStepType.GROUP_MESSAGE:
      return {
        icon: campaignSummaryIcon,
        title: 'Group Message',
        messageTitle: 'Message:',
        modalTitle: 'Choose Group Message Template',
      };
    case CampaignSequenceStepType.EVENT_MESSAGE:
      return {
        icon: campaignSummaryIcon,
        title: 'Event Message',
        messageTitle: 'Message:',
        modalTitle: 'Choose Event Message Template',
      };
    case CampaignSequenceStepType.EMAIL:
      return {
        withSubject: true,
        withAttachments: true,
        icon: emailIcon,
        title: 'Email',
        messageTitle: 'Message:',
        modalTitle: 'Choose Email Template',
      };
    case CampaignSequenceStepType.TWITTER_DM:
      return {
        icon: twitterIcon,
        withAttachments: true,
        title: 'Send message via X (Twitter)',
        messageTitle: 'Message:',
        modalTitle: 'Choose X (Twitter) DM Template',
      };
    default:
      return {
        icon: campaignSummaryIcon,
        title: 'Send message',
        messageTitle: 'Message:',
        modalTitle: 'Choose Message Template',
        withSubject: true,
        withAttachments: true,
      };
  }
};

export const configSequenceDetails = (
  type: CampaignSequenceStepType,
  sequence: ISequenceStep,
): TConfigSequenceDetailsResponse => {
  const { connect_followup } = sequence;

  switch (type) {
    case CampaignSequenceStepType.IN_MAIL_MESSAGE:
      return {
        icon: CampaignCategory.LINKEDIN,
        title: 'Send InMail message',
        messages: [
          { key: 'subject', title: 'Subject:' },
          { key: 'message', title: 'Message:' },
        ],
      };
    case CampaignSequenceStepType.LINKED_IN_CONNECT:
      return {
        icon: CampaignCategory.LINKEDIN,
        title: 'LinkedIn connect',
        messages: [
          { key: 'message', title: 'Message:' },
          { key: 'followup_message', title: connect_followup ? 'Follow-up Message:' : '' },
        ],
      };
    case CampaignSequenceStepType.LINKED_IN_MESSAGE:
      return {
        icon: CampaignCategory.LINKEDIN,
        title: 'Send LinkedIn message',
        messages: [{ key: 'message', title: 'Message:' }],
      };
    case CampaignSequenceStepType.LINKED_IN_VIEW:
      return {
        icon: CampaignCategory.LINKEDIN,
        title: 'Engage with Profile',
      };
    case CampaignSequenceStepType.EMAIL:
      return {
        icon: CampaignCategory.EMAIL,
        title: 'Send email',
        messages: [
          { key: 'subject', title: 'Subject:' },
          { key: 'message', title: 'Message:' },
        ],
      };
    case CampaignSequenceStepType.TWITTER_DM:
      return {
        icon: CampaignCategory.TWITTER,
        title: 'Send X (Twitter) Direct Message',
        messages: [{ key: 'message', title: 'Message:' }],
      };
    case CampaignSequenceStepType.TWITTER_FOLLOW:
      return {
        icon: CampaignCategory.TWITTER,
        title: 'Follow Lead on X (Twitter)',
      };
    case CampaignSequenceStepType.GROUP_MESSAGE:
      return {
        icon: CampaignCategory.LINKEDIN,
        title: 'Send Group message',
        messages: [{ key: 'message', title: 'Message:' }],
      };
    case CampaignSequenceStepType.EVENT_MESSAGE:
      return {
        icon: CampaignCategory.LINKEDIN,
        title: 'Send Event message',
        messages: [{ key: 'message', title: 'Message:' }],
      };
    default:
      return null;
  }
};

export const summaryBoardTypeResolver = (type?: CampaignType, isSummary = false) => {
  if (isSummary) {
    switch (type) {
      case 'linkedin event':
        return {
          description: 'LinkedIn Event',
          linkTitle: 'LinkedIn Event URL',
          linkText: 'Open LinkedIn Event and review attendees',
          isShowParameters: true,
        };

      case 'email_only':
        return {
          description: 'Email addresses uploaded via CSV file',
        };
      case 'email_connections':
        return {
          description: 'Email addresses uploaded via Contacts',
        };

      case 'twitter_connections':
        return {
          description: 'X (Twitter) profiles uploaded via Contacts',
        };
      case 'twitter_csv':
        return {
          description: 'X (Twitter) profiles uploaded via CSV file',
        };
      case 'zapier leads':
        return {
          description: 'Zapier leads',
        };
    }
  }

  switch (type) {
    case 'csv upload':
      return {
        description: 'LinkedIn URLs uploaded via CSV file',
      };

    case 'email_only':
      return {
        description: 'Email addresses uploaded via CSV file',
      };

    case 'crm connections':
      return {
        description: '1st degree connections',
      };

    case 'sales navigator search':
      return {
        description: 'Sales Navigator search via URL',
        linkTitle: 'Sales Navigator Search URL',
        linkText: 'View Sales Navigator search results on LinkedIn',
        isShowParameters: true,
      };

    case 'sales navigator saved search':
      return {
        description: 'Sales Navigator saved search via URL',
        linkTitle: 'Sales Navigator Saved Search URL',
        linkText: 'View Sales Navigator saved search results on LinkedIn',
        isShowParameters: true,
      };

    case 'recruiter search':
      return {
        description: 'Recruiter search via URL',
        linkTitle: 'Recruiter Search URL',
        linkText: 'View Recruiter search results on LinkedIn',
      };

    case 'linkedin group':
      return {
        description: 'LinkedIn Group',
        linkTitle: 'LinkedIn Group',
        linkText: 'View members of LinkedIn Group',
        isShowParameters: true,
      };

    case 'linkedin post':
      return {
        description: 'LinkedIn Post',
        linkTitle: 'LinkedIn Post URL',
        linkText: 'View LinkedIn Post',
        isShowParameters: true,
      };

    case 'zapier':
      return {
        description: 'Send LinkedIn URLs to Alfred through Zapier',
        linkTitle: 'LinkedIn Event URL',
        linkText: 'Open LinkedIn Event and review attendees',
      };

    default:
      return {
        description: 'Linkedin Search',
        linkTitle: 'Search URL',
        linkText: 'View search results on LinkedIn',
        isShowParameters: true,
      };
  }
};

interface IGetEmptySequenceStepProps {
  campaign?: ICampaign;
  isFirstStep: boolean;
  sequenceType: CampaignSequenceStepType;
  sequenceForm: UseFormReturn<ISequence>;
}

export const getEmptySequenceStep = ({
  campaign,
  isFirstStep,
  sequenceForm,
  sequenceType,
}: IGetEmptySequenceStepProps): Omit<ISequenceStep, 'type'> => {
  const { getValues } = sequenceForm;
  const values = getValues().sequence || [];

  const defaultMessage: Record<string, string> = {
    [CampaignSequenceStepType.TWITTER_DM]: `Hi there!`,
    [CampaignSequenceStepType.GROUP_MESSAGE]: `Hi there!`,
    [CampaignSequenceStepType.EVENT_MESSAGE]: `Hi there!`,
    [CampaignSequenceStepType.LINKED_IN_MESSAGE]: `Hi there!`,
    [CampaignSequenceStepType.IN_MAIL_MESSAGE]: 'How are you?',
    [CampaignSequenceStepType.EMAIL]: `<p>Hi,</p><p>How are you today?</p>`,
    [CampaignSequenceStepType.LINKED_IN_CONNECT]: `Looks like we have similar connections, let's connect :)`,
  };

  const defaultSubject: Record<string, string> = {
    [CampaignSequenceStepType.EMAIL]: values[values.length - 1]?.subject || 'Checking in',
    [CampaignSequenceStepType.IN_MAIL_MESSAGE]: 'Hi there!',
  };

  const defaultFollowupMessage: Record<string, string> = {
    [CampaignSequenceStepType.LINKED_IN_CONNECT]: `Thanks for connecting with me.`,
  };

  const emptyStep: Omit<ISequenceStep, 'type'> = {
    delay_time_unit: CampaignSequenceDelayUnit.DAYS,
    delay_number: isFirstStep ? 0 : 1,
    proceed_to_next: false,
    followup_message: defaultFollowupMessage[sequenceType] ?? '',
    days_til_revoke: 0,
    message: defaultMessage[sequenceType] ?? '',
    subject: defaultSubject[sequenceType] ?? '',
    connect_followup: sequenceType === CampaignSequenceStepType.LINKED_IN_CONNECT,
    revoke_invite: false,
    auto_follow_twitter: false,
    gmail_signature: false,
    is_editing: true,
    auto_endorse: false,
    auto_post_like: false,
    auto_follow: false,
    view: false,
    endorsements_count: 1,
    posts_count: 1,
    tags: [],
    attachments: [],
  };

  if (campaign?.campaignType === CampaignType.LINKEDIN_GROUP) {
    emptyStep.group_id = campaign.searchUrl.split('/').pop();
  }

  return emptyStep;
};

export const resolveSNSearchUrl = (option: { type: string; value: string }): string => {
  switch (option.type) {
    case CampaignSearchListType.ACCOUNT_LIST:
      return `https://www.linkedin.com/sales/lists/company/${option.value}`;
    case CampaignSearchListType.SAVED_ACCOUNTS_SEARCH:
      return `https://www.linkedin.com/sales/search/company?savedSearchId=${option.value}`;
    case CampaignSearchListType.LEAD_LIST:
      return `https://www.linkedin.com/sales/lists/people/${option.value}`;
    case CampaignSearchListType.SAVED_LEADS_SEARCH:
      return `https://www.linkedin.com/sales/search/people?savedSearchId=${option.value}`;
    default:
      return '';
  }
};

export const resolveNoLeadsPlaceholder = (campaignType?: CampaignType, shortAppName?: string) => {
  const isCsvUploadCampaign =
    !!campaignType &&
    [CampaignType.LINKEDIN_CSV_UPLOAD, CampaignType.EMAIL_CSV_UPLOAD, CampaignType.TWITTER_CSV_UPLOAD].includes(
      campaignType,
    );

  return isCsvUploadCampaign
    ? `No leads at this time. If you have just started this campaign, please wait while ${
        shortAppName || 'Alfred'
      } verifies new leads.`
    : `No leads at this time. If you have just started this campaign, please wait while ${
        shortAppName || 'Alfred'
      } searches out new leads.`;
};

export const getReturnToCampaignTooltip = ({
  isApproved,
  isReturnToCampaign,
  isCampaignRemoved,
}: {
  isApproved: boolean;
  isReturnToCampaign: boolean;
  isCampaignRemoved: boolean;
}) => {
  const suffix = isReturnToCampaign ? 'ed' : '';

  if (isCampaignRemoved) {
    return 'Campaign is deleted';
  }

  return isApproved ? `Return${suffix} to campaign` : 'The Lead is not in the campaign';
};

export const getRemovedNotificationText = ({
  isCampaignRemoved,
  isLeadRemoved,
}: {
  isCampaignRemoved: boolean;
  isLeadRemoved: boolean;
}): string | null => {
  if (isCampaignRemoved) {
    return 'Campaign deleted';
  }

  if (isLeadRemoved) {
    return 'Deleted from campaign';
  }

  return null;
};
