import { debounce } from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { MultiValue, SingleValue } from 'react-select';
import { Box } from '@mui/material';

import { getAvailableSequenceTemplates } from 'src/api';
import { Button, SelectBasic, SelectBasicAsync, Typography } from 'src/components';
import { CAMPAIGN_CATEGORIES } from 'src/constants';
import { SequenceStep } from 'src/containers/Campaigns/_components';
import { CampaignCategory } from 'src/enums';
import { closeModal } from 'src/store';
import { transformSequenceTemplatesToSelectOptions } from 'src/transformers';
import { IColorSelectOption, IModalProps, ISequenceStep, ISequenceTemplateSelectOption } from 'src/types';
import { ModalBody, ModalFooter } from '../_components';

interface ModalViewSequenceTemplateProps {
  category: CampaignCategory;
  onAddSequenceTemplate: (steps: Array<ISequenceStep>) => void;
}

export const CAMPAIGN_CATEGORIES_GROUPED = CAMPAIGN_CATEGORIES.reduce(
  (gen, curr) => {
    gen[curr.category] = curr.name;

    return gen;
  },
  {} as Record<CampaignCategory, string>,
);

const loadAutocomplete = (
  {
    search,
    category,
  }: {
    search: string;
    category: CampaignCategory;
  },
  callback: (array: Array<ISequenceTemplateSelectOption>) => void,
) => {
  getAvailableSequenceTemplates({
    search,
    category,
  }).then((resp) => callback(transformSequenceTemplatesToSelectOptions(resp)));
};

const debouncedAutocomplete = debounce(loadAutocomplete, 500);

export const ModalChooseSequenceTemplate = ({
  params: { category, onAddSequenceTemplate },
}: IModalProps<ModalViewSequenceTemplateProps>) => {
  const dispatch = useDispatch();

  const [sequence, setSequence] = useState<Array<ISequenceStep>>([]);

  const onCancel = () => {
    dispatch(closeModal());
  };

  const onAdd = () => {
    onAddSequenceTemplate(sequence || []);
    dispatch(closeModal());
  };

  const onTemplateChange = (value: SingleValue<IColorSelectOption> | MultiValue<IColorSelectOption>) => {
    const template = value as SingleValue<IColorSelectOption>;

    if (template) {
      setSequence(template.value?.sequence);
    }
  };

  return (
    <>
      <ModalBody>
        <Box display="flex" gap="24px">
          <Box width="33%">
            <Typography fontSize="14px" semibold mb="5px" lineHeight="1.3rem">
              Category{' '}
              <Typography inline color="error.light">
                *
              </Typography>
            </Typography>
            <SelectBasic
              styles={{ container: (styles) => ({ ...styles, height: '42px' }) }}
              isDisabled
              value={{ value: category, label: CAMPAIGN_CATEGORIES_GROUPED[category] }}
            />
            <Typography fontSize="14px" semibold mb="5px" mt="16px" lineHeight="1.3rem">
              Template{' '}
              <Typography inline color="error.light">
                *
              </Typography>
            </Typography>
            <SelectBasicAsync
              placeholder="Search Template"
              defaultOptions
              cacheOptions
              onChange={onTemplateChange}
              styles={{ container: (styles) => ({ ...styles, height: '42px' }) }}
              loadOptions={(search, cb) => debouncedAutocomplete({ search, category }, cb)}
            />
          </Box>
          <Box maxHeight="500px" overflow="scroll" margin="0 auto">
            {sequence?.map((step, index) => (
              <SequenceStep
                key={index}
                index={index}
                sequence={step}
                highlight={sequence?.length !== 1 && index !== sequence?.length - 1}
              />
            ))}
          </Box>
        </Box>
      </ModalBody>

      <ModalFooter alignToTheEnd>
        <Button variant="gray" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={onAdd} disabled={!sequence?.length}>
          Add
        </Button>
      </ModalFooter>
    </>
  );
};
