import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { restartSearchCampaign } from 'src/api';
import { CampaignCategory } from 'src/enums';
import { showToast } from 'src/store/toast.slice';
import {
  ICustomAxiosError,
  IGetCampaign,
  IGetCampaignResponse,
  ILeadSource,
  IRestartSearchCampaignRequest,
} from 'src/types';

export const useRestartSearchCampaign = (
  { category, campaignId }: { campaignId: number; category?: CampaignCategory },
  options?: UseMutationOptions<void, ICustomAxiosError, IRestartSearchCampaignRequest>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const getCampaignsKey = ['get-campaigns', category];
  const getLeadSourcesKey = ['get-lead-sources', campaignId];
  const getCampaignKey = ['campaign', campaignId];

  const { mutate, ...rest } = useMutation(
    ['restart-search', campaignId],
    (data: IRestartSearchCampaignRequest) => restartSearchCampaign(campaignId, data),
    {
      ...options,
      onMutate: async (variables) => {
        options?.onMutate?.(variables);
        const { leadSourceIds, restartCampaign } = variables;
        await queryClient.cancelQueries(getCampaignsKey);
        await queryClient.cancelQueries(getLeadSourcesKey);
        await queryClient.cancelQueries(getCampaignKey);

        const prevDataCampaign = queryClient.getQueryData(getCampaignKey);
        const prevDataCampaigns = queryClient.getQueriesData(getCampaignsKey);
        const prevDataLeadSources = queryClient.getQueryData(getLeadSourcesKey);

        if (leadSourceIds?.length) {
          queryClient.setQueryData<ILeadSource[] | undefined>(
            getLeadSourcesKey,
            (data) =>
              data &&
              data.map((leadSource) =>
                leadSourceIds.includes(leadSource.id) ? { ...leadSource, isSearchEnded: false } : leadSource,
              ),
          );

          queryClient.setQueriesData<IGetCampaignResponse | undefined>(
            getCampaignsKey,
            (data) =>
              data && {
                ...data,
                campaigns: data.campaigns.map((campaign) =>
                  campaign.id === campaignId
                    ? { ...campaign, leadSourcesSearchEnded: campaign.leadSourcesSearchEnded - leadSourceIds.length }
                    : campaign,
                ),
              },
          );
        }

        if (restartCampaign) {
          if (prevDataCampaign) {
            queryClient.setQueryData<IGetCampaign | undefined>(
              getCampaignKey,
              (data) => data && { ...data, isSearchEnded: false },
            );
          }

          queryClient.setQueriesData<IGetCampaignResponse | undefined>(
            getCampaignsKey,
            (data) =>
              data && {
                ...data,
                campaigns: data.campaigns.map((campaign) =>
                  campaign.id === campaignId ? { ...campaign, isSearchEnded: false } : campaign,
                ),
              },
          );
        }

        return { prevData: { prevDataCampaigns, prevDataLeadSources, prevDataCampaign } };
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueriesData(getCampaignsKey, context?.prevData.prevDataCampaigns);
        queryClient.setQueryData(getLeadSourcesKey, context?.prevData.prevDataLeadSources);
        queryClient.setQueryData(getCampaignKey, context?.prevData.prevDataCampaign);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        dispatch(
          showToast({
            type: 'info',
            message: 'SUCCESSFULLY RESTARTED SEARCH',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { restartSearch: mutate, ...rest };
};
