import { Breadcrumbs, HeaderContainer, HeaderDescription, HeaderTitle, Link, Typography } from 'src/components';

interface EditSubscriptionLayoutProps {
  isPlanChange?: boolean;
}

export const EditSubscriptionLayout = ({ isPlanChange }: EditSubscriptionLayoutProps) => {
  if (isPlanChange) {
    return (
      <HeaderContainer pt="16px">
        <Breadcrumbs>
          <Link to="/billing/subscription">
            <Typography>Subscription Plan</Typography>
          </Link>
          <Link to="/billing/subscription/plans">
            <Typography>Change Plan</Typography>
          </Link>
          <Typography>New Plan</Typography>
        </Breadcrumbs>
        <HeaderTitle pt="16px">New plan</HeaderTitle>
        <HeaderDescription>Set up your new plan and confirm the changes.</HeaderDescription>
      </HeaderContainer>
    );
  }

  return (
    <HeaderContainer pt="16px">
      <Breadcrumbs>
        <Link to="/billing/subscription">
          <Typography>Subscription Plan</Typography>
        </Link>
        <Typography>Edit your plan</Typography>
      </Breadcrumbs>
      <HeaderTitle pt="16px">Edit your plan</HeaderTitle>
      <HeaderDescription>Modify your current plan to better suit your needs and preferences.</HeaderDescription>
    </HeaderContainer>
  );
};
