import { Link, NotificationDot } from 'src/components';
import { LEAD_ACTIONS_MESSAGES, LEAD_ACTIVITY_ACTIONS } from 'src/constants';
import { ACTIONS_SYSTEM_MESSAGES, LeadActivityEnum } from 'src/enums';
import { ICampaignActivity } from 'src/types';

export const resolveActionDescription = (action: LeadActivityEnum, msg: string | null = '') => {
  const showNotificationDot =
    msg &&
    (
      [
        ACTIONS_SYSTEM_MESSAGES.withoutMessage,
        ACTIONS_SYSTEM_MESSAGES.postponed,
        ACTIONS_SYSTEM_MESSAGES.attachmentWithoutMessage,
      ] as Array<string>
    ).includes(msg);

  return (
    <>
      {LEAD_ACTIVITY_ACTIONS[action] || action}
      {showNotificationDot ? <NotificationDot tooltip={{ title: LEAD_ACTIONS_MESSAGES[msg] }} /> : null}
    </>
  );
};

export const resolveActionLikedPosts = (posts: string): JSX.Element => {
  try {
    const postsArray: Array<string> = JSON.parse(posts);

    return (
      <>
        {postsArray.map((link, index) => (
          <span key={link}>
            {index ? ', ' : ''}
            <Link external to={link} openNewTab onClick={(e) => e.stopPropagation()} underline>
              Post {index + 1}
            </Link>
          </span>
        ))}
      </>
    );
  } catch (e) {
    return <></>;
  }
};

export const resolveActionMessage = (action: LeadActivityEnum, message: string): JSX.Element | string => {
  if (message === ACTIONS_SYSTEM_MESSAGES.attachmentWithoutMessage) {
    return '';
  }

  if (action === LeadActivityEnum.LIKED_POST) {
    return resolveActionLikedPosts(message);
  }

  return message;
};

export const resolvePersonNameForActivity = (action: ICampaignActivity): string => {
  if (action.description === LeadActivityEnum.INVITE_WITHDRAWN) {
    return action.person?.name || action.message || '';
  }

  return action.person?.name || '';
};

export const resolveActionStepNumber = ({
  action,
  stepNumber,
  totalSteps,
  incrementStepNumber = false,
}: {
  action?: LeadActivityEnum;
  stepNumber: number | null;
  totalSteps: number;
  incrementStepNumber?: boolean;
}): string => {
  if (
    action &&
    ![
      LeadActivityEnum.EMAILED,
      LeadActivityEnum.TWITTER_MESSAGE_SENT,
      LeadActivityEnum.LEAD_FOLLOWED_IN_TWITTER,
      LeadActivityEnum.EVENT_MESSAGE_SENT,
      LeadActivityEnum.GROUP_MESSAGE_SENT,
      LeadActivityEnum.INMAIL_MESSAGE_SENT,
      LeadActivityEnum.MESSAGE_SENT,
      LeadActivityEnum.FOLLOWED_AFTER_CONNECT,
      LeadActivityEnum.REQUESTED_CONNECT,
      LeadActivityEnum.LIKED_POST,
      LeadActivityEnum.ENDORSED_PROFILE,
      LeadActivityEnum.FOLLOWED_PROFILE,
      LeadActivityEnum.VIEWED,
    ].includes(action)
  )
    return '';

  if (stepNumber === null || !totalSteps) return '';

  const step = incrementStepNumber ? stepNumber + 1 : stepNumber;

  return step > totalSteps ? `${totalSteps}/${totalSteps}` : `${step}/${totalSteps}`;
};
