import { InfiniteData, useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { modifyConversation } from 'src/api';
import { LIConversationFilterType, ModifyConversationActions } from 'src/enums';
import { useAppSelector } from 'src/hooks';
import { setSelectedConversation } from 'src/store';
import { ICustomAxiosError, ILIConversation } from 'src/types';

export const useModifyConversation = (
  conversationId: string,
  isSales = false,
  options?: UseMutationOptions<void, ICustomAxiosError, ModifyConversationActions>,
) => {
  const dispatch = useDispatch();
  const { filterType } = useAppSelector((state) => state.conversation);

  const queryClient = useQueryClient();

  const taggedConversation = filterType === LIConversationFilterType.TAGGED && ['get-tagged-conversations'];
  const replyConversation = filterType === LIConversationFilterType.REPLIES && ['get-replies-conversations'];

  const liQueryKey = taggedConversation || replyConversation || ['get-li-conversations', filterType];
  const queryKey = isSales ? ['get-sn-conversations'] : liQueryKey;

  const { mutate, ...rest } = useMutation<void, ICustomAxiosError, ModifyConversationActions, { prevData: unknown }>(
    ['modify-conversation', conversationId],
    (action: ModifyConversationActions) => modifyConversation({ conversationId, action, isSales }),
    {
      onMutate: async (action) => {
        options?.onMutate?.(action);
        await queryClient.cancelQueries(queryKey);

        const prevData = queryClient.getQueryData(queryKey);

        queryClient.setQueriesData<InfiniteData<ILIConversation[]> | undefined>(queryKey, (conversations) => {
          if (conversations) {
            const newPages = conversations.pages.map((results) => {
              return results
                .map((conv) => {
                  if (conv.conversationId === conversationId && action === ModifyConversationActions.UNREAD) {
                    return {
                      ...conv,
                      unreadCount: 1,
                    };
                  }

                  return conv;
                })
                .filter((conv) => {
                  if (
                    conv.conversationId === conversationId &&
                    [
                      ModifyConversationActions.ARCHIVE,
                      ModifyConversationActions.UNARCHIVE,
                      ModifyConversationActions.DELETE,
                    ].includes(action)
                  ) {
                    return false;
                  }

                  return !!conv;
                });
            });

            return { ...conversations, pages: newPages };
          }

          return conversations;
        });

        if (action !== ModifyConversationActions.UNREAD) {
          dispatch(setSelectedConversation(null));
        }

        return { prevData };
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueryData([queryKey], context?.prevData);
      },
      onSettled: async (data, error, variables, context) => {
        options?.onSettled?.(data, error, variables, context);
        queryClient.invalidateQueries(['get-li-conversations']).catch(console.error);
        queryClient.invalidateQueries(['get-tagged-conversations']).catch(console.error);
      },
    },
  );

  return {
    modifyConversation: mutate,
    ...rest,
  };
};
