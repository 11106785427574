import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';

import { Button, Input, Typography } from 'src/components';
import { REGEXP_VALID_EMAIL } from 'src/constants';
import { LeadActivityEnum } from 'src/enums';
import { useAppSelector } from 'src/hooks';
import { useExportCSVCampaignActivity } from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';
import { IModalProps } from 'src/types';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../_components';

const StyledInput = styled(Input)`
  height: 34px;
  margin-top: 20px;
`;

interface IModalExportCampaignActivity {
  campaignId: number;
  filters: LeadActivityEnum[];
}
export const ModalExportCampaignActivity = ({ onCancel, params }: IModalProps<IModalExportCampaignActivity>) => {
  const dispatch = useDispatch();
  const { profile } = useAppSelector((state) => state.user);

  const { campaignId, filters } = params || {};

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<{
    email: string;
  }>({ defaultValues: { email: profile.email ?? '' } });

  const { exportCampaignActivity, isLoading, isSuccess } = useExportCSVCampaignActivity(campaignId);

  const confirmHandler = ({ email }: { email: string }) => {
    exportCampaignActivity({
      email,
      filters,
    });
  };

  const closeHandler = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  return (
    <ModalContainer>
      <ModalHeader onClose={closeHandler}>{isSuccess ? 'Export confirmation' : 'Email confirmation'}</ModalHeader>

      <ModalBody>
        {isSuccess ? (
          <Typography color="violet.dark">
            We are processing your request. You will receive the exported file on an email shortly.
          </Typography>
        ) : (
          <>
            <Typography color="violet.dark">Please type the email you want to use for receiving export.</Typography>
            <StyledInput
              register={register}
              name="email"
              parameters={{
                required: 'Please enter email',
                pattern: {
                  value: REGEXP_VALID_EMAIL,
                  message: 'Please enter a valid email',
                },
              }}
              placeholder="email"
            />
            {errors.email && (
              <Typography color="error.light" mt="5px">
                {errors.email?.message}
              </Typography>
            )}
          </>
        )}
      </ModalBody>

      <ModalFooter alignToTheEnd={isSuccess}>
        <Button variant="gray" onClick={closeHandler}>
          {isSuccess ? 'Close' : 'Cancel'}
        </Button>
        {!isSuccess && (
          <Button disabled={isLoading} processing={isLoading} onClick={handleSubmit(confirmHandler)}>
            Confirm
          </Button>
        )}
      </ModalFooter>
    </ModalContainer>
  );
};
