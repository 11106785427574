import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { Box, CircularProgress } from '@mui/material';

import {
  ActionButton,
  Attachment,
  Button,
  Checkbox,
  HiddenFileInput,
  TextEditor,
  Tooltip,
  Typography,
} from 'src/components';
import { uploadAttachmentToBucket } from 'src/helpers';
import { useGetUserSignature, useUpdateUserSignature } from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';
import { showToast } from 'src/store/toast.slice';
import { IAttachment } from 'src/types';

const SignatureBox = styled(Box)`
  background: ${({ theme }) => theme.palette.light.light};
  padding: 30px 40px;
`;

const MAX_FILES_LIMIT = 1;

export const ModalSignature = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [attachments, setAttachments] = useState<IAttachment[]>([]);
  const [isExcludedSignatureDash, setIsExcludedSignatureDash] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { signature, isFetching } = useGetUserSignature();
  const { updateUserSignature, isLoading: isUpdating } = useUpdateUserSignature();

  useEffect(() => {
    setValue(signature?.emailSignature || '');
    setAttachments(signature?.emailAttachments || []);
    setIsExcludedSignatureDash(!!signature?.isExcludedSignatureDash);
  }, [signature]);

  const onAttachmentClick = () => fileInputRef.current?.click();
  const onAttachmentChange = () => {
    const candidateFile = (fileInputRef?.current?.files ?? [])[0];
    const showToastWrapper = (type: 'info' | 'error' | 'success', message: string) =>
      dispatch(
        showToast({
          type,
          message,
          autoCloseTime: 3000,
        }),
      );

    if (attachments.length >= MAX_FILES_LIMIT) {
      return showToastWrapper('info', 'You can only add one image!');
    }

    showToastWrapper('info', 'Uploading start');

    return uploadAttachmentToBucket({
      file: candidateFile,
      onSuccess(resp) {
        setAttachments((prev) => [...prev, resp]);
        showToastWrapper('success', 'File has been added');
      },
      onUrlError() {
        showToastWrapper('error', 'Uploading failed! Please contact support!');
      },
      onUploadError() {
        showToastWrapper('error', "Couldn't upload the file!");
      },
    });
  };

  const update = () => {
    updateUserSignature({ emailSignature: value, emailAttachments: attachments, isExcludedSignatureDash });
    dispatch(closeModal());
  };

  if (isFetching) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="200px">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <SignatureBox>
        <Box position="relative">
          <TextEditor
            defaultValue={signature?.emailSignature || ''}
            onChange={setValue}
            height="100%"
            setOptions={{
              buttonList: [['undo', 'redo'], ['list'], ['underline', 'italic', 'bold']],
            }}
          />

          <ActionButton
            position="absolute"
            right="10px"
            bottom="10px"
            zIndex="8"
            icon={faPaperclip}
            onClick={onAttachmentClick}
          />
          <HiddenFileInput
            ref={fileInputRef}
            type="file"
            accept="image/gif,image/jpeg,image/jpg,image/png,image/bmp,image/webp"
            onChange={onAttachmentChange}
          />
        </Box>
        <Box display="grid" gridTemplateColumns="1fr 1fr" alignItems="center" my="10px">
          {!!attachments.length && (
            <Box>
              {attachments.map(({ file_name, file_uri }) => (
                <Attachment
                  m="0"
                  bgcolor="white"
                  key={file_uri}
                  name={file_name}
                  onDeleteClick={() => setAttachments([])}
                />
              ))}
            </Box>
          )}

          <Box>
            <Tooltip
              title={
                <Box textAlign="left">
                  This option will add separator which includes two dashes and new-line. For example:
                  <br /> Email text
                  <br /> --
                  <br /> Signature
                </Box>
              }
            >
              <Box display="inline-flex" alignItems="center">
                <Checkbox
                  type="checkbox"
                  checked={!isExcludedSignatureDash}
                  onChange={() => setIsExcludedSignatureDash((prev) => !prev)}
                />
                <Typography ml="8px">Add separator</Typography>
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </SignatureBox>

      <Box py={2} px={5} display="flex" justifyContent="flex-end" id="here">
        <Button disabled={isUpdating} onClick={update}>
          Update
        </Button>
      </Box>
    </>
  );
};
