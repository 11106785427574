import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { updateCampaignAudience } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICampaign, ICustomAxiosError } from 'src/types';

export const useUpdateCampaignAudience = (
  id: number,
  options?: UseMutationOptions<ICampaign, ICustomAxiosError, Omit<ICampaign, 'status' | 'id' | 'touchSequence'>>,
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { mutate, isLoading, ...rest } = useMutation<
    ICampaign,
    ICustomAxiosError,
    Omit<ICampaign, 'id' | 'status' | 'touchSequence'>
  >(['update-campaign-audience'], (data) => updateCampaignAudience(id, data), {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      await queryClient.cancelQueries(['campaign', data.id]);

      queryClient.setQueryData<ICampaign>(['campaign', data.id], () => data);

      navigate(`/campaign/${data.id}/sequence`);
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { updateCampaignAudience: mutate, isUpdateLoading: isLoading, ...rest };
};
