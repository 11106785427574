import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { faPencilAlt, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import {
  HeaderButton,
  HeaderContainer,
  HeaderDescription,
  HeaderTabLink,
  HeaderTabsContainer,
  HeaderTitle,
  HeaderVideo,
  Link,
} from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { FeatureFlagEnum, Features, TeamUserRole, TrialStatus } from 'src/enums';
import { useAppSelector, useFeatureFlag, useTeamPlan, useWhiteLabel } from 'src/hooks';
import { useGetBillingInfo, useUpdateTeam } from 'src/reactQueries';
import { TextInput } from '../_components';

const RowContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const RowCellContainer = styled(Box)`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-grow: 1;
  align-items: center;
`;

const ButtonContainer = styled(RowCellContainer)`
  flex-grow: 0;
`;

const IconButton = styled(Box)`
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.light.dark} !important;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-left: 8px;
  margin-top: 20px;
  width: 30px;

  &:hover {
    background: ${({ theme }) => theme.palette.light.light};
  }
`;

interface IManageTeamLayoutProps {
  hideChangeUsersSeats?: boolean;
}

export const ManageTeamLayout = ({ hideChangeUsersSeats = false }: IManageTeamLayoutProps) => {
  const navigate = useNavigate();
  const { whitelabel } = useWhiteLabel();
  const { checkFeature, isTrial, isBusiness, isBusinessV2 } = useTeamPlan();

  const teamData = useAppSelector((state) => state.team.data);
  const { isAppDeactivated } = useAppSelector((state) => state.user);

  const [isEditing, setIsEditing] = useState(false);

  const [changeNumUsersPath] = useFeatureFlag(
    FeatureFlagEnum.updatedSubscriptionFlow,
    '/billing/subscription/edit',
    '/team/manage-team/seats',
  );

  const { handleSubmit, register, watch, setValue } = useForm<{
    teamName: string;
  }>({
    defaultValues: { teamName: teamData?.name ?? '' },
  });

  const { billingInfo, isSubscriptionCanceled } = useGetBillingInfo();
  const { updateTeam } = useUpdateTeam();
  const teamName = watch('teamName');

  const onChangeNumUserClick = () => navigate(changeNumUsersPath);

  const onCancelEditing = () => {
    setValue('teamName', teamData?.name ?? '');
    setIsEditing(false);
  };

  const onSaveChanges = () => {
    handleSubmit((values) => {
      updateTeam({ teamId: teamData?.id ?? -1, name: values.teamName });
      setIsEditing(false);
    })();
  };

  const disabledChangeSeats =
    billingInfo?.trial === TrialStatus.STARTED ||
    (billingInfo?.trial === TrialStatus.ENDED && !billingInfo?.hasPaidPlan) ||
    !billingInfo?.trial;

  return (
    <HeaderContainer>
      <RowContainer>
        {!isAppDeactivated && (
          <RowCellContainer>
            {isEditing ? (
              <>
                <Box width="60%" mt="15px">
                  <TextInput type="text" name="teamName" register={register} />
                </Box>
                <IconButton data-testid="save" onClick={onSaveChanges}>
                  <FontAwesomeIcon icon={faSave} fontSize="14px" />
                </IconButton>
                <IconButton data-testid="cancel" onClick={onCancelEditing}>
                  <FontAwesomeIcon icon={faTimes} fontSize="14px" />
                </IconButton>
              </>
            ) : (
              <>
                <HeaderTitle>Manage {teamName}</HeaderTitle>
                <IconButton data-testid="edit" onClick={() => setIsEditing(true)}>
                  <FontAwesomeIcon icon={faPencilAlt} fontSize="14px" />
                </IconButton>
              </>
            )}
          </RowCellContainer>
        )}
        <ButtonContainer>
          <Box marginTop="10px">
            {!hideChangeUsersSeats &&
              billingInfo?.canUpdateSeats &&
              teamData?.teamUser?.role === TeamUserRole.OWNER &&
              !whitelabel.isWhiteLabelUser && (
                <HeaderButton
                  onClick={onChangeNumUserClick}
                  disabled={disabledChangeSeats || isAppDeactivated || isSubscriptionCanceled}
                  tooltip={{
                    title:
                      (isSubscriptionCanceled &&
                        'Please resume your subscription to change the number of users on your team') ||
                      (disabledChangeSeats && 'Please subscribe to change the number of users on your team'),
                  }}
                >
                  Change number of users
                </HeaderButton>
              )}
          </Box>
        </ButtonContainer>
      </RowContainer>
      <HeaderDescription>
        Create accounts, invite users, assign roles, group accounts, and impersonate team members.
        {whitelabel.isWhiteLabelUser || (
          <>
            <Link
              leftIcon
              underline
              external
              openNewTab
              to="https://help.meetalfred.com/en/articles/3371554-administrate-team-members-and-multiple-accounts"
            >
              Learn how it works
            </Link>
            <HeaderVideo src="https://player.vimeo.com/video/622872104" />
          </>
        )}
      </HeaderDescription>
      {teamData?.teamUser?.role === TeamUserRole.OWNER && (
        <HeaderTabsContainer>
          {checkFeature(Features.teamMembers) && <HeaderTabLink to="/team/manage-team">Members</HeaderTabLink>}
          {checkFeature(Features.teamGroups) && (
            <HeaderTabLink
              to="/team/groups"
              disabled={isTrial || isBusiness}
              tooltip={(isTrial || isBusiness) && PLAN_TOOLTIPS.agency}
            >
              Groups
            </HeaderTabLink>
          )}
          {checkFeature(Features.teamSettings) && (
            <HeaderTabLink
              to="/team/settings"
              disabled={isTrial || isBusinessV2}
              tooltip={(isTrial || isBusinessV2) && PLAN_TOOLTIPS.agency}
            >
              Team Settings
            </HeaderTabLink>
          )}
        </HeaderTabsContainer>
      )}
    </HeaderContainer>
  );
};
