import { useMutation, UseMutationOptions } from 'react-query';

import { postFacebookData } from 'src/api';
import { ICustomAxiosError, IFacebookData } from 'src/types';

export const usePostFacebookData = (options?: UseMutationOptions<void, ICustomAxiosError, IFacebookData>) => {
  const { mutate, ...rest } = useMutation('post-facebook-data', postFacebookData, options);

  return {
    postFacebookData: mutate,
    ...rest,
  };
};
