import { useQuery, UseQueryOptions } from 'react-query';
import { useTheme } from '@emotion/react';

import { getAllTags } from 'src/api';
import { useAppSelector } from 'src/hooks';
import { ICustomAxiosError, ITag } from 'src/types';

export const useGetAllTags = (options?: UseQueryOptions<ITag[], ICustomAxiosError>) => {
  const { isAuth } = useAppSelector((state) => state.user);
  const { palette } = useTheme();

  const { data, ...rest } = useQuery<ITag[], ICustomAxiosError>(['get-all-tags'], getAllTags, {
    enabled: isAuth,
    refetchOnMount: true,
    ...options,
  });

  const normalizeTags = data?.map((tag) => ({ ...tag, color: tag?.color || palette.gray[600] }));

  return { tags: normalizeTags, ...rest };
};
