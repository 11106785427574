import { createFormData } from 'src/helpers';
import {
  ICreateTemplateRequest,
  IGetTemplatesRequest,
  IGetTemplatesResponse,
  ITemplate,
  ITemplateCategory,
  IUpdateTemplateRequest,
} from 'src/types';
import { appApi } from './axios';

export const getTemplatesPersonal = (params: IGetTemplatesRequest): Promise<IGetTemplatesResponse> =>
  appApi.get(`/templates/personal`, { params });

export const getTemplatesLibrary = (params: IGetTemplatesRequest): Promise<IGetTemplatesResponse> =>
  appApi.get(`/templates/library`, { params });

export const getTemplatesTeam = (params: IGetTemplatesRequest): Promise<IGetTemplatesResponse> =>
  appApi.get(`/templates/team`, { params });

export const getTemplateCategories = (): Promise<ITemplateCategory[]> => appApi.get(`/templates/categories`);

export const getTemplatesByCategory = (params: { categoryId?: number }): Promise<ITemplate[]> =>
  appApi.get(`/templates`, { params });

export const createTemplate = (params: ICreateTemplateRequest): Promise<void> =>
  appApi.post(`/templates`, createFormData(params), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const updateTemplate = (data: IUpdateTemplateRequest): Promise<void> =>
  appApi.put(`/templates/${data.id}`, createFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const updateToggleShareTemplate = (id: number): Promise<void> =>
  appApi.patch(`/templates/${id}/toggle-availability`);

export const deleteTemplate = (id: number): Promise<void> => appApi.delete(`/templates/${id}`);
