import { useQuery, UseQueryOptions } from 'react-query';

import { getUsersEmail } from 'src/api';
import { ICustomAxiosError, IUserEmailResponse } from 'src/types';

export const useGetUsersEmail = (options?: UseQueryOptions<IUserEmailResponse, ICustomAxiosError>) => {
  const { data, ...rest } = useQuery<IUserEmailResponse, ICustomAxiosError>('get-users-email', getUsersEmail, options);

  return { userEmail: data, ...rest };
};
