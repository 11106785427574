import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { PersonAvatar, Spinner, Typography } from 'src/components';
import { PersonalDetails } from 'src/containers/Contacts/_components';
import { useAppSelector } from 'src/hooks';
import { useGetLeadInfo } from 'src/reactQueries';
import { NoteForm, SelectLeadTag } from '../_components';

const Wrapper = styled(Box)`
  width: 20%;
  border-left: 1px solid ${({ theme }) => theme.palette.light.light};
  overflow: auto;
  height: 100%;
  padding-bottom: 80px;
  box-sizing: border-box;
  flex-shrink: 0;
`;

const MainInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 10px auto;
  padding: 5px 0 10px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.light.light};
`;

export const PersonInformation = () => {
  const { selectedConversation } = useAppSelector((state) => state.conversation);

  const { entityUrn = '', objectUrn = '' } = selectedConversation || {};

  const { leadInfo, isLeadLoading } = useGetLeadInfo(entityUrn, objectUrn);

  const { name = '', currentTitle = '' } = {
    name: leadInfo?.person?.name || selectedConversation?.name,
    currentTitle: leadInfo?.person?.currentTitle || selectedConversation?.headline,
  };

  if (isLeadLoading) {
    return (
      <Wrapper>
        <Spinner />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <MainInfo>
        <PersonAvatar src={selectedConversation?.imageUrl} width="100px" height="100px" />
        {!!name && (
          <Typography fontSize="14px" color="gray.dark" mt="10px" mb="5px" semibold>
            {name}
          </Typography>
        )}
        {!!currentTitle && (
          <Typography fontSize="13px" color="gray.light" my="5px" textAlign="center">
            {currentTitle}
          </Typography>
        )}
      </MainInfo>

      <Box mx="10px" borderBottom={({ palette }) => `1px solid ${palette.light.light}`}>
        <PersonalDetails person={leadInfo?.person} label="Personal details" />
      </Box>

      <Box mx="10px" py="15px" borderBottom={({ palette }) => `1px solid ${palette.light.light}`}>
        <Typography fontSize="14px" mb="5px">
          Tags
        </Typography>
        <SelectLeadTag leadInfo={leadInfo} />
      </Box>
      {selectedConversation?.isConnected && (
        <Box mx="10px" py="15px">
          <Typography fontSize="14px">Note</Typography>
          <NoteForm leadInfo={leadInfo} />
        </Box>
      )}
    </Wrapper>
  );
};
