import { useQuery, UseQueryOptions } from 'react-query';

import { getGroupListPage } from 'src/api/groups';
import { ICustomAxiosError, IGetGroupListPageResponse, IGetGroups } from 'src/types';

export const useGetGroupListPage = (
  { page, perPage, sortField, sortOrder }: IGetGroups,
  options?: UseQueryOptions<IGetGroupListPageResponse, ICustomAxiosError>,
) => {
  return useQuery<IGetGroupListPageResponse, ICustomAxiosError>(
    ['get-group-list-page', page, perPage, sortField, sortOrder],
    () => getGroupListPage({ page, perPage, sortField, sortOrder }),
    { refetchOnMount: true, ...options },
  );
};
