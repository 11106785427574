import { InfiniteData, useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { sendLIAttachmentMessage } from 'src/api';
import { LIMessageType } from 'src/enums';
import { useAppSelector } from 'src/hooks';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, ILIMessages, ISendLIMessageResponse } from 'src/types';

interface IUseSendLIAttachmentMessageParams {
  files: { file: File; fileSrc: string }[];
  message?: string;
}

export const useSendLIAttachmentMessage = (
  {
    conversationId,
    entityUrn,
  }: {
    entityUrn: string;
    conversationId: string;
  },
  options?: UseMutationOptions<ISendLIMessageResponse, ICustomAxiosError, IUseSendLIAttachmentMessageParams>,
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { linkedInEntityUrn } = useAppSelector((state) => state.user.profile);

  const getLiMessagesKey = ['get-li-conversation-messages', entityUrn, conversationId];

  const { mutate, isLoading, ...rest } = useMutation<
    ISendLIMessageResponse,
    ICustomAxiosError,
    IUseSendLIAttachmentMessageParams,
    { prevData: unknown }
  >(
    ['send-conversation-message', conversationId],
    ({ files, message }: IUseSendLIAttachmentMessageParams) =>
      sendLIAttachmentMessage({ conversationId, files: files.map(({ file }) => file), message }),
    {
      onMutate: async (variables) => {
        options?.onMutate?.(variables);

        const { files, message } = variables;
        await queryClient.cancelQueries(getLiMessagesKey);

        const prevData = queryClient.getQueryData(getLiMessagesKey);

        queryClient.setQueryData<InfiniteData<ILIMessages> | undefined>(getLiMessagesKey, (messages) => {
          if (messages) {
            const pages = [...messages.pages];

            pages[0].messages.unshift({
              subtype: LIMessageType.memberToMember,
              content: {
                attributedBody: { text: message || '' },
                body: '',
                attachments: files.map(({ file, fileSrc }) => ({
                  id: '',
                  name: file.name,
                  mediaType: file.type,
                  byteSize: file.size,
                  reference: {
                    string: fileSrc,
                  },
                })),
              },
              createdAt: Date.now(),
              entityUrn: linkedInEntityUrn,
              imageUrl: '',
            });

            const newMessages: InfiniteData<ILIMessages> = {
              ...messages,
              pages,
            };

            return newMessages;
          }

          return messages;
        });

        return { prevData };
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        toast.dismiss();

        dispatch(
          showToast({
            type: 'error',
            message: 'Uploading failed! Please contact support!',
            autoCloseTime: 3000,
          }),
        );

        queryClient.setQueryData(getLiMessagesKey, context?.prevData);
      },
      onSuccess: (data) => {
        if (data?.originalHttpCode === 422) {
          dispatch(showToast({ type: 'error', message: 'Unable to send message' }));
        }
      },
      onSettled: async (data, error, variables, context) => {
        options?.onSettled?.(data, error, variables, context);
        await queryClient.invalidateQueries(getLiMessagesKey);
      },
    },
  );

  return { sendLIAttachmentMessage: mutate, isSendLiAttachmentLoading: isLoading, ...rest };
};
