import { PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import {
  HeaderButton,
  HeaderContainer,
  HeaderDescription,
  HeaderTabLink,
  HeaderTabLinkCounter,
  HeaderTitle,
  HeaderVideo,
  Link,
  TabsContainer,
} from 'src/components';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from 'src/constants';
import { CampaignActions, ModalTypes } from 'src/enums';
import { useCompanyTheme, useWhiteLabel } from 'src/hooks';
import { useGetCampaignActivity, useGetCampaignInvites, useUpdateCampaignInviteStatus } from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';
import { IGetCampaignActivityRequest, IGetInvitesRequest, TInvitesStatus } from 'src/types';

const defaultInvitesParams = {
  page: DEFAULT_PAGE,
  perPage: DEFAULT_PER_PAGE,
  sortField: 'sentTime',
  sortOrder: 'DESC',
} as IGetInvitesRequest;

const defaultWithdrawnInvitesParams = {
  page: DEFAULT_PAGE,
  perPage: DEFAULT_PER_PAGE,
  sortField: 'name',
  sortOrder: 'ASC',
  filters: [CampaignActions.INVITE_WITHDRAWN],
  skipNoPersonActions: true,
} as IGetCampaignActivityRequest;

interface IInvitationsLayout {
  selected: number[];
  onSuccess: () => void;
  getInvitesParams?: IGetInvitesRequest;
  getWithdrawnInvitesParams?: IGetCampaignActivityRequest;
}

export const InvitationsLayout = ({
  children,
  selected,
  getInvitesParams = defaultInvitesParams,
  getWithdrawnInvitesParams = defaultWithdrawnInvitesParams,
  onSuccess,
}: PropsWithChildren<IInvitationsLayout>) => {
  const { tab } = useParams();
  const dispatch = useDispatch();
  const { whitelabel } = useWhiteLabel();
  const { shortCompanyName } = useCompanyTheme();

  const { updateStatus } = useUpdateCampaignInviteStatus(getInvitesParams);
  const updateInvite = (status: TInvitesStatus) => updateStatus({ status, inviteIds: selected }, { onSuccess });

  const { data: sentData } = useGetCampaignInvites(
    { ...(tab === 'sent' ? getInvitesParams : defaultInvitesParams), status: 'SENT' },
    { refetchOnMount: true },
  );
  const { data: receivedData } = useGetCampaignInvites(
    { ...(tab === 'received' ? getInvitesParams : defaultInvitesParams), status: 'RECEIVED' },
    { refetchOnMount: true },
  );
  const { data: withdrawnData } = useGetCampaignActivity(
    tab === 'withdrawn' ? getWithdrawnInvitesParams : defaultWithdrawnInvitesParams,
    { refetchOnMount: true },
  );

  const selectedCount = selected.length;

  const TABS = [
    { name: 'Sent', count: sentData?.total, route: 'sent' },
    { name: 'Received', count: receivedData?.total, route: 'received' },
    { name: 'Withdrawn', count: withdrawnData?.total, route: 'withdrawn' },
  ];

  const actionResolver = () => {
    switch (tab) {
      case 'sent':
        return [
          {
            title: 'Withdraw selected invitations',
            onClick: () => updateInvite('WITHDRAW'),
            description: 'profile(s) to withdraw',
          },
        ];
      case 'received':
        return [
          {
            title: 'Accept',
            onClick: () => updateInvite('ACCEPT'),
            description: 'invitation(s) to accept',
          },
          {
            title: 'Reject',
            onClick: () => updateInvite('REJECT'),
            description: 'invitation(s) to reject',
          },
        ];
      default:
        return [];
    }
  };

  const onButtonClick = (description: string, callback: () => void) => {
    if (!selectedCount) {
      return dispatch(
        openModal({
          type: ModalTypes.INVITE,
          headerText: 'Warning',
          params: {
            description: 'Please select at least one invitation to make this action.',
            buttonText: 'Close',
          },
        }),
      );
    }

    return dispatch(
      openModal({
        type: ModalTypes.INVITE,
        headerText: 'Success',
        params: {
          description: `The selected ${description} will be added to ${shortCompanyName}’s next batch for processing.`,
          buttonText: 'Proceed',
          onConfirm: callback,
        },
      }),
    );
  };

  return (
    <>
      <HeaderContainer>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <HeaderTitle>Invitations</HeaderTitle>
          <Box display="flex" gap="20px">
            {actionResolver().map(({ title, description, onClick }) => (
              <HeaderButton onClick={() => onButtonClick(description, onClick)} key={title}>
                {title} {!!selectedCount && ` ( ${selectedCount} ) `}
              </HeaderButton>
            ))}
          </Box>
        </Box>
        <HeaderDescription>
          Easily handle all your LinkedIn connection requests in one place.
          {whitelabel.isWhiteLabelUser || (
            <>
              <Link
                leftIcon
                underline
                external
                openNewTab
                to="https://meetalfred.com/help/en/articles/5276922-rejected-or-pending-invitations-by-recipients"
              >
                Learn how it works
              </Link>
              <HeaderVideo src="https://player.vimeo.com/video/622842901" />
            </>
          )}
        </HeaderDescription>
        <Box display="flex" width="100%">
          <TabsContainer>
            {TABS.map(({ name, count = 0, route }) => {
              const to = `/invitations/${route}`;

              return (
                <HeaderTabLink key={to} to={to}>
                  {name} <HeaderTabLinkCounter>{count}</HeaderTabLinkCounter>
                </HeaderTabLink>
              );
            })}
          </TabsContainer>
        </Box>
      </HeaderContainer>
      {children}
    </>
  );
};
