import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { TablePagination } from 'src/components';
import { DEFAULT_PER_PAGE, LEADS_FILTER_OPTIONS } from 'src/constants';
import { CampaignCategory, CampaignType, ModalTypes } from 'src/enums';
import { getCampaignTypes } from 'src/helpers';
import { useAppSelector, useDebounce } from 'src/hooks';
import { useGetCampaign, useGetCampaignLeads, useGetCampaignLeadsStatuses } from 'src/reactQueries';
import { updateSelectedLeads } from 'src/store/campaign.slice';
import { openModal } from 'src/store/modal.slice';
import { ITablePagination, TLeadsSortField, TLeadsSortType, TSortOrder } from 'src/types';
import { DetailsLayout } from '../DetailsLayout';
import { EmailLeadsTable } from './EmailLeads/EmailLeadsTable';
import { LeadsFilters } from './Filters';
import { LinkedinTable } from './LinkedinTable/LinkedinTable';
import { TwitterLeadsTable } from './TwitterLeads/TwitterLeadsTable';

const defaultSearchFilters = {
  company: '',
  location: '',
  name: '',
  title: '',
  email: '',
  twitter: '',
  excludedOnly: false,
};

export type TLeadSearchFilters = typeof defaultSearchFilters;

export const Leads = () => {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const [searchParams] = useSearchParams();
  const { entityUrns } = useAppSelector((state) => state.campaign.selectedLeads, isEqual);
  const CAMPAIGN_ID = Number(campaignId);

  const [searchFields, setSearchFields] = useState(defaultSearchFilters);
  const [leadFilter, setLeadFilter] = useState(searchParams.get('filter') || 'approved');

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [sortOrder, setSortOrder] = useState<TSortOrder>('ASC');
  const [sortField, setSortField] = useState<TLeadsSortField>('name');

  const nameDebounced = useDebounce(searchFields.name);
  const emailDebounced = useDebounce(searchFields.email);
  const titleDebounced = useDebounce(searchFields.title);
  const companyDebounced = useDebounce(searchFields.company);
  const twitterDebounced = useDebounce(searchFields.twitter);
  const locationDebounced = useDebounce(searchFields.location);

  const leadSearchFields = useMemo(
    () => ({
      name: nameDebounced?.trim(),
      title: titleDebounced?.trim(),
      email: emailDebounced?.trim(),
      company: companyDebounced?.trim(),
      location: locationDebounced?.trim(),
      twitterHandle: twitterDebounced?.trim(),
    }),
    [nameDebounced, titleDebounced, emailDebounced, companyDebounced, locationDebounced, twitterDebounced],
  );

  const getCampaignLeadData = useMemo(
    () => ({
      ...leadSearchFields,
      type: leadFilter as TLeadsSortType,
      page,
      perPage,
      sortField,
      sortOrder,
      excludedOnly: searchFields.excludedOnly,
    }),
    [leadSearchFields, leadFilter, page, perPage, sortField, sortOrder, searchFields.excludedOnly],
  );
  const { campaign } = useGetCampaign(CAMPAIGN_ID);
  const { data: optionsCount, isLoading: isLoadingLeadStatuses } = useGetCampaignLeadsStatuses(
    CAMPAIGN_ID,
    leadSearchFields,
    { refetchOnMount: true },
  );
  const { data, isLoading } = useGetCampaignLeads(CAMPAIGN_ID, getCampaignLeadData, {
    refetchOnMount: true,
  });
  const { leads = [], total = 0 } = data ?? {};

  const { category } = getCampaignTypes(campaign);
  const currPageLeads = useMemo(() => leads?.map(({ entityUrn }) => entityUrn), [leads]);

  const isSelectedAll = useMemo(
    () =>
      leads?.length !== 0 && (entityUrns?.length === total || currPageLeads?.every((urm) => entityUrns?.includes(urm))),
    [leads, entityUrns, currPageLeads],
  );

  useEffect(() => {
    if (['inmailReplies', 'emailReplies', 'replies', 'allReplies'].includes(leadFilter)) {
      setSortField('updatedAt');
      setSortOrder('DESC');
    }
  }, [leadFilter]);

  const optionsWithCount = useMemo(() => {
    const filteredOptions = LEADS_FILTER_OPTIONS.filter(
      ({ key }) => key === 'leadsCount' || (optionsCount && optionsCount[key]),
    );

    if (!optionsCount || !filteredOptions?.length) {
      return [];
    }

    return filteredOptions.map(({ key, label, type }) => ({
      label: `${label}(${optionsCount[key]})`,
      value: type,
    }));
  }, [optionsCount, total, LEADS_FILTER_OPTIONS]);

  const onSortFieldChange = useCallback(
    (field: string, order: string) => {
      setSortField(field as TLeadsSortField);
      setSortOrder(order as TSortOrder);
    },
    [setSortField, setSortOrder],
  );

  const sortingProps = {
    sortField,
    sortOrder,
    onSortFieldChange,
  };

  const setSelectLeads = useCallback(
    (newEntityUrns: string[]) => dispatch(updateSelectedLeads({ campaignId: CAMPAIGN_ID, entityUrns: newEntityUrns })),
    [CAMPAIGN_ID, dispatch, updateSelectedLeads],
  );

  const toggleLead = useCallback(
    (entityUrn: string) =>
      entityUrns.includes(entityUrn)
        ? setSelectLeads(entityUrns?.filter((urm) => urm !== entityUrn))
        : setSelectLeads([...entityUrns, entityUrn]),
    [entityUrns, setSelectLeads],
  );

  const toggleAllLeads = useCallback(
    () =>
      isSelectedAll
        ? setSelectLeads(entityUrns?.filter((urm) => !currPageLeads?.includes(urm)))
        : setSelectLeads([...entityUrns, ...currPageLeads.filter((urm) => !entityUrns?.includes(urm))]),
    [isSelectedAll, setSelectLeads, entityUrns, currPageLeads],
  );

  const selectProps = {
    isSelectedAll,
    selectLeads: entityUrns,
    toggleLead,
    toggleAllLeads,
  };

  const onExportClick = () =>
    dispatch(
      openModal({
        type: ModalTypes.EXPORT_LEAD_CSV,
        params: {
          entityUrns,
          type: leadFilter,
          campaignId: CAMPAIGN_ID,
          ...leadSearchFields,
        },
      }),
    );

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  const onFilterChange = (value: string) => {
    setLeadFilter(value);
    setPage(1);
    setPerPage(DEFAULT_PER_PAGE);
    setSelectLeads([]);
  };

  const onSearchFieldsChange = (values: Partial<TLeadSearchFilters>) => {
    setPage(1);
    setSearchFields((prev) => ({ ...prev, ...values }));
  };

  const resolveCampaignLeads = () => {
    if (!campaign) {
      return null;
    }

    switch (true) {
      case category === CampaignCategory.TWITTER:
        return (
          <TwitterLeadsTable
            leads={leads}
            isLoading={isLoading}
            sortingProps={sortingProps}
            getLeadKeys={getCampaignLeadData}
            campaign={campaign}
            selectProps={selectProps}
          />
        );
      case campaign.campaignType === CampaignType.EMAIL_CSV_UPLOAD:
        return (
          <EmailLeadsTable
            leads={leads}
            isLoading={isLoading}
            getLeadKeys={getCampaignLeadData}
            campaign={campaign}
            selectProps={selectProps}
            sortingProps={sortingProps}
          />
        );
      default:
        return (
          <LinkedinTable
            leads={leads}
            isLoading={isLoading}
            sortingProps={sortingProps}
            getLeadKeys={getCampaignLeadData}
            campaign={campaign}
            selectProps={selectProps}
          />
        );
    }
  };

  return (
    <DetailsLayout
      onExportClick={!!(leads?.length || entityUrns?.length) && onExportClick}
      option={leadFilter}
      onOptionChange={onFilterChange}
      options={optionsWithCount}
      isOptionsLoading={isLoadingLeadStatuses}
      getLeadKeys={getCampaignLeadData}
      selectLeads={entityUrns}
      showAddMoreLeads
    >
      <LeadsFilters searchFields={searchFields} onSearchFieldsChange={onSearchFieldsChange} campaign={campaign} />
      <Box padding="15px">
        {resolveCampaignLeads()}
        <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={paginationChangeHandler} />
      </Box>
    </DetailsLayout>
  );
};
