import classNames from 'classnames';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';

import { Button, FormInput } from 'src/components';
import { useWhiteLabel } from 'src/hooks/useWhiteLabel';
import { useUpdateWhiteLabelBranding } from 'src/reactQueries';
import { openModal } from 'src/store';
import { Accordion, AccordionDetails, AccordionSummary, ColorInput, ErrorMessage, UploadInput } from '../_components';
import { AlfPreview } from './AlfPreview';

interface IBrandingAttachment {
  url?: string;
  name?: string;
  file?: File;
  isRequired?: boolean;
}

export interface IBrandingForm {
  companyName: string;
  favicon: IBrandingAttachment;
  logo: IBrandingAttachment;
  logoIcon: IBrandingAttachment;
  menuFontColor: string;
  titleColor: string;
  accentColor: string;
  secondaryColor: string;
  notificationErrorColor: string;
  notificationWarningColor: string;
}

const getNameFromUrl = (url?: string) =>
  url?.replace('https://di1x3nx76kt7l.cloudfront.net/', '').replace(/-\d+\./, '.');

export const Branding = () => {
  const dispatch = useDispatch();

  const { palette } = useTheme();
  const { whitelabel } = useWhiteLabel();
  const { companyName, favicon, logo, logoIcon, withBranding } = whitelabel ?? {};

  const [expanded, setExpanded] = useState(!whitelabel.withBranding);

  const defaultValues: Partial<IBrandingForm> = {
    companyName: companyName,
    logo: {
      url: logo,
      isRequired: !logo,
      name: getNameFromUrl(logo),
    },
    favicon: {
      url: favicon,
      isRequired: !favicon,
      name: getNameFromUrl(favicon),
    },
    logoIcon: {
      url: logoIcon,
      isRequired: !logoIcon,
      name: getNameFromUrl(logoIcon),
    },
    menuFontColor: palette.primary.dark,
    titleColor: palette.primary.wlLight,
    accentColor: palette.secondary.main,
    secondaryColor: palette.primary.main,
    notificationErrorColor: palette.notification.errorBg,
    notificationWarningColor: palette.notification.warningBg,
  };

  const form = useForm<IBrandingForm>({ defaultValues });
  const {
    register,
    watch,
    setValue,
    setError,
    handleSubmit,
    formState: { errors },
  } = form;
  const formError = Object.values(errors).find((value) => value?.message);

  const { updateBranding, isLoading } = useUpdateWhiteLabelBranding();

  const submit = (data: IBrandingForm) => {
    switch (true) {
      case !data.favicon.url:
        return setError('favicon', { message: 'Please enter a Favicon' });
      case !data.logoIcon.url:
        return setError('logoIcon', { message: 'Please enter a Logo Icon' });
      case !data.logo.url:
        return setError('logo', { message: 'Please enter a Logo' });
    }

    dispatch(
      openModal({
        headerText: 'Apply Branding Settings',
        descriptionText:
          'Are you sure you want to continue?<br /><br />This action will update the interface with your customized branding elements.',
        confirmButtonLabel: 'Yes, proceed',
        confirmButtonLoading: isLoading,
        onConfirm: () =>
          updateBranding({ ...data, favicon: data.favicon.file, logo: data.logo.file, logoIcon: data.logoIcon.file }),
      }),
    );
  };

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded((prev) => !prev)}>
      <AccordionSummary expanded={expanded} title="Branding" status={withBranding ? 'applied' : 'pending'}>
        <Button onClick={handleSubmit(submit)} processing={isLoading}>
          Apply Branding Settings
        </Button>
      </AccordionSummary>
      <AccordionDetails>
        <Box maxWidth="388px">
          <FormInput
            label="Company Name"
            name="companyName"
            placeholder="MeetAlfred"
            description="We'll use this name to display company name on the app."
            className={classNames({ error: errors.companyName })}
            register={register}
            maxLength={30}
            parameters={{
              required: 'Please enter a company name',
            }}
          />
        </Box>
        <Box display="flex" flexWrap="wrap" gap="20px" justifyContent="space-between">
          <Box mt="16px" minWidth="800px">
            <UploadInput
              name="favicon"
              label="Favicon"
              description="PNG, JPG or SVG with scale of 16x16px"
              tooltipHint="This icon is displayed on the browser tab"
              accept="image/svg+xml,image/jpeg,image/jpg,image/png"
              form={form}
              validation={{ width: 16, height: 16 }}
            />

            <Box display="grid" gridTemplateColumns="1fr 1fr" gap="24px" mt="16px">
              <UploadInput
                name="logoIcon"
                label="App Logo"
                description="PNG or JPG with scale of 200x200px"
                tooltipHint="This icon is displayed in app"
                form={form}
                validation={{ width: 200, height: 200 }}
              />
              <UploadInput
                name="logo"
                label="Email Logo"
                description="PNG or JPG with scale of 200x200px"
                tooltipHint="This logo is displayed in system emails"
                form={form}
                validation={{ width: 200, height: 200 }}
              />
            </Box>
            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gap="24px" mt="38px">
              <ColorInput
                label="Menu Color"
                color={watch('menuFontColor')}
                onColorChange={(color) => setValue('menuFontColor', color)}
              />
              <ColorInput
                label="Title Color"
                color={watch('titleColor')}
                onColorChange={(color) => setValue('titleColor', color)}
              />
              <ColorInput
                label="Accent Color"
                color={watch('accentColor')}
                onColorChange={(color) => setValue('accentColor', color)}
              />
              <ColorInput
                label="Secondary Color"
                color={watch('secondaryColor')}
                onColorChange={(color) => setValue('secondaryColor', color)}
              />
            </Box>
            <Box display="grid" gridTemplateColumns="1fr 1fr 1fr 1fr" gap="24px" mt="38px">
              <ColorInput
                label="Error Notification Banner Color"
                color={watch('notificationErrorColor')}
                onColorChange={(color) => setValue('notificationErrorColor', color)}
              />
              <ColorInput
                label="Warning Notification Banner Color"
                color={watch('notificationWarningColor')}
                onColorChange={(color) => setValue('notificationWarningColor', color)}
              />
            </Box>
          </Box>
          <AlfPreview watch={watch} />
        </Box>
        {!!formError?.message && <ErrorMessage mt="15px">{formError?.message}</ErrorMessage>}
      </AccordionDetails>
    </Accordion>
  );
};
