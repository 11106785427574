import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import {
  HeaderContainer,
  HeaderDescription,
  HeaderTabLink,
  HeaderTitle,
  HeaderVideo,
  Link,
  TabsContainer,
} from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features } from 'src/enums';
import { useAppSelector, useTeamPlan, useWhiteLabel } from 'src/hooks';
import { useGetRealUser, useGetUserAccount, useGetUserPreferences } from 'src/reactQueries';

const SNinboxLabel = 'Sales Navigator inbox';

export const ConversationLayout = () => {
  const location = useLocation();
  const { whitelabel } = useWhiteLabel();
  const { userMe } = useGetUserAccount();
  const { realImpersonateUser } = useGetRealUser();
  const { isTrialStarted, checkFeature } = useTeamPlan();
  const { userPreferences } = useGetUserPreferences();

  const { isImpersonate, profile } = useAppSelector((state) => state.user);

  const isInmailAllowed = checkFeature(Features.inmailInbox);
  const isSalesNavigatorAllowed = checkFeature(Features.salesNavigatorInbox);

  const showUserInbox =
    checkFeature(Features.inbox) &&
    (realImpersonateUser?.isAdminImpersonate ||
      (userMe?.isGhostUser && userMe.isLinkedInLoginValid) ||
      (isImpersonate && userPreferences?.enableMembersInbox) ||
      !isImpersonate ||
      realImpersonateUser?.isAlfredAdmin);

  const salesNavigatorTooltip = useMemo(() => {
    switch (true) {
      case isTrialStarted:
        return SNinboxLabel;

      case !isSalesNavigatorAllowed:
        return PLAN_TOOLTIPS.professional;

      case !profile?.hasLinkedInSalesNavigator:
        return 'Sales Navigator is required';

      default:
        return SNinboxLabel;
    }
  }, [isSalesNavigatorAllowed, isTrialStarted, profile?.hasLinkedInSalesNavigator]);

  const tabs = [
    { name: 'LinkedIn', path: 'linkedin', tooltip: 'LinkedIn inbox' },
    {
      name: 'InMail',
      path: 'in-mail',
      tooltip: isInmailAllowed ? 'InMails from LinkedIn inbox' : PLAN_TOOLTIPS.professional,
      disabled: !isInmailAllowed,
    },
    {
      name: 'Sales Navigator',
      path: 'sales-navigator',
      tooltip: salesNavigatorTooltip,
      disabled: !isSalesNavigatorAllowed || !profile?.hasLinkedInSalesNavigator,
    },
  ];

  return (
    <HeaderContainer>
      <HeaderTitle>Inbox</HeaderTitle>
      <HeaderDescription>
        Access your LinkedIn conversations in real time to stay connected with your network.
        {whitelabel.isWhiteLabelUser || (
          <>
            <Link
              leftIcon
              underline
              external
              openNewTab
              to="https://meetalfred.com/help/en/articles/4178778-access-your-linkedin-inbox-through-alfred"
            >
              Learn how it works
            </Link>
            <HeaderVideo src="https://player.vimeo.com/video/622864427" />
          </>
        )}
      </HeaderDescription>
      {showUserInbox && (
        <TabsContainer>
          {tabs.map(({ tooltip, path, name, disabled }) => {
            const to = `/inbox/${path}`;

            return (
              <HeaderTabLink
                isActive={to === location.pathname}
                to={disabled ? '#' : path}
                disabled={disabled}
                tooltip={tooltip}
                key={path}
              >
                {name}
              </HeaderTabLink>
            );
          })}
        </TabsContainer>
      )}
    </HeaderContainer>
  );
};
