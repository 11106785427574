import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { InfoLabel, ProgressBar, Typography } from 'src/components';
import { CampaignSequenceStepType } from 'src/enums';
import { separateNumWithComma } from 'src/helpers';
import { useGetCampaign, useGetCampaignSequenceProgress } from 'src/reactQueries';

const Container = styled(Box)`
  background: white;
  box-shadow: 0 1px 1px 0 ${({ theme }) => theme.palette.light.dark};
  border-radius: 5px;
  padding: 20px;
`;

const Grid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 20px;
`;

export const CampaignSequenceProgress = () => {
  const { campaignId } = useParams();
  const CAMPAIGN_ID = Number(campaignId);

  const { campaign } = useGetCampaign(CAMPAIGN_ID);
  const { data } = useGetCampaignSequenceProgress(CAMPAIGN_ID, { refetchOnMount: true });

  const infoLabels = useMemo(() => {
    return [
      {
        label: 'Total Leads:',
        value: data?.totalLeads,
      },
      {
        label: 'Active Leads:',
        value: data?.activeLeads,
      },
      {
        label: 'Ignored Leads:',
        value: data?.ignoredLeads,
      },
      {
        label: 'Passed all steps:',
        value: data?.completedLeads,
        percentage: data?.activeLeads && `${Math.round((data.completedLeads / data.activeLeads) * 100)}%`,
        tooltip: 'The number of leads that have passed all steps in the campaign.',
      },
      {
        label: 'Pending to connect:',
        value: data?.waitingForConnectLeads,
        percentage: data?.activeLeads && `${Math.round((data.waitingForConnectLeads / data.activeLeads) * 100)}%`,
        tooltip: 'The number of leads that are pending to accept a connection request.',
        show: campaign?.touchSequence?.sequence?.some(
          (sequence) => sequence.type === CampaignSequenceStepType.LINKED_IN_CONNECT,
        ),
      },
      {
        label: 'Paused:',
        value: data?.pausedLeads,
        percentage: data?.activeLeads && `${Math.round((data.pausedLeads / data.activeLeads) * 100)}%`,
        tooltip: 'The number of leads that replied and are now paused in the campaign sequence.',
      },
    ];
  }, [data]);

  return (
    <Container>
      <Typography medium color="gray.600" lineHeight="16px" fontSize="15px" mb="12px">
        Progress
      </Typography>

      <Box display="flex" alignItems="center" gap="6px">
        <Typography medium color="gray.600" lineHeight="22px" fontSize="15px" inline>
          {data?.progress || 0}%
        </Typography>
        <ProgressBar progress={data?.progress || 0} />
      </Box>

      <Grid>
        {infoLabels.map(
          ({ label, value, percentage, tooltip, show = true }) =>
            show && (
              <InfoLabel
                key={label}
                label={label}
                value={separateNumWithComma(value)}
                percentage={percentage}
                tooltip={tooltip}
              />
            ),
        )}
      </Grid>
    </Container>
  );
};
