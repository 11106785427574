import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { createWhiteLabelDomain } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IWhiteLabel } from 'src/types';

export const useCreateWhiteLabelDomain = (options?: UseMutationOptions<IWhiteLabel, ICustomAxiosError, string>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const getWhiteLabelQueryKey = ['get-whitelabel'];
  const { mutate, isLoading, ...rest } = useMutation(['create-whitelabel-domain'], createWhiteLabelDomain, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      await queryClient.setQueriesData(getWhiteLabelQueryKey, () => data);

      if (!data.isDomainRecordsValidationFailed) {
        dispatch(
          showToast({
            type: 'success',
            message: 'Custom domain created successfully',
            autoCloseTime: 3000,
          }),
        );
      }
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { createDomain: mutate, isDomainLoading: isLoading, ...rest };
};
