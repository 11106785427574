import { useMutation, UseMutationOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { exportCSVCampaignActivity } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IExportCampaignActivityCSVRequest } from 'src/types';

export const useExportCSVCampaignActivity = (
  campaignId: number,
  options?: UseMutationOptions<void, ICustomAxiosError, IExportCampaignActivityCSVRequest>,
) => {
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation(
    ['campaign-export-CSV-activity'],
    (body: IExportCampaignActivityCSVRequest) => exportCSVCampaignActivity(campaignId, body),
    {
      ...options,
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { exportCampaignActivity: mutate, ...rest };
};
