import { useMutation, UseMutationOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateEmail } from 'src/api';
import { setCookie } from 'src/helpers';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError } from 'src/types';

export const useUpdateEmail = (options?: UseMutationOptions<void, ICustomAxiosError, string>) => {
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation(['update-user-email'], (email: string) => updateEmail({ email }), {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      setCookie('changeUserEmailProcess', true);
      dispatch(
        showToast({
          type: 'info',
          message: 'email updated successfully',
          autoCloseTime: 3000,
        }),
      );
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { changeEmail: mutate, ...rest };
};
