import styled from '@emotion/styled';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Spinner, Tooltip } from 'src/components';

const Button = styled.div`
  display: flex;
  align-items: center;
  padding: 7px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
`;

interface RestartButtonProps {
  processing: boolean;
  onClick: () => void;
}

export const RestartButton = ({ processing, onClick }: RestartButtonProps) => {
  return processing ? (
    <Tooltip title="Search is still running" placement="left">
      <span>
        <Spinner type="dots" mr="5px" />
      </span>
    </Tooltip>
  ) : (
    <Tooltip title="Restart search" placement="left">
      <Button onClick={() => onClick()}>
        <FontAwesomeIcon color="#fff" icon={faSyncAlt} />
      </Button>
    </Tooltip>
  );
};
