import { useLayoutEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useTeamPlan } from './useTeamPlan';

const TRIAL_USER_SEARCH_PARAM = 'trial-user';

export const useIsTrialUserQueryParam = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isTrialStarted, isTrial } = useTeamPlan();

  useLayoutEffect(() => {
    if (searchParams.has(TRIAL_USER_SEARCH_PARAM) || !(isTrial && isTrialStarted)) return;

    setSearchParams({ [TRIAL_USER_SEARCH_PARAM]: 'true' }, { replace: true, state: location.state });
  }, [searchParams, location.pathname, location.search]);
};
