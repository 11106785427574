import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { toggleSequenceTemplateAvailability } from 'src/api';
import { ICustomAxiosError, IGetSequenceTemplatesResponse } from 'src/types';

export const useToggleSequenceTemplateAvailability = (
  sequenceTemplateId: number,
  options?: UseMutationOptions<void, ICustomAxiosError>,
) => {
  const queryClient = useQueryClient();

  const getSequenceTemplatesKey = ['get-sequence-templates'];

  const { mutate, ...rest } = useMutation(
    ['toggle-sequence-template-availability', sequenceTemplateId],
    () => toggleSequenceTemplateAvailability(sequenceTemplateId),
    {
      ...options,
      onMutate: async (variables) => {
        options?.onMutate?.(variables);
        await queryClient.cancelQueries(getSequenceTemplatesKey);

        const prevData = queryClient.getQueryData(getSequenceTemplatesKey);

        queryClient.setQueriesData<IGetSequenceTemplatesResponse | undefined>(getSequenceTemplatesKey, (items) => {
          if (items) {
            return {
              ...items,
              sequenceTemplates: items?.sequenceTemplates?.map((item) => {
                if (item.id === sequenceTemplateId) {
                  item.isAvailableForTeam = !item.isAvailableForTeam;
                }

                return item;
              }),
            };
          }

          return items;
        });

        return { prevData };
      },
    },
  );

  return {
    toggleSequenceTemplateAvailability: mutate,
    ...rest,
  };
};
