import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getCampaignReplies } from 'src/api';
import { IGetCampaignReplies, IGetCampaignRepliesRequest } from 'src/types';

export const useGetCampaignReplies = <TData = IGetCampaignReplies>(
  data: IGetCampaignRepliesRequest,
  options?: UseQueryOptions<IGetCampaignReplies, AxiosError, TData>,
) => {
  return useQuery<IGetCampaignReplies, AxiosError, TData>(
    ['get-campaign-replies', JSON.stringify(data)],
    () => getCampaignReplies(data),
    options,
  );
};
