import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getCampaignDetailsActivity } from 'src/api';
import { IGetCampaignActivity, IGetDetailsActivityRequest } from 'src/types';

export const useGetCampaignDetailsActivity = <TData = IGetCampaignActivity>(
  campaignId: number,
  data: IGetDetailsActivityRequest,
  options?: UseQueryOptions<IGetCampaignActivity, AxiosError, TData>,
) => {
  return useQuery<IGetCampaignActivity, AxiosError, TData>(
    ['get-campaign-details-activity', campaignId, JSON.stringify(data)],
    () => getCampaignDetailsActivity(campaignId, data),
    options,
  );
};
