import { PostAttachmentError, PostStatusMessage } from 'src/enums';
import { TPostTypes } from 'src/types';

export const POST_MAX_CONTENT: Record<TPostTypes, number> = {
  instagram: 2200,
  twitter: 280,
  linkedin: 3000,
  facebook: 63206,
};

export const POSTS_MAX_VIDEO_SIZE: Record<TPostTypes, number> = {
  instagram: 100000000,
  twitter: 512000000,
  linkedin: 10485760,
  facebook: 4294967296,
};

export const POST_MAX_VIDEO_DURATION: Record<TPostTypes, number> = {
  instagram: 60,
  twitter: 140,
  linkedin: 600,
  facebook: 7200,
};

export const AUDIENCE_LABELS: { [key: string]: string } = {
  anyone: 'Anyone',
  connections_only: 'Connections only',
};

export const POST_ATTACHMENTS_ERROR_MESSAGE: Record<PostAttachmentError, string> = {
  [PostAttachmentError.INVALID_ASPECT_RATIO]: 'Invalid aspect ratio, please try another file',

  [PostAttachmentError.TWITTER_IMAGE_SIZE_ERROR]: 'Maximum size limit is 5MB (X (Twitter))',
  [PostAttachmentError.TWITTER_VIDEO_SIZE_ERROR]: 'Maximum size limit is 512MB (X (Twitter))',
  [PostAttachmentError.TWITTER_VIDEO_DURATION_ERROR]: 'Uploaded video is over 2 mins 20 secs (X (Twitter))',
  [PostAttachmentError.INVALID_TWITTER_ASPECT_RATIO]: 'Invalid aspect ratio, please try another file (X (Twitter))',

  [PostAttachmentError.INSTAGRAM_IMAGE_SIZE_ERROR]: 'Maximum size limit is 8MB (Instagram)',
  [PostAttachmentError.INSTAGRAM_VIDEO_SIZE_ERROR]: 'Maximum size limit is 100Mb (Instagram)',
  [PostAttachmentError.INSTAGRAM_VIDEO_WIDTH_ERROR]: 'Maximum video width is 1920 (Instagram)',
  [PostAttachmentError.INSTAGRAM_VIDEO_TYPE_ERROR]: 'Instagram accepts MP4 and MOV video types only',
  [PostAttachmentError.INSTAGRAM_VIDEO_DURATION_ERROR]:
    'Uploaded video duration should be between 3 and 60 seconds (Instagram)',
  [PostAttachmentError.INSTAGRAM_INVALID_VIDEO_CODEC]:
    'Uploaded video codec is not supported. HEVC or H264 are supported (Instagram)',

  [PostAttachmentError.LINKEDIN_IMAGE_SIZE_ERROR]: 'Maximum size limit is 15MB (LinkedIn)',
  [PostAttachmentError.LINKEDIN_VIDEO_SIZE_ERROR]: 'Maximum size limit is 10MB (LinkedIn)',
  [PostAttachmentError.LINKEDIN_VIDEO_DURATION_ERROR]: 'Uploaded video is over 10 mins (LinkedIn)',
  [PostAttachmentError.LINKEDIN_DOCUMENT_SIZE_ERROR]: 'Maximum size limit is 100MB (LinkedIn)',
  [PostAttachmentError.LINKEDIN_VIDEO_RESOLUTION_RANGE_ERROR]:
    'Resolution range for video should be 256x144 - 4096x2304 (LinkedIn)',
  [PostAttachmentError.LINKEDIN_IMAGE_INVALID_FORMAT]:
    'This image format is not supported. Supported formats are: png, jpeg, gif. (Linkedin)',

  [PostAttachmentError.FACEBOOK_IMAGE_SIZE_ERROR]: 'Maximum size limit is 30MB (Facebook)',
  [PostAttachmentError.FACEBOOK_VIDEO_SIZE_ERROR]: 'Maximum size limit is 4GB (Facebook)',
  [PostAttachmentError.FACEBOOK_VIDEO_DURATION_ERROR]: 'Uploaded video is over 120 mins (Facebook)',
};

export const POST_STATUS_ERROR_MESSAGE: Record<PostStatusMessage, string> = {
  [PostStatusMessage.INSTAGRAM_UPLOAD_ERROR]: 'Instagram server error. Try again.',
  [PostStatusMessage.INSTAGRAM_SPAM]: 'Instagram marked this post as spam',
  [PostStatusMessage.INSTAGRAM_DAILY_LIMIT]: 'You reached instagram daily post limit. Try again tomorrow',
  [PostStatusMessage.INSTAGRAM_RESTRICTED]:
    'Instagram account is inactive or restricted. Reconnect instagram(facebook) integration',
  [PostStatusMessage.INVALID_VIDEO_FORMAT]:
    'Instagram does not support this video format or video codec. Try another video.',
  [PostStatusMessage.INSTAGRAM_INVALID_PHOTO_FORMAT]: 'Instagram does not support this image format',
  [PostStatusMessage.INSTAGRAM_INVALID_ASPECT_RATIO]:
    "The image's aspect ratio does not fall within Instagram acceptable range. Photo must fall into a 4:5 to 1.91:1 range",
  [PostStatusMessage.INSTAGRAM_INVALID_CAPTION]: 'Caption is too long for Instagram post',
  [PostStatusMessage.PAYLOAD_TOO_LONG]: 'This post is skipped, the attachment file is too large.',
};
