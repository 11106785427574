import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { faCopy, faEye } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';

import { ActionButton, TableCell, TableRow, Typography } from 'src/components';
import { TEMPLATE_AVAILABILITY_OPTIONS } from 'src/constants';
import { ModalTypes } from 'src/enums';
import { openModal } from 'src/store/modal.slice';
import { IEditTemplateState, ITemplate } from 'src/types';

export const TemplatesLibraryRow = (template: ITemplate & { categoryLabel: string }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categoryLabel, content, isAvailableForTeam, name, categoryId, id } = template;
  const { message = '', subject = '', attachments = [] } = content.sequence[0];

  const onShareButtonClick = () => {
    const state: IEditTemplateState = {
      id,
      name: `${name} (clone)`,
      category: {
        label: categoryLabel,
        value: String(categoryId),
      },
      availability: isAvailableForTeam ? TEMPLATE_AVAILABILITY_OPTIONS[1] : TEMPLATE_AVAILABILITY_OPTIONS[0],
      message,
      subject: subject,
      isSubjectShown: !!subject,
      attachments: attachments,
    };

    navigate(`/templates/new`, {
      state,
    });
  };

  const onEyeButtonClick = () => {
    dispatch(
      openModal({
        type: ModalTypes.VIEW_TEMPLATE,
        headerText: template?.name.slice(0, 40),
        params: { template, categoryLabel, canEdit: false },
      }),
    );
  };

  const actions = [
    {
      title: 'View Template',
      onClick: onEyeButtonClick,
      icon: faEye,
    },
    {
      title: 'Clone Template',
      onClick: onShareButtonClick,
      icon: faCopy,
    },
  ];

  return (
    <TableRow>
      <TableCell tooltip={name}>
        <Typography maxWidth="250px" inline crop>
          {name}
        </Typography>
      </TableCell>
      <TableCell>
        <div>
          {subject && (
            <>
              <Typography color="primary.wlLight" maxWidth="250px" inline crop>
                {subject}
              </Typography>
              {' - '}
            </>
          )}
          <Typography maxWidth="500px" inline crop>
            {message}
          </Typography>
        </div>
      </TableCell>
      <TableCell justifyContent="flex-end" sticky>
        <Box display="flex" gap="8px">
          {actions.map(({ icon, onClick, title }) => (
            <ActionButton data-testid={title} key={title} tooltip={title} onClick={onClick} icon={icon} />
          ))}
        </Box>
      </TableCell>
    </TableRow>
  );
};
