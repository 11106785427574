import { UseFormRegister } from 'react-hook-form';

import { Checkbox, Tooltip } from 'src/components';
import { ITwitterDetails } from 'src/types';
import { CheckboxContainer } from '../../_components';

interface ITwitterCheckboxProps {
  audience: string;
  // eslint-disable-next-line
  register: UseFormRegister<any>;
  disabled?: boolean;
  twitterData?: ITwitterDetails;
}

export const TwitterCheckbox = ({ audience, twitterData, register, disabled = false }: ITwitterCheckboxProps) => {
  if (twitterData) {
    return (
      <Tooltip
        title={audience === 'anyoneAndTwitter' && 'LinkedIn will publish your content on X (Twitter).'}
        placement="top"
        arrow
      >
        <CheckboxContainer>
          <Checkbox
            name="postTypes"
            register={register}
            type="checkbox"
            value="twitter"
            disabled={disabled || audience === 'anyoneAndTwitter'}
          />
          <label>X (Twitter)</label>
        </CheckboxContainer>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={<>Please authenticate X (Twitter) in the Integrations page.</>} placement="top" arrow>
      <CheckboxContainer>
        <Checkbox name="postTypes" register={register} type="checkbox" value="twitter" disabled />
        <label>X (Twitter)</label>
      </CheckboxContainer>
    </Tooltip>
  );
};
