import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { GroupedPersonAvatar, HtmlParser, PersonAvatar, Typography } from 'src/components';
import { LIConversationFilterType, LIConversationType } from 'src/enums';
import { ILIConversation } from 'src/types';

const ConversationWrapper = styled(Box)<{ selected?: boolean }>`
  cursor: pointer;
  border-radius: 5px;
  ${({ theme, selected }) => selected && `background-color: ${theme.palette.light.main} !important`};

  transition: 0.2s;

  &:hover {
    background-color: #f7f9ff;
  }
`;

const UnreadBlock = styled(Box)`
  width: 18px;
  height: 18px;
  background-color: red;
  border-radius: 50px;
  color: #ffffff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
`;

interface IConversationProps {
  conversation: ILIConversation;
  selectedConversation: ILIConversation | null;
  filterType: LIConversationFilterType;
  handleConversation: (conversation?: ILIConversation) => void;
}

export const Conversation = ({
  conversation,
  selectedConversation,
  handleConversation,
  filterType,
}: IConversationProps) => {
  const isInmail = filterType === LIConversationFilterType.INMAIL;
  const isSponsoredMessage = conversation.type === LIConversationType.SPONSORED_MESSAGE;

  if (!isInmail && conversation.type === LIConversationType.INMAIL) {
    return null;
  }

  const {
    imageUrl,
    name,
    firstName,
    content,
    unreadCount,
    isConnected,
    conversationId,
    groupName,
    isGroup,
    participants,
    isPostResent,
    from,
    entityUrn,
  } = conversation;

  const resolveConversationContentPreview = () => {
    if (isInmail || isSponsoredMessage) {
      return <HtmlParser crop parseText={content} />;
    }

    if (isPostResent) {
      return `${entityUrn === from ? firstName : 'You'} sent a post`;
    }

    return content;
  };

  return (
    <ConversationWrapper
      display="flex"
      alignItems="center"
      p="10px"
      selected={conversationId === selectedConversation?.conversationId}
      onClick={() => handleConversation(conversation)}
    >
      {isGroup ? (
        <GroupedPersonAvatar>
          {participants?.map((participant) => (
            <PersonAvatar key={participant.entityUrn} src={participant.imageUrl} width="40px" height="40px" />
          ))}
        </GroupedPersonAvatar>
      ) : (
        <PersonAvatar src={imageUrl} width="40px" height="40px" />
      )}

      <Box py="4px" ml="10px" mr="auto" flexGrow="1" width="calc(100% - 70px)">
        <Typography color="rgba(0,0,0,.9)" crop>
          {(isGroup && groupName) || name}&nbsp;
          {!isConnected && isGroup && (
            <Typography component="span" color="gray.light" inline>
              (Haven't connected)
            </Typography>
          )}
        </Typography>
        <Typography component="div" color="gray.light" crop>
          {resolveConversationContentPreview()}
        </Typography>
      </Box>

      {!!unreadCount && <UnreadBlock>{unreadCount}</UnreadBlock>}
    </ConversationWrapper>
  );
};
