import styled from '@emotion/styled';
import MuiTableHead, { TableHeadProps } from '@mui/material/TableHead';

const StyledMuiTableHead = styled(MuiTableHead)`
  &,
  th.sticky > div {
    background-color: ${({ theme }) => theme.palette.gray['50']}50;
  }
`;

export const TableHead = ({ children, ...restProps }: TableHeadProps) => {
  return (
    <StyledMuiTableHead data-testid="table-head" {...restProps}>
      {children}
    </StyledMuiTableHead>
  );
};
