import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateCampaignInviteStatus } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IGetInvitesRequest, IGetInvitesResponse, IInvite, IUpdateInviteStatus } from 'src/types';

export const useUpdateCampaignInviteStatus = (
  getInvitesKeys: IGetInvitesRequest,
  options?: UseMutationOptions<IInvite[], ICustomAxiosError, IUpdateInviteStatus>,
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const getInvitesQueryKey = ['get-campaign-invites', JSON.stringify(getInvitesKeys)];

  const { mutate, ...rest } = useMutation(
    'update-campaign-running-state',
    (data: IUpdateInviteStatus) => updateCampaignInviteStatus(data),
    {
      ...options,
      onMutate: async (variables) => {
        options?.onMutate?.(variables);
        await queryClient.cancelQueries(getInvitesQueryKey);

        const prevData = queryClient.getQueryData(getInvitesQueryKey);

        queryClient.setQueryData<IGetInvitesResponse | undefined>(getInvitesQueryKey, (data) => {
          if (data) {
            return {
              ...data,
              invites: data?.invites?.filter(({ id }) => !variables?.inviteIds.includes(id)),
              total: data?.total - variables?.inviteIds.length,
            };
          }

          return data;
        });

        return { prevData };
      },
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        await queryClient.refetchQueries(getInvitesQueryKey, { exact: false });
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueryData(getInvitesQueryKey, context?.prevData);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return {
    updateStatus: mutate,
    ...rest,
  };
};
