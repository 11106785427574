import { CampaignCategory } from 'src/enums';
import { IColumnConfig, IFilterLeads } from 'src/types';

export const MAX_PERSONAL_RUNNING_CAMPAIGNS = 3;
export const MAX_INDIVIDUAL_RUNNING_CAMPAIGNS = 3;
export const MAX_TRIAL_RUNNING_CAMPAIGNS = 2;
export const MAX_STARTER_RUNNING_CAMPAIGNS = 1;

export const LEADS_FILTER_OPTIONS: IFilterLeads[] = [
  { key: 'leadsCount', label: 'All', type: 'approved' },
  { key: 'viewedLeadsCount', label: 'Viewed', type: 'viewed' },
  { key: 'requestedLeadsCount', label: 'Invites Sent', type: 'requested' },
  { key: 'connectedLeadsCount', label: 'Connected', type: 'connected' },
  { key: 'invitesPendingCount', label: 'Invites Pending', type: 'invitesPending' },
  { key: 'followedUpLeadsCount', label: 'Followed up', type: 'followedUp' },
  { key: 'messagedLeadsCount', label: 'Messages Sent', type: 'messaged' },
  { key: 'inmailedLeadsCount', label: 'InMails Sent', type: 'inmailed' },
  { key: 'inmailSkippedCount', label: 'InMails Skipped', type: 'inmailSkipped' },
  { key: 'allRepliesCount', label: 'All Replies', type: 'allReplies' },
  { key: 'repliesCount', label: 'Replies', type: 'replies' },
  { key: 'followedTwitterCount', label: 'X (Twitter) Followed', type: 'followedTwitter' },
  { key: 'twitterMessagesSentCount', label: 'X (Twitter) DM Sent', type: 'twitterMessageSent' },
  { key: 'alreadyConnectedCount', label: 'Already Connected', type: 'alreadyConnected' },
  { key: 'alreadyInvitedCount', label: 'Already Invited', type: 'alreadyInvited' },
  { key: 'inmailRepliesCount', label: 'InMail Replies', type: 'inmailReplies' },
  { key: 'eventMessagesCount', label: 'Event Messages', type: 'eventMessages' },
  { key: 'emailsBouncedCount', label: 'Emails Bounced', type: 'emailsBounced' },
  { key: 'userInteractionsCount', label: 'User Interaction', type: 'userInteraction' },
  { key: 'withdrawInviteLeadsCount', label: 'Invites Withdrawn', type: 'invitesWithdraw' },
  { key: 'emailedCount', label: 'Emails Sent', type: 'emailed' },
  { key: 'emailRepliesCount', label: 'Email Replies', type: 'emailReplies' },
  { key: 'emailOpenedCount', label: 'Email Open', type: 'emailOpened' },
  { key: 'groupMessageSent', label: 'Group Messages', type: 'groupMessageSent' },
  { key: 'noEmailFound', label: 'No Email Found', type: 'noEmailFound' },
  { key: 'unableToSendMessage', label: 'Messages skipped', type: 'unableToSendMessage' },
  { key: 'twitterUnableToMessage', label: 'X (Twitter) Unable Messages', type: 'twitterUnableToMessage' },
  { key: 'emailUnsubscribed', label: 'Unsubscribed from email', type: 'emailUnsubscribed' },
  { key: 'leadPostponedCount', label: 'Unable to resend invitation', type: 'leadPostponed' },
  { key: 'messagePostponedCount', label: 'Message postponed', type: 'messagePostponed' },
  { key: 'unableToSendInmail', label: 'InMail Unable Messages', type: 'unableToSendInmail' },
];

export const LINKEDIN_COLUMNS: IColumnConfig[] = [
  {
    label: 'Views',
    color: '#8e90ad',
    getValue: ({ views }) => views,
  },
  {
    label: 'Follows',
    color: '#8e90ad',
    getValue: ({ leadsFollowed }) => leadsFollowed,
  },
  {
    label: 'Endorsed',
    color: '#8e90ad',
    getValue: ({ skillEndorsed }) => skillEndorsed,
  },
  {
    label: 'Likes',
    color: '#8e90ad',
    getValue: ({ likedPost }) => likedPost,
  },
  {
    label: 'Invites',
    color: 'primary.light',
    getLink: ({ id }) => `/campaign/details/leads/${id}?filter=requested`,
    getValue: ({ requestedConnect }) => requestedConnect,
  },
  {
    label: 'Connected',
    color: 'primary.light',
    getLink: ({ id }) => `/campaign/details/leads/${id}?filter=connected`,
    getValue: ({ connected }) => connected,
  },
  {
    label: 'Conversion',
    color: '#8e90ad',
    isPercentage: true,
    columnTooltip: 'This shows how many people accepted your LinkedIn connection requests out of the ones you invited.',
    getValue: ({ requestedConnect, connected }) => {
      if (!requestedConnect || !connected) {
        return 0;
      }

      return `${((connected / requestedConnect) * 100).toFixed(1)}%`;
    },
  },
  {
    label: 'Withdrawn',
    color: '#8e90ad',
    getValue: ({ withdrawals }) => withdrawals,
  },
  {
    label: 'Messages',
    color: '#8e90ad',
    getValue: ({ messages }) => messages,
  },
  {
    label: 'Replies',
    color: '#f62713',
    getLink: ({ id }) => `/campaign/details/leads/${id}?filter=replies`,
    getValue: ({ responded }) => responded,
  },
  {
    label: 'Reply rate',
    color: '#8e90ad',
    columnTooltip:
      'This is the percentage of replies received on LinkedIn, based on the total outreach efforts (messages and connection messages).',
    getValue: ({ responded = 0, messages = 0, connectMessages = 0 }) => {
      const totalSent = messages + connectMessages;
      if (!totalSent) {
        return 0;
      }

      return `${((responded / totalSent) * 100).toFixed(1)}%`;
    },
  },
];

export const INMAIL_COLUMNS: IColumnConfig[] = [
  {
    label: 'Views',
    color: '#8e90ad',
    getValue: ({ views }) => views,
  },
  {
    label: 'Follows',
    color: '#8e90ad',
    getValue: ({ leadsFollowed }) => leadsFollowed,
  },
  {
    label: 'Endorsed',
    color: '#8e90ad',
    getValue: ({ skillEndorsed }) => skillEndorsed,
  },
  {
    label: 'Likes',
    color: '#8e90ad',
    getValue: ({ likedPost }) => likedPost,
  },
  {
    label: 'InMails',
    color: '#8e90ad',
    getValue: ({ messages }) => messages,
  },
  {
    label: 'Replies',
    color: '#f62713',
    getLink: ({ id }) => `/campaign/details/leads/${id}?filter=inmailReplies`,
    getValue: ({ responded }) => responded,
  },
  {
    label: 'Reply rate',
    color: '#8e90ad',
    columnTooltip:
      'This is the percentage of replies received on LinkedIn, based on the total outreach efforts (InMails).',
    getValue: ({ responded = 0, messages = 0 }) => {
      if (!messages) {
        return 0;
      }

      return `${((responded / messages) * 100).toFixed(1)}%`;
    },
  },
];

export const LINKEDIN_GROUP_COLUMNS: IColumnConfig[] = [
  {
    label: 'Views',
    color: '#8e90ad',
    getValue: ({ views }) => views,
  },
  {
    label: 'Follows',
    color: '#8e90ad',
    getValue: ({ leadsFollowed }) => leadsFollowed,
  },
  {
    label: 'Endorsed',
    color: '#8e90ad',
    getValue: ({ skillEndorsed }) => skillEndorsed,
  },
  {
    label: 'Likes',
    color: '#8e90ad',
    getValue: ({ likedPost }) => likedPost,
  },
  {
    label: 'G. Messages',
    color: '#8e90ad',
    getValue: ({ groupMessages }) => groupMessages,
  },
  {
    label: 'Replies',
    color: '#f62713',
    getLink: ({ id }) => `/campaign/details/leads/${id}?filter=allReplies`,
    getValue: ({ responded }) => responded,
  },
  {
    label: 'Reply rate',
    color: '#8e90ad',
    columnTooltip:
      'This is the percentage of replies received on LinkedIn, based on the total outreach efforts (group messages).',
    getValue: ({ responded = 0, messages = 0, groupMessages = 0 }) => {
      const totalSent = messages + groupMessages;
      if (!totalSent) {
        return 0;
      }

      return `${((responded / totalSent) * 100).toFixed(1)}%`;
    },
  },
];

export const LINKEDIN_EVENT_COLUMNS: IColumnConfig[] = [
  {
    label: 'Views',
    color: '#8e90ad',
    getValue: ({ views }) => views,
  },
  {
    label: 'Follows',
    color: '#8e90ad',
    getValue: ({ leadsFollowed }) => leadsFollowed,
  },
  {
    label: 'Endorsed',
    color: '#8e90ad',
    getValue: ({ skillEndorsed }) => skillEndorsed,
  },
  {
    label: 'Likes',
    color: '#8e90ad',
    getValue: ({ likedPost }) => likedPost,
  },
  {
    label: 'Event Messages',
    color: '#8e90ad',
    getValue: ({ messages }) => messages,
  },
  {
    label: 'Replies',
    color: '#f62713',
    getLink: ({ id }) => `/campaign/details/leads/${id}?filter=allReplies`,
    getValue: ({ responded }) => responded,
  },
  {
    label: 'Reply rate',
    color: '#8e90ad',
    columnTooltip:
      'This is the percentage of replies received on LinkedIn, based on the total outreach efforts (event messages).',
    getValue: ({ responded = 0, messages = 0 }) => {
      if (!messages) {
        return 0;
      }

      return `${((responded / messages) * 100).toFixed(1)}%`;
    },
  },
];

export const LINKEDIN_POST_COLUMNS: IColumnConfig[] = [
  {
    label: 'Views',
    color: '#8e90ad',
    getValue: ({ views }) => views,
  },
  {
    label: 'Follows',
    color: '#8e90ad',
    getValue: ({ leadsFollowed }) => leadsFollowed,
  },
  {
    label: 'Endorsed',
    color: '#8e90ad',
    getValue: ({ skillEndorsed }) => skillEndorsed,
  },
  {
    label: 'Likes',
    color: '#8e90ad',
    getValue: ({ likedPost }) => likedPost,
  },
  {
    label: 'Invites',
    color: 'primary.light',
    getLink: ({ id }) => `/campaign/stats/info/invites/${id}`,
    getValue: ({ requestedConnect }) => requestedConnect,
  },
  {
    label: 'Connected',
    color: 'primary.light',
    getLink: ({ id }) => `/campaign/stats/info/connected/${id}`,
    getValue: ({ connected }) => connected,
  },
  {
    label: 'Conversion',
    color: '#8e90ad',
    isPercentage: true,
    columnTooltip: 'This shows how many people accepted your LinkedIn connection requests out of the ones you invited.',
    getValue: ({ requestedConnect, connected }) => {
      if (!requestedConnect || !connected) {
        return 0;
      }

      return `${((connected / requestedConnect) * 100).toFixed(1)}%`;
    },
  },
  {
    label: 'Withdrawn',
    color: '#8e90ad',
    getValue: ({ withdrawals }) => withdrawals,
  },
  {
    label: 'Messages',
    color: '#8e90ad',
    getValue: ({ messages }) => messages,
  },
  {
    label: 'Replies',
    color: '#f62713',
    getLink: ({ id }) => `/campaign/details/leads/${id}?filter=allReplies`,
    getValue: ({ responded }) => responded,
  },
  {
    label: 'Reply rate',
    color: '#8e90ad',
    columnTooltip:
      'This is the percentage of replies received on LinkedIn, based on the total outreach efforts (messages and connection messages).',
    getValue: ({ responded = 0, messages = 0, connectMessages = 0 }) => {
      const totalSent = messages + connectMessages;
      if (!totalSent) {
        return 0;
      }

      return `${((responded / totalSent) * 100).toFixed(1)}%`;
    },
  },
];

export const EMAIL_COLUMNS: IColumnConfig[] = [
  {
    label: 'Sent',
    color: '#8e90ad',
    getValue: ({ firstEmailsSent }) => firstEmailsSent,
  },
  {
    label: 'Opened',
    color: '#8e90ad',
    columnTooltip: 'First Emails Opened',
    getValue: ({ firstEmailsOpened }) => firstEmailsOpened,
  },
  {
    label: 'Conversion',
    color: '#8e90ad',
    columnTooltip:
      'This displays the percentage of people who opened your first email among those you initially sent it to.',
    getValue: ({ firstEmailsOpened, firstEmailsSent }) => {
      if (!firstEmailsSent) {
        return 0;
      }

      return `${((firstEmailsOpened / firstEmailsSent) * 100).toFixed(1)}%`;
    },
  },
  {
    label: 'Bounced',
    color: '#8e90ad',
    getValue: ({ bounced }) => bounced,
  },
  {
    label: 'Bounced rate',
    color: '#8e90ad',
    columnTooltip:
      'This indicates the percentage of emails that could not be delivered (bounced) out of the total number of emails sent.',
    getValue: ({ bounced, emails }) => {
      if (!emails) {
        return 0;
      }

      return `${((bounced / emails) * 100).toFixed(1)}%`;
    },
  },
  {
    label: 'Follow-ups',
    color: '#8e90ad',
    getValue: ({ emails = 0, firstEmailsSent = 0 }) => emails - firstEmailsSent,
  },
  {
    label: 'Opened',
    color: '#8e90ad',
    columnTooltip: 'Followup Emails Opened',
    getValue: ({ emailsOpened = 0, firstEmailsOpened = 0 }) => emailsOpened - firstEmailsOpened,
  },
  {
    label: 'Replies',
    color: '#f62713',
    getLink: ({ id }) => `/campaign/details/leads/${id}?filter=emailReplies`,
    getValue: ({ emailResponded }) => emailResponded,
  },
  {
    label: 'Reply rate',
    color: '#8e90ad',
    columnTooltip:
      'This shows the percentage of email responses you received compared to the total number of emails sent.',
    getValue: ({ emailResponded, emails }) => {
      if (!emails) {
        return 0;
      }

      return `${((emailResponded / emails) * 100).toFixed(1)}%`;
    },
  },
];

export const TWITTER_COLUMNS: IColumnConfig[] = [
  {
    label: 'Follows',
    color: '#8e90ad',
    getValue: ({ twitterLeadsFollowed }) => twitterLeadsFollowed,
  },
  {
    label: 'DM messages',
    color: '#8e90ad',
    getValue: ({ dmsSent }) => dmsSent,
  },
  {
    label: 'Replies',
    color: '#f62713',
    getLink: ({ id }) => `/campaign/details/leads/${id}?filter=allReplies`,
    getValue: ({ twitterResponded }) => twitterResponded,
  },
  {
    label: 'Reply rate',
    color: '#8e90ad',
    columnTooltip:
      'This is the percentage of responses to your X (Twitter) direct messages compared to all X (Twitter) messages sent.',
    getValue: ({ twitterResponded, dmsSent }) => {
      if (!dmsSent) {
        return 0;
      }

      return `${((twitterResponded / dmsSent) * 100).toFixed(1)}%`;
    },
  },
];

export const CAMPAIGN_CATEGORIES = [
  {
    category: CampaignCategory.LINKEDIN,
    name: 'LinkedIn Campaign',
  },
  {
    category: CampaignCategory.EMAIL,
    name: 'Email Campaign',
  },
  {
    category: CampaignCategory.TWITTER,
    name: 'X (Twitter) Campaign',
  },
  {
    category: CampaignCategory.MULTICHANNEL,
    name: 'Multi-channel Campaign',
  },
  {
    category: CampaignCategory.INMAIL,
    name: 'InMail Campaign',
  },
  {
    category: CampaignCategory.GROUP,
    name: 'LinkedIn Group Campaign',
  },
  {
    category: CampaignCategory.EVENT,
    name: 'LinkedIn Event Campaign',
  },
  {
    category: CampaignCategory.POST,
    name: 'LinkedIn Post Campaign',
  },
];
