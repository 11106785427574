import { Controller, UseFormReturn } from 'react-hook-form';
import { SingleValue } from 'react-select';
import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';

import { ReactComponent as InfoIcon } from 'src/assets/icons/info.svg';
import { Divider, Input, SelectBasic, StripeCard, Typography } from 'src/components';
import { CurrencyEnum } from 'src/enums';
import { IColorSelectOption, ICreateSubscription } from 'src/types';
import { BlockTitle } from '../../_components';

interface PaymentDetailsFormProps extends UseFormReturn<ICreateSubscription> {
  currency: CurrencyEnum;
  disabled: boolean;
}

const selectControlClassName = css`
  min-height: 42px !important;
`;

const labelStyle = css`
  font-weight: 500 !important;
`;

const currencies = [
  { label: 'USD', value: CurrencyEnum.USD },
  { label: 'EUR', value: CurrencyEnum.EUR },
  { label: 'GBP', value: CurrencyEnum.GBP },
];

export const PaymentDetailsForm = ({
  currency,
  disabled,
  control,
  register,
  formState: { errors },
  clearErrors,
  trigger,
}: PaymentDetailsFormProps) => {
  const { palette } = useTheme();

  const onStripeCardChange = () => {
    if (errors.paymentMethod) {
      clearErrors('paymentMethod');
      trigger('paymentMethod');
    }
  };

  return (
    <Box p="20px" borderRadius="4px" border={`1px solid ${palette.gray[100]}`} component="fieldset" disabled={disabled}>
      <BlockTitle mb="24px">Payment</BlockTitle>

      <Typography fontSize="15px" color="gray.600" mb="8px">
        <InfoIcon width="16px" height="16px" style={{ marginRight: '8px', verticalAlign: 'sub' }} />
        Choose a currency for payment, you cannot change it later
      </Typography>

      <Controller
        control={control}
        name="currency"
        defaultValue={currency}
        render={({ field }) => (
          <>
            <Typography fontSize="14px" medium mb="5px" lineHeight="1.3rem">
              Currency
            </Typography>
            <SelectBasic
              {...field}
              classNames={{ control: () => selectControlClassName }}
              options={currencies}
              onChange={(option) => field.onChange((option as SingleValue<IColorSelectOption>)?.value as CurrencyEnum)}
              value={currencies.find((option) => option.value === field.value)}
            />
          </>
        )}
      />

      <Divider my="24px" />

      <Box display="flex" flexDirection="column" gap="24px">
        <Input
          label="Phone"
          placeholder="Enter your phone"
          name="phone"
          register={register}
          parameters={{ required: true }}
          labelStyle={labelStyle}
          showRequiredIcon
        />

        <Input
          label="Company Name"
          placeholder="Enter company name"
          name="company"
          register={register}
          parameters={{ required: true }}
          labelStyle={labelStyle}
          showRequiredIcon
        />

        <Input label="Address" placeholder="Enter address" name="address" register={register} labelStyle={labelStyle} />
      </Box>

      <Divider my="24px" />

      <Typography fontSize="14px" medium mb="5px" lineHeight="1.3rem">
        Card Number
        <Typography color="error.light" ml="4px" inline>
          *
        </Typography>
      </Typography>
      <StripeCard onChange={onStripeCardChange} />
      {errors.paymentMethod?.message && (
        <Typography color="error.light" mt="10px">
          {errors.paymentMethod?.message}
        </Typography>
      )}

      <Typography fontSize="15px" color="gray.600" mt="24px">
        *Your credit card will be charged immediately.
      </Typography>
      <Typography fontSize="15px" color="gray.600">
        *All features included in your plan will be enabled
      </Typography>
    </Box>
  );
};
