import { ReactNode, useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { Spinner } from 'src/components';
import { CampaignCategory } from 'src/enums';
import { useUpdateCampaignDataBySocket } from 'src/hooks';
import { useGetCampaignsCount, useGetUserAccount } from 'src/reactQueries';
import { TIntervalFilter } from 'src/types';
import { CampaignsTable } from '../_components';
import { CampaignsDraftList } from './CampaignsDraft/CampaignsDraftList';
import { CampaignsStatsLayout } from './CampaignsStatsLayout';
import { EmptyTab } from './EmptyTab';

export const CampaignsStats = () => {
  const { category = CampaignCategory.LINKEDIN, tab } = useParams<{ category: CampaignCategory; tab: string }>();
  const currentUser = useGetUserAccount({ refetchOnMount: true });
  const { data } = useGetCampaignsCount(category);
  const [interval, setInterval] = useState<TIntervalFilter>('all');
  const { active = 1, paused = 1, archived = 1, draft = 1 } = data ?? {};

  const onChangeInterval = (newInterval: TIntervalFilter) => setInterval(newInterval);

  useUpdateCampaignDataBySocket({ interval });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!Object.values(CampaignCategory).includes(category)) {
    return <Navigate to="/campaign/stats" />;
  }

  const withEmptyTab = (count: number, component: ReactNode) => {
    if (count !== 0) {
      return component;
    }

    if (tab === 'active') {
      return <EmptyTab />;
    }

    return (
      <Box textAlign="center" mt="60px">
        You don't have any {tab} yet.
      </Box>
    );
  };

  const generateContent = () => {
    switch (true) {
      case currentUser.isLoading:
        return <Spinner type="dots" mt="10px" />;

      case tab === 'drafts':
        return withEmptyTab(draft, <CampaignsDraftList category={category} />);
      case tab === 'archived':
        return withEmptyTab(
          archived,
          <CampaignsTable category={category} campaignStatus="archived" campaignTab="archived" />,
        );
      case tab === 'paused':
        return withEmptyTab(
          paused,
          <CampaignsTable
            interval={interval}
            category={category}
            campaignStatus="active"
            runState="paused"
            campaignTab="paused"
          />,
        );
      case tab === 'active':
        return withEmptyTab(
          active,
          <CampaignsTable
            interval={interval}
            category={category}
            campaignStatus="active"
            runState="running"
            campaignTab="active"
          />,
        );
      default:
        return <Navigate to="/campaign/stats" />;
    }
  };

  return (
    <Box pb="80px">
      <CampaignsStatsLayout
        interval={tab === 'active' && active !== 0 && interval}
        onChangeInterval={onChangeInterval}
      />
      <Box px="15px">{generateContent()}</Box>
    </Box>
  );
};
