import { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { faFileArchive, faPause, faPen, faPlay, faUserPlus, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { ReactComponent as DeleteBucket } from 'src/assets/icons/delete-bucket.svg';
import { ReactComponent as Unarchive } from 'src/assets/icons/unarchive.svg';
import { Button, HeaderContainer, HeaderTabLink, SelectBasicGray, TabsContainer } from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { CampaignType, Features } from 'src/enums';
import { getAddMoreLeadsState, getCampaignTypes } from 'src/helpers';
import { useAppSelector, useLocationState, useTeamPlan } from 'src/hooks';
import {
  useArchiveCampaign,
  useDeleteAllIgnoredCampaignLeads,
  useDeleteCampaign,
  useDeleteIgnoredCampaignLeads,
  useGetCampaign,
  useReturnIgnoredCampaignLead,
  useSkipCampaignLead,
  useUpdateRunningState,
} from 'src/reactQueries';
import { openModal, updateIgnoredSelectedLeads } from 'src/store';
import { updateSelectedLeads } from 'src/store/campaign.slice';
import { showToast } from 'src/store/toast.slice';
import { IGetIgnoredCampaignLeadRequest, IGetLeadsRequest, ISelectOption } from 'src/types';
import { NameInput } from '../_components';

interface IDetailsLayoutProps extends PropsWithChildren {
  onExportClick?: false | (() => void);
  showRemoveAll?: boolean;
  showAddMoreLeads?: boolean;
  selectLeads?: string[];
  option?: string;
  getLeadKeys?: IGetLeadsRequest;
  getIgnoredLeadKeys?: IGetIgnoredCampaignLeadRequest;
  onOptionChange?: (value: string) => void;
  options?: ISelectOption[];
  isOptionsLoading?: boolean;
  renderSelector?: () => JSX.Element;
}

export const DetailsLayout = ({
  onExportClick,
  showRemoveAll,
  onOptionChange,
  selectLeads,
  getLeadKeys,
  getIgnoredLeadKeys,
  children,
  options,
  isOptionsLoading,
  option,
  renderSelector,
  showAddMoreLeads = false,
}: IDetailsLayoutProps) => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { campaignId } = useParams();
  const { refetch = true } = useLocationState<{ refetch?: boolean }>();
  const { campaignId: selectedLeadsCampaignId } = useAppSelector((state) => state.campaign.selectedLeads);
  const { campaignId: selectedIgnoredLeadsCampaignId, entityUrns: selectedIgnoredLeads } = useAppSelector(
    (state) => state.campaign.selectedIgnoredLeads,
  );
  const CAMPAIGN_ID = Number(campaignId);
  const showHandler = (message: string) =>
    dispatch(
      showToast({
        type: 'info',
        message,
        autoCloseTime: 3000,
      }),
    );

  const { campaign } = useGetCampaign(CAMPAIGN_ID, { refetchOnMount: refetch });
  const { name = '', status, runState = 'paused', ignoredLeadsCount = 0 } = campaign ?? {};

  const { category, isTwitterCampaign } = getCampaignTypes(campaign);
  const { deleteCampaign } = useDeleteCampaign(
    { campaignId: CAMPAIGN_ID, category },
    {
      onSuccess: () => {
        navigate(`/campaign/stats/${category}/${status}`);
        showHandler('Campaign was successfully deleted');
      },
    },
  );

  const { checkFeature } = useTeamPlan();
  const isCSVExportAllowed = checkFeature(Features.exportCSV);

  const totalSelectedLeads = selectLeads?.length;
  const totalIgnoredSelectedLeads = selectedIgnoredLeads.length;
  const isRunning = runState === 'running';
  const isArchived = status === 'archived';
  const isDraft = status === 'draft';

  const { archiveCampaign, isArchiveLoading } = useArchiveCampaign(CAMPAIGN_ID);
  const { deleteAllIgnoredLeads, isDeleteLeadsLoading } = useDeleteAllIgnoredCampaignLeads(CAMPAIGN_ID);
  const { skipCampaignLead, isSkipLoading } = useSkipCampaignLead(CAMPAIGN_ID, getLeadKeys as IGetLeadsRequest);
  const { updateRunningState, isUpdateLoading } = useUpdateRunningState(CAMPAIGN_ID, {
    campaignType: campaign?.campaignType,
    isLinkedInOnly: campaign?.isLinkedinOnly,
    category: campaign?.category,
  });
  const { deleteCampaignLead, isDeleteCampaignLoading } = useDeleteIgnoredCampaignLeads(
    CAMPAIGN_ID,
    getIgnoredLeadKeys as IGetIgnoredCampaignLeadRequest,
  );
  const { returnCampaignLead } = useReturnIgnoredCampaignLead(
    CAMPAIGN_ID,
    getIgnoredLeadKeys as IGetIgnoredCampaignLeadRequest,
  );

  useEffect(() => {
    if (selectedLeadsCampaignId !== CAMPAIGN_ID || selectedIgnoredLeadsCampaignId !== CAMPAIGN_ID) {
      dispatch(updateSelectedLeads({ campaignId: CAMPAIGN_ID, entityUrns: [] }));
      dispatch(updateIgnoredSelectedLeads({ campaignId: CAMPAIGN_ID, entityUrns: [] }));
    }
  }, [selectedLeadsCampaignId, selectedIgnoredLeadsCampaignId]);

  const tabs = [
    { label: 'Overview', route: 'details/overview' },
    { label: 'Leads', route: 'details/leads' },
    { label: 'Ignored Leads', show: !!ignoredLeadsCount, route: 'details/ignored-leads' },
    { label: 'Activity', route: 'details/activity' },
  ];

  const addMoreLeads = () => {
    if (campaign) {
      const { path, state } = getAddMoreLeadsState(campaign, {
        redirectTo: `/campaign/details/overview/${campaignId}`,
      });

      navigate(path, { state });
    }
  };

  const actionButtons = [
    {
      icon: faUserPlus,
      show:
        showAddMoreLeads &&
        checkFeature(Features.addMoreLeads) &&
        campaign?.status === 'active' &&
        ![CampaignType.LINKEDIN_GROUP, CampaignType.LINKEDIN_POST, CampaignType.LINKEDIN_EVENT].includes(
          campaign.campaignType,
        ),
      tooltip: isTwitterCampaign ? 'Feature under Maintenance' : 'Add more leads',
      onClick: () => addMoreLeads(),
      disabled: isTwitterCampaign,
    },
    {
      icon: faUserSlash,
      show: !!showRemoveAll,
      tooltip: 'Remove all ignored leads',
      isLoading: isDeleteLeadsLoading,
      onClick: () => deleteAllIgnoredLeads(),
    },
    {
      icon: faPen,
      show: !isArchived,
      tooltip: isTwitterCampaign ? 'Feature under Maintenance' : 'Edit Sequence',
      onClick: () =>
        navigate(`/campaign/${campaignId}/sequence`, {
          state: { isEdit: true, canPublish: isDraft },
        }),
      disabled: isTwitterCampaign,
    },
    {
      icon: isRunning ? faPause : faPlay,
      show: !isDraft && !isArchived,
      tooltip:
        (!isRunning && isTwitterCampaign && 'Feature under Maintenance') ||
        (isRunning ? 'Pause campaign' : 'Resume campaign'),
      isLoading: isUpdateLoading,
      onClick: () => campaignId && updateRunningState(isRunning ? 'paused' : 'running'),
      disabled: !isRunning && isTwitterCampaign,
    },
    {
      icon: faFileArchive,
      show: !isArchived,
      tooltip: 'Archive campaign',
      isLoading: isArchiveLoading,
      onClick: () =>
        archiveCampaign(
          { toArchive: true },
          {
            onSuccess: () => {
              showHandler('Campaign was successfully archived');
            },
          },
        ),
    },
    {
      customIcon: <DeleteBucket height="14px" width="14px" />,
      show: isArchived,
      tooltip: 'Delete campaign',
      onClick: () => deleteCampaign(),
    },
    {
      customIcon: <Unarchive height="14px" width="14px" />,
      show: isArchived,
      tooltip: isTwitterCampaign ? 'Feature under Maintenance' : 'Unarchive campaign',
      onClick: () => {
        archiveCampaign({ toArchive: false });
        navigate(`/campaign/stats/${category}/paused`);
        showHandler('Campaign was successfully unarchived');
      },
      disabled: isTwitterCampaign,
    },
  ];

  const onMoveLeadsToIgnored = () =>
    !!selectLeads?.length &&
    dispatch(
      openModal({
        headerText: 'Remove Leads',
        descriptionText: `Are you sure you want to remove ${selectLeads.length} lead${
          selectLeads.length === 1 ? '' : 's'
        }?`,
        onConfirm: () => skipCampaignLead(selectLeads),
        confirmButtonLoading: isSkipLoading,
      }),
    );

  const onRemoveLeads = () =>
    !!selectedIgnoredLeads.length &&
    dispatch(
      openModal({
        headerText: 'Remove Leads',
        descriptionText: `Are you sure you want to remove ${selectedIgnoredLeads.length} lead${
          selectedIgnoredLeads.length === 1 ? '' : 's'
        }?`,
        onConfirm: () => {
          deleteCampaignLead(selectedIgnoredLeads);
          dispatch(updateIgnoredSelectedLeads({ campaignId: CAMPAIGN_ID, entityUrns: [] }));
        },
        confirmButtonLoading: isDeleteCampaignLoading,
      }),
    );

  const onMoveLeads = () =>
    !!selectedIgnoredLeads.length &&
    dispatch(
      openModal({
        headerText: 'Move Leads',
        descriptionText: `Are you sure you want to move ${selectedIgnoredLeads.length} lead${
          selectedIgnoredLeads.length === 1 ? '' : 's'
        } back to this campaign?`,
        onConfirm: () => {
          returnCampaignLead(selectedIgnoredLeads);
          dispatch(updateIgnoredSelectedLeads({ campaignId: CAMPAIGN_ID, entityUrns: [] }));
        },
      }),
    );

  const onChangeFilter = (filter: ISelectOption) => {
    onOptionChange && onOptionChange(filter.value);
  };

  return (
    <Box bgcolor="light.light" pb="80px">
      <HeaderContainer>
        <Box display="flex" justifyContent="space-between" height="50px" mb="20px">
          <Box display="flex" alignItems="flex-end" width="30%">
            <NameInput name={name} isArchived={isArchived} campaignId={CAMPAIGN_ID} />
          </Box>
          <Box display="flex" alignItems="flex-end" gap="20px">
            {actionButtons?.map(
              ({ icon, onClick, tooltip, show = true, customIcon, disabled }, index) =>
                show && (
                  <Button
                    onClick={onClick}
                    key={tooltip + index}
                    tooltip={{ title: tooltip }}
                    size={{ height: '34px' }}
                    disabled={disabled}
                  >
                    {customIcon ? customIcon : <FontAwesomeIcon icon={icon} />}
                  </Button>
                ),
            )}
            {!!totalIgnoredSelectedLeads && getIgnoredLeadKeys && (
              <Button onClick={onMoveLeads}>Move Leads ({totalIgnoredSelectedLeads})</Button>
            )}
            {!!totalIgnoredSelectedLeads && getIgnoredLeadKeys && (
              <Button onClick={onRemoveLeads}>Remove Leads ({totalIgnoredSelectedLeads})</Button>
            )}
            {!!totalSelectedLeads && !getIgnoredLeadKeys && (
              <Button onClick={onMoveLeadsToIgnored}>Remove Leads ({totalSelectedLeads})</Button>
            )}
            {!isDraft && onExportClick && (
              <Button
                onClick={onExportClick}
                disabled={!isCSVExportAllowed}
                tooltip={{ title: !isCSVExportAllowed && PLAN_TOOLTIPS.professional }}
              >
                {totalSelectedLeads ? `Export leads CSV (${totalSelectedLeads})` : 'Export all leads CSV'}
              </Button>
            )}
          </Box>
        </Box>
        <Box display="flex" width="100%">
          <TabsContainer>
            {tabs?.map(({ label, route, show = true }) => {
              const to = `/campaign/${route}/${campaignId}`;

              return (
                show && (
                  <HeaderTabLink key={to} to={to}>
                    {label}
                  </HeaderTabLink>
                )
              );
            })}
          </TabsContainer>
          <Box display="flex" alignItems="center" margin="0 0 0 auto">
            {options && (
              <SelectBasicGray
                label="Filter by:"
                placeholder="All"
                value={option}
                onChange={onChangeFilter}
                options={options}
                defaultValue={options?.[0]}
                minWidth="180px"
                isLoading={isOptionsLoading}
                valueContainerPosition="space-between"
                styles={{ placeholder: (styles) => ({ ...styles, color: palette.primary.main }) }}
              />
            )}
            {renderSelector?.()}
          </Box>
        </Box>
      </HeaderContainer>
      {children}
    </Box>
  );
};
