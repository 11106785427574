import { IFacebookDetailsResponse, IFacebookPage, IFacebookResponse, IInstagramPage } from 'src/types';

type TFBCallback<T> = (response: T) => void;

export const getFacebookPages = (fbToken: string | null, callback: TFBCallback<IFacebookResponse<IFacebookPage>>) =>
  window.FB.api(
    '/me/accounts?type=page',
    {
      access_token: fbToken,
    },
    callback,
  );

export const getFacebookDetails = (callback: TFBCallback<IFacebookDetailsResponse>) =>
  window.FB.api('me?fields=id,name,email,picture', callback);

export const getInstagramDetails = (fbToken: string | null, pageId: string, callback: TFBCallback<IInstagramPage>) =>
  window.FB.api(
    `${pageId}?fields=username,profile_picture_url,follows_count,followers_count,media_count,name`,
    {
      access_token: fbToken,
    },
    callback,
  );

export const getInstagramBusinessAccounts = (
  fbToken: string | null,
  callback: TFBCallback<
    IFacebookResponse<{
      id: string;
      instagram_business_account: { id: string };
    }>
  >,
) =>
  window.FB.api(
    '/me/accounts?fields=instagram_business_account',
    {
      access_token: fbToken,
    },
    callback,
  );
