import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { archivePost } from 'src/api';
import { PostStatus } from 'src/enums';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IGetPost, IGetPostsResponse } from 'src/types';

export const useArchivePost = (options?: UseMutationOptions<void, ICustomAxiosError, number>) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const getPostsKey = ['get-posts'];

  const { mutate, ...rest } = useMutation(['archive-post'], (postId: number) => archivePost(postId), {
    ...options,
    onMutate: async (postId) => {
      options?.onMutate?.(postId);
      const postIdKey = ['post', postId];

      await queryClient.cancelQueries(getPostsKey);
      await queryClient.cancelQueries(postIdKey);

      const prevData = queryClient.getQueriesData(getPostsKey);
      queryClient.setQueriesData<IGetPostsResponse | undefined>(getPostsKey, (data) => {
        if (data) {
          return {
            ...data,
            posts: data.posts.map((post) =>
              post.id === postId
                ? {
                    ...post,
                    status: PostStatus.ARCHIVED,
                  }
                : post,
            ),
          };
        }

        return data;
      });

      const prevPostData = queryClient.getQueryData(postIdKey);
      if (prevPostData) {
        queryClient.setQueryData<IGetPost | undefined>(postIdKey, (data) => {
          if (data) {
            return {
              ...data,
              status: PostStatus.ARCHIVED,
            };
          }

          return data;
        });
      }

      return { prevData, prevPostData };
    },
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      queryClient.invalidateQueries(getPostsKey);

      dispatch(
        showToast({
          type: 'success',
          message: 'Post archived successfully',
          autoCloseTime: 3000,
        }),
      );
    },
    onError: (err, postId, context) => {
      options?.onError?.(err, postId, context);
      context?.prevData?.forEach(([key, data]) => queryClient.setQueryData(key, data));
      queryClient.setQueryData(['post', postId], context?.prevPostData);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return {
    archivePost: mutate,
    ...rest,
  };
};
