import { useState } from 'react';

import { Table, TableBody, TableHead, TableHeadCell, TablePagination, TableRow } from 'src/components';
import { DEFAULT_PER_PAGE } from 'src/constants';
import { useDebounce } from 'src/hooks';
import { useGetTeamMembers } from 'src/reactQueries';
import { ITablePagination, TSortOrder } from 'src/types';
import { MembersTableRow } from './MembersTableRow';

export type TSortField = 'name' | 'email' | 'role';
interface IMembersTableProps {
  searchByName: string;
  searchByEmail: string;
}

export const MembersTable = ({ searchByEmail, searchByName }: IMembersTableProps) => {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [sortOrder, setSortOrder] = useState<TSortOrder>('ASC');
  const [sortField, setSortField] = useState<TSortField>('name');

  const searchByNameDebounced = useDebounce(searchByName);
  const searchByEmailDebounced = useDebounce(searchByEmail);

  const { teamMembers, isLoading } = useGetTeamMembers({
    page,
    perPage,
    sortField,
    sortOrder,
    searchName: searchByNameDebounced?.trim(),
    searchEmail: searchByEmailDebounced?.trim(),
  });
  const { members = [], total = 0 } = teamMembers ?? {};

  const sortingProps = {
    sortField,
    sortOrder,
    onSortFieldChange: (field: string, order: string) => {
      setSortField(field as TSortField);
      setSortOrder(order as TSortOrder);
    },
  };

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  return (
    <>
      <Table processing={isLoading} sx={{ marginBottom: '0px !important' }}>
        <TableHead>
          <TableRow>
            <TableHeadCell name="name" sorting={sortingProps} justifyContent="flex-start">
              Name
            </TableHeadCell>
            <TableHeadCell name="email" sorting={sortingProps} justifyContent="flex-start">
              E-mail
            </TableHeadCell>
            <TableHeadCell name="role" width="180px" sorting={sortingProps} justifyContent="flex-start">
              Role
            </TableHeadCell>
            <TableHeadCell width="220px">Status</TableHeadCell>
            <TableHeadCell width="60px" />
          </TableRow>
        </TableHead>
        <TableBody>
          {members?.map(({ role, user, userId }) => (
            <MembersTableRow
              key={userId}
              userId={userId}
              email={user.email}
              name={user.name}
              role={role}
              userStatus="active"
            />
          ))}
        </TableBody>
      </Table>

      <TablePagination total={total} onPaginationChange={paginationChangeHandler} page={page} perPage={perPage} />
    </>
  );
};
