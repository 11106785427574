import styled from '@emotion/styled';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getContrastYIQ } from 'src/helpers';
import { useAuth } from 'src/hooks';

const LogoutButton = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    color: ${({ theme }) => getContrastYIQ(theme.palette.secondary.main)};
  }
`;

export const Logout = () => {
  const { logout } = useAuth();

  return (
    <LogoutButton onClick={logout}>
      <FontAwesomeIcon icon={faArrowRightFromBracket} />
    </LogoutButton>
  );
};
