import { useMutation, UseMutationOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { createSequenceTemplate } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICreateSequenceTemplate, ICustomAxiosError, ISequenceTemplate } from 'src/types';

export const useCreateSequenceTemplate = (
  options?: UseMutationOptions<ISequenceTemplate, ICustomAxiosError, ICreateSequenceTemplate>,
) => {
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation('create-sequence-template', createSequenceTemplate, {
    ...options,
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      dispatch(
        showToast({
          type: 'success',
          message: 'Campaign Sequence Template was successfully created',
          autoCloseTime: 3000,
        }),
      );
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return {
    createSequenceTemplate: mutate,
    ...rest,
  };
};
