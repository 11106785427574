import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { changeGmailAlias } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IGmailAlias } from 'src/types';

export const useChangeGmailAlias = (options?: UseMutationOptions<void, ICustomAxiosError, string>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const getGmailAliasKey = ['get-gmail-aliases'];

  const { mutate, ...rest } = useMutation('change-gmail-alias', changeGmailAlias, {
    ...options,
    onMutate: async (selectedAlias) => {
      await queryClient.cancelQueries(getGmailAliasKey);

      const prevData = queryClient.getQueryData<IGmailAlias[]>(getGmailAliasKey);

      queryClient.setQueryData<IGmailAlias[] | undefined>(getGmailAliasKey, (data) => {
        if (data) {
          return data.map((alias) => ({ ...alias, alfredDefault: alias.sendAsEmail === selectedAlias }));
        }

        return data;
      });

      return { prevData };
    },
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      await queryClient.invalidateQueries(['get-users-email'], { refetchInactive: true });
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      queryClient.setQueryData(getGmailAliasKey, context?.prevData);

      dispatch(
        showToast({
          message: err.message,
          type: 'error',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return {
    changeAlias: mutate,
    ...rest,
  };
};

// options?: UseMutationOptions<void, ICustomAxiosError>

// onSuccess: async (data, variables, context) => {
//   options?.onSuccess?.(data, variables, context);

// onError: (err, _vars, context) => {
//   options?.onError?.(err, _vars, context);

//   onMutate: async (variables) => {
//     options?.onMutate?.(variables);

// onSettled: async (data, error, variables, context) => {
//   options?.onSettled?.(data, error, variables, context);
