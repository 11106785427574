import dayjs from 'dayjs';
import { useState } from 'react';
import { Box } from '@mui/material';

import {
  FilterInput,
  Filters,
  PersonAvatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
  Typography,
} from 'src/components';
import { DEFAULT_PAGE, DEFAULT_PER_PAGE } from 'src/constants';
import { CampaignActions } from 'src/enums';
import { useDebounce } from 'src/hooks';
import { useGetCampaignActivity } from 'src/reactQueries';
import {
  IGetCampaignActivityRequest,
  IInvitesSearchFields,
  ITablePagination,
  TActivitySortField,
  TSortOrder,
} from 'src/types';
import { InvitationsLayout } from './InvitationsLayout';

export const Withdrawn = () => {
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE);
  const [sortField, setSortField] = useState<TActivitySortField>('date');
  const [sortOrder, setSortOrder] = useState<TSortOrder>('DESC');
  const [select, setSelect] = useState<number[]>([]);
  const [searchFields, setSearchFields] = useState<IInvitesSearchFields>({
    name: '',
  });

  const nameDebounced = useDebounce(searchFields.name?.trim() || '');

  const getWithdrawnInvitesParams: IGetCampaignActivityRequest = {
    page,
    perPage,
    sortField,
    sortOrder,
    filters: [CampaignActions.INVITE_WITHDRAWN],
    skipNoPersonActions: true,
    ...(nameDebounced ? { names: [nameDebounced] } : {}),
  };

  const { data, isLoading } = useGetCampaignActivity(getWithdrawnInvitesParams);

  const { actions = [], total = 0 } = data ?? {};

  const noInvitesText = searchFields.name ? 'No profiles meet your search criteria' : 'No withdrawn invitations yet.';

  const paginationChangeHandler = (pagination: ITablePagination) => {
    setPage(pagination.page);
    setPerPage(pagination.perPage);
  };

  const sortingProps = {
    sortOrder,
    sortField,
    onSortFieldChange: (field: TActivitySortField, order: TSortOrder) => {
      setSortField(field);
      setSortOrder(order);
    },
  };

  const handleSearchFieldChange = (fields: Partial<IInvitesSearchFields>) => {
    setPage(DEFAULT_PAGE);
    setSearchFields((prev) => ({ ...prev, ...fields }));
  };

  return (
    <InvitationsLayout
      selected={select}
      onSuccess={() => setSelect([])}
      getWithdrawnInvitesParams={getWithdrawnInvitesParams}
    >
      <Filters>
        <FilterInput
          label="Name"
          name="name"
          value={searchFields.name}
          onChange={(e) => handleSearchFieldChange({ name: e.target.value })}
        />
      </Filters>
      <Box padding="20px 20px 80px">
        <Table processing={isLoading}>
          <TableHead>
            <TableRow>
              <TableHeadCell width="55px" />

              <TableHeadCell name="name" sorting={sortingProps}>
                Name
              </TableHeadCell>
              <TableHeadCell name="date" sorting={sortingProps} width="160px">
                Date
              </TableHeadCell>
              <TableHeadCell width="80px">Time</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody placeholder={!total && noInvitesText}>
            {actions.map(({ id, person, createdAt }) => {
              const { linkedInData, name } = person || {};

              return (
                <TableRow key={id}>
                  <TableCell>
                    <PersonAvatar src={linkedInData?.pic} width="34px" height="34px" />
                  </TableCell>
                  <TableCell>
                    <Typography fontSize="15px">{name}</Typography>
                  </TableCell>
                  <TableCell>{dayjs.utc(createdAt).local().format('MMMM Do YYYY')}</TableCell>
                  <TableCell>{dayjs.utc(createdAt).local().format('h:mm a')}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={paginationChangeHandler} />
      </Box>
    </InvitationsLayout>
  );
};
