import { useMutation, UseMutationOptions } from 'react-query';

import { updateTeamDataAfterPaymentConfirmation } from 'src/api';
import { ICustomAxiosError, IUpdateTeamAfterPaymentConfirmation } from 'src/types';

export const useUpdateTeamDataAfterPaymentConfirmation = (
  options?: UseMutationOptions<void, ICustomAxiosError, IUpdateTeamAfterPaymentConfirmation>,
) => {
  const { mutate, ...rest } = useMutation(
    ['update-team-data-payment-confirmation'],
    updateTeamDataAfterPaymentConfirmation,
    options,
  );

  return { updateTeamDataAfterPaymentConfirmation: mutate, ...rest };
};
