import { Box } from '@mui/material';

import { TeamGroupsLayout } from './TeamGroupsLayout';
import { TeamGroupsTable } from './TeamGroupsTable';

export const TeamGroups = () => {
  return (
    <Box minWidth="960px">
      <TeamGroupsLayout />
      <TeamGroupsTable />
    </Box>
  );
};
