import { useMutation, UseMutationOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { refreshTrendPosts } from 'src/api';
import { setFetchingTrend } from 'src/store/trends.slice';
import { ICustomAxiosError, ITrend } from 'src/types';
import { useGetTrends } from './useGetTrends';

export const useRefreshTrendPosts = (options?: UseMutationOptions<ITrend, ICustomAxiosError, number>) => {
  const { data: trends } = useGetTrends();
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation(['refresh-trend-posts'], (trendId: number) => refreshTrendPosts(trendId), {
    ...options,
    onMutate: async (trendId) => {
      options?.onMutate?.(trendId);

      if (trendId === -1) {
        // setting all trends to fetch state when refreshing all
        dispatch(
          setFetchingTrend(
            trends?.reduce(
              (gen, curr) => {
                gen[curr.id] = true;

                return gen;
              },
              { [-1]: true } as Record<number, boolean>,
            ) || {},
          ),
        );
      } else {
        dispatch(
          setFetchingTrend({
            [trendId]: true,
          }),
        );
      }
    },
  });

  return { refreshTrendPosts: mutate, ...rest };
};
