import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Typography } from 'src/components';

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 15px;
  height: 150px;
  background-color: #ffffff;
`;

export const FetchingPosts = () => {
  return (
    <Wrapper>
      <Typography fontSize="20px" color="primary.wlLight">
        Fetching new posts.
      </Typography>
      <Typography fontSize="20px" color="primary.wlLight">
        Please wait...
      </Typography>
    </Wrapper>
  );
};
