import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { deleteMailSettings } from 'src/api';
import { showToast } from 'src/store';
import { ICustomAxiosError, IMailSettings } from 'src/types';

export const useDeleteMailSettings = (options?: UseMutationOptions<void, ICustomAxiosError>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const getMailSettingsQueryKey = ['get-mail-settings'];

  const { mutate, isLoading, ...rest } = useMutation(['set-mail-settings'], deleteMailSettings, {
    ...options,
    onMutate: async (variables) => {
      options?.onMutate?.(variables);

      await queryClient.cancelQueries(getMailSettingsQueryKey);

      const prevData = queryClient.getQueryData(getMailSettingsQueryKey);

      queryClient.setQueryData<IMailSettings | undefined>(
        getMailSettingsQueryKey,
        (data) =>
          data &&
          ({
            imapSettings: {},
            smtpSettings: {},
          } as IMailSettings),
      );

      return { prevData };
    },
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      dispatch(
        showToast({
          type: 'success',
          message: 'SMTP + IMAP settings deleted successfully',
          autoCloseTime: 3000,
        }),
      );
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);

      queryClient.setQueryData(getMailSettingsQueryKey, context?.prevData);

      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return {
    deleteMailSettings: mutate,
    isDeleteLoading: isLoading,
    ...rest,
  };
};
