import { useDispatch } from 'react-redux';

import { Button, Typography } from 'src/components';
import { useDeleteTag } from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';
import { IModalProps } from 'src/types';
import { ModalBody, ModalFooter } from '../_components';

export const ModalDeleteTag = ({ onCancel, params }: IModalProps) => {
  const dispatch = useDispatch();
  const { deleteTag } = useDeleteTag(params?.id ?? -1);

  const confirmHandler = () => {
    deleteTag();
  };

  const closeHandler = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  return (
    <>
      <ModalBody padding="24px 32px">
        <Typography color="black.base" lineHeight="22px" fontSize="16px">
          Are you sure you want to delete this tag?
        </Typography>
        <Typography color="black.base" lineHeight="22px" fontSize="16px">
          This item will be deleted immediately across all of your contacts. This includes leads and connections and
          cannot be undone.
        </Typography>
      </ModalBody>

      <ModalFooter alignToTheEnd padding="15px 24px">
        <Button variant="gray" onClick={closeHandler}>
          Cancel
        </Button>
        <Button onClick={confirmHandler}>Delete</Button>
      </ModalFooter>
    </>
  );
};
