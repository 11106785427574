import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button, HeaderContainer, HeaderDescription, HeaderTitle, HeaderVideo, Link } from 'src/components';
import { delay } from 'src/helpers';
import { useCompanyTheme, useWhiteLabel } from 'src/hooks';
import { useGetUserPreferences, useUpdateDailyLimits } from 'src/reactQueries';
import { IDailyLimits, IDailyLimitsFields, IUserPreferences } from 'src/types';
import { DayLimitSlider } from './_components';
import { Subtitle, SuccessMessage, Wrapper } from './_components/ui';

const SliderTitle = styled(Subtitle)`
  font-weight: 500;
`;

const generateDailyLimitsDefaultValues = (data?: IUserPreferences) => {
  const {
    views = 0,
    connects = 0,
    messages = 0,
    groupMessages = 0,
    eventMessages = 0,
    inmails = 0,
    emails = 0,
    twitterDMs = 0,
    twitterFollows = 0,
  } = data ?? {};

  return {
    views,
    connects,
    messages,
    groupMessages,
    eventMessages,
    inmails,
    emails,
    twitterDMs,
    twitterFollows,
  };
};

export const DailyLimits = () => {
  const [show, setShow] = useState(false);
  const { shortCompanyName } = useCompanyTheme();
  const { whitelabel } = useWhiteLabel();

  const { userPreferences } = useGetUserPreferences();
  const { updateDailyLimits, isSuccess, isLoading } = useUpdateDailyLimits();
  const { control, reset, handleSubmit } = useForm<IDailyLimits>({
    defaultValues: generateDailyLimitsDefaultValues(userPreferences),
  });
  const {
    maxConnects,
    maxEmails,
    maxEventMessages,
    maxGroupMessages,
    maxInmails,
    maxMessages,
    maxTwitterDMs,
    maxViews,
    maxTwitterFollows,
  } = userPreferences ?? {};

  useEffect(() => {
    if (userPreferences) {
      reset(generateDailyLimitsDefaultValues(userPreferences));
    }
  }, [userPreferences]);

  useEffect(() => {
    if (isSuccess) {
      setShow(true);
    }
    const timeId = delay(3000, () => setShow(false));

    return () => {
      clearTimeout(timeId);
    };
  }, [isSuccess]);

  const dailyLimits: IDailyLimitsFields[] = [
    {
      name: 'views',
      title: 'Engage profile',
      max: maxViews,
    },
    {
      name: 'connects',
      title: 'Invites',
      max: maxConnects,
    },
    {
      name: 'messages',
      title: 'Messages',
      max: maxMessages,
    },
    {
      name: 'groupMessages',
      title: 'Group Messages',
      max: maxGroupMessages,
    },
    {
      name: 'eventMessages',
      title: 'Event Messages',
      max: maxEventMessages,
    },
    {
      name: 'inmails',
      title: 'Inmails',
      max: maxInmails,
    },
    {
      name: 'emails',
      title: 'Emails',
      max: maxEmails,
    },
    {
      name: 'twitterFollows',
      title: 'X (Twitter) Follows',
      max: maxTwitterFollows,
    },
    {
      name: 'twitterDMs',
      title: 'X (Twitter) DMs',
      max: maxTwitterDMs,
    },
  ];

  const onSubmit = (values: IDailyLimits) => {
    updateDailyLimits(values);
  };

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Daily Limits</HeaderTitle>
        <HeaderDescription>
          Choose the maximum number of actions that {shortCompanyName} can perform per day.
          {whitelabel.isWhiteLabelUser || (
            <>
              <Link
                leftIcon
                underline
                external
                openNewTab
                to="https://help.meetalfred.com/en/articles/3327405-set-campaign-daily-limits"
              >
                Learn how it works
              </Link>
              <HeaderVideo src="https://player.vimeo.com/video/871558911" />
            </>
          )}
        </HeaderDescription>
      </HeaderContainer>
      <Wrapper maxWidth="46%">
        {dailyLimits.map(({ max, name, title, defaultValue = 0 }) => (
          <Box key={name}>
            <SliderTitle>{title}</SliderTitle>
            <Controller
              control={control}
              name={name}
              render={({ field }) => <DayLimitSlider field={field} item={{ max, name, title, defaultValue }} />}
            />
          </Box>
        ))}
        <Box display="flex" justifyContent="space-between">
          <Box>{show && <SuccessMessage mt="20px">Daily limits updated.</SuccessMessage>}</Box>
          <Box display="flex" justifyContent="flex-end" mt="20px">
            <Button processing={isLoading} onClick={handleSubmit(onSubmit)}>
              Save Limits
            </Button>
          </Box>
        </Box>
      </Wrapper>
    </>
  );
};
