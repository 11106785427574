import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

dayjs.extend(timezone);
dayjs.extend(customParseFormat);

import { useAppSelector, useLocationState } from 'src/hooks';
import { useSchedulePost } from 'src/reactQueries';
import { IFormCreatePost, PostForm } from './PostForm/PostForm';
import { PostFormLayout } from './PostForm/PostFormLayout';

export const CreatePost = () => {
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user.profile);

  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);
  const [attachmentError, setAttachmentError] = useState('');

  const { defaultValues } = useLocationState<{ defaultValues: IFormCreatePost }>();
  const { title, postTypes, allowComments, postAs, attachments, audience, content } = defaultValues || {};

  const form = useForm<IFormCreatePost>({
    defaultValues: {
      title: title || '',
      postTypes: postTypes || [],
      allowComments: allowComments || true,
      postAs: postAs || 'You',
      audience: audience || 'anyone',
      attachments: attachments || [],
      content: content || '',
      scheduledAt: dayjs.tz(`${dayjs().format('YYYY-MM-DD')} 12:00 AM`, 'YYYY-MM-DD h:mm A', user?.timezone?.name),
    },
  });

  const { schedulePost, isLoading } = useSchedulePost({
    onSuccess: (data, variables) => navigate(`/posts/${variables.postTypes[0] || 'linkedin'}`),
  });

  return (
    <>
      <PostFormLayout
        title="Create new post"
        onPublish={schedulePost}
        isPublishDisabled={isLoading || isAttachmentLoading}
        handleSubmit={form.handleSubmit}
        attachmentError={attachmentError}
      />

      <PostForm
        form={form}
        isAttachmentLoading={isAttachmentLoading}
        setIsAttachmentLoading={setIsAttachmentLoading}
        setAttachmentError={setAttachmentError}
      />
    </>
  );
};
