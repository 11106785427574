import { GroupBase, StylesConfig } from 'react-select';
import AsyncSelect, { AsyncProps } from 'react-select/async';
import { useTheme } from '@emotion/react';

import { IColorSelectOption } from 'src/types';
import { defaultComponents, defaultStyles } from './SelectBasic';

export const SelectBasicAsync = ({
  styles,
  components,
  ...otherProps
}: AsyncProps<IColorSelectOption, boolean, GroupBase<IColorSelectOption>>) => {
  const { palette } = useTheme();

  return (
    <AsyncSelect
      styles={
        { ...defaultStyles, ...styles } as StylesConfig<IColorSelectOption, boolean, GroupBase<IColorSelectOption>>
      }
      components={{ ...defaultComponents(palette), ...components }}
      {...otherProps}
    />
  );
};
