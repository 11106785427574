import { useQuery, UseQueryOptions } from 'react-query';

import { getFacebookOptions } from 'src/api';
import { ICustomAxiosError, IGetFacebookOptionsResponse } from 'src/types';

export const useGetFacebookPostOptions = (
  options?: UseQueryOptions<IGetFacebookOptionsResponse, ICustomAxiosError>,
) => {
  return useQuery<IGetFacebookOptionsResponse, ICustomAxiosError>(['get-facebook-post-options'], getFacebookOptions, {
    retry: false,
    refetchOnMount: true,
    ...options,
  });
};
