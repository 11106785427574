import { IFacebookDetailsResponse, IFacebookPage, IInstagramPage, ITwitterDetails, TSchedulePost } from 'src/types';
import { Facebook, Instagram, LinkedIn, Twitter } from './Previews';

export const PostPreview = ({
  postData,
  facebookProfileDetails,
  instagramProfile,
  twitterData,
  enabledFacebookPages,
}: {
  postData: TSchedulePost;
  facebookProfileDetails: IFacebookDetailsResponse | null;
  instagramProfile: IInstagramPage | null;
  twitterData: ITwitterDetails | null;
  enabledFacebookPages: IFacebookPage[];
}) => {
  // landscape aspect ratio is 1.91:1 and portrait aspect ratio is 4:5
  const updatePostAttachment = (width: number, height: number) => {
    const aspectRatio = width / height;

    postData.attachments[0].wrong_aspect_ratio =
      (width > height && aspectRatio > 1.91) || (width < height && aspectRatio < 0.8);
  };

  let content = postData.content;
  if (content && content.length > 0) {
    content = content.replace(/\B(#[a-zA-Z]+\b)(?!;)/g, '<span class="post-hashtag">$&</span>');

    content = content.replace(/\n/g, '<br />');
  }

  if (postData?.attachments?.length) {
    if (postData?.attachments[0].file_type.includes('video')) {
      const video = document.createElement('video');

      video.onload = function () {
        updatePostAttachment(video.videoWidth, video.videoHeight);
      };
      video.src = postData.attachments[0].file_uri;
    } else {
      const img = new Image();
      img.onload = function () {
        updatePostAttachment(img.naturalWidth, img.naturalHeight);
      };
      img.src = postData.attachments[0].file_uri;
    }
  }

  return (
    <div>
      {postData.postTypes.includes('linkedin') && <LinkedIn postData={{ ...postData, content }} />}
      {postData.postTypes.includes('facebook') && (
        <Facebook
          postData={{ ...postData, content }}
          enabledFacebookPages={enabledFacebookPages || []}
          facebookProfileDetails={facebookProfileDetails}
        />
      )}
      {postData.postTypes.includes('instagram') && (
        <Instagram postData={{ ...postData, content }} instagramProfile={instagramProfile} />
      )}
      {postData.postTypes.includes('twitter') && (
        <Twitter postData={{ ...postData, content }} twitterData={twitterData} />
      )}
    </div>
  );
};
