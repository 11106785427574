import { faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { Box } from '@mui/material';

import { IPostAttachment, ITwitterDetails, TSchedulePost } from 'src/types';
import {
  AttachmentImage,
  AttachmentVideo,
  PostPreviewText,
  PreviewContainer,
  PreviewSocialIcon,
  SocialAvatar,
  SocialAvatarContainer,
  UserDataContainer,
  UserName,
} from '../../_components';

export const Twitter = ({
  postData,
  twitterData,
}: {
  postData: TSchedulePost;
  twitterData: ITwitterDetails | null;
}) => {
  const { name, imageUrl, username } = twitterData ?? {};

  return (
    <PreviewContainer>
      <PreviewSocialIcon color="#000000" icon={faXTwitter} />
      <Box display="flex">
        <SocialAvatarContainer>
          <SocialAvatar src={imageUrl} />
        </SocialAvatarContainer>
        <Box ml="10px" minWidth="0">
          <UserDataContainer>
            <Box display="flex">
              <UserName>{name}</UserName>
              {!!username && <Box ml="10px">@{username}</Box>}
              <Box mx="5px">•</Box>
              <span>Now</span>
            </Box>
          </UserDataContainer>
          <PostPreviewText content={postData.content} type="twitter" />
        </Box>
      </Box>
      <Box ml="60px">
        {postData.attachments?.map((attachment: IPostAttachment) => {
          const fileType = attachment.file_type;

          if (fileType.includes('image')) {
            return (
              <AttachmentImage
                data-testid="image-attachment"
                src={attachment.file_uri}
                key={`attachment-${attachment.file_uri}`}
              />
            );
          }

          if (fileType.includes('video')) {
            return (
              <AttachmentVideo
                data-testid="video-attachment"
                key={`attachment-${attachment.file_uri}`}
                src={attachment.file_uri}
                controls
              ></AttachmentVideo>
            );
          }

          return null;
        })}
      </Box>
    </PreviewContainer>
  );
};
