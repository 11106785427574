import React, { PropsWithChildren } from 'react';
import styled, { StyledComponent } from '@emotion/styled';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Tooltip } from 'src/components';
import { getContrastYIQ } from 'src/helpers';
import { IButtonProps, TSmallButtonVariant } from 'src/types';

interface IButtonStylesProps extends PropsWithChildren {
  processing: boolean;
  size?: { width?: string; height?: string };
  noPadding?: boolean;
}

const Wrapper = styled.span<{ margin: string }>`
  flex-shrink: 0;
  ${({ margin }) => margin && `margin: ${margin}`}}
`;

const StyledButton = styled.button<IButtonStylesProps>(
  ({ color = '#ffffff', processing, disabled, theme, size, noPadding }) => ({
    fontFamily: 'ProximaSoft, sans-serif',
    fontSize: '14px',
    fontWeight: 300,
    lineHeight: '16px',
    display: 'block',
    justifyContent: 'flex-end',
    border: '1px solid',
    borderRadius: '4px',
    color: color,
    cursor: 'pointer',
    padding: noPadding ? 0 : '8px 24px',
    opacity: processing ? 0.5 : 1,
    pointerEvents: `${disabled || processing ? 'none' : 'auto'}`,
    boxShadow: '0 1px 0 0 #ffffff26 inset',
    textShadow: '0 0.5px 1px #00000025',

    ['&:disabled']: {
      color: '#a3aab8',
      textShadow: 'none',
      border: '1px solid #b1b7c340',
      background: `linear-gradient(0deg, ${theme.palette.gray[100]}, ${theme.palette.gray[100]}), linear-gradient(0deg, #b1b7c340, #b1b7c340)`,
    },

    ...(size?.width ? { width: size.width } : {}),
    ...(size?.height ? { height: size.height } : {}),
  }),
);

const PrimaryButton = styled(StyledButton)(({ theme: { palette } }) => ({
  color: getContrastYIQ(palette.accent[500]),
  background: `linear-gradient(180deg, ${palette.accent[500]}de 0%, ${palette.accent[500]} 22.92%, ${palette.accent[500]} 100%);`,
  borderColor: `${palette.accent[600]}de`,
  ['&:hover']: {
    background: `linear-gradient(180deg, ${palette.accent[600]}de 0%, ${palette.accent[600]} 22.92%, ${palette.accent[600]} 100%);`,
  },
}));

const SecondaryButton = styled(StyledButton)(({ theme: { palette } }) => ({
  color: getContrastYIQ(palette.secondary.main),
  background: `linear-gradient(180deg, ${palette.secondary.main}e0 0%, ${palette.secondary.main} 36.98%, ${palette.secondary.main} 100%);`,
  borderColor: `${palette.secondary.dark}e0`,
  ['&:hover']: {
    background: `linear-gradient(180deg, ${palette.secondary.dark}e0 0%, ${palette.secondary.dark} 36.98%, ${palette.secondary.dark} 100%)`,
  },
}));

const WarningButton = styled(StyledButton)(({ theme: { palette } }) => ({
  color: '#ffffff',
  background: `linear-gradient(180deg, ${palette.error[500]}e0 0%, ${palette.error[500]} 36.98%, ${palette.error[500]} 100%);`,
  borderColor: `${palette.error[600]}e0`,
  ['&:hover']: {
    background: `linear-gradient(180deg, ${palette.error[600]}e0 0%, ${palette.error[600]} 36.98%, ${palette.error[600]} 100%);`,
  },
}));

const GrayButton = styled(StyledButton)(({ theme: { palette } }) => ({
  color: palette.gray[800],
  background: `linear-gradient(0deg, ${palette.gray[50]}, ${palette.gray[50]});`,
  borderColor: `${palette.gray[200]}`,
  ['&:hover']: {
    background: `linear-gradient(0deg, ${palette.gray[100]}, ${palette.gray[100]});`,
  },
}));

const WhiteButton = styled(StyledButton)(({ theme: { palette } }) => ({
  color: palette.gray[800],
  background: '#ffffff',
  borderColor: `${palette.gray[200]}`,
  ['&:hover']: {
    background: palette.gray[100],
  },
}));

const TransparentButton = styled(StyledButton)(({ theme: { palette } }) => ({
  color: palette.accent[500],
  background: 'transparent',
  borderColor: `${palette.gray[100]}`,
  ['&:hover']: {
    opacity: 0.8,
  },
}));

const Spinner = styled(FontAwesomeIcon)`
  animation: fa-spin 2s linear infinite;
  height: 15px;
`;

export const Button = ({
  children,
  variant = 'primary',
  tooltip = {
    title: '',
  },
  processing = false,
  margin = '',
  ...restButtonProps
}: IButtonProps) => {
  const components: Record<TSmallButtonVariant, StyledComponent<IButtonStylesProps>> = {
    primary: PrimaryButton,
    secondary: SecondaryButton,
    warning: WarningButton,
    gray: GrayButton,
    white: WhiteButton,
    transparent: TransparentButton,
  };

  const Component = components[variant];

  return (
    <Tooltip arrow placement="top" {...tooltip}>
      <Wrapper margin={margin}>
        <Component {...restButtonProps} processing={processing}>
          {processing ? <Spinner size="lg" icon={faSpinner} /> : children}
        </Component>
      </Wrapper>
    </Tooltip>
  );
};
