import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getCampaignsSearchListIgnored } from 'src/api';
import { ICampaignSearchItem } from 'src/types';

export const useGetCampaignsSearchListIgnored = <TData = ICampaignSearchItem[]>(
  campaignId: number,
  options?: UseQueryOptions<ICampaignSearchItem[], AxiosError, TData>,
) => {
  return useQuery<ICampaignSearchItem[], AxiosError, TData>(
    ['campaign-search-list-ignored'],
    () => getCampaignsSearchListIgnored(campaignId),
    options,
  );
};
