import styled from '@emotion/styled';
import { Box, BoxProps } from '@mui/material';

import { ReactComponent as QuestionMarkCircle } from 'src/assets/icons/question-mark-circle.svg';
import { Tooltip, Typography } from 'src/components';

interface InfoLabelProps extends BoxProps {
  label: string;
  value?: string | number;
  percentage?: string | number;
  tooltip?: string;
}

const IconWrapper = styled.span`
  vertical-align: middle;
  margin-left: 4px;
`;

export const InfoLabel = ({ label, value, percentage, tooltip, ...props }: InfoLabelProps) => {
  return (
    <Box {...props}>
      <Typography medium color="gray.600" lineHeight="16px" fontSize="15px" mb="6px">
        {label}

        {tooltip && (
          <Tooltip title={tooltip}>
            <IconWrapper>
              <QuestionMarkCircle />
            </IconWrapper>
          </Tooltip>
        )}
      </Typography>

      <Typography medium component="span" color="gray.800" fontSize="28px" lineHeight="28px" inline>
        {value ?? '-'}
      </Typography>

      {!!percentage && (
        <Typography
          component="span"
          color="gray.500"
          lineHeight="20px"
          fontSize="20px"
          inline
          medium
          ml="4px"
          sx={{ verticalAlign: 'top' }}
        >
          ({percentage})
        </Typography>
      )}
    </Box>
  );
};
