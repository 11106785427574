import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { acceptTeamInvitation } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { IAcceptInvitationToTeamRequest, IAcceptInvitationToTeamResponse, ICustomAxiosError } from 'src/types';

export const useAcceptInvitationToTeam = (
  options?: UseMutationOptions<IAcceptInvitationToTeamResponse, ICustomAxiosError, IAcceptInvitationToTeamRequest>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation(['accept-invitation-to-team'], acceptTeamInvitation, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      queryClient.removeQueries(['get-invitation-to-team']);

      localStorage.removeItem('lastInviteKey');

      if (data.redirectTo) {
        return location.replace(`${data.redirectTo}/auth/login?exchange-token=${data.authToken}`);
      }

      location.reload();
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return { acceptInvitation: mutate, ...rest };
};
