import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { addMoreLeadsCampaign } from 'src/api';
import { useSingleCampaignProps } from 'src/hooks';
import { showToast } from 'src/store';
import { ICreateLeadSourcesBody, ICustomAxiosError, ILeadSource } from 'src/types';

export const useAddMoreLeadsCampaign = (
  campaignId: number,
  options?: UseMutationOptions<ILeadSource, ICustomAxiosError, ICreateLeadSourcesBody>,
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { category, campaign, redirectTo } = useSingleCampaignProps();

  const { mutate, isLoading, ...rest } = useMutation(
    ['add-more-leads-campaign'],
    (data: ICreateLeadSourcesBody) => addMoreLeadsCampaign(data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        await queryClient.invalidateQueries(['get-lead-sources', campaignId], { refetchInactive: true });
        await queryClient.invalidateQueries(['campaign', campaignId], { refetchInactive: true });
        navigate(redirectTo || `/campaign/stats/${category}/${campaign?.runState === 'paused' ? 'paused' : 'active'}`);
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { addMoreLeads: mutate, isAddMoreLeadsLoading: isLoading, ...rest };
};
