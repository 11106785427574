import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { skipCampaignLead } from 'src/api';
import { useAppSelector } from 'src/hooks';
import { updateSelectedLeads } from 'src/store/campaign.slice';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IGetCampaign, IGetLeadsRequest, IGetLeadsResponse } from 'src/types';

export const useSkipCampaignLead = (
  campaignId: number,
  getLeadKeys: IGetLeadsRequest,
  options?: UseMutationOptions<void, ICustomAxiosError, string[]>,
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { entityUrns } = useAppSelector((state) => state.campaign.selectedLeads);

  const campaignQueryKey = ['campaign', campaignId];
  const campaignLeadQueryKey = ['get-campaign-leads', campaignId, JSON.stringify(getLeadKeys)];

  const { mutate, isLoading, ...rest } = useMutation(
    ['skip-campaign-lead', campaignId],
    (entityUrns: string[]) => skipCampaignLead(campaignId, { entityUrns }),
    {
      ...options,
      onMutate: async (variables) => {
        options?.onMutate?.(variables);
        await queryClient.cancelQueries(campaignLeadQueryKey);
        await queryClient.cancelQueries(campaignQueryKey);

        const prevLeadsData = queryClient.getQueryData(campaignLeadQueryKey);
        const prevCampaignData = queryClient.getQueryData(campaignQueryKey);

        queryClient.setQueryData<IGetLeadsResponse | undefined>(campaignLeadQueryKey, (data) => {
          if (data) {
            return {
              ...data,
              leads: data?.leads?.filter((lead) => !entityUrns.includes(lead.entityUrn)),
            };
          }

          return data;
        });

        queryClient.setQueryData<IGetCampaign | undefined>(campaignQueryKey, (data) => {
          if (data) {
            return {
              ...data,
              ignoredLeadsCount: 1,
            };
          }

          return data;
        });

        return { prevLeadsData, prevCampaignData };
      },
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        dispatch(
          updateSelectedLeads({
            campaignId,
            entityUrns: entityUrns.filter((entityUrn) => !variables.includes(entityUrn)),
          }),
        );
        await queryClient.invalidateQueries(campaignLeadQueryKey);
        await queryClient.invalidateQueries({
          queryKey: ['get-campaign-ignored-leads', campaignId],
          refetchInactive: true,
        });
        await queryClient.invalidateQueries(['get-campaign-leads-statuses']);
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueryData(campaignLeadQueryKey, context?.prevLeadsData);
        queryClient.setQueryData(campaignQueryKey, context?.prevCampaignData);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { skipCampaignLead: mutate, isSkipLoading: isLoading, ...rest };
};
