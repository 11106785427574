export enum NotificationTypes {
  ERROR,
  WARN,
  LINKEDIN_ERROR,
  TRIAL,
  DEACTIVATED,
  OUTSIDE_SCHEDULED,
  LINKEDIN_MAX_PENDING_INVITES,
  LIMITED_CONNECTIONS_REQUESTS,
  LINKEDIN_PASSWORD_RESET,
  LIMIT_OF_LI_CONNECTIONS,
  BOT_RECONNECT_STATUS,
  OUTLOOK_INTEGRATION_ERROR,
  LI_INVITES_RESTRICTION,
  FACEBOOK_INTEGRATION_ERROR,
  SUBSCRIPTION_CANCELLED,
}
