import { useMutation, UseMutationOptions } from 'react-query';

import { impersonateUser } from 'src/api';
import { COOKIE_TOKEN_NAME } from 'src/constants';
import { navigateAfterImpersonating, setCookie } from 'src/helpers';
import { IAuthToken, ICustomAxiosError, IImpersonateUser } from 'src/types';
import { useGetRealUser } from './useGetRealUser';

export const usePostImpersonateUser = (
  options?: UseMutationOptions<IAuthToken, ICustomAxiosError, IImpersonateUser>,
) => {
  const { realImpersonateUser } = useGetRealUser();

  const { mutate, ...rest } = useMutation(
    ['impersonate-user'],
    (data: IImpersonateUser) =>
      impersonateUser({
        ...data,
        isAdminImpersonate: realImpersonateUser?.isAdminImpersonate,
        redirectLink: realImpersonateUser?.redirectLink,
      }),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);

        setCookie(COOKIE_TOKEN_NAME, data.authToken);

        navigateAfterImpersonating();
      },
    },
  );

  return { impersonateUser: mutate, ...rest };
};
