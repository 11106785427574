import { useQuery, UseQueryOptions } from 'react-query';

import { getCampaigns } from 'src/api';
import { ICustomAxiosError, IGetCampaignResponse, IGetCampaignsRequest } from 'src/types';

export const useGetCampaigns = <TData = IGetCampaignResponse>(
  params: IGetCampaignsRequest,
  options?: UseQueryOptions<IGetCampaignResponse, ICustomAxiosError, TData>,
) => {
  const { filter = 'all', sortField = 'name', sortOrder = 'ASC', status, page, perPage, category, runState } = params;

  return useQuery<IGetCampaignResponse, ICustomAxiosError, TData>(
    ['get-campaigns', category, status, runState, filter, sortField, sortOrder, page, perPage],
    () =>
      getCampaigns({
        sortField,
        sortOrder,
        status,
        page,
        perPage,
        filter,
        category,
        runState,
      }),
    { refetchOnMount: true, ...options },
  );
};
