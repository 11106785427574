import styled from '@emotion/styled';
import { Box, TooltipProps } from '@mui/material';

import { Tooltip } from './Tooltip';

const Dot = styled(Box)`
  width: 5px;
  min-width: 5px;
  height: 5px;
  display: inline-block;
  border-radius: 50%;
  margin-left: 5px;
`;

export interface INotificationDotProps {
  tooltip?: Omit<TooltipProps, 'children'>;
  color?: string;
}

export const NotificationDot = ({
  color = '#ffa500',
  tooltip = {
    title: '',
  },
  ...rest
}: INotificationDotProps) => {
  return (
    <Tooltip arrow {...tooltip}>
      <Dot {...rest} bgcolor={color} />
    </Tooltip>
  );
};
