import { StylesConfig } from 'react-select';

import { ITag } from 'src/types';

export const selectTagStyles: StylesConfig<ITag> = {
  control: () => ({
    padding: '5px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #eee',
    position: 'relative',
    display: 'flex',
    backgroundColor: '#fff',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  multiValue: (_provided, state) => ({
    display: 'flex',
    backgroundColor: `${state.data.color}1a` || 'var(--light-main)',
    borderRadius: '16px',
    border: `1px solid ${state.data.color}80`,
    lineHeight: '22px',
    margin: '3px 5px 3px 0',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    position: 'relative',
  }),
  multiValueLabel: (provided, state) => ({
    padding: '0 24px 0 10px',
    color: state.data.color,
    fontSize: '14px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: state.data.color,
    cursor: 'pointer',
    position: 'absolute',
    top: '50%',
    right: '4px',
    transform: 'translateY(-50%)',
    ['&:hover']: {
      color: state.data.color,
      backgroundColor: 'transparent',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '14px',
    padding: '10px 15px',
    backgroundColor: 'transparent !important',
    transition: '0.2s',
    ['&:hover']: {
      color: 'var(--info-dark)',
    },
    ['&::before']: {
      display: 'inline-block',
      marginRight: '10px',
      content: "''",
      color: state.data.color || 'var(--light-main)',
      width: '20px',
      height: '20px',
      borderRadius: '4px',
      backgroundColor: state.data.color || 'var(--light-main)',
      border: '1px solid rgb(0 0 0 / 20%)',
      flexShrink: 0,
    },
  }),
};
