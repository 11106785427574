import { UseFormReturn } from 'react-hook-form';
import { Box } from '@mui/material';

import { Typography } from 'src/components';
import { useCompanyTheme } from 'src/hooks';
import { ISequence } from 'src/types';
import { SequenceForm } from '../SequenceForm/SequenceForm';
import { Tags } from '../Tags';

interface ITwitterDirectMessageProps extends UseFormReturn<ISequence> {
  index: number;
  customSnippets: Record<string, string>;
}

export const TwitterDirectMessage = ({ index, customSnippets, ...sequenceForm }: ITwitterDirectMessageProps) => {
  const { shortCompanyName } = useCompanyTheme();

  const snippetOptions = Object.keys(customSnippets).map((key) => ({ label: key, value: key }));
  const { watch, setValue } = sequenceForm;

  return (
    <>
      <Box mb="20px">
        <SequenceForm
          title="Message"
          watchField="message"
          description={`If you are not following this profile on X (Twitter), ${shortCompanyName} will follow the profile before sending the message.`}
          config={{
            textMaxLength: 8000,
            withUploadFile: true,
          }}
          index={index}
          snippetOptions={snippetOptions}
          registerOptions={{
            validate: (value, formValues) => !!value.trim() || !!formValues.sequence?.[index]?.attachments?.length,
          }}
          {...sequenceForm}
        />
      </Box>
      <Tags index={index} watch={watch} setValue={setValue} />
      <Typography color="red" mt="20px">
        Warning: X (Twitter) DM feature is under maintenance. The step won’t be completed as expected.
      </Typography>
    </>
  );
};
