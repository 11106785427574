import { useQuery, UseQueryOptions } from 'react-query';

import { getCampaignInvites } from 'src/api';
import { ICustomAxiosError, IGetInvitesRequest, IGetInvitesResponse } from 'src/types';

export const useGetCampaignInvites = (
  data: IGetInvitesRequest,
  options?: UseQueryOptions<IGetInvitesResponse, ICustomAxiosError>,
) => {
  return useQuery<IGetInvitesResponse, ICustomAxiosError>(
    ['get-campaign-invites', JSON.stringify(data)],
    () => getCampaignInvites(data),
    options,
  );
};
