import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { updateLeadTags } from 'src/api';
import { ICustomAxiosError, ILeadInfo, ITag } from 'src/types';

export const useUpdateLeadTags = (entityUrn: string, options?: UseMutationOptions<void, ICustomAxiosError, ITag[]>) => {
  const queryClient = useQueryClient();

  const getLeadsInfoKey = ['get-lead-info', entityUrn];

  const { mutate, ...rest } = useMutation(
    ['update-lead-tags', entityUrn],
    (tags: ITag[]) => updateLeadTags(entityUrn, tags),
    {
      ...options,
      onMutate: async (tags) => {
        options?.onMutate?.(tags);
        await queryClient.cancelQueries(getLeadsInfoKey);

        const prevData = queryClient.getQueryData(getLeadsInfoKey);

        queryClient.setQueryData<ILeadInfo | undefined>(getLeadsInfoKey, (lead) => {
          if (lead) {
            return {
              ...lead,
              tags,
            };
          }

          return lead;
        });

        return { prevData };
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueryData(getLeadsInfoKey, context?.prevData);
      },
    },
  );

  return { updateLeadTags: mutate, ...rest };
};
