import { useQuery } from 'react-query';

import { getPosts } from 'src/api';
import { IGetPostsRequest } from 'src/types';

export const usePosts = (data: IGetPostsRequest) => {
  return useQuery(['get-posts', JSON.stringify(data)], () => getPosts(data), {
    refetchOnMount: true,
  });
};
