import { ICreateTagRequest, IGetTagsRequest, IGetTagsResponse, ITag, IUpdateTagRequest } from 'src/types';
import { appApi } from './axios';

export const createTag = (params: ICreateTagRequest): Promise<ITag> => appApi.post(`/tags`, params);

export const getTags = (params: IGetTagsRequest): Promise<IGetTagsResponse> => appApi.get(`/tags`, { params });

export const updateTag = ({ id, ...params }: IUpdateTagRequest): Promise<void> => appApi.patch(`/tags/${id}`, params);

export const deleteTag = (id: number): Promise<void> => appApi.delete(`/tags/${id}`);

export const getAllTags = (): Promise<ITag[]> => appApi.get(`/tags/all`);
