import { useCallback, useEffect } from 'react';

const beforeunloadListener = (event: BeforeUnloadEvent) => {
  event.preventDefault();

  event.returnValue = true;
};

export const usePageListeners = () => {
  const addUnloadConfirmation = useCallback(() => {
    window.addEventListener('beforeunload', beforeunloadListener);
  }, []);

  const removeUnloadConfirmation = useCallback(() => {
    window.removeEventListener('beforeunload', beforeunloadListener);
  }, []);

  useEffect(() => {
    return () => removeUnloadConfirmation();
  }, []);

  return { addUnloadConfirmation, removeUnloadConfirmation };
};
