import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_PER_PAGE } from 'src/constants';
import { IConnectionsFilter, ISelectedLead } from 'src/types';

interface IInitialState {
  request: IConnectionsFilter;
  exportData: {
    selectedLeads: ISelectedLead[];
  };
}

const DEFAULT_CONNECTIONS_REQUEST: IConnectionsFilter = {
  page: 1,
  perPage: DEFAULT_PER_PAGE,
  search: '',
  sortField: 'connectedAt',
  sortOrder: 'DESC',
  firstName: [],
  lastName: [],
  companyName: [],
  title: [],
  geography: [],
  industryCode: [],
  languages: [],
  tags: [],
  campaignIds: [],
  isPremium: false,
  isOpenLink: false,
  email: false,
  phone: false,
  skype: false,
  twitter: false,
  isExcluded: true,
  selectedOnly: false,
};

export const connectionsBlackListInitialState: IInitialState = {
  request: DEFAULT_CONNECTIONS_REQUEST,
  exportData: {
    selectedLeads: [],
  },
};

export const connectionsBlacklistSlice = createSlice({
  name: 'connections',
  initialState: connectionsBlackListInitialState,
  reducers: {
    updateBlacklistConnectionsRequestParams: (state, action: PayloadAction<IConnectionsFilter>) => {
      state.request = { ...state.request, ...(action.payload ?? {}) };
    },
    setBlacklistConnectionSelectedLeads: (state, action) => {
      state.exportData.selectedLeads = action.payload;
    },
    clearBlacklistConnectionRequestParams: (state) => {
      state.request = DEFAULT_CONNECTIONS_REQUEST;
    },
  },
});

export const {
  setBlacklistConnectionSelectedLeads,
  clearBlacklistConnectionRequestParams,
  updateBlacklistConnectionsRequestParams,
} = connectionsBlacklistSlice.actions;

export default connectionsBlacklistSlice.reducer;
