import { useMutation, UseMutationOptions } from 'react-query';

import { closeCancelModalTrigger } from 'src/api';
import { ICustomAxiosError } from 'src/types';

export const useCloseCancelModalTrigger = (options?: UseMutationOptions<void, ICustomAxiosError, string>) => {
  const { mutate, isLoading, ...rest } = useMutation(['cancel-modal-trigger'], closeCancelModalTrigger, options);

  return { closeCancelModalTrigger: mutate, isCloseLoading: isLoading, ...rest };
};
