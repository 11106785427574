import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getCampaign } from 'src/api';
import { IGetCampaign } from 'src/types';

export const useGetCampaign = <TData = IGetCampaign>(
  id: number,
  options?: UseQueryOptions<IGetCampaign, AxiosError, TData>,
) => {
  const { data, ...rest } = useQuery<IGetCampaign, AxiosError, TData>(['campaign', id], () => getCampaign(id), options);

  return { campaign: data, ...rest };
};
