import styled from '@emotion/styled';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

const Image = styled.img<{ width: string; height: string }>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${({ theme }) => theme.palette.lightGray.main};
  border-radius: 50%;
  flex-shrink: 0;
`;

const ImageBox = styled(Box)<{ width: string; height: string }>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background-color: ${({ theme }) => theme.palette.lightGray.main};
  border-radius: 50%;
  flex-shrink: 0;
`;

export const GroupedPersonAvatar = styled(Box)`
  display: flex;
  margin: 0;
  padding-left: 30px;
  white-space: pre;

  & > * {
    margin-left: -36px;
  }
`;

interface IPersonAvatarProps {
  src?: string;
  width?: string;
  height?: string;
}

export const PersonAvatar = ({ src, width = '60px', height = '60px' }: IPersonAvatarProps) => {
  if (!src) {
    return (
      <ImageBox display="flex" justifyContent="center" alignItems="center" width={width} height={height}>
        <FontAwesomeIcon color="#e3e5ec" icon={faUser} />
      </ImageBox>
    );
  }

  return <Image src={src} width={width} height={height} />;
};
