import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faReply, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Box } from '@mui/material';

import { ActionButton, Checkbox, HtmlParser, NotificationDot, TableCell, TableRow, Typography } from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { ModalTypes, Plan } from 'src/enums';
import { resolveActionStepNumber, resolvePersonName } from 'src/helpers';
import { useAppSelector } from 'src/hooks';
import { useReturnCampaignReplies, useSkipCampaignLead } from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';
import { ICampaignLead, IGetCampaign, IGetLeadsRequest, ISelectProps, TLeadsSortType } from 'src/types';

const Text = styled(Box)<{
  checked?: boolean;
}>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ checked, theme }) => (checked ? 'inherit' : theme.palette.text.primary)};
`;

interface ITwitterLeadsTableRowProps {
  lead: ICampaignLead;
  campaign: IGetCampaign;
  getLeadKeys: IGetLeadsRequest;
  selectProps: ISelectProps;
  config: {
    showMessages: boolean;
    repliesFilter: boolean;
    showDate: boolean;
    isAllFilter: boolean;
    showActions: boolean;
  };
}

const SHOW_MESSAGE_WHITELIST: TLeadsSortType[] = ['twitterMessageSent', 'replies', 'allReplies'];

export const TwitterLeadsTableRow = ({
  lead,
  campaign,
  selectProps,
  getLeadKeys,
  config,
}: ITwitterLeadsTableRowProps) => {
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const { plan } = useAppSelector((state) => state?.team?.data) ?? {};
  const { status, isReturnToCampaign, linkedinData, createdAt, message, name, workEmail, isExcluded, nextTouchIndex } =
    lead;
  const { isAllFilter, repliesFilter, showActions, showDate, showMessages } = config;
  const { entityUrn, twitterHandle, csvColumns } = lead;
  const { name: csvName = '', twitter } = csvColumns ?? {};

  const { skipCampaignLead, isSkipLoading } = useSkipCampaignLead(campaign?.id, getLeadKeys);
  const { returnCampaignReplies, isReturnLoading } = useReturnCampaignReplies({ campaignId: campaign?.id });

  const isLeadApproved = status === 'approved';
  const isSelected = selectProps.selectLeads.includes(entityUrn);
  const returnedLead = repliesFilter && lead?.isReturnToCampaign;
  const returnTooltip = isLeadApproved
    ? `Return${isReturnToCampaign ? 'ed' : ''} to campaign`
    : 'The Lead is not in the campaign';
  const totalSteps = campaign?.touchSequence?.sequence?.length || 1;

  const { avatarContent, name: profileName } = resolvePersonName({
    workEmail,
    twitterHandle,
    linkedInData: linkedinData,
    name: csvName || name,
    campaignCategory: campaign.category,
  });

  const messageWithSnippets = Object.entries(lead?.csvColumns ?? {}).reduce(
    (acc, value) => acc?.replaceAll(`{{${value[0]}}}`, value[1]),
    message || '',
  );

  const actions = [
    {
      isShow: isAllFilter,
      icon: faTimes,
      title: 'Remove Lead',
      isLoading: isSkipLoading,
      onClick: () => skipCampaignLead([entityUrn]),
    },
    {
      isShow: repliesFilter,
      icon: faReply,
      title: plan === Plan.PERSONAL ? PLAN_TOOLTIPS.professional : returnTooltip,
      disabled: !isLeadApproved || isReturnToCampaign || plan === Plan.PERSONAL,
      isLoading: isReturnLoading,
      onClick: () => returnCampaignReplies([entityUrn]),
    },
  ];

  const modalHandler = () => {
    dispatch(
      openModal({
        type: ModalTypes.VIEW_LEAD,
        closable: false,
        params: {
          campaignId: campaign?.id,
          entityUrn,
        },
      }),
    );
  };

  return (
    <TableRow
      checkedBgColor={palette.primary.main}
      key={entityUrn}
      checked={isSelected}
      sx={{ cursor: 'pointer' }}
      onClick={() => modalHandler()}
    >
      <TableCell onClick={(event) => event.stopPropagation()}>
        <Checkbox checked={isSelected} onChange={() => selectProps.toggleLead(entityUrn)} />
      </TableCell>
      <TableCell>
        <Box display="flex" alignItems="center">
          <Box margin="0 17px 0 1px">{avatarContent}</Box>
          <Text checked={isSelected}>{profileName}</Text>
          {returnedLead && (
            <Typography color="violet.dark" fontSize="12px">
              (Returned to campaign)
            </Typography>
          )}
          {isExcluded && (
            <NotificationDot
              tooltip={{
                title: (
                  <>
                    <b>Excluded lead:</b> This lead was excluded from the campaign. Update their status in the CRM to
                    make changes.
                  </>
                ),
                placement: 'right',
              }}
            />
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Text checked={isSelected}>{twitter || twitterHandle}</Text>
      </TableCell>
      {showDate && (
        <TableCell>
          <Text>{dayjs.utc(createdAt).local().format('MMMM Do YYYY h:mm a')}</Text>
        </TableCell>
      )}
      {showMessages && (
        <TableCell>
          <Text>
            {SHOW_MESSAGE_WHITELIST.includes(getLeadKeys?.type) ? (
              <HtmlParser parseText={(messageWithSnippets ?? '')?.replace(/<img[^>]*>/g, '')} />
            ) : (
              messageWithSnippets
            )}
          </Text>
        </TableCell>
      )}

      <TableCell>{resolveActionStepNumber({ stepNumber: nextTouchIndex, totalSteps })}</TableCell>

      {showActions && (
        <TableCell preventClick justifyContent="flex-end">
          <Box display="flex" gap="8px">
            {actions?.map(
              ({ icon, onClick, title: actionTitle, isLoading, disabled, isShow }) =>
                isShow && (
                  <ActionButton
                    key={actionTitle}
                    processing={isLoading}
                    tooltip={actionTitle}
                    disabled={disabled}
                    onClick={onClick}
                    icon={icon}
                  />
                ),
            )}
          </Box>
        </TableCell>
      )}
    </TableRow>
  );
};
