import classNames from 'classnames';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { RegisterOptions, UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import SunEditorCore from 'suneditor/src/lib/core';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Input, SelectCampaignSnippet, TextArea, TextEditor, Tooltip, Typography } from 'src/components';
import { IModalChooseTemplateProps } from 'src/components/Modal';
import { Attachment } from 'src/components/ui/Attachment';
import { PLAN_TOOLTIPS, TEMPLATE_EMAIL_SNIPPET_OPTIONS, TEMPLATE_SNIPPET_OPTIONS } from 'src/constants';
import { CampaignType, Features, ModalTypes } from 'src/enums';
import { separateNumWithComma } from 'src/helpers';
import { useTeamPlan } from 'src/hooks';
import { useGetCampaign, useGetTemplatesPersonal } from 'src/reactQueries';
import { openModal } from 'src/store/modal.slice';
import { IAttachment, ISelectOption, ISequence, ISequenceStep } from 'src/types';
import { Attachments } from './Attachments';

const StyledTextArea = styled(TextArea)`
  align-self: center;
  height: 33px;
  line-height: 19px;
  margin-bottom: 10px;
  max-height: 500px;
  min-height: 70px;
  padding: 5px 10px;
  width: 100%;
  resize: vertical;
`;

const StyledInput = styled(Input)`
  height: 40px;
  min-height: 40px;
  margin-bottom: 10px;
  margin-top: 0;
  font-size: 14px;
  line-height: 19px;
  padding: 5px 10px;
  box-shadow: none;

  &.error {
    border: 1px solid ${({ theme }) => theme.palette.error.light};
    box-shadow:
      inset 0 1px 1px rgb(0 0 0 / 8%),
      0 0 8px rgb(246 39 19 / 60%);
  }
`;

const AttachmentBox = styled(Box)`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.light.light};
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px 4px 0 0;
  margin-left: 5px;

  &:hover {
    color: #fff;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: 'ProximaSoft', sans-serif;
  white-space: pre-wrap;
`;

export interface ISequenceFormConfig {
  type: 'input' | 'textarea' | 'editField';
  withUploadFile: boolean;
  withTemplates: boolean;
  textMaxLength: number;
}

interface ISequenceFormProps extends UseFormReturn<ISequence> {
  index: number;
  title?: string;
  description?: ReactNode;
  snippetOptions?: ISelectOption[];
  config?: Partial<ISequenceFormConfig>;
  watchField: keyof ISequenceStep;
  registerOptions?: RegisterOptions;
}

const defaultConfig: ISequenceFormConfig = {
  textMaxLength: 0,
  withUploadFile: false,
  withTemplates: true,
  type: 'textarea',
};

export const SequenceForm = ({
  title,
  index,
  config,
  watchField,
  description,
  snippetOptions,
  registerOptions = {},
  ...sequenceForm
}: ISequenceFormProps) => {
  const dispatch = useDispatch();
  const { campaignId } = useParams<{ campaignId: string }>();
  const { checkFeature } = useTeamPlan();

  const FORM_CONFIG = { ...defaultConfig, ...config };
  const {
    watch,
    setValue,
    register,
    formState: { errors },
    trigger,
  } = sequenceForm;

  const { campaign } = useGetCampaign(Number(campaignId), { enabled: !isNaN(Number(campaignId)) });
  const { data } = useGetTemplatesPersonal({}, { enabled: FORM_CONFIG.withTemplates });

  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [sunEdit, setSunEdit] = useState<SunEditorCore>();

  const watchText = watch(`sequence.${index}.${watchField}`) as string;
  const watchAttachments = watch(`sequence.${index}.attachments`) ?? [];
  const watchType = watch(`sequence.${index}.type`);

  const watchTextLength = watchText?.length ?? 0;
  const numLettersLeft = (FORM_CONFIG.textMaxLength ?? 0) - watchTextLength;
  const caretPosition = useRef(watchTextLength);
  const showLinkedinSnippet = ![CampaignType.EMAIL_CSV_UPLOAD, CampaignType.TWITTER_CSV_UPLOAD].includes(
    campaign?.campaignType as CampaignType,
  );
  const showCSVSnippet = [CampaignType.EMAIL_CSV_UPLOAD, CampaignType.TWITTER_CSV_UPLOAD].includes(
    campaign?.campaignType as CampaignType,
  );
  const isAttachTemplatesAllowed = checkFeature(Features.templatesInSequenceStep);

  useEffect(() => {
    if (FORM_CONFIG.textMaxLength && watchTextLength > FORM_CONFIG.textMaxLength) {
      setValue(`sequence.${index}.${watchField}`, watchText.slice(0, FORM_CONFIG.textMaxLength));
    }
  }, [watch(`sequence.${index}.${watchField}`)]);

  const onAttachmentDelete = (name: string) => {
    setValue(
      `sequence.${index}.attachments`,
      watchAttachments.filter((file) => file.file_uri !== name),
    );
    trigger(`sequence.${index}.message`);
  };

  const checkShowSnippetPlanWarn = useCallback(
    (text: string) => {
      if (checkFeature(Features.CSVSnippets)) {
        return false;
      }
      const fieldSnippets = text.match(/{{.*?}}/g);
      const snippetOptionsList = snippetOptions || [];

      return fieldSnippets?.some((snippet) =>
        snippetOptionsList.map(({ value }) => value).includes(snippet.replace(/{{/g, '').replace(/}}/g, '')),
      );
    },
    [snippetOptions],
  );
  const showSnippetPlanWarn = useMemo(() => checkShowSnippetPlanWarn(watchText), [watchText, checkShowSnippetPlanWarn]);

  const checkShowNoSnippetWarn = useCallback(
    (text: string) => {
      const conditionEmailCSVSnippet = showCSVSnippet ? TEMPLATE_EMAIL_SNIPPET_OPTIONS : [];
      const liSnippets = showLinkedinSnippet ? TEMPLATE_SNIPPET_OPTIONS : conditionEmailCSVSnippet;
      const allSnippets = [...liSnippets, ...(snippetOptions || [])]?.map(({ value }) => value);

      const fieldSnippets = text?.match(/{{.*?}}/g);

      return fieldSnippets?.some((snippet) => !allSnippets.includes(snippet.replace(/{{/g, '').replace(/}}/g, '')));
    },
    [showCSVSnippet, showLinkedinSnippet, snippetOptions],
  );
  const showNoSnippetWarn = useMemo(() => checkShowNoSnippetWarn(watchText), [watchText, checkShowNoSnippetWarn]);

  const onOpenSelect = () => setIsSelectOpen(true);
  const onCloseSelect = () => setIsSelectOpen(false);

  const onSnippetClick = (value: string) => {
    const snippet = `{{${value}}}`;

    if (sunEdit && FORM_CONFIG.type === 'editField') {
      return sunEdit.insertHTML(snippet);
    }

    const position = caretPosition.current;
    const leftValueSide = watchText.slice(0, position) + snippet;
    const newValue = leftValueSide + watchText.slice(position);

    caretPosition.current = leftValueSide.length;

    return setValue(`sequence.${index}.${watchField}`, newValue);
  };

  const onChooseTemplates = () => {
    if (!isAttachTemplatesAllowed) {
      return;
    }

    dispatch(
      openModal({
        type: ModalTypes.CHOOSE_TEMPLATE,
        closable: false,
        size: data?.total ? 'large' : 'medium',
        params: {
          type: watchType,
          field: watchField,
          onConfirm: (message, subject, attachments) => {
            if (message) {
              setValue(`sequence.${index}.${watchField}`, message, { shouldValidate: true });
            }
            if (subject) {
              setValue(`sequence.${index}.subject`, subject, { shouldValidate: true });
            }
            if (attachments) {
              setValue(`sequence.${index}.attachments`, attachments, { shouldValidate: true });
            }
          },
        } as IModalChooseTemplateProps,
      }),
    );
  };

  const inputField = () => {
    switch (FORM_CONFIG.type) {
      case 'input':
        return (
          <StyledInput
            className={classNames({
              error: errors?.sequence?.[index]?.[watchField],
            })}
            maxLength={FORM_CONFIG.textMaxLength || -1}
            name={`sequence.${index}.${watchField}`}
            register={register}
            parameters={{
              ...registerOptions,
              validate: (value, formValues) => {
                const validation =
                  !registerOptions?.validate ||
                  typeof registerOptions.validate !== 'function' ||
                  registerOptions.validate(value, formValues);

                return validation && !checkShowSnippetPlanWarn(value) && !checkShowNoSnippetWarn(value);
              },
              onBlur: (e) => {
                caretPosition.current = e.target.selectionStart;
              },
            }}
          />
        );

      case 'textarea':
        return (
          <StyledTextArea
            className={classNames({
              error: showNoSnippetWarn || showSnippetPlanWarn || errors?.sequence?.[index]?.[watchField],
            })}
            maxLength={FORM_CONFIG.textMaxLength || -1}
            name={`sequence.${index}.${watchField}`}
            register={register}
            parameters={{
              ...registerOptions,
              validate: (value, formValues) => {
                const validation =
                  !registerOptions?.validate ||
                  typeof registerOptions.validate !== 'function' ||
                  registerOptions.validate(value, formValues);

                return validation && !checkShowSnippetPlanWarn(value) && !checkShowNoSnippetWarn(value);
              },
              onBlur: (e) => {
                caretPosition.current = e.target.selectionStart;
              },
            }}
          />
        );
      case 'editField':
        return (
          <Box mb="10px">
            <TextEditor
              height="100%"
              isError={showNoSnippetWarn || showSnippetPlanWarn || !!errors?.sequence?.[index]?.[watchField]}
              {...register(`sequence.${index}.${watchField}`, {
                ...registerOptions,
                validate: (
                  value: string | number | boolean | { key: number; value: string }[] | IAttachment[] = '',
                  formValues,
                ) => {
                  if (typeof value !== 'string') return true;

                  const validation =
                    !registerOptions?.validate ||
                    typeof registerOptions.validate !== 'function' ||
                    registerOptions.validate(value, formValues);

                  return validation && !checkShowSnippetPlanWarn(value) && !checkShowNoSnippetWarn(value);
                },
              })}
              onChange={(value) => {
                setValue(`sequence.${index}.${watchField}`, value);
                trigger(`sequence.${index}.message`);
              }}
              getSunEditorInstance={(sunEditor) => setSunEdit(sunEditor)}
              setOptions={{
                buttonList: [['undo', 'redo'], ['link', 'codeView'], ['list'], ['underline', 'italic', 'bold']],
              }}
              setContents={watchText?.replaceAll('\n', '<br>')}
            />
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {!!title && <Typography marginBottom="5px">{title}</Typography>}
      <Box position="relative">
        {inputField()}
        <Box display="flex" position="absolute" top="-30px" right="0">
          {FORM_CONFIG.withUploadFile && <Attachments {...sequenceForm} index={index} />}
          {FORM_CONFIG.withTemplates && (
            <Tooltip title={!isAttachTemplatesAllowed && PLAN_TOOLTIPS.any}>
              <AttachmentBox px="15px" onClick={onChooseTemplates}>
                Templates
              </AttachmentBox>
            </Tooltip>
          )}
          <Box position="relative">
            <AttachmentBox px="15px" onClick={onOpenSelect}>
              Personalize
            </AttachmentBox>

            <SelectCampaignSnippet
              isSelectOpen={isSelectOpen}
              onCloseSelect={onCloseSelect}
              onOpenSelect={onOpenSelect}
              onSnippetClick={onSnippetClick}
              snippetOptions={snippetOptions}
              showLinkedinSnippet={showLinkedinSnippet}
            />
          </Box>
        </Box>
      </Box>
      {(showNoSnippetWarn || showSnippetPlanWarn) && (
        <Typography fontSize="13px" color="red" mb="10px">
          <b>Warning:</b>
          {showSnippetPlanWarn
            ? ' Found snippets that are unavailable for current subscription Plan'
            : ' Found snippets that are unavailable from'}
          {!showSnippetPlanWarn ? (showCSVSnippet && !showLinkedinSnippet ? ' CSV file' : ' LinkedIn') : ''}
        </Typography>
      )}
      <Box display="flex" justifyContent="space-between">
        <Description>{description}</Description>
        {!!FORM_CONFIG.textMaxLength && (
          <Typography textAlign="right">{separateNumWithComma(numLettersLeft)}</Typography>
        )}
      </Box>
      {FORM_CONFIG.withUploadFile && !!watchAttachments.length && (
        <Box mt="15px">
          <Typography marginBottom="5px">Attachments:</Typography>
          <Box display="flex" flexWrap="wrap" gap="15px 20px">
            {watchAttachments.map((file) => (
              <Attachment
                key={file.file_uri}
                name={file.file_name}
                fileId={file.file_uri}
                onDeleteClick={onAttachmentDelete}
                margin="0px"
              />
            ))}
          </Box>
        </Box>
      )}
    </>
  );
};
