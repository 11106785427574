import { useMutation, UseMutationOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { setUserContractId } from 'src/api';
import { setUserSelectedContract } from 'src/store/user.slice';
import { ICustomAxiosError } from 'src/types';

export const useSetUserContractId = (options?: UseMutationOptions<void, ICustomAxiosError, string>) => {
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation(['set-contract-id'], setUserContractId, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      dispatch(setUserSelectedContract(variables));
    },
  });

  return {
    setUserContractId: mutate,
    ...rest,
  };
};
