import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { createGroup } from 'src/api/groups';
import { closeModal } from 'src/store/modal.slice';
import { showToast } from 'src/store/toast.slice';
import { ICreateGroupRequest, ICreateGroupResponse, ICustomAxiosError } from 'src/types';

export const useCreateGroup = (
  options?: UseMutationOptions<ICreateGroupResponse, ICustomAxiosError, ICreateGroupRequest>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation('create-group', createGroup, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      dispatch(closeModal());
      dispatch(showToast({ type: 'info', message: 'The group has been created' }));

      queryClient.invalidateQueries(['get-group-list-full']);
      queryClient.invalidateQueries(['get-group-list-page']);
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return {
    createGroup: mutate,
    ...rest,
  };
};
