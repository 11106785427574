import styled from '@emotion/styled';
import MuiSlider from '@mui/material/Slider';

export const Slider = styled(MuiSlider)<{
  exceeding?: boolean;
}>`
  height: 12px;
  padding: 0;
  width: calc(100% + 14px);
  margin: 10px 14px;

  & .MuiSlider-rail {
    background-color: ${({ theme, exceeding }) => (exceeding ? theme.palette.error.light : '#e6e6e6')};
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 40%);
    opacity: ${({ exceeding }) => (exceeding ? '1' : '0.38')};
    transition:
      background-color 0.3s ease,
      opacity 0.3s ease;
    left: -14px;
    width: calc(100% + 28px);
  }

  & .MuiSlider-track {
    background-color: ${({ theme }) => theme.palette.primary.main};
    border: none;
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 40%);
    left: -14px !important;
    padding-left: 14px;
  }

  & .MuiSlider-thumb {
    width: 30px;
    height: 30px;
    background: #fff;
    border: 1px solid #ccc;
    box-shadow:
      0 1px 3px rgb(0 0 0 / 40%),
      0 -1px 3px rgb(0 0 0 / 40%);

    &::after {
      content: ' ';
      position: absolute;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #dadada;
      box-shadow:
        inset 0 1px 3px rgb(0 0 0 / 40%),
        inset 0 -1px 3px rgb(0 0 0 / 40%);
    }

    &.Mui-active,
    &.Mui-focusVisible {
      box-shadow: none;
    }
  }
`;
