import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(isToday);

export const parseDate = (timestamp: number | Date) => {
  if (!timestamp) {
    return 'NA NA';
  }

  if (dayjs(timestamp).isToday()) {
    return `TODAY ${dayjs(timestamp).format('h:mm A')}`;
  }

  if (dayjs().diff(timestamp, 'days') >= 7) {
    if (dayjs().get('year') - dayjs(timestamp).get('year') > 0) {
      return dayjs(timestamp).format('MMM D, YYYY h:mm A');
    }

    return dayjs(timestamp).format('MMM D h:mm A');
  }

  return dayjs(timestamp).format('dddd h:mm A');
};
