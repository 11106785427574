import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const Alert = styled(Box)`
  background-color: #f2dede;
  border: 1px solid #ebccd1;
  color: #a94442;
  border-radius: 4px;
  padding: 15px;
  font-size: 16px;
  line-height: 16px;
  text-align: start;
`;
