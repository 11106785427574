import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import {
  Checkbox,
  HeaderButton,
  HeaderContainer,
  HeaderDescription,
  HeaderTitle,
  HeaderVideo,
  Link,
  Table,
  TableBody,
  TableHead,
  TableHeadCell,
  TablePagination,
  TableRow,
  Tooltip,
} from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { ConversationDrawer, IConversationDrawerDataProps } from 'src/containers/Conversations/_components';
import { Features, ModalTypes } from 'src/enums';
import { useAppSelector, useTeamPlan, useWhiteLabel } from 'src/hooks';
import { useGetCampaignReplies, useReturnCampaignReplies } from 'src/reactQueries';
import { openModal, TRepliesParams, updateRepliesParams } from 'src/store';
import { TActivitySortField, TSortOrder } from 'src/types';
import { RepliesTableRow } from './RepliesTableRow';

export const CampaignsReplies = () => {
  const dispatch = useDispatch();
  const { whitelabel } = useWhiteLabel();
  const { entityUrns, ...repliesParams } = useAppSelector(({ campaign }) => campaign.repliesParams);
  const { page, perPage, sortField, sortOrder } = repliesParams;

  const { checkFeature } = useTeamPlan();
  const isExportRepliesAllowed = checkFeature(Features.exportReplies);
  const isReturnToCampaignAllowed = checkFeature(Features.returnToCampaign);
  const isBulkActionCheckboxAllowed = checkFeature(Features.repliesBulkAction);

  const { returnCampaignReplies, isReturnLoading } = useReturnCampaignReplies();
  const { data, isLoading } = useGetCampaignReplies(repliesParams);
  const { replies = [], total = 0 } = data ?? {};

  const [drawerState, setDrawerState] = useState<IConversationDrawerDataProps>({ conversationId: '', entityUrn: '' });

  const currPageEntityUrns = replies
    .filter(
      ({ isReturnToCampaign, leadStatus }) =>
        isExportRepliesAllowed && !isReturnToCampaign && leadStatus === 'approved',
    )
    .map(({ person }) => person.key);
  const isSelectedAll =
    !!entityUrns.length && (entityUrns.length === total || currPageEntityUrns.every((urm) => entityUrns.includes(urm)));

  const updateParams = (params: Partial<TRepliesParams>) => dispatch(updateRepliesParams(params));
  const sortingProps = {
    sortField,
    sortOrder,
    onSortFieldChange: (sortField: TActivitySortField, sortOrder: TSortOrder) => updateParams({ sortField, sortOrder }),
  };

  const toggleLead = (entityUrn: string) =>
    entityUrns.includes(entityUrn)
      ? updateParams({ entityUrns: entityUrns.filter((urm) => urm !== entityUrn) })
      : updateParams({ entityUrns: [...entityUrns, entityUrn] });

  const toggleAllLeads = () =>
    isSelectedAll
      ? updateParams({ entityUrns: entityUrns.filter((urm) => !currPageEntityUrns.includes(urm)) })
      : updateParams({ entityUrns: [...entityUrns, ...currPageEntityUrns.filter((urm) => !entityUrns.includes(urm))] });

  const onExportClick = () => {
    dispatch(
      openModal({
        type: ModalTypes.EXPORT_REPLIES,
      }),
    );
  };

  return (
    <>
      <HeaderContainer>
        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <HeaderTitle>Replies</HeaderTitle>
          <Box display="flex" gap="15px">
            {!!entityUrns.length && (
              <HeaderButton
                processing={isReturnLoading}
                onClick={() => returnCampaignReplies(entityUrns)}
                disabled={!isReturnToCampaignAllowed}
                tooltip={{ title: !isReturnToCampaignAllowed && PLAN_TOOLTIPS.professional }}
              >
                Return to campaign ({entityUrns.length})
              </HeaderButton>
            )}
            {!!total && (
              <HeaderButton
                tooltip={{ title: !isExportRepliesAllowed && PLAN_TOOLTIPS.professional }}
                disabled={!isExportRepliesAllowed}
                onClick={onExportClick}
              >
                Export Replies
              </HeaderButton>
            )}
          </Box>
        </Box>
        <HeaderDescription>
          Track campaign replies and efficiently manage conversations with prospects.
          {whitelabel.isWhiteLabelUser || (
            <>
              <Link
                leftIcon
                underline
                external
                openNewTab
                to="https://help.meetalfred.com/en/articles/5615170-master-replies-and-streamline-conversations"
              >
                Learn how it works
              </Link>
              <HeaderVideo src="https://player.vimeo.com/video/650050931" />
            </>
          )}
        </HeaderDescription>
      </HeaderContainer>

      <Box px="15px" pt="20px" pb="80px">
        <Table processing={isLoading} disabled={isLoading}>
          <TableHead>
            <TableRow>
              <TableHeadCell width="40px">
                <Tooltip title={!isBulkActionCheckboxAllowed && PLAN_TOOLTIPS.professional}>
                  <span>
                    <Checkbox
                      checkIcon={isSelectedAll ? 'check-mark' : 'dash'}
                      checked={!!entityUrns.length}
                      onChange={toggleAllLeads}
                      disabled={!isBulkActionCheckboxAllowed}
                    />
                  </span>
                </Tooltip>
              </TableHeadCell>
              <TableHeadCell width="60px" contentStyle={{ padding: '10px 0' }} />
              <TableHeadCell name="name" width="240px" sorting={sortingProps}>
                Name
              </TableHeadCell>
              <TableHeadCell width="50px" />
              <TableHeadCell name="action" width="400px" sorting={sortingProps}>
                Message
              </TableHeadCell>
              <TableHeadCell name="campaign" width="250px" sorting={sortingProps}>
                Campaign
              </TableHeadCell>
              <TableHeadCell name="date" width="200px" sorting={sortingProps}>
                Date
              </TableHeadCell>
              <TableHeadCell width="80px" sorting={sortingProps}>
                Time
              </TableHeadCell>
              <TableHeadCell width="150px" sticky />
            </TableRow>
          </TableHead>
          <TableBody placeholder={!replies.length && 'No replies yet.'}>
            {replies.map((reply) => (
              <RepliesTableRow
                key={reply.msgCreatedAt + reply.conversationUrn + reply.createdAt}
                replies={reply}
                getRepliesKeys={repliesParams}
                selectLeads={entityUrns}
                toggleLead={toggleLead}
                setDrawerState={setDrawerState}
              />
            ))}
          </TableBody>
        </Table>
        <TablePagination page={page} perPage={perPage} total={total} onPaginationChange={updateParams} />
      </Box>
      <ConversationDrawer {...drawerState} setDrawerState={setDrawerState} />
    </>
  );
};
