import { useQuery, UseQueryOptions } from 'react-query';

import { getUserReferral } from 'src/api';
import { useAppSelector } from 'src/hooks';
import { ICustomAxiosError, IGetReferral } from 'src/types';

export const useGetUserReferral = (options?: UseQueryOptions<IGetReferral, ICustomAxiosError>) => {
  const { isAuth } = useAppSelector((state) => state.user);

  const { data, ...rest } = useQuery<IGetReferral, ICustomAxiosError>(['get-user-referral'], () => getUserReferral(), {
    enabled: isAuth,
    ...options,
  });

  return { userReferral: data, ...rest };
};
