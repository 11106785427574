import styled from '@emotion/styled';
import { Box, Grid, LinearProgress } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';

import { Button, Typography } from 'src/components';

export const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} {...props} />)`
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.gray[100]};
  background: #fff;

  &::before {
    display: none;
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  padding: 15px 20px;
  border-top: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  padding: 15px 20px;
  border-radius: 4px;
  background: #fcfcfc;

  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  cursor: default !important;

  min-height: 80px;
  max-height: 80px;
  &.Mui-expanded {
    min-height: 80px;
    max-height: 80px;
  }
`;

export const ErrorDivCheckboxContainer = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 5px;
  border: 1px solid red;
  border-radius: 4px;
`;

export const PageItem = styled.div`
  padding: 10px;
  border-radius: 5px;
  background: #fff;
  margin: 10px 0;
  display: block;
  flex: 1 0 auto;
  align-items: center;
  align-content: space-between;
  max-width: 100%;
  justify-content: space-between;
`;

export const PageDetails = styled.div`
  display: inline-block;
  width: calc(100% - 200px);
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const PageName = styled.span`
  font-family: 'ProximaSoft', sans-serif !important;
  color: ${({ theme }) => theme.palette.gray.dark};
  font-weight: 600;
  font-size: 15px !important;
`;

export const PageCategory = styled.span`
  display: block;
  font-size: 12px !important;
`;

export const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  width: auto !important;
  height: auto !important;
  padding: 6.5px 10px;
`;

export const IconButton = styled(Button)`
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`;

export const Title = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.wlLight};
  font-size: 22px;
  font-weight: 600;
`;

export const Text = styled(Typography)`
  margin: 8px 0;
`;

export const Row = styled(Box)`
  display: flex;
  width: 100%;
`;

export const IntegrationContainer = styled(Box)`
  background-color: #ffffff;
  padding: 20px;
  min-width: 300px;
  box-shadow: 0 1px 1px 0 ${({ theme }) => theme.palette.light.dark};
  border-radius: 5px;
`;

export const Divider = styled(Box)`
  height: 1px;
  background-color: ${({ theme }) => theme.palette.gray[100]};
`;

export const Loader = styled(LinearProgress)`
  background-color: #2e7d32;
  & .MuiLinearProgress-bar {
    background-color: #afcdb1;
  },
`;

export const GridContainer = styled(Grid)`
  container-type: inline-size;
  container-name: grid;
`;

export const GridItem = styled(Grid)(({ ...props }) => {
  const { xs = 1 } = (props || {}) as { xs: number };

  return {
    '@container grid (max-width: 800px)': {
      maxWidth: `${((100 / 12) * xs).toFixed(4)}%`,
      flexBasis: `${((100 / 12) * xs).toFixed(4)}%`,
      flexGrow: 0,
    },
  };
});

export const ExternalLink = styled.a`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.palette.success[700]};
  border-bottom: 1px solid ${({ theme }) => theme.palette.success[700]};
`;
