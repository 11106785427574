import styled from '@emotion/styled';
import { CardElement, CardElementProps } from '@stripe/react-stripe-js';

const StyledCard = styled(CardElement)`
  padding: 13px 12px;
  height: 42px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.palette.gray[200]};
  background-color: #ffffff;
  font-size: 16px;
  box-shadow: 0 1px 1px 0 ${({ theme }) => theme.palette.light.dark};
  box-sizing: border-box;

  &::placeholder {
    color: ${({ theme }) => theme.palette.gray[400]};
  }

  &:focus {
    outline: none;
    border: 2px solid #52d9ff;
  }
`;

export const StripeCard = (props: CardElementProps) => {
  return (
    <>
      <StyledCard
        {...props}
        options={{
          style: {
            base: {
              fontSmoothing: 'antialiased',
              fontSize: '14px',
              '::placeholder': {
                color: 'var(--gray-main)',
              },
            },
            invalid: {
              color: '#fa755a',
              iconColor: '#fa755a',
            },
          },
        }}
      />
    </>
  );
};
