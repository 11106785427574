import { flatMap } from 'lodash';
import { useMemo } from 'react';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { getSNConversations } from 'src/api';
import { setConversationPictures } from 'src/store/conversation.slice';
import { ICustomAxiosError, ISNConversation } from 'src/types';

export const useGetSNConversations = (
  { query }: { query: string },
  options?: UseInfiniteQueryOptions<ISNConversation[], ICustomAxiosError, ISNConversation[]>,
) => {
  const dispatch = useDispatch();

  const { data, ...rest } = useInfiniteQuery<ISNConversation[], ICustomAxiosError, ISNConversation[]>(
    ['get-sn-conversations', query],
    ({ pageParam }) => getSNConversations({ query, createdBefore: pageParam }),
    {
      ...options,
      enabled: true,
      getNextPageParam: (lastPage) => {
        return [...lastPage].pop()?.nextPageStartsAt;
      },
      onSuccess: (resp) => {
        options?.onSuccess?.(resp);
        const array = resp?.pages?.length ? flatMap(resp.pages) : [];

        dispatch(
          setConversationPictures(
            array.reduce(
              (acc, { entityUrn, imageUrl }) => ({
                ...acc,
                [entityUrn]: imageUrl,
              }),
              {},
            ),
          ),
        );
      },
    },
  );

  const conversations = useMemo(() => {
    return data?.pages.flat() || [];
  }, [data]);

  return { conversations, ...rest };
};
