import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Link, NotificationDot, Tooltip, Typography } from 'src/components';
import { getRemovedNotificationText } from 'src/helpers';

interface CampaignTitleProps {
  campaignId: number;
  campaignName: string;
  isCampaignRemoved: boolean;
  isLeadRemoved: boolean;
}

const Wrapper = styled(Box)`
  margin: 0 auto 12px auto;
  padding: 5px 10px;
  border-radius: 20px;
  max-width: 370px;
  background-color: rgba(242, 243, 247, 0.5);
  display: flex;
  align-items: center;
`;

const OverflowBox = styled(Box)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const CampaignTitle = ({ campaignName, campaignId, isCampaignRemoved, isLeadRemoved }: CampaignTitleProps) => {
  const ref = useRef<HTMLElement>(null);
  const [showToolTip, setShowToolTip] = useState<boolean>(true);

  const notificationText = useMemo(
    () => getRemovedNotificationText({ isCampaignRemoved, isLeadRemoved }),
    [isCampaignRemoved, isLeadRemoved],
  );

  useEffect(() => {
    const element = ref.current;

    if (element) {
      setShowToolTip(element.scrollHeight > element.offsetHeight);
    }
  }, []);

  return (
    <Tooltip title={showToolTip && campaignName}>
      <Wrapper ref={ref}>
        <OverflowBox display="flex">
          <Typography lineHeight="22px">
            Campaign:{' '}
            <Link
              variant="lightBlue"
              semibold
              to={`/campaign/details/overview/${campaignId}`}
              disabled={isCampaignRemoved}
              cursor={isCampaignRemoved ? 'default' : 'pointer'}
            >
              {campaignName}
            </Link>
          </Typography>
        </OverflowBox>
        {notificationText && <NotificationDot tooltip={{ title: notificationText }} />}
      </Wrapper>
    </Tooltip>
  );
};

export default CampaignTitle;
