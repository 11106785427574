import { Dispatch, SetStateAction } from 'react';
import { Box } from '@mui/material';

import { TextArea, Typography } from 'src/components';

interface IDescribeIssueProps {
  header: string;
  subheader?: string;
  placeholder?: string;
  errorMessage: string;
  reason: string;
  setReason: Dispatch<SetStateAction<string>>;
  showError: boolean;
}

export const DescribeIssue = ({
  header,
  subheader,
  errorMessage,
  reason,
  setReason,
  showError,
  placeholder,
}: IDescribeIssueProps) => {
  return (
    <Box py="30px" px="40px" bgcolor="light.light">
      <Typography color="primary.wlLight">{header}</Typography>
      <Typography mb="20px" color="violet.dark">
        {subheader}
      </Typography>

      <TextArea
        name="reason"
        value={reason}
        placeholder={placeholder}
        onChange={(e) => setReason(e.target.value)}
        style={{ minHeight: '100px' }}
      />

      <Box display="flex">
        {showError && <Typography color="error.main">{errorMessage}</Typography>}
        <Typography ml="auto" color={({ palette }) => (showError ? palette.error.main : palette.violet.dark)}>
          {reason.trim().length}
        </Typography>
      </Box>
    </Box>
  );
};
