import { UseFormRegister } from 'react-hook-form';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Checkbox, Tooltip } from 'src/components';
import { IFacebookDetailsResponse } from 'src/types';
import { CheckboxContainer } from '../../_components';

const FacebookLabelContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: 'ProximaSoft', sans-serif;
  margin: 3px 10px 0;

  label {
    margin: 0;
  }
`;

interface IFacebookCheckboxProps {
  pages: number;
  // eslint-disable-next-line
  register: UseFormRegister<any>;
  profileDetails: IFacebookDetailsResponse | null;
  disabled?: boolean;
}

export const FacebookCheckbox = ({ profileDetails, pages, register, disabled = false }: IFacebookCheckboxProps) => {
  return (
    <>
      {!!profileDetails && !!pages && (
        <CheckboxContainer>
          <Checkbox name="postTypes" register={register} type="checkbox" value="facebook" disabled={disabled} />
          <FacebookLabelContainer>
            <label>Facebook</label>
            <span>({pages} pages)</span>
          </FacebookLabelContainer>
        </CheckboxContainer>
      )}
      {!profileDetails && (
        <Tooltip
          title={
            <>
              Please authenticate Facebook in the Integrations page.
              <br />
              Click on Integrations tab on top navigation bar.
            </>
          }
          placement="top"
          arrow
        >
          <CheckboxContainer>
            <Checkbox name="postTypes" register={register} type="checkbox" value="facebook" disabled />
            <label>Facebook</label>
          </CheckboxContainer>
        </Tooltip>
      )}
      {!!profileDetails && !pages && (
        <Tooltip
          title={
            <>
              Please connect at least one Facebook page.
              <br />
              Click on Integrations tab on top navigation bar.
            </>
          }
          placement="top"
          arrow
        >
          <CheckboxContainer>
            <Checkbox name="postTypes" register={register} type="checkbox" value="facebook" disabled />
            <label>Facebook</label>
          </CheckboxContainer>
        </Tooltip>
      )}
    </>
  );
};
