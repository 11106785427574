import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { transformRGBChannel } from 'src/helpers';

export const NavBar = styled.nav`
  background-color: ${({ theme }) => theme.palette.brand[500]};
  border-radius: 0;
  border: 0;
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
`;

export const LogoWrapper = styled(Box)`
  height: 48px;
  width: 66px;

  & > a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
`;

export const Logo = styled.img`
  height: 26px;
  margin: 2px 14px;
`;

export const NavigationWrapper = styled(Box)`
  display: flex;
  align-items: center;
  height: 48px;
`;

export const NavigationSection = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: max-content;
  cursor: pointer;
  color: #ffffff;
  transition: 0.2s;

  &:not(:last-of-type) > div {
    border-right: 1px solid #ffffff33;
  }

  &:hover,
  &.active {
    background-color: ${({ theme }) =>
      transformRGBChannel(theme.palette.brand[500], { red: 193, green: 181, blue: 169 }, true) || '#ffffffb3'};
    color: ${({ theme }) => theme.palette.brand[500]};

    & > div {
      border-color: transparent;
    }
  }
`;

export const NavigationLabel = styled(Box)`
  display: flex;
  align-items: center;
  height: 20px;
  padding: 0 16px;
`;

export const TabsWrapper = styled(Box)`
  background-color: ${({ theme }) =>
    transformRGBChannel(theme.palette.brand[500], { red: 193, green: 181, blue: 169 }, true) ||
    theme.palette.brand[500]};
`;

export const Tabs = styled(Box)`
  display: flex;
  align-items: center;
  height: 43px;
  width: 100%;
  background-color: ${({ theme }) =>
    transformRGBChannel(theme.palette.brand[500], { red: 193, green: 181, blue: 169 }, true) || '#ffffffb3'};

  & * {
    transition: 0.2s;
  }

  & > a {
    height: 100%;
    color: ${({ theme }) => theme.palette.brand[500]};
    padding: 0 16px;
    font-weight: 500;

    & > span {
      height: 100%;
      display: flex;
      align-items: center;
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      box-sizing: border-box;
    }

    &:hover,
    &.active {
      background-color: #ffffff;
    }
  }
`;

export const Divider = styled(Box)`
  &:not(:last-of-type) {
    height: 22px;
    width: 1px;
    border-radius: 100px;
    background-color: #03363d33;
  }
`;
