import { UseFormSetValue, UseFormWatch } from 'react-hook-form';

import { Typography } from 'src/components';
import { ISequence } from 'src/types';
import { Tags } from '../Tags';

interface ITwitterFollowProps {
  index: number;
  setValue: UseFormSetValue<ISequence>;
  watch: UseFormWatch<ISequence>;
}

export const TwitterFollow = ({ index, watch, setValue }: ITwitterFollowProps) => {
  return (
    <>
      <Typography mb="10px">
        Leads in this campaign will be followed on X (Twitter).
        <br />
        If you're already following the lead, this step will be skipped.
      </Typography>
      <Tags index={index} watch={watch} setValue={setValue} />
    </>
  );
};
