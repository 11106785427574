import { isEqual } from 'lodash';
import { memo, useState } from 'react';
import { Box } from '@mui/material';

import { Checkbox, Table, TableBody, TableHead, TableHeadCell, TableRow, Typography } from 'src/components';
import { ConversationDrawer, IConversationDrawerDataProps } from 'src/containers/Conversations/_components';
import { getCampaignTypes, resolveNoLeadsPlaceholder } from 'src/helpers';
import { useCompanyTheme } from 'src/hooks';
import { useGetCampaignZapierLeads } from 'src/reactQueries';
import { ICampaignLead, IGetCampaign, IGetLeadsRequest, ISelectProps, ITableSorting, TLeadsSortType } from 'src/types';
import { LinkedinTableRow } from './LinkedinTableRow';

interface ILinkedinTableProps {
  leads: ICampaignLead[];
  campaign: IGetCampaign;
  getLeadKeys: IGetLeadsRequest;
  isLoading: boolean;
  sortingProps: ITableSorting;
  selectProps: ISelectProps;
}

export const LinkedinTable = memo(
  ({ campaign, getLeadKeys, selectProps, leads, isLoading, sortingProps }: ILinkedinTableProps) => {
    const { shortCompanyName } = useCompanyTheme();
    const { isLinkedinCampaign, isMultichannelCampaign } = getCampaignTypes(campaign);
    const [drawerState, setDrawerState] = useState<IConversationDrawerDataProps>({ conversationId: '', entityUrn: '' });

    const { data } = useGetCampaignZapierLeads(campaign.id, { refetchOnMount: true });
    const { couldNotVerifyLeads, pendingLeads } = data ?? {};

    const includeLeadType = (types: TLeadsSortType[]) => types?.includes(getLeadKeys?.type);

    const showMessages = includeLeadType([
      'replies',
      'allReplies',
      'inmailReplies',
      'messaged',
      'eventMessages',
      'groupMessageSent',
      'inmailed',
      'noEmailFound',
      'inmailSkipped',
      'emailed',
      'emailReplies',
      'emailsBounced',
    ]);
    const showLocation = !showMessages;
    const hideActions = includeLeadType(['viewed', 'alreadyConnected', 'inmailSkipped', 'emailed', 'emailOpened']);

    return (
      <Box>
        {!!(couldNotVerifyLeads || pendingLeads) && (isLinkedinCampaign || isMultichannelCampaign) && (
          <Box mb="20px" display="flex" justifyContent="flex-end">
            <Box display="flex" flexDirection="column" alignItems="flex-end" gap="15px">
              {!!pendingLeads && (
                <Typography fontSize="13.6px">
                  {pendingLeads} {pendingLeads > 1 ? 'leads' : 'lead'} waiting for verification.
                </Typography>
              )}
              {!!couldNotVerifyLeads && (
                <Typography fontSize="13.6px">
                  Couldn't verify {couldNotVerifyLeads} {couldNotVerifyLeads > 1 ? 'leads' : 'lead'}.
                </Typography>
              )}
            </Box>
          </Box>
        )}
        <Table processing={isLoading} disabled={isLoading}>
          <TableHead>
            <TableRow>
              <TableHeadCell width="40px">
                <Checkbox checked={selectProps.isSelectedAll} onChange={() => selectProps.toggleAllLeads()} />
              </TableHeadCell>
              <TableHeadCell name="name" sorting={sortingProps} width="25%">
                <Box ml="55px">Lead</Box>
              </TableHeadCell>

              <TableHeadCell name="title" sorting={sortingProps} width="25%">
                Position
              </TableHeadCell>

              <TableHeadCell name="company" sorting={sortingProps} width="25%">
                Company
              </TableHeadCell>

              {showMessages && <TableHeadCell width="25%">Messages</TableHeadCell>}
              {showLocation && <TableHeadCell width="20%">Location</TableHeadCell>}

              <TableHeadCell name="tags" width="25%">
                Tags
              </TableHeadCell>

              <TableHeadCell width="150px" name="touchIndex" sorting={sortingProps}>
                Steps
              </TableHeadCell>

              {hideActions || <TableHeadCell width="150px" />}
            </TableRow>
          </TableHead>
          <TableBody
            placeholder={
              !leads?.length && !isLoading && resolveNoLeadsPlaceholder(campaign?.campaignType, shortCompanyName)
            }
          >
            {leads?.map((lead, index) => (
              <LinkedinTableRow
                key={lead.entityUrn + lead.createdAt || index}
                lead={lead}
                campaign={campaign}
                getLeadKeys={getLeadKeys}
                selectProps={selectProps}
                config={{ hideActions, showLocation, showMessages }}
                setDrawerState={setDrawerState}
              />
            ))}
          </TableBody>
        </Table>
        <ConversationDrawer {...drawerState} setDrawerState={setDrawerState} />
      </Box>
    );
  },
  isEqual,
);
