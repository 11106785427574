import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { publishCampaign } from 'src/api';
import {
  MAX_INDIVIDUAL_RUNNING_CAMPAIGNS,
  MAX_PERSONAL_RUNNING_CAMPAIGNS,
  MAX_STARTER_RUNNING_CAMPAIGNS,
  MAX_TRIAL_RUNNING_CAMPAIGNS,
} from 'src/constants';
import { getCampaignTypes } from 'src/helpers';
import { useTeamPlan } from 'src/hooks';
import { setConnectionSelectedLeads } from 'src/store/connections.slice';
import { showToast } from 'src/store/toast.slice';
import { ICampaign, ICustomAxiosError, IPublishCampaignResponse } from 'src/types';

export const usePostPublishCampaign = (
  id: number,
  options?: UseMutationOptions<
    IPublishCampaignResponse,
    ICustomAxiosError,
    {
      name: string;
    }
  >,
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isTrialStarted, isStarter, isPersonal, isIndividual } = useTeamPlan();

  const { mutate, ...rest } = useMutation(
    ['publish-campaign', id],
    (data: { name: string }) => publishCampaign(id, data),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        const { isLimitReachedError, isProOnlyError, campaign } = data;
        const { isConnectionsCampaign } = getCampaignTypes(campaign);
        if (isConnectionsCampaign) {
          dispatch(setConnectionSelectedLeads([]));
        }

        await queryClient.cancelQueries(['campaign', campaign.id]);

        queryClient.setQueryData<ICampaign>(['campaign', campaign.id], () => campaign);

        queryClient
          .invalidateQueries(['get-campaigns', campaign.category], { refetchInactive: true })
          .catch(console.error);

        if (isProOnlyError) {
          dispatch(
            showToast({
              type: 'info',
              message: 'This type of campaign is not available on your current subscription plan.',
              autoCloseTime: 3000,
            }),
          );
        } else if (isLimitReachedError) {
          dispatch(
            showToast({
              type: 'info',
              message:
                (isTrialStarted &&
                  `You have reached the maximum limit of ${MAX_TRIAL_RUNNING_CAMPAIGNS} active campaigns for your current subscription plan.`) ||
                (isStarter &&
                  `You have reached the maximum limit of ${MAX_STARTER_RUNNING_CAMPAIGNS} active campaigns for your current subscription plan.`) ||
                (isPersonal &&
                  `You have reached the maximum limit of ${MAX_PERSONAL_RUNNING_CAMPAIGNS} active campaigns for your current subscription plan.`) ||
                (isIndividual &&
                  `You have reached the maximum limit of ${MAX_INDIVIDUAL_RUNNING_CAMPAIGNS} active campaigns for your current subscription plan.`) ||
                '',
              autoCloseTime: 3000,
            }),
          );
        }

        navigate(`/campaign/stats/${campaign.category}`);
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
      onSettled: async (data, error, variables, context) => {
        options?.onSettled?.(data, error, variables, context);
        await queryClient.invalidateQueries(['campaign', id]);
      },
    },
  );

  return {
    publishCampaign: mutate,
    ...rest,
  };
};
