import * as _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_PER_PAGE } from 'src/constants';
import { IConnectionColumnsPosition, IConnectionsFilter, ISelectedLead } from 'src/types';

interface IInitialState {
  request: IConnectionsFilter;
  exportData: {
    selectedLeads: ISelectedLead[];
  };
  connectionColumns: IConnectionColumnsPosition[];
}

export const DEFAULT_CONNECTIONS_REQUEST: IConnectionsFilter = {
  page: 1,
  perPage: DEFAULT_PER_PAGE,
  search: '',
  sortField: 'connectedAt',
  sortOrder: 'DESC',
  firstName: [],
  lastName: [],
  companyName: [],
  title: [],
  geography: [],
  industryCode: [],
  languages: [],
  tags: [],
  campaignIds: [],
  isPremium: false,
  isOpenLink: false,
  email: false,
  phone: false,
  skype: false,
  twitter: false,
  isExcluded: false,
  selectedOnly: false,
};

export const DEFAULT_CONNECTION_COLUMNS: IConnectionColumnsPosition[] = [
  { key: 'name', label: 'Name', readOnly: true },
  { key: 'company', label: 'Company' },
  { key: 'title', label: 'Position' },
  { key: 'industry', label: 'Industry' },
  { key: 'geography', label: 'Location' },
  { key: 'email', label: 'Email' },
  { key: 'phone', label: 'Phone' },
  { key: 'connectedAt', label: 'Connected on' },
  { key: 'tags', label: 'Tags' },
  { key: 'notes', label: 'Notes' },
  { key: 'language', label: 'Languages' },
  { key: 'skill', label: 'Skills' },
  { key: 'school', label: 'School' },
  { key: 'field_of_study', label: 'Field of Study' },
  { key: 'campaignName', label: 'Campaign' },
];

export const connectionsInitialState: IInitialState = {
  request: DEFAULT_CONNECTIONS_REQUEST,
  connectionColumns: DEFAULT_CONNECTION_COLUMNS,
  exportData: {
    selectedLeads: [],
  },
};

export const connectionsSlice = createSlice({
  name: 'connections',
  initialState: connectionsInitialState,
  reducers: {
    updateConnectionsRequestParams: (state, action: PayloadAction<IConnectionsFilter>) => {
      state.request = { ...state.request, ...(action.payload ?? {}) };
    },
    setConnectionSelectedLeads: (state, action) => {
      state.exportData.selectedLeads = action.payload;
    },
    setConnectionColumns: (state, action) => {
      state.connectionColumns = action.payload
        ? _.unionBy(action.payload, DEFAULT_CONNECTION_COLUMNS, 'key')
        : DEFAULT_CONNECTION_COLUMNS;
    },
    clearConnectionRequestParams: (state) => {
      state.request = DEFAULT_CONNECTIONS_REQUEST;
    },
  },
});

export const {
  setConnectionSelectedLeads,
  setConnectionColumns,
  clearConnectionRequestParams,
  updateConnectionsRequestParams,
} = connectionsSlice.actions;

export default connectionsSlice.reducer;
