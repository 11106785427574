import { PropsWithChildren } from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { GOOGLE_AUTH_CLIENT_ID } from 'src/constants';

export const GoogleProvider = ({ children }: PropsWithChildren) => {
  return GOOGLE_AUTH_CLIENT_ID ? (
    <GoogleOAuthProvider clientId={GOOGLE_AUTH_CLIENT_ID}>{children}</GoogleOAuthProvider>
  ) : (
    <>{children}</>
  );
};
