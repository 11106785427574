import { useParams } from 'react-router-dom';

import { useSendVerificationCode } from 'src/reactQueries';

export const ConfirmEmail = () => {
  const { sendVerificationCode, isLoading: verificationCodeIsLoading } = useSendVerificationCode();

  const { verificationCode } = useParams<{ verificationCode: string }>();

  if (verificationCode && !verificationCodeIsLoading) {
    sendVerificationCode(verificationCode);
  }

  return <>Redirecting...</>;
};
