import { useQuery, UseQueryOptions } from 'react-query';

import { getUserCloudBotInfo } from 'src/api';
import { ICustomAxiosError, IUserCloudBotInfo } from 'src/types';

export const useGetUserCloudBotInfo = (options?: UseQueryOptions<IUserCloudBotInfo, ICustomAxiosError>) => {
  const { data, ...rest } = useQuery<IUserCloudBotInfo, ICustomAxiosError>(
    ['get-user-cloud-bot-info'],
    getUserCloudBotInfo,
    options,
  );

  return { userCloudBotInfo: data, ...rest };
};
