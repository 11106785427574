import { InputHTMLAttributes } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

const StyledInput = styled.input<{ labelSize?: string }>`
  margin-right: 5px;

  & ~ label {
    display: flex;
    flex-direction: column;
    ${({ labelSize }) => labelSize && `font-size: ${labelSize};`}

    span {
      font-size: 12px;
      color: ${({ theme }) => theme.palette.error.main};
    }
  }
`;

interface IRadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  labelSize?: string;
  subLabel?: string;
}

export const RadioButtonOld = ({ label, id, subLabel, ...restProps }: IRadioButtonProps) => {
  return (
    <Box display="flex" alignItems="center">
      <StyledInput id={id} type="radio" {...restProps} />
      <label htmlFor={id}>
        {label}
        {subLabel && <span>{subLabel}</span>}
      </label>
    </Box>
  );
};
