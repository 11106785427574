import { UseFormReturn } from 'react-hook-form';
import { Box } from '@mui/material';

import { ISequence } from 'src/types';
import { SequenceForm } from '../SequenceForm/SequenceForm';
import { Tags } from '../Tags';

interface ISendGroupMessage extends UseFormReturn<ISequence> {
  index: number;
}

export const SendGroupMessage = ({ index, ...sequenceForm }: ISendGroupMessage) => {
  const { watch, setValue } = sequenceForm;

  return (
    <>
      <SequenceForm
        watchField="message"
        title="Message"
        description={`Messages will only be sent to Group members you are part of.\nIf the lead is not a member of selected group, the message will be skipped.`}
        config={{ textMaxLength: 8000 }}
        index={index}
        registerOptions={{
          validate: (value) => !!value.trim(),
        }}
        {...sequenceForm}
      />

      <Box mt="20px">
        <Tags index={index} watch={watch} setValue={setValue} />
      </Box>
    </>
  );
};
