import { useMutation, UseMutationOptions } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { signInViaEmail } from 'src/api';
import { COOKIE_TOKEN_NAME } from 'src/constants';
import { setCookie } from 'src/helpers';
import { setUser } from 'src/store/user.slice';
import { IAuthResponse, ICustomAxiosError, IEmailAuthRequest } from 'src/types';

export const useSignInViaEmail = (
  options?: UseMutationOptions<IAuthResponse, ICustomAxiosError, IEmailAuthRequest>,
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mutate, ...rest } = useMutation('sign-in', signInViaEmail, {
    ...options,
    onSuccess: (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);

      if (data.redirectTo) {
        return location.replace(`${data.redirectTo}/auth/login?exchange-token=${data.authToken}`);
      }

      setCookie(COOKIE_TOKEN_NAME, data?.authToken);

      dispatch(setUser(data?.user));

      navigate('/dashboard');
    },
  });

  return {
    signInViaEmail: mutate,
    ...rest,
  };
};
