import { useMemo } from 'react';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { faComment, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { faExternalLink, faThumbsUp as faThumbsUpSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { ActionButton, HtmlParser, PersonAvatar, Typography } from 'src/components';
import { getPostedTime } from 'src/helpers';
import { useLikeTrendPost } from 'src/reactQueries';
import { ITrendPost } from 'src/types';

const Wrapper = styled(Box)`
  background-color: #ffffff;
  max-width: calc(100% - 460px);
  box-sizing: border-box;
`;

interface IPostProps {
  post?: ITrendPost;
}

export const Post = ({ post }: IPostProps) => {
  const { palette } = useTheme();
  const { id = -1, liked, keyword = '', summary = '', trendId } = post ?? {};

  const { likePost } = useLikeTrendPost(id || -1, trendId || -1);

  const likeHandler = () => {
    if (id && !liked) {
      likePost(id);
    }
  };

  const highlightedSummary = useMemo(() => {
    const regex = new RegExp(keyword, 'i');

    return summary.replace(regex, `<strong>${keyword}</strong>`);
  }, [summary, keyword]);

  return (
    <Wrapper data-testid="post" ml="10px" p="10px" height="fit-content" flexGrow="1">
      {!post && <Typography color={palette.primary.wlLight}>Select a post to see preview</Typography>}
      {!!post && (
        <>
          <Box display="flex">
            <PersonAvatar src={post.profilePic} />

            <Box width="100%" ml="10px">
              <Box display="flex" alignItems="center" my="5px">
                <Typography data-testid="title" color={palette.gray.dark} semibold>
                  {post.title}
                </Typography>
                <Typography fontSize="16px" color="#828282" ml="3px">
                  {post.badgeText}
                </Typography>

                <Box ml="auto" mr="10px" display="flex" alignItems="center">
                  {!!post.trackingUrn && (
                    <a
                      data-testid="tracking-link"
                      href={`https://linkedin.com/feed/update/${post.trackingUrn}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ActionButton icon={faExternalLink} tooltip="View on Linkedin" />
                    </a>
                  )}
                  <Box ml="20px">
                    <ActionButton
                      data-testid="like"
                      onClick={likeHandler}
                      icon={post.liked ? faThumbsUpSolid : faThumbsUp}
                      tooltip="Like"
                    />
                  </Box>
                  <Typography color={palette.gray.dark} ml="5px" mr="20px">
                    {post.likes}
                  </Typography>

                  <FontAwesomeIcon color={palette.gray.dark} icon={faComment} />
                  <Typography color={palette.gray.dark} ml="5px">
                    {post.comments}
                  </Typography>
                </Box>
              </Box>

              <Box my="5px">
                <Typography color={palette.gray.dark}>{post.primarySubtitle}</Typography>
              </Box>

              <Typography my="5px" fontSize="12px" color="#828282" ml="auto">
                {getPostedTime(post.secondarySubtitle)}
              </Typography>
            </Box>
          </Box>
          <Typography my="30px" mx="10px" whiteSpace="pre-wrap">
            <HtmlParser parseText={highlightedSummary} />
          </Typography>
        </>
      )}
    </Wrapper>
  );
};
