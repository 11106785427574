import { createTheme } from '@mui/material';

import { getBaseThemePalette } from 'src/helpers';
import { useWhiteLabel } from './useWhiteLabel';

export const useBaseTheme = () => {
  const { whitelabel } = useWhiteLabel();

  // eslint-disable-next-line no-undefined
  const palette = getBaseThemePalette(whitelabel.isActive ? whitelabel.theme : undefined);
  const baseTheme = createTheme({ palette });

  const cssVarsThemePalette = Object.entries(palette).map(([generalName, subColors]) =>
    Object.entries(subColors).map(([subName, color]) => `--${generalName}-${subName}: ${color};`),
  );

  return { baseTheme, cssVarsThemePalette };
};
