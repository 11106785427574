import { useDispatch } from 'react-redux';

import { HeaderButton } from 'src/components';
import { ModalTypes } from 'src/enums';
import { openModal } from 'src/store/modal.slice';

interface IButtonAddTagsProps {
  type: 'connections' | 'leads';
  entityUrns: string[];
}

export const ButtonAddTags = ({ type, entityUrns }: IButtonAddTagsProps) => {
  const dispatch = useDispatch();

  const clickButtonHandler = () => {
    dispatch(
      openModal({
        headerText: 'Add Tags',
        type: ModalTypes.ADD_CONNECTIONS_TAGS,
        params: { type, entityUrns },
      }),
    );
  };

  return (
    <HeaderButton variant="gray" onClick={clickButtonHandler}>
      Add Tag {entityUrns.length}
    </HeaderButton>
  );
};
