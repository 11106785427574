import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button, Checkbox, Spinner } from 'src/components';
import { useChangeGmailAlias, useGetGmailAliases } from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';
import { IModalProps } from 'src/types';
import { ModalBody } from '../_components';

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ModalFooter = styled(Box)`
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 15px 40px;
`;

export const ModalEmailAlias = ({ onCancel }: IModalProps) => {
  const dispatch = useDispatch();
  const [alias, setAlias] = useState('');

  const { changeAlias, isLoading: isChangeLoading } = useChangeGmailAlias({
    onSuccess: () => {
      onCancel && onCancel();
      dispatch(closeModal());
    },
  });
  const { data = [], isLoading } = useGetGmailAliases();

  useEffect(() => {
    if (data) {
      const defaultAlias = data?.find(({ isDefault }) => isDefault);
      const alfredDefaultAlias = data?.find(({ alfredDefault }) => alfredDefault);
      setAlias(alfredDefaultAlias?.sendAsEmail || defaultAlias?.sendAsEmail || '');
    }
  }, [data]);

  const updateHandler = () => {
    changeAlias(alias);
  };

  if (isLoading) {
    return (
      <ModalBody display="flex" justifyContent="center">
        <Spinner type="dots" py="30px" size="18px" />
      </ModalBody>
    );
  }

  return (
    <>
      <ModalBody display="flex" flexDirection="column" gap="20px">
        {data?.map(({ sendAsEmail }) => (
          <CheckboxContainer key={sendAsEmail}>
            <Checkbox type="checkbox" checked={sendAsEmail === alias} onChange={() => setAlias(sendAsEmail)} />
            <label>{sendAsEmail}</label>
          </CheckboxContainer>
        ))}
      </ModalBody>

      <ModalFooter>
        <Button processing={isChangeLoading} onClick={updateHandler}>
          Update
        </Button>
      </ModalFooter>
    </>
  );
};
