import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { PersonAvatar, Typography } from 'src/components';
import { IParticipant } from 'src/types';

interface GroupMembersProps {
  participants: IParticipant[];
}

const Wrapper = styled(Box)`
  width: 20%;
  border-left: 1px solid ${({ theme }) => theme.palette.light.light};
  overflow: auto;
  height: 100%;
  padding: 15px 10px;
  box-sizing: border-box;
  flex-shrink: 0;
`;

const Divider = styled(Box)`
  width: 90%;
  margin: 10px auto;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.light.light};
`;

export const GroupMembers = ({ participants }: GroupMembersProps) => {
  return (
    <Wrapper>
      <Typography color="gray.dark" fontSize="17px" bold textAlign="center">
        Group Members
      </Typography>

      <Divider />

      <Box display="flex" flexDirection="column">
        {participants.map(({ imageUrl, entityUrn, headline, name }) => (
          <Box key={entityUrn} display="flex" my="10px" alignItems="center">
            <PersonAvatar width="40px" height="40px" src={imageUrl} />
            <Box ml="10px" width="calc(100% - 50px)">
              <Typography>
                <a href={`https://linkedin.com/in/${entityUrn}`} target="_blank" rel="noopener noreferrer">
                  {name}
                </a>
              </Typography>
              <Typography crop color="gray.light">
                {headline}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Wrapper>
  );
};
