import { useMemo } from 'react';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';

import { getTaggedConnections } from 'src/api';
import { ICustomAxiosError, ILIConversation } from '../../types';

export const useGetTaggedConnections = (
  { selectedTags }: { selectedTags: number[] },
  options?: UseInfiniteQueryOptions<ILIConversation[], ICustomAxiosError, ILIConversation[]>,
) => {
  const { data, isLoading, isFetchingNextPage, ...rest } = useInfiniteQuery<
    ILIConversation[],
    ICustomAxiosError,
    ILIConversation[]
  >(
    ['get-tagged-conversations', selectedTags],
    ({ pageParam = 1 }) => getTaggedConnections({ page: pageParam, selectedTags }),
    {
      ...options,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length === 20) {
          return allPages.length + 1;
        }
      },
    },
  );

  const taggedConnections = useMemo(() => {
    return data?.pages.flat() || [];
  }, [data]);

  return {
    taggedConnections,
    isFetchingNextPageTagged: isFetchingNextPage,
    isLoadingTagged: isLoading,
    ...rest,
  };
};
