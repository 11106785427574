import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { likeTrendPost } from 'src/api';
import { ICustomAxiosError, ITrendPost } from 'src/types';

export const useLikeTrendPost = (
  postId: number,
  trendId: number,
  options?: UseMutationOptions<void, ICustomAxiosError, number>,
) => {
  const queryClient = useQueryClient();

  const getTrendPostsKey = ['get-trend-posts', trendId];
  const getTrendAllPosts = ['get-trend-posts', -1];

  const { mutate, ...rest } = useMutation(['like-trend-post', postId], likeTrendPost, {
    ...options,
    onMutate: async (mutatePostId) => {
      options?.onMutate?.(mutatePostId);
      await queryClient.cancelQueries(getTrendPostsKey);
      await queryClient.cancelQueries(getTrendAllPosts);

      const prevKeyTrends = queryClient.getQueryData(getTrendPostsKey);
      const prevAllTrends = queryClient.getQueryData(getTrendAllPosts);

      queryClient.setQueryData<ITrendPost[] | undefined>(getTrendPostsKey, (posts) =>
        posts?.map((post) => (post.id === mutatePostId ? { ...post, liked: true, likes: post.likes + 1 } : post)),
      );

      queryClient.setQueryData<ITrendPost[] | undefined>(getTrendAllPosts, (posts) =>
        posts?.map((post) => (post.id === mutatePostId ? { ...post, liked: true, likes: post.likes + 1 } : post)),
      );

      return { prevKeyTrends, prevAllTrends };
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      queryClient.setQueryData(getTrendPostsKey, context?.prevKeyTrends);
      queryClient.setQueryData(getTrendAllPosts, context?.prevAllTrends);
    },
  });

  return { likePost: mutate, ...rest };
};
