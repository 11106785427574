import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box, useTheme } from '@mui/material';

import { Link, NotificationDot, Spinner, TableCell, Typography } from 'src/components';
import { CampaignCategory, ModalTypes } from 'src/enums';
import { getCampaignTypes } from 'src/helpers';
import { useCampaignsListProps } from 'src/hooks';
import { useRestartSearchCampaign } from 'src/reactQueries';
import { updateSelectedLeads } from 'src/store';
import { openModal } from 'src/store/modal.slice';
import { IGetCampaigns, TCampaignsStatus } from 'src/types';

const MessageWrapper = styled(Box)`
  display: flex;
  margin-left: calc(13%);
`;

interface ICampaignsTableRowCellProps {
  campaign: IGetCampaigns;
  campaignStatus: TCampaignsStatus;
}

export const CampaignsTableRowCell = ({ campaign, campaignStatus }: ICampaignsTableRowCellProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { palette } = useTheme();

  const { id, leadSourcesCount, isSearchEnded, leadsFound, category } = campaign;

  const { restartSearch } = useRestartSearchCampaign({ campaignId: id, category });
  const { isZapierCampaign } = getCampaignTypes(campaign);
  const { isInactive, inactiveLabel, inactiveNoLeads, columnConfig } = useCampaignsListProps(category);

  const type = useMemo(() => {
    switch (category) {
      case CampaignCategory.EMAIL:
        return 'email';
      case CampaignCategory.TWITTER:
        return 'twitter';
      default:
        return 'linkedin';
    }
  }, [category]);

  const isActive = campaignStatus === 'active';

  const openRestartSearchModal = () => {
    if (!leadSourcesCount) {
      return restartSearch({ restartCampaign: true });
    }

    return dispatch(
      openModal({
        type: ModalTypes.RESTART_CAMPAIGN_SEARCH,
        closable: false,
        params: {
          campaignId: id,
        },
      }),
    );
  };

  if (isInactive && (inactiveNoLeads || (!isSearchEnded && !leadsFound))) {
    return (
      <TableCell colSpan={columnConfig.length + 1} contentStyle={{ width: '100%' }}>
        <MessageWrapper>
          <Typography color="error.main" fontSize="15px">
            <Spinner type="dots" size="14px" color="text.primary" inline />
            &nbsp;&nbsp;
            <Link variant="error" semibold underline to={`/integrations/${type}`}>
              Click here
            </Link>
            &nbsp;to connect your {inactiveLabel} account to run this campaign
          </Typography>
        </MessageWrapper>
      </TableCell>
    );
  }

  if (isActive && !isSearchEnded && !leadsFound && !isZapierCampaign) {
    return (
      <TableCell colSpan={columnConfig.length + 1} contentStyle={{ overflow: 'visible', width: '100%' }}>
        <MessageWrapper>
          <Typography fontSize="15px">
            <Spinner type="dots" size="14px" inline />
            &nbsp;&nbsp;Preparing your campaign.
            <br />
            Allow 8-12 mins during scheduled{' '}
            <Link variant="darkBlue" to="/settings/work-hours">
              work hours
            </Link>
          </Typography>
        </MessageWrapper>
      </TableCell>
    );
  }

  if (!leadsFound && !isZapierCampaign) {
    return (
      <TableCell colSpan={columnConfig.length + 1} contentStyle={{ width: '100%' }}>
        {isActive && (
          <MessageWrapper>
            <Box>
              <Typography>We couldn't find any leads. Leads may be removed or in other campaign</Typography>
              <Typography>
                If you think this is a mistake please{' '}
                <Typography component="a" inline pointer color="primary.wlLight" onClick={openRestartSearchModal}>
                  click here
                </Typography>{' '}
                to restart your search.
              </Typography>
            </Box>
          </MessageWrapper>
        )}
      </TableCell>
    );
  }

  return (
    <>
      <TableCell justifyContent="flex-end">
        <Box display="flex" alignItems="center">
          <Typography
            color={palette.brand[300]}
            hoverColor={palette.brand[400]}
            pointer={!!campaign.leadsApproved}
            onClick={() => campaign.leadsApproved && navigate(`/campaign/details/leads/${id}`)}
            fontSize="15px"
          >
            {campaign.leadsApproved}
          </Typography>

          {!!campaign.leadsMoved && (
            <NotificationDot
              tooltip={{
                title: `${campaign.leadsMoved} profile${campaign.leadsMoved > 1 ? 's' : ''} moved to another campaign.`,
                placement: 'right',
                arrow: true,
              }}
            />
          )}
        </Box>
      </TableCell>

      {columnConfig?.map(({ getValue, color, withNull, getTooltip, getLink }, index) => {
        const value = getValue(campaign);
        const noValueLabel = value || typeof value === 'string' ? value : '-';
        const resolvedValue = withNull ? value : noValueLabel;
        const tooltip = getTooltip && getTooltip(campaign);
        const withLink = resolvedValue !== '-' && !!getLink;

        const onValueClick = () => {
          if (withLink) {
            dispatch(updateSelectedLeads({ campaignId: id, entityUrns: [] }));
            navigate(getLink(campaign), { state: { campaignId: id } });
          }
        };

        return (
          <TableCell key={index} justifyContent="flex-end">
            <Box display="flex" alignItems="center">
              <Typography
                color={withLink ? palette.brand[300] : color}
                pointer={withLink}
                onClick={onValueClick}
                fontSize="15px"
                {...(withLink && { hoverColor: palette.brand[400] })}
              >
                {resolvedValue}
              </Typography>

              {tooltip && <NotificationDot tooltip={{ title: tooltip, placement: 'right', arrow: true }} />}
            </Box>
          </TableCell>
        );
      })}
    </>
  );
};
