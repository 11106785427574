import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getSequenceTemplates } from 'src/api';
import { IGetSequenceTemplates, IGetSequenceTemplatesResponse } from 'src/types';

export const useGetSequenceTemplates = <TData = IGetSequenceTemplatesResponse>(
  params: IGetSequenceTemplates,
  options?: UseQueryOptions<IGetSequenceTemplatesResponse, AxiosError, TData>,
) => {
  const { page, perPage, category, availability, search, sortField, sortOrder } = params;

  const { data, ...rest } = useQuery<IGetSequenceTemplatesResponse, AxiosError, TData>(
    ['get-sequence-templates', availability, category, search, page, perPage, sortField, sortOrder],
    () => getSequenceTemplates(params),
    {
      refetchOnMount: true,
      ...options,
    },
  );

  return { data, ...rest };
};
