import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import integrationsLogo from 'src/assets/img/integrations_logo.png';
import { Button } from 'src/components';
import { ModalTypes } from 'src/enums/modal.enum';
import { checkLinkedInIsConnectedSuccessfully } from 'src/helpers';
import { useCompanyTheme, useWhiteLabel } from 'src/hooks';
import {
  useCompleteUserWalkthrough,
  useGetFacebookData,
  useGetUserAccount,
  useGetUsersEmail,
  useUserTwitterDetails,
} from 'src/reactQueries';
import { openModal } from 'src/store';
import { TIntegrationTypes } from 'src/types';

const Container = styled(Box)`
  height: calc(100vh - 70px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageContainer = styled(Box)`
  margin-left: 100px;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: #4da34f;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translate(0, -50%);
`;

const Title = styled.h1`
  font-weight: bold;
  font-size: 45px;
  color: ${({ theme }) => theme.palette.primary.dark};
  line-height: 56px;

  @media screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 40px;
  }
`;

const Text = styled.p`
  color: #333;
`;

const StyledButton = styled(Button)`
  position: relative;
`;

const StyledLink = styled.span`
  color: #002f52;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.palette.info.main};
  }
`;

export const FirstIntegrations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shortCompanyName } = useCompanyTheme();
  const {
    whitelabel: { isAlfredDomain },
  } = useWhiteLabel();

  const { userMe } = useGetUserAccount();
  const { userEmail } = useGetUsersEmail({});
  const { data: facebookData } = useGetFacebookData({});
  const { data: twitterData } = useUserTwitterDetails({});
  const { completeUserWalkthrough } = useCompleteUserWalkthrough({
    onSuccess: () => {
      navigate('/campaign/stats');
    },
  });

  const isLinkedInConnected = checkLinkedInIsConnectedSuccessfully(userMe);
  const isEmailConnected = Boolean((userEmail && userEmail.gmailEnabled) || userEmail?.outlookEnabled);
  const isTwitterConnected = !!twitterData;
  const isFacebookConnected = Boolean(facebookData && facebookData.facebookData);

  const isIntegrationConnect = isFacebookConnected || isTwitterConnected || isEmailConnected || isLinkedInConnected;

  const onIntegrationClick = (integrationType: TIntegrationTypes) => {
    dispatch(
      openModal({
        type: ModalTypes.INTEGRATIONS,
        params: {
          integrationType,
        },
      }),
    );
  };

  const onConnectLinkedin = () => onIntegrationClick('linkedIn');
  const onConnectEmail = () => onIntegrationClick('email');
  const onConnectTwitter = () => onIntegrationClick('twitter');
  const onConnectFacebook = () => onIntegrationClick('facebook');

  const integrationButtons = [
    {
      name: 'Linkedin',
      isConnect: isLinkedInConnected,
      onClick: onConnectLinkedin,
    },
    {
      name: 'Email',
      isConnect: isEmailConnected,
      onClick: onConnectEmail,
    },
    {
      name: 'X (Twitter)',
      isConnect: isTwitterConnected,
      onClick: onConnectTwitter,
    },
    {
      name: 'Facebook / Instagram',
      isConnect: isFacebookConnected,
      onClick: onConnectFacebook,
    },
  ];

  if (userMe?.isWalkthroughCompleted || !isAlfredDomain) {
    return <Navigate to="/campaign/stats" />;
  }

  return (
    <Container>
      <Box display="flex" flexDirection="column" alignItems="center" width="400px">
        <Title>{shortCompanyName} Integrations</Title>
        <Box marginTop="2rem">
          <Text>Choose which platforms to automate</Text>
        </Box>
        <Box display="flex" flexDirection="column" width="100%" gap="30px" margin="30px 0px 26px">
          {integrationButtons.map(({ isConnect, name, onClick }) => (
            <StyledButton
              data-testid={name}
              key={name}
              disabled={isConnect}
              onClick={onClick}
              size={{ width: '100%', height: '47px' }}
            >
              {isConnect || 'Connect '}
              {name}
              {isConnect && ' Connected'}
              {isConnect && <StyledFontAwesomeIcon icon={faCircleCheck} />}
            </StyledButton>
          ))}
        </Box>
        <Box alignSelf="flex-end">
          <StyledLink onClick={() => completeUserWalkthrough()}>
            {isIntegrationConnect ? 'continue' : 'skip'}
          </StyledLink>
        </Box>
      </Box>
      <ImageContainer>
        <img src={integrationsLogo} height="490px" alt="logo" />
      </ImageContainer>
    </Container>
  );
};
