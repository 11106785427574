import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { CSVUpload, HeaderButton } from 'src/components';
import { CampaignType } from 'src/enums';
import { compareCsvSnippets } from 'src/helpers';
import { useCompanyTheme, useSingleCampaignProps } from 'src/hooks';
import { useCreateCampaignFromLinkedinCsv, useUpdateCampaignFromCsvUpload } from 'src/reactQueries';
import { showToast } from 'src/store/toast.slice';
import { transformCampaignRequestBody } from 'src/transformers';
import { ICSVUploadValue } from 'src/types';
import { CampaignsSteps } from '../../CampaignSteps';

export const LinkedinCsvUpload = ({
  isLinkedinOnly,
  isMultichannel = false,
}: {
  isLinkedinOnly: boolean;
  isMultichannel?: boolean;
}) => {
  const dispatch = useDispatch();
  const { shortCompanyName } = useCompanyTheme();
  const { isAddMoreLeads, campaignId, campaign, category } = useSingleCampaignProps();
  const [parsedCsv, setParsedCsv] = useState<ICSVUploadValue>({});
  const [exclude1stDegree, setExclude1stDegree] = useState(false);
  const [retargetLeads, setRetargetLeads] = useState(false);

  const { createCampaignFromLinkedinCsv, isCreateLoading } = useCreateCampaignFromLinkedinCsv();
  const { updateCampaignFromCsv, isLoading } = useUpdateCampaignFromCsvUpload({
    generalCampaignType: 'linked-in',
    campaignId,
    isAddMoreLeads,
  });
  const cancelHandler = () => {
    setParsedCsv({});
  };
  const isButtonsBlocked = (isAddMoreLeads && isLoading) || isCreateLoading;

  const submitHandler = () => {
    const headerValues = Object.entries(parsedCsv?.request?.header ?? {});
    const hasDuplicateNames = headerValues.find(
      (el, index, array) => array.findIndex((elem) => el[1]?.toLowerCase() === elem[1]?.toLowerCase()) !== index,
    );

    if (hasDuplicateNames?.length) {
      setParsedCsv((prevData) => ({ ...prevData, focusedField: hasDuplicateNames[0] }));

      return dispatch(showToast({ type: 'info', message: 'PLEASE USE DIFFERENT NAMES FOR EACH COLUMN' }));
    }
    if (campaign?.id && !compareCsvSnippets(campaign?.customSnippets, parsedCsv.request?.header)) {
      return dispatch(
        showToast({
          type: 'info',
          message: "Please use same snippets that you've used before",
        }),
      );
    }

    const body = transformCampaignRequestBody({
      category,
      campaignType: CampaignType.LINKEDIN_CSV_UPLOAD,
      ...parsedCsv.request,
      isLinkedinOnly: isLinkedinOnly,
      noFirstConnections: exclude1stDegree,
      retargetLeads,
    });

    if (campaign?.id) {
      updateCampaignFromCsv(body);
    } else {
      createCampaignFromLinkedinCsv(body);
    }
  };

  return (
    <>
      <CampaignsSteps>
        {!!parsedCsv.request && (
          <>
            <HeaderButton processing={isButtonsBlocked} disabled={isButtonsBlocked} onClick={submitHandler}>
              Upload
            </HeaderButton>
            <HeaderButton disabled={isButtonsBlocked} onClick={cancelHandler}>
              Cancel
            </HeaderButton>
          </>
        )}
      </CampaignsSteps>
      <Box padding="15px" pb="80px">
        <CSVUpload
          value={parsedCsv}
          label="Include a column with the lead's LinkedIn profile URL in the first column, optionally you can add multiple custom columns to use as snippets in your messages."
          status={`Pending uploaded leads (leads here will move below to "approved" once verified by ${shortCompanyName})`}
          type="linkedin"
          isRemoveButton
          customSnippets={campaign?.customSnippets}
          onUploaded={setParsedCsv}
          exclude1stDegree={exclude1stDegree}
          exclude1stDegreeChange={setExclude1stDegree}
          retargetLeads={retargetLeads}
          setRetargetLeads={setRetargetLeads}
          isMultichannel={isMultichannel}
        />
      </Box>
    </>
  );
};
