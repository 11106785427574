import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_PER_PAGE } from 'src/constants';
import { CampaignCategory } from 'src/enums';
import {
  IGetCampaignActivityRequest,
  IGetCampaignRepliesRequest,
  IPagination,
  ISorting,
  TCampaignsSortField,
  TCampaignsStatus,
} from 'src/types';

export type TStatTableParams = IPagination & ISorting<TCampaignsSortField>;
type TStatsTabs = Exclude<TCampaignsStatus, 'all'>;
export type TRepliesParams = IGetCampaignRepliesRequest & { entityUrns: string[] };

interface IInitialState {
  selectedLeads: {
    campaignId: number;
    entityUrns: string[];
  };
  selectedIgnoredLeads: {
    campaignId: number;
    entityUrns: string[];
  };
  statsParams: Record<CampaignCategory, Record<TStatsTabs, TStatTableParams>>;
  activityParams: IGetCampaignActivityRequest;
  repliesParams: TRepliesParams;
}

const statPageParams = Object.values(CampaignCategory).reduce<
  Record<CampaignCategory, Record<TStatsTabs, TStatTableParams>>
>(
  (gen, curr) => {
    gen[curr] = {
      active: { page: 1, perPage: DEFAULT_PER_PAGE, sortField: 'createdAt', sortOrder: 'DESC' },
      paused: { page: 1, perPage: DEFAULT_PER_PAGE, sortField: 'createdAt', sortOrder: 'DESC' },
      draft: { page: 1, perPage: DEFAULT_PER_PAGE, sortField: 'createdAt', sortOrder: 'DESC' },
      archived: { page: 1, perPage: DEFAULT_PER_PAGE, sortField: 'createdAt', sortOrder: 'DESC' },
    };

    return gen;
  },
  {} as Record<CampaignCategory, Record<TStatsTabs, TStatTableParams>>,
);

const initialState: IInitialState = {
  selectedLeads: {
    campaignId: 0,
    entityUrns: [],
  },
  selectedIgnoredLeads: {
    campaignId: 0,
    entityUrns: [],
  },
  statsParams: statPageParams,
  repliesParams: {
    page: 1,
    perPage: DEFAULT_PER_PAGE,
    sortField: 'date',
    sortOrder: 'DESC',
    entityUrns: [],
  },
  activityParams: {
    page: 1,
    perPage: DEFAULT_PER_PAGE,
    sortField: 'date',
    sortOrder: 'DESC',
  },
};

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    updateSelectedLeads: (state, action: PayloadAction<IInitialState['selectedLeads']>) => {
      state.selectedLeads = { ...state.selectedLeads, ...(action.payload ?? {}) };
    },
    updateIgnoredSelectedLeads: (state, action: PayloadAction<IInitialState['selectedLeads']>) => {
      state.selectedIgnoredLeads = { ...state.selectedIgnoredLeads, ...(action.payload ?? {}) };
    },
    removeIgnoredSelectedLead: (state, action) => {
      const updatedIgnoredLeads = state.selectedIgnoredLeads.entityUrns.filter(
        (lead) => !action.payload.includes(lead),
      );
      state.selectedIgnoredLeads.entityUrns = [...updatedIgnoredLeads];
    },
    clearStatTableParams: (state) => {
      state.statsParams = initialState.statsParams;
    },
    updateStatTableParams: (
      state,
      action: PayloadAction<{
        category: CampaignCategory;
        status: TCampaignsStatus;
        params: Partial<TStatTableParams>;
      }>,
    ) => {
      const { params, category, status } = action.payload || {};

      state.statsParams[category][status] = { ...state.statsParams[category][status], ...params };
    },
    clearActivityParams: (state) => {
      state.activityParams = initialState.activityParams;
    },
    updateActivityParams: (state, action: PayloadAction<Partial<IGetCampaignActivityRequest>>) => {
      state.activityParams = { ...state.activityParams, ...(action.payload ?? {}) };

      if (action.payload.names || action.payload.campaigns || action.payload.filters) {
        state.activityParams.page = 1;
      }
    },
    clearRepliesParams: (state) => {
      state.repliesParams = initialState.repliesParams;
    },
    updateRepliesParams: (state, action: PayloadAction<Partial<TRepliesParams>>) => {
      state.repliesParams = { ...state.repliesParams, ...(action.payload ?? {}) };
    },
  },
});

export const {
  updateSelectedLeads,
  updateStatTableParams,
  clearStatTableParams,
  clearActivityParams,
  updateActivityParams,
  clearRepliesParams,
  updateRepliesParams,
  updateIgnoredSelectedLeads,
  removeIgnoredSelectedLead,
} = campaignSlice.actions;

export default campaignSlice.reducer;
