import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { ReactComponent as CheckCircleIcon } from 'src/assets/icons/check-circle.svg';
import { Button, Divider, Link, Typography } from 'src/components';
import { BILLING_TYPE_NAMES, PLAN_INFO } from 'src/constants';
import { BillingTypeEnum, CurrencyEnum, Plan } from 'src/enums';
import { checkIfPlanIsDowngrade } from 'src/helpers';
import { useTeamPlan } from 'src/hooks';
import { useGetBillingInfo } from 'src/reactQueries';
import { openModal } from 'src/store';
import { PlanPrice } from '../_components';

interface SubscriptionPlanCardProps {
  plan: Plan;
  billingType: BillingTypeEnum;
  currency: CurrencyEnum;
}

const Card = styled(Box)`
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  background-color: #ffffff;
`;

const CardHeader = styled(Box)`
  display: flex;
  height: 76px;
  padding: 20px;
  flex-direction: column;
  justify-content: flex-end;
`;

const CardBody = styled(Box)`
  padding: 20px 20px 40px 20px;
`;

export const SubscriptionPlanCard = ({ plan, billingType, currency }: SubscriptionPlanCardProps) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isTrial, isDeactivated } = useTeamPlan();

  const { billingInfo, isSubscriptionCanceled } = useGetBillingInfo();

  const { features, description, price, priceDescription } = useMemo(() => {
    const { features = [], description, price, priceDescription } = PLAN_INFO[plan] || {};

    return {
      features,
      description,
      priceDescription,
      price: (price?.[currency]?.[billingType] || 0) * 100,
    };
  }, [plan, billingType, currency, billingInfo]);

  const { isChosen, buttonText, isButtonDisabled } = useMemo(() => {
    const props = {
      isChosen: plan === billingInfo?.plan,
      buttonText: isTrial || isDeactivated ? 'Choose Plan' : 'Change Plan',
      isButtonDisabled: false,
    };

    if (plan === billingInfo?.plan) {
      props.buttonText = billingType === billingInfo?.billingType ? 'Your current plan' : `Switch to ${billingType}`;
      props.isButtonDisabled = billingType === billingInfo?.billingType;
    }

    return props;
  }, [plan, billingType, billingInfo, isTrial]);

  const onCardButtonClick = () => {
    const props = isTrial
      ? { url: '/billing/subscription/new', state: { plan, billingType, currency } }
      : { url: '/billing/subscription/edit', state: { plan, billingType } };

    if (isDeactivated) {
      props.url = '/deactivated/subscription';
      props.state = { plan, billingType, currency };
    }

    const { url, state } = props;
    if (checkIfPlanIsDowngrade(plan, billingInfo?.plan as Plan)) {
      return dispatch(
        openModal({
          headerText: 'Are you sure you want to change your plan?',
          descriptionText: 'Downgrading your account will limit your access to exclusive features and integrations.',
          cancelButtonLabel: 'Stay on Current Plan',
          confirmButtonLabel: 'Yes, Change Plan',
          confirmButtonVariant: 'warning',
          onConfirm: () => navigate(url, { state }),
        }),
      );
    }

    navigate(url, { state });
  };

  return (
    <Card border={`1px solid ${isChosen || isTrial ? palette.accent[500] : palette.gray[200]}`}>
      <CardHeader bgcolor={isChosen ? `${palette.brand[100]}80` : `${palette.brand[100]}40`}>
        <Typography fontSize="30px" color="black.base" lineHeight="32px" semibold capitalizeFirstLetter>
          {plan}
        </Typography>

        <Typography fontSize="15px" color="gray.800" mt="12px">
          {description}
        </Typography>
      </CardHeader>

      <CardBody>
        {price ? (
          <PlanPrice price={price} currency={currency} size="20px" />
        ) : (
          <Typography fontSize="20px" color="gray.800" semibold component="span">
            NONE PROVIDED
          </Typography>
        )}

        <Typography color="gray.500" fontSize="15px" mt="12px" mb="24px">
          {price
            ? `${priceDescription}, billed ${BILLING_TYPE_NAMES[billingType]?.toLowerCase()}`
            : 'For this billing cycle'}
        </Typography>

        <Button
          disabled={isButtonDisabled || !price || isSubscriptionCanceled}
          size={{ width: '100%' }}
          onClick={onCardButtonClick}
          tooltip={{
            title:
              isSubscriptionCanceled &&
              'Please resume your subscription on the Subscription Plan page if you want to change your plan',
          }}
        >
          {buttonText}
        </Button>

        <Divider my="24px" />

        <Typography fontSize="15px" color="gray.500">
          Included features:
        </Typography>

        <Box mb="24px">
          {features.map((feature) => (
            <Box key={feature} display="flex" gap="8px" mt="12px" color="success.500">
              <CheckCircleIcon />
              <Typography component="span" fontSize="15px" color="gray.800">
                {feature}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box position="absolute" bottom="20px">
          <Link
            leftIcon
            to="https://meetalfred.com/pricing#features"
            openNewTab
            external
            underline
            underlineColor={palette.brand[200]}
            pb="1px"
          >
            View All Plan Features
          </Link>
        </Box>
      </CardBody>
    </Card>
  );
};
