import { Box, styled } from '@mui/material';

import { Typography } from './Typography';

interface ProgressBarProps {
  progress?: number;
  showInside?: boolean;
  bgColor?: string;
  height?: string;
}

const Progress = styled(Box)`
  width: 100%;
  background-color: rgb(245, 245, 245);
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 2px inset;
  overflow: hidden;
  border-radius: 4px;
`;

export const ProgressBar = ({
  progress,
  showInside = false,
  bgColor = '#91d438',
  height = '20px',
}: ProgressBarProps) => {
  return (
    <Progress height={height}>
      <Box
        width={`${progress}%`}
        height="100%"
        bgcolor={bgColor}
        role="progressbar"
        aria-valuenow={progress}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        {showInside && (
          <Typography fontSize="12px" lineHeight="20px" color="#fff" textAlign="center">
            {progress} %
          </Typography>
        )}
      </Box>
    </Progress>
  );
};
