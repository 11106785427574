import './styles.scss';
import styled from '@emotion/styled';
import { Box, MenuItem, Select as SelectMui } from '@mui/material';

import { Tooltip, Typography } from 'src/components';
import { PLAN_TOOLTIPS, TEMPLATE_SNIPPET_OPTIONS } from 'src/constants';
import { Features } from 'src/enums';
import { capitalizeFirstLetter } from 'src/helpers';
import { useTeamPlan } from 'src/hooks';
import { ISelectOption } from 'src/types';

const Select = styled(SelectMui)`
  position: absolute;
  visibility: hidden;
`;

const SelectMenuItem = styled(MenuItem)`
  background-color: #fff !important;
  color: ${({ theme }) => theme.palette.gray.dark};
  font-size: 14px;
  font-family: 'ProximaSoft', sans-serif;
  line-height: 30px;
  padding: 3px 0 !important;

  &:hover {
    color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export const SelectCampaignSnippet = ({
  onCloseSelect,
  onOpenSelect,
  isSelectOpen,
  onSnippetClick,
  snippetOptions,
  showLinkedinSnippet,
}: {
  onOpenSelect: () => void;
  onCloseSelect: () => void;
  isSelectOpen: boolean;
  showLinkedinSnippet?: boolean;
  onSnippetClick: (value: string) => void;
  snippetOptions?: ISelectOption[];
}) => {
  const { checkFeature } = useTeamPlan();
  const isCSVSnippetsAllowed = checkFeature(Features.CSVSnippets);

  const onOptionClick = (value: string) => {
    onCloseSelect();
    onSnippetClick(value);
  };

  return (
    <Select
      open={isSelectOpen}
      onClose={onCloseSelect}
      onOpen={onOpenSelect}
      MenuProps={{
        classes: {
          paper: 'select-snippet_paper',
        },
        PaperProps: {
          style: {
            maxHeight: '400px',
            overflowY: 'auto',
          },
        },
      }}
    >
      <Box display="flex" flexDirection="column" gap="16px">
        {showLinkedinSnippet && (
          <Box>
            <Typography color="#869a9d" fontSize="10px" lineHeight="12px" marginBottom="4px">
              From LinkedIn
            </Typography>
            {TEMPLATE_SNIPPET_OPTIONS.map((snippet) => {
              return (
                <SelectMenuItem onClick={() => onOptionClick(snippet.value)} key={snippet.value} value={snippet.value}>
                  {snippet.label}
                </SelectMenuItem>
              );
            })}
          </Box>
        )}
        {!!snippetOptions?.length && (
          <Box>
            <Typography color="#869a9d" fontSize="10px" lineHeight="12px" marginBottom="4px">
              From CSV
            </Typography>
            <Tooltip arrow placement="top" followCursor title={!isCSVSnippetsAllowed ? PLAN_TOOLTIPS.professional : ''}>
              <div>
                {snippetOptions.map((snippet) => {
                  return (
                    <SelectMenuItem
                      onClick={() => onOptionClick(snippet.value)}
                      key={snippet.value}
                      value={snippet.value}
                      disabled={!isCSVSnippetsAllowed}
                    >
                      {capitalizeFirstLetter(snippet.label)}
                    </SelectMenuItem>
                  );
                })}
              </div>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Select>
  );
};
