import { useQuery, UseQueryOptions } from 'react-query';

import { getInvoiceDetails } from 'src/api';
import { BillingTypeEnum, CurrencyEnum, Plan } from 'src/enums';
import { ICustomAxiosError, IStripeInvoice } from 'src/types';

interface IUseGetInvoiceDetailsParams {
  plan: Plan;
  seats: number;
  billingType: BillingTypeEnum;
  currency: CurrencyEnum;
}

export const useGetInvoiceDetails = (
  { plan, seats, billingType, currency }: IUseGetInvoiceDetailsParams,
  options?: UseQueryOptions<IStripeInvoice, ICustomAxiosError>,
) => {
  const { data, ...rest } = useQuery<IStripeInvoice, ICustomAxiosError>(
    ['get-invoice-details', plan, seats, billingType, currency],
    () => getInvoiceDetails({ plan, seats, billingType, currency }),
    { ...options, enabled: !!plan && !!seats && !!billingType && !!currency && !!options?.enabled },
  );

  return { invoiceDetails: data, ...rest };
};
