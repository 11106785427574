import { UseFormReturn } from 'react-hook-form';
import { Box } from '@mui/material';

import { ISequence } from 'src/types';
import { SequenceForm } from '../SequenceForm/SequenceForm';
import { Tags } from '../Tags';

interface ISendEventMessage extends UseFormReturn<ISequence> {
  index: number;
}

export const SendEventMessage = ({ index, ...sequenceForm }: ISendEventMessage) => {
  const { watch, setValue } = sequenceForm;

  return (
    <>
      <Box mb="20px">
        <SequenceForm
          title="Message"
          watchField="message"
          description={`Messages will only be sent to Event members.\nIf the lead cannot receive the message, it will be skipped.`}
          index={index}
          config={{ textMaxLength: 8000 }}
          registerOptions={{
            validate: (value) => !!value.trim(),
          }}
          {...sequenceForm}
        />
      </Box>
      <Tags index={index} watch={watch} setValue={setValue} />
    </>
  );
};
