import { Dispatch, SetStateAction, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import styled from '@emotion/styled';

import microsoftIcon from 'src/assets/icons/microsoft-icon.svg';
import { useGetMicrosoftCodeUrl, useMicrosoftAuth } from 'src/reactQueries';
import { IAuthResponse, IMicrosoftSignUpRequest } from 'src/types';
import { Button } from '.';

const StyledButton = styled(Button)`
  background: #00a1f1;
  font-size: 0.9em;
  font-weight: 600;
  position: relative;
  width: 100%;
  height: 42px;
  border: none;
`;

const StyledImage = styled.img`
  padding: 8px;
  border-radius: 0.3em 0 0 0.3em;
  background-color: #ffffff;
  position: absolute;
  left: 2px;
  top: 2px;
`;

interface IMicrosoftButton {
  redirectTo: string;
  isSignup?: boolean;
  onSuccess?: (data: IAuthResponse) => void;
  setError: Dispatch<SetStateAction<number | null>>;
}

const cookies = new Cookies();

export const MicrosoftButton = ({ redirectTo, setError, onSuccess, isSignup = false }: IMicrosoftButton) => {
  const { state } = useLocation();
  const locationState = state as IMicrosoftSignUpRequest;

  const { getMicrosoftCodeUrl, isLoading } = useGetMicrosoftCodeUrl(redirectTo);
  const { isLoading: isLoadingAuth, error } = useMicrosoftAuth(
    {
      ...locationState,
      code: locationState?.code ?? '',
      referralCode: cookies.get('referralCode'),
    },
    isSignup,
    { onSuccess },
  );

  const clickHandler = () => {
    getMicrosoftCodeUrl();
  };

  useEffect(() => {
    if (error?.statusCode) {
      setError(error.statusCode);
    }
  }, [error, setError]);

  return (
    <StyledButton disabled={isLoading || isLoadingAuth} onClick={clickHandler}>
      <StyledImage src={microsoftIcon} alt="microsoft icon" />
      {isSignup ? 'Sign up' : 'Login'} with Microsoft
    </StyledButton>
  );
};
