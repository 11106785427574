import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateCompanyInfo } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { IBillingInfo, ICompany, ICustomAxiosError } from 'src/types';

export const useUpdateCompanyInfo = (options?: UseMutationOptions<IBillingInfo, ICustomAxiosError, ICompany>) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation(['update-company-info'], updateCompanyInfo, {
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries(['get-billing-info']);

      dispatch(
        showToast({
          type: 'info',
          message: 'Invoice information updated',
          autoCloseTime: 3000,
        }),
      );

      options?.onSuccess?.(data, variables, context);
    },
  });

  return { updateCompany: mutate, ...rest };
};
