import * as _ from 'lodash';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { HeaderButton, Spinner, Tooltip, Typography } from 'src/components';
import { CampaignSearchListType, CampaignType } from 'src/enums';
import { resolveSNSearchUrl } from 'src/helpers';
import { useCompanyTheme, useLocationState, useSingleCampaignProps } from 'src/hooks';
import {
  useAddMoreLeadsCampaign,
  useCreateCampaignAudience,
  useGetCampaign,
  useGetSNSavedSearch,
  useRefreshSNSavedSearch,
  useUpdateCampaignAudience,
} from 'src/reactQueries';
import { transformCampaignRequestBody } from 'src/transformers';
import { ICampaignAudienceFormFields, ILocationStateSingleCampaign } from 'src/types';
import { ControlledCheckbox, ControlledSingleSelect, ControlledSlider, RefreshButton } from '../../_components';
import { CampaignsSteps } from '../../CampaignSteps';

export const LinkedinSalesNavigator = ({ isLinkedinOnly }: { isLinkedinOnly: boolean }) => {
  const { control, reset, watch, setValue, handleSubmit } = useForm<ICampaignAudienceFormFields>();

  const { shortCompanyName } = useCompanyTheme();
  const { initialCampaignId } = useLocationState<ILocationStateSingleCampaign>();
  const { isAddMoreLeads, isEdit, isCampaignLoading, campaignId, campaign, category } = useSingleCampaignProps();
  const { campaign: initialCampaign } = useGetCampaign(initialCampaignId, {
    enabled: !!campaignId && !!initialCampaignId && !isAddMoreLeads,
  });

  const { data, isLoading } = useGetSNSavedSearch({
    select: (resp) => resp.map((item) => ({ value: item.savedSearchId, label: item.name, type: item.listType })),
  });
  const selectedOption = data?.find(({ value }) => value === watch('searchUrl'));
  const isCheckbox = selectedOption?.type !== CampaignSearchListType.LEAD_LIST;
  const searchUrl = watch('searchUrl');

  const { addMoreLeads, isAddMoreLeadsLoading } = useAddMoreLeadsCampaign(campaignId);
  const { createCampaignAudience, isCreateLoading } = useCreateCampaignAudience();
  const { updateCampaignAudience, isUpdateLoading } = useUpdateCampaignAudience(campaignId);

  const { refreshSNSavedSearch, isLoading: isRefreshing } = useRefreshSNSavedSearch();

  useEffect(() => {
    setValue('excludeNoPhotos', false);
    setValue('openLinkOnly', false);
    setValue('noFirstConnections', false);
    setValue('premiumOnly', false);
  }, [searchUrl]);

  useEffect(() => {
    // eslint-disable-next-line no-undefined
    const nonAddLeadsCampaign = isAddMoreLeads ? undefined : campaign;
    const tempCampaign = initialCampaignId ? initialCampaign : nonAddLeadsCampaign;

    if (tempCampaign) {
      const index = tempCampaign.salesNavigatorUrl?.indexOf('savedSearchId=');

      reset({
        excludeNoPhotos: tempCampaign.excludeNoPhotos,
        maxSearchAmount: tempCampaign.maxSearchAmount,
        noFirstConnections: tempCampaign.noFirstConnections,
        openLinkOnly: tempCampaign.openLinkOnly,
        premiumOnly: tempCampaign.premiumOnly,
        ...(index > 0 && { searchUrl: tempCampaign.salesNavigatorUrl.slice(index + 14) }),
      });
    }
  }, [initialCampaignId, campaign, initialCampaign]);

  const submit = (values: ICampaignAudienceFormFields) => {
    const body = transformCampaignRequestBody({
      ...values,
      category,
      campaignType: CampaignType.LINKEDIN_SN_SAVED_SEARCH,
      includeMessageOnly: true,
      isLinkedinOnly: isLinkedinOnly,
      searchUrl: selectedOption ? resolveSNSearchUrl(selectedOption) : '',
    });

    if (isAddMoreLeads) {
      return addMoreLeads({
        ...body,
        searchType: CampaignType.LINKEDIN_SN_SAVED_SEARCH,
        campaignId,
      });
    }

    if (isEdit) {
      return updateCampaignAudience(body);
    }

    return createCampaignAudience(body);
  };

  const refreshHandler = () => {
    refreshSNSavedSearch();
  };

  const typeToLabel: Record<string, string> = {
    saved_leads_search: 'Saved leads searches',
    lead_list: 'Lead list',
  };

  const grouped = _.groupBy(data, 'type');
  const groupedData = Object.keys(grouped).map((key) => ({
    label: typeToLabel[key],
    options: grouped[key],
  }));

  return (
    <>
      <CampaignsSteps>
        <HeaderButton
          size={{ width: '180px' }}
          processing={isAddMoreLeadsLoading || isCreateLoading || isUpdateLoading}
          disabled={!searchUrl || isAddMoreLeadsLoading}
          onClick={handleSubmit(submit)}
        >
          Next
        </HeaderButton>
      </CampaignsSteps>
      {(isLoading || isCampaignLoading) && <Spinner type="dots" />}
      {!!data?.length && !isCampaignLoading && (
        <Box padding="30px 15px" width="100%" maxWidth="900px">
          <form>
            <Box display="flex" alignItems="flex-end">
              <ControlledSingleSelect
                name="searchUrl"
                options={groupedData}
                label="Select from Saved Searches."
                placeholder="Select Sales Navigator Saved Search"
                control={control}
              />
              <Tooltip title="Refresh saved searches">
                <Box mb="12px">
                  <RefreshButton variant="icon" onClick={refreshHandler} processing={isRefreshing} />
                </Box>
              </Tooltip>
            </Box>

            <ControlledSlider name="maxSearchAmount" label="Maximum amount of leads:" control={control} />

            <ControlledCheckbox name="excludeNoPhotos" label="Exclude profiles without photos." control={control} />
            <ControlledCheckbox name="noFirstConnections" label="Exclude 1st degree connections." control={control} />
            {isCheckbox && <ControlledCheckbox name="premiumOnly" label="Premium users only." control={control} />}
            {isCheckbox && (
              <ControlledCheckbox name="openLinkOnly" label="Open Link Profiles only." control={control} />
            )}
            <ControlledCheckbox name="retargetLeads" label="Include leads from other campaigns." control={control} />
          </form>
        </Box>
      )}

      {!isLoading && !data?.length && (
        <Box mt="40px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Box display="flex" alignItems="center" mb="20px">
            <Typography mr="5px" color="#262A70">
              No Saved Searches detected.
            </Typography>
            <Tooltip
              title={
                <Box textAlign="left">
                  This is because
                  <br />
                  <br />
                  1. You don't have Sales Navigator.
                  <br />
                  2. You haven't saved any searches.
                  <br />
                  3. {shortCompanyName} bot has not synced with Sales Navigator.
                </Box>
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} size="sm" color="red" />
            </Tooltip>
          </Box>
          <RefreshButton
            variant="button"
            onClick={refreshHandler}
            processing={isRefreshing}
            label="Refresh saved searches"
          />
        </Box>
      )}
    </>
  );
};
