import { useDispatch } from 'react-redux';
import { useIntercom } from 'react-use-intercom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button, Typography } from 'src/components';
import { ModalTypes } from 'src/enums';
import { useCompanyTheme } from 'src/hooks';
import { useCloseCancelModalTrigger, useGetBillingInfo, useGetIntercomInitialData } from 'src/reactQueries';
import { openModal } from 'src/store';

const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;

  padding: 20px;

  border: 1px solid ${({ theme }) => theme.palette.gray[100]};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.error[100]}80;
`;

export const CancelSubscription = () => {
  const dispatch = useDispatch();

  const { shortCompanyName } = useCompanyTheme();

  const { isSubscriptionCanceled } = useGetBillingInfo();

  const { closeCancelModalTrigger } = useCloseCancelModalTrigger();

  const { update: updateIntercomData } = useIntercom();
  const { data: intercomData } = useGetIntercomInitialData();

  const deleteAccount = () => {
    dispatch(
      openModal({
        isActionsShown: false,
        headerText: `Remove your account from ${shortCompanyName}`,
        descriptionText: 'To remove your account, please contact support on the chat',
      }),
    );
  };

  const cancelSubscription = () => {
    dispatch(
      openModal({
        type: ModalTypes.CANCEL_SUBSCRIPTION,
        closable: false,
        size: 'large',
        onCancel: () => {
          updateIntercomData({
            userHash: intercomData?.intercomHash,
            customAttributes: {
              closedCancellationView: true,
            },
          });
          closeCancelModalTrigger('close cancel view');
        },
      }),
    );
  };

  return (
    <Wrapper>
      <Typography semibold color="gray.700" mr="auto">
        Permanently delete your account and all associated data, or stop future billing.
      </Typography>

      <Button
        variant="gray"
        onClick={cancelSubscription}
        disabled={isSubscriptionCanceled}
        tooltip={{ title: isSubscriptionCanceled && 'Your Subscription is Canceled' }}
      >
        Cancel Subscription
      </Button>

      <Button variant="warning" onClick={deleteAccount}>
        Delete account
      </Button>
    </Wrapper>
  );
};
