import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';

import { ReactComponent as CreditCard } from 'src/assets/icons/credit-card.svg';
import { ReactComponent as EditPencilIcon } from 'src/assets/icons/edit-pencil-icon.svg';
import { Button, Divider, Spinner, Typography } from 'src/components';
import { ModalTypes } from 'src/enums';
import { useGetBillingInfo } from 'src/reactQueries';
import { openModal } from 'src/store';

interface PaymentMethodProps {
  isProfileText?: boolean;
  isTransparentButton?: boolean;
  withBorder?: boolean;
  fullWidth?: boolean;
}

export const PaymentMethod = ({
  isProfileText = false,
  fullWidth = false,
  withBorder = false,
  isTransparentButton = false,
}: PaymentMethodProps) => {
  const { palette } = useTheme();

  const dispatch = useDispatch();

  const { billingInfo, isLoading } = useGetBillingInfo();

  const openUpdateCreditCard = () => {
    dispatch(openModal({ closable: false, type: ModalTypes.UPDATE_CREDIT_CARD }));
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box
      p="20px"
      width={fullWidth ? '100%' : '50%'}
      bgcolor="#ffffff"
      borderRadius="4px"
      boxSizing="border-box"
      border={withBorder ? `1px solid ${palette.gray[100]}` : 'none'}
    >
      <Box display="flex" justifyContent="space-between">
        <Box mb="20px">
          <Typography color="gray.800" fontSize="22px" semibold mb="8px">
            Payment Method
          </Typography>
          <Typography color="gray.800" fontSize="15px">
            Change your credit card here
          </Typography>
        </Box>

        <Button onClick={openUpdateCreditCard} variant={isTransparentButton ? 'transparent' : 'primary'}>
          <Box display="flex" alignItems="flex-end" gap="4px">
            {isTransparentButton && <EditPencilIcon />}

            <Box component="span" lineHeight="14px">
              Update Credit Card
            </Box>
          </Box>
        </Button>
      </Box>

      <Divider />

      <Box display="flex" alignItems="center" gap="12px" mt="20px" mb="24px">
        <CreditCard />
        <Typography component="span" color="gray.800" fontSize="16px" medium>
          •••• •••• •••• {billingInfo?.stripeTokenObject?.card?.last4}
        </Typography>
      </Box>

      {isProfileText ? (
        <>
          <Typography fontSize="15px" color="gray.500">
            *The card on file will be used for future payments.
          </Typography>
          <Typography fontSize="15px" color="gray.500">
            *Feel free to update your payment details at any time on this page.
          </Typography>
        </>
      ) : (
        <>
          <Typography fontSize="15px" color="gray.500">
            *Your credit card will be charged immediately.
          </Typography>
          <Typography fontSize="15px" color="gray.500">
            *Unused time from your current billing cycle will be credited for future invoices.
          </Typography>
        </>
      )}
    </Box>
  );
};
