import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Checkbox } from 'src/components';
import { Typography } from 'src/components/ui/Typography';
import { IFacebookPage } from 'src/types';
import { PageCategory, PageDetails, PageItem, PageName, StyledButton } from '../ui';

const InstagramAccountContainer = styled(Box)`
  width: 100%;
  margin-top: 10px;
  border-radius: 6px;
  border: 1px solid rgba(224, 224, 224, 0.36);
  background: linear-gradient(145deg, rgba(196, 0, 146, 0.2) 0%, rgba(228, 0, 49, 0.2) 100%);
  display: grid;
  grid-template-columns: 25px 60px 1fr;
`;

const AvatarContainer = styled.div`
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  width: 40px;
  height: 40px;
  margin: 6px 10px;
`;

const InstagramProfileCounter = styled.span`
  display: inline-flex;
  flex: 1;
  justify-content: space-between;
`;

const InstagramCheckboxContainer = styled.div`
  float: left;
  margin-top: 17px;
`;

interface IFacebookPageProps {
  page: IFacebookPage;
  checkedPages: string[];
  checkedInstagramPages: string[];
  onCheckPage: () => void;
  onGetInstagramDetails: () => void;
  toggleInstagramSelect: () => void;
}

export const FacebookPage = ({
  page,
  checkedPages,
  onCheckPage,
  onGetInstagramDetails,
  checkedInstagramPages,
  toggleInstagramSelect,
}: IFacebookPageProps) => {
  return (
    <PageItem>
      <Box display="flex" alignItems="center">
        <Box marginRight="15px">
          <Checkbox key="checked" type="checkbox" checked={checkedPages.indexOf(page.id) > -1} onChange={onCheckPage} />
        </Box>
        <PageDetails>
          <PageName>{page.name}</PageName>
          <PageCategory>{page.category}</PageCategory>
        </PageDetails>
        <div>
          {page.ig && page.ig.id && (
            <StyledButton
              variant="gray"
              title="Click to display Instagram account connected to this page."
              onClick={onGetInstagramDetails}
            >
              Get Instagram Details
            </StyledButton>
          )}
        </div>
      </Box>
      <div>
        {page.ig && page.ig.id && page.ig.username && (
          <>
            <InstagramAccountContainer>
              <InstagramCheckboxContainer>
                <Box mr="5px">
                  <Checkbox
                    checked={checkedInstagramPages.indexOf(page.ig.id) > -1}
                    key="checked"
                    onChange={toggleInstagramSelect}
                    type="checkbox"
                  />
                </Box>
              </InstagramCheckboxContainer>
              <AvatarContainer>
                <img src={page.ig.profile_pic || ''} alt="Facebook profile image" width="40" />
              </AvatarContainer>

              <Box display="block" mx="10px" mt="5px">
                <a href={`https://www.instagram.com/${page.ig.username}`} target="_blank" rel="noopener noreferrer">
                  <Typography color="primary.wlLight" fontSize="15px" semibold>
                    {page.ig.username}
                  </Typography>
                </a>
                <Box display="flex">
                  <InstagramProfileCounter>{page.ig.media_count || 0} posts</InstagramProfileCounter>
                  <InstagramProfileCounter>{page.ig.followers_count || 0} followers</InstagramProfileCounter>
                  <InstagramProfileCounter>{page.ig.follows_count || 0} following</InstagramProfileCounter>
                </Box>
              </Box>
            </InstagramAccountContainer>
          </>
        )}
      </div>
    </PageItem>
  );
};
