import { createFormData } from 'src/helpers';
import { IGetBrandingReq, IUpdateBrandingReq, IUpdateSMTPSettingsReq, IWhiteLabel } from 'src/types';
import { appApi } from './axios';

export const getWhiteLabel = (): Promise<IWhiteLabel> => appApi.get(`/whitelabels`);

export const getWhiteLabelBranding = (params: IGetBrandingReq): Promise<IWhiteLabel> =>
  appApi.get(`/whitelabels/branding`, { params });

export const updateWhiteLabelBranding = (data: IUpdateBrandingReq): Promise<IWhiteLabel> =>
  appApi.patch(`/whitelabels/branding`, createFormData(data), {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

export const toggleWhiteLabelStatus = (): Promise<IWhiteLabel> => appApi.patch(`/whitelabels/toggle-status`);

export const updateWhiteLabelSMTPSettings = (data: IUpdateSMTPSettingsReq): Promise<IWhiteLabel> =>
  appApi.put(`/whitelabels/smtp-settings`, data);

export const createWhiteLabelDomain = (domain: string): Promise<IWhiteLabel> =>
  appApi.patch(`/whitelabels/domain`, { domain });

export const deleteWhiteLabelSMTP = (): Promise<void> => appApi.delete(`/whitelabels/smtp-settings`);

export const deleteWhiteLabelDomain = (): Promise<void> => appApi.delete(`/whitelabels/domain`);

export const verifyDns = (): Promise<void> => appApi.patch(`/whitelabels/domain/verify-dns`);
