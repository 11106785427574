import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { updateWorkHoursTimezone } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError } from 'src/types';

export const useUpdateDailyLimitsTimezone = (options?: UseMutationOptions<void, ICustomAxiosError, string>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useMutation(
    ['update-user-daily-limits'],
    (timezone: string) => updateWorkHoursTimezone(timezone),
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        await queryClient.invalidateQueries(['get-user-profile']);
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { updateDailyLimitsTimezone: mutate, ...rest };
};
