import { useEffect, useMemo, useRef, useState } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form';
import styled from '@emotion/styled';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box } from '@mui/material';

import { HiddenFileInput, TextArea, Tooltip, Typography } from 'src/components';
import { SelectSnippet } from 'src/components/Select';
import { Attachment } from 'src/components/ui/Attachment';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features } from 'src/enums';
import { separateNumWithComma } from 'src/helpers';
import { useTeamPlan } from 'src/hooks';
import { IAttachment } from 'src/types';
import { ICreateTemplateForm } from './TemplateCreator';

const StyledTextArea = styled(TextArea)`
  max-height: 500px;
  min-height: 70px;
  resize: vertical;
  line-height: 19px;
  padding: 5px 10px;
  color: #333;
  margin-bottom: 10px;
`;

const TooltipText = styled.p`
  text-align: center;
`;

const AttachmentBox = styled(Box)`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
  background-color: ${({ theme }) => theme.palette.light.light};
  cursor: pointer;
  border-radius: 4px 4px 0 0;
  margin-left: 5px;
  font-size: 14px;

  &:hover {
    color: #fff;
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
`;

export interface ITextAreaWithSnippetProps {
  attachedFiles: File[];
  attachments: IAttachment[];
  label: string;
  maxLength?: number;
  name: keyof ICreateTemplateForm;
  onFileAttach: (files: FileList | null | undefined) => void;
  onFileDelete: (name: string) => void;
  onAttachmentDelete: (name: string) => void;
  register: UseFormRegister<ICreateTemplateForm>;
  required?: boolean | string;
  setValue: UseFormSetValue<ICreateTemplateForm>;
  value: string;
}

export const TextAreaWithSnippet = ({
  attachedFiles,
  attachments,
  label,
  maxLength,
  name,
  onFileAttach,
  onFileDelete,
  onAttachmentDelete,
  register,
  setValue,
  required,
  value,
}: ITextAreaWithSnippetProps) => {
  const caretPosition = useRef(value.length);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [touched, setTouched] = useState(false);
  const [buffValue, setBuffValue] = useState(value);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const { checkFeature } = useTeamPlan();
  const isAddingAttachmentAllowed = checkFeature(Features.templatesAttachments);

  const numLetterShown = !!maxLength;
  const numLettersLeft = (maxLength ?? 0) - (touched ? value.length : 0);

  const onOpenSelect = () => setIsSelectOpen(true);
  const onCloseSelect = () => setIsSelectOpen(false);

  const onAttachmentClick = () => isAddingAttachmentAllowed && fileInputRef.current?.click();
  const onAttachmentChange = () => isAddingAttachmentAllowed && onFileAttach(fileInputRef?.current?.files);

  const onSnippetClick = (selectValue: string) => {
    const snippet = `{{${selectValue}}}`;
    const position = caretPosition.current;
    const leftValueSide = value.slice(0, position) + snippet;
    const newValue = leftValueSide + value.slice(position);

    caretPosition.current = leftValueSide.length;
    setValue(name, newValue);
  };

  useEffect(() => {
    if (value !== buffValue) {
      setTouched(true);
    }
    setBuffValue(value);
  }, [value]);

  const filesLength = attachedFiles.length + attachments.length;

  const tooltipTitle = useMemo(() => {
    if (!isAddingAttachmentAllowed) {
      return <TooltipText>{PLAN_TOOLTIPS.professional}</TooltipText>;
    }

    return filesLength ? (
      <>
        <TooltipText>{filesLength} file(s) are added.</TooltipText>
        <TooltipText>Click here to choose again</TooltipText>
      </>
    ) : (
      <>
        <TooltipText>Select file(s) as attachment(s) to this message.</TooltipText>
        <TooltipText>Max file size limit is 10MB</TooltipText>
      </>
    );
  }, [filesLength, isAddingAttachmentAllowed]);

  return (
    <>
      <Typography marginBottom="5px" marginTop="30px" semibold>
        {label}
      </Typography>

      <Box position="relative">
        <StyledTextArea
          maxLength={maxLength}
          name={name}
          register={register}
          parameters={{
            onBlur: (e) => {
              caretPosition.current = e.target.selectionStart;
            },
            required,
          }}
        />
        <Box display="flex" position="absolute" top="-30px" right="0">
          <HiddenFileInput
            ref={fileInputRef}
            type="file"
            data-testid="file-input"
            accept="image/*,.ai,.psd,.pdf,.doc,.docx,.csv,.zip,.rar,.ppt,.pptx,.pps,.ppsx,.odt,.rtf,.xls,.xlsx,.txt,.pub,.html,.7z,.eml"
            onChange={onAttachmentChange}
            multiple
          />
          <Tooltip title={tooltipTitle} placement="top">
            <AttachmentBox data-testid="choose-attachment" width="30px" onClick={onAttachmentClick}>
              <FontAwesomeIcon icon={faPaperclip} />
            </AttachmentBox>
          </Tooltip>

          <Box position="relative">
            <AttachmentBox onClick={onOpenSelect} padding="0 15px">
              Personalize
            </AttachmentBox>

            <SelectSnippet
              isSelectOpen={isSelectOpen}
              onCloseSelect={onCloseSelect}
              onOpenSelect={onOpenSelect}
              onSnippetClick={onSnippetClick}
            />
          </Box>
        </Box>
      </Box>

      {numLetterShown && (
        <Typography data-testid="char-count" textAlign="right">
          {separateNumWithComma(numLettersLeft)}
        </Typography>
      )}

      <Box display="flex" flexWrap="wrap">
        {!!attachments?.length &&
          attachments.map((file) => {
            return (
              <Attachment
                key={file.file_uri}
                name={file.file_name}
                onDeleteClick={(fileName) => onAttachmentDelete(fileName)}
              />
            );
          })}
        {!!attachedFiles?.length &&
          attachedFiles.map((file) => {
            return <Attachment key={file.name} name={file.name} onDeleteClick={(fileName) => onFileDelete(fileName)} />;
          })}
      </Box>
    </>
  );
};
