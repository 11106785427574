import { useParams } from 'react-router-dom';

import { CampaignCategory } from 'src/enums';
import { EmailCsvUpload } from './AudienceForms/EmailCsvUpload';
import { LinkedinCsvUpload } from './AudienceForms/LinkedinCsvUpload';
import { LinkedinEvent } from './AudienceForms/LinkedinEvent';
import { LinkedinGroup } from './AudienceForms/LinkedinGroup';
import { LinkedinPost } from './AudienceForms/LinkedinPost';
import { LinkedinSalesNavigator } from './AudienceForms/LinkedinSalesNavigator';
import { LinkedinSearch } from './AudienceForms/LinkedinSearch';
import { LinkedinUrl } from './AudienceForms/LinkedinUrl';
import { LinkedinZapier } from './AudienceForms/LinkedinZapier';
import { TwitterCsvUpload } from './AudienceForms/TwitterCsvUpload';

export const NewCampaign = () => {
  const { category = CampaignCategory.LINKEDIN, campaignPageType } = useParams<{
    category: CampaignCategory;
    campaignPageType: string;
  }>();
  const isLinkedinOnly = [
    CampaignCategory.LINKEDIN,
    CampaignCategory.INMAIL,
    CampaignCategory.GROUP,
    CampaignCategory.POST,
    CampaignCategory.EVENT,
  ].includes(category as CampaignCategory);
  const isCsvCampaign = campaignPageType === 'csv-upload';

  switch (true) {
    case isCsvCampaign && isLinkedinOnly:
      return <LinkedinCsvUpload isLinkedinOnly={true} />;
    case isCsvCampaign && category === CampaignCategory.EMAIL:
      return <EmailCsvUpload />;
    case isCsvCampaign && category === CampaignCategory.TWITTER:
      return <TwitterCsvUpload />;
    case isCsvCampaign && category === CampaignCategory.MULTICHANNEL:
      return <LinkedinCsvUpload isLinkedinOnly={false} isMultichannel />;
    case campaignPageType === 'zapier':
      return <LinkedinZapier isLinkedinOnly={isLinkedinOnly} />;
    case campaignPageType === 'search':
      return <LinkedinSearch isLinkedinOnly={isLinkedinOnly} />;
    case campaignPageType === 'post':
      return <LinkedinPost />;
    case campaignPageType === 'event':
      return <LinkedinEvent />;
    case campaignPageType === 'sales-navigator':
      return <LinkedinSalesNavigator isLinkedinOnly={isLinkedinOnly} />;
    case campaignPageType === 'url':
      return <LinkedinUrl isLinkedinOnly={isLinkedinOnly} />;
    case campaignPageType === 'group':
      return <LinkedinGroup />;
    default:
      return null;
  }
};
