import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IGetDashboardParams, IPagination } from 'src/types';

interface IInitialState {
  campaignsParams: IGetDashboardParams & IPagination;
}

const initialState: IInitialState = {
  campaignsParams: {
    page: 1,
    perPage: 10,
  },
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    clearCampaignsParams: (state) => {
      state.campaignsParams = initialState.campaignsParams;
    },
    updateCampaignsParams: (state, action: PayloadAction<Partial<IGetDashboardParams & IPagination>>) => {
      state.campaignsParams = { ...state.campaignsParams, ...(action.payload ?? {}) };
    },
  },
});

export const { updateCampaignsParams, clearCampaignsParams } = dashboardSlice.actions;

export default dashboardSlice.reducer;
