export const GET_CAMPAIGN = [
  {
    json: {
      campaigns: [
        {
          id: 1,
          key: 'campaign',
          label: 'twit',
          status: 'active',
          required: true,
          dropdown: true,
        },
      ],
    },
  },
];

export const GET_MEMBER_CONNECTIONS = [
  {
    json: {
      connections: [
        {
          AIM: '',
          BLOG_WEBSITE: '',
          COMPANY_WEBSITE: '',
          GTALK: '',
          ICQ: '',
          OTHER_WEBSITE: '',
          PERSONAL_WEBSITE: '',
          PORTFOLIO_WEBSITE: 'https://www.behance.net/test-connection',
          QQ: '',
          RSS_WEBSITE: '',
          SKYPE: '',
          WECHAT: '',
          YAHOO_MESSENGER: '',
          address: '',
          birthday: '',
          companyName: 'Test Company',
          connectedAt: '2021-05-23T11:42:29Z',
          conversation_id: null,
          degree: 1,
          email: 'testconnection@test.com',
          entityUrn: 'ACoAADH1nVEBQRX2NJ-KBCkasdsdadsadasd',
          excluded: false,
          fieldOfStudy: 'Information Technology',
          firstName: 'Test Connection',
          headline: 'Graphic Designer',
          id: 'ACoAADH1nVEBQRX2NJasdsadasdasdasd',
          industryCode: 140,
          industryName: 'Graphic Design',
          languages: '',
          lastName: 'Connection',
          liEntityUrn: 'ACoAADH1nVEBQRX2NJ-KBCkyasdsadasdasdasd',
          linkedin_profile_url: 'https://www.linkedin.com/in/ACoAADH1nVEBQRX2NJ-KBasdasdasdasdasd',
          locationName: 'US',
          name: 'Test Connection',
          notes: null,
          objectUrn: '838180177',
          openLink: false,
          phone: '',
          picture:
            'https://media-exp1.licdn.com/dms/image/D4D35AQErdzpvXmmwag/profile-framedphoto-shrink_400_400/0/1640116502689?e=1642593600&v=beta&t=Aasdsadasdsadasdasd',
          postalCode: '',
          premium: false,
          publicIdentifier: 'test-connection',
          schoolName: 'Test University',
          skills:
            'WordPress||1,HTML||2,Cascading Style Sheets (CSS)||3,Web Development||4,Front-end Development||5,Responsive Web Design||6,React Native||7,HTML5||8,PSD to Wordpress||9,WordPress Design||10,Wordpress Development||11,JavaScript||12,css||13,Bootstrap||14,Finance||15,SQL||16,Microsoft Word||17,Google Sheets||18,Microsoft Office||19,Microsoft Excel||20',
          snEntityUrn: null,
          startedMonth: null,
          startedYear: '2019',
          tags: [],
          tenureMonths: null,
          tenureYears: null,
          title: 'Graphic Designer',
          trackingId: '5nK4+VpeSmmh+3d5q6iBwg==',
          twitter: '',
          updated_at: '2022-01-18T11:01:43.393029',
          versionTag: '2137336159',
        },
      ],
    },
  },
];

export const GET_TEAM_MEMBERS = [
  {
    json: {
      members: [
        {
          email: 'testmember@test.com',
          id: 2,
          name: 'Test member',
          timezone: {
            aliasOf: null,
            country: 'PK',
            dstOffset: 300,
            dstOffsetStr: '+05:00',
            name: 'Asia/Karachi',
            utcOffset: 300,
            utcOffsetStr: '+05:00',
          },
          webhook_key: '4JFeJVj1vRGSaTBXd1daVOwV3zi6DsQd',
        },
        {
          email: 'testmember2@test.com',
          id: 25,
          name: 'Test member 2',
          timezone: {
            aliasOf: null,
            country: 'PK',
            dstOffset: 300,
            dstOffsetStr: '+05:00',
            name: 'Asia/Karachi',
            utcOffset: 300,
            utcOffsetStr: '+05:00',
          },
          webhook_key: 'SKAHSJVj1vRGSaTBXd1daVaasdasdsd',
        },
      ],
    },
  },
];

export const GET_REPLIES = [
  {
    json: {
      actions: [
        {
          campaign: 1977,
          campaign_name: 'Test Eevent',
          campaign_status: 'archived',
          companyName: 'Test Company',
          conversation_urn: '2-NjkxZjA4MWItNTY1OC00NzY0LTk1OWEtYWU2YWYyMGRhOW',
          desc: 'linkedin reply detected',
          email: '',
          id: 124343,
          linkedin_conversation_url: 'https://www.linkedin.com/messaging/thread/2-NjkxZjA4MWItNTY1OC00NzY0LTk1OWEtYWU',
          linkedin_profile_url: 'https://www.linkedin.com/in/test_profile',
          name: 'Test Lead',
          phone: '',
          reply_detected_on: '2021-10-06T10:01:09Z',
          reply_message: 'hi thanks for reaching out',
          reply_received_on: '2021-09-25T03:52:55Z',
          return_to_campaign: false,
          status: 'approved',
          thread_id: '',
        },
      ],
    },
  },
];

export const GET_CONNECTIONS = [
  {
    json: {
      actions: [
        {
          AIM: '',
          BLOG_WEBSITE: '',
          COMPANY_WEBSITE: '',
          GTALK: '',
          ICQ: '',
          OTHER_WEBSITE: '',
          PERSONAL_WEBSITE: '',
          PORTFOLIO_WEBSITE: '',
          QQ: '',
          RSS_WEBSITE: '',
          SKYPE: '',
          WECHAT: '',
          YAHOO_MESSENGER: '',
          address: '',
          birthday: '3-30-undefined',
          companyName: 'test company',
          connectedAt: '2021-07-29T13:38:18Z',
          conversation_id: null,
          degree: 1,
          email: 'test@gmail.com',
          entityUrn: 'test_entity_urn',
          excluded: false,
          fieldOfStudy: 'Computer Software Engineering',
          firstName: 'Test',
          headline: '--',
          id: 'test_id',
          industryCode: 4,
          industryName: 'Computer Software',
          languages: '',
          lastName: 'User',
          liEntityUrn: 'test_li_entity_urn',
          linkedin_profile_url: 'https://www.linkedin.com/in/test_urn',
          locationName: 'United States',
          name: 'Test',
          notes: null,
          objectUrn: '111111111',
          openLink: false,
          phone: '001122233444',
          picture: 'https://test-image-url',
          postalCode: '',
          premium: false,
          publicIdentifier: 'test_identifier',
          schoolName: 'Oxford',
          skills: 'User Behavior||1,Magneto||2,Engineering||3,Front End Engineering Design (FEED)||4',
          snEntityUrn: null,
          startedMonth: '6',
          startedYear: '2021',
          tags: [],
          tenureMonths: '3',
          tenureYears: '0',
          title: 'Web Developer',
          trackingId: 'cOE4tvQhRJ6NUJW2NX0PYQ==',
          twitter: '',
          updated_at: '2021-10-11T12:58:14.261440',
          versionTag: '2489329063',
        },
      ],
    },
  },
];

export const GET_LAST_ACTIONS = [
  {
    json: {
      actions: [
        {
          created_at: '2022-08-12 10:17:59+00:00',
          desc: 'requested connect',
          lead: {
            campaign: {
              key: 345033,
              name: 'My campaign',
            },
            connect_followup: false,
            conversation_urn: null,
            csv_columns: null,
            custom_data: {},
            excluded: false,
            lead_source: null,
            li_url: 'https://linkedin.com/',
            li_urn: '',
            next_touch_at: 'Fri, 12 Aug 2022 10:05:49 GMT',
            next_touch_index: 1,
            object_urn: '624159071',
            person: {
              angellist_handle: '',
              current_employer: 'CO2Analysis.com',
              current_employer_size: '',
              current_title: 'Chair',
              email: 'test@gmail.com',
              facebook_handle: '',
              first_name: 'Test',
              github_handle: '',
              gravatar_handle: '',
              last_name: 'Lead',
              li_urn: '',
              linkedin_data: {
                firstName: 'Test',
                handle: 'test_handle',
                headline: 'Graphic Designer',
                lastName: 'Lead',
                location: 'United Kingdom',
                name: 'Test Lead',
                objectUrn: '624159071',
                pic: 'pic_url',
                subHeadline: 'Past: Web Designer at Freelance',
              },
              linkedin_handle: 'test_handle',
              location: 'United Kingdom',
              name: 'Test Lead',
              object_urn: '624159071',
              personal_email: '',
              phone: '',
              twitter_handle: 'test_handle',
              updated_at: '2022-10-13T12:35:18.376136',
              work_email: '',
            },
            request_connect_at: 'Fri, 12 Aug 2022 10:17:59 GMT',
            return_to_campaign: false,
            return_to_campaign_date: null,
            status: 'approved',
            thread_id: null,
          },
          msg: 'How are you?',
          tags: ['My Second Tag', 'My First tag'],
        },
      ],
    },
  },
];

export const GET_LEAD = [
  {
    json: {
      actions: [
        {
          campaign: {
            auto_approve_leads: true,
            campaign_type: 'csv upload',
            created_at: '2021-10-18 08:36:03+00:00',
            custom_snippets: {},
            exclude_invited_profiles: true,
            exclude_no_photos: false,
            no_first_connections: false,
            excluded_profiles: 0,
            get_new_leads: true,
            include_message_only: false,
            key: 2104,
            limit_exceeded: null,
            linkedin_only: false,
            max_search_amount: null,
            name: 'test ',
            next_search_at: null,
            next_search_index: null,
            num_pending_leads: 0,
            num_touches: 1,
            open_link_only: false,
            premium_only: false,
            removed: false,
            run_state: 'running',
            sales_navigator_url: null,
            search_ended: false,
            search_parameters: {
              connections: {
                first: false,
                second: false,
                third: false,
              },
              currentCompanies: [],
              industries: [],
              keywords: '',
              locations: [],
              nonprofitInterests: {
                boardService: false,
                skilledVounteering: false,
              },
              pastCompanies: [],
              profileLanguages: {
                english: false,
                french: false,
                polish: false,
                portuguese: false,
                russian: false,
                spanish: false,
              },
              schools: [],
              specifyKeywords: {
                company: '',
                firstName: '',
                lastName: '',
                school: '',
                title: '',
              },
            },
            search_url: null,
            sequence_updated: false,
            sequence_updated_at: null,
            status: 'active',
            team_key: 30,
            touch_sequence: {
              sequence: [
                {
                  delay_number: 0,
                  delay_time_unit: 'day(s)',
                  gmail_signature: false,
                  is_editing: false,
                  message: '<p>Hi,<br>\n<br>\nHow are you today?</p>',
                  subject: 'Hello',
                  tags: [],
                  type: 'Email',
                },
              ],
            },
            updated_at: '2021-10-18 08:36:24+00:00',
            user_key: 2,
          },
          connect_followup: false,
          conversation_urn: null,
          custom_data: {},
          email: '',
          excluded: false,
          id: 'Test-Leads',
          last_action: {
            campaign_key: 2104,
            campaign_name: 'test ',
            created_at: '2021-10-18 11:01:21+00:00',
            desc: 'verified uploaded lead',
            key: 126563,
            msg: null,
            msg_created_at: null,
          },
          lead_source: null,
          next_touch_at: null,
          next_touch_index: 0,
          person: {
            angellist_handle: null,
            csv_columns: {},
            current_employer: 'CO2Analysis.com',
            current_employer_size: null,
            current_title: 'Chair',
            custom_col1: null,
            custom_col2: null,
            custom_col3: null,
            custom_col4: null,
            custom_col5: null,
            custom_col6: null,
            email: null,
            email_only_person: null,
            facebook_handle: null,
            first_name: 'Test',
            github_handle: null,
            gravatar_handle: null,
            key: 'ACtest',
            last_name: 'Lead',
            li_urn: '',
            linkedin_data: {
              company: 'CO2Analysis.com',
              companySite: 'https://www.linkedin.com/company/test',
              entityUrn: 'test_entity_urn',
              firstName: 'Test',
              handle: 'test_handle',
              headline: 'Chair',
              lastName: 'Lead',
              location: 'United Kingdom',
              name: 'test lead',
              objectUrn: '47235085',
              pic: 'pic_url',
            },
            linkedin_handle: 'test-handle',
            location: 'United Kingdom',
            name: 'test lead',
            personal_email: null,
            phone: null,
            twitter_handle: null,
            updated_at: '2021-10-18T11:01:21.898984',
            work_email: null,
          },
          request_connect_at: null,
          return_to_campaign: false,
          return_to_campaign_date: null,
          status: 'approved',
          tags: [],
          thread_id: null,
        },
      ],
    },
  },
];
