import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { getPostById } from 'src/api';
import { IGetPost } from 'src/types';

export const useGetPost = <TData = IGetPost>(id: number, options?: UseQueryOptions<IGetPost, AxiosError, TData>) => {
  const { data, ...rest } = useQuery<IGetPost, AxiosError, TData>(['post', id], () => getPostById(id), options);

  return { post: data, ...rest };
};
