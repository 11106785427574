import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { inviteUserWithoutEmail } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IInviteUserWithoutEmailRequest, IInviteUserWithoutEmailResponse } from 'src/types';

export const useInviteUserWithoutEmail = (
  options?: UseMutationOptions<IInviteUserWithoutEmailResponse, ICustomAxiosError, IInviteUserWithoutEmailRequest>,
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, isLoading, ...rest } = useMutation(['invite-user-without-email'], inviteUserWithoutEmail, {
    ...options,
    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      await queryClient.invalidateQueries(['get-team-members']);
      await queryClient.invalidateQueries(['get-all-team-members']);
      dispatch(
        showToast({
          message: 'User created successfully',
          type: 'info',
          autoCloseTime: 5000,
        }),
      );
    },
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
          autoCloseTime: 3000,
        }),
      );
    },
  });

  return {
    inviteUserWithoutEmail: mutate,
    isInviteLoading: isLoading,
    ...rest,
  };
};
