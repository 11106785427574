import { act } from '@testing-library/react';

export const waitForQuery = (delay = 500) =>
  act(
    () =>
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, delay);
      }),
  );
