import { Control, Controller } from 'react-hook-form';
import { Box } from '@mui/material';

import { Slider, Typography } from 'src/components';

interface IControlledSliderProps {
  name: string;
  label?: string;
  min?: number;
  max?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
}

export const ControlledSlider = ({ name, label, min = 1, max = 2500, control }: IControlledSliderProps) => {
  return (
    <Box width="100%" pt="15px" pb="10px">
      <Typography mt="20px">{label}</Typography>

      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          const valueNumber = (value as number) || max;

          return (
            <Box display="flex" alignItems="center">
              <Slider onChange={onChange} value={valueNumber} max={max} min={min} />
              <Typography width="60px" textAlign="right" fontSize="16px" ml="10px" lineHeight="18px">
                {valueNumber}
              </Typography>
            </Box>
          );
        }}
      />
    </Box>
  );
};
