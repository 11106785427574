import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { css, Global } from '@emotion/react';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, styled } from '@mui/material';

import { Button, HtmlParser, Typography } from 'src/components';
import { useAppSelector } from 'src/hooks';
import { closeModal } from 'src/store/modal.slice';
import { IModalProps } from 'src/types';
import { renderBody } from './renderBody';

const Wrapper = styled(Box)`
  margin: 30px 0;
  width: 600px;
  border-radius: 6px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
  background-color: #ffffff;
  outline: none;

  &.large {
    width: 900px;
  }
`;

const ModalBackdrop = styled(Box)<{ isLastModal: boolean }>`
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: auto;
  z-index: var(--modal-z-index);
  background-color: rgba(0, 0, 0, 0.5);
  visibility: ${({ isLastModal }) => !isLastModal && 'hidden'};
`;

const ModalContainer = styled(Box)`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
`;

const ContentWrapper = styled(Box)`
  margin: auto;
`;

const globalOpenModalStyles = css`
  body {
    overflow: hidden;
  }
`;

const ModalBody = styled(Box)`
  background: ${({ theme }) => theme.palette.light.light};
  padding: 30px 40px;
`;

const IconBox = styled(Box)`
  cursor: pointer;
  margin-left: 5px;
`;

export const Modal = ({ modal, index, isLastModal }: { modal: IModalProps; index: number; isLastModal: boolean }) => {
  const dispatch = useDispatch();

  const {
    type,
    onCancel,
    onConfirm,
    headerText,
    params = {},
    modalStyles,
    backdropStyles,
    size = 'medium',
    descriptionText = '',
    closable = true,
    isActionsShown = true,
    cancelButtonLoading,
    confirmButtonLoading,
    confirmButtonLabel,
    cancelButtonLabel,
    confirmButtonStyles,
    confirmButtonVariant = 'primary',
    headerStyles,
    containerHeaderStyles,
    closeButtonStyles,
  } = modal;

  const confirmHandler = () => {
    onConfirm && onConfirm(params);
    dispatch(closeModal());
  };

  const closeHandler = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  return (
    <ModalBackdrop key={index} onClick={closeHandler} style={backdropStyles} isLastModal={isLastModal}>
      <ModalContainer>
        <Global styles={globalOpenModalStyles} />
        <ContentWrapper>
          <Wrapper
            style={modalStyles}
            data-testid={type}
            className={classNames(size)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {(closable || !!headerText) && (
              <Box pt="24px" pb="18px" px={5} display="flex" style={containerHeaderStyles}>
                <Typography
                  data-testid="modal-title"
                  mr="auto"
                  lineHeight="22px"
                  style={headerStyles}
                  sx={{ wordBreak: 'break-all' }}
                >
                  {headerText}
                </Typography>
                {closable && (
                  <IconBox>
                    <FontAwesomeIcon
                      icon={faXmark}
                      onClick={closeHandler}
                      style={closeButtonStyles}
                      size={closeButtonStyles?.size}
                    />
                  </IconBox>
                )}
              </Box>
            )}
            {type ? (
              renderBody(type, { ...modal, ...params })
            ) : (
              <>
                <ModalBody>
                  <Typography color="violet.dark">
                    <HtmlParser parseText={descriptionText} />
                  </Typography>
                </ModalBody>
                <Box py={2} px={5} display="flex" justifyContent="space-between">
                  {isActionsShown && (
                    <>
                      <Button variant="gray" onClick={closeHandler} processing={cancelButtonLoading}>
                        {cancelButtonLabel || 'Cancel'}
                      </Button>
                      <Button
                        variant={confirmButtonVariant}
                        onClick={confirmHandler}
                        processing={confirmButtonLoading}
                        style={confirmButtonStyles}
                      >
                        {confirmButtonLabel || 'Confirm'}
                      </Button>
                    </>
                  )}
                </Box>
              </>
            )}
          </Wrapper>
        </ContentWrapper>
      </ModalContainer>
    </ModalBackdrop>
  );
};

export const Modals = () => {
  const modals = useAppSelector((state) => state.modal);

  return (
    <>
      {modals.map((modal, i) => (
        <Modal key={i} modal={modal} index={i} isLastModal={i === modals.length - 1} />
      ))}
    </>
  );
};
