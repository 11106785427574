import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

dayjs.extend(timezone);

import { Spinner } from 'src/components';
import { PostStatus } from 'src/enums';
import { useAppSelector, useLocationState } from 'src/hooks';
import { useGetPost, useUpdatePost } from 'src/reactQueries';
import { TPostTypes } from 'src/types';
import { IFormCreatePost, PostForm } from './PostForm/PostForm';
import { PostFormLayout } from './PostForm/PostFormLayout';

export const EditPost = () => {
  const { postId } = useParams();
  const POST_ID = Number(postId);

  const navigate = useNavigate();

  const locationState = useLocationState<{ postType: TPostTypes }>();

  const user = useAppSelector((state) => state.user.profile);

  const { post, isLoading: isLoadingPost } = useGetPost(POST_ID);

  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);
  const [attachmentError, setAttachmentError] = useState('');

  const form = useForm<IFormCreatePost>();

  const { updatePost, isLoading } = useUpdatePost(POST_ID, {
    onSuccess: () => navigate(`/posts/${locationState?.postType || post?.postType || 'linkedin'}`),
  });

  useEffect(() => {
    if (post) {
      form.reset({
        title: post.title,
        postTypes: [post.postType],
        allowComments: post.isCommentsAllowed,
        postAs: post.postAs,
        attachments: post.attachments,
        content: post.content,
        audience: post.audience,
        scheduledAt: dayjs(post.scheduledAt).tz(user?.timezone?.name),
      });
    }
  }, [post]);

  return (
    <>
      <PostFormLayout
        title="Post details"
        onPublish={updatePost}
        isPublishDisabled={isLoading || isAttachmentLoading}
        handleSubmit={form.handleSubmit}
        attachmentError={attachmentError}
        post={post}
        isEdit
      />

      {isLoadingPost ? (
        <Spinner />
      ) : (
        <PostForm
          form={form}
          isAttachmentLoading={isAttachmentLoading}
          setIsAttachmentLoading={setIsAttachmentLoading}
          setAttachmentError={setAttachmentError}
          disablePostTypes
          disableForm={![PostStatus.SKIPPED, PostStatus.PENDING].includes(post?.status as PostStatus)}
        />
      )}
    </>
  );
};
