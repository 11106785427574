import { useQuery, UseQueryOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { getTeamById } from 'src/api';
import { removeTeam, setTeam } from 'src/store/team.slice';
import { ICustomAxiosError, ITeam, IUseGetTeamById } from 'src/types';

export const useGetTeamById = ({ teamId }: IUseGetTeamById, options?: UseQueryOptions<ITeam, ICustomAxiosError>) => {
  const dispatch = useDispatch();

  const { data, ...rest } = useQuery<ITeam, ICustomAxiosError>(
    ['get-team-by-id', teamId],
    () => getTeamById({ teamId }),
    {
      ...options,
      onSuccess: (teamData) => {
        options?.onSuccess?.(teamData);
        if (teamData) {
          dispatch(setTeam(teamData));
        }
      },
      onError: (err) => {
        options?.onError?.(err);
        /** Remove team data if user has been removed from the team by the owner */
        if ([403, 404].includes(err?.statusCode)) {
          dispatch(removeTeam());
        }
      },
    },
  );

  return { teamData: data, ...rest };
};
