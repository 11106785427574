import { useState } from 'react';
import { Box } from '@mui/material';

import { HeaderContainer, HeaderDescription, HeaderTitle, Link } from 'src/components';
import { useWhiteLabel } from 'src/hooks';
import { useGetTeamMembers } from 'src/reactQueries';
import { TeamsSearchBar } from '../_components';
import { InvitesTable } from './MembersTable/InvitesTable';
import { MembersTable } from './MembersTable/MembersTable';

export const GroupManage = () => {
  const { whitelabel } = useWhiteLabel();

  const [searchByName, setSearchByName] = useState('');
  const [searchByEmail, setSearchByEmail] = useState('');

  const { teamMembers } = useGetTeamMembers();
  const myGroup = teamMembers?.members[0]?.group?.name ?? '';

  const onChangeSearch = (key: 'name' | 'email' | 'group', value: string) => {
    switch (key) {
      case 'name':
        return setSearchByName(value);
      case 'email':
        return setSearchByEmail(value);
    }
  };

  return (
    <>
      <HeaderContainer>
        <HeaderTitle>Manage group {myGroup}</HeaderTitle>

        <HeaderDescription>
          Managing your team is easy. Invite new users, assign roles and login as your team members.
          {whitelabel.isWhiteLabelUser || (
            <Link
              leftIcon
              underline
              external
              openNewTab
              to="https://meetalfred.com/help/en/articles/3743821-create-and-edit-teams-in-alfred"
            >
              Learn how it works
            </Link>
          )}
        </HeaderDescription>
      </HeaderContainer>

      <TeamsSearchBar emailValue={searchByEmail} nameValue={searchByName} onChangeValue={onChangeSearch} />

      <Box padding="20px 15px 80px" minWidth={960}>
        <MembersTable searchByEmail={searchByEmail} searchByName={searchByName} />
        <InvitesTable />
      </Box>
    </>
  );
};
