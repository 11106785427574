import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { setLeadTags } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError, IGetIgnoredCampaignLeadRequest, IGetLeadsRequest, ILeadInfo, ITag } from 'src/types';

interface ISetLeadTagsParams {
  entityUrn: string;
  campaignId?: number;
  campaignKeys?: IGetIgnoredCampaignLeadRequest | IGetLeadsRequest;
}

export const useSetLeadTags = (
  { entityUrn, campaignId, campaignKeys }: ISetLeadTagsParams,
  options?: UseMutationOptions<void, ICustomAxiosError, ITag[]>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const getLeadsInfo = ['get-lead-info', entityUrn];

  const { mutate, ...rest } = useMutation(
    ['set-lead-tags', entityUrn],
    (tags: ITag[]) => setLeadTags(entityUrn, tags),
    {
      ...options,

      onMutate: async (variables) => {
        options?.onMutate?.(variables);
        await queryClient.cancelQueries(getLeadsInfo);

        const prevData = queryClient.getQueryData(getLeadsInfo);

        if (prevData) {
          queryClient.setQueryData<ILeadInfo | undefined>(getLeadsInfo, (lead) => {
            if (lead) {
              return {
                ...lead,
                tags: variables,
              };
            }

            return lead;
          });
        }

        return { prevData };
      },
      onSuccess: async (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
        await queryClient.invalidateQueries(['post-leads'], { refetchInactive: true, exact: false });
        await queryClient.invalidateQueries(['post-connections'], { refetchInactive: true, exact: false });
        await queryClient.invalidateQueries(['get-campaign-leads', campaignId, JSON.stringify(campaignKeys)], {
          refetchInactive: true,
          exact: false,
        });
        await queryClient.invalidateQueries(['get-campaign-ignored-leads', campaignId, JSON.stringify(campaignKeys)], {
          refetchInactive: true,
          exact: false,
        });
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);
        queryClient.setQueryData(getLeadsInfo, context?.prevData);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Something went wrong',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return { setLeadTags: mutate, ...rest };
};
