import { useDispatch } from 'react-redux';

import { Button, Typography } from 'src/components';
import { useDeleteTemplate } from 'src/reactQueries';
import { closeModal } from 'src/store/modal.slice';
import { IModalProps } from 'src/types';
import { ModalBody, ModalFooter } from '../_components';

export const ModalDeleteTemplate = ({ onCancel, params }: IModalProps) => {
  const dispatch = useDispatch();

  const { deleteTemplate, isLoading } = useDeleteTemplate(params?.id ?? -1, params?.goBack);

  const confirmHandler = () => {
    deleteTemplate();
  };

  const closeHandler = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  return (
    <>
      <ModalBody>
        <Typography color="violet.dark">Are you sure to delete this template?</Typography>
      </ModalBody>

      <ModalFooter>
        <Button variant="gray" onClick={closeHandler}>
          Cancel
        </Button>
        <Button processing={isLoading} onClick={confirmHandler}>
          Delete
        </Button>
      </ModalFooter>
    </>
  );
};
