import styled from '@emotion/styled';

import { IButtonProps } from 'src/types';
import { Button } from '../Buttons';

const StyledButton = styled(Button)`
  position: relative;
  display: inline-block;
  white-space: nowrap;
`;

export const HeaderButton = (props: IButtonProps) => {
  return <StyledButton {...props} />;
};
