import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { useCompanyTheme, useWhiteLabel } from 'src/hooks';

const StyledAuthHeader = styled(Box)`
  padding: 24px 40px 24px calc(40px + 10%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10%;
  margin-top: 0;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.primary.dark} !important;
  font-size: 0.9em;
  font-weight: 600;
  display: inline-block;
  text-decoration: none !important;
  text-align: end;

  & span {
    color: ${({ theme }) => theme.palette.secondary.main};
  }
`;

interface IAuthHeaderProps {
  isSignUp?: boolean;
}

export const AuthHeader = ({ isSignUp = false }: IAuthHeaderProps) => {
  const { whitelabel } = useWhiteLabel();
  const { logoIconBlue } = useCompanyTheme();

  return (
    <StyledAuthHeader>
      <a href={whitelabel.isWhiteLabelUser ? '' : 'https://meetalfred.com/'} className="brand">
        <img src={logoIconBlue} id="logo" width="45px" className="authLogo" alt="alf logo" />
      </a>
      {whitelabel.isWhiteLabelUser ||
        (isSignUp ? (
          <StyledLink to="/auth/login">
            Already have an account? <span>Login</span>
          </StyledLink>
        ) : (
          <StyledLink to="/auth/signup">
            Not a member? <span>Create an account</span>
          </StyledLink>
        ))}
    </StyledAuthHeader>
  );
};
