import classNames from 'classnames';
import { FieldValues, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { Button, ErrorMessage, Input, Typography } from 'src/components';
import { useWhiteLabel } from 'src/hooks';
import { AuthLayout } from 'src/layouts';
import { useSendRecoveryLink } from 'src/reactQueries';
import { AuthForm, MainHeading } from './_components';

const StyledAnchor = styled(Link)`
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0 auto;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Record<string, string>>();
  const { whitelabel } = useWhiteLabel();

  const { sendRecoveryLink, error, isSuccess, isLoading } = useSendRecoveryLink();

  const submit = (data: FieldValues) => {
    sendRecoveryLink(data.email);
  };

  return (
    <AuthLayout>
      <Box width="90%" maxWidth="541px" m="auto" p="3rem">
        <MainHeading>Password recovery</MainHeading>
        <AuthForm data-testid="form" onSubmit={handleSubmit(submit)}>
          <Input
            register={register}
            label="E-mail"
            name="email"
            parameters={{
              required: 'Please enter an email',
            }}
            data-testid="email-field"
            placeholder="E-mail"
            className={classNames({ error: errors.email })}
          />
          <Button
            data-testid="button"
            type="submit"
            disabled={isLoading}
            className="margin-top-20"
            size={{ width: '100%' }}
          >
            Send password recovery link
          </Button>

          {(errors.email || !!error) && (
            <ErrorMessage data-testid="error-message">
              {errors.email?.message ||
                (whitelabel.isWhiteLabelUser
                  ? 'Something went wrong, please make sure your have entered the correct email.'
                  : 'Something went wrong. If you signed up with gmail or microsoft please make sure to login with that method')}
            </ErrorMessage>
          )}

          {isSuccess && (
            <Typography mt="20px" bold lineHeight="20px" color="primary.wlLight">
              Recovery link was sent to your email. Please make sure to also check your spam folder.
            </Typography>
          )}

          <Box display="flex" mt="20px">
            <StyledAnchor to="/auth/login">Back to login page</StyledAnchor>
          </Box>
        </AuthForm>
      </Box>
    </AuthLayout>
  );
};
