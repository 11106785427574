import styled from '@emotion/styled';
import { Box } from '@mui/material';

import doneIcon from 'src/assets/icons/done.svg';
import undoneIcon from 'src/assets/icons/undone.svg';
import usersIcon from 'src/assets/icons/users.svg';
import documentSearch from 'src/assets/img/document-search.svg';
import { Link, Typography } from 'src/components';
import { CampaignType } from 'src/enums';
import { summaryBoardTypeResolver } from 'src/helpers';
import { TCampaignsStatus } from 'src/types';
import { RestartButton } from './RestartButton';

const Container = styled(Box)`
  background: white;
  box-shadow: 0 1px 1px 0 ${({ theme }) => theme.palette.light.dark};
  border-radius: 5px;
  padding: 20px;
`;

const Info = styled(Box)`
  display: flex;
  align-items: center;
`;

const Divider = styled(Box)`
  margin-top: 20px;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.light.light};
`;

type PropsT = {
  campaignType?: CampaignType;
  campaignStatus?: TCampaignsStatus;
  searchUrl?: string;
  isSummary?: boolean;
  isOverviewSummary?: boolean;
  maxSearchAmount?: number;
  options?: {
    excludeNoPhotos?: boolean;
    noFirstConnections?: boolean;
    premiumOnly?: boolean;
    openLinkOnly?: boolean;
    includeMessageOnly?: boolean;
    retargetLeads?: boolean;
  };
  isSearchEnded?: boolean;
  onSearchRestart?: () => void;
};

export const SummaryBoard = ({
  campaignType,
  isSummary = true,
  isOverviewSummary,
  searchUrl,
  options = {},
  maxSearchAmount,
  isSearchEnded,
  onSearchRestart,
  campaignStatus,
}: PropsT) => {
  const { premiumOnly, includeMessageOnly, openLinkOnly, excludeNoPhotos, noFirstConnections, retargetLeads } = options;
  const { description, isShowParameters, linkText, linkTitle } = summaryBoardTypeResolver(campaignType, isSummary);
  const isShowIncludeLeads = !isOverviewSummary && campaignType === CampaignType.LINKEDIN_EVENT;

  const renderOptions = [
    { label: 'Exclude Profiles Without Photos:', include: excludeNoPhotos },
    { label: 'Exclude 1st degree connections:', include: noFirstConnections },
    { label: 'Premium Users Only:', include: premiumOnly },
    {
      label: 'Open link Profiles Only:',
      include: openLinkOnly,
      show: [CampaignType.LINKEDIN_SN_SAVED_SEARCH, CampaignType.LINKEDIN_SN_SEARCH].includes(
        campaignType as CampaignType,
      ),
    },
    {
      label: 'Include Leads To Whom You Can Message:',
      include: includeMessageOnly,
      show: isShowIncludeLeads,
    },
    { label: 'Include leads from other campaigns:', include: retargetLeads },
  ];

  const isAvailableToRestart = ![
    CampaignType.LINKEDIN_CONNECTIONS,
    CampaignType.EMAIL_CONNECTIONS,
    CampaignType.TWITTER_CONNECTIONS,
    CampaignType.LINKEDIN_CSV_UPLOAD,
    CampaignType.TWITTER_CSV_UPLOAD,
    CampaignType.EMAIL_CSV_UPLOAD,
    CampaignType.ZAPIER_LEADS,
  ].includes(campaignType as CampaignType);

  return (
    <Container>
      <Info>
        <img width="40px" height="40px" src={documentSearch} alt="" />
        <Box ml="20px">
          <Typography lineHeight="14px" fontWeight="300">
            Where are your leads coming from?
          </Typography>
          <Typography lineHeight="14px" fontWeight="700">
            {description}
          </Typography>
        </Box>
        {campaignStatus === 'active' && onSearchRestart && isAvailableToRestart && (
          <Box ml="auto">
            <RestartButton processing={!isSearchEnded} onClick={onSearchRestart} />
          </Box>
        )}
      </Info>
      {searchUrl && (
        <>
          <Divider />
          <Box mt="25px">
            <Typography lineHeight="4px" fontWeight="300">
              {linkTitle}
            </Typography>
            <Link external openNewTab to={searchUrl}>
              {linkText}
            </Link>
          </Box>
        </>
      )}
      {isShowParameters &&
        renderOptions.map(
          ({ include, label, show = true }) =>
            show && (
              <Box key={label} mt="18px">
                <Typography fontWeight="300">{label}</Typography>
                <Box display="flex" alignItems="center" mt="10px">
                  <img width="20px" height="20px" src={include ? doneIcon : undoneIcon} />
                  <Typography marginLeft="10px" bold>
                    {include ? 'Yes' : 'No'}
                  </Typography>
                </Box>
              </Box>
            ),
        )}
      {isShowParameters && !!maxSearchAmount && (
        <Box mt="18px">
          <Typography fontWeight="300">Maximum amount of leads:</Typography>
          <Box display="flex" alignItems="center" mt="10px">
            <img width="20px" height="20px" src={usersIcon} />
            <Typography marginLeft="10px" bold>
              {maxSearchAmount}
            </Typography>
          </Box>
        </Box>
      )}
    </Container>
  );
};
