import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { ReactComponent as NoCampaignsYet } from 'src/assets/img/campaign/no_campaigns_yet.svg';
import { Button, Typography } from 'src/components';
import { CampaignCategory } from 'src/enums';

export const EmptyTab = () => {
  const { category } = useParams();
  const navigate = useNavigate();

  const isTwitterCategory = category === CampaignCategory.TWITTER;

  return (
    <Box margin="0 15px" display="flex" justifyContent="center" alignItems="center">
      <Box display="flex" padding="0 15px" flexDirection="column" width="33.33%" alignItems="center">
        <Typography fontSize="30px" lineHeight="32px" semibold alignSelf="start">
          No campaigns yet...
        </Typography>
        <Typography fontSize="18px" margin="20px 0">
          Here will be your all campaigns. Click the "Create Campaign" button, choose a campaign type and start editing
          it.
        </Typography>

        <Button
          size={{ width: '257px', height: '70px' }}
          onClick={() => navigate(`/campaign/new/${category}`)}
          disabled={isTwitterCategory}
          tooltip={{ title: isTwitterCategory ? 'Feature under Maintenance' : '' }}
        >
          + Create Campaign
        </Button>
      </Box>

      <Box width="33.33%" padding="30px 15px 60px">
        <NoCampaignsYet width="400px" />
      </Box>
    </Box>
  );
};
