import { keyBy, sortBy } from 'lodash';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { components, MultiValue, SingleValue } from 'react-select';
import Creatable from 'react-select/creatable';
import { Box, styled } from '@mui/material';

import { ReactComponent as XMarkIcon } from 'src/assets/icons/x-mark.svg';
import { Button, selectTagStyles, Tooltip, WithTagCircle } from 'src/components';
import { PLAN_TOOLTIPS } from 'src/constants';
import { Features, ModalTypes } from 'src/enums';
import { getRandomHexColor } from 'src/helpers';
import { useTeamPlan } from 'src/hooks';
import { useCreateTag, useGetAllTags, useSetLeadTags } from 'src/reactQueries';
import { closeModal, openModal } from 'src/store/modal.slice';
import { IModalProps, ITag } from 'src/types';
import { ModalBody, ModalFooter } from '../_components';

const Label = styled(Box)`
  margin-bottom: 14px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-family: 'ProximaSoft', sans-serif;
  font-size: 14px;
  line-height: 16px;
`;

export const ModalAddLeadsTags = ({ onCancel, params }: IModalProps) => {
  const dispatch = useDispatch();
  const { entityUrn, tagIds = [], campaignId, campaignKeys } = params ?? {};
  const [selectedTagIds, setSelectedTagIds] = useState<number[]>(tagIds);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const { checkFeature } = useTeamPlan();

  const { tags } = useGetAllTags();
  const objectTags = useMemo(() => keyBy(tags, 'id'), [tags]);
  const selectedTags = useMemo(
    () => sortBy(selectedTagIds.map((id) => objectTags[id]).filter(Boolean), 'tag'),
    [objectTags, selectedTagIds],
  );

  const options = useMemo(() => tags?.map((tag) => ({ ...tag, label: tag.tag, value: tag.tag })), [tags]) || [];

  const { setLeadTags, isLoading } = useSetLeadTags(
    {
      entityUrn,
      campaignId,
      campaignKeys,
    },
    {
      onSuccess: () => {
        dispatch(closeModal());
      },
    },
  );
  const { createTag, isLoading: isCreateLoading } = useCreateTag({
    withNotCloseModal: true,
  });

  const onCreateTag = (tag: string) => {
    createTag(
      { tag, color: getRandomHexColor() },
      {
        onSuccess: (data) => {
          setSelectedTagIds((prev) => [...prev, data.id].filter((id) => id !== -1));
        },
      },
    );
  };

  const confirmHandler = () => {
    setLeadTags(selectedTags);
  };

  const closeHandler = () => {
    onCancel && onCancel();
    dispatch(closeModal());
  };

  const onChange = (value: MultiValue<ITag> | SingleValue<ITag>) => {
    if (!Array.isArray(value)) {
      return null;
    }

    const nextTagIds = (value as ITag[])?.map((tag) => tag?.id);

    if (nextTagIds.length < selectedTags.length) {
      setIsMenuOpen(false);

      return dispatch(
        openModal({
          headerText: 'Delete Tag',
          modalStyles: { boxShadow: 'none', height: '266px', display: 'grid', gridTemplateRows: 'auto 1fr auto' },
          type: ModalTypes.DELETE_LEAD_TAG,
          onConfirm: () => setSelectedTagIds(nextTagIds),
        }),
      );
    }

    return setSelectedTagIds(nextTagIds);
  };

  return (
    <div>
      <ModalBody>
        <Label>Select tags</Label>

        <Tooltip title={!checkFeature(Features.leadsTags) && PLAN_TOOLTIPS.any}>
          <span>
            <Creatable
              isMulti
              menuIsOpen={isMenuOpen}
              onMenuOpen={() => setIsMenuOpen(true)}
              onMenuClose={() => setIsMenuOpen(false)}
              isClearable={false}
              placeholder="Select Tags"
              value={selectedTags.map((tag) => ({ label: tag.tag, ...tag, value: tag.tag }))}
              options={options}
              styles={selectTagStyles}
              components={{
                MultiValueRemove: (props) => {
                  return (
                    <components.MultiValueRemove {...props}>
                      <XMarkIcon color={props.data.color} />
                    </components.MultiValueRemove>
                  );
                },
              }}
              onChange={onChange}
              onCreateOption={onCreateTag}
              formatCreateLabel={(inputText) => {
                return <WithTagCircle>Create option "{inputText}"</WithTagCircle>;
              }}
              isDisabled={!checkFeature(Features.leadsTags) || isCreateLoading}
            />
          </span>
        </Tooltip>
      </ModalBody>

      <ModalFooter>
        <Button variant="gray" onClick={closeHandler}>
          Cancel
        </Button>
        <Button
          onClick={confirmHandler}
          processing={isLoading || isCreateLoading}
          disabled={!checkFeature(Features.leadsTags)}
        >
          Save tags
        </Button>
      </ModalFooter>
    </div>
  );
};
