import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { Button, ErrorMessage, Input } from 'src/components';
import { useUpdatePassword } from 'src/reactQueries';
import { closeModal } from 'src/store';
import { IUserPreferences } from 'src/types';
import { ModalBody, ModalFooter } from '../_components';

export const ModalSetPassword = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const onCancel = () => {
    dispatch(closeModal());
  };

  const { changePassword, isLoading } = useUpdatePassword({
    onSuccess: () => {
      queryClient.setQueryData<IUserPreferences | undefined>(['get-user-preferences'], (data) =>
        data ? { ...data, userProfile: { ...data.userProfile, havePassword: true } } : data,
      );
      onCancel();
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<{ password: string }>();

  const onSetPassword = async (values: { password: string }) => {
    changePassword({ newPassword: values.password });
  };

  return (
    <>
      <ModalBody>
        <Input
          register={register}
          parameters={{
            required: 'Please enter a password',
            minLength: {
              value: 6,
              message: 'The password must be a minimum of 6 characters',
            },
          }}
          className={classNames({
            error: errors?.password,
          })}
          type="password"
          name="password"
          label="Password"
          placeholder="Password"
        />
        {errors?.password?.message && <ErrorMessage>{errors.password.message}</ErrorMessage>}
      </ModalBody>

      <ModalFooter>
        <Button variant="gray" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={isLoading} onClick={handleSubmit(onSetPassword)}>
          Confirm
        </Button>
      </ModalFooter>
    </>
  );
};
