import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { updateToggleShareTemplate } from 'src/api';
import { ICustomAxiosError, IGetTemplatesResponse } from 'src/types';

export const useToggleShareTemplate = (templateId: number, options?: UseMutationOptions<void, ICustomAxiosError>) => {
  const queryClient = useQueryClient();

  const getPersonalTemplatesKey = ['get-templates-personal'];

  const { mutate, isLoading, ...rest } = useMutation(
    ['post-share-template', templateId],
    () => updateToggleShareTemplate(templateId),
    {
      ...options,
      onMutate: async (variables) => {
        options?.onMutate?.(variables);
        await queryClient.cancelQueries(getPersonalTemplatesKey);

        const prevData = queryClient.getQueryData(getPersonalTemplatesKey);

        queryClient.setQueriesData<IGetTemplatesResponse | undefined>(getPersonalTemplatesKey, (items) => {
          if (items) {
            return {
              ...items,
              templates: items?.templates?.map((item) => {
                if (item.id === templateId) {
                  return { ...item, isAvailableForTeam: !item.isAvailableForTeam };
                }

                return item;
              }),
            };
          }

          return items;
        });

        return { prevData };
      },
    },
  );

  return {
    toggleShareTemplate: mutate,
    isShareLoading: isLoading,
    ...rest,
  };
};
