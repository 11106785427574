import { useMutation, UseMutationOptions } from 'react-query';

import { updateTeamDataAfterFailedPayment } from 'src/api';
import { ICustomAxiosError } from 'src/types';

export const useUpdateTeamDataAfterFailedPayment = (options?: UseMutationOptions<void, ICustomAxiosError>) => {
  const { mutate, ...rest } = useMutation(
    ['update-team-data-failed-payment'],
    updateTeamDataAfterFailedPayment,
    options,
  );

  return { updateTeamDataAfterFailedPayment: mutate, ...rest };
};
