import dayjs from 'dayjs';
import { useState } from 'react';
import { ActiveModifiers, DayPicker } from 'react-day-picker';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Box, Grid, MenuItem, Select } from '@mui/material';

import { Button, Typography } from 'src/components';
import { useAppSelector } from 'src/hooks';
import { useReschedulePost } from 'src/reactQueries/posts/useReschedulePost';
import { closeModal } from 'src/store/modal.slice';
import { showToast } from 'src/store/toast.slice';
import { IGetPostsRequest, IModalProps, IPost, IPostSelectedDate } from 'src/types';

const StyledDayPicker = styled(DayPicker)`
  margin: 0 !important;
  width: 250px;

  .rdp-day_selected {
    background-color: ${({ theme }) => theme.palette.primary.main};
  }
  .rdp-day_today:not(.rdp-day_outside) {
    color: #d0021b;
  }
  .rdp-day_today.rdp-day_selected {
    color: white;
  }
  .rdp-caption_label,
  .rdp-head_cell {
    font-weight: 500;
  }
`;

const StyledSelect = styled(Select)`
  height: 40px;
`;

const TimeDivides = styled.div`
  margin: 0 10px;
`;

const TimeWarning = styled.div`
  margin-top: 20px;
  font-size: 15px;
  color: red;
  font-family: 'ProximaSoft', sans-serif;
`;

const modifiersStyles = {
  scheduled: {
    color: 'white',
    backgroundColor: '#ffc727',
    opacity: 1,
  },
  published: {
    color: 'white',
    backgroundColor: '#04d600',
    opacity: 1,
  },
};

const defaultHours = {
  hour: 12,
  minute: 0,
  place: 'AM',
};

interface IModalReschedulePostParams {
  posts: IPost[];
  postId: number;
  getPostsQueryKeys: IGetPostsRequest;
}

export const ModalReschedulePost = ({ params }: IModalProps<IModalReschedulePostParams>) => {
  const dispatch = useDispatch();
  const { posts = [], postId = -1, getPostsQueryKeys } = params ?? {};
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedTime, setSelectedTime] = useState<Omit<IPostSelectedDate, 'date'>>(defaultHours);

  const { profile } = useAppSelector((state) => state.user);

  const { reschedulePost, isLoading } = useReschedulePost(postId, getPostsQueryKeys, {
    onSuccess: () => {
      dispatch(closeModal());
    },
  });

  const scheduledPosts = posts.filter(({ status }) => status === 'pending');
  const postedDates = scheduledPosts.map(({ scheduledAt }) => new Date(scheduledAt));

  const publishedPosts = posts.filter(({ status }) => status === 'published');
  const publishedDates = publishedPosts.map(({ scheduledAt }) => new Date(scheduledAt));

  const modifiers = {
    scheduled: postedDates,
    published: publishedDates,
  };

  const selectedFullDate = dayjs(
    `${dayjs(selectedDate).format('YYYY-MM-DD')} ${selectedTime.hour}:${selectedTime.minute} ${selectedTime.place}`,
    'YYYY-MM-DD h:mm A',
  );
  const selectedDateTime = dayjs(selectedFullDate, 'YYYY-MM-DD h:mm A');

  const handleDateChange = (day: Date, newModifiers: ActiveModifiers) => {
    if (newModifiers.disabled) {
      return;
    }

    const selectDay = new Date(day.getFullYear(), day.getMonth(), day.getDate(), 0, 0, 0);

    setSelectedDate(selectDay);
  };

  const onUpdateClick = () => {
    if (dayjs().tz(profile?.timezone?.name).isAfter(selectedFullDate)) {
      return dispatch(
        showToast({
          type: 'info',
          message: `Post time should be after the current time ${dayjs()
            .tz(profile?.timezone?.name)
            .format('hh:mm A')}`,
        }),
      );
    }

    return reschedulePost({ timestamp: Number(dayjs(selectedFullDate).format('x')) });
  };

  return (
    <Box p="20px">
      <Typography semibold color="primary.wlLight">
        Reschedule to
      </Typography>
      <Typography semibold color="primary.wlLight" mt="20px">
        Post At
      </Typography>
      <Typography mt="5px">When do you want to schedule your post?</Typography>
      <Box display="grid" gridTemplateColumns="1fr 1fr">
        <Box width="300px" display="inline-block" mt="10px">
          <StyledDayPicker
            showOutsideDays
            selected={selectedDate}
            numberOfMonths={1}
            modifiers={modifiers}
            modifiersStyles={modifiersStyles}
            disabled={[{ before: new Date() }]}
            onDayClick={handleDateChange}
          />
          {selectedDateTime && (
            <Box mt="10px" p="10px">
              <Typography fontSize="15px">Scheduling this post at: </Typography>
              <Typography fontSize="15px" color="primary.main" semibold>
                {selectedDateTime.format('dddd, MMMM D, YYYY h:mm A')}
              </Typography>
            </Box>
          )}
        </Box>

        <Box p="10px" display="flex" flexWrap="wrap" alignContent="flex-start">
          <Grid item xs={12}>
            <Typography mb="15px" semibold>
              Select a time
            </Typography>
            <Box display="flex" alignItems="center">
              <StyledSelect
                defaultValue={12}
                onChange={(e) =>
                  setSelectedTime((prev) => ({
                    ...prev,
                    hour: e.target.value as number,
                  }))
                }
              >
                <MenuItem value={1}>01</MenuItem>
                <MenuItem value={2}>02</MenuItem>
                <MenuItem value={3}>03</MenuItem>
                <MenuItem value={4}>04</MenuItem>
                <MenuItem value={5}>05</MenuItem>
                <MenuItem value={6}>06</MenuItem>
                <MenuItem value={7}>07</MenuItem>
                <MenuItem value={8}>08</MenuItem>
                <MenuItem value={9}>09</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={11}>11</MenuItem>
                <MenuItem value={12}>12</MenuItem>
              </StyledSelect>
              <TimeDivides>:</TimeDivides>
              <StyledSelect
                defaultValue={0}
                onChange={(e) =>
                  setSelectedTime((prev) => ({
                    ...prev,
                    minute: e.target.value as number,
                  }))
                }
              >
                <MenuItem value={0}>00</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={45}>45</MenuItem>
              </StyledSelect>
              <TimeDivides />
              <StyledSelect
                defaultValue="AM"
                onChange={(e) =>
                  setSelectedTime((prev) => ({
                    ...prev,
                    place: e.target.value as string,
                  }))
                }
              >
                <MenuItem value="AM">AM</MenuItem>
                <MenuItem value="PM">PM</MenuItem>
              </StyledSelect>
            </Box>
          </Grid>
          {dayjs().tz(profile?.timezone?.name).isAfter(selectedFullDate) && (
            <TimeWarning>
              Post time should be after the current time ({dayjs().tz(profile?.timezone?.name).format('hh:mm A')})
            </TimeWarning>
          )}
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end" mt="40px">
        <Button processing={isLoading} onClick={onUpdateClick}>
          Update
        </Button>
      </Box>
    </Box>
  );
};
