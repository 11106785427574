import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { deleteCampaign } from 'src/api';
import { CampaignCategory } from 'src/enums';
import { showToast } from 'src/store/toast.slice';
import { ICustomAxiosError } from 'src/types';

export const useDeleteCampaign = (
  { campaignId, category }: { campaignId: number; category?: CampaignCategory },
  options?: UseMutationOptions<void, ICustomAxiosError>,
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, ...rest } = useMutation(['delete-campaign', campaignId], () => deleteCampaign(campaignId), {
    ...options,
    onError: (err, _vars, context) => {
      options?.onError?.(err, _vars, context);
      dispatch(
        showToast({
          type: 'error',
          message: (err as Error)?.message ?? 'Something went wrong',
          autoCloseTime: 3000,
        }),
      );
    },

    onSuccess: async (data, variables, context) => {
      options?.onSuccess?.(data, variables, context);
      await queryClient.invalidateQueries(['get-campaigns', category]);
      await queryClient.invalidateQueries(['get-campaigns-count']);
      await queryClient.invalidateQueries(['campaign-activity-search-list'], { refetchInactive: true });
      await queryClient.invalidateQueries(['campaigns-dashboard'], { refetchInactive: true });
    },
  });

  return { deleteCampaign: mutate, ...rest };
};
