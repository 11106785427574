import { useMutation, UseMutationOptions } from 'react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { updateSequenceTemplateById } from 'src/api';
import { showToast } from 'src/store/toast.slice';
import { ICreateSequenceTemplate, ICustomAxiosError, ISequenceTemplate } from 'src/types';

export const useUpdateSequenceTemplate = (
  sequenceTemplateId: number,
  options?: UseMutationOptions<ISequenceTemplate, ICustomAxiosError, ICreateSequenceTemplate>,
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { mutate, ...rest } = useMutation(
    ['update-sequence-template', sequenceTemplateId],
    (values: ICreateSequenceTemplate) => updateSequenceTemplateById(sequenceTemplateId, values),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);

        navigate(`/sequence-templates/personal?category=${variables.category}`);

        dispatch(
          showToast({
            type: 'success',
            message: 'Campaign Sequence Template was successfully updated',
            autoCloseTime: 3000,
          }),
        );
      },
      onError: (err, _vars, context) => {
        options?.onError?.(err, _vars, context);

        dispatch(
          showToast({
            type: 'error',
            message: (err as Error)?.message ?? 'Oops, something has gone wrong!',
            autoCloseTime: 3000,
          }),
        );
      },
    },
  );

  return {
    updateSequenceTemplate: mutate,
    ...rest,
  };
};
