import { PropsWithChildren } from 'react';
import * as Sentry from '@sentry/react';

import { ErrorBoundary } from 'src/components';

export const SentryProvider = ({ children }: PropsWithChildren) => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    enabled: process.env.REACT_APP_NODE_ENV === 'staging' || process.env.REACT_APP_NODE_ENV === 'production',
    environment: process.env.REACT_APP_NODE_ENV,
  });

  return <Sentry.ErrorBoundary fallback={ErrorBoundary}>{children} </Sentry.ErrorBoundary>;
};
