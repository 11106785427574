import isUrlValidator from 'validator/lib/isURL';

import { PLAN_TOOLTIPS, REGEXP_VALID_URL } from 'src/constants';

export const validateLinkedinSearch = (search: string) => {
  const orCount = (search.match(/ OR /g) || []).length;
  const andCount = (search.match(/ AND /g) || []).length;
  const notCount = (search.match(/ NOT /g) || []).length;

  return (
    orCount + andCount + notCount <= 5 || 'Please use less than 5 boolean operators (eg. OR, AND, etc.) in your search.'
  );
};

export const validateLinkedinPostUrl = (value: string) => {
  const isCorrectUrlFormat = REGEXP_VALID_URL.test(value) && value.indexOf('https://www.linkedin.com/posts/') === 0;
  const isPost = isCorrectUrlFormat && (value.indexOf('activity-') > -1 || value.indexOf('ugcPost-') > -1);
  const isRepliedPost = REGEXP_VALID_URL.test(value) && value.indexOf('https://www.linkedin.com/feed/update/') === 0;

  if (isRepliedPost || isPost) {
    return true;
  }

  return 'Wrong linkedin post url';
};

export const validateLinkedinEventUrl = (value: string) => {
  const isCorrectUrlFormat = value.startsWith('https://www.linkedin.com/events/');
  const isVideoEventURL = value.startsWith('https://www.linkedin.com/video/event/');
  const isEventAttending = value.indexOf('eventAttending=') > -1;
  if (REGEXP_VALID_URL.test(value) && (isCorrectUrlFormat || isVideoEventURL || isEventAttending)) {
    return true;
  }

  return 'Wrong linkedin event url';
};

export const validateLinkedinSearchUrl = (value: string) => {
  if (
    (REGEXP_VALID_URL.test(value) && value.indexOf('https://www.linkedin.com/search/results/people') === 0) ||
    value.indexOf('https://www.linkedin.com/search/results/PEOPLE') === 0
  ) {
    return true;
  }

  return 'Please use a valid Linkedin search URL.';
};

export const validateLinkedinSalesNavigatorUrl = (value: string) => {
  if (value.includes('?recentSearchId=')) {
    return false;
  }

  if (REGEXP_VALID_URL.test(value) && value.indexOf('https://www.linkedin.com/sales/search/people') === 0) {
    return true;
  }

  return 'Please use a valid Linkedin sales navigator URL.';
};

export const validateLinkedinSavedSearch = (value: string) => {
  if (value.indexOf('savedSearchId') !== -1 || value.indexOf('delta-saved-search') !== -1) {
    return true;
  }

  return 'Please use a valid saved search.';
};

export const validateRecruiterUrl = (value: string) => {
  if (REGEXP_VALID_URL.test(value) && value.indexOf('https://www.linkedin.com/recruiter/smartsearch') === 0) {
    return true;
  }

  return 'Please use a valid recruiter URL.';
};

export const validateLinkedInUrl = (value: string, isLinkedInSNUrlAllowed: boolean): boolean | string => {
  if (validateLinkedinSearchUrl(value) === true) {
    return true;
  }

  if (validateLinkedinSalesNavigatorUrl(value) === true) {
    return isLinkedInSNUrlAllowed ? true : PLAN_TOOLTIPS.any;
  }

  return 'Please use a valid Linkedin search URL.';
};

export const validateLinkedinCsvUrl = (url: string) => {
  return (
    (url?.search(/linkedin.com\/in\//i) > -1 ||
      url?.search(/linkedin.com\/pub\//i) > -1 ||
      url?.search(/linkedin.com\/sales\/people\//i) > -1 ||
      url?.search(/linkedin.com\/sales\/lead\//i) > -1) &&
    isUrlValidator(url)
  );
};

export const isLinkedinEntityUrn = (entityUrn?: string) => entityUrn?.length === 39 && entityUrn?.includes('AC');
