import { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';

import { Tooltip } from 'src/components';

const StyledInput = styled.input`
  max-width: 100px;
  height: 30px;
  padding: 0 2px;
  font-family: 'ProximaSoft', sans-serif;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.primary.wlLight};
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;

  &:focus + span {
    display: block;
  }
  &[disabled] {
    background-color: transparent;
  }
`;

interface ICSVTableProps {
  name: string;
  value: string;
  focusedField?: string;
  disabled?: boolean;
  onChange?: (value: Record<string, string>) => void;
}

export const CSVTableHeadCell = ({ name, value, focusedField, disabled, onChange }: ICSVTableProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focusedField === name) {
      setIsFocused(true);
      inputRef?.current?.focus?.();
    }
  }, [focusedField]);

  const focusHandler = () => {
    setIsFocused(true);
  };

  const blurHandler = () => {
    setIsFocused(false);
  };

  const changeHandler = (evt: ChangeEvent<HTMLInputElement>) => {
    typeof onChange === 'function' && onChange({ [name]: evt.currentTarget.value });
  };

  return (
    <Tooltip open={isFocused} title="You can set a custom name to your data columns">
      <StyledInput
        ref={inputRef}
        name={name}
        value={value}
        autoFocus={isFocused}
        placeholder="Enter a unique"
        autoComplete="off"
        disabled={disabled}
        onFocus={focusHandler}
        onBlur={blurHandler}
        onChange={changeHandler}
      />
    </Tooltip>
  );
};
