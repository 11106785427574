import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DEFAULT_PER_PAGE } from 'src/constants';
import { ILeadColumnsPosition, ILeadColumnsPositionResponse, ILeadsFilters, ISelectedLead } from 'src/types';

interface IInitialState {
  request: ILeadsFilters;
  exportData: {
    selectedLeads: ISelectedLead[];
  };
  leadColumns: ILeadColumnsPosition[];
}

export const DEFAULT_LEADS_REQUEST: ILeadsFilters = {
  page: 1,
  perPage: DEFAULT_PER_PAGE,
  search: '',
  sortField: 'updatedAt',
  sortOrder: 'DESC',
  name: [],
  currentTitle: [],
  currentEmployer: [],
  location: [],
  campaignIds: [],
  tags: [],
  isExcluded: false,
  connectedOnly: false,
  selectedOnly: false,
};

const DEFAULT_LEAD_COLUMNS: ILeadColumnsPosition[] = [
  { key: 'name', label: 'Name', readOnly: true },
  { key: 'company', label: 'Company' },
  { key: 'title', label: 'Position' },
  { key: 'campaign', label: 'Campaign' },
  { key: 'action', label: 'Last Action' },
  { key: 'connected', label: 'Connected' },
  { key: 'tags', label: 'Tags' },
  { key: 'notes', label: 'Notes' },
];

export const leadInitialState: IInitialState = {
  request: DEFAULT_LEADS_REQUEST,
  leadColumns: DEFAULT_LEAD_COLUMNS,
  exportData: {
    selectedLeads: [],
  },
};

export const leadSlice = createSlice({
  name: 'lead',
  initialState: leadInitialState,
  reducers: {
    updateLeadsRequestParams: (state, action) => {
      state.request = { ...state.request, ...(action.payload ?? {}) };
    },
    setSelectedLeads: (state, action) => {
      state.exportData.selectedLeads = action.payload;
    },
    setLeadColumns: (state, action: PayloadAction<ILeadColumnsPositionResponse[]>) => {
      const columns = action?.payload || DEFAULT_LEAD_COLUMNS;
      state.leadColumns = columns
        ?.filter((col) => col?.key !== 'shared_connections')
        ?.map((col) =>
          col?.key === 'degree' ? { key: 'connected', label: 'Connected' } : col,
        ) as ILeadColumnsPosition[];
    },
    clearLeadRequestParams: (state) => {
      state.request = DEFAULT_LEADS_REQUEST;
    },
  },
});

export const { updateLeadsRequestParams, setSelectedLeads, setLeadColumns, clearLeadRequestParams } = leadSlice.actions;

export default leadSlice.reducer;
