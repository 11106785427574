import { Box } from '@mui/material';

import { Spinner, TableCell, TableRow, Typography } from 'src/components';
import { useGetCampaignProgress } from 'src/reactQueries';
import { IMemberCampaignStat } from 'src/types';

export const CampaignInfoTableRow = ({
  id,
  name,
  invites,
  connected,
  viewed,
  messages,
  replies,
  runState,
}: IMemberCampaignStat) => {
  const { data, isLoading } = useGetCampaignProgress(id);

  return (
    <TableRow>
      <TableCell>
        <Box ml="10px" height="30px" display="grid" alignItems="center">
          <Typography lineHeight="16px" color="primary.light">
            {name}
          </Typography>
          {!!name && runState === 'paused' && (
            <Typography fontSize="10px" color="violet.dark">{`(${runState})`}</Typography>
          )}
        </Box>
      </TableCell>
      <TableCell>
        <Typography color="violet.dark">{invites}</Typography>
      </TableCell>
      <TableCell>
        <Typography color="violet.dark">{connected}</Typography>
      </TableCell>
      <TableCell>
        <Typography color="violet.dark">{viewed}</Typography>
      </TableCell>
      <TableCell>
        <Typography color="violet.dark">{messages}</Typography>
      </TableCell>
      <TableCell>
        <Typography color="violet.dark">{replies}</Typography>
      </TableCell>
      <TableCell>
        <Typography color="violet.dark">{`${
          messages + invites && replies ? ((replies / (messages + invites)) * 100).toFixed(1) : 0
        }%`}</Typography>
      </TableCell>

      <TableCell>
        {isLoading ? <Spinner type="dots" /> : <Typography color="violet.dark">{data?.progress || 0}%</Typography>}
      </TableCell>
    </TableRow>
  );
};
