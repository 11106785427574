import classNames from 'classnames';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import { CampaignCategory, CampaignSequenceDelayUnit, CampaignSequenceStepType } from 'src/enums';
import { getEmptySequenceStep } from 'src/helpers';
import { IGetCampaign, ISequence } from 'src/types';
import { AddSequenceStep } from './AddSequenceStep/AddSequenceStep';
import { SequenceStep } from './SequenceStep';

interface SequenceProps {
  sequenceForm: UseFormReturn<ISequence>;
  fieldArrayForm: UseFieldArrayReturn<ISequence, 'sequence'>;
  campaign?: IGetCampaign;
  category?: CampaignCategory;
}

const DroppableBox = styled(Box)`
  max-width: 1150px;
  border: 1px dashed transparent;

  &.dragging {
    border: 1px dashed ${({ theme }) => theme.palette.gray.main};
  }
`;

export const Sequence = ({ campaign, sequenceForm, category, fieldArrayForm }: SequenceProps) => {
  const { setValue, watch } = sequenceForm;

  const { fields, append, remove, move } = fieldArrayForm;

  const steps = watch('sequence');

  const addStepHandler = (type: CampaignSequenceStepType) => {
    append({
      ...getEmptySequenceStep({ campaign, isFirstStep: !steps?.length, sequenceType: type, sequenceForm }),
      type,
    });
  };

  const onDragEnd = (result: DropResult) => {
    if (result?.destination) {
      const sourceIndex = result?.source?.index;
      const destinationIndex = result?.destination?.index;
      move(sourceIndex, destinationIndex);

      // setting 1st touch step zero delay
      setValue(`sequence.${0}.delay_time_unit`, CampaignSequenceDelayUnit.DAYS);
      setValue(`sequence.${0}.delay_number`, 0);

      // setting 1st touch step 1 day delay after dragging
      if (destinationIndex === 0 || sourceIndex === 0) {
        setValue(`sequence.${sourceIndex || destinationIndex}.delay_number`, 1);
      }
    }
  };

  return (
    <>
      <Box pt="10px" px="15px" pb="150px">
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <DroppableBox ref={provided?.innerRef} className={classNames({ dragging: snapshot?.isDraggingOver })}>
                {fields.map((field, index) => (
                  <SequenceStep
                    key={field?.id}
                    fields={fields}
                    remove={remove}
                    campaign={campaign}
                    index={index}
                    step={field}
                    sequenceForm={sequenceForm}
                  />
                ))}
                {provided?.placeholder}
              </DroppableBox>
            )}
          </Droppable>
        </DragDropContext>

        <AddSequenceStep steps={steps} onAddSequenceStep={addStepHandler} category={category} campaign={campaign} />
      </Box>
    </>
  );
};
