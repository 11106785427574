import { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { Button, RadioButtonOld, Typography } from 'src/components';
import { useCompanyTheme } from 'src/hooks';
import { closeModal } from 'src/store/modal.slice';

interface IPauseSubscriptionProps {
  type: 'cancel' | 'pause';
}

export const PauseSubscription = ({ type }: IPauseSubscriptionProps) => {
  const dispatch = useDispatch();
  const { shortCompanyName } = useCompanyTheme();

  const [period, setPeriod] = useState<number>(0);
  const [showError, setShowError] = useState(false);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setShowError(false);
    setPeriod(Number(e.target.value));
  };

  const close = () => dispatch(closeModal());

  return (
    <>
      <Box py="30px" px="40px" bgcolor="light.light">
        <Typography color="violet.dark">
          Pausing your subscription will keep all your campaigns and data safely stored in our servers for future use
          without change. No payment will be required until you reactivate your account, but note that you will not gain
          access to your data until you do. You can unpause your subscription at any time.
        </Typography>

        <Typography my="8px" bold>
          Please select how long you wish to pause your subscription:
        </Typography>

        <RadioButtonOld id="period30" label="30 days" value={30} checked={period === 30} onChange={changeHandler} />

        <RadioButtonOld id="period60" label="60 days" value={60} checked={period === 60} onChange={changeHandler} />

        <RadioButtonOld id="period90" label="90 days" value={90} checked={period === 90} onChange={changeHandler} />

        <RadioButtonOld id="period180" label="180 days" value={180} checked={period === 180} onChange={changeHandler} />

        {showError && (
          <Typography mt="10px" mb="5px" color="error.main">
            Please select how long you wish to pause your subscription
          </Typography>
        )}
      </Box>
      {type === 'pause' ? (
        <Box py="20px" px="40px" display="flex" justifyContent="flex-end">
          <Button onClick={close}>Cancel</Button>
        </Box>
      ) : (
        <Box py="20px" px="40px" display="flex" justifyContent="center">
          <Button onClick={close}>Nevermind, keep using {shortCompanyName}</Button>
        </Box>
      )}
    </>
  );
};
