import { v4 as uuidv4 } from 'uuid';

import { IAttachment } from 'src/types';

export function getFileName(fileName: string) {
  const splitFile = fileName.split('.');
  const finalString = splitFile[splitFile.length - 1];

  let finalFileName = `${fileName.substring(0, fileName.length - finalString.length - 1)}`;

  const regex = /[^a-zA-Z0-9-_()]*/g;
  const letters = finalFileName.match(regex) || [];

  for (const letter of letters) {
    finalFileName = finalFileName.replace(letter, '');
  }

  return `${finalFileName}.${finalString}`;
}

export const getSingedURL = async (fileName: string, mimeType: string) => {
  const response = await fetch('https://xayifr2rag.execute-api.us-east-1.amazonaws.com/dev/api/v1/get-presigned-url', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      filename: fileName,
      mimetype: mimeType,
    }),
  });
  const data = await response.json();

  return data?.signed_url;
};

export const postDataToSignedUrl = (
  options: {
    url: string;
    file: File;
    formData: FormData;
    filetype: string;
  },
  callBack: (status: string) => void,
) => {
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', options.url, true);
  xhr.overrideMimeType(options.file.type);
  xhr.send(options.file);
  xhr.onload = function () {
    callBack(xhr.status === 200 ? 'success' : 'error');
  };
};

export const uploadAttachmentToBucket = async ({
  file,
  onSuccess,
  onUploadError,
  onUploadStart,
  onUrlError,
  isLinkedIn = false,
}: {
  file: File;
  onSuccess: (file: IAttachment) => void;
  onUploadError?: () => void;
  onUploadStart?: () => void;
  onUrlError?: () => void;
  isLinkedIn?: boolean;
}) => {
  let mediaType = isLinkedIn ? 'MESSAGING_FILE_ATTACHMENT' : 'DOCUMENT_SHARING';

  if (!isLinkedIn && file.type.indexOf('video') > -1) {
    mediaType = 'VIDEO_SHARING';
  } else if (file.type.indexOf('image') > -1) {
    mediaType = isLinkedIn ? 'MESSAGING_PHOTO_ATTACHMENT' : 'IMAGE_SHARING';
  }

  const fileName = getFileName(file.name);

  const fileNameToStore = `${uuidv4()}__${Date.now()}__${fileName}`;

  try {
    const signedUrl = await getSingedURL(fileNameToStore, file.type);

    if (signedUrl) {
      onUploadStart?.();
      const formData = new FormData();
      formData.append('file', file);

      const options = {
        url: signedUrl,
        file: file,
        formData: formData,
        filetype: file.type,
      };

      await new Promise<void>((resolve, reject) => {
        postDataToSignedUrl(options, (status: string) => {
          if (status === 'success') {
            onSuccess({
              file_uri: `https://d2p456lgyelacr.cloudfront.net/${fileNameToStore}`,
              file_name: fileName,
              file_type: file.type,
              file_size: file.size,
              mediaType: mediaType,
            });
            resolve();
          } else {
            onUploadError?.();
            reject(new Error("Couldn't upload the file!"));
          }
        });
      });
    } else {
      onUrlError?.();
    }
  } catch (_error) {
    onUploadError?.();
  }
};

export const createTempVideoElement = (file: File, onLoad: (element: HTMLVideoElement) => void) => {
  const temporaryVideoElement = document.createElement('video');
  temporaryVideoElement.preload = 'metadata';
  temporaryVideoElement.onloadedmetadata = async () => {
    window.URL.revokeObjectURL(temporaryVideoElement.src);
    onLoad(temporaryVideoElement);
  };
  if (file) {
    temporaryVideoElement.src = window.URL.createObjectURL(file);
  }
};

export const createTempImageElement = (file: File, onLoad: (element: HTMLImageElement) => void) => {
  const image = new Image();
  image.onload = () => {
    window.URL.revokeObjectURL(image.src);
    onLoad(image);
  };

  if (file) {
    image.src = window.URL.createObjectURL(file);
  }
};

export const getImageMimeType = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const uint8Array = new Uint8Array(reader?.result as ArrayBuffer)?.subarray(0, 4);
      const header = uint8Array.reduce((acc, value) => acc + value.toString(16), '');

      switch (header) {
        case '89504e47':
          return resolve('image/png');
        case '47494638':
          return resolve('image/gif');
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
          return resolve('image/jpeg');
        default:
          return resolve('unknown');
      }
    };
    reader.onerror = () => reject(reader.error);
    reader.readAsArrayBuffer(file);
  });
};

export const loadFileByUrl = async (url: string, name: string, type: string): Promise<File> => {
  const image = await fetch(url);

  const blob = await image.blob();

  return new File([blob], name, { type });
};

export const download = (href: string) => {
  const link = document.createElement('a');

  link.href = href;

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
};
