import { sortBy } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';

import {
  Checkbox,
  HtmlParser,
  Input,
  NotificationDot,
  ProfileAvatar,
  TableCell,
  TableRow,
  Tags,
  Tooltip,
  Typography,
} from 'src/components';
import { TagWrapper } from 'src/containers/Contacts/_components';
import { IConversationDrawerDataProps } from 'src/containers/Conversations/_components';
import { LeadStatus, ModalTypes } from 'src/enums';
import { openModal } from 'src/store/modal.slice';
import {
  ICampaignLead,
  IGetCampaign,
  IGetLeadsRequest,
  ILeadsUpdateFields,
  ISelectProps,
  TLeadsSortType,
} from 'src/types';
import { resolveActionStepNumber } from '../../../../../helpers';
import { LinkedinTableRowActions } from './LinkedinTableRowActions';

const Text = styled(Box)<{ checked?: boolean }>`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${({ checked, theme }) => (checked ? 'inherit' : theme.palette.text.primary)};
`;

const StyledInput = styled(Input)`
  height: 40px;
  margin: 0;
  color: #000;
`;

interface ILinkedinTableRow {
  campaign: IGetCampaign;
  lead: ICampaignLead;
  getLeadKeys: IGetLeadsRequest;
  selectProps: ISelectProps;
  setDrawerState: Dispatch<SetStateAction<IConversationDrawerDataProps>>;
  config: {
    showMessages: boolean;
    showLocation: boolean;
    hideActions: boolean;
  };
}

const SHOW_MESSAGE_WHITELIST: TLeadsSortType[] = ['emailed', 'emailsBounced', 'emailReplies'];

export const LinkedinTableRow = ({
  lead,
  getLeadKeys,
  selectProps,
  setDrawerState,
  campaign,
  config,
}: ILinkedinTableRow) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();

  const [isEdit, setIsEdit] = useState(false);
  const {
    title,
    company,
    location,
    message,
    entityUrn,
    linkedinData,
    customData,
    name,
    csvColumns,
    tags,
    status,
    isExcluded,
    nextTouchIndex,
  } = lead;
  const { firstName = '', lastName = '', pic = '' } = linkedinData || {};
  const { hideActions, showLocation, showMessages } = config;

  const [leadFirstName, leadLastName] = (name || '')?.trim()?.split(/\s(.+)/) || [];
  const values = {
    firstName: firstName || leadFirstName,
    lastName: lastName || leadLastName,
    company,
    title,
    tags,
    ...customData,
  };

  const sortedTags = useMemo(() => sortBy(tags, 'tag'), [tags]);
  const tagIds = useMemo(() => sortedTags.map(({ id }) => id), [sortedTags]);

  const onCellClick = () => {
    dispatch(
      openModal({
        headerText: 'Change tags',
        type: ModalTypes.ADD_LEADS_TAGS,
        params: { entityUrn, tagIds, campaignId: campaign.id, campaignKeys: getLeadKeys },
      }),
    );
  };

  const { register, handleSubmit, reset } = useForm<ILeadsUpdateFields>({
    defaultValues: values,
  });

  const leadProfileName =
    values?.firstName || values?.lastName
      ? `${values?.firstName} ${values?.lastName}`
      : csvColumns?.email || csvColumns?.name;
  const repliesFilter: TLeadsSortType[] = ['replies', 'allReplies', 'inmailReplies', 'userInteraction', 'emailReplies'];
  const returnedLead = repliesFilter?.includes(getLeadKeys?.type) && lead?.isReturnToCampaign;
  const isSelected = selectProps?.selectLeads?.includes(entityUrn);
  const totalSteps = campaign?.touchSequence?.sequence?.length || 1;

  const messageWithSnippets = Object?.entries(lead?.csvColumns || {})?.reduce(
    (acc, value) => acc?.replaceAll(`{{${value[0]}}}`, value[1]),
    message || '',
  );

  useEffect(() => {
    reset(values);
  }, [customData]);

  const modalHandler = () => {
    dispatch(
      openModal({
        type: ModalTypes.VIEW_LEAD,
        closable: false,
        params: {
          campaignId: campaign?.id,
          entityUrn,
        },
      }),
    );
  };

  return (
    <TableRow
      key={lead?.createdAt}
      cursor="pointer"
      hoverBgColor={({ light }) => light.main}
      checkedColor="#fff"
      checkedBgColor={palette.primary.main}
      checked={isSelected}
      onClick={modalHandler}
    >
      <TableCell onClick={(event) => event.stopPropagation()}>
        <Checkbox checked={isSelected} onChange={() => selectProps.toggleLead(entityUrn)} />
      </TableCell>
      <TableCell preventClick={isEdit}>
        {isEdit ? (
          <Box display="flex" justifyContent="space-between" gap="10px" paddingRight="5%">
            <StyledInput name="firstName" placeholder="First Name" register={register} />

            <StyledInput name="lastName" placeholder="Last Name" register={register} />
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            <ProfileAvatar
              margin="0 20px 0 1px"
              cursor="pointer"
              avatarSrc={pic}
              firstLatter={(leadProfileName || '')[0]}
            />

            <Text checked={isSelected}>{leadProfileName}</Text>
            {returnedLead && (
              <Typography color="violet.dark" fontSize="12px">
                (Returned to campaign)
              </Typography>
            )}
            {(status !== LeadStatus.APPROVED && (
              <NotificationDot tooltip={{ title: 'Ignored lead', placement: 'right' }} />
            )) ||
              (isExcluded && (
                <NotificationDot
                  tooltip={{
                    title: (
                      <>
                        <b>Excluded lead:</b> This lead was excluded from the campaign. Update their status in the CRM
                        to make changes.
                      </>
                    ),
                    placement: 'right',
                  }}
                />
              ))}
          </Box>
        )}
      </TableCell>

      <TableCell preventClick={isEdit}>
        {isEdit ? (
          <StyledInput name="title" placeholder="Position" register={register} />
        ) : (
          <Tooltip title={title?.length > 30 && title}>
            <Text checked={isSelected}>{values?.title}</Text>
          </Tooltip>
        )}
      </TableCell>

      <TableCell preventClick={isEdit}>
        {isEdit ? (
          <StyledInput name="company" placeholder="Company" register={register} />
        ) : (
          <Text checked={isSelected}>{values?.company}</Text>
        )}
      </TableCell>

      {showLocation && (
        <TableCell preventClick={isEdit}>
          <Text checked={isSelected}>{location}</Text>
        </TableCell>
      )}

      {showMessages && (
        <TableCell preventClick={isEdit}>
          <Text checked={isSelected}>
            {SHOW_MESSAGE_WHITELIST.includes(getLeadKeys?.type) ? (
              <HtmlParser parseText={(messageWithSnippets ?? '')?.replace(/<img[^>]*>/g, '')} />
            ) : (
              messageWithSnippets
            )}
          </Text>
        </TableCell>
      )}

      <TableCell
        maxWidth="180px"
        onClick={(event) => {
          event.stopPropagation();
          onCellClick();
        }}
        contentStyle={{ minWidth: ' 100%' }}
      >
        <TagWrapper>
          <Tags withTooltip ids={tagIds} />
        </TagWrapper>
      </TableCell>

      <TableCell>{resolveActionStepNumber({ stepNumber: nextTouchIndex, totalSteps })}</TableCell>

      {hideActions || (
        <LinkedinTableRowActions
          lead={lead}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          getLeadKeys={getLeadKeys}
          campaign={campaign}
          handleSubmit={handleSubmit}
          setDrawerState={setDrawerState}
        />
      )}
    </TableRow>
  );
};
