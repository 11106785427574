import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';

import { Button, Typography } from 'src/components';
import { ModifyConversationActions } from 'src/enums';
import { useModifyConversation } from 'src/reactQueries';
import { setSelectedConversation } from 'src/store/conversation.slice';
import { closeModal } from 'src/store/modal.slice';

interface IModalDeleteConversationProps {
  name: string;
  conversationId: string;
}

export const ModalDeleteConversation = ({ name, conversationId }: IModalDeleteConversationProps) => {
  const dispatch = useDispatch();

  const { modifyConversation, isLoading } = useModifyConversation(conversationId);

  const close = () => {
    dispatch(closeModal());
  };

  const confirm = () => {
    modifyConversation(ModifyConversationActions.DELETE);
    dispatch(setSelectedConversation(null));
    close();
  };

  return (
    <>
      <Box display="flex" justifyContent="center" p="30px">
        <Typography mr="4px">Sure you want to delete your entire conversation history with</Typography>
        <Typography semibold>{name}</Typography>
        <Typography>?</Typography>
      </Box>
      <Box pb="20px" px="40px" display="flex" justifyContent="space-around">
        <Button variant="gray" onClick={close}>
          Cancel
        </Button>
        <Button processing={isLoading} onClick={confirm}>
          Yes, delete
        </Button>
      </Box>
    </>
  );
};
